// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import * as ProtoDescriptor from '../../../ProtoDescriptor';
import { ParamScope } from '../../../lib/ParamScope';
import { SelectOption } from '../../../lib/componentTypes/form';
import { colors } from '../../../lib/designSystem';
import { TABLE_PREVIEW_SUBTITLE } from '../../../lib/explorationUtils';
import * as explorationpb from '../../../proto/exploration/exploration_pb';
import { DataSelect } from '../../Form/DataSelect';
import QuantityAdornment from '../../QuantityAdornment';
import Tooltip from '../../Tooltip';
import { TablePreviewDialog } from '../../dialog/TablePreviewDialog';
import { TableOutlinedIcon } from '../../svg/TableOutlinedIcon';
import { XIcon } from '../../svg/XIcon';
import { AxisAdornment } from '../../visual/AxisAdornment';
import './ColumnOnlySelector.scss';

export interface ColumnOnlySelectorProps {
  /** Param to be modified */
  param: ProtoDescriptor.Param;
  /**
   * The available columns names that can be selected.
   * The order must be equal to the order in the uploaded table
   */
  columns: string[];
  /** The index of the selected column name in `props.columns`, undefined if none selected */
  selected?: number;
  /** If not undefined, an axis adornment is displayed on the dropdown */
  axisAdornment?: 'x' | 'y' | 'z';
  /**
   * The index of the column to highlight in the table preview dialog
   *
   * If undefined, the `selected` index will be used
   */
  highlightIndex?: number;
  /** True if this is disabled */
  disabled?: boolean;
  /** Optional tooltip text for while this is disabled */
  disabledReason?: string;
  /** The name of the selected table */
  tableName?: string;
  /** The url of the selected table */
  tableUrl?: string;
  /** Callback when the selected column changes where `value` is the index in `props.columns` */
  onChange: (value: number) => void;
  /** Callback when the column is unlinked from the variable */
  onUnlink: () => void;
  /**
   * Optional exploration details
   *
   * If provided, the Table Preview will substitute empty cells with the values from the exploration
   * and simulation setup
   */
  explorationPreview?: {
    /** The param scope */
    paramScope: ParamScope
    /** The exploration */
    exploration: explorationpb.Exploration;
  };
}

export function ColumnOnlySelector(props: ColumnOnlySelectorProps) {
  // Props
  const {
    param, columns, selected, axisAdornment, highlightIndex, disabled, disabledReason,
    tableName, tableUrl, onChange, onUnlink, explorationPreview,
  } = props;

  // State
  const [previewOpen, setPreviewOpen] = useState(false);

  if (selected === undefined) {
    // when no column is selected, display drop down menu
    const options: SelectOption<number>[] = columns.map((name, index) => ({
      name,
      value: index,
      icon: {
        name: 'tableOutlined',
        color: colors.inputPlaceholderText,
        tooltip: 'This value is a column from the linked table.',
      },
    }));
    return (
      <DataSelect
        asBlock
        disabled={disabled}
        onChange={onChange}
        options={options}
        placeholderText={axisAdornment && `Select ${axisAdornment}...`}
        size="small"
        tooltip={disabledReason}
      />
    );
  }

  // when a column is selected, display the column name and have the option to preview the table
  // or to unlink the column
  const columnName = columns[selected];

  return (
    <div className="columnOnlyContainer">
      <Tooltip arrow={false} title={disabled ? '' : 'Click to Preview'}>
        <button className="column" onClick={() => setPreviewOpen(true)} type="button">
          <div className="name">
            {axisAdornment && <AxisAdornment axis={axisAdornment} />}
            <div className="icon">
              <TableOutlinedIcon maxHeight={12} maxWidth={12} />
            </div>
            {columnName}
          </div>
          <QuantityAdornment quantity={param.quantityType} />
        </button>
      </Tooltip>
      <Tooltip arrow={false} title={disabled ? '' : 'Unlink Table'}>
        <button
          className="unlink"
          onClick={onUnlink}
          type="button">
          <div className="controlIcon">
            <XIcon maxHeight={9} maxWidth={9} />
          </div>
        </button>
      </Tooltip>
      {tableName && tableUrl && (
        <TablePreviewDialog
          explorationPreview={explorationPreview}
          headerColumn
          highlightColumn={highlightIndex}
          onClose={() => setPreviewOpen(false)}
          open={previewOpen}
          subtitle={TABLE_PREVIEW_SUBTITLE}
          tableName={tableName}
          tableUrl={tableUrl}
        />
      )}
    </div>
  );
}
