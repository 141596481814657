// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
/**
 * Utils for lcvis state persistence serialization and deserialization
 */

import { extname, isLcSolnExtension } from '../../lib/path';

/**
 * LCVis state persistence utils.
 * (requirements comment copied from paraviewState.ts)
 * In the simulation tabs we have a design constraint when it comes to state management. When we
 * create a simulation, the simulation tab is opened displaying the mesh file. In this
 * case we want to keep the Setup and Simulation tab in sync. As soon as a simulation file is
 * displayed (once the simulation run completes), the simulation tab switches over to use the
 * shared settings between all completed simulations in that project.
*/

/** A key used to access an LCVis object from the kvstore. */
export type LcvisKvStoreKey = {
  projectId: string,
  /**
   * The ordered list of keys to fetch.
   * Each key is of the format ${prefix}/fileType=${fileType}
   * where prefix is the type of object requested, and the fileType is either 'mesh' or 'solution'.
   * For solution files we first request using the key for lcsoln, if that isn't found we request
   * with the lcmesh key. For mesh/non-solution files we just use the mesh key.
  */
  lcvMeshKeys: string[],
}

/**
 * The type of object requested from the kvstore. Must have a corresponding entry in
 * projectstate.go.
 */
export type LcvisMeshPrefix = 'lcvisCamera' | 'lcvisVisibility' | 'lcvisSettings'

/** The filetype corresponding to the active mesh in the visualizer. */
export type LcvisFileType = 'mesh' | 'solution'

const newKvStoreKey = (
  prefix: LcvisMeshPrefix,
  fileType: LcvisFileType,
) => `${prefix}/lcvisFileType=${fileType}`;

/** Return the lcvMeshKeys to use for a given object type (indicated by the prefix). */
export const makeLcvMeshKeys = (
  prefix: LcvisMeshPrefix,
  activeVisUrl: string,
) => {
  if (!activeVisUrl) {
    return [];
  }

  if (isLcSolnExtension(extname(activeVisUrl))) {
    return [
      newKvStoreKey(prefix, 'solution'),
      newKvStoreKey(prefix, 'mesh'),
    ];
  }
  return [newKvStoreKey(prefix, 'mesh')];
};
