// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { Logger } from '../../../observability/logs';

const logger = new Logger('AsyncQueue');

/* A simple queue for async tasks. This is used to serialize access to calls that
 * modify the import data set filters and executing the workspace.
*/
export class AsyncQueue {
  private queue: Promise<any> = Promise.resolve();

  enqueue(task: () => Promise<void>): Promise<void> {
    this.queue = this.queue.then(this.wrapTask(task));
    return this.queue;
  }

  wrapTask(task: () => Promise<void>): () => Promise<void> {
    return () => new Promise((resolve, reject) => {
      task().then(() => resolve()).catch((error) => {
        logger.error('error executing task', error);
        resolve();
      });
    });
  }
}
