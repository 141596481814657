// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const HashIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="hash"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M6.327,24
           C6.505,24 6.648,23.946 6.755,23.839
           C6.862,23.732 6.934,23.607 6.969,23.464
           L8.04,17.571
           L13.98,17.571
           L12.963,23.25
           C12.928,23.429 12.972,23.598 13.097,23.759
           C13.222,23.92 13.391,24 13.606,24
           L14.89,24
           C15.068,24 15.211,23.946 15.318,23.839
           C15.425,23.732 15.497,23.607 15.532,23.464
           L16.603,17.571
           L21.58,17.571
           C21.723,17.571 21.856,17.518 21.981,17.411
           C22.106,17.304 22.186,17.179 22.222,17.036
           L22.436,15.75
           C22.472,15.571 22.427,15.402 22.302,15.241
           C22.177,15.08 22.008,15 21.794,15
           L17.031,15
           L18.101,9
           L23.132,9
           C23.275,9 23.408,8.946 23.533,8.839
           C23.658,8.732 23.738,8.607 23.774,8.464
           L23.988,7.179
           C24.024,7 23.979,6.83 23.854,6.67
           C23.729,6.509 23.56,6.429 23.346,6.429
           L18.583,6.429
           L19.6,0.75
           C19.635,0.571 19.591,0.402 19.466,0.241
           C19.341,0.08 19.171,0 18.957,0
           L17.673,0
           C17.495,0 17.352,0.054 17.245,0.161
           C17.138,0.268 17.066,0.393 17.031,0.536
           L15.96,6.429
           L10.02,6.429
           L11.037,0.75
           C11.072,0.571 11.028,0.402 10.903,0.241
           C10.778,0.08 10.609,0 10.394,0
           L9.11,0
           C8.932,0 8.789,0.054 8.682,0.161
           C8.575,0.268 8.503,0.393 8.468,0.536
           L7.397,6.429
           L2.42,6.429
           C2.242,6.429 2.099,6.482 1.992,6.589
           C1.885,6.696 1.814,6.821 1.778,6.964
           L1.564,8.25
           C1.528,8.429 1.573,8.598 1.698,8.759
           C1.823,8.92 1.992,9 2.206,9
           L6.969,9
           L5.899,15
           L0.868,15
           C0.725,15 0.592,15.054 0.467,15.161
           C0.342,15.268 0.262,15.393 0.226,15.536
           L0.012,16.821
           C-0.024,17 0.021,17.17 0.146,17.33
           C0.271,17.491 0.44,17.571 0.654,17.571
           L5.417,17.571
           L4.4,23.25
           C4.365,23.429 4.409,23.598 4.534,23.759
           C4.659,23.92 4.829,24 5.043,24
           L6.327,24Z
           M14.462,15
           L8.468,15
           L9.538,9
           L15.532,9
           L14.462,15Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
