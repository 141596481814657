// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

let nextId = 1;

// For regular objects, we store the ID in field lcInternalObjectId.  For frozen
// objects, we keep the IDs in a weakmap.
const frozenObjects = new WeakMap();

// Assigns a UID to an object. It return -1 for null.
export default function objectId(obj: any): number {
  if (obj == null) {
    return -1;
  }
  if (!Object.isExtensible(obj)) {
    let value = frozenObjects.get(obj);
    if (!value) {
      nextId += 1;
      value = nextId;
      frozenObjects.set(obj, value);
    }
    return value;
  }
  if ((obj as any).lcInternalObjectId == null) {
    nextId += 1;
    (obj as any).lcInternalObjectId = nextId;
  }
  return (obj as any).lcInternalObjectId;
}
