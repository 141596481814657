// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { useGeometryState, useIsLastAckedMod } from '../../../../recoil/geometry/geometryState';
import { useProjectContext } from '../../../context/ProjectContext';
import { SectionMessage } from '../../../notification/SectionMessage';

type ModificationWarning = {
  level: 'info' | 'warning' | 'error';
  message: string;
}

export enum MODIFICATION_WARNINGS {
  EDIT_WARNING,
}

const MOFICIATION_WARNINGS_MAP: { [e: string]: ModificationWarning } = {
  [MODIFICATION_WARNINGS.EDIT_WARNING]: {
    level: 'warning',
    message: 'Altering or deleting this step might impact subsequent modifications.',
  },
};

interface ModificationWarningMessageProps {
  errorType: MODIFICATION_WARNINGS
}

export const ModificationWarningMessage = (props: ModificationWarningMessageProps) => {
  const { errorType } = props;

  const { level, message } = MOFICIATION_WARNINGS_MAP[errorType];

  return (
    <div style={{ marginBottom: '12px' }}>
      <SectionMessage level={level}>
        {message}
      </SectionMessage>
    </div>
  );
};

/**
 * A section message to warn the user if they edit a modification that has already been created.
 */
export const EditModificationMessage = (props: { nodeId: string }) => {
  const { nodeId } = props;
  const { projectId, geometryId } = useProjectContext();
  const geometryState = useGeometryState(projectId, geometryId);
  const isLastAcked = useIsLastAckedMod({ geometryId, nodeId, projectId });

  const isAcknowledgedFeature = geometryState?.ackModifications.has(nodeId);

  if (isAcknowledgedFeature && !isLastAcked) {
    return <ModificationWarningMessage errorType={MODIFICATION_WARNINGS.EDIT_WARNING} />;
  }
  return <></>;
};
