// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
// Tooltip text with corresponding key binding next to it, slightly greyed out.
import React from 'react';

import { colors } from '../../lib/designSystem';
import { parseString } from '../../lib/html';

export interface TipWithBindingsProps {
    binding: React.ReactNode;
    tip: string;
}

export const TipWithBindings = (props: TipWithBindingsProps) => {
  const { tip, binding } = props;

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: '16px',
      maxWidth: '100%',
    }}>
      <span style={{ flex: '1 1 auto' }}>{parseString(tip)}</span>
      <span style={{ color: colors.lowEmphasisText, flex: '0 0 auto' }}>
        {typeof binding === 'string' ? parseString(binding) : binding}
      </span>
    </div>
  );
};
