// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { Tab, TabSize } from './Tab';

import './ButtonTabs.scss';

export interface TabConfig<T> {
  value: T;
  label: string;
  disabled?: boolean; // optionally disable individual tab
}

export interface ButtonTabsProps<T> {
  tabs: TabConfig<T>[];
  onChange: (value: T) => void; // callback for tab selection
  disabled?: boolean; // optionally disable the whole control
  asBlock?: boolean; // optionally layout as a block level element rather than inline
  equalWidths?: boolean; // optionally force individual tabs to be the same width as the widest one
  value?: T; // optionally specify the currently selected tab
  size?: TabSize; // optionally specify a size (default: 'medium')
}

export function ButtonTabs<T>(props: ButtonTabsProps<T>) {
  const { asBlock, disabled, equalWidths, onChange, size, tabs, value } = props;

  return (
    <div className={cx('buttonTabs', size, { asBlock, disabled, equalWidths })}>
      {tabs.map((tab, i) => (
        <div
          className="tabWrapper"
          key={`${tab.value}`}>
          <Tab
            disabled={disabled || tab.disabled}
            first={i === 0}
            label={tab.label}
            last={i === tabs.length - 1}
            name={`${tab.value}`}
            onClick={() => {
              if (!(disabled || tab.disabled)) {
                onChange(tab.value);
              }
            }}
            selected={value === tab.value}
            size={size}
          />
        </div>
      ))}
    </div>
  );
}
