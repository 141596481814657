// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as basepb from '../proto/base/base_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';

import { getAdValue, newAdFloat } from './adUtils';
import { DEGREES_TO_RADIANS } from './geometry';
import { fromBigInt } from './number';
import {
  Vector,
  addVectors,
  crossProduct,
  dotProduct,
  magnitude as magnitudeVa,
  scalarMultiplyVector,
  subtractVectors,
  unitVector,
} from './vectorAlgebra';

/**
 * Return an ordered array of numbers from a vector
 * @param vec
 * @returns
 */
export function toArray(vec: basepb.Vector3) {
  const { x, y, z } = vec;
  return [x, y, z];
}

/**
   Create a new basepb.Vector3 from the three values.
*/
export function newProto(x: number, y: number, z: number): basepb.Vector3 {
  return new basepb.Vector3({ x, y, z });
}

/**
   Create a new basepb.Vector3 at the origin (0, 0, 0).
*/
export function newOriginProto(): basepb.Vector3 {
  return newProto(0, 0, 0);
}

/**
   Check if the two vectors are identical
*/
export function equal(v0: basepb.Vector3, v1: basepb.Vector3) {
  return (v0.x === v1.x && v0.y === v1.y && v0.z === v1.z);
}

/**
   Check if the two vectors are close to each other.
*/
export function near(v0: basepb.Vector3, v1: basepb.Vector3) {
  return (
    (v0.x - v1.x) ** 2 <= 1e-6 &&
    (v0.y - v1.y) ** 2 <= 1e-6 &&
    (v0.z - v1.z) ** 2 <= 1e-6);
}

/**
   Check if the two ParaviewRpc.Vector3s are close to each other.
*/
export function nearPv(v0: ParaviewRpc.Vector3, v1: ParaviewRpc.Vector3) {
  return (
    (v0.x - v1.x) ** 2 <= 1e-6 &&
    (v0.y - v1.y) ** 2 <= 1e-6 &&
    (v0.z - v1.z) ** 2 <= 1e-6);
}
/**
   Convert a proto Vector3 to the pvproto format.
*/
export function toPvProto(vec: basepb.Vector3): ParaviewRpc.Vector3 {
  return {
    x: vec.x,
    y: vec.y,
    z: vec.z,
  };
}

export function toVector3(vec: basepb.Vector3): Vector<3> {
  return [vec.x, vec.y, vec.z];
}

// Add two vectors.
export function add(a: basepb.Vector3, b: basepb.Vector3): basepb.Vector3 {
  const [x, y, z] = addVectors(toVector3(a), toVector3(b));
  return newProto(x, y, z);
}

// Subtract vector b from a.
export function subtract(a: basepb.Vector3, b: basepb.Vector3): basepb.Vector3 {
  const [x, y, z] = subtractVectors(toVector3(a), toVector3(b));
  return newProto(x, y, z);
}

/**
   Multiplies a proto Vector3 by a scalar.
*/
export function mult(vec: basepb.Vector3, factor: number): basepb.Vector3 {
  const [x, y, z] = scalarMultiplyVector(toVector3(vec), factor);
  return newProto(x, y, z);
}

/**
  Cross product between two vectors
*/
export function cross(a: basepb.Vector3, b: basepb.Vector3): basepb.Vector3 {
  const [x, y, z] = crossProduct(toVector3(a), toVector3(b));
  return newProto(x, y, z);
}

/**
   Convert a pvproto Vector3 to the proto format.
*/
export function toProto(vec: ParaviewRpc.Vector3): basepb.Vector3 {
  const { x, y, z } = vec;
  return new basepb.Vector3({ x, y, z });
}

/**
  Convert a raw list/tuple to Vector3.
*/
export function listToPv(vec: [number, number, number]): ParaviewRpc.Vector3 {
  return {
    x: vec[0],
    y: vec[1],
    z: vec[2],
  };
}

/**
 * Convert a pv Vector3 to a list.
 */
export function pvToList(pvVec: { x: number, y: number, z: number }): [number, number, number] {
  return [pvVec.x, pvVec.y, pvVec.z];
}

/** Compute v0 + v1 */
export function pvAdd(v0: ParaviewRpc.Vector3, v1: ParaviewRpc.Vector3): ParaviewRpc.Vector3 {
  const [x, y, z] = addVectors([v0.x, v0.y, v0.z], [v1.x, v1.y, v1.z]);
  return { x, y, z };
}

/** Compute v0 - v1 */
export function pvSub(v0: ParaviewRpc.Vector3, v1: ParaviewRpc.Vector3): ParaviewRpc.Vector3 {
  const [x, y, z] = subtractVectors([v0.x, v0.y, v0.z], [v1.x, v1.y, v1.z]);
  return { x, y, z };
}

/** Compute vec * multiplier */
export function pvMul(vec: ParaviewRpc.Vector3, multiplier: number): ParaviewRpc.Vector3 {
  const [x, y, z] = scalarMultiplyVector([vec.x, vec.y, vec.z], multiplier);
  return { x, y, z };
}

/** Compute vec / multiplier */
export function pvDiv(vec: ParaviewRpc.Vector3, multiplier: number): ParaviewRpc.Vector3 {
  const [x, y, z] = scalarMultiplyVector([vec.x, vec.y, vec.z], 1 / multiplier);
  return { x, y, z };
}

/** Report the length (L2 norm) of the vector */
export function pvNorm(vec: ParaviewRpc.Vector3): number {
  return magnitudeVa([vec.x, vec.y, vec.z]);
}

/** Convert radians to degrees */
export function radsToDegrees(rad: number): number {
  return rad / DEGREES_TO_RADIANS;
}

/** Convert degrees to radians */
export function degreesToRads(deg: number): number {
  return deg * DEGREES_TO_RADIANS;
}

/** Compute the dot product of a and b. */
export function dot(a: basepb.Vector3, b: basepb.Vector3): number {
  return dotProduct(toVector3(a), toVector3(b));
}

/** Report the magnitude (length) of the vector */
export function magnitude(vec: basepb.Vector3): number {
  return magnitudeVa(toVector3(vec));
}

/** Normalize the vector vec. */
export function normalize(vec: basepb.Vector3): basepb.Vector3 {
  const [x, y, z] = unitVector(toVector3(vec));
  return newProto(x, y, z);
}

/**
  Convert a normal vector into a set of Euler angles (degrees)
  using spherical coords. Z+ is up.
*/
export function normalToEuler(vec: basepb.Vector3): basepb.Vector3 {
  const mag = magnitude(vec);
  let theta = 0.0;
  let phi = 0.0;
  if (mag > 0) {
    // Make sure this is normalized.
    const nx = vec.x / mag;
    const ny = vec.y / mag;
    const nz = vec.z / mag;
    theta = radsToDegrees(Math.acos(nz));
    phi = radsToDegrees(Math.atan2(ny, nx));
  }
  return new basepb.Vector3({ x: 0, y: theta, z: phi });
}

/**
 * Rotate a vector by a set of Euler angles in degrees consistent with the param conventions: x
 * then y then z.
 */
export function rotate(rotDegrees: basepb.Vector3, vec: basepb.Vector3): basepb.Vector3 {
  const radX = degreesToRads(rotDegrees.x);
  const radY = degreesToRads(rotDegrees.y);
  const radZ = degreesToRads(rotDegrees.z);
  const cosRX = Math.cos(radX);
  const cosRY = Math.cos(radY);
  const cosRZ = Math.cos(radZ);

  const sinRX = Math.sin(radX);
  const sinRY = Math.sin(radY);
  const sinRZ = Math.sin(radZ);

  const x = (cosRY * cosRZ) * vec.x +
    (sinRX * sinRY * cosRZ - cosRX * sinRZ) * vec.y +
    (cosRX * sinRY * cosRZ + sinRX * sinRZ) * vec.z;

  const y = (cosRY * sinRZ) * vec.x +
    (sinRX * sinRY * sinRZ + cosRX * cosRZ) * vec.y +
    (sinRZ * sinRY * cosRX - cosRZ * sinRX) * vec.z;

  const z = -sinRY * vec.x +
    cosRY * sinRX * vec.y +
    cosRX * cosRY * vec.z;

  const res = new basepb.Vector3({ x: x || 0, y: y || 0, z: z || 0 });
  return res;
}

/**
 * Given an x-axis and y-axis defining a new coordinate system, return the euler angles
 * to transform that system back to the global coordinate system when applied in XYZ order
 */
export function axesToEuler(newX: basepb.Vector3, newY: basepb.Vector3): basepb.Vector3 {
  const newZ = normalize(cross(newX, newY));
  // the rotation matrix
  const mat = [
    [newX.x, newY.x, newZ.x],
    [newX.y, newY.y, newZ.y],
    [newX.z, newY.z, newZ.z],
  ];

  const [r31, r32, r33] = [mat[2][0], mat[2][1], mat[2][2]];
  const r21 = mat[1][0];
  const [r11, r12, r13] = [mat[0][0], mat[0][1], mat[0][2]];
  let psi = 0.0;
  let theta = 0.0;
  let phi = 0.0;

  if (Math.abs(r31) === 1) {
    phi = 0;
    if (r31 === -1) {
      theta = Math.PI / 2;
      psi = phi + Math.atan2(r12, r13);
    } else {
      theta = -Math.PI / 2;
      psi = -phi + Math.atan2(-r12, -r13);
    }
  } else {
    theta = -Math.asin(r31);
    const cosTheta = Math.cos(theta);
    psi = Math.atan2(r32 / cosTheta, r33 / cosTheta);
    phi = Math.atan2(r21 / cosTheta, r11 / cosTheta);
  }

  return new basepb.Vector3({
    x: radsToDegrees(psi),
    y: radsToDegrees(theta),
    z: radsToDegrees(phi),
  });
}

export function intVectorToArray(vec: basepb.IntVector3): [number, number, number] {
  const { x, y, z } = vec;
  return [fromBigInt(x), fromBigInt(y), fromBigInt(z)];
}

/**
 * Transform an AdVector3 to an array of length 3
 *
 * @returns an array of length 3 or undefined if the AdVector3 is missing any values
 */
export function adVectorToArray(vec: basepb.AdVector3): [number, number, number] | undefined {
  const x = getAdValue(vec.x);
  const y = getAdValue(vec.y);
  const z = getAdValue(vec.z);

  if (x === undefined || y === undefined || z === undefined) {
    return undefined;
  }
  return [x, y, z];
}

/**
 * Set a value at a specific index of a vector
 *
 * @param vec the vector to modify
 * @param index the index of the vector to modify (0, 1, or 2)
 * @param value the value to to be set to
 * @returns a vector with the value set at the index, if the index is invalid, the original vector
 * is returned
 */
export function setAdVector3AxisWithIndex(
  vec: basepb.AdVector3,
  index: number,
  value: number | basepb.AdFloatType | undefined,
): basepb.AdVector3 {
  const floatValue = typeof value === 'number' ? newAdFloat(value) : value;
  if (index === 0) {
    vec.x = floatValue;
  }
  if (index === 1) {
    vec.y = floatValue;
  }
  if (index === 2) {
    vec.z = floatValue;
  }
  return vec;
}

/**
 * Set a value at a specific index of a vector
 *
 * @param vec the vector to modify
 * @param index the index of the vector to modify (0, 1, or 2)
 * @param value the value to to be set to
 * @returns a vector with the value set at the index, if the index is invalid, the original vector
 * is returned
 */
export function setIntVector3AxisWithIndex(
  vec: basepb.IntVector3,
  index: number,
  value: number | undefined,
): basepb.IntVector3 {
  if (value === undefined) {
    return vec;
  }
  if (index === 0) {
    vec.x = BigInt(value);
  }
  if (index === 1) {
    vec.y = BigInt(value);
  }
  if (index === 2) {
    vec.z = BigInt(value);
  }
  return vec;
}

/** Get the value of the vector of a specific axis given an index representing the axis
 *
 * @param vec the vector to get the value from
 * @param index the index of the axis (x = 0, y = 1, z = 2)
 *
 * @returns the value of the axis, or undefined if the index is invalid
 */
export function getAxisWithIndex(
  vec: basepb.AdVector3,
  index: number,
) {
  if (index === 0) {
    return vec.x;
  } if (index === 1) {
    return vec.y;
  } if (index === 2) {
    return vec.z;
  }
  return undefined;
}

export function newIntArray(x = 0n, y = 0n, z = 0n) {
  return new basepb.IntVector3({ x, y, z });
}
