// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { colors } from '../../../../lib/designSystem';
import { createStyles, makeStyles } from '../../../Theme';

const useStyles = makeStyles(
  () => createStyles({
    panelHeading: {
      textAlign: 'center',
      padding: '16px 16px 0',
    },
    title: {
      fontSize: '18px',
    },
    subtitle: {
      padding: '4px',
      color: colors.lowEmphasisText,
      fontSize: '13px',
    },
  }),
  { name: 'PanelHeading' },
);

interface PanelHeadingProps {
  title: string;
  subtitle: string;
}

export const PanelHeading = (props: PanelHeadingProps) => {
  const { title, subtitle } = props;

  const classes = useStyles();

  return (
    <div className={classes.panelHeading}>
      <div className={classes.title}>{title}</div>
      <div className={classes.subtitle}>{subtitle}</div>
    </div>
  );
};
