// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { Code, ConnectError } from '@connectrpc/connect';
import { SetterOrUpdater } from 'recoil';

import * as frontendpb from '../proto/frontend/frontend_pb';
import * as meshgenerationpb from '../proto/meshgeneration/meshgeneration_pb';
import { analytics } from '../services/analytics';

import assert from './assert';
import { Logger } from './observability/logs';
import * as rpc from './rpc';
import { stringifyError } from './status';
import * as status from './status';
import { addRpcError } from './transientNotification';
import { WorkOrderDone, WorkOrderReply, WorkOrderRequest } from './workOrderTypes';

const logger = new Logger('workOrderHandler');

// Drop a work order with the given ID. This cancels the work order.
const dropWorkOrder = (projectId: string, workOrderId: string) => {
  const req = new frontendpb.DropWorkOrderRequest({ projectId, workOrderId });
  rpc.client.dropWorkOrder(req)
    .then((reply) => {
      logger.debug(`dropped work order ${workOrderId}`);
      analytics.track('Work Order Dropped', { projectId, workOrderId });
    })
    .catch((err) => {
      logger.error(`dropWorkOrder: ${stringifyError(err)}`);
      analytics.track('Work Order Drop Failed', {
        projectId,
        workOrderId,
        error: stringifyError(err),
      });
    });
};

export type WorkOrderRpcPool = rpc.StreamingRpcPool<WorkOrderRequest, WorkOrderReply>;

export interface WorkOrderContext {
  projectId: string;
  readonly setPendingWorkOrders: SetterOrUpdater<frontendpb.PendingWorkOrders>;
  clearRequest?: () => void;
  handleReply?: (type: frontendpb.WorkOrderType, done: WorkOrderDone, url: string) => void;
  handlePreview?: (preview: frontendpb.GetGeometryReply_Preview) => void;
  readonly setUploadError: (error: string) => void;
}

// Handles a particular WorkOrderType. Sends the RPC and handles cancelling.
export abstract class WorkOrderHandler {
  private endRpcBase: (() => void) | null = null;

  constructor(
    readonly rpcPool: rpc.StreamingRpcPool<WorkOrderRequest, WorkOrderReply>,
    readonly workOrderType: frontendpb.WorkOrderType,
  ) { }

  // Sends a request and returns a promise of a reply.
  async sendRequest(request: WorkOrderRequest, rpcKey: string, context: WorkOrderContext) {
    analytics.track('Work Order Request Sent', { workOrderType: this.workOrderType, rpcKey });
    return new Promise<WorkOrderDone>((
      resolve: (reply: WorkOrderDone) => void,
      reject: (err: Error) => void,
    ) => {
      this.endRpcBase = this.rpcPool.start(
        rpcKey,
        () => request,
        (reply: WorkOrderReply) => {
          if (reply.typ.case === 'start') {
            this.onStart(reply.typ.value.workOrderId, context);
          }
          if (reply.typ.case === 'progress') {
            this.onProgress(reply.typ.value, context);
          }
          if (this.workOrderType === frontendpb.WorkOrderType.GET_GEOMETRY) {
            const geoReply = reply as frontendpb.GetGeometryReply;
            if (geoReply.typ.case === 'preview') {
              context.handlePreview?.(geoReply.typ.value);
              analytics.track('Geometry Preview Received', { workOrderType: this.workOrderType });
            }
          }
          if (reply.typ.case === 'done') {
            this.endRpc();
            analytics.track('Work Order Completed', { workOrderType: this.workOrderType });
            resolve(reply.typ.value);
          }
        },
        (err: ConnectError) => {
          // Ignore a cancelled message.
          if (err.code !== Code.Canceled) {
            this.endRpc();
            this.clearPending(context);
            analytics.track('Work Order Failed', {
              workOrderType: this.workOrderType,
              error: err.message,
            });
            reject(err);
          }
        },
      );
    });
  }

  // Handles the start of a work order. Saves the ID in the pending work orders.
  private onStart(newWorkOrderId: string, context: WorkOrderContext) {
    context.setPendingWorkOrders((workOrders: frontendpb.PendingWorkOrders) => {
      const newWorkOrders = workOrders.clone();
      const pendingWorkOrder = newWorkOrders.workOrders[this.workOrderType];
      if (pendingWorkOrder) {
        pendingWorkOrder.workOrderId = newWorkOrderId;
      }
      return newWorkOrders;
    });
    analytics.track('Work Order Started', {
      workOrderType: this.workOrderType,
      workOrderId: newWorkOrderId,
    });
  }

  // Saves the current progress in the pending work orders.
  private onProgress(progress: frontendpb.ProgressInfo, context: WorkOrderContext) {
    context.setPendingWorkOrders((workOrders: frontendpb.PendingWorkOrders) => {
      const newWorkOrders = workOrders.clone();
      const pendingWorkOrder = newWorkOrders.workOrders[this.workOrderType];
      if (pendingWorkOrder) {
        pendingWorkOrder.progressInfo = progress;
      }
      return newWorkOrders;
    });
  }

  // Cancels the work order. The order will not be completed.
  cancel(context: WorkOrderContext, workOrderId: string) {
    this.clearPending(context);
    dropWorkOrder(context.projectId, workOrderId);
    analytics.track('Work Order Cancelled', { workOrderType: this.workOrderType, workOrderId });
  }

  // Sends a request.
  request(workOrder: frontendpb.PendingWorkOrder, context: WorkOrderContext) {
    throw Error('Request should be implemented by the derived class.');
  }

  // endRpc does not fully cancel the work order. It temporarily ends the RPC, but the order is
  // still in frontend menu state. So the order can resume once the user navigates back to the page.
  endRpc() {
    this.endRpcBase?.();
    this.endRpcBase = null;
  }

  // Clear the pending work order. ends the RPC, remove it from requested work orders and frontend
  // menu state.
  clearPending(context: WorkOrderContext) {
    this.endRpc();
    context.clearRequest?.();
    context.setPendingWorkOrders(
      (workOrders) => {
        const newWorkOrders = workOrders.clone();
        const workOrdersMap = newWorkOrders.workOrders;
        if (workOrdersMap[this.workOrderType]) {
          delete newWorkOrders.workOrders[this.workOrderType];
          return newWorkOrders;
        }
        return workOrders;
      },
    );
    analytics.track('Work Order Cleared', { workOrderType: this.workOrderType });
  }
}

export class GetGeometryHandler extends WorkOrderHandler {
  constructor(
    public rpcPool: rpc.StreamingRpcPool<WorkOrderRequest, WorkOrderReply>,
  ) {
    super(rpcPool, frontendpb.WorkOrderType.GET_GEOMETRY);
  }

  request(workOrder: frontendpb.PendingWorkOrder, context: WorkOrderContext) {
    assert(workOrder.typ.case === 'getGeometry', 'Only get-geometry work orders supported');

    const url = workOrder.typ.value.userGeo!.url;
    this.sendRequest(
      workOrder.typ.value,
      url,
      context,
    ).then(
      (done) => {
        context.handleReply?.(frontendpb.WorkOrderType.GET_GEOMETRY, done, url);
        analytics.track('Get Geometry Completed', { url });
      },
    ).catch((err: Error) => {
      this.clearPending(context);
      context.setUploadError('Geometry Import Failed');
      const statusMessage = status.toProto(err);
      let message = '';
      if (statusMessage) {
        const parsed = new status.Parser(statusMessage);
        message = parsed.headlineDetailsMessage();
      }
      addRpcError(`GetGeometry failed${message ? `: ${message}` : ''}`, err);
      analytics.track('Get Geometry Failed', { url, error: message || err.message });
    });
  }
}

export class ComputeGeomContactsHandler extends WorkOrderHandler {
  constructor(
    public rpcPool: rpc.StreamingRpcPool<WorkOrderRequest, WorkOrderReply>,
  ) {
    super(rpcPool, frontendpb.WorkOrderType.COMPUTE_GEOMETRY_CONTACTS);
  }

  request(workOrder: frontendpb.PendingWorkOrder, context: WorkOrderContext) {
    assert(
      workOrder.typ.case === 'computeGeometryContacts',
      'Only compute-geometry-contacts work orders supported',
    );

    const url = workOrder.typ.value.userGeo!.url;
    this.sendRequest(
      workOrder.typ.value,
      url,
      context,
    ).then(
      (done) => {
        context.handleReply?.(
          frontendpb.WorkOrderType.COMPUTE_GEOMETRY_CONTACTS,
          done,
          url,
        );
        analytics.track('Compute Geometry Contacts Completed', { url });
      },
    ).catch((err: Error) => {
      this.clearPending(context);
      context.setUploadError('Contact extraction Failed');
      const statusMessage = status.toProto(err);
      let message = '';
      if (statusMessage) {
        const parsed = new status.Parser(statusMessage);
        message = parsed.headlineDetailsMessage();
      }
      if (message) {
        addRpcError(`GetContacts failed: ${message}`, err);
      } else {
        addRpcError('GetContacts failed', err);
      }
      analytics.track('Compute Geometry Contacts Failed', { url, error: message || err.message });
    });
  }
}

export class CheckGeometryHandler extends WorkOrderHandler {
  constructor(
    public rpcPool: rpc.StreamingRpcPool<WorkOrderRequest, WorkOrderReply>,
  ) {
    super(rpcPool, frontendpb.WorkOrderType.CHECK_GEOMETRY);
  }

  request(workOrder: frontendpb.PendingWorkOrder, context: WorkOrderContext) {
    assert(workOrder.typ.case === 'checkGeometry', 'Only check-geometry work orders supported');

    const url = workOrder.typ.value.userGeo!.url;
    context.clearRequest?.();
    this.sendRequest(
      workOrder.typ.value,
      url,
      context,
    ).then(
      (done) => {
        context.handleReply?.(frontendpb.WorkOrderType.CHECK_GEOMETRY, done, url);
        analytics.track('Check Geometry Completed', { url });
      },
    ).catch((err: Error) => {
      const statusMessage = status.toProto(err);
      let message = '';
      if (statusMessage) {
        const parsed = new status.Parser(statusMessage);
        message = parsed.headlineDetailsMessage();
      }
      if (message) {
        addRpcError(`CheckGeometry failed: ${message}`, err);
      } else {
        addRpcError('CheckGeometry failed', err);
      }
      analytics.track('Check Geometry Failed', { url, error: message || err.message });
    });
  }
}

export class GetMeshHandler extends WorkOrderHandler {
  constructor(
    public rpcPool: rpc.StreamingRpcPool<WorkOrderRequest, WorkOrderReply>,
  ) {
    super(rpcPool, frontendpb.WorkOrderType.GET_MESH);
  }

  request(workOrder: frontendpb.PendingWorkOrder, context: WorkOrderContext) {
    assert(workOrder.typ.case === 'getMesh', 'Only get-mesh work orders supported');

    const url = workOrder.typ.value.userGeo!.url;
    context.clearRequest?.();
    this.sendRequest(
      workOrder.typ.value,
      url,
      context,
    ).then(
      (done: WorkOrderDone) => {
        context.handleReply?.(frontendpb.WorkOrderType.GET_MESH, done, url);
        analytics.track('Get Mesh Result', {
          url,
        });
      },
    ).catch((err: Error) => {
      const statusMessage = status.toProto(err);
      let message = '';
      if (statusMessage) {
        const parsed = new status.Parser(statusMessage);
        message = parsed.headlineDetailsMessage();
      }
      if (message) {
        addRpcError(`GetMesh failed: ${message}`, err);
      } else {
        addRpcError('GetMesh failed', err);
      }
      analytics.track('Get Mesh Failed', {
        url,
        error: err?.message,
      });
    });
  }
}

// Sets a default value for the user_geo_mod field to aid in distinguishing GET_GEOMETRY work
// orders that are the same from the frontend's point of view but that have different work order
// ids. The latter happens because GET_GEOMETRY work orders handle the preview and non-preview
// geometry RPCs which results in two different work order ids.
// See LC-16051 for more information.
export function fixGetGeometryMessage(workOrder: frontendpb.PendingWorkOrder) {
  if (workOrder.typ.case === 'getGeometry' && workOrder.typ.value.userGeoMod === undefined) {
    workOrder.typ.value.userGeoMod = new meshgenerationpb.UserGeometryMod();
  }
}
