// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useAtomValue, useSetAtom } from 'jotai';

/**
 * The height of the 3D viewer
 * This value is used for calculating the size for the floating geometry panel's content
 */
const visHeightState = atom(0);

export function useVisHeightValue() {
  return useAtomValue(visHeightState);
}

export function useSetVisHeight() {
  return useSetAtom(visHeightState);
}
