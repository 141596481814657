// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import * as projectstatepb from '../../../proto/projectstate/projectstate_pb';
import { useMeshUrlState } from '../../../recoil/meshState';
import { useIsGeometryPending } from '../../../recoil/pendingWorkOrders';
import { useMeshReadyState } from '../../../recoil/useMeshReadyState';
import { useCurrentView } from '../../../state/internal/global/currentView';
import { CurrentView } from '../../componentTypes/context';
import { Tracer } from '../tracing';

import { pageLocationTracer } from './useLocationTrace';
import { useOnPathOrVisibiltyChange } from './usePathOrVisibilityChange';

const setupTabTracer = new Tracer('SetupTabTracer', pageLocationTracer, true);

/**
 * A hook to create spans within the setup tab. It should be called from PageBody.tsx.
 * It listens for changes to the meshUrl to log the time a user spends on Geometry vs Mesh.
 */
export const useSetupTabTrace = (projectId: string, workflowId: string, jobId: string) => {
  const currentView = useCurrentView();
  const setupView = (currentView === CurrentView.SETUP);
  const meshReady = useMeshReadyState(projectId, workflowId, jobId);
  const isGeometryPending = useIsGeometryPending(projectId);
  const [meshUrlState] = useMeshUrlState(projectId);
  const setupWithMesh = meshUrlState.activeType === projectstatepb.UrlType.MESH;

  const maybeStartSpan = useCallback(() => {
    // because this hook is called from PageBody (which is used for Setup, Results, and Analysis
    // tabs), we need to check first that we're only tracing the Setup tab.
    if (!setupView) {
      return;
    }
    if (isGeometryPending) {
      setupTabTracer.startSpan('Setup during Geometry Upload');
    } else if (!meshReady) {
      setupTabTracer.startSpan('Setup (Empty)');
    } else if (setupWithMesh) {
      setupTabTracer.startSpan('Setup with Mesh');
    } else {
      setupTabTracer.startSpan('Setup with Geometry');
    }
  }, [setupWithMesh, setupView, isGeometryPending, meshReady]);

  const endActiveSpan = useCallback(() => {
    setupTabTracer.endActiveSpan();
  }, []);

  // Start listening for path or visibility changes
  useOnPathOrVisibiltyChange(endActiveSpan, maybeStartSpan);
};
