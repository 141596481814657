// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';
import { ROW_OUTER_HEIGHT } from '../Theme/commonStyles';

const BORDER_RADIUS = 4;
// min height should match the root Geometry row's height
export const MIN_HEIGHT = ROW_OUTER_HEIGHT;
export const TREE_VERTICAL_PADDING = 8;
const SEMI_TRANSPARENT_BACKGROUND = 'rgba(31, 32, 34, 0.90)';

const treePanelStyles = () => createStyles({
  root: {
    borderRadius: BORDER_RADIUS,
    border: '1px solid transparent',
    '&.inSelectionMode': {
      borderColor: colors.primaryCta,
    },

    // this targets the header of the CollapsiblePanel
    '& > div > div:first-child': {
      backgroundColor: colors.neutral150,
    },
  },
  headerRight: {
    marginRight: '8px',
    display: 'flex',
  },
  iconButton: {
    color: 'currentColor',
    padding: 0,
  },
  heading: {
    display: 'flex',
    gap: '8px',
    height: '16px',
    alignItems: 'center',
  },
  searchButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16px',
    height: '16px',
    padding: '0px',
    border: 'none',
    background: 'transparent',
    color: colors.highEmphasisText,
    cursor: 'pointer',
    outline: 'none',
    '&:focus-visible': {
      border: `1px solid ${colors.primaryCta}`,
    },
  },
  searchInput: {
    padding: '0px',
    background: 'transparent',
    border: 'none',
    color: colors.highEmphasisText,
    outline: 'none',
    fontSize: '13px',
  },
  content: {
    backgroundColor: SEMI_TRANSPARENT_BACKGROUND,
    padding: `${TREE_VERTICAL_PADDING}px 0`,
    '&:last-child': {
      borderBottomLeftRadius: BORDER_RADIUS,
      borderBottomRightRadius: BORDER_RADIUS,
    },
  },
  list: {
    overflow: 'hidden',
  },
  listNoVirtuoso: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  loadButtonContainer: {
    padding: '0 8px 8px',
  },
  noResults: {
    fontSize: '13px',
    padding: '5px 16px 5px 30px',
  },
  treeFooterWithToggle: {
    borderTop: `1px solid ${colors.neutral50}`,
    borderBottomLeftRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS,
    color: colors.highEmphasisText,
    fontSize: '13px',
    padding: '0 12px',
    display: 'flex',
    height: '36px',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: SEMI_TRANSPARENT_BACKGROUND,
  },
  pendingRow: {
    display: 'flex',
    width: '100%',
    padding: '4px 24px',
    alignItems: 'center',
    fontSize: '13px',
    gap: '6px',
    color: colors.highEmphasisText,
  },
});

export const useTreePanelStyles = makeStyles(treePanelStyles, { name: 'FloatingTreePanel' });
