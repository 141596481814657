// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

// For ITAR environments, this dialog will appear when the user logs in or when they click
// the ITAR text in the header.

import React from 'react';

import * as AuthV2 from '../../lib/AuthV2';
import { useIsItarEnvDialogOpened } from '../../state/internal/dialog/itar';

import { Dialog } from './Base';

export const ItarEnvironmentDialog = () => {
  // Recoil
  const [opened, setOpened] = useIsItarEnvDialogOpened();

  // Handlers
  const handleClose = () => {
    setOpened(false);
    AuthV2.logout();
  };

  const handleContinue = () => {
    setOpened(false);
  };

  return (
    <Dialog
      cancelButton={{ label: 'Logout', kind: 'secondary' }}
      continueButton={{ label: 'Continue' }}
      modal
      onClose={handleClose}
      onContinue={handleContinue}
      open={opened}
      title="ITAR Environment">
      This environment contains highly-restricted content subject to the International Traffic
      in Arms Regulations (ITAR).
      By proceeding, you represent that you are authorized to access ITAR-controlled content.
    </Dialog>
  );
};
