// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { getQuantityUnit } from '../QuantityDescriptor';
import { QuantityType } from '../proto/quantity/quantity_pb';

import { TextAdornment } from './TextAdornment';

export interface FieldInputProps {
  /** The type of quantity to display */
  quantity?: QuantityType;
}

// FieldInput draws one input field, either numeric or multiple choice.
const QuantityAdornment = (props: FieldInputProps) => {
  const { quantity } = props;
  const units = quantity && getQuantityUnit(quantity);

  return (
    units ?
      <TextAdornment label={units} /> :
      null
  );
};

export default QuantityAdornment;
