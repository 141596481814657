// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { colors } from '../../lib/designSystem';
import { listen } from '../../lib/event';
import { clamp } from '../../lib/number';
import { OVERLAY_CARD_WIDTH, VIEWER_PADDING } from '../../lib/visUtils';
import { useGeometryState } from '../../recoil/geometry/geometryState';
import { usePropertiesPanelVisible, useSetSimulationPropertiesPanelDocked } from '../../recoil/propertiesPanel';
import { useEditableTextState } from '../../state/internal/component/editableText';
import { useLeftOverlayCardsWidthValue } from '../../state/internal/component/leftOverlayCards';
import { useRightOverlayCardsWidthValue } from '../../state/internal/component/rightOverlayCards';
import { useClickedTreeRowVisOffsetValue } from '../../state/internal/tree/clickedTreeRowVisOffset';
import { useVisHeightValue } from '../../state/internal/vis/visHeight';
import { useWorkflowFlagValue } from '../../workflowFlag';
import { IconButton } from '../Button/IconButton';
import { MOTION_PANEL_HEIGHT } from '../Paraview/MotionAnimationSettings';
import { createStyles, makeStyles } from '../Theme';
import { BUTTON_SIZE } from '../Toolbar/ToolbarButton';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { EditableText } from '../controls/EditableText';
import { useNodeRenaming } from '../hooks/useNodeRenaming';
import { PropertiesPanelPosition, useIgnoreFloatingPropertiesPanel, usePropertiesPanelPosition } from '../hooks/usePropertiesPanel';
import { RectanglePoppedInIcon } from '../svg/RectanglePoppedIn';
import { XIcon } from '../svg/XIcon';

import { PropertiesPanel } from './PropertiesPanel';

const PROP_PANEL_DEFAULT_TOP = BUTTON_SIZE + 2 * VIEWER_PADDING;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      position: 'absolute',
      width: OVERLAY_CARD_WIDTH,
      overflowY: 'auto',
      zIndex: 3,
      backgroundColor: colors.neutral150,
      borderRadius: '4px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 12px',
      fontWeight: 600,
      lineHeight: '16px',
      fontSize: '13px',
      gap: '12px',
    },
    headerMain: {
      flexGrow: 1,
      overflow: 'hidden',
    },
    headerButtons: {
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'FloatingPropertiesPanel' },
);

interface FloatingPropertiesPanelProps {
  hasMotionPanel: boolean;
}

/**
 * A panel for displaying detailed properties of whichever node is currently
 * selected in the Geometry tree.
 * Similar to PropertiesPanelSimulation (which is for the prop panels for Simulation tree nodes).
 */
export const FloatingPropertiesPanel = ({ hasMotionPanel }: FloatingPropertiesPanelProps) => {
  // == Contexts
  const { selectedNode } = useSelectionContext();
  const { projectId, geometryId } = useProjectContext();

  // == Hooks
  const classes = useStyles();
  const renaming = useNodeRenaming(selectedNode);
  const propertiesPanelPosition = usePropertiesPanelPosition();
  const ignoreFloatingPropertiesPanel = useIgnoreFloatingPropertiesPanel();
  const leftOverlayCardsWidth = useLeftOverlayCardsWidthValue();
  const rightOverlayCardsWidth = useRightOverlayCardsWidthValue();

  // == Recoil
  const [propertiesPanelVisible, setPropertiesPanelVisible] = usePropertiesPanelVisible();
  const [tempText, setTempText] = useEditableTextState();
  const setSimulationPropertiesPanelDocked = useSetSimulationPropertiesPanelDocked();
  const clickedTreeRowVisOffsetValue = useClickedTreeRowVisOffsetValue();
  const visHeight = useVisHeightValue();
  const workflowFlag = useWorkflowFlagValue();
  const geometryState = useGeometryState(projectId, geometryId);

  // == Data
  const [isRenaming, setIsRenaming] = useState(false);
  const propPanelRef = useRef<HTMLDivElement>(null);
  const [
    geometryPropertiesPanelTop,
    setGeometryPropertiesPanelTop,
  ] = useState(PROP_PANEL_DEFAULT_TOP);

  const propertiesStyles = useMemo(() => {
    const topOffset = hasMotionPanel ? MOTION_PANEL_HEIGHT + VIEWER_PADDING : 0;
    const maxHeight =
      `calc(100% - (2 * ${BUTTON_SIZE}px) - (6 * ${VIEWER_PADDING}px) - ${topOffset}px)`;

    if (propertiesPanelPosition === PropertiesPanelPosition.POPPED_OUT_LEFT) {
      return {
        left: leftOverlayCardsWidth + (2 * VIEWER_PADDING),
        top: geometryPropertiesPanelTop + topOffset,
        maxHeight,
      };
    }
    // The properties panel for the simulation tree may often change in height (due to the content
    // not being available from the start), which makes it very hard to vertically align the panel
    // next to the clicked row, without jumping effects and without the content overflowing
    // from the window. Because of that, we'll position the prop panel for the simulation tree
    // near the top, where it's safe for it to grow downwards as needed.
    if (propertiesPanelPosition === PropertiesPanelPosition.POPPED_OUT_RIGHT) {
      return {
        right: workflowFlag ? rightOverlayCardsWidth + (2 * VIEWER_PADDING) : VIEWER_PADDING,
        top: PROP_PANEL_DEFAULT_TOP + topOffset,
        maxHeight,
      };
    }
    return {
      top: topOffset,
      maxHeight,
    };
  }, [
    hasMotionPanel,
    propertiesPanelPosition,
    leftOverlayCardsWidth,
    geometryPropertiesPanelTop,
    rightOverlayCardsWidth,
    workflowFlag,
  ]);

  const handleRename = useCallback(async (value: string) => {
    await renaming?.onCommit(value);
    setIsRenaming(false);
  }, [renaming]);

  // == Effects

  // If the geometry prop panel is visible and on the left, position it next to the clicked row
  useLayoutEffect(() => {
    if (
      propertiesPanelVisible &&
      propertiesPanelPosition === PropertiesPanelPosition.POPPED_OUT_LEFT &&
      propPanelRef.current?.offsetHeight
    ) {
      const panelHeight = propPanelRef.current.offsetHeight;
      const minTop = BUTTON_SIZE + 2 * VIEWER_PADDING;
      const maxTop = (visHeight - BUTTON_SIZE - 4 * VIEWER_PADDING) - panelHeight;
      const topPosition = clamp(clickedTreeRowVisOffsetValue, [minTop, maxTop]);
      setGeometryPropertiesPanelTop(topPosition);
    }
  }, [
    propertiesPanelPosition,
    propertiesPanelVisible,
    clickedTreeRowVisOffsetValue,
    selectedNode?.id,
    visHeight,
  ]);

  // The empty space in the paraview's canvas doesn't emit the click events so we need a custom
  // handler that will save the edit in progress if we click over the paraview's canvas.
  useEffect(() => {
    const canvas = document.querySelector('canvas');
    if (canvas) {
      const handler = listen(canvas, 'click', async (event: Event) => {
        if (isRenaming) {
          await handleRename(tempText ?? '');

          // If we click over the paraview, the whole property panel gets hidden and this causes
          // the EditableText to not trigger its blur event. That's why we have to explicitly end
          // the EditableText's editing by also clearing the recoil tempText.
          setTempText(null);
        }
      });
      return () => handler.remove();
    }
    return () => {};
  }, [tempText, isRenaming, handleRename, setTempText]);

  if (
    !selectedNode ||
    ignoreFloatingPropertiesPanel ||
    !propertiesPanelVisible ||
    geometryState?.geometryFeatures.some((feature) => feature.id === selectedNode?.id) ||
    (!workflowFlag && propertiesPanelPosition === PropertiesPanelPosition.POPPED_IN)
  ) {
    return null;
  }

  return (
    <div
      className={classes.root}
      key={`${selectedNode?.id}`}
      ref={propPanelRef}
      style={propertiesStyles}>
      <div className={classes.header}>
        <div className={classes.headerMain}>
          <EditableText
            active={isRenaming}
            asBlock
            disabled={renaming ? renaming?.disabled : true}
            editButton
            onChange={handleRename}
            onDoubleClick={() => {
              setIsRenaming(true);
            }}
            truncate
            value={selectedNode?.name ?? ''}
          />
        </div>
        <div className={classes.headerButtons}>
          {propertiesPanelPosition === PropertiesPanelPosition.POPPED_OUT_RIGHT && !workflowFlag &&
            (
              <IconButton onClick={() => setSimulationPropertiesPanelDocked(true)}>
                <RectanglePoppedInIcon maxHeight={13} />
              </IconButton>
            )}
          <IconButton onClick={() => setPropertiesPanelVisible(false)}>
            <XIcon maxHeight={12} />
          </IconButton>
        </div>
      </div>
      <PropertiesPanel />
    </div>
  );
};
