import React from 'react';

import { useSolidOrFluidState } from '../../../state/internal/tree/geometrySetupTree';
import { DataSelect } from '../../Form/DataSelect';
import LabeledInput from '../../Form/LabeledInput';
import { createStyles, makeStyles } from '../../Theme';
import { useProjectContext } from '../../context/ProjectContext';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      padding: '10px 12px 12px',
    },
  }),
  { name: 'CadSolidOrFluidPropPanel' },
);

export const CadSolidOrFluidPropPanel = () => {
  const classes = useStyles();
  const { projectId } = useProjectContext();
  const [solidOrFluid, setSolidOrFluid] = useSolidOrFluidState(projectId);

  return (
    <div className={classes.root}>
      <LabeledInput label="Solid or Fluid">
        <DataSelect
          asBlock
          onChange={(newVal: 'solid' | 'fluid' | 'none') => setSolidOrFluid(newVal)}
          options={[
            { value: 'none', name: 'None', disabled: true, selected: solidOrFluid === 'none' },
            { value: 'solid', name: 'Solid', selected: solidOrFluid === 'solid' },
            { value: 'fluid', name: 'Fluid', selected: solidOrFluid === 'fluid' },
          ]}
          size="small"
        />
      </LabeledInput>
    </div>
  );
};
