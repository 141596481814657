// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const PersonIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="person"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M23.533,21.508
           C22.842,19.932 19.51,18.882 17.289,17.906
           C15.068,16.93 15.368,16.33 15.293,15.52
           C15.278,15.415 15.278,15.294 15.278,15.174
           C16.043,14.499 16.659,13.568 17.064,12.503
           C17.064,12.503 17.079,12.443 17.094,12.428
           C17.184,12.203 17.259,11.947 17.319,11.707
           C17.829,11.602 18.13,11.047 18.235,10.537
           C18.355,10.326 18.58,9.816 18.535,9.231
           C18.46,8.48 18.16,8.135 17.829,8
           L17.829,7.865
           C17.829,6.919 17.739,5.538 17.574,4.638
           C17.544,4.383 17.484,4.143 17.409,3.887
           C17.079,2.792 16.403,1.786 15.488,1.096
           C14.527,0.39 13.192,0 12.006,0
           C10.82,0 9.484,0.375 8.539,1.096
           C7.623,1.801 6.948,2.792 6.617,3.887
           C6.542,4.143 6.482,4.383 6.452,4.638
           C6.272,5.538 6.197,6.904 6.197,7.865
           L6.197,7.985
           C5.837,8.12 5.522,8.465 5.447,9.231
           C5.402,9.801 5.642,10.311 5.747,10.522
           C5.867,11.047 6.167,11.602 6.692,11.692
           C6.752,11.947 6.828,12.188 6.918,12.428
           C6.918,12.443 6.933,12.458 6.933,12.473
           L6.948,12.488
           C7.353,13.568 7.998,14.514 8.779,15.189
           C8.779,15.294 8.764,15.4 8.764,15.505
           C8.689,16.315 8.914,16.915 6.707,17.891
           C4.486,18.867 1.154,19.917 0.464,21.493
           C-0.227,23.069 0.058,24 0.058,24
           L23.953,24
           C23.938,24.015 24.223,23.084 23.533,21.508Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
