// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/lcstatus/codes.proto (package luminary.proto.lcstatus, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Code is used to indicate a specific status. Each status code may also be
 * associated with a details message. Values must be different from grpc status
 * codes (https://github.com/googleapis/googleapis/blob/master/google/rpc/code.proto)
 * (except Ok status).
 *
 * @generated from enum luminary.proto.lcstatus.Code
 */
export const Code = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.lcstatus.Code",
  [
    {no: 0, name: "LC_OK"},
    {no: 1000, name: "LC_INTERNAL"},
    {no: 6011, name: "GEO_VERTEX_DUPLICATE"},
    {no: 6111, name: "GEO_EDGE_UNMESHABLE"},
    {no: 6112, name: "GEO_EDGE_NOT_SMOOTH"},
    {no: 6113, name: "GEO_EDGE_LARGE_TOLERANCE"},
    {no: 6211, name: "GEO_FACE_UNMESHABLE"},
    {no: 6212, name: "GEO_FACE_EDGES_TOO_CLOSE"},
    {no: 6213, name: "GEO_FACE_EDGE_TOO_SMALL"},
    {no: 6214, name: "GEO_FACE_FACE_INTERSECTION"},
    {no: 6215, name: "GEO_FACE_SELF_INTERSECTION"},
    {no: 6216, name: "GEO_FACE_NOT_SMOOTH"},
    {no: 6217, name: "GEO_FACE_LARGE_TOLERANCE"},
    {no: 6218, name: "GEO_FACE_EDGE_CROSS"},
    {no: 6219, name: "GEO_FACE_NEEDS_IMPRINT"},
    {no: 6220, name: "GEO_CONTACT_NEEDS_IMPRINT"},
    {no: 6311, name: "GEO_VOLUME_OPEN"},
    {no: 6312, name: "GEO_VOLUME_NON_MANIFOLD"},
    {no: 6313, name: "GEO_VOLUME_UNMESHABLE"},
    {no: 7001, name: "SOLVER_PARAM_EXCEEDS_LOWER_BOUND"},
    {no: 7002, name: "SOLVER_PARAM_EXCEEDS_UPPER_BOUND"},
    {no: 7010, name: "SOLVER_INTERFACE_ISSUES"},
    {no: 10001, name: "GEO_FEATURE_UNKNOWN_ERROR"},
    {no: 10002, name: "GEO_INVALID_REQUEST"},
    {no: 11001, name: "ANALYZER_FIELD_NOT_FOUND"},
    {no: 11002, name: "ANALYZER_DEPENDENCY_ERROR"},
    {no: 11003, name: "ANALYZER_INVALID_EXPRESSION"},
    {no: 11004, name: "ANALYZER_ENTITY_NOT_FOUND"},
    {no: 11005, name: "ANALYZER_NO_DATA_FOR_ENTITY_TYPE"},
  ],
);

