// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 12;
export const SparkleDoubleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="sparkleDouble"
      height={finalHeight}
      ref={ref}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}>
      <path
        d="M0 7.24085
           C0.467587 7.03126 0.933961 6.81738 1.40574 6.61155
           C2.54785 6.1133 3.30146 5.3491 3.78262 4.40002
           C4.10129 3.77139 4.4086 3.13889 4.72496 2.5
           C4.81828 2.5781 4.83824 2.67759 4.88303 2.7654
           C5.2188 3.42436 5.50471 4.09999 5.88692 4.74329
           C6.38407 5.57997 7.13978 6.22404 8.17258 6.65788
           C8.61148 6.8422 9.03903 7.04395 9.5 7.25044
           C9.19489 7.38832 8.90986 7.51705 8.62483 7.64578
           C8.1311 7.86893 7.62414 8.07432 7.19107 8.37468
           C6.46747 8.87668 5.98366 9.50675 5.63631 10.2109
           C5.35712 10.7768 5.08026 11.3433 4.79997 11.9089
           C4.78585 11.9375 4.78728 11.9763 4.72275 12
           C4.62304 11.7969 4.52674 11.5939 4.42371 11.3929
           C4.15115 10.8611 3.92028 10.3154 3.61208 9.79553
           C3.11923 8.96416 2.38658 8.31081 1.36228 7.87334
           C0.92977 7.68858 0.507077 7.48925 0.0797513 7.29688
           C0.0493068 7.2832 0.0127955 7.27625 0 7.24085Z"
        fill={getColor(0)}
      />
      <path
        d="M6.5 2.74466
           C6.7707 2.62323 7.04069 2.49952 7.31384 2.38036
           C7.97509 2.09201 8.41132 1.64949 8.68989 1.10007
           C8.87449 0.736121 9.05227 0.370073 9.23548 0
           C9.28955 0.0453186 9.3011 0.102711 9.32699 0.153628
           C9.5214 0.535075 9.68693 0.92632 9.90828 1.29867
           C10.1961 1.783 10.6336 2.15587 11.2315 2.40714
           C11.4856 2.51387 11.7332 2.63058 12 2.75026
           C11.8233 2.83005 11.6583 2.90459 11.4933 2.97913
           C11.2073 3.10826 10.9139 3.22725 10.6631 3.40117
           C10.2442 3.69181 9.9641 4.05645 9.76304 4.46415
           C9.60136 4.7917 9.44108 5.11978 9.27887 5.44716
           C9.27065 5.46378 9.27152 5.48618 9.23425 5.5
           C9.17651 5.38242 9.12069 5.26483 9.0612 5.14847
           C8.90336 4.84052 8.76968 4.52469 8.59137 4.22373
           C8.30598 3.74237 7.88182 3.36425 7.28881 3.11089
           C7.03842 3.00398 6.79379 2.88849 6.54637 2.77721
           C6.5287 2.76934 6.50752 2.76531 6.50017 2.74466
           H6.5Z"
        fill={getColor(0)}
      />
    </svg>
  );
});
