// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

// Maintain references to newly created nodes

import { DefaultValue, atom, selectorFamily, useRecoilState, useSetRecoilState } from 'recoil';

import { areSetsEquivalent } from '../lib/lang';

export type SubscriberKey = string;
type NodeId = string;

export type NewNode = {
  // The ID of the newly created node
  nodeId: string;
  // Arbitrary tags add color to the newly added node.  For example, motion frames can be created
  // via different menu options (Add Rotation or Add Coordinates), each affecting the initial state
  // of the new node's props panel.  Using different tags for each of these menu options allows the
  // new prop panel to know how the node was created.
  tags: string[];
}

// Initialize a new node with a given nodeId, an initial tags list, and an empty processed record
export function initializeNewNode(nodeId: string, tags: string[] = []): NewNode {
  return {
    nodeId,
    tags,
  };
}

// This recoil state maintains a stack of newly created nodes; since it's just in-memory recoil
// state, it's reset whenever the browser hard-refreshes.  The primary use case is for subscribers
// to react to state changes more-or-less instantaneously, so that persistence is not necessary.
export const newNodesState = atom<NewNode[]>({
  key: 'newNodesState',
  default: [],
});

export type ProcessedNodes = Record<SubscriberKey, NodeId[]>;

// Maintain a record of subscriber keys, keyed by node IDs, to indicate which subscribers have
// already processed the node ID
export const processedNodesState = atom<ProcessedNodes>({
  key: 'processedNewNodesState',
  default: {},
});

// A selector for `processedNodesState` by node ID
export const processedNodeState = selectorFamily<string[], string>({
  key: 'processedNewNodeState',
  get: (nodeId: string) => ({ get }) => get(processedNodesState)[nodeId] || [],
  set: (nodeId: string) => ({ get, set }, subscriberKeys: string[] | DefaultValue) => {
    const oldState = get(processedNodesState);

    // Make a writable copy of the old state
    const newState: ProcessedNodes = {};
    Object.entries(oldState).forEach(([key, value]) => {
      newState[key] = value;
    });

    const oldKeys = newState[nodeId] || [];
    const newKeys = (subscriberKeys instanceof DefaultValue) ? [] : subscriberKeys;

    // To prevent an infinite loop, update state only if it's really changed.
    if (!areSetsEquivalent(oldKeys, newKeys)) {
      newState[nodeId] = newKeys;
      set(processedNodesState, newState);
    }
  },
});

export const useNewNodes = () => useRecoilState(newNodesState);
export const useSetNewNodes = () => useSetRecoilState(newNodesState);

export const useProcessedNewNode = (nodeId: string) => useRecoilState(processedNodeState(nodeId));
