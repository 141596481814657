// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 18;
const baseHeight = 21;
export const ArrowDownIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="arrowDown"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M16.5,10.5
           C16.05,10.5 15.75,10.65 15.45,10.95
           L10.5,15.9
           L10.5,1.5
           C10.5,0.75 9.9,0 9,0
           C8.1,0 7.5,0.75 7.5,1.5
           L7.5,15.9
           L2.55,10.95
           C2.25,10.65 1.95,10.5 1.5,10.5
           C0.75,10.5 0,11.25 0,12
           C0,12.45 0.15,12.75 0.45,13.05
           L7.95,20.55
           C8.25,20.85 8.55,21 9,21
           C9.45,21 9.75,20.85 10.05,20.55
           L17.55,13.05
           C17.85,12.75 18,12.45 18,12
           C18,11.1 17.4,10.5 16.5,10.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
