// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import * as entitypb from '../proto/client/entity_pb';

import { newNodeId } from './projectDataUtils';

/**
 * The new client simulation params expose a generalized EntityIdentifier that's used to add
 * ID/name pairs to objects.  The createIdentifier() utility should be used to generate a new
 * EntityIdentifier with a regular ID pattern (a random string of 32 chars).
 */
export function createIdentifier(name: string) {
  return (new entitypb.EntityIdentifier({ id: newNodeId(), name }));
}
