// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const ResetIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="reset"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M9,7.5
           C9,8.325 8.325,9 7.5,9
           L1.5,9
           C0.675,9 0,8.325 0,7.5
           L0,1.5
           C0,0.675 0.675,0 1.5,0
           C2.325,0 3,0.675 3,1.5
           L3,4.11
           C5.19,1.605 8.4,0 12,0
           C18.63,0 24,5.37 24,12
           C24,18.09 19.47,23.1 13.59,23.88
           C13.56,23.88 13.53,23.895 13.5,23.895
           C13.005,23.955 12.51,24 12,24
           C5.37,24 0,18.63 0,12
           C0,11.175 0.675,10.5 1.5,10.5
           C2.325,10.5 3,11.175 3,12
           C3,16.965 7.035,21 12,21
           C13.065,21 14.055,20.775 15,20.43
           L15,20.445
           C18.495,19.215 21,15.915 21,12
           C21,7.035 16.965,3 12,3
           C9.345,3 6.96,4.17 5.31,6
           L7.5,6
           C8.325,6 9,6.675 9,7.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
