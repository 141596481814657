// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const ClockIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="clock"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M12,0
           C5.37,0 0,5.37 0,12
           C0,18.63 5.37,24 12,24
           C18.63,24 24,18.63 24,12
           C24,5.37 18.63,0 12,0Z
           M12,21
           C7.035,21 3,16.965 3,12
           C3,7.035 7.035,3 12,3
           C16.965,3 21,7.035 21,12
           C21,16.965 16.965,21 12,21Z
           M13.5,11.385
           L13.5,6
           C13.5,5.175 12.825,4.5 12,4.5
           C11.175,4.5 10.5,5.175 10.5,6
           L10.5,12
           C10.5,12.42 10.665,12.795 10.935,13.065
           L13.935,16.065
           C14.205,16.335 14.58,16.5 15,16.5
           C15.825,16.5 16.5,15.825 16.5,15
           C16.5,14.58 16.335,14.205 16.065,13.935
           L13.5,11.385Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
