// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as simulationpb from '../proto/client/simulation_pb';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as feoutputpb from '../proto/frontend/output/output_pb';
import * as outputpb from '../proto/output/output_pb';
import { QuantityType } from '../proto/quantity/quantity_pb';
import { EntityGroupData } from '../recoil/entityGroupState';

import { newAdFloat } from './adUtils';
import { toPositiveAbsoluteInteger } from './number';
import { createOutputs, setIncluded } from './outputNodeUtils';
import { newBasicOutput } from './outputUtils';
import { newNodeId } from './projectDataUtils';
import { getOrCreateConvergenceCriteria } from './simulationParamUtils';
import { isSimulationImplicitTime, isSimulationTransient } from './simulationUtils';

const propsCasesToUpdate: feoutputpb.OutputNode['nodeProps']['case'][] = [
  'force', 'surfaceAverage', 'pointProbe', 'volumeReduction', 'derived',
];

export const DEPRECATED_NODE_ID = 'deprecated';

export const DEFAULT_STOP_COND = new feoutputpb.StoppingCondition({
  nIterations: 1,
  nAnalysisIters: 1,
  threshold: 1e-4,
  startAt: 1,
  legacyNodeId: DEPRECATED_NODE_ID,
});

export function newStopCond() {
  const cond = DEFAULT_STOP_COND.clone();
  cond.id = newNodeId();
  return cond;
}

// Find a node in the output node list by its id.
export function findNode(nodes: feoutputpb.OutputNodes, id: string) {
  return nodes.nodes.find((node) => id === node?.id);
}

// Add the stopping conditions to param.
export function updateStoppingConds(
  stopConds: feoutputpb.StoppingConditions,
  param: simulationpb.SimulationParam,
  outputNodes: feoutputpb.OutputNodes,
  entityGroupData: EntityGroupData,
): simulationpb.SimulationParam {
  const stopCondsParam: outputpb.StoppingCondition[] = [];
  const stopCondsTimeStepParam: outputpb.StoppingCondition[] = [];

  const transient = isSimulationTransient(param);
  const timeImplicit = isSimulationImplicitTime(param);

  const basicStopCond = (output: outputpb.Output, threshold: number) => (
    new outputpb.StoppingCondition({
      output,
      op: outputpb.StoppingConditionOp.STOP_COND_OP_FORCE,
      threshold: newAdFloat(threshold),
    })
  );

  // add max iterations and max time
  const iterOutput = newBasicOutput(QuantityType.ITERATION_INDEX, false);
  stopCondsParam.push(basicStopCond(iterOutput, stopConds.maxIterations));
  if (transient) {
    stopCondsParam.push(
      basicStopCond(
        newBasicOutput(QuantityType.PHYSICAL_TIME, false),
        stopConds.maxPhysicalTime,
      ),
    );
    if (timeImplicit) {
      stopCondsTimeStepParam.push(basicStopCond(iterOutput, stopConds.maxInnerIterations));
    }
  }

  // Loop through all conditions, find the corresponding output node
  // and create the stopping condition.
  stopConds.cond.forEach((cond) => {
    if (cond.node) {
      // Ensure iteration fields are integers >= 1
      const analysisIters = toPositiveAbsoluteInteger(cond.nAnalysisIters);
      const avgIters = toPositiveAbsoluteInteger(cond.nIterations);

      const newNode = cond.node!.clone();
      if (propsCasesToUpdate.includes(newNode.nodeProps.case)) {
        newNode.include = {};
        setIncluded(newNode, feoutputpb.OutputIncludes.OUTPUT_INCLUDE_MAX_DEV, true);
        newNode.analysisIters = analysisIters;
        newNode.averageIters = avgIters;
      }

      const { outputList } = createOutputs(
        newNode,
        outputNodes,
        param,
        entityGroupData,
        true,
        false,
      );

      outputList.forEach((output) => {
        const newCond = new outputpb.StoppingCondition({
          output,
          op: stopConds.op,
          threshold: newAdFloat(cond.threshold),
        });

        output.range = new outputpb.IterationRange({
          end: toPositiveAbsoluteInteger(cond.startAt),
        });
        output.inSurfaces = [...output.inSurfaces];
        if (transient && timeImplicit) {
          stopCondsTimeStepParam.push(newCond);
        } else {
          stopCondsParam.push(newCond);
        }
      });
    }
  });
  const newParam = param.clone();
  const convCriteria = getOrCreateConvergenceCriteria(newParam);
  convCriteria.stoppingCondition = stopCondsParam;
  convCriteria.timeStepStoppingCondition = stopCondsTimeStepParam;

  // Define the adjoint output.
  if (newParam.adjoint?.adjointOutput?.id.length) {
    let foundOutput: boolean = false;
    outputNodes.nodes.forEach((node) => {
      if (node.id === newParam.adjoint?.adjointOutput?.id) {
        const { outputList } = createOutputs(
          node,
          outputNodes,
          param,
          entityGroupData,
          true,
          false,
        );
        if (outputList.length) {
          newParam.adjoint!.adjointOutput = outputList[0];
          // The name and ID given by createOutputs do not match the ones for the output node, so
          // we need to restore them.
          newParam.adjoint!.adjointOutput!.name = node.name;
          newParam.adjoint!.adjointOutput!.id = node.id;
          foundOutput = true;
        } else {
          newParam.adjoint!.adjointOutput = new outputpb.Output();
        }
      }
    });
    // The output node may have been deleted after it was assigned, this will cause a validator
    // error and the simulation will not start.
    if (!foundOutput) {
      newParam.adjoint!.adjointOutput = new outputpb.Output();
    }
  }
  return newParam;
}

// Get a detailed message for a stop condition status reply. If the returned string
// is empty, we have satisfied all conditions.
// TODO(talbring): this is just temporary, we need a nicer way of showing which stop
// conds are not satisfied
export function detailedStatus(stopStatusReply: frontendpb.StoppingConditionReply): string {
  const messages: string[] = [];
  // If no forced stop cond is satisfied we know that some other condition must be satisfied
  // and we can simply return success.
  if (stopStatusReply.status?.forceStop !== undefined && !stopStatusReply.status.forceStop) {
    return '';
  }
  stopStatusReply.condition.forEach((cond, i) => {
    // Only add an extra message if the condition is not satisfied and its not a
    // forced stopping condition.
    if (!stopStatusReply.status?.condSuccess[i] &&
      cond.op !== outputpb.StoppingConditionOp.STOP_COND_OP_FORCE) {
      messages.push(`${cond.output?.name}`);
    }
  });
  // We also want to return an empty string (i.e. return success) if we only have forced stopping
  // conditions and only one of them is satisfied. In that case messages is empty but getSuccess()
  // will return false.
  const baseMessage = !stopStatusReply.status?.success && messages.length ?
    'Some stopping conditions are not satisfied: ' : '';
  return baseMessage + messages.join(', ');
}
