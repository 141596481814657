// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import * as Vector from '../../../lib/Vector';
import * as basepb from '../../../proto/base/base_pb';
import { QuantityType } from '../../../proto/quantity/quantity_pb';
import Form from '../../Form';
import { Vector3Input } from '../../Form/Vector3Input';
import { UnitVectorButtons } from '../../controls/UnitVectorButtons';

export interface PlaneParam {
  // A point on the plane.
  origin: basepb.Vector3;
  // A vector perpendicular to the plane.
  normal: basepb.Vector3;
}

export interface PlaneInputProps {
  // The value to display
  param: PlaneParam;
  // Can the value be modified by the user? Default false.
  readOnly?: boolean;
  // Called when the value is committed
  onCommit: (newParam: PlaneParam) => void;
  // Called when the value is changed
  onChange?: (newParam: PlaneParam) => void;
}

/** Dialog for specifying parameters for a plane in 3D space.  It has inputs for
    two vectors: origin and normal.
*/
export const PlaneInput = (props: PlaneInputProps) => {
  const { onChange, onCommit, param, readOnly } = props;
  const { normal, origin } = param;

  return (
    <React.Fragment>
      <Form.LabeledInput
        help="A point on the plane"
        label="Origin">
        <Vector3Input
          disabled={readOnly || false}
          onChange={(newOrigin) => onChange?.({ ...param, origin: newOrigin })}
          onCommit={(newOrigin) => onCommit({ ...param, origin: newOrigin })}
          quantityType={QuantityType.LENGTH}
          value={origin}
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help="Vector perpendicular to the plane"
        label="Normal">
        <Vector3Input
          disabled={readOnly || false}
          onChange={(newNormal) => onChange?.({ ...param, normal: newNormal })}
          onCommit={(newNormal) => onCommit({ ...param, normal: newNormal })}
          value={normal}
        />
      </Form.LabeledInput>
      <Form.LabeledInput label="">
        {readOnly ? null : (
          <Form.Group>
            <UnitVectorButtons
              onClick={(x, y, z) => {
                const vec = Vector.newProto(x, y, z);
                onCommit({
                  ...param,
                  normal: Vector.equal(vec, normal) ? Vector.mult(vec, -1) : vec,
                });
              }}
            />
          </Form.Group>
        )}
      </Form.LabeledInput>
    </React.Fragment>
  );
};

PlaneInput.defaultProps = {
  readOnly: false,
};
