// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/job/job.proto (package luminary.proto.job, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Duration, proto3 } from "@bufbuild/protobuf";

/**
 * The constraints that should be applied to a running job.
 *
 * @generated from message luminary.proto.job.RuntimeConstraints
 */
export const RuntimeConstraints = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.job.RuntimeConstraints",
  () => [
    { no: 1, name: "max_run_time", kind: "message", T: Duration, opt: true },
  ],
);

