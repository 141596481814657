// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as ProtoDescriptor from '../ProtoDescriptor';
import { Choice } from '../ProtoDescriptor';
import * as simulationpb from '../proto/client/simulation_pb';

import { ParamScope, createParamScope } from './ParamScope';
import { newAdFloat } from './adUtils';
import assert from './assert';
import { SelectOption } from './componentTypes/form';
import { protoChoicesToSelectOptions } from './form';
import { newInt } from './intUtils';
import { SOLUTION_CONTROLS_PRESET_CHOICES_HEAT, SPATIAL_DISCRETIZATION_PRESET_CHOICES_HEAT } from './physicsUtils';

const ControlsPresetVersion: number = 71824;

const {
  AGGRESSIVE_SOLUTION_CONTROLS_HEAT,
  CUSTOM_SOLUTION_CONTROLS_HEAT,
  INTERMEDIATE_SOLUTION_CONTROLS_HEAT,
} = simulationpb.SolutionControlsHeatPreset;

// Function which checks that there only exists one preset whose conditional evaluates to true.
export function checkPresets(presets: Map<string, ProtoDescriptor.Cond>, paramScope: ParamScope) {
  let enabledPresets = 0;
  presets.forEach((cond, key) => {
    if (paramScope.isEnabled(cond)) {
      enabledPresets += 1;
    }
  });
  assert(enabledPresets === 1, 'Incompatible presets');
}

// Needed for returning the presets that are used.
const { CUSTOM_SPATIAL_DISCRETIZATION_HEAT } = simulationpb.SpatialDiscretizationHeatPreset;

export const useHeatPhysicsPresets = (
  spatialDiscretizationPreset: simulationpb.SpatialDiscretizationHeatPreset | undefined,
  solutionControlsPreset: simulationpb.SolutionControlsHeatPreset | undefined,
  allowCustom = true,
) => {
  // When allowCustom is false, only show the "Custom" choice if it's the current selection, but
  // always disable it
  const spatialDiscChoices = SPATIAL_DISCRETIZATION_PRESET_CHOICES_HEAT.filter((choice) => (
    allowCustom ||
    choice.enumNumber === spatialDiscretizationPreset ||
    choice.enumNumber !== CUSTOM_SPATIAL_DISCRETIZATION_HEAT
  ));
  const solnControlChoices = SOLUTION_CONTROLS_PRESET_CHOICES_HEAT.filter((choice) => (
    allowCustom ||
    choice.enumNumber === solutionControlsPreset ||
    choice.enumNumber !== CUSTOM_SOLUTION_CONTROLS_HEAT
  ));

  const spatialDiscOptions: SelectOption<Choice['enumNumber']>[] = protoChoicesToSelectOptions(
    spatialDiscChoices,
    spatialDiscretizationPreset,
    { explicitHelp: true, disabledEnums: allowCustom ? [] : [CUSTOM_SPATIAL_DISCRETIZATION_HEAT] },
  );

  const solnControlOptions: SelectOption<Choice['enumNumber']>[] = protoChoicesToSelectOptions(
    solnControlChoices,
    solutionControlsPreset,
    { explicitHelp: true, disabledEnums: allowCustom ? [] : [CUSTOM_SOLUTION_CONTROLS_HEAT] },
  );

  return {
    spatialDiscOptions,
    solnControlOptions,
  };
};

// Sets the parameters for each controls preset.
export function applyHeatControlsPreset(
  physics: simulationpb.Physics,
  simParam: simulationpb.SimulationParam,
  experimentConfig: string[],
): void {
  // Only heat physics have solution control presets here.
  const heat = physics.params.case === 'heat' ? physics.params.value : null;
  if (!heat) {
    return;
  }
  const physicsScope = createParamScope(heat, experimentConfig);
  const paramScope = createParamScope(simParam, experimentConfig, physicsScope);

  /* eslint-disable-next-line id-length */
  const p = heat.solutionControlsHeat;
  const value = p?.solutionControlsHeatPreset;

  if (!p || value === CUSTOM_SOLUTION_CONTROLS_HEAT) {
    return;
  }
  // Always tag the project with the current version. See LC-6227.
  p.controlsPresetVersion = newInt(ControlsPresetVersion);

  // BEGIN GENERATED CODE (see gen/presets.py)
  const presets = new Map<string, ProtoDescriptor.Cond>();
  presets.set(
    'CTRL_HEAT_TRANSFER',
    {
      type: ProtoDescriptor.CondType.TRUE,
    },
  );
  checkPresets(presets, paramScope);
  if (paramScope.isEnabled(presets.get('CTRL_HEAT_TRANSFER')!)) {
    p.relaxationMethod = simulationpb.RelaxationMethod.IMPLICIT;
    p.implicitMethod = simulationpb.ImplicitMethod.BACKWARD_EULER;
    p.linearSolverType = simulationpb.LinearSolverType.AMG_KRYLOV_AMGX;
    p.linsolIterations = newInt(7);
    p.linsolTolerance = newAdFloat(0.05);
    p.linsolAmgPreSweeps = newInt(1);
    p.linsolAmgPostSweeps = newInt(1);
    p.linsolAmgCoarseningSize = newInt(8);
    p.linsolAmgLevels = newInt(20);
    p.linsolAmgRelaxation = newAdFloat(0.75);
    p.linsolAmgCycleType = simulationpb.LinsolAmgCycleType.LINSOL_AMG_CYCLE_TYPE_V;
    p.linsolAmgFreezeLevelsThreshold = newInt(300);
    p.linsolAmgSmoother = simulationpb.LinsolAmgSmoother.LINSOL_AMG_SMOOTHER_JACOBI;
    if (value === INTERMEDIATE_SOLUTION_CONTROLS_HEAT) {
      p.relaxationMethod = simulationpb.RelaxationMethod.IMPLICIT;
      p.implicitMethod = simulationpb.ImplicitMethod.BACKWARD_EULER;
      p.linearSolverType = simulationpb.LinearSolverType.AMG_KRYLOV_AMGX;
      p.linsolIterations = newInt(3);
      p.linsolTolerance = newAdFloat(0.2);
      p.linsolAmgPreSweeps = newInt(1);
      p.linsolAmgPostSweeps = newInt(1);
      p.linsolAmgCoarseningSize = newInt(8);
      p.linsolAmgLevels = newInt(20);
      p.linsolAmgRelaxation = newAdFloat(0.75);
      p.linsolAmgCycleType = simulationpb.LinsolAmgCycleType.LINSOL_AMG_CYCLE_TYPE_V;
      p.linsolAmgFreezeLevelsThreshold = newInt(300);
      p.linsolAmgSmoother = simulationpb.LinsolAmgSmoother.LINSOL_AMG_SMOOTHER_JACOBI;
    }
    if (value === AGGRESSIVE_SOLUTION_CONTROLS_HEAT) {
      p.relaxationMethod = simulationpb.RelaxationMethod.IMPLICIT;
      p.implicitMethod = simulationpb.ImplicitMethod.BACKWARD_EULER;
      p.linearSolverType = simulationpb.LinearSolverType.AMG_KRYLOV_AMGX;
      p.linsolIterations = newInt(2);
      p.linsolTolerance = newAdFloat(0.4);
      p.linsolAmgPreSweeps = newInt(0);
      p.linsolAmgPostSweeps = newInt(1);
      p.linsolAmgCoarseningSize = newInt(8);
      p.linsolAmgLevels = newInt(20);
      p.linsolAmgRelaxation = newAdFloat(0.75);
      p.linsolAmgCycleType = simulationpb.LinsolAmgCycleType.LINSOL_AMG_CYCLE_TYPE_V;
      p.linsolAmgFreezeLevelsThreshold = newInt(300);
      p.linsolAmgSmoother = simulationpb.LinsolAmgSmoother.LINSOL_AMG_SMOOTHER_JACOBI;
    }
  }
  // END GENERATED CODE
}

export function applyAllHeatControlsPresets(
  simParam: simulationpb.SimulationParam,
  experimentConfig: string[],
): void {
  return simParam.physics.forEach((physics) => {
    applyHeatControlsPreset(physics, simParam, experimentConfig);
  });
}
