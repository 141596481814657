// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo, useState } from 'react';

import assert from '../../../../lib/assert';
import { cadIdsToVolumeNodeIds, volumeNodeIdsToCadIds } from '../../../../lib/geometryUtils';
import * as random from '../../../../lib/random';
import * as rpc from '../../../../lib/rpc';
import { NodeType } from '../../../../lib/simulationTree/node';
import { defaultNodeFilter, mapVisualizerEntitiesToVolumes } from '../../../../lib/subselectUtils';
import { addRpcError } from '../../../../lib/transientNotification';
import * as geometryservicepb from '../../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as geometrypb from '../../../../proto/geometry/geometry_pb';
import { QuantityType } from '../../../../proto/quantity/quantity_pb';
import { DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE, createOrUpdateFeature, useGeometrySelectedFeature, useGeometryState, useSetGeometryState } from '../../../../recoil/geometry/geometryState';
import { useCadMetadata } from '../../../../recoil/useCadMetadata';
import { useStaticVolumes } from '../../../../recoil/volumes';
import Form from '../../../Form';
import { NumberInput } from '../../../Form/NumberInput';
import QuantityAdornment from '../../../QuantityAdornment';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { NodeSubselect } from '../../NodeSubselect';

import GeometryModificationPanelFooter from './GeometryModificationPanelFooter';
import { EditModificationMessage } from './GeometryModificationShared';

export const defaultShrinkwrap = () => new geometrypb.Shrinkwrap({
  mode: geometrypb.ShrinkwrapMode.AUTOMATIC,
  // TODO: Add default resolutions based on geometry/tessellation.
});

// Handles shrinkwrap geometry modifications.
export const GeometryModificationShrinkwrapPropPanel = () => {
  // == Contexts
  const { projectId, geometryId, readOnly } = useProjectContext();
  const { selectedNode: node, setSelection } = useSelectionContext();
  assert(!!node, 'No selected geometry modification shrinkwrap row');

  // == Recoil
  const geometryState = useGeometryState(projectId, geometryId);
  const setGeometryState = useSetGeometryState(projectId, geometryId);
  const [, setSelectedFeature] = useGeometrySelectedFeature(geometryId);
  const [cadMetadata] = useCadMetadata(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  // == Hooks
  const commonClasses = useCommonTreePropsStyles();

  // == Derived data
  const mod = geometryState?.geometryFeatures.find((feature) => feature.id === node.id);
  assert(!!mod, 'No selected geometry modification shrinkwrap');
  const [shrinkwrap, setShrinkwrap] = useState(mod.operation.value as geometrypb.Shrinkwrap);
  const initialBodies = useMemo(
    () => cadIdsToVolumeNodeIds(shrinkwrap.body, staticVolumes, cadMetadata),
    [shrinkwrap.body, staticVolumes, cadMetadata],
  );
  const isAcknowledgedFeature = useMemo(
    () => geometryState?.ackModifications.has(node.id),
    [geometryState, node.id],
  );

  const onSetShrinkwrap = useCallback((newShrinkwrap: geometrypb.Shrinkwrap) => {
    setShrinkwrap(newShrinkwrap);
    setGeometryState((oldGeometryState) => {
      if (oldGeometryState === undefined) {
        return oldGeometryState;
      }
      const newGeometryState = { ...oldGeometryState };
      newGeometryState.geometryFeatures.forEach((feature) => {
        if (feature.id !== mod.id) {
          return;
        }
        feature.operation.value = newShrinkwrap;
      });
      return newGeometryState;
    });
  }, [mod.id, setGeometryState]);

  // == State
  const [selectedBodiesNodeIds, setSelectedBodiesNodeIds] = useState<string[]>(initialBodies);

  const nodeFilter = useCallback((nodeType: NodeType, nodeId: string) => {
    if (nodeType === NodeType.VOLUME) {
      return {
        related: true,
        disabled: selectedBodiesNodeIds.includes(nodeId),
      };
    }
    return defaultNodeFilter(nodeType);
  }, [selectedBodiesNodeIds]);

  const mapVisualizerEntities = useCallback(
    (ids: string[]) => mapVisualizerEntitiesToVolumes(ids, staticVolumes),
    [staticVolumes],
  );

  const onShrinkwrapSave = async () => {
    const feature = new geometrypb.Feature({
      operation: {
        case: 'shrinkwrap',
        value: mod.operation.value as geometrypb.Shrinkwrap,
      },
      id: node.id,
      featureName: mod.featureName,
    });
    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification: new geometrypb.Modification({
        modType: createOrUpdateFeature(geometryState, node.id),
        feature,
      }),
    });
    rpc.clientGeometry!.modifyGeometry(req).catch((err) => (
      addRpcError(`Server error ${err}`, err)
    ));

    // Focus out of the panel, to avoid weird rerenderings when calling setSelection.
    setSelectedFeature(DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE);
    setSelection([]);
  };

  const onNodeChange = useCallback((nodeIds: string[]) => {
    setGeometryState((oldGeometryState) => {
      if (oldGeometryState === undefined) {
        return oldGeometryState;
      }
      const newGeometryState = { ...oldGeometryState };
      newGeometryState.geometryFeatures.forEach((feature) => {
        if (feature.id !== mod.id) {
          return;
        }
        const transfOut = feature.operation.value as geometrypb.Transform;
        const bodies = volumeNodeIdsToCadIds(nodeIds, staticVolumes, cadMetadata);
        transfOut.body = bodies;
      });
      return newGeometryState;
    });
    setSelectedBodiesNodeIds(nodeIds);
  }, [cadMetadata, mod.id, setGeometryState, staticVolumes]);

  return (
    <div className={commonClasses.properties}>
      <EditModificationMessage nodeId={node.id} />
      <NodeSubselect
        autoStart={!isAcknowledgedFeature}
        iconNotFoundNodes={{ name: 'cubeOutline' }}
        id="wrap"
        labels={['volumes']}
        mapVisualizerEntities={mapVisualizerEntities}
        nodeFilter={nodeFilter}
        nodeIds={selectedBodiesNodeIds}
        onChange={onNodeChange}
        readOnly={readOnly}
        referenceNodeIds={[node.id]}
        showNotFoundNodes
        title="Volumes to Shrinkwrap"
        visibleTreeNodeTypes={[NodeType.VOLUME]}
      />
      <div style={{ marginTop: 8 }}>
        <Form.MultiCheckBox checkBoxProps={[{
          checked: shrinkwrap.mode === geometrypb.ShrinkwrapMode.AUTOMATIC,
          help: 'Automatic choice of sizing for the shrinkwrap.',
          optionText: 'Automatic Sizing',
          onChange: (checked) => {
            const newWrap = shrinkwrap.clone();
            newWrap.mode = checked ?
              geometrypb.ShrinkwrapMode.AUTOMATIC : geometrypb.ShrinkwrapMode.MANUAL;
            onSetShrinkwrap(newWrap);
          },
        }]}
        />
      </div>
      {shrinkwrap.mode === geometrypb.ShrinkwrapMode.MANUAL && (
        <Form.LabeledInput
          help="Finest resolution used, under which geometrical features are ignored."
          label="Min. Resolution">
          <NumberInput
            asBlock
            disabled={false}
            endAdornment={<QuantityAdornment quantity={QuantityType.LENGTH} />}
            onCommit={(resolution) => {
              const newWrap = shrinkwrap.clone();
              newWrap.resolutionMin = resolution;
              onSetShrinkwrap(newWrap);
            }}
            size="small"
            value={shrinkwrap.resolutionMin}
          />
        </Form.LabeledInput>
      )}
      {shrinkwrap.mode === geometrypb.ShrinkwrapMode.MANUAL && (
        <Form.LabeledInput
          help="Coarsest resolution used, under which geometrical features are ignored."
          label="Max. Resolution">
          <NumberInput
            asBlock
            disabled={false}
            endAdornment={<QuantityAdornment quantity={QuantityType.LENGTH} />}
            onCommit={(resolution) => {
              const newWrap = shrinkwrap.clone();
              newWrap.resolutionMax = resolution;
              onSetShrinkwrap(newWrap);
            }}
            size="small"
            value={shrinkwrap.resolutionMax}
          />
        </Form.LabeledInput>
      )}

      <GeometryModificationPanelFooter
        featureId={node.id}
        onModificationSave={onShrinkwrapSave}
      />
    </div>
  );
};
