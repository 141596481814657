// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import {
  DefaultValue,
  SetterOrUpdater,
  atomFamily,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { applyVisibilities } from '../../lib/lcvis/api';
import { shallowVisMapDiff } from '../../lib/lcvis/lcvisUtils';
import { LcVisVisibilityMap } from '../../lib/lcvis/types';
import * as persist from '../../lib/persist';
import { syncProjectStateEffect } from '../../lib/recoilSync';
import { recordToMap } from '../../lib/tsUtils';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import { entityGroupDataSelector } from '../entityGroupState';
import { getGeoState } from '../geometry/geometryState';
import { activeVisUrlState } from '../vis/activeVisUrl';
import { filterVisibilitiesState } from '../vis/filterState';

import { LcvisKvStoreKey, makeLcvMeshKeys } from './lcvisPersistUtils';

function serialize(val: LcVisVisibilityMap): Uint8Array {
  const protoMap = new frontendpb.LCVisVisibilityMap();
  [...val.keys()].forEach((key) => {
    protoMap.entries[key] = val.get(key) ?? false;
  });
  return protoMap.toBinary();
}

function deserialize(val: Uint8Array): LcVisVisibilityMap {
  const visMap = (
    val.length ?
      frontendpb.LCVisVisibilityMap.fromBinary(val) :
      new frontendpb.LCVisVisibilityMap()
  );
  const visibilities = visMap.entries;
  return recordToMap(visibilities);
}

const lcvisVisibilityMapState = atomFamily<LcVisVisibilityMap, LcvisKvStoreKey>({
  key: 'lcvisVisibilityMap',
  default: async (key: LcvisKvStoreKey) => {
    if (!key.lcvMeshKeys.length) {
      return new Map<string, boolean>();
    }
    const storedVal = await persist.getProjectState(key.projectId, key.lcvMeshKeys, deserialize);
    const { toShow, toHide } = shallowVisMapDiff(new Map(), storedVal);
    applyVisibilities(toShow, toHide);
    return storedVal;
  },
  effects: (key: LcvisKvStoreKey) => [
    ({ onSet }) => {
      // whenever the recoil visibility changes, update the lcvis visibilities accordingly.
      onSet((newVal: LcVisVisibilityMap, oldVal: LcVisVisibilityMap | DefaultValue) => {
        const prevVal = oldVal instanceof DefaultValue ? new Map() : oldVal;
        const { toShow, toHide } = shallowVisMapDiff(prevVal, newVal);
        applyVisibilities(toShow, toHide);
      });
    },
    key.lcvMeshKeys.length ?
      syncProjectStateEffect(key.projectId, key.lcvMeshKeys[0], deserialize, serialize) :
      () => { },
  ],
});

export const lcvisVisibilityMapSelector = selectorFamily<
  LcVisVisibilityMap, persist.RecoilProjectKey
>({
  key: 'lcvisVisibilitySelector',
  get: (key: persist.RecoilProjectKey) => ({ get }) => {
    const activeVisUrl = get(activeVisUrlState(key));
    const lcvMeshKeys = makeLcvMeshKeys('lcvisVisibility', activeVisUrl);
    const state = get(lcvisVisibilityMapState({ projectId: key.projectId, lcvMeshKeys }));
    const entityGroupData = get(entityGroupDataSelector(key));
    const geoState = getGeoState(get, key.projectId);
    if (state.size) {
      if (geoState === undefined) {
        return state;
      }
      // This is a workaround to update the visibility map when the entity group changes due to
      // geometry modifications.
      const allFound = [...entityGroupData.leafMap.values()].every(
        (leaves) => [...leaves].every((leaf) => state.has(leaf)),
      );
      if (allFound) {
        return state;
      }
      const newVisMap = new Map<string, boolean>();
      entityGroupData.leafMap.forEach((leaves) => {
        leaves.forEach((leaf) => newVisMap.set(leaf, state.get(leaf) ?? true));
      });
      return newVisMap;
    }
    // if state.size is 0, there's no persisted value in the kvstore yet. We should construct
    // a default value from the entity group state where all surfaces are visible.
    const newVisMap = new Map<string, boolean>();
    entityGroupData.leafMap.forEach((leaves) => {
      leaves.forEach((leaf) => newVisMap.set(leaf, true));
    });
    return newVisMap;
  },
  set: (key: persist.RecoilProjectKey) => (
    { get, set },
    newVal: LcVisVisibilityMap | DefaultValue,
  ) => {
    const activeVisUrl = get(activeVisUrlState(key));
    const lcvMeshKeys = makeLcvMeshKeys('lcvisVisibility', activeVisUrl);
    if (newVal instanceof DefaultValue) {
      // newVal will only be DefaultValue if someone calls resetRecoilState. So in that case
      // set the state to an empty map.
      set(lcvisVisibilityMapState({ projectId: key.projectId, lcvMeshKeys }), new Map());
      return;
    }
    const trueNewVal = new Map(newVal);
    const filterVisibilities = get(filterVisibilitiesState(key));
    // Remove any filter visibilities from the new value, since those should only
    // be persisted with the filterState.
    filterVisibilities.forEach((_, filterId) => {
      trueNewVal.delete(filterId);
    });
    set(lcvisVisibilityMapState({ projectId: key.projectId, lcvMeshKeys }), trueNewVal);
  },
});

/**
 * Returns a SetterOrUpdater to modify the visibility map. As part of the setState action,
 * it calls showSurfaces and hideSurfaces on any map entries that have changed from the previous
 * map state. If an entry didn't change from the previous value, we don't invoke lcvis.
 */
export const useSetLcvisVisibilityMap = (
  key: persist.RecoilProjectKey,
): SetterOrUpdater<LcVisVisibilityMap> => useSetRecoilState(lcvisVisibilityMapSelector(key));

export const useLcvisVisibilityMap = (
  key: persist.RecoilProjectKey,
) => useRecoilState(lcvisVisibilityMapSelector(key));

export const useLcvisVisibilityMapValue = (
  key: persist.RecoilProjectKey,
) => useRecoilValue(lcvisVisibilityMapSelector(key));
