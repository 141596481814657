// Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { formatNumber } from '../../../lib/number';

import { ThumbValue } from './util';

export interface ReadoutProps {
  // Whether to disable the readout altogether
  disabled: boolean;
  // Whether to show the readout
  active: boolean;
  // ThumbValue instance for thumb control represented by this Readout
  thumbValue: ThumbValue;
  verticalOffset: number;
  formatValue?: (value: number) => string;
}

export const Readout = (props: ReadoutProps) => {
  const { active, disabled, formatValue, thumbValue, verticalOffset } = props;

  const format = (value: number) => {
    if (formatValue) {
      return formatValue(value);
    }
    return formatNumber(value);
  };

  return (
    <div
      className={cx('readout', { active, disabled })}
      style={{
        left: `${thumbValue.percentage}%`,
        bottom: `calc(100% + ${verticalOffset}px)`,
        '--value-param': `${thumbValue.parameterized}`,
        '--value-percentage': `${thumbValue.percentage}%`,
      } as React.CSSProperties}>
      <div className="body">
        {format(thumbValue.value)}
      </div>
      <div className="pointer" />
    </div>

  );
};
