// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 15;
const baseHeight = 24;
export const CaretLeftIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="caretLeft"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M13.5,0
           C13.11,0 12.78,0.15 12.51,0.39
           L0.51,10.89
           C0.21,11.16 0,11.55 0,12
           C0,12.45 0.21,12.84 0.51,13.11
           L12.51,23.61
           C12.78,23.85 13.11,24 13.5,24
           C14.34,24 15,23.34 15,22.5
           L15,1.5
           C15,0.66 14.34,0 13.5,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
