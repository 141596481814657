const CREDITS_WARNING_THRESHOLD = 20;
const CREDITS_ERROR_THRESHOLD = 0;

export enum CreditStatus {
  ERROR,
  WARNING,
  OK,
}

export const getCreditsStatus = (credits: number | null) => {
  if (credits === null) {
    return CreditStatus.OK;
  }

  if (credits <= CREDITS_ERROR_THRESHOLD) {
    return CreditStatus.ERROR;
  }

  if (credits <= CREDITS_WARNING_THRESHOLD) {
    return CreditStatus.WARNING;
  }

  return CreditStatus.OK;
};
