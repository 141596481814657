// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { extractValues } from '../../lib/explorationUtils';
import { getSimulationParam } from '../../lib/simulationParamUtils';
import { useWorkflowState } from '../../recoil/workflowState';
import { useSimulationBoundaryNames } from '../../state/external/project/simulation/param/boundaryNames';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';

import { JobDataCategory, newJobDataCategory } from './JobDataCategory';
import JobDataPanel from './JobDataPanel';
import JobVerticalDataTable from './JobVerticalDataTable';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      gap: '8px',
    },
  }),
  { name: 'JobPanel' },
);

// JobPanel summarizes a single job. This is similar to JobTable, but JobTable
// shows many jobs.

interface jobPanelProps {
  // Whether we are showing the results from an exploration.
  isExploration: boolean;
}

const JobPanel = (props: jobPanelProps) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const workflow = useWorkflowState(projectId, workflowId);
  const bcNames = useSimulationBoundaryNames(projectId, workflowId, jobId);

  const exploration = workflow?.config!.exploration!;

  const classes = useStyles();

  // Adds a data category for a particular output.
  const simParam = getSimulationParam(workflow?.config!);

  const job = workflow?.job[jobId]!;

  // Compute the values for the different data categories.
  let explorationCategories: JobDataCategory[] = [];
  if (props.isExploration) {
    const formattedValues = extractValues(
      job.explorationValues!,
      exploration,
      simParam,
      bcNames,
    );
    explorationCategories = formattedValues.map(
      (value) => newJobDataCategory([value.name], [value.value], [job.jobId]),
    );
  }

  return (
    <div className={classes.root}>
      {props.isExploration && (
        <JobDataPanel
          body={(
            <JobVerticalDataTable
              categories={explorationCategories}
              name="exploration"
            />
          )}
          title={<span>Exploration</span>}
        />
      )}
    </div>
  );
};

export default JobPanel;
