// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { RefObject, useRef } from 'react';

import parseHTMLString from 'html-react-parser';

import * as random from '../../lib/random';
import useResizeObserver from '../../lib/useResizeObserver';

import Tooltip, { TooltipProps } from '.';

export interface TaggedTooltipProps extends TooltipProps {
  tag: string | ReturnType<typeof parseHTMLString>,
  innerRef: RefObject<HTMLElement>,
  outerRef: RefObject<HTMLElement>,
}

/**
 * Using resize observer, dynamically prepend a tag to a tooltip title, whenever the outerRef's
 * width is <= the innerRef's width
 * */
export const TaggedTooltip = (props: TaggedTooltipProps) => {
  const { innerRef, outerRef, tag, title, ...tooltipProps } = props;

  const id = useRef(random.string(8));
  // Listen for resizes and prepend the tag in bold to the tooltip if the innerRef is overflowing
  const innerSize = useResizeObserver(innerRef, { name: `${id.current}-tagged-tooltip-inner` });
  const outerSize = useResizeObserver(outerRef, { name: `${id.current}-tagged-tooltip-outer` });

  const getFormattedTitle = () => {
    if (title && (outerSize.width <= innerSize.width)) {
      // Inner content is overflowing--prepend the tag in bold to the tooltip
      return (
        <span>
          <b>{tag}</b>: {title}
        </span>
      );
    }
    return title;
  };

  return <Tooltip {...tooltipProps} title={getFormattedTitle()} />;
};
