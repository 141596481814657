// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useAtom, useAtomValue } from 'jotai';

import * as jwt from '../../../lib/jwt';
import * as userstatepb from '../../../proto/userstate/userstate_pb';
import { atomWithInitialSessionValue } from '../../lib/sessionState';

// #region: Constants

const frontendRoleKey = 'frontendRole';

// #region: Helper Functions

function serialize(val: userstatepb.SystemRole) {
  return new userstatepb.FrontendRole({ role: val }).toBinary();
}

function deserialize(val: Uint8Array) {
  if (val.length) {
    const result = userstatepb.FrontendRole.fromBinary(val).role;
    return result;
  }
  const js = jwt.loadSessionJwt();
  return jwt.systemRole(js!);
}

// #region: Atoms

export const frontendRoleState = atomWithInitialSessionValue(
  '',
  frontendRoleKey,
  userstatepb.SystemRole.USER,
  serialize,
  deserialize,
);

/**
 * Atom value returns true if the user is a staff member
 */
export const staffRoleState = atom((get) => {
  const role = get(frontendRoleState);
  return role === userstatepb.SystemRole.STAFF || role === userstatepb.SystemRole.ADMIN;
});

// #region: Hooks

export function useFrontendRole() {
  return useAtom(frontendRoleState);
}

/**
 * @returns true if the user has the staff or admin system role
 * */
export function useIsStaff() {
  return useAtomValue(staffRoleState);
}
