// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { ReactElement, ReactNode } from 'react';

import cx from 'classnames';

import { SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { colors } from '../../lib/designSystem';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  (theme) => createStyles({
    root: {
      alignItems: 'center',
      color: 'rgba(255, 255, 255, 0.35)',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '20px',
      textAlign: 'center',
    },
    title: {
      fontSize: '18px',
      fontWeight: 600,
      color: colors.neutral900,
    },
    subtitle: {
      fontSize: '14px',
      fontWeight: 400,
      width: '500px',
      color: colors.neutral800,
      flexBasis: '25vh',
      overflowY: 'scroll',
      scrollbarWidth: 'none',

      '&::-webkit-scrollbar': {
        width: 0,
      },
    },
    children: {
      marginTop: '34px',
      width: '100%',
    },
  }),
  { name: 'EmptyState' },
);

export interface EmptyStateProps {
  /**
   * Optional icon.
   */
  icon?: SvgIconSpec;
  /**
   * Optional button.
   */
  button?: ReactElement;

  className?: string;
  /**
   * Additional, optional explanatory text for the empty state.
   */
  subtitle?: ReactElement;
  /**
   * The main heading of the empty state.
   */
  title: string;
  children?: ReactNode;
}

/**
 * Placeholder component that displays a message or call to action when there's
 * no content to render. Shows a title by default, with an optional subtitle for
 * more context or instruction. Accepts children for displaying arbitrary
 * content like a call to action button.
 */
const EmptyState = (props: EmptyStateProps) => {
  const classes = useStyles();

  const subtitle = props.subtitle ? (
    <div className={classes.subtitle}>
      {props.subtitle}
    </div>
  ) : null;

  const children = props.children ? (
    <div className={classes.children}>
      {props.children}
    </div>
  ) : null;

  const icon = props.icon ?
    <div style={{ paddingBottom: '16px' }}><SvgIcon {...props.icon} /></div> :
    <></>;

  return (
    <div className={cx(classes.root, props.className)}>
      {icon}
      <div className={classes.title}>
        {props.title}
      </div>
      {subtitle}
      {children}
      {props.button}
    </div>
  );
};

export default EmptyState;
