// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, waitForAll } from 'recoil';

import { ParamScope } from '../../../lib/ParamScope';
import { nullableMeshing } from '../../../lib/mesh';
import { RecoilProjectKey } from '../../../lib/persist';
import * as cadmetadatapb from '../../../proto/cadmetadata/cadmetadata_pb';
import * as simulationpb from '../../../proto/client/simulation_pb';
import * as frontendpb from '../../../proto/frontend/frontend_pb';
import * as feoutputpb from '../../../proto/frontend/output/output_pb';
import * as projectstatepb from '../../../proto/projectstate/projectstate_pb';
import * as workflowpb from '../../../proto/workflow/workflow_pb';
import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import { EntityGroupData, entityGroupDataSelector } from '../../../recoil/entityGroupState';
import { GeometryTags } from '../../../recoil/geometry/geometryTagsObject';
import { geometryTagsState } from '../../../recoil/geometry/geometryTagsState';
import { FrontendGeometryContacts, geometryContactsStateSelector } from '../../../recoil/geometryContactsState';
import { geometryHealthState } from '../../../recoil/geometryHealth';
import { lcVisEnabledSelector } from '../../../recoil/lcvis/lcvisEnabledState';
import { FilterStatus, lcvisFilterStatus } from '../../../recoil/lcvis/lcvisFilterStatus';
import { meshUrlState } from '../../../recoil/meshState';
import { outputNodesState } from '../../../recoil/outputNodes';
import { cadMetadataState } from '../../../recoil/useCadMetadata';
import { CadModifier, cadModifierState } from '../../../recoil/useCadModifier';
import { enabledExperimentsSelector_DEPRECATED } from '../../../recoil/useExperimentConfig';
import { meshingMultiPartSelector } from '../../../recoil/useMeshingMultiPart';
import { stoppingConditionsSelectorUpdate } from '../../../recoil/useStoppingConditions';
import { viewStateAtomFamily_DEPRECATED } from '../../../recoil/useViewState';
import { StaticVolume, staticVolumesState } from '../../../recoil/volumes';
import { currentConfigSelector } from '../../../recoil/workflowConfig';

import { simulationParamState } from './simulation/param';
import { simulationParamScopeState } from './simulation/paramScope';

/** Data representing params and KV-store configuration needed for validation */
export interface ProjectValidationData {
  cadMetadata: cadmetadatapb.CadMetadata;
  cadModifier: CadModifier;
  config: workflowpb.Config;
  entityGroupData: EntityGroupData;
  experimentConfig: string[];
  geometryContacts: FrontendGeometryContacts;
  geometryHealth: frontendpb.CheckGeometryReply_Done | null;
  isSensitivityAnalysis: boolean;
  lcvisEnabled: boolean;
  meshUrl: projectstatepb.MeshUrl;
  multiPartMeshing: nullableMeshing;
  outputNodes: feoutputpb.OutputNodes;
  param: simulationpb.SimulationParam;
  paramScope: ParamScope;
  staticVolumes: StaticVolume[];
  stoppingConditions: feoutputpb.StoppingConditions;
  viewState: ParaviewRpc.ViewState | null;
  filterStatus: FilterStatus;
  geometryTags: GeometryTags;
}

export const projectValidationDataState = selectorFamily<ProjectValidationData, RecoilProjectKey>({
  key: 'projectValidationDataState',
  get: (key: RecoilProjectKey) => ({ get }) => {
    const { projectId } = key;

    const [
      cadMetadata,
      cadModifier,
      config,
      entityGroupData,
      experimentConfig,
      geometryContacts,
      geometryHealth,
      lcvisEnabled,
      meshUrl,
      multiPartMeshing,
      outputNodes,
      param,
      paramScope,
      staticVolumes,
      stoppingConditions,
      viewState,
      filterStatus,
      geometryTags,
    ] = get(waitForAll([
      cadMetadataState(projectId),
      cadModifierState(projectId),
      currentConfigSelector(key),
      entityGroupDataSelector(key),
      enabledExperimentsSelector_DEPRECATED,
      geometryContactsStateSelector({ projectId }),
      geometryHealthState(projectId),
      lcVisEnabledSelector(projectId),
      meshUrlState(projectId),
      meshingMultiPartSelector(key),
      outputNodesState({ projectId, workflowId: '', jobId: '' }),
      simulationParamState(key),
      simulationParamScopeState(key),
      staticVolumesState(projectId),
      stoppingConditionsSelectorUpdate(key),
      viewStateAtomFamily_DEPRECATED(projectId),
      lcvisFilterStatus,
      geometryTagsState({ projectId }),
    ]));

    const { exploration } = config;

    return {
      cadMetadata,
      cadModifier,
      config,
      entityGroupData,
      experimentConfig,
      geometryContacts,
      geometryHealth,
      isSensitivityAnalysis: exploration?.policy.case === 'sensitivityAnalysis',
      lcvisEnabled,
      meshUrl,
      multiPartMeshing,
      outputNodes,
      param,
      paramScope,
      staticVolumes,
      stoppingConditions,
      viewState,
      filterStatus,
      geometryTags,
    };
  },
  dangerouslyAllowMutability: true,
});
