// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import cx from 'classnames';

export interface PageLinkControlProps {
  display: string;
  onClick: () => void;
  isCurrent?: boolean;
}

/**
 * In a paginated data set, the PageLinkControl component is a button used to change the current
 * page
 */
export const PageLinkControl = (props: PageLinkControlProps) => {
  const { display, isCurrent, onClick } = props;

  return (
    <div className="pageControl">
      <button
        className={cx('controlButton', { current: isCurrent })}
        onClick={onClick}
        type="button">
        {display}
      </button>
    </div>
  );
};
