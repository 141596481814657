// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { BoxClipParam, PlaneParam, TreeNodeParam } from '../../../../pvproto/ParaviewRpc';
import { pvToList } from '../../../Vector';
import assert from '../../../assert';
import { LcvModule } from '../../types';

import { LcvClipBoxWidget } from './LcvClipBoxWidget';
import { LcvClipPlaneWidget } from './LcvClipPlaneWidget';
import { LcvFilterWidgetType } from './LcvWidget';

export function applyParamToBoxWidget(widget: LcvClipBoxWidget, filterParam: BoxClipParam) {
  assert(widget.type === 'clip_box', 'Widget is not a clip box');
  const { position, rotation, length } = filterParam;
  widget.setState({
    center: pvToList(position),
    rotation: pvToList(rotation),
    size: pvToList(length),
  });
}

export function applyParamToPlaneWidget(widget: LcvClipPlaneWidget, filterParam: PlaneParam) {
  assert(widget.type === 'clip_plane', 'Widget is not a clip plane');
  const { normal, origin } = filterParam;
  const normalArr = pvToList(normal);
  const originArr = pvToList(origin);
  widget.setState({
    normal: normalArr,
    position: originArr,
  });
}

/** Given a TreeNodeParam, apply it to the widget's params. */
export function applyParamToWidget(widget: LcvFilterWidgetType, param: TreeNodeParam): void {
  switch (param.typ) {
    case 'Clip': {
      const { filterParam } = param;
      if (filterParam.typ === 'BoxClip') {
        applyParamToBoxWidget(widget as LcvClipBoxWidget, filterParam);
        break;
      }
      applyParamToPlaneWidget(widget as LcvClipPlaneWidget, filterParam);
      break;
    }
    case 'Slice': {
      const { filterParam } = param;
      assert(widget.type === 'clip_plane', 'Widget is not a clip plane');
      assert(filterParam.typ === 'Plane', 'Filter param is not a plane');
      applyParamToPlaneWidget(widget as LcvClipPlaneWidget, filterParam);
      (widget as LcvClipPlaneWidget).setToSlice();
      break;
    }
    default:
    // none
  }
}

/**
* Given a TreeNodeParam, creates and returns a new LcvWidget corresponding to that param's
* type. If the param isn't yet supported in LCVis, returns null.
*/
export function lcvWidgetFromParam(
  lcv: LcvModule,
  sessionHandle: number,
  param: TreeNodeParam,
): LcvFilterWidgetType | null {
  switch (param.typ) {
    case 'Clip': {
      if (param.filterParam.typ === 'BoxClip') {
        // create box clip widget
        const boxClip = new LcvClipBoxWidget(lcv, sessionHandle);
        applyParamToWidget(boxClip, param);
        return boxClip;
      }
      // create clip plane widget (param.filterParam.typ = 'Plane')
      const planeWidget = new LcvClipPlaneWidget(lcv, sessionHandle);
      applyParamToWidget(planeWidget, param);
      return planeWidget;
    }
    case 'Slice': {
      const sliceWidget = new LcvClipPlaneWidget(lcv, sessionHandle);
      applyParamToWidget(sliceWidget, param);
      return sliceWidget;
    }
    default:
      return null;
  }
}
