// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 18;
export const EyeCheckIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="eyeCheck"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M15.435,8.55
           C15.705,8.82 16.08,8.985 16.5,8.985
           C16.92,8.985 17.295,8.82 17.565,8.55
           L23.565,2.55
           C23.82,2.28 24,1.905 24,1.5
           C24,0.675 23.325,0 22.5,0
           C22.08,0 21.705,0.165 21.435,0.435
           L16.5,5.37
           L14.565,3.435
           C14.295,3.165 13.92,3 13.5,3
           C12.675,3 12,3.66 12,4.5
           C12,4.905 12.18,5.28 12.45,5.55
           L15.435,8.55Z
           M24,10.44
           L24,10.335
           C23.97,10.125 23.88,9.93 23.745,9.795
           C23.295,9.195 22.77,8.655 22.245,8.115
           L19.695,10.665
           C18.87,11.49 17.745,11.985 16.5,11.985
           C16.41,11.985 16.335,11.97 16.245,11.955
           C15.63,13.725 13.98,15 12.015,15
           C9.525,15 7.515,12.975 7.515,10.485
           C7.515,8.88 8.355,7.485 9.6,6.69
           C9.24,6.045 9.015,5.295 9.015,4.5
           C9.015,4.11 9.075,3.75 9.165,3.39
           C8.115,3.69 7.11,4.14 6.15,4.68
           C4.26,5.73 2.55,7.2 1.05,8.835
           C0.78,9.15 0.51,9.45 0.255,9.78
           C-0.075,10.215 -0.075,10.74 0.255,11.175
           C1.02,12.18 1.935,13.08 2.85,13.89
           C4.845,15.645 7.125,17.115 9.645,17.715
           C11.1,18.06 12.57,18.075 14.025,17.79
           C15.36,17.52 16.635,16.98 17.835,16.305
           C19.71,15.24 21.435,13.77 22.92,12.135
           C23.19,11.835 23.475,11.52 23.73,11.19
           C23.865,11.04 23.955,10.845 23.985,10.635
           L23.985,10.485
           C24,10.47 24,10.455 24,10.44Z
           M12.015,12
           C12.735,12 13.32,11.475 13.455,10.785
           C13.41,10.74 13.38,10.695 13.35,10.65
           L13.32,10.665
           L11.7,9.045
           C11.025,9.195 10.515,9.765 10.515,10.485
           C10.515,11.325 11.19,12 12.015,12Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
