// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useCallback } from 'react';

import { AnyKeyboardEvent } from '../../../lib/event';
import { isEventTrigger } from '../../../lib/keyBindings';
import { ListenerEvent, useEventListener } from '../../../lib/useEventListener';

export interface LcVisOverlayProps {
  text: string;
  className: string;
  onExit?: () => void;
  children?: ReactNode;
}

/*
  A transparent SVG element that sits on
  top of the LcVis canvas and displays a text message.
*/
const LcVisOverlay = (props: LcVisOverlayProps) => {
  const { text, className, onExit } = props;

  // pressing escape should exit the current overlay.
  const handleExitOverlay = useCallback((event: AnyKeyboardEvent) => {
    if (isEventTrigger('exitOverlay', event)) {
      event.preventDefault();
      onExit?.();
    }
  }, [onExit]);
  useEventListener(
    'keydown',
    (event: ListenerEvent) => handleExitOverlay(event as AnyKeyboardEvent),
  );

  return (
    <div className={className} style={{ pointerEvents: 'none' }}>
      <svg
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
        }}>
        <g>
          <text
            style={{
              // Make sure that the text is not selectable
              dominantBaseline: 'middle',
              color: 'white',
              cursor: 'default',
              fill: 'white',
              fontFamily: 'Inter,Helvetica,Arial,sans-serif',
              fontSize: '15',
              pointerEvents: 'none',
              textAnchor: 'middle',
              MozUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none',
              WebkitUserSelect: 'none',
            }}
            x="50%"
            y="90%">
            {text}
          </text>
        </g>
        {props.children}
      </svg>
    </div>
  );
};

export default LcVisOverlay;
