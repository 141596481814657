// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
// File to define types/classes relevant to grouping.
import * as frontendpb from '../proto/frontend/frontend_pb';

import GroupMap, { GroupLeafMap, Groupable } from './GroupMap';
import { NodeType } from './simulationTree/node';

// All geometric entities have a separate EntityGroup/EntityGroupMap so this can be used for other
// non-geometric node types that we want to be groupable. Currently this only works for the
// CameraInfo but can be extended to include other nodes.
export interface NodeGroup extends Groupable {
  item: frontendpb.CameraInfo; // extend with other item types in the future
  nodeType: NodeType;
}

export class NodeGroupMap extends GroupMap<NodeGroup> {
  constructor(existingMap?: NodeGroupMap) {
    super(
      (group, groupMap) => { },
      existingMap,
    );
  }
}

export interface NodeGroupData {
  groupMap: NodeGroupMap;
  leafMap: GroupLeafMap;
}
