// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 12;
export const WavesTripleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="wavesTriple"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M7.676,1.417
           C7.676,1.417 8.602,0.903 8.984,0.727
           C9.722,0.389 10.751,0 11.667,0
           C12.582,0 13.611,0.389 14.35,0.727
           C14.732,0.903 15.063,1.078 15.299,1.209
           C15.417,1.275 15.512,1.33 15.578,1.369
           C15.611,1.389 15.637,1.404 15.655,1.415
           L15.685,1.433
           C15.997,1.628 16.094,2.04 15.9,2.352
           C15.705,2.665 15.294,2.761 14.982,2.566
           L14.963,2.555
           C14.949,2.546 14.927,2.533 14.899,2.516
           C14.842,2.483 14.757,2.434 14.651,2.374
           C14.437,2.255 14.137,2.097 13.793,1.939
           C13.079,1.611 12.275,1.333 11.667,1.333
           C11.059,1.333 10.254,1.611 9.54,1.939
           C9.196,2.097 8.324,2.583 8.324,2.583
           C8.324,2.583 7.399,3.097 7.016,3.273
           C6.278,3.611 5.249,4 4.333,4
           C3.418,4 2.389,3.611 1.65,3.273
           C1.268,3.097 0.937,2.922 0.701,2.791
           C0.583,2.725 0.488,2.67 0.422,2.631
           C0.389,2.611 0.363,2.596 0.345,2.585
           L0.315,2.567
           C0.003,2.372 -0.094,1.96 0.1,1.648
           C0.295,1.335 0.706,1.239 1.018,1.434
           L1.037,1.445
           C1.051,1.454 1.073,1.467 1.101,1.484
           C1.158,1.517 1.243,1.566 1.349,1.626
           C1.563,1.745 1.863,1.903 2.207,2.061
           C2.921,2.389 3.725,2.667 4.333,2.667
           C4.941,2.667 5.746,2.389 6.46,2.061
           C6.804,1.903 7.676,1.417 7.676,1.417Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M7.676,5.417
           C7.676,5.417 8.602,4.903 8.984,4.727
           C9.722,4.389 10.751,4 11.667,4
           C12.582,4 13.611,4.389 14.35,4.727
           C14.732,4.903 15.063,5.078 15.299,5.209
           C15.417,5.275 15.512,5.33 15.578,5.369
           C15.611,5.389 15.637,5.404 15.655,5.415
           L15.685,5.433
           C15.997,5.628 16.094,6.04 15.9,6.352
           C15.705,6.665 15.294,6.761 14.982,6.566
           L14.963,6.555
           C14.949,6.546 14.927,6.533 14.899,6.516
           C14.842,6.483 14.757,6.434 14.651,6.374
           C14.437,6.255 14.137,6.097 13.793,5.939
           C13.079,5.611 12.275,5.333 11.667,5.333
           C11.059,5.333 10.254,5.611 9.54,5.939
           C9.196,6.097 8.324,6.583 8.324,6.583
           C8.324,6.583 7.399,7.097 7.016,7.273
           C6.278,7.611 5.249,8 4.333,8
           C3.418,8 2.389,7.611 1.65,7.273
           C1.268,7.097 0.937,6.922 0.701,6.791
           C0.583,6.725 0.488,6.67 0.422,6.631
           C0.389,6.611 0.363,6.596 0.345,6.585
           L0.315,6.567
           C0.003,6.372 -0.094,5.96 0.1,5.648
           C0.295,5.335 0.706,5.239 1.018,5.434
           L1.037,5.445
           C1.051,5.454 1.073,5.467 1.101,5.484
           C1.158,5.517 1.243,5.566 1.349,5.626
           C1.563,5.745 1.863,5.903 2.207,6.061
           C2.921,6.389 3.725,6.667 4.333,6.667
           C4.941,6.667 5.746,6.389 6.46,6.061
           C6.804,5.903 7.676,5.417 7.676,5.417Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M7.676,9.417
           C7.676,9.417 8.602,8.903 8.984,8.727
           C9.722,8.389 10.751,8 11.667,8
           C12.582,8 13.611,8.389 14.35,8.727
           C14.732,8.903 15.063,9.078 15.299,9.209
           C15.417,9.275 15.512,9.33 15.578,9.369
           C15.611,9.389 15.637,9.404 15.655,9.415
           L15.685,9.433
           C15.997,9.628 16.094,10.04 15.9,10.352
           C15.705,10.665 15.294,10.761 14.982,10.566
           L14.963,10.555
           C14.949,10.546 14.927,10.533 14.899,10.516
           C14.842,10.483 14.757,10.434 14.651,10.374
           C14.437,10.255 14.137,10.097 13.793,9.939
           C13.079,9.611 12.275,9.333 11.667,9.333
           C11.059,9.333 10.254,9.611 9.54,9.939
           C9.196,10.097 8.324,10.583 8.324,10.583
           C8.324,10.583 7.399,11.097 7.016,11.273
           C6.278,11.611 5.249,12 4.333,12
           C3.418,12 2.389,11.611 1.65,11.273
           C1.268,11.097 0.937,10.922 0.701,10.791
           C0.583,10.725 0.488,10.67 0.422,10.631
           C0.389,10.611 0.363,10.596 0.345,10.585
           L0.315,10.567
           C0.003,10.372 -0.094,9.96 0.1,9.648
           C0.295,9.335 0.706,9.24 1.018,9.434
           L1.037,9.445
           C1.051,9.454 1.073,9.467 1.101,9.484
           C1.158,9.517 1.243,9.567 1.349,9.626
           C1.563,9.745 1.863,9.903 2.207,10.061
           C2.921,10.389 3.725,10.667 4.333,10.667
           C4.941,10.667 5.746,10.389 6.46,10.061
           C6.804,9.903 7.676,9.417 7.676,9.417Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
