// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { CurrentView } from '../../lib/componentTypes/context';
import { RecoilProjectKey } from '../../lib/persist';
import { getSimulationParam } from '../../lib/simulationParamUtils';
import { currentViewAtom_DEPRECATED } from '../../state/internal/global/currentView';
import { onGeometryTabSelector } from '../geometry/geometryState';
import { getActiveUrl, meshUrlState } from '../meshState';
import { selectedSolution } from '../selectedSolution';
import { currentConfigSelector } from '../workflowConfig';

/** The active url of the file displayed in the visualizer (both Paraview and LCVis). */
export const activeVisUrlState = selectorFamily<string, RecoilProjectKey>({
  key: 'activeVisUrlState',
  get: (key: RecoilProjectKey) => ({ get }) => {
    // In geometry mode, we will follow a different path to render the tesselation (without files).
    if (get(onGeometryTabSelector)) {
      return '';
    }
    const currentView = get(currentViewAtom_DEPRECATED);
    const analysisView = currentView === CurrentView.ANALYSIS;

    // Active url to load into the visualizer depends on the selected type (geometry or mesh).
    // For analysis tabs, just display the input or solution mesh.
    if (analysisView) {
      const [currentConfig, solution] = get(waitForAll([
        currentConfigSelector(key),
        selectedSolution(key),
      ]));
      const simParam = getSimulationParam(currentConfig);

      // Use the solution url or, if no solution file is available yet,
      // set it to the input url in the simParam.
      const url = solution ? solution.url : simParam.input!.url;
      return url;
    }

    const meshUrl = get(meshUrlState(key.projectId));
    // if not in analysisView, we're in the setup tab.
    // So use either the geometry, mesh, or preview url
    return getActiveUrl(meshUrl);
  },
});

/**
 * @returns the active url of the file displayed in the visualizer (for both Paraview and LCVis).
 * */
export const useActiveVisUrlValue = (
  key: RecoilProjectKey,
) => useRecoilValue(activeVisUrlState(key));
