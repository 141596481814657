// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { Empty } from '@bufbuild/protobuf';
import { atom, useAtomValue } from 'jotai';

import * as rpc from '../../../lib/rpc';
import { staffRoleState } from '../user/frontendRole';

const supportUsersState = atom((get) => {
  const isStaff = get(staffRoleState);
  if (isStaff) {
    return rpc.callRetry(
      'listSupportUsers',
      rpc.client.listSupportUsers,
      new Empty(),
    ).then((response) => response.users).catch(() => undefined);
  }
  return undefined;
});

export function useListSupportUsers() {
  return useAtomValue(supportUsersState);
}
