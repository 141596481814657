// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { useWorkflowFlagValue } from '../../workflowFlag';
import Dropdown from '../Dropdown';
import { FloatingGroup } from '../Pane/FloatingGroup';
import { useGeometryOperationsData } from '../hooks/ribbonToolbar/useGeometryOperationsData';

import { ToolbarButton } from './ToolbarButton';

const GeometryToolbar = () => {
  const geometryOperationsData = useGeometryOperationsData();

  const workflowFlag = useWorkflowFlagValue();

  const mainButtons = geometryOperationsData.map((op) => {
    if (op.items) {
      return (
        <Dropdown
          key={op.key}
          maxWidth={300}
          menuItems={op.items}
          position="below-right"
          toggle={(
            <ToolbarButton
              disabled={op.disabled}
              dropdown
              icon={op.icon}
              locator={op.locator}
              onClick={op.onClick}
              title={op.title}
            />
          )}
        />
      );
    }
    return (
      <ToolbarButton
        disabled={op.disabled}
        icon={op.icon}
        key={op.key}
        locator={op.locator}
        onClick={op.onClick}
        title={op.title}
      />
    );
  });

  return (
    <>
      {!workflowFlag && <FloatingGroup>{mainButtons}</FloatingGroup>}
    </>
  );
};

export default GeometryToolbar;
