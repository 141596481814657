// Copyright 2020-2023 Luminary Cloud, Inc. All Rights Reserved.

import React, { ReactNode } from 'react';

import cx from 'classnames';
import { Helmet } from 'react-helmet';

import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    centered: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'unset',
    },
  }),
  { name: 'BaseLayout' },
);

export interface BaseLayoutProps {
  /**
   * Whether or not to center the layout contents in the window vertically and
   * horizontally.
   */
  centered?: boolean;
  /**
   * String to be displayed in the browser tab.
   */
  title: string;
  children?: ReactNode;
}

/**
 * Basic layout component for app pages, on top of which more complex layout
 * components can be built. Passes a string to `<head>` and applies some basic
 * app-wide styling. We might eventually handle other things here like logging
 * or displaying app-wide UI elements like alerts or toasts.
 */
const BaseLayout = (props: BaseLayoutProps) => {
  const { centered, title } = props;

  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div
        className={cx(classes.root, {
          [classes.centered]: centered,
        })}>
        {props.children}
      </div>
    </>
  );
};

export default BaseLayout;
