// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { Choice } from '../../../../ProtoDescriptor';
import { ParamGroupName, paramGroupDesc } from '../../../../SimulationParamDescriptor';
import { createParamScope } from '../../../../lib/ParamScope';
import assert from '../../../../lib/assert';
import { SelectOptionGroup } from '../../../../lib/componentTypes/form';
import { protoChoicesToSelectOptions } from '../../../../lib/form';
import { SOLID_PRESET_CHOICES } from '../../../../lib/materialUtils';
import { useNodePanel } from '../../../../lib/useNodePanel';
import { useMaterialEntity } from '../../../../model/hooks/useMaterialEntity';
import { useMaterials } from '../../../../model/hooks/useMaterials';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { useEnabledExperiments } from '../../../../recoil/useExperimentConfig';
import { useSimulationParamScope } from '../../../../state/external/project/simulation/paramScope';
import { DataSelect } from '../../../Form/DataSelect';
import LabeledInput from '../../../Form/LabeledInput';
import { CollapsiblePanel } from '../../../Panel/CollapsiblePanel';
import { ParamForm } from '../../../ParamForm';
import Divider from '../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { TempDependentMaterialRemoveParams, useMaterialSolidTempVaryingTabularData } from '../../../hooks/useTabularData';
import { AttributesDisplay } from '../../AttributesDisplay';
import PropertiesSection from '../../PropertiesSection';
import { MaterialVolumes } from '../shared/MaterialVolumes';

const { CUSTOM_MATERIAL_SOLID } = simulationpb.MaterialSolidPreset;

const paramGroup = paramGroupDesc[ParamGroupName.MaterialSolid];

export const MaterialSolidPropPanel = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode } = useSelectionContext();
  assert(!!selectedNode, 'No selected solid material row');

  // == Recoil
  const experimentConfig = useEnabledExperiments();
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // == Hooks
  const propClasses = useCommonTreePropsStyles();
  const { availableSolidPresets } = useMaterials(projectId, workflowId, jobId, readOnly);
  const {
    solid,
    saveSolidMaterial,
    updateSolidPreset,
    hasFixedPreset,
  } = useMaterialEntity(projectId, workflowId, jobId, readOnly, selectedNode.id);
  assert(!!solid, 'No selected solid material');

  const { insertTabularElements } = useMaterialSolidTempVaryingTabularData();

  // == Data
  const currentPreset = solid.materialSolidPreset;
  const readOnlyForm = readOnly || hasFixedPreset;
  const defnPanel = useNodePanel(
    selectedNode.id,
    'definition',
    { defaultExpanded: currentPreset === CUSTOM_MATERIAL_SOLID },
  );

  const selectOptions: SelectOptionGroup<Choice['enumNumber']>[] = availableSolidPresets.map(
    (group) => {
      const options = group.map(({ preset, used }) => {
        const choice = SOLID_PRESET_CHOICES.find((item) => item.enumNumber === preset)!;
        const option = protoChoicesToSelectOptions([choice], currentPreset)[0];
        if (used && preset !== currentPreset) {
          option.disabled = true;
          option.disabledReason = 'This material has already been added to your project';
        }
        return option;
      });
      return {
        options,
      };
    },
  );

  const scope = useMemo(
    () => createParamScope(solid, experimentConfig, paramScope),
    [experimentConfig, paramScope, solid],
  );

  return (
    <div className={propClasses.properties}>
      <AttributesDisplay attributes={[{ label: 'Type', value: 'Solid Material' }]} />
      <Divider />
      <PropertiesSection>
        <LabeledInput label="Material">
          <DataSelect
            asBlock
            disabled={readOnly}
            onChange={(value) => {
              updateSolidPreset(value);
              defnPanel.setExpanded(value === CUSTOM_MATERIAL_SOLID);
            }}
            options={selectOptions}
            size="small"
          />
        </LabeledInput>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={defnPanel.collapsed}
          heading="Definitions"
          onToggle={defnPanel.toggle}>
          <ParamForm<simulationpb.MaterialSolid>
            group={paramGroup}
            insertElement={insertTabularElements}
            key={paramGroup.name}
            onUpdate={saveSolidMaterial}
            paramScope={scope}
            proto={solid}
            readOnly={readOnlyForm}
            removeParams={['MaterialSolidPreset', ...TempDependentMaterialRemoveParams]}
          />
        </CollapsiblePanel>
      </PropertiesSection>
      <Divider />
      <MaterialVolumes materialId={selectedNode.id} />
    </div>
  );
};
