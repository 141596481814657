// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

/* The 'text' utility is home to natural language text processing functions */

export interface ListOptions {
  separator?: string, // defaults to ',' but ';' is not uncommon
  oxford?: boolean, // default to true
  conjunction?: 'and' | 'or', // defaults to 'and'
}

// Return the given string with its first character upper-cased
export const upperFirst: (text: string) => string = (text) => {
  if (text.length) {
    return `${text[0].toLocaleUpperCase()}${text.slice(1)}`;
  }
  return text;
};

// Return the given string with its first character lower-cased
export const lowerFirst: (text: string) => string = (text) => {
  if (text.length) {
    return `${text[0].toLocaleLowerCase()}${text.slice(1)}`;
  }
  return text;
};

// Removes quotes from text, only if they appear at the beginning and end of the
// string
export function unquote(text: string): string {
  if (/^(['"])(.*)\1$/.test(text)) {
    return RegExp.$2;
  }
  return text;
}

// Returns "s" if count is more than one.
export function plural(count: number): string {
  return count > 1 ? 's' : '';
}

// Contructs a list from an array of words.  E.g:
//   [cat, mouse, dog] => "cat, mouse, and dog"
//   [cat, mouse] => " cat and mouse"
//   [cat] => "cat"
export function wordsToList(parts: string[], options?: ListOptions): string {
  const conjunction = options?.conjunction ?? 'and';
  if (parts.length <= 2) {
    return parts.join(` ${conjunction} `);
  }

  const { separator = ',', oxford = true } = options || {};
  const mainSep = `${separator} `;
  const finalSep = `${oxford ? separator : ''} ${conjunction} `;

  return [parts.slice(0, -1).join(mainSep), parts.slice(-1)].join(finalSep);
}

/**
 * Creates a list of words from an array of words, with a maximum number of words in the list
 * to display.
 * e.g. [cat, mouse, dog, bird, fish], maxWords: 3 will return "cat, mouse, dog, and 2 more"
 * @param words the list of words to create a list from
 * @param maxWords the maximum number of words to display from the list
 * @returns a string with the list of words
 */
export function truncatedWordList(words: string[], maxWords: number): string {
  if (words.length <= maxWords) {
    return wordsToList(words);
  }
  return `${words.slice(0, maxWords).join(', ')}, and ${words.length - maxWords} more`;
}

// Lifted directly from:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
// When constructing a regular expresion with a variable (e.g. new RegExp(myVar)), the content
// of the variable may need to be escaped.  For example, if myVar == '.', the regular expression
// will match everything.  If the calling code wants to match on a literal '.', then it may use
// this function to escape the variable's value.
export function escapeRegExp(regexp: string) {
  return regexp.replace(/[/.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

// Turns "firstName" to "First Name" by default, but can change with the transform option. It's
// useful if we want to convert keys to readable labels.
export function camelCaseToWords(
  text: string,
  options?: {
    transform: 'original' | 'lowerFirst' | 'upperFirst' // defaults to upperFirst
  },
): string {
  const { transform = 'upperFirst' } = options || {};
  const words = text.replace(/([a-z])([A-Z])/g, '$1 $2');

  if (transform === 'upperFirst') {
    return words.split(' ').map((word) => upperFirst(word)).join(' ');
  }

  if (transform === 'lowerFirst') {
    return words.toLowerCase();
  }

  return words;
}

/**
 * Returns a snake-case version of both pascal and camel case inputs
 * @param text
 * @returns
 */
export function toSnakeCase(text: string) {
  const withUnderscores = text.replace(/[A-Z]/g, (match, offset) => {
    if (offset) {
      return `_${match}`;
    }
    // If it's the first letter, don't add an underscore
    return match;
  });
  return withUnderscores.toLowerCase();
}

export function toCamelCase(value: string) {
  let newValue = value;
  // Trim leading/trailing non-alphanumeric characters
  newValue = newValue.replace(/^[^A-Z0-9]+/i, '');
  newValue = newValue.replace(/[^A-Z0-9]+$/i, '');

  newValue = newValue.replace(
    /[^A-Za-z0-9]([a-z0-9])/g,
    (match, letter) => letter.toLocaleUpperCase(),
  );

  return lowerFirst(newValue);
}

export function toPascalCase(value: string) {
  return upperFirst(toCamelCase(value));
}

export function conjugateIs(count: number) {
  if (count === 1) {
    return 'is';
  }
  return 'are';
}
