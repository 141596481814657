// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 18;
export const SwapAxesIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="swapAxes"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M23.25,18
           L1.5,18
           C1.094,18 0.742,17.852 0.445,17.555
           C0.148,17.258 0,16.906 0,16.5
           L0,0.75
           C0,0.531 0.07,0.352 0.211,0.211
           C0.352,0.07 0.531,0 0.75,0
           L1.5,0
           C1.719,0 1.898,0.07 2.039,0.211
           C2.18,0.352 2.25,0.531 2.25,0.75
           L2.25,15.75
           L23.25,15.75
           C23.469,15.75 23.648,15.82 23.789,15.961
           C23.93,16.102 24,16.281 24,16.5
           L24,17.25
           C24,17.469 23.93,17.648 23.789,17.789
           C23.648,17.93 23.469,18 23.25,18Z
           M9.652,1.573
           L8.614,2.74
           C10.422,2.721 12.272,3.32 13.707,4.7
           C15.461,6.386 16.233,8.794 15.892,11.06
           C15.892,11.067 15.892,11.074 15.892,11.081
           L17.078,10.026
           C17.244,9.878 17.474,9.788 17.732,9.788
           C18.237,9.788 18.651,10.157 18.651,10.607
           C18.651,10.828 18.55,11.032 18.385,11.188
           L15.625,13.634
           C15.46,13.781 15.23,13.871 14.972,13.871
           C14.724,13.871 14.494,13.781 14.328,13.634
           L11.569,11.188
           C11.403,11.04 11.302,10.836 11.302,10.607
           C11.302,10.157 11.716,9.788 12.222,9.788
           C12.47,9.788 12.7,9.878 12.875,10.026
           L14.061,11.081
           C14.061,11.073 14.062,11.065 14.062,11.057
           L14.057,11.057
           C14.059,11.045 14.062,11.033 14.064,11.021
           C14.068,10.971 14.077,10.921 14.091,10.874
           C14.385,9.143 13.851,7.299 12.511,6.01
           C11.456,4.995 10.104,4.52 8.768,4.569
           C8.713,4.583 8.656,4.59 8.597,4.59
           L9.652,5.776
           C9.8,5.942 9.89,6.172 9.89,6.429
           C9.89,6.935 9.521,7.349 9.071,7.349
           C8.85,7.349 8.646,7.248 8.49,7.082
           L6.044,4.323
           C5.897,4.158 5.807,3.928 5.807,3.67
           C5.807,3.422 5.897,3.192 6.044,3.026
           L8.49,0.267
           C8.638,0.101 8.842,0 9.071,0
           C9.521,0 9.89,0.414 9.89,0.92
           C9.89,1.168 9.8,1.398 9.652,1.573Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
