// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVManipulationMode, LCVObject, LCVType } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';

import { LcvBoxWidgetState } from './LcvClipBoxWidget';
import { LcvWidget } from './LcvWidget';

export class LcvBoxWidget extends LcvWidget {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, sessionHandle, 'box');
  }

  setState(newState: Partial<LcvBoxWidgetState>) {
    Object.keys(newState).forEach((key) => {
      this.setParam(key, LCVType.kLCVDataTypeFloat3, newState[key as keyof LcvBoxWidgetState]);
    });
  }

  getState(): LcvBoxWidgetState {
    return {
      center: this.getProperty('center', LCVType.kLCVDataTypeFloat3),
      size: this.getProperty('size', LCVType.kLCVDataTypeFloat3),
      rotation: this.getProperty('rotation', LCVType.kLCVDataTypeFloat3),
    };
  }

  showControls() {
    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      LCVManipulationMode.kLCVManipulationModeTranslate | // eslint-disable-line no-bitwise
        LCVManipulationMode.kLCVManipulationModeScale,
    );
  }

  hideControls(): void {
    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      LCVManipulationMode.kLCVManipulationModeNone,
    );
  }

  /**
   * Set the callback to be invoked whenever the box widget state changes.
   * @param callback a callback to be invoked whenever the box widget state changes.
   */
  setOnUpdateCallback(callback: ((newState: LcvBoxWidgetState, message?: string) => void) | null) {
    if (!callback) {
      this.setParam('updated_callback', LCVType.kLCVDataTypeFunction, null);
      return;
    }
    const internalCallback = (
      _lcv: LcvModule,
      _session: LCVObject,
      _obj: LCVObject,
      message: string,
    ) => {
      const newState = this.getState();
      callback(newState, message);
    };
    this.setParam('updated_callback', LCVType.kLCVDataTypeFunction, internalCallback);
  }
}
