// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as lcmeshpb from '../proto/lcn/lcmesh_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';
import { DisplayProps, TreeNode, TreeNodeParam, TreeNodeType } from '../pvproto/ParaviewRpc';

import * as Vector from './Vector';
import * as random from './random';

// Standard messages shown when Paraview client is connecting
export const CONNECTING_MESSAGE = 'Connecting to Luminary3D';

export function updateTreeNodes(
  root: TreeNode,
  callback: (node: TreeNode) => TreeNode,
): TreeNode {
  let newChild: TreeNode[] | null = null;
  root.child.forEach((child: TreeNode, i: number) => {
    const node = updateTreeNodes(child, callback);
    if (node === child) {
      return;
    }
    if (!newChild) {
      newChild = [...root.child];
    }
    newChild[i] = node;
  });
  let newRoot = root;
  if (newChild) {
    newRoot = { ...root, child: newChild };
  }
  return callback(newRoot);
}
/**
 * Traverse all nodes in the tree and execute the callback
 * on each node.
 */
export function traverseTreeNodes(
  root: TreeNode,
  callback: (node: TreeNode) => void,
): void {
  callback(root);
  root.child.forEach((child: TreeNode, i: number) => {
    traverseTreeNodes(child, callback);
  });
}

/**
 * Find a node in the tree by node id. Returns null if not found.
 */
export function findNode(root: TreeNode, nodeId: string): TreeNode | null {
  let foundNode: TreeNode | null = null;
  const isTheNodeImLookingFor = (node: TreeNode) => {
    if (node.id === nodeId) {
      foundNode = node;
    }
  };

  // This will traverse through all nodes and not return early.  Our trees are
  // never deep (maybe 4ish total nodes) so this is not bad.
  if (root) {
    traverseTreeNodes(root, isTheNodeImLookingFor);
  }
  return foundNode;
}

let ID_SEQ = 88;

/**
 * Generate a random string that can be used as a TreeNode.id value.
 */
export function newNodeId(typ: TreeNodeType): string {
  ID_SEQ += 1;
  const rand = random.string(32);
  return `${typ}:${ID_SEQ}:${rand}`;
}

// Returns the base node name (no number at the end) for a given type. The type
// is actually a string that Paraview uses for the filters. This name is what
// we want the users to see. Usually, the two strings are the same, but not
// always.
export function baseNodeName(type: TreeNodeType): string {
  if (type === 'Glyph') {
    return 'Vector';
  } if (type === 'Contour') {
    return 'Isosurface';
  }
  return type;
}

/* Returns a new node name consisting of the baseName plus a unique number.
* The unique number is determined by searching through all existing node names.
* Valid names we consider in our search look like this: baseName5-suffix.
* They consist of three parts: the baseName, a number, and a suffix.
* The suffix is optional, since we believe users may optionally add this information.
* The suffix begins when any of these special characters appear ' ', '-', "_", "[", "(", "{".
* The new number is one more than the largest valid number found in the search.
* If there is no separator, the node name and corresponding number
* is not considered, eg. basename5suffix will not consider the number 5 in the search.
*/
export function newNodeName(typ: TreeNodeType, root: TreeNode | null): string {
  const baseName = baseNodeName(typ);
  let maxSeq = -1;
  const rec = (node: TreeNode) => {
    if (node.name.startsWith(baseName)) {
      const substr = node.name.substring(baseName.length);

      const separatorRegex = /[\s\-_({[]/;
      const index = substr.search(separatorRegex);
      const numericPart = index !== -1 ? substr.substring(0, index) : substr;

      const digitRegex = /^\d+$/;
      const onlyDigits = digitRegex.test(numericPart);

      // If the substring prior to the separator is only digits, we can assume it has been generated
      // by our system. If any other characters exist, it might have been custom named by the user.
      // If the user changed the numerical part to be a larger number,
      if (onlyDigits) {
        const seq = Number.parseInt(numericPart, 10);
        if (!Number.isNaN(seq)) {
          maxSeq = Math.max(seq, maxSeq);
        }
      }
    }
    node.child.forEach(rec);
  };
  if (root) {
    rec(root);
  }
  return `${baseName}${maxSeq + 1}`;
}

/**
 * Creates a new tree node with default settings.
 */
export function newNode(
  param: TreeNodeParam,
  root: TreeNode | null,
  visible: boolean,
  displayProps: DisplayProps | null,
): TreeNode {
  const type = param.typ as TreeNodeType;
  return {
    name: newNodeName(type, root),
    id: newNodeId(type),
    param,
    paramSeq: 0,
    pointData: [],
    bounds: null,
    visible,
    child: [],
    displayProps,
  };
}

export function mainGridName(zone: lcmeshpb.ZoneMetadata): string {
  // This name must match the MainGridName function in vtkSbmeshReader.
  return `${zone.name}/cv`;
}
export interface RawCameraInfo {
  center: [number, number, number];
  focal: [number, number, number];
  up: [number, number, number];
  position: [number, number, number];
  parallelProjection: boolean;
  scale: number;
  angle: number; // zoom rate. > 0.
}

// Convert from raw camera info object received from server to
// type we use in our paraview protocol.
export function toCameraState(rawInfo: RawCameraInfo): ParaviewRpc.CameraState {
  return {
    center: Vector.listToPv(rawInfo.center),
    focal: Vector.listToPv(rawInfo.focal),
    up: Vector.listToPv(rawInfo.up),
    position: Vector.listToPv(rawInfo.position),
    parallelProjection: rawInfo.parallelProjection,
    scale: rawInfo.scale,
    angle: rawInfo.angle || 0,
  };
}

// Some basic checks to assert if the input viewState object resembles ParaviewRpc.ViewState.
// This is needed because ViewState is not a proto message and it has changed over time.
// So it's casting to ParaviewRpc.ViewState may fail while still being a valid ViewState
// after using upgradePvproto.
// NOTE: this is a very lazy check, please don't use it in production.
export function lazyValidViewState(viewState: any): boolean {
  const basicStruct = viewState.path &&
    viewState.data &&
    viewState.surfaceData &&
    viewState.blocks &&
    viewState.root &&
    viewState.attrs &&
    viewState.attrs.viewAttrsVersion;
  return basicStruct && viewState.attrs.viewAttrsVersion <= ParaviewRpc.PVPROTO_VIEW_ATTRS_VERSION;
}

// Whether two viewStates are similar enough such that if we apply one the viewState
// to the current UI configuration and soln/mesh url we don't crash.
// NOTE: this is a very lazy test and should not be used in production. We should
// check the mesh sha + bounds or something similar to be 100% sure that two viewState
// refer to the same configuration.
export function lazyViewStatesSimilarEnough(
  viewState1: ParaviewRpc.ViewState,
  viewState2: ParaviewRpc.ViewState,
) {
  // Check if the viewStates have the same blocks.
  const sameBlocks = (viewState1.blocks.length === viewState2.blocks.length &&
    viewState1.blocks.every((value, index) => value === viewState2.blocks[index]));

  // Check if the root url have the same extension, so that we don't apply setup view states
  // on simulation view states and viceversa.
  const ext1 = (viewState1.root.param as ParaviewRpc.ReaderParam).url.split('.').pop();
  const ext2 = (viewState2.root.param as ParaviewRpc.ReaderParam).url.split('.').pop();
  const sameFileType = ext1 === ext2;

  // Check.
  return sameBlocks && sameFileType;
}

// Returns the component value of a given ParaviewRpc.Vector3.
function vectorComponent(vector: ParaviewRpc.Vector3, dim: number): number {
  switch (dim) {
    case 0:
      return vector.x;
    case 1:
      return vector.y;
    case 2:
      return vector.z;
    default:
      throw Error('Incompatible vector component');
  }
}

// Returns the bounds center.
export function boundsCenter(bounds: ParaviewRpc.Bounds): ParaviewRpc.Vector3 {
  return {
    x: (bounds[1] + bounds[0]) * 0.5,
    y: (bounds[3] + bounds[2]) * 0.5,
    z: (bounds[5] + bounds[4]) * 0.5,
  };
}

// Returns the maximum length of the bounds principal axes.
export function boundsMaxLength(bounds: ParaviewRpc.Bounds) {
  let maxLength = 0;
  for (let dim = 0; dim < 3; dim += 1) {
    maxLength = Math.max(maxLength, bounds[2 * dim + 1] - bounds[2 * dim]);
  }
  return maxLength;
}

// Scales a bounding box. If any of the axis length is too small, set it to a
// fraction of the maximum length. Also, scale the bounds with a fraction > 1 to
// avoid z-buffer fighting issues. This code is very similar to pqAdjustBounds
// of ParaView.
export function boundsScale(bounds: ParaviewRpc.Bounds, scale: number) {
  const SMALL_FLOATING_POINT = 1e-20;
  const SCALE_SMALL_AXES = 1.05;
  const newBounds: ParaviewRpc.Bounds = [...bounds];

  // Max bounds axis length.
  const maxLength = boundsMaxLength(bounds);

  // If all the axes are very small, simply return the original bounds.
  if (maxLength < SMALL_FLOATING_POINT) {
    return newBounds;
  }

  // Scale the axes.
  const center = boundsCenter(bounds);
  for (let dim = 0; dim < 3; dim += 1) {
    const mid = vectorComponent(center, dim);

    // Artificially modify the zero-sized axes length to avoid having bounds with
    // zero-sized axes.
    let length = newBounds[2 * dim + 1] - newBounds[2 * dim];
    if (length < SMALL_FLOATING_POINT) {
      length = maxLength * (SCALE_SMALL_AXES - 1);
    }

    // Scale based on the axes length.
    newBounds[2 * dim] = mid - scale * length / 2;
    newBounds[2 * dim + 1] = mid + scale * length / 2;
  }

  return newBounds;
}

// Minimum radius of a sphere centered at the bounds center such that it's inscribed
// inside the bounds.
export function boundsSphereRadius(bounds: ParaviewRpc.Bounds) {
  const maxLength = boundsMaxLength(bounds);
  return maxLength / 2;
}

// Return a new visibility map with all surfaces in surfaceIds hidden.
export function hideSurfaces(
  surfaceIds: string[],
  visibility: Map<string, boolean>,
) {
  return surfaceIds.reduce((map, id) => (map.set(id, false)), new Map(visibility));
}
