// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const StreamVorticesIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="streamVortices"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M6.51,0
           C5.254,0 4.111,0.524 3.297,1.357
           C3.031,1.609 2.923,1.987 3.018,2.342
           C3.112,2.697 3.393,2.971 3.749,3.057
           C4.106,3.143 4.481,3.027 4.726,2.754
           C5.182,2.287 5.806,2 6.51,2
           C7.902,2 9.009,3.107 9.009,4.5
           C9.009,5.893 7.902,7 6.51,7
           L1.014,7
           C0.653,6.995 0.318,7.184 0.136,7.496
           C-0.045,7.807 -0.045,8.193 0.136,8.504
           C0.318,8.816 0.653,9.005 1.014,9
           L6.51,9
           C8.982,9 11.008,6.973 11.008,4.5
           C11.008,2.027 8.982,0 6.51,0Z
           M18.503,2
           C16.887,2 15.421,2.705 14.416,3.822
           C14.168,4.086 14.083,4.464 14.192,4.809
           C14.302,5.154 14.589,5.413 14.944,5.486
           C15.299,5.559 15.665,5.434 15.901,5.16
           C16.543,4.447 17.465,4 18.503,4
           C20.447,4 22.001,5.556 22.001,7.5
           C22.001,9.444 20.447,11 18.503,11
           L1.014,11
           C0.653,10.995 0.318,11.184 0.136,11.496
           C-0.045,11.807 -0.045,12.193 0.136,12.504
           C0.318,12.816 0.653,13.005 1.014,13
           L18.503,13
           C21.527,13 24,10.526 24,7.5
           C24,4.474 21.527,2 18.503,2Z
           M1.014,15
           C0.653,14.995 0.318,15.184 0.136,15.496
           C-0.045,15.807 -0.045,16.193 0.136,16.504
           C0.318,16.816 0.653,17.005 1.014,17
           L15.505,17
           C16.897,17 18.004,18.107 18.004,19.5
           C18.004,20.893 16.897,22 15.505,22
           C14.8,22 14.177,21.713 13.721,21.246
           C13.476,20.973 13.101,20.857 12.744,20.943
           C12.387,21.029 12.107,21.303 12.012,21.658
           C11.918,22.013 12.025,22.391 12.292,22.643
           C13.105,23.476 14.249,24 15.505,24
           C17.977,24 20.002,21.973 20.002,19.5
           C20.002,17.027 17.977,15 15.505,15
           L1.014,15Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
