import { atom, useRecoilState } from 'recoil';

import { getLocalStorageData } from '../../lib/browserStorage';
import { localStorageEffect } from '../../lib/persist';

export type BoxInputRepresentationType = 'center' | 'corners';
const boxReprKey = 'boxRepresentationPref';

/**
 * The user's preference for how to represent a box in the BoxInput component.
 */
const boxRepresentationPref = atom<BoxInputRepresentationType>({
  key: boxReprKey,
  default: getLocalStorageData(boxReprKey, 'center'),
  effects: [
    localStorageEffect(boxReprKey),
  ],
});

export const useBoxRepresentationPref = () => useRecoilState(boxRepresentationPref);
