// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ForwardedRef, MutableRefObject, forwardRef } from 'react';

import { useLcvisContextMenuItems, useLcvisMenuSettings } from '../../../recoil/lcvis/lcvisMenuSettings';
import { CommonMenu } from '../../Menu/CommonMenu';

const ContextMenu = forwardRef((props, ref: ForwardedRef<HTMLCanvasElement | null>) => {
  const [menuSettings, setMenuSettings] = useLcvisMenuSettings();
  // Generate the context menu items.
  const menuItems = useLcvisContextMenuItems();

  return (
    <CommonMenu
      anchorEl={(ref as MutableRefObject<HTMLCanvasElement | null>).current}
      closeOnSelect
      menuItems={menuItems}
      onClose={() => {
        setMenuSettings((old) => ({ ...old, menuOpen: false, clickedId: '' }));
      }}
      open={menuSettings.menuOpen}
      position="below-right"
      positionTransform={{
        left: menuSettings.transform.left,
        top: menuSettings.transform.top ?
          (
            menuSettings.transform.top - (
              (ref as MutableRefObject<HTMLCanvasElement | null>).current?.scrollHeight ?? 0
            )
          ) : 0,
      }}
    />
  );
});

export default ContextMenu;
