// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

/** Designed to be used in a PropPanel component, the GeometryMotion component allows for the
 * configuration of motion frames from the point of view of a geometry element (a surface or
 * volume). */

import React, { useCallback, useMemo } from 'react';

import { colors } from '../../../../lib/designSystem';
import { EntityGroupMap } from '../../../../lib/entityGroupMap';
import { getSurfaceGroupSurfaces } from '../../../../lib/entityGroupUtils';
import { intersectSet, intersects, subtractSet } from '../../../../lib/lang';
import {
  appendFrameGeometry,
  assignFrameOrigin,
  createFrame,
  frameAuxiliaryIcons,
  getAllAttachedDomains,
  getAllAttachedSurfaceIds,
  getDefaultAttachableGeometry,
  isFrameGlobal,
  orderedFrames,
  removeFrameGeometry,
} from '../../../../lib/motionDataUtils';
import { NodeType } from '../../../../lib/simulationTree/node';
import { useMeshGeometry } from '../../../../lib/useMeshGeometry';
import { findStaticVolumeById, getVolumeName } from '../../../../lib/volumeUtils';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { useEntityGroupData } from '../../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../../recoil/geometry/geometryTagsState';
import { StaticVolume, useStaticVolumes } from '../../../../recoil/volumes';
import { pushConfirmation, useSetConfirmations } from '../../../../state/internal/dialog/confirmations';
import { createStyles, makeStyles } from '../../../Theme';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { ModelCreator, ModelData, ModelSelector } from '../../../controls/ModelSelector';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { CubeOutlineIcon } from '../../../svg/CubeOutlineIcon';
import { CubeSolidIcon } from '../../../svg/CubeSolidIcon';
import { LabeledSection } from '../../LabeledSection';

const useListStyles = makeStyles(
  () => createStyles({
    list: {
      fontSize: '13px',
      color: colors.lowEmphasisText,
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
  }),
  { name: 'GeometryList' },
);

const { CONSTANT_ANGULAR_MOTION, CONSTANT_TRANSLATION_MOTION, NO_MOTION } = simulationpb.MotionType;

const creatorTemplate = [
  { id: 'rotation', label: 'Rotation', type: CONSTANT_ANGULAR_MOTION },
  { id: 'translation', label: 'Translation', type: CONSTANT_TRANSLATION_MOTION },
  { id: 'frame', label: 'Frame', type: NO_MOTION },
];

export interface GeometryListProps {
  volumeIds: string[];
  surfaceIds: string[];
  entityGroupMap: EntityGroupMap;
  staticVolumes: StaticVolume[];
}

// Display a list of geometry elements (with icons) for use in a confirmation dialog
export const GeometryList = (props: GeometryListProps) => {
  const { entityGroupMap, staticVolumes, surfaceIds, volumeIds } = props;

  const classes = useListStyles();

  const volumeNames = volumeIds.map(
    (volumeId) => getVolumeName(volumeId, staticVolumes, entityGroupMap),
  );
  const surfaceNames = surfaceIds.map(
    (surfaceId) => entityGroupMap.get(surfaceId).name,
  );

  return (
    <ul className={classes.list}>
      {volumeNames.map((name) => (
        <li className={classes.listItem} key={name}>
          <CubeSolidIcon maxHeight={12} maxWidth={12} />
          <span>{name}</span>
        </li>
      ))}
      {surfaceNames.map((name) => (
        <li className={classes.listItem} key={name}>
          <CubeOutlineIcon maxHeight={12} maxWidth={12} />
          <span>{name}</span>
        </li>
      ))}
    </ul>
  );
};

export type GeometryNodeType = NodeType.VOLUME | NodeType.SURFACE | NodeType.SURFACE_GROUP;

export interface GeometryMotionProps {
  nodeId: string;
  nodeType: GeometryNodeType;
}

interface Conflicts {
  volume: string[];
  surface: string[];
}

export const GeometryMotion = (props: GeometryMotionProps) => {
  // Props
  const { nodeId, nodeType } = props;

  // Hooks and contexts
  const { jobId, projectId, readOnly, workflowId } = useProjectContext();
  const { setSelection, setScrollTo } = useSelectionContext();
  const { calculateCentroid } = useMeshGeometry(projectId);
  const { simParam, saveParamAsync } = useSimulationConfig();

  // Recoil state
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId);
  const setConfirmStack = useSetConfirmations();
  const geometryTags = useGeometryTags(projectId);

  // Non-global frames
  const frames = useMemo(
    () => simParam.motionData.filter(({ frameId }) => !isFrameGlobal(simParam, frameId)),
    [simParam],
  );

  // Frames to list in drop down, converted to ModelData for use in ModelSelector
  const modelFrames = useMemo(
    () => frames.map((frame) => ({
      model: frame,
      id: frame.frameId,
      label: frame.frameName,
      auxIcon: frameAuxiliaryIcons(simParam, frame),
    } as ModelData<simulationpb.MotionData>)),
    [frames, simParam],
  );

  // Which item in the ModelSelector, if any, is currently selected
  const selectedFrameIds = useMemo(() => {
    const frameIds = new Set<string | undefined>();

    orderedFrames(simParam).frames.forEach((frame, i) => {
      if (i === 0) {
        // Skip the global frame
        return;
      }

      const attachedBoundaries = new Set(frame.attachedBoundaries);
      const attachedDomains = new Set(frame.attachedDomains);

      const { frameId } = frame;
      switch (nodeType) {
        case NodeType.VOLUME: {
          const volume = findStaticVolumeById(nodeId, staticVolumes);
          if (volume?.domain && attachedDomains.has(volume.domain)) {
            frameIds.add(frameId);
          }
          break;
        }
        case NodeType.SURFACE: {
          if (attachedBoundaries.has(nodeId)) {
            frameIds.add(frameId);
          }
          break;
        }
        case NodeType.SURFACE_GROUP: {
          // Here, we treat the individual leaf surfaces
          const surfaceIds = getSurfaceGroupSurfaces(nodeId, entityGroupData);

          if (intersects(surfaceIds, attachedBoundaries)) {
            // If one or more surfaces in the surface group is attached to this frame, add it to the
            // list
            frameIds.add(frameId);
          }
          if (subtractSet(surfaceIds, attachedBoundaries)) {
            // If one or more surfaces in the surface group is NOT attached to this frame, add
            // `undefined` to the list, so that ModelSelector can handle mixed selections.
            frameIds.add(undefined);
          }
          break;
        }
        default: // no default
      }
    });

    if (frameIds.size === 1 && frameIds.has(undefined)) {
      // We don't want to pass a list of only 'undefined' values.  This means none of the surfaces
      // is assigned to a frame, so we should just pass an empty list.
      return [];
    }

    return [...frameIds];
  }, [entityGroupData, nodeId, nodeType, simParam, staticVolumes]);

  // Handlers
  const createNewFrame = useCallback(async (
    motionType: simulationpb.MotionType,
    volumeIds: Set<string>,
    surfaceIds: Set<string>,
  ) => {
    const newId = await saveParamAsync((newParam) => {
      const frame = createFrame(newParam, motionType);
      const { frameId } = frame;

      // Initialize origin to geometry's centroid
      const centroid = calculateCentroid([...volumeIds], [...surfaceIds]);
      assignFrameOrigin(newParam, frameId, centroid);

      // Append surfaces and volumes to frame
      appendFrameGeometry(
        newParam,
        frameId,
        volumeIds,
        surfaceIds,
        staticVolumes,
        geometryTags,
        entityGroupData,
      );
      return frameId;
    });

    setSelection([newId]);
    setScrollTo({ node: newId });
  }, [
    saveParamAsync,
    setSelection,
    setScrollTo,
    calculateCentroid,
    staticVolumes,
    geometryTags,
    entityGroupData,
  ]);

  // Queue a confirmation whenever before overwriting frame attachments for geometry elements
  const queueFrameAttachment = useCallback((conflicts: Conflicts, onContinue: () => void) => {
    pushConfirmation(setConfirmStack, {
      onContinue,
      title: 'Confirm Geometry Reassignment',
      children: (
        <div>
          Motion has already been assigned for some related geometry.  Confirming this change will
          replace previous motion definitions for the following geometry:
          <GeometryList
            entityGroupMap={entityGroupData.groupMap}
            staticVolumes={staticVolumes}
            surfaceIds={conflicts.surface}
            volumeIds={conflicts.volume}
          />
        </div>
      ),
    });
  }, [entityGroupData, setConfirmStack, staticVolumes]);

  // Memoize attachable geometry associated with the node.  For a volume, the attachable geometry is
  // the volume and every surface in the volume.  For a surface, the attachable geometry is the
  // surface's parent volume and every surface in that volume.
  const attachableGeometry = useMemo(
    () => getDefaultAttachableGeometry(new Set([nodeId]), entityGroupData, staticVolumes),
    [entityGroupData, nodeId, staticVolumes],
  );

  // Return lists of any attachable geometry elements that are already attached to a motion frame
  const findAttachmentConflicts = useCallback((forFrameId?: string): Conflicts => {
    // If forFrameId is provided, then geometry elements already assigned to that frame aren't
    // conflicts, so exclude the frame.
    const excludeFrameIds = forFrameId ? [forFrameId] : undefined;

    // Get a list of geometry that's already attached to a frame
    const surfaceIdsWithMotion = getAllAttachedSurfaceIds(
      simParam,
      { excludeFrameIds },
      geometryTags,
      entityGroupData,
    );
    const volumeIdsWithMotion = getAllAttachedDomains(simParam, { excludeFrameIds }, geometryTags);

    // Now check if the attachable geometry conflicts with existing frame assignments
    return {
      volume: [...intersectSet(volumeIdsWithMotion, attachableGeometry.volumes)],
      surface: [...intersectSet(surfaceIdsWithMotion, attachableGeometry.surfaces)],
    };
  }, [attachableGeometry, simParam, geometryTags, entityGroupData]);

  const handleNewMotionFrame = useCallback(async (type: simulationpb.MotionType) => {
    const doSave = () => createNewFrame(
      type,
      attachableGeometry.volumes,
      attachableGeometry.surfaces,
    );

    // If there are any conflicts, ask the user to confirm.  Otherwise, create the frame and assign
    // the geometry without interruption.
    const conflicts = findAttachmentConflicts();
    if (conflicts.volume.length || conflicts.surface.length) {
      queueFrameAttachment(conflicts, doSave);
    } else {
      await doSave();
    }
  }, [attachableGeometry, createNewFrame, findAttachmentConflicts, queueFrameAttachment]);

  const creators = useMemo<ModelCreator[]>(() => creatorTemplate.map(({ id, label, type }) => ({
    id,
    label,
    onClick: () => handleNewMotionFrame(type),
  })), [handleNewMotionFrame]);

  const updateFrameAttachment = useCallback(async (
    frameId: string,
    volumeIds: Set<string>,
    surfaceIds: Set<string>,
  ) => {
    await saveParamAsync(
      (newParam) => appendFrameGeometry(
        newParam,
        frameId,
        volumeIds,
        surfaceIds,
        staticVolumes,
        geometryTags,
        entityGroupData,
      ),
    );
  }, [saveParamAsync, staticVolumes, geometryTags, entityGroupData]);

  // When a frame is selected from the ModelSelector, attach the geometry element (referenced in
  // props nodeId and nodeType).
  const attachFrame = useCallback(async (model: simulationpb.MotionData) => {
    const { frameId } = model;
    const doSave = () => updateFrameAttachment(
      frameId,
      attachableGeometry.volumes,
      attachableGeometry.surfaces,
    );
    // If there are any conflicts, ask the user to confirm.  Otherwise, create the frame and assign
    // the geometry without interruption.
    const conflicts = findAttachmentConflicts(frameId);
    if (conflicts.volume.length || conflicts.surface.length) {
      queueFrameAttachment(conflicts, doSave);
    } else {
      await doSave();
    }
  }, [attachableGeometry, findAttachmentConflicts, updateFrameAttachment, queueFrameAttachment]);

  // When frame selection is cleared in the ModelSelector, detach geometry element from its motion
  // frame.
  const detachFrame = useCallback(async () => {
    await saveParamAsync((newParam) => {
      const nodeIdSet = new Set([nodeId]);
      const emptySet = new Set<string>();

      switch (nodeType) {
        case NodeType.VOLUME: {
          removeFrameGeometry(
            newParam,
            nodeIdSet,
            emptySet,
            staticVolumes,
            geometryTags,
            entityGroupData,
          );
          break;
        }
        case NodeType.SURFACE: {
          removeFrameGeometry(
            newParam,
            emptySet,
            nodeIdSet,
            staticVolumes,
            geometryTags,
            entityGroupData,
          );
          break;
        }
        case NodeType.SURFACE_GROUP: {
          const surfaceIds = getSurfaceGroupSurfaces(nodeId, entityGroupData);
          removeFrameGeometry(
            newParam,
            emptySet,
            surfaceIds,
            staticVolumes,
            geometryTags,
            entityGroupData,
          );
          break;
        }
        default: // no default
      }
    });
  }, [entityGroupData, geometryTags, nodeId, nodeType, saveParamAsync, staticVolumes]);

  return (
    <LabeledSection label="Frames & Motion">
      <ModelSelector
        creators={creators}
        disabled={readOnly}
        icon={{ name: 'triAxes' }}
        models={modelFrames}
        onSelect={({ model }) => attachFrame(model)}
        onUnselect={detachFrame}
        selected={selectedFrameIds}
        tooltip={readOnly ? '' : 'Assign this volume to a motion frame or create a new one'}
      />
    </LabeledSection>
  );
};
