// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { CommonMenuListItem, CommonMenuPositionTransform } from '../../lib/componentTypes/menu';
import { Suggestion, SuggestionType } from '../../lib/expressionInput/AutocompleteEditor';
import { CommonMenu } from '../Menu/CommonMenu';

export interface AutocompleteSuggestionsMenuProps {
  suggestions: Suggestion[];
  selectedIndex?: number;
  onClick: (index: number) => void;
  onClose?: () => void;
  anchorEl: Element | null;
  position: CommonMenuPositionTransform;
}

export function AutocompleteSuggestionsMenu(props: AutocompleteSuggestionsMenuProps) {
  const { suggestions, selectedIndex, onClick, onClose, anchorEl, position } = props;

  const items: CommonMenuListItem[] = suggestions.map((suggestion, index) => ({
    label: suggestion.value,
    onClick: () => onClick(index),
    selected: selectedIndex === index,
    startIcon: {
      name: suggestion.type === SuggestionType.VARIABLE ? 'hash' : 'function',
    },
  }));

  return (
    <CommonMenu
      anchorEl={anchorEl}
      disableFocus
      menuItems={items}
      onClose={onClose ?? (() => { })}
      open={!!suggestions.length}
      positionTransform={position}
    />
  );
}
