// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { LCVClipType, LCVType } from '@luminarycloudinternal/lcvis';
import { deepEqual } from 'fast-equals';

import { RgbColor } from '../../../designSystem';
import { LcvModule } from '../../types';

import { LcvFilter } from './LcvFilter';
import { Box, ClipFunction, ClipState, Plane } from './filterUtils';

export const isBoxClip = (fcn: ClipFunction): fcn is Box => (fcn as Box).position !== undefined;
export const isPlaneClip = (
  fcn: ClipFunction,
): fcn is Plane => (fcn as Plane).origin !== undefined;

/**
 * A filter for clipping a mesh. The clip can be either a box or a plane, as specified by its state.
 */
export class LcvClip extends LcvFilter {
  state: ClipState;
  parent: LcvFilter;
  color: RgbColor | null = null;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    workspaceHandle: number,
    id: string,
    parent: LcvFilter,
    initialState: ClipState,
  ) {
    super(
      lcv,
      lcv.newFilter(
        sessionHandle,
        workspaceHandle,
        'clip',
        `clip_${id}`,
        0,
      ).filter,
      sessionHandle,
      workspaceHandle,
      'clip',
      id,
    );

    this.parent = parent;
    this.state = initialState;

    this.lcv.connectFilters(sessionHandle, workspaceHandle, parent.handle, this.handle);

    this.updateParams();
  }

  /**
   * Update the mesh_extra field command to be compatible with the new filter state.
   */
  updateParams() {
    const { smooth, invert, clipFunction } = this.state;

    this.setParam('smooth', LCVType.kLCVDataTypeUint, smooth ? 1 : 0);
    this.setParam('inverted', LCVType.kLCVDataTypeUint, invert ? 1 : 0);

    if (isBoxClip(clipFunction)) {
      // Set the box clip params
      const { position, rotation, length } = clipFunction;
      this.setParam('clip_type', LCVType.kLCVDataTypeUint, LCVClipType.kLCVClipTypeBox);
      this.setParam('origin', LCVType.kLCVDataTypeFloat3, position);
      this.setParam('size', LCVType.kLCVDataTypeFloat3, length);
      this.setParam('rotation', LCVType.kLCVDataTypeFloat3, rotation);
    } else if (isPlaneClip(clipFunction)) {
      const { origin, normal } = clipFunction;
      this.setParam('clip_type', LCVType.kLCVDataTypeUint, LCVClipType.kLCVClipTypePlane);
      this.setParam('origin', LCVType.kLCVDataTypeFloat3, origin);
      this.setParam('plane_normal', LCVType.kLCVDataTypeFloat3, normal);
    }
  }

  setState(newState: ClipState) {
    if (deepEqual(this.state, newState)) {
      return;
    }
    this.state = newState;
    return this.updateParams();
  }
}
