// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/lcstatus/levels.proto (package luminary.proto.lcstatus, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Level indicates the severity of a particular status.
 *
 * @generated from enum luminary.proto.lcstatus.Level
 */
export const Level = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.lcstatus.Level",
  [
    {no: 0, name: "INFO"},
    {no: 1, name: "WARN"},
    {no: 2, name: "ERROR"},
  ],
);

