// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const AirplaneIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="airplane"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M24,2.25
           C24,1.005 22.995,0 21.75,0
           C21.135,0 20.565,0.255 20.16,0.66
           L14.865,5.955
           L3,1.5
           L1.5,4.5
           L10.395,10.425
           L5.82,15
           L1.5,15
           L0,16.5
           L4.5,19.5
           L7.5,24
           L9,22.5
           L9,18.18
           L13.575,13.605
           L19.5,22.5
           L22.5,21
           L18.045,9.135
           L23.34,3.84
           C23.745,3.435 24,2.865 24,2.25Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
