// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import './LoadingEllipsis.scss';

export const LoadingEllipsis = () => (
  <>
    <span className="loading-ellipsis1">.</span>
    <span className="loading-ellipsis2">.</span>
    <span className="loading-ellipsis3">.</span>
  </>
);
