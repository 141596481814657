// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily } from 'recoil';

import { connectionKey, paraviewClientState } from '../lib/ParaviewClient';
import { fromBigInt } from '../lib/number';
import * as plotpb from '../proto/plots/plots_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';

import { plotNodesState } from './plotNodes';

type plotSelectorKey = {
  projectId: string,
  workflowId: string,
  jobId: string,
  plotId: string,
  plotInfoString: string,
}

function createPlotParams(xyPlot: plotpb.PlotSettings_XYPlot | undefined): ParaviewRpc.PlotParam {
  return {
    typ: ParaviewRpc.TreeNodeType.PLOT,
    nodeIds: xyPlot?.dataIds || [],
    param: {
      typ: 'ScatterPlot',
      // The extents of the scalar quantity.
      range: [
        xyPlot?.yAxisRange?.rangeStart!,
        xyPlot?.yAxisRange?.rangeEnd!,
      ],
      // Variable to plot.
      quantity: {
        // 'Density', etc.
        displayDataName: xyPlot?.yAxis?.displayDataName || '',
        // Used to select vector components.
        displayDataNameComponent: fromBigInt(xyPlot?.yAxis?.displayDataNameComponent || 0),
      },
    },
  };
}

export const plotDataSelector = selectorFamily<
  ParaviewRpc.PlotData | null,
  plotSelectorKey
>({
  key: 'plotDataState',
  get: (key: plotSelectorKey) => async ({ get }) => {
    const connKey = get(connectionKey({ ...key }));
    const clientState = get(paraviewClientState(connKey));
    const client = clientState?.client;

    // Find the plot with the passed id and retrieve RPC params from that plot's proto
    const plotNodes = get(plotNodesState(key.projectId));
    const node = plotNodes.plots.find((plotNode) => plotNode.id === key.plotId);
    const xyPlot = node?.plot.case === 'xyPlot' ? node.plot.value : undefined;
    const plotParam = createPlotParams(xyPlot);

    // Check param values to make sure we don't call an rpc with invalid parameters
    // Also make sure client exists
    if (client) {
      return ParaviewRpc.getplotdata(client, plotParam);
    }
    return null;
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});
