// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, forwardRef } from 'react';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';

const useGroupStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    gap: '1px',
    alignItems: 'center',
    borderRadius: '4px',
    backgroundColor: colors.neutral150,
  },
}), { name: 'FloatingGroup' });

export interface FloatingGroupProps {
  children: ReactNode;
}

export const FloatingGroup = forwardRef<HTMLDivElement, FloatingGroupProps>((props, ref) => {
  const classes = useGroupStyles();

  return (
    <div className={classes.root} ref={ref}>
      {props.children}
    </div>
  );
});
