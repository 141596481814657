// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { DefaultValue, atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';

export type LcvisMeasureStateType = {
  // If this widget is active or not.
  active: boolean;
  // State of the measure.
  // 0: waiting for the user to select the first point.
  // 1: waiting for the user to select the second point.
  // 2: both points selected and measure complete.
  pointToUdpdate: number;
  // The length of the current line valid for states 1 and 2.
  length?: number;
  // The position of the mouse in the canvas. Only valid when pointToUpdate ==
  // 1.
  mousePos: [number, number];
  // The start and end points of the line. Only valid when pointToUpdate !== 0.
  startPoint: [number, number, number];
  endPoint: [number, number, number];
}

const deactivateMeasure = () => {
  const { display } = lcvHandler;
  display?.widgets.measureWidget?.reset();
  display?.widgets.measureWidget?.detachFrame();
};

const activateMeasure = () => {
  const { display } = lcvHandler;
  display?.widgets.measureWidget?.attachFrame(display.frames[0]);
};

/**
 * The state of the lcvis measure widget. When active, we overlay text on the screen whenever
 * the user clicks a surface or entity in the visualizer.
 */
export const lcvisMeasureState = atom<LcvisMeasureStateType>({
  key: 'lcvisMeasureState',
  default: {
    active: false,
    pointToUdpdate: 0,
    mousePos: [-1, -1],
    startPoint: [0, 0, 0],
    endPoint: [0, 0, 0],
  },
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newValue, oldValue) => {
        const isDefault = oldValue instanceof DefaultValue;
        if (isDefault) {
          deactivateMeasure();
          return;
        }
        if (!oldValue.active && newValue.active) {
          try {
            activateMeasure();
          } catch {
            // if another widget has focus, the lcvis api will throw an error if we try to attach
            // the measure widget. So if the widget can't get focus, don't attach it
            // but set the recoil state back to reflect that the measure isn't active yet.
            setSelf({ ...newValue, active: false });
          }
        }
        if (!newValue.active) {
          deactivateMeasure();
          setSelf({ ...newValue, pointToUdpdate: 0 });
        }
      });
    },
  ],
});

export const useLcvisMeasureState = () => useRecoilState(lcvisMeasureState);

export const useSetLcvisMeasureState = () => useSetRecoilState(lcvisMeasureState);

export const useLcvisMeasureValue = () => useRecoilValue(lcvisMeasureState);
