// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ActionButton } from '../../components/Button/ActionButton';
import { ActionLink } from '../../components/Button/ActionLink';
import { createStyles, makeStyles } from '../../components/Theme';
import { AuthPageLayout, useAuthPageLayoutStyles } from '../../components/layout/page/AuthPageLayout';
import { SectionMessage } from '../../components/notification/SectionMessage';
import OpenedLetterWarning from '../../components/svg/OpenedLetterWarning';
import { SUPPORT_EMAIL } from '../../lib/constants';
import { routes } from '../../lib/navigation';
import { Logger } from '../../lib/observability/logs';
import * as rpc from '../../lib/rpc';
import * as frontendpb from '../../proto/frontend/frontend_pb';

const logger = new Logger('activateaccount');

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      alignItems: 'center',
      marginTop: '52px', // needs 168px, but the left section in AuthPageLayout already has 116px
    },
    moreHelpText: {
      textAlign: 'center',
    },
    supportLink: {
      color: 'var(--primary-interactive)',
    },
  }),
  { name: 'InvalidActivateLinkPageBody' },
);

const InvalidActivateLinkPageBody = () => {
  // Hooks
  const authClasses = useAuthPageLayoutStyles();
  const classes = useStyles();
  const navigate = useNavigate();

  // State
  const [error, setError] = useState('');
  const [done, setDone] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Other state
  const ticketId = new URLSearchParams(window.location.search).get('ticketid') || '';

  const handleResendActivation = async () => {
    setError('');
    setSubmitting(true);

    try {
      const req = new frontendpb.ResendActivationEmailRequest({ ticketId });
      await rpc.callRetry('ResendActivationEmail', rpc.client.resendActivationEmail, req);
      setDone(true);
    } catch (err) {
      logger.error('ResendActivationEmail failed: ', err);
      setError('An unexpected error occured. Please try again or contact your system admin');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AuthPageLayout
      rightSection={false}>
      <div className={classes.root}>
        <OpenedLetterWarning />
        <div className={authClasses.title}>
          {ticketId ? 'Access Expired' : 'Invalid Link'}
        </div>

        {done && (
          <div className={classes.moreHelpText}>
            Check your email. We’ve sent you a new activation link.
          </div>
        )}

        {!ticketId && (
          <div className={classes.moreHelpText}>
            If you were invited to Luminary Cloud and want to activate your account,
            please check your email. You should have an activation link.
          </div>
        )}

        {error && <SectionMessage level="error" title={error} />}

        {done || !ticketId ? (
          <ActionButton
            kind="alternative"
            onClick={() => navigate(routes.login)}>
            Return to Login Screen
          </ActionButton>
        ) : (
          <ActionButton
            kind="primary"
            onClick={handleResendActivation}
            showSpinner={submitting}>
            Resend Activation Email
          </ActionButton>
        )}

        <div className={classes.moreHelpText}>
          Need more help?
          <br />
          <br />
          Please{' '}
          <ActionLink
            externalIcon
            href={`mailto:${SUPPORT_EMAIL}?subject=Invalid%20Activation%20Link`}>
            contact support
          </ActionLink>
          .
        </div>
      </div>
    </AuthPageLayout>
  );
};

export default InvalidActivateLinkPageBody;
