// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

/**
 * The rectilinearTable.globalMap package exports methods for managing the param's global
 * RectilinearTable data libary.  Tables are stored in a map, keyed by a string.
 */
import * as simulationpb from '../../proto/client/simulation_pb';
import * as explorationpb from '../../proto/exploration/exploration_pb';
import { Metadata } from '../../proto/table/table_pb';

import { TableDefinition } from './model';
import { verifyMetadataCompatibility } from './util';

// Returns true if the name exists as a key in the reference table map
export function hasKeyReference(
  simParam: simulationpb.SimulationParam,
  name: string,
) {
  return name in simParam.tableReferences;
}

/**
 * @returns true if the table name exists in the exploration table reference map
 */
export function hasKeyReferenceExploration(
  exploration: explorationpb.Exploration,
  name: string,
) {
  return name in exploration.tableReferences;
}

// Adds a new name/table pair to the reference table map
export function addEntryReference(
  simParam: simulationpb.SimulationParam,
  name: string,
  metadata: Metadata,
) {
  if (hasKeyReference(simParam, name)) {
    throw Error(`Name ${name} already exists in the table reference map.`);
  }
  simParam.tableReferences[name] = metadata;
}

function createTablesMap(
  referenceMap: Record<string, Metadata>,
  tableDefinition: TableDefinition,
) {
  const map = new Map<string, Metadata>();

  Object.entries(referenceMap).forEach(([key, metadata]) => {
    if (verifyMetadataCompatibility(metadata, tableDefinition.tableType)) {
      map.set(key, metadata);
    }
  });

  return map;
}

// Return a Map with all tables that are compatible based on metadata.tableType
export function getCompatibleTablesMap(
  simParam: simulationpb.SimulationParam,
  tableDefinition: TableDefinition,
) {
  return createTablesMap(simParam.tableReferences, tableDefinition);
}

/**
 * @returns a map of table names to table metadata that have a compatible TableDefinition
 */
export function getCompatibleTablesMapExploration(
  exploration: explorationpb.Exploration,
  tableDefinition: TableDefinition,
) {
  return createTablesMap(exploration.tableReferences, tableDefinition);
}

export function updateEntryReference(
  type: string,
  name: string,
  param: simulationpb.SimulationParam,
  metadata?: Metadata,
) {
  let link = false;
  switch (type) {
    case 'create': {
      addEntryReference(param, name, metadata!);
      break;
    }
    case 'create-assign': {
      addEntryReference(param, name, metadata!);
      link = true;
      break;
    }
    case 'assign': {
      link = true;
      break;
    }
    default: {
      // no default
    }
  }
  return link;
}
