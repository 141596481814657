// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useMemo } from 'react';

import { useGeometryState } from '../../recoil/geometry/geometryState';
import { useProjectContext } from '../context/ProjectContext';

// Check if there is a farfield for the current project and geometry in the geometry view
export function useHasFarfield() {
  // == Contexts
  const { projectId, geometryId } = useProjectContext();

  // == State
  const geometryState = useGeometryState(projectId, geometryId);

  return useMemo(
    () => geometryState?.geometryFeatures.some((feature) => feature.operation.case === 'farfield'),
    [geometryState],
  );
}
