// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 10;
const baseHeight = 11;
export const BeakerIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="beaker"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M6.369,3.794
           L8.648,7.454
           C9.61,8.995 8.498,11 6.678,11
           L2.324,11
           C0.504,11 -0.608,8.995 0.354,7.454
           L2.633,3.793
           L2.633,1
           L1.96,1
           L1.96,0
           L7.051,0
           L7.051,1
           L6.369,1
           L6.369,3.794Z
           M3.633,1
           L3.633,3.436
           L5.369,3.436
           L5.369,1
           L3.633,1Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
