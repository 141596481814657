// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { useEffect, useMemo, useRef, useState } from 'react';

import { useDataTablePage } from '../state/internal/component/dataTable';

/**
 * This module expose types and custom hooks that simplify pagination.  Pagination design can be
 * found in the Data Table Design Document:
 * https://docs.google.com/document/d/1PfTnqsEythZ4vjSPpkFcmcInSSqy8hwVvmhGt7ydjHk
 */

// The PageDescriptor encapsulates a page number (which is 0-indexed) and the
// page number's display value, so that applications don't have to worry about
// whether to add 1 or not to the page number for display purposes.
export interface PageDescriptor {
  // The 0-index page number
  page: number;
  // The string that represents the page (typically page + 1 cast as a string)
  display: string;
}

// Per the design document, some page controls are links to explicit page numbers.
export interface PageNumberLink extends PageDescriptor {
  type: 'page';
}

// Per the design document, some page controls represent a slice of (consecutive) pages.
export interface PageSlice {
  type: 'slice';
  slice: PageDescriptor[];
}

// A discriminated union of page control types
export type PageLink = PageNumberLink | PageSlice;

function createPageDescriptor(page: number): PageDescriptor {
  return { page, display: `${page + 1}` };
}

/** usePageBase exposes some common derived fields, based on `total` and `pageSize` values */
export const usePageBase = (total: number, pageSize: number) => {
  if (pageSize <= 0) {
    throw Error('Page size must be greater than 0');
  }

  const pageCount = (pageSize === Infinity) ? 1 : Math.ceil(total / pageSize);
  const maxPage = pageCount - 1;
  // For symmetry with maxPage, minPage is captured here; it is always 0;
  const minPage = 0;

  return {
    minPage,
    maxPage,
    pageCount,
  };
};

/** usePageTracker allows applications (like Tables) to manage the current page */
export const usePageTracker = (name: string, total: number, pageSize: number, persist: boolean) => {
  const { minPage, maxPage } = usePageBase(total, pageSize);
  const [storagePage, setStoragePage] = useDataTablePage(name);

  const [currentPage, setCurrentPage] = useState(storagePage);
  const prevPageSizeRef = useRef(pageSize);

  // Returns the lower and upper indices that define the range of values that are in the current
  // page.  For example, if current page is 1, total is 100, and page size is 20, the range
  // [20, 39] will be returned.
  const indexRange = useMemo((): [number, number] => {
    if (pageSize === Infinity) {
      return [0, total - 1];
    }

    const low = currentPage * pageSize;
    const high = low + pageSize - 1;

    return [
      Math.max(0, low),
      Math.min(total - 1, high),
    ];
  }, [currentPage, pageSize, total]);

  const updateCurrentPage = (value: number) => {
    // Normalize the value so that it's always within a valid range
    const newPage = Math.max(minPage, Math.min(maxPage, value));
    setCurrentPage(newPage);
    if (persist) {
      setStoragePage(newPage);
    }
  };

  const resetCurrentPage = useRef(() => {
    updateCurrentPage(minPage);
  });

  // If we are on the last page and there's only a single item on that page, removing that item
  // should reset the pagination and bring the user back to the first page. Otherwise the user
  // would stay on that last page, without any results there.
  useEffect(() => {
    if (currentPage > maxPage) {
      resetCurrentPage.current();
    }
  }, [currentPage, maxPage]);

  useEffect(() => {
    // Whenever the page size changes, reset the current page to the first page; otherwise, it
    // could become invalid. Do this only if the page size really changes and not when initializing
    // because it will overwrite the page saved in the browser storage.
    if (prevPageSizeRef.current !== pageSize) {
      resetCurrentPage.current();
      prevPageSizeRef.current = pageSize;
    }
  }, [pageSize, resetCurrentPage]);

  return {
    currentPage,
    indexRange,
    updateCurrentPage,
  };
};

/** usePageControl provides state for pagination control components */
export const usePageControl = (
  total: number,
  pageSize: number,
  currentPage: number,
  linkNeighborhood: number,
) => {
  const { minPage, maxPage, pageCount } = usePageBase(total, pageSize);

  const canDecrementPage = currentPage > minPage;
  const canIncrementPage = currentPage < maxPage;

  // Returns an array of links (explicit or slice) that should be laid out in pagination controls.
  // The 'neighborhood' argument controls how many explicit page links should be exposed on each
  // side of the current page.  See design document for details.
  const pageLinks = useMemo<PageLink[]>(() => {
    const pageNumbers: number[] = [];

    // Construct a list of all page numbers
    for (let i = minPage; i <= maxPage; i += 1) {
      pageNumbers.push(i);
    }

    // Page numbers in this range should always be explicit (not collapsed into slices).
    const preserveRange = [currentPage - linkNeighborhood, currentPage + linkNeighborhood];

    const links: PageLink[] = [];

    for (let i = minPage; i <= maxPage; i += 1) {
      if ((i === minPage) || (i === maxPage)) {
        // First and last page are always explicit page links
        links.push({ type: 'page', ...createPageDescriptor(i) });
      } else if (i >= preserveRange[0] && i <= preserveRange[1]) {
        // Pages in the current page's neighborhood are always explicit page links
        links.push({ type: 'page', ...createPageDescriptor(i) });
      } else {
        // Otherwise, we're either creating a slice or updating one
        if (links.length) {
          const lastLink = links[links.length - 1];
          if (lastLink.type === 'slice') {
            // If the last link is a slice type, then append this page number
            lastLink.slice.push(createPageDescriptor(i));
            continue;
          }
        }
        // If we're here, create a new slice
        links.push({
          type: 'slice',
          slice: [createPageDescriptor(i)],
        });
      }
    }

    // There may be some page-list-type links with just 1 page in them.  Replace those with
    // explicit page links.
    return links.map((link) => {
      if (link.type === 'slice') {
        if (link.slice.length === 1) {
          const { page, display } = link.slice[0];
          return { type: 'page', page, display };
        }
      }
      return link;
    });
  }, [currentPage, linkNeighborhood, maxPage, minPage]);

  return {
    canDecrementPage,
    canIncrementPage,
    pageCount,
    pageLinks,
  };
};
