// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const CloudDownloadIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="cloudDownload"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M16.5,16.5
           C16.08,16.5 15.705,16.665 15.435,16.935
           L13.5,18.885
           L13.5,12
           C13.5,11.175 12.825,10.5 12,10.5
           C11.175,10.5 10.5,11.175 10.5,12
           L10.5,18.885
           L8.565,16.95
           C8.295,16.665 7.92,16.5 7.5,16.5
           C6.675,16.5 6,17.175 6,18
           C6,18.42 6.165,18.795 6.435,19.065
           L10.935,23.565
           C11.205,23.835 11.58,24 12,24
           C12.42,24 12.795,23.835 13.065,23.565
           L17.565,19.065
           C17.835,18.795 18,18.42 18,18
           C18,17.175 17.325,16.5 16.5,16.5Z
           M18,6
           C17.955,6 17.895,6 17.85,6.015
           C17.16,2.58 14.13,0 10.5,0
           C6.36,0 3,3.36 3,7.5
           C3,7.665 3.015,7.83 3.03,7.995
           C1.245,8.835 0,10.65 0,12.75
           C0,14.865 1.26,16.665 3.045,17.505
           C3.3,15.255 5.19,13.5 7.5,13.5
           C7.59,13.5 7.695,13.53 7.785,13.53
           C7.605,13.05 7.5,12.54 7.5,12
           C7.5,9.51 9.51,7.5 12,7.5
           C14.49,7.5 16.5,9.51 16.5,12
           C16.5,12.54 16.395,13.05 16.215,13.53
           C16.305,13.53 16.41,13.5 16.5,13.5
           C18.72,13.5 20.55,15.105 20.925,17.205
           C22.755,16.185 24,14.25 24,12
           C24,8.685 21.315,6 18,6Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
