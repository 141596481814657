// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import { isUnmodifiedEnterKey } from '../../lib/event';
import { createStyles, makeStyles } from '../Theme';
import { CONTROL_HEIGHT } from '../Theme/commonStyles';
import Tooltip from '../Tooltip';
import { useNodeSelect } from '../hooks/useNodeSelect';

const useStyles = makeStyles(
  () => createStyles({
    link: {
      cursor: 'pointer',
      color: 'inherit',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      outline: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline',
      lineHeight: `${CONTROL_HEIGHT}px`,
      '&.styled': {
        color: colors.highEmphasisText,
        fontWeight: 500,
        fontSize: '13px',
        textDecoration: 'underline',
      },
      '&.underline': {
        textDecoration: 'underline',
      },
      '&.asBlock': {
        display: 'block',
      },
      '&.disabled': {
        cursor: 'auto',
        textDecoration: 'none',
      },
    },
  }),
  { name: 'NodeLink' },
);

export interface NodeLinkProps {
  // The node IDs to select when the link is clicked.
  nodeIds: string[];
  // The text of the link.
  text: string;
  unstyled?: boolean;
  underline?: boolean;
  // True if the link should be displayed as a block rather than inline
  asBlock?: boolean;
  disabled?: boolean;
  help?: string;
  // optional callback to fire before the default NodeLink behavior
  onClick?: () => void;
}

// Displays a link to another node or set of nodes. When the link is clicked
// the nodes are selected.
export const NodeLink = (props: NodeLinkProps) => {
  // Props
  const { nodeIds, text, unstyled, asBlock, disabled, help, underline, onClick } = props;

  // Hooks
  const classes = useStyles();
  const select = useNodeSelect();

  // Handlers
  const handleSelect = () => {
    if (disabled) {
      return;
    }
    onClick?.();

    select(nodeIds[0]);
  };

  return (
    <Tooltip title={help}>
      <span>
        <div
          className={cx(classes.link, { styled: !unstyled }, { asBlock, disabled, underline })}
          onClick={handleSelect}
          onKeyUp={(event) => isUnmodifiedEnterKey(event) && handleSelect()}
          role="link"
          tabIndex={0}>
          {text}
        </div>
      </span>
    </Tooltip>
  );
};

export default NodeLink;
