// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVType } from '@luminarycloudinternal/lcvis';

import { newProto, pvToList, rotate, toVector3 } from '../../../../Vector';
import assert from '../../../../assert';
import { RgbColor } from '../../../../designSystem';
import { areArraysEqual } from '../../../../lang';
import { SimAnnotationParam } from '../../../../simAnnotationUtils';
import { LcvModule } from '../../../types';
import { LcvObjectList } from '../../base/LcvObjectList';

import { LcvSimAnnotation } from './LcvSimAnnotation';

export class LcvDiskList extends LcvObjectList implements LcvSimAnnotation {
  constructor(lcv: LcvModule, sessionHandle: number, size: number) {
    super(
      lcv,
      lcv.newAnnotation(sessionHandle, 'disks', 0).annotation,
      sessionHandle,
      size,
    );
  }

  updateItemAtIndex(index: number, param: SimAnnotationParam): void {
    assert(param.typ === 'ActuatorDisk', 'Param is not an actuator disk');

    const { innerradius, outerradius, plane, usenormal } = param;
    const { origin, normal } = plane;

    let listNormal = pvToList(normal);
    if (!usenormal || areArraysEqual(listNormal, [0, 0, 0])) {
      // if usenormal is false, then the 'normal' attribute in the plane param contains euler
      // angles. LCVis expects a normal vector, so we should rotate the z vec by the euler angles
      // to get a normal to give it.
      const zVec = newProto(0, 0, 1);
      const eulerAngles = newProto(...listNormal);
      const newNormal = rotate(eulerAngles, zVec);
      listNormal = toVector3(newNormal) as [number, number, number];
    }
    this.setParamAtIndex(index, 'normals', LCVType.kLCVDataTypeFloat3, listNormal);

    const listOrigin = pvToList(origin);
    this.setParamAtIndex(index, 'positions', LCVType.kLCVDataTypeFloat3, listOrigin);
    this.setParamAtIndex(index, 'radii', LCVType.kLCVDataTypeFloat2, [innerradius, outerradius]);
  }

  showItemAtIndex(index: number): void {
    this.setParamAtIndex(index, 'visible', LCVType.kLCVDataTypeUint, 1);
  }

  hideItemAtIndex(index: number): void {
    this.setParamAtIndex(index, 'visible', LCVType.kLCVDataTypeUint, 0);
  }

  setColorAtIndex(index: number, color: RgbColor): void {
    this.setParamAtIndex(index, 'colors', LCVType.kLCVDataTypeFloat3, color);
  }
}
