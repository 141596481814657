// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const VerticalControlsIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="verticalControls"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M4.5,1.5
           C4.5,0.675 3.825,0 3,0
           C2.175,0 1.5,0.675 1.5,1.5
           L1.5,6
           L4.5,6
           L4.5,1.5Z
           M4.5,7.5
           L1.5,7.5
           C0.675,7.5 0,8.175 0,9
           L0,12
           C0,12.825 0.675,13.5 1.5,13.5
           L4.5,13.5
           C5.325,13.5 6,12.825 6,12
           L6,9
           C6,8.175 5.325,7.5 4.5,7.5Z
           M22.5,1.5
           C22.5,0.675 21.825,0 21,0
           C20.175,0 19.5,0.675 19.5,1.5
           L19.5,4.5
           L22.5,4.5
           L22.5,1.5Z
           M13.5,1.5
           C13.5,0.675 12.825,0 12,0
           C11.175,0 10.5,0.675 10.5,1.5
           L10.5,10.5
           L13.5,10.5
           L13.5,1.5Z
           M1.5,22.5
           C1.5,23.325 2.175,24 3,24
           C3.825,24 4.5,23.325 4.5,22.5
           L4.5,15
           L1.5,15
           L1.5,22.5Z
           M22.5,6
           L19.5,6
           C18.675,6 18,6.675 18,7.5
           L18,10.5
           C18,11.325 18.675,12 19.5,12
           L22.5,12
           C23.325,12 24,11.325 24,10.5
           L24,7.5
           C24,6.675 23.325,6 22.5,6Z
           M19.5,22.5
           C19.5,23.325 20.175,24 21,24
           C21.825,24 22.5,23.325 22.5,22.5
           L22.5,13.5
           L19.5,13.5
           L19.5,22.5Z
           M13.5,12
           L10.5,12
           C9.675,12 9,12.675 9,13.5
           L9,16.5
           C9,17.325 9.675,18 10.5,18
           L13.5,18
           C14.325,18 15,17.325 15,16.5
           L15,13.5
           C15,12.675 14.325,12 13.5,12Z
           M10.5,22.5
           C10.5,23.325 11.175,24 12,24
           C12.825,24 13.5,23.325 13.5,22.5
           L13.5,19.5
           L10.5,19.5
           L10.5,22.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
