// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const ProbeIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="probe"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M18.582,0
           C17.196,0 15.809,0.529 14.751,1.587
           L5.365,10.972
           C3.319,13.018 4.311,17.418 2.066,19.662
           L0.47,21.26
           C-0.157,21.886 -0.157,22.902 0.47,23.529
           C0.784,23.842 1.193,24 1.604,24
           C2.015,24 2.426,23.842 2.74,23.529
           L4.337,21.934
           C6.576,19.695 10.968,20.695 13.027,18.635
           L22.413,9.249
           C24.529,7.133 24.529,3.702 22.413,1.587
           C21.355,0.529 19.969,0 18.582,0Z
           M18.582,1.846
           C19.536,1.846 20.431,2.217 21.106,2.892
           C22.499,4.285 22.499,6.552 21.106,7.944
           L16.413,12.636
           L11.72,17.329
           C11.124,17.926 9.865,18.146 8.531,18.38
           C7.665,18.532 6.747,18.707 5.854,18.995
           L5.004,18.146
           C5.296,17.246 5.474,16.322 5.628,15.449
           C5.862,14.121 6.081,12.865 6.668,12.278
           L8.882,10.064
           L16.054,2.892
           C16.73,2.218 17.629,1.846 18.582,1.846Z
           M19.384,3.692
           C18.875,3.692 18.461,4.106 18.461,4.615
           C18.461,5.125 18.875,5.538 19.384,5.538
           C19.894,5.538 20.307,5.125 20.307,4.615
           C20.307,4.106 19.894,3.692 19.384,3.692Z
           M15.164,6.462
           L10.154,11.474
           L12.528,13.846
           L17.538,8.836
           L15.164,6.462Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
