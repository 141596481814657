// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtomValue, useSetAtom } from 'jotai';

/**
 * The state tracks which node from the NodeTable/NodeSubselect is being hovered at the moment.
 */
const nodeSelectHoveredIdState = atom<string | null>(null);

export function useNodeSelectHoveredIdValue() {
  return useAtomValue(nodeSelectHoveredIdState);
}

export function useSetNodeSelectHoveredId() {
  return useSetAtom(nodeSelectHoveredIdState);
}
