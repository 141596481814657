// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';

export const DEFAULT_MESH_NAME = 'Untitled Mesh';
/**
 * This atom tracks the state of the MultiMesh panel: Select Mesh, Edit Mesh, Mesh Details
 */
const meshNameAtom = atomFamily((projectId: string) => atom(DEFAULT_MESH_NAME));

export function useSetMeshName(projectId: string) {
  return useSetAtom(meshNameAtom(projectId));
}

export function useMeshName(projectId: string) {
  return useAtomValue(meshNameAtom(projectId));
}
