import React, { ReactElement } from 'react';

import cx from 'classnames';

import { colors } from '../../../../../lib/designSystem';
import { isUnmodifiedEnterKey } from '../../../../../lib/event';
import { assignDefaultBodyFrame, createFrame, findBodyFrame } from '../../../../../lib/motionDataUtils';
import * as simulationpb from '../../../../../proto/client/simulation_pb';
import * as meshgenerationpb from '../../../../../proto/meshgeneration/meshgeneration_pb';
import { initializeNewNode, useSetNewNodes } from '../../../../../recoil/nodeSession';
import useMeshGeneration, { useSetMeshGeneration } from '../../../../../recoil/useMeshGeneration';
import { useMeshReadOnly } from '../../../../../recoil/useMeshReadOnly';
import Form from '../../../../Form';
import CheckBox from '../../../../Form/CheckBox';
import { CollapsibleNodePanel } from '../../../../Panel/CollapsibleNodePanel';
import { createStyles, makeStyles } from '../../../../Theme';
import { useCommonMultiInputLines } from '../../../../Theme/commonStyles';
import Tooltip from '../../../../Tooltip';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useSelectionContext } from '../../../../context/SelectionManager';
import { useSimulationConfig } from '../../../../hooks/useSimulationConfig';
import NodeLink from '../../../NodeLink';
import PropertiesSection from '../../../PropertiesSection';

const useStyles = makeStyles(
  () => createStyles({
    link: {
      flex: '1 0 60%',
      color: colors.lowEmphasisText,
      fontSize: '13px',
      fontWeight: 600,
      textDecoration: 'underline',
      cursor: 'pointer',
      outline: 'none',
    },
  }),
  { name: 'OptionalParamsPanel' },
);

interface OptionalParamsPanelProps {
    /** A URL containing info about the mesh. Use an empty string when generating a new mesh. */
    meshUrl: string;
    /** Use the [readonly] property to disable the ability to change values,
     * allowing them to be displayed only. */
    readOnly?: boolean;
}

// A panel that displays optional parameters.
export const OptionalParamsPanel = ({ meshUrl, readOnly = false }: OptionalParamsPanelProps) => {
  const { selectedNode, setSelection, setScrollTo } = useSelectionContext();
  const { projectId, readOnly: readOnlyProject } = useProjectContext();
  const { simParam, saveParamAsync } = useSimulationConfig();
  const meshGeneration = useMeshGeneration(projectId, meshUrl);
  const setMeshGeneration = useSetMeshGeneration(projectId);
  const meshReadOnly = useMeshReadOnly(projectId);
  const multiInputClasses = useCommonMultiInputLines();
  const classes = useStyles();
  const bodyFrame = findBodyFrame(simParam);
  const setNewNodes = useSetNewNodes();

  const updateBodyFrameLink = (): ReactElement => (
    <NodeLink
      asBlock
      nodeIds={bodyFrame ? [bodyFrame.frameId!] : []}
      text="Update Body Frame"
    />
  );

  const addBodyFrame = async () => {
    const nodeId = await saveParamAsync((newParam) => {
      const frame = createFrame(newParam, simulationpb.MotionType.NO_MOTION, 'Body Frame');
      assignDefaultBodyFrame(newParam, frame);
      return frame.frameId;
    });

    setNewNodes((oldValue) => [...oldValue, initializeNewNode(nodeId)]);
    setSelection([nodeId]);
    setScrollTo({ node: nodeId });
  };

  const createBodyFrameLink = (): ReactElement => (
    <div
      className={cx(classes.link)}
      onClick={() => addBodyFrame()}
      onKeyUp={(event) => isUnmodifiedEnterKey(event) && addBodyFrame()}
      role="link"
      tabIndex={0}>
      Create Body Frame
    </div>
  );

  const refinementDisabled = (!meshGeneration?.addRefinement && !bodyFrame) ||
    readOnlyProject ||
    meshReadOnly;
  const proximityActive = (meshGeneration?.proximityLayers || 0) > 0;
  const autoRefinementRegionsActive = !!meshGeneration?.addRefinement;

  return (
    <PropertiesSection>
      <CollapsibleNodePanel
        heading="Optional"
        nodeId={selectedNode!.id}
        panelName="optional">
        <Form.LabeledInput
          faultType={meshGeneration?.addRefinement && !bodyFrame ? 'warning' : undefined}
          help={`Add refinement box aligned such that Body Frame axes are normal to box faces. Box
              length extends farther into the -x direction in the Body Frame to capture downstream
              flow features`}
          key="refinement-checkbox"
          label="Auto Refinement Regions">
          <div className={multiInputClasses.root}>
            {readOnly && (meshGeneration?.addRefinement ? 'Enabled' : 'Disabled')}
            {!readOnly && (
            <Tooltip title="Mesh refinement requires Body Frame to orient refinement box.">
              <label className={multiInputClasses.line}>
                <div className={multiInputClasses.control}>
                  <Form.CheckBox
                    checked={autoRefinementRegionsActive}
                    disabled={refinementDisabled}
                    onChange={(checked) => {
                      setMeshGeneration((meshGen) => new meshgenerationpb.UserMeshingParams({
                        ...meshGen,
                        addRefinement: checked,
                      }));
                    }}
                  />
                </div>
                <div>Enable</div>
              </label>
            </Tooltip>
            )}
          </div>
        </Form.LabeledInput>
        {!readOnly && (
        <Form.LabeledInput
          key="body-frame-link"
          label="">
          <div className={classes.link}>
            {bodyFrame ? updateBodyFrameLink() : createBodyFrameLink()}
          </div>
        </Form.LabeledInput>
        )}
        <Form.LabeledInput
          help={`This ensures that a sufficient level of refinement is present
              across volume (gaps, for instance) or on a surface patch (trailing
              edges). This option also triggers an additional proximity sizing to
              ensure a better Boundary Layer transition across sharp features
              when a boundary layer mesh added. The proximity sizes are still
              bounded by the minimal size on a given volume.`}
          label="Proximity">
          {readOnly && (proximityActive ? 'Enabled' : 'Disabled')}
          {!readOnly && (
          <CheckBox
            checked={proximityActive}
            disabled={readOnlyProject || meshReadOnly}
            onChange={(checked) => {
              setMeshGeneration((meshGen) => new meshgenerationpb.UserMeshingParams({
                ...meshGen,
                proximityLayers: checked ? 1 : 0,
              }));
            }}
          />
          )}
        </Form.LabeledInput>
      </CollapsibleNodePanel>
    </PropertiesSection>
  );
};
