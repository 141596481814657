// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as simulationpb from '../proto/client/simulation_pb';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import { syncDependentBoundaryCondition } from './multiphysicsInterfaceUtils';
import { PARAM_VALIDATION_ERROR_NOTIFICATION_ID, setNotification } from './transientNotification';

const { VENKATAKRISHNAN_CV, INVARIANT_VENKATAKRISHNAN_CV } = simulationpb.Limiter;

function upgradeVenkatakrishnanLimiter(param: simulationpb.SimulationParam) {
  const upgraded = param.physics.reduce((result, physics) => {
    if (physics.params.case === 'fluid') {
      const spatialDisc = physics.params.value.spatialDiscretizationFluid;
      if (spatialDisc?.limiter === VENKATAKRISHNAN_CV) {
        spatialDisc.limiter = INVARIANT_VENKATAKRISHNAN_CV;
        return true;
      }
    }
    return result;
  }, false);
  if (upgraded) {
    setNotification(
      PARAM_VALIDATION_ERROR_NOTIFICATION_ID,
      'warning',
      'The Venkatakrishnan limiter implementation has changed and the legacy version has been ' +
      'deprecated. The new implementation has been selected automatically.',
      undefined,
    );
  }
}

// Make sure dependendent boundary conditions are generated for any multiphysics coupling interfaces
function syncCouplingInterfaces(
  param: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  param.interfaces.forEach((couplingInterface) => {
    syncDependentBoundaryCondition(
      param,
      couplingInterface.slidingInterfaceId,
      geometryTags,
      staticVolumes,
    );
  });
}

export function upgradeUploadedParam(
  param: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  upgradeVenkatakrishnanLimiter(param);
  syncCouplingInterfaces(param, geometryTags, staticVolumes);
}
