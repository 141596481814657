// Metadata definition for physical quantities used throughout the luminary
// platform

// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/quantity/quantity_options.proto (package luminary.proto.quantity, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Condition } from "../condition/condition_pb.js";

/**
 * @generated from enum luminary.proto.quantity.QuantityTag
 */
export const QuantityTag = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.quantity.QuantityTag",
  [
    {no: 0, name: "TAG_INVALID"},
    {no: 1, name: "TAG_BASIC"},
    {no: 2, name: "TAG_FIELD"},
    {no: 3, name: "TAG_FORCE"},
    {no: 4, name: "TAG_MOMENT"},
    {no: 5, name: "TAG_EXTERNAL"},
    {no: 6, name: "TAG_AERODYNAMICS"},
    {no: 7, name: "TAG_INTERNAL"},
    {no: 8, name: "TAG_TORQUE"},
    {no: 9, name: "TAG_DROP"},
    {no: 10, name: "TAG_AUTO_DIRECTION"},
    {no: 11, name: "TAG_COEFFICIENT"},
    {no: 12, name: "TAG_ANALYZER_AVERAGE"},
    {no: 13, name: "TAG_ANALYZER_FORCES"},
    {no: 14, name: "TAG_TIME_AVERAGE"},
    {no: 15, name: "TAG_RESIDUAL"},
    {no: 16, name: "TAG_ANALYZER_RESIDUAL"},
    {no: 17, name: "TAG_ANALYZER_BASIC"},
    {no: 18, name: "TAG_TURBULENCE"},
    {no: 19, name: "TAG_POINT_PROBE"},
    {no: 20, name: "TAG_HIDDEN_SOLVER_STATE"},
    {no: 21, name: "TAG_SURFACE_FIELD"},
    {no: 22, name: "TAG_ACTUATOR_DISK"},
    {no: 23, name: "TAG_ANALYZER_VOLUME"},
  ],
);

/**
 * @generated from message luminary.proto.quantity.Tags
 */
export const Tags = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.quantity.Tags",
  () => [
    { no: 1, name: "vals", kind: "enum", T: proto3.getEnumType(QuantityTag), repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.quantity.Children
 */
export const Children = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.quantity.Children",
  () => [
    { no: 1, name: "vals", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.quantity.Parents
 */
export const Parents = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.quantity.Parents",
  () => [
    { no: 1, name: "vals", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.quantity.ConditionalParents
 */
export const ConditionalParents = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.quantity.ConditionalParents",
  () => [
    { no: 1, name: "vals", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.quantity.Quantity
 */
export const Quantity = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.quantity.Quantity",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "unit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "tags", kind: "message", T: Tags },
    { no: 6, name: "help", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "child", kind: "message", T: Children },
    { no: 9, name: "parent", kind: "message", T: Parents },
    { no: 10, name: "conditional_parent", kind: "message", T: ConditionalParents },
    { no: 8, name: "cond", kind: "message", T: Condition },
  ],
);

