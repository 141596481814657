// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as ParaviewRpc from '../pvproto/ParaviewRpc';

import { Client, ViewName } from './ParaviewClient';
import { ModifierMap } from './event';
import { StatusCardProps } from './statusCardProps';

export enum RendererType {
  IMAGE = 1, // ImageRenderer
}

export interface MouseClickEvent extends ModifierMap {
  // The screen pixel coordinate of the pointer. (0, 0) is at the upper left
  // edge.
  screenX: number;
  screenY: number;
  // Which button was pressed. 1 = left, 2 = middle, 3 = right.
  button: number;
}

// Abstract interface for paraview renderer. It manages the contents of the
// window, and provides methods for controlling camera angles.
//
// - ImageRenderer manages window contents using the viewport.image.* protocol.
// The server renders the mesh on its internal buffer and sends jpeg images to
// the client. The camera is managed by the server.
interface Renderer {
  // Reports the type of this renderer.
  rendererType(): RendererType;

  // Must be called once when a <div> element is mounted.
  //
  // REQUIRES: elem is a valid HTML element.
  initialize(viewName: ViewName, elem: HTMLDivElement, experimentConfig: string[]): void;

  // Called every time the backend connecitivy changes.
  setClient(
    client: Client | null,
    onCameraUpdate: (state: ParaviewRpc.CameraState) => void,
    onMouseClick: (event: MouseClickEvent) => void,
    onMouseDoubleClick: (event: MouseClickEvent) => void,
    onMouseDrag: (event: MouseClickEvent) => void,
    onKeepalive: () => void): void;

  // Called when the <div> element is unmounted. No other method shall be called
  // after stop().
  stop(): void;

  // Camera-control methods.
  resetCamera(): void;
  zoomToBox(minX: number, maxX: number, minY: number, maxY: number): void;
  boxBlockSelect(
    minX: number,
    maxX: number,
    minY: number,
    maxY: number,
    useFrustum: boolean,
    onBlocksSelected: (block: string[]) => void
  ): void;
  setCenterOfRotationCamera(screenX: number, screenY: number): void;
  zoomCamera(scale: number): void;
  setCameraAngle(
    parallelProjection: boolean,
    viewUp: ParaviewRpc.Vector3 | null,
    cameraDirection: ParaviewRpc.Vector3 | null): void;

  // Get the blockname of the object the given screen location. (0,0) is at the
  // upper left corner.
  getSurfaceAtPoint(screenX: number, screenY: number): Promise<string>;

  // Find the cell that at the given screen location and return its quantities.
  getCellDataAtPoint(screenX: number, screenY: number): Promise<ParaviewRpc.CellState[]>;

  // Returns the the current screen visible data range.
  getScreenVisibleDataRange(
    displayVariable: ParaviewRpc.DisplayPvVariable
  ): Promise<[number, number] | null>;

  // Show the grid axes.
  showAxesGrid(show: boolean): Promise<ParaviewRpc.RpcResult>

  // Store a PNG image with the screenshot of the current view. Text is a suffix
  // that will be added to the screenshot filename as `screenshot {text}.png`.
  screenshot(text: string, transparentBackground: boolean): void

  // Handles the initialization and playing of the grid motion animation in the renderer.
  // The function generates the grid motion source in ParaView and it is charge
  // of handling the starting and stopping of the animation. setMotionAnimation is used to modify
  // the motion animation recoil atom state.
  handleMotionAnimation(
    param: ParaviewRpc.GridMotionParam,
    setStatusCardProps: (statusCardProps: StatusCardProps) => void,
    setMotionAnimation: (arg: boolean) => void,
    frameCallback?: () => void,
  ): void;

  // Stops the motion animation initiated with handleMotionAnimation.
  stopMotionAnimation(): void;

  // Registers a function to be called when a widget's state changes.  It will
  // be called immediately after a widget is created, and on every widget-state
  // change.
  registerOnUpdateWidgetHandler(fn: (state: ParaviewRpc.WidgetState) => void): Promise<() => void>;

  // Creates a widget with the given state. If a widget of a different type
  // already exists, it will be deleted. If a widget of the same type already
  // exists, its parameters will be updated to match the `state`.
  activateWidget(bounds: ParaviewRpc.Bounds, state: ParaviewRpc.WidgetState): void;
  // Deletes the current active widget. A no-op if the widget doesn't exist.
  deleteWidget(): void;

  // Updates the camera state on the renderer side.
  updateCameraState(state: ParaviewRpc.CameraState): void;

  // tracks the time of the most recent update to camera state
  mostRecentCameraUpdate: number;
  panCamera?: (direction: ParaviewRpc.PanDirectionType, amount: number) => void;
  rotateCamera?: (axis: ParaviewRpc.RotationAxisType, angle: number) => void;
}

export default Renderer;
