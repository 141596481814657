// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useEffect, useState } from 'react';

import { colors, hexToRgb } from '../../lib/designSystem';
import * as random from '../../lib/random';
import { createStyles, makeStyles } from '../Theme';
import { LcLogoGraphOnlyIcon } from '../svg/LcLogoGraphOnlyIcon';

// The interval of time that each tip is displayed;
const TIP_DISPLAY_INTERVAL_MS = 10000;

const TIP_LIST: string[] = [
  `Luminary's automated meshing feature has been built to work seamlessly with our flow
   solver.`,
  `Luminary can import and mesh many geometry file types, such as from SolidWorks, Inventor, and
   Rhino. Ask our AI chatbot, Lumi, for a complete list.`,
  `Luminary captures integrated surface quantities at every iteration, letting you configure outputs
   before, during, or even after your simulation.`,
  `Monitor points and planes can be used to record simulation values at specific locations. These
  must be setup prior to running the simulation.`,
  `You can create custom outputs using Luminary's expression parser to meet your specific needs.`,
  `Luminary can be scripted using a custom SDK to quickly rerun common workflows.`,
  `A slice or clip plane can be used to inspect the mesh around internal surfaces.`,
  `Luminary will automatically determine the optimal number of GPUs for your simulation, giving both
   speed and efficiency.`,
  `Our AI chatbot, Lumi, can answer most questions. Give it a try!`,
  `Luminary can automatically detect contact interfaces between volumes.`,
  `Luminary supports various RANS and LES turbulence models.`,
  `The 'Exploration' feature of Luminary can be used to quickly perform parameter sweeps, allowing
  you to evaluate your design over multiple conditions in record time.`,
  `Reference values can be edited at any time; we'll update dependent fields for you.`,
  `Luminary caches pre-processing data from your first simulation, helping the next one complete
  faster.`,
  `You can reach our technical support team through live chat. Just ask the AI chatbot, Lumi, to
  connect you.`,
];

// TipsOverlay displays tips and tricks to entertain and distract the user while their geometry is
// loading.
const useStyles = makeStyles(() => createStyles({
  overlay: {
    backgroundColor: hexToRgb(colors.surfaceBackground, 0.5),
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    overflow: 'hidden',
  },
  dialog: {
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    position: 'absolute',
  },
  text: {
    color: colors.neutral700,
    fontSize: '14px',
    lineHeight: '24px',
    paddingTop: '25px',
  },
  bold: {
    fontWeight: 600,
  },
}), { name: 'TipsOverlay' });

function pickRandomTip() {
  return TIP_LIST[random.integer(0, TIP_LIST.length - 1)];
}

const TipsOverlay = () => {
  const classes = useStyles();
  const [tip, setTip] = useState<string>(pickRandomTip());

  useEffect(() => {
    // Replace the tip with a new one at regular intervals.
    const interval = setInterval(() => {
      setTip(pickRandomTip());
    }, TIP_DISPLAY_INTERVAL_MS);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes.overlay}>
      <div className={classes.dialog}>
        <LcLogoGraphOnlyIcon maxHeight={78} />
        <div className={classes.text}>
          <div className={classes.bold}>
            DID YOU KNOW
          </div>
          {tip}
        </div>
      </div>
    </div>
  );
};

export default TipsOverlay;
