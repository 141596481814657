// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { parseString } from '../../lib/html';
import { LeveledMessage } from '../../lib/notificationUtils';
import { createStyles, makeStyles } from '../Theme';

import { AlertIcon } from './AlertIcon';

const useStyles = makeStyles(
  () => createStyles({
    list: {
      padding: '10px',
      margin: 0,
      listStyleType: 'none',
    },
    listItem: {
      '& + li': {
        marginTop: '5px',
      },
    },
    listContent: {
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '8px',
    },
    iconContainer: {
      flex: '0 0 auto',
      height: '15px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    icon: {
      flex: '0 0 auto',
      width: '12px',
      height: '12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&.error': {
        color: 'var(--color-red-500)',
      },
      '&.warning': {
        color: 'var(--color-yellow-500)',
      },
      '&.success': {
        color: 'var(--color-green-500)',
      },
      '&.info': {
        color: 'var(--color-blue-500)',
      },
    },
    message: {
      lineHeight: 1.3,
      fontSize: '13px',
    },
  }),
  { name: 'LeveledMessageList' },
);

export type SortType = 'level' | 'message';

export interface LeveledMessageListProps {
  // A list of messages annotated with a Level
  messages: LeveledMessage[];
  // Optionally limit list to a maximum number of entries
  maxToShow?: number;
}

export const LeveledMessageList = (props: LeveledMessageListProps) => {
  const { maxToShow, messages } = props;

  const classes = useStyles();

  const messagesToShow = maxToShow ? messages.slice(0, maxToShow) : messages;

  return (
    <ul className={classes.list}>
      {messagesToShow.map(({ message, level }) => (
        <li className={classes.listItem} key={message}>
          <div className={classes.listContent}>
            <div className={classes.iconContainer}>
              <div className={cx(classes.icon, level)}>
                <AlertIcon level={level} maxHeight={12} maxWidth={12} />
              </div>
            </div>
            <div className={classes.message}>{parseString(message)}</div>
          </div>
        </li>
      ))}
      {(messages.length > messagesToShow.length) && (
        <li>&hellip;</li>
      )}
    </ul>
  );
};
