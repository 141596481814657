// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { Any, Message } from '@bufbuild/protobuf';

// Represents a subclass of Message
export type MessageConstructor<T extends Message> = {
  new(...args: any[]): T;
}

/**
 * Pack a Message into the Any message type and set the typeUrl
 * @param proto
 * @returns
 */
export function packProto(message: Message): Any {
  const data = Any.pack(message);
  data.typeUrl = message.getType().typeName;
  return data;
}

/**
 * Unpack an Any proto message into a message of type MessageClass
 * @param data
 * @param MessageClass
 * @returns
 */
export function unpackProto<T extends Message>(
  data: Any | undefined,
  MessageClass: MessageConstructor<T>,
): T | null {
  if (data) {
    const result = new MessageClass();
    if (data.unpackTo(result)) {
      return result;
    }
  }
  return null;
}
