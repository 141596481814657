// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 50;
const baseHeight = 50;
export const DesktopMonitorFrameIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="desktopMonitorFrame"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M46.875,0
           L3.125,0
           C1.406,0 0,1.406 0,3.125
           L0,34.375
           C0,36.094 1.406,37.5 3.125,37.5
           L17.969,37.5
           L16.406,43.75
           L12.5,43.75
           C10.781,43.75 9.375,45.156 9.375,46.875
           C9.375,48.594 10.781,50 12.5,50
           L37.5,50
           C39.219,50 40.625,48.594 40.625,46.875
           C40.625,45.156 39.219,43.75 37.5,43.75
           L33.594,43.75
           L32.031,37.5
           L46.875,37.5
           C48.594,37.5 50,36.094 50,34.375
           L50,3.125
           C50,1.406 48.594,0 46.875,0Z
           M43.75,31.25
           L6.25,31.25
           L6.25,6.25
           L43.75,6.25
           L43.75,31.25Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M13.602,18.854
           L13.602,13.646
           L18.898,13.646
           C20.048,13.646 20.981,12.713 20.981,11.563
           C20.981,10.413 20.048,9.479 18.898,9.479
           L11.519,9.479
           C10.369,9.479 9.435,10.413 9.435,11.563
           L9.435,18.854
           C9.435,20.004 10.369,20.938 11.519,20.938
           C12.669,20.938 13.602,20.004 13.602,18.854Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M36.458,18.75
           L36.458,23.958
           L31.163,23.958
           C30.013,23.958 29.079,24.892 29.079,26.042
           C29.079,27.192 30.013,28.125 31.163,28.125
           L38.542,28.125
           C39.692,28.125 40.625,27.192 40.625,26.042
           L40.625,18.75
           C40.625,17.6 39.692,16.667 38.542,16.667
           C37.392,16.667 36.458,17.6 36.458,18.75Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
