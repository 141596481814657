// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { ActionButton } from '../../Button/ActionButton';
import { createStyles, makeStyles } from '../../Theme';

import ImageSelectionCell, { ImageCellData, ImageSelectionEmptyCell } from './ImageSelectionCell';

const useStyles = makeStyles(
  () => createStyles({
    row: {
      display: 'flex',
      gap: '16px',
    },
    pageRows: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      textAlign: 'center',
      fontSize: '14px',
    },
    menu: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      width: 'fit-content',
    },
    buttons: {
      display: 'flex',
      gap: '16px',
      justifyContent: 'center',
    },
  }),
  { name: 'ImageSelectionMenu' },
);

export interface ImageSelectionMenuProps {
  // If clicking on the menu items is disabled.
  disabled: boolean;
  // The number of rows to display at once.
  rows: number;
  // The number of columns to display at once.
  columns: number;
  // The data for all the images.
  cellData: ImageCellData[];
  // The index of the cell that is currently selected.
  selected: number;
  // Set the index of the cell that is currently selected.
  setSelected: (index: number) => void;
}

const ImageSelectionMenu = (props: ImageSelectionMenuProps) => {
  const classes = useStyles();
  const { disabled, rows, columns, cellData, selected, setSelected } = props;

  const [pageIndex, setPageIndex] = useState<number>(0);

  const cellsPerPage = rows * columns;
  const numPages = Math.ceil(cellData.length / cellsPerPage);
  const minColumnsPerRow = Math.min(cellData.length, columns);

  // Add rows to each page.
  const pageRows: React.ReactElement[] = [];
  for (let i = 0; i < rows; i += 1) {
    // Add cells to each row.
    const rowCells: React.ReactElement[] = [];
    let rowKey = `${i}`;
    for (let j = 0; j < minColumnsPerRow; j += 1) {
      const cellIndex = pageIndex * cellsPerPage + i * columns + j;
      if (cellIndex < cellData.length) {
        const key = `${cellData[cellIndex].text}-${i}-${j}`;
        rowKey = key;
        rowCells.push(
          <ImageSelectionCell
            cellData={cellData[cellIndex]}
            disabled={disabled}
            key={key}
            onClick={() => !disabled && setSelected(cellIndex)}
            selected={cellIndex === selected}
          />,
        );
      } else {
        rowCells.push(<ImageSelectionEmptyCell key={`empty-cell-${i}-${j}`} />);
      }
    }
    pageRows.push(<div className={classes.row} key={rowKey}>{rowCells}</div>);
  }

  // Add a previous and next button to increment or decrement the page index.
  const buttons = (
    <div className={classes.buttons}>
      <ActionButton
        disabled={disabled || pageIndex === 0}
        kind="secondary"
        onClick={() => !disabled && setPageIndex(pageIndex - 1)}
        size="small">
        {'<'}
      </ActionButton>
      <ActionButton
        disabled={disabled || pageIndex === numPages - 1}
        kind="secondary"
        onClick={() => !disabled && setPageIndex(pageIndex + 1)}
        size="small">
        {'>'}
      </ActionButton>
    </div>
  );

  return (
    <div className={classes.menu}>
      <div className={classes.pageRows}>{pageRows}</div>
      {buttons}
    </div>
  );
};

export default ImageSelectionMenu;
