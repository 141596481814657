// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const ArrowUpDownLeftRightIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="arrowUpDownLeftRight"
      height={finalHeight}
      ref={ref}
      style={{
        strokeMiterlimit: 2,
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        fill: getColor(0),
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M2.255,8.661L3.329,9.735C3.587,9.993 3.587,10.411 3.329,10.669C3.072,10.927 2.653,10.927
           2.395,10.669L0.193,8.467C-0.064,8.209 -0.064,7.791 0.193,7.533L2.395,5.331C2.653,5.073
           3.072,5.073 3.329,5.331C3.587,5.589 3.587,6.007 3.329,6.265L2.255,7.339L7.339,
           7.339L7.339,2.255L6.265,3.329C6.007,3.587 5.589,3.587 5.331,3.329C5.073,3.072 5.073,2.653
           5.331,2.395L7.533,0.193C7.791,-0.064 8.209,-0.064 8.467,0.193L10.669,2.395C10.927,2.653
           10.927,3.072 10.669,3.329C10.411,3.587 9.993,3.587 9.735,3.329L8.661,2.255L8.661,
           7.339L13.745,7.339L12.671,6.265C12.413,6.007 12.413,5.589 12.671,5.331C12.928,5.073
           13.347,5.073 13.605,5.331L15.807,7.533C16.064,7.791 16.064,8.209 15.807,8.467L13.605,
           10.669C13.347,10.927 12.928,10.927 12.671,10.669C12.413,10.411 12.413,9.993 12.671,
           9.735L13.745,8.661L8.661,8.661L8.661,13.745L9.735,12.671C9.993,12.413 10.411,12.413
           10.669,12.671C10.927,12.928 10.927,13.347 10.669,13.605L8.467,15.807C8.209,16.064 7.791,
           16.064 7.533,15.807L5.331,13.605C5.073,13.347 5.073,12.928 5.331,12.671C5.589,12.413
           6.007,12.413 6.265,12.671L7.339,13.745L7.339,8.661L2.255,8.661Z"
      />
    </svg>
  );
});
