// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 68;
const baseHeight = 80;
export const ConeIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="cone"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeMiterlimit: 2,
        fill: getColor(0),
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M66.815,53.032L37.539,2.047C35.993,-0.682 32.006,-0.682 30.46,2.047L1.191,
           53.027C2.887,51.673 4.837,50.471 6.939,49.434C14,45.951 23.571,43.871 34,43.871C44.429,
           43.871 54,45.951 61.061,49.434C63.165,50.473 65.118,51.676 66.815,53.032ZM0,64.516C0,
           61.032 2.931,57.182 9.279,54.051C15.47,50.996 24.207,49.032 34,49.032C43.793,49.032
           52.529,50.996 58.721,54.051C65.069,57.182 68,61.032 68,64.516C68,68.001 65.069,71.85
           58.721,74.982C52.529,78.036 43.793,80 34,80C24.207,80 15.47,78.036 9.279,74.982C2.931,
           71.85 0,68.001 0,64.516Z"
      />
    </svg>
  );
});
