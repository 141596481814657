// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Timestamp } from '@bufbuild/protobuf';
import { ConnectError } from '@connectrpc/connect';
import { custom } from '@recoiljs/refine';
import { AtomEffect, DefaultValue } from 'recoil';
import { ListenToItems, WriteItems, syncEffect } from 'recoil-sync';

import * as frontendpb from '../proto/frontend/frontend_pb';
import { selectedGeometryKey } from '../recoil/selectedGeometryKey';

import { Logger } from './observability/logs';
import { setState } from './persist';
import { isProjectAccessDeniedError, isProjectDeletedError } from './projectUtilsErrors';
import { StreamingRpcPool } from './rpc';
import { addRpcError } from './transientNotification';

const logger = new Logger('recoilSync.ts');
export function getSessionStoreId(projectId?: string) {
  return `sessionStore/${projectId}`;
}

// Time a state with a particular key was last set by the frontend.
const timeLastChanged = new Map<string, Timestamp>();
// Returns a function that listens to updates of SessionStateStream and updates the corresponding
// recoil atoms (if they are subscribed).
export function listenToSessionState(
  rpcPool: StreamingRpcPool<frontendpb.SessionStateRequest, frontendpb.SessionStateStreamReply>,
  projectId?: string,
  geometryId?: string,
): ListenToItems {
  return ({ updateItems }) => rpcPool.start(
    getSessionStoreId(projectId),
    () => new frontendpb.SessionStateRequest({ projectId: projectId ?? '' }),
    (reply) => {
      if (geometryId) {
        // We do not want to listen to kvstore updates in the geometry tab since the geometry tab
        // does not use the kvstore.
        return;
      }
      const stateEntries = new Map<string, unknown>();
      reply.entry.forEach((entry) => {
        const { key, timestamp } = entry;
        // Only update states if the value coming from the backend is newer.
        if (!timeLastChanged.get(key) ||
          (timeLastChanged.get(key)!.toDate().getTime() < timestamp!.toDate().getTime())) {
          stateEntries.set(key, entry.value);
        } else {
          logger.info(`debugging LC-15045 ignoring ${key}`);
        }
      });
      updateItems(stateEntries);
    },
    (err: ConnectError) => {
      if (!isProjectAccessDeniedError(err.message) && !isProjectDeletedError(err.message)) {
        addRpcError('Failed to get session state', err);
      }
    },
  );
}

// Returns a function that writes back all states that have changed during a recoil transaction
// to the backend session state storage
export function writeSessionState(
  projectId?: string,
  geometryId?: string,
): WriteItems {
  return ({ diff }) => {
    diff.forEach((value, key) => {
      // When geometryId is defined we only allow selectedGeometryKey to be set. This is because
      // that's the kvstore pair that allows us to perform the liason between the setup and geometry
      // tabs.
      if (geometryId && key !== selectedGeometryKey) {
        return;
      }
      timeLastChanged.set(key, Timestamp.fromDate(new Date(Date.now())));
      setState(projectId ?? '', key, value as Uint8Array);
    });
  };
}

// Returns an atomEffect that will sync an atom with a RecoilSync component. This is used to react
// to updates of the session state on the backend.
function syncSessionStateEffect<T>(
  storeKey: string,
  itemkey: string,
  deserialize: (val: Uint8Array) => T,
  serialize: (newVal: T) => Uint8Array,
) {
  return syncEffect<T>({
    refine: custom<T>((value) => (value as T), 'Wrong type returned by session state effect.'),
    storeKey,
    itemKey: itemkey,
    read: ({ read }) => {
      const val = read(itemkey);
      if (val instanceof DefaultValue) {
        return val;
      }
      return deserialize(val as Uint8Array);
    },
    write: ({ write }, newValue) => {
      if (!(newValue instanceof DefaultValue)) {
        write(itemkey, serialize(newValue));
      }
    },
    syncDefault: false,
  });
}

// Atom effect that syncs a project state with the SessionState component.
export function syncProjectStateEffect<T>(
  projectId: string,
  key: string,
  deserialize: (val: Uint8Array) => T,
  serialize: (newVal: T) => Uint8Array,
): AtomEffect<T> {
  return syncSessionStateEffect(getSessionStoreId(projectId), key, deserialize, serialize);
}

// Atom effect that syncs a user state with the SessionState component.
export function syncUserStateEffect<T>(
  key: string,
  deserialize: (val: Uint8Array) => T,
  serialize: (newVal: T) => Uint8Array,
): AtomEffect<T> {
  return syncSessionStateEffect(getSessionStoreId(), key, deserialize, serialize);
}
