// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { FormControlSize } from '../lib/componentTypes/form';
import { colors } from '../lib/designSystem';
import {
  isUnmodifiedArrowDownKey,
  isUnmodifiedArrowUpKey,
  isUnmodifiedSpaceKey,
  listen,
} from '../lib/event';
import { clamp, getDecimalPlaces } from '../lib/number';

import { NumberInput } from './Form/NumberInput';
import { createStyles, makeStyles } from './Theme';
import { useCommonInputContainerStyles } from './Theme/commonStyles';
import { ChevronUpIcon } from './svg/ChevronUpIcon';

const DEFAULT_STEP = 1;

const useStyles = makeStyles(
  () => createStyles({
    spinners: {
      flex: '0 0 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '3px',
    },
    spinner: {
      flex: '0 0 auto',
      display: 'flex',
      opacity: 0.35,
      transition: 'transform 250ms, opacity 250ms',
      outline: 0,
      border: 0,
      margin: 0,
      padding: '1px 4px',
      backgroundColor: 'transparent',
      color: colors.highEmphasisText,
      '&.down': {
        paddingBottom: '4px',
      },
      '&.up': {
        paddingTop: '4px',
      },
      '&.enabled': {
        cursor: 'pointer',
        opacity: 0.7,
        '&:hover': {
          opacity: 1,
        },
        '&:active, &.keying': {
          transform: 'translateY(1px)',
        },
        '&:focus': {
          opacity: 1,
        },
      },
    },
    iconContainer: {
      width: '8px',
      height: '5px',
      display: 'inline-flex',
      '&.down': {
        transform: 'rotate(180deg)',
      },
    },
  }),
  { name: 'NumberSpinner' },
);

interface SpinnerButtonProps {
  direction: 'down' | 'up';
  disabled?: boolean;
  onStart: (
    event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
  ) => void;
  onEnd: () => void;
}

const SpinnerButton = (props: SpinnerButtonProps) => {
  const { direction, disabled, onEnd, onStart } = props;

  // Track when the user is pressing the space key so we can change the CSS
  const [keying, setKeying] = useState(false);
  const classes = useStyles();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (isUnmodifiedSpaceKey(event)) {
      setKeying(true);
      onStart(event);
    }
  };

  const handleKeyUp = () => {
    setKeying(false);
    onEnd();
  };

  return (
    <button
      className={cx(classes.spinner, direction, { disabled, enabled: !disabled, keying })}
      data-locator={`number-spinner-button-${direction}`}
      disabled={disabled}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onMouseDown={onStart}
      onMouseLeave={onEnd}
      onMouseUp={onEnd}
      type="button">
      <div
        className={cx(classes.iconContainer, direction)}
        data-locator="numberSpinnerIconContainer">
        <ChevronUpIcon maxHeight={5} />
      </div>
    </button>
  );
};

SpinnerButton.defaultProps = { disabled: false };

interface StepOptions {
  scaleFactor: number,
}

export interface NumberSpinnerProps {
  value: number | null;
  maximumValue?: number;
  minimumValue?: number;
  // exclusiveMin/Max are used to indicate that the min/max values are exclusive
  exclusiveMin?: boolean;
  exclusiveMax?: boolean;
  // if scaleFactor is provided (i.e. step is a StepOptions),
  // pressing the 'up' arrow will multiply the input value by scaleFactor,
  // and pressing 'down' will divide it by scaleFactor.
  step?: number | StepOptions;
  placeholder?: string;
  disabled?: boolean;
  // onChange is called whenever the input value changes (equivalent to native onInput)
  onChange?: (value: number) => void;
  // onCommit is called whenever the input value is committed by (a) hitting ENTER
  // in the input; (b) blurring the input; or (c) spinning the value by clicking one
  // of the two spinner buttons.
  onCommit?: (value: number) => void;
  endAdornment?: ReactNode;
  faultType?: 'error' | 'warning';
  size?: FormControlSize;
}

interface SpinRepeater {
  startTime: number;
  up: boolean;
}

/* NumberSpinner mimics the native <input type="number" /> HTML element.
   Because there's no way to control the styling of the native element
   (particularly its up/down spinner buttons), it's necessary to craft a custom
   component.  Features include:
   - Enforce that the value is a number (onChange is only called when valid)
   - Enforce optional min/max values
   - Type up/down arrow keys to increment/decrement value
   - Hold up/down arrow keys to repeatedly increment/decrement value
   - Click up/down buttons to increment/decrement value
   - Hold up/down buttons to repeatedly increment/decrement value
   - TODO(cedric): support scroll wheel behavior
*/
export const NumberSpinner = (props: NumberSpinnerProps) => {
  const {
    disabled,
    maximumValue: max,
    minimumValue: min,
    exclusiveMin = false,
    exclusiveMax = false,
    onChange,
    onCommit,
    step = DEFAULT_STEP,
    value,
    endAdornment,
    faultType,
    size = 'small',
  } = props;

  const [spinRepeater, setSpinRepeater] = useState<SpinRepeater | undefined>();
  const [focused, setFocused] = useState(false);

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const validate = (val: number): boolean => {
    if (Number.isNaN(val)) {
      return false;
    }
    if ((max !== undefined) && (exclusiveMax ? (val >= max) : (val > max))) {
      return false;
    }
    if ((min !== undefined) && (exclusiveMin ? (val <= min) : (val < min))) {
      return false;
    }
    return true;
  };

  const handleChange = (numValue: number) => {
    onChange?.(numValue);
  };

  const handleCommit = (newValue: number) => {
    const isValid = validate(newValue);
    if (isValid) {
      // If the value is invalid, don't commit it if the user blurs the input. The user can
      // still commit the value by spinning the value but we will show the faultType.
      onCommit?.(newValue);
    }
  };

  const spinValue = useCallback((up: boolean, commit: boolean) => {
    let newValue: number = value ?? 1;
    if (Number.isNaN(newValue)) {
      newValue = (up ? min : max) ?? 0;
    }

    // if scaleFactor is not provided, use step instead
    // if scaleFactor is provided but the input value is 0, scaling it will do nothing, so increment
    // by 1 first, then only use scale.
    if (typeof step === 'number') {
      const delta = step * (up ? 1 : -1);
      newValue += delta;
    } else if (newValue === 0) {
      newValue = 1;
    } else {
      newValue = up ? (newValue * step.scaleFactor) : (newValue / step.scaleFactor);
    }

    if (max !== undefined) {
      newValue = Math.min(max, newValue);
    }

    if (min !== undefined) {
      newValue = Math.max(min, newValue);
    }

    if (typeof step === 'number') {
      const decimalCt = getDecimalPlaces(step);
      newValue = parseFloat(newValue.toFixed(decimalCt));
    }

    onCommit?.(newValue);
  }, [max, min, onCommit, step, value]);

  useEffect(() => {
    const handleKeyDown = (event: Event) => {
      if (!focused) {
        return;
      }
      const up = isUnmodifiedArrowUpKey(event as KeyboardEvent);
      const down = isUnmodifiedArrowDownKey(event as KeyboardEvent);
      if (up || down) {
        event.preventDefault();
        spinValue(up, false);
      }
    };

    const { remove } = listen(window, 'keydown', handleKeyDown);
    return () => remove();
  }, [focused, spinValue]);

  const handleSpinStart = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
    up: boolean,
  ) => {
    // If the user rapidly clicks and moves the mouse even a small amount,
    // the content of the spinner buttons will get selected.  Calling
    // event.preventDefault prevents that.
    event.preventDefault();
    setSpinRepeater({ startTime: Date.now(), up });
  };

  const clearSpinTimeout = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  };

  const handleSpinEnd = useCallback(() => {
    clearSpinTimeout();
  }, []);

  // This effect should depend only on spinRepeater, since it triggers changes
  // to the timeout.  The react-hooks/exhaustive-deps eslint rule is disabled
  // because it complains about a dependency on spinValue.
  useEffect(() => {
    clearSpinTimeout();

    if (spinRepeater) {
      spinValue(spinRepeater.up, true);

      // How many seconds has the user held down the spinner button?
      const delta = (Date.now() - spinRepeater.startTime) / 1000;

      // Ease in the spin delay from about 300ms when the user first clicks to
      // 50ms after the user has held down the button for 1s or longer
      const startInterval = 300;
      const endInterval = 50;
      const parameter = clamp(delta, [0, 1]);
      const interval = startInterval + parameter * (endInterval - startInterval);

      const tmt = setTimeout(() => {
        if (spinRepeater) {
          setSpinRepeater({ ...spinRepeater });
        }
      }, interval);
      timeout.current = tmt;
    }

    return clearSpinTimeout;
  }, [spinRepeater]); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();
  const inputClasses = useCommonInputContainerStyles();

  return (
    <NumberInput
      adornmentButton={(
        <div className={classes.spinners}>
          <SpinnerButton
            direction="up"
            disabled={!!disabled}
            onEnd={handleSpinEnd}
            onStart={(event) => handleSpinStart(event, true)}
          />
          <SpinnerButton
            direction="down"
            disabled={!!disabled}
            onEnd={handleSpinEnd}
            onStart={(event) => handleSpinStart(event, false)}
          />
        </div>
      )}
      disabled={disabled}
      endAdornment={endAdornment && (<div className={inputClasses.adornment}>{endAdornment}</div>)}
      faultType={validate(value ?? 0) ? faultType : 'error'}
      onBlur={() => setFocused(false)}
      onChange={handleChange}
      onCommit={handleCommit}
      onFocus={() => setFocused(true)}
      placeholder={props.placeholder}
      size={size}
      value={value}
    />
  );
};
