// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 20;
const baseHeight = 25;
export const PlayIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="play"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M20,12.5
           C20,11.625 19.525,10.9 18.85,10.45
           L3.85,0.45
           C3.475,0.2 3.025,0 2.5,0
           C1.125,0 0,1.125 0,2.5
           L0,22.5
           C0,23.875 1.125,25 2.5,25
           C3.025,25 3.475,24.8 3.85,24.55
           L18.85,14.55
           C19.525,14.1 20,13.375 20,12.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
