// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useRecoilState, useSetRecoilState } from 'recoil';

export const setupSummaryOpenedState = atom<boolean>({
  key: 'setupSummaryOpened',
  default: false,
});

export function useSetupSummaryOpened() {
  return useRecoilState(setupSummaryOpenedState);
}

export function useSetSetupSummaryOpened() {
  return useSetRecoilState(setupSummaryOpenedState);
}
