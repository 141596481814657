// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import assert from '../../lib/assert';
import { expandGroups } from '../../lib/entityGroupUtils';
import { generateDefaultShape, getFeatureInitialName, volumeNodeIdsToCadIds } from '../../lib/geometryUtils';
import { Logger } from '../../lib/observability/logs';
import * as random from '../../lib/random';
import * as rpc from '../../lib/rpc';
import { NodeType } from '../../lib/simulationTree/node';
import { addRpcError } from '../../lib/transientNotification';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as geometrypb from '../../proto/geometry/geometry_pb';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useGeometryServerStatus } from '../../recoil/geometry/geometryServerStatus';
import { DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE, GeometryState, useGeometrySelectedFeature, useGeometryState, useLatestTessellationValue, useSetGeometryState } from '../../recoil/geometry/geometryState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { initializeNewNode, useSetNewNodes } from '../../recoil/nodeSession';
import { useSelectedGeometry } from '../../recoil/selectedGeometry';
import { useCadMetadata } from '../../recoil/useCadMetadata';
import { useStaticVolumes } from '../../recoil/volumes';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

import { useGetNodeFromAnyTree } from './useTree';

const logger = new Logger('useInteractiveGeometry');

/*
  * Returns a reason why the delete button should be disabled. If the delete button should not be
  * disabled, returns undefined.
*/
function disableDeleteReason(
  readOnly: boolean,
  featureIndex: number | undefined,
  geometryServerStatus: string,
) {
  if (readOnly) {
    return 'Cannot delete features in read-only mode';
  }
  if (featureIndex === 0) {
    return 'Cannot delete initial geometry import';
  }
  if (geometryServerStatus === 'busy') {
    return 'Cannot delete features while the server is processing';
  }
  return undefined;
}

const getFaceId = (surfaceId: string) => {
  const faceId = surfaceId.split('/bound/BC_').at(1);
  assert(!!faceId, 'Expected a surface name');

  return Number(BigInt(faceId));
};

export const useDeleteGeometryModification = (rightClickNodeId?: string) => {
  const { projectId, geometryId, readOnly } = useProjectContext();
  const { selectedNode: node, setSelection } = useSelectionContext();

  const geoState = useGeometryState(projectId, geometryId);
  const setGeoState = useSetGeometryState(projectId, geometryId);
  const [geometryServerStatus, setGeometryServerStatus] = useGeometryServerStatus(geometryId);
  const [, setSelectedFeature] = useGeometrySelectedFeature(geometryId);

  // When right clicking, the selected node may be null or a different node altogether. For the
  // right click context menu, we pass in the id of the feature we want to delete.
  const featureId = rightClickNodeId || node?.id;

  const featureIndex = useMemo(() => (
    geoState?.geometryFeatures.findIndex((item) => item.id === featureId)
  ), [geoState?.geometryFeatures, featureId]);
  const disabledDeleteReason = disableDeleteReason(readOnly, featureIndex, geometryServerStatus);

  const deleteRow = async () => {
    if (!featureId) {
      return;
    }
    // First step is to always delete the feature from the client's state. We will decide later
    // what to do on the server-side.
    setGeoState((oldGeoState) => {
      if (oldGeoState === undefined) {
        return undefined;
      }
      return {
        ...oldGeoState,
        geometryFeatures: oldGeoState.geometryFeatures.filter(({ id }) => id !== featureId),
      };
    });

    // Update the selected feature, so that the feature manager does not end up with a stale
    // reference to the latest feature. See LC-21271.
    setSelectedFeature(DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE);
    // The server returns the latest tessellation so we need to reset the selection state so that
    // we are consistent with the tessellation being sent back.
    setSelection([]);

    // If the server is aware of the feature we are deleting, we need to request a deletion to
    // the server.
    const acked = geoState?.ackModifications.has(featureId);
    if (acked) {
      setGeometryServerStatus('busy');
      const feature = new geometrypb.Feature({
        id: featureId,
      });
      const req = new geometryservicepb.ModifyGeometryRequest({
        geometryId,
        modification: new geometrypb.Modification({
          modType: geometrypb.Modification_ModificationType.DELETE_FEATURE,
          feature,
        }),
      });
      try {
        await rpc.clientGeometry!.modifyGeometry(req).catch((error) => logger.error(error));
      } catch (error) {
        addRpcError('Failed to delete geometry modification', error);
      }
    }
  };

  return { deleteRow, disabledDeleteReason };
};

/**
 * Returns true if the geometry in the setup tab is up to date with the latest geometry version.
 * */
export const useIsSetupGeometryUpToDate = () => {
  const { projectId, geometryId } = useProjectContext();

  const geoState = useGeometryState(projectId, geometryId);
  const [selectedGeometry] = useSelectedGeometry(projectId);

  const existingGeoVersionId = selectedGeometry?.geometryVersionId;
  const lastEntry = geoState?.geometryHistory[geoState.geometryHistory.length - 1];
  const geometryVersionId = lastEntry?.historyEntry?.geometryVersionNewId;

  return !!existingGeoVersionId && geometryVersionId === existingGeoVersionId;
};

/**
 * Returns a hook to be called when renaming a geometry feature and a boolean indicating whether
 * the feature renaming is disabled.
 */
export const useRenameGeometryFeature = () => {
  const { projectId, geometryId, readOnly } = useProjectContext();
  const geoState = useGeometryState(projectId, geometryId);
  const setGeoState = useSetGeometryState(projectId, geometryId);
  const [geometryServerStatus, setGeometryServerStatus] = useGeometryServerStatus(geometryId);
  const isFeatureRenameDisabled = readOnly || geometryServerStatus === 'busy';

  const renameGeometryFeature = (featureId: string, newName: string) => {
    if (!geoState) {
      return;
    }

    // Start by updating our local state with the new name.
    let sameName = false;
    setGeoState((oldGeoState) => {
      if (!oldGeoState) {
        return undefined;
      }
      return {
        ...oldGeoState,
        geometryFeatures: oldGeoState.geometryFeatures.map((feature) => {
          if (feature.id === featureId) {
            sameName = feature.featureName === newName;
            if (!sameName) {
              return {
                ...feature,
                featureName: newName,
              };
            }
          }
          return feature;
        }),
      } as GeometryState;
    });

    if (sameName) {
      return;
    }

    // If this feature has not been sent to the server yet, we don't need to send a request. This
    // can happen if users try to rename the feature before clicking on apply.
    if (!geoState.ackModifications.has(featureId)) {
      return;
    }

    setGeometryServerStatus('busy');
    // Request a rename to the server.
    const req = new geometryservicepb.ModifyGeometryRequest({
      geometryId,
      modification: new geometrypb.Modification({
        modType: geometrypb.Modification_ModificationType.RENAME_FEATURE,
        feature: new geometrypb.Feature({
          id: featureId,
          featureName: newName,
        }),
      }),
    });
    req.requestId = random.string(32);
    rpc.clientGeometry?.modifyGeometry(req).catch((err) => {
      logger.error('Failed to rename geometry modification', err);
    });
  };

  return { renameGeometryFeature, isFeatureRenameDisabled };
};

export const useTagsInteractiveGeometry = () => {
  const { projectId, geometryId, readOnly, jobId, workflowId } = useProjectContext();
  const geoState = useGeometryState(projectId, geometryId);
  const [geometryServerStatus, setGeometryServerStatus] = useGeometryServerStatus(geometryId);
  const geometryTags = useGeometryTags(projectId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const isGeometryView = useIsGeometryView();
  const getNodeFromAnyTree = useGetNodeFromAnyTree();
  const isTagRenameDisabled = readOnly || geometryServerStatus === 'busy' || !isGeometryView;
  const isCreateTagDisabled = isTagRenameDisabled;
  const isRemoveTagDisabled = isTagRenameDisabled;
  const [cadMetadata] = useCadMetadata(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  const renameTag = async (nodeId: string, newName: string) => {
    if (!geoState) {
      return;
    }

    assert(geometryTags.isTagId(nodeId), 'Expected a tag ID');

    const oldName = geometryTags.tagNameFromId(nodeId);
    // The backend returns an error in this case. This can also happen when focusing away of the
    // renaming operation with ESC or similar.
    if (oldName === newName) {
      return;
    }
    assert(!!oldName, 'Expected a tag name');
    setGeometryServerStatus('busy');
    const req = new geometryservicepb.ModifyGeometryRequest({
      geometryId,
      requestId: random.string(32),
      modification: new geometrypb.Modification({
        modType: geometrypb.Modification_ModificationType.RENAME_TAG,
        renameTag: new geometrypb.RenameTag({
          newName,
          oldName,
        }),
      }),
    });
    await rpc.clientGeometry?.modifyGeometry(req).catch((err) => {
      addRpcError('Failed to rename tag', err);
      logger.error('Failed to rename geometry modification', err);
    });
  };

  const removeItemFromTag = async (
    tagId: string,
    itemToRemove: { surfaceId: string; } | { volumeId: string; },
  ) => {
    const foundTag = geometryTags.tags.find(({ id }) => id === tagId);
    assert(!!foundTag, `"${tagId}" is not tag`);

    const deleteTag = new geometrypb.DeleteTag({ name: foundTag.name });

    if ('surfaceId' in itemToRemove) {
      deleteTag.faces = [getFaceId(itemToRemove.surfaceId)];
    } else {
      const cadId = volumeNodeIdsToCadIds(
        [itemToRemove.volumeId],
        staticVolumes,
        cadMetadata,
      ).at(0);

      assert(cadId !== undefined, `Expected cadID for volume "${itemToRemove.volumeId}"`);
      deleteTag.bodies = [Number(cadId)];
    }

    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification: new geometrypb.Modification({
        modType: geometrypb.Modification_ModificationType.DELETE_TAG,
        deleteTag: new geometrypb.CreateOrUpdateTag(deleteTag),
      }),
    });

    setGeometryServerStatus('busy');
    await rpc.clientGeometry?.modifyGeometry(req).catch((err) => {
      addRpcError('Failed to update tag', err);
      logger.error('Failed to rename geometry modification', err);
    });
  };

  const addVolumeToExistingTag = async (tagId: string, volumeId: string) => {
    const foundTag = geometryTags.tags.find(({ id }) => id === tagId);
    assert(!!foundTag, `"${tagId}" is not tag`);

    assert(!!volumeId, 'Expected a volume name');
    const cadIdBigInt = volumeNodeIdsToCadIds([volumeId], staticVolumes, cadMetadata).at(0);

    assert(cadIdBigInt !== undefined, 'Expected a cad id');
    const cadId = Number(cadIdBigInt);

    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification: new geometrypb.Modification({
        modType: geometrypb.Modification_ModificationType.UPDATE_TAG,
        createOrUpdateTag: new geometrypb.CreateOrUpdateTag({
          name: foundTag.name,
          faces: foundTag.faceIds,
          bodies: [...foundTag.bodyIds, cadId],
        }),
      }),
    });

    setGeometryServerStatus('busy');
    await rpc.clientGeometry?.modifyGeometry(req).catch((err) => {
      addRpcError('Failed to update tag', err);
      logger.error('Failed to rename geometry modification', err);
    });
  };

  const addSurfaceToExistingTag = async (tagId: string, surfaceId: string) => {
    const foundTag = geometryTags.tags.find(({ id }) => id === tagId);
    assert(!!foundTag, `"${tagId}" is not tag`);

    const faceId = getFaceId(surfaceId);

    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification: new geometrypb.Modification({
        modType: geometrypb.Modification_ModificationType.UPDATE_TAG,
        createOrUpdateTag: new geometrypb.CreateOrUpdateTag({
          name: foundTag.name,
          faces: [...foundTag.faceIds, faceId],
          bodies: foundTag.bodyIds,
        }),
      }),
    });

    setGeometryServerStatus('busy');
    await rpc.clientGeometry?.modifyGeometry(req).catch((err) => {
      addRpcError('Failed to update tag', err);
      logger.error('Failed to rename geometry modification', err);
    });
  };

  const createTag = async (tagName: string, nodeIds: string[]) => {
    if (!geoState) {
      return;
    }

    const ids = expandGroups(entityGroupData.leafMap)(nodeIds);
    const faceIds = ids.map((surface) => {
      const node = getNodeFromAnyTree(surface);
      if (!node) {
        return undefined;
      }

      if (node.type === NodeType.SURFACE) {
        const faceId = getFaceId(surface);

        return faceId;
      }

      return undefined;
    }).filter((id) => id !== undefined) as number[];

    const bodyIds = ids.map((surface) => {
      const node = getNodeFromAnyTree(surface);
      if (!node) {
        return undefined;
      }

      if (node.type === NodeType.VOLUME) {
        return Number(volumeNodeIdsToCadIds([surface], staticVolumes, cadMetadata)[0]);
      }
      return undefined;
    }).filter((id) => id !== undefined) as number[];

    setGeometryServerStatus('busy');
    // Request a rename to the server.
    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification: new geometrypb.Modification({
        modType: geometrypb.Modification_ModificationType.CREATE_TAG,
        createOrUpdateTag: new geometrypb.CreateOrUpdateTag({
          name: tagName,
          faces: faceIds,
          bodies: bodyIds,
        }),
      }),
    });
    await rpc.clientGeometry?.modifyGeometry(req).catch((err) => {
      addRpcError('Failed to create tag', err);
      logger.error('Failed to rename geometry modification', err);
    });
  };

  const removeTags = async (nodeIds: string[]) => {
    if (!geoState) {
      return;
    }

    setGeometryServerStatus('busy');
    const req = () => {
      assert(nodeIds.length > 0, 'Expected at least one tag ID');
      assert(geometryTags.isTagId(nodeIds[0]), 'Expected a tag ID');
      if (nodeIds.length === 1) {
        const oldName = geometryTags.tagNameFromId(nodeIds[0]);
        return new geometryservicepb.ModifyGeometryRequest({
          geometryId,
          requestId: random.string(32),
          modification: new geometrypb.Modification({
            modType: geometrypb.Modification_ModificationType.DELETE_TAG,
            deleteTag: new geometrypb.DeleteTag({
              name: oldName,
            }),
          }),
        });
      }
      const tagNames = nodeIds.map((id) => {
        assert(geometryTags.isTagId(id), 'Expected a tag ID');
        return geometryTags.tagNameFromId(id)!;
      });
      return new geometryservicepb.ModifyGeometryRequest({
        geometryId,
        requestId: random.string(32),
        modification: new geometrypb.Modification({
          modType: geometrypb.Modification_ModificationType.DELETE_TAGS,
          deleteTags: new geometrypb.DeleteTags({
            names: tagNames,
          }),
        }),
      });
    };

    await rpc.clientGeometry?.modifyGeometry(req()).catch((err) => {
      addRpcError('Failed to remove tag', err);
      logger.error('Failed to rename geometry modification', err);
    });
  };

  return {
    renameTag,
    isTagRenameDisabled,
    createTag,
    isCreateTagDisabled,
    removeTag: removeTags,
    isRemoveTagDisabled,
    addSurfaceToExistingTag,
    addVolumeToExistingTag,
    removeItemFromTag,
  };
};
/**
 * Hook to create a new modification.
 * @param prepopulateBodies whether to prepopulate the bodies of the new modification with the
 * currently selected volumes.
 * @returns a callback that creates a new modification.
 */
export const useCreateNewModification = (prepopulateBodies: boolean = false) => {
  const { projectId, geometryId } = useProjectContext();
  const setGeometryState = useSetGeometryState(projectId, geometryId);
  const geoState = useGeometryState(projectId, geometryId);
  const setNewNodes = useSetNewNodes();
  const { selectedNodeIds, setSelection, setScrollTo } = useSelectionContext();
  const latestTessellation = useLatestTessellationValue(geometryId);
  const staticVolumes = useStaticVolumes(projectId);
  const cadMetadata = geoState?.cadMetadata;

  return useCallback((operation: geometrypb.Feature['operation']) => {
    // We make this async so that we can get the latest tessellation before creating the new
    // modification, then call the inner async function to create the modification.
    const asyncInner = async () => {
      // If the current geometry state isn't from the latest tessellation, we need to get the
      // latest tessellation before creating a new modification.
      if (
        !latestTessellation?.cadMetadata?.equals(geoState?.cadMetadata) ||
        !latestTessellation.lcnMeta?.equals(geoState?.metadata)
      ) {
        const req = new geometryservicepb.LatestTessellationRequest({
          geometryId,
        });
        // The subscription part will handle the return value from here, we don't retry yet.
        try {
          await rpc.clientGeometry!.latestTessellation(req);
        } catch (error) {
          addRpcError('Failed to update the tessellation', error);
        }
      }

      if (operation.case === 'create') {
        generateDefaultShape(geoState!.cadMetadata, operation.value as geometrypb.Create);
      } else if (operation.case === 'farfield') {
        generateDefaultShape(
          geoState!.cadMetadata,
          (operation.value as geometrypb.Farfield).create!,
        );
      }

      const getBodies = () => {
        if (cadMetadata) {
          return volumeNodeIdsToCadIds(selectedNodeIds, staticVolumes, cadMetadata);
        }
        return [];
      };
      const id = random.string(32);
      setGeometryState((oldGeometryState) => {
        if (oldGeometryState === undefined) {
          return oldGeometryState;
        }
        const newFeature = new geometrypb.Feature({
          id,
          operation,
        });
        newFeature.featureName = getFeatureInitialName(
          newFeature,
          oldGeometryState.geometryFeatures,
        );
        const newGeometryState = { ...oldGeometryState };
        newGeometryState.geometryFeatures.push(newFeature);

        if (prepopulateBodies && selectedNodeIds.length > 0) {
          // If the operation is something that takes bodies as input, prepopulate it with the
          // currently selected volumes, if any exist.
          switch (newFeature.operation.case) {
            case 'delete':
              newFeature.operation.value.ids = getBodies();
              break;
            case 'transform':
            case 'pattern':
            case 'imprint':
            case 'shrinkwrap':
              newFeature.operation.value.body = getBodies();
              break;
            case 'boolean': {
              const bool = newFeature.operation.value.op.value;
              if (bool?.bodies) {
                bool.bodies = getBodies();
              }
            }
              break;
            default:
            // none
          }
        }

        return newGeometryState;
      });
      const newNode = initializeNewNode(id);
      setNewNodes((nodes) => [...nodes, newNode]);
      setSelection([id]);
      setScrollTo({ node: id });
    };
    asyncInner().catch((err) => {
      logger.error(err);
    });
  }, [geometryId, geoState, latestTessellation,
    setGeometryState, setNewNodes, setSelection, setScrollTo,
    selectedNodeIds, staticVolumes, cadMetadata, prepopulateBodies]);
};
