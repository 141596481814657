// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import * as Vector from '../../../lib/Vector';
import * as basepb from '../../../proto/base/base_pb';
import Form from '../../Form';
import { Vector3Input } from '../../Form/Vector3Input';
import { UnitVectorButtons } from '../../controls/UnitVectorButtons';

export interface LineParam {
  // Point 1 on the ine.
  point1: basepb.Vector3;
  // Point 2 on the line..
  point2: basepb.Vector3;
}

export interface LineInputProps {
  // The value to display
  param: LineParam;
  // Can the value be modified by the user? (default: false)
  readOnly?: boolean;
  // Called when the value is committed
  onCommit: (newParam: LineParam) => void;
  // Called when the value is changed
  onChange?: (newParam: LineParam) => void;
}

/** Dialog for specifying parameters for a Line in 3D space.  It has inputs for two vectors: point1
 *  and point2.
*/
export const LineInput = (props: LineInputProps) => {
  // Unpack props
  const { onChange, onCommit, param, readOnly } = props;
  const { point1, point2 } = param;

  return (
    <React.Fragment>
      <Form.LabeledInput help="Point 1 on the line" label="Point 1">
        <Vector3Input
          disabled={readOnly}
          onChange={(newPoint) => onChange?.({ ...param, point1: newPoint })}
          onCommit={(newPoint) => onCommit({ ...param, point1: newPoint })}
          value={point1}
        />
      </Form.LabeledInput>
      <Form.LabeledInput help="Point 2 on the line" label="Point 2">
        <Vector3Input
          disabled={readOnly}
          onChange={(newPoint) => onChange?.({ ...param, point2: newPoint })}
          onCommit={(newPoint) => onCommit({ ...param, point2: newPoint })}
          value={point2}
        />
      </Form.LabeledInput>
      <Form.LabeledInput label="">
        {readOnly ? null : (
          <Form.Group>
            <UnitVectorButtons
              onClick={(x, y, z) => {
                const newPoint = Vector.newProto(x, y, z);
                onCommit({
                  ...param,
                  point1: Vector.mult(newPoint, -1),
                  point2: newPoint,
                });
              }}
            />
          </Form.Group>
        )}
      </Form.LabeledInput>
    </React.Fragment>
  );
};

LineInput.defaultProps = {
  readOnly: false,
};
