// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import DOMPurify from 'dompurify';
import { escape } from 'html-escaper';
import parseHTMLString from 'html-react-parser';

// HTML utilities

// The function accepts a string with html tags and transforms it such a way that these
// tags will be rendered as html in the React component and not be shown as plain strings.
// But it does it in a safe XSS way by stripping all vulnerable tags that are known to cause
// problems like <script>, <iframe>, etc.).
export function parseString(
  value: string | undefined,
): string | ReturnType<typeof parseHTMLString> {
  if (value) {
    const cleanValue = DOMPurify.sanitize(value, { USE_PROFILES: { html: true } });
    return parseHTMLString(cleanValue);
  }
  return '';
}

export function boldEscaped(name: string) {
  return `<b>${escape(name)}</b>`;
}
