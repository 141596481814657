// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useEffect, useRef, useState } from 'react';

// The hook accepts a seconds prop which will be used to start a countdown to 0. The countdown
// doesn't start imemdiatelly but is managed by the methods from the returned object.
const useCountdown = (seconds: number) => {
  // State
  const [secondsLeft, setSecondsLeft] = useState(seconds);
  const [completed, setCompleted] = useState(false);

  // Refs
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  // Handlers
  const removeInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const startCountdown = useCallback(() => {
    removeInterval();
    intervalRef.current = setInterval(() => {
      setSecondsLeft((value) => value - 1);
    }, 1000);
  }, [setSecondsLeft]);

  // Effects
  useEffect(() => {
    if (secondsLeft <= 0) {
      removeInterval();
      setCompleted(true);
    }
  }, [secondsLeft]);

  return {
    completed,
    secondsLeft,
    startCountdown,
    pauseCountdown: removeInterval,
    resetCountdown: () => {
      setSecondsLeft(seconds);
      setCompleted(false);
      startCountdown();
    },
  };
};

export default useCountdown;
