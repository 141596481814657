// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { format } from 'util';

import { SeverityNumber } from '@opentelemetry/api-logs';
import { OTLPLogExporter } from '@opentelemetry/exporter-logs-otlp-http';
import { BatchLogRecordProcessor, InMemoryLogRecordExporter, LoggerProvider } from '@opentelemetry/sdk-logs';

import { pageVersion } from '../RuntimeParams';
import { sessionUrl } from '../logrocketSetup';
import { isTestingEnv } from '../testing/utils';

import { localhost, resource, serviceName } from './constants';

const logName = 'browser';
const otelExporterOtlpLogsEndpoint = `https://${window.location.hostname}/v1/logs`;

const provider = new LoggerProvider({ resource });
// To reduce noise when running on localhost, just keep the logs in memory and don't send them
// anywhere.
const exporter = (window.location.hostname === localhost) ?
  new InMemoryLogRecordExporter() :
  new OTLPLogExporter({ url: otelExporterOtlpLogsEndpoint });

provider.addLogRecordProcessor(new BatchLogRecordProcessor(exporter));

const logger = provider.getLogger(serviceName, pageVersion);

export class Logger {
  constructor(
    private readonly scope: string,
    private readonly label: string = '',
    public verbose = false,
  ) {
  }

  public debug(msg?: string, ...param: any[]) {
    // Calling format with an empty param will append a [] to the end of the message
    // which we don't want
    const message = this.format(msg, param);
    if (this.verbose) {
      console.debug(message);
    }
    // TODO(LC-11547): Send debug logs to GCP conditionally on a toggle in the UI.
    // logger.emit({
    //   severityNumber: SeverityNumber.DEBUG,
    //   severityText: 'debug',
    //   body: message,
    //   attributes: {
    //     'gcp.log_name': logName,
    //     scope: this.scope,
    //   },
    // });
  }

  public info(msg?: string, ...param: any[]) {
    const message = this.format(msg, param);
    if (this.verbose) {
      console.info(message);
    }
    if (isTestingEnv()) {
      return;
    }
    logger.emit({
      severityNumber: SeverityNumber.INFO,
      severityText: 'info',
      body: message,
      attributes: this.buildAttrs(),
    });
  }

  public warn(msg?: string, ...param: any[]) {
    const message = this.format(msg, param);
    console.warn(message);
    if (isTestingEnv()) {
      return;
    }
    logger.emit({
      severityNumber: SeverityNumber.WARN,
      severityText: 'warn',
      body: message,
      attributes: this.buildAttrs(),
    });
  }

  public error(msg?: string, ...param: any[]) {
    const message = this.format(msg, param);
    console.error(message);
    if (isTestingEnv()) {
      return;
    }
    logger.emit({
      severityNumber: SeverityNumber.ERROR,
      severityText: 'error',
      body: message,
      attributes: this.buildAttrs(),
    });
  }

  public fatal(msg?: string, ...param: any[]) {
    const message = this.format(msg, param);
    console.error(message);
    if (isTestingEnv()) {
      return;
    }
    logger.emit({
      severityNumber: SeverityNumber.FATAL,
      severityText: 'fatal',
      body: message,
      attributes: this.buildAttrs(),
    });
  }

  private buildAttrs() {
    return {
      logRocketSession: sessionUrl(),
      'gcp.log_name': logName,
      scope: this.scope,
      lc_label: this.label,
    };
  }

  private format(msg?: string, param?: any) {
    return param && param.length > 0 ? format(msg, param) : msg;
  }
}
