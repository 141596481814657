// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { Message } from '@bufbuild/protobuf';

import { ParamGroup, ParamType } from '../ProtoDescriptor';

import { newAdFloat, newScalarAdVector } from './adUtils';
import newInt from './intUtils';
import { paramCallback } from './paramCallback';

// Intializes a proto message and all its children with default values
export function initParamGroupProto<T extends Message>(
  paramProto: T,
  group: ParamGroup,
) {
  paramCallback<any>(
    paramProto,
    group,
    ({ param, set }) => {
      switch (param.type) {
        case ParamType.MULTIPLE_CHOICE:
          set(param.defaultValue ?? param.choices[0].enumNumber);
          break;
        case ParamType.VECTOR3:
          if (param.defaultValue) {
            const { x, y, z } = param.defaultValue;
            const vector = newScalarAdVector(x, y, z);
            set(vector);
          }
          break;
        case ParamType.REAL:
          set(newAdFloat((param.defaultValue as number) ?? 0));
          break;
        case ParamType.INT:
          set(newInt((param.defaultValue as number) ?? 0));
          break;
        default:
          if (param.defaultValue) {
            set(param.defaultValue);
          }
          break;
      }
    },
    () => { },
    null,
  );
  return paramProto;
}
