// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
// Returns true if we are running in test environment
export function isTestingEnv() {
  return process.env.NODE_ENV === 'test' || process.env.JEST_WORKER_ID !== undefined;
}

export function isDevelopmentEnv() {
  return process.env.NODE_ENV === 'development';
}

export function isStorybookEnv() {
  return !!process.env.STORYBOOK;
}

type MockPropAccess = {
  // name of the property accessed
  name?: string,
  // the arguments passed if this was a function call. Undefined if this was an access of some
  // non-function property.
  argsIfFunc?: any[],
  // the MockPropAccesses on the object returned by this access
  nested?: MockPropAccess[],
}

const emptyTrace = (): MockPropAccess[] => [];

type RecursiveMock = any & { trace: MockPropAccess[] }

/**
 * Creates a generic mock object with recursive behavior.
 * You can chain property accesses or method calls, where
 * each access returns another mock object. Querying for recursiveMock.trace gives an object
 * telling us which functions or properties were accessed on the mock.
 *
 * This is useful for objects like the LcvModule, since not all of its functions are exposed to
 * our UI code, so it gives us a way to mock all the possible functions that can be called on it
 * (and their return values) without going through each one individually. It also lets us know
 * what functions and properties were accessed, even if the accesses are hidden inside of many
 * different classes.
 */
export const createRecursiveMock = (trace: MockPropAccess[] = emptyTrace()): RecursiveMock => {
  // The apply proxy trap doesn't know the name of the function it intercepted. And the get trap
  // doesn't know if the property accessed was a function. So by the end of the chain of calls, we
  // may end up with parts like {name: 'one', nested: [{argsIfFunc: [0, 1]}]} when instead that part
  // should be {name: 'one', argsIfFunc: [0, 1], nested: []} to repesent the call obj.one(0, 1).
  // So we should flatten the function calls to make the trace more readable.
  const condenseTrace = (traceToCondense: MockPropAccess[]) => {
    const newTrace = [...traceToCondense];
    newTrace.forEach((tr) => {
      if (tr.name && !tr.argsIfFunc && tr.nested?.length === 1 && tr.nested[0].argsIfFunc) {
        tr.argsIfFunc = tr.nested[0].argsIfFunc;
        tr.nested = condenseTrace(tr.nested[0].nested!);
      } else {
        tr.nested = condenseTrace(tr.nested ?? []);
      }
    });
    return newTrace;
  };

  const handler: ProxyHandler<any> = {
    get: (_, prop) => {
      if (prop === 'trace') {
        return condenseTrace(trace);
      }
      const newAccess: MockPropAccess = {
        name: prop as string,
        nested: [],
      };
      trace.push(newAccess);
      return createRecursiveMock(newAccess.nested);
    },
    apply: (a, b, args) => {
      const funcTrace: MockPropAccess = {
        argsIfFunc: args,
        nested: [],
      };
      trace.push(funcTrace);
      return createRecursiveMock(funcTrace.nested);
    },
  };
  return new Proxy(() => {}, handler);
};
