// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const RotateIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="rotate"
      height={finalHeight}
      ref={ref}
      style={{
        strokeMiterlimit: 2,
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        fill: getColor(0),
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M2,5.395L6.011,5.395L6.011,6.782L0.797,6.782C0.357,6.782 0,6.426 0,5.986L0,0.781L1.387,
           0.781L1.387,3.942C1.754,3.527 2.275,2.972 3.013,2.234C5.992,-0.745 10.822,-0.745 13.802,
           2.234C14.857,3.29 15.539,4.578 15.847,5.934L16,6.611L14.647,6.918L14.494,6.241C14.242,
           5.132 13.684,4.079 12.821,3.215C10.383,0.778 6.431,0.778 3.994,3.215C2.932,4.277 2.304,
           5.017 2,5.395Z"
      />
      <rect height="6.935" width="9.247" x="3.376" y="9.065" />
    </svg>
  );
});
