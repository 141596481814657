// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
// Some helpers for Luminary's color library.

import { colors } from './designSystem';

// The list of Luminary's tertiary colors.
export const tertiaryColors = [
  colors.violet600,
  colors.orange600,
  colors.cyan600,
  colors.citron600,
  colors.pink600,
  colors.citron100,
  colors.greenYellow600,
];

export const getTertiaryColor = (position: number) => (
  tertiaryColors[position % tertiaryColors.length]
);
