// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { selectorFamily, waitForAll } from 'recoil';

import { explorationVariableNodeId, getLabel, getProtoForType } from '../../../lib/explorationUtils';
import {
  NodeType,
  POLICY_CONTAINER_ID,
  ROOT_EXPLORATION_CONTAINER_ID,
  SimulationTreeNode,
  VARIABLE_CONTAINER_ID,
} from '../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../lib/simulationTree/sectionRecoilKey';
import { currentConfigSelector } from '../../../recoil/workflowConfig';
import { simulationParamState } from '../../external/project/simulation/param';
import { simulationBoundaryNamesState } from '../../external/project/simulation/param/boundaryNames';

import { outputsSectionSelector } from './section/output';

export const explorationTreeSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'explorationTreeSelector',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    const [config, param] = get(waitForAll([
      currentConfigSelector(key),
      simulationParamState(key),
    ]));
    const explorationChildren: SimulationTreeNode[] = [];
    const { exploration } = config;

    if (exploration) {
      const [outputSection, bcNames] = get(waitForAll([
        outputsSectionSelector(key.projectId),
        simulationBoundaryNamesState(key),
      ]));
      const userDefinedVars = exploration.var.filter(
        (variable) => !variable.synthetic,
      );
      const hasSensitivity = exploration.policy.case === 'sensitivityAnalysis';
      const variablesChildren = userDefinedVars.filter(
        (variable) => {
          const varSpec = variable.spec!;
          if (!getProtoForType(param, varSpec.type, varSpec.id)) {
            console.error('Exploration variable could not be found:', varSpec);
            return false;
          }
          return true;
        },
      ).map((variable, index) => new SimulationTreeNode(
        NodeType.EXPLORATION_VARIABLE,
        explorationVariableNodeId(index),
        getLabel(param, bcNames, variable.spec!),
      ));
      const variables = new SimulationTreeNode(
        NodeType.EXPLORATION_VARIABLE_CONTAINER,
        VARIABLE_CONTAINER_ID,
        'Inputs',
        variablesChildren,
      );

      if (!hasSensitivity) {
        explorationChildren.push(
          new SimulationTreeNode(
            NodeType.EXPLORATION_POLICY,
            POLICY_CONTAINER_ID,
            'Exploration Policy',
          ),
        );
      }

      explorationChildren.push(variables, outputSection);
    }

    return new SimulationTreeNode(
      NodeType.ROOT_EXPLORATION,
      ROOT_EXPLORATION_CONTAINER_ID,
      'Exploration',
      explorationChildren,
    );
  },
  dangerouslyAllowMutability: true,
});
