// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import { DateTime } from 'luxon';

import { ActionButton } from '../../components/Button/ActionButton';
import { ActionLink } from '../../components/Button/ActionLink';
import { AuthPageLayout, useAuthPageLayoutStyles } from '../../components/layout/page/AuthPageLayout';
import { saveStringAsTxt } from '../../lib/saveAsTxt';

interface Setup2FABackupProps {
  recoveryCode: string;
  onContinue: () => void;
}

// This page will be shown when the user sucessfully configures an MFA method and as a next step
// has to write down their recovery.
// It is also used if the user has logged in via their recovery code (bc they don't have access to
// their device) and they are shown a new recovery code for their next login.
// The parent component has the job to go to the next step when the Continue button is pressed.
const Setup2FABackup = (props: Setup2FABackupProps) => {
  // Props
  const { recoveryCode, onContinue } = props;

  // Hooks
  const authClasses = useAuthPageLayoutStyles();

  // Handlers
  const handleDownload = () => {
    const timestamp = DateTime.local().toFormat('yyyyMMdd_HHmm');
    saveStringAsTxt(recoveryCode, `LuminaryCloud_Backup_Code_${timestamp}.txt`);
  };

  return (
    <AuthPageLayout
      subtitle={(
        <>
          In the event that you need to login without your device you’ll need a recovery
          code. Take a note and keep this recovery code somewhere safe.
        </>
      )}
      title="Keep your backup code handy and avoid account lockout">
      <div className={authClasses.secretSection}>
        <div className={authClasses.code}>
          {/* Show the secret in more readable 4-letter chunks */}
          {(recoveryCode.match(/.{1,4}/g) || []).join(' - ')}
        </div>
        <ActionLink onClick={handleDownload}>
          Download code
        </ActionLink>
      </div>

      <ActionButton asBlock kind="primary" onClick={onContinue}>
        Continue to Product
      </ActionButton>
    </AuthPageLayout>
  );
};

export default Setup2FABackup;
