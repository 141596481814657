// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import cx from 'classnames';

import { CommonMenuItem } from '../../../lib/componentTypes/menu';
import { ActionButton } from '../../Button/ActionButton';
import { SplitButton } from '../../Button/SplitButton';
import { SearchBox } from '../../controls/SearchBox';
import { GridQuadSolidIcon } from '../../svg/GridQuadSolidIcon';
import { HorizontalLinesQuadrupleIcon } from '../../svg/HorizontalLinesQuadrupleIcon';
import { SearchIcon } from '../../svg/SearchIcon';
import { Shutter } from '../../transition/Shutter';

import './CollectionControlBar.scss';

export type ViewType = 'list' | 'grid';

export interface ActionGroup {
  id: string;
  kind: 'primary' | 'secondary';
  label: string;
  onClick: () => void;
  menuItems?: CommonMenuItem[];
  disabled?: boolean;
}

export interface CollectionControlBarProps {
  viewType?: ViewType;
  onChangeViewType?: (type: ViewType) => void;
  onSearch?: (text: string) => void;
  onFilter?: () => void;
  buttons?: ActionGroup[];
}

export const CollectionControlBar = (props: CollectionControlBarProps) => {
  const { buttons = [], viewType = 'list', onChangeViewType, onFilter, onSearch } = props;

  const [findMode, setFindMode] = useState<'searching' | 'filtering' | null>(null);
  const [searchText, setSearchText] = useState('');

  const isSearching = findMode === 'searching';
  const isFiltering = findMode === 'filtering';

  const hasSearchGroup = !!(onFilter || onSearch);

  return (
    <div className="collectionControlBar">
      <div className="main">
        {onChangeViewType && (
          <div className="controlGroup">
            <button
              className={cx('barControl', { selected: viewType === 'list' })}
              onClick={() => onChangeViewType('list')}
              type="button">
              <HorizontalLinesQuadrupleIcon maxHeight={14} />
            </button>
            <button
              className={cx('barControl', { selected: viewType === 'grid' })}
              onClick={() => onChangeViewType('grid')}
              type="button">
              <GridQuadSolidIcon maxHeight={14} />
            </button>
          </div>
        )}
        {(onChangeViewType && hasSearchGroup) && (
          <div className="groupDivider" />
        )}
        {onSearch && (
          <>
            <Shutter open={!isSearching}>
              <button
                className={cx('barControl', { engaged: isSearching, selected: searchText })}
                disabled={isFiltering}
                onClick={() => {
                  setFindMode(isSearching ? null : 'searching');
                }}
                type="button">
                <SearchIcon maxHeight={14} />
              </button>
            </Shutter>
            <Shutter fixedWidth={180} open={isSearching}>
              <SearchBox
                autofocus
                decorated
                disabled={!isSearching}
                onChange={(value) => {
                  setSearchText(value);
                  onSearch(value);
                }}
                onClickIcon={() => {
                  if (isSearching) {
                    setFindMode(null);
                  }
                }}
                onEscape={() => setFindMode(null)}
                value={searchText}
              />
            </Shutter>
          </>
        )}
        {/* Not yet implemented */}
        {/* {onFilter && (
          <button
            className={cx('barControl', { engaged: isFiltering })}
            disabled={isSearching}
            onClick={() => {
              setFindMode(isFiltering ? null : 'filtering');
            }}
            type="button">
            <FilterIcon maxHeight={14} />
          </button>
        )} */}
        {!!buttons.length && (
          <div className="actionButtons">
            {buttons.map((button) => (
              button.menuItems?.length ? (
                <SplitButton
                  disabled={button.disabled}
                  key={button.id}
                  kind={button.kind}
                  menuItems={button.menuItems}
                  onClick={button.onClick}
                  size="small">
                  {button.label}
                </SplitButton>
              ) : (
                <ActionButton
                  disabled={button.disabled}
                  key={button.id}
                  kind={button.kind}
                  onClick={button.onClick}
                  size="small">
                  {button.label}
                </ActionButton>
              )
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
