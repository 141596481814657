// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { colors } from '../../../../../lib/designSystem';
import { useHasActiveMesh } from '../../../../../recoil/useProjectActiveMesh';
import { ActionLink } from '../../../../Button/ActionLink';
import { createStyles, makeStyles } from '../../../../Theme';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useGetMeshRefinementCount } from '../../../../hooks/useMesh';
import { SparkleDoubleIcon } from '../../../../svg/SparkleDoubleIcon';
import { Flex } from '../../../../visual/Flex';

import { AdaptationDetailsModal } from './AdaptationDetailsModal';

const useStyles = makeStyles(
  () => createStyles({
    lmaHeading: {
      fontSize: '13px',
      fontWeight: 600,
      color: colors.mediumEmphasisText,
      margin: '0 auto 0 0',
    },
    lmaDescription: {
      fontSize: '12px',
      margin: '14px 0 12px',
      color: colors.neutral700,
    },
    lmaHighlight: {
      fontWeight: 600,
      color: colors.mediumEmphasisText,
    },
  }),
  { name: 'LMAHeaderSection' },
);

export const DetailsButton = () => {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);

  return (
    <>
      <ActionLink onClick={() => setOpen(true)}>Details</ActionLink>
      <AdaptationDetailsModal onClose={onClose} open={open} />
    </>
  );
};

export const LMAHeaderSection = () => {
  const { projectId } = useProjectContext();
  const hasActiveMesh = useHasActiveMesh(projectId);
  const classes = useStyles();
  const { getMeshRefinementCount } = useGetMeshRefinementCount();

  // The text at the top of the panel is different before mesh generation
  const setupTextContent = (
    <p className={classes.lmaDescription}>
      A series of remeshes will occur while adapting to the solution within specified
      <span className={classes.lmaHighlight}> Max Mesh Size. </span>
      Generate a minimal mesh or utilize an existing mesh to start the adaptation.
    </p>
  );
  const activeTextContent = (
    <div className={classes.lmaDescription}>
      <span className={classes.lmaHighlight}>{getMeshRefinementCount() - 1} remeshes </span>will
      occur while adapting to the solution within a specified Max Size on the chosen Base
      Mesh.&nbsp;<DetailsButton />
    </div>
  );

  return (
    <>
      <Flex alignItems="center" gap={4}>
        <SparkleDoubleIcon color={colors.purple800} maxHeight={16} />
        <p className={classes.lmaHeading}>Lumi Mesh Adaptation</p>
      </Flex>
      {hasActiveMesh ? activeTextContent : setupTextContent}
    </>
  );
};
