// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, useCallback, useRef } from 'react';

import cx from 'classnames';

import { isUnmodifiedSpaceKey } from '../../../lib/event';

import { ColorConfig, StatefulColors, colorCssVars } from './color';

import './ToggleSwitch.scss';

/**
 * ToggleSwitch - a control component used to represent and change boolean
 * states.
 *
 * Design:
 * - The layout of the ToggleSwitch is simple with one root element, a 'gutter'
 *   element for the background, and a 'knob' element that indicates state by
 *   its position.
 * - The component uses a boolean 'value' prop to indicate state.  When 'value'
 *   is true, the knob is positioned on the right side of the gutter; when
 *   'value' is false, it's positioned on the left side of the gutter.
 * - When the optional 'disabled' prop is true, users may not interact with the
 *   component, but state may still be reflected by the knob's position.
 * - Applications may provide an 'onChange' prop which is called whenever the
 *   value should change.  It is only called when 'disabled' is not true, and it
 *   is called conservatively, i.e. only when the new value is different from
 *   the current 'value' prop value.
 *
 * Definitions:
 * - 'Value State' refers to the boolean value prescribed by the 'value' prop.
 * - 'Interaction State' refers to a set of mutually exclusive states that may
 *   be applied to the component, either through user interaction or the
 *   'disabled' prop.  These states are 'enabled', 'hovered', 'active', and
 *   'disabled'.
 *
 * Interaction:
 * - A user may click any part of the switch to toggle its value state, as long
 *   as it's not disabled.
 * - A user may tab to the component, giving it focus.  When the component has
 *   focus, the user may hit the SPACE key (with no modifiers) to toggle the
 *   value state, calling onChange(!value).
 *
 * Configuration:
 * - The default colors should suffice for most uses.  However, overrides may be
 *   provided in the 'knobColors' and 'gutterColors' props.  Both the knob and
 *   gutter support a different color for each possible
 *   value-state/interaction-state pair, and applications may override all or
 *   some of these values.  See the ColorConfig interface in the color.ts
 *   module.
 * */

const DEFAULT_KNOB_RADIUS = 8;
const DEFAULT_KNOB_INSET = 2;
const DEFAULT_ASPECT_RATIO = 2.5;
const DEFAULT_GUTTER_COLORS: StatefulColors = {
  enabled: ['var(--color-primary-cta)', 'var(--color-neutral-50)'],
  hover: ['var(--color-purple-700)', 'var(--color-neutral-100)'],
  active: ['var(--color-purple-700)', 'var(--color-neutral-100)'],
  disabled: ['var(--color-neutral-350)', 'var(--color-neutral-350)'],
};
const DEFAULT_KNOB_COLORS: StatefulColors = {
  enabled: ['var(--color-high-emphasis-text)', 'var(--color-high-emphasis-text)'],
  hover: ['var(--color-high-emphasis-text)', 'var(--color-high-emphasis-text)'],
  active: ['var(--color-high-emphasis-text)', 'var(--color-high-emphasis-text)'],
  disabled: ['var(--color-neutral-700)', 'var(--color-neutral-700)'],
};

export interface ToggleSwitchProps {
  value: boolean; // The component's value state
  knobRadius?: number; // The radius of the knob
  knobInset?: number; // The space between the knob and the gutter edges
  width?: number; // The width of the toggle switch
  onChange?: (value: boolean) => void; // Optional value state change handler
  disabled?: boolean; // Used to disable the component, making it non-interactive
  gutterColors?: ColorConfig; // Optional gutter color overrides
  knobColors?: ColorConfig; // Optional knob color overrides
}

export const ToggleSwitch = (props: ToggleSwitchProps) => {
  const { disabled, onChange, value } = props;

  const gutterNode = useRef<HTMLDivElement>(null);

  const knobRadius = props.knobRadius ?? DEFAULT_KNOB_RADIUS;
  const knobInset = props.knobInset ?? DEFAULT_KNOB_INSET;
  const minWidth = 2 * (knobRadius + knobInset);
  const width = Math.ceil(Math.max(props.width ?? DEFAULT_ASPECT_RATIO * 2 * knobRadius, minWidth));

  const rootStyle = {
    '--knob-radius': `${knobRadius}px`,
    '--knob-inset': `${knobInset}px`,
    '--width': `${width}px`,
    ...colorCssVars([
      ['gutter', DEFAULT_GUTTER_COLORS, props.gutterColors],
      ['knob', DEFAULT_KNOB_COLORS, props.knobColors],
    ]),
  } as CSSProperties;

  // The nominal distance between the knob's left and right positions
  const knobTravel = width - 2 * (knobInset + knobRadius);
  // The amount the knob should actually be offset in the X-direction, based on
  // the value state.
  const knobOffset = (value ? knobTravel : 0);

  const knobStyle = { transform: `translateX(${knobOffset}px)` };

  // No part of this code calls onChange directly; announceValue is called
  // instead to make sure onChange is only called when appropriate.
  const announceValue = useCallback(
    (newValue: boolean) => {
      if (onChange && !disabled && (newValue !== value)) {
        onChange(newValue);
      }
    },
    [disabled, onChange, value],
  );

  const toggleValue = () => {
    if (!disabled) {
      announceValue(!value);
    }
  };

  const handleRootClick = (event: React.MouseEvent) => {
    if (!disabled) {
      event.stopPropagation();
    }
    toggleValue();
  };

  const handleRootKeyDown = (event: React.KeyboardEvent) => {
    if (isUnmodifiedSpaceKey(event)) {
      event.preventDefault();
    }
  };

  const handleRootKeyUp = (event: React.KeyboardEvent) => {
    if (isUnmodifiedSpaceKey(event)) {
      toggleValue();
    }
  };

  const a11yRootProps = disabled ? {} : {
    onClick: handleRootClick,
    onKeyDown: handleRootKeyDown,
    onKeyUp: handleRootKeyUp,
    role: 'switch',
    tabIndex: 0,
  };

  return (
    <div
      aria-checked={value}
      aria-disabled={disabled}
      className={cx('toggleSwitch', { disabled, on: value })}
      data-locator="toggleSwitch"
      style={rootStyle}
      {...a11yRootProps}>
      <div
        className="gutter"
        ref={gutterNode}
        role="presentation">
        <div
          className="knob"
          style={knobStyle}
        />
      </div>
    </div>
  );
};
