// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 18;
const baseHeight = 18;
export const ArrowUpRightIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="arrowUpRight"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M16.5,0
           L6,0
           C5.175,0 4.5,0.675 4.5,1.5
           C4.5,2.325 5.175,3 6,3
           L12.885,3
           L0.45,15.435
           C0.165,15.705 0,16.08 0,16.5
           C0,17.325 0.675,18 1.5,18
           C1.92,18 2.295,17.835 2.565,17.565
           L15,5.115
           L15,12
           C15,12.825 15.675,13.5 16.5,13.5
           C17.325,13.5 18,12.825 18,12
           L18,1.5
           C18,0.675 17.325,0 16.5,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
