// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { ColumnState, RowConfig } from '../../../lib/componentTypes/table';
import { matchSearchTerms, parseSearchString } from '../../../lib/searchText';

import { getFormattedValue, getNormalizedValue, getTransformedValue, isValueNullish } from './util';

/**
 * Given rowConfigs, column states, and a search string, the executeSearch function parses the
 * search string into a set of search terms and returns a subset of the rowConfigs that are
 * implicated by the set of search terms.
 */
export const executeSearch = (
  rowConfigs: RowConfig[],
  columns: ColumnState[],
  searchString: string,
) => {
  const searchTerms = parseSearchString(searchString);

  if (searchTerms.length) {
    return rowConfigs.filter((row) => {
      // Find all keywords associated with the row by getting each column's provide value,
      // normalized value, and formatted value, and storing them in a Set (to de-dupe)
      const keywords = columns.reduce((kwResult, column) => {
        const { config: { id } } = column;

        const value = row.values[id];
        const normalValue = getNormalizedValue(value, column);
        const transformedValue = getTransformedValue(normalValue, column);
        const formattedValue = getFormattedValue(transformedValue, column);

        if (!isValueNullish(value)) {
          kwResult.add(`${value}`);
        }
        if (!isValueNullish(normalValue)) {
          kwResult.add(`${normalValue}`);
        }
        if (!isValueNullish(transformedValue)) {
          kwResult.add(`${transformedValue}`);
        }
        kwResult.add(formattedValue);

        return kwResult;
      }, new Set<string>());

      return (matchSearchTerms(searchTerms, [...keywords]));
    });
  }

  return rowConfigs;
};
