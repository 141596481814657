// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { Table } from '../../components/data/Table';
import { PlatformAdminUserDialog } from '../../components/dialog/PlatformAdminUser';
import { MainPageLayout } from '../../components/layout/page/Main';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { ColumnConfig, RowConfig } from '../../lib/componentTypes/table';
import { GetPlatformAdminRole, PlatformAdminIcon, PlatformAdminRoleNames, PlatformAdminRoleType } from '../../lib/platformAdminUtils';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import useAccountInfo from '../../recoil/useAccountInfo';
import { useEnabledPlatformAdminRoles } from '../../recoil/usePersonalPlatformAdmin';
import { useIsStaff } from '../../state/external/user/frontendRole';

const columnConfigs: ColumnConfig[] = [
  { id: 'firstName', label: 'First Name', type: 'string' },
  { id: 'lastName', label: 'Last Name', type: 'string' },
  { id: 'email', label: 'Email', type: 'string' },
  {
    id: 'roles',
    label: 'Platform Admin Roles',
    type: 'string',
    displayOptions: { textTransform: 'upper', minimalWidth: true, emptyDisplay: '' },
    disableVisibility: true,
  },
];

type User = frontendpb.AccountInfoReply_UserInfo;

interface PlatformAdminDialogState {
  open: boolean;
  user?: User;
}
interface AdminTableProps {
  editable: boolean,
  setPlatformAdminDialogState: React.Dispatch<React.SetStateAction<PlatformAdminDialogState>>
}

const AdminsTable = (props: AdminTableProps) => {
  const { editable, setPlatformAdminDialogState } = props;

  const accountInfo = useAccountInfo();
  const users = (accountInfo?.user || []);

  const adminRowData: RowConfig[] = users.map((user: frontendpb.AccountInfoReply_UserInfo) => {
    const userRoles = user.platformAdmin?.roles.map(GetPlatformAdminRole);
    const menuItems: CommonMenuItem[] = [];
    const roleIconsDisplay: SvgIconSpec[] = [];

    if (editable) {
      menuItems.push(
        {
          label: 'Edit user roles',
          startIcon: { name: 'pencil' },
          onClick: () => setPlatformAdminDialogState({ open: true, user }),
        },
      );
    }

    const rolesMap = {
      [PlatformAdminRoleType.ADMIN]: {
        name: PlatformAdminIcon.ADMIN,
        title: 'Platform admin admin',
      },
      [PlatformAdminRoleType.SUPPORT]: {
        name: PlatformAdminIcon.SUPPORT,
        title: 'Platform support admin',
      },
      [PlatformAdminRoleType.SAMPLE]: {
        name: PlatformAdminIcon.SAMPLE,
        title: 'Platform sample projects admin',
      },
      [PlatformAdminRoleType.BIZOPS]: {
        name: PlatformAdminIcon.BIZOPS,
        title: 'Platform bizops admin',
      },
    };

    userRoles?.forEach((role) => {
      if (role !== PlatformAdminRoleType.INVALID && rolesMap[role]) {
        roleIconsDisplay.push(rolesMap[role]);
      }
    });

    return {
      id: user.lcUserId,
      values: {
        firstName: user.givenName,
        lastName: user.familyName,
        email: user.email,
        roles: userRoles?.map(PlatformAdminRoleNames).join(),
      },
      cellDisplay: {
        roles: [{ type: 'circleIcons', icons: roleIconsDisplay }],
      },
      menuItems,
    };
  });

  const filteredRowData = adminRowData.filter((row) => row.values.roles);

  return (
    <Table
      asBlock
      columnConfigs={columnConfigs}
      defaultSort={{ columnId: 'roles', descending: true }}
      name="admin-table"
      pagination={{ availablePageSizes: [25, 50, 100] }}
      rowConfigs={editable ? adminRowData : filteredRowData}
    />
  );
};

const PlatformAdminAdminPageBody = () => {
  const platformAdminRoles = useEnabledPlatformAdminRoles();
  const editable = platformAdminRoles?.includes(PlatformAdminRoleType.ADMIN);
  const isStaff = useIsStaff();

  const [
    platformAdminUserDialogState,
    setPlatformAdminUserDialogState,
  ] = useState<PlatformAdminDialogState>({ open: false });

  return (
    <MainPageLayout
      permission={isStaff}
      title="Platform Team Administration">
      <AdminsTable
        editable={editable}
        setPlatformAdminDialogState={setPlatformAdminUserDialogState}
      />
      <PlatformAdminUserDialog
        {...platformAdminUserDialogState}
        onClose={() => setPlatformAdminUserDialogState({ open: false })}
      />
    </MainPageLayout>
  );
};

export default PlatformAdminAdminPageBody;
