// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as ProtoDescriptor from '../ProtoDescriptor';

import { RadioButtonOption, SelectOption, SelectOptionGroup } from './componentTypes/form';

// Form and form-input utilities

export function protoChoicesToRadioOptions(
  choices: ProtoDescriptor.Choice[],
  disabledEnums: number[] = [],
) {
  return choices.map((choice) => ({
    disabled: disabledEnums.includes(choice.enumNumber),
    value: choice.enumNumber,
    label: choice.text,
    help: choice.help,
  } as RadioButtonOption<number>));
}

export interface SelectOptionsPrefs {
  disabledEnums?: number[];
  explicitHelp?: boolean;
}

export function protoChoicesToSelectOptions(
  choices: ProtoDescriptor.Choice[],
  currentValue: number | undefined,
  prefs: SelectOptionsPrefs = {},
) {
  const { disabledEnums = [], explicitHelp = false } = prefs;

  return choices.map((choice) => ({
    name: choice.text,
    description: explicitHelp ? choice.help : '',
    tooltip: explicitHelp ? '' : choice.help,
    value: choice.enumNumber,
    selected: currentValue === choice.enumNumber,
    disabled: disabledEnums.includes(choice.enumNumber),
  } as SelectOption<number>));
}

/**
 * Given a list of SelectOption or SelectOptionGroup objects, return a flat list of SelectOption
 */
export function flattenSelectionOptionGroups<T>(
  selectOptions: (SelectOption<T> | SelectOptionGroup<T>)[],
) {
  return selectOptions.reduce((acc, option) => {
    if ('options' in option) {
      acc.push(...option.options);
      return acc;
    }
    acc.push(option);
    return acc;
  }, [] as SelectOption<T>[]);
}
