// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import assert from '../../../../../lib/assert';
import { getPhysicsId, getPhysicsName } from '../../../../../lib/physicsUtils';
import { SURFACE_NODE_TYPES } from '../../../../../lib/simulationTree/node';
import { getBoundaryCondName } from '../../../../../lib/simulationTree/utils';
import { useMultiphysicsInterface } from '../../../../../model/hooks/useMultiphysicsInterface';
import { useSimulationTreeSubselect } from '../../../../../recoil/simulationTreeSubselect';
import { useSimulationBoundaryNames } from '../../../../../state/external/project/simulation/param/boundaryNames';
import { CollapsibleNodePanel } from '../../../../Panel/CollapsibleNodePanel';
import Divider from '../../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../../Theme/commonStyles';
import Tooltip from '../../../../Tooltip';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useSelectionContext } from '../../../../context/SelectionManager';
import { useMultiphysicsInterfaceSurfaces } from '../../../../hooks/subselect/useMultiphysicsInterfaceSurfaces';
import { useSimulationConfig } from '../../../../hooks/useSimulationConfig';
import { DiskInfoIcon } from '../../../../svg/DiskInfoIcon';
import { Flex } from '../../../../visual/Flex';
import NodeLink from '../../../NodeLink';
import { NodeSubselect } from '../../../NodeSubselect';
import PropertiesSection from '../../../PropertiesSection';

const DEPENDENT_BC_TOOLTIP = `A dependent boundary condition is created under a related fluid flow
  physics to set Momentum and Roughness or under a related heat transfer physics.`;

interface InterfaceSideProps {
  interfaceId: string;
  sideA?: boolean;
}

const getSubselectId = (sideA: boolean) => `coupling-interfaces-side-${sideA ? 'a' : 'b'}`;

const InterfaceSideStatus = (props: InterfaceSideProps) => {
  // == Props
  const { interfaceId, sideA = false } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { isTreeModal } = useSelectionContext();

  // == Recoil
  const boundaryConditionNames = useSimulationBoundaryNames(projectId, workflowId, jobId);

  // == Custom hooks
  const commonClasses = useCommonTreePropsStyles();
  const {
    physicsA,
    physicsB,
    getDependentBoundaryCondition,
  } = useMultiphysicsInterface(projectId, workflowId, jobId, readOnly, interfaceId);
  const { simParam } = useSimulationConfig();

  // == Data
  const physicsList = sideA ? physicsA : physicsB;
  const singlePhysics = physicsList.length === 1 ? physicsList[0] : null;
  const dependentBc = getDependentBoundaryCondition(sideA);

  return (
    <>
      {!!physicsList.length && (
        <div className={commonClasses.gridPanel}>
          <div className="label">Related physics</div>
          <div className="value">
            {singlePhysics ? (
              <NodeLink
                asBlock
                disabled={isTreeModal}
                nodeIds={[getPhysicsId(singlePhysics)]}
                text={getPhysicsName(singlePhysics, simParam)}
              />
            ) : (
              <span>[Unknown]</span>
            )}
          </div>
          <div className="label">
            <Flex alignItems="baseline" gap={6}>
              <span>Dependent BC</span>
              <Tooltip
                arrow={false}
                title={DEPENDENT_BC_TOOLTIP}>
                <span>
                  <DiskInfoIcon maxHeight={12} maxWidth={12} />
                </span>
              </Tooltip>
            </Flex>
          </div>
          <div className="value">
            {dependentBc ? (
              <NodeLink
                asBlock
                disabled={isTreeModal}
                nodeIds={[dependentBc.boundaryConditionName]}
                text={getBoundaryCondName(boundaryConditionNames, dependentBc)}
              />
            ) : (
              <span>[Unknown]</span>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const InterfaceSide = (props: InterfaceSideProps) => {
  // == Props
  const { interfaceId, sideA = false } = props;

  // == Contexts
  const { readOnly } = useProjectContext();

  const {
    nodeFilter,
    nodeIds,
    setSurfaceIds,
  } = useMultiphysicsInterfaceSurfaces(interfaceId, sideA);

  return (
    <div>
      <Flex flexDirection="column" gap={8}>
        <NodeSubselect
          id={getSubselectId(sideA)}
          labels={['surfaces']}
          nodeFilter={nodeFilter}
          nodeIds={nodeIds}
          onChange={setSurfaceIds}
          readOnly={readOnly}
          referenceNodeIds={[interfaceId]}
          title="Surfaces"
          visibleTreeNodeTypes={SURFACE_NODE_TYPES}
        />
        <InterfaceSideStatus
          interfaceId={interfaceId}
          sideA={sideA}
        />
      </Flex>
    </div>
  );
};
export const PhysicsMultiInterfacePropPanel = () => {
  // == Contexts
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected multi-physics interface row');

  // == Hooks
  const treeSubselect = useSimulationTreeSubselect();

  // == Custom hooks
  const commonClasses = useCommonTreePropsStyles();

  return (
    <div className={commonClasses.properties}>
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Interface"
          nodeId={node.id}
          panelName="interface">
          <div className={commonClasses.note}>
            Interfaces combine surfaces from two different physics nodes.
          </div>
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={treeSubselect.id === getSubselectId(true)}
          heading="Side A"
          nodeId={node.id}
          panelName="sideA">
          <div className={commonClasses.sectionDescription}>
            Select all surfaces that represent one side of the interface
          </div>
          <InterfaceSide interfaceId={node.id} sideA />
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={treeSubselect.id === getSubselectId(false)}
          heading="Side B"
          nodeId={node.id}
          panelName="sideB">
          <div className={commonClasses.sectionDescription}>
            Select all surfaces that represent the other side of the interface
          </div>
          <InterfaceSide interfaceId={node.id} />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
