// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { createIdentifier } from '../../../lib/baseParamUtils';
import { findMaterialEntityById, getMaterialId, getMaterialName } from '../../../lib/materialUtils';
import { copiedName } from '../../../lib/name';
import { useWorkflowConfig } from '../../../model/hooks/useWorkflowConfig';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';

/**
 * @returns a callback to duplicate the material with the given id.
 */
export const useCopyMaterial = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection, setScrollTo } = useSelectionContext();

  const { saveParamAsync } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  const copyMaterial = useCallback(async (id: string) => {
    const newId = await saveParamAsync((param) => {
      const entity = findMaterialEntityById(param, id);
      if (!entity) {
        return;
      }
      // clone does a deep copy so the materialIdentifier and materialFluid/materialSolid will be
      // copies too.
      const newEntity = entity.clone();
      const oldName = getMaterialName(entity, param);
      newEntity.materialIdentifier = createIdentifier(copiedName(oldName));

      param.materialEntity.push(newEntity);
      return getMaterialId(newEntity);
    });
    if (newId) {
      setSelection([newId]);
      setScrollTo({ node: newId });
    }
  }, [saveParamAsync, setSelection, setScrollTo]);

  return copyMaterial;
};
