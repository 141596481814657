// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { getBoundaryConditionsByPhysics } from '../../lib/boundaryConditionUtils';
import {
  findMultiphysicsInterfaceById,
  physicsSummary,
  syncDependentBoundaryConditionBySide,
} from '../../lib/multiphysicsInterfaceUtils';
import {
  findFluidPhysicsMaterial,
  findPhysicsById,
  getPhysicsContainingSurfaces,
  getSurfaceToPhysicsMap,
  isPhysicsFluid,
  isPhysicsHeat,
} from '../../lib/physicsUtils';
import { upperFirst } from '../../lib/text';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useStaticVolumes } from '../../recoil/volumes';

import { useWorkflowConfig } from './useWorkflowConfig';

const { CONSTANT_DENSITY } = simulationpb.DensityRelationship;

export const useMultiphysicsInterface = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
  id: string,
) => {
  // == Recoil
  const staticVolumes = useStaticVolumes(projectId);
  const geometryTags = useGeometryTags(projectId);

  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  const multiphysicsInterface = useMemo(
    () => findMultiphysicsInterfaceById(simParam, id),
    [id, simParam],
  );

  const surfaceIdsA = useMemo(
    () => multiphysicsInterface?.slidingA,
    [multiphysicsInterface],
  );

  const surfaceIdsB = useMemo(
    () => multiphysicsInterface?.slidingB,
    [multiphysicsInterface],
  );

  const physicsA = useMemo(() => (
    getPhysicsContainingSurfaces(simParam, surfaceIdsA || [], geometryTags, staticVolumes)
  ), [geometryTags, simParam, staticVolumes, surfaceIdsA]);

  const physicsB = useMemo(() => (
    getPhysicsContainingSurfaces(simParam, surfaceIdsB || [], geometryTags, staticVolumes)
  ), [geometryTags, simParam, staticVolumes, surfaceIdsB]);

  const interfaceSummary = useMemo(
    () => physicsSummary(simParam, id, geometryTags, staticVolumes),
    [geometryTags, simParam, id, staticVolumes],
  );

  const physicsBySurfaceId = useMemo(
    () => getSurfaceToPhysicsMap(simParam, geometryTags, staticVolumes),
    [geometryTags, simParam, staticVolumes],
  );

  const surfaceAssignmentDisabledReason = useCallback((surfaceId: string, sideA: boolean) => {
    const surfacePhysicsId = physicsBySurfaceId[surfaceId];
    if (!surfacePhysicsId) {
      return `Surface is not associated with a physics.`;
    }

    const thisSideStatus = sideA ? interfaceSummary.sideA : interfaceSummary.sideB;
    const otherSideStatus = sideA ? interfaceSummary.sideB : interfaceSummary.sideA;

    const sideLabel = sideA ? 'side A' : 'side B';
    if (thisSideStatus.physics.length > 1) {
      return `${upperFirst(sideLabel)} already has too many physics.  Please fix before assigning
        new surfaces.`;
    }

    if (thisSideStatus.physics.length === 1) {
      if (thisSideStatus.physics[0] !== surfacePhysicsId) {
        return `Surface is not associated with the same physics as ${sideLabel}.`;
      }
    }

    const surfacePhysics = findPhysicsById(simParam, surfacePhysicsId);

    if (surfacePhysics && isPhysicsFluid(surfacePhysics)) {
      const surfaceMaterial =
        findFluidPhysicsMaterial(simParam, surfacePhysics, geometryTags, staticVolumes);
      if (
        surfaceMaterial?.material.case === 'materialFluid' &&
        surfaceMaterial.material.value.densityRelationship === CONSTANT_DENSITY
      ) {
        return `Surface cannot be assigned to a multiphysics coupling interface, because it belongs
          to a physics associated with a constant density material.`;
      }
    }

    if (otherSideStatus.physics.length === 1) {
      const otherPhysicsId = otherSideStatus.physics[0];
      if (otherPhysicsId === surfacePhysicsId) {
        return `Surface's associated physics is already represented on the other side of the
          interface.`;
      }

      const otherPhysics = findPhysicsById(simParam, otherPhysicsId);
      if (otherPhysics && surfacePhysics) {
        if (!isPhysicsHeat(otherPhysics) && !isPhysicsHeat(surfacePhysics)) {
          return 'One side of the coupling interface must represent a heat transfer physics';
        }
      }
    }

    return '';
  }, [geometryTags, interfaceSummary, physicsBySurfaceId, simParam, staticVolumes]);

  const saveSurfaceIds = useCallback(async (surfaceIds: string[], sideA: boolean) => saveParamAsync(
    (newParam) => {
      const newInterface = findMultiphysicsInterfaceById(newParam, id);
      if (newInterface) {
        if (sideA) {
          newInterface.slidingA = surfaceIds;
        } else {
          newInterface.slidingB = surfaceIds;
        }
      }
      syncDependentBoundaryConditionBySide(newParam, id, geometryTags, staticVolumes, sideA);
    },
  ), [geometryTags, id, saveParamAsync, staticVolumes]);

  const getDependentBoundaryCondition = useCallback((sideA: boolean) => {
    const physicsList = sideA ? physicsA : physicsB;
    if (physicsList.length === 1) {
      return getBoundaryConditionsByPhysics(physicsList[0]).find(
        (bc) => bc.boundaryConditionInterfaceId === id,
      );
    }
    return undefined;
  }, [id, physicsA, physicsB]);

  return {
    multiphysicsInterface,

    surfaceIdsA,
    surfaceIdsB,
    physicsA,
    physicsB,
    getDependentBoundaryCondition,

    physicsBySurfaceId,
    surfaceAssignmentDisabledReason,
    saveSurfaceIds,
  };
};
