// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 80;
const baseHeight = 74;
export const TriAxesIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="triAxes"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M15.927,64.307
           L19.634,70.728
           C20.555,72.323 19.088,74.235 17.309,73.759
           L1.533,69.531
           C0.43,69.236 -0.225,68.102 0.071,66.999
           L4.298,51.224
           C4.775,49.444 7.165,49.13 8.086,50.725
           L11.792,57.145
           L35.865,43.247
           L35.865,15.851
           L28.451,15.851
           C26.609,15.851 25.687,13.624 26.989,12.321
           L38.538,0.773
           C39.345,-0.035 40.654,-0.035 41.462,0.773
           L53.011,12.321
           C54.313,13.624 53.391,15.851 51.549,15.851
           L44.135,15.851
           L44.135,43.247
           L68.208,57.145
           L71.915,50.725
           C72.836,49.13 75.226,49.444 75.702,51.224
           L79.929,66.999
           C80.224,68.102 79.57,69.236 78.467,69.531
           L62.692,73.759
           C60.912,74.235 59.445,72.323 60.366,70.728
           L64.073,64.307
           L40,50.409
           L15.927,64.307Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
