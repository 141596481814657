// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/workorder/workorder.proto (package luminary.proto.workorder, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Manifest, Operation } from "../lcc/internal_pb.js";

/**
 * @generated from message luminary.proto.workorder.WorkOrderDetailRequest
 */
export const WorkOrderDetailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workorder.WorkOrderDetailRequest",
  () => [
    { no: 2, name: "work_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.workorder.WorkOrderDetailReply
 */
export const WorkOrderDetailReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workorder.WorkOrderDetailReply",
  () => [
    { no: 1, name: "work_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "operation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "operations", kind: "message", T: OperationDetail, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.workorder.OperationDetail
 */
export const OperationDetail = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workorder.OperationDetail",
  () => [
    { no: 1, name: "operation", kind: "message", T: Operation },
    { no: 2, name: "manifest", kind: "message", T: Manifest },
    { no: 3, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "deps", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

