// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const DiskExclamationIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="diskExclamation"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M11.993,0
           C5.367,0 0,5.367 0,11.993
           C0,18.619 5.367,23.986 11.993,23.986
           C18.619,23.986 23.986,18.619 23.986,11.993
           C23.986,5.367 18.619,0 11.993,0Z
           M13.492,19.489
           L10.494,19.489
           L10.494,16.49
           L13.492,16.49
           L13.492,19.489Z
           M13.492,14.991
           L10.494,14.991
           L10.494,4.497
           L13.492,4.497
           L13.492,14.991Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
