// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import { useCommonUserTableStyles } from '../Theme/commonStyles';

export const Emphasis = (props: { content: string }) => {
  const classes = useCommonUserTableStyles();
  return <span className={classes.confirmationEmphasis}>{props.content}</span>;
};
