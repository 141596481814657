// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { ConnectError } from '@connectrpc/connect';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atom as atomRecoil } from 'recoil';

import { experimentConfigFixture } from '../lib/fixtures';
import { EMPTY_CONFIG_REPLY, EMPTY_PREFERENCES } from '../lib/paramDefaults/experimentConfig';
import * as rpc from '../lib/rpc';
import { isTestingEnv } from '../lib/testing/utils';
import { addRpcError } from '../lib/transientNotification';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as userstatepb from '../proto/userstate/userstate_pb';
import { atomWithInitialSessionValue } from '../state/lib/sessionState';

const expPreferencesKey = 'experimentPreferences';

function serialize(val: userstatepb.ExperimentPreferences) {
  return val.toBinary();
}
function deserialize(val: Uint8Array) {
  return val.length ? userstatepb.ExperimentPreferences.fromBinary(val) : EMPTY_PREFERENCES;
}

const rpcPool = new rpc.StreamingRpcPool<
  frontendpb.ExperimentConfigRequest,
  frontendpb.ExperimentConfigReply
>('ExperimentConfig', rpc.client.experimentConfig);

const experimentConfigState = atom(experimentConfigFixture());
if (!isTestingEnv()) {
  experimentConfigState.onMount = (setAtom) => rpcPool.start(
    'ExperimentConfig',
    () => new frontendpb.ExperimentConfigRequest(),
    (reply: frontendpb.ExperimentConfigReply) => {
      setAtom(reply);
    },
    (err: ConnectError) => {
      addRpcError('ExperimentConfig failed', err);
      setAtom(EMPTY_CONFIG_REPLY);
    },
  );
}

const experimentPreferencesState = atomWithInitialSessionValue(
  '',
  expPreferencesKey,
  EMPTY_PREFERENCES,
  serialize,
  deserialize,
);

export const mergedExperimentState = atom((get) => {
  const experimentConfig = get(experimentConfigState);
  const currentPrefs = get(experimentPreferencesState);
  const experimentPrefs = new userstatepb.ExperimentPreferences({
    version: experimentConfig.version,
  });
  // Add prefs for valid experiments
  Object.entries(experimentConfig.experiments).forEach((entry) => {
    const [id, experiment] = entry;
    let pref = currentPrefs.experiments[id]?.clone();
    if (!pref) {
      // pref does not exist; create it.
      pref = new userstatepb.ExperimentMeta({
        override: false,
        id: BigInt(id),
      });
    }
    // Update the name and UI tag in case they changed.
    pref.name = experiment.name;
    pref.tag = experiment.uiTag;
    experimentPrefs.experiments[id] = pref;
  });
  return experimentPrefs;
});

export const useExperimentConfig = () => useAtomValue(mergedExperimentState);
export const useSetExperimentConfig = () => useSetAtom(experimentPreferencesState);

export const enabledExperimentsState = atom((get) => {
  const expConfig = get(mergedExperimentState);
  const tags: string[] = [];
  Object.values(expConfig.experiments).forEach((meta: userstatepb.ExperimentMeta) => {
    if (!meta.override) {
      tags.push(meta.tag);
    }
  });
  return tags;
});

// Returns the enabled experiments for this user. This doesn't include any tags which have
// been manually overriden.
export const useEnabledExperiments = () => useAtomValue(enabledExperimentsState);

// Returns true if a specific experiment is enabled
// There was an issue (the get was not returning) when using a separate selector for the
// isEnabled state so instead we use a simple wrapper (as of 09/20/22)
export const useIsEnabled = (expTag: string) => (
  useAtomValue(enabledExperimentsState).includes(expTag)
);

export const enabledExperimentsSelector_DEPRECATED = atomRecoil<string[]>({
  key: 'enabledExperimentsSelector_DEPRECATED',
  default: [],
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});
