// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.

import { LCVMapMode } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../types';

import { LcvObject } from './base/LcvObject';

export class LcvData1D extends LcvObject {
  isMapped = false;

  constructor(lcv: LcvModule, handle: number, sessionHandle: number) {
    super(lcv, handle, sessionHandle);
  }

  /**
   * mapData returns a pointer to the wasm memory.
   * It ensures that any objects in the returned mapping have a refcount of at least 1 while the
   * data is mapped.
  */
  mapData() {
    if (this.isMapped) {
      throw new Error('data is already mapped');
    }
    const map = this.lcv.mapData(
      this.sessionHandle,
      this.handle,
      LCVMapMode.kLCVMapModeRead,
      0,
      0,
    );
    this.isMapped = true;
    return map;
  }

  /**
   * unmapData tells the api that we're done looking at the wasm memory, so the objects
   * inside the mapping can have their refcount decremented again.
   */
  unmapData() {
    if (!this.isMapped) {
      throw new Error('data is not yet mapped');
    }
    this.lcv.unmapData(this.sessionHandle, this.handle);
    this.isMapped = false;
  }
}
