// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import Form from './Form';
import { ParamFieldInput, ParamFieldInputProps } from './ParamFieldInput';

export type ParamLabelCellProps = {
  // If true, the nestLevel is set to zero. Else, the nestLevel is set equal to
  // `param.nestLevel`.
  label?: string;
  suppressTooltip?: boolean;
  earlyAccess?: boolean;
  nestLevel: number;
};

export type ParamRowProps = ParamLabelCellProps & ParamFieldInputProps;

// Generate a row for the parameter. It includes the label and the input field.
const ParamRow = (props: ParamRowProps) => {
  const { earlyAccess, label, suppressTooltip, param } = props;
  const labelText = label ?? param.text;

  return (
    <Form.LabeledInput
      earlyAccess={earlyAccess}
      help={param.help}
      label={labelText}
      nestLevel={props.nestLevel}
      suppressTooltip={suppressTooltip}>
      <ParamFieldInput {...props} />
    </Form.LabeledInput>
  );
};

export default ParamRow;
