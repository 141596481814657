// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, getDefaultStore, useAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { atomFamily as atomFamilyRecoil } from 'recoil';

import { viewStateFixture } from '../lib/fixtures';
import { isTestingEnv } from '../lib/testing/utils';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';

export const viewState = atomFamily(
  (projectId: string) => atom(isTestingEnv() ? viewStateFixture() : null),
);

export const viewStateAtomFamily_DEPRECATED = atomFamilyRecoil<
  ParaviewRpc.ViewState | null,
  string
>({
  key: 'viewState',
  default: (projectId: string) => getDefaultStore().get(viewState(projectId)),
});

export function useViewState(projectId: string) {
  return useAtom(viewState(projectId));
}
