// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import AuthForm from '../../components/Form/AuthForm';
import { createStyles, makeStyles } from '../../components/Theme';
import Tooltip from '../../components/Tooltip';
import { AuthPageLayout } from '../../components/layout/page/AuthPageLayout';
import { DiskInfoIcon } from '../../components/svg/DiskInfoIcon';
import { MFA_TOKEN_EXPIRED_ERROR, SetupApp2FALocationState, SetupSelect2FALocationState, SetupSms2FALocationState, US_PHONE_PREFIX, getOobMfaSetupCode, isMfaTokenExpired, modifyNumber } from '../../lib/AuthMFA';
import { colors } from '../../lib/designSystem';
import { routes } from '../../lib/navigation';
import { isStorybookEnv } from '../../lib/testing/utils';

const useStyles = makeStyles(
  () => createStyles({
    infoIcon: {
      position: 'relative',
      top: '1px',
    },
  }),
  { name: 'SetupSelect2fa' },
);

// If MFA is enabled and required for the account, the user will be shown this page after
// successful login.
const SetupSelect2fa = () => {
  // Hooks
  const classes = useStyles();
  const navigate = useNavigate();
  const locationState = useLocation().state as SetupSelect2FALocationState;
  // State
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Handlers

  // Submitting the form with a valid phone number should send an SMS to that number. In addition,
  // the getOobMfaSetupCode will return an oob setup code that will be used to finish the MFA
  // setup on the next page where we'd need:
  // 1. this oob setup code;
  // 2. the mfaToken (from the initial login and the useHandleMfaRequired);
  // 3. the binding code that the user receives via SMS and enters in the next step.
  const handleSubmit = async (data: Record<string, string>) => {
    setError('');
    setSubmitting(true);

    if (isMfaTokenExpired(locationState?.mfaTokenExpiry)) {
      navigate(routes.login, { state: { error: MFA_TOKEN_EXPIRED_ERROR } });
      return;
    }

    try {
      const oobSetup = await getOobMfaSetupCode(
        locationState?.mfaToken,
        modifyNumber(data.phoneNumber, { clean: true, appendUSPrefix: true }),
      );

      const navigateStateForSetupSms2FA: SetupSms2FALocationState = {
        mfaToken: locationState?.mfaToken,
        mfaTokenExpiry: locationState?.mfaTokenExpiry,
        phoneNumber: modifyNumber(data.phoneNumber, { appendUSPrefix: true }),
        oobSetup,
      };

      navigate(routes.setupSms2FA, { state: navigateStateForSetupSms2FA });
    } catch (err) {
      setError(err.message || 'Authentication error: could not get OOB code.');
    } finally {
      setSubmitting(false);
    }
  };

  // Effects
  useEffect(() => {
    if (isStorybookEnv()) {
      return;
    }

    // If there isn't mfaToken in the url, redirect to login because we won't be able to get the oob
    // data without it.
    if (!locationState?.mfaToken) {
      navigate(routes.login, {
        state: {
          error: 'Authentication error: missing MFA token for setup',
        },
      });
    }
  }, [locationState, navigate]);

  return (
    <AuthPageLayout
      alternativeOption={{
        description: (
          <div>
            Recommended verification method to generate security codes on your phone without needing
            to receive text messages. We support multiple apps&nbsp;
            <Tooltip
              placement="right"
              title="Authy, Google Authenticator, Auth0 Guardian, Microsoft Authenticator">
              <span className={classes.infoIcon}>
                <DiskInfoIcon color={colors.highEmphasisText} maxHeight={12} />
              </span>
            </Tooltip>.
          </div>
        ),
        label: 'Continue with Authenticator App',
        onClick: () => {
          const setupSelect2FALocationState: SetupApp2FALocationState = {
            mfaToken: locationState?.mfaToken,
            mfaTokenExpiry: locationState?.mfaTokenExpiry,
            idToken: locationState?.idToken,
          };
          navigate(routes.setupApp2FA, { state: setupSelect2FALocationState });
        },
      }}
      subtitle="Keep your account extra secure with a second login step."
      title="Your workspace requires multi-factor authentication">
      <AuthForm
        fields={[
          {
            asBlock: true,
            autofocus: true,
            label: 'Mobile Number (US only)',
            disabled: submitting,
            name: 'phoneNumber',
            placeholder: '(___) ___ - ____',
            // Remove all non-digit chars before checking the length
            validate: (value) => modifyNumber(value, { clean: true }).length === 10,
            startAdornment: US_PHONE_PREFIX,
          },
          {
            label: (
              <span>
                By providing your mobile phone number, you agree to receive text messages from
                Luminary Cloud upon sign-up and login. Reply HELP for help. Reply STOP to opt out.
                Message and data rates may apply.
              </span>
            ),
            disabled: submitting,
            name: 'consent',
            required: true,
            type: 'checkbox',
          },
        ]}
        globalError={error}
        onSubmit={handleSubmit}
        submit={{
          disabled: submitting,
          showSpinner: submitting,
          label: 'Send Code',
        }}
      />
    </AuthPageLayout>
  );
};

export default SetupSelect2fa;
