// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { lcvResetCamera } from '../../lib/lcvis/api';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useProjectContext } from '../context/ProjectContext';

import { useHandleManualCameraViewChange } from './useHandleManualCameraViewChange';

export const useZoomToFit = () => {
  // == Contexts
  const { projectId } = useProjectContext();
  const { paraviewRenderer } = useParaviewContext();

  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const onManualCameraViewChange = useHandleManualCameraViewChange();

  return useCallback(() => {
    if (lcvisEnabled) {
      lcvResetCamera();
    } else {
      paraviewRenderer.resetCamera();
      onManualCameraViewChange();
    }
  }, [lcvisEnabled, paraviewRenderer, onManualCameraViewChange]);
};
