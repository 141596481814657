// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const PatternCircularIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="patternCircular"
      fill="none"
      height={finalHeight}
      ref={ref}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4394_23256)">
        <path
          d="M11 3C11 4.65685 9.65685 6 8 6C6.34315 6 5 4.65685 5 3C5 1.34315 6.34315 0 8 0C9.65685
          0 11 1.34315 11 3Z"
          fill={getColor(0)}
        />
        <path
          clipRule="evenodd"
          d="M8 15C9.10457 15 10 14.1046 10 13C10 11.8954 9.10457 11 8 11C6.89543 11 6 11.8954 6
          13C6 14.1046 6.89543 15 8 15ZM8 16C9.65685 16 11 14.6569 11 13C11 11.3431 9.65685 10 8
          10C6.34315 10 5 11.3431 5 13C5 14.6569 6.34315 16 8 16Z"
          fill={getColor(0)}
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M13 10C14.1046 10 15 9.10457 15 8C15 6.89543 14.1046 6 13 6C11.8954 6 11 6.89543 11
          8C11 9.10457 11.8954 10 13 10ZM13 11C14.6569 11 16 9.65685 16 8C16 6.34315 14.6569 5 13
          5C11.3431 5 10 6.34315 10 8C10 9.65685 11.3431 11 13 11Z"
          fill={getColor(0)}
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M3 10C4.10457 10 5 9.10457 5 8C5 6.89543 4.10457 6 3 6C1.89543 6 1 6.89543 1 8C1
          9.10457 1.89543 10 3 10ZM3 11C4.65685 11 6 9.65685 6 8C6 6.34315 4.65685 5 3 5C1.34315 5
          0 6.34315 0 8C0 9.65685 1.34315 11 3 11Z"
          fill={getColor(0)}
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_4394_23256">
          <rect fill={getColor(0)} height={baseHeight} width={baseWidth} />
        </clipPath>
      </defs>
    </svg>
  );
});
