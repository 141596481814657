// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useMemo } from 'react';

import { Code, ConnectError } from '@connectrpc/connect';
import {
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  waitForAll,
} from 'recoil';

import { workflowStateFixture } from '../lib/fixtures';
import * as rpc from '../lib/rpc';
import { isTestingEnv } from '../lib/testing/utils';
import { addRpcError } from '../lib/transientNotification';
import { hashMessage } from '../lib/utils';
import * as frontendpb from '../proto/frontend/frontend_pb';

import { projectMetadataMapState_DEPRECATED } from './useProjectMetadata';

export interface WorkflowMap {
  [workflowId: string]: frontendpb.GetWorkflowReply | undefined
}

const rpcPool = new rpc.StreamingRpcPool<
  frontendpb.GetWorkflowRequest,
  frontendpb.GetWorkflowReply
>('GetWorkflow', rpc.client.getWorkflow);

const rpcWorkflowsPool = new rpc.StreamingRpcPool<
  frontendpb.GetWorkflowsRequest,
  frontendpb.GetWorkflowsReply
>('GetWorkflows', rpc.client.getWorkflows);

type WorkflowRecoilKey = {
  projectId: string,
  workflowId: string,
}

export const workflowState = atomFamily<
  frontendpb.GetWorkflowReply | undefined,
  WorkflowRecoilKey
>({
  key: 'workflow',
  default: isTestingEnv() ? workflowStateFixture() : undefined,
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});

const startWorkflowRpc = (
  projectId: string,
  workflowId: string,
  onUpdate: (reply: frontendpb.GetWorkflowReply
  ) => void,
) => {
  if (!projectId || !workflowId) {
    return () => { };
  }
  return rpcPool.start(
    workflowId,
    () => new frontendpb.GetWorkflowRequest({ projectId, workflowId }),
    onUpdate,
    (err: ConnectError) => {
      if (err.code === Code.NotFound) {
        // Workflow deleted during open GetWorkflow.
        // Don't sweat it.
        return;
      }
      addRpcError('GetWorkflow failed', err);
    },
  );
};

const startWorkflowsRpc = (
  projectId: string,
  workflowIds: string[],
  onUpdate: (reply: frontendpb.GetWorkflowsReply) => void,
) => {
  if (!projectId || !workflowIds.length || (workflowIds.length === 1 && !workflowIds[0])) {
    return () => { };
  }
  const key = projectId + hashMessage(workflowIds.join(','));
  return rpcWorkflowsPool.start(
    key,
    () => new frontendpb.GetWorkflowsRequest({ projectId, workflowIds }),
    onUpdate,
    (err: ConnectError) => {
      addRpcError('GetWorkflows failed', err);
    },
  );
};

export function useWorkflowState(
  projectId: string,
  workflowId: string,
): frontendpb.GetWorkflowReply | undefined {
  const [workflow, setWorkflow] = useRecoilState(workflowState({ projectId, workflowId }));
  useEffect(
    () => startWorkflowRpc(projectId, workflowId, setWorkflow),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, workflowId],
  );
  return workflow;
}

type WorkflowMapRecoilKey = {
  projectId: string,
  workflowIds: string[],
}

export const workflowMapSelector = selectorFamily<WorkflowMap, WorkflowMapRecoilKey>({
  key: 'workflowMap',
  get: (key: WorkflowMapRecoilKey) => async ({ get }) => {
    const getWorkflows = key.workflowIds.map(
      (workflowId) => workflowState({ projectId: key.projectId, workflowId }),
    );
    const workflows = get(waitForAll(getWorkflows));
    const workflowMap: WorkflowMap = {};
    workflows.forEach((reply, index) => {
      workflowMap[key.workflowIds[index]] = reply;
    });
    return workflowMap;
  },
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});

export const workflowIdsSelector = selectorFamily<string[], string>({
  key: 'workflowIds',
  get: (projectId: string) => ({ get }) => {
    const projectMetadataMap = get(projectMetadataMapState_DEPRECATED(projectId));
    if (projectMetadataMap === null) {
      return [];
    }
    const workflows = projectMetadataMap!.workflow;
    const workflowIds = workflows.map((workflow) => workflow.workflowId);
    return workflowIds;
  },
});

export function useWorkflowMap(
  projectId: string,
  workflowIds: string[],
): WorkflowMap {
  const [, setWorkflowSingle] = useRecoilState(
    workflowState({ projectId, workflowId: workflowIds.length === 1 ? workflowIds[0] : '' }),
  );
  // Create a callback that returns a function that sets the state of a workflow
  // with a certain index in workflowIds array
  const setWorkflow = useRecoilCallback(
    ({ set }) => (idx: number) => (reply: frontendpb.GetWorkflowReply) => (
      set(workflowState({ projectId, workflowId: workflowIds[idx] }), reply)
    ),
  );

  const mapIdx = useMemo(() => {
    const mapIdxReturn = new Map<string, number>();
    workflowIds.forEach((id, idx) => {
      mapIdxReturn.set(id, idx);
    });
    return mapIdxReturn;
  }, [workflowIds]);

  useEffect(() => {
    if (workflowIds.length === 1) {
      // Special case if we just want one workflow, we can start a single streaming RPC.
      const cancelRpc = startWorkflowRpc(projectId, workflowIds[0], setWorkflowSingle);
      return () => {
        // Cancel the RPC on unmount.
        cancelRpc();
      };
    }
    // Start the aggregated RPC to get all workflows.
    const cancelRpc = startWorkflowsRpc(
      projectId,
      workflowIds,
      (reply: frontendpb.GetWorkflowsReply) => {
        Object.entries(reply.workflowsReplies).forEach(([workflowId, workflowReply]) => {
          const idx = mapIdx.get(workflowId);
          if (idx === undefined) {
            return;
          }
          setWorkflow(idx)(workflowReply);
        });
        Object.entries(reply.workflowsErrors).forEach(([workflowId, error]) => {
          const idx = mapIdx.get(workflowId);
          if (idx === undefined) {
            return;
          }
          if (error.code !== Code.NotFound) {
            addRpcError(`GetWorkflow failed for ${workflowId}`, new Error(error.toJsonString()));
          }
        });
      },
    );
    return () => {
      // Cancel the RPC on unmount.
      cancelRpc();
    };
  }, [projectId, workflowIds, mapIdx, setWorkflow, setWorkflowSingle]);
  return useRecoilValue(workflowMapSelector({ projectId, workflowIds }));
}
