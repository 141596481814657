import React, { forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';

import { colors } from '../../../lib/designSystem';
import { useIsGeometryCheck } from '../../../recoil/pendingWorkOrders';
import { ActionButton } from '../../Button/ActionButton';
import { TextInput } from '../../Form/TextInput';
import { createStyles, makeStyles } from '../../Theme';
import Tooltip from '../../Tooltip';
import { useProjectContext } from '../../context/ProjectContext';
import { useGeometryStatus } from '../../hooks/useGeometryStatus';
import { PaperAirplaneIcon } from '../../svg/PaperAirplaneIcon';
import { SparkleDoubleIcon } from '../../svg/SparkleDoubleIcon';

export type ChatMessage = {
  id: string;
  text: string;
  timestamp: Date;
  isUser: boolean;
}

type ChatProps = {
  messages: ChatMessage[];
  onSendMessage: (message: string) => void;
  // If true, the user cannot send messages.
  disabled?: boolean;
}

const MAX_CHAT_WIDTH = 500;
const MIN_CHAT_WIDTH = 200;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      width: '100%',
      maxWidth: `${MAX_CHAT_WIDTH}px`,
      minWidth: `${MIN_CHAT_WIDTH}px`,
      height: '100%',
      backgroundColor: colors.neutral150,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      overflow: 'hidden',
    },
    chatHistory: {
      overflowY: 'auto',
      padding: '8px',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      flex: '1',
      fontSize: '13px',
    },
    inputBox: {
      display: 'flex',
      gap: '8px',
      padding: '8px',
      position: 'relative',
      alignSelf: 'center',
    },
    spacer: {
      // Pushes the messages to the bottom
      flex: '1',
    },
  }),
  { name: 'Chat' },
);

const useAssistantChatStyles = makeStyles(
  () => createStyles({
    assistantMessage: {
      backgroundColor: 'inherit',
      color: colors.lowEmphasisText,
      display: 'flex',
      flexDirection: 'row',
      gap: '6px',
      alignSelf: 'flex-start',
      alignItems: 'baseline',
      maxWidth: '80%',

      // When the message start to appear, if it is a math formula, we might end up in a case
      // where only a part of the formula is displayed. In that case, the message will have
      // "$$" only at the beginning of the message but not at the end, and remark/rehype will
      // treat the text as an error (not as a latex formula) and will display it in red color.
      // To prevent this transitional error state, we'll just hide the unfinished formula.
      '& .katex-error': {
        display: 'none',
      },

      '& table': {
        '& thead': {
          backgroundColor: colors.surfaceMedium1,
        },
        '& th, & td': {
          padding: '4px',
          borderBottom: `1px solid ${colors.neutral300}`,
        },
      },
    },
    assistantIcon: {
      height: '12px',
      width: '12px',
      flex: '0 0 auto',
    },
    preparingResponse: {
      fontStyle: 'italic',
    },
    markdown: {
      '& p': {
        marginTop: '0.9em',
        marginBottom: '0.75em',
      },
      '& ul, & ol': {
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: '20px',
      },
      '& li': {
        marginBottom: '0.25em',
      },
      '& a': {
        color: colors.blue700,
        textDecoration: 'none',
      },
      '& a:hover': {
        textDecoration: 'underline',
      },
      '& a:visited': {
        color: colors.purple700,
      },
    },
  }),
  { name: 'AssistantChat' },
);

const useUserChatStyles = makeStyles(
  () => createStyles({
    userMessage: {
      backgroundColor: colors.neutral300,
      color: colors.highEmphasisText,
      borderRadius: '8px',
      padding: '4px 8px',
      maxWidth: '80%',
      alignSelf: 'flex-end',
      textAlign: 'left',
      width: 'max-content',
    },
  }),
  { name: 'UserChat' },
);

const useAnimatedEllipsisStyles = makeStyles(
  () => createStyles({
    ellipsis: {
      display: 'inline-block',
      overflow: 'hidden',
      verticalAlign: 'bottom',
      '& span': {
        opacity: 0,
        animation: '$ellipsis-keyframes 2s infinite',
      },
      '& span:nth-child(1)': {
        animationDelay: '0s',
      },
      '& span:nth-child(2)': {
        animationDelay: '0.4s',
      },
      '& span:nth-child(3)': {
        animationDelay: '0.8s',
      },
    },
    '@keyframes ellipsis-keyframes': {
      '0%': { opacity: 0 },
      '50%': { opacity: 1 },
      '100%': { opacity: 0 },
    },
  }),
  { name: 'AnimatedEllipsis' },
);

const AnimatedEllipsis = () => {
  const classes = useAnimatedEllipsisStyles();
  return (
    <span className={classes.ellipsis}>
      <span>.</span><span>.</span><span>.</span>
    </span>
  );
};

const AssistantMessage = forwardRef<HTMLDivElement, { text: string; isPreparing?: boolean }>(
  ({ text, isPreparing = false }, ref) => {
    const classes = useAssistantChatStyles();

    return (
      <div className={classes.assistantMessage} ref={ref}>
        <div className={classes.assistantIcon}>
          <SparkleDoubleIcon color={colors.purple800} maxHeight={12} maxWidth={12} />
        </div>
        {isPreparing ? (
          <span className={classes.preparingResponse}>
            Preparing response<AnimatedEllipsis />
          </span>
        ) : (
          <ReactMarkdown
            className={classes.markdown}
            components={{
              a: ({ children, href }) => (
                <a href={href} rel="noopener noreferrer" target="_blank">
                  {children}
                </a>
              ),
            }}
            rehypePlugins={[rehypeKatex]}
            remarkPlugins={[remarkMath, remarkGfm]}>
            {text}
          </ReactMarkdown>
        )}
      </div>
    );
  },
);

const UserMessage = forwardRef<HTMLDivElement, { text: string; isLastUserMessage?: boolean }>(
  ({ text, isLastUserMessage }, ref) => {
    const classes = useUserChatStyles();
    return (
      <div
        className={classes.userMessage}
        data-last-user-message={isLastUserMessage ? true : undefined}
        ref={ref}>
        {text}
      </div>
    );
  },
);

export const Chat = (props: ChatProps) => {
  // == Props
  const { onSendMessage, disabled } = props;

  // == Contexts
  const { projectId } = useProjectContext();

  // == Hooks
  const classes = useStyles();
  const { working } = useGeometryStatus();
  const isGeometryCheck = useIsGeometryCheck(projectId);
  const chatHistoryRef = useRef<HTMLDivElement | null>(null);
  const recentUserMessageRef = useRef<HTMLDivElement | null>(null);
  const [currentMessage, setCurrentMessage] = useState('');
  const [isPreparing, setIsPreparing] = useState(false);

  // When the assistant starts to generate a response, its first message is an empty text.
  // Then, after a few moments the empty message gets replaced with the actual response. We don't
  // want to show this empty message and receiving it ends the "is preparing" state so it's better
  // to just filter out these empty messages.
  const messages = props.messages.filter((message) => message.text);

  const disabledReason = useMemo(() => {
    if (working || isGeometryCheck) {
      return `The AI assistant is only available after the geometry preparation and checks
        are completed.`;
    }
    return '';
  }, [working, isGeometryCheck]);

  // Disable the send button if the last message was from the user
  const disableSend = (
    currentMessage.length === 0 ||
    disabled ||
    !!disabledReason ||
    messages[messages.length - 1]?.isUser ||
    isPreparing
  );

  useLayoutEffect(() => {
    if (chatHistoryRef.current) {
      // Do not use the scrollIntoView as it messes up the layout when the messages contain math
      // formulas and ReactMarkdown uses the rehype/remark plugins to format them.
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    // Reset isPreparing if a new non-user message has appeared
    if (isPreparing && messages.length > 0 && !messages[messages.length - 1].isUser) {
      setIsPreparing(false);
    }
  }, [messages, isPreparing]);

  const handleSubmit = () => {
    if (currentMessage.length > 0 && !disableSend) {
      onSendMessage(currentMessage);
      setCurrentMessage('');
      setIsPreparing(true);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.chatHistory} ref={chatHistoryRef}>
        <div className={classes.spacer} /> {/* Spacer to push messages to the bottom */}
        {messages.map((message) => {
          if (message.isUser) {
            // Update recentUserMessageRef
            return (
              <UserMessage
                key={message.id}
                ref={recentUserMessageRef} // Now points to the last user message
                text={message.text}
              />
            );
          }

          // If not a user message, just render the assistant's message.
          return (
            <AssistantMessage
              key={message.id}
              text={message.text}
            />
          );
        })}
        {isPreparing && (
          <AssistantMessage
            isPreparing
            key="preparing"
            text="Preparing response"
          />
        )}
      </div>
      <div className={classes.inputBox}>
        <Tooltip title={disabledReason}>
          <span>
            <TextInput
              adornmentButton={(
                <ActionButton
                  disabled={disableSend}
                  onClick={handleSubmit}
                  size="small">
                  <PaperAirplaneIcon maxHeight={12} maxWidth={12} /> Send
                </ActionButton>
          )}
              cols={MAX_CHAT_WIDTH / 10}
              disabled={!!disabledReason}
              multiline
              onChange={(newValue: string) => {
                setCurrentMessage(newValue);
              }}
              onEnter={handleSubmit}
              placeholder="Enter a message"
              resize="none"
              rows={6}
              submitOnEnter
              value={currentMessage}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
