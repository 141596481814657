// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useState } from 'react';

import { ActionLink } from '../../components/Button/ActionLink';
import AuthForm from '../../components/Form/AuthForm';
import { AuthPageLayout } from '../../components/layout/page/AuthPageLayout';
import { AuthState } from '../../lib/AuthInfoCallback';
import { setAuthInfoV2 } from '../../lib/AuthInfoCallbackV2';
import { TOS_URL } from '../../lib/constants';
import { loadSessionJwt } from '../../lib/jwt';
import * as rpc from '../../lib/rpc';
import * as frontendpb from '../../proto/frontend/frontend_pb';

// This page is for re-accepting terms for old users if the terms have changed.
const AcceptTermsUpdatedPage = () => {
  // State
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);

    try {
      const req = new frontendpb.AcceptTermsRequest({ isNewUser: false });
      await rpc.callRetry('AcceptTerms', rpc.client.acceptTerms, req);
      const jwt = loadSessionJwt();
      if (jwt !== null) {
        jwt['https://luminarycloud.com/new_user'] = false;
      }
      setAuthInfoV2({
        authState: AuthState.AUTHENTICATED,
        jwt,
      });
    } catch (err) {
      setError(err.message || 'Accepting the terms of service failed. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AuthPageLayout
      subtitle={`
        We have recently updated our Terms of Service.
        Please read and accept the updated terms to continue using Luminary.
      `}
      title="Login">
      <AuthForm
        contained
        fields={[
          {
            label: (
              <span>
                I agree to the
                Luminary Cloud <ActionLink href={TOS_URL}>Terms of Service</ActionLink>.
              </span>
            ),
            disabled: submitting,
            name: 'consent',
            required: true,
            type: 'checkbox',
          },
        ]}
        globalError={error}
        onSubmit={handleSubmit}
        submit={{
          disabled: submitting,
          showSpinner: submitting,
          label: 'Agree and continue',
        }}
      />
    </AuthPageLayout>
  );
};

export default AcceptTermsUpdatedPage;
