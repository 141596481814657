// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtom, useSetAtom } from 'jotai';
import { atomFamily, atomWithStorage } from 'jotai/utils';

import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';

// Defaults of the Motion Animation state
const DEFAULT_START_TIME = 0;
const DEFAULT_END_TIME = 0.01;
const DEFAULT_NSTEPS = 10;
const DEFAULT_AXES_LENGTH = 0.10;

export function defaultMotionAnimationParamState(): ParaviewRpc.GridMotionParam {
  return {
    startTime: DEFAULT_START_TIME,
    endTime: DEFAULT_END_TIME,
    numberOfSteps: DEFAULT_NSTEPS,
    fvmMotionData: '',
    drawAxes: true,
    transformSurfaces: true,
    drawAxesLinks: false,
    axesLength: DEFAULT_AXES_LENGTH,
    childFrameId: '',
  };
}

function getStorageKey(projectId: string) {
  return `motion-animation-param-state-${projectId}`;
}

const motionAnimationParamState = atomFamily(
  (projectId: string) => atomWithStorage<ParaviewRpc.GridMotionParam>(
    getStorageKey(projectId),
    defaultMotionAnimationParamState(),
  ),
);

export function useMotionAnimationParamState(projectId: string) {
  return useAtom(motionAnimationParamState(projectId));
}

// Whether the motion animation is active.
const motionAnimationPlayingState = atom(false);

export function useMotionAnimationPlaying() {
  return useAtom(motionAnimationPlayingState);
}
export function useSetMotionAnimationPlaying() {
  return useSetAtom(motionAnimationPlayingState);
}

const showMotionAnimationSettings = atom(false);

export function useShowMotionAnimationSettings() {
  return useAtom(showMotionAnimationSettings);
}
