// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { Choice } from '../../../../ProtoDescriptor';
import { ParamGroupName, paramGroupDesc } from '../../../../SimulationParamDescriptor';
import { createParamScope } from '../../../../lib/ParamScope';
import assert from '../../../../lib/assert';
import { SelectOptionGroup } from '../../../../lib/componentTypes/form';
import { protoChoicesToSelectOptions } from '../../../../lib/form';
import { FLUID_PRESET_CHOICES, disabledEnumValues } from '../../../../lib/materialUtils';
import { useNodePanel } from '../../../../lib/useNodePanel';
import { useMaterialEntity } from '../../../../model/hooks/useMaterialEntity';
import { useMaterials } from '../../../../model/hooks/useMaterials';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { useEnabledExperiments } from '../../../../recoil/useExperimentConfig';
import { useSimulationParamScope } from '../../../../state/external/project/simulation/paramScope';
import { DataSelect } from '../../../Form/DataSelect';
import LabeledInput from '../../../Form/LabeledInput';
import { CollapsiblePanel } from '../../../Panel/CollapsiblePanel';
import { ParamForm } from '../../../ParamForm';
import Divider from '../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { TempDependentMaterialRemoveParams, useMaterialFluidTempVaryingTabularData } from '../../../hooks/useTabularData';
import { AttributesDisplay } from '../../AttributesDisplay';
import PropertiesSection from '../../PropertiesSection';
import { MaterialVolumes } from '../shared/MaterialVolumes';

const { CUSTOM_MATERIAL_FLUID } = simulationpb.MaterialFluidPreset;

// Some settings should not be made read-only even if a preset is selected. For example, users may
// want to simulate "Standard Air" either as ideal gas or constant density. Similarly, they may want
// to use different viscosity or conductivity models for the same material.
const alwaysModifiableFields = new Set([
  'reference_pressure',
  'density_relationship',
  'laminar_viscosity_model_newtonian',
  'laminar_thermal_conductivity',
  'boussinesq_approximation',
]);

// Do not show the labels of these parameters because the preceding multiple choice makes it clear
// what they are.
const skipLabelParams = [
  'ConstantDensityValue',
  'LaminarConstantViscosityConstant',
  'LaminarConstantThermalPrandtlConstant',
  'LaminarConstantThermalConductivityConstant',
];

const paramGroup = paramGroupDesc[ParamGroupName.MaterialFluid];

export const MaterialFluidPropPanel = () => {
  // == Context
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected fluid material row');

  // == Recoil
  const experimentConfig = useEnabledExperiments();
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // == Hooks
  const propClasses = useCommonTreePropsStyles();
  const { availableFluidPresets } = useMaterials(projectId, workflowId, jobId, readOnly);
  const {
    fluid,
    updateFluidPreset,
    saveFluidMaterial,
    hasFixedPreset,
  } = useMaterialEntity(projectId, workflowId, jobId, readOnly, node.id);
  assert(!!fluid, 'No selected fluid material');

  const scope = useMemo(
    () => createParamScope(fluid, experimentConfig, paramScope),
    [experimentConfig, paramScope, fluid],
  );
  const { insertTabularElements } = useMaterialFluidTempVaryingTabularData(scope);

  // == Data
  const currentPreset = fluid.materialFluidPreset;
  const alwaysWritable = readOnly ? undefined : alwaysModifiableFields;
  const readOnlyForm = readOnly || hasFixedPreset;
  const defnPanel = useNodePanel(
    node.id,
    'definition',
    { defaultExpanded: currentPreset === CUSTOM_MATERIAL_FLUID },
  );

  const selectOptions: SelectOptionGroup<Choice['enumNumber']>[] = availableFluidPresets.map(
    (group) => {
      const options = group.map(({ preset, used }) => {
        const choice = FLUID_PRESET_CHOICES.find((item) => item.enumNumber === preset)!;
        const option = protoChoicesToSelectOptions([choice], currentPreset)[0];
        if (used && preset !== currentPreset) {
          option.disabled = true;
          option.disabledReason = 'This material has already been added to your project';
        }
        return option;
      });
      return {
        options,
      };
    },
  );

  const disableEnums = disabledEnumValues(currentPreset);

  return (
    <div className={propClasses.properties}>
      <AttributesDisplay attributes={[{ label: 'Type', value: 'Fluid Material' }]} />
      <Divider />
      <PropertiesSection>
        <LabeledInput label="Material">
          <DataSelect
            asBlock
            disabled={readOnly}
            onChange={(value) => {
              updateFluidPreset(value);
              defnPanel.setExpanded(value === CUSTOM_MATERIAL_FLUID);
            }}
            options={selectOptions}
            size="small"
          />
        </LabeledInput>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={defnPanel.collapsed}
          heading="Definitions"
          onToggle={defnPanel.toggle}>
          <ParamForm<simulationpb.MaterialFluid>
            alwaysWritable={alwaysWritable}
            group={paramGroup}
            ignoreNestLevel
            insertElement={insertTabularElements}
            key={paramGroup.name}
            onUpdate={saveFluidMaterial}
            options={{ disableEnums }}
            paramScope={scope}
            proto={fluid}
            readOnly={readOnlyForm}
            removeParams={['MaterialFluidPreset', ...TempDependentMaterialRemoveParams]}
            skipLabelParams={skipLabelParams}
          />
        </CollapsiblePanel>
      </PropertiesSection>
      <Divider />
      <MaterialVolumes materialId={node.id} />
    </div>
  );
};
