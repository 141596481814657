// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, ReactNode, useState } from 'react';

import IconButton from '@mui/material/IconButton';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';
import { ChartLineIcon } from '../svg/ChartLineIcon';
import { TriangleIcon } from '../svg/TriangleIcon';
import { XIcon } from '../svg/XIcon';
import Collapsible from '../transition/Collapsible';

const useStyles = makeStyles(
  () => createStyles({
    panel: {
      background: 'rgba(47, 48, 52, 0.78)',
      borderRadius: '4px',
      fontSize: '13px',
      padding: '8px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
    },
    headerTitle: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      overflow: 'hidden',
    },
    headerTitleText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    headerControls: {
      flex: '0 0 auto',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    button: {
      color: colors.neutral650,
    },
    control: {
      width: '10px',
      height: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconExpand: {
      color: '#8e8e8f',
      height: '24px',
      width: '24px',
    },
    close: {
      color: '#8e8e8f',
      height: '18px',
      width: '18px',
    },
  }),
  { name: 'JobDataPanel' },
);

interface JobDataPanelProps {
  pauseResumeToggle?: ReactElement | null;
  title: ReactElement;
  body: ReactElement;
  onClose?: () => void;
}

const JobDataPanel = (props: JobDataPanelProps) => {
  // When open, the table is shown. When closed the table is hidden.
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  let icon: ReactNode | null = null;
  if (props.onClose) {
    icon = <XIcon maxHeight={10} maxWidth={10} />;
  } else {
    icon = <TriangleIcon direction={open ? 'down' : 'right'} height={5} width={10} />;
  }
  return (
    <div className={classes.panel}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <ChartLineIcon maxHeight={17} maxWidth={17} />
          <div className={classes.headerTitleText}>
            {props.title}
          </div>
        </div>
        <div className={classes.headerControls}>
          {props.pauseResumeToggle}
          <IconButton
            className={classes.button}
            onClick={() => {
              if (props.onClose) {
                props.onClose();
              } else {
                setOpen(!open);
              }
            }}>
            <div className={classes.control}>
              {icon}
            </div>
          </IconButton>
        </div>
      </div>
      <Collapsible
        collapsed={!open}
        transitionPeriod={250}>
        {props.body}
      </Collapsible>
    </div>
  );
};

export default JobDataPanel;
