// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 27;
const baseHeight = 30;
export const StepForwardIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="stepForward"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M24,0
           L21,0
           C19.35,0 18,1.35 18,3
           L18,11.16
           L4.86,0.66
           L4.86,0.69
           C4.32,0.27 3.72,0 3,0
           C1.35,0 -0,1.35 -0,3
           L-0,27
           C-0,28.65 1.35,30 3,30
           C3.72,30 4.32,29.73 4.86,29.31
           L4.89,29.34
           L18,18.84
           L18,27
           C18,28.65 19.35,30 21,30
           L24,30
           C25.65,30 27,28.65 27,27
           L27,3
           C27,1.35 25.65,0 24,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
