// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ParamName, paramDesc } from '../../../../SimulationParamDescriptor';
import assert from '../../../../lib/assert';
import { findPeriodicPairById } from '../../../../lib/boundaryConditionUtils';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import Form from '../../../Form';
import { AdVector3Input } from '../../../Form/Vector3Input';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import Divider from '../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { usePeriodicPairSelection } from '../../../hooks/subselect/usePeriodicPairSelection';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { AttributesDisplay } from '../../AttributesDisplay';
import { NodeSubselect } from '../../NodeSubselect';
import PropertiesSection from '../../PropertiesSection';

interface SurfaceSelectorPanelProps {
  nodeId: string;
  periodicPair: simulationpb.PeriodicPair;
  sideA?: boolean;
  readOnly?: boolean;
}

const SurfaceSelectorPanel = (props: SurfaceSelectorPanelProps) => {
  const { nodeId, periodicPair, sideA, readOnly } = props;
  const currentSide = sideA ? 'sideA' : 'sideB';

  const { nodeFilter, setSurfaces } = usePeriodicPairSelection(currentSide);
  const { selectedNode: node } = useSelectionContext();

  const commonClasses = useCommonTreePropsStyles();

  const surfaces = sideA ? periodicPair.boundA : periodicPair.boundB;
  return (
    <CollapsibleNodePanel
      heading={sideA ? 'Side A' : 'Side B'}
      nodeId={nodeId}
      panelName={`periodic-pair-${sideA ? 'sideA' : 'sideB'}`}>
      <div className={commonClasses.sectionDescription}>
        Select all surfaces that represent one side of the periodic pair
      </div>
      <NodeSubselect
        id={`periodic-pair-table-${currentSide}`}
        independentSelection
        labels={['surfaces']}
        nodeFilter={nodeFilter}
        nodeIds={surfaces}
        onChange={setSurfaces}
        readOnly={readOnly}
        referenceNodeIds={[node?.id || '']}
        title="Surfaces"
      />
    </CollapsibleNodePanel>
  );
};

// A panel displaying all the properties of the periodic boundary pair.
export const PhysicsPeriodicPropPanel = () => {
  const commonClasses = useCommonTreePropsStyles();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected periodic pair row');

  const { simParam, saveParam } = useSimulationConfig();
  const { readOnly } = useProjectContext();

  const periodicPair = findPeriodicPairById(simParam, node.id);
  const updateField = (changeParam: (newBc: simulationpb.PeriodicPair) => void) => {
    saveParam((newParam) => {
      const newPair = findPeriodicPairById(newParam, node.id);
      if (newPair) {
        changeParam(newPair);
      }
    });
  };

  if (!periodicPair) {
    return <div />;
  }

  return (
    <div className={commonClasses.properties}>
      <AttributesDisplay
        attributes={[{ label: 'Type', value: 'Periodic Pair' }]}
      />
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel heading="Definition" nodeId={node.id} panelName="definition">
          <Form.LabeledInput
            help={paramDesc[ParamName.PeriodicTranslation].help}
            label="Translation">
            <AdVector3Input
              disabled={readOnly}
              onCommit={(vec) => updateField((newBc) => {
                newBc.periodicTranslation = vec;
              })}
              quantityType={paramDesc[ParamName.PeriodicTranslation].quantityType}
              value={periodicPair.periodicTranslation!}
            />
          </Form.LabeledInput>
          <Form.LabeledInput
            help={paramDesc[ParamName.PeriodicCenterOfRotation].help}
            label="Origin">
            <AdVector3Input
              disabled={readOnly}
              onCommit={(vec) => updateField((newBc) => {
                newBc.periodicCenterOfRotation = vec;
              })}
              quantityType={paramDesc[ParamName.PeriodicCenterOfRotation].quantityType}
              value={periodicPair.periodicCenterOfRotation!}
            />
          </Form.LabeledInput>
          <Form.LabeledInput
            help={paramDesc[ParamName.PeriodicRotationAngles].help}
            label="Rotation Angles">
            <AdVector3Input
              disabled={readOnly}
              onCommit={(vec) => updateField((newBc) => {
                newBc.periodicRotationAngles = vec;
              })}
              quantityType={paramDesc[ParamName.PeriodicRotationAngles].quantityType}
              value={periodicPair.periodicRotationAngles!}
            />
          </Form.LabeledInput>
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <SurfaceSelectorPanel
          nodeId={node.id}
          periodicPair={periodicPair}
          readOnly={readOnly}
          sideA
        />
      </PropertiesSection>
      <PropertiesSection>
        <SurfaceSelectorPanel
          nodeId={node.id}
          periodicPair={periodicPair}
          readOnly={readOnly}
        />
      </PropertiesSection>
    </div>
  );
};
