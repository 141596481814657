// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { EMPTY_VALUE } from '../../lib/constants';
import { parseString } from '../../lib/html';
import { formatNumber } from '../../lib/number';
import Tooltip from '../Tooltip';

interface OutputCellProps {
  result: number | undefined;
  status?: string;
  marginTop?: number;
  marginBottom?: number;
}

// A single cell of a table displaying one output value for a particular job.
const OutputCell = (props: OutputCellProps) => {
  const style: React.CSSProperties = {
    marginTop: props.marginTop ?? 0,
    marginBottom: props.marginBottom ?? 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'inherit',
  };

  const strValue = props.result ? formatNumber(props.result) : EMPTY_VALUE;

  const content = (
    <span className="tabularNumber compact">{strValue}</span>
  );

  return (
    <div style={style}>
      {props.status && props.status !== strValue ? (
        <Tooltip title={parseString(props.status)}>
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </div>
  );
};

export default OutputCell;
