// Copyright 2022 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, useEffect, useState } from 'react';

import { DragControl } from '../../lib/DragControl';
import { NestedBoxConstraint } from '../../lib/dragConstraints';
import { DragOffset } from '../../lib/dragUtil';
import { Point } from '../../lib/geometry';

// State that describes the item currently being dragged
interface DragState {
  id: string,
  initialPosition: Point;
  dragControl: DragControl;
}

export const useDraggable = () => {
  // Track how much the mouse has moved during a dragging operation
  const [dragOffset, setDragOffset] = useState<DragOffset | null>(null);
  // Track the current dragging operation state
  const [dragState, setDragState] = useState<DragState | null>(null);
  // Maintain positions of items that have been dragged
  const [draggedPositions, setDraggedPositions] = useState<Record<string, Point>>({});
  // Maintain a boolean state indicating a dragging operation is ongoing.  This
  // almost lines up with !!dragState, but it's not set until the first
  // mousemove event, and it's unset a few milliseconds after the dragging
  // operation ends (for properly ignoring onClick events when the drag
  // operation ends).
  const [isDragging, setIsDragging] = useState(false);
  // Expose the ID of the current drag operation
  const [currentDragId, setCurrentDragId] = useState('');

  const startDrag = () => {
    setIsDragging(true);
  };

  const doDrag = (offset: DragOffset) => {
    setDragOffset(offset);
  };

  const endDrag = () => {
    setDragState(null);
    setDragOffset(null);
    setTimeout(() => {
      setIsDragging(false);
    }, 200);
  };

  useEffect(() => {
    if (isDragging) {
      if (dragState) {
        setCurrentDragId(dragState.id);
      }
    } else {
      setCurrentDragId('');
    }
  }, [dragState, isDragging]);

  const initDrag = (
    id: string,
    event: React.MouseEvent,
    targetNode: HTMLElement,
    refNode: HTMLElement,
  ) => {
    if (event.button !== 0) {
      // button == 0 corresponds to left click; ignore right-clicks
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    if (dragState) {
      dragState.dragControl.cancel();
    }

    const constraint = new NestedBoxConstraint(targetNode, refNode, 0);

    setDragState({
      id,
      initialPosition: draggedPositions[id] || { x: 0, y: 0 },
      dragControl: new DragControl(
        event.nativeEvent,
        startDrag,
        doDrag,
        endDrag,
        [constraint],
      ),
    });
  };

  useEffect(
    () => {
      if (dragState && dragOffset) {
        const { id, initialPosition } = dragState;
        const x = initialPosition.x + dragOffset.deltaX;
        const y = initialPosition.y + dragOffset.deltaY;

        if (draggedPositions[id]?.x !== x || draggedPositions[id]?.y !== y) {
          // Only call setDraggedPositions if the coordinates have changed;
          // otherwise, an infinite loop will be triggered.
          setDraggedPositions({
            ...draggedPositions,
            [id]: { x, y },
          });
        }
      }
    },
    [dragState, dragOffset, draggedPositions, setDraggedPositions],
  );

  // A utility that maps a position to a CSS style object
  const dragPositionToStyle = (position: Point | undefined): CSSProperties => {
    if (position) {
      return {
        transform: `translateX(${position.x}px) translateY(${position.y}px)`,
      };
    }
    return {};
  };

  return {
    draggedPositions,
    dragPositionToStyle,
    initDrag,
    currentDragId,
  };
};
