// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const ScissorsIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="scissors"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M5.143,24
           C6.571,24 7.786,23.5 8.786,22.5
           C9.786,21.5 10.286,20.286 10.286,18.857
           C10.286,18 10.089,17.196 9.696,16.446
           L12,14.143
           L20.25,22.393
           C20.75,22.893 21.357,23.143 22.071,23.143
           C22.786,23.143 23.393,22.893 23.893,22.393
           C23.964,22.321 24,22.223 24,22.098
           C24,21.973 23.964,21.875 23.893,21.804
           L14.089,12
           L23.893,2.196
           C23.964,2.125 24,2.027 24,1.902
           C24,1.777 23.964,1.679 23.893,1.607
           C23.393,1.107 22.786,0.857 22.071,0.857
           C21.357,0.857 20.75,1.107 20.25,1.607
           L12,9.857
           L9.696,7.554
           C10.089,6.804 10.286,6 10.286,5.143
           C10.286,3.714 9.786,2.5 8.786,1.5
           C7.786,0.5 6.571,0 5.143,0
           C3.714,0 2.5,0.5 1.5,1.5
           C0.5,2.5 0,3.714 0,5.143
           C0,6.571 0.5,7.786 1.5,8.786
           C2.5,9.786 3.714,10.286 5.143,10.286
           C6,10.286 6.804,10.089 7.554,9.696
           L9.857,12
           L7.554,14.304
           C6.804,13.911 6,13.714 5.143,13.714
           C3.714,13.714 2.5,14.214 1.5,15.214
           C0.5,16.214 0,17.429 0,18.857
           C0,20.286 0.5,21.5 1.5,22.5
           C2.5,23.5 3.714,24 5.143,24Z
           M5.143,7.714
           C4.429,7.714 3.821,7.464 3.321,6.964
           C2.821,6.464 2.571,5.857 2.571,5.143
           C2.571,4.429 2.821,3.821 3.321,3.321
           C3.821,2.821 4.429,2.571 5.143,2.571
           C5.857,2.571 6.464,2.821 6.964,3.321
           C7.464,3.821 7.714,4.429 7.714,5.143
           C7.714,5.857 7.464,6.464 6.964,6.964
           C6.464,7.464 5.857,7.714 5.143,7.714Z
           M5.143,21.429
           C4.429,21.429 3.821,21.179 3.321,20.679
           C2.821,20.179 2.571,19.571 2.571,18.857
           C2.571,18.143 2.821,17.536 3.321,17.036
           C3.821,16.536 4.429,16.286 5.143,16.286
           C5.857,16.286 6.464,16.536 6.964,17.036
           C7.464,17.536 7.714,18.143 7.714,18.857
           C7.714,19.571 7.464,20.179 6.964,20.679
           C6.464,21.179 5.857,21.429 5.143,21.429Z
           M5.143,24
           C6.571,24 7.786,23.5 8.786,22.5
           C9.786,21.5 10.286,20.286 10.286,18.857
           C10.286,18 10.089,17.196 9.696,16.446
           L12,14.143
           L20.25,22.393
           C20.75,22.893 21.357,23.143 22.071,23.143
           C22.786,23.143 23.393,22.893 23.893,22.393
           C23.964,22.321 24,22.223 24,22.098
           C24,21.973 23.964,21.875 23.893,21.804
           L14.089,12
           L23.893,2.196
           C23.964,2.125 24,2.027 24,1.902
           C24,1.777 23.964,1.679 23.893,1.607
           C23.393,1.107 22.786,0.857 22.071,0.857
           C21.357,0.857 20.75,1.107 20.25,1.607
           L12,9.857
           L9.696,7.554
           C10.089,6.804 10.286,6 10.286,5.143
           C10.286,3.714 9.786,2.5 8.786,1.5
           C7.786,0.5 6.571,0 5.143,0
           C3.714,0 2.5,0.5 1.5,1.5
           C0.5,2.5 0,3.714 0,5.143
           C0,6.571 0.5,7.786 1.5,8.786
           C2.5,9.786 3.714,10.286 5.143,10.286
           C6,10.286 6.804,10.089 7.554,9.696
           L9.857,12
           L7.554,14.304
           C6.804,13.911 6,13.714 5.143,13.714
           C3.714,13.714 2.5,14.214 1.5,15.214
           C0.5,16.214 0,17.429 0,18.857
           C0,20.286 0.5,21.5 1.5,22.5
           C2.5,23.5 3.714,24 5.143,24Z
           M5.143,7.714
           C4.429,7.714 3.821,7.464 3.321,6.964
           C2.821,6.464 2.571,5.857 2.571,5.143
           C2.571,4.429 2.821,3.821 3.321,3.321
           C3.821,2.821 4.429,2.571 5.143,2.571
           C5.857,2.571 6.464,2.821 6.964,3.321
           C7.464,3.821 7.714,4.429 7.714,5.143
           C7.714,5.857 7.464,6.464 6.964,6.964
           C6.464,7.464 5.857,7.714 5.143,7.714Z
           M5.143,21.429
           C4.429,21.429 3.821,21.179 3.321,20.679
           C2.821,20.179 2.571,19.571 2.571,18.857
           C2.571,18.143 2.821,17.536 3.321,17.036
           C3.821,16.536 4.429,16.286 5.143,16.286
           C5.857,16.286 6.464,16.536 6.964,17.036
           C7.464,17.536 7.714,18.143 7.714,18.857
           C7.714,19.571 7.464,20.179 6.964,20.679
           C6.464,21.179 5.857,21.429 5.143,21.429Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
