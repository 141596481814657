// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';
import { copiedName } from '../../../lib/name';
import * as random from '../../../lib/random';
import { ASYNC_VIS_NODES, AsyncVisNodeType, addChildNode, applyVisibilityToNode, findNodeAndParent, isClipOrSlice, visComputeFilterWrapper } from '../../../lib/visUtils';
import { UrlType } from '../../../proto/projectstate/projectstate_pb';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useMeshUrlState } from '../../../recoil/meshState';
import { useActiveVisUrlValue } from '../../../recoil/vis/activeVisUrl';
import { useFilterState } from '../../../recoil/vis/filterState';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';

/**
 * @returns a callback to duplicate the vis filter node with the given id.
 */
export const useCopyVisFilter = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const { setSelection } = useSelectionContext();
  const { viewState, addNode, activeEdit } = useParaviewContext();

  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const [lcvisFilterState, setLcvisFilterState] = useFilterState({ projectId, workflowId, jobId });
  const activeVisUrl = useActiveVisUrlValue({ projectId, workflowId, jobId });
  const [meshUrlState] = useMeshUrlState(projectId);
  const isMesh = meshUrlState.activeType === UrlType.MESH;

  const copyLcvFilter = useCallback((id: string): void => {
    const prevRoot = lcvisFilterState;
    let newId = '';
    // update the filterstate with a copy of the filter node
    const [node, parent] = findNodeAndParent(prevRoot, id);
    if (!node) {
      return;
    }
    // copy the node. Its children shouldn't be copied.
    newId = random.string(32);
    const newNode = { ...node, child: [], id: newId, name: copiedName(node.name) };
    // if node is defined, its parent must exist (since all vis filters are children of the reader)
    let newRoot = addChildNode(prevRoot, parent!.id, newNode);
    newRoot = applyVisibilityToNode(
      newRoot,
      newId,
      isClipOrSlice(newNode),
      newNode.visible,
      isMesh,
    );

    setLcvisFilterState(newRoot);
    lcvHandler.queueDisplayFunction('update filter state', (display) => {
      const { filterHandler } = display;
      filterHandler?.deleteAllNodes();
      filterHandler?.applyInitialState(newRoot);
    });
    if (newId) {
      setSelection([newId]);
    }
  }, [setLcvisFilterState, lcvisFilterState, setSelection, isMesh]);

  const copyParaviewFilter = useCallback((id: string) => {
    if (!viewState) {
      return;
    }
    const [node, parent] = findNodeAndParent(viewState.root, id);
    if (!node) {
      return;
    }
    const newNode = { ...node, child: [], id: random.string(32), name: copiedName(node.name) };
    // if node is defined, its parent must exist (since all vis filters are children of the reader)
    addNode(parent!.id, newNode);
    if (ASYNC_VIS_NODES.includes(node.param.typ as AsyncVisNodeType)) {
      visComputeFilterWrapper(
        projectId,
        activeVisUrl,
        newNode.param,
        newNode.id,
        activeEdit,
      );
    }
    setSelection([newNode.id]);
  }, [addNode, viewState, setSelection, activeEdit, activeVisUrl, projectId]);

  return lcvisEnabled ? copyLcvFilter : copyParaviewFilter;
};
