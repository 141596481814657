// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 20;
const baseHeight = 20;
export const CheckBulletIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      fill="none"
      height={finalHeight}
      ref={ref}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}>
      <circle cx="10" cy="10" r="9" strokeWidth="2" style={{ stroke: getColor(0) }} />
      <path
        d="M7 10.5L9 12.5L13 8.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        style={{ stroke: getColor(0) }}
      />
    </svg>
  );
});
