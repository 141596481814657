// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { replaceInterfaceWithContactsWarning } from '../../lib/simulationTree/deletionWarningUtils';
import { useMultiphysicsInterfaces } from '../../model/hooks/useMultiphysicsInterfaces';
import { initializeNewNode, useSetNewNodes } from '../../recoil/nodeSession';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

import { useSimulationConfig } from './useSimulationConfig';

export const useMultiphysicsNode = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection } = useSelectionContext();

  // == Recoil
  const setConfirmStack = useSetConfirmations();
  const setNewNodes = useSetNewNodes();

  // == Custom hooks
  const { saveParamAsync, simParam } = useSimulationConfig();
  const {
    addMultiphysicsInterfacesFromContacts,
  } = useMultiphysicsInterfaces(projectId, workflowId, jobId, readOnly);

  const addInterfaces = useCallback(async () => {
    const newIds = await saveParamAsync(
      (newParam) => addMultiphysicsInterfacesFromContacts(newParam),
    );
    if (newIds.length) {
      setSelection(newIds);
      setNewNodes((nodes) => [...nodes, ...newIds.map((id) => initializeNewNode(id))]);
    }
  }, [addMultiphysicsInterfacesFromContacts, saveParamAsync, setSelection, setNewNodes]);

  // Given that addInterfaces is destructive, we show a modal explaining the user that the
  // interfaces will be erased when copying the contacts.
  const queueAddFromContacts = async () => {
    // If there are no interfaces, then there is no data-loss and hence we don't have to show
    // the modal.
    if (!simParam?.interfaces?.length) {
      await addInterfaces();
      return;
    }

    pushConfirmation(setConfirmStack, {
      destructive: true,
      onContinue: async () => {
        await addInterfaces();
      },
      title: 'Convert geometry contacts into interfaces',
      children: replaceInterfaceWithContactsWarning('multiphysics coupling'),
    });
  };

  return {
    queueAddFromContacts,
  };
};
