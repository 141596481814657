// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { formatNumber } from '../../lib/number';
import { ActionButton } from '../Button/ActionButton';
import { Flex } from '../visual/Flex';

export interface ScaleButtonsProps {
  onClick: (scale: number) => void;
  scaleValues: number[];
  disabled?: boolean;
  getHelp?: (formattedValue: string) => string;
}

export const ScaleButtons = (props: ScaleButtonsProps) => {
  const { onClick, scaleValues, disabled, getHelp } = props;

  const valueList = useMemo(() => scaleValues.map((value) => ({
    value,
    formatted: formatNumber(value),
  })), [scaleValues]);

  return (
    <Flex gap={4}>
      {valueList.map(({ value, formatted }) => (
        <ActionButton
          disabled={disabled}
          key={value}
          kind="minimal"
          onClick={() => onClick(value)}
          size="small"
          title={getHelp?.(formatted)}>
          {formatted}x
        </ActionButton>
      ))}
    </Flex>
  );
};
