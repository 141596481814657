// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import * as EmailValidator from 'email-validator';

import { isItarEnv } from '../../lib/RuntimeParams';
import { colors } from '../../lib/designSystem';
import { Logger } from '../../lib/observability/logs';
import * as rpc from '../../lib/rpc';
import * as status from '../../lib/status';
import useFormValidation from '../../lib/useFormValidation';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import { RadioButton } from '../Form/RadioButton';
import { TextInput } from '../Form/TextInput';
import { createStyles, makeStyles } from '../Theme';
import { SectionMessage } from '../notification/SectionMessage';
import { AutoCollapsingMessage } from '../visual/AutoCollapsingMessage';

import { Dialog } from './Base';

const logger = new Logger('dialog/User');

const useStyles = makeStyles(
  () => createStyles({
    formSections: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '16px',
    },
    formContainerGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '24px',
    },
    formContainerCell: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '4px',
    },
    formLabel: {
      color: colors.neutral900,
      lineHeight: '24px',
      fontWeight: 600,
    },
    formHeading: {
      color: colors.neutral900,
      lineHeight: '24px',
      fontWeight: 600,
      marginBottom: '8px',
    },
    radioButtonField: {
      flex: '1 1 50%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: '8px',
    },
    radioButtonInput: {
      flex: '0 0 auto',
      display: 'flex',
      padding: '3px 0',
    },
    radioButtonLabel: {
      color: colors.highEmphasisText,
      lineHeight: '24px',
      fontWeight: 600,
      marginBottom: '4px',
    },
    radioButtonDescription: {
      fontWeight: 400,
      lineHeight: '20px',
      color: colors.lowEmphasisText,
    },
  }),
  { name: 'User' },
);

export interface UserDialogProps {
  open: boolean;
  onClose: () => void;
  user?: frontendpb.AccountInfoReply_UserInfo;
}

export type AccountRole = 'user' | 'admin';
const USER_ROLE: AccountRole = 'user';
const ADMIN_ROLE: AccountRole = 'admin';

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  role: AccountRole;
}

function initialFormValues(user?: frontendpb.AccountInfoReply_UserInfo): FormValues {
  return {
    firstName: user?.givenName || '',
    lastName: user?.familyName || '',
    email: user?.email || '',
    role: (user?.accountRole || USER_ROLE) as AccountRole,
  };
}
export const UserDialog = (props: UserDialogProps) => {
  // Props
  const { open, user } = props;

  // State
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues(user));
  const [working, setWorking] = useState(false);

  // Derived state
  const isEdit = !!user;
  const titleText = isEdit ? 'Edit User' : 'Add User';
  const submitButtonText = isEdit ? 'Update' : 'Add';
  const userId = user?.userId || '';
  const lcUserId = user?.lcUserId || '';

  // Hooks
  const classes = useStyles();
  const { validate, errors } = useFormValidation([
    {
      key: 'firstName',
      value: formValues.firstName,
      required: true,
    },
    {
      key: 'lastName',
      value: formValues.lastName,
      required: true,
    },
    {
      key: 'email',
      value: formValues.email,
      required: true,
      validate: (str: string) => EmailValidator.validate(str),
    },
  ]);

  // Effects
  useEffect(() => {
    if (open) {
      // Initialize fields when opening
      setFormValues(initialFormValues(user));
    }
  }, [open, user]);

  // Methods
  const createUser = async () => {
    const req = new frontendpb.CreateUserRequest({
      firstName: formValues.firstName.trim(),
      lastName: formValues.lastName.trim(),
      // normalize this email before we create the user by making it lowercase
      email: formValues.email.toLowerCase().trim(),
      role: formValues.role,
      newUserLogIn: true,
    });
    await rpc.callRetry('CreateUser', rpc.client.createUser, req).then(() => {
      props.onClose();
    }).catch((error) => {
      logger.warn(`createUser error: ${status.stringifyError(error)}`);
    });
  };

  const updateUser = async () => {
    const req = new frontendpb.UpdateUserRequest({
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      role: formValues.role,
      userId,
      lcUserId,
    });
    await rpc.callRetry('UpdateUser', rpc.client.updateUser, req).then(() => {
      props.onClose();
    }).catch((error) => {
      logger.warn(`updateUser error: ${status.stringifyError(error)}`);
    });
  };

  // Handlers
  const changeFormValue = (field: keyof FormValues, value: string) => {
    setFormValues((prevValue) => ({ ...prevValue, [field]: value }));
  };

  const handleSubmit = async () => {
    if (validate()) {
      setWorking(true);
      if (userId || lcUserId) {
        await updateUser();
      } else {
        await createUser();
      }
      setWorking(false);
    }
  };

  return (
    <Dialog
      cancelButton={{ label: 'Cancel' }}
      continueButton={{ label: submitButtonText }}
      controlState={working ? 'working' : 'normal'}
      modal
      onClose={props.onClose}
      onContinue={handleSubmit}
      open={open}
      subtitle="Input a user&apos;s contact information and set their permissions."
      title={titleText}>
      <div className={classes.formSections}>
        <section>
          <div className={classes.formContainerGrid}>
            <div className={classes.formContainerCell}>
              <div className={classes.formLabel}>First Name</div>
              <div>
                <TextInput
                  asBlock
                  disabled={working}
                  faultType={errors.firstName ? 'error' : undefined}
                  name="firstName"
                  onChange={(value) => changeFormValue('firstName', value)}
                  placeholder="Enter First Name…"
                  value={formValues.firstName}
                />
              </div>
              <AutoCollapsingMessage level="error" message={errors.firstName} />
            </div>
            <div className={classes.formContainerCell}>
              <div className={classes.formLabel}>Last Name</div>
              <div>
                <TextInput
                  asBlock
                  disabled={working}
                  faultType={errors.lastName ? 'error' : undefined}
                  name="lastName"
                  onChange={(value) => changeFormValue('lastName', value)}
                  placeholder="Enter Last Name…"
                  value={formValues.lastName}
                />
              </div>
              <AutoCollapsingMessage level="error" message={errors.lastName} />
            </div>
            <div className={classes.formContainerCell}>
              <div className={classes.formLabel}>Email</div>
              <div>
                <TextInput
                  asBlock
                  dataPrivate
                  disabled={working || isEdit}
                  faultType={errors.email ? 'error' : undefined}
                  name="email"
                  onChange={(value) => changeFormValue('email', value)}
                  placeholder="Enter Email…"
                  value={formValues.email}
                />
              </div>
              <AutoCollapsingMessage level="error" message={errors.email} />
            </div>
          </div>
        </section>
        <hr />
        <section>
          <div className={classes.formHeading}>Role</div>
          <div className={classes.formContainerGrid}>
            <div className={classes.formContainerCell}>
              <div className={classes.radioButtonField}>
                <div className={classes.radioButtonInput}>
                  <RadioButton
                    checked={formValues.role === USER_ROLE}
                    disabled={working}
                    name="userRole"
                    onClick={() => {
                      changeFormValue('role', USER_ROLE);
                    }}
                    value={USER_ROLE}
                  />
                </div>
                <div>
                  <div className={classes.radioButtonLabel}>User</div>
                  <div className={classes.radioButtonDescription}>
                    Standard user account
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.formContainerCell}>
              <div className={classes.radioButtonField}>
                <div className={classes.radioButtonInput}>
                  <RadioButton
                    checked={formValues.role === ADMIN_ROLE}
                    disabled={working}
                    name="userRole"
                    onClick={() => {
                      changeFormValue('role', ADMIN_ROLE);
                    }}
                    value={ADMIN_ROLE}
                  />
                </div>
                <div>
                  <div className={classes.radioButtonLabel}>Admin</div>
                  <div className={classes.radioButtonDescription}>
                    Admin users can view and edit all account-wide settings, including
                    management of users, security, usage and billing.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isItarEnv && (
          <SectionMessage
            level="warning"
            message="This is an ITAR environment. Please ensure this user is approved to access
              ITAR data."
            title="ITAR Environment"
          />
        )}
      </div>
    </Dialog>
  );
};
