// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import { CommonMenuItem } from '../../../lib/componentTypes/menu';
import useButtonMenu from '../../../lib/useButtonMenu';
import { PageDescriptor } from '../../../lib/usePagination';
import { CommonMenu } from '../../Menu/CommonMenu';
import { HorizontalCirclesTripleIcon } from '../../svg/HorizontalCirclesTripleIcon';

export interface PageSliceControlProps {
  slice: PageDescriptor[];
  onSelectPage: (page: number) => void;
}

/**
 * In a paginated data set, some pages will have explicit PageLinkControl representations, but
 * large slices of page links may be replaced by a kebab icon that also acts as a button with a
 * drop-down menu of all the pages in the slice.
 */
export const PageSliceControl = (props: PageSliceControlProps) => {
  const { onSelectPage, slice } = props;
  const { anchorEl, anchorRef, onClickButton, onCloseMenu } = useButtonMenu();

  const menuItems: CommonMenuItem[] = slice.map(({ page, display }) => ({
    type: 'item',
    label: `${display}`,
    onClick: () => onSelectPage(page),
  }));

  return (
    <div className="pageControl" ref={anchorRef}>
      <button
        className="controlButton sliceControlButton"
        onClick={onClickButton}
        type="button">
        <HorizontalCirclesTripleIcon maxHeight={8} maxWidth={12} />
      </button>
      {menuItems && (
        <CommonMenu
          anchorEl={anchorEl}
          closeOnSelect
          menuItems={menuItems}
          onClose={onCloseMenu}
          open={!!anchorEl}
        />
      )}
    </div>
  );
};
