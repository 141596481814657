// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily } from 'recoil';

import { NodeType, OUTPUT_CONTAINER_ID, OUTPUT_REFERENCE_VALUE_NODE_ID, SimulationTreeNode } from '../../../../lib/simulationTree/node';
import * as feoutputpb from '../../../../proto/frontend/output/output_pb';
import { outputNodesState } from '../../../../recoil/outputNodes';

export const outputsSectionSelector = selectorFamily<SimulationTreeNode, string>({
  key: 'outputsSection',
  get: (projectId: string) => async ({ get }) => {
    const outputNodes = get(outputNodesState({ projectId, workflowId: '', jobId: '' }));

    const treeOutputNodes: SimulationTreeNode[] = [
      // Add the node for the Reference Values
      new SimulationTreeNode(
        NodeType.REFERENCE_VALUE,
        OUTPUT_REFERENCE_VALUE_NODE_ID,
        'Reference Values',
      ),
      // Add each output as a separate tree node
      ...outputNodes.nodes.map(
        ({ id, name }: feoutputpb.OutputNode) => new SimulationTreeNode(NodeType.OUTPUT, id, name),
      ),
    ];
    return new SimulationTreeNode(
      NodeType.OUTPUT_CONTAINER,
      OUTPUT_CONTAINER_ID,
      'Outputs',
      treeOutputNodes,
    );
  },
  dangerouslyAllowMutability: true,
});
