// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { Fragment, ReactElement, cloneElement, useState } from 'react';

import { CommonMenuItem, CommonMenuPosition } from '../../lib/componentTypes/menu';
import { CommonMenu } from '../Menu/CommonMenu';

export interface DropdownProps {
  disableCloseOnClick?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  /**
   * Reference element used to position the menu and control whether it's open
   * or not. This will typically be some kind of button.
   */
  toggle: ReactElement; // The toggle element cannot be React.fragment (<></>).
  menuItems: CommonMenuItem[];
  position?: CommonMenuPosition;
  maxWidth?: number;
  /** Corresponds to the flushAlign CommonMenu prop */
  flushAlign?: boolean;
}

/**
 * MUI's API for dropdown menus is powerful but pretty low-level and a pain to
 * use on its own. This is a basic dropdown component that accepts a `toggle`
 * element and a menuItems prop that is compatible with CommonMenu; it handles
 * open/close states.
 */
const Dropdown = (props: DropdownProps) => {
  // Make sure that the onClose prop is not in the otherProps or it will override the onClose
  // handler that we set manually.
  const { disableCloseOnClick, menuItems, position, onOpen, onClose, ...otherProps } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOnClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  const toggle = cloneElement(props.toggle, {
    ...props.toggle.props,
    onClick: (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      onOpen?.();
    },
  });

  return (
    <Fragment>
      {toggle}
      <CommonMenu
        anchorEl={anchorEl}
        closeOnSelect={!disableCloseOnClick}
        menuItems={menuItems}
        onClose={handleOnClose}
        open={!!anchorEl}
        position={position}
        {...otherProps}
      />
    </Fragment>
  );
};

export default Dropdown;
