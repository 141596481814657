// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as ProtoDescriptor from '../ProtoDescriptor';
import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../SimulationParamDescriptor';
import { Vector3 } from '../proto/base/base_pb';
import * as simulationpb from '../proto/client/simulation_pb';
import { RectilinearTable } from '../proto/table/table_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';

import { adVec3ToVec3, getAdValue } from './adUtils';
import { initParamGroupProto } from './initParam';
import { prefixNameGen, uniqueSequenceName } from './name';
import { boundsSphereRadius } from './paraviewUtils';
import { newNodeId } from './projectDataUtils';
import { ProbePointsTableModel, generateProbePointsTable } from './rectilinearTable/model';

type ChoiceValue = ProtoDescriptor.Choice['enumNumber'];

const { ACTUATOR_DISK_SPECIFY_NORMAL_VECTOR } = simulationpb.ActuatorDiskOrientationSelection;

export class ProbePoint {
  public readonly vector: Vector3;

  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly x: number,
    public readonly y: number,
    public readonly z: number,
  ) {
    this.vector = new Vector3({ x, y, z });
  }
}

// Generate a new name for a particle group, based on the selected type
function newName(param: simulationpb.SimulationParam, type: ProtoDescriptor.Choice): string {
  const particlesByType = param.particleGroup.filter(
    (particle) => (particle.particleGroupType === type.enumNumber),
  );

  const existingNamesOfType = particlesByType?.map((particle) => particle.particleGroupName);

  return uniqueSequenceName(existingNamesOfType || [], prefixNameGen(type.text));
}

function findParticleGroupTypeChoice(type: ChoiceValue) {
  return (paramDesc[ParamName.ParticleGroupType] as ProtoDescriptor.MultipleChoiceParam)
    .choices.find(
      (choice) => choice.enumNumber === type,
    );
}

// Instantiate a particle group and initialize the particle group type
export function newParticleGroup(
  param: simulationpb.SimulationParam,
  type: ProtoDescriptor.Choice,
) {
  const name = newName(param, type);

  const particleGroup = initParamGroupProto(
    new simulationpb.ParticleGroup(),
    paramGroupDesc[ParamGroupName.ParticleGroup],
  );

  particleGroup.particleGroupId = newNodeId();
  particleGroup.particleGroupName = name;
  particleGroup.particleGroupType = type.enumNumber;
  return particleGroup;
}

// Remove a particle group by ID
export function removeParticleGroup(param: simulationpb.SimulationParam, id: string): boolean {
  const oldParticleGroups = param.particleGroup;
  const newParticleGroups = oldParticleGroups.filter(
    (particleGroup) => (particleGroup.particleGroupId !== id),
  );
  if (newParticleGroups.length < oldParticleGroups.length) {
    param.particleGroup = newParticleGroups;
    return true;
  }
  return false;
}

// Return a particle group proto of type ACTUATOR_DISK
export function newActuatorDisk(param: simulationpb.SimulationParam) {
  const choice = findParticleGroupTypeChoice(simulationpb.ParticleGroupType.ACTUATOR_DISK);
  return newParticleGroup(param, choice!);
}

// If a particle group of type PROBE_POINTS exists, it *should* be a singleton.
// Find and return it if it exists
export function getProbePointsParticleGroup(param: simulationpb.SimulationParam) {
  return param.particleGroup.find((particleGroup) => (
    particleGroup.particleGroupType === simulationpb.ParticleGroupType.PROBE_POINTS
  ));
}

// If a particle group of type PROBE_POINTS exists, it contains a pointer to a
// table with actual probe point values that are represented in the tree.  The
// actual PROBE_POINT particle group should not be represented in the tree.
export function getParticleGroupsForSimulationTree(param: simulationpb.SimulationParam) {
  return param.particleGroup.filter((particleGroup) => (
    particleGroup.particleGroupType !== simulationpb.ParticleGroupType.PROBE_POINTS
  ));
}

// Initialize table in global table map
function initializeProbePointsTable(
  param: simulationpb.SimulationParam,
  probePoints: simulationpb.ParticleGroup,
) {
  if (probePoints.particleGroupType !== simulationpb.ParticleGroupType.PROBE_POINTS) {
    throw Error('Particle group is not of type PROBE_POINTS');
  }

  const mapKey = newNodeId();
  const table = generateProbePointsTable();
  param.table[mapKey] = table;
  probePoints.particlePositionsTable = mapKey;

  return table;
}

// If a particle group of type PROBE_POINTS doesn't exist, create one.  Return
// a tuple of (particle group, created) where created is true if the particle
// group had to be created.
export function getOrCreateProbePoints(
  param: simulationpb.SimulationParam,
): simulationpb.ParticleGroup {
  const existing = getProbePointsParticleGroup(param);
  if (existing) {
    return existing;
  }

  const choice = findParticleGroupTypeChoice(simulationpb.ParticleGroupType.PROBE_POINTS);
  const probePoints = newParticleGroup(param, choice!);
  param.particleGroup.push(probePoints);

  initializeProbePointsTable(param, probePoints);

  return probePoints;
}

export function pruneProbePointsParticleGroup(param: simulationpb.SimulationParam) {
  const particleGroups = param.particleGroup.filter((particleGroup) => (
    particleGroup.particleGroupType !== simulationpb.ParticleGroupType.PROBE_POINTS
  ));
  param.particleGroup = particleGroups;
}

export function findParticleGroupById(
  param: simulationpb.SimulationParam,
  id: string,
): simulationpb.ParticleGroup | undefined {
  return param.particleGroup.find(
    (particleGroup) => particleGroup.particleGroupId === id,
  );
}

// Return a human-readable label for a given particle group type
export function particleGroupTypeLabel(type: simulationpb.ParticleGroupType): string {
  const choice = findParticleGroupTypeChoice(type);
  return choice?.text || '';
}

// Parameters for an actuator disk imposter in the setup tab
export function newActuatorDiskParam(groupId: string): ParaviewRpc.ActuatorDiskParam {
  return {
    typ: ParaviewRpc.TreeNodeType.ACTUATOR_DISK,
    plane: {
      typ: 'Plane',
      // Assume (0,0,0) is the center of interest.
      origin: { x: 0, y: 0, z: 0 },
      // The default normal is the YZ plane.
      normal: { x: 0, y: 0, z: 0 },
    },
    // Interpret the normal as either a normal or Euler angle.
    usenormal: false,
    circumferentialresolution: 50,
    // Actuator Disk radial resolution
    radialresolution: 10,
    // Inner radius of the disk
    innerradius: 0,
    // Outer radius of the disk
    outerradius: 1,
    particleGroupId: groupId,
  };
}

export function actuatorDiskParamFromParticleGroup(
  particleGroup: simulationpb.ParticleGroup,
): ParaviewRpc.ActuatorDiskParam {
  const center = adVec3ToVec3(particleGroup.actuatorDiskCenter!);
  const innerRadius = particleGroup.actuatorDiskInnerRadius!;
  const outerRadius = particleGroup.actuatorDiskOuterRadius!;
  const normal = adVec3ToVec3(particleGroup.actuatorDiskNormalVector!);
  const euler = adVec3ToVec3(particleGroup.actuatorDiskRotationAngle!);
  const useNormal = (
    particleGroup.actuatorDiskOrientationSelection === ACTUATOR_DISK_SPECIFY_NORMAL_VECTOR
  );
  const rot = useNormal ? normal : euler;
  return {
    typ: ParaviewRpc.TreeNodeType.ACTUATOR_DISK,
    plane: {
      typ: 'Plane',
      origin: {
        x: center.x,
        y: center.y,
        z: center.z,
      },
      normal: {
        x: rot.x,
        y: rot.y,
        z: rot.z,
      },
    },
    // Interpret the normal as either a normal or Euler angle.
    usenormal: useNormal,
    circumferentialresolution: 50,
    // Actuator Disk radial resolution
    radialresolution: 10,
    // Inner radius of the disk
    innerradius: getAdValue(innerRadius),
    // Outer radius of the disk
    outerradius: getAdValue(outerRadius),
    particleGroupId: particleGroup.particleGroupId,
  };
}

export function newMonitorPointParam(
  particleId: string,
  root: ParaviewRpc.TreeNode,
  attrs: ParaviewRpc.ViewAttrs,
): ParaviewRpc.MonitorPointParam {
  let radius = 1.0;
  // If there is a global sphere size, use that.
  if (attrs.sphereSize) {
    radius = attrs.sphereSize;
  } else if (root.bounds) {
    // Scale the volume mesh bounding box sphere radius to provide a better
    // initial guess of the sphere radius.
    radius = boundsSphereRadius(root.bounds) * 0.3;
  }
  return {
    typ: ParaviewRpc.TreeNodeType.MONITOR_POINT,
    point: {
      typ: 'Sphere',
      center: {
        x: 0,
        y: 0,
        z: 0,
      },
      radius,
    },
    id: particleId,
  };
}

// Return a ProbePoint object with a unique ID, a sequential name (based on
// existing) point names, and default coordinates of (0, 0, 0).
export function newProbePoint(table: ProbePointsTableModel): ProbePoint {
  const existingNames = table.records.map(
    (record) => record.getNamedEntry('name') as string,
  );
  const name = uniqueSequenceName(existingNames, prefixNameGen('Point'));
  return new ProbePoint(newNodeId(), name, 0, 0, 0);
}

function getTableFromProbePoints(
  param: simulationpb.SimulationParam,
  probePoints: simulationpb.ParticleGroup,
) {
  if (probePoints.particleGroupType !== simulationpb.ParticleGroupType.PROBE_POINTS) {
    throw new Error('Particle group is not a monitor point.');
  }
  const mapKey = probePoints.particlePositionsTable;
  if (mapKey) {
    return param.table[mapKey];
  }

  return null;
}

export function monitorPointParamFromParticleGroup(
  particleGroup: simulationpb.ParticleGroup,
  particleId: string,
  param: simulationpb.SimulationParam,
  viewState: ParaviewRpc.ViewState,
)
  : ParaviewRpc.MonitorPointParam {
  let xVal = 0;
  let yVal = 0;
  let zVal = 0;
  const table = getTableFromProbePoints(param, particleGroup);
  let radius = 1.0;
  // If there is a global sphere size, use that.
  if (viewState.attrs?.sphereSize) {
    radius = viewState.attrs.sphereSize;
  } else if (viewState.root.bounds) {
    // Scale the volume mesh bounding box sphere radius to provide a better
    // initial guess of the sphere radius.
    radius = boundsSphereRadius(viewState.root.bounds) * 0.3;
  }
  if (table) {
    // Use a TableModel
    const tableModel = new ProbePointsTableModel(table);
    const pointRecord = tableModel.getRecord('id', particleId);
    if (pointRecord) {
      xVal = pointRecord.getNamedEntry('x') as number;
      yVal = pointRecord.getNamedEntry('y') as number;
      zVal = pointRecord.getNamedEntry('z') as number;
    }
  }
  return {
    typ: ParaviewRpc.TreeNodeType.MONITOR_POINT,
    point: {
      typ: 'Sphere',
      center: {
        x: xVal,
        y: yVal,
        z: zVal,
      },
      radius,
    },
    id: particleId,
  };
}

// If a particle group of type PROBE_POINTS exists, return its associated table
// from the global table map
export function getProbePointsTable(param: simulationpb.SimulationParam) {
  const probePoints = getProbePointsParticleGroup(param);
  if (probePoints) {
    return getTableFromProbePoints(param, probePoints);
  }
  return null;
}

export function getOrCreateProbePointsTable(param: simulationpb.SimulationParam): RectilinearTable {
  const probePoints = getOrCreateProbePoints(param);
  const table = getTableFromProbePoints(param, probePoints);
  if (table) {
    return table;
  }
  return initializeProbePointsTable(param, probePoints);
}

export function getProbePointName(
  param: simulationpb.SimulationParam,
  id: string,
): string | undefined {
  const tableModel = new ProbePointsTableModel(getOrCreateProbePointsTable(param));
  const entry = tableModel.getRecord('id', id)?.getNamedEntry('name');
  // entry can be string | number
  return `${entry}`;
}

// If a particle group of type PROBE_POINTS exists, return a list of ProbePoint
// objects constructed from its table
export function getProbePoints(param: simulationpb.SimulationParam): ProbePoint[] {
  const table = getProbePointsTable(param);
  if (table) {
    const tableModel = new ProbePointsTableModel(table);
    return tableModel.records.map((record) => new ProbePoint(
      record.getNamedEntry('id') as string,
      record.getNamedEntry('name') as string,
      record.getNamedEntry('x') as number,
      record.getNamedEntry('y') as number,
      record.getNamedEntry('z') as number,
    ));
  }

  return [];
}
