// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Empty } from '@bufbuild/protobuf';
import { atom } from 'recoil';

import { Logger } from '../lib/observability/logs';
import * as rpc from '../lib/rpc';
import * as frontendpb from '../proto/frontend/frontend_pb';

const logger = new Logger('recoil/state');

// List of projects shown in the home page.  Caches the last reply from
// ListProjects RPC.
export const projectListState = atom<frontendpb.ListProjectsReply | null>({
  key: 'projectList',
  default: null,
  effects: [
    ({ setSelf }) => {
      rpc.callRetry('listProjects', rpc.client.listProjects, new Empty()).then(
        (response) => setSelf(response),
      ).catch(
        (err) => logger.warn('Error trying to list projects', err),
      );
    },
  ],
});
