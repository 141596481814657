// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { BaseEditor, Editor, Node, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

/**
 * Get the text from the current line from the start of the currently selected node to the
 * cursor position.
 *
 * @param editor the editor to get the line fome
 * @param unit the unit to get, default to 'line'
 *
 * @returns the current text and its range if it exists, otherwise undefined
 */
export function getCurrentLineText(
  editor: BaseEditor & ReactEditor,
  unit: 'line' | 'word' = 'line',
): { text: string, range: Range } | undefined {
  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    // user has not selected a range of text
    // get the text written so far
    const [start] = Range.edges(selection);
    const lineBefore = Editor.before(editor, start, { unit });
    const lineRange = lineBefore && Editor.range(editor, lineBefore, start);
    return lineRange && { text: Editor.string(editor, lineRange), range: lineRange };
  }
  return undefined;
}

/**
 * Move the cursor to the end of the editor
 *
 * @param editor the editor to move
 */
export function moveCursorToEnd(editor: BaseEditor & ReactEditor) {
  Transforms.select(editor, Editor.end(editor, []));
}

/**
 * Replace the Node state of the editor
 *
 * @param editor the editor to modify
 * @param value the new value for the editor
 */
export function setEditorValue(editor: BaseEditor & ReactEditor, value: Node[]) {
// Delete all entries leaving 1 empty node
  Transforms.delete(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  });
  // Removes empty node
  Transforms.removeNodes(editor, {
    at: [0],
  });
  // Insert array of children nodes
  Transforms.insertNodes(
    editor,
    value,
  );
}
