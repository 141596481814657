// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilValue } from 'recoil';

import { TransientNotification } from '../lib/notificationUtils';
import * as transientNotification from '../lib/transientNotification';

/**
 * Transient notifications in recoil, kept in sync with the notifications
 * map in transientNotification.ts
 */
const transientNotificationState = atomFamily<
  TransientNotification[],
  transientNotification.PageKey
>({
  key: 'transientNotificationState',
  default: [],
  effects: (key: transientNotification.PageKey) => [({ setSelf }) => {
    // Sync transientNotificationState with transientNotification.notifications.
    const cb: transientNotification.Callback = (notification: TransientNotification[]) => {
      setSelf(notification);
    };
    transientNotification.addCallback(cb, key);
    return () => transientNotification.removeCallback(cb, key);
  }],
});

/**
 * Given a page, returns all the notifications to be shown given a specific page.
 * Global notifications (pageKey = DEFAULT_PAGE) will be shown on all pages.
 * Project notifications (pageKey = <projectId>) will be shown only on pages within that
 * project. Project tab notifications (pageKey = { <projectId>, <workflowId>, <jobId> })
 * will be shown only on the specific tab of the given project.
*/
const transientNotificationSelector = selectorFamily<
  TransientNotification[],
  transientNotification.PageKey
>({
  key: 'transientNotificationSelector',
  get: (pageKey: transientNotification.PageKey) => ({ get }) => {
    // The selector will always return the 'global' notifications list.
    const glob = [...get(transientNotificationState(transientNotification.DEFAULT_PAGE))];
    if (pageKey === transientNotification.DEFAULT_PAGE) {
      return glob;
    }
    glob.push(...get(transientNotificationState(pageKey)));
    if (transientNotification.isProjectKey(pageKey)) {
      // If the key is for a specific project, also show the 'project-wide' notifications
      // for that project.
      glob.push(...get(transientNotificationState(pageKey.projectId)));
    }
    // Sort the notifications so that the most recent one appears at the top.
    glob.sort((a, b) => b.createTime.getTime() - a.createTime.getTime());
    return glob;
  },
});

export const useTransientNotificationValue = (
  key: transientNotification.PageKey,
) => useRecoilValue(transientNotificationSelector(key));
