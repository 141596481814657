// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import AuthForm, { DataValues } from '../../components/Form/AuthForm';
import { AuthPageLayout } from '../../components/layout/page/AuthPageLayout';
import { MFA_TOKEN_EXPIRED_ERROR, SetupSms2FALocationState, getLoginTokenWithOobMfa, isMfaTokenExpired } from '../../lib/AuthMFA';
import { handleAuthenticatedV2 } from '../../lib/AuthV2';
import { getUserErrorMessage } from '../../lib/errors';
import { routes } from '../../lib/navigation';
import { Logger } from '../../lib/observability/logs';
import { isStorybookEnv } from '../../lib/testing/utils';

import Setup2FABackup from './Setup2FABackup';
import Setup2FAComplete from './Setup2FAComplete';

const logger = new Logger('SetupApp2FA');

export enum PageState {
  FORM,
  BACKUP,
  DONE,
}

interface SetupSms2FAProps {
  // Optional initial page state. This should be used ONLY for the storybook
  initialPageState?: PageState;
  // Optional initial oob. This should be used ONLY for the storybook
  initialOob?: {
    authenticatorType: string,
    oobCode: string,
    recoveryCodes: string[],
  }
}

// This page is where the user enters the code received to their mobile phone for configuring
// the SMS-based MFA. If the code is correct, the next step is to show a recovery code
// that the user has to write down or download. The last step is just a transient success page that
// will be shown for 5 seconds before the user is redirected into the app.
const SetupSms2FA = (props: SetupSms2FAProps) => {
  // Props
  const { initialPageState = PageState.FORM, initialOob } = props;

  // Hooks
  const locationState = useLocation().state as SetupSms2FALocationState;
  const navigate = useNavigate();

  // State
  const [pageState, setPageState] = useState(initialPageState);
  const [submitting, setSubmitting] = useState(false);
  const [fieldsError, setFieldsError] = useState<{ code?: string }>({});
  const [loginToken, setLoginToken] = useState('');

  const oobSetup = initialOob || locationState?.oobSetup;

  // Effects
  useEffect(() => {
    if (isStorybookEnv()) {
      return;
    }

    if (props.initialPageState !== undefined || props.initialOob !== undefined) {
      throw Error('Do not use the initialPageState/initialOob outside of storybook env.');
    }
  }, [props.initialOob, props.initialPageState]);

  const handleSubmit = async (data: DataValues) => {
    if (isMfaTokenExpired(locationState?.mfaTokenExpiry)) {
      navigate(routes.login, { state: { error: MFA_TOKEN_EXPIRED_ERROR } });
      return;
    }

    // If there isn't oobCode or mfaToken in the locationState, redirect to the login page because
    // we won't be able to finish the setup without it.
    if (!oobSetup?.oobCode || !locationState.mfaToken) {
      navigate(routes.login, {
        state: {
          error: 'Authentication error: missing OOB setup code or MFA auth token',
        },
      });
      return;
    }

    setFieldsError({});
    setSubmitting(true);

    try {
      // Get the login token and go to the next step of writing down the recovery code. The final
      // step should pass the loginToken to the handleAuthenticatedV2. It should then set
      // the AuthState to AuthState.AUTHENTICATED and the useEffect in the main index.tsx should
      // redirect to the projects page.
      const token = await getLoginTokenWithOobMfa(
        locationState.mfaToken,
        '',
        oobSetup?.oobCode,
        data.code,
        true,
        // data.remember === 'true',
      );
      setLoginToken(token);

      if (oobSetup?.recoveryCodes?.length) {
        setPageState(PageState.BACKUP);
      } else {
        setPageState(PageState.DONE);
      }
    } catch (err) {
      logger.error('SetupSms2FA failed during get login token: ', err);
      setFieldsError({ code: getUserErrorMessage(err) });
    }
    setSubmitting(false);
  };

  // Automatically submit the form when 6 symbols are entered
  const handleChange = async (data: DataValues) => {
    if (data.code.length === 6) {
      await handleSubmit(data);
    }
  };

  // This will be called on the last step step after the countdown expires.
  const handleComplete = useCallback(() => {
    if (loginToken) {
      // When we pass the loginToken to the handleAuthenticatedV2, it will set the AuthState to
      // authenticated. This will be interecpted by the main useEffect in the index.tsx and the user
      // will be redirected to the app.
      handleAuthenticatedV2(loginToken).catch(() => { });
    }
  }, [loginToken]);

  if (pageState === PageState.BACKUP) {
    return (
      <Setup2FABackup
        onContinue={() => setPageState(PageState.DONE)}
        recoveryCode={oobSetup?.recoveryCodes?.[0] || ''}
      />
    );
  }

  // This is the last step. After it's shown for a while, the AuthState will be updated with the
  // loginToken and the user will be redirected into the app.
  if (pageState === PageState.DONE) {
    return <Setup2FAComplete onComplete={handleComplete} />;
  }

  return (
    <AuthPageLayout
      back
      subtitle={`Enter the 6-digit code we texted to ${locationState?.phoneNumber}.
        It may take a minute for the SMS to arrive.`}
      title="Enter the code we sent to verify it’s you">
      <AuthForm
        fields={[
          {
            asBlock: true,
            autofocus: true,
            label: '6-digit Code',
            disabled: submitting,
            name: 'code',
            placeholder: '0 0 0 0 0 0',
            required: true,
          },
          // The "remember" option stopped worked after the Auth0 Actions migration.
          // Hiding the checkbox until LC-19810 is resolved.
          // {
          //   label: 'Remember this device',
          //   disabled: submitting,
          //   name: 'remember',
          //   type: 'checkbox',
          // },
        ]}
        fieldsError={fieldsError}
        onChange={handleChange}
        onSubmit={handleSubmit}
        resetFieldsOnError={['code']}
        submit={{
          disabled: submitting,
          showSpinner: submitting,
          label: 'Activate',
        }}
      />
    </AuthPageLayout>
  );
};

export default SetupSms2FA;
