// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { useRecoilCallback } from 'recoil';

import { getLocalStorageData } from '../../../lib/browserStorage';
import { Color, IconName } from '../../../lib/componentTypes/svgIcon';
import { colors } from '../../../lib/designSystem';
import { Notification } from '../../../lib/notificationUtils';
import { ListenerEvent, isMouseEvent, useEventListener } from '../../../lib/useEventListener';
import { notificationReadState } from '../../../recoil/useNotifications';
import { ActionLink } from '../../Button/ActionLink';
import { SvgIcon } from '../../Icon/SvgIcon';
import { createStyles, makeStyles } from '../../Theme';

import { NotificationCard } from './NotificationCard';

const useStyles = makeStyles(
  () => createStyles({
    dropdown: {
      boxShadow:
        `0px 1px 0px 0px ${colors.surfaceLight1} inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.50);`,
      backgroundColor: colors.neutral200,
      borderRadius: '8px',
      position: 'fixed',
      top: '44px',
      right: '10px',
      width: '350px',
      maxHeight: 'calc(100% - 200px)',
      zIndex: 10,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  }),
  { name: 'NotificationCenter' },
);

const usePlaceholderStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      height: '88px',
      width: '100%',
    },
    message: {
      color: colors.lowEmphasisText,
      fontSize: '13px',
      lineHeight: '16px',
    },
  }),
  { name: 'NotificationCenter-Placeholder' },
);

interface PlaceholderProps {
  iconName: IconName;
  iconColor: Color;
  message: string;
}

// A placeholder card used for special, non-notification items
// in the center (for example, the empty state or the end marker).
const Placeholder = (props: PlaceholderProps) => {
  const classes = usePlaceholderStyles();
  return (
    <div className={classes.root} data-locator="notificationCard">
      <SvgIcon
        color={props.iconColor}
        maxHeight={24}
        maxWidth={24}
        name={props.iconName}
      />
      <div className={classes.message}>
        {props.message}
      </div>
    </div>
  );
};

const useNotificationListStyles = makeStyles(
  () => createStyles({
    divider: {
      backgroundColor: colors.neutral100,
      height: '1px',
      width: '100%',
    },
  }),
  { name: 'NotificationCenter-NotificationList' },
);

interface NotificationListProps {
  notifications: Notification[];
  unread: boolean;
}

const NotificationList = (props: NotificationListProps) => {
  const classes = useNotificationListStyles();
  if (props.notifications.length === 0) {
    return (
      <Placeholder
        iconColor={colors.green600}
        iconName="flag"
        message={`No ${props.unread ? 'Unread' : ''} Notifications`}
      />
    );
  }
  return (
    <>
      {[...props.notifications]
        .sort((n1, n2) => n2.timestamp.getTime() - n1.timestamp.getTime())
        .map((notif) => (
          <Fragment key={notif.id}>
            <NotificationCard
              notification={notif}
            />
            <div className={classes.divider} />
          </Fragment>
        ))}
      <Placeholder
        iconColor={colors.green600}
        iconName="flag"
        message="That's all your notifications from the last 30 days"
      />
    </>
  );
};

const borderSizePx = 2;

const useHeaderStyles = makeStyles(
  () => createStyles({
    root: {
      padding: '12px 20px 0px 12px',
      borderBottom: `${borderSizePx}px solid ${colors.neutral100}`,
    },
    topContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      color: colors.highEmphasisText,
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
    },
    bottomContainer: {
      display: 'flex',
    },
    toggle: {
      backgroundColor: colors.neutral200,
      cursor: 'pointer',
      marginBottom: `${-1 * borderSizePx}px`,
      padding: '8px',
      border: 'none',
      '&.active': {
        color: colors.highEmphasisText,
        borderBottom: `${borderSizePx}px solid ${colors.highEmphasisText}`,
      },
      '&.inactive': {
        color: colors.inputPlaceholderText,
        borderBottom: `${borderSizePx}px solid ${colors.neutral100}`,
      },
    },
    markRead: {
      fontSize: '13px',
      lineHeight: '16px',
    },
  }),
  { name: 'NotificationCenter-Header' },
);

interface HeaderProps {
  unread: boolean;
  markAllRead: () => void;
  onToggle: (unread: boolean) => void;
}

const Header = (props: HeaderProps) => {
  const classes = useHeaderStyles();
  return (
    <div className={classes.root}>
      <div className={classes.topContainer}>
        <div className={classes.title}>
          Notifications
        </div>
        <ActionLink onClick={props.markAllRead}>
          <div className={classes.markRead}>
            Mark all as read
          </div>
        </ActionLink>
      </div>
      <div className={classes.bottomContainer}>
        <button
          className={cx(props.unread ? 'inactive' : 'active', classes.toggle)}
          onClick={() => props.onToggle(false)}
          type="button">
          All
        </button>
        <button
          className={cx(props.unread ? 'active' : 'inactive', classes.toggle)}
          onClick={() => props.onToggle(true)}
          type="button">
          Unread
        </button>
      </div>
    </div>
  );
};

interface NotificationCenterProps {
  notifications: Notification[];
  open: boolean;
  setOpen: (val: boolean) => void;
}

export const NotificationCenter = (props: NotificationCenterProps) => {
  const classes = useStyles();
  const { open, setOpen } = props;
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const unreadNotifications = props.notifications.filter((notification) => !notification.read);

  const unreadNotificationsKey = 'showUnreadNotifications';
  const [showUnread, setShowUnread] = useState<boolean>(
    getLocalStorageData(unreadNotificationsKey, false),
  );
  useEffect(() => {
    localStorage.setItem(unreadNotificationsKey, `${showUnread}`);
  }, [showUnread]);

  const displayNotifications = showUnread ? unreadNotifications : props.notifications;

  // Close the notifications list when a user clicks outside of the component.
  const handleCloseNotifications = useCallback((event: MouseEvent) => {
    if (
      isMouseEvent(event) &&
      open &&
      event.target instanceof HTMLElement &&
      !bodyRef.current?.contains(event.target) &&
      event.target.closest(
        '[data-locator]',
      )?.getAttribute('data-locator') !== 'navNotificationsButton'
    ) {
      setOpen(false);
    }
  }, [open, setOpen]);

  useEventListener(
    'mouseup',
    (event: ListenerEvent) => handleCloseNotifications(event as MouseEvent),
  );

  const markAllAsRead = useRecoilCallback(({ set }) => () => {
    props.notifications.forEach(
      (notification) => set(notificationReadState(notification.id), true),
    );
  });

  return (
    <div style={{ display: 'inline-flex' }}>
      {open && (
        <div className={classes.dropdown} ref={bodyRef}>
          <Header
            markAllRead={markAllAsRead}
            onToggle={setShowUnread}
            unread={showUnread}
          />
          <NotificationList
            notifications={displayNotifications}
            unread={showUnread}
          />
        </div>
      )}
    </div>
  );
};
