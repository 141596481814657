// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { useMeshMetadata, useMeshUrlState } from '../recoil/meshState';

import { newProto } from './Vector';
import { BoundingBox, centroid, mergeBoundingBoxes } from './geometry';
import { defaultBoundingBox, meshAggregateStats } from './mesh';

function mergeBoxes(initBox: BoundingBox, boxes: Record<string, BoundingBox>, ids: string[]) {
  return ids.reduce((result, id) => {
    if (boxes[id]) {
      return mergeBoundingBoxes(result, boxes[id]);
    }
    return result;
  }, initBox);
}

export const useMeshGeometry = (projectId: string) => {
  const [meshUrl] = useMeshUrlState(projectId);
  const meshMetadata = useMeshMetadata(projectId, meshUrl.mesh)?.meshMetadata;

  const meshStats = useMemo(() => meshAggregateStats(meshMetadata), [meshMetadata]);

  const calculateBoundingBox = useCallback(
    (volumeIds: string[], surfaceIds: string[]): BoundingBox => {
      let box = defaultBoundingBox();
      box = mergeBoxes(box, meshStats.boundingBoxes.volumes, volumeIds);
      box = mergeBoxes(box, meshStats.boundingBoxes.surfaces, surfaceIds);

      return box;
    },
    [meshStats],
  );

  const calculateCentroid = useCallback((volumeIds: string[], surfaceIds: string[]) => {
    const box = calculateBoundingBox(volumeIds, surfaceIds);
    const boxCenter = centroid(box);

    return newProto(boxCenter.x, boxCenter.y, boxCenter.z);
  }, [calculateBoundingBox]);

  return {
    meshStats,
    calculateBoundingBox,
    calculateCentroid,
  };
};
