// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 381;
const baseHeight = 381;
export const GridOffIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="gridOff"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M23.335,0
           L0,23.335
           L357.665,381
           L381,357.665
           L23.335,0Z
           M34,57.335
           L34,315
           C34,332.661 48.339,347 66,347
           L323.665,347
           L291.665,315
           L266,315
           L266,289.335
           L234,257.335
           L234,315
           L166,315
           L166,247
           L223.665,247
           L191.665,215
           L166,215
           L166,189.335
           L134,157.335
           L134,215
           L66,215
           L66,147
           L123.665,147
           L91.665,115
           L66,115
           L66,89.335
           L34,57.335Z
           M134,247
           L66,247
           L66,315
           L134,315
           L134,247Z
           M86.418,15
           L118.418,47
           L134,47
           L134,62.582
           L166,94.582
           L166,47
           L234,47
           L234,115
           L186.418,115
           L218.418,147
           L234,147
           L234,162.582
           L266,194.582
           L266,147
           L334,147
           L334,215
           L286.418,215
           L318.418,247
           L334,247
           L334,262.582
           L366,294.582
           L366,47
           C366,29.339 351.661,15 334,15
           L86.418,15Z
           M334,47
           L266,47
           L266,115
           L334,115
           L334,47Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
