// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useEffect, useRef, useState } from 'react';

import { type DraggableEventHandler } from 'react-draggable';

import { ElementDetails, findClosestElementIdentifier } from '../../../lib/tabUtils';

import { type TabProps } from './Tab';

export interface TabConfig extends TabProps {
  id: string;
  draggable?: boolean;
}

interface UseDraggableTabsProps {
  tabs: TabConfig[];
  containerRef: React.RefObject<HTMLDivElement>;
  orderPersistenceKey: string;
}

/**
 * This hook manages the behavior of draggable tabs, handling the necessary calculations
 * and logic to ensure a smooth user experience. It calculates the tab shifts, sorts tabs,
 * and provides handlers for the `react-draggable` wrapper. Additionally, it offers helper
 * functions to further enhance the user interaction.
 *
 * The hook returns handlers for `react-draggable` and utils to handle the UX things properly,
 * such as offsets, currently dragged tab and dragging constraints.
 */
export function useDraggableTabs({
  tabs,
  containerRef,
  orderPersistenceKey,
}: UseDraggableTabsProps) {
  const [persistedOrder, setPersistedOrder] = useState<string[]>([]);
  const elementDetailsByIdentifier = useRef(new Map<string, ElementDetails>());
  const [currentlyDraggedId, setCurrentlyDraggedId] = useState<null | string>(null);
  const [tempShiftByIdentifier, setTempShiftByIdentifier] = useState<Record<string, number>>(
    Object.fromEntries(tabs.map(({ id }) => [id, 0])),
  );

  useEffect(() => {
    const persistedOrderContent = localStorage.getItem(orderPersistenceKey) || '[]';
    const persistedOrderJson = JSON.parse(persistedOrderContent);

    if (Array.isArray(persistedOrderJson)) {
      setPersistedOrder(persistedOrderJson);
    }
  }, [orderPersistenceKey]);

  const getDragHandler = (id: string): DraggableEventHandler => (_event, data) => {
    if (data.deltaX !== 0) {
      setCurrentlyDraggedId(id);
    }

    const currentElementDetails = elementDetailsByIdentifier.current.get(id);
    const offsetSortedIdentifiers = [...elementDetailsByIdentifier.current.entries()]
      .sort(([, { offset: offsetA }], [, { offset: offsetB }]) => offsetA - offsetB)
      .map(([identifier]) => identifier);

    if (!currentElementDetails) {
      return;
    }

    const { width } = currentElementDetails;

    const closestElementIdentifier = findClosestElementIdentifier(
      data.x,
      id,
      elementDetailsByIdentifier.current,
    );

    const currentElementIndex = offsetSortedIdentifiers.indexOf(id);
    const closestElementIndex = offsetSortedIdentifiers.indexOf(closestElementIdentifier);

    const indexDelta = closestElementIndex - currentElementIndex;

    const shiftByIdentifier =
    Object.fromEntries(offsetSortedIdentifiers.map((identifier, index) => {
      let shift = 0;

      if (index === currentElementIndex) {
        shift = data.x;
      } else if (index > currentElementIndex && index <= currentElementIndex + indexDelta) {
        shift = -width;
      } else if (index < currentElementIndex && index >= currentElementIndex + indexDelta) {
        shift = width;
      }

      return [identifier, shift];
    }));

    setTempShiftByIdentifier(shiftByIdentifier);
  };

  /**
 * Recalculates the size of each tab when the tab size changes,
 * ensuring proper handling of tab swapping during user drag actions.
 */
  const updateWrapperSizes = useCallback(() => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    const availableTabs = [...container.querySelectorAll('[data-tab-id]')] as HTMLElement[];

    availableTabs.forEach((tabElement) => {
      const tabIdentifier = tabElement.dataset.tabId;

      if (tabIdentifier) {
        elementDetailsByIdentifier.current.set(tabIdentifier, {
          offset: tabElement.offsetLeft,
          width: tabElement.offsetWidth,
        });
      }
    });
  }, [containerRef]);

  const dragStopHandler = () => {
    // delay the reset action so that click is not executed when dragging stops
    requestAnimationFrame(() => {
      setCurrentlyDraggedId(null);
    });

    const sortedIdentifiersAfterShift =
      [...elementDetailsByIdentifier.current.entries()]
        .map(([identifier, initialDetails]) => {
          const tempShift = tempShiftByIdentifier[identifier];

          return [identifier, initialDetails.offset + tempShift] as const;
        })
        .sort(([, aOffset], [, bOffset]) => aOffset - bOffset)
        .map(([identifier]) => identifier);

    setPersistedOrder(sortedIdentifiersAfterShift);
    localStorage.setItem(orderPersistenceKey, JSON.stringify(sortedIdentifiersAfterShift));
    updateWrapperSizes();

    setTempShiftByIdentifier(
      Object.fromEntries([...elementDetailsByIdentifier.current.keys()].map((key) => [key, 0])),
    );
  };

  // trigger wrapper sizes update when tabs.length changes
  useEffect(() => {
    updateWrapperSizes();
  }, [updateWrapperSizes, tabs.length]);

  const sortedElements = [...elementDetailsByIdentifier.current.entries()]
    .sort(([, { offset: offsetA }], [, { offset: offsetB }]) => offsetA - offsetB);
  const firstElementOffset = sortedElements.at(0)?.[1]?.offset || 0;

  const minimumOffsetByIdentifier = Object.fromEntries(sortedElements
    .map(([identifier, { offset }]) => [identifier, firstElementOffset - offset]));

  const sortedTabs = [...tabs].sort((a, b) => {
    // prioritize non-draggable items first
    if (!a.draggable && b.draggable) {
      return -1;
    }
    if (a.draggable && !b.draggable) {
      return 1;
    }

    const aIndex = persistedOrder.indexOf(a.id);
    const bIndex = persistedOrder.indexOf(b.id);

    // if element has not been found - push it to the right side
    const aFinalIndex = aIndex === -1 ? Number.MAX_SAFE_INTEGER : aIndex;
    const bFinalIndex = bIndex === -1 ? Number.MAX_SAFE_INTEGER : bIndex;

    return aFinalIndex - bFinalIndex;
  });

  return {
    currentlyDraggedId,
    getDragHandler,
    dragStopHandler,
    updateWrapperSizes,
    tempShiftByIdentifier,
    minimumOffsetByIdentifier,
    sortedTabs,
  };
}
