// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect } from 'react';

import { selectorFamily, useRecoilValue, useSetRecoilState, waitForAll } from 'recoil';

import { fromBigInt } from '../lib/number';
import * as frontendpb from '../proto/frontend/frontend_pb';

import { jobsState, startJobStateRpc } from './jobState';
import { selectedIterationState } from './selectedIter';

type RecoilKey = {
  projectId: string,
  workflowId: string,
  jobId: string,
};

export const selectedSolution = selectorFamily<frontendpb.Solution | null, RecoilKey>({
  key: 'selectedSolution',
  get: (key: RecoilKey) => async ({ get }) => {
    const [jobState, selectedIteration] = get(
      waitForAll([jobsState(key.jobId), selectedIterationState(key)]),
    );
    const solutions = jobState?.solutions;
    if (solutions && selectedIteration >= 0) {
      for (let i = 0; i < solutions.length; i += 1) {
        if (fromBigInt(solutions[i].iter) === selectedIteration) {
          return solutions[i];
        }
      }
    }
    return null;
  },
});

// Returns the state of the currently selected solution. If there is no solution
// available yet, the state will be null
export const useSelectedSolution = (projectId: string, workflowId: string, jobId: string) => {
  const setJobState = useSetRecoilState(jobsState(jobId));
  // Since the selected solution state depends on the jobsState we have to
  // start the streaming rpc if it is not running yet
  useEffect(
    () => startJobStateRpc(projectId, workflowId, jobId, setJobState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, workflowId, jobId],
  );
  return useRecoilValue(selectedSolution({ projectId, workflowId, jobId }));
};
