// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

// Based on the current usage of the statusPage (status.luminarycloud.com),
// we schedule a maintenace before prod upgrade. It is expected to notify a user
// thrice - between 3hr-1hr, 1hr-10min, <10min before the upgrade.

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Logger } from '../lib/observability/logs';
import {
  NotifState,
  StatusPageResponse,
  cleanup,
  createNotifMessage,
  fetchInterval,
  isStatusPageResponse,
  notifEntryKey,
  ttl,
} from '../lib/prodStatus';

import { createStyles, makeStyles } from './Theme';
import { Banner, BannerProps } from './notification/Banner';

const logger = new Logger('ProdStatus');

const statusPageURL = 'https://status.luminarycloud.com';
const statusPageAPI = 'https://status.luminarycloud.com/api/v2/scheduled-maintenances/upcoming.json';
const notifTimes = [10, 60, 180]; // in mins

const useStyles = makeStyles(() => createStyles({
  statusContainer: {
    width: '100%',
    position: 'relative',
    z_index: 20,
  },
}), { name: 'ProdStatus' });

export const ProdStatus = () => {
  const classes = useStyles();
  const [bannerProp, setBannerProp] = useState<BannerProps>();
  const notifState = useRef<NotifState>();

  const storeNotifState = (state: NotifState) => {
    localStorage.setItem(state.id, JSON.stringify(state));
    notifState.current = state;
  };

  const processMaintenance = useCallback((data: StatusPageResponse) => {
    if (!isStatusPageResponse(data)) {
      // remove any existing banner
      setBannerProp(undefined);
      logger.error(`Malformed JSON response from status page: ${data}`);
      return;
    }

    const curTime = new Date().getTime();
    const maintenances = data.scheduled_maintenances;
    // Get maintenance within last notifTime.
    const curMaintenances = maintenances.filter((maint) => {
      const scheduledFor = new Date(maint.scheduled_for).getTime();
      const timeDiff = (scheduledFor - curTime) / 60_000;
      return timeDiff > 0 && timeDiff < notifTimes[notifTimes.length - 1];
    });

    if (!curMaintenances.length) {
      // remove any existing banner
      setBannerProp(undefined);
      return;
    }

    if (curMaintenances.length > 1) {
      logger.error('Overlapping maintenances created in statusPage.');
      throw Error('Overlapping maintenances');
    }

    const maintenance = curMaintenances[0];
    const id = notifEntryKey(maintenance.id);
    const scheduledForDate = new Date(maintenance.scheduled_for);
    const scheduledFor = scheduledForDate.getTime();
    const scheduledUntil = new Date(maintenance.scheduled_until).getTime();

    // Get notifState
    const item = localStorage.getItem(id);
    notifState.current = item ? JSON.parse(item) : undefined;

    // Set notifState - 1) if not found,
    // 2) deleted while component is mounted,
    // 3) stale notifState (very unlikely)
    if (!notifState.current || notifState.current.id !== id) {
      const state: NotifState = {
        id,
        dismissed: Array<boolean>(notifTimes.length).fill(false),
        expiration: curTime + ttl,
      };
      storeNotifState(state);
    }

    if (!notifState.current) {
      // Cannot access local storage.
      setBannerProp(undefined);
      return;
    }

    const bannerMsg = createNotifMessage(scheduledForDate, scheduledUntil - scheduledFor);

    // Time in mins until maintenance.
    const timeDiff = (scheduledFor - curTime) / 60_000;
    // Find current time interval
    const notifIndex = notifTimes.findIndex((time) => timeDiff <= time);
    const dismissed = notifState.current.dismissed;

    // Display appropriate notif if not seen.
    if (!dismissed[notifIndex]) {
      dismissed[notifIndex] = true;
      const onDismiss = () => {
        storeNotifState({ ...notifState.current as NotifState, dismissed });
        setBannerProp(undefined);
      };
      const newBannerProp: BannerProps = {
        message: bannerMsg,
        level: 'warning',
        onDismiss,
        link: {
          label: 'Learn More',
          href: statusPageURL,
          external: true,
        },
      };
      // Set error level for firstNotif
      if (!notifIndex) {
        newBannerProp.level = 'error';
      }
      setBannerProp(newBannerProp);
    }
  }, []);

  useEffect(() => {
    let didCancel = false;
    const controller = new AbortController();

    // Fetch upcoming maintainces.
    const getScheduledMaintenance = async () => {
      const res = await fetch(statusPageAPI, { signal: controller.signal });
      const data = await res.json();
      if (didCancel) {
        return;
      }
      processMaintenance(data);
    };

    cleanup();
    getScheduledMaintenance().catch((err) => logger.warn(err));

    // Repeat every INTERVAL_MS.
    const interval = setInterval(() => {
      getScheduledMaintenance().catch((err) => logger.warn(err));
    }, fetchInterval);

    // On component unmount
    return () => {
      didCancel = true;
      // cancel previous fetch
      controller.abort();
      // clear interval to prevent memory leaks.
      clearInterval(interval);
    };
  }, [processMaintenance]);

  return (
    <>
      <div className={classes.statusContainer}>
        {bannerProp && (
          <Banner
            {...bannerProp}
            key={bannerProp.message}
          />
        )}
      </div>
    </>
  );
};
