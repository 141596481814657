// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/*
 Displays the list of projects for a user. This is usually a landing page.
*/
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilState } from 'recoil';

import { ActionButton } from '../components/Button/ActionButton';
import suspenseWidget from '../components/SuspenseWidget';
import { createStyles, makeStyles } from '../components/Theme';
import { useCommonTablePageStyles } from '../components/Theme/commonStyles';
import { ActionGroup, CollectionControlBar } from '../components/data/CollectionControlBar';
import { Table } from '../components/data/Table';
import ProjectDialog from '../components/dialog/Project';
import { MainPageLayout } from '../components/layout/page/Main';
import { useProjectDialogState } from '../components/project/controller/dialog/useProjectDialogState';
import { useSaveProject } from '../components/project/controller/useSaveProject';
import { LoadingEllipsis } from '../components/visual/LoadingEllipsis';
import ProjectListPoller from '../lib/ProjectListPoller';
import { CommonMenuItem } from '../lib/componentTypes/menu';
import { SvgIconSpec } from '../lib/componentTypes/svgIcon';
import { Banner, ColumnConfig, RowConfig, RowId } from '../lib/componentTypes/table';
import { colors } from '../lib/designSystem';
import { ERRORS, parseError } from '../lib/errors';
import { getLcUserId } from '../lib/jwt';
import { subtractSet } from '../lib/lang';
import { projectLink, routes } from '../lib/navigation';
import { fromBigInt } from '../lib/number';
import { useCheckProjectRole } from '../lib/projectRoles';
import { SHARED_TO_SUPPORT_TOOLTIP, hasSupportAclEntry } from '../lib/projectShareUtils';
import { rpcDeleteProject, rpcRemoveProjectAccess, useCopyProject } from '../lib/projectUtils';
import { wordsToList } from '../lib/text';
import { addRpcError } from '../lib/transientNotification';
import useResizeObserver from '../lib/useResizeObserver';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { meshMetadataSelector, meshUrlState } from '../recoil/meshState';
import { projectListState } from '../recoil/state';
import useAccountInfo, { useNoCredits } from '../recoil/useAccountInfo';
import { useProjectCopiesInProgress } from '../recoil/useProjectCopiesInProgress';
import { useReturningUser } from '../recoil/user';
import { analytics } from '../services/analytics';
import { useProjectShareDialog } from '../state/external/project/sharing';
import { useDataTableSelectedRows, useDataTableValue } from '../state/internal/component/dataTable';
import { pushConfirmation, useSetConfirmations } from '../state/internal/dialog/confirmations';

import ProjectListEmptyState from './ProjectListEmptyState';

const useStyles = makeStyles(
  () => createStyles({
    copyInProgressRow: {
      display: 'flex',
      alignItems: 'center',
      color: colors.lowEmphasisText,
    },
    copyInProgressName: {
      fontWeight: 600,
    },
  }),
  { name: 'ProjectListPage' },
);

const PROJECT_TABLE_NAME = 'project-list';

const ProjectListPage = () => {
  const checkRole = useCheckProjectRole();
  const dialogState = useProjectDialogState();
  const [projectShareDialog, setProjectShareDialog] = useProjectShareDialog();
  const accountInfo = useAccountInfo();
  const copyProject = useCopyProject();
  const setConfirmStack = useSetConfirmations();
  const userId = getLcUserId();
  const [selectedRowIds, setSelectedRowIds] = useDataTableSelectedRows(PROJECT_TABLE_NAME);
  const [searchText, setSearchText] = useState('');
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const { width: tableWrapperWidth } = useResizeObserver(tableWrapperRef);
  const { columns: tableColumns } = useDataTableValue(PROJECT_TABLE_NAME);
  const noCredits = useNoCredits();

  const sharingOptionDisabled = projectShareDialog.open || !accountInfo;

  // Keep track of the projects that are being copied at the moment so we can show a transient
  // copy row in the table.
  const [copiesInProgress, setCopiesInProgress] = useProjectCopiesInProgress();
  const refToTop = useRef<HTMLTableSectionElement>(null);

  const [projectList, setProjectList] = useRecoilState(projectListState);
  const poller = useRef<ProjectListPoller | null>(null);
  const navigate = useNavigate();
  const classes = useStyles();
  const tableClasses = useCommonTablePageStyles();
  const [returningUser, setReturningUser] = useReturningUser();
  const restartPoller = useCallback(() => poller.current?.startRefresh(), [poller]);
  const saveProject = useSaveProject(() => {
    restartPoller();
    dialogState.close();
  });

  useEffect(() => {
    // If this is the first time the user logs in, navigate them to the Get Started page instead.
    if (!returningUser) {
      setReturningUser(true);
      navigate(routes.getStarted, { replace: true });
    }
  }, [navigate, returningUser, setReturningUser]);

  useEffect(() => {
    // Clear the existing projectId from the analytics service when the component loads
    analytics.updateUserProperties({ projectId: null });
  }, []);

  const projects = useMemo(() => projectList?.project ?? [], [projectList]);

  // In theory this shouldn't be needed, because once the share dialog is opened, it uses the
  // useIsMeshReady hook which should fetch the meshMetadata data for the project. But if we don't
  // fetch this selector here, before the share dialog is opened, then once the share dialog is
  // opened, the page will visually reload. Getting the selector here, fixes that interim reload.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchMeshSelector = useRecoilCallback(({ snapshot }) => async (projectId: string) => {
    const meshUrl = await snapshot.getPromise(meshUrlState(projectId));
    const metaUrl = meshUrl?.mesh || meshUrl?.geometry;
    await snapshot.getPromise(meshMetadataSelector({ projectId, meshUrl: metaUrl }));
  });

  useEffect(() => {
    if (poller.current) {
      throw Error('double useeffect call');
    }
    poller.current = new ProjectListPoller((reply) => {
      setProjectList(reply);
    });
    poller.current.start();
    return () => {
      poller.current?.stop();
      poller.current = null;
    };
  }, [setProjectList]);

  // When the project list is updated, check if there's a project copy in progress and remove
  // its transient "copy in progress" row if the copied project already exists in the new list.
  useEffect(() => {
    if (copiesInProgress.length) {
      const existingIds = projectList?.project.map((proj) => proj.projectId) || [];

      copiesInProgress.forEach((copy) => {
        if (existingIds.includes(copy.id)) {
          setCopiesInProgress((list) => list.filter((item) => item.id !== copy.id));
        }
      });
    }
  }, [projectList, copiesInProgress, setCopiesInProgress]);

  // If there are projects which are currently being copied, we should refresh the poller constantly
  // until the copy op is finished. This is needed if a project is copied from the project row and
  // also if the user has initiated the copy from the project page (and redirected to this page).
  useEffect(() => {
    if (copiesInProgress.length) {
      const interval = setInterval(() => {
        if (poller.current) {
          poller.current.startRefresh();
        }
      }, 2000);

      return () => {
        clearInterval(interval);
      };
    }

    return () => { };
  }, [copiesInProgress]);

  // We should refresh the project list page immediately when the project list page is opened
  // because there are a lot of cases where a change might have been done but the project poller
  // hasn't refreshed the list yet. Some examples are if the user deletes a project from the project
  // page and is redirected to the project list or if the user renames/updates description and
  // returns to the list.
  useEffect(() => {
    if (poller.current) {
      poller.current.startRefresh();
    }
  }, []);

  const copyOptionDisabled = useCallback(
    (project: frontendpb.ProjectSummary) => copiesInProgress.some(
      (copy) => copy.name === project.name,
    ),
    [copiesInProgress],
  );

  const deleteProjects = useCallback(async (projectsToDelete: frontendpb.ProjectSummary[]) => {
    await Promise.allSettled(
      projectsToDelete.map((project) => rpcDeleteProject(project.projectId).then(
        // Return project ID so we can access it in the callback for Promise.allSettled
        () => project.projectId,
      ).catch(
        (err) => addRpcError(parseError(ERRORS.DeleteProject, [project.name]), err),
      )),
    ).then((results) => {
      const deletedRowIds = results.reduce((acc, result) => {
        if (result.status === 'fulfilled' && result.value) {
          acc.push(result.value);
        }
        return acc;
      }, [] as RowId[]);
      setSelectedRowIds((oldRows) => subtractSet(oldRows, deletedRowIds));
      restartPoller();
    });
  }, [restartPoller, setSelectedRowIds]);

  const deleteProject = useCallback(async (project: frontendpb.ProjectSummary) => {
    try {
      await rpcDeleteProject(project.projectId);
      restartPoller();
    } catch (err) {
      addRpcError(parseError(ERRORS.DeleteProject, [project.name]), err);
    }
  }, [restartPoller]);

  const leaveProject = useCallback(async (project: frontendpb.ProjectSummary) => {
    try {
      await rpcRemoveProjectAccess(project.projectId, userId, []);
      restartPoller();
    } catch (err) {
      addRpcError(parseError(ERRORS.RemoveAccessProject, [project.name]), err);
    }
  }, [restartPoller, userId]);

  const handleOpenProject = useCallback((projectId: string) => {
    analytics.track('Project Opened', { projectId });
    analytics.updateUserProperties({ projectId });
    navigate(projectLink(projectId));
  }, [navigate]);

  const handleShareProject = useCallback((project: frontendpb.ProjectSummary) => {
    setProjectShareDialog({
      open: true,
      projectId: project.projectId,
    });
  }, [setProjectShareDialog]);

  const handleEditProject = useCallback(
    (project: frontendpb.ProjectSummary) => {
      dialogState.editProject(project);
    },
    [dialogState],
  );

  const handleCopyProject = useCallback(async (project: frontendpb.ProjectSummary) => {
    refToTop.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    await copyProject(project.projectId, project.name);
  }, [copyProject]);

  const handleDeleteProject = useCallback((project: frontendpb.ProjectSummary) => {
    pushConfirmation(setConfirmStack, {
      continueLabel: 'Delete',
      destructive: true,
      onContinue: () => deleteProject(project),
      title: 'Delete Project',
      children: (
        <div>
          Are you sure you want to delete
          project <span className="fontWeight700">{project.name}</span>?
        </div>
      ),
    });
  }, [deleteProject, setConfirmStack]);

  const handleLeaveProjectItem = useCallback((project: frontendpb.ProjectSummary) => {
    pushConfirmation(setConfirmStack, {
      continueLabel: 'Leave Project',
      onContinue: () => leaveProject(project),
      title: 'Leave Project',
      children: (
        <div>
          Are you sure you want to leave <span className="fontWeight700">{project.name}</span>?
          You will no longer have access to this project unless the owner shares it with you again.
        </div>
      ),
    });
  }, [leaveProject, setConfirmStack]);

  const getMenuItems = (
    project: frontendpb.ProjectSummary,
    isUserOwner: boolean,
    isUserReader: boolean,
  ) => {
    const menuItems: CommonMenuItem[] = [
      {
        label: 'Open',
        onClick: () => handleOpenProject(project.projectId),
      },
    ];

    if (isUserOwner) {
      menuItems.push(
        {
          label: 'Share',
          onClick: () => handleShareProject(project),
          disabled: sharingOptionDisabled,
        },
        {
          label: 'Edit',
          onClick: () => handleEditProject(project),
        },
        {
          label: 'Make a Copy',
          onClick: () => handleCopyProject(project),
          disabled: copyOptionDisabled(project),
        },
        {
          label: 'Delete',
          onClick: () => handleDeleteProject(project),
          destructive: true,
        },
      );
    } else if (isUserReader) {
      menuItems.push(
        {
          label: 'Make a Copy',
          onClick: () => handleCopyProject(project),
          disabled: copyOptionDisabled(project),
        },
        {
          label: 'Leave Project',
          onClick: () => handleLeaveProjectItem(project),
        },
      );
    }

    return menuItems;
  };

  const handleDeleteSelected = useCallback(() => {
    // Get matching projects (and strip out potentially invalid IDs)
    const projectsToDelete = projects.filter(
      (project) => selectedRowIds.has(project.projectId),
    );

    projectsToDelete.sort((a, b) => a.name.localeCompare(b.name));

    pushConfirmation(setConfirmStack, {
      continueLabel: 'Delete',
      destructive: true,
      onContinue: () => deleteProjects(projectsToDelete),
      title: 'Delete Selected Projects',
      children: (
        <div>
          Are you sure you want to delete the following projects?
          <ul>
            {projectsToDelete.map((project) => (
              <li key={project.projectId}>{project.name}</li>
            ))}
          </ul>
        </div>
      ),
    });
  }, [deleteProjects, projects, selectedRowIds, setConfirmStack]);

  // Configure actions for the CollectionControlBar
  const controlBarButtons = useMemo<ActionGroup[]>(() => [{
    id: 'projects-action',
    kind: 'secondary',
    label: 'Delete Selected',
    disabled: !selectedRowIds.size,
    onClick: handleDeleteSelected,
  }], [handleDeleteSelected, selectedRowIds.size]);

  if (!projectList) {
    // Draw the suspense widget while the project list RPC is being proccessed.
    return suspenseWidget;
  }

  if (!projectList.project.length) {
    return (
      <ProjectListEmptyState />
    );
  }

  const columnWidths = {
    updated: tableColumns.update?.hidden ? 0 : 180,
    created: tableColumns.creation?.hidden ? 0 : 180,
    storage: tableColumns.storage?.hidden ? 0 : 140,
    computeCredits: tableColumns.compute_credits?.hidden ? 0 : 200,
    sharingStatus: tableColumns.sharing_status?.hidden ? 0 : 108,
    // rest of them are always visible
    checkbox: 48,
    actions: 48,
  };
  const occupiedWidth = Object.values(columnWidths).reduce((a, b) => a + b, 0);

  const columnConfigs: ColumnConfig[] = [
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      customWidth: `${(tableWrapperWidth - occupiedWidth) / 2}px`,
    },
    {
      id: 'description',
      label: 'Description',
      type: 'string',
      customWidth: `${(tableWrapperWidth - occupiedWidth) / 2}px`,
    },
    {
      id: 'update',
      label: 'Updated',
      type: 'number',
      format: 'datetime',
      customWidth: `${columnWidths.updated}px`,
    },
    {
      id: 'creation',
      label: 'Created',
      type: 'number',
      format: 'datetime',
      customWidth: `${columnWidths.created}px`,
    },
    {
      id: 'storage',
      label: 'Storage Used',
      type: 'number',
      format: 'bytes',
      customWidth: `${columnWidths.storage}px`,
    },
    {
      id: 'compute_credits',
      label: 'Total Credits',
      type: 'number',
      customWidth: `${columnWidths.computeCredits}px`,
    },
    {
      id: 'sharing_status',
      label: '\xa0', // use non breakable space so that columns have full height
      type: 'string',
      displayOptions: { textTransform: 'upper', emptyDisplay: '' },
      disableSorting: true,
      customWidth: `${columnWidths.sharingStatus}px`,
    },
  ];

  const banners: Banner[] = copiesInProgress.map((copy) => ({
    id: copy.id,
    content: (
      <div className={classes.copyInProgressRow}>
        Copying&nbsp;
        <span className={classes.copyInProgressName}>{copy.name}</span>
        <LoadingEllipsis />
      </div>
    ),
  }));

  const rowConfigs: RowConfig[] = projects.map((project) => {
    const isUserOwner = checkRole(project, 'admin');
    const isUserReader = checkRole(project, 'reader');

    let sharedStatusValue = '';
    const sharedIconsDisplay: SvgIconSpec[] = [];

    if (isUserReader) {
      sharedStatusValue = 'view only';
    } else {
      const isSharedWithSupport = hasSupportAclEntry(project.acl);
      const isSharedWithOthers = project.acl.length > (isSharedWithSupport ? 2 : 1);

      const textParts: string[] = [];
      if (isSharedWithSupport) {
        textParts.push('shared with support');
        sharedIconsDisplay.push({ name: 'headphones', title: SHARED_TO_SUPPORT_TOOLTIP });
      }
      if (isSharedWithOthers) {
        textParts.push('shared');
        sharedIconsDisplay.push({ name: 'people', maxWidth: 15 });
      }
      sharedStatusValue = wordsToList(textParts);
    }

    return {
      id: project.projectId,
      route: projectLink(project.projectId),
      values: {
        compute_credits: fromBigInt(project.computeMilliCredits) / 1000,
        name: project.name,
        description: project.description,
        update: Math.round(project.updateTime * 1000),
        creation: Math.round(project.creationTime * 1000),
        storage: fromBigInt(project.storageBytes),
        sharing_status: sharedStatusValue,
      },
      cellDisplay: {
        sharing_status: isUserReader ? [
          { type: 'tag', backgroundColor: colors.neutral750, foregroundColor: colors.neutral50 },
          { type: 'tooltip', content: 'Make a copy to edit' },
        ] : [
          {
            type: 'circleIcons',
            icons: sharedIconsDisplay,
          },
        ],
      },
      menuItems: getMenuItems(project, isUserOwner, isUserReader),
      // Disable row selection for shared projects, since this user can't delete it
      canSelect: !isUserReader,
    };
  });

  return (
    <MainPageLayout
      loading={!projectList}
      primaryAction={(
        <ActionButton
          aria-label="add"
          dataLocator="create-project-button"
          disabled={noCredits}
          kind="primary"
          name="new-project"
          onClick={dialogState.newProject}
          title={noCredits ? 'Credits are required to perform this action.' : ''}>
          New Project
        </ActionButton>
      )}
      title="Projects">
      <div className={tableClasses.tableContainer}>
        <div>
          <CollectionControlBar
            buttons={controlBarButtons}
            onSearch={setSearchText}
          />
        </div>
        <div ref={tableWrapperRef}>
          <Table
            asBlock
            banners={banners}
            columnConfigs={columnConfigs}
            defaultSort={{ columnId: 'creation', descending: true }}
            enableRowSelection
            name={PROJECT_TABLE_NAME}
            pagination={{ availablePageSizes: [10, 25, 100], persist: true }}
            rowConfigs={rowConfigs}
            searchText={searchText}
          />
        </div>
        {/* New project dialog */}
        <ProjectDialog
          {...dialogState.props}
          onSubmit={saveProject}
        />
      </div>
    </MainPageLayout>
  );
};

export default ProjectListPage;
