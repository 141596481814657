// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useMemo } from 'react';

import { AuthState } from '../lib/AuthInfoCallback';
import { getAuthInfoV2 } from '../lib/AuthInfoCallbackV2';
import { initializeIntercom } from '../lib/intercom';
import { getLcUserIdFromToken } from '../lib/jwt';
import { useAuthInfoV2Value } from '../state/external/auth/authInfo';
import { useIntercomUserHash } from '../state/external/user/intercom';

export const Intercom = () => {
  const authInfoV2 = useAuthInfoV2Value();

  // If we get the id from the jwt (instead of useCurrentUser) it will be safe to use the hook from
  // the main index.tsx (where the user might not be available and he useCurrentUser would fail).
  const userIdFromJwt = useMemo(() => {
    const authenticated = authInfoV2.authState === AuthState.AUTHENTICATED;
    if (authenticated) {
      const jwt = getAuthInfoV2().jwt!;
      return getLcUserIdFromToken(jwt);
    }
    return '';
  }, [authInfoV2.authState]);

  const intercomUserHash = useIntercomUserHash(userIdFromJwt);

  useEffect(() => {
    if (intercomUserHash) {
      initializeIntercom(intercomUserHash);
    }
  }, [intercomUserHash]);

  return null;
};
