// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useRef, useState } from 'react';

import cx from 'classnames';

import { callAfterAnimation } from '../../../lib/animationUtils';
import { Level, getIconName } from '../../../lib/notificationUtils';
import { SvgIcon } from '../../Icon/SvgIcon';
import { XIcon } from '../../svg/XIcon';
import { Link, PanelLink } from '../PanelLinks';

import './Banner.scss';

export type BannerLevel = Exclude<Level, 'success'>

export interface BannerProps {
  message: string,
  level: BannerLevel,
  onDismiss?: () => void,
  link?: Link,
  position?: 'relative' | 'absolute',
}

const ANIMATION_DURATION = 200;

export const Banner = (props: BannerProps) => {
  const { message, level, onDismiss, link, position = 'relative' } = props;
  const iconName = getIconName(level);
  // state to animate closing the banner
  const [closing, setClosing] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const dismissAction = () => {
    setClosing(true);
    callAfterAnimation(() => {
      onDismiss?.();

      if (ref.current) {
        setClosing(false);
      }
    }, ANIMATION_DURATION);
  };

  return (
    <div
      className={cx('banner', level, {
        closing,
      })}
      ref={ref}
      style={{ position }}>
      <div className="bannerMessage">
        {iconName && (
          <div className="icon">
            <SvgIcon maxHeight={12.5} maxWidth={12.5} name={iconName} />
          </div>
        )}
        <div className="messageText">
          {message}
        </div>
        {link && (
          <div className="linkText">
            <PanelLink link={link} />
          </div>
        )}
      </div>
      {level !== 'fatal' && onDismiss && (
        <div className="control">
          <button data-testid="bannerDismissButton" onClick={dismissAction} type="button">
            <XIcon maxHeight={8} maxWidth={8} />
          </button>
        </div>
      )}
    </div>
  );
};
