// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/output/output.proto (package luminary.proto.output, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { AdFloatType, AdVector3, Vector3Component } from "../base/base_pb.js";
import { QuantityType } from "../quantity/quantity_pb.js";

/**
 * @generated from enum luminary.proto.output.TimeAnalysisType
 */
export const TimeAnalysisType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.output.TimeAnalysisType",
  [
    {no: 0, name: "INVALID_TIME_ANALYSIS_TYPE"},
    {no: 1, name: "TIME_AVERAGE"},
    {no: 2, name: "TIME_SERIES"},
    {no: 3, name: "TIME_MAX"},
    {no: 4, name: "TIME_MIN"},
    {no: 5, name: "TIME_MOVING_AVERAGE"},
    {no: 6, name: "TIME_AVERAGE_CAUCHY"},
    {no: 7, name: "TIME_SERIES_CAUCHY"},
    {no: 8, name: "TIME_MOVING_AVERAGE_CAUCHY"},
    {no: 9, name: "PERCENT_MAX_DEV"},
    {no: 10, name: "PERCENT_MAX_DEV_SERIES"},
    {no: 11, name: "PERCENT_MAX_DEV_AVG"},
    {no: 12, name: "PERCENT_MAX_DEV_AVG_SERIES"},
    {no: 13, name: "TIME_TRAILING_AVG_SERIES"},
  ],
);

/**
 * @generated from enum luminary.proto.output.SpaceAveragingType
 */
export const SpaceAveragingType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.output.SpaceAveragingType",
  [
    {no: 0, name: "INVALID_SPACE_AVERAGING_TYPE"},
    {no: 1, name: "SPACE_MASS_FLOW_AVERAGING"},
    {no: 2, name: "SPACE_AREA_AVERAGING"},
    {no: 3, name: "SPACE_NO_AVERAGING"},
  ],
);

/**
 * @generated from enum luminary.proto.output.VolumeReductionType
 */
export const VolumeReductionType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.output.VolumeReductionType",
  [
    {no: 0, name: "INVALID_VOLUME_REDUCTION_TYPE"},
    {no: 1, name: "VOLUME_MINIMUM"},
    {no: 2, name: "VOLUME_MAXIMUM"},
    {no: 3, name: "VOLUME_AVERAGING"},
  ],
);

/**
 * @generated from enum luminary.proto.output.ForceDirectionType
 */
export const ForceDirectionType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.output.ForceDirectionType",
  [
    {no: 0, name: "INVALID_FORCE_DIRECTION_TYPE"},
    {no: 1, name: "FORCE_DIRECTION_CUSTOM"},
    {no: 2, name: "FORCE_DIRECTION_BODY_ORIENTATION_AND_FLOW_DIR"},
  ],
);

/**
 * @generated from enum luminary.proto.output.ResidualType
 */
export const ResidualType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.output.ResidualType",
  [
    {no: 0, name: "INVALID_RESIDUAL_TYPE"},
    {no: 1, name: "RESIDUAL_ABSOLUTE"},
    {no: 2, name: "RESIDUAL_RELATIVE"},
    {no: 3, name: "RESIDUAL_MAX"},
    {no: 4, name: "RESIDUAL_MIN"},
  ],
);

/**
 * @generated from enum luminary.proto.output.StoppingConditionOp
 */
export const StoppingConditionOp = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.output.StoppingConditionOp",
  [
    {no: 0, name: "INVALID_STOP_COND_OP"},
    {no: 1, name: "STOP_COND_OP_ALL"},
    {no: 2, name: "STOP_COND_OP_ANY"},
    {no: 3, name: "STOP_COND_OP_FORCE"},
  ],
);

/**
 * @generated from message luminary.proto.output.ForceProperties
 */
export const ForceProperties = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.output.ForceProperties",
  () => [
    { no: 1, name: "force_dir_type", kind: "enum", T: proto3.getEnumType(ForceDirectionType) },
    { no: 2, name: "force_direction", kind: "message", T: AdVector3 },
    { no: 3, name: "moment_center", kind: "message", T: AdVector3 },
    { no: 4, name: "porous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.output.ResidualProperties
 */
export const ResidualProperties = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.output.ResidualProperties",
  () => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(ResidualType) },
    { no: 2, name: "physics_index", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.output.SurfaceAverageProperties
 */
export const SurfaceAverageProperties = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.output.SurfaceAverageProperties",
  () => [
    { no: 1, name: "averaging_type", kind: "enum", T: proto3.getEnumType(SpaceAveragingType) },
  ],
);

/**
 * @generated from message luminary.proto.output.VolumeReductionProperties
 */
export const VolumeReductionProperties = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.output.VolumeReductionProperties",
  () => [
    { no: 1, name: "reduction_type", kind: "enum", T: proto3.getEnumType(VolumeReductionType) },
  ],
);

/**
 * @generated from message luminary.proto.output.IterationRange
 */
export const IterationRange = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.output.IterationRange",
  () => [
    { no: 1, name: "begin", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "end", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.output.BasicType
 */
export const BasicType = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.output.BasicType",
  [],
);

/**
 * @generated from message luminary.proto.output.PointProbeType
 */
export const PointProbeType = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.output.PointProbeType",
  [],
);

/**
 * @generated from message luminary.proto.output.DerivedType
 */
export const DerivedType = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.output.DerivedType",
  () => [
    { no: 1, name: "expression", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dependencies", kind: "message", T: Output, repeated: true },
  ],
);

/**
 * A quantity that the user wants reported in the outputs of a simulation.
 * Next available tag is 15.
 *
 * @generated from message luminary.proto.output.Output
 */
export const Output = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.output.Output",
  () => [
    { no: 1, name: "quantity", kind: "enum", T: proto3.getEnumType(QuantityType) },
    { no: 19, name: "vector_component", kind: "enum", T: proto3.getEnumType(Vector3Component) },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "in_surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "short_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "time_analysis", kind: "enum", T: proto3.getEnumType(TimeAnalysisType) },
    { no: 8, name: "force_properties", kind: "message", T: ForceProperties, oneof: "output_properties" },
    { no: 9, name: "surface_average_properties", kind: "message", T: SurfaceAverageProperties, oneof: "output_properties" },
    { no: 10, name: "residual_properties", kind: "message", T: ResidualProperties, oneof: "output_properties" },
    { no: 13, name: "basic_properties", kind: "message", T: BasicType, oneof: "output_properties" },
    { no: 14, name: "probe_properties", kind: "message", T: PointProbeType, oneof: "output_properties" },
    { no: 20, name: "derived_properties", kind: "message", T: DerivedType, oneof: "output_properties" },
    { no: 21, name: "volume_reduction_properties", kind: "message", T: VolumeReductionProperties, oneof: "output_properties" },
    { no: 11, name: "range", kind: "message", T: IterationRange },
    { no: 15, name: "avg_iters", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "analysis_iters", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 17, name: "frame_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "inner_iters", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Result is the result of a query.
 * If multiple outputs were requested in an Output query,
 * each sub query will have a value in the order of the
 * sub query.
 *
 * @generated from message luminary.proto.output.Result
 */
export const Result = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.output.Result",
  () => [
    { no: 5, name: "values", kind: "message", T: AdFloatType, repeated: true },
  ],
);

/**
 * Stopping condition for the solver
 *
 * @generated from message luminary.proto.output.StoppingCondition
 */
export const StoppingCondition = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.output.StoppingCondition",
  () => [
    { no: 1, name: "output", kind: "message", T: Output },
    { no: 2, name: "threshold", kind: "message", T: AdFloatType },
    { no: 3, name: "op", kind: "enum", T: proto3.getEnumType(StoppingConditionOp) },
  ],
);

/**
 * @generated from message luminary.proto.output.StoppingConditionStatus
 */
export const StoppingConditionStatus = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.output.StoppingConditionStatus",
  () => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "cond_success", kind: "scalar", T: 8 /* ScalarType.BOOL */, repeated: true },
    { no: 3, name: "value", kind: "message", T: Result, repeated: true },
    { no: 4, name: "force_stop", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

