// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { atom, useAtom, useSetAtom } from 'jotai';

const isItarEnvDialogOpened = atom(false);

export function useIsItarEnvDialogOpened() {
  return useAtom(isItarEnvDialogOpened);
}

export function useOpenItarEnvDialog() {
  const setIsItarEnvDialogOpened = useSetAtom(isItarEnvDialogOpened);

  return useCallback(() => setIsItarEnvDialogOpened(true), [setIsItarEnvDialogOpened]);
}
