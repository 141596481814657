// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.

import { useEffect, useRef } from 'react';

import { useAllowEventListener } from '../recoil/eventListenerState/useAllowEventListener';

import { AnyKeyboardEvent, listen } from './event';

// hook which takes in a function and its dependencies,
// and adds an event listener.
// It uses a ref to keep track of the most recent closure of the function
// and avoid unnecessary mounting/unmounting of the listener.

// We should use this hook for any keyboard shortcut event handlers (see
// handleGroups in Toolbar.tsx for example usage).

export type ListenerEvent = AnyKeyboardEvent | MouseEvent;

export const isMouseEvent = (
  event: ListenerEvent,
): event is MouseEvent => (event as AnyKeyboardEvent).key === undefined;

export function useEventListener(
  eventName: string,
  callbackFn: (event: ListenerEvent) => void,
  target: EventTarget | null = window.document,
) {
  const callbackRef = useRef(callbackFn);
  const allowEventListener = useAllowEventListener();
  // update ref every time dependencies of callbackFn change
  useEffect(() => {
    callbackRef.current = (event: ListenerEvent) => {
      // if the event is a mouseEvent, don't need to disable it when inputs are active.
      if (allowEventListener || isMouseEvent(event)) {
        callbackFn(event);
      }
    };
  }, [callbackFn, allowEventListener]);

  // callLatestFn is what the event listener calls so it always works with the most recent
  // closure of callbackFn.
  const callLatestFn = (event: Event) => callbackRef.current(event as ListenerEvent);

  useEffect(() => {
    if (target) {
      const listener = listen(target, eventName, callLatestFn);
      return () => listener.remove();
    }
    return () => {};
  }, [eventName, target]);
}
