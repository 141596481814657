// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { RadiusInput } from '../PrimitiveInputs/RadiusInput';
import { StartEndInput } from '../PrimitiveInputs/StartEndInput';

type Cylinder = {
  start: { x: number, y: number, z: number },
  end: { x: number, y: number, z: number },
  radius: number,
}

export interface CylinderInputProps {
  value: Cylinder,
  onCommit: (value: Cylinder) => void,
  disabled?: boolean,
}

export const CylinderInput = (props: CylinderInputProps) => {
  const { value, onCommit, disabled = false } = props;

  const handleStartCommit = (start: { x: number, y: number, z: number }) => {
    onCommit({ ...value, start });
  };

  const handleEndCommit = (end: { x: number, y: number, z: number }) => {
    onCommit({ ...value, end });
  };

  const handleRadiusChange = (radius: number) => {
    onCommit({ ...value, radius });
  };

  return (
    <>
      <StartEndInput
        disabled={disabled}
        endProps={{
          help: "Location of the cylinder's first base's center.",
          value: { ...value.end },
          onCommit: handleEndCommit,
        }}
        startProps={{
          help: "Location of the cylinder's second base's center.",
          value: { ...value.start },
          onCommit: handleStartCommit,
        }}
      />
      <RadiusInput
        inputProps={{
          value: value.radius,
          onCommit: handleRadiusChange,
          disabled,
        }}
        type="single"
      />
    </>
  );
};
