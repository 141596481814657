// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';

import { JobDataCategory } from './JobDataCategory';

const useDataCellStyles = makeStyles(
  () => createStyles({
    root: {
      // some of these props might be overwritten from the JobVerticalDataTable styles
      borderBottom: 'none',
      fontSize: '13px',
      lineHeight: '16px',
      padding: '6px 0',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  }),
  { name: 'JobVerticalDataCell' },
);

interface JobVerticalDataCellProps {
  data: ReactElement;
  // Whether this is the column containing the name. The name column is
  // formatted so the text is aligned to the left.
  nameCol: boolean;
  // If the cell should have extra padding.
  extraPadding: boolean;
  // For rows where we don't show the name/label, we may want the value column to have a colspan=2
  // so that the value cell contains the full row.
  colspan?: number;
}

const JobVerticalDataCell = (props: JobVerticalDataCellProps) => {
  const classes = useDataCellStyles();
  return (
    <TableCell
      classes={classes}
      colSpan={props.colspan}
      style={{
        paddingLeft: props.extraPadding && props.nameCol ? '15px' : '0',
        paddingRight: props.extraPadding && !props.nameCol ? '15px' : '0',
        textAlign: props.nameCol ? 'left' : 'right',
        justifyContent: props.nameCol ? 'flex-start' : 'flex-end',
      }}>
      {props.data}
    </TableCell>
  );
};

// Returns the set of rows for a category when the table is displayed vertically.
function createCategoryRows(category: JobDataCategory, extraPadding: boolean): ReactElement[][] {
  let rows: ReactElement[][] = [];
  const cells: ReactElement[] = [];
  if (category.showName ?? true) {
    cells.push(
      <JobVerticalDataCell
        data={(
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginTop: category.marginTop || 0,
              marginBottom: category.marginBottom || 0,
            }}>
            <Tooltip title={category.nameLines[0]}>
              {category.subcategories.length > 0 ?
                <strong>{category.nameLines[0]}</strong> :
                <span>{category.nameLines[0]}</span>}
            </Tooltip>
          </div>
        )}
        extraPadding={extraPadding}
        key={`name-${category.nameLines.join('-')}`}
        nameCol
      />,
    );
  }
  category.values.forEach((value) => {
    cells.push(
      <JobVerticalDataCell
        colspan={category.colspan}
        data={value}
        extraPadding={extraPadding}
        key={`value-${category.nameLines.join('-')}`}
        nameCol={false}
      />,
    );
  });
  rows.push(cells);
  category.subcategories.forEach((subcategory: JobDataCategory) => {
    rows = rows.concat(createCategoryRows(subcategory, extraPadding));
  });
  return rows;
}

const useDataTableStyles = makeStyles(
  () => createStyles({
    root: {
      '&.inRunStatusPanel': {
        '& tr td': {
          fontSize: '12px',
          padding: '4px 0 0 ',
          color: colors.lowEmphasisText,
        },

        // The Status row should stand out from the rest
        '& tr:first-child td': {
          fontSize: '13px',
          paddingBottom: '4px', // total of 8px between the status and the next row
          color: colors.highEmphasisText,
        },
      },
    },
  }),
  { name: 'JobVerticalDataTable' },
);

interface JobVerticalDataTableProps {
  categories: JobDataCategory[];
  // The maximum number of rows. The rows may have multiple columns if the
  // number of categories exceeds the maximum number of rows.
  maxRows?: number;
  // If the cells should have extra padding.
  extraPadding?: boolean;
  name: string;
  inRunStatusPanel?: boolean;
}

// A data table that is oriented vertically.
const JobVerticalDataTable = (props: JobVerticalDataTableProps) => {
  const classes = useDataTableStyles();
  let categoryRows: ReactElement[][] = [];
  props.categories.forEach((category: JobDataCategory) => {
    categoryRows = categoryRows.concat(createCategoryRows(category, props.extraPadding || false));
  });
  // Organize the category data into rows.
  const maxRows = props.maxRows || categoryRows.length;
  const rows: ReactElement[][] = [];
  for (let i = 0; i < categoryRows.length; i += 1) {
    if (i < maxRows) {
      rows[i] = categoryRows[i];
    } else {
      const index = i % maxRows;
      rows[index] = rows[index].concat(categoryRows[i]);
    }
  }
  const tableRows = rows.map((row, index) => {
    const key = index < props.categories.length ?
      props.categories[index].nameLines.join('-') :
      `row${index}`;
    return (
      <TableRow
        key={key}>
        {row}
      </TableRow>
    );
  });

  return (
    <Table
      className={cx(classes.root, { inRunStatusPanel: props.inRunStatusPanel })}
      data-name={`${props.name}JobVertTable`}
      sx={{
        tableLayout: 'fixed',
        marginTop: '8px',
      }}>
      <TableBody>
        {tableRows}
      </TableBody>
    </Table>
  );
};

export default JobVerticalDataTable;
