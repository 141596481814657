// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { LcvModule } from '../types';

import { LcvObject } from './base/LcvObject';

export class LcvReferenceFrame extends LcvObject {
  constructor(lcv: LcvModule, sessionHandle: number) {
    super(lcv, lcv.newReferenceFrame(sessionHandle, 0).reference_frame, sessionHandle);
  }
}
