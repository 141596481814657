// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import * as ReactDOM from 'react-dom';

import { isUnmodifiedEscapeKey, listen } from '../../../lib/event';
import { useSetModalsActive } from '../../../recoil/eventListenerState/useModalsActive';

import './Modal.scss';

export interface ModalProps {
  onClose?: () => void;
  onClickBackDrop?: () => void;
  children: ReactNode;
}

export const Modal = (props: ModalProps) => {
  const { onClose, onClickBackDrop } = props;

  const rootRef = useRef<HTMLDivElement>(null);
  // Keep track of where the click was initiated so that we don't accidentaly close the
  // dialog if the user mouse downed inside of the modal but released the button outside.
  const [mouseDownTarget, setMouseDownTarget] = useState<EventTarget | null>(null);

  const [incrementModals, decrementModals] = useSetModalsActive();

  // raise the counter for open modals every time one gets opened,
  // decrement it on close.
  useEffect(() => {
    incrementModals();
    return () => decrementModals();
  }, [incrementModals, decrementModals]);

  useEffect(() => {
    const handler = listen(document, 'keydown', (event: Event) => {
      // Only the top modal in the stack should respond to ESC presses
      if (
        !rootRef.current?.nextElementSibling && // dialog is top of the stack
        isUnmodifiedEscapeKey(event as KeyboardEvent) &&
        onClose
      ) {
        event.stopPropagation();
        onClose();
      }
    });
    return () => handler.remove();
  });

  // Handlers
  const handleMouseDown = (event: React.MouseEvent) => {
    setMouseDownTarget(event.target);
  };

  const handleMouseUp = (event: React.MouseEvent) => {
    const targetNode = event.target as HTMLElement;

    if (rootRef.current === targetNode && rootRef.current === mouseDownTarget) {
      // Backdrop clicked
      onClickBackDrop?.();
    }

    setMouseDownTarget(null);
  };

  const modalsNode = document.getElementById('modals');
  if (!modalsNode) {
    return null;
  }

  const content = (
    <div
      className="modalContainer"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      ref={rootRef}
      role="presentation">
      {props.children}
    </div>
  );

  return ReactDOM.createPortal(content, modalsNode);
};
