// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { MessageCollector } from '../../../../lib/MessageCollector';
import { VIS_FILTER_CREATION_ERROR, VIS_FILTER_EMPTY_WARNING } from '../../../../lib/visUtils';
import Divider from '../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { SectionMessage } from '../../../notification/SectionMessage';
import PropertiesSection from '../../PropertiesSection';

export interface CommonFilterMessagesProps {
  calculating?: boolean;
  creationError?: boolean;
  emptyFilter?: boolean;
  disabled?: boolean;
  warnings?: string[];
}

export const CommonFilterMessages = (props: CommonFilterMessagesProps) => {
  const {
    calculating = false,
    creationError = false,
    disabled = false,
    emptyFilter = false,
    warnings = [],
  } = props;

  const propsClasses = useCommonTreePropsStyles();

  const sectionMessages = useMemo(() => {
    const collector = new MessageCollector();
    if (creationError) {
      collector.addError(VIS_FILTER_CREATION_ERROR);
    }
    if (emptyFilter) {
      collector.addWarning(VIS_FILTER_EMPTY_WARNING);
    }
    if (!disabled && warnings.length) {
      collector.addWarning(warnings[0]);
    }
    if (calculating) {
      collector.addInfo('Creating visualization filter.');
    }
    return collector.messages;
  }, [calculating, disabled, emptyFilter, creationError, warnings]);

  return (
    <>
      {!!sectionMessages.length && (
        <>
          <PropertiesSection>
            <div className={propsClasses.sectionMessages}>
              {sectionMessages.map((message) => (
                <SectionMessage key={message.message} {...message} />
              ))}
            </div>
          </PropertiesSection>
          <Divider />
        </>
      )}
    </>
  );
};
