// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import backgroundImage from '../assets/images/luminary-get-started.jpg';
import { ActionButton } from '../components/Button/ActionButton';
import { ActionLink } from '../components/Button/ActionLink';
import { createStyles, makeStyles } from '../components/Theme';
import ProjectDialog from '../components/dialog/Project';
import { MainPageLayout } from '../components/layout/page/Main';
import { useProjectDialogState } from '../components/project/controller/dialog/useProjectDialogState';
import { useSaveProject } from '../components/project/controller/useSaveProject';
import {
  LEARNING_ACCOUNT_USAGE_URL,
  LEARNING_CAD_MODELS_URL,
  LEARNING_PIPER_CHEROKEE_TUTORIAL_URL,
  LEARNING_PROJECTS_GUIDE_URL,
  LEARNING_SHARING_PROJECTS_URL,
  SUPPORT_URL,
} from '../lib/constants';
import { colors } from '../lib/designSystem';
import { INTERCOM_LAUNCHER_SELECTOR, isIntercomReady } from '../lib/intercom';
import { useNoCredits } from '../recoil/useAccountInfo';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      maxWidth: '900px',
      minWidth: '600px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '36px',
    },
    image: {
      height: '178px',
      borderRadius: '8px',
      background: `url(${backgroundImage}) center center no-repeat`,
      backgroundSize: 'cover',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
    },
    title: {
      position: 'absolute',
      bottom: '36px',
      fontSize: '32px',
      lineHeight: 1.25,
      fontWeight: 600,
    },
    intro: {
      display: 'flex',
      padding: '24px 48px',
      flexDirection: 'column',
      gap: '24px',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderRadius: '8px',
      backgroundColor: colors.surfaceMedium1,
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '36px 48px',
    },
    action: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    actionTitle: {
      fontSize: '16px',
      fontWeight: 600,
    },
    actionDescription: {
      lineHeight: '20px',
      color: colors.lowEmphasisText,
    },
  }),
  { name: 'GetStartedPage' },
);

const GetStartedPage = () => {
  // Hooks
  const dialogState = useProjectDialogState();
  const saveProject = useSaveProject(() => dialogState.close());
  const classes = useStyles();
  const noCredits = useNoCredits();

  const actions = [
    {
      title: 'Sample Projects',
      description: 'Learn how to use Luminary with pre-configured sample projects',
      button: {
        label: 'Create Project',
        onClick: () => dialogState.newProject(),
        disabled: noCredits,
        title: noCredits ? 'Credits are required to perform this action.' : '',
      },
    },
    {
      title: 'Projects',
      description: 'Set up, run, and analyze simulations in projects.',
      link: {
        label: 'Learn More',
        href: LEARNING_PROJECTS_GUIDE_URL,
      },
    },
    {
      title: 'Sharing',
      description: 'Share projects with your coworkers and get aligned in real time.',
      link: {
        label: 'Learn More',
        href: LEARNING_SHARING_PROJECTS_URL,
      },
    },
    {
      title: 'CAD and Mesh Files',
      description: 'Upload CAD to generate a mesh or upload existing mesh files.',
      link: {
        label: 'Learn More',
        href: LEARNING_CAD_MODELS_URL,
      },
    },
    {
      title: 'Usage and Billing',
      description: 'View and manage credit usage under My Account or the Administration menu.',
      link: {
        label: 'Learn More',
        href: LEARNING_ACCOUNT_USAGE_URL,
      },
    },
    {
      title: 'Support',
      description: `
        Need help? Our expert support team is standing by. Don't hesitate to reach out.
      `,
      link: {
        label: 'Contact',
        href: !isIntercomReady() ? SUPPORT_URL : undefined,
      },
      className: isIntercomReady() ? INTERCOM_LAUNCHER_SELECTOR.replace('.', '') : '',
    },
  ];

  return (
    <MainPageLayout
      noHeading
      title="Get Started">
      <div className={classes.root}>
        <div className={classes.image}>
          <div className={classes.title}>Welcome to Luminary!</div>
        </div>
        <div className={classes.intro}>
          <div>
            First time in the app? Get started with this step-by-step Piper Cherokee tutorial.
          </div>
          <a href={LEARNING_PIPER_CHEROKEE_TUTORIAL_URL} rel="noopener noreferrer" target="_blank">
            {/* We are preventing the button to be focused by pressing the `tab` key, because
             the parent anchor is already tabbable */}
            <ActionButton endIcon={{ name: 'open' }} kind="primary" tabIndex={-1}>
              View Tutorial
            </ActionButton>
          </a>
        </div>
        <div className={classes.grid}>
          {actions.map((action) => (
            <div
              className={classes.action}
              key={action.title}>
              <div className={classes.actionTitle}>
                {action.title}
              </div>
              <div className={classes.actionDescription}>
                {action.description}
              </div>
              {/* Need the extra div to avoid the button from expanding */}
              <div className={action.className}>
                {action.button && (
                  <ActionButton
                    disabled={action.button.disabled}
                    kind="secondary"
                    onClick={action.button.onClick}
                    title={action.button.title}>
                    {action.button.label}
                  </ActionButton>
                )}
                {action.link && (
                  <ActionLink
                    allowPropagation={action.link.label === 'Contact' && isIntercomReady()}
                    bold
                    externalIcon={!!action.link.href}
                    href={action.link.href}>
                    {action.link.label}
                  </ActionLink>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <ProjectDialog
        {...dialogState.props}
        onSubmit={saveProject}
      />
    </MainPageLayout>
  );
};

export default GetStartedPage;
