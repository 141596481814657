// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 21;
const baseHeight = 24;
export const CubeOutlineInvertedCircleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="cubeOutlineInvertedCircle"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M10.028,0.273C10.32,0.104 10.68,0.104 10.972,0.273L20.42,5.727
           C20.712,5.896 20.892,6.208 20.892,6.545L20.892,17.455
           C20.892,17.792 20.712,18.104 20.42,18.273L10.972,23.727
           C10.68,23.896 10.32,23.896 10.028,23.727L0.58,18.273
           C0.288,18.104 0.108,17.792 0.108,17.455L0.108,6.545C0.108,6.208 0.288,5.896 0.58,5.727
           L10.028,0.273ZM9.556,2.726L1.996,7.09L1.996,15.819L6.457,13.244
           C6.646,13.861 6.974,14.418 7.402,14.879L2.941,17.455L10.5,21.819L18.06,17.455
           L13.598,14.879C14.026,14.418 14.353,13.861 14.543,13.244L19.004,15.819L19.004,7.09
           L11.444,2.726L11.444,7.877C11.141,7.807 10.825,7.771 10.5,7.771
           C10.175,7.771 9.859,7.807 9.556,7.877L9.556,2.726Z"
        style={{ fill: getColor(0) }}
      />
      <circle cx="10.5" cy="12" r="2.361" style={{ fill: getColor(0) }} />
    </svg>
  );
});
