// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 109;
const baseHeight = 120;
export const ShareIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="share"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M90.645,84.819
           C86.066,84.819 81.97,86.627 78.837,89.458
           L35.886,64.458
           C36.187,63.072 36.428,61.687 36.428,60.241
           C36.428,58.795 36.187,57.41 35.886,56.024
           L78.355,31.265
           C81.608,34.277 85.886,36.145 90.645,36.145
           C100.645,36.145 108.717,28.072 108.717,18.072
           C108.717,8.072 100.645,0 90.645,0
           C80.645,0 72.572,8.072 72.572,18.072
           C72.572,19.518 72.813,20.904 73.114,22.289
           L30.645,47.048
           C27.392,44.036 23.114,42.169 18.355,42.169
           C8.355,42.169 0.283,50.241 0.283,60.241
           C0.283,70.241 8.355,78.313 18.355,78.313
           C23.114,78.313 27.392,76.446 30.645,73.434
           L73.536,98.494
           C73.235,99.759 73.054,101.084 73.054,102.41
           C73.054,112.108 80.946,120 90.645,120
           C100.343,120 108.235,112.108 108.235,102.41
           C108.235,92.711 100.343,84.819 90.645,84.819Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
