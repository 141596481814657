// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/support/support.proto (package luminary.proto.support, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message luminary.proto.support.CreateSupportTicketRequest
 */
export const CreateSupportTicketRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.support.CreateSupportTicketRequest",
  () => [
    { no: 1, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.support.CreateSupportTicketReply
 */
export const CreateSupportTicketReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.support.CreateSupportTicketReply",
  () => [
    { no: 2, name: "ticket_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.support.UpdateSupportUserRequest
 */
export const UpdateSupportUserRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.support.UpdateSupportUserRequest",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "support_user", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.support.ListSupportUsersRequest
 */
export const ListSupportUsersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.support.ListSupportUsersRequest",
  [],
);

/**
 * @generated from message luminary.proto.support.ListSupportUsersReply
 */
export const ListSupportUsersReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.support.ListSupportUsersReply",
  () => [
    { no: 1, name: "users", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * No explict request parameters; we get the user info from the request
 * headers/metadata.
 *
 * @generated from message luminary.proto.support.GenerateIntercomHMACRequest
 */
export const GenerateIntercomHMACRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.support.GenerateIntercomHMACRequest",
  [],
);

/**
 * @generated from message luminary.proto.support.GenerateIntercomHMACReply
 */
export const GenerateIntercomHMACReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.support.GenerateIntercomHMACReply",
  () => [
    { no: 1, name: "hmac", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

