// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 48;
const baseHeight = 39;
export const FolderIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="folder"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0,35.979
           C0,37.628 1.349,38.977 2.998,38.977
           L44.973,38.977
           C46.622,38.977 47.971,37.628 47.971,35.979
           L47.971,14.991
           L0,14.991
           L0,35.979Z
           M44.973,5.996
           L22.247,5.996
           L17.12,0.899
           C16.58,0.33 15.831,0 14.991,0
           L2.998,0
           C1.349,0 0,1.349 0,2.998
           L0,11.993
           L47.971,11.993
           L47.971,8.995
           C47.971,7.346 46.622,5.996 44.973,5.996Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
