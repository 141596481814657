// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import { IconName } from '../../lib/componentTypes/svgIcon';
import { colors } from '../../lib/designSystem';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    title: {
      color: colors.lowEmphasisText,
      fontSize: '14px',
      marginTop: '18px',
    },
    subtitle: {
      color: colors.lowEmphasisText,
      fontSize: '13px',
      margin: '5px',
      marginBottom: '20px',
      textAlign: 'center',
    },
    emptyState: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '45px 15px 0 15px',
    },
    buttonsBox: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      flexWrap: 'wrap',
    },
  }),
  { name: 'PropertiesEmptyState' },
);

export interface PropertiesEmptyStateProps {
  title: string;
  subtitle: string;
  iconName: IconName;
  children: ReactNode;
}

/**
 * Displays an empty state for the properties panel.
 */
const PropertiesEmptyState = (props: PropertiesEmptyStateProps) => {
  const classes = useStyles();

  return (
    <div className={classes.emptyState}>
      <SvgIcon color={colors.neutral800} maxWidth={54} name={props.iconName} />
      <div className={classes.title}>{props.title}</div>
      <div className={classes.subtitle}>{props.subtitle}</div>
      <div className={classes.buttonsBox}>
        {props.children}
      </div>
    </div>
  );
};

export default PropertiesEmptyState;
