// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ParamName, paramDesc } from '../../../SimulationParamDescriptor';
import { adVec3ToPv, equalsZero, newScalarAdVector, pvToAdVec3 } from '../../../lib/adUtils';
import assert from '../../../lib/assert';
import { getMonitorPlaneImposter, monitorPlaneToParam } from '../../../lib/imposterFilteringUtils';
import { NodeTableType } from '../../../lib/nodeTableUtil';
import { useNodePanel } from '../../../lib/useNodePanel';
import { mapDomainsToIds } from '../../../lib/volumeUtils';
import { AdVector3 } from '../../../proto/base/base_pb';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { BoxClipParam, BoxWidgetState, ImplicitPlaneWidgetState, PlaneParam, Vector3, WidgetState, WidgetType } from '../../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useStaticVolumes } from '../../../recoil/volumes';
import Form from '../../Form';
import { CollapsiblePanel } from '../../Panel/CollapsiblePanel';
import ParamRow, { ParamRowProps } from '../../ParamRow';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import Divider from '../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import Tooltip from '../../Tooltip';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { EditButtons } from '../../controls/EditButtons';
import { LuminaryToggleSwitch } from '../../controls/LuminaryToggleSwitch';
import { UnitVectorButtons } from '../../controls/UnitVectorButtons';
import { useSimulationConfig } from '../../hooks/useSimulationConfig';
import { AttributesDisplay } from '../AttributesDisplay';
import NodeTable from '../NodeTable';
import PropertiesSection from '../PropertiesSection';

type EditMode = 'plane' | 'constraint';
type ParamEditStateType = {
  editMode: EditMode | null;
  plane: PlaneParam | null;
  constraint: BoxClipParam | null;
  editSource: 'Paraview' | 'Form' | null;
}

const emptyParamEditState = () => ({
  editMode: null,
  plane: null,
  constraint: null,
  editSource: null,
});

const isEditingPlane = (editState: ParamEditStateType) => editState.editMode === 'plane';
const isEditingConstraint = (editState: ParamEditStateType) => editState.editMode === 'constraint';

// A panel displaying all the settings for the selected monitor plane node.
export const MonitorPlanePropPanel = () => {
  const { readOnly, projectId } = useProjectContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);

  // The current fields being edited. This determines which widget to use in Paraview.
  const [paramEditState, setParamEditState] = useState<ParamEditStateType>({
    editMode: null,
    plane: null,
    constraint: null,
    editSource: null,
  });

  const setPlaneNormal = (x: number, y: number, z: number) => {
    if (paramEditState.plane) {
      const newPlane = {
        ...paramEditState.plane,
        normal: { x, y, z },
      };
      setParamEditState((prev) => ({
        ...prev,
        plane: newPlane,
        editSource: 'Form',
      }));
    }
  };

  const commonClasses = useCommonTreePropsStyles();
  const {
    addNode,
    activeEdit,
    viewState,
    getDataVisibilityBounds,
    paraviewRenderer,
    paraviewMeshMetadata,
  } = useParaviewContext();

  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected monitor plane row');

  const { simParam, saveParamAsync } = useSimulationConfig();

  const plane = simParam.monitorPlane.find(({ monitorPlaneId }) => monitorPlaneId === node.id);

  const defnPanel = useNodePanel(node.id, 'definition');
  const constraintPanel = useNodePanel(`${node.id}-constraint`, 'constraint');
  const boxConstraintPanel = useNodePanel(`${node.id}-box-constraint`, 'box-constraint');
  const volumeConstraintPanel = useNodePanel(`${node.id}-volume-constraint`, 'volume-constraint');

  const imposter = useMemo(() => {
    if (viewState?.root && plane) {
      return getMonitorPlaneImposter(plane, viewState.root, addNode);
    }

    return null;
  }, [viewState, plane, addNode]);

  const updateImposter = (monitorPlane: simulationpb.MonitorPlane) => {
    if (imposter) {
      const visParam = monitorPlaneToParam(monitorPlane);
      activeEdit(imposter.id, visParam);
    }
  };

  const commitEdits = async () => {
    if (paramEditState) {
      await saveParamAsync((newParam) => {
        const newPlane = newParam.monitorPlane.find(
          ({ monitorPlaneId }) => monitorPlaneId === node.id,
        );
        if (newPlane) {
          if (paramEditState.editMode === 'plane') {
            const { origin, normal } = paramEditState.plane!;
            newPlane.monitorPlanePoint = pvToAdVec3(origin);
            newPlane.monitorPlaneNormal = pvToAdVec3(normal);
          } else if (paramEditState.editMode === 'constraint') {
            const { position, length, rotation } = paramEditState.constraint!;
            newPlane.monitorPlaneClipCenter = pvToAdVec3(position);
            newPlane.monitorPlaneClipSize = pvToAdVec3(length);
            newPlane.monitorPlaneClipRotation = pvToAdVec3(rotation);
          }
          updateImposter(newPlane);
        }
      });
      setParamEditState(emptyParamEditState());
    }
  };

  const cancelEdits = () => {
    setParamEditState(emptyParamEditState());
  };

  const changeValue = (
    editMode: EditMode,
    field: 'origin' | 'normal' | 'position' | 'length' | 'rotation',
    value: Vector3,
  ) => {
    setParamEditState((prev) => ({
      ...prev,
      editMode,
      [editMode]: {
        ...prev[editMode],
        [field]: value,
      },
      editSource: 'Form',
    }));
  };

  const isConstrained = !!plane?.monitorPlaneBoxClip;
  const isVolumeConstrained = !!plane?.monitorPlaneVolumeClip;

  const setIsConstrained = async (constrained: boolean) => {
    await saveParamAsync((newParam) => {
      const newPlane = newParam.monitorPlane.find(
        ({ monitorPlaneId }) => monitorPlaneId === node.id,
      );
      if (newPlane) {
        newPlane.monitorPlaneBoxClip = constrained;
        if (constrained) {
          const clipSize = newPlane.monitorPlaneClipSize ?? newScalarAdVector();
          // Check to see if this is a default value, so we can help the user with
          // some better initial params.
          if (equalsZero(clipSize)) {
            if (paraviewMeshMetadata) {
              const bounds = getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata);
              newPlane.monitorPlaneClipSize = newScalarAdVector(
                bounds[1] - bounds[0],
                bounds[3] - bounds[2],
                bounds[5] - bounds[4],
              );
            }
          }
        }
      }
    });
  };

  const setIsVolumeConstrained = async (constrained: boolean) => {
    await saveParamAsync((newParam) => {
      const newPlane = newParam.monitorPlane.find(
        (item) => item.monitorPlaneId === plane?.monitorPlaneId,
      );
      if (newPlane) {
        newPlane.monitorPlaneVolumeClip = constrained;
      }
    });
  };

  const allProps: ParamRowProps[] = [];

  allProps.push(
    {
      nestLevel: 0,
      inputOptions: { showUnits: true },
      param: paramDesc[ParamName.MonitorPlanePoint],
      readOnly: readOnly || defnPanel.collapsed,
      setValue: (origin: AdVector3) => {
        changeValue('plane', 'origin', adVec3ToPv(origin));
      },
      value: isEditingPlane(paramEditState) ?
        pvToAdVec3(paramEditState.plane!.origin) :
        plane?.monitorPlanePoint,
    },
    {
      nestLevel: 0,
      inputOptions: { showUnits: true },
      param: paramDesc[ParamName.MonitorPlaneNormal],
      readOnly: readOnly || defnPanel.collapsed,
      setValue: (normal: AdVector3) => {
        changeValue('plane', 'normal', adVec3ToPv(normal));
      },
      value: isEditingPlane(paramEditState) ?
        pvToAdVec3(paramEditState.plane!.normal) :
        plane?.monitorPlaneNormal,
    },
  );

  const constraintProps: ParamRowProps[] = [
    {
      nestLevel: 0,
      inputOptions: { showUnits: true },
      param: paramDesc[ParamName.MonitorPlaneClipCenter],
      readOnly: readOnly || defnPanel.collapsed,
      setValue: (position: AdVector3) => {
        changeValue('constraint', 'position', adVec3ToPv(position));
      },
      value: isEditingConstraint(paramEditState) ?
        pvToAdVec3(paramEditState.constraint!.position) :
        plane?.monitorPlaneClipCenter,
    },
    {
      nestLevel: 0,
      inputOptions: { showUnits: true },
      param: paramDesc[ParamName.MonitorPlaneClipSize],
      readOnly: readOnly || defnPanel.collapsed,
      setValue: (length: AdVector3) => {
        changeValue('constraint', 'length', adVec3ToPv(length));
      },
      value: isEditingConstraint(paramEditState) ?
        pvToAdVec3(paramEditState.constraint!.length) :
        plane?.monitorPlaneClipSize,
    },
    {
      nestLevel: 0,
      inputOptions: { showUnits: true },
      param: paramDesc[ParamName.MonitorPlaneClipRotation],
      readOnly: readOnly || defnPanel.collapsed,
      setValue: (rotation: AdVector3) => {
        changeValue('constraint', 'rotation', adVec3ToPv(rotation));
      },
      value: isEditingConstraint(paramEditState) ?
        pvToAdVec3(paramEditState.constraint!.rotation) :
        plane?.monitorPlaneClipRotation,
    },
  ];

  const staticVolumes = useStaticVolumes(projectId);

  const selectedVolumeIds = useMemo(
    () => mapDomainsToIds(
      staticVolumes,
      plane?.monitorPlaneVolumes.map((identifier) => identifier.id) ?? [],
    ),
    [plane, staticVolumes],
  );

  const disablePlane = readOnly || !isEditingPlane(paramEditState);
  const disableConstraint = readOnly || !isEditingConstraint(paramEditState);
  const constraintDisabledReason = viewState ?
    '' : 'Connection to visualizer required to modify constraints';

  const onPlaneWidgetUpdate = useCallback((newParam: WidgetState) => {
    if (newParam.typ === WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION) {
      const { plane: planeParam } = newParam;
      setParamEditState({
        editMode: 'plane',
        plane: planeParam,
        constraint: null,
        editSource: 'Paraview',
      });
    }
  }, []);

  const onBoxWidgetUpdate = useCallback((newParam: WidgetState) => {
    if (newParam.typ === WidgetType.BOX_WIDGET_REPRESENTATION) {
      const { box: boxParam } = newParam;
      setParamEditState({
        editMode: 'constraint',
        plane: null,
        constraint: boxParam,
        editSource: 'Paraview',
      });
    }
  }, []);

  /** Activate the plane widget or box widget when actively editing one of the two. */
  useEffect(() => {
    if (
      lcvisEnabled ||
      !paraviewRenderer ||
      paramEditState.editMode === null ||
      !paraviewMeshMetadata?.meshMetadata
    ) {
      return;
    }
    const startOrUpdateWidget = async () => {
      if (paramEditState.editSource !== 'Paraview' && isEditingPlane(paramEditState)) {
        const bounds = getDataVisibilityBounds(paraviewMeshMetadata?.meshMetadata);
        const widgetState: ImplicitPlaneWidgetState = {
          typ: WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
          plane: paramEditState.plane!,
          bounds,
        };
        paraviewRenderer.activateWidget(
          bounds,
          widgetState,
        );
        await paraviewRenderer.registerOnUpdateWidgetHandler(onPlaneWidgetUpdate);
      } else if (isEditingConstraint(paramEditState) && paramEditState.editSource !== 'Paraview') {
        const bounds = getDataVisibilityBounds(paraviewMeshMetadata?.meshMetadata);
        const widgetState: BoxWidgetState = {
          typ: WidgetType.BOX_WIDGET_REPRESENTATION,
          box: paramEditState.constraint!,
        };
        paraviewRenderer.activateWidget(
          bounds,
          widgetState,
        );
        await paraviewRenderer.registerOnUpdateWidgetHandler(onBoxWidgetUpdate);
      }
    };
    startOrUpdateWidget().catch(() => {
      // do nothing
    });
  }, [
    lcvisEnabled,
    paraviewRenderer,
    paramEditState.editMode,
    paraviewMeshMetadata?.meshMetadata,
    paramEditState,
    getDataVisibilityBounds,
    onPlaneWidgetUpdate,
    onBoxWidgetUpdate,
  ]);

  useEffect(() => {
    if (lcvisEnabled || !paraviewRenderer) {
      return;
    }
    return () => {
      paraviewRenderer.deleteWidget();
    };
  }, [lcvisEnabled, paraviewRenderer]);

  const onCancel = () => {
    cancelEdits();
    paraviewRenderer?.deleteWidget();
  };

  // Make sure we cancel any edits if the component is dismounted.
  useEffect(() => () => {
    onCancel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = async () => {
    await commitEdits();
    paraviewRenderer?.deleteWidget();
  };
  const onStartEdit = (mode: EditMode) => {
    if (mode === 'plane') {
      return () => setParamEditState({
        editMode: 'plane',
        plane: {
          typ: 'Plane',
          origin: adVec3ToPv(plane?.monitorPlanePoint!),
          normal: adVec3ToPv(plane?.monitorPlaneNormal!),
        },
        constraint: null,
        editSource: 'Form',
      });
    }
    if (mode === 'constraint') {
      return () => setParamEditState({
        editMode: 'constraint',
        plane: null,
        constraint: {
          typ: 'BoxClip',
          position: adVec3ToPv(plane?.monitorPlaneClipCenter!),
          length: adVec3ToPv(plane?.monitorPlaneClipSize!),
          rotation: adVec3ToPv(plane?.monitorPlaneClipRotation!),
        },
        editSource: 'Form',
      });
    }
    return () => { };
  };

  return (
    <div className={commonClasses.properties}>
      <AttributesDisplay attributes={[{ label: 'Type', value: 'Monitor Plane' }]} />
      <Divider />
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={defnPanel.collapsed}
          headerRight={(
            <EditButtons
              disableEdit={readOnly || isEditingConstraint(paramEditState) || lcvisEnabled}
              disableSave={false}
              editMode={isEditingPlane(paramEditState)}
              onCancel={onCancel}
              onSave={onSave}
              onStartEdit={onStartEdit('plane')}
            />
          )}
          heading="Definition"
          onToggle={defnPanel.toggle}>
          {allProps.map((rowProps) => (
            <ParamRow
              key={`${node.id}-${rowProps.param.name}`}
              {...rowProps}
              readOnly={disablePlane}
            />
          ))}
          <Form.LabeledInput label="">
            {readOnly ? null : (
              <Form.Group>
                <UnitVectorButtons
                  disabled={!isEditingPlane(paramEditState)}
                  onClick={setPlaneNormal}
                />
              </Form.Group>
            )}
          </Form.LabeledInput>
        </CollapsiblePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={constraintPanel.collapsed}
          headerRight={(
            <EditButtons
              disableEdit={readOnly || isEditingPlane(paramEditState) || lcvisEnabled}
              disableSave={false}
              editMode={isEditingConstraint(paramEditState)}
              onCancel={onCancel}
              onSave={onSave}
              onStartEdit={onStartEdit('constraint')}
            />
          )}
          heading="Constraints"
          onToggle={constraintPanel.toggle}>
          <CollapsiblePanel
            collapsed={boxConstraintPanel.collapsed || !isConstrained}
            headerRight={(
              <Tooltip title={constraintDisabledReason}>
                <LuminaryToggleSwitch
                  disabled={disableConstraint || !!constraintDisabledReason}
                  onChange={setIsConstrained}
                  small
                  value={isConstrained}
                />
              </Tooltip>
            )}
            heading="Box Clip"
            help="Constrain the monitor plane to a box."
            onToggle={boxConstraintPanel.toggle}>
            {isConstrained && constraintProps.map((rowProps) => (
              <ParamRow
                key={`${node.id}-${rowProps.param.name}`}
                {...rowProps}
                readOnly={disableConstraint}
              />
            ))}
            {isConstrained && <div style={{ height: '7px' }} />}
          </CollapsiblePanel>
          <CollapsiblePanel
            collapsed={volumeConstraintPanel.collapsed || !isVolumeConstrained}
            headerRight={(
              <LuminaryToggleSwitch
                disabled={disableConstraint}
                onChange={setIsVolumeConstrained}
                small
                value={isVolumeConstrained}
              />
            )}
            heading="Volumes"
            help="Constrain the monitor plane to selected volumes."
            onToggle={volumeConstraintPanel.toggle}>
            {isVolumeConstrained &&
              (
                <NodeTable
                  editable={!disableConstraint}
                  nodeIds={selectedVolumeIds}
                  tableId="selected-volumes"
                  tableType={NodeTableType.MONITOR_PLANE_VOLUMES}
                  title=""
                />
              )}
          </CollapsiblePanel>
        </CollapsiblePanel>
      </PropertiesSection>
    </div>
  );
};
