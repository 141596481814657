// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 18;
const baseHeight = 18;
export const ArrowDownRightIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="arrowDownRight"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M16.5,4.5
           C15.675,4.5 15,5.175 15,6
           L15,12.885
           L2.565,0.435
           C2.295,0.165 1.92,0 1.5,0
           C0.675,0 0,0.675 0,1.5
           C0,1.92 0.165,2.295 0.435,2.565
           L12.885,15
           L6,15
           C5.175,15 4.5,15.675 4.5,16.5
           C4.5,17.325 5.175,18 6,18
           L16.5,18
           C17.325,18 18,17.325 18,16.5
           L18,6
           C18,5.175 17.325,4.5 16.5,4.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
