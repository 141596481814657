// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/condition/condition.proto (package luminary.proto.condition, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Represents a condition of a parameter that must be fullfilled in order for
 * the parameter to be available
 *
 * @generated from message luminary.proto.condition.Condition
 */
export const Condition = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.condition.Condition",
  () => [
    { no: 1, name: "choice", kind: "message", T: Choice, oneof: "typ" },
    { no: 2, name: "anyof", kind: "message", T: AnyOf, oneof: "typ" },
    { no: 3, name: "allof", kind: "message", T: AllOf, oneof: "typ" },
    { no: 5, name: "false", kind: "message", T: False, oneof: "typ" },
    { no: 6, name: "not", kind: "message", T: Not, oneof: "typ" },
    { no: 7, name: "boolean", kind: "message", T: TrueFalse, oneof: "typ" },
    { no: 8, name: "tag", kind: "message", T: Tag, oneof: "typ" },
  ],
);

/**
 * A choice condition that must be enabled in order for a parameter to be
 * available
 *
 * @generated from message luminary.proto.condition.Choice
 */
export const Choice = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.condition.Choice",
  () => [
    { no: 1, name: "tag", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "param_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Group of conditions in which any of them must be fulfilled in order for a
 * parameter to be available
 *
 * @generated from message luminary.proto.condition.AnyOf
 */
export const AnyOf = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.condition.AnyOf",
  () => [
    { no: 1, name: "cond", kind: "message", T: Condition, repeated: true },
  ],
);

/**
 * Group of conditions in which all of them must be fulfilled in order for a
 * parameter to be available
 *
 * @generated from message luminary.proto.condition.AllOf
 */
export const AllOf = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.condition.AllOf",
  () => [
    { no: 1, name: "cond", kind: "message", T: Condition, repeated: true },
  ],
);

/**
 * The parameter is always hidden.
 *
 * @generated from message luminary.proto.condition.False
 */
export const False = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.condition.False",
  [],
);

/**
 * Logical complement for a condition
 *
 * @generated from message luminary.proto.condition.Not
 */
export const Not = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.condition.Not",
  () => [
    { no: 1, name: "cond", kind: "message", T: Condition },
  ],
);

/**
 * @generated from message luminary.proto.condition.TrueFalse
 */
export const TrueFalse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.condition.TrueFalse",
  () => [
    { no: 1, name: "param_name_tag", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.condition.Tag
 */
export const Tag = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.condition.Tag",
  () => [
    { no: 1, name: "tag_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

