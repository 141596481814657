// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 30;
const baseHeight = 21;
export const ContourIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="contour"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M7.5,21
           C7.906,21 8.258,20.852 8.555,20.555
           C8.852,20.258 9,19.906 9,19.5
           L9,15
           C9,14.594 8.852,14.242 8.555,13.945
           C8.258,13.648 7.906,13.5 7.5,13.5
           L6.422,13.5
           C6.547,11.938 7.055,10.531 7.945,9.281
           C8.836,8.031 9.969,7.078 11.344,6.422
           C11.438,6.734 11.617,6.992 11.883,7.195
           C12.148,7.398 12.438,7.5 12.75,7.5
           L17.25,7.5
           C17.563,7.5 17.852,7.398 18.117,7.195
           C18.383,6.992 18.563,6.734 18.656,6.422
           C20.031,7.078 21.164,8.031 22.055,9.281
           C22.945,10.531 23.453,11.938 23.578,13.5
           L22.5,13.5
           C22.094,13.5 21.742,13.648 21.445,13.945
           C21.149,14.242 21,14.594 21,15
           L21,19.5
           C21,19.906 21.149,20.258 21.445,20.555
           C21.742,20.852 22.094,21 22.5,21
           L27,21
           C27.406,21 27.758,20.852 28.055,20.555
           C28.352,20.258 28.5,19.906 28.5,19.5
           L28.5,15
           C28.5,14.594 28.352,14.242 28.055,13.945
           C27.758,13.648 27.406,13.5 27,13.5
           L25.828,13.5
           C25.703,11.688 25.164,10.024 24.211,8.508
           C23.258,6.992 22.016,5.781 20.484,4.875
           L24.234,4.875
           C24.453,5.438 24.813,5.891 25.313,6.234
           C25.813,6.578 26.375,6.75 27,6.75
           C27.844,6.75 28.555,6.461 29.133,5.883
           C29.711,5.305 30,4.594 30,3.75
           C30,2.906 29.711,2.195 29.133,1.617
           C28.555,1.039 27.844,0.75 27,0.75
           C26.375,0.75 25.813,0.922 25.313,1.266
           C24.813,1.609 24.453,2.063 24.234,2.625
           L18.75,2.625
           L18.75,1.5
           C18.75,1.094 18.602,0.742 18.305,0.445
           C18.008,0.148 17.656,0 17.25,0
           L12.75,0
           C12.344,0 11.992,0.148 11.695,0.445
           C11.398,0.742 11.25,1.094 11.25,1.5
           L11.25,2.625
           L5.766,2.625
           C5.547,2.063 5.188,1.609 4.688,1.266
           C4.188,0.922 3.625,0.75 3,0.75
           C2.156,0.75 1.445,1.039 0.867,1.617
           C0.289,2.195 0,2.906 0,3.75
           C0,4.594 0.289,5.305 0.867,5.883
           C1.445,6.461 2.156,6.75 3,6.75
           C3.625,6.75 4.188,6.578 4.688,6.234
           C5.188,5.891 5.547,5.438 5.766,4.875
           L9.516,4.875
           C7.984,5.781 6.742,6.992 5.789,8.508
           C4.836,10.024 4.297,11.688 4.172,13.5
           L3,13.5
           C2.594,13.5 2.242,13.648 1.945,13.945
           C1.648,14.242 1.5,14.594 1.5,15
           L1.5,19.5
           C1.5,19.906 1.648,20.258 1.945,20.555
           C2.242,20.852 2.594,21 3,21
           L7.5,21Z
           M16.5,5.25
           L13.5,5.25
           L13.5,2.25
           L16.5,2.25
           L16.5,5.25Z
           M27.047,4.5
           C26.859,4.5 26.695,4.445 26.555,4.336
           C26.414,4.227 26.328,4.094 26.297,3.938
           L26.25,3.844
           L26.25,3.656
           L26.297,3.563
           C26.328,3.406 26.414,3.273 26.555,3.164
           C26.695,3.055 26.859,3 27.047,3
           C27.234,3 27.399,3.07 27.539,3.211
           C27.68,3.352 27.75,3.531 27.75,3.75
           C27.75,3.969 27.68,4.148 27.539,4.289
           C27.399,4.43 27.234,4.5 27.047,4.5Z
           M2.953,4.5
           C2.766,4.5 2.602,4.43 2.461,4.289
           C2.32,4.148 2.25,3.969 2.25,3.75
           C2.25,3.531 2.32,3.352 2.461,3.211
           C2.602,3.07 2.766,3 2.953,3
           C3.141,3 3.305,3.055 3.445,3.164
           C3.586,3.273 3.672,3.406 3.703,3.563
           L3.75,3.656
           L3.75,3.844
           L3.703,3.938
           C3.672,4.094 3.586,4.227 3.445,4.336
           C3.305,4.445 3.141,4.5 2.953,4.5Z
           M6.75,18.75
           L3.75,18.75
           L3.75,15.75
           L6.75,15.75
           L6.75,18.75Z
           M26.25,18.75
           L23.25,18.75
           L23.25,15.75
           L26.25,15.75
           L26.25,18.75Z
           M7.5,21
           C7.906,21 8.258,20.852 8.555,20.555
           C8.852,20.258 9,19.906 9,19.5
           L9,15
           C9,14.594 8.852,14.242 8.555,13.945
           C8.258,13.648 7.906,13.5 7.5,13.5
           L6.422,13.5
           C6.547,11.938 7.055,10.531 7.945,9.281
           C8.836,8.031 9.969,7.078 11.344,6.422
           C11.438,6.734 11.617,6.992 11.883,7.195
           C12.148,7.398 12.438,7.5 12.75,7.5
           L17.25,7.5
           C17.563,7.5 17.852,7.398 18.117,7.195
           C18.383,6.992 18.563,6.734 18.656,6.422
           C20.031,7.078 21.164,8.031 22.055,9.281
           C22.945,10.531 23.453,11.938 23.578,13.5
           L22.5,13.5
           C22.094,13.5 21.742,13.648 21.445,13.945
           C21.149,14.242 21,14.594 21,15
           L21,19.5
           C21,19.906 21.149,20.258 21.445,20.555
           C21.742,20.852 22.094,21 22.5,21
           L27,21
           C27.406,21 27.758,20.852 28.055,20.555
           C28.352,20.258 28.5,19.906 28.5,19.5
           L28.5,15
           C28.5,14.594 28.352,14.242 28.055,13.945
           C27.758,13.648 27.406,13.5 27,13.5
           L25.828,13.5
           C25.703,11.688 25.164,10.024 24.211,8.508
           C23.258,6.992 22.016,5.781 20.484,4.875
           L24.234,4.875
           C24.453,5.438 24.813,5.891 25.313,6.234
           C25.813,6.578 26.375,6.75 27,6.75
           C27.844,6.75 28.555,6.461 29.133,5.883
           C29.711,5.305 30,4.594 30,3.75
           C30,2.906 29.711,2.195 29.133,1.617
           C28.555,1.039 27.844,0.75 27,0.75
           C26.375,0.75 25.813,0.922 25.313,1.266
           C24.813,1.609 24.453,2.063 24.234,2.625
           L18.75,2.625
           L18.75,1.5
           C18.75,1.094 18.602,0.742 18.305,0.445
           C18.008,0.148 17.656,0 17.25,0
           L12.75,0
           C12.344,0 11.992,0.148 11.695,0.445
           C11.398,0.742 11.25,1.094 11.25,1.5
           L11.25,2.625
           L5.766,2.625
           C5.547,2.063 5.188,1.609 4.688,1.266
           C4.188,0.922 3.625,0.75 3,0.75
           C2.156,0.75 1.445,1.039 0.867,1.617
           C0.289,2.195 0,2.906 0,3.75
           C0,4.594 0.289,5.305 0.867,5.883
           C1.445,6.461 2.156,6.75 3,6.75
           C3.625,6.75 4.188,6.578 4.688,6.234
           C5.188,5.891 5.547,5.438 5.766,4.875
           L9.516,4.875
           C7.984,5.781 6.742,6.992 5.789,8.508
           C4.836,10.024 4.297,11.688 4.172,13.5
           L3,13.5
           C2.594,13.5 2.242,13.648 1.945,13.945
           C1.648,14.242 1.5,14.594 1.5,15
           L1.5,19.5
           C1.5,19.906 1.648,20.258 1.945,20.555
           C2.242,20.852 2.594,21 3,21
           L7.5,21Z
           M16.5,5.25
           L13.5,5.25
           L13.5,2.25
           L16.5,2.25
           L16.5,5.25Z
           M27.047,4.5
           C26.859,4.5 26.695,4.445 26.555,4.336
           C26.414,4.227 26.328,4.094 26.297,3.938
           L26.25,3.844
           L26.25,3.656
           L26.297,3.563
           C26.328,3.406 26.414,3.273 26.555,3.164
           C26.695,3.055 26.859,3 27.047,3
           C27.234,3 27.399,3.07 27.539,3.211
           C27.68,3.352 27.75,3.531 27.75,3.75
           C27.75,3.969 27.68,4.148 27.539,4.289
           C27.399,4.43 27.234,4.5 27.047,4.5Z
           M2.953,4.5
           C2.766,4.5 2.602,4.43 2.461,4.289
           C2.32,4.148 2.25,3.969 2.25,3.75
           C2.25,3.531 2.32,3.352 2.461,3.211
           C2.602,3.07 2.766,3 2.953,3
           C3.141,3 3.305,3.055 3.445,3.164
           C3.586,3.273 3.672,3.406 3.703,3.563
           L3.75,3.656
           L3.75,3.844
           L3.703,3.938
           C3.672,4.094 3.586,4.227 3.445,4.336
           C3.305,4.445 3.141,4.5 2.953,4.5Z
           M6.75,18.75
           L3.75,18.75
           L3.75,15.75
           L6.75,15.75
           L6.75,18.75Z
           M26.25,18.75
           L23.25,18.75
           L23.25,15.75
           L26.25,15.75
           L26.25,18.75Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
