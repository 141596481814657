// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useState } from 'react';

import { boldEscaped } from '../../lib/html';
import { getMaterialName } from '../../lib/materialUtils';
import { getPhysicsName } from '../../lib/physicsUtils';
import { NodeType, SimulationTreeNode } from '../../lib/simulationTree/node';
import { getMaterialAssignmentTooltip, getPhysicsAssignmentTooltip } from '../../lib/volumeUtils';
import { usePhysicsSet } from '../../model/hooks/usePhysicsSet';
import { useGeometryList } from '../../recoil/geometry/geometryListState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';
import { ContextMenuSection } from '../treePanel/TreeRow';

import { useTagsInteractiveGeometry } from './useInteractiveGeometry';
import { useVolumeNode } from './useVolumeNode';

export const useVolumeNodeRowMenu = (id: string, node?: SimulationTreeNode | null) => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);

  // == Hooks
  const {
    staticVolume,

    assignedMaterialId,
    assignedMaterial,
    materialsMenuItems,
    unassignMaterial,

    assignedPhysicsId,
    assignedPhysics,
    physicsMenuItems,
    unassignPhysics,
  } = useVolumeNode(id);
  const {
    getVolumePhysicsAssignmentDisabledReason,
  } = usePhysicsSet(projectId, workflowId, jobId, readOnly);
  const {
    isCreateTagDisabled,
    addVolumeToExistingTag,
    removeItemFromTag,
  } = useTagsInteractiveGeometry();
  const isGeometryView = useIsGeometryView();
  const geometryTags = useGeometryTags(projectId);
  const geometryList = useGeometryList(projectId);

  const geoUsesTags = geometryList?.geometries[0]?.usesTags ?? false;

  // With tag creation, we request the users to provide a name for the tag via a dialog.
  const [isCreateTagDialogOpen, setIsCreateTagDialogOpen] = useState(false);
  const [isCreateTagFacesDialogOpen, setIsCreateTagFacesDialogOpen] = useState(false);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];
    if (isGeometryView) {
      if (!isCreateTagDisabled && geoUsesTags) {
        const groupingSection: ContextMenuSection = {
          section: 'grouping',
          menuItems: [
            {
              label: 'Create Tag',
              onClick: () => {
                setIsCreateTagDialogOpen(true);
              },
            },
            {
              label: 'Create Tag on Faces',
              onClick: () => {
                setIsCreateTagFacesDialogOpen(true);
              },
            },
            {
              label: 'Add to Tag',
              disabled: geometryTags.tags.length === 0,
              disabledReason: geometryTags.tags.length === 0 ?
                'There are no existing Tags yet.' :
                '',
              onClick: () => { },
              ...(geometryTags.tags.length === 0 ? {} : {
                items: geometryTags.tags.map((tag) => ({
                  label: tag.name,
                  onClick: async () => {
                    await addVolumeToExistingTag(tag.id, id);
                  },
                })),
              }),
            },
          ],
        };

        const isTagChildren = node?.type === NodeType.TAGS_BODY;

        if (isTagChildren) {
          const tagId = node?.parent?.id || '';
          const tagChildren = [
            ...(geometryTags.surfacesFromTagEntityGroupId(tagId) || []),
            ...geometryTags.domainsFromTag(tagId),
          ];

          const canRemoveTagChild = tagChildren.length > 1;

          groupingSection.menuItems.push({
            label: 'Remove from Tag',
            onClick: async () => {
              await removeItemFromTag(tagId, { volumeId: id });
            },
            disabled: !canRemoveTagChild,
            disabledReason: !canRemoveTagChild ?
              'Tags must contain at least one item' :
              undefined,
          });
        }

        sections.push(groupingSection);
      }

      return sections;
    }
    const materialLocked = !!(assignedPhysicsId && assignedMaterialId);
    const materialTooltip = getMaterialAssignmentTooltip(
      readOnly,
      !!assignedPhysicsId,
      !!assignedMaterialId,
    );
    const physicsTooltip = getPhysicsAssignmentTooltip(
      readOnly,
      !!assignedPhysics,
      !!assignedMaterial,
    );

    if (assignedMaterial) {
      sections.push({
        section: 'relations',
        menuItems: [
          {
            label: `Unassign ${boldEscaped(getMaterialName(assignedMaterial, simParam))}`,
            help: materialLocked ? '' : materialTooltip,
            onClick: unassignMaterial,
            disabled: materialLocked,
            disabledReason: materialLocked ? materialTooltip : '',
          },
        ],
      });
      if (assignedPhysics && assignedPhysicsId && staticVolume) {
        const disabledReason = getVolumePhysicsAssignmentDisabledReason(
          assignedPhysicsId,
          staticVolume.domain,
        );
        sections.push({
          section: 'relations',
          menuItems: [
            {
              label: `Unassign ${boldEscaped(getPhysicsName(assignedPhysics, simParam))}`,
              help: physicsTooltip,
              onClick: unassignPhysics,
              disabled: !!disabledReason,
              disabledReason: disabledReason || physicsTooltip,
            },
          ],
        });
      } else if (physicsMenuItems.length) {
        sections.push({
          section: 'relations',
          menuItems: [
            {
              label: 'Assign Physics',
              onClick: () => { },
              items: physicsMenuItems,
            },
          ],
        });
      }
    } else if (materialsMenuItems.length) {
      sections.push({
        section: 'relations',
        menuItems: [
          {
            label: 'Assign Material',
            onClick: () => { },
            items: materialsMenuItems,
          },
        ],
      });
    }

    return sections;
  }, [
    assignedMaterial,
    assignedMaterialId,
    assignedPhysics,
    assignedPhysicsId,
    getVolumePhysicsAssignmentDisabledReason,
    materialsMenuItems,
    physicsMenuItems,
    readOnly,
    simParam,
    staticVolume,
    unassignMaterial,
    unassignPhysics,
    isGeometryView,
    isCreateTagDisabled,
    geoUsesTags,
    addVolumeToExistingTag,
    geometryTags,
    id,
    removeItemFromTag,
    node,
  ]);

  return {
    getExtraContextMenuItems,
    isCreateTagDialogOpen,
    isCreateTagFacesDialogOpen,
    setIsCreateTagDialogOpen,
    setIsCreateTagFacesDialogOpen,
  };
};
