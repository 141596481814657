// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { useSelectionContext } from '../../../components/context/SelectionManager';
import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';
import { NodeTableType } from '../../../lib/nodeTableUtil';
import { useSetEditState } from '../../paraviewState';
import { useLcVisReadyValue } from '../lcvisReadyState';

/** Cancel the current edit state. This will dismiss the widget being edited in LCVis. */
export const useLcvisCancelEdit = () => {
  const lcvisReady = useLcVisReadyValue();
  const setEditState = useSetEditState();
  const { setActiveNodeTable } = useSelectionContext();

  const lcvisCancelEdit = useCallback(() => {
    if (lcvisReady) {
      lcvHandler.display?.filterHandler?.cancelEdit();
    }
    setActiveNodeTable({ type: NodeTableType.NONE });
    setEditState(null);
  }, [lcvisReady, setEditState, setActiveNodeTable]);

  return lcvisCancelEdit;
};
