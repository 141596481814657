// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useLayoutEffect, useState } from 'react';

import { Tooltip, defaultStyles } from '@visx/tooltip';
import { TooltipWithBoundsProps } from '@visx/tooltip/lib/tooltips/TooltipWithBounds';

/**
 * A custom implementation of the visx TooltipWithBounds:
 * https://github.com/airbnb/visx/blob/master/packages/visx-tooltip/src/tooltips/TooltipWithBounds.tsx
 *
 * The visx component assumes that the top of the tooltip's parent === the
 * top of the window itself. On the other hand, the CustomTooltipWithBounds checks whether the
 * tooltip will overflow the bottom of the window, and if it will, shifts it up by just enough
 * to keep the whole tooltip visible.
 *
 * Also, we add a useLayoutEffect to recompute the parent and child boundingClientRects before
 * finally rendering the tooltip. This has the same effect as the withBoundingRects wrapper function
 * in the visx repo.
 */
const CustomTooltipWithBounds = (props: TooltipWithBoundsProps) => {
  const {
    getRects,
    left: initialLeft = 0,
    offsetLeft = 10,
    offsetTop = 10,
    style = defaultStyles,
    top: initialTop = 0,
    unstyled = false,
    nodeRef,
    ...otherProps
  } = props;

  const tooltipId = 'outputChartTooltip';
  const [ownBounds, setOwnBounds] = useState(props.rect);
  const [parentBounds, setParentBounds] = useState(props.parentRect);

  let transform: React.CSSProperties['transform'];
  let placeTooltipLeft = false;
  let placeTooltipUp = false;

  // The below if statement is almost entirely copied from the visx repo. The added functionality
  // is any part with a comment above it involving the cutoffAmount.
  if (ownBounds && parentBounds) {
    let left = initialLeft;
    let top = initialTop;

    if (parentBounds.width) {
      const rightPlacementClippedPx = left + offsetLeft + ownBounds.width - parentBounds.width;
      const leftPlacementClippedPx = ownBounds.width - left - offsetLeft;
      placeTooltipLeft = (
        rightPlacementClippedPx > 0 &&
        rightPlacementClippedPx > leftPlacementClippedPx
      );
    } else {
      const rightPlacementClippedPx = left + offsetLeft + ownBounds.width - window.innerWidth;
      const leftPlacementClippedPx = ownBounds.width - left - offsetLeft;
      placeTooltipLeft = (
        rightPlacementClippedPx > 0 &&
        rightPlacementClippedPx > leftPlacementClippedPx
      );
    }

    if (parentBounds.height) {
      const bottomPlacementClippedPx = top + offsetTop + ownBounds.height - parentBounds.height;
      const topPlacementClippedPx = ownBounds.height - top - offsetTop;
      placeTooltipUp = (
        bottomPlacementClippedPx > 0 &&
        bottomPlacementClippedPx > topPlacementClippedPx
      );
    } else {
      placeTooltipUp = top + offsetTop + ownBounds.height > window.innerHeight;
    }

    // Check if placing the tooltip below top, left will cause part of it to be cut off.
    const bottomCutoff = (
      placeTooltipUp ?
        0 :
        top + offsetTop + parentBounds.top + ownBounds.height - window.innerHeight
    );

    left = placeTooltipLeft ? left - ownBounds.width - offsetLeft : left + offsetLeft;
    top = placeTooltipUp ? top - ownBounds.height - offsetTop : top + offsetTop;

    // If part of the tooltip will be hidden, instead move it up so it is always fully visible.
    if (bottomCutoff > 0) {
      top -= bottomCutoff;
    }

    left = Math.round(left);
    top = Math.round(top);

    transform = `translate(${left}px, ${top}px)`;
  }

  // Update the parent and tooltip rects before the repaint, so we can move the tooltip if needed.
  useLayoutEffect(() => {
    // we have to use an id to access the tooltip, since it can't accept a ref.
    const tooltipDiv = document.getElementById(tooltipId) as HTMLDivElement | undefined;
    if (!tooltipDiv) {
      return;
    }
    const tooltipBounds = tooltipDiv.getBoundingClientRect();
    setOwnBounds(tooltipBounds);
    const parentDiv = tooltipDiv.parentNode as HTMLElement | undefined;
    if (!parentDiv) {
      return;
    }
    const parentRect = parentDiv.getBoundingClientRect();
    setParentBounds(parentRect);

    // we want this to update anytime any props change, since any of those props may change the
    // dimensions of the tooltip div.
  }, [props]);

  return (
    <Tooltip
      id={tooltipId}
      ref={nodeRef}
      style={{
        left: 0,
        top: 0,
        transform,
        ...(!unstyled && style),
      }}
      {...otherProps}>
      {props.children}
    </Tooltip>
  );
};

export const TooltipWithBounds = CustomTooltipWithBounds;
