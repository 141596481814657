// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, ReactNode } from 'react';

import cx from 'classnames';

import { SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { colors } from '../../lib/designSystem';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      borderRadius: '100px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      fontWeight: 'bold',
      width: '24px',
      height: '24px',
      fontSize: '12px',
      color: 'var(--color)',
      backgroundColor: 'var(--bg-color)',

      '&.active': {
        '&:after': {
          position: 'absolute',
          content: '""',
          border: `1px solid ${colors.yellow600}`,
          borderRadius: '50%',
          padding: '1px',
          top: '-2px',
          left: '-2px',
          bottom: '-2px',
          right: '-2px',
        },
      },

      '&:hover': {
        backgroundColor: 'var(--bg-hover-color)',
      },
    },
  }),
  { name: 'ColoredCircle' },
);

interface ColoredCircleProps {
  active?: boolean;
  children?: ReactNode;
  icon?: SvgIconSpec;
  // colors for: background, background on hover, color. Default palette is using the neutral color
  palette?: [string, string, string];
  hoverEffect?: boolean;
}

export const ColoredCircle = (props: ColoredCircleProps) => {
  // Props
  const {
    active,
    children,
    icon,
    hoverEffect,
    palette = [colors.neutral750, colors.neutral850, colors.neutral200],
  } = props;

  // Hooks
  const classes = useStyles();

  // Render
  return (
    <div
      className={cx(classes.root, { active })}
      style={{
        '--bg-color': palette[0],
        '--bg-hover-color': hoverEffect ? palette[1] : palette[0],
        '--color': palette[2],
      } as CSSProperties}>
      {icon && <SvgIcon {...icon} />}
      {/* if the children is a string, show only the first 3 chars so that it doesn't overflow */}
      {typeof children === 'string' ? children.substring(0, 3) : children}
    </div>
  );
};
