// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { formatDurationRelative } from '../../lib/formatDate';
import { TransientNotification } from '../../lib/notificationUtils';
import { upperFirst } from '../../lib/text';
import * as transientNotification from '../../lib/transientNotification';
import { useTransientNotificationValue } from '../../recoil/useTransientNotifications';
import { ToastPropsWithId, Toaster } from '../notification/Toaster';

// cut off a message before it gets too long
const MAX_MESSAGE_LENGTH = 200;

const TWO_MINUTES = 2 * 60 * 1000;

interface TransientNotificationManagerProps {
  page: transientNotification.PageKey,
  bottom?: number,
  left?: number,
}

// Format the message so it's less packed together and limit its length
const filterMessage = (message: string) => message.substring(0, MAX_MESSAGE_LENGTH);

// The message to use in the Toast.
const getMessage = (notification: TransientNotification) => {
  const notifAge = formatDurationRelative((Date.now() - notification.createTime.getTime()) / 1000);
  let message = notification.message;
  if (!message && !!notification.err) {
    message = filterMessage(notification.err.message);
  }
  return `${message} (${notifAge})`;
};

const getTitle = (
  notification: TransientNotification,
) => notification.title || upperFirst(notification.level);

const removeToast = (toastId: number) => transientNotification.removeNotification(toastId);

// convert a list of TransientNotification objects to ToastPropsWithId[]
const convertNotificationsToToastProps = (notifications: TransientNotification[]) => {
  const propsList: ToastPropsWithId[] = notifications.map(
    (notification: TransientNotification) => ({
      id: `${notification.id}`,
      title: getTitle(notification),
      projectName: notification?.projectName,
      message: getMessage(notification),
      level: notification.level,
      onDismiss: () => removeToast(notification.id),
    }),
  );
  return propsList;
};

/**
 * Displays transient notifications added by other components by formatting them
 * into toasts.
 */
export const TransientNotificationManager = (props: TransientNotificationManagerProps) => {
  const { page = transientNotification.DEFAULT_PAGE, bottom, left } = props;

  const notifications = useTransientNotificationValue(page);

  const [notifState, setNotifState] = useState(convertNotificationsToToastProps(notifications));

  useEffect(() => {
    setNotifState(convertNotificationsToToastProps(notifications));
    // Update the notifications every 2 minutes, since otherwise the time elapsed for old
    // notifications would stay the same until a rerender.
    const toastUpdateInterval = setInterval(() => {
      setNotifState(convertNotificationsToToastProps(notifications));
    }, TWO_MINUTES);
    return () => clearInterval(toastUpdateInterval);
  }, [notifications]);

  return (
    <div style={{ position: 'absolute', bottom: 0 }}>
      <Toaster
        bottom={bottom}
        left={left}
        portal
        toastInfoList={notifState}
      />
    </div>
  );
};
