// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 48;
const baseHeight = 45;
export const SortDescIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="sortDesc"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M15,33
           C14.16,33 13.41,33.33 12.87,33.87
           L12,34.77
           L12,24
           C12,22.35 10.65,21 9,21
           C7.35,21 6,22.35 6,24
           L6,34.77
           L5.13,33.9
           C4.59,33.33 3.84,33 3,33
           C1.35,33 0,34.35 0,36
           C0,36.84 0.33,37.59 0.87,38.13
           L6.87,44.13
           C7.41,44.67 8.16,45 9,45
           C9.84,45 10.59,44.67 11.13,44.13
           L17.13,38.13
           C17.67,37.59 18,36.84 18,36
           C18,34.35 16.65,33 15,33Z
           M27,36
           L24,36
           C22.35,36 21,37.35 21,39
           C21,40.65 22.35,42 24,42
           L27,42
           C28.65,42 30,40.65 30,39
           C30,37.35 28.65,36 27,36Z
           M39,12
           L24,12
           C22.35,12 21,13.35 21,15
           C21,16.65 22.35,18 24,18
           L39,18
           C40.65,18 42,16.65 42,15
           C42,13.35 40.65,12 39,12Z
           M33,24
           L24,24
           C22.35,24 21,25.35 21,27
           C21,28.65 22.35,30 24,30
           L33,30
           C34.65,30 36,28.65 36,27
           C36,25.35 34.65,24 33,24Z
           M45,0
           L24,0
           C22.35,0 21,1.35 21,3
           C21,4.65 22.35,6 24,6
           L45,6
           C46.65,6 48,4.65 48,3
           C48,1.35 46.65,0 45,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
