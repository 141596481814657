// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVType } from '@luminarycloudinternal/lcvis';

import { RgbaColor, colors, hexToRgbList, rgbToRgba } from '../../designSystem';
import { LcvModule } from '../types';

import { LcvObject } from './base/LcvObject';

export class LcvRenderer extends LcvObject {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    type = 'surface',
    backgroundColor = rgbToRgba(hexToRgbList(colors.darkBackgroundColor)),
  ) {
    super(lcv, lcv.newRenderer(sessionHandle, type, 0).renderer, sessionHandle);
    this.setBackgroundColor(backgroundColor);
  }

  getBackgroundColor(): RgbaColor {
    return this.getProperty('background_color', LCVType.kLCVDataTypeFloat4);
  }

  setBackgroundColor(color: RgbaColor) {
    this.setParam('background_color', LCVType.kLCVDataTypeFloat4, color);
  }

  /** Sets the opacity of all transparent surfaces. */
  setTransparentOpacity(opacity: number) {
    this.setParam('transparent_overlay_opacity', LCVType.kLCVDataTypeFloat, opacity);
  }
}
