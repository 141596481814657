// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { CallbackInterface, waitForAll } from 'recoil';

import * as simulationpb from '../proto/client/simulation_pb';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';
import { entityGroupState, pruneGroups, updateGroups } from '../recoil/entityGroupState';
import { frontendMenuState } from '../recoil/frontendMenuState';
import { geometryTagsState } from '../recoil/geometry/geometryTagsState';
import { meshMetadataSelector, meshUrlState } from '../recoil/meshState';
import { outputNodesState } from '../recoil/outputNodes';
import { selectedIterationState } from '../recoil/selectedIter';
import { selectedSolution } from '../recoil/selectedSolution';
import { enabledExperimentsSelector_DEPRECATED } from '../recoil/useExperimentConfig';
import { explorationsState } from '../recoil/useExplorationSet';
import { stoppingConditionsSelectorUpdate } from '../recoil/useStoppingConditions';
import { defaultVolumeState, staticVolumesState } from '../recoil/volumes';
import { currentConfigSelector, projectConfigState } from '../recoil/workflowConfig';

import WorkflowConfigValidator from './WorkflowConfigValidator';
import { filterExperimentVariables, validExploration } from './explorationUtils';
import { applyAllMaterialPresets } from './materialPreset';
import { updateOutputNodes } from './outputNodeUtils';
import { createValidScope, updateSimulationParam } from './paramUtils';
import { RecoilProjectKey } from './persist';
import { applyAllFluidControlsPresets } from './physicsFluidControlsPresets';
import { applyAllFluidDiscretizationPresets } from './physicsFluidDiscretizationPresets';
import { applyAllHeatControlsPresets } from './physicsHeatControlsPresets';
import { applyAllHeatDiscretizationPresets } from './physicsHeatDiscretizationPresets';
import { getPhysicsInitialization } from './physicsUtils';
import { getSimulationParam } from './simulationParamUtils';

export const onParamUpdateCallback = ({ snapshot, set }: CallbackInterface) => async (
  recoilKey: RecoilProjectKey,
  newParam: simulationpb.SimulationParam,
  validator?: WorkflowConfigValidator,
) => {
  const { getPromise } = snapshot;

  const projectId = recoilKey.projectId;
  const currMeshUrl = await getPromise(meshUrlState(projectId));
  const metaUrl = currMeshUrl.mesh || currMeshUrl.geometry;
  const meshMetadata = await getPromise(meshMetadataSelector({ projectId, meshUrl: metaUrl }));
  const enabledExperiments = await getPromise(enabledExperimentsSelector_DEPRECATED);
  const currentConfig = await getPromise(currentConfigSelector(recoilKey));
  const staticVolumes = await getPromise(staticVolumesState(recoilKey.projectId));
  const geometryTags = await getPromise(geometryTagsState({ projectId: recoilKey.projectId }));

  // Inform the controls and discretization presets that a param update happened and allow them
  // to modify the presets values based on this.  First apply the discretization presets since it
  // can have an influence on the controls presets.
  applyAllFluidDiscretizationPresets(newParam, enabledExperiments, geometryTags, staticVolumes);
  applyAllFluidControlsPresets(newParam, enabledExperiments, geometryTags, staticVolumes);
  applyAllHeatDiscretizationPresets(newParam, enabledExperiments);
  applyAllHeatControlsPresets(newParam, enabledExperiments);
  applyAllMaterialPresets(newParam);

  const { paramScope, validParam } =
    createValidScope(newParam, enabledExperiments, geometryTags, staticVolumes);

  const newConfig =
    updateSimulationParam(currentConfig, validParam, paramScope, geometryTags, staticVolumes);
  set(projectConfigState(projectId), newConfig);
  if (meshMetadata && validator) {
    validator.newParam(newConfig, meshMetadata.meshMetadata);
  }

  // Update the entity group structure (to add/remove disks/planes/points)
  set(entityGroupState({ projectId, workflowId: '', jobId: '' }), (oldGroups) => {
    const pruned = pruneGroups(oldGroups, getSimulationParam(newConfig));
    updateGroups({
      groupMap: pruned,
      param: getSimulationParam(newConfig),
      meshMetadata: meshMetadata?.meshMetadata,
      staticVolumes,
    });
    return pruned;
  });

  // Update the output nodes. The turbulent residual is disabled for some param settings.
  const oldNodes = await getPromise(outputNodesState({ projectId, workflowId: '', jobId: '' }));
  set(
    outputNodesState({ projectId, workflowId: '', jobId: '' }),
    updateOutputNodes(oldNodes, validParam, enabledExperiments, geometryTags, staticVolumes),
  );
};

export const copyToSetupCallback = ({ snapshot, set }: CallbackInterface) => async (
  recoilKey: RecoilProjectKey,
) => {
  const { getPromise } = snapshot;

  const projectId = recoilKey.projectId;
  // Fetch the state that's only needed here.
  const [
    entityGroup,
    currentSolution,
    selectedIter,
    frontendMenuStateCurr,
    currentConfig,
    experimentConfig,
    stopConds,
    explorationSet,
    geometryTags,
    staticVolumes,
  ] = await getPromise(waitForAll([
    entityGroupState(recoilKey),
    selectedSolution(recoilKey),
    selectedIterationState(recoilKey),
    frontendMenuState(recoilKey),
    currentConfigSelector(recoilKey),
    enabledExperimentsSelector_DEPRECATED,
    stoppingConditionsSelectorUpdate(recoilKey),
    explorationsState(projectId),
    geometryTagsState(recoilKey),
    staticVolumesState(projectId),
  ]));
  const setupKey: RecoilProjectKey = { projectId, workflowId: '', jobId: '' };

  // explicitly setting entityGroupState ensures it is not the default value, which is a selector.
  // this prevents entityGroupState from being dynamically updated via the selector.
  set(entityGroupState(setupKey), entityGroup);

  // Copy the simulation state to the setup tab state.
  let newConfig = currentConfig.clone();
  const newParam = getSimulationParam(currentConfig).clone();
  const { paramScope, validParam } =
    createValidScope(newParam, experimentConfig, geometryTags, staticVolumes);
  newConfig =
    updateSimulationParam(currentConfig, validParam, paramScope, geometryTags, staticVolumes);
  if (currentSolution) {
    // If we have a valid solution, set it as the default initialization.
    validParam.physics.forEach((physics) => {
      const init = getPhysicsInitialization(physics);
      if (init) {
        init.existingSolutionUrl = currentSolution.url;
        init.initializationType = simulationpb.InitializationType.EXISTING_SOLUTION;
      }
    });
  }

  // Load the mesh from the copied config.  Even if there's already another mesh in
  // the setup tab, we should overwrite it with the mesh from the copied simulation.
  const jobConfig = newConfig.jobConfigTemplate;
  const simParam = jobConfig?.typ.case === 'simulationParam' ? jobConfig.typ.value : null;
  const simulationInput = simParam?.input;
  const newMeshUrl = simulationInput?.url;
  const newMeshID = simulationInput?.meshIdentifier?.id ?? '';
  if (newMeshUrl) {
    set(meshUrlState(projectId), (oldMeshUrlState) => {
      oldMeshUrlState.mesh = newMeshUrl;
      oldMeshUrlState.meshId = newMeshID;
      oldMeshUrlState.activeType = projectstatepb.UrlType.MESH;
      return oldMeshUrlState;
    });
    // Explicitly set entityGroupState to update it.
    const meshMetadata = await getPromise(meshMetadataSelector(
      { projectId, meshUrl: newMeshUrl },
    ));
    if (meshMetadata) {
      // This adds surfaces and volumes of the mesh from the copied config to the current
      // entity group map. (This works as a middle ground between maintaining the current
      // entity group map and creating a new default entity group map from the mesh. There
      // are obvious issues if the current entity group map reflects a completely different
      // geometry than the one being copied... but that's a general limitation currently.)
      set(entityGroupState(setupKey), (oldGroups) => {
        updateGroups({
          groupMap: oldGroups,
          param: getSimulationParam(newConfig),
          meshMetadata: meshMetadata.meshMetadata,
          staticVolumes: defaultVolumeState(meshMetadata.meshMetadata),
        });
        return oldGroups;
      });
    }

    // TODO: https://luminarycloud.atlassian.net/browse/LC-17050
    // Unfortunately, the following block is causing the copied mesh sizing params to be locked.
    // After copying mesh setup, the sizing strategy and cell count cannot be changed. This will
    // need to be resolved if we want true "copy mesh setup" functionality

    // Even though we have copied the actual mesh (which is the thing that matters), we also want
    // to copy the mesh settings (Mesh Size, Model and Boundary Layer) that were used to generate
    // the mesh for that simulation.
    // const meshingMultiPart = await getPromise(meshingMultiPartSelector(recoilKey));
    // const newMeshMultiPart = meshingMultiPart?.clone();
    // if (newMeshMultiPart) {
    //   set(meshingMultiPartState(projectId), newMeshMultiPart);
    // }
  }

  // Clear the GPU pref list to avoid the issue described in LC-6973.
  // NOTE: when users modify the GPU pref through the cloud settings, the gpuPrefList has length
  // equal to 1. In that case, we do not clear the gpuPrefList to maintain the user's input.
  if (jobConfig?.gpuPref && jobConfig?.gpuPref.length > 1) {
    jobConfig.gpuPref = [];
  }

  // Go back to the default index if the exploration is not valid and
  // filter out all invalid variables.
  if (!validExploration(newConfig.exploration)) {
    explorationSet.index = -1;
    explorationSet.exploration = explorationSet.exploration.map(
      (exploration) => filterExperimentVariables(
        exploration,
        validParam,
        paramScope,
        geometryTags,
        staticVolumes,
      ),
    );
  }

  // Set the project config of the setup page.
  set(projectConfigState(projectId), newConfig);

  const newMenuState = frontendMenuStateCurr.clone();
  newMenuState.initState = new projectstatepb.InitializationState({
    iter: selectedIter,
    jobId: recoilKey.jobId,
    workflowId: recoilKey.workflowId,
  });

  set(frontendMenuState(setupKey), newMenuState);
  set(stoppingConditionsSelectorUpdate(setupKey), stopConds);
  set(explorationsState(projectId), explorationSet);
};
