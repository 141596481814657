// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const DiskQuestionIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="diskQuestion"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M12,0
           C5.37,0 0,5.37 0,12
           C0,18.63 5.37,24 12,24
           C18.63,24 24,18.63 24,12
           C24,5.37 18.63,0 12,0Z
           M13.5,19.5
           L10.5,19.5
           L10.5,16.5
           L13.5,16.5
           L13.5,19.5Z
           M16.395,9.72
           C16.185,10.2 15.87,10.68 15.465,11.175
           L13.875,13.245
           C13.695,13.47 13.515,13.68 13.455,13.875
           C13.395,14.07 13.32,14.325 13.32,14.655
           L13.32,15
           L10.68,15
           L10.68,13.32
           C10.68,13.32 10.755,12.555 10.995,12.255
           L12.6,10.095
           C12.93,9.705 13.125,9.36 13.26,9.075
           C13.395,8.79 13.44,8.505 13.44,8.205
           C13.44,7.755 13.29,7.38 13.02,7.08
           C12.75,6.795 12.36,6.66 11.88,6.66
           C11.385,6.66 10.995,6.81 10.71,7.095
           C10.425,7.38 10.215,7.785 10.11,8.31
           C10.065,8.475 9.96,8.535 9.81,8.52
           L7.26,8.145
           C7.08,8.13 7.02,8.025 7.05,7.86
           C7.23,6.63 7.74,5.655 8.595,4.95
           C9.45,4.23 10.575,3.87 11.97,3.87
           C12.675,3.87 13.32,3.975 13.905,4.2
           C14.49,4.425 14.985,4.71 15.405,5.085
           C15.825,5.46 16.14,5.91 16.38,6.42
           C16.605,6.945 16.71,7.5 16.71,8.1
           C16.71,8.7 16.605,9.225 16.395,9.72Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
