// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { escape } from 'html-escaper';
import { SetterOrUpdater } from 'recoil';

import * as basepb from '../proto/base/base_pb';
import * as shapepb from '../proto/cad/shape_pb';
import * as meshgenerationpb from '../proto/meshgeneration/meshgeneration_pb';

import { axesToEuler, newOriginProto, newProto, rotate } from './Vector';
import { nullableMeshing } from './mesh';
import { prefixNameGen, uniqueSequenceName } from './name';
import * as random from './random';

export const rrShapes = ['Sphere', 'Cylinder', 'Box'] as const;
export type Shape = typeof rrShapes[number]

export function generateRegionId() {
  return `rrParam-${random.string(16)}`;
}

/**
 * TODO(jw):
 * Add tests for these functions (LC-12588)
 * */

/** Deletes the refinement region with the given id. */
export const deleteRefinementRegion = (
  setMeshMultiPart: SetterOrUpdater<nullableMeshing>,
  id: string,
): boolean => {
  let deleted = false;
  setMeshMultiPart((curr) => {
    const newMeshMultiPart = curr?.clone() ?? new meshgenerationpb.MeshingMultiPart();
    const rrParamsList = newMeshMultiPart.refinementParams.filter(
      (rrParam) => rrParam.id !== id,
    );
    deleted = rrParamsList.length < newMeshMultiPart.refinementParams.length;
    newMeshMultiPart.refinementParams = rrParamsList;
    return newMeshMultiPart;
  });
  return deleted;
};

/**
 * Add a new refinement region param to meshMultiPart with a unique
 * sequence name starting with namePrefix, where namePrefix is the Shape name.
 */
const addRrParam = (currMeshMultiPart: nullableMeshing, id: string, namePrefix: Shape) => {
  const existingNamesOfType = currMeshMultiPart?.refinementParams
    .filter((rrParam) => rrParam.name.startsWith(namePrefix)) ?? [];
  const existingNames = existingNamesOfType.map((rrParam) => rrParam.name) ?? [];
  const name = uniqueSequenceName(existingNames, prefixNameGen(namePrefix));
  const newMultiPart = currMeshMultiPart?.clone() ?? new meshgenerationpb.MeshingMultiPart();

  // The max size should be the upper bound of all max sizes so that creating a refinement region
  // and forgetting to update the size shouldn't result in a super refined mesh.
  const maxSize = newMultiPart.volumeParams.reduce(
    (prev, curr) => (prev.maxSize > curr.maxSize ? prev : curr),
  ).maxSize;

  const newParam = new meshgenerationpb.MeshingMultiPart_RefinementRegionParams({
    hLimit: maxSize,
    id,
    name,
  });
  newMultiPart.refinementParams.push(newParam);
  return { newParam, newMultiPart };
};

/** All rr 'cubes' are actually OrientedCubes. */
export const defaultCube = (sideLength: number) => {
  const newLength = Math.round(sideLength * 1000) / 1000; // round to 3 decimal places
  return new shapepb.OrientedCube({
    min: newOriginProto(),
    max: newProto(newLength, newLength, newLength),
    origin: newOriginProto(),
    xAxis: newProto(1, 0, 0),
    yAxis: newProto(0, 1, 0),
  });
};

/** All 'cylinders' are annular cylinders with inner radius set to 0. */
export const defaultCylinder = (outerRadius: number) => new shapepb.AnnularCylinder({
  start: newOriginProto(),
  end: newProto(Math.round(outerRadius * 1000) / 1000, 0, 0),
  radius: Math.round(outerRadius * 1000) / 1000,
  radiusInner: 0,
});

/** All 'spheres' are sphere shells with inner radius set to 0. */
export const defaultSphere = (radius: number) => new shapepb.SphereShell({
  center: newOriginProto(),
  radius: Math.round(radius * 1000) / 1000,
  radiusInner: 0,
});

/** Add a default box to meshMultiPart and assign it the given id. */
export const addBox = (
  setMeshMultiPart: SetterOrUpdater<nullableMeshing>,
  id: string,
  sideLength: number,
) => {
  setMeshMultiPart((meshMultiPart) => {
    const { newMultiPart, newParam } = addRrParam(meshMultiPart, id, 'Box');
    newParam.shape = {
      case: 'orientedCube',
      value: defaultCube(sideLength),
    };
    return newMultiPart;
  });
};

/** Add a default cylinder to meshMultiPart and assign it the given id. */
export const addCylinder = (
  setMeshMultiPart: SetterOrUpdater<nullableMeshing>,
  id: string,
  outerRadius: number,
) => {
  setMeshMultiPart((meshMultiPart) => {
    const { newMultiPart, newParam } = addRrParam(meshMultiPart, id, 'Cylinder');
    newParam.shape = {
      case: 'annularCylinder',
      value: defaultCylinder(outerRadius),
    };
    return newMultiPart;
  });
};

/** Add a default sphere to meshMultiPart and assign it the given id. */
export const addSphere = (
  setMeshMultiPart: SetterOrUpdater<nullableMeshing>,
  id: string,
  outerRadius: number,
) => {
  setMeshMultiPart((meshMultiPart) => {
    const { newMultiPart, newParam } = addRrParam(meshMultiPart, id, 'Sphere');
    newParam.shape = {
      case: 'sphereShell',
      value: defaultSphere(outerRadius),
    };
    return newMultiPart;
  });
};

/** Get the refinement region param with the given id. */
export const getCurrentRrParam = (
  id: string | undefined,
  meshMultiPart: nullableMeshing,
) => meshMultiPart?.refinementParams
  .find((rrParam) => rrParam.id === id);

/** The fields on a refinement region that are updatable. */
export type RrFieldNumber = (
  'Size' | 'SphereRadiusOuter' | 'SphereRadiusInner' | 'CylRadiusOuter' | 'CylRadiusInner'
)

export type RrFieldVector = (
  'CubeMin' | 'CubeMax' | 'Center' | 'Start' | 'End' | 'Origin'
)

/**
 * Given a numeric field and a refinement region id,
 * sets that field's value in meshingMultiPart.
 * */
export const updateRrParamNumber = (
  setMeshMultiPart: SetterOrUpdater<nullableMeshing>,
  id: string,
  newValue: number,
  fieldToUpdate: RrFieldNumber,
) => {
  setMeshMultiPart((oldMeshMultiPart) => {
    const newMeshMultiPart = oldMeshMultiPart!.clone();
    const currentRrParam = getCurrentRrParam(id, newMeshMultiPart)!;
    switch (fieldToUpdate) {
      case 'Size':
        currentRrParam.hLimit = newValue as number;
        break;
      case 'SphereRadiusOuter':
        (currentRrParam.shape.value as shapepb.SphereShell).radius = newValue;
        break;
      case 'SphereRadiusInner':
        (currentRrParam.shape.value as shapepb.SphereShell).radiusInner = newValue;
        break;
      case 'CylRadiusInner':
        (currentRrParam.shape.value as shapepb.AnnularCylinder).radiusInner = newValue;
        break;
      case 'CylRadiusOuter':
        (currentRrParam.shape.value as shapepb.AnnularCylinder).radius = newValue;
        break;
      default:
    }
    return newMeshMultiPart;
  });
};

/** Given a vector param and a refinement region id, sets that field's value in meshingMultiPart. */
export const updateRrParamVector = (
  setMeshMultiPart: SetterOrUpdater<nullableMeshing>,
  id: string,
  newValue: basepb.Vector3,
  fieldToUpdate: RrFieldVector,
) => {
  setMeshMultiPart((oldMeshMultiPart) => {
    const newMeshMultiPart = oldMeshMultiPart!.clone();
    const currentRrParam = getCurrentRrParam(id, newMeshMultiPart)!;
    switch (fieldToUpdate) {
      case 'Center':
        (currentRrParam.shape.value as shapepb.SphereShell).center = newValue;
        break;
      case 'CubeMax':
        (currentRrParam.shape.value as shapepb.OrientedCube).max = newValue;
        break;
      case 'CubeMin':
        (currentRrParam.shape.value as shapepb.OrientedCube).min = newValue;
        break;
      case 'Start':
        (currentRrParam.shape.value as shapepb.AnnularCylinder).start = newValue;
        break;
      case 'End':
        (currentRrParam.shape.value as shapepb.AnnularCylinder).end = newValue;
        break;
      case 'Origin':
        (currentRrParam.shape.value as shapepb.OrientedCube).origin = newValue;
        break;
      default:
    }
    return newMeshMultiPart;
  });
};

export const getShapeName = (
  currentRrParam: meshgenerationpb.MeshingMultiPart_RefinementRegionParams,
): Shape => {
  switch (currentRrParam.shape.case) {
    case 'annularCylinder':
      return 'Cylinder';
    case 'sphereShell':
      return 'Sphere';
    case 'orientedCube':
      return 'Box';
    default:
      return 'Box';
  }
};

/**
 * Transform the displayed orientation and translation vectors into actual rotation and
 * translation to send to the backend and Paraview
 */
export const eulerAnglesToAxes = (orientation: basepb.Vector3): {
  xAxis: basepb.Vector3, yAxis: basepb.Vector3
} => {
  const oldX = newProto(1, 0, 0);
  const oldY = newProto(0, 1, 0);

  const xAxis = rotate(orientation, oldX);
  const yAxis = rotate(orientation, oldY);

  return { xAxis, yAxis };
};

/** Given the x and y axes, get the rotation vec to show to the user. */
export const axesToEulerAngles = (
  xAxis: basepb.Vector3,
  yAxis: basepb.Vector3,
): basepb.Vector3 => {
  const orientation = axesToEuler(xAxis, yAxis);
  return orientation;
};

export const getHLimitBounds = (meshMultiPart: meshgenerationpb.MeshingMultiPart) => {
  // This can happen for example if the user imports a library entry from an external mesh,
  // in which case the volumeParams will be empty.
  if (!meshMultiPart.volumeParams.length) {
    return { maxSize: 0, minSize: 0 };
  }
  const maxSize = meshMultiPart.volumeParams.reduce(
    (prev, curr) => (prev.maxSize > curr.maxSize ? prev : curr),
  ).maxSize;
  const minSize = meshMultiPart.volumeParams.reduce(
    (prev, curr) => (prev.minSize < curr.minSize ? prev : curr),
  ).minSize;

  return { maxSize, minSize };
};

export const getRefinementRegionWarnings = (
  region: meshgenerationpb.MeshingMultiPart_RefinementRegionParams,
  maxSize: number,
  minSize: number,
) => {
  const warnings: string[] = [];

  if (!region) {
    return warnings;
  }
  const regionName = `<b>${escape(region.name)}</b>`;
  if (region.hLimit > maxSize) {
    warnings.push(`Refinement region ${regionName}: HLimit must be less than Mesh Max Size.`);
  }
  if (region.hLimit < minSize) {
    warnings.push(`Refinement region ${regionName}: HLimit must be greater than Mesh Min Size.`);
  }

  switch (region.shape.case) {
    case 'annularCylinder': {
      const cylinder = region.shape.value!;
      if (cylinder.radiusInner >= cylinder.radius) {
        warnings.push(`Refinement region ${regionName}: inner radius must be < outer radius.`);
      }
      if (cylinder.radiusInner < 0) {
        warnings.push(`Refinement region ${regionName}: inner radius must be positive.`);
      }
      if (cylinder.radius < 0) {
        warnings.push(`Refinement region ${regionName}: outer radius must be positive.`);
      }
      break;
    }
    case 'sphereShell': {
      const sphere = region.shape.value!;
      if (sphere.radiusInner >= sphere.radius) {
        warnings.push(`Refinement region ${regionName}: inner radius must be < outer radius.`);
      }
      if (sphere.radiusInner < 0) {
        warnings.push(`Refinement region ${regionName}: inner radius must be positive.`);
      }
      if (sphere.radius < 0) {
        warnings.push(`Refinement region ${regionName}: outer radius must be positive.`);
      }
      break;
    }
    default:
      break;
  }

  return warnings;
};

/**
 * When we click 'Generate mesh', the current refinement regions are sent along with their
 * ids and stored in the backend. But the visualization of those refinement regions is tied to
 * their ids. So hiding a region in the simulation result tab that is still present in setup will
 * also hide it in the setup tab.
 * This function assigns a new id to each refinement region right before we send the mesh params
 * to the backend. Then in the simulation result tab, regions that were the same as those in the
 * setup tab no longer have id collisions.
 */
export const resetRegionIds = (meshMultiPart: meshgenerationpb.MeshingMultiPart) => {
  const newMultiPart = meshMultiPart.clone();

  const rr = newMultiPart.refinementParams;
  rr.forEach((region) => {
    region.id = generateRegionId();
  });
  return newMultiPart;
};

type RefinementRegion = {
  origin?: { x: number; y: number; z: number };
  max?: { x: number; y: number; z: number };
}

type Box = {
  size: { x: number; y: number; z: number };
  center: { x: number; y: number; z: number };
}

export const refineRegionDefinitionToBoxDefinition = (refineRegion: RefinementRegion): Box => ({
  center: {
    x: refineRegion.origin!.x + refineRegion.max!.x / 2,
    y: refineRegion.origin!.y + refineRegion.max!.y / 2,
    z: refineRegion.origin!.z + refineRegion.max!.z / 2,
  },
  size: {
    x: refineRegion.max!.x,
    y: refineRegion.max!.y,
    z: refineRegion.max!.z,
  },
});

export const boxDefinitionToRefineRegionDefinition = (box: Box): RefinementRegion => ({
  origin: {
    x: box.center!.x - box.size!.x / 2,
    y: box.center!.y - box.size!.y / 2,
    z: box.center!.z - box.size!.z / 2,
  },
  max: {
    x: box.size!.x,
    y: box.size!.y,
    z: box.size!.z,
  },
});
