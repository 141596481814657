// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useRef } from 'react';

/**
 * In order to queue a callback for the _next_ frame (rather than the _current_ frame), use two
 * nested window.requestAnimationFrame calls.  Use this hook to reduce boilerplate and to make sure
 * any frame requests are cancelled on unmount.
 */
export const useNextAnimationFrame = () => {
  const afIds = useRef(new Set<number>());

  const requestNextFrame = (callback: (timestamp: DOMHighResTimeStamp) => void) => {
    const outerId = window.requestAnimationFrame(() => {
      const innerId = window.requestAnimationFrame((ts) => {
        callback(ts);
        afIds.current.delete(innerId);
      });
      afIds.current.add(innerId);
      afIds.current.delete(outerId);
    });
    afIds.current.add(outerId);
  };

  useEffect(() => () => {
    afIds.current.forEach((id) => window.cancelAnimationFrame(id));
    afIds.current.clear();
  });

  return { requestNextFrame };
};
