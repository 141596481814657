// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, {
  CSSProperties,
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import cx from 'classnames';

import { DragControl } from '../../lib/DragControl';
import { NestedBoxConstraint } from '../../lib/dragConstraints';
import { DragOffset } from '../../lib/dragUtil';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    splitter: {
      flex: '0 0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: 2,
    },
    content: {
      flex: '0 0 auto',
      display: 'flex',
      position: 'relative',
      zIndex: 1,
    },
  }),
  { name: 'Resizable' },
);

export type SplitterPlacement = 'top' | 'right' | 'bottom' | 'left';

export interface DragInputs {
  // An HTML node that bounds the movement of the splitter (typically an ancestor DOM node)
  boundingNode?: HTMLElement;
}

type SplitterOptions = {
  openOnClick?: boolean;
  closeOnClick?: boolean;
  popOpenSize?: string;
}

export interface ResizableProps {
  // A CSS length value like 'auto', '80px', or '25%'
  initialSize: string;
  // Arbitrary content for the splitter
  splitterContent: ReactElement | HTMLElement;
  // Placement of splitter relative to content (children)
  splitterPlacement: SplitterPlacement;
  // Content to be resized
  children: ReactNode;
  // When dragging starts, getDragInputs is called to get the bounding box.  (This is in a
  // function rather than a direct prop, because parent components may store this in a useRef,
  // which isn't populate on initial rendering)
  getDragInputs: () => DragInputs;
  // Optionally set a maximum content size
  maxSize?: number;
  // Optionally set a minimum content size
  minSize?: number;
  // Optionally set the z-index of the content, default = 1
  zIndex?: number;
  // Optionally set the z-index of the splitter, default = zIndex + 1
  zIndexSplitter?: number;
  // Optionally set double click settings
  clickSettings?: SplitterOptions;
  // Optionally fire a callback when the splitter reaches the min size
  onCollapseOrExpand?: (collapsed: boolean) => void;
  // Threshold at which the component collapses
  collapseThreshold?: number;
}

export const Resizable = (props: ResizableProps) => {
  // Props
  const {
    children,
    getDragInputs,
    initialSize,
    maxSize,
    minSize,
    splitterContent,
    splitterPlacement,
    zIndex = 1,
    zIndexSplitter = zIndex + 1,
    clickSettings,
    onCollapseOrExpand,
    collapseThreshold,
  } = props;

  const { openOnClick, closeOnClick, popOpenSize } = clickSettings || {};

  // Hooks
  const [dragControl, setDragControl] = useState<DragControl>();
  const [contentSize, setContentSize] = useState(initialSize);
  const contentRef = useRef<HTMLDivElement>(null);
  const splitterRef = useRef<HTMLDivElement>(null);
  const mouseDownRef = useRef({ x: 0, y: 0 });

  // CSS
  const classes = useStyles();

  // Effects
  useEffect(() => {
    setContentSize(initialSize);
  }, [initialSize]);

  // Other state
  const vertical = (splitterPlacement === 'top' || splitterPlacement === 'bottom');
  const leading = (splitterPlacement === 'top' || splitterPlacement === 'left');
  const sizeProp = vertical ? 'height' : 'width';
  const minSizeProp = vertical ? 'minHeight' : 'minWidth';
  const maxSizeProp = vertical ? 'maxHeight' : 'maxWidth';
  const directionFactor = leading ? -1 : 1;

  const contentStyle: CSSProperties = {
    [sizeProp]: contentSize,
    [minSizeProp]: minSize === undefined ? 'auto' : `${minSize}px`,
    [maxSizeProp]: maxSize === undefined ? 'auto' : `${maxSize}px`,
    zIndex,
  };

  const isZeroSize = (
    contentSize === '0%' ||
    contentSize === '0px'
  );

  const cursorStyle = (() => {
    if (isZeroSize && vertical) {
      return 'n-resize';
    }
    if (vertical) {
      return 'ns-resize';
    }
    return 'ew-resize';
  })();

  const splitterStyle: CSSProperties = {
    zIndex: zIndexSplitter,
    cursor: cursorStyle,
  };

  // Handlers
  const handleDragState = (event: MouseEvent) => {
    // Ignore right-clicks
    if (event.button !== 0) {
      return;
    }
    mouseDownRef.current = { x: event.clientX, y: event.clientY };

    const splitterNode = splitterRef.current;
    const contentNode = contentRef.current;
    const { boundingNode } = getDragInputs();
    if (!boundingNode || !splitterNode || !contentNode) {
      return;
    }

    // Get initial size (in pixels) of content
    const contentBox = contentNode.getBoundingClientRect();
    const currentSize = vertical ? contentBox.height : contentBox.width;

    // Set a constaint for the splitter dragging operation
    const constraint = new NestedBoxConstraint(splitterNode, boundingNode);

    const control = new DragControl(
      event.nativeEvent,
      () => { },
      (offset: DragOffset) => {
        const absoluteDelta = vertical ? offset.deltaY : offset.deltaX;
        const finalDelta = directionFactor * absoluteDelta;

        let size = currentSize + finalDelta;
        if (maxSize !== undefined) {
          size = Math.min(maxSize, size);
        }
        if (minSize !== undefined) {
          size = Math.max(minSize, size);
        }

        // Check if size is below collapse threshold
        if (collapseThreshold !== undefined && size <= collapseThreshold) {
          setContentSize('0px');
          onCollapseOrExpand?.(true);
        } else {
          setContentSize(`${size}px`);
          if (size === minSize) {
            onCollapseOrExpand?.(true);
          }
          if (isZeroSize && size > 0) {
            onCollapseOrExpand?.(false);
          }
        }
      },
      () => {
        setDragControl(undefined);
      },
      [constraint],
    );

    setDragControl(control);
  };

  const handleDoubleClick = () => {
    if (isZeroSize && openOnClick) {
      setContentSize(popOpenSize ?? '50%');
      onCollapseOrExpand?.(false);
    }
    if (!isZeroSize && closeOnClick) {
      setContentSize('0px');
      onCollapseOrExpand?.(true);
    }
  };

  const splitter = (
    <div
      className={cx(classes.splitter, { vertical, dragging: dragControl })}
      onDoubleClick={handleDoubleClick}
      onMouseDown={handleDragState}
      ref={splitterRef}
      role="button"
      style={splitterStyle}
      tabIndex={-1}>
      {splitterContent}
    </div>
  );

  const content = (
    <div
      className={classes.content}
      ref={contentRef}
      style={contentStyle}>
      {children}
    </div>
  );

  return leading ?
    (<>{splitter}{content}</>) :
    (<>{content}{splitter}</>);
};
