// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as notifpb from '../proto/notification/notification_pb';

import { validExploration } from './explorationUtils';
import { hashMessage } from './utils';

export type JobNameKey = {
  workflowId: string,
  jobId?: string,
  type?: notifpb.JobType,
}

export interface JobNameMap {
  get(key: JobNameKey): string | undefined;
  getDefault(key: JobNameKey): string;
  set(key: JobNameKey, newName: string): void;
  // This hash can be used in dependency arrays. It isn't _really_ needed right now because
  // any call to JobNameMapSwitcher.set() eventually results in the jobNameMapSelector being
  // refreshed - but just in case.
  hash: number;
}

// This is the newer implementation of JobNameMap. It's mostly just a dumb wrapper around some
// immutable state. Most of the logic should be handled by recoil and frontendserver.
export class NewJobNameMap implements JobNameMap {
  readonly hash: number = 0;
  private nameMap: Map<string, string>;

  constructor(
    nameMap: Map<string, string>,
    private renameCallback: (key: JobNameKey, name: string) => void,
  ) {
    this.nameMap = structuredClone(nameMap);
    this.hash = hashMessage(JSON.stringify(nameMap));
  }

  public get(key: JobNameKey): string | undefined {
    if (key.jobId === undefined) {
      return this.nameMap.get(key.workflowId);
    }
    return this.nameMap.get(key.jobId);
  }

  public getDefault(key: JobNameKey): string {
    if (key.jobId === undefined) {
      return this.nameMap.get(key.workflowId) || 'Untitled exploration';
    }
    return this.nameMap.get(key.jobId) || 'Untitled simulation';
  }

  // Set the name for a given job by its ID.
  public set(key: JobNameKey, newName: string): void {
    this.renameCallback(key, newName);
  }
}

// Get the type of a job within the given workflow.
export function getJobType(reply: frontendpb.GetWorkflowReply): notifpb.JobType {
  const exploration = reply.config!.exploration;
  if (exploration?.policy.case === 'sensitivityAnalysis') {
    return notifpb.JobType.SENSITIVITY_JOB;
  }
  if (validExploration(exploration)) {
    return notifpb.JobType.EXPLORATION_JOB;
  }
  return notifpb.JobType.SIMULATION_JOB;
}
