// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import { isLMAState } from './useIsLMA';
import { projectMeshListAtom } from './useProjectMeshList';

export enum MeshPanelType {
  SELECT = 0,
  EDIT = 1,
  DETAILS = 2
}

const meshPanelStateDefaultSelector = selectorFamily<MeshPanelType, string>({
  key: 'meshPanelStateDefault',
  get: (projectId: string) => ({ get }) => {
    const list = get(projectMeshListAtom(projectId));
    const isLMA = get(isLMAState({ projectId, workflowId: '', jobId: '' }));
    return (list.length === 0 && !isLMA) ? MeshPanelType.SELECT : MeshPanelType.EDIT;
  },
});

/**
 * This atom tracks the state of the MultiMesh panel: Select Mesh, Edit Mesh, Mesh Details
 */
export const meshPanelStateAtom = atomFamily<MeshPanelType, string>({
  key: 'meshPanelType',
  default: meshPanelStateDefaultSelector,
});

export const useSetMeshPanelState = (projectId: string) => (
  useSetRecoilState(meshPanelStateAtom(projectId))
);

export const useMeshPanelState = (projectId: string) => (
  useRecoilValue(meshPanelStateAtom(projectId))
);

export const useIsMeshSelectPanel = (projectId: string) => (
  useRecoilValue(meshPanelStateAtom(projectId)) === MeshPanelType.SELECT
);

export const useIsMeshEditPanel = (projectId: string) => (
  useRecoilValue(meshPanelStateAtom(projectId)) === MeshPanelType.EDIT
);

export const useIsMeshDetailsPanel = (projectId: string) => (
  useRecoilValue(meshPanelStateAtom(projectId)) === MeshPanelType.DETAILS
);
