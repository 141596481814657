// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { LcvModule } from '../../types';
import { LcvObject } from '../base/LcvObject';

export class LcvGridAxes extends LcvObject {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, lcv.newAnnotation(sessionHandle, 'grid_annotation', 0).annotation, sessionHandle);
  }
}
