// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { createStyles, makeStyles } from './Theme';

const useAdornmentStyles = makeStyles(
  () => createStyles({
    root: {
      fontSize: '90%',
      lineHeight: 1.0,
      opacity: 0.7,
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'TextAdornment' },
);

export interface TextAdornmentProps {
  /** A label to display as a unit in inputs */
  label: string
}

/** An end adornment for text inputs that displays a unit of measurement */
export function TextAdornment(props: TextAdornmentProps) {
  const { label } = props;
  const adornmentClasses = useAdornmentStyles();

  return (
    <div className={adornmentClasses.root}>{label}</div>
  );
}
