// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 12;
export const GeometryScaleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="geometryScale"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <rect
        height="10.909"
        style={{
          fill: 'none',
          stroke: getColor(0),
          strokeWidth: '1.09px',
          strokeDasharray: '3.64,1.82',
        }}
        width="10.909"
        x="0.545"
        y="0.545"
      />
      <rect height="6.545" style={{ fill: getColor(0) }} width="6.545" x="0" y="5.455" />
    </svg>
  );
});
