// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useMemo } from 'react';

import assert from '../../../lib/assert';
import {
  findFluidBoundaryCondition,
  findHeatBoundaryCondition,
  findParentPhysicsByBoundaryConditionId,
  getUnassignedSurfacesByPhysics,
} from '../../../lib/boundaryConditionUtils';
import { expandGroupsExcludingTags, rollupGroups } from '../../../lib/entityGroupUtils';
import { getPhysicsId, getSurfaceToPhysicsMap } from '../../../lib/physicsUtils';
import { NodeType } from '../../../lib/simulationTree/node';
import { assignSurfacesToBoundaryCondition } from '../../../lib/simulationUtils';
import { NodeFilterData, defaultNodeFilter } from '../../../lib/subselectUtils';
import { addError } from '../../../lib/transientNotification';
import { usePhysicsSet } from '../../../model/hooks/usePhysicsSet';
import { useWorkflowConfig } from '../../../model/hooks/useWorkflowConfig';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { useStaticVolumes } from '../../../recoil/volumes';

/**
 * A model hook for managing the NodeSubselect of an individual boundary condition
 * @param projectId
 * @param workflowId
 * @param jobId
 * @param readOnly
 * @param id
 * @returns
 */
export const useCommonBoundaryCondition = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
  id: string, // Boundary condition ID
) => {
  // == Recoil
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  // == Model hooks
  const { saveBoundaryConditionAsync } = usePhysicsSet(projectId, workflowId, jobId, readOnly);

  // == Custom hooks
  const { simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  // Memoize the boundary condition object itself.
  const boundaryCondition = useMemo(() => (
    findFluidBoundaryCondition(simParam, id) || findHeatBoundaryCondition(simParam, id)
  ), [id, simParam]);
  assert(!!boundaryCondition, 'No selected boundary condition');

  const rollup = useMemo(() => rollupGroups(entityGroupData), [entityGroupData]);
  const nodeIds = useMemo(() => rollup(boundaryCondition.surfaces), [rollup, boundaryCondition]);

  const surfaceWarnings = useMemo(() => {
    const state: Record<string, NodeFilterData<NodeType.SURFACE | NodeType.SURFACE_GROUP>> = {};
    const surfacesToPhysics = getSurfaceToPhysicsMap(simParam, geometryTags, staticVolumes);
    const physics = findParentPhysicsByBoundaryConditionId(simParam, id);
    assert(!!physics, 'Cannot find parent physics for boundary condition');
    const physicsId = getPhysicsId(physics);
    const surfacePool = new Set(getUnassignedSurfacesByPhysics(
      simParam,
      physicsId,
      geometryTags,
      staticVolumes,
      entityGroupData,
    ));

    staticVolumes.forEach((volume) => {
      volume.bounds.forEach((surfaceId) => {
        const surfacePhysics = surfacesToPhysics[surfaceId];
        if (!surfacePhysics) {
          state[surfaceId] = {
            disabled: true,
            type: NodeType.SURFACE,
          };
          return;
        }
        if (surfacePhysics !== physicsId) {
          state[surfaceId] = {
            disabled: true,
            type: NodeType.SURFACE,
          };
          return;
        }
        const surfaceInPool = surfacePool.has(surfaceId);
        if (!surfaceInPool) {
          state[surfaceId] = {
            disabled: true,
            type: NodeType.SURFACE,
          };
          return;
        }
        state[surfaceId] = {
          disabled: false,
          type: NodeType.SURFACE,
        };
      });
    });

    entityGroupData.leafMap.forEach((leafIds, groupId) => {
      // These ones were covered above. Geometry tags have to be handled differently.
      if (groupId in state || geometryTags.isTagId(groupId)) {
        return;
      }

      // Groups are disabled if any of the surfaces in the group are disabled.
      let someDisabled = false;
      leafIds.forEach((leafId) => {
        if (state[leafId]?.disabled ?? true) {
          someDisabled = true;
        }
      });

      state[groupId] = {
        disabled: someDisabled,
        type: NodeType.SURFACE_GROUP,
      };
    });

    geometryTags.tagIds().forEach((tagId) => {
      const nBodies = geometryTags.domainsFromTag(tagId).length;
      const nFaces = geometryTags.surfacesFromTagEntityGroupId(tagId)?.length || 0;
      if (nFaces === 0 && nBodies > 0) {
        state[tagId] = {
          disabled: true,
          type: NodeType.SURFACE_GROUP,
        };
        return;
      }
      const someAssigned = geometryTags.surfacesFromTagEntityGroupId(tagId)?.some((surfaceId) => (
        !surfacePool.has(surfaceId)
      ));
      if (someAssigned) {
        state[tagId] = {
          disabled: true,
          type: NodeType.SURFACE_GROUP,
        };
      }
    });

    return state;
  }, [id, entityGroupData, geometryTags, simParam, staticVolumes]);

  /** Memoize the nodeFilter object passed to NodeSubselect to avoid infinite looping */
  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (nodeType !== NodeType.SURFACE && nodeType !== NodeType.SURFACE_GROUP) {
      return defaultNodeFilter(nodeType);
    }
    if (surfaceWarnings[nodeId]) {
      return {
        related: true,
        tooltip: surfaceWarnings[nodeId].tooltip,
        disabled: surfaceWarnings[nodeId].disabled,
      };
    }
    if (geometryTags.isTagId(nodeId)) {
      return {
        related: true,
        disabled: false,
      };
    }
    return defaultNodeFilter(nodeType);
  }, [surfaceWarnings, geometryTags]);

  const saveSurfaceIds = useCallback(async (surfaceIds: string[]) => saveBoundaryConditionAsync(
    (newParam) => {
      const newBoundaryCondition =
        findFluidBoundaryCondition(newParam, id) || findHeatBoundaryCondition(newParam, id);
      assert(!!newBoundaryCondition, 'No fluid boundary condition found');
      const expandedSurfaces = expandGroupsExcludingTags(entityGroupData, surfaceIds);
      const errors = assignSurfacesToBoundaryCondition(
        expandedSurfaces,
        newBoundaryCondition,
        newParam,
        geometryTags,
        staticVolumes,
        entityGroupData,
      );
      if (errors.length) {
        addError(errors);
      }
      return newBoundaryCondition.boundaryConditionName;
    },
  ), [entityGroupData, geometryTags, id, saveBoundaryConditionAsync, staticVolumes]);

  const setSurfacesIds = useCallback(async (surfaceIds: string[]) => {
    await saveSurfaceIds(surfaceIds);
  }, [saveSurfaceIds]);

  return {
    nodeIds,
    nodeFilter,
    setSurfacesIds,
  };
};
