// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { getOrCreateSolutionOutput } from '../../lib/simulationParamUtils';
import * as simulationpb from '../../proto/client/simulation_pb';

import { useWorkflowConfig } from './useWorkflowConfig';
/**
 * Model hook for managing the Solution Output data structure at the SimluationParam root that
 * exposes settings like iterations per output
 * @param projectId
 * @param workflowId
 * @param jobId
 * @param readOnly
 * @returns
 */
export const useSolutionOutput = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
) => {
  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  const solutionOutput = getOrCreateSolutionOutput(simParam);

  const setSolutionOutput = useCallback(
    async (value: simulationpb.Output) => saveParamAsync((newParam) => {
      newParam.output = value;
    }),
    [saveParamAsync],
  );

  return {
    solutionOutput,
    setSolutionOutput,
  };
};
