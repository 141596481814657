// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { colors } from '../../../../lib/designSystem';
import Form from '../../../Form';
import { createStyles, makeStyles } from '../../../Theme';

const useStyles = makeStyles(() => createStyles({
  circle: {
    background: colors.purple800,
    width: '20px',
    color: colors.purple50,
    fontSize: '12px',
    borderRadius: '10px',
  },
  text: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '20px',
    width: '20px',
  },
}), { name: 'CustomCount' });

interface CustomCountProps {
  // The number of custom values.
  count: number;
}

// Displays the number of custom values.
export const CustomCount = (props: CustomCountProps) => {
  const classes = useStyles();
  return (
    <Form.LabeledInput label="Custom">
      {props.count === 0 ?
        'None' : (
          <div className={classes.circle}>
            <div className={classes.text}>
              {props.count}
            </div>
          </div>
        )}
    </Form.LabeledInput>
  );
};
