// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import assert from '../../../../lib/assert';
import { RadioButtonOption } from '../../../../lib/componentTypes/form';
import {
  DEFAULT_FARFIELD_SCALE,
  boxCenter,
  diagonalLength,
  scaleModifier,
  validateFarfield,
} from '../../../../lib/farfieldUtils';
import { generateDefaultShape } from '../../../../lib/geometryUtils';
import { hideBoxWidget, hideCylinderWidget, hideHalfSphereWidget, hideSphereWidget, showBoxWidget, showCylinderWidget, showHalfSphereWidget, showSphereWidget, updateBoxWidgetState, updateCylinderWidgetState, updateHalfSphereWidgetState, updateSphereWidgetState } from '../../../../lib/lcvis/api';
import { LcvBoxWidgetState } from '../../../../lib/lcvis/classes/widgets/LcvClipBoxWidget';
import { LcvCylinderWidgetState } from '../../../../lib/lcvis/classes/widgets/LcvCylinderWidget';
import { LcvHalfSphereWidgetState } from '../../../../lib/lcvis/classes/widgets/LcvHalfSphereWidget';
import { LcvSphereWidgetState } from '../../../../lib/lcvis/classes/widgets/LcvSphereWidget';
import { lcvHandler } from '../../../../lib/lcvis/handler/LcvHandler';
import * as random from '../../../../lib/random';
import * as rpc from '../../../../lib/rpc';
import { addRpcError } from '../../../../lib/transientNotification';
import { debounce } from '../../../../lib/utils';
import * as geometryservicepb from '../../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import { Vector3 } from '../../../../proto/base/base_pb';
import * as shapepb from '../../../../proto/cad/shape_pb';
import * as geometrypb from '../../../../proto/geometry/geometry_pb';
import * as meshgenerationpb from '../../../../proto/meshgeneration/meshgeneration_pb';
import {
  DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE,
  GeometryState,
  createOrUpdateFeature,
  useGeometryState,
  useSetGeometrySelectedFeature,
  useSetGeometryState,
} from '../../../../recoil/geometry/geometryState';
import { useLcVisReadyValue } from '../../../../recoil/lcvis/lcvisReadyState';
import { useCadMetadata } from '../../../../recoil/useCadMetadata';
import { CadModifier } from '../../../../recoil/useCadModifier';
import Form from '../../../Form';
import { RadioButtonGroup } from '../../../Form/RadioButtonGroup';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { ScaleButtons } from '../../../controls/ScaleButtons';
import { SectionMessage } from '../../../notification/SectionMessage';
import { CubePanel, CylinderPanel, HalfSpherePanel, SpherePanel, getFarFieldOptions } from '../FarField';

import GeometryModificationPanelFooter from './GeometryModificationPanelFooter';
import { EditModificationMessage } from './GeometryModificationShared';

function featureFromModifier(
  modifier: CadModifier,
  featureId: string,
  featureName: string,
  isFarfield: boolean,
) {
  assert(!!modifier, 'Missing modifier');
  const create = new geometrypb.Create();
  switch (modifier.farField.case) {
    case 'sphere':
      create.shape = { case: 'sphere', value: modifier.farField.value };
      break;
    case 'halfSphere':
      create.shape = { case: 'halfSphere', value: modifier.farField.value };
      break;
    case 'cube':
      create.shape = { case: 'box', value: modifier.farField.value };
      break;
    case 'cylinder':
      create.shape = { case: 'cylinder', value: modifier.farField.value };
      break;
    default:
      throw Error('Not supported');
  }
  if (!isFarfield) {
    return new geometrypb.Feature({
      id: featureId,
      featureName,
      operation: { case: 'create', value: create },
    });
  }
  return new geometrypb.Feature({
    id: featureId,
    featureName,
    operation: {
      case: 'farfield',
      value: new geometrypb.Farfield({
        create,
      }),
    },
  });
}

function createToUserGeometryMod(
  create: geometrypb.Create | undefined,
): meshgenerationpb.UserGeometryMod | undefined {
  switch (create?.shape.case) {
    case 'box': {
      return new meshgenerationpb.UserGeometryMod({
        farField: {
          case: 'cube',
          value: create.shape.value,
        },
      });
    }
    case 'cylinder':
    case 'sphere':
    case 'halfSphere': {
      return new meshgenerationpb.UserGeometryMod({
        farField: {
          case: create.shape.case,
          value: create.shape.value,
        },
      });
    }
    case undefined:
      return undefined;
    default:
      throw Error('Not supported');
  }
}

// A panel displaying all the settings for the generation of shapes and farfield operations in a
// geometry.
export const GeometryModificationShapePropPanel = () => {
  const { projectId, geometryId, readOnly } = useProjectContext();
  const { selectedNode: node, setSelection } = useSelectionContext();
  const setSelectedFeature = useSetGeometrySelectedFeature(geometryId);
  const geometryState = useGeometryState(projectId, geometryId);
  const setGeometryState = useSetGeometryState(projectId, geometryId);
  const [cadMetadata] = useCadMetadata(projectId);
  assert(!!node, 'No selected geometry modification shape row');
  const featureMod = useMemo(() => geometryState?.geometryFeatures.find(
    (feat) => feat.id === node.id,
  ), [geometryState, node.id]);
  const isFarfield = featureMod?.operation.case === 'farfield';
  const shapeValue = (featureMod?.operation.value as geometrypb.Farfield)?.create?.shape?.case;
  // Keep track of who last edited the box, so that we don't get in a loop of
  // updating the box widget.
  const editSource = useRef<'lcvis' | 'form'>('lcvis');

  const lcvisReady = useLcVisReadyValue();
  assert(lcvisReady, 'The visualizer is not ready');

  // Define the radio buttons for the farfield.
  const shapeOptions: RadioButtonOption<string>[] = [
    { label: 'Sphere', value: 'sphere' },
    { label: 'Half Sphere', value: 'halfSphere' },
    { label: 'Box', value: 'box' },
    { label: 'Cylinder', value: 'cylinder' },
  ];

  const getCurrentModifier = (geoState: GeometryState | undefined, id: string) => {
    if (geoState === undefined) {
      return undefined;
    }
    const mod = geoState.geometryFeatures.find((feature) => feature.id === id);
    if (mod === undefined) {
      return undefined;
    }
    const create = mod.operation.case === 'farfield' ?
      mod.operation.value.create : mod.operation.value as geometrypb.Create;
    return createToUserGeometryMod(create);
  };

  // Current modifier is the CAD modifier that is current in the settings. Edit
  // modifier is the one we are editing. It will go into the settings if save
  // is clicked. It is discarded if cancel is clicked.
  const currModifier = getCurrentModifier(geometryState, node.id);
  const initialized = currModifier !== undefined;
  const bBox = cadMetadata.boundingBox;
  const center = boxCenter(bBox);
  const radius = DEFAULT_FARFIELD_SCALE * diagonalLength(bBox);
  const newModifier = new meshgenerationpb.UserGeometryMod({
    farField: { case: 'sphere', value: new shapepb.Sphere({ center, radius }) },
  });

  const [editModifier, setEditModifier] = useState<CadModifier>(
    initialized ? currModifier : newModifier,
  );
  const modifier = editModifier || currModifier;
  const inEditMode = !!editModifier;
  const commonClasses = useCommonTreePropsStyles();

  // Apply a changeMod function to the edit modifier. This sets the shape.
  const setShape = useCallback((
    changeMod: (oldMod: meshgenerationpb.UserGeometryMod) => void,
    fromLcvis: boolean = false,
  ) => {
    editSource.current = fromLcvis ? 'lcvis' : 'form';
    const newMod = editModifier!.clone();
    changeMod(newMod);
    setEditModifier(newMod);
    const feature = featureFromModifier(newMod, node.id, node.name, isFarfield);
    setGeometryState((oldGeometryState) => {
      if (oldGeometryState === undefined) {
        return oldGeometryState;
      }
      const newGeometryState = { ...oldGeometryState };
      newGeometryState.geometryFeatures = newGeometryState.geometryFeatures.map((featureNew) => {
        if (featureNew.id === node.id) {
          return feature;
        }
        return featureNew;
      });
      return newGeometryState;
    });
  }, [setEditModifier, editModifier, node.id, setGeometryState, node.name, isFarfield]);

  // Respond to changes in the radio buttons for the Far-Field.
  const onShapeChange = (newShapeValueIn: string) => {
    const newShapeValue = newShapeValueIn as Partial<geometrypb.Create['shape']['case']>;
    setShape((oldMod: meshgenerationpb.UserGeometryMod) => {
      const create = new geometrypb.Create();
      create.shape = { case: newShapeValue } as any;
      generateDefaultShape(cadMetadata, create);
      const newMod = createToUserGeometryMod(create) || oldMod;
      oldMod.farField = newMod.farField;
    });
  };

  // Determine which parameter panel to display.
  let parameterPanel: ReactNode;
  let shapeType: 'sphere' | 'halfSphere' | 'cube' | 'cylinder' | undefined;
  if (modifier?.farField.case === 'sphere') {
    shapeType = 'sphere';
    parameterPanel = (
      <SpherePanel
        readOnly={!inEditMode || readOnly}
        setSphere={(sphere) => setShape((newOp) => {
          newOp.farField = { case: 'sphere', value: sphere };
        })}
        sphere={modifier.farField.value}
      />
    );
  } else if (modifier?.farField.case === 'halfSphere') {
    shapeType = 'halfSphere';
    parameterPanel = (
      <HalfSpherePanel
        halfSphere={modifier.farField.value}
        readOnly={!inEditMode || readOnly}
        setHalfSphere={(halfSphere) => setShape((newOp) => {
          newOp.farField = { case: 'halfSphere', value: halfSphere };
        })}
      />
    );
  } else if (modifier?.farField.case === 'cube') {
    shapeType = 'cube';
    parameterPanel = (
      <CubePanel
        cube={modifier.farField.value}
        readOnly={!inEditMode || readOnly}
        setCube={(cube) => setShape((newOp) => {
          newOp.farField = { case: 'cube', value: cube };
        })}
      />
    );
  } else if (modifier?.farField.case === 'cylinder') {
    shapeType = 'cylinder';
    parameterPanel = (
      <CylinderPanel
        cylinder={modifier.farField.value}
        readOnly={!inEditMode || readOnly}
        setCylinder={
          (cylinder) => setShape((newOp) => {
            newOp.farField = { case: 'cylinder', value: cylinder };
          })
        }
      />
    );
  }

  const applyScale = (scale: number) => {
    setShape((mod) => scaleModifier(scale, mod));
  };

  // Saves the edit modifier as the current modifier and create a request to update the geometry.
  const onGeoModShapeSave = async () => {
    if (!editModifier) {
      return;
    }

    assert(!!rpc.clientGeometry, 'Missing geometry RPC client');
    assert(!!geometryId, 'Missing geometry ID');
    const feature = featureFromModifier(editModifier, node.id, node.name, isFarfield);
    const modType = createOrUpdateFeature(geometryState, node.id);
    const modification = new geometrypb.Modification({
      feature,
      modType: modType === geometrypb.Modification_ModificationType.CREATE_FEATURE ?
        geometrypb.Modification_ModificationType.CREATE_FEATURE :
        geometrypb.Modification_ModificationType.UPDATE_FEATURE,
    });
    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification,
    });
    rpc.clientGeometry.modifyGeometry(req).catch((err) => addRpcError(`Server error ${err}`, err));

    // Focus out of the panel, to avoid weird rerenderings when calling setSelection.
    setSelectedFeature(DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE);

    // Imposter updates are triggered by setting the state inside ParaviewManager.
    setEditModifier(null);

    // Remove the focus on the panel, the geometry being displayed may not be linked to the
    // modification.
    setSelection([]);
  };

  const errors = validateFarfield(editModifier);

  // Add the far field when the component mounts.
  useEffect(() => {
    if (editModifier) {
      const farFieldOptions = getFarFieldOptions(editModifier, true);
      // If we can edit this shape using an LCVis widget, don't add the far-field
      // since we'll display the widget instead
      if (shapeType !== 'cube' && shapeType !== 'sphere' && shapeType !== 'cylinder' &&
        shapeType !== 'halfSphere') {
        lcvHandler.queueDisplayFunction('add far field', (display) => {
          display.workspace?.addFarField(farFieldOptions).catch((error) => console.warn(error));
        });
      } else {
        lcvHandler.queueDisplayFunction('remove far field', (display) => {
          display.workspace?.removeFarField().catch((error) => console.warn(error));
        });
      }
    }
  }, [shapeType, editModifier]);

  // Remove the far field and hide any displayed widgets whenever the component unmounts
  useEffect(() => () => {
    lcvHandler.queueDisplayFunction('remove far field', (display) => {
      display.workspace?.removeFarField().catch((error) => console.warn(error));
    });
    hideBoxWidget();
    hideSphereWidget();
    hideCylinderWidget();
    hideHalfSphereWidget();
  }, []);

  const debouncedSetShape = useMemo(() => debounce(setShape, 25), [setShape]);

  // If the shape is one supported by LCVis 3D widgets, use the corresponding LCVis widget
  useEffect(() => {
    if (shapeType === 'cube') {
      showBoxWidget((newState: LcvBoxWidgetState) => {
        const { center: cn, size } = newState;
        const formattedCenter = {
          x: cn[0],
          y: cn[1],
          z: cn[2],
        };
        const formattedSize = {
          x: size[0],
          y: size[1],
          z: size[2],
        };
        debouncedSetShape((mod: meshgenerationpb.UserGeometryMod) => {
          if (mod.farField.case === 'cube') {
            mod.farField.value.min = new Vector3({
              x: formattedCenter.x - formattedSize.x / 2,
              y: formattedCenter.y - formattedSize.y / 2,
              z: formattedCenter.z - formattedSize.z / 2,
            });
            mod.farField.value.max = new Vector3({
              x: formattedCenter.x + formattedSize.x / 2,
              y: formattedCenter.y + formattedSize.y / 2,
              z: formattedCenter.z + formattedSize.z / 2,
            });
          }
        }, true);
      });
    } else {
      hideBoxWidget();
    }

    if (shapeType === 'sphere') {
      showSphereWidget((newState: LcvSphereWidgetState) => {
        const { center: sphereCenter, radius: sphereRadius } = newState;
        debouncedSetShape((mod: meshgenerationpb.UserGeometryMod) => {
          if (mod.farField.case === 'sphere') {
            mod.farField.value.center = new Vector3({
              x: sphereCenter[0],
              y: sphereCenter[1],
              z: sphereCenter[2],
            });
            mod.farField.value.radius = sphereRadius;
          }
        }, true);
      });
    } else {
      hideSphereWidget();
    }

    if (shapeType === 'cylinder') {
      showCylinderWidget((newState: LcvCylinderWidgetState) => {
        const { start, end, radius: cylRadius } = newState;
        debouncedSetShape((mod: meshgenerationpb.UserGeometryMod) => {
          if (mod.farField.case === 'cylinder') {
            mod.farField.value.start = new Vector3({
              x: start[0],
              y: start[1],
              z: start[2],
            });
            mod.farField.value.end = new Vector3({
              x: end[0],
              y: end[1],
              z: end[2],
            });
            mod.farField.value.radius = cylRadius;
          }
        }, true);
      });
    } else {
      hideCylinderWidget();
    }

    if (shapeType === 'halfSphere') {
      showHalfSphereWidget((newState: LcvHalfSphereWidgetState) => {
        const { center: hsCenter, normal: hsNormal, radius: hsRadius } = newState;
        debouncedSetShape((mod: meshgenerationpb.UserGeometryMod) => {
          if (mod.farField.case === 'halfSphere') {
            mod.farField.value.center = new Vector3({
              x: hsCenter[0],
              y: hsCenter[1],
              z: hsCenter[2],
            });
            mod.farField.value.normal = new Vector3({
              x: hsNormal[0],
              y: hsNormal[1],
              z: hsNormal[2],
            });
            mod.farField.value.radius = hsRadius;
          }
        }, true);
      });
    } else {
      hideHalfSphereWidget();
    }
  }, [shapeType, debouncedSetShape]);

  // Update the LCVis widget when the user edits it in the property panel, this is
  // when the edit source is 'form' and not lcvis. When edit source is LCVis it means
  // the user modified it using the 3D widget inside LCVis and LCVis told us about
  // the change
  const mounted = useRef(false);
  useEffect(() => {
    // Make sure this runs when mounting.
    if (editSource.current === 'form' || !mounted.current) {
      mounted.current = true;
      if (shapeType === 'cube' &&
        editModifier?.farField.case === 'cube'
      ) {
        const { min, max } = editModifier.farField.value;
        if (!min || !max) {
          return;
        }
        updateBoxWidgetState({
          center: [(min.x + max.x) / 2, (min.y + max.y) / 2, (min.z + max.z) / 2],
          size: [max.x - min.x, max.y - min.y, max.z - min.z],
          rotation: [0, 0, 0],
        });
      } else if (shapeType === 'sphere' &&
        editModifier?.farField.case === 'sphere'
      ) {
        const { center: sphereCenter, radius: sphereRadius } = editModifier.farField.value;
        if (!sphereCenter || !sphereRadius) {
          return;
        }
        updateSphereWidgetState({
          center: [sphereCenter.x, sphereCenter.y, sphereCenter.z],
          radius: sphereRadius,
        });
      } else if (shapeType === 'cylinder' &&
        editModifier?.farField.case === 'cylinder'
      ) {
        const { start, end, radius: cylRadius } = editModifier.farField.value;
        if (!start || !end || !cylRadius) {
          return;
        }
        updateCylinderWidgetState({
          start: [start.x, start.y, start.z],
          end: [end.x, end.y, end.z],
          radius: cylRadius,
        });
      } else if (shapeType === 'halfSphere' &&
        editModifier?.farField.case === 'halfSphere'
      ) {
        const { center: hsCenter, normal: hsNormal, radius: hsRadius } =
          editModifier.farField.value;
        if (!hsCenter || !hsNormal || !hsRadius) {
          return;
        }
        updateHalfSphereWidgetState({
          center: [hsCenter.x, hsCenter.y, hsCenter.z],
          normal: [hsNormal.x, hsNormal.y, hsNormal.z],
          radius: hsRadius,
        });
      }
    }
  }, [editModifier, shapeType, editSource]);

  return (
    <div className={commonClasses.properties}>
      <EditModificationMessage nodeId={node.id} />
      {isFarfield && (
        <Form.LabeledInput
          help=""
          label="Shape">
          <RadioButtonGroup
            disabled={!inEditMode}
            kind="secondary"
            name="shapes"
            onChange={onShapeChange}
            options={shapeOptions}
            value={shapeValue}
          />
        </Form.LabeledInput>
      )}
      {parameterPanel}
      {!(shapeType === 'sphere' || shapeType === 'halfSphere') && (
        <Form.LabeledInput
          help="Scale the Parameters Up or Down"
          label="Scale">
          <ScaleButtons
            disabled={!inEditMode}
            onClick={applyScale}
            scaleValues={[0.5, 2]}
          />
        </Form.LabeledInput>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          justifyContent: 'center',
          marginTop: '10px',
        }}>
        {errors.map((error) => (
          <SectionMessage
            key={error}
            level="error"
            message={error}
            title="Error in shape settings"
          />
        ))}
      </div>
      <GeometryModificationPanelFooter
        featureId={node.id}
        onModificationSave={onGeoModShapeSave}
      />
    </div>
  );
};
