// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 28;
const baseHeight = 30;
export const CubeOutlineIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="cubeOutline"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M14,-0
           L0.073,6.906
           L0.073,23.094
           L14,30
           L14.477,29.768
           L27.927,23.094
           L27.927,6.906
           L14,-0Z
           M14,2.374
           L23.976,7.33
           L14,12.725
           L4.024,7.33
           L14,2.374Z
           M2.216,8.774
           L12.929,14.568
           L12.929,27.095
           L2.216,21.782
           L2.216,8.774Z
           M25.784,8.774
           L25.784,21.782
           L15.071,27.095
           L15.071,14.568
           L25.784,8.774Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
