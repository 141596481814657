// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo, useRef, useState } from 'react';

import * as ProtoDescriptor from '../../../ProtoDescriptor';
import { newAdFloat } from '../../../lib/adUtils';
import { CommonMenuItem } from '../../../lib/componentTypes/menu';
import { getCompatibleTablesMap } from '../../../lib/rectilinearTable/globalMap';
import { TableDefinition } from '../../../lib/rectilinearTable/model';
import * as basepb from '../../../proto/base/base_pb';
import { Metadata } from '../../../proto/table/table_pb';
import useTableState from '../../../recoil/tableState';
import { ActionButton } from '../../Button/ActionButton';
import Form from '../../Form';
import { CommonMenu } from '../../Menu/CommonMenu';
import { ParamFieldInput } from '../../ParamFieldInput';
import { useProjectContext } from '../../context/ProjectContext';
import { RectilinearTableDialog, RectilinearTableDialogProps } from '../../dialog/RectilinearTable';
import { useSimulationConfig } from '../../hooks/useSimulationConfig';
import { FixedSizeWrapper } from '../../layout/FixedSizeWrapper';
import { TableOutlinedIcon } from '../../svg/TableOutlinedIcon';
import { Flex } from '../../visual/Flex';

import { SelectedColumnInput } from './SelectedColumnInput';

import { TableMapConfig, TableMapInputProps } from '.';

export interface NumberAndTableInputProps {
  param: ProtoDescriptor.Param;
  label?: string;

  value: basepb.AdFloatType;
  setValue: (newValue: any) => void;

  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (newValue: number) => void;
  onCommit?: (newValue: basepb.AdFloatType) => void;

  /** Metadata for the selected table */
  linkedTable?: Metadata;
  unlinkTable: () => void;

  title: string;
  subtitle?: RectilinearTableDialogProps['subtitle'];
  tableDefinition: TableDefinition;
  tableErrorFunc?: RectilinearTableDialogProps['tableErrorFunc'];
  tableNameErrorFunc: TableMapConfig['nameErrorFunc'];
  tableOnChange: TableMapInputProps['onChange'];

  readOnly?: boolean;
}

export function NumberAndTableInput(props: NumberAndTableInputProps) {
  const {
    param,
    label,
    value,
    setValue,
    onFocus,
    onBlur,
    onChange,
    onCommit,
    linkedTable,
    unlinkTable,
    title,
    subtitle,
    tableDefinition,
    tableErrorFunc,
    tableNameErrorFunc,
    tableOnChange,
    readOnly,
  } = props;

  // Context
  const { projectId } = useProjectContext();

  const { simParam } = useSimulationConfig();

  const tableProto = useTableState(projectId, linkedTable?.url || null);

  // States
  const [dialogOpen, setDialogOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const rowRef = useRef<HTMLDivElement | null>(null);

  const tableMap = useMemo(
    () => getCompatibleTablesMap(simParam, tableDefinition),
    [simParam, tableDefinition],
  );
  const hasTables = tableMap.size > 0;

  const menuItems: CommonMenuItem[] = useMemo(() => {
    const items: CommonMenuItem[] = [];
    items.push(...Array.from(tableMap).map(([tableName]) => ({
      label: tableName,
      onClick: () => {
        setMenuOpen(false);
        tableOnChange({ type: 'assign', name: tableName });
      },
    })));

    items.push(
      { separator: true },
      {
        label: 'Upload Table',
        startIcon: { name: 'diskArrowUp' },
        onClick: () => {
          setMenuOpen(false);
          setDialogOpen(true);
        },
      },
    );
    return items;
  }, [tableMap, tableOnChange]);

  const selectedTableName = useMemo(() => {
    let result = '';
    if (linkedTable) {
      Array.from(tableMap).forEach(([tableName, table]) => {
        if (table.url === linkedTable.url) {
          result = tableName;
        }
      });
    }
    return result;
  }, [linkedTable, tableMap]);

  return (
    <>
      <Form.LabeledInput label={label ?? ''}>
        {tableProto ? (
          <SelectedColumnInput
            columnIndex={1}
            columnName={selectedTableName}
            hideIcon
            hideQuantity
            param={param}
            table={tableProto}
            unlinkColumn={unlinkTable}
            unlinkTooltip="Unlink file"
          />
        ) : (
          <div ref={rowRef}>
            <Flex gap={8}>
              <div style={{ width: '100%' }}>
                <ParamFieldInput
                  onBlur={onBlur}
                  onChange={onChange}
                  onCommit={(newValue: number) => {
                    const newFloatValue = newAdFloat(newValue);
                    setValue(newFloatValue);
                    onCommit?.(newFloatValue);
                  }}
                  onFocus={onFocus}
                  param={param}
                  readOnly={!!readOnly}
                  setValue={setValue}
                  value={value}
                />
              </div>
              <ActionButton
                disabled={readOnly}
                kind="secondary"
                onClick={() => (hasTables ? setMenuOpen(true) : setDialogOpen(true))}
                size="small">
                <FixedSizeWrapper height={12} width={12}>
                  <TableOutlinedIcon />
                </FixedSizeWrapper>
              </ActionButton>
            </Flex>
          </div>
        )}
      </Form.LabeledInput>
      <RectilinearTableDialog
        disableHelp
        nameErrorFunc={tableNameErrorFunc}
        onClose={() => {
          setDialogOpen(false);
        }}
        onSubmit={(name, metadata) => {
          tableOnChange({ type: 'create-assign', name, metadata });
          setDialogOpen(false);
        }}
        open={dialogOpen && !readOnly}
        projectId={projectId}
        subtitle={subtitle}
        tableDefinition={tableDefinition}
        tableErrorFunc={tableErrorFunc}
        title={title}
        uploadOptions={{ inputAccept: '.csv' }}
      />
      <CommonMenu
        anchorEl={rowRef.current}
        menuItems={menuItems}
        onClose={() => setMenuOpen(false)}
        open={menuOpen}
        position="below-left"
        positionTransform={{ top: 4 }}
      />
    </>
  );
}
