// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, useCallback } from 'react';

import cx from 'classnames';

import { isUnmodifiedEnterKey } from '../../../lib/event';
import Tooltip from '../../Tooltip';

import './DotToggle.scss';

const RADIUS_PX = 8;
const DOT_RADIUS_PX = 3;
const BORDER_THICKNESS_PX = 1;

export interface DotToggleProps {
  value: boolean;
  onChange?: (value: boolean) => void;
  // Whether or not the toggle's border should be visible.
  borderVisible: boolean;
  tooltipTitle: string;
}

/**
 * DotToggle - a control component used to represent and change boolean states.
 * */
export const DotToggle = (props: DotToggleProps) => {
  const { borderVisible, onChange, value } = props;

  const rootStyle = {
    '--radius': `${RADIUS_PX}px`,
    '--border-thickness': `${BORDER_THICKNESS_PX}px`,
    '--dot-radius': `${DOT_RADIUS_PX}px`,
  } as CSSProperties;

  // No part of this code calls onChange directly; announceValue is called
  // instead to make sure onChange is only called when appropriate.
  const announceValue = useCallback(
    (newValue: boolean) => {
      if (onChange && (newValue !== value)) {
        onChange(newValue);
      }
    },
    [onChange, value],
  );

  const toggleValue = () => {
    announceValue(!value);
  };

  const handleRootClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    toggleValue();
  };

  const handleRootKeyDown = (event: React.KeyboardEvent) => {
    if (isUnmodifiedEnterKey(event)) {
      event.preventDefault();
    }
  };

  const handleRootKeyUp = (event: React.KeyboardEvent) => {
    if (isUnmodifiedEnterKey(event)) {
      toggleValue();
    }
  };

  const a11yRootProps = {
    onClick: handleRootClick,
    onKeyDown: handleRootKeyDown,
    onKeyUp: handleRootKeyUp,
    role: 'switch',
    tabIndex: 0,
  };

  return (
    <Tooltip arrow={false} placement="left" title={props.tooltipTitle}>
      <div
        aria-checked={value}
        className={cx('dotToggle', { on: value, borderVisible })}
        style={rootStyle}
        {...a11yRootProps}>
        <div className="dot" />
      </div>
    </Tooltip>
  );
};
