// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

// Track the expanded/collapsed state of panels, keyed by nodeId/panelName, and persist in
// sessionStorage

import { shallowEqual } from 'fast-equals';
import { atom, useAtom } from 'jotai';
import { atomFamily, atomWithStorage, createJSONStorage } from 'jotai/utils';

// #region nodeExpandedPanels

export type NodeId = string;
export type PanelName = string;

// The PanelsState type represents a { panel-name <=> expanded }  mapping
export type PanelsState = Record<PanelName, boolean>;
// The NodePanelsState type represents panel states for individual node IDs
export type NodePanelsState = Record<NodeId, PanelsState>;

export const STORAGE_KEY = 'nodePanelsState';
export const DEFAULT_STORE = {};

// Switch to sessionStorage instead of default localStorage
const storage = createJSONStorage<NodePanelsState>(() => sessionStorage);

const nodeExpandedPanelsStore = atomWithStorage<NodePanelsState>(
  STORAGE_KEY,
  DEFAULT_STORE,
  storage,
);

// Selector for all expanded panels, keyed by node ID
const nodeExpandedPanelsState = atomFamily((key: NodeId) => atom(
  (get) => {
    const store = get(nodeExpandedPanelsStore);
    return store[key] || {};
  },
  (get, set, setValue: PanelsState) => {
    const store = get(nodeExpandedPanelsStore);
    const newStore: NodePanelsState = { ...store };
    if (!Object.keys(setValue).length) {
      delete newStore[key];
    } else {
      newStore[key] = setValue;
    }
    set(nodeExpandedPanelsStore, newStore);
  },
));

// #region panel

export type PanelKey = {
  nodeId: NodeId;
  panelName: PanelName;
  defaultExpanded?: boolean;
}

// Selector for getting or setting a boolean (representing expanded) for an individual panel,
// keyed by { nodeID, panelName }
const panelState = atomFamily((key: PanelKey) => atom(
  (get) => {
    const { nodeId, panelName, defaultExpanded = false } = key;
    const panels = get(nodeExpandedPanelsState(nodeId));
    if (panelName in panels) {
      return panels[panelName];
    }
    return defaultExpanded;
  },
  // ivaylo: if we want to pass a setter function that accepts the previous value, e.g.
  // setPanel((old) => !old), we have to change the type for the newValue to something like
  // SetStateAction<boolean> and update the set below. This usually works for the other states, but
  // I couldn't make the types work here. For now, we can set the state by passing true/false only.
  (get, set, newValue: boolean) => {
    const { nodeId, panelName } = key;
    const panels = get(nodeExpandedPanelsState(nodeId));
    const newPanels = { ...panels };

    newPanels[panelName] = newValue;

    set(nodeExpandedPanelsState(nodeId), { ...newPanels });
  },
), shallowEqual);

export function usePanel(key: PanelKey) {
  return useAtom(panelState(key));
}
