// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { appendSlidingInterface } from '../../lib/slidingInterfaceUtils';
import { initializeNewNode, useSetNewNodes } from '../../recoil/nodeSession';
import { useSelectionContext } from '../context/SelectionManager';

import { useSimulationConfig } from './useSimulationConfig';

export const useSlidingInterfaceNodes = (physicsId: string) => {
  // == Contexts
  const { setSelection } = useSelectionContext();

  // == Recoil
  const setNewNodes = useSetNewNodes();

  // == Hooks
  const { saveParamAsync } = useSimulationConfig();

  const addSlidingInterface = async () => {
    const nodeId = await saveParamAsync(
      (newParam) => appendSlidingInterface(newParam, physicsId).slidingInterfaceId,
    );
    setSelection([nodeId]);
    setNewNodes((nodes) => [...nodes, initializeNewNode(nodeId)]);
  };

  return {
    addSlidingInterface,
  };
};
