// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import useFormValidation from '../../lib/useFormValidation';
import Form from '../Form';
import { TextInput } from '../Form/TextInput';
import { createStyles, makeStyles } from '../Theme';
import { AutoCollapsingMessage } from '../visual/AutoCollapsingMessage';

import { Dialog } from './Base';

const useStyles = makeStyles(
  () => createStyles({
    leftSide: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    inputs: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    input: {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
    },
  }),
  { name: 'CreateTagDialog' },
);

interface CreateTagMenuProps {
  // Whether the dialog is open.
  isOpen: boolean,
  // Cancels the dialog modifications.
  onCancel: () => void,
  // Callback used when the user hits on Apply .
  onSubmit: (name: string) => Promise<void>,
}

const CreateTagDialog = (props: CreateTagMenuProps) => {
  // == Props
  const { isOpen } = props;

  // == Hooks
  const classes = useStyles();

  // == State
  const [isComponentMounted, setComponentMounted] = useState(true);

  // == Data
  const [name, setName] = useState('');
  // Used to avoid calling multiple onContinue in quick succession.
  const [submitting, setSubmitting] = useState(false);

  const { validate, errors } = useFormValidation([{
    key: 'tagName',
    value: name,
    required: true,
  }]);

  useEffect(() => {
    setComponentMounted(true);

    // Return cleanup function to handle unmounting
    return () => {
      setComponentMounted(false);
    };
  }, []);

  return (
    <Dialog
      cancelButton={{ label: 'Cancel' }}
      continueButton={{
        label: 'Create',
        name: 'create-tag',
        disabled: !name,
      }}
      controlState={submitting ? 'working' : 'normal'}
      modal
      onClose={props.onCancel}
      onContinue={async () => {
        if (validate() && !submitting) {
          setSubmitting(true);

          try {
            await props.onSubmit(name);
          } catch (error: any) {
            if (isComponentMounted) {
              setSubmitting(false);
            }
            throw error;
          }

          if (isComponentMounted) {
            setSubmitting(false);
          }
        }
      }}
      open={isOpen}
      subtitle="Set the new tag name"
      title="Create a tag"
      width="600px">
      <div>
        <div className={classes.leftSide}>
          <div className={cx(classes.inputs)}>
            <div className={classes.input}>
              <Form.Label>Tag Name</Form.Label>
              <TextInput
                autoFocus
                dataPrivate
                disabled={submitting}
                faultType={errors.tagName ? 'error' : undefined}
                name="name"
                onChange={setName}
                placeholder="Tag Name"
                value={name}
              />
              <AutoCollapsingMessage level="error" message={errors.tagName} />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateTagDialog;
