// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { CurrentView } from '../lib/componentTypes/context';
import { RecoilProjectKey } from '../lib/persist';
import { isTestingEnv } from '../lib/testing/utils';
import { currentViewAtom_DEPRECATED } from '../state/internal/global/currentView';

import { meshMetadataSelector } from './meshState';
import { geometryPendingState } from './pendingWorkOrders';
import { activeVisUrlState } from './vis/activeVisUrl';

export const meshReadyStateAtom = atomFamily<boolean, RecoilProjectKey>({
  key: 'meshReadyStateAtom',
  default: selectorFamily<boolean, RecoilProjectKey>({
    key: 'meshReadyStateAtom/default',
    get: (key: RecoilProjectKey) => ({ get }) => {
      if (isTestingEnv()) {
        return true;
      }

      // To simplify, in the geometry mode we'll consider that the mesh is ready so that we can
      // show the tree.
      const currentView = get(currentViewAtom_DEPRECATED);
      if (currentView === CurrentView.GEOMETRY) {
        return true;
      }

      const [geometryPending, activeVisUrl] = get(waitForAll([
        geometryPendingState(key.projectId),
        activeVisUrlState(key),
      ]));

      const meshMetadata = get(meshMetadataSelector({
        projectId: key.projectId,
        meshUrl: activeVisUrl,
      }));

      return !!meshMetadata || geometryPending;
    },
  }),
});

/**
 * If meshReadyState is true, we will display all the panels and toolbars.
 * Otherwise, we are in the empty state where we upload a mesh and we show a limited UI.
 * Show meshReadyState early if upload streamline is enabled.
 */
export function useMeshReadyState(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(meshReadyStateAtom({ projectId, workflowId, jobId }));
}
