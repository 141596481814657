// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVArcballCameraWidgetSignal, LCVCameraResetMode, LCVObject, LCVType } from '@luminarycloudinternal/lcvis';

import { Bounds, LcvModule, LcvisCameraStateType, defaultLcvCamera } from '../../types';
import { LcvCamera } from '../LcvCamera';
import { LcvFrame } from '../LcvFrame';

import { LcvWidget } from './LcvWidget';

export class LcvArcballCameraWidget extends LcvWidget {
  frame: LcvFrame | null = null;
  camera: LcvCamera | null = null;
  hasCallback = false;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    frame: LcvFrame,
    camera: LcvCamera,
    initialState?: LcvisCameraStateType,
  ) {
    super(lcv, sessionHandle, 'arcball_camera');
    this.attachFrame(frame);
    this.setCamera(camera);
    if (initialState) {
      this.setCameraState(initialState);
    } else {
      this.setCameraState(defaultLcvCamera());
    }
    // Use the quaternion mode by default. The alternative is Paul'setParam
    // spherical camera mode that works like the blender camera.
    this.setParam(
      'camera_mode',
      LCVType.kLCVDataTypeUint,
      0,
    );
  }

  setCamera(camera: LcvCamera) {
    this.setParam('camera', LCVType.kLCVDataTypeCamera, camera.handle);
    this.camera = camera;
  }

  /**
   * Change the camera widget's parameters. The cameraState is what we store and
   * use to load the camera when the page reloads.
   */
  setCameraState(cameraState: LcvisCameraStateType) {
    Object.keys(cameraState).forEach((key) => {
      const value = cameraState[key as keyof LcvisCameraStateType];
      if (['position', 'target', 'up', 'pan'].includes(key) && Array.isArray(value)) {
        this.setParam(key, LCVType.kLCVDataTypeFloat3, value);
      } else if (['near_clip', 'far_clip', 'fov'].includes(key)) {
        this.setParam(key, LCVType.kLCVDataTypeFloat, value);
      }
    });
    this.setParam(
      'center_of_rotation_modifier',
      LCVType.kLCVDataTypeInt,
      cameraState.center_of_rotation_modifier,
    );
    this.setParam(
      'zoom_to_box_modifier',
      LCVType.kLCVDataTypeInt,
      cameraState.zoom_to_box_modifier,
    );
    this.setParam(
      'orthographic',
      LCVType.kLCVDataTypeUint,
      cameraState.orthographic ? 1 : 0,
    );
  }

  getCameraState(): LcvisCameraStateType {
    const state: LcvisCameraStateType = {
      position: this.getProperty('position', LCVType.kLCVDataTypeFloat3),
      target: this.getProperty('target', LCVType.kLCVDataTypeFloat3),
      up: this.getProperty('up', LCVType.kLCVDataTypeFloat3),
      look: this.getProperty('look', LCVType.kLCVDataTypeFloat3),
      pan: this.getProperty('pan', LCVType.kLCVDataTypeFloat3),
      fov: this.getProperty('fov', LCVType.kLCVDataTypeFloat),
      near_clip: this.getProperty('near_clip', LCVType.kLCVDataTypeFloat),
      far_clip: this.getProperty('far_clip', LCVType.kLCVDataTypeFloat),
      center_of_rotation_modifier: this.getProperty(
        'center_of_rotation_modifier',
        LCVType.kLCVDataTypeInt,
      ),
      zoom_to_box_modifier: this.getProperty(
        'zoom_to_box_modifier',
        LCVType.kLCVDataTypeInt,
      ),
      orthographic: !!this.getProperty('orthographic', LCVType.kLCVDataTypeUint),
      editSource: 'LCVis',
    };
    return state;
  }

  /** Attach a callback to the camera widget */
  setCallback(callback: null | ((camera: LcvisCameraStateType, message?: string) => void)) {
    if (!this.hasCallback) {
      const trueCallback = (_lcv: any, _session: LCVObject, obj: LCVObject, message: string) => {
        const newState = this.getCameraState();
        callback?.(newState, message);
      };
      this.setParam('camera_updated_callback', LCVType.kLCVDataTypeFunction, trueCallback);
    }
    this.hasCallback = true;
  }

  resetCamera() {
    this.setParam(
      'reset_camera',
      LCVType.kLCVDataTypeUint,
      LCVCameraResetMode.kLCVCameraResetModeVisibleBounds,
    );
  }

  /** Resets the camera immediately using the bounds currently available
  * in the workspace. This may not include the most up to date frame bounds
  * because it will happen prior to rendering the next frame.
  */
  resetCameraImmediate() {
    this.sendSignal(
      LCVArcballCameraWidgetSignal.kLCVArcballCameraWidgetSignalResetImmediate,
      LCVType.kLCVDataTypeUint,
      0,
    );
  }

  /** Zoom to a custom bounding box */
  zoomToBoundingBox(bounds: Bounds) {
    this.setParam(
      'reset_camera',
      LCVType.kLCVDataTypeUint,
      LCVCameraResetMode.kLCVCameraResetModeCustomBounds,
    );
    this.setParam('reset_custom_bounds', LCVType.kLCVDataTypeFloat3x2, bounds);
  }
}
