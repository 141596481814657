// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { MultipleChoiceParam } from '../ProtoDescriptor';
import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../SimulationParamDescriptor';
import * as entitypb from '../proto/client/entity_pb';
import * as simulationpb from '../proto/client/simulation_pb';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import { chainParamScopes, createParamScope } from './ParamScope';
import assert from './assert';
import { createIdentifier } from './baseParamUtils';
import { AnyBoundaryCondition } from './boundaryConditionUtils';
import { IconName } from './componentTypes/svgIcon';
import { MAX_HEAT_PHYSICS, MAX_PHYSICS } from './constants';
import { findPhysicsIdByDomain, getPhysicsDomains, getPhysicsMaterialIds } from './entityRelationships';
import { initParamGroupProto } from './initParam';
import { intersects, isSuperset } from './lang';
import { getMaterialId, isMaterialFluid, isMaterialSolid } from './materialUtils';
import { uniqueSequenceName } from './name';
import { conjugateIs } from './text';
import { surfacesFromVolumes } from './volumeUtils';

export type PhysicsType = simulationpb.Physics['params']['case'];
export type ConfigurablePhysicsType = 'fluid' | 'heat';

export type SubPhysics = simulationpb.Fluid | simulationpb.Heat;

const {
  CONSERVATIVE_SPATIAL_DISCRETIZATION_FLUID,
  CUSTOM_SPATIAL_DISCRETIZATION_FLUID,
  DEFAULT_SPATIAL_DISCRETIZATION_FLUID,
  HIGH_ACCURACY_SPATIAL_DISCRETIZATION_FLUID,
} = simulationpb.SpatialDiscretizationFluidPreset;
const {
  AGGRESSIVE_SOLUTION_CONTROLS_FLUID,
  CUSTOM_SOLUTION_CONTROLS_FLUID,
  DEFAULT_SOLUTION_CONTROLS_FLUID,
  INTERMEDIATE_SOLUTION_CONTROLS_FLUID,
} = simulationpb.SolutionControlsFluidPreset;

const {
  CONSERVATIVE_SPATIAL_DISCRETIZATION_HEAT,
  CUSTOM_SPATIAL_DISCRETIZATION_HEAT,
  DEFAULT_SPATIAL_DISCRETIZATION_HEAT,
  HIGH_ACCURACY_SPATIAL_DISCRETIZATION_HEAT,
} = simulationpb.SpatialDiscretizationHeatPreset;
const {
  AGGRESSIVE_SOLUTION_CONTROLS_HEAT,
  CUSTOM_SOLUTION_CONTROLS_HEAT,
  DEFAULT_SOLUTION_CONTROLS_HEAT,
  INTERMEDIATE_SOLUTION_CONTROLS_HEAT,
} = simulationpb.SolutionControlsHeatPreset;

// The set of fluid physics spatial discretization presets that users may assign.
const AVAILABLE_SPATIAL_DISCRETIZATION_PRESETS: simulationpb.SpatialDiscretizationFluidPreset[] = [
  CONSERVATIVE_SPATIAL_DISCRETIZATION_FLUID,
  CUSTOM_SPATIAL_DISCRETIZATION_FLUID,
  DEFAULT_SPATIAL_DISCRETIZATION_FLUID,
  HIGH_ACCURACY_SPATIAL_DISCRETIZATION_FLUID,
];

// The set of fluid physics spatial discretization presets that users may assign.
const AVAILABLE_SOLUTION_CONTROLS_PRESETS: simulationpb.SolutionControlsFluidPreset[] = [
  AGGRESSIVE_SOLUTION_CONTROLS_FLUID,
  CUSTOM_SOLUTION_CONTROLS_FLUID,
  DEFAULT_SOLUTION_CONTROLS_FLUID,
  INTERMEDIATE_SOLUTION_CONTROLS_FLUID,
];

// The set of heat physics spatial discretization presets that users may assign.
type HeatSpatDiscPreset = simulationpb.SpatialDiscretizationHeatPreset;
const AVAILABLE_SPATIAL_DISCRETIZATION_PRESETS_HEAT: HeatSpatDiscPreset[] = [
  CONSERVATIVE_SPATIAL_DISCRETIZATION_HEAT,
  CUSTOM_SPATIAL_DISCRETIZATION_HEAT,
  DEFAULT_SPATIAL_DISCRETIZATION_HEAT,
  HIGH_ACCURACY_SPATIAL_DISCRETIZATION_HEAT,
];

// The set of heat physics spatial discretization presets that users may assign.
const AVAILABLE_SOLUTION_CONTROLS_PRESETS_HEAT: simulationpb.SolutionControlsHeatPreset[] = [
  AGGRESSIVE_SOLUTION_CONTROLS_HEAT,
  CUSTOM_SOLUTION_CONTROLS_HEAT,
  DEFAULT_SOLUTION_CONTROLS_HEAT,
  INTERMEDIATE_SOLUTION_CONTROLS_HEAT,
];

export const SPATIAL_DISCRETIZATION_PRESET_CHOICES = (
  paramDesc[ParamName.SpatialDiscretizationFluidPreset] as MultipleChoiceParam
).choices.filter((choice) => AVAILABLE_SPATIAL_DISCRETIZATION_PRESETS.includes(choice.enumNumber));

export const SOLUTION_CONTROLS_PRESET_CHOICES = (
  paramDesc[ParamName.SolutionControlsFluidPreset] as MultipleChoiceParam
).choices.filter((choice) => AVAILABLE_SOLUTION_CONTROLS_PRESETS.includes(choice.enumNumber));

export const SPATIAL_DISCRETIZATION_PRESET_CHOICES_HEAT = (
  paramDesc[ParamName.SpatialDiscretizationHeatPreset] as MultipleChoiceParam
).choices.filter(
  (choice) => AVAILABLE_SPATIAL_DISCRETIZATION_PRESETS_HEAT.includes(choice.enumNumber),
);

export const SOLUTION_CONTROLS_PRESET_CHOICES_HEAT = (
  paramDesc[ParamName.SolutionControlsHeatPreset] as MultipleChoiceParam
).choices.filter((choice) => AVAILABLE_SOLUTION_CONTROLS_PRESETS_HEAT.includes(choice.enumNumber));

const SUBNODE_ID_SEPARATOR = '//';

/**
 * Forms a unique ID for the singleton volume selection under a physics
 * @param physicsId
 * @returns string
 */
export function makeVolumeSelectionId(physicsId: string): string {
  return [physicsId, 'volume-selection'].join(SUBNODE_ID_SEPARATOR);
}

/**
 * Forms a unique ID for the physical models seection under a physics
 * @param physicsId
 * @returns string
 */
export function makePhysicalModelsId(physicsId: string): string {
  return [physicsId, 'physical-models'].join(SUBNODE_ID_SEPARATOR);
}

/**
 * Forms a unique ID for the heat sources container under a physics
 * @param physicsId
 * @returns string
 */
export function makeHeatSourcesId(physicsId: string): string {
  return [physicsId, 'heat-sources'].join(SUBNODE_ID_SEPARATOR);
}

/**
 * Forms a unique ID for the boundary conditions container under a physics
 * @param physicsId
 * @returns string
 */
export function makeBoundaryConditionsId(physicsId: string): string {
  return [physicsId, 'boundary-conditions'].join(SUBNODE_ID_SEPARATOR);
}

/**
 * Forms a unique ID for the singleton initialization under a physics
 * @param physicsId
 * @returns string
 */
export function makeInitializationId(physicsId: string): string {
  return [physicsId, 'initialization'].join(SUBNODE_ID_SEPARATOR);
}

/**
 * Extract a physics ID from a sub node ID generated by one of the make* functions above
 * @param id
 * @returns string
 */
export function parsePhysicsIdFromSubId(id: string): string {
  return id.split(SUBNODE_ID_SEPARATOR)[0];
}

/**
 * Returns type-based prefix for naming a new physics
 */
export function getTypeLabel(type: PhysicsType) {
  switch (type) {
    case 'fluid': {
      return 'Fluid Flow';
    }
    case 'heat': {
      return 'Heat Transfer in Solid';
    }
    default:
      throw Error('Invalid physics type');
  }
}

export function getTypeIconName(type: PhysicsType): IconName {
  switch (type) {
    case 'fluid': {
      return 'wavesTriple';
    }
    case 'heat': {
      return 'thermometer';
    }
    default: {
      return 'atom';
    }
    // no default
  }
}

/**
 * Since the physics identifier is optional (a proto thing), this wrapper function is used both to
 * return a physics ID and throw if it isn't set.
 */
export function getPhysicsId(physics: simulationpb.Physics): string {
  const id = physics.physicsIdentifier?.id;
  assert(!!id, 'Physics is missing an ID');
  return id;
}

/**
 * Returns the name to display for a physics.  While a name should always be set, we use a generic
 * default as backup
 */
export function getPhysicsName(
  physics: simulationpb.Physics,
  simParam: simulationpb.SimulationParam,
): string {
  const name = physics.physicsIdentifier?.name;
  if (name) {
    return name;
  }

  const label = `${getTypeLabel(physics.params.case)}`;

  const id = getPhysicsId(physics);
  const type = physics.params.case;
  const physicsOfType = simParam.physics.filter((item) => item.params.case === type);
  const index = physicsOfType.findIndex((item) => getPhysicsId(item) === id);

  if (index < 0) {
    return `[${label}]`;
  }

  return `[${label} ${index + 1}]`;
}

/**
 * Return true iff the physics type is Fluid
 * @param physics
 * @returns
 */
export function isPhysicsFluid(physics: simulationpb.Physics): boolean {
  return physics.params.case === 'fluid';
}

/**
 * Return true iff the physics type is Heat
 * @param physics
 * @returns
 */
export function isPhysicsHeat(physics: simulationpb.Physics): boolean {
  return physics.params.case === 'heat';
}

/**
 * Return the Fluid object for a fluid flow physics or undefined for non-fluid-flow physics
 * @param physics
 * @returns
 */
export function getFluid(physics: simulationpb.Physics): simulationpb.Fluid | undefined {
  if (physics.params.case === 'fluid') {
    return physics.params.value;
  }
  return undefined;
}

/**
 * Return the Heat object for a heat transfer physics or undefined for non-heat-transfer physics
 * @param physics
 * @returns
 */
export function getHeat(physics: simulationpb.Physics): simulationpb.Heat | undefined {
  if (physics.params.case === 'heat') {
    return physics.params.value;
  }
  return undefined;
}

/**
 * Return a sub physics (heat or fluid) from a physics object
 * @param physics
 * @returns
 */
export function getSubPhysics(physics: simulationpb.Physics): SubPhysics | undefined {
  return getFluid(physics) || getHeat(physics);
}

/**
 * Return a Map of the number of physics of each possible type
 * @param simParam
 * @returns
 */
export function physicsTypeCount(simParam: simulationpb.SimulationParam) {
  const counts = new Map<ConfigurablePhysicsType | '', number>(
    [['heat', 0], ['fluid', 0], ['', 0]],
  );

  simParam.physics.forEach((physics) => {
    const type = physics.params.case;
    if (type) {
      counts.set(type, (counts.get(type) ?? 0) + 1);
    }
  });

  return counts;
}

/**
 * Returns all 'fluid' type physics
 */
export function getFluidPhysics(param: simulationpb.SimulationParam) {
  return param.physics.filter((physics) => isPhysicsFluid(physics));
}

/**
 * Returns all 'heat' type physics
 */
export function getHeatPhysics(param: simulationpb.SimulationParam) {
  return param.physics.filter((physics) => isPhysicsHeat(physics));
}

/**
 * Looks for a physics in a simulation and returns the result or undefined
 */
export function findPhysicsById(param: simulationpb.SimulationParam, id: string) {
  return param.physics.find(
    (item) => getPhysicsId(item) === id,
  );
}

/**
 * Looks for a physics in a simulation and returns the index of it in the physics list or -1
 */
export function findPhysicsIndexById(param: simulationpb.SimulationParam, id: string) {
  return param.physics.findIndex(
    (item) => item.physicsIdentifier?.id === id,
  );
}

/**
 * Given a physics object, return its EntityIdentifier object, creating one if necessary
 * @param physics
 * @returns EntityIdentifier
 */
export function getOrCreatePhysicsIdentifier(
  physics: simulationpb.Physics,
): entitypb.EntityIdentifier {
  if (!physics.physicsIdentifier) {
    physics.physicsIdentifier = new entitypb.EntityIdentifier();
  }
  return physics.physicsIdentifier!;
}

/**
 * Given a fluid physics object, return a BasicFluid object, creating one if necessary
 * @param fluid
 * @returns
 */
export function getOrCreateFluidBasic(fluid: simulationpb.Fluid): simulationpb.BasicFluid {
  if (!fluid.basicFluid) {
    fluid.basicFluid = initParamGroupProto(
      new simulationpb.BasicFluid(),
      paramGroupDesc[ParamGroupName.BasicFluid],
    );
  }
  return fluid.basicFluid;
}

/**
 * Given a fluid physics object, return a SpatialDiscretizationFluid object, creating one if
 * necessary
 * @param fluid
 * @returns
 */
export function getOrCreateFluidSpatialDiscretization(
  fluid: simulationpb.Fluid,
): simulationpb.SpatialDiscretizationFluid {
  if (!fluid.spatialDiscretizationFluid) {
    fluid.spatialDiscretizationFluid = initParamGroupProto(
      new simulationpb.SpatialDiscretizationFluid(),
      paramGroupDesc[ParamGroupName.SpatialDiscretizationFluid],
    );
  }
  return fluid.spatialDiscretizationFluid;
}

/**
 * Given a fluid physics object, return a SolutionControlsFluid object, creating one if necessary
 * @param fluid
 * @returns
 */
export function getOrCreateFluidSolutionControls(
  fluid: simulationpb.Fluid,
): simulationpb.SolutionControlsFluid {
  if (!fluid.solutionControlsFluid) {
    fluid.solutionControlsFluid = initParamGroupProto(
      new simulationpb.SolutionControlsFluid(),
      paramGroupDesc[ParamGroupName.SolutionControlsFluid],
    );
  }
  return fluid.solutionControlsFluid;
}

/**
 * Given a fluid physics object, return a InitializationFluid object, creating one if necessary
 * @param fluid
 * @returns
 */
export function getOrCreateFluidInitialization(
  fluid: simulationpb.Fluid,
): simulationpb.InitializationFluid {
  if (!fluid.initializationFluid) {
    fluid.initializationFluid = initParamGroupProto(
      new simulationpb.InitializationFluid(),
      paramGroupDesc[ParamGroupName.InitializationFluid],
    );
  }
  return fluid.initializationFluid;
}

/**
 * Given a heat physics object, return a SpatialDiscretizationHeat object, creating one if
 * necessary
 * @param heat
 * @returns
 */
export function getOrCreateHeatSpatialDiscretization(
  heat: simulationpb.Heat,
): simulationpb.SpatialDiscretizationHeat {
  if (!heat.spatialDiscretizationHeat) {
    heat.spatialDiscretizationHeat = initParamGroupProto(
      new simulationpb.SpatialDiscretizationHeat(),
      paramGroupDesc[ParamGroupName.SpatialDiscretizationHeat],
    );
  }
  return heat.spatialDiscretizationHeat!;
}

/**
 * Given a heat physics object, return a SolutionControlsHeat object, creating one if necessary
 * @param heat
 * @returns
 */
export function getOrCreateHeatSolutionControls(
  heat: simulationpb.Heat,
): simulationpb.SolutionControlsHeat {
  if (!heat.solutionControlsHeat) {
    heat.solutionControlsHeat = initParamGroupProto(
      new simulationpb.SolutionControlsHeat(),
      paramGroupDesc[ParamGroupName.SolutionControlsHeat],
    );
  }
  return heat.solutionControlsHeat;
}

/**
 * Given a fluid physics object, return a Turbulence object, creating one if necessary
 * @param fluid
 * @returns
 */
export function getOrCreateTurbulence(
  fluid: simulationpb.Fluid,
): simulationpb.Turbulence {
  if (!fluid.turbulence) {
    fluid.turbulence = initParamGroupProto(
      new simulationpb.Turbulence(),
      paramGroupDesc[ParamGroupName.Turbulence],
    );
  }
  return fluid.turbulence;
}

/**
 * Given a heat physics object, return a InitializationHeat object, creating one if necessary
 * @param heat
 * @returns
 */
export function getOrCreateHeatInitialization(
  heat: simulationpb.Heat,
): simulationpb.InitializationHeat {
  if (!heat.initializationHeat) {
    heat.initializationHeat = initParamGroupProto(
      new simulationpb.InitializationHeat(),
      paramGroupDesc[ParamGroupName.InitializationHeat],
    );
  }
  return heat.initializationHeat;
}

/**
 * Generates an EntityIdentifier for a physics type ('fluid' or 'heat'), automatically generating a
 * unique, incrementally numbered name for that physics type.
 */
export function createPhysicsIdentifier(param: simulationpb.SimulationParam, type: PhysicsType) {
  const existingNames: string[] = param.physics.reduce((result, physics) => {
    if (physics.params.case === type) {
      const name = physics.physicsIdentifier?.name;
      if (name) {
        result.push(name);
      }
    }
    return result;
  }, [] as string[]);

  const prefix = getTypeLabel(type);
  const newName = uniqueSequenceName(existingNames, (count: number) => `${prefix} ${count}`);

  return createIdentifier(newName);
}

/**
 * Returns an error message if adding a physics of type `type` would exceed max count values
 * @param simParam
 * @param type
 * @returns
 */
export function maxPhysicsCountsMessage(
  simParam: simulationpb.SimulationParam,
  type: PhysicsType,
) {
  const allPhysics = simParam.physics;
  if (allPhysics.length >= MAX_PHYSICS) {
    return `No more than ${MAX_PHYSICS} physics ${conjugateIs(MAX_PHYSICS)} supported`;
  }
  if (type === 'heat') {
    const heatPhysics = allPhysics.filter(isPhysicsHeat);
    if (heatPhysics.length >= MAX_HEAT_PHYSICS) {
      return `No more than ${MAX_HEAT_PHYSICS} heat physics ${conjugateIs(MAX_HEAT_PHYSICS)}
        supported`;
    }
  }
  return '';
}
/**
 * Throw an error if the number of physics is at or above the max number supported.
 */
function preemptivelyEnforceMaxPhysicsCounts(
  simParam: simulationpb.SimulationParam,
  type: PhysicsType,
): void {
  const errorMsg = maxPhysicsCountsMessage(simParam, type);
  if (errorMsg) {
    throw Error(errorMsg);
  }
}

/**
 * Return true if the simulation is CHT (conjugate heat transfer), which requires at least one fluid
 * physics and one solid physics
 * @param simParam
 * @returns
 */
export function isChtSimulation(simParam: simulationpb.SimulationParam) {
  return !!(getFluidPhysics(simParam).length && getHeatPhysics(simParam).length);
}

/**
 * This function should wrap any function that mutates the physics list.  Depending on whether the
 * simulation is/was CHT, the multi-physics coupling options may be reset.
 * @param simParam
 * @param mutate
 * @returns
 */
export function wrapMutatePhysicsList<T>(
  simParam: simulationpb.SimulationParam,
  mutate: () => T,
): T {
  const wasCht = isChtSimulation(simParam);
  const result = mutate();
  const isCht = isChtSimulation(simParam);

  // If mutating a physics transitions the simulation between CHT and non-CHT, then clear the
  // coupling options, so they'll get re-initialized when needed.
  if (
    (isCht && !wasCht) || // Simulation went from non-CHT to CHT
    (!isCht) // Simulation is now non-CHT (doesn't matter if it was before)
  ) {
    simParam.mpCouplingOptions = undefined;
  }

  return result;
}

/**
 * Append a fluid physics to the simulation param
 * @param simParam
 * @returns the ID of the newly created fluid physics
 */
export function appendFluidPhysics(simParam: simulationpb.SimulationParam) {
  return wrapMutatePhysicsList(
    simParam,
    () => {
      preemptivelyEnforceMaxPhysicsCounts(simParam, 'fluid');

      const fluid = initParamGroupProto(
        new simulationpb.Fluid(),
        paramGroupDesc[ParamGroupName.Fluid],
      );

      const physics = new simulationpb.Physics({
        params: {
          case: 'fluid', value: fluid,
        },
        physicsIdentifier: createPhysicsIdentifier(simParam, 'fluid'),
      });
      simParam.physics.push(physics);

      return physics;
    },
  );
}

/**
 * Append a heat physics to the simulation param
 * @param simParam
 * @returns the ID of the newly created heat physics
 */
export function appendHeatPhysics(simParam: simulationpb.SimulationParam) {
  return wrapMutatePhysicsList(
    simParam,
    () => {
      preemptivelyEnforceMaxPhysicsCounts(simParam, 'heat');

      const heat = initParamGroupProto(
        new simulationpb.Heat(),
        paramGroupDesc[ParamGroupName.Heat],
      );

      const physics = new simulationpb.Physics({
        params: {
          case: 'heat', value: heat,
        },
        physicsIdentifier: createPhysicsIdentifier(simParam, 'heat'),
      });
      simParam.physics.push(physics);

      return physics;
    },
  );
}

/**
 * Append a physics of a given type to the simulation param
 * @param simParam
 * @param type
 * @returns the ID of the newly created physics
 */
export function appendPhysics(
  simParam: simulationpb.SimulationParam,
  type: ConfigurablePhysicsType,
): simulationpb.Physics {
  switch (type) {
    case 'fluid': {
      return appendFluidPhysics(simParam);
    }
    case 'heat': {
      return appendHeatPhysics(simParam);
    }
    default: {
      // Shouldn't get here, type enum is exhausted, but TS demands it
      throw Error('Cannot append a physics without configurable type');
    }
  }
}

/**
 * Through its volume selections, a physics should be associated with a list of materials.  Return
 * that list.
 * @param simParam
 * @param physics
 * @returns list of associated materials
 */
export function getPhysicsMaterials(
  simParam: simulationpb.SimulationParam,
  physics: simulationpb.Physics,
  geometryTags: GeometryTags,
  volumeState: StaticVolume[],
) {
  const physicsId = getPhysicsId(physics);
  const materialIds = getPhysicsMaterialIds(simParam, physicsId, geometryTags, volumeState);

  return simParam.materialEntity.filter((entity) => materialIds.has(getMaterialId(entity)));
}

/**
 * Gets the boundary condition list of a physics, irrerespective if it's a fluid or heat physics.
 * @param simParam
 * @param physics
 * @returns list of boundary conditions
 */
export function getBoundaryConditionsForPhysics(
  physics: simulationpb.Physics,
): AnyBoundaryCondition[] {
  return (
    getFluid(physics)?.boundaryConditionsFluid ??
    getHeat(physics)?.boundaryConditionsHeat ??
    []
  );
}

/**
 * A fluid physics should be associated with exactly one fluid material.  Return that material or
 * undefined if the requirement isn't satisfied.
 * @param simParam
 * @param physics
 * @returns associated fluid material or undefined
 */
export function findFluidPhysicsMaterial(
  simParam: simulationpb.SimulationParam,
  physics: simulationpb.Physics,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  const materials = getPhysicsMaterials(simParam, physics, geometryTags, staticVolumes);

  if (materials.length > 1) {
    console.error('Fluid physics is incorrectly associated with multiple materials');
  }

  if (materials.length === 1 && isMaterialFluid(materials[0])) {
    return materials[0];
  }

  return undefined;
}

/**
 * A solid physics should be associated with a list of solid materials.  Return that list of solid
 * materials or an empty list if the requirement isn't satisfied.
 * @param simParam
 * @param physics
 * @returns associated solid materials
 */
export function getHeatPhysicsMaterials(
  simParam: simulationpb.SimulationParam,
  physics: simulationpb.Physics,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  const materials = getPhysicsMaterials(simParam, physics, geometryTags, staticVolumes);

  if (materials.every(isMaterialSolid)) {
    return materials;
  }

  return [];
}

/**
 * Return all porous behaviors configured for any physics in the simulation param
 * @param simParam
 * @returns a list of porous behaviors
 */
export function getAllPorousBehaviors(
  simParam: simulationpb.SimulationParam,
): simulationpb.PorousBehavior[] {
  return simParam.physics.flatMap((physics) => (
    getFluid(physics)?.porousBehavior ?? []
  ));
}

/**
 * Remove any boundary condition configured on any physics in the simulation param
 * @param simParam
 */
export function clearAllBoundaryConditions(simParam: simulationpb.SimulationParam) {
  simParam.physics.forEach((physics) => {
    const fluid = getFluid(physics);
    const heat = getHeat(physics);
    if (fluid) {
      fluid.boundaryConditionsFluid = [];
    }
    if (heat) {
      heat.boundaryConditionsHeat = [];
    }
  });
}

/**
 * Return the physics associated with a volume (identified by domain, e.g. "0") or null if no
 * physics is associated yet
 * @param simParam
 * @param domain
 * @returns
 */
export function findPhysicsByDomain(
  simParam: simulationpb.SimulationParam,
  domain: string,
  geometryTags: GeometryTags,
) {
  const physicsId = findPhysicsIdByDomain(simParam, domain, geometryTags);
  if (physicsId) {
    return findPhysicsById(simParam, physicsId);
  }

  return undefined;
}

/**
 * Return a list of StaticVolume objects representing the volumes (domains) assigned to a physics
 * (identified by ID)
 * @param simParam
 * @param physicsId
 * @param staticVolumes
 * @returns a list of StaticVolume objects
 */
export function getAssignedVolumes(
  simParam: simulationpb.SimulationParam,
  physicsId: string,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  const domains = getPhysicsDomains(simParam, physicsId, geometryTags, staticVolumes);
  return staticVolumes.filter((volume) => domains.has(volume.domain));
}

/**
 * Return a list of surfaces belonging to all volumes assigned to a physics (identified by ID)
 * @param simParam
 * @param physicsId
 * @param staticVolumes
 * @returns a list of surface IDs
 */
export function getAvailableSurfaces(
  simParam: simulationpb.SimulationParam,
  physicsId: string,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  const volumes = getAssignedVolumes(simParam, physicsId, geometryTags, staticVolumes);
  return surfacesFromVolumes(volumes);
}

/**
 * Get a list of physics whose volume selections include every surface in `surfaceIds` and return
 * the result
 * @param simParam
 * @param surfaceIds
 * @param staticVolumes
 * @returns a list of physics objects
 */
export function getPhysicsContainingSurfaces(
  simParam: simulationpb.SimulationParam,
  surfaceIds: string[] | Set<string>,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  // Find all volumes with at least one surface in the surfaceIds list
  const volumes = staticVolumes.filter((volume) => intersects(surfaceIds, volume.bounds));

  // Convert volume list to domains
  const domains = volumes.map((volume) => volume.domain);

  return simParam.physics.filter((physics) => {
    const physicsDomains = getPhysicsDomains(
      simParam,
      getPhysicsId(physics),
      geometryTags,
      staticVolumes,
    );
    return intersects(physicsDomains, domains);
  });
}

/**
 * Find and and return the single physics (if one exists) whose volumes completely contain the given
 * surface ID list
 * @param simParam
 * @param surfaceIds
 * @param staticVolumes
 * @returns a list of physics objects
 */
export function findPhysicsContainingSurfaces(
  simParam: simulationpb.SimulationParam,
  surfaceIds: string[] | Set<string>,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  // Find all volumes with at least one surface in the surfaceIds list
  const volumes = staticVolumes.filter((volume) => intersects(surfaceIds, volume.bounds));

  // Convert volume list to domains
  const domains = volumes.map((volume) => volume.domain);

  return simParam.physics.find((physics) => {
    const physicsDomains =
      getPhysicsDomains(simParam, getPhysicsId(physics), geometryTags, staticVolumes);
    return isSuperset(physicsDomains, domains);
  });
}

/**
 * Return an object to lookup a physics ID by a surface ID (based on physics' volume selections)
 * @param simParam
 * @param staticVolumes
 * @returns
 */
export function getSurfaceToPhysicsMap(
  simParam: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  const map: Record<string, string> = {};

  simParam.physics.forEach((physics) => {
    const physicsId = getPhysicsId(physics);
    const surfaceIds = getAvailableSurfaces(simParam, physicsId, geometryTags, staticVolumes);
    surfaceIds.forEach((surfaceId) => {
      map[surfaceId] = physicsId;
    });
  });

  return map;
}

/**
 * For a given physics, get or create an initialization object for either heat or fluid and return
 * the result
 * @param physics
 * @returns
 */
export function getPhysicsInitialization(physics?: simulationpb.Physics) {
  if (physics) {
    const fluid = getFluid(physics);
    const heat = getHeat(physics);
    if (fluid) {
      return getOrCreateFluidInitialization(fluid);
    }
    if (heat) {
      return getOrCreateHeatInitialization(heat);
    }
  }
  return null;
}

/**
 * Return true iff the given physics's various configuration objects (i.e. heat sources) reference
 * any of the given volumes
 * @param physics
 * @param staticVolumes
 * @returns
 */
export function physicsReferencesVolumes(
  physics: simulationpb.Physics,
  staticVolumes: StaticVolume[],
) {
  const domains = new Set(staticVolumes.map((volume) => volume.domain));

  return getHeat(physics)?.heatSource.some(
    (source) => intersects(source.heatSourceZoneIds, domains),
  );
}

/**
 * Return true iff the given physics's various configuration objects (i.e. boundary conditions,
 * periodic pairs, sliding interfaces) reference surfaces in any of the given volumes
 * @param physics
 * @param staticVolumes
 * @returns
 */
export function physicsReferencesVolumesSurfaces(
  physics: simulationpb.Physics,
  staticVolumes: StaticVolume[],
) {
  const surfaceIdsSet = staticVolumes.reduce((result, volume) => {
    volume.bounds.forEach((surfaceId) => result.add(surfaceId));
    return result;
  }, new Set<string>());

  const subPhysics = getSubPhysics(physics);
  return (
    [
      ...getFluid(physics)?.boundaryConditionsFluid || [],
      ...getHeat(physics)?.boundaryConditionsHeat || [],
    ].some((bc) => intersects(bc.surfaces, surfaceIdsSet)) ||
    subPhysics?.periodicPair.some((pair) => (
      intersects(pair.boundA, surfaceIdsSet) ||
      intersects(pair.boundB, surfaceIdsSet)
    )) ||
    subPhysics?.slidingInterfaces.some((slidingInterface) => (
      intersects(slidingInterface.slidingA, surfaceIdsSet) ||
      intersects(slidingInterface.slidingB, surfaceIdsSet)
    ))
  );
}
/**
 * Creates a new scope containing the physics and associated (fluid) material.
 * @param simParam
 * @param physics
 * @param experimentConfig
 * @returns
 */
export function createPhysicsScope(
  simParam: simulationpb.SimulationParam,
  physics: simulationpb.Physics,
  experimentConfig: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  const material = findFluidPhysicsMaterial(simParam, physics, geometryTags, staticVolumes);
  const mainScope = createParamScope(simParam, experimentConfig);
  return chainParamScopes([physics, material], experimentConfig, mainScope);
}

/**
 * Renames a physics (identified by ID), returning true if a rename took place
 */
export function renamePhysics(
  param: simulationpb.SimulationParam,
  id: string,
  name: string,
): boolean {
  const physics = findPhysicsById(param, id);
  if (physics) {
    getOrCreatePhysicsIdentifier(physics).name = name;
    return true;
  }
  return false;
}
