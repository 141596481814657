// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import { inputFilenameFixture } from '../lib/fixtures';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import { isTestingEnv } from '../lib/testing/utils';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';

/**
 * The input filename is normally stored in the workflow config in the
 * params under param.getInput().getFilename(). The filename is set in
 * defaultWorkflowConfig by extracting the name from meshUrl.getInput(). This
 * works fine for .lcmesh files. Unfortunately for CAD files, the name is
 * not found in meshUrl.getInput(). This is a work around for that.
 *
 * We use this state to save the file name when the files are first uploaded.
 * Then if defaultWorkflowConfig comes up empty, we add the file name back in.
 * We want to save this value in the workflow config and then use the value in
 * the workflow config rather than the value here, so that if we upload a new
 * mesh, the old workflow configs have the original filename.
 */

const inputFilenameKey = 'inputFilename';

const DEFAULT_URLS = new projectstatepb.InputFilename();

function serialize(val: projectstatepb.InputFilename): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}

function deserialize(val: Uint8Array): projectstatepb.InputFilename {
  return (val.length ?
    projectstatepb.InputFilename.fromBinary(val) :
    DEFAULT_URLS);
}

const inputFileNameSelectorRpc = selectorFamily<projectstatepb.InputFilename, string>({
  key: `${inputFilenameKey}/rpc`,
  get: (projectId: string) => () => (
    persist.getProjectState(projectId, [inputFilenameKey], deserialize)
  ),
});

const inputFileNameSelectorTesting = selectorFamily<projectstatepb.InputFilename, string>({
  key: `${inputFilenameKey}/testing`,
  get: () => inputFilenameFixture,
});

const inputFileNameSelector = isTestingEnv() ?
  inputFileNameSelectorTesting : inputFileNameSelectorRpc;

export const inputFilenameState = atomFamily<projectstatepb.InputFilename, string>({
  key: inputFilenameKey,
  default: inputFileNameSelector,
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, inputFilenameKey, deserialize, serialize),
  ],
});

export const useInputFilename = (projectId: string) => (
  useRecoilValue(inputFilenameState(projectId))
);

export const useSetInputFilename = (projectId: string) => (
  useSetRecoilState(inputFilenameState(projectId))
);
