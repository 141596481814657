// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
// If a node in the simulation settings tree (not the geometry tree) was clicked, find and return

import { useCallback } from 'react';

import { SIMULATION_TREE_DATA_LOCATOR } from '../../../lib/simulationTree/node';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useSimulationTreeSubselect } from '../../../recoil/simulationTreeSubselect';
import { useProjectContext } from '../../context/ProjectContext';

// the row ID in its ancestor tree
export function getClickedSimulationRowId(el: Node) {
  const treeContainerEl = document.querySelector(`[data-locator=${SIMULATION_TREE_DATA_LOCATOR}]`);
  if (treeContainerEl?.contains(el)) {
    let rowEl: Node | null = el;
    while (rowEl && treeContainerEl.contains(rowEl)) {
      const rowId = (rowEl as Element)?.getAttribute('data-row-id');
      if (rowId) {
        return rowId;
      }
      rowEl = rowEl.parentNode;
    }
  }

  return null;
}

export function getSelectorListLocator(id: string) {
  return `node-selector-list-${id}`;
}

/**
 * This hook only returns information about a mousedown event--specifically, whether it should
 * cancel an active subselect and, if so, which row ID was clicked.
 */
export const useMouseDown = () => {
  const { projectId } = useProjectContext();
  const treeSubselect = useSimulationTreeSubselect();
  const lcVisEnabled = useLcVisEnabledValue(projectId);

  return useCallback((targetNode: Node) => {
    if (treeSubselect.active) {
      const nodeListLocator = getSelectorListLocator(treeSubselect.id);
      const nodeListEl = document.querySelector(`[data-locator="${nodeListLocator}"]`);
      const geoTreeContainerEl = document.querySelector('[data-locator="geometryTreePanel"]');
      const cameraToolbarEl = document.querySelector('[data-locator="cameraControlPanel"]');
      const viewerContainerEl = document.querySelector(lcVisEnabled ?
        '[data-locator="lcvisEventHandler"]' : '.Paraview-rendererChild canvas');

      if (
        !nodeListEl?.contains(targetNode) &&
        !geoTreeContainerEl?.contains(targetNode) &&
        !cameraToolbarEl?.contains(targetNode) &&
        !viewerContainerEl?.contains(targetNode)
      ) {
        const clickedRowId = getClickedSimulationRowId(targetNode);
        return { cancelSubselect: true, clickedRowId };
      }
    }
    return { cancelSubselect: false };
  }, [treeSubselect, lcVisEnabled]);
};
