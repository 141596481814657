// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 27;
const baseHeight = 30;
export const StepBackwardIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="stepBackward"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M24,0
           C23.28,0 22.68,0.27 22.14,0.69
           L22.11,0.66
           L9,11.16
           L9,3
           C9,1.35 7.65,0 6,0
           L3,0
           C1.35,0 -0,1.35 -0,3
           L-0,27
           C-0,28.65 1.35,30 3,30
           L6,30
           C7.65,30 9,28.65 9,27
           L9,18.84
           L22.14,29.34
           L22.17,29.31
           C22.68,29.73 23.28,30 24,30
           C25.65,30 27,28.65 27,27
           L27,3
           C27,1.35 25.65,0 24,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
