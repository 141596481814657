// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as ProtoDescriptor from '../ProtoDescriptor';
import * as simulationpb from '../proto/client/simulation_pb';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import { ParamScope, createParamScope } from './ParamScope';
import { newAdFloat } from './adUtils';
import assert from './assert';
import { newInt } from './intUtils';
import { findFluidPhysicsMaterial } from './physicsUtils';

const ControlsPresetVersion: number = 71824;

const {
  AGGRESSIVE_SOLUTION_CONTROLS_FLUID,
  CUSTOM_SOLUTION_CONTROLS_FLUID,
  INTERMEDIATE_SOLUTION_CONTROLS_FLUID,
} = simulationpb.SolutionControlsFluidPreset;

// Function which checks that there only exists one preset whose conditional evaluates to true.
export function checkPresets(presets: Map<string, ProtoDescriptor.Cond>, paramScope: ParamScope) {
  let enabledPresets = 0;
  presets.forEach((cond, key) => {
    if (paramScope.isEnabled(cond)) {
      enabledPresets += 1;
    }
  });
  assert(enabledPresets === 1, 'Incompatible presets');
}

// Sets the parameters for each controls preset.
export function applyFluidControlsPreset(
  physics: simulationpb.Physics,
  simParam: simulationpb.SimulationParam,
  experimentConfig: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
): void {
  // Only fluid physics have solution control presets here.
  const fluid = (physics.params.case === 'fluid') ? physics.params.value : null;
  const material = findFluidPhysicsMaterial(simParam, physics, geometryTags, staticVolumes);
  if (!fluid || !material) {
    return;
  }

  const materialScope = createParamScope(material, experimentConfig);
  const physicsScope = createParamScope(fluid, experimentConfig, materialScope);
  const paramScope = createParamScope(simParam, experimentConfig, physicsScope);

  /* eslint-disable-next-line id-length */
  const p = fluid.solutionControlsFluid;
  const value = p?.solutionControlsFluidPreset;

  if (!p || value === CUSTOM_SOLUTION_CONTROLS_FLUID) {
    return;
  }
  // Always tag the project with the current version. See LC-6227.
  p.controlsPresetVersion = newInt(ControlsPresetVersion);

  // BEGIN GENERATED CODE (see gen/presets.py)
  const presets = new Map<string, ProtoDescriptor.Cond>();
  presets.set(
    'CTRL_FDS',
    {
      list: [
        {
          list: [
            {
              choice: 40881,
              param: 'convective_schemes_density_based',
              type: ProtoDescriptor.CondType.CHOICE,
            },
            {
              choice: 64640,
              param: 'flow_behavior',
              type: ProtoDescriptor.CondType.CHOICE,
            },
          ],
          type: ProtoDescriptor.CondType.ALL,
        },
        {
          choice: 37484,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
  );
  presets.set(
    'CTRL_RC',
    {
      cond: {
        list: [
          {
            list: [
              {
                choice: 40881,
                param: 'convective_schemes_density_based',
                type: ProtoDescriptor.CondType.CHOICE,
              },
              {
                choice: 64640,
                param: 'flow_behavior',
                type: ProtoDescriptor.CondType.CHOICE,
              },
            ],
            type: ProtoDescriptor.CondType.ALL,
          },
          {
            choice: 37484,
            param: 'density_relationship',
            type: ProtoDescriptor.CondType.CHOICE,
          },
        ],
        type: ProtoDescriptor.CondType.ANY,
      },
      type: ProtoDescriptor.CondType.NOT,
    },
  );
  checkPresets(presets, paramScope);
  if (paramScope.isEnabled(presets.get('CTRL_FDS')!)) {
    p.relaxationMethod = simulationpb.RelaxationMethod.IMPLICIT;
    p.implicitMethod = simulationpb.ImplicitMethod.BACKWARD_EULER;
    p.linearSolverType = simulationpb.LinearSolverType.GS;
    p.pseudoTimeStepMethod = simulationpb.PseudoTimeStepMethod.CFL_BASED;
    p.localTimeStepping = simulationpb.LocalTimeStepping.LOCAL_TIME_STEPPING_ON;
    p.robustStartup = simulationpb.RobustStartup.ROBUST_STARTUP_ON;
    p.robustStartupIterations = newInt(300);
    p.robustStartupInitialCfl = newAdFloat(1.0);
    p.jacobianWarmupThreshold = newInt(300);
    p.relaxFlow = newAdFloat(1.0);
    p.relaxTurb = newAdFloat(0.5);
    p.updateLimitFlow = newAdFloat(0.2);
    p.updateLimitTurb = newAdFloat(0.99);
    p.linsolIterations = newInt(15);
    p.linsolTolerance = newAdFloat(0.05);
    p.cfl = newAdFloat(50.0);
    p.jacobianUpdateInterval = newAdFloat(1.0);
    if (value === INTERMEDIATE_SOLUTION_CONTROLS_FLUID) {
      p.relaxationMethod = simulationpb.RelaxationMethod.IMPLICIT;
      p.implicitMethod = simulationpb.ImplicitMethod.BACKWARD_EULER;
      p.linearSolverType = simulationpb.LinearSolverType.GS;
      p.pseudoTimeStepMethod = simulationpb.PseudoTimeStepMethod.CFL_BASED;
      p.localTimeStepping = simulationpb.LocalTimeStepping.LOCAL_TIME_STEPPING_ON;
      p.robustStartup = simulationpb.RobustStartup.ROBUST_STARTUP_ON;
      p.robustStartupIterations = newInt(300);
      p.robustStartupInitialCfl = newAdFloat(1.0);
      p.jacobianWarmupThreshold = newInt(300);
      p.relaxFlow = newAdFloat(1.0);
      p.relaxTurb = newAdFloat(0.5);
      p.updateLimitFlow = newAdFloat(0.2);
      p.updateLimitTurb = newAdFloat(0.99);
      p.linsolIterations = newInt(12);
      p.linsolTolerance = newAdFloat(0.1);
      p.cfl = newAdFloat(100.0);
      p.jacobianUpdateInterval = newAdFloat(3.0);
    }
    if (value === AGGRESSIVE_SOLUTION_CONTROLS_FLUID) {
      p.relaxationMethod = simulationpb.RelaxationMethod.IMPLICIT;
      p.implicitMethod = simulationpb.ImplicitMethod.BACKWARD_EULER;
      p.linearSolverType = simulationpb.LinearSolverType.GS;
      p.pseudoTimeStepMethod = simulationpb.PseudoTimeStepMethod.CFL_BASED;
      p.localTimeStepping = simulationpb.LocalTimeStepping.LOCAL_TIME_STEPPING_ON;
      p.robustStartup = simulationpb.RobustStartup.ROBUST_STARTUP_ON;
      p.robustStartupIterations = newInt(300);
      p.robustStartupInitialCfl = newAdFloat(1.0);
      p.jacobianWarmupThreshold = newInt(300);
      p.relaxFlow = newAdFloat(1.0);
      p.relaxTurb = newAdFloat(0.5);
      p.updateLimitFlow = newAdFloat(0.2);
      p.updateLimitTurb = newAdFloat(0.99);
      p.linsolIterations = newInt(12);
      p.linsolTolerance = newAdFloat(0.2);
      p.cfl = newAdFloat(150.0);
      p.jacobianUpdateInterval = newAdFloat(5.0);
    }
  }
  if (paramScope.isEnabled(presets.get('CTRL_RC')!)) {
    p.relaxationMethod = simulationpb.RelaxationMethod.IMPLICIT;
    p.implicitMethod = simulationpb.ImplicitMethod.BACKWARD_EULER;
    p.linearSolverType = simulationpb.LinearSolverType.AMG_KRYLOV_AMGX;
    p.pseudoTimeStepMethod = simulationpb.PseudoTimeStepMethod.CFL_BASED;
    p.localTimeStepping = simulationpb.LocalTimeStepping.LOCAL_TIME_STEPPING_ON;
    p.robustStartup = simulationpb.RobustStartup.ROBUST_STARTUP_ON;
    p.robustStartupIterations = newInt(300);
    p.robustStartupInitialCfl = newAdFloat(1.0);
    p.jacobianWarmupThreshold = newInt(300);
    p.relaxFlow = newAdFloat(1.0);
    p.relaxTurb = newAdFloat(0.4);
    p.updateLimitFlow = newAdFloat(0.2);
    p.updateLimitTurb = newAdFloat(0.99);
    p.linsolIterations = newInt(15);
    p.linsolTolerance = newAdFloat(0.05);
    p.cfl = newAdFloat(50.0);
    p.jacobianUpdateInterval = newAdFloat(1.0);
    p.linsolAmgPreSweeps = newInt(1);
    p.linsolAmgPostSweeps = newInt(1);
    p.linsolAmgCoarseningSize = newInt(8);
    p.linsolAmgLevels = newInt(20);
    p.linsolAmgRelaxation = newAdFloat(0.75);
    p.linsolAmgCycleType = simulationpb.LinsolAmgCycleType.LINSOL_AMG_CYCLE_TYPE_V;
    p.linsolAmgFreezeLevelsThreshold = newInt(300);
    p.linsolAmgSmoother = simulationpb.LinsolAmgSmoother.LINSOL_AMG_SMOOTHER_JACOBI;
    if (value === INTERMEDIATE_SOLUTION_CONTROLS_FLUID) {
      p.relaxationMethod = simulationpb.RelaxationMethod.IMPLICIT;
      p.implicitMethod = simulationpb.ImplicitMethod.BACKWARD_EULER;
      p.linearSolverType = simulationpb.LinearSolverType.AMG_KRYLOV_AMGX;
      p.pseudoTimeStepMethod = simulationpb.PseudoTimeStepMethod.CFL_BASED;
      p.localTimeStepping = simulationpb.LocalTimeStepping.LOCAL_TIME_STEPPING_ON;
      p.robustStartup = simulationpb.RobustStartup.ROBUST_STARTUP_ON;
      p.robustStartupIterations = newInt(300);
      p.robustStartupInitialCfl = newAdFloat(1.0);
      p.jacobianWarmupThreshold = newInt(300);
      p.relaxFlow = newAdFloat(1.0);
      p.relaxTurb = newAdFloat(0.4);
      p.updateLimitFlow = newAdFloat(0.2);
      p.updateLimitTurb = newAdFloat(0.99);
      p.linsolIterations = newInt(12);
      p.linsolTolerance = newAdFloat(0.1);
      p.cfl = newAdFloat(100.0);
      p.jacobianUpdateInterval = newAdFloat(1.0);
      p.linsolAmgPreSweeps = newInt(1);
      p.linsolAmgPostSweeps = newInt(1);
      p.linsolAmgCoarseningSize = newInt(8);
      p.linsolAmgLevels = newInt(20);
      p.linsolAmgRelaxation = newAdFloat(0.75);
      p.linsolAmgCycleType = simulationpb.LinsolAmgCycleType.LINSOL_AMG_CYCLE_TYPE_V;
      p.linsolAmgFreezeLevelsThreshold = newInt(300);
      p.linsolAmgSmoother = simulationpb.LinsolAmgSmoother.LINSOL_AMG_SMOOTHER_JACOBI;
    }
    if (value === AGGRESSIVE_SOLUTION_CONTROLS_FLUID) {
      p.relaxationMethod = simulationpb.RelaxationMethod.IMPLICIT;
      p.implicitMethod = simulationpb.ImplicitMethod.BACKWARD_EULER;
      p.linearSolverType = simulationpb.LinearSolverType.AMG_KRYLOV_AMGX;
      p.pseudoTimeStepMethod = simulationpb.PseudoTimeStepMethod.CFL_BASED;
      p.localTimeStepping = simulationpb.LocalTimeStepping.LOCAL_TIME_STEPPING_ON;
      p.robustStartup = simulationpb.RobustStartup.ROBUST_STARTUP_ON;
      p.robustStartupIterations = newInt(300);
      p.robustStartupInitialCfl = newAdFloat(1.0);
      p.jacobianWarmupThreshold = newInt(300);
      p.relaxFlow = newAdFloat(1.0);
      p.relaxTurb = newAdFloat(0.4);
      p.updateLimitFlow = newAdFloat(0.2);
      p.updateLimitTurb = newAdFloat(0.99);
      p.linsolIterations = newInt(10);
      p.linsolTolerance = newAdFloat(0.2);
      p.cfl = newAdFloat(200.0);
      p.jacobianUpdateInterval = newAdFloat(1.0);
      p.linsolAmgPreSweeps = newInt(1);
      p.linsolAmgPostSweeps = newInt(1);
      p.linsolAmgCoarseningSize = newInt(8);
      p.linsolAmgLevels = newInt(20);
      p.linsolAmgRelaxation = newAdFloat(0.75);
      p.linsolAmgCycleType = simulationpb.LinsolAmgCycleType.LINSOL_AMG_CYCLE_TYPE_V;
      p.linsolAmgFreezeLevelsThreshold = newInt(300);
      p.linsolAmgSmoother = simulationpb.LinsolAmgSmoother.LINSOL_AMG_SMOOTHER_JACOBI;
    }
  }
  // END GENERATED CODE

  // Override the startup duration for AMR so that at least the first mesh is run 1st order.
  // We are doing this outside of the normal preset framework to avoid an explosion of combinations.
  if (simParam.adaptiveMeshRefinement?.meshingMethod ===
      simulationpb.MeshingMethod.MESH_METHOD_AUTO) {
    p.robustStartupIterations = newInt(600);
  }
}

export function applyAllFluidControlsPresets(
  simParam: simulationpb.SimulationParam,
  experimentConfig: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
): void {
  simParam.physics.forEach((physics) => {
    applyFluidControlsPreset(physics, simParam, experimentConfig, geometryTags, staticVolumes);
  });
}
