// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { CallbackInterface } from 'recoil';

import { meshingMultiPartSelectorNew, meshingMultiPartState } from '../recoil/useMeshingMultiPart';
import { refinementRegionVisibilityState } from '../recoil/useRefinementRegions';
import { staticVolumesState } from '../recoil/volumes';

import { getMeshInfoFromMeshId, reconcileIncomingSizingParams, reconcileSelectionFields } from './mesh';
import { defaultMeshComplexityParams } from './simulationUtils';

interface RecoilMeshKey {
  projectId: string;
  meshId: string;
}

export const copyMeshParamsIntoMeshPanel = ({ snapshot, set }: CallbackInterface) => async (
  recoilKey: RecoilMeshKey,
) => {
  const { getPromise } = snapshot;

  const { projectId, meshId } = recoilKey;
  // Get the mesh that we are copying
  const mesh = await getMeshInfoFromMeshId(meshId);
  const { meshUrl } = mesh!;
  const volumes = await getPromise(staticVolumesState(projectId));
  const surfaces = volumes.reduce((acc, volume) => (acc + volume.bounds.size), 0);
  const meshingMultiPart = await getPromise(
    meshingMultiPartSelectorNew({ projectId, meshId, meshUrl }),
  );

  const newMeshMultiPart = meshingMultiPart?.clone();

  // Only set the complexity params if they are absent
  if (newMeshMultiPart && !newMeshMultiPart?.complexityParams) {
    newMeshMultiPart.complexityParams = defaultMeshComplexityParams();
  }

  // Reconcile incoming sizing params
  reconcileIncomingSizingParams(newMeshMultiPart);

  // Hide related Refinement Regions
  const refinementIds = newMeshMultiPart?.refinementParams.map((region) => region.id);

  set(refinementRegionVisibilityState(projectId), (oldVis) => {
    const newVis = { ...oldVis };
    refinementIds?.forEach((id) => {
      newVis[id] = false;
    });
    return newVis;
  });

  // Fix missing selection fields in params
  // Currently, the backend does not save the selection field for volumeParams, blParams, and
  // modelParams. While we cannot know exactly what the user selected, we can at least ensure that
  // the derived choice is compatible with the selection.
  reconcileSelectionFields(newMeshMultiPart, volumes.length, surfaces);

  if (newMeshMultiPart) {
    set(meshingMultiPartState(projectId), newMeshMultiPart);
  }

  // Return the new mesh multi part which may be needed to update other meshing params like
  // adaptation boundary layers.
  return newMeshMultiPart;
};
