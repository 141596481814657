// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 120;
const baseHeight = 117;
export const LayersIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="layers"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M4.125,37.05
           L56.625,63.3
           C57.675,63.825 58.8,64.125 60,64.125
           C61.2,64.125 62.325,63.825 63.375,63.3
           L115.875,37.05
           C118.275,35.85 120,33.3 120,30.375
           C120,27.3 118.125,24.6 115.425,23.475
           L63,0.975
           C62.025,0.6 61.05,0.375 60,0.375
           C58.95,0.375 57.975,0.6 57.075,0.975
           L4.65,23.475
           C1.875,24.6 0,27.3 0,30.375
           C0,33.3 1.725,35.85 4.125,37.05Z
           M112.5,75.375
           C111.3,75.375 110.175,75.675 109.125,76.2
           L60,100.725
           L10.875,76.125
           C9.825,75.675 8.7,75.375 7.5,75.375
           C3.375,75.375 0,78.75 0,82.875
           C0,85.8 1.725,88.35 4.125,89.55
           L56.625,115.8
           C57.675,116.325 58.8,116.625 60,116.625
           C61.2,116.625 62.325,116.325 63.375,115.8
           L115.875,89.55
           C118.275,88.35 120,85.8 120,82.875
           C120,78.75 116.625,75.375 112.5,75.375Z
           M112.5,49.125
           C111.3,49.125 110.175,49.425 109.125,49.95
           L60,74.475
           L10.875,49.95
           C9.825,49.425 8.7,49.125 7.5,49.125
           C3.375,49.125 0,52.5 0,56.625
           C0,59.55 1.725,62.1 4.125,63.3
           L56.625,89.55
           C57.675,90.075 58.8,90.375 60,90.375
           C61.2,90.375 62.325,90.075 63.375,89.55
           L115.875,63.3
           C118.275,62.1 120,59.55 120,56.625
           C120,52.5 116.625,49.125 112.5,49.125Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
