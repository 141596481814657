// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useRecoilCallback } from 'recoil';

import { convertBLParamsToAdaptationBLParams, getMeshInfoFromMeshId } from '../../lib/mesh';
import { copyMeshParamsIntoMeshPanel } from '../../lib/multiMesh';
import { createValidScope, updateSimulationParam } from '../../lib/paramUtils';
import { getFluid, getHeat } from '../../lib/physicsUtils';
import { setInputMeshUrl, setMeshId } from '../../lib/simulationParamUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useSetMeshUrlState } from '../../recoil/meshState';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { MeshPanelType, useSetMeshPanelState } from '../../recoil/useMeshPanelState';
import { useStaticVolumes } from '../../recoil/volumes';
import { useCurrentConfig, useSetProjectConfig } from '../../recoil/workflowConfig';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useProjectContext } from '../context/ProjectContext';

export const useHandleMeshSelect = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const setMeshPanelState = useSetMeshPanelState(projectId);
  const setMeshUrlState = useSetMeshUrlState(projectId);
  const cloneMeshParams = useRecoilCallback(copyMeshParamsIntoMeshPanel);
  const setProjectConfigState = useSetProjectConfig(projectId);
  const currentConfig = useCurrentConfig(projectId, '', '');
  const experimentConfig = useEnabledExperiments();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  const handleMeshSelect = async (meshId?: string) => {
    // If no meshId is provided, clear the mesh and load the select panel
    if (!meshId) {
      setMeshUrlState((meshUrlState) => {
        const newState = meshUrlState.clone();

        newState.mesh = '';
        newState.meshId = '';
        newState.activeType = projectstatepb.UrlType.GEOMETRY;

        return newState;
      });
      setInputMeshUrl(simParam.input, '');
      setMeshPanelState(MeshPanelType.SELECT);
      return;
    }

    const newParam = simParam.clone();

    // We need to check all Initialization instances across all physics
    newParam.physics.forEach((physics) => {
      // If user copied to setup, the initialization type is set to Existing Solution by default.
      // Then, if a user changes meshes, the existing solution mesh may not match the new mesh and
      // the sim cannot be validated. This sets the init type to Uniform Values on each mesh swap.
      const init = (
        getFluid(physics)?.initializationFluid ||
        getHeat(physics)?.initializationHeat
      );
      if (init?.initializationType === simulationpb.InitializationType.EXISTING_SOLUTION) {
        init.initializationType = simulationpb.InitializationType.UNIFORM_VALUES;
      }
    });

    const { paramScope, validParam } =
      createValidScope(newParam, experimentConfig, geometryTags, staticVolumes);

    // Get selected mesh URL using the mesh ID
    const newMesh = await getMeshInfoFromMeshId(meshId);
    if (newMesh) {
      // Set meshUrlState (this tells the viewer which mesh to display and will cause the viewer
      // to refresh)
      setMeshUrlState((oldMeshUrl) => {
        const newMeshUrl = oldMeshUrl.clone();
        newMeshUrl.mesh = newMesh.meshUrl;
        newMeshUrl.meshId = newMesh.id;
        // Switch the viewer to mesh mode as we are changing the mesh
        newMeshUrl.activeType = projectstatepb.UrlType.MESH;
        return newMeshUrl;
      });

      // Change mesh panel to Details mode
      setMeshPanelState(MeshPanelType.DETAILS);

      // Updates the simParam input with the mesh id and url (this is what tells the backend which
      // mesh to use in simulation)
      setInputMeshUrl(validParam.input, newMesh.meshUrl);
      setMeshId(validParam.input, newMesh.id);

      // Load other mesh params into the mesh panel. Convert BL params to adaptation BL params for
      // adaptive meshing.
      const newMeshMultiPart = await cloneMeshParams({ projectId, meshId: newMesh.id });
      if (validParam.adaptiveMeshRefinement) {
        const newProfile = convertBLParamsToAdaptationBLParams(newMeshMultiPart?.blParams);
        validParam.adaptiveMeshRefinement.boundaryLayerProfile = newProfile;
      }

      const newConfig =
        updateSimulationParam(currentConfig, validParam, paramScope, geometryTags, staticVolumes);
      // Update project config, which contains the simParam
      setProjectConfigState(newConfig);
    }
  };

  return { handleMeshSelect };
};
