// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 16;
export const ClipboardCheckIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="clipboardCheck"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M8.835,1.175
           L7.475,1.175
           C7.202,0.42 6.722,0.022 6.069,0.001
           C5.352,-0.023 4.875,0.336 4.521,1.174
           C4.369,1.174 4.217,1.174 4.064,1.174
           C3.758,1.173 3.45,1.173 3.142,1.174
           C2.884,1.175 2.77,1.288 2.769,1.541
           C2.768,1.914 2.768,2.286 2.769,2.659
           C2.769,3.5 2.874,3.5 3.136,3.5
           L8.871,3.5
           C9.128,3.5 9.232,3.5 9.232,2.654
           C9.234,2.293 9.233,1.931 9.232,1.57
           C9.231,1.283 9.124,1.175 8.835,1.175Z
           M11.638,2.166
           C11.816,2.168 11.935,2.276 11.978,2.451
           C11.997,2.53 12,2.615 12,2.696
           L12,15.451
           C12,15.533 11.996,15.618 11.977,15.697
           C11.932,15.882 11.801,15.983 11.609,15.997
           C11.561,16 11.514,16 11.467,16
           C11.455,16 11.443,16 11.431,16
           L0.575,16
           C0.117,16 0,15.881 0,15.42
           C-0.001,11.186 -0.001,6.953 0.001,2.719
           C0.001,2.625 0.006,2.528 0.031,2.439
           C0.078,2.276 0.193,2.168 0.364,2.165
           C0.775,2.16 1.26,2.161 1.681,2.162
           C1.84,2.162 2.001,2.163 2.164,2.163
           C2.164,2.254 2.164,2.465 2.164,2.674
           C2.164,2.879 2.163,3.083 2.163,3.17
           C2.167,3.741 2.551,4.129 3.123,4.129
           C5.041,4.131 6.959,4.131 8.878,4.129
           C9.45,4.129 9.834,3.741 9.837,3.17
           L9.837,2.163
           C10,2.163 10.161,2.163 10.32,2.163
           C10.742,2.162 11.226,2.161 11.638,2.166Z
           M9.469,7.764
           L9.487,7.746
           L9.487,7.747
           C9.635,7.595 9.717,7.389 9.717,7.167
           C9.717,6.72 9.364,6.357 8.93,6.357
           C8.726,6.357 8.537,6.431 8.393,6.568
           L5.795,10.389
           L5.55,10.75
           L5.246,10.437
           L3.771,8.92
           C3.625,8.772 3.427,8.69 3.215,8.69
           C2.78,8.69 2.427,9.053 2.427,9.5
           C2.427,9.722 2.509,9.928 2.657,10.08
           L4.943,12.413
           C5.089,12.562 5.296,12.645 5.501,12.645
           C5.706,12.645 5.897,12.568 6.041,12.429
           L9.454,7.784
           L9.469,7.764Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
