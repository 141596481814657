// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const HeadphonesIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="headphones"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M22.275,9.51
           C21.27,4.08 17.055,0 12,0
           C6.945,0 2.73,4.08 1.725,9.51
           C0.705,10.35 0,12.15 0,14.25
           C0,17.145 1.35,19.5 3,19.5
           C3,21.15 4.35,22.5 6,22.5
           L9,22.5
           C9,23.325 9.675,24 10.5,24
           L13.5,24
           C14.325,24 15,23.325 15,22.5
           L15,21
           C15,20.175 14.325,19.5 13.5,19.5
           L10.5,19.5
           C9.675,19.5 9,20.175 9,21
           L6,21
           C5.175,21 4.5,20.325 4.5,19.5
           C5.325,19.5 6,18.825 6,18
           L6,10.5
           C6,9.825 5.55,9.285 4.935,9.09
           C5.955,5.55 8.715,3 12,3
           C15.285,3 18.045,5.55 19.065,9.09
           C18.45,9.285 18,9.825 18,10.5
           L18,18
           C18,18.825 18.675,19.5 19.5,19.5
           L21,19.5
           C22.65,19.5 24,17.145 24,14.25
           C24,12.15 23.295,10.35 22.275,9.51Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
