// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { AugmentedKind, FormControlSize } from '../../lib/componentTypes/form';
import { getTagColors } from '../../lib/tagUtils';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { XIcon } from '../svg/XIcon';

const disabledColors = getTagColors(true);
const primaryColors = getTagColors(false, 'primary');
const secondaryColors = getTagColors(false, 'secondary');
const tertiaryColors = getTagColors(false, 'tertiary');

const useStyles = makeStyles(
  () => createStyles({
    root: {
      '--content-color': disabledColors.foregroundColor,
      '--base-bg-color': disabledColors.backgroundColor,
      '--close-hover-bg-color': disabledColors.backgroundColor,
      '--font-size': '13px',
      '--font-weight': 400,
      '--line-height': '24px',
      '--border-radius': '4px',

      '&:not(.disabled)': {
        '&.primary': {
          '--content-color': primaryColors.foregroundColor,
          '--base-bg-color': primaryColors.backgroundColor,
          '--close-hover-bg-color': primaryColors.hoverBackgroundColors[1],
          '&:hover': {
            '--base-bg-color': primaryColors.hoverBackgroundColors[0],
          },
        },

        '&.secondary': {
          '--content-color': secondaryColors.foregroundColor,
          '--base-bg-color': secondaryColors.backgroundColor,
          '--close-hover-bg-color': secondaryColors.hoverBackgroundColors[1],
          '&:hover': {
            '--base-bg-color': secondaryColors.hoverBackgroundColors[0],
          },
        },

        '&.tertiary': {
          '--content-color': tertiaryColors.foregroundColor,
          '--base-bg-color': tertiaryColors.backgroundColor,
          '--close-hover-bg-color': tertiaryColors.hoverBackgroundColors[1],
          '&:hover': {
            '--base-bg-color': tertiaryColors.hoverBackgroundColors[0],
          },
        },
      },

      '&.small': {
        '--font-size': '11px',
        '--line-height': '16px',
        '--border-radius': '3px',
        '--font-weight': 600,
      },

      display: 'inline-flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      gap: '0px',
      transition: 'background-color 500ms',
      backgroundColor: 'var(--base-bg-color)',
      color: 'var(--content-color)',
      borderRadius: 'var(--border-radius)',

      '& button': {
        // Reset button styling
        backgroundColor: 'transparent',
        border: 0,
        outline: 0,
        boxShadow: 'none',
        color: 'inherit',
        '&:enabled': {
          cursor: 'pointer',
        },
      },
    },
    tagMain: {
      fontSize: 'var(--font-size)',
      fontWeight: 'var(--font-weight)',
      lineHeight: 'var(--line-height)',
      padding: '0 6px',
    },
    tagMainClosable: {
      paddingRight: '4px',
    },
    closeButton: {
      background: 'transparent',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '0 4px 4px 0',
      transition: 'background-color 500ms, opacity 500ms',
      '.small > &': {
        padding: '0 6px',
      },
      '.medium > &': {
        padding: '6px',
      },
      '&:enabled:hover': {
        backgroundColor: 'var(--close-hover-bg-color)',
      },
      '&:disabled': {
        opacity: 0.4,
      },
    },
  }),
  { name: 'Tag' },
);

interface TagClosing {
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
}

interface TagProps {
  // The text of the tag.
  text: string;
  disabled?: boolean;
  // Color kind
  kind?: AugmentedKind;
  // Function to call if any other part of the tag is clicked.
  onClick?: () => void;
  // Optionally configure a close button
  closing?: TagClosing;
  size?: FormControlSize;
  // Optional tooltip text for main content
  tooltip?: string;
}

// An invidual tag with text.
const Tag = (props: TagProps) => {
  const {
    closing,
    disabled,
    kind = 'primary',
    onClick,
    size = 'medium',
    text,
    tooltip = '',
  } = props;

  const classes = useStyles();

  const iconSize = size === 'medium' ? 8 : 6;

  return (
    <div className={cx(classes.root, kind, size, { disabled })}>
      <button
        className={cx(classes.tagMain, {
          [classes.tagMainClosable]: closing,
        })}
        data-locator="treePanelTagMain"
        disabled={disabled || !onClick}
        onClick={onClick}
        type="button">
        <Tooltip title={tooltip}>
          <div style={{ display: 'flex' }}>
            {text}
          </div>
        </Tooltip>
      </button>
      {closing && (
        <Tooltip title={closing.tooltip}>
          <div style={{ display: 'flex' }}>
            <button
              className={classes.closeButton}
              disabled={disabled || closing.disabled}
              onClick={(event) => {
                event.stopPropagation();
                closing.onClick();
              }}
              type="button">
              <XIcon maxHeight={iconSize} maxWidth={iconSize} />
            </button>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default Tag;
