// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import assert from '../../../../../lib/assert';
import { parsePhysicsIdFromSubId } from '../../../../../lib/physicsUtils';
import { useCommonTreePropsStyles } from '../../../../Theme/commonStyles';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useSelectionContext } from '../../../../context/SelectionManager';
import { useHeatBoundaryConditionContainerNode } from '../../../../hooks/useHeatBoundaryConditionContainer';
import { EmptyPropPanel } from '../../Empty';
import { UndefinedBoundaries } from '../../shared/UndefinedBoundaries';

export const PhysicsHeatBoundaryConditionContainerPropPanel = () => {
  // == Contexts
  const { readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected heat boundary conditions row');

  // == Hooks
  const commonClasses = useCommonTreePropsStyles();
  const physicsId = parsePhysicsIdFromSubId(node.id);
  const {
    typeMenuItems,
    surfacesWithoutBoundaryConditions,
  } = useHeatBoundaryConditionContainerNode(physicsId);

  return (
    <>
      {(!readOnly && surfacesWithoutBoundaryConditions.length) ? (
        <div className={commonClasses.properties}>
          <div className={commonClasses.properties}>
            <UndefinedBoundaries
              nodeId={node.id}
              surfaces={surfacesWithoutBoundaryConditions}
              typeMenuItems={typeMenuItems}
            />
          </div>
        </div>
      ) : (
        <EmptyPropPanel />
      )}
    </>
  );
};
