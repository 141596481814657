// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { NodeType } from '../../lib/simulationTree/node';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem } from '../../lib/treeUtils';
import { useMaterialEntity } from '../../model/hooks/useMaterialEntity';
import { useProjectContext } from '../context/ProjectContext';
import { ContextMenuSection } from '../treePanel/TreeRow';

import { useCopyMaterial } from './nodeDuplication/useCopyMaterial';
import { useNodeDeletion } from './useNodeDeletion';

/**
 * A custom hook for managing a tree node that represents a material
 * @param nodeId
 * @param nodeType
 * @param workflowConfig - optional config fixtures injected by unit tests
 * @returns
 */
export const useMaterialEntityNode = (
  nodeId: string,
  nodeType: NodeType.MATERIAL_FLUID | NodeType.MATERIAL_SOLID,
) => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { canDelete, deleteMaterial, postDeleteNodeIds } = useNodeDeletion();

  // Material models
  const {
    deletionDisabledReason,
  } = useMaterialEntity(projectId, workflowId, jobId, readOnly, nodeId);

  const deleteRow = useCallback(async () => {
    if (await deleteMaterial(nodeId)) {
      postDeleteNodeIds([nodeId]);
    }
  }, [deleteMaterial, nodeId, postDeleteNodeIds]);

  const copyRow = useCopyMaterial();

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];

    const disabled = !canDelete(nodeType, nodeId);
    const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled, deletionDisabledReason);
    const copyItem = duplicateTreeNodeMenuItem(() => copyRow(nodeId), readOnly);
    sections.push({ section: 'crud', menuItems: [copyItem, deleteItem] });

    return sections;
  }, [canDelete, deleteRow, deletionDisabledReason, nodeId, nodeType, copyRow, readOnly]);

  return {
    getExtraContextMenuItems,
  };
};
