// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ActionButton } from '../Button/ActionButton';
import { createStyles, makeStyles } from '../Theme';
import { CheckmarkIcon } from '../svg/CheckmarkIcon';
import { PencilIcon } from '../svg/PencilIcon';
import { XIcon } from '../svg/XIcon';

const ICON_HEIGHT = 8;

const useStyles = makeStyles(
  () => createStyles({
    buttonGroup: {
      display: 'flex',
      gap: '4px',
    },
    icon: {
      height: '18px',
      width: '18px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { name: 'EditButtons' },
);

export interface EditButtonsProps {
  // Whether to show an edit button instead of the default cancel/save buttons
  editMode?: boolean;
  // Optionally disable the edit button
  disableEdit?: boolean;
  // Optionally disabled the save (check) button
  disableSave?: boolean;
  // Called when the cancel (x) button is clicked
  onCancel: () => void;
  // Called when the save (check) button is clicked
  onSave: () => void;
  // Called when the edit (pencil) button is clicked
  onStartEdit: () => void;
  // Optional help text for cancel button
  helpTextCancel?: string;
  // Optional help text for save button
  helpTextSave?: string;
  // Optional help text for edit button
  helpTextEdit?: string;
}

export const EditButtons = (props: EditButtonsProps) => {
  const {
    disableEdit,
    disableSave,
    editMode,
    helpTextCancel = '',
    helpTextEdit = '',
    helpTextSave = '',
    onCancel,
    onSave,
    onStartEdit,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {editMode ? (
        <div className={classes.buttonGroup}>
          <ActionButton
            compact
            kind="secondary"
            onClick={(event) => {
              event.stopPropagation();
              onCancel();
            }}
            size="small"
            title={helpTextCancel}>
            <div className={classes.icon} data-locator="editButtonIcon">
              <XIcon maxHeight={ICON_HEIGHT} />
            </div>
          </ActionButton>
          <ActionButton
            compact
            disabled={disableSave}
            kind="primary"
            onClick={(event) => {
              event.stopPropagation();
              onSave();
            }}
            size="small"
            title={helpTextSave}>
            <div className={classes.icon} data-locator="editButtonIcon">
              <CheckmarkIcon maxHeight={ICON_HEIGHT} />
            </div>
          </ActionButton>
        </div>
      ) : (
        <ActionButton
          compact
          disabled={disableEdit}
          kind="secondary"
          onClick={(event) => {
            event.stopPropagation();
            onStartEdit();
          }}
          size="small"
          title={helpTextEdit}>
          <div className={classes.icon} data-locator="editButtonIcon">
            <PencilIcon maxHeight={ICON_HEIGHT} />
          </div>
        </ActionButton>
      )}
    </div>
  );
};
