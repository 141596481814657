// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/analyzer/analyzer.proto (package luminary.proto.analyzer, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { SimulationParam } from "../client/simulation_pb.js";
import { Output, Result, StoppingCondition, StoppingConditionStatus } from "../output/output_pb.js";
import { Vector3 } from "../base/base_pb.js";
import { ReferenceValues } from "../output/reference_values_pb.js";
import { MeshFileMetadata } from "../lcn/lcmesh_pb.js";

/**
 * @generated from message luminary.proto.analyzer.OutputDirectionRequest
 */
export const OutputDirectionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.analyzer.OutputDirectionRequest",
  () => [
    { no: 1, name: "client_params", kind: "message", T: SimulationParam },
    { no: 2, name: "output", kind: "message", T: Output },
  ],
);

/**
 * @generated from message luminary.proto.analyzer.OutputDirectionReply
 */
export const OutputDirectionReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.analyzer.OutputDirectionReply",
  () => [
    { no: 1, name: "direction", kind: "message", T: Vector3 },
    { no: 2, name: "moment_center", kind: "message", T: Vector3 },
  ],
);

/**
 * @generated from message luminary.proto.analyzer.ComputeOutputRequest
 */
export const ComputeOutputRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.analyzer.ComputeOutputRequest",
  () => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_url_root", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "outputs", kind: "message", T: Output, repeated: true },
    { no: 8, name: "reference_values", kind: "message", T: ReferenceValues },
    { no: 11, name: "mesh_metadata", kind: "message", T: MeshFileMetadata },
    { no: 12, name: "client_params", kind: "message", T: SimulationParam, oneof: "params" },
    { no: 14, name: "solver_params", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "params" },
    { no: 16, name: "use_cache", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.analyzer.ComputeOutputReply
 */
export const ComputeOutputReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.analyzer.ComputeOutputReply",
  () => [
    { no: 4, name: "result", kind: "message", T: Result, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.analyzer.StoppingConditionRequest
 */
export const StoppingConditionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.analyzer.StoppingConditionRequest",
  () => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_url_root", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "condition", kind: "message", T: StoppingCondition, repeated: true },
    { no: 8, name: "reference_values", kind: "message", T: ReferenceValues },
    { no: 10, name: "mesh_metadata", kind: "message", T: MeshFileMetadata },
    { no: 11, name: "client_params", kind: "message", T: SimulationParam, oneof: "params" },
    { no: 14, name: "solver_params", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "params" },
    { no: 15, name: "use_cache", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.analyzer.StoppingConditionReply
 */
export const StoppingConditionReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.analyzer.StoppingConditionReply",
  () => [
    { no: 2, name: "status", kind: "message", T: StoppingConditionStatus },
  ],
);

/**
 * @generated from message luminary.proto.analyzer.ValidateExpressionRequest
 */
export const ValidateExpressionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.analyzer.ValidateExpressionRequest",
  () => [
    { no: 1, name: "expression", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.analyzer.ValidateExpressionResponse
 */
export const ValidateExpressionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.analyzer.ValidateExpressionResponse",
  () => [
    { no: 1, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "errors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.analyzer.ValidateExpressionReply
 */
export const ValidateExpressionReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.analyzer.ValidateExpressionReply",
  () => [
    { no: 3, name: "response", kind: "message", T: ValidateExpressionResponse, repeated: true },
  ],
);

