// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as basepb from '../proto/base/base_pb';
import * as notifpb from '../proto/notification/notification_pb';
import { TabDescription } from '../recoil/useTabsState';

import assert from './assert';
import { IconName } from './componentTypes/svgIcon';
import { fromBigInt } from './number';
import { Logger } from './observability/logs';
import * as rpc from './rpc';

export type Level = 'info' | 'success' | 'warning' | 'error' | 'neutral' | 'fatal';

/**
 * Specifies the different kinds of notifications that can be displayed to the
 * user. NotificationType.INVALID notifications will not be shown.
 */
export enum NotificationType {
  INVALID = 'INVALID',
  JOB_SUCCESS = 'JOB_SUCCESS',
  JOB_FAILURE = 'JOB_FAILURE',
  DOE_PROGRESS = 'DOE_PROGRESS',
  PROJECT_SHARING = 'PROJECT_SHARING'
}

export type JobMessage = {
  type: NotificationType,
  read: boolean,
  projectName: string,
  tabName: string,
  projectId: string,
}

export type DoeMessage = {
  type: NotificationType,
  read: boolean,
  projectName: string,
  doeName: string,
  projectId: string,
  jobsCompleted: number,
  totalJobs: number,
}

export type ProjectShareMessage = {
  type: NotificationType,
  read: boolean,
  projectName: string,
  projectId: string,
  ownerName: string,
}

export type NotificationMessageType = JobMessage | DoeMessage | ProjectShareMessage;

export type NamedAction = {
  name: string;
  // function to be called on clicking the named action button in the notification.
  onClick?: () => void;
  // If link is set, clicking on the named action button will navigate to that link.
  // Overrides onClick.
  link?: string;
}

// Notification in the notification center.
export interface Notification {
  // Notification message. Contains the information needed to determine which message template
  // to use.
  message: NotificationMessageType;
  // Whether or not the notification has been read.
  read: boolean;
  // Timestamp of the notification.
  timestamp: Date;
  // Named action (next step) for the user to take (e.g. download or share something).
  // undefined indicates no named action.
  namedAction?: NamedAction;
  // Implicit action associated with the notification (e.g. view a resource)
  // undefined indicates no implicit action.
  implicitAction?: () => void;
  // If implicitLink is set, clicking on the notification will navigate to that link.
  // Overrides implicitAction.
  implicitLink?: string;
  // Notification ID
  id: string;
  // the metadata from the initial protobuf notification
  metadata?: notifpb.Metadata,
}

export interface LeveledMessage {
  message: string;
  level: Level;
}

// Replacement for frontendpb.Notification for use with transient notifications/toasts.
// For now, pretty much a 1:1 adaptation of the old proto, except using the frontend
// notification levels.
export interface TransientNotification {
  // Unique notification ID.
  id: number;
  // Message is the notification message.
  message: string;
  // Optional error proto.  For console logging.
  err?: basepb.Status;
  // Optional notification title.  If unspecified, the notification level is used as a title.
  title?: string;
  // Time at which this transient notification was created.
  createTime: Date;
  // Notification level
  level: Level;
  // Project name
  projectName?: string;
}

const logger = new Logger('notificationUtils');

export const rankedLevels: Level[] = ['neutral', 'info', 'success', 'warning', 'error', 'fatal'];

// Maps a level to an IconName
export function getIconName(level: Level): IconName | null {
  switch (level) {
    case 'error':
      return 'diskExclamation';
    case 'warning':
      return 'warning';
    case 'success':
      return 'diskCheck';
    case 'info':
      return 'diskInfo';
    default:
      return null;
  }
}

// Converts a numeric rank to a Level type
export function rankToLevel(value: number): Level {
  if (value >= 0 && value < rankedLevels.length) {
    return rankedLevels[value];
  }

  throw Error(`${value} is not a valid level rank`);
}

// Converts a Level type to a numeric rank
export function levelToRank(value: Level): number {
  const rank = rankedLevels.findIndex((item) => item === value);
  if (rank === -1) {
    throw Error(`${value} is not a valid level`);
  }

  return rank;
}

// Returns the Level with the highest rank from a list of Level types
export function mostSevereLevel(levels: Level[]): Level {
  const rank = levels.reduce((result, level) => Math.max(result, levelToRank(level)), -1);

  return rankToLevel(rank);
}

// Returns the highest ranked Level from a list of LeveledMessage types
export function mostSevereMessageLevel(messages: LeveledMessage[]): Level {
  const rank = messages.reduce(
    (result, message) => Math.max(result, levelToRank(message.level)),
    -1,
  );
  return rankToLevel(rank);
}

/** Sends an updateNotification rpc to mark the given notification as 'read' or 'unread'. */
export const updateNotification = (id: string, read: boolean) => {
  const notifId = parseInt(id, 10);
  const req = new notifpb.UpdateRequest({ id: BigInt(notifId), read });

  rpc.callRetry('UpdateNotification', rpc.client.updateNotification, req).catch(
    (err) => {
      // Not a critical error so this shouldn't break things.
      logger.warn('updateNotification error: ', err);
    },
  );
};

/**
 * A default Notification implementation.
 */
export class BaseNotification implements Notification {
  constructor(
    public id: string,
    public message: NotificationMessageType,
    public read: boolean,
    public timestamp: Date,
    public namedAction?: NamedAction,
    public implicitAction?: (() => void),
    public implicitLink?: string,
    public metadata?: notifpb.Metadata,
  ) {
  }
}

export const getNotificationType = (
  eventType: notifpb.EventType,
  level: notifpb.Level,
): NotificationType => {
  switch (eventType) {
    case (notifpb.EventType.SIMULATION_COMPLETE): {
      if (level === notifpb.Level.SUCCESS) {
        return NotificationType.JOB_SUCCESS;
      }
      if (level === notifpb.Level.ERROR) {
        return NotificationType.JOB_FAILURE;
      }
      break;
    }
    case (notifpb.EventType.DOE_PROGRESS): {
      return NotificationType.DOE_PROGRESS;
    }
    case (notifpb.EventType.PROJECT_SHARING): {
      return NotificationType.PROJECT_SHARING;
    }
    default:
      return NotificationType.INVALID;
  }
  return NotificationType.INVALID;
};

/**
 * Returns a NotificationMessage which will be parsed by the NotificationCard to bold certain
 * regions of text.
 */
export const getMessage = (
  type: NotificationType,
  read: boolean,
  ownerName: string | null,
  projectName: string | null,
  tabName: string | null,
  metadata?: notifpb.Metadata,
): NotificationMessageType => {
  if (metadata?.meta.case) {
    switch (type) {
      case NotificationType.JOB_FAILURE:
      case NotificationType.JOB_SUCCESS: {
        assert(
          metadata?.meta.case === 'simulationCompleteMetadata',
          'Completed simulation notification metadata expected when getting message',
        );
        return {
          type,
          read,
          projectName: projectName!,
          tabName: tabName!,
          projectId: metadata.meta.value.projectId,
        };
      }
      case NotificationType.DOE_PROGRESS:
        assert(
          metadata?.meta.case === 'doeProgressMetadata',
          'Exploration progress notification metadata expected when getting message',
        );
        return {
          type,
          read,
          projectName: projectName!,
          doeName: tabName!,
          projectId: metadata.meta.value.projectId,
          jobsCompleted: fromBigInt(
            (metadata.meta.value.jobsSucceeded || 0n) +
            (metadata.meta.value.jobsFailed || 0n),
          ),
          totalJobs: fromBigInt(metadata.meta.value.totalJobs),
        };
      case NotificationType.PROJECT_SHARING:
        assert(
          metadata?.meta.case === 'projectSharingMetadata',
          'Project sharing notification metadata expected when getting message',
        );
        return {
          type,
          read,
          projectName: projectName!,
          projectId: metadata.meta.value.projectId,
          ownerName: ownerName!,
        };
      default: // no default, fall through
    }
  }
  return { type: NotificationType.INVALID, read, projectName: '', tabName: '', projectId: '' };
};

export const getNotificationIcon = (type: NotificationType): IconName => {
  // TODO (LC-10399): decide which icons to use for these event types
  switch (type) {
    case NotificationType.JOB_SUCCESS:
    case NotificationType.JOB_FAILURE:
    case NotificationType.DOE_PROGRESS:
    case NotificationType.PROJECT_SHARING:
      return 'streamArrows';
    default:
      return 'circle';
  }
};

export const getActions = (
  type: NotificationType,
  path: string | null,
): ({
  namedAction?: NamedAction,
  implicitAction?: () => void,
  implicitLink?: string
}
  ) => {
  let namedAction: { name: string, onClick?: (() => void), link?: string } | undefined;
  let implicitAction: (() => void) | undefined;
  let implicitLink: string | undefined;
  switch (type) {
    case NotificationType.JOB_SUCCESS:
    case NotificationType.JOB_FAILURE:
      // For job success and failure, path must be defined.
      namedAction = undefined;
      implicitLink = path!;
      break;
    case NotificationType.DOE_PROGRESS:
      namedAction = undefined;
      implicitLink = path!;
      break;
    case NotificationType.PROJECT_SHARING:
      namedAction = undefined;
      implicitLink = path!;
      break;
    default:
      namedAction = undefined;
      implicitAction = undefined;
      implicitLink = undefined;
  }
  return { namedAction, implicitAction, implicitLink };
};

/**
 * Returns a notification for an EventType.SIMULATION_COMPLETE or
 * EventType.DOE_PROGRESS event.
 */
const newJobNotification = (
  notification: notifpb.Notification,
  tabDescription: TabDescription,
  projectName: string | null,
  metadata?: notifpb.Metadata,
): Notification | null => {
  const { pathName, tabName } = tabDescription;
  const id = `${notification.id}`;
  const read = notification.read;
  const timestamp = notification.createTime!.toDate();

  // Icon, message, and actions are determined by the type of notification.
  const type = getNotificationType(notification.eventType, notification.level);
  if (type === NotificationType.INVALID) {
    return null;
  }
  const { namedAction, implicitAction, implicitLink } = getActions(type, pathName);
  const message = getMessage(
    type,
    read,
    null,
    projectName,
    tabName,
    notification.metadata,
  );

  return new BaseNotification(
    id,
    message,
    read,
    timestamp,
    namedAction,
    implicitAction,
    implicitLink,
    metadata,
  );
};

/**
* Returns a notification for an EventType.PROJECT_SHARING
*/
const newProjectSharingNotification = (
  notification: notifpb.Notification,
  tabDescription: TabDescription,
  ownerName: string | null,
  projectName: string | null,
  metadata?: notifpb.Metadata,
): Notification | null => {
  const { pathName, tabName } = tabDescription;
  const id = `${notification.id}`;
  const read = notification.read;
  const timestamp = notification.createTime!.toDate();

  // Icon, message, and actions are determined by the type of notification.
  const type = getNotificationType(notification.eventType, notification.level);
  if (type === NotificationType.INVALID) {
    return null;
  }
  const { namedAction, implicitAction, implicitLink } = getActions(type, pathName);
  const message = getMessage(
    type,
    read,
    ownerName,
    projectName,
    tabName,
    notification.metadata,
  );

  return new BaseNotification(
    id,
    message,
    read,
    timestamp,
    namedAction,
    implicitAction,
    implicitLink,
    metadata,
  );
};

/**
 * Translates a notifpb.Notification to a frontend Notification object to feed into the
 * Notification Center and display to the user.
 */
export const translateNotification = (
  notification: notifpb.Notification,
  tabDescription: TabDescription,
  ownerName: string | null,
  projectName: string | null,
  metadata?: notifpb.Metadata,
): Notification | null => {
  switch (notification.eventType) {
    case notifpb.EventType.SIMULATION_COMPLETE:
      return newJobNotification(notification, tabDescription, projectName, metadata);
    case notifpb.EventType.DOE_PROGRESS:
      return newJobNotification(notification, tabDescription, projectName, metadata);
    case notifpb.EventType.PROJECT_SHARING:
      return newProjectSharingNotification(
        notification,
        tabDescription,
        ownerName,
        projectName,
        metadata,
      );
    default:
      // Invalid or unsupported notifications should not be displayed.
      return null;
  }
};

/**
 * Sort list of LeveledMessage objects in descending order of severity (errors first)
 * @param messages
 */
export function sortLeveledMessages(messages: LeveledMessage[]) {
  messages.sort((a, b) => levelToRank(b.level) - levelToRank(a.level));
}
