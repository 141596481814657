import { LcvModule } from '../../types';
import { LcvFrame } from '../LcvFrame';
import { LcvObjectList } from '../base/LcvObjectList';

export class LcvArrowAnnotation extends LcvObjectList {
  frame: LcvFrame | null = null;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, lcv.newAnnotation(sessionHandle, 'arrows', 0).annotation, sessionHandle, 1);
  }

  show(frame: LcvFrame | null) {
    if (this.frame === frame) {
      return;
    }
    this.frame?.detachAnnotation('arrows');
    this.frame = frame;
    if (frame) {
      frame?.attachAnnotation('arrows', this);
    }
  }

  hide() {
    this.show(null);
  }
}
