// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useRef, useState } from 'react';

import { listen } from './event';

/**
 * Exposes a getter/setter pair for managing some component's 'open' state, automatically setting
 * 'open' to false if the user clicks away from a DOM node of interest.
 */
export const useClickBasedOpenState = (
  controlRef: React.RefObject<HTMLElement>,
  contentRef: React.RefObject<HTMLElement>,
) => {
  const [open, setOpen] = useState(false);

  const clickHandlers = useRef([
    listen(document, 'click', (event: Event) => {
      // If the click happened on an element outside the content area, then close the menu.
      const targetNode = event.target as HTMLElement;
      if (controlRef.current?.contains(targetNode)) {
        // Clicking the control (e.g. a button) should be ignored.
        return;
      }
      if (open && !contentRef.current?.contains(targetNode)) {
        setOpen(false);
      }
    }),
    listen(document, 'contextmenu', (event: Event) => {
      // Context menu (aka right-click) events don't fire 'click' events, so this handler
      // specifically closes the menu whenever a right-click event fires.
      setOpen(false);
    }),
  ]);

  // Return a cleanup function
  useEffect(() => () => {
    clickHandlers.current.forEach((handler) => handler.remove());
    clickHandlers.current = [];
  }, []);

  return { open, setOpen };
};
