// Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved.
import { DragOffset, FullInset, ScalarInset, normalizeInset } from './dragUtil';
import { Point } from './geometry';
import { clamp } from './number';

export interface DragConstraint {
  constrainOffset: (offset: DragOffset) => DragOffset;
}

type DeltaDirection = 'horizontal' | 'vertical';

export class MinMaxDeltaConstraint implements DragConstraint {
  constructor(
    private minDelta: number,
    private maxDelta: number,
    private direction: DeltaDirection,
  ) {
    // no-op
  }

  public constrainOffset(offset: DragOffset): DragOffset {
    const newOffset = { ...offset };
    if (this.direction === 'horizontal') {
      newOffset.deltaX = clamp(offset.deltaX, [this.minDelta, this.maxDelta]);
    } else {
      newOffset.deltaY = clamp(offset.deltaY, [this.minDelta, this.maxDelta]);
    }
    return newOffset;
  }
}

export class DiscreteValuesConstraint implements DragConstraint {
  constructor(
    private initialValue: Point,
    private stops: Point[],
    private direction?: DeltaDirection,
  ) {
    // no-op
  }

  public constrainOffset(offset: DragOffset): DragOffset {
    const { initialValue, direction, stops } = this;

    const newValue = {
      x: initialValue.x + offset.deltaX,
      y: initialValue.y + offset.deltaY,
    };

    const result: { distance: number, offset: DragOffset } = {
      distance: Infinity,
      offset: { ...offset },
    };

    stops.forEach((stop) => {
      const xDistance = Math.abs(newValue.x - stop.x);
      const yDistance = Math.abs(newValue.y - stop.y);

      if (direction === 'horizontal') {
        if (xDistance < result.distance) {
          result.distance = xDistance;
          result.offset.deltaX = stop.x - initialValue.x;
        }
      } else if (direction === 'vertical') {
        if (yDistance < result.distance) {
          result.distance = yDistance;
          result.offset.deltaY = stop.y - initialValue.y;
        }
      } else {
        const distance = xDistance ** 2 + yDistance ** 2;
        if (distance < result.distance) {
          result.distance = distance;
          result.offset.deltaX = stop.x - initialValue.x;
          result.offset.deltaY = stop.y - initialValue.y;
        }
      }
    });

    return result.offset;
  }
}

export class NestedBoxConstraint implements DragConstraint {
  private targetBox: DOMRect;
  private refBox: DOMRect;
  private offsetLimits: Record<string, number>;

  constructor(
    targetNode: HTMLElement, // node to constrain
    refNode: HTMLElement, // containing node
    inset: ScalarInset | FullInset = 0, // padding applied to containing node's bounding box
  ) {
    this.targetBox = targetNode.getBoundingClientRect();
    this.refBox = refNode.getBoundingClientRect();

    const fullInset = normalizeInset(inset);

    this.offsetLimits = {
      top: (this.refBox.top + fullInset.top) - this.targetBox.top,
      bottom: (this.refBox.bottom - fullInset.bottom) - this.targetBox.bottom,
      left: (this.refBox.left + fullInset.left) - this.targetBox.left,
      right: (this.refBox.right - fullInset.right) - this.targetBox.right,
    };
  }

  public constrainOffset(offset: DragOffset): DragOffset {
    return {
      deltaX: clamp(offset.deltaX, [this.offsetLimits.right, this.offsetLimits.left]),
      deltaY: clamp(offset.deltaY, [this.offsetLimits.bottom, this.offsetLimits.top]),
    };
  }
}
