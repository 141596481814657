// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ActionLink } from '../../components/Button/ActionLink';
import AuthForm, { DataValues } from '../../components/Form/AuthForm';
import { AuthPageLayout, AuthPageState } from '../../components/layout/page/AuthPageLayout';
import { getTempEmail } from '../../lib/AuthV2';
import { SUPPORT_EMAIL } from '../../lib/constants';
import { routes } from '../../lib/navigation';
import { Logger } from '../../lib/observability/logs';
import * as rpc from '../../lib/rpc';
import { isStorybookEnv } from '../../lib/testing/utils';
import useCountdown from '../../lib/useCountdown';
import { isUnixTimestampOlderThan48Hours } from '../../lib/usermanagementUtils';
import * as frontendpb from '../../proto/frontend/frontend_pb';

const logger = new Logger('recoil/cameraState');

interface SetPasswordPageProps {
  // Optional initial page state. This should be used ONLY for the storybook
  initialPageState?: AuthPageState;
}

const SetPasswordPage = (props: SetPasswordPageProps) => {
  // Props
  const { initialPageState = AuthPageState.INITIALIZING } = props;

  // Hooks
  const navigate = useNavigate();

  // State
  const [pageState, setPageState] = useState(initialPageState);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [fieldsError, setFieldsError] = useState<DataValues>({});
  const { completed, secondsLeft, startCountdown } = useCountdown(5);

  // Other state
  const ticketId = new URLSearchParams(window.location.search).get('ticketid') || '';
  const email = getTempEmail();

  // Effects
  useEffect(() => {
    if (isStorybookEnv()) {
      return;
    }

    if (props.initialPageState !== undefined) {
      throw Error('Do not use the initialPageState outside of storybook env.');
    }

    const timestamp = new URLSearchParams(window.location.search).get('t') || '';
    if (isUnixTimestampOlderThan48Hours(Number(timestamp))) {
      logger.error(`Expired timestamp for resetting password: ${timestamp}`);
      setPageState(AuthPageState.INVALID_LINK);
      return;
    }

    const req = new frontendpb.UseTicketRequest({ ticketId });
    rpc.callRetry('UseTicket', rpc.client.useTicket, req).then(
      () => setPageState(AuthPageState.FORM),
    ).catch(
      () => setPageState(AuthPageState.INVALID_LINK),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageState === AuthPageState.DONE) {
      startCountdown();
    }
  }, [pageState, startCountdown]);

  useEffect(() => {
    if (completed) {
      navigate(routes.login, { replace: true });
    }
  }, [completed, navigate]);

  // Handlers
  const handleChange = (data: DataValues) => {
    if (data.password2.trim() !== '' && data.password1 !== data.password2) {
      setFieldsError({ password2: 'Passwords must match' });
    } else {
      setFieldsError({});
    }
  };

  const handleSubmit = async (data: Record<string, string>) => {
    if (data.password1.trim() === '') {
      setError('Password cannot be empty');
      return;
    }
    if (data.password1 !== data.password2) {
      setError('Passwords must match');
      return;
    }
    setError('');
    setSubmitting(true);
    try {
      const req = new frontendpb.SetPasswordRequest({ ticketId, password: data.password1 });
      await rpc.callRetry('SetPassword', rpc.client.setPassword, req);
      setPageState(AuthPageState.DONE);
    } catch (err) {
      setError(err.message || 'Password change failed. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  if (pageState === AuthPageState.INITIALIZING) {
    return <AuthPageLayout loading />;
  }

  if (pageState === AuthPageState.INVALID_LINK) {
    return (
      <AuthPageLayout
        backToLogin
        subtitle="The link that you used is invalid.
            Please request a new one using the forgot password button"
        title="Reset Your Password"
      />
    );
  }

  if (pageState === AuthPageState.DONE) {
    return (
      <AuthPageLayout
        backToLogin
        footer={(
          <>
            Having problems?&nbsp;
            <ActionLink href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</ActionLink>
          </>
        )}
        subtitle={`You will be redirected to a login screen in ${secondsLeft} seconds ...`}
        title="Success! Your password has been reset."
      />
    );
  }

  return (
    <AuthPageLayout
      subtitle={email ? <>Enter a new password for <b>{email}</b></> : 'Enter a new password'}
      title="Reset Your Password">
      <AuthForm
        fields={[
          {
            asBlock: true,
            label: 'Password',
            disabled: submitting,
            name: 'password1',
            placeholder: 'Enter new password...',
            required: true,
            strength: true,
            type: 'password',
          },
          {
            asBlock: true,
            label: 'Confirm Password',
            disabled: submitting,
            name: 'password2',
            placeholder: 'Confirm your new password...',
            required: true,
            type: 'password',
          },
        ]}
        fieldsError={fieldsError}
        globalError={error}
        onChange={handleChange}
        onSubmit={handleSubmit}
        submit={{
          disabled: submitting,
          showSpinner: submitting,
          label: 'Save Password',
        }}
      />
    </AuthPageLayout>
  );
};

export default SetPasswordPage;
