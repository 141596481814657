// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';

import * as random from '../random';

export const localhost = 'localhost';
export const serviceName = 'ts/frontend (browser)';
export const resource = Resource.default().merge(
  new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: serviceName,
  }),
);

/**
 * An id which uniquely identifies the current session.
 * This will be reset every time the page is reloaded.
 * */
export const SESSION_ID = random.string(32);
