// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 21;
const baseHeight = 24;
export const BellOutlinedIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="bellOutlined"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M19.5,19.5
           C19.938,19.5 20.297,19.352 20.578,19.055
           C20.859,18.758 21,18.414 21,18.023
           C21,17.633 20.859,17.281 20.578,16.969
           C20.547,16.938 20.5,16.875 20.438,16.781
           C19.719,16.031 19.188,15.297 18.844,14.578
           C18.281,13.328 18,11.719 18,9.75
           C18,7.938 17.438,6.359 16.313,5.016
           C15.188,3.672 13.75,2.828 12,2.484
           L12,1.5
           C12,1.094 11.852,0.742 11.555,0.445
           C11.258,0.148 10.906,0 10.5,0
           C10.094,0 9.742,0.148 9.445,0.445
           C9.149,0.742 9,1.094 9,1.5
           L9,2.484
           C7.25,2.828 5.813,3.672 4.688,5.016
           C3.563,6.359 3,7.938 3,9.75
           C3,11.719 2.719,13.328 2.156,14.578
           C1.813,15.297 1.281,16.031 0.563,16.781
           L0.422,16.969
           C0.141,17.281 0,17.633 0,18.023
           C0,18.414 0.141,18.758 0.422,19.055
           C0.703,19.352 1.063,19.5 1.5,19.5
           L19.5,19.5Z
           M17.813,17.25
           L3.188,17.25
           C4.563,15.406 5.25,12.922 5.25,9.797
           L5.25,9.75
           C5.25,8.813 5.484,7.938 5.953,7.125
           C6.422,6.313 7.063,5.672 7.875,5.203
           C8.688,4.734 9.563,4.5 10.5,4.5
           C11.438,4.5 12.313,4.734 13.125,5.203
           C13.938,5.672 14.578,6.313 15.047,7.125
           C15.516,7.938 15.75,8.813 15.75,9.75
           L15.75,9.797
           C15.75,12.922 16.438,15.406 17.813,17.25Z
           M10.5,24
           C11.313,24 12.016,23.711 12.609,23.133
           C13.203,22.555 13.5,21.844 13.5,21
           L7.5,21
           C7.5,21.844 7.797,22.555 8.391,23.133
           C8.984,23.711 9.688,24 10.5,24Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
