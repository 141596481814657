// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily, atomWithStorage, createJSONStorage } from 'jotai/utils';

// Generate a unique key for the project and job
const getStorageKey = (projectId: string) => `last-opened-results-tab-${projectId}`;

// Switch to sessionStorage instead of default localStorage
const storage = createJSONStorage(() => sessionStorage);

const lastOpenedResultsTabState = atomFamily(
  (projectId: string) => atomWithStorage(getStorageKey(projectId), '', storage),
);

export function useLastOpenedResultsTab(projectId: string) {
  return useAtom(lastOpenedResultsTabState(projectId));
}

export function useLastOpenedResultsTabValue(projectId: string) {
  return useAtomValue(lastOpenedResultsTabState(projectId));
}

export function useSetLastOpenedResultsTab(projectId: string) {
  return useSetAtom(lastOpenedResultsTabState(projectId));
}
