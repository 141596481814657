// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { createStyles, makeStyles } from '../Theme';

export interface CollapsibleProps {
  collapsed: boolean;
  transitionPeriod?: number;
  children: ReactNode;
  /** If true, overflow is set to 'visible' allowing the parent to handle overflow  */
  allowOverflow?: boolean;
  /** If true, overflow is set to 'hidden' so that elements don't overlap other elements */
  overflowHidden?: boolean;
}

const defaultTransitionPeriod = 500;
const collapsedOpacity = 0;
const collapsedHeight = 0;
const collapsedHeightPx = `${collapsedHeight}px`;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      overflow: 'hidden',
      opacity: 1,
      transitionProperty: 'opacity, height',
      transitionDuration: `var(--period, ${defaultTransitionPeriod}ms)`,
      padding: 0,
      '&.collapsed': {
        opacity: collapsedOpacity,
      },
      '&.allowOverflow:not(.collapsed)': {
        overflow: 'visible',
      },
    },
    innerContent: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      '&.allowOverflow': {
        overflow: 'visible',
      },
      '&.overflowHidden': {
        overflow: 'hidden',
      },
    },
  }),
  { name: 'CollapsibleTransition' },
);

// A component that manages the height of its child content based on a
// 'collapsed' property.  When collapsed, the height of the content is set to 0;
// otherwise, the height is set to its natural offsetHeight value and then, when
// the transition is complete, it's set to '' (or auto).
const Collapsible = (props: CollapsibleProps) => {
  const {
    collapsed,
    allowOverflow,
    transitionPeriod = defaultTransitionPeriod,
    overflowHidden,
  } = props;

  // Tracks if the content is in its initial state
  const [initialState, setInitialState] = useState(true);
  // Tracks the height of the root component
  const [height, setHeight] = useState('');
  // Tracks when the height should be transitioning
  const [transition, setTransition] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  // When height transition ends, if collapsed is false (i.e. expanded), set
  // height to '' (same as 'auto')
  const handleFinishTransition = useCallback(() => {
    if (!collapsed) {
      setHeight('');
    }
    setTransition(false);
  }, [collapsed]);

  useEffect(() => {
    let tmt: ReturnType<typeof setTimeout> | null = null;
    if (transition) {
      tmt = setTimeout(handleFinishTransition, transitionPeriod);
    }
    return () => {
      if (tmt) {
        clearTimeout(tmt);
      }
    };
  }, [handleFinishTransition, transition, transitionPeriod]);

  useEffect(() => {
    const naturalHt = containerRef.current ?
      containerRef.current.offsetHeight : collapsedHeight;
    const naturalHtPx = `${naturalHt}px`;

    if (collapsed) {
      if (height || initialState) {
        // if height is already set, we've caught things in mid-transition, so
        // we don't need to set a starting height.  Or if initialState is true,
        // we don't want to transition to 0--just set it right away.
        setHeight(collapsedHeightPx);
        setTransition(true);
      } else {
        // Set a starting height (since we can't animate from 'auto') and then
        // set to 0 in the next frame
        setHeight(naturalHtPx);
        window.requestAnimationFrame(() => {
          setHeight(collapsedHeightPx);
          setTransition(true);
        });
      }
    } else {
      // If we're attempting to expand, we would expect height to be set to
      // '0px'; if it's an empty string, then this is the initial call of a
      // just-mounted component, and we don't need to do anything.
      /* eslint-disable-next-line no-lonely-if */
      if (height) {
        setHeight(naturalHtPx);
        setTransition(true);
      }
    }

    setInitialState(false);
  }, [collapsed, height, initialState]);

  const classes = useStyles();

  const style = { height, '--period': `${transitionPeriod}ms` };
  return (
    <div
      className={cx(classes.root, { collapsed, allowOverflow })}
      style={style}>
      <div
        className={cx(classes.innerContent, { allowOverflow, overflowHidden })}
        ref={containerRef}>
        {!collapsed && props.children}
      </div>
    </div>
  );
};

export default Collapsible;
