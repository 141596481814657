// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { Vector3 } from '../../../proto/base/base_pb';
import { useBoxRepresentationPref } from '../../../recoil/form/boxRepresentationPref';
import LabeledInput from '../LabeledInput';
import { CenterInput } from '../PrimitiveInputs/CenterInput';
import { LengthVectorInput } from '../PrimitiveInputs/LengthVectorInput';
import { RadioButtonGroup } from '../RadioButtonGroup';
import { ValidVector3Input } from '../ValidatedInputs/ValidVector3Input';

export type Box = {
  center: { x: number, y: number, z: number },
  size: { x: number, y: number, z: number },
}

export interface BoxInputProps {
  value: Box,
  onCommit: (value: Box) => void,
  disabled?: boolean,
}

export const BoxInput = (props: BoxInputProps) => {
  const { value, onCommit, disabled = false } = props;
  const { center, size } = value;
  const [representation, setRepresentation] = useBoxRepresentationPref();

  const handleCenterChange = (newCenter: Vector3) => {
    onCommit({ ...value, center: { x: newCenter.x, y: newCenter.y, z: newCenter.z } });
  };

  const handleSizeChange = (newSize: Vector3) => {
    onCommit({ ...value, size: { x: newSize.x, y: newSize.y, z: newSize.z } });
  };

  const bottomLeft = useMemo(() => new Vector3({
    x: center.x - size.x / 2,
    y: center.y - size.y / 2,
    z: center.z - size.z / 2,
  }), [center, size]);

  const topRight = useMemo(() => new Vector3({
    x: center.x + size.x / 2,
    y: center.y + size.y / 2,
    z: center.z + size.z / 2,
  }), [center, size]);

  const handleBottomLeftChange = (newBottomLeft: Vector3) => {
    const newSize = {
      x: topRight.x - newBottomLeft.x,
      y: topRight.y - newBottomLeft.y,
      z: topRight.z - newBottomLeft.z,
    };
    const newCenter = {
      x: newBottomLeft.x + newSize.x / 2,
      y: newBottomLeft.y + newSize.y / 2,
      z: newBottomLeft.z + newSize.z / 2,
    };
    onCommit({ center: newCenter, size: newSize });
  };

  const handleTopRightChange = (newTopRight: Vector3) => {
    const newSize = {
      x: newTopRight.x - bottomLeft.x,
      y: newTopRight.y - bottomLeft.y,
      z: newTopRight.z - bottomLeft.z,
    };
    const newCenter = {
      x: bottomLeft.x + newSize.x / 2,
      y: bottomLeft.y + newSize.y / 2,
      z: bottomLeft.z + newSize.z / 2,
    };
    onCommit({ center: newCenter, size: newSize });
  };

  const representationInput = (
    <LabeledInput
      help="Whether to define the box using its center or vertices"
      label="Box representation">
      <RadioButtonGroup
        disabled={disabled}
        kind="secondary"
        name="box-representation"
        onChange={(newRepr) => setRepresentation(newRepr)}
        options={[
          { label: 'Center', value: 'center' },
          { label: 'Corners', value: 'corners' },
        ]}
        value={representation}
      />
    </LabeledInput>
  );

  if (representation === 'corners') {
    return (
      <>
        {representationInput}
        <LabeledInput label="Bottom Left">
          <ValidVector3Input
            disabled={disabled}
            onCommit={handleBottomLeftChange}
            value={bottomLeft}
          />
        </LabeledInput>
        <LabeledInput label="Top Right">
          <ValidVector3Input
            disabled={disabled}
            onCommit={handleTopRightChange}
            value={topRight}
          />
        </LabeledInput>
      </>
    );
  }

  return (
    <>
      {representationInput}
      <CenterInput
        disabled={disabled}
        label="Center"
        onCommit={handleCenterChange}
        value={new Vector3({ ...center })}
      />
      <LengthVectorInput
        disabled={disabled}
        label="Lengths"
        onCommit={handleSizeChange}
        value={new Vector3({ ...size })}
      />
    </>
  );
};
