// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { Vector3 } from '../../../proto/base/base_pb';
import LabeledInput from '../LabeledInput';
import { ValidVector3Input, Vec3FaultInfo } from '../ValidatedInputs/ValidVector3Input';

export interface StartEndInputProps {
  disabled?: boolean,
  startProps: {
    label?: string,
    help?: string,
    value: { x: number, y: number, z: number },
    onCommit: (value: { x: number, y: number, z: number }) => void,
  },
  endProps: {
    label?: string,
    help?: string,
    value: { x: number, y: number, z: number },
    onCommit: (value: { x: number, y: number, z: number }) => void,
  },
}

/**
 * Input for 2 points which cannot be the same point.
 */
export const StartEndInput = (props: StartEndInputProps) => {
  const { startProps, endProps, disabled } = props;
  const {
    value: startVal,
    label: startLabel = 'Start',
    help: startHelp,
  } = startProps;
  const {
    value: endVal,
    label: endLabel = 'End',
    help: endHelp,
  } = endProps;

  const handleStartCommit = (start: Vector3) => {
    startProps.onCommit({ x: start.x, y: start.y, z: start.z });
  };

  const handleEndCommit = (end: Vector3) => {
    endProps.onCommit({ x: end.x, y: end.y, z: end.z });
  };

  const validatePoints = (start: Vector3, end: Vector3): Vec3FaultInfo | undefined => {
    if (start.x === end.x && start.y === end.y && start.z === end.z) {
      return {
        type: { x: 'error', y: 'error', z: 'error' },
        message: 'Start and end points cannot be the same',
      };
    }
    return undefined;
  };

  const validateStart = (start: Vector3): Vec3FaultInfo | undefined => validatePoints(
    start,
    new Vector3({ ...endVal }),
  );

  const validateEnd = (end: Vector3): Vec3FaultInfo | undefined => validatePoints(
    new Vector3({ ...startVal }),
    end,
  );

  return (
    <>
      <LabeledInput help={startHelp} label={startLabel}>
        <ValidVector3Input
          disabled={disabled}
          onCommit={handleStartCommit}
          validate={validateStart}
          value={new Vector3({ ...startVal })}
        />
      </LabeledInput>
      <LabeledInput help={endHelp} label={endLabel}>
        <ValidVector3Input
          disabled={disabled}
          onCommit={handleEndCommit}
          validate={validateEnd}
          value={new Vector3({ ...endVal })}
        />
      </LabeledInput>
    </>
  );
};
