// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 13;
const baseHeight = 30;
export const ThermometerIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="thermometer"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve">
      <path
        d="M6.5,30C5.634,30 4.793,29.83 4.001,29.495C3.236,29.172 2.55,28.709 1.961,28.119
           C1.371,27.53 0.908,26.844 0.585,26.079C0.25,25.288 0.08,24.447 0.08,23.58
           C0.08,22.554 0.329,21.533 0.799,20.627C1.203,19.848 1.772,19.151 2.455,18.596
           L2.455,3.059C2.455,1.372 3.827,0 5.514,0L7.486,0C9.173,0 10.545,1.372 10.545,3.059
           L10.545,18.596C11.228,19.151 11.797,19.848 12.201,20.627
           C12.671,21.533 12.92,22.554 12.92,23.58C12.92,24.447 12.75,25.288 12.415,26.079
           C12.092,26.844 11.629,27.53 11.039,28.119C10.45,28.709 9.764,29.172 8.999,29.495
           C8.207,29.83 7.366,30 6.5,30ZM5.514,1.26C4.522,1.26 3.715,2.067 3.715,3.059L3.715,4.812
           L4.221,4.812C4.466,4.812 4.665,5.011 4.665,5.257L4.665,5.742
           C4.665,5.988 4.466,6.187 4.221,6.187L3.715,6.187L3.715,7.712L4.222,7.712
           C4.467,7.712 4.665,7.911 4.665,8.157L4.665,8.642C4.665,8.888 4.467,9.087 4.222,9.087
           L3.715,9.087L3.715,10.637L4.222,10.637C4.468,10.637 4.666,10.837 4.666,11.082
           L4.666,11.567C4.666,11.813 4.468,12.012 4.222,12.012L3.715,12.012L3.715,13.75
           L9.285,13.75L9.285,3.059C9.285,2.067 8.478,1.26 7.486,1.26L5.514,1.26ZM4.564,23.012
           C4.647,22.522 4.887,22.089 5.258,21.76C5.506,21.54 5.526,21.162 5.309,20.911
           C5.059,20.623 4.583,20.765 4.334,20.986C3.764,21.491 3.505,22.059 3.378,22.812
           C3.31,23.213 3.315,23.618 3.392,24.014C3.448,24.302 3.771,24.553 4.097,24.49
           C4.423,24.426 4.636,24.11 4.573,23.784C4.523,23.53 4.52,23.27 4.564,23.012Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
