// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { SetterOrUpdater } from 'recoil';

import assert from '../../../../lib/assert';
import { nullableMeshing } from '../../../../lib/mesh';
import { formatNumber } from '../../../../lib/number';
import {
  axesToEulerAngles,
  boxDefinitionToRefineRegionDefinition,
  eulerAnglesToAxes,
  getCurrentRrParam,
  getHLimitBounds,
  getShapeName,
  refineRegionDefinitionToBoxDefinition,
  updateRrParamNumber,
  updateRrParamVector,
} from '../../../../lib/refinementRegionUtils';
import * as basepb from '../../../../proto/base/base_pb';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import * as meshgenerationpb from '../../../../proto/meshgeneration/meshgeneration_pb';
import * as quantitypb from '../../../../proto/quantity/quantity_pb';
import { useMeshReadOnly } from '../../../../recoil/useMeshReadOnly';
import useMeshMultiPart, { useSetMeshMultiPart } from '../../../../recoil/useMeshingMultiPart';
import { useSetRefinementRegionSelection } from '../../../../recoil/useRefinementRegions';
import { useSimulationParam } from '../../../../state/external/project/simulation/param';
import { BoxInput } from '../../../Form/CompositeInputs/BoxInput';
import { InputDescription } from '../../../Form/InputDescription';
import LabeledInput from '../../../Form/LabeledInput';
import { NumberField } from '../../../Form/NumberField';
import { Vector3Input } from '../../../Form/Vector3Input';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import QuantityAdornment from '../../../QuantityAdornment';
import Divider from '../../../Theme/Divider';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import ValidatedNumberSpinner from '../../../controls/ValidatedNumberSpinner';
import { SimpleSlider } from '../../../controls/slider/SimpleSlider';
import PropertiesSection from '../../PropertiesSection';
import { EmptyPropPanel } from '../Empty';

type SizingSectionProps = {
  id: string,
  readOnly: boolean,
  currentRrParam: meshgenerationpb.MeshingMultiPart_RefinementRegionParams,
  setMeshMultiPart: SetterOrUpdater<nullableMeshing>,
  meshMultiPart: meshgenerationpb.MeshingMultiPart,
}

const SizingSection = (props: SizingSectionProps) => {
  const { id, readOnly, currentRrParam, setMeshMultiPart, meshMultiPart } = props;
  const [displayValue, setDisplayValue] = useState(currentRrParam.hLimit);

  const { maxSize, minSize } = getHLimitBounds(meshMultiPart);

  const validate = (newValue: number) => {
    if (newValue < 0) {
      return 'Must be > 0.';
    }
    // When the NumberField is blurred and the value is at its max or min,
    // the input value displayed will sometimes be rounded to an out-of-bounds value. So here we
    // need to parse the formatted input value to check that it's still within bounds, even if
    // it is being displayed as something out of bounds.
    if (newValue > parseFloat(formatNumber(maxSize))) {
      return 'Must be less than the Maximum Mesh Size';
    } if (newValue < parseFloat(formatNumber(minSize))) {
      return 'Must be greater than the Minimum Mesh Size';
    }
    return '';
  };

  const [warning, setWarning] = useState(displayValue ? validate(displayValue) : '');

  // Rerendering the SizingSection when we switch nodes (e.g. from Box 1 to Box 2) doesn't
  // reset its state, so we must manually set displayValue to the hLimit of the newly selected node.
  useEffect(() => {
    setDisplayValue(currentRrParam.hLimit);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // update the meshMultiPart proto when displayValue changes.
  // It's here so we can debounce it later.
  useEffect(() => {
    if (!readOnly) {
      updateRrParamNumber(setMeshMultiPart, id, displayValue, 'Size');
    }
  }, [displayValue, id, readOnly, setMeshMultiPart]);

  return (
    <CollapsibleNodePanel
      heading="Sizing"
      nodeId={`sizing-${id}`}
      panelName="sizing">
      <LabeledInput label="Max Size">
        <NumberField
          asBlock
          disabled={readOnly}
          endAdornment={<QuantityAdornment quantity={quantitypb.QuantityType.LENGTH} />}
          faultType={warning ? 'error' : undefined}
          isInput
          onBlur={() => setWarning('')}
          onChange={(newVal: number) => {
            setWarning(validate(newVal));
          }}
          onCommit={(newValue: number) => {
            if (warning) {
              return;
            }
            setDisplayValue(newValue);
          }}
          readOnly={readOnly}
          size="small"
          value={displayValue}
        />
        {warning && (
          <div style={{ paddingTop: '8px' }}>
            <InputDescription faultType="error" value={warning} />
          </div>
        )}
      </LabeledInput>
      <LabeledInput label="">
        <div style={{ padding: '0 8px' }}>
          <SimpleSlider
            disabled={readOnly}
            gutterHeight={4}
            max={maxSize}
            min={Math.max(minSize, 0)}
            onChange={(newValue) => {
              if (newValue > maxSize) {
                setDisplayValue(maxSize);
              } else if (newValue < minSize) {
                setDisplayValue(minSize);
              } else {
                setDisplayValue(newValue);
              }
            }}
            readoutConfig={{ disabled: true }}
            value={displayValue}
          />
        </div>
      </LabeledInput>
    </CollapsibleNodePanel>
  );
};

interface ShapeSectionProps {
  id: string,
  readOnly: boolean,
  currentRrParam: meshgenerationpb.MeshingMultiPart_RefinementRegionParams,
  setMeshMultiPart: SetterOrUpdater<nullableMeshing>,
}

const BoxSection = (props: ShapeSectionProps) => {
  const { id, readOnly, currentRrParam, setMeshMultiPart } = props;
  assert(currentRrParam.shape.case === 'orientedCube', 'BoxSection only valid for OrientedCube');
  const cubeParam = currentRrParam.shape.value!;

  const rotation = useMemo(() => {
    // If we have a rotation field, we're in the setup tab and preserve the user's exact input.
    if (currentRrParam.rotation) {
      return currentRrParam.rotation!;
    }
    // If not, we're in a simulation tab and we don't have access to the user's exact input.
    // So we have to call getDisplayValues to get some valid euler angles for the orientation field
    // (which might not be identical to what the user originally entered, since euler angles are
    // not unique).
    return axesToEulerAngles(cubeParam.xAxis!, cubeParam.yAxis!);
  }, [currentRrParam, cubeParam]);

  const handleRotation = (newRotation: basepb.Vector3) => {
    const { xAxis, yAxis } = eulerAnglesToAxes(newRotation);
    setMeshMultiPart((oldMeshMultiPart) => {
      const newMeshMultiPart = oldMeshMultiPart!.clone();
      const param = getCurrentRrParam(id, newMeshMultiPart)!;
      assert(param.shape.case === 'orientedCube', 'BoxSection handleRotation must be OrientedCube');
      param.rotation = newRotation;
      const box = param.shape.value;
      box.xAxis = xAxis;
      box.yAxis = yAxis;
      return newMeshMultiPart;
    });
  };

  return (
    <>
      <BoxInput
        onCommit={(box) => {
          const refineRegion = boxDefinitionToRefineRegionDefinition(box);

          updateRrParamVector(
            setMeshMultiPart,
            id,
            new basepb.Vector3(refineRegion.origin),
            'Origin',
          );
          updateRrParamVector(
            setMeshMultiPart,
            id,
            new basepb.Vector3(refineRegion.max),
            'CubeMax',
          );
        }}
        value={refineRegionDefinitionToBoxDefinition(cubeParam)}
      />
      <LabeledInput
        help="Euler angles defining the rotation of the box."
        label="Orientation">
        <Vector3Input
          disabled={readOnly}
          onCommit={handleRotation}
          quantityType={quantitypb.QuantityType.DEGREE}
          value={rotation}
        />
      </LabeledInput>
    </>
  );
};

const SphereSection = (props: ShapeSectionProps) => {
  const { id, readOnly, currentRrParam, setMeshMultiPart } = props;
  assert(currentRrParam.shape.case === 'sphereShell', 'SphereSection only valid for SphereShell');
  const sphereParam = currentRrParam.shape.value!;

  return (
    <>
      <LabeledInput
        label="Center">
        <Vector3Input
          disabled={readOnly}
          onCommit={(newValue: basepb.Vector3) => {
            updateRrParamVector(setMeshMultiPart, id, newValue, 'Center');
          }}
          value={sphereParam.center!}
        />
      </LabeledInput>
      <LabeledInput
        label="Outer Radius">
        <ValidatedNumberSpinner
          disabled={readOnly}
          endAdornment={<QuantityAdornment quantity={quantitypb.QuantityType.LENGTH} />}
          messageOnError={(newValue: number) => {
            if (newValue < 0) {
              return 'Must be > 0.';
            }
            const innerRadius = sphereParam.radiusInner;
            if (newValue < innerRadius) {
              return 'Must be > inner radius.';
            }
            return '';
          }}
          minimumValue={Math.max(sphereParam.radiusInner, 0)}
          onCommit={(newValue: number) => {
            updateRrParamNumber(setMeshMultiPart, id, newValue, 'SphereRadiusOuter');
          }}
          step={{ scaleFactor: 2 }}
          value={sphereParam.radius}
        />
      </LabeledInput>
      <LabeledInput
        label="Inner Radius">
        <ValidatedNumberSpinner
          disabled={readOnly}
          endAdornment={<QuantityAdornment quantity={quantitypb.QuantityType.LENGTH} />}
          maximumValue={sphereParam.radius}
          messageOnError={(newValue: number) => {
            if (newValue < 0) {
              return 'Must be > 0.';
            }
            const outerRadius = sphereParam.radius;
            if (newValue > outerRadius) {
              return 'Must be < outer radius.';
            }
            return '';
          }}
          minimumValue={0}
          onCommit={(newValue: number) => {
            updateRrParamNumber(setMeshMultiPart, id, newValue, 'SphereRadiusInner');
          }}
          step={{ scaleFactor: 2 }}
          value={sphereParam.radiusInner}
        />
      </LabeledInput>
    </>
  );
};

const CylinderSection = (props: ShapeSectionProps) => {
  const { id, readOnly, currentRrParam, setMeshMultiPart } = props;
  assert(
    currentRrParam.shape.case === 'annularCylinder',
    'CylinderSection only valid for AnnularCylinder',
  );
  const cylinderParam = currentRrParam.shape.value!;

  return (
    <>
      <LabeledInput
        help="Location of the cylinder's first base's center."
        label="Start">
        <Vector3Input
          disabled={readOnly}
          onCommit={(newValue: basepb.Vector3) => {
            updateRrParamVector(setMeshMultiPart, id, newValue, 'Start');
          }}
          value={cylinderParam.start!}
        />
      </LabeledInput>
      <LabeledInput
        help="Location of the cylinder's second base's center."
        label="End">
        <Vector3Input
          disabled={readOnly}
          onCommit={(newValue: basepb.Vector3) => {
            updateRrParamVector(setMeshMultiPart, id, newValue, 'End');
          }}
          value={cylinderParam.end!}
        />
      </LabeledInput>
      <LabeledInput
        label="Outer Radius">
        <ValidatedNumberSpinner
          disabled={readOnly}
          endAdornment={<QuantityAdornment quantity={quantitypb.QuantityType.LENGTH} />}
          messageOnError={(newValue: number) => {
            if (newValue < 0) {
              return 'Must be > 0.';
            }
            const innerRadius = cylinderParam.radiusInner;
            if (newValue <= innerRadius) {
              return 'Must be > inner radius.';
            }
            return '';
          }}
          minimumValue={cylinderParam.radiusInner}
          onCommit={(newValue: number) => {
            updateRrParamNumber(setMeshMultiPart, id, newValue, 'CylRadiusOuter');
          }}
          step={{ scaleFactor: 2 }}
          value={cylinderParam.radius}
        />
      </LabeledInput>
      <LabeledInput
        label="Inner Radius">
        <ValidatedNumberSpinner
          disabled={readOnly}
          endAdornment={<QuantityAdornment quantity={quantitypb.QuantityType.LENGTH} />}
          maximumValue={cylinderParam.radius}
          messageOnError={(newValue: number) => {
            if (newValue < 0) {
              return 'Must be > 0.';
            }
            const outerRadius = cylinderParam.radius;
            if (newValue >= outerRadius) {
              return 'Must be < outer radius.';
            }
            return '';
          }}
          minimumValue={0}
          onCommit={(newValue: number) => {
            updateRrParamNumber(setMeshMultiPart, id, newValue, 'CylRadiusInner');
          }}
          step={{ scaleFactor: 2 }}
          value={cylinderParam.radiusInner}
        />
      </LabeledInput>
    </>
  );
};

interface SectionProps extends ShapeSectionProps {
  param: simulationpb.SimulationParam,
}

const ShapeSection = (props: SectionProps) => {
  const { id, currentRrParam } = props;
  const nodeId = `shapeDef-${id}`;
  const nodeName = 'ShapeDefinition';
  const currentShape = getShapeName(currentRrParam);

  const shapeParamSection = useMemo(() => {
    switch (currentShape) {
      case 'Sphere':
        return <SphereSection {...props} />;
      case 'Cylinder':
        return <CylinderSection {...props} />;
      case 'Box':
        return <BoxSection {...props} />;
      default:
        return <></>;
    }
  }, [currentShape, props]);

  return (
    <PropertiesSection>
      <CollapsibleNodePanel
        heading="Shape Definition"
        nodeId={nodeId}
        panelName={nodeName}>
        {shapeParamSection}
      </CollapsibleNodePanel>
    </PropertiesSection>
  );
};

export const RefinementRegionPropPanel = () => {
  const { selectedNode } = useSelectionContext();
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const setSelectedRegion = useSetRefinementRegionSelection(projectId);
  const meshReadOnly = useMeshReadOnly(projectId);

  const disabled = readOnly || meshReadOnly;

  const idRef = useRef(selectedNode?.id);

  const currentRrParam = useMemo(
    () => getCurrentRrParam(selectedNode?.id, meshMultiPart),
    [meshMultiPart, selectedNode?.id],
  );

  useEffect(() => {
    if (selectedNode && currentRrParam && selectedNode.id === currentRrParam.id) {
      idRef.current = selectedNode.id;
      setSelectedRegion(currentRrParam.id);
    }

    return () => {
      if (!idRef.current) {
        return;
      }
      setSelectedRegion('');
    };
  }, [selectedNode, currentRrParam, setSelectedRegion]);

  if (!selectedNode || !meshMultiPart?.refinementParams.length || !currentRrParam) {
    return <EmptyPropPanel />;
  }

  return (
    <div>
      <PropertiesSection>
        <SizingSection
          currentRrParam={currentRrParam}
          id={selectedNode.id}
          meshMultiPart={meshMultiPart}
          readOnly={disabled}
          setMeshMultiPart={setMeshMultiPart}
        />
      </PropertiesSection>
      <Divider />
      <ShapeSection
        currentRrParam={currentRrParam}
        id={selectedNode.id}
        param={simParam}
        readOnly={disabled}
        setMeshMultiPart={setMeshMultiPart}
      />
    </div>
  );
};
