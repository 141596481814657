// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { ReactNode, memo, useState } from 'react';

import cx from 'classnames';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { colors } from '../../lib/designSystem';
import Dropdown from '../Dropdown';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { EditableText } from '../controls/EditableText';
import { HorizontalCirclesTripleIcon } from '../svg/HorizontalCirclesTripleIcon';

const useStyles = makeStyles((theme) => createStyles({
  cardButton: {
    '--bg-color': colors.neutral250,
    '--hover-bg-color': colors.neutral300,
    border: `1px solid ${colors.neutral250}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'var(--bg-color)',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    '&:hover': {
      '&:enabled': {
        backgroundColor: 'var(--hover-bg-color)',
      },
      '&.selected': {
        border: `1px solid ${colors.purple600}`,
      },
    },
    '&:focus': {
      border: `1px solid ${colors.purple600}`,
    },
    '&.active': {
      backgroundColor: 'var(--hover-bg-color)',
    },
    '&.selected': {
      border: `1px solid ${colors.purple600}`,
    },
    '&:disabled': {
      backgroundColor: colors.neutral300,
      color: colors.neutral600,
      cursor: 'default',
    },
    '&.compact': {
      flexDirection: 'row',
      alignItems: 'center',
    },
    '&:focus-visible': {
      outline: `1px solid ${colors.primaryCta}`,
    },
    color: colors.neutral900,
    padding: '8px',
    gap: '8px',
    textAlign: 'left',
    transition: 'all ease 300ms',
    cursor: 'pointer',
  },
  cardButtonFlat: {
    '--bg-color': colors.neutral250,
    border: `1px solid ${colors.neutral250}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'var(--bg-color)',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    cursor: 'default',
    '&:disabled': {
      backgroundColor: colors.neutral300,
      color: colors.neutral600,
      cursor: 'default',
    },
    '&.compact': {
      flexDirection: 'row',
      alignItems: 'center',
    },
    '&:focus': {
      outline: 'none',
    },
    color: colors.neutral900,
    padding: '8px',
    gap: '8px',
    textAlign: 'left',
    transition: 'all ease 300ms',
  },
  headerRow: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  iconWrapper: {
    display: 'flex',
    position: 'relative',
  },
  cardHeader: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
    padding: '1px',
    margin: 0,
    '&.compact': {
      marginBottom: '4px',
    },
    '&.editable': {
      cursor: 'text',
    },
  },
  cardSubheader: {
    color: colors.neutral800,
    fontSize: '12px',
    lineHeight: '16px',
    margin: 0,
    '&.disabled': {
      color: colors.neutral600,
    },
    '&.compact': {
      paddingLeft: '3px',
    },
  },
  menuControl: {
    padding: '0 8px 6px',
    outline: '1px solid transparent',
    border: '0',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 'auto',
    alignItems: 'center',
    color: colors.neutral800,
    cursor: 'pointer',
    background: 'none',
    transition: 'all ease 300ms',

    '&:focus-visible': {
      outline: `1px solid ${colors.primaryCta}`,
    },
    '&:hover': {
      background: colors.neutral350,
    },
    '&.active': {
      background: colors.neutral350,
      color: colors.highEmphasisText,
    },
  },
  blankButton: {
    border: 'none',
    outline: 'none',
    background: 'none',
    padding: 0,
    margin: '0 0 0 auto',
    color: 'inherit',
    cursor: 'pointer',
    textAlign: 'left',
  },
  dot: {
    width: '6px',
    height: '6px',
    background: colors.redDotColor,
    position: 'absolute',
    bottom: '-2px',
    right: '-2px',
    borderRadius: '50%',
  },
}), { name: 'IconButton' });

interface CardButtonProps {
  // Displays accent border when selected
  selected?: boolean;
  // Reorganizes the button to be more compact (for mesh selection)
  compact?: boolean;
  // Removes the accent border and hover effect
  flat?: boolean;
  // SVG Icon displayed in the left side of the button
  icon?: SvgIconSpec;
  // Displays a dot next to the icon
  showIconDot?: boolean;
  // Handler for the editable title
  handleEditTitle?: (val: string) => void | Promise<void> | undefined;
  // Handler for double clicking the text
  handleTextDoubleClick?: () => void;
  // Optional menu for mesh options
  menuItems?: CommonMenuItem[];
  // Main header text for the button
  title?: string;
  // Subheading for the button
  subtitle?: string | ReactNode;
  // Handle button click events
  onClick?: () => void;
  // Optionally disable button
  disabled?: boolean;
  // Optional reason for disabling, will display as tooltip
  disabledReason?: string;
  // Disables the editable field
  editableDisabled?: boolean;
}

/**
 * Simple card that acts as a button for navigating the mesh selection process.
 * Has two variants, regular and compact. Compact is used for displaying existing meshes.
 * In the compact mode, the mesh title is editable so long as a handler is passed in.
 */
export const CardButton = memo((props: CardButtonProps) => {
  const {
    onClick = () => { },
    compact = false,
    disabled = false,
    disabledReason = '',
    editableDisabled = false,
    flat = false,
    handleEditTitle = undefined,
    handleTextDoubleClick,
    title = '',
    subtitle,
    selected = false,
    icon,
    menuItems = [],
    showIconDot = false,
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  const classes = useStyles();

  const buttonClass = flat ?
    cx(classes.cardButtonFlat, { compact }) :
    cx(classes.cardButton, { selected, compact, disabled, active: menuOpen });

  return (
    <Tooltip title={disabledReason}>
      <button
        className={buttonClass}
        disabled={disabled}
        onClick={onClick}
        type="button">
        {/* If compact, layout is horizontal, menu is optional, as is editable title */}
        {compact ? (
          <>
            <div className={cx(classes.headerRow, classes.iconWrapper)}>
              {icon && <SvgIcon {...icon} />}
              {showIconDot && <div className={classes.dot} />}
            </div>
            <div>
              <button
                className={classes.blankButton}
                onDoubleClick={handleTextDoubleClick}
                type="button">
                <EditableText
                  active={!editableDisabled && !disabled}
                  onChange={handleEditTitle || (() => { })}
                  value={title}
                />
              </button>
              <p className={cx(classes.cardSubheader, { disabled, compact })}>{subtitle}</p>
            </div>
            {!!menuItems.length && (
              <button
                className={classes.blankButton}
                onClick={(event) => event.stopPropagation()}
                type="button">
                <Dropdown
                  menuItems={menuItems}
                  onClose={() => setMenuOpen(false)}
                  onOpen={() => setMenuOpen(true)}
                  toggle={(
                    <button
                      className={cx(classes.menuControl, { active: menuOpen })}
                      type="button">
                      <HorizontalCirclesTripleIcon maxHeight={16} maxWidth={16} />
                    </button>
                  )}
                />
              </button>
            )}
          </>
        ) : (
          <>
            <div className={classes.headerRow}>
              <div className={classes.iconWrapper}>
                {icon && <SvgIcon {...icon} />}
                {showIconDot && <div className={classes.dot} />}
              </div>
              <h3 className={classes.cardHeader}>{title}</h3>
            </div>
            <p className={cx(classes.cardSubheader, { disabled })}>{subtitle}</p>
          </>
        )}
      </button>
    </Tooltip>
  );
});

CardButton.displayName = 'CardButton';
