// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import {
  DoeMessage,
  JobMessage,
  NotificationMessageType,
  NotificationType,
  ProjectShareMessage,
} from '../../../../lib/notificationUtils';

export const JobSuccessMessage = (props: NotificationMessageType) => {
  const { read, projectName, tabName } = props as JobMessage;
  if (read) {
    return <span>{tabName} in {projectName} is ready to view.</span>;
  }
  return <span><b>{tabName}</b> in <b>{projectName}</b> is ready to view.</span>;
};

export const JobFailureMessage = (props: NotificationMessageType) => {
  const { read, projectName, tabName } = props as JobMessage;
  if (read) {
    return <span>{tabName} in {projectName} failed.</span>;
  }
  return <span><b>{tabName}</b> in <b>{projectName}</b> failed.</span>;
};

export const DoeProgressMessage = (props: NotificationMessageType) => {
  const { read, projectName, doeName, jobsCompleted, totalJobs } = props as DoeMessage;
  const prefix = read ?
    (`${doeName} in ${projectName}`) :
    (<><b>{doeName}</b> in <b>{projectName}</b></>);

  return (
    <span>
      {prefix}: {jobsCompleted} of {totalJobs} simulation{
        totalJobs === 1 ? '' : 's'
      } {jobsCompleted === 1 ? 'is' : 'are'} ready to view.
    </span>
  );
};

export const ProjectSharingMessage = (props: NotificationMessageType) => {
  const { read, projectName, ownerName } = props as ProjectShareMessage;
  const nameString = read ?
    (`${projectName}`) :
    (<><b>{projectName}</b></>);
  const ownerString = read ?
    (`${ownerName}`) :
    (<><b>{ownerName}</b></>);
  return (
    <span>
      {ownerString} shared {nameString} with you.
    </span>
  );
};

// This should never happen since invalid notifications will be filtered out and not displayed.
export const EmptyMessage = (
  props: NotificationMessageType,
) => <span />;

const messages = {
  [NotificationType.INVALID]: EmptyMessage,
  [NotificationType.JOB_SUCCESS]: JobSuccessMessage,
  [NotificationType.JOB_FAILURE]: JobFailureMessage,
  [NotificationType.DOE_PROGRESS]: DoeProgressMessage,
  [NotificationType.PROJECT_SHARING]: ProjectSharingMessage,
};

export const NotificationMessage = (props: NotificationMessageType) => {
  /**
   * A message corresponding to the given NotificationType.
   */
  const Message = messages[props.type];
  return <Message {...props} />;
};
