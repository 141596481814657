// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { Vector3 } from '../../../proto/base/base_pb';
import { InputDescription } from '../InputDescription';
import { Vec3FaultsType, Vector3Input, Vector3InputProps } from '../Vector3Input';

export type Vec3FaultInfo = {
  type: Vec3FaultsType;
  message?: string;
}

export interface ValidVector3InputProps extends Vector3InputProps {
  // A function that validates the input value. If the function returns a string,
  // the input is invalid and the string is displayed as an error message.
  // The validate function runs anytime the input changes.
  validate?: (value: Vector3) => Vec3FaultInfo | undefined;
}

/**
 * A Vector3Input component with validation. It takes an additional 'validate' prop.
 */
export const ValidVector3Input = (props: ValidVector3InputProps) => {
  const { validate, value, onChange, onCommit, ...inputProps } = props;

  const [faultInfo, setFaultInfo] = useState<Vec3FaultInfo | undefined>();

  const handleChange = (newValue: Vector3) => {
    const newFaultInfo = validate?.(newValue);
    setFaultInfo(newFaultInfo);
    if (!newFaultInfo) {
      onChange?.(newValue);
    }
  };

  const handleCommit = (newValue: Vector3) => {
    const newFaultInfo = validate?.(newValue);
    setFaultInfo(newFaultInfo);
    if (!newFaultInfo) {
      onCommit?.(newValue);
    }
  };

  const messageType = faultInfo?.type.x || faultInfo?.type.y || faultInfo?.type.z;

  return (
    <>
      <Vector3Input
        {...inputProps}
        faultTypes={faultInfo?.type}
        onChange={handleChange}
        onCommit={handleCommit}
        value={value}
      />
      {faultInfo?.message && (
        <div style={{ paddingTop: '4px' }}>
          <InputDescription faultType={messageType} value={faultInfo.message} />
        </div>
      )}
    </>
  );
};
