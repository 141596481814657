import React from 'react';

import { Import } from '../../../proto/geometry/geometry_pb';
import { ActionButton } from '../../Button/ActionButton';
import { createStyles, makeStyles } from '../../Theme';
import { useGeometryStatus } from '../../hooks/useGeometryStatus';
import { useCreateNewModification } from '../../hooks/useInteractiveGeometry';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      padding: '16px 12px 12px',
    },
  }),
  { name: 'UploadCadOrMeshPropPanel' },
);

export const UploadCadOrMeshPropPanel = () => {
  const classes = useStyles();
  const { working } = useGeometryStatus();
  const createNewModification = useCreateNewModification();

  const handleUpload = () => {
    createNewModification({
      case: 'import',
      value: new Import({
        scaling: 1,
      }),
    });
  };

  return (
    <div className={classes.root}>
      <ActionButton
        disabled={working}
        kind="primary"
        onClick={handleUpload}
        startIcon={{ name: 'diskArrowUp' }}>
        Upload
      </ActionButton>
    </div>
  );
};
