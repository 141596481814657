// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithRefresh } from 'jotai/utils';

import * as rpc from '../../../lib/rpc';
import * as librarypb from '../../../proto/library/library_pb';

const personalLibraryState = atomWithRefresh(
  () => rpc.callRetry(
    'ListLibraryItems',
    rpc.client.listLibraryItems,
    new librarypb.ListItemsRequest(),
  ).then((response) => response.items).catch(() => undefined),
);

export function usePersonalLibrary() {
  return useAtomValue(personalLibraryState);
}

export function useRefreshPersonalLibrary() {
  return useSetAtom(personalLibraryState);
}
