// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { getTypeLabel } from '../../lib/heatSourceUtils';
import { useHeatPhysics } from '../../model/hooks/useHeatPhysics';
import * as simulationpb from '../../proto/client/simulation_pb';
import { initializeNewNode, useSetNewNodes } from '../../recoil/nodeSession';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useGeometryStatus } from '../hooks/useGeometryStatus';

import { AddNodeMenuButton } from './AddNodeButton';

interface AddHeatSourceButtonProps {
  /** An ID for a heat transfer physics */
  physicsId: string;
}

export const AddHeatSourceButton = (props: AddHeatSourceButtonProps) => {
  // == Props
  const { physicsId } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection } = useSelectionContext();

  // == Recoil
  const setNewNodes = useSetNewNodes();

  // == Hooks
  const {
    heatSourceTypesToAdd,
    addHeatSource,
    hasVolumes,
  } = useHeatPhysics(projectId, workflowId, jobId, readOnly, physicsId);
  const { working } = useGeometryStatus();

  const addHeatSourceNode = useCallback(async (type: simulationpb.HeatSourceType) => {
    const newId = await addHeatSource(type);
    if (newId) {
      setNewNodes((nodes) => [...nodes, initializeNewNode(newId)]);
      setSelection([newId]);
    }
  }, [addHeatSource, setNewNodes, setSelection]);

  if (readOnly) {
    return null;
  }

  const menuItems: CommonMenuItem[] = heatSourceTypesToAdd.map((type) => ({
    label: getTypeLabel(type),
    onClick: () => addHeatSourceNode(type),
  }));

  const disabledReason = hasVolumes ?
    '' :
    'Make volume selections for this physics to enable heat source addition';

  return (
    <AddNodeMenuButton
      disabled={working || !!disabledReason}
      menuItems={menuItems}
      tooltip={working ? '' : disabledReason}
    />
  );
};
