// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement } from 'react';

import { colors } from '../../lib/designSystem';
import { RowDatum, getRowId } from '../../lib/jobTableUtils';
import { JobType } from '../../proto/notification/notification_pb';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';

import { useHoveredRowId, useSelectedRowIdValue } from './state';

const useRowStyles = makeStyles(
  () => createStyles({
    root: {
      transition: 'background-color 500ms',
    },
  }),
  { name: 'ResultsTableRow' },
);

const useCellStyles = makeStyles(
  () => createStyles({
    root: {
      borderColor: colors.neutral200,
      cursor: 'default',
      justifyContent: 'flex-end',
      textAlign: 'right',
      color: colors.highEmphasisText,
      padding: '8px 17px',
    },
  }),
  { name: 'ResultsTableCell' },
);

const useCellHeadStyles = makeStyles(
  () => createStyles({
    root: {
      borderBottom: `1px solid ${colors.surfaceBackground}`,
      cursor: 'default',
      fontWeight: 600,
      lineHeight: '16px',
      fontSize: '12px',
      color: colors.lowEmphasisText,
      padding: '8px 17px',
      textTransform: 'uppercase',
      verticalAlign: 'bottom',
    },
  }),
  { name: 'ResultsTableCellHead' },
);

const useTableHeadStyles = makeStyles(
  () => createStyles({
    root: {
      position: 'sticky',
      top: 0,
      backgroundColor: colors.surfaceMedium2,
    },
  }),
  { name: 'ResultsTableHead' },
);

const useStyles = makeStyles(
  () => createStyles({
    container: {
      flex: '1 1 auto',
      display: 'flex',
      width: '100%',
    },
    table: {
      width: '100%',
      // tableLayout: 'fixed',
    },
    headerLine: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    },
  }),
  { name: 'ResultsTable' },
);

// Find the background color
function getBackgroundColor(selected: boolean, hovered: boolean, type: JobType) {
  if (selected) {
    return colors.neutral200;
  }
  if (hovered) {
    return colors.neutral150;
  }
  if ([JobType.EXPLORATION_JOB, JobType.SENSITIVITY_JOB].includes(type)) {
    return colors.neutral0;
  }
  return colors.neutral100;
}

export interface TableColumn {
  // The name of the column headed divided into lines. Each string in the array
  // is a line.
  nameLines: string[];
  // The values in each row of the column.
  values: ReactElement[];
  // Whether to left-align the column header and content.  The default alignment is right.
  leftAlign?: boolean;
  // The maximum width of the column.
  maxWidth?: string;
  // When true, disables wrapping of the column content. Note: if this is true and the max width
  // is too small, overflow may occur
  noWrap?: boolean;
  // Whether to add a tooltip to the column header.
  tooltipLabel?: string;
}

interface ResultsTableProps {
  rowData: RowDatum[],
  columns: TableColumn[],
  onRowClicked: (rowId: string) => void;
  onRowDoubleClicked: (rowId: string) => void;
}

function getColumnKey(column: TableColumn) {
  return column.nameLines.join('-');
}

// This panel displays the job list and several accompanying buttons and a
// graph.
const ResultsTable = (props: ResultsTableProps) => {
  const {
    rowData,
    columns,
    onRowClicked,
    onRowDoubleClicked,
  } = props;
  const classes = useStyles();
  const rowClasses = useRowStyles();
  const cellClasses = useCellStyles();
  const cellHeadClasses = useCellHeadStyles();
  const theadClasses = useTableHeadStyles();
  const [rowHovered, setRowHovered] = useHoveredRowId();
  const rowSelected = useSelectedRowIdValue();

  const headers = columns.map((column: TableColumn) => (
    <Tooltip
      key={getColumnKey(column)}
      placement={column.leftAlign ? 'top-start' : 'top-end'}
      title={column.nameLines.join(' - ')}>
      <th
        className={cellHeadClasses.root}
        style={{
          textAlign: column.leftAlign ? 'start' : 'end',
          maxWidth: column.maxWidth,
        }}>
        {column.nameLines.map(
          (line) => (
            <div className={classes.headerLine} key={line}>
              {line}
            </div>
          ),
        )}
      </th>
    </Tooltip>
  ));

  // Create the rows in the main body.
  const rows = rowData.map((row, index) => {
    const rowId = getRowId(row);
    const cells: ReactElement[] = [];
    columns.forEach((column: TableColumn) => {
      if (column.values.length > index) {
        cells.push(
          <td
            className={cellClasses.root}
            key={getColumnKey(column)}
            style={{
              textAlign: column.leftAlign ? 'start' : 'end',
              justifyContent: column.leftAlign ? 'flex-start' : 'flex-end',
              whiteSpace: column.noWrap ? 'nowrap' : undefined,
            }}>
            {column.values[index]}
          </td>,
        );
      }
    });
    const selected = (rowSelected === rowId);
    const hovered = (rowHovered === rowId);

    return (
      <tr
        className={rowClasses.root}
        key={rowId}
        onClick={() => onRowClicked(rowId)}
        onDoubleClick={() => onRowDoubleClicked(rowId)}
        onMouseEnter={() => setRowHovered(rowId)}
        onMouseLeave={() => setRowHovered(null)}
        style={{
          backgroundColor: getBackgroundColor(selected, hovered, row.type),
          ...(selected ? { fontWeight: 'bold' } : {}),
        }}>
        {cells}
      </tr>
    );
  });

  return (
    <div className={classes.container}>
      <table className={classes.table} data-locator="results-table">
        <thead className={theadClasses.root}>
          <tr>{headers}</tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    </div>
  );
};

export default ResultsTable;
