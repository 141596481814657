// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useState } from 'react';

import { AnyMouseEvent, isShiftModifiedOnly } from '../../../lib/event';
import { ListenerEvent, useEventListener } from '../../../lib/useEventListener';
import { useLcvisCameraValue } from '../../../recoil/lcvis/lcvisCameraState';
import { useLcvisProbeValue } from '../../../recoil/lcvis/lcvisProbeState';
import { useRemoveOverlays } from '../../../recoil/lcvis/useRemoveOverlays';
import { useQuantitySelectionValue } from '../../../recoil/selectionOptions';
import { useProjectContext } from '../../context/ProjectContext';

import LcVisOverlay from './LcVisOverlay';

/** The active svg overlay on top of the LCVis canvas. */
export const ActiveOverlay = (props: {className: string}) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const cameraState = useLcvisCameraValue({ projectId, workflowId, jobId });
  const probeState = useLcvisProbeValue();
  const removeOverlays = useRemoveOverlays({ projectId, workflowId, jobId });
  const [shiftPressed, setShiftPressed] = useState(false);
  const quantitySelectionValue = useQuantitySelectionValue(projectId);

  // When the shift key is pressed or released, show or hide the box select overlay text.
  const handleShift = useCallback((event: ListenerEvent) => {
    const target = (event as AnyMouseEvent).target as HTMLElement;
    if (target && target.getAttribute('data-locator') !== 'lcvisEventHandler') {
      return;
    }
    // The LCVis box select will be active if shift is true, regardless of other modifiers.
    if (isShiftModifiedOnly(event)) {
      setShiftPressed(true);
    } else {
      setShiftPressed(false);
    }
  }, []);
  useEventListener('mousedown', handleShift);
  const handleMouseUp = useCallback((event: ListenerEvent) => setShiftPressed(false), []);
  useEventListener('mouseUp', handleMouseUp);
  useEventListener('keyup', handleMouseUp);

  if (probeState.active) {
    return <></>;
  }
  if (shiftPressed || quantitySelectionValue === 'boxSelect') {
    return (
      <LcVisOverlay
        className={props.className}
        text={
          `Drag left to right to select visible surfaces only, or right to left to select both
          visible and underlying surfaces.`
        }
      />
    );
  }
  if (cameraState.center_of_rotation_modifier) {
    return (
      <LcVisOverlay
        className={props.className}
        onExit={removeOverlays}
        text={
          'Select a point on the screen to act as the center of rotation for the camera.' +
          ' Press ESC to exit.'
        }
      />
    );
  }
  if (cameraState.zoom_to_box_modifier) {
    return (
      <LcVisOverlay
        className={props.className}
        onExit={removeOverlays}
        text={
          'Draw a box on screen by dragging the mouse.' +
          ' Press ESC to exit.'
        }
      />
    );
  }
  return <></>;
};
