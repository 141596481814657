// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

// DraggableBoxOverlay is a Overlay with a box included that you can click and
// drag.

import React, { useCallback, useMemo, useState } from 'react';

import { dot, mult, newProto, normalize } from '../../../lib/Vector';
import { colors } from '../../../lib/designSystem';
import { ListenerEvent, useEventListener } from '../../../lib/useEventListener';

import Overlay, { OverlayProps } from './Overlay';

export type OnBoxSelectArguments = {
  // Always less than or equal to maxX
  minX: number;
  maxX: number;
  // Always less than or equal to maxY.
  minY: number;
  maxY: number;
  // True if the rectangle was drawn with increasing x. Else, false.
  increasingX: boolean;
  // Mouse event obtained in the onMouseUp callback.
  event: MouseEvent;
}

export type DraggableBoxOverlayProps = OverlayProps & {
  className: string;
  // If the box should be activated as soon as the mouse starts moving.
  // Otherwise, it requires a mouse down event.
  autoActivate: boolean;
  // Returns the selected box area when the mouse is released.
  onBoxSelect: (args: OnBoxSelectArguments) => void;
  // If the box dimensions should be constrained to the aspect ratio of the Paraview window.
  constrainBox?: boolean;
};

const DraggableBoxOverlay = (props: DraggableBoxOverlayProps) => {
  const { className, onBoxSelect, text, constrainBox } = props;
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  // Is the rectangle being rendered?
  const [renderingRect, setRenderingRect] = useState<boolean>(false);
  // Anchor point of the rectangle.
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  // Calibration values to account for the differences between MouseEvent.offset* and
  // React.MouseEvent.client*.
  const [calibration, setCalibration] = useState({ x: 0, y: 0 });

  // Used to get the current canvas.
  const ref = React.useRef<HTMLDivElement | null>(null);

  // Store mouse position.
  const mouseMove = (ev: React.MouseEvent) => {
    ev.persist();
    // Mouse click position.
    const x = ev.clientX;
    const y = ev.clientY;
    setMousePosition({ x, y });
    if (props.autoActivate && !renderingRect) {
      setRenderingRect(true);
      setAnchorPoint({ x: x + calibration.x, y: y - calibration.y });
    }
  };

  // Calculate rectangle dimensions from anchor pt, mouse position, and (maybe) aspect ratio.
  const rectDims = useMemo(() => {
    const widthSigned = mousePosition.x - anchorPoint.x - calibration.x;
    const heightSigned = mousePosition.y - anchorPoint.y - calibration.y;
    const increasingX = widthSigned >= 0;

    const width = Math.abs(widthSigned);
    const height = Math.abs(heightSigned);
    const leftX = Math.min(anchorPoint.x, mousePosition.x - calibration.x);
    const bottomY = Math.min(anchorPoint.y, mousePosition.y - calibration.y);

    const clientRect = ref.current?.getBoundingClientRect();
    if (!clientRect || !constrainBox) {
      return { width, height, leftX, bottomY, increasingX };
    }
    // If constrainBox is true, limit the aspect ratio to the PV window aspect ratio.
    // The position of the corner is choosen as the closest point to the cursor.
    // https://docs.google.com/document/d/1xjS0qJUexGs0tjXUJZzgBogYZk188Q2bUw2TKWhd-k0
    const cursor = newProto(
      mousePosition.x - anchorPoint.x - calibration.x,
      mousePosition.y - anchorPoint.y - calibration.y,
      0,
    );
    const maxWidth = clientRect.width;
    const maxHeight = clientRect.height;
    // The corner can be on one of two lines. u is the direction parallel to the line.
    // v is perpendicular to the line.
    const u1 = normalize(newProto(maxWidth, maxHeight, 0));
    const u2 = normalize(newProto(maxWidth, -maxHeight, 0));
    const v1 = newProto(u1.y, -u1.x, 0);
    const v2 = newProto(u2.y, -u2.x, 0);

    // Compute the distance to the closest point on each line.
    const dist1 = Math.abs(dot(v1, cursor));
    const dist2 = Math.abs(dot(v2, cursor));

    // Pick the closest line and project onto it. Find the corner by adding anchorPoint to point.
    const point = (dist1 < dist2) ? mult(u1, dot(u1, cursor)) : mult(u2, dot(u2, cursor));
    const corner = { x: point.x + anchorPoint.x, y: point.y + anchorPoint.y };
    return {
      leftX: Math.min(anchorPoint.x, corner.x),
      bottomY: Math.min(anchorPoint.y, corner.y),
      width: Math.abs(anchorPoint.x - corner.x),
      height: Math.abs(anchorPoint.y - corner.y),
      increasingX,
    };
  }, [constrainBox, anchorPoint, mousePosition, calibration]);

  const onMouseDown = (event: MouseEvent) => {
    // If we are not rendering the rectangle, record the click position and that the rectangle
    // is being rendered. Then, exit the render loop since we need to record a mouse up event to
    // draw a valid rectangle.
    if (!renderingRect) {
      // Mouse click position.
      const x = event.offsetX;
      const y = event.offsetY;
      setRenderingRect(true);
      setAnchorPoint({ x, y });
    }
  };

  const onMouseUp = useCallback((event: MouseEvent) => {
    // On mouse up events, call zoom to box with the current rectangle being drawn on screen.
    // This is a sanity check to make sure that we don't call zoomToBox if the rectangle is
    // not being rendered.
    if (!renderingRect || !ref.current) {
      return;
    }
    // Zoom to the box. The box shape can be now computed since we have the two rectangle corners
    // positions.
    const boxLeftX = Math.round(rectDims.leftX);
    // Paraview's Y axis grows from bottom to y, hence we need to transform from html coordinates
    // to ParaView's coordinates.
    const boxRightY = ref.current.clientHeight - Math.round(rectDims.bottomY);
    const width = Math.round(rectDims.width);
    const height = Math.round(rectDims.height);
    const args: OnBoxSelectArguments = {
      minX: boxLeftX,
      maxX: boxLeftX + width,
      minY: boxRightY - height,
      maxY: boxRightY,
      increasingX: rectDims.increasingX,
      event,
    };
    onBoxSelect(args);

    // Deactivate the rectangle rendering.
    setRenderingRect(false);
  }, [rectDims, renderingRect, onBoxSelect, setRenderingRect]);

  useEventListener(
    'mousedown',
    (event: ListenerEvent) => onMouseDown(event as MouseEvent),
    ref.current,
  );
  useEventListener(
    'mouseup',
    (event: ListenerEvent) => onMouseUp(event as MouseEvent),
    ref.current,
  );

  // Update calibration once the ref element renders with height and width.
  const refCallback = useCallback((el: HTMLDivElement) => {
    ref.current = el;
    let newCalibration = { x: 0, y: 0 };
    if (el) {
      const clientRect = el.getBoundingClientRect();
      newCalibration = { x: Math.round(clientRect.x), y: Math.round(clientRect.y) };
    }
    if (newCalibration.x !== calibration.x || newCalibration.y !== calibration.y) {
      setCalibration(newCalibration);
      setAnchorPoint((oldAnchor) => ({
        x: oldAnchor.x - newCalibration.x + calibration.x,
        y: oldAnchor.y + newCalibration.y + calibration.y,
      }));
    }
  }, [calibration]);

  return (
    <div
      className={className}
      onMouseMove={(ev) => mouseMove(ev)}
      ref={refCallback}>
      <Overlay text={text}>
        {renderingRect && (
          <g>
            <rect
              fill="none"
              height={rectDims.height}
              style={{ strokeWidth: 2, stroke: colors.citronGreen600 }}
              width={rectDims.width}
              x={rectDims.leftX}
              y={rectDims.bottomY}
            />
          </g>
        )}
      </Overlay>
    </div>
  );
};

export default DraggableBoxOverlay;
