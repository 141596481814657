// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 18;
const baseHeight = 24;
export const DocumentIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="document"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M10.5,0
           L1.5,0
           C0.675,0 0,0.675 0,1.5
           L0,22.5
           C0,23.325 0.675,24 1.5,24
           L16.5,24
           C17.325,24 18,23.325 18,22.5
           L18,7.5
           L10.5,0Z
           M15,21
           L3,21
           L3,3
           L9,3
           L9,9
           L15,9
           L15,21Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
