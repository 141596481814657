// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';

import { ColoredAvatar } from './ColoredAvatar';
import { ColoredCircle } from './ColoredCircle';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      gap: '16px',
      maxWidth: '100%',
      alignItems: 'center',
    },
    info: {
      minWidth: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
      lineHeight: '16px',
      fontSize: '13px',
    },
    name: {
      color: colors.neutral900,
    },
    email: {
      color: colors.neutral700,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  { name: 'UserCard' },
);

export interface UserCardProps {
  // email field is optional, but passing it will automatically include it in
  // the card and will show a larger ColoredAvatar to match the enlarged content.
  email?: string;
  name: string;
  // if the component should show the Support card instead, and not the card for a specific user
  isSupport?: boolean;
}

export const UserCard = (props: UserCardProps) => {
  const { email, name, isSupport } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isSupport ? (
        <ColoredCircle icon={{ name: 'headphones', maxWidth: 16 }} />
      ) : (
        <ColoredAvatar name={name} />
      )}
      <div className={cx(classes.info)}>
        <div className={classes.name}>{name}</div>
        {email && <div className={classes.email}>{email}</div>}
      </div>
    </div>
  );
};
