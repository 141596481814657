// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 8;
const baseHeight = 8;
export const StopIconOutline = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      height={finalHeight}
      ref={ref}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}>
      <path
        d="M1 0.75
               H7
               C7.13636 0.75 7.25 0.863642 7.25 1
               V7
               C7.25 7.13636 7.13636 7.25 7 7.25
               H1
               C0.863642 7.25 0.75 7.13636 0.75 7
               V1
               C0.75 0.863642 0.863642 0.75 1 0.75Z"
        strokeWidth={baseWidth / finalWidth}
        style={{ stroke: getColor(0), fill: 'none' }}
      />
    </svg>
  );
});
