// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import { LcvModule } from '../types';

import { LcvObject } from './base/LcvObject';

export class LcvCamera extends LcvObject {
  constructor(lcv: LcvModule, sessionHandle: number) {
    super(lcv, lcv.newCamera(sessionHandle, 'matrix', 0).camera, sessionHandle);
  }
}
