// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { heatPhysicalBoundaryChoices } from '../../lib/boundaryConditionUtils';
import { useHeatPhysics } from '../../model/hooks/useHeatPhysics';
import * as simulationpb from '../../proto/client/simulation_pb';
import { paramChoicesToMenuItems } from '../Menu/CommonMenu';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

export const useHeatBoundaryConditionContainerNode = (physicsId: string) => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection } = useSelectionContext();

  // == Model Hooks
  const {
    surfacesWithoutBoundaryConditions,
    createHeatBoundaryConditionWithUnassignedSurfaces,
  } = useHeatPhysics(projectId, workflowId, jobId, readOnly, physicsId);

  // == Other
  const defineRemainingSurfaces = useCallback(
    async (boundaryType: simulationpb.HeatPhysicalBoundary) => {
      const newId = await createHeatBoundaryConditionWithUnassignedSurfaces(boundaryType);
      setSelection([newId]);
    },
    [createHeatBoundaryConditionWithUnassignedSurfaces, setSelection],
  );

  const typeMenuItems = paramChoicesToMenuItems(
    heatPhysicalBoundaryChoices(),
    (choice) => defineRemainingSurfaces(choice.enumNumber),
  );

  return {
    typeMenuItems,
    surfacesWithoutBoundaryConditions,
  };
};
