// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { CoreProps, MessagePanel } from './MessagePanel';

export const SectionMessage = (props: CoreProps) => {
  // Strip expiry from SectionMessage
  const { expiry, ...coreProps } = props;

  return (
    <MessagePanel {...coreProps} variant="sectionMessage" />
  );
};
