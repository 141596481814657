// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const VerticalWavesIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="verticalWaves"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M3.197,0.207
           C3.708,-0.153 4.414,-0.031 4.774,0.48
           C5.134,0.991 5.012,1.697 4.501,2.057
           C4.501,2.057 2.264,3.651 2.264,6.566
           C2.264,7.265 2.562,7.966 2.967,8.682
           C3.454,9.544 4.107,10.411 4.757,11.284
           C6.272,13.319 7.698,15.412 7.698,17.434
           C7.698,21.696 4.477,23.81 4.477,23.81
           C3.957,24.157 3.253,24.015 2.907,23.495
           C2.56,22.975 2.702,22.272 3.222,21.926
           C3.222,21.926 5.434,20.421 5.434,17.434
           C5.434,16.708 5.136,15.985 4.728,15.253
           C4.242,14.38 3.59,13.508 2.941,12.637
           C1.424,10.6 0,8.531 0,6.566
           C0,2.443 3.197,0.207 3.197,0.207Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M11.348,0.207
           C11.859,-0.153 12.565,-0.031 12.925,0.48
           C13.285,0.991 13.163,1.697 12.652,2.057
           C12.652,2.057 10.415,3.651 10.415,6.566
           C10.415,7.265 10.713,7.966 11.117,8.682
           C11.605,9.544 12.258,10.411 12.908,11.284
           C14.423,13.319 15.849,15.412 15.849,17.434
           C15.849,21.696 12.628,23.81 12.628,23.81
           C12.107,24.157 11.404,24.015 11.058,23.495
           C10.711,22.975 10.852,22.272 11.372,21.926
           C11.372,21.926 13.585,20.421 13.585,17.434
           C13.585,16.708 13.287,15.985 12.879,15.253
           C12.393,14.38 11.741,13.508 11.092,12.637
           C9.575,10.6 8.151,8.531 8.151,6.566
           C8.151,2.443 11.348,0.207 11.348,0.207Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M19.499,0.207
           C20.009,-0.153 20.716,-0.031 21.076,0.48
           C21.436,0.991 21.314,1.697 20.803,2.057
           C20.803,2.057 18.566,3.651 18.566,6.566
           C18.566,7.265 18.863,7.966 19.268,8.682
           C19.756,9.544 20.409,10.411 21.059,11.284
           C22.574,13.319 24,15.412 24,17.434
           C24,21.696 20.778,23.81 20.778,23.81
           C20.258,24.157 19.555,24.015 19.209,23.495
           C18.862,22.975 19.003,22.272 19.523,21.926
           C19.523,21.926 21.736,20.421 21.736,17.434
           C21.736,16.708 21.438,15.985 21.03,15.253
           C20.543,14.38 19.892,13.508 19.243,12.637
           C17.726,10.6 16.302,8.531 16.302,6.566
           C16.302,2.443 19.499,0.207 19.499,0.207Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
