// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 14.2;
const baseHeight = 14.2;
export const RingPlusIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="ringPlus"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M6.7249 4.0129
           V6.7249
           H4.0129
           V7.4749
           H6.7249
           V10.1869
           H7.4749
           V7.4749
           H10.1869
           V6.7249
           H7.4749
           V4.0129
           H6.7249Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M7.0999 0.199902
           C3.4697 0.199902 0.199902 3.4697 0.199902 7.0999
           C0.199902 10.7301 3.4697 14 7.0999 14
           C10.7301 14 14 10.7301 14 7.0999
           C14 3.4697 10.7301 0.199902 7.0999 0.199902Z
           M1.1999 7.0999
           C1.1999 3.73772 3.73772 1.1999 7.0999 1.1999
           C10.4621 1.1999 13 3.73772 13 7.0999
           C13 10.4621 10.4621 13 7.0999 13
           C3.73772 13 1.1999 10.4621 1.1999 7.0999Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
