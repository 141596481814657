// Copyright 2022 Luminary Cloud, Inc. All Rights Reserved.
import { CSSProperties } from 'react';

// Colors can be specified as hex, rgb, custom variables, color functions, etc.,
// but they're all strings
type Color = string;

// For a given interaction state (hovered, disabled, etc.), a ColorSpec
// represents the color for each value state (false and true).
type ColorSpec = [Color, Color];

// Colors may be prescribed with the ColorConfig interface, where each
// key (interaction state) is optional and colors may be given as single colors
// or full ColorSpec types
export interface ColorConfig {
  // For keys (i.e. enabled, hover, active, disabled) not defined, default may
  // be used.
  default?: Color | ColorSpec,
  enabled?: Color | ColorSpec,
  hover?: Color | ColorSpec,
  active?: Color | ColorSpec,
  disabled?: Color | ColorSpec,
}

// A complete specification for the ToggleSwitch's colors includes all
// interaction states with values normalized to an explicit tuple.
export interface StatefulColors {
  enabled: ColorSpec,
  hover: ColorSpec,
  active: ColorSpec,
  disabled: ColorSpec,
}

// If a single color is specified in a ColorConfig, it applies to both value
// states, and we can return a tuple.
const normalizeColorSpec = (color: Color | ColorSpec): ColorSpec => (
  Array.isArray(color) ? color : [color, color]
);

// ColorConfig objects are, by design, partial and incomplete, while
// StatefulColor objects fully describe all states (value and interaction).
// This function takes a ColorConfig objectA and a StatefulColor objectB as
// arguments and returns a new StatefulColor object whose default values come
// from objectB, with objectA's values applied on top.
const normalizeColorConfig: (
  colorConfig: ColorConfig,
  defaultMap: StatefulColors,
) => StatefulColors = (colorConfig, defaultMap) => {
  const defaultSpec = colorConfig.default;

  return {
    enabled: normalizeColorSpec(colorConfig.enabled ?? defaultSpec ?? defaultMap.enabled),
    hover: normalizeColorSpec(colorConfig.hover ?? defaultSpec ?? defaultMap.hover),
    active: normalizeColorSpec(colorConfig.active ?? defaultSpec ?? defaultMap.active),
    disabled: normalizeColorSpec(colorConfig.disabled ?? defaultSpec ?? defaultMap.disabled),
  };
};

// Return a CSS style object with custom variables for each value state and
// interaction state represented by a StatefulColors object
const colorMapToCssVars: (
  colors: StatefulColors,
  name: string,
) => CSSProperties = (colors, name) => {
  const vars: Record<string, string> = {};

  Object.entries(colors).forEach(([interactionState, [trueColor, falseColor]]) => {
    vars[`--${name}-${interactionState}-true-color`] = trueColor;
    vars[`--${name}-${interactionState}-false-color`] = falseColor;
  });

  return vars as CSSProperties;
};

export const colorCssVars = (
  colorItems: [string, StatefulColors, ColorConfig | undefined][],
) => (
  colorItems.reduce((result, [name, defaultColors, configColors]) => {
    const colors = configColors ?
      normalizeColorConfig(configColors, defaultColors) :
      defaultColors;
    return {
      ...result,
      ...colorMapToCssVars(colors, name),
    };
  }, {} as CSSProperties)
);
