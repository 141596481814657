// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useRef, useState } from 'react';

import * as Vector from '../../../../lib/Vector';
import { Logger } from '../../../../lib/observability/logs';
import { addRpcError } from '../../../../lib/transientNotification';
import { EditSource } from '../../../../lib/visUtils';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useEditState } from '../../../../recoil/paraviewState';
import Form from '../../../Form';
import { DataSelect } from '../../../Form/DataSelect';
import { NumberSpinner } from '../../../NumberSpinner';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import Divider from '../../../Theme/Divider';
import { LineInput, LineParam } from '../../../visFilter/LineInput';
import { useSelectedFilterNode } from '../../../visFilter/useFilterNode';
import { FilterEditControl } from '../../FilterEditControl';
import PropertiesSection from '../../PropertiesSection';
import { FilterDisplayPanel } from '../shared/FilterDisplayPanel';

import { FilterPropertiesPanelProps } from './props';

const logger = new Logger('filter/LineProbe');

function defaultLineParam(): ParaviewRpc.LineParam {
  return {
    typ: 'Line',
    point1: {
      x: 0,
      y: 0,
      z: 0,
    },
    point2: {
      x: 1,
      y: 0,
      z: 0,
    },
  };
}

// Create the param filled with default values, to be used when creating a new
// filter off the given parent.
export function newLineProbeParam(
  parent: ParaviewRpc.TreeNode,
): ParaviewRpc.LineProbeParam {
  return {
    typ: ParaviewRpc.TreeNodeType.LINE,
    line: defaultLineParam(),
    sampling: 'Sample Uniformly',
    resolution: 1000,
  };
}

// Panel for displaying and modifying a line probe.
export const LineProbePropPanel = (props: FilterPropertiesPanelProps) => {
  const { displayProps, filterNode, nodeId, viewState } = props;

  const {
    paraviewClientState,
    paraviewMeshMetadata,
    paraviewRenderer,
    onRpcSuccess,
  } = useParaviewContext();

  const [editState] = useEditState();
  const editSource = editState ? editState.editSource : EditSource.FORM;
  const paraviewClient = paraviewClientState.client;
  const [disableApply, setDisableApply] = useState<boolean>(false);
  const { updateEditState } = useSelectedFilterNode();

  const onUpdate = (source: EditSource, newParam: ParaviewRpc.TreeNodeParam) => (
    updateEditState({
      editSource: source,
      param: newParam,
    })
  );

  const param = props.param as ParaviewRpc.LineProbeParam;
  const readOnly = !editState;
  const samplingPatterns: string[] = [
    'Sample Uniformly',
    'Sample At Cell Boundaries',
    'Sample At Segment Centers',
  ];

  const samplingPatternOptions = [{
    name: 'Uniformly',
    value: samplingPatterns[0],
    selected: param.sampling === samplingPatterns[0],
  }, {
    name: 'At Mesh Resolution',
    value: samplingPatterns[1],
    selected: param.sampling === samplingPatterns[1],
  }];

  const setSamplingPattern = (newPattern: string) => {
    onUpdate(
      EditSource.FORM,
      {
        ...param,
        sampling: newPattern,
      },
    );
  };

  const setSamplingResolution = (value: number) => {
    onUpdate(
      EditSource.FORM,
      {
        ...param,
        resolution: value,
      },
    );
  };

  const uniformSamplingPattern = param.sampling === samplingPatterns[0];
  // We need to keep track of whether we need to reset the axes to prior state on unmount.
  // If ON from before - set to false and do nothing.
  // If OFF prior to component mount - set to true and hide on unmount.
  // If turned ON / OFF by the user while component is mounted - set to false and do nothing.
  const resetAxesGridRef = useRef<boolean>(false);

  useEffect(() => {
    // Run on mount
    if (!viewState.axesGridVisibility) {
      paraviewRenderer.showAxesGrid(true).then((res: ParaviewRpc.RpcResult) => {
        onRpcSuccess('showAxesGrid', res);
        resetAxesGridRef.current = true;
      }).catch((err: Error) => {
        addRpcError('Could not set grid axes', err);
      });
    }
    // Run on unmount
    return () => {
      if (resetAxesGridRef.current) {
        paraviewRenderer.showAxesGrid(false).then((res: ParaviewRpc.RpcResult) => {
          onRpcSuccess('showAxesGrid', res);
        }).catch((err: Error) => {
          addRpcError('Could not set grid axes', err);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If at any point the user switches axes grid off, (and then on/off/on again,)
    // do not hide the grid axes on line probe panel unmount. Leave it in user chosen end state.
    if (!viewState.axesGridVisibility) {
      resetAxesGridRef.current = false;
    }
  }, [viewState.axesGridVisibility]);

  useEffect(() => {
    if (!editState) {
      return () => { };
    }

    let mounted = true;
    let unsubscribeFn: (() => void) | null = null;

    // Called when the user moves the widget interactively.
    const onLineWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION) {
        throw Error('only line widget supported now');
      }
      if (!mounted) {
        return;
      }
      const lineParams = param.line as ParaviewRpc.LineParam;
      if (
        !Vector.nearPv(newParam.line.point1, lineParams.point1) ||
        !Vector.nearPv(newParam.line.point2, lineParams.point2)
      ) {
        onUpdate(
          EditSource.PARAVIEW,
          {
            ...param,
            line: {
              typ: 'Line',
              point1: newParam.line.point1,
              point2: newParam.line.point2,
            },
          },
        );
      }
    };
    const startWidget = async (): Promise<void> => {
      if (!paraviewClient) {
        return;
      }
      // Register the widget update handler.
      unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onLineWidgetUpdate);
      // Activate the widget.
      paraviewRenderer.activateWidget(
        filterNode.bounds!,
        {
          typ: ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION,
          line: param.line as ParaviewRpc.LineParam,
          resolution: 1000.0,
        },
      );
    };

    startWidget().then(() => { }).catch((err: Error) => {
      addRpcError('Could not activate widget', err);
    });
    return () => {
      mounted = false;
      if (unsubscribeFn) {
        unsubscribeFn();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!editState, nodeId]);

  useEffect(() => {
    if (editState && editSource !== EditSource.PARAVIEW && paraviewMeshMetadata) {
      logger.debug('Line Probe: set widget');
      // When the user manually updates the line dialog, reflect the new line
      // params to the widget.
      const ws: ParaviewRpc.LineSourceWidgetState = {
        typ: ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION,
        line: param.line as ParaviewRpc.LineParam,
        resolution: 1000.0,
      };
      paraviewRenderer.activateWidget(
        filterNode.bounds!,
        ws,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editState, editSource, param, param.line, nodeId]);

  return (
    <div>
      <FilterDisplayPanel filterNode={filterNode} />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={!!editState}
          expandWhenDisabled
          headerRight={(
            <FilterEditControl
              disableApply={disableApply}
              displayProps={displayProps}
              nodeId={nodeId}
              param={param}
            />
          )}
          heading="Visualization Input"
          nodeId={nodeId}
          panelName="input">
          <div>
            <LineInput
              onChange={(newParam: LineParam) => {
                const pt1 = newParam.point1;
                const pt2 = newParam.point2;
                const allLinePts = [pt1.x, pt1.y, pt1.z, pt2.x, pt2.y, pt2.z];
                setDisableApply(allLinePts.some(Number.isNaN));
              }}
              onCommit={(newParam: LineParam) => onUpdate(
                EditSource.FORM,
                {
                  ...param,
                  line: {
                    typ: 'Line',
                    point1: Vector.toPvProto(newParam.point1),
                    point2: Vector.toPvProto(newParam.point2),
                  },
                },
              )}
              param={{
                point1: Vector.toProto(param.line.point1),
                point2: Vector.toProto(param.line.point2),
              }}
              readOnly={readOnly}
            />
            <Form.LabeledInput
              help="Strategy to identify points along the line."
              label="Sample">
              <DataSelect
                asBlock
                disabled={!editState}
                onChange={(type) => setSamplingPattern(type)}
                options={samplingPatternOptions}
                size="small"
              />
            </Form.LabeledInput>
            {uniformSamplingPattern && (
              <Form.LabeledInput help="Number of samples." label="Resolution">
                <NumberSpinner
                  disabled={readOnly}
                  onCommit={(value) => setSamplingResolution(value)}
                  value={param.resolution}
                />
              </Form.LabeledInput>
            )}
          </div>
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
    </div>
  );
};
