// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ParamName, paramDesc } from '../../SimulationParamDescriptor';
import { getAdValue } from '../../lib/adUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { TableMapOptions } from '../ParamFieldInput';
import ParamRow from '../ParamRow';
import { ChangeOperation } from '../controls/TableMapInput';

export interface AirfoilConfigProps {
  airfoil: simulationpb.BladeElementAirfoilData;
  readOnly: boolean;
  onChangeStation: (value: number) => void;
  onChangePerformance: (value: ChangeOperation) => void;
  tableMapOptions: TableMapOptions;
  projectId: string;
}

export const AirfoilConfig = (props: AirfoilConfigProps) => {
  const {
    airfoil,
    onChangePerformance,
    onChangeStation,
    projectId,
    readOnly,
    tableMapOptions,
  } = props;

  return (
    <div>
      <ParamRow
        inputOptions={{
          showUnits: true,
          numberOptions: {
            inputType: 'slidercombo',
            max: 1,
            min: 0,
            step: 0.1,
          },
        }}
        label="r/R"
        nestLevel={0}
        param={paramDesc[ParamName.AirfoilRadialStation]}
        readOnly={readOnly}
        setValue={onChangeStation}
        value={getAdValue(airfoil.airfoilRadialStation)}
      />
      <ParamRow
        inputOptions={{ tableMapOptions }}
        label="Performance"
        nestLevel={0}
        param={paramDesc[ParamName.AirfoilPerformanceData]}
        projectId={projectId}
        readOnly={readOnly}
        setValue={onChangePerformance}
        value={airfoil.airfoilPerformanceData}
      />
    </div>
  );
};
