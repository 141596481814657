// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { isGroupVisible } from '../../../lib/entityGroupUtils';
import {
  deleteTreeNodeMenuItem,
  duplicateTreeNodeMenuItem,
  groupTreeNodeMenuItem,
  ungroupTreeNodeMenuItem,
} from '../../../lib/treeUtils';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import { useLcvisVisibilityMapValue } from '../../../recoil/lcvis/lcvisVisibilityMap';
import { useToggleVisibility } from '../../../recoil/vis/useToggleVisibility';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useCopySimAnnotation } from '../../hooks/nodeDuplication/useCopySimAnnotation';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeGrouping } from '../../hooks/useNodeGrouping';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { ContextMenuSection, TreeRow, VisibilityControl } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'circle' };

// A row in the simulation tree representing a particle group
export const ParticleGroupTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { viewState, visibilityMap } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Recoil
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();
  const visibilityV2 = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteParticleGroupNode, postDeleteNodeIds } = useNodeDeletion();
  const {
    canGroup,
    canUngroup,
    groupEntities,
    ungroupEntities,
    groupableNodes,
  } = useNodeGrouping();

  // == Data
  const isVisible = lcvisEnabled ? (
    isGroupVisible(visibilityV2, entityGroupData.groupMap, node.id)
  ) : (
    isGroupVisible(visibilityMap, entityGroupData.groupMap, node.id)
  );
  const isSelected = selectedNodeIds.includes(props.node.id);
  const toggleIds = new Set(isSelected ? selectedNodeIds : [node.id]);

  const toggleVis = useToggleVisibility(toggleIds, isVisible);

  const visibilityControl: VisibilityControl = {
    disabled: lcvisEnabled ? !lcvisReady : !viewState,
    show: isVisible,
    toggle: toggleVis,
  };

  const duplicateRow = useCopySimAnnotation();

  const deleteRow = useCallback(async () => {
    if (await deleteParticleGroupNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteParticleGroupNode, node.id, postDeleteNodeIds]);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];

    const disabled = !canDelete(node.type, node.id);
    const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
    const duplicateItem = duplicateTreeNodeMenuItem(() => duplicateRow(node.id, 'disk'), disabled);
    sections.push({ section: 'crud', menuItems: [duplicateItem, deleteItem] });

    const nodeIdsToGroup = groupableNodes(node.id);
    if (canGroup(nodeIdsToGroup, entityGroupData)) {
      const groupItem = groupTreeNodeMenuItem(() => groupEntities(nodeIdsToGroup));
      sections.push({ section: 'grouping', menuItems: [groupItem] });
    }

    if (canUngroup(node, entityGroupData)) {
      const ungroupItem = ungroupTreeNodeMenuItem(() => ungroupEntities(node.id));
      sections.push({ section: 'grouping', menuItems: [ungroupItem] });
    }

    return sections;
  }, [
    canDelete,
    canGroup,
    canUngroup,
    deleteRow,
    duplicateRow,
    entityGroupData,
    groupEntities,
    groupableNodes,
    node,
    ungroupEntities,
  ]);

  return (
    <TreeRow
      {...props}
      canMultiSelect
      getExtraContextMenuItems={getExtraContextMenuItems}
      primaryIcon={PRIMARY_ICON}
      propertiesControl
      renaming={renaming}
      visibility={visibilityControl}
    />
  );
};
