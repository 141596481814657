// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const RotatingDotsIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="rotatingDots"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M15.2,6.4
           C15.84,6.4 16.48,6.24 16.96,5.92
           C19.2,7.2 20.64,9.6 20.8,12.16
           C20.8,12.96 21.6,13.6 22.4,13.6
           C23.36,13.6 24,12.96 24,12
           L24,11.68
           C23.68,7.84 21.6,4.64 18.4,2.88
           C18.24,1.28 16.96,0 15.2,0
           C13.44,0 12,1.44 12,3.2
           C12,4.96 13.44,6.4 15.2,6.4Z
           M6.4,13.6
           C6.4,12.48 5.76,11.52 4.96,10.88
           C5.44,8.64 6.88,6.72 8.96,5.6
           C9.44,5.44 9.92,4.96 9.92,4.16
           C9.92,3.36 9.28,2.56 8.32,2.56
           C8,2.56 7.84,2.56 7.52,2.72
           C4.48,4.32 2.4,7.2 1.76,10.72
           C0.64,11.36 0,12.48 0,13.6
           C0,15.36 1.44,16.8 3.2,16.8
           C4.96,16.8 6.4,15.36 6.4,13.6Z
           M20.48,15.52
           C18.72,15.52 17.28,16.96 17.28,18.72
           L17.28,19.2
           C16,20.16 14.4,20.64 12.8,20.64
           C10.88,20.64 9.12,20 7.68,18.88
           C7.36,18.56 7.04,18.4 6.56,18.4
           C5.6,18.4 4.96,19.04 4.96,20
           C4.96,20.48 5.12,20.96 5.44,21.28
           C7.36,23.04 9.92,24 12.8,24
           C15.2,24 17.6,23.2 19.36,21.92
           C19.68,22.08 20.16,22.08 20.48,22.08
           C22.24,22.08 23.68,20.64 23.68,18.88
           C23.68,17.12 22.24,15.52 20.48,15.52Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
