// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { AuthState } from './AuthInfoCallback';
import * as jwt from './jwt';

// Auth0 authenticator status.
//
// AUTHENTICATED means that the browser assumes that it has a valid
// authentication token. This is the initial state.
// The browser may issue RPCs to the backend.  If an RPC
// fails with an authentication error, the authstate transitions to
// UNAUTHENTICATED.
//
// UNAUTHENTICATED means that auth0 has been initialized, and the browser
// doesn't have a valid authentication token.  When UNAUTHENTICATED, the browser
// must start a login process. After a successful login, the authstate
// transitions to AUTHENTICATED.

export interface AuthInfoV2 {
  authState: AuthState,
  jwt: jwt.Jwt | null,
}

export type AuthInfoCallbackV2 = (arg0: AuthInfoV2) => void;

/**
 * Get the cached AuthInfo from the browser cookies.
 * Returned authState is best effort.  If the Jwt is missing or expired, return UNAUTHENTICATED.
 * Otherwise, return AUTHENTICATED and the server is responsible for identifying a tampered or
 * otherwise invalid Jwt.
 *
 * On every RPC, the server should validate the Jwt. When it returns an authentication error,
 * hasCredentials will become false, causing the app to show the LoginPage.
 */
function cachedAuthInfoV2(): AuthInfoV2 {
  const cachedJwt = jwt.loadSessionJwt();
  let authState = AuthState.UNAUTHENTICATED;
  if (jwt.isExpired(cachedJwt)) {
    authState = AuthState.AUTHENTICATION_EXPIRED;
  } else if (jwt.isValid(cachedJwt)) {
    authState = AuthState.AUTHENTICATED;
  }
  return {
    authState,
    jwt: cachedJwt,
  };
}

let currentAuthInfo = cachedAuthInfoV2();

const authInfoCallbacks: AuthInfoCallbackV2[] = [];

export function addAuthInfoCallbackV2(cb: AuthInfoCallbackV2): void {
  authInfoCallbacks.push(cb);
  cb(currentAuthInfo);
}

export function removeAuthInfoCallbackV2(cb: AuthInfoCallbackV2): void {
  const index = authInfoCallbacks.indexOf(cb);
  if (index < 0) {
    throw Error('authcallback not found');
  }
  authInfoCallbacks.splice(index, 1);
}

export function getAuthInfoV2(): AuthInfoV2 {
  return currentAuthInfo;
}

export function setAuthInfoV2(info: AuthInfoV2) {
  currentAuthInfo = info;
  authInfoCallbacks.forEach((cb: AuthInfoCallbackV2) => cb(info));
}
