// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { Logger } from './observability/logs';

const logger = new Logger('browserStorage');

// Parse data stored in a Storage bin and return it or a default value as type T
function getBrowserStorage<T>(
  storage: Storage,
  storageKey: string,
  defaultValue: T,
) {
  const savedJSON = storage.getItem(storageKey);

  if (savedJSON !== null) {
    try {
      return JSON.parse(savedJSON) as T;
    } catch (error) {
      logger.error('Error parsing JSON from local storage', error);
    }
  }

  return defaultValue;
}

// Return data of type T from Window.localStorage
export function getLocalStorageData<T>(storageKey: string, defaultValue: T): T {
  return getBrowserStorage(localStorage, storageKey, defaultValue);
}

// Return data of type T from Window.sessionStorage
export function getSessionStorageData<T>(storageKey: string, defaultValue: T): T {
  return getBrowserStorage(sessionStorage, storageKey, defaultValue);
}
