// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { findFluidBoundaryCondition, findHeatBoundaryCondition, findParentPhysicsByBoundaryConditionId } from '../../../lib/boundaryConditionUtils';
import { copiedName } from '../../../lib/name';
import { newNodeId } from '../../../lib/projectDataUtils';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { useSelectionContext } from '../../context/SelectionManager';
import { useSimulationConfig } from '../useSimulationConfig';

function makeCopy<
  T extends simulationpb.BoundaryConditionsFluid | simulationpb.BoundaryConditionsHeat
>(boundaryCondition: T, name: string): T {
  const newBoundaryCondition = boundaryCondition.clone();
  newBoundaryCondition.boundaryConditionName = newNodeId();
  newBoundaryCondition.boundaryConditionDisplayName = copiedName(name);
  newBoundaryCondition.surfaces = [];
  return newBoundaryCondition as T;
}

/**
 * @returns a callback which takes a name and id and duplicates the fluid boundary
 * condition with that id.
 */
export const useCopyFluidBC = () => {
  const { setSelection, setScrollTo } = useSelectionContext();
  const { saveParamAsync } = useSimulationConfig();

  const copyFluidBC = useCallback(async (name: string, id: string) => {
    const newId = await saveParamAsync((param) => {
      const boundaryCondition = findFluidBoundaryCondition(param, id);
      if (!boundaryCondition) {
        return;
      }
      const physics = findParentPhysicsByBoundaryConditionId(param, id);
      if (physics?.params.case !== 'fluid') {
        return;
      }
      const newBoundaryCondition = makeCopy(boundaryCondition, name);
      physics.params.value.boundaryConditionsFluid.push(newBoundaryCondition);

      return newBoundaryCondition.boundaryConditionName;
    });
    if (newId) {
      setSelection([newId]);
      setScrollTo({ node: newId });
    }
  }, [saveParamAsync, setSelection, setScrollTo]);

  return copyFluidBC;
};

/**
 * @returns a callback which takes a name and id and duplicates the heat boundary
 * condition with that id.
 */
export const useCopyHeatBC = () => {
  const { setSelection, setScrollTo } = useSelectionContext();
  const { saveParamAsync } = useSimulationConfig();

  const copyHeatBC = useCallback(async (name: string, id: string) => {
    const newId = await saveParamAsync((param) => {
      const boundaryCondition = findHeatBoundaryCondition(param, id);
      if (!boundaryCondition) {
        return;
      }
      const physics = findParentPhysicsByBoundaryConditionId(param, id);
      if (physics?.params.case !== 'heat') {
        return;
      }
      const newBoundaryCondition = makeCopy(boundaryCondition, name);
      physics.params.value.boundaryConditionsHeat.push(newBoundaryCondition);

      return newBoundaryCondition.boundaryConditionName;
    });
    if (newId) {
      setSelection([newId]);
      setScrollTo({ node: newId });
    }
  }, [saveParamAsync, setSelection, setScrollTo]);

  return copyHeatBC;
};
