// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 105;
const baseHeight = 117;
export const CubeSolidIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="cubeSolid"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M98.953,25.547
           L52.5,54.581
           L6.047,25.547
           L48.779,1.129
           C51.085,-0.188 53.915,-0.188 56.221,1.129
           L98.953,25.547Z
           M104.579,30.875
           C104.854,31.66 105,32.497 105,33.354
           L105,84.65
           C105,87.341 103.558,89.826 101.221,91.162
           L56.25,116.859
           L56.25,61.081
           L104.579,30.875Z
           M0.422,30.875
           L48.75,61.081
           L48.75,116.859
           L3.779,91.162
           C1.442,89.826 0,87.341 0,84.65
           L0,33.354
           C0,32.497 0.146,31.66 0.422,30.875Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
