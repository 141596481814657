// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as rpc from '../rpc';

import { RpcQueue } from './RpcQueue';

class SessionStateRpcQueue extends RpcQueue<
  string,
  frontendpb.SessionStateRequest,
  Uint8Array,
  frontendpb.SessionStateReply> {
  packData(keys: string[], req: frontendpb.SessionStateRequest): void {
    req.key.push(...keys);
  }

  unpackData(index: number, reply: frontendpb.SessionStateReply): Uint8Array {
    return reply.value[index] as Uint8Array;
  }

  getDataSize(req: frontendpb.SessionStateRequest): number {
    return req.key.length;
  }

  sendRequest(req: frontendpb.SessionStateRequest):
    Promise<frontendpb.SessionStateReply> {
    return rpc.callRetry('sessionState', rpc.client.sessionState, req);
  }

  clearData(req: frontendpb.SessionStateRequest): void {
    req.key = [];
  }
}

export default SessionStateRpcQueue;
