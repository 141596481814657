// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { LCVManipulationMode, LCVObject, LCVType } from '@luminarycloudinternal/lcvis';
import { SetterOrUpdater } from 'recoil';

import { listToPv } from '../../../Vector';
import { EditState } from '../../../visUtils';
import { LcvModule } from '../../types';

import { LcvFilterWidgetType, LcvWidget } from './LcvWidget';

export type LcvClipPlaneWidgetState = {
  position: [number, number, number],
  normal: [number, number, number],
  thickness: number,
}

type LcvClipPlaneCallback = (newState: LcvClipPlaneWidgetState, message?: string) => void;

// Slice thickness is a relative value with respect to the current
// visisble bounds. The actual world space thickness is a percent of
// the diagonal of the visible bounding bounding box so it scales with
// the size of the dataset.
const SLICE_THICKNESS = 0.005;
const MAX_CLIP_THICKNESS = 1;

/** A widget which enables clipping and slicing the geometry. */
export class LcvClipPlaneWidget extends LcvWidget implements LcvFilterWidgetType {
  hasCallback = false;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, sessionHandle, 'clip_plane');
  }

  getState(): LcvClipPlaneWidgetState {
    return {
      position: this.getProperty('position', LCVType.kLCVDataTypeFloat3),
      normal: this.getProperty('normal', LCVType.kLCVDataTypeFloat3),
      thickness: this.getProperty('thickness', LCVType.kLCVDataTypeFloat),
    };
  }

  setState(newState: Partial<LcvClipPlaneWidgetState>) {
    const { position, normal, thickness } = newState;
    if (position) {
      this.setParam('position', LCVType.kLCVDataTypeFloat3, position);
    }
    if (normal) {
      this.setParam('normal', LCVType.kLCVDataTypeFloat3, normal);
    } if (thickness) {
      this.setParam('thickness', LCVType.kLCVDataTypeFloat, thickness);
    }
  }

  // Reset the state of the clip widget to be centered on the
  // visible bounds with the default orientation.
  resetState() {
    this.setParam('reset', LCVType.kLCVDataTypeUint, 1);
  }

  // make this widget act as a slice filter
  setToSlice() {
    this.setParam('thickness', LCVType.kLCVDataTypeFloat, SLICE_THICKNESS);
  }

  // make this widget act as a clip filter. the 1 value means the the clip
  // thickness is set to the length of the diagonal of the visible bounds.
  setToClip() {
    this.setParam('thickness', LCVType.kLCVDataTypeFloat, MAX_CLIP_THICKNESS);
  }

  setCallback(callback: LcvClipPlaneCallback | null) {
    if (!callback) {
      this.setParam('callback', LCVType.kLCVDataTypeFunction, null);
      this.hasCallback = false;
      return;
    }
    this.hasCallback = true;
    const internalCallback = (_lcv: any, _session: LCVObject, obj: LCVObject, message: string) => {
      requestAnimationFrame(() => {
        const newState = this.getState();
        callback(newState, message);
      });
    };
    this.setParam('callback', LCVType.kLCVDataTypeFunction, internalCallback);
  }

  setEditStateCallback(setEditState: SetterOrUpdater<EditState | null> | null): void {
    if (setEditState === null) {
      this.setCallback(null);
      return;
    }

    this.setCallback((newState: LcvClipPlaneWidgetState) => {
      const { position, normal } = newState;
      setEditState((oldState) => {
        if (oldState === null) {
          return null;
        }
        return {
          ...oldState,
          param: {
            ...oldState.param,
            filterParam: {
              typ: 'Plane',
              origin: listToPv(position),
              normal: listToPv(normal),
            },
          },
        };
      });
    });
  }

  showControls(): void {
    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      LCVManipulationMode.kLCVManipulationModeTranslateRotate,
    );
  }

  hideControls(): void {
    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      LCVManipulationMode.kLCVManipulationModeNone,
    );
  }
}
