// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { MultipleChoiceParam, Param, ParamType } from '../../../ProtoDescriptor';
import { ParamName, paramDesc } from '../../../SimulationParamDescriptor';
import { ParamScope } from '../../../lib/ParamScope';
import assert from '../../../lib/assert';
import {
  getCompatibleTablesMap,
  hasKeyReference,
} from '../../../lib/rectilinearTable/globalMap';
import {
  BladeGeometryTableDefinition,
  RadialDistributionTableDefinition,
} from '../../../lib/rectilinearTable/model';
import { checkBladeElementGeometryTable, checkRadialStation } from '../../../lib/rectilinearTable/util';
import { AdFloatType, AdVector3, Int } from '../../../proto/base/base_pb';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { ParamRowProps } from '../../ParamRow';
import { useProjectContext } from '../../context/ProjectContext';
import { ChangeOperation } from '../../controls/TableMapInput';
import { useGetFanCurve } from '../boundaryConditions/useFanCurve';

const {
  ACTUATOR_DISK_BLADE_ELEMENT,
  ACTUATOR_DISK_RADIAL_DISTRIBUTION,
  ACTUATOR_DISK_UNIFORM_THRUST,
  FAN_CURVE_INTERNAL,
  INVALID_ACTUATOR_DISK_MODEL,
} = simulationpb.ActuatorDiskModel;

type ValueUpdater = (
  behavior: simulationpb.PhysicalBehavior,
  newParam: simulationpb.SimulationParam,
) => void;

export const useGetPhysicalBehaviorCellProps = (
  simParam: simulationpb.SimulationParam,
  readOnly: {
    general: boolean;
    blade: boolean;
    advanced: boolean;
  },
) => {
  const { projectId } = useProjectContext();
  const getFanCurve = useGetFanCurve();

  return (
    behavior: simulationpb.PhysicalBehavior,
    paramScope: ParamScope,
    updateField: (updateValue: ValueUpdater) => void = () => { },
    updateTableField: (
      value: ChangeOperation,
      saveName: (newBehavior: simulationpb.PhysicalBehavior, name: string) => void,
    ) => void = () => { },
    deleteImposters: () => void = () => { },
  ) => {
    const behaviorType = behavior.physicalBehaviorModel;

    const isEnabled = (param: Param) => paramScope.isEnabled(param.cond);

    const filterChoices = (param: Param): MultipleChoiceParam => {
      assert(param.type === ParamType.MULTIPLE_CHOICE, 'Param is not multiple choice');
      const mpParam = param as MultipleChoiceParam;
      return {
        ...mpParam,
        choices: mpParam.choices.filter((choice) => paramScope.isEnabled(choice.cond)),
      };
    };

    const fields = {
      diskType: paramDesc[ParamName.ActuatorDiskModel],
      lineType: paramDesc[ParamName.ActuatorLineModel],
      srcPointsType: paramDesc[ParamName.ParticleSourceModel],
      thrust: paramDesc[ParamName.ActuatorDiskThrust],
      torque: paramDesc[ParamName.ActuatorDiskTorque],
      radialForce: paramDesc[ParamName.ActuatorDiskRadialForce],
      radialDistribution: paramDesc[ParamName.ActuatorDiskRadialTableData],
      rotationRate: paramDesc[ParamName.ActuatorDiskRotationRate],
      referenceSoundSpeed: paramDesc[ParamName.BladeElementReferenceSoundSpeed],
      bladeCount: paramDesc[ParamName.ActuatorDiskBladeCount],
      injectionRate: paramDesc[ParamName.SourceParticleMassInjectionRate],
      accelerationVector: paramDesc[ParamName.SourceParticleAccelerationVector],
      bladeElementGeometry: paramDesc[ParamName.BladeElementGeometryData],
      pitchSchedule: paramDesc[ParamName.BladeElementPitchSchedule],
      headLossCoefficient: paramDesc[ParamName.HeadLossCoefficient],
    };

    const changeDiskModel = (value: any) => {
      updateField((newBehavior) => {
        // Anytime we change models, just issue a call to delete the imposters.
        deleteImposters();
        newBehavior.actuatorDiskModel = value as simulationpb.ActuatorDiskModel;
      });
    };

    const changeLineModel = (value: any) => {
      updateField((newBehavior) => {
        newBehavior.actuatorLineModel = value as number;
      });
    };

    const changeSourcePointsModel = (value: any) => {
      updateField((newBehavior) => {
        newBehavior.particleSourceModel = value as number;
      });
    };

    const changeThrust = (value: any) => {
      updateField((newBehavior) => {
        newBehavior.actuatorDiskThrust = value as AdFloatType;
      });
    };

    const changeTorque = (value: any) => {
      updateField((newBehavior) => {
        newBehavior.actuatorDiskTorque = value as AdFloatType;
      });
    };

    const changeRadialForce = (value: any) => {
      updateField((newBehavior) => {
        newBehavior.actuatorDiskRadialForce = value as AdFloatType;
      });
    };

    const changeRadialDistribution = (value: ChangeOperation) => {
      updateTableField(
        value,
        (newBehavior, tableName) => {
          newBehavior.actuatorDiskRadialTableData = tableName;
        },
      );
    };

    const changeRotationRate = (value: any) => {
      updateField((newBehavior) => {
        newBehavior.actuatorDiskRotationRate = value as AdFloatType;
      });
    };

    const changeReferenceSoundSpeed = (value: any) => {
      updateField((newBehavior) => {
        newBehavior.bladeElementReferenceSoundSpeed = value as AdFloatType;
      });
    };

    const changeBladeCount = (value: any) => {
      updateField((newBehavior) => {
        newBehavior.actuatorDiskBladeCount = value as Int;
      });
    };

    const changeInjectionRate = (value: any) => {
      updateField((newBehavior) => {
        newBehavior.sourceParticleMassInjectionRate = value as AdFloatType;
      });
    };

    const changeAccelerationVector = (value: any) => {
      updateField((newBehavior) => {
        newBehavior.sourceParticleAccelerationVector = value as AdVector3;
      });
    };

    const changeBladeGeometry = (value: ChangeOperation) => {
      updateTableField(
        value,
        (newBehavior, tableName) => {
          newBehavior.bladeElementGeometryData = tableName;
        },
      );
    };

    const changePitchSchedule = (value: any) => {
      updateField(
        (newBehavior) => {
          newBehavior.bladeElementPitchSchedule = value as AdVector3;
        },
      );
    };

    const changeHeadLossField = (value: any) => {
      updateField((newBehavior) => {
        newBehavior.headLossCoefficient = value;
      });
    };

    const props = {
      general: [] as ParamRowProps[],
      blade: [] as ParamRowProps[],
      advanced: [] as ParamRowProps[],
    };

    switch (behaviorType) {
      case simulationpb.PhysicalBehaviorModel.ACTUATOR_DISK_MODEL: {
        const diskModel = behavior.actuatorDiskModel;
        assert(isEnabled(fields.diskType), 'Disk type field is not enabled');
        props.general.push(
          {
            nestLevel: 0,
            inputOptions: { explicitHelp: true },
            param: filterChoices(fields.diskType),
            readOnly: readOnly.general,
            setValue: changeDiskModel,
            value: diskModel,
          },
        );
        switch (diskModel) {
          case ACTUATOR_DISK_UNIFORM_THRUST: {
            assert(isEnabled(fields.thrust), 'Thrust field is not enabled');
            props.general.push({
              nestLevel: 0,
              param: fields.thrust,
              readOnly: readOnly.general,
              setValue: changeThrust,
              value: behavior.actuatorDiskThrust,
            });
            break;
          }
          case ACTUATOR_DISK_RADIAL_DISTRIBUTION: {
            if (isEnabled(fields.radialDistribution)) {
              props.general.push({
                label: 'Aero File',
                nestLevel: 0,
                inputOptions: {
                  tableMapOptions: {
                    dialogTitle: 'Aero File Upload',
                    dialogSubtitle: 'Upload a radial distribution file to model your propeller',
                    tableDefinition: RadialDistributionTableDefinition,
                    tableMap: getCompatibleTablesMap(simParam, RadialDistributionTableDefinition),
                    tableErrorFunc: (table) => checkRadialStation(table, 0),
                    nameErrorFunc: (name) => {
                      if (hasKeyReference(simParam, name)) {
                        return 'Name is already in use';
                      }
                      return '';
                    },
                    unlinkTooltip: 'Unlink aero file',
                  },
                },
                projectId,
                param: fields.radialDistribution,
                readOnly: readOnly.general,
                setValue: changeRadialDistribution,
                value: behavior.actuatorDiskRadialTableData,
              });
            }
            if (isEnabled(fields.thrust)) {
              props.general.push({
                nestLevel: 0,
                param: fields.thrust,
                readOnly: readOnly.general,
                setValue: changeThrust,
                value: behavior.actuatorDiskThrust,
              });
            }
            if (isEnabled(fields.torque)) {
              props.general.push({
                nestLevel: 0,
                param: fields.torque,
                readOnly: readOnly.general,
                setValue: changeTorque,
                value: behavior.actuatorDiskTorque,
              });
            }
            if (isEnabled(fields.radialForce)) {
              props.general.push({
                label: 'Radial Force',
                nestLevel: 0,
                param: fields.radialForce,
                readOnly: readOnly.general,
                setValue: changeRadialForce,
                value: behavior.actuatorDiskRadialForce,
              });
            }
            break;
          }
          case ACTUATOR_DISK_BLADE_ELEMENT: {
            if (isEnabled(fields.bladeCount)) {
              props.general.push({
                nestLevel: 0,
                inputOptions: { numberOptions: { inputType: 'spinner', min: 0 } },
                param: fields.bladeCount!,
                readOnly: readOnly.general,
                setValue: changeBladeCount,
                value: behavior.actuatorDiskBladeCount ?? 0,
              });
            }
            if (isEnabled(fields.rotationRate)) {
              props.general.push({
                nestLevel: 0,
                param: fields.rotationRate!,
                readOnly: readOnly.general,
                setValue: changeRotationRate,
                value: behavior.actuatorDiskRotationRate ?? 0,
              });
            }
            if (isEnabled(fields.referenceSoundSpeed)) {
              props.general.push({
                nestLevel: 0,
                param: fields.referenceSoundSpeed!,
                readOnly: readOnly.general,
                setValue: changeReferenceSoundSpeed,
                value: behavior.bladeElementReferenceSoundSpeed ?? 0,
              });
            }
            if (isEnabled(fields.bladeElementGeometry)) {
              props.blade.push({
                nestLevel: 0,
                inputOptions: {
                  tableMapOptions: {
                    dialogTitle: 'Aero File Upload',
                    dialogSubtitle: 'Upload a blade geometry file',
                    tableDefinition: BladeGeometryTableDefinition,
                    tableMap: getCompatibleTablesMap(simParam, BladeGeometryTableDefinition),
                    tableErrorFunc: (table) => checkBladeElementGeometryTable(table),
                    nameErrorFunc: (name) => {
                      if (hasKeyReference(simParam, name)) {
                        return 'Name is already in use';
                      }
                      return '';
                    },
                    unlinkTooltip: 'Unlink aero file',
                  },
                },
                projectId,
                param: fields.bladeElementGeometry,
                readOnly: readOnly.blade,
                setValue: changeBladeGeometry,
                value: behavior.bladeElementGeometryData,
              });
            }
            if (isEnabled(fields.pitchSchedule)) {
              props.advanced.push({
                nestLevel: 0,
                inputOptions: { showUnits: true },
                param: fields.pitchSchedule,
                readOnly: readOnly.advanced,
                setValue: changePitchSchedule,
                value: behavior.bladeElementPitchSchedule,
              });
            }
            break;
          }
          case FAN_CURVE_INTERNAL: {
            props.general.push(
              getFanCurve(behavior.physicalBehaviorId).fanCurveUploadElement?.element.props,
            );
            props.general.push({
              nestLevel: 0,
              param: fields.headLossCoefficient,
              readOnly: readOnly.general,
              setValue: changeHeadLossField,
              value: behavior.headLossCoefficient,
            });
            break;
          }
          case INVALID_ACTUATOR_DISK_MODEL: {
            break;
          }
          // no default
        }
        break;
      }
      case simulationpb.PhysicalBehaviorModel.ACTUATOR_LINE_MODEL: {
        const lineModel = behavior.actuatorLineModel;
        assert(isEnabled(fields.lineType), 'Line type field is not enabled');
        props.general.push(
          {
            nestLevel: 0,
            param: filterChoices(fields.lineType),
            readOnly: readOnly.general,
            setValue: changeLineModel,
            value: lineModel,
          },
        );
        break;
      }
      case simulationpb.PhysicalBehaviorModel.SOURCE_POINTS_MODEL: {
        const srcPtsModel = behavior.particleSourceModel;
        assert(isEnabled(fields.srcPointsType), 'Source points type field is not enabled');
        props.general.push(
          {
            nestLevel: 0,
            param: filterChoices(fields.srcPointsType),
            readOnly: readOnly.general,
            setValue: changeSourcePointsModel,
            value: srcPtsModel,
          },
        );

        switch (srcPtsModel) {
          case simulationpb.ParticleSourceModel.GENERAL_MASS_SOURCE: {
            assert(isEnabled(fields.injectionRate), 'Injection rate field is not enabled');
            props.general.push({
              nestLevel: 0,
              param: fields.injectionRate,
              readOnly: readOnly.general,
              setValue: changeInjectionRate,
              value: behavior.sourceParticleMassInjectionRate,
            });
            break;
          }
          case simulationpb.ParticleSourceModel.GENERAL_ACCELERATION_SOURCE: {
            assert(
              isEnabled(fields.accelerationVector),
              'Acceleration vector field is not enabled',
            );
            props.general.push({
              nestLevel: 0,
              param: fields.accelerationVector,
              readOnly: readOnly.general,
              setValue: changeAccelerationVector,
              value: behavior.sourceParticleAccelerationVector,
              inputOptions: { verticalVectors: true, showAxisLabel: true },
            });
            break;
          }
          case simulationpb.ParticleSourceModel.INVALID_PARTICLE_SOURCE_MODEL: {
            break;
          }
          // no default
        }
        break;
      }
      case simulationpb.PhysicalBehaviorModel.INVALID_PHYSICAL_BEHAVIOR_MODEL: {
        break;
      }
      // no default
    }

    return props;
  };
};
