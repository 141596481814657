// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { ParamScope, createParamScope } from '../../../../lib/ParamScope';
import { RecoilProjectKey } from '../../../../lib/persist';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { enabledExperimentsSelector_DEPRECATED } from '../../../../recoil/useExperimentConfig';

import { simulationParamState } from './param';

export interface ParamData {
  param: simulationpb.SimulationParam;
  paramScope: ParamScope;
}

/**
 * Shared state for the param scope built from a simulation param
 *
 * Use with caution!!  This state represents the ParamScope object built from the currently saved
 * simulation params and may not be useful in components that are actively updating the params.
 */
export const simulationParamScopeState = selectorFamily<ParamScope, RecoilProjectKey>({
  key: 'simulationParamScopeState',
  get: (key) => ({ get }) => {
    const [
      experimentConfig,
      param,
    ] = get(waitForAll([enabledExperimentsSelector_DEPRECATED, simulationParamState(key)]));
    return createParamScope(param, experimentConfig);
  },
});

export function useSimulationParamScope(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(simulationParamScopeState({ projectId, workflowId, jobId }));
}
