// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/cad/shape.proto (package luminary.proto.cad, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Vector3 } from "../base/base_pb.js";

/**
 * Sphere defines the location and size of a sphere.
 *
 * @generated from message luminary.proto.cad.Sphere
 */
export const Sphere = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.Sphere",
  () => [
    { no: 1, name: "center", kind: "message", T: Vector3 },
    { no: 2, name: "radius", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * SphereShell defines the location and size of a spherical shell.
 *
 * @generated from message luminary.proto.cad.SphereShell
 */
export const SphereShell = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.SphereShell",
  () => [
    { no: 1, name: "center", kind: "message", T: Vector3 },
    { no: 2, name: "radius", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "radius_inner", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * HalfSphere defines the location, size, and orientation of a hemisphere.
 *
 * @generated from message luminary.proto.cad.HalfSphere
 */
export const HalfSphere = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.HalfSphere",
  () => [
    { no: 1, name: "center", kind: "message", T: Vector3 },
    { no: 2, name: "radius", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "normal", kind: "message", T: Vector3 },
  ],
);

/**
 * Cube defines the location and size of a rectangular prism.
 *
 * @generated from message luminary.proto.cad.Cube
 */
export const Cube = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.Cube",
  () => [
    { no: 1, name: "min", kind: "message", T: Vector3 },
    { no: 2, name: "max", kind: "message", T: Vector3 },
  ],
);

/**
 * OrientedCube defines the location and size of a rectangular prism
 * arbitrarily translated and rotated. min and max in transformed coordinates
 *
 * @generated from message luminary.proto.cad.OrientedCube
 */
export const OrientedCube = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.OrientedCube",
  () => [
    { no: 1, name: "min", kind: "message", T: Vector3 },
    { no: 2, name: "max", kind: "message", T: Vector3 },
    { no: 3, name: "origin", kind: "message", T: Vector3 },
    { no: 4, name: "x_axis", kind: "message", T: Vector3 },
    { no: 5, name: "y_axis", kind: "message", T: Vector3 },
  ],
);

/**
 * Cylinder defines the location and size of a cylinder.
 *
 * @generated from message luminary.proto.cad.Cylinder
 */
export const Cylinder = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.Cylinder",
  () => [
    { no: 1, name: "start", kind: "message", T: Vector3 },
    { no: 2, name: "end", kind: "message", T: Vector3 },
    { no: 3, name: "radius", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * AnnularCylinder defines the location and size of an annular cylinder.
 *
 * @generated from message luminary.proto.cad.AnnularCylinder
 */
export const AnnularCylinder = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.AnnularCylinder",
  () => [
    { no: 1, name: "start", kind: "message", T: Vector3 },
    { no: 2, name: "end", kind: "message", T: Vector3 },
    { no: 3, name: "radius", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "radius_inner", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Torus defines the location and size of a torus.
 *
 * @generated from message luminary.proto.cad.Torus
 */
export const Torus = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.Torus",
  () => [
    { no: 1, name: "center", kind: "message", T: Vector3 },
    { no: 2, name: "normal", kind: "message", T: Vector3 },
    { no: 3, name: "major_radius", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "minor_radius", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Cone defines the location and size of a cone.
 *
 * @generated from message luminary.proto.cad.Cone
 */
export const Cone = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.Cone",
  () => [
    { no: 1, name: "apex", kind: "message", T: Vector3 },
    { no: 2, name: "base_center", kind: "message", T: Vector3 },
    { no: 3, name: "base_radius", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

