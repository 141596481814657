/**
 * The assistant macros are functions that the assistant
 * can call to perform actions in the app.
 *
 * But, most of the assistant macros need to be able to call into React state and hooks.
 * So the logic for these macros is implemented in the MacroSubscriber component,
 * but they are triggered by the functions in this file.
 *
 * The MacroSubscriber subscribes to the macros called by the assistant,
 * and calls the appropriate React hooks to update the app state.
 */

import { BehaviorSubject } from 'rxjs';

// The supported macros that the assistant can call.
const supportedMacros = [
  'home',
  'openProject',
  'selectNode',
  'uploadCadOrMesh',
  'zoomToFit',
  'minimalMesh',
  'createSimulation',
  'setMeshAdaptation',
  'pullSettings',
  'pushSettings',
  'pullUiState',
] as const;

export type MacroName = typeof supportedMacros[number];

// The type of the object emitted by the macro emitter.
export type MacroEmitterType = {
  action: MacroName,
  args?: any[],
  resolve: (value: any) => void,
  reject: (reason?: any) => void,
}

/**
 * A subject that emits the next macro to be executed by the assistant.
 */
export const macroEmitter = new BehaviorSubject<MacroEmitterType | null>(null);

/**
 * A function that the assistant can call to execute a macro.
 * @param action The name of the macro to execute.
 * @param args The arguments to pass to the macro.
 * @returns A promise that resolves when the macro completes.
 */
export function executeMacro<T>(action: MacroName, args?: any[]): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    macroEmitter.next({ action, args, resolve, reject });
  });
  // Note: thrown errors are caught by the assistantCall.ts
}

// The home macro navigates to the home page.
export const home = () => executeMacro<void>('home');

// The openProject navigates to a project.
export const openProject = (
  projectId: string,
) => executeMacro<void>('openProject', [projectId]);

// Selects and opens a node from the tree (and expands its parents if needed)
export const selectNode = (nodeId: string) => executeMacro<void>('selectNode', [nodeId]);

// The uploadCadOrMesh macro opens a project file dialog to upload a CAD or mesh file.
export const uploadCadOrMesh = (
  projectId: string,
) => executeMacro<void>('uploadCadOrMesh', [projectId]);

// The zoomToFit macro zooms the geometry so it fits into the visualizer.
export const zoomToFit = () => executeMacro<string>('zoomToFit');

// The minimalMesh macro creates a minimal mesh.
export const minimalMesh = () => executeMacro<string>('minimalMesh');

// The createSimulation macro clicks 'run simulation' if possible.
export const createSimulation = () => executeMacro<string>('createSimulation');

export const adaptMesh = (
  projectId: string,
  maxMeshSize: number,
  adaptBoundaryLayerCount: number,
  initialSize: number,
  growthRate: number,
) => executeMacro<string>(
  'setMeshAdaptation',
  [projectId, maxMeshSize, adaptBoundaryLayerCount, initialSize, growthRate],
);

// The pullSettings macro pulls the current simulation settings.
export const pullSettings = () => executeMacro<string>('pullSettings');

// The pushSettings macro pushes settings to the current simulation.
export const pushSettings = (
  settings: string,
) => executeMacro<string>('pushSettings', [settings]);

// The pullUiState macro for syncing ui state to the assistant
export const pullUiState = () => executeMacro<void>('pullUiState');

export type MacroFn = (...args: any[]) => Promise<any>;

export const MacroMap = new Map<MacroName | string, MacroFn>([
  // --- Macro: home ---
  // Navigates to the home page (project list)
  // No parameters
  // Callback #1 - if success, no value; if faulure, error message
  ['home', home],

  // --- Macro: openProject ---
  // Navigate to a project
  // Param #1 - id of existing project
  // Callback #1 - if success, no value; if failure, error message
  ['openProject', openProject],

  // --- Macro: selectNode ---
  // Selects and opens a node from the tree
  // Param #1 - id of node to open
  // Callback #1 - if success, no value; if failure, error message
  ['selectNode', selectNode],

  // --- Macro: uploadCadOrMesh ---
  // Uploads a cad or mesh file into an existing project
  // Param #1 - id of existing project
  // Callback #1 - if success, no value; if failure, error message
  ['uploadCadOrMesh', uploadCadOrMesh],

  // --- Macro: zoomToFit ---
  // Zooms the geometry to fit into the visualizer
  // No parameters
  // Callback #1 - if success, no value; if failure, error message
  ['zoomToFit', zoomToFit],

  // --- Macro: minimalMesh
  // Generates a minimal mesh for the current project
  // No parameters
  // Callback #1 - if success, no value; if failure, error message
  ['minimalMesh', minimalMesh],

  // --- Macro: createSimulation
  // Click the Run Simulation button of the currently open project
  // No parameters
  // Callback #1 - if success, project name; if failure, null
  ['createSimulation', createSimulation],

  // --- Macro: meshAdaptation
  // Adapts mesh for the named project
  // Param #1 - id of existing project
  // Param #2 - max mesh size (default: 10, unit: million CVs)
  // Param #3 - adapt boundary layer count (default: 30)
  // Param #4 - initial size (default: 5e-6)
  // Param #5 - growth rate (default: 1.2)
  // Callback #1 - if success, no value; if failure, error message
  ['setMeshAdaptation', adaptMesh],

  // --- Macro: pullSettings
  // Allows assistant to pull current simulation settings without user intervention
  // No parameters
  // Callback #1 - if success, serialized settings; if failure, error message
  ['pullSettings', pullSettings],

  // --- Macro: pushSettings
  // Allows assistant to push settings to current simulation without user intervention
  // Param #1 - serialized settings
  // Callback #1 - if success, no value; if failure, error message
  ['pushSettings', pushSettings],

  // -- Macro: pullUiState
  // Get the current state of UI
  // No parameters
  // Callback #1 - if success, object with below object shape; if failure, error message
  // Object: {
  //   visualizerReady, visualizerUsed, volumeIdToName, currentView, assistantEnabled,
  //   surfaceIdToName,
  //   projectName, workflowId, jobId, isMesh,
  // }
  ['pullUiState', pullUiState],
]);
