// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import assert from '../../../../lib/assert';
import { parsePhysicsIdFromSubId } from '../../../../lib/physicsUtils';
import { NodeType } from '../../../../lib/simulationTree/node';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { usePhysicsVolumes } from '../../../hooks/subselect/usePhysicsVolumes';
import { NodeSubselect } from '../../NodeSubselect';
import PropertiesSection from '../../PropertiesSection';

export const PhysicsVolumeSelectionPropPanel = () => {
  // Contexts
  const { readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected volume selection row');

  const {
    nodeIds,
    nodeFilter,
    setVolumeIds,
    mapVisualizerEntities,
  } = usePhysicsVolumes(parsePhysicsIdFromSubId(node.id));
  const propClasses = useCommonTreePropsStyles();

  return (
    <div className={propClasses.properties}>
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled
          heading="Geometry Selection for Physics"
          nodeId={node.id}
          panelName="volumes">
          <NodeSubselect
            id="physics-volume-selection"
            // We don't to unroll the entity groups in order to use tags.
            independentSelection
            labels={['volumes']}
            mapVisualizerEntities={mapVisualizerEntities}
            nodeFilter={nodeFilter}
            nodeIds={nodeIds}
            onChange={setVolumeIds}
            readOnly={readOnly}
            referenceNodeIds={[node.id]}
            showNotFoundNodes
            title="Volumes"
            visibleTreeNodeTypes={[NodeType.VOLUME, NodeType.TAGS_BODY, NodeType.TAGS_CONTAINER]}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
