// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import * as basepb from '../../../proto/base/base_pb';
import Form from '../../Form';
import { NumberInput } from '../../Form/NumberInput';
import { Vector3Input } from '../../Form/Vector3Input';

export interface SphereParam {
  // center of the sphere.
  center: basepb.Vector3;
  // radius of the sphere.
  radius: number;
}

export interface SphereInputProps {
  // The value to display
  param: SphereParam;
  // Can the value be modified by the user? Default false.
  readOnly?: boolean;
  // Called when the value is committed
  onCommit: (newParam: SphereParam) => void;
  // Called when the value is changed
  onChange?: (newParam: SphereParam) => void;
}

/** Dialog for specifying parameters for a Sphere in 3D space.
*/
export const SphereInput = (props: SphereInputProps) => {
  const { onChange, onCommit, param, readOnly } = props;
  const { center, radius } = param;

  return (
    <React.Fragment>
      <Form.LabeledInput help="Center of the sphere" label="Center">
        <Vector3Input
          disabled={readOnly}
          onChange={(newCenter) => onChange?.({ ...param, center: newCenter })}
          onCommit={(newCenter) => onCommit({ ...param, center: newCenter })}
          value={center}
        />
      </Form.LabeledInput>
      <Form.LabeledInput help="Radius of the sphere" label="Radius">
        <NumberInput
          asBlock
          disabled={readOnly}
          onChange={(newRadius) => onChange?.({ ...param, radius: newRadius })}
          onCommit={(newRadius) => onCommit({ ...param, radius: newRadius })}
          size="small"
          value={radius}
        />
      </Form.LabeledInput>
    </React.Fragment>
  );
};

SphereInput.defaultProps = {
  readOnly: false,
};
