// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Timestamp } from '@bufbuild/protobuf';
import { Code } from '@connectrpc/connect';
import { AtomEffect } from 'recoil';

import * as frontendpb from '../proto/frontend/frontend_pb';

import { isProjectAccessDeniedError, isProjectDeletedError } from './projectUtilsErrors';
import * as rpc from './rpc';
import SessionStateRpcQueue from './rpcQueue/SessionStateRpcQueue';
import * as status from './status';
import { EMPTY_UINT8_ARRAY } from './stringarray';
import { addRpcError } from './transientNotification';

// How long we wait after a value stops changing before we send a setState RPC.
// This is to prevent rapidly-changing states (for example, camera angle
// from spamming set RPCs.
let throttleIntervalMs = 100;

// Debounce timer for each state. Used to batch the setState call until the user
// stops calling setState for the same key for the throttle interval. It's null
// initially. On a setState call, it is set to the the value of setTimeout(...,
// throttleIntervalMs).
const timeoutSetState: { [key: string]: ReturnType<typeof setTimeout> | null } = {};

// Allow custom throttle interval for unit tests.
export const setThrottleIntervalMs = (intervalMs: number) => {
  throttleIntervalMs = intervalMs;
};

const internalKey = (projectId: string, key: string) => `${projectId}/${key}`;

// Returns Promise<string> of the value stored in the server-side
// persistence layer for the given key.
//
// Exported only for unittests.
export async function getState(projectId: string, key: string[]): Promise<Uint8Array[]> {
  const req = new frontendpb.SessionStateRequest({ projectId, key });
  try {
    const result = await rpc.callRetry('SessionState', rpc.client.sessionState, req);
    return result.value;
  } catch (err) {
    addRpcError('Failed to get project state', err);
    return key.map(() => EMPTY_UINT8_ARRAY);
  }
}

// Immediately sets the state for the requested key.
// Unlike setState, the caller can be assured that, if they wait for the returned
// promise to resolve, the state will be set server-side.
export async function setStateNow(projectId: string, key: string, time: Date, value: Uint8Array) {
  return setStateBatchNow(projectId, [key], time, [value]);
}

export async function setStateBatchNow(
  projectId: string,
  keys: string[],
  time: Date,
  values: Uint8Array[],
) {
  const timestamp = Timestamp.fromDate(time);
  const req = new frontendpb.SetSessionStateBatchRequest({
    projectId,
    key: keys,
    value: values,
    timestamp,
  });
  try {
    await rpc.callRetry('setSessionStateBatch', rpc.client.setSessionStateBatch, req);
  } catch (err) {
    const statusErr = status.toProto(status.forUser(err, ''));
    const projectDenied = isProjectAccessDeniedError(err.message) ||
      isProjectDeletedError(err.message);
    if (!projectDenied && statusErr?.code === Code.PermissionDenied) {
      addRpcError('Permission denied', err);
    } else {
      addRpcError('Failed to set project state', err);
    }
  }
}

export type State = {
  projectId: string,
  key: string,
  value: Uint8Array,
}

// Immediately set multiple states at once. Returns a list of promises that when resolved all states
// are set server-side
// TODO(LC-6846): Create rpc method to set multiple states at once.
export const setStatesNow = (states: State[]) => (
  states.map((state) => (
    setStateNow(state.projectId, state.key, new Date(), state.value)
  ))
);

// Schedules a call to set the state of the given key.  The actual
// update will be delayed until the user stops calling this function for at
// least throttleIntervalMs. For the given key, only the last call take effect.
//
// Exported only for unittests.
export const setState = (projectId: string, key: string, value: Uint8Array) => {
  const ik = internalKey(projectId, key);
  const time = new Date();
  const timeout = timeoutSetState[ik];
  timeout && clearTimeout(timeout);
  timeoutSetState[ik] = setTimeout(async () => {
    timeoutSetState[ik] = null;
    await setStateNow(projectId, key, time, value);
  }, throttleIntervalMs);
};

const rpcQueueMap: { [key: string]: SessionStateRpcQueue } = {};

async function getSessionState<T>(
  projectId: string,
  keys: string[],
  deserialize: (val: Uint8Array, index: number) => T,
): Promise<T> {
  let rpcQueue = rpcQueueMap[`rpcQueue/${projectId}`];
  if (!rpcQueue) {
    const request = new frontendpb.SessionStateRequest({
      projectId,
    });
    rpcQueue = new SessionStateRpcQueue(request);
    rpcQueueMap[`rpcQueue/${projectId}`] = rpcQueue;
  }
  return new Promise<T>((resolve) => rpcQueue.start(keys, (values) => {
    for (let i = 0; ; i += 1) {
      const data = values[i];
      if (!(data instanceof Uint8Array)) {
        throw Error(`cannot parse values=${values} i=${i} ${data} as Uint8Array`);
      }
      if (data.length || i >= values.length - 1) {
        return resolve(deserialize(data, i));
      }
    }
  }, keys.join(',')));
}

function getSessionStateEffect<T>(
  projectId: string,
  key: string,
  deserialize: (val: Uint8Array, index: number) => T,
): AtomEffect<T> {
  return ({ setSelf }) => {
    setSelf(getSessionState(projectId, [key], deserialize));
  };
}

export function resetSessionState(
  projectId: string,
  key: string,
  recoilReset: () => void,
) {
  return () => {
    setStateNow(projectId, key, new Date(), EMPTY_UINT8_ARRAY)
      .then(() => recoilReset())
      .catch(() => {
        throw Error(`Error while reseting state ${key}`);
      });
  };
}

// getProjectState/getUserState and setProjectStateEffect/setUserStateEffect are used in recoil
// atom or atomFamily to enable async fetching and updating of kvstore states.  If state is
// per-project, getProjectState/setProjectStateEffect should be used, and if it is not
// project-scoped, getUserState/setUserStateEffect should be used.
//
// Example:
//
//   function serialize(value: projectstatepb.MeshUrl | null) : Uint8Array {
//    return value ? value.serializeBinary() : EMPTY_UINT8_ARRAY;
//   }
//   function deserialize(value: Uint8Array) : projectstatepb.MeshUrl {
//    return value.length ? projectstatepb.MeshUrl.deserializeBinary(value) : EMPTY_MESH_URL;
//   }
//   const meshUrlState = atomFamily<projectstatepb.MeshUrl | null, string>({
//    key: 'meshUrl',
//    default: async (projectId: string) =>
//       persist.getSessionState(projectId, [meshKey], deserialize),
//    effects: (projectId: string) =>
//       [persist.setSessionStateEffect(projectId, meshKey, serialize)],
//    dangerouslyAllowMutability: true,
//   });
//

// getProjectState is an async function that fetches a list of keys from the
// backend. It then calls deserialize() for each value returned by the server.
// the "index" argument to deserialize is the index into keys[] (index=i for the
// value corresponding to key[i]).
//
// It can be used as the default value of an recoil atom or recoil atomFamily.

// setProjectStateEffect/setUserStateEffect generates a recoil atom effect that intercepts atom
// "set" calls and initiates backend project-state updates. Arg "serialize" is
// passed the new value. It should serialize it to an Uint8Array.

// getUserStateEffect generates a recoil atom effect triggered by 'get' calls and will fetch the
// current value and sets the deserialized value in the local state. It is used to
// initialize a user state for global atoms.

export async function getProjectState<T>(
  projectId: string,
  keys: string[],
  deserialize: (val: Uint8Array, index: number) => T,
): Promise<T> {
  return getSessionState<T>(projectId, keys, deserialize);
}

export function getUserStateEffect<T>(
  key: string,
  deserialize: (val: Uint8Array, index: number) => T,
): AtomEffect<T> {
  return getSessionStateEffect('', key, deserialize);
}

// Recoil key for (project) states stored in kv store
export type RecoilProjectKey = {
  projectId: string,
  workflowId: string,
  jobId: string,
}

// Recoil key for accessing project meshes and mesh parameters
export type RecoilMeshKey = {
  projectId: string,
  meshId: string,
  meshUrl: string
}

// Creates a kv store key based on a prefix and recoil key
export function getProjectStateKey(prefix: string, recoilKey: RecoilProjectKey) {
  return `${prefix}/workflow=${recoilKey.workflowId}/job=${recoilKey.jobId}`;
}

// This recoil effect can be used for storing any atom/atomFamily to the browser's sessionStorage
export function sessionStorageEffect<T>(storageKey: string): AtomEffect<T> {
  return ({ onSet }) => {
    onSet((value, _, isReset) => {
      if (isReset) {
        sessionStorage.removeItem(storageKey);
      } else {
        sessionStorage.setItem(storageKey, JSON.stringify(value));
      }
    });
  };
}

// This recoil effect can be used for storing any atom/atomFamily to the browser's localStorage
export function localStorageEffect<T>(storageKey: string): AtomEffect<T> {
  return ({ onSet }) => {
    onSet((value, _, isReset) => {
      if (isReset) {
        localStorage.removeItem(storageKey);
      } else {
        localStorage.setItem(storageKey, JSON.stringify(value));
      }
    });
  };
}
