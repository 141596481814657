// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { selectorFamily } from 'recoil';

import { GEOMETRY_CONTACTS_CONTAINER_ID, NodeType, SimulationTreeNode } from '../../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../../lib/simulationTree/sectionRecoilKey';
import { geometryContactsStateSelector } from '../../../../recoil/geometryContactsState';

export const geometryContactsSectionSelector = selectorFamily<
  SimulationTreeNode,
  SectionRecoilKey
>({
  key: 'geometryContactsSection',
  get: (key: SectionRecoilKey) => ({ get }) => {
    const geoContacts = get(geometryContactsStateSelector(key));
    const contacts = geoContacts.contacts.map((contact, index) => new SimulationTreeNode(
      NodeType.GEOMETRY_CONTACT,
      contact.id,
      `Contact ${index + 1}`,
    ));

    const name = contacts.length ? `Contacts (${contacts.length})` : 'Contacts';

    return new SimulationTreeNode(
      NodeType.GEOMETRY_CONTACT_CONTAINER,
      GEOMETRY_CONTACTS_CONTAINER_ID,
      name,
      contacts,
    );
  },
  dangerouslyAllowMutability: true,
});
