// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { InputDescription } from '../Form/InputDescription';
import { NumberSpinner, NumberSpinnerProps } from '../NumberSpinner';

export type ValidatedNumberSpinnerProps = NumberSpinnerProps & {
  messageOnError?: (value: number) => string;
}

/**
 * A NumberSpinner that accepts a messageOnError prop to give the user a message as an
 * InputDescription if the input is invalid.
 */
const ValidatedNumberSpinner = (props: ValidatedNumberSpinnerProps) => {
  const { messageOnError, value, minimumValue, maximumValue } = props;

  const [error, setError] = useState(value !== null ? messageOnError?.(value) : '');

  useEffect(() => {
    if (value !== null) {
      setError(messageOnError?.(value));
    }
  }, [value, minimumValue, maximumValue, messageOnError]);

  return (
    <>
      <NumberSpinner
        {...props}
        faultType={error ? 'error' : undefined}
        onChange={(newValue: number) => {
          setError(messageOnError?.(newValue));
          props.onChange?.(newValue);
        }}
        onCommit={props.onCommit}
      />
      {error && (
        <div style={{ paddingTop: '4px' }}>
          <InputDescription faultType="error" value={error} />
        </div>
      )}
    </>
  );
};

export default ValidatedNumberSpinner;
