// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const DiskArrowUpRightIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="diskArrowUpRight"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M22.5,11.25
           C21.3,11.25 20.25,10.65 19.5,9.75
           L17.7,11.55
           C17.1,12.3 16.05,12.6 15,12.6
           C12.9,12.75 11.25,11.1 11.25,9
           C11.25,7.95 11.7,7.05 12.3,6.3
           L14.25,4.5
           C13.35,3.75 12.75,2.7 12.75,1.5
           C12.75,1.05 12.9,0.45 13.05,0
           L12,0
           C5.4,0 0,5.4 0,12
           C0,18.6 5.4,24 12,24
           C18.6,24 24,18.6 24,12
           L24,10.95
           C23.55,11.1 23.1,11.25 22.5,11.25Z
           M22.5,0
           L16.5,0
           C15.6,0 15,0.75 15,1.5
           C15,2.25 15.6,3 16.5,3
           L18.9,3
           L13.95,7.95
           C13.65,8.25 13.5,8.55 13.5,9
           C13.5,9.75 14.1,10.5 15,10.5
           C15.45,10.5 15.75,10.35 16.05,10.05
           L21,5.1
           L21,7.5
           C21,8.4 21.6,9 22.5,9
           C23.25,9 24,8.4 24,7.5
           L24,1.5
           C24,0.75 23.4,0 22.5,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
