// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVConstant, LCVManipulationMode, LCVObject, LCVType } from '@luminarycloudinternal/lcvis';
import { SetterOrUpdater } from 'recoil';

import { listToPv } from '../../../Vector';
import { EditState } from '../../../visUtils';
import { LcvModule } from '../../types';
import { LcvData1D } from '../LcvData1D';

import { LcvFilterWidgetType, LcvWidget } from './LcvWidget';

export interface LcvBoxWidgetState {
  center: [number, number, number],
  size: [number, number, number],
  rotation: [number, number, number],
}

// A callback to be invoked whenever the clip box state changes
export type ClipBoxPositionCallback = (newState: LcvBoxWidgetState, message?: string) => void;

// A callback to be invoked whenever the clip box selection changes
export type ClipBoxSelectionCallback = (selection: number[], message?: string) => void;

/**
 * A widget which presents a 3d box to clip the geometry. It optionally accepts a selection
 * callback which can be used to access all the surfaces contained by the box.
 */
export class LcvClipBoxWidget extends LcvWidget implements LcvFilterWidgetType {
  hasSelectionCallback: boolean = false;
  hasPositionCallback = false;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, sessionHandle, 'clip_box');
  }

  getState(): LcvBoxWidgetState {
    return {
      center: this.getProperty('center', LCVType.kLCVDataTypeFloat3),
      size: this.getProperty('size', LCVType.kLCVDataTypeFloat3),
      rotation: this.getProperty('rotation', LCVType.kLCVDataTypeFloat3),
    };
  }

  setState(newState: Partial<LcvBoxWidgetState>) {
    Object.keys(newState).forEach((key) => {
      this.setParam(key, LCVType.kLCVDataTypeFloat3, newState[key as keyof LcvBoxWidgetState]);
    });
  }

  // Reset the state to be centered at the visible bounds with the default
  // orientation and a size that is .5 the bounding box.
  resetState() {
    this.setParam('reset', LCVType.kLCVDataTypeUint, 1);
  }

  /**
   * Returns the indices of all surfaces contained by or overlapping the bounds of the clip box.
   * Each index is the index of the given surface in the attached frame's workspace dataset.
   *
   * If this widget doesn't have a selection callback attached, the selection will always be empty,
   * since LCVis doesn't store the selection unless it has a selection callback param.
    */
  getSelection(): number[] {
    if (!this.hasSelectionCallback) {
      return [];
    }
    const selectionHandle = this.getProperty('selection', LCVType.kLCVDataTypeData1D);
    const selectionData1D = new LcvData1D(this.lcv, selectionHandle, this.sessionHandle);
    const mappedData = selectionData1D.mapData();
    const ptr = mappedData.mapping;
    const nSelection = mappedData.size;
    // Selection contains nSelection (object ID, primitive ID) tuples
    const selection = new Uint32Array(this.lcv.memory(), ptr, nSelection * 2).slice();
    selectionData1D.unmapData();
    selectionData1D.release();

    const arr = (selection[0] === LCVConstant.kLCVInvalidObjectId) ? [] : Array.from(selection);
    return arr;
  }

  /**
   * Set the selection callback. The callback will be called anytime the surfaces contained by
   * or overlapping the box widget change.
   *
   * If the selection callback is not set, this.getSelection() will always return an empty list.
   */
  setSelectionCallback(callback: ClipBoxSelectionCallback | null) {
    if (!callback) {
      this.setParam('selection_updated_callback', LCVType.kLCVDataTypeFunction, null);
      this.hasSelectionCallback = false;
      return;
    }
    const internalCallback = (_lcv: any, _session: LCVObject, obj: LCVObject, message: string) => {
      const newSelection = this.getSelection();
      callback(newSelection, message);
    };
    this.setParam('selection_updated_callback', LCVType.kLCVDataTypeFunction, internalCallback);
    this.hasSelectionCallback = true;
  }

  setPositionUpdatedCallback(callback: ClipBoxPositionCallback | null) {
    if (!callback) {
      this.setParam('updated_callback', LCVType.kLCVDataTypeFunction, null);
      return;
    }
    const internalCallback = (_lcv: any, _session: LCVObject, obj: LCVObject, message: string) => {
      const newState = this.getState();
      callback(newState, message);
    };
    this.setParam('updated_callback', LCVType.kLCVDataTypeFunction, internalCallback);
  }

  // setEditStateCallback implements the LcvFilterWidgetType interface.
  setEditStateCallback(setEditState: SetterOrUpdater<EditState | null> | null): void {
    if (setEditState === null) {
      this.setPositionUpdatedCallback(null);
      this.hasPositionCallback = false;
      return;
    }

    this.hasPositionCallback = true;
    this.setPositionUpdatedCallback((newState: LcvBoxWidgetState) => {
      const { center, size, rotation } = newState;
      setEditState((oldState) => {
        if (oldState === null) {
          return null;
        }
        return {
          ...oldState,
          param: {
            ...oldState.param,
            filterParam: {
              typ: 'BoxClip',
              position: listToPv(center),
              rotation: listToPv(rotation),
              length: listToPv(size),
            },
          },
        };
      });
    });
  }

  showControls() {
    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      LCVManipulationMode.kLCVManipulationModeAll,
    );
  }

  hideControls(): void {
    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      LCVManipulationMode.kLCVManipulationModeNone,
    );
  }
}
