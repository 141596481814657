// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as ProtoDescriptor from '../ProtoDescriptor';
import * as simulationpb from '../proto/client/simulation_pb';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import { createParamScope } from './ParamScope';
import { newAdFloat } from './adUtils';
import { newInt } from './intUtils';
import { checkPresets } from './physicsFluidControlsPresets';
import { findFluidPhysicsMaterial } from './physicsUtils';

const DiscretizationPresetVersion: number = 71824;

const {
  CONSERVATIVE_SPATIAL_DISCRETIZATION_FLUID,
  CUSTOM_SPATIAL_DISCRETIZATION_FLUID,
  HIGH_ACCURACY_SPATIAL_DISCRETIZATION_FLUID,
} = simulationpb.SpatialDiscretizationFluidPreset;

// Sets the parameters for each discretization preset.
export function applyFluidDiscretizationPreset(
  physics: simulationpb.Physics,
  simParam: simulationpb.SimulationParam,
  experimentConfig: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
): void {
  // Only handles fluid discretization presets.
  const fluid = (physics.params.case === 'fluid') ? physics.params.value : null;
  const material = findFluidPhysicsMaterial(simParam, physics, geometryTags, staticVolumes);
  if (!fluid || !material) {
    return;
  }

  const materialScope = createParamScope(material, experimentConfig);
  const physicsScope = createParamScope(fluid, experimentConfig, materialScope);
  const paramScope = createParamScope(simParam, experimentConfig, physicsScope);

  /* eslint-disable-next-line id-length */
  const p = fluid.spatialDiscretizationFluid;
  const value = p?.spatialDiscretizationFluidPreset;

  if (!p || value === CUSTOM_SPATIAL_DISCRETIZATION_FLUID) {
    return;
  }

  // Always tag the project with the current version. See LC-6227.
  p.discretizationPresetVersion = newInt(DiscretizationPresetVersion);

  // BEGIN GENERATED CODE (see gen/presets.py)
  const presets = new Map<string, ProtoDescriptor.Cond>();
  presets.set(
    'DISCR_IDEAL_GAS',
    {
      choice: 37484,
      param: 'density_relationship',
      type: ProtoDescriptor.CondType.CHOICE,
    },
  );
  presets.set(
    'DISCR_CONSTANT_DENSITY',
    {
      list: [
        {
          choice: 28817,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
        {
          choice: 41777,
          param: 'density_relationship',
          type: ProtoDescriptor.CondType.CHOICE,
        },
      ],
      type: ProtoDescriptor.CondType.ANY,
    },
  );
  checkPresets(presets, paramScope);
  if (paramScope.isEnabled(presets.get('DISCR_IDEAL_GAS')!)) {
    p.convectiveSchemesDensityBased = simulationpb.ConvectiveSchemesDensityBased.ROE;
    p.robustDissipation = simulationpb.RobustDissipation.ROBUST_DISS_OFF;
    p.upwindSchemeOrder = simulationpb.UpwindSchemeOrder.SECOND;
    p.umusclChi = newAdFloat(0.0);
    p.orderBlend = newAdFloat(1.0);
    p.entropyEpsilon = newAdFloat(2.0);
    p.gradientMethod = simulationpb.GradientMethod.NODAL_GRADIENT;
    p.hlsqBlend = newAdFloat(2.0);
    p.limiter = simulationpb.Limiter.INVARIANT_VENKATAKRISHNAN_CV;
    p.limiterKappa = newAdFloat(0.1);
    p.geometryFixes = simulationpb.GeometryFixes.GEOMETRY_FIXES_ON;
    p.geometryFixesMitigations = newAdFloat(0.25);
    p.preconditioning = simulationpb.Preconditioning.PRECONDITIONING_ON;
    if (value === CONSERVATIVE_SPATIAL_DISCRETIZATION_FLUID) {
      p.convectiveSchemesDensityBased = simulationpb.ConvectiveSchemesDensityBased.ROE;
      p.robustDissipation = simulationpb.RobustDissipation.ROBUST_DISS_ON;
      p.upwindSchemeOrder = simulationpb.UpwindSchemeOrder.SECOND;
      p.umusclChi = newAdFloat(0.0);
      p.orderBlend = newAdFloat(1.0);
      p.entropyEpsilon = newAdFloat(2.0);
      p.gradientMethod = simulationpb.GradientMethod.NODAL_GRADIENT;
      p.hlsqBlend = newAdFloat(2.0);
      p.limiter = simulationpb.Limiter.INVARIANT_VENKATAKRISHNAN_CV;
      p.limiterKappa = newAdFloat(0.1);
      p.geometryFixes = simulationpb.GeometryFixes.GEOMETRY_FIXES_ON;
      p.geometryFixesMitigations = newAdFloat(0.25);
      p.preconditioning = simulationpb.Preconditioning.PRECONDITIONING_ON;
    }
    if (value === HIGH_ACCURACY_SPATIAL_DISCRETIZATION_FLUID) {
      p.convectiveSchemesDensityBased = simulationpb.ConvectiveSchemesDensityBased.ROE;
      p.robustDissipation = simulationpb.RobustDissipation.ROBUST_DISS_OFF;
      p.upwindSchemeOrder = simulationpb.UpwindSchemeOrder.SECOND;
      p.umusclChi = newAdFloat(0.0);
      p.orderBlend = newAdFloat(1.0);
      p.entropyEpsilon = newAdFloat(2.0);
      p.gradientMethod = simulationpb.GradientMethod.NODAL_GRADIENT;
      p.hlsqBlend = newAdFloat(2.0);
      p.limiter = simulationpb.Limiter.INVARIANT_VENKATAKRISHNAN_CV;
      p.limiterKappa = newAdFloat(0.1);
      p.geometryFixes = simulationpb.GeometryFixes.GEOMETRY_FIXES_OFF;
      p.geometryFixesMitigations = newAdFloat(0.25);
      p.preconditioning = simulationpb.Preconditioning.PRECONDITIONING_ON;
    }
  }
  if (paramScope.isEnabled(presets.get('DISCR_CONSTANT_DENSITY')!)) {
    p.convectiveSchemesDensityBased = simulationpb.ConvectiveSchemesDensityBased.RHIE_CHOW;
    p.robustDissipation = simulationpb.RobustDissipation.ROBUST_DISS_OFF;
    p.upwindSchemeOrder = simulationpb.UpwindSchemeOrder.SECOND;
    p.umusclChi = newAdFloat(0.0);
    p.orderBlend = newAdFloat(1.0);
    p.entropyEpsilon = newAdFloat(2.0);
    p.gradientMethod = simulationpb.GradientMethod.GREEN_GAUSS;
    p.hlsqBlend = newAdFloat(2.0);
    p.limiter = simulationpb.Limiter.INVARIANT_VENKATAKRISHNAN_CV;
    p.limiterKappa = newAdFloat(0.1);
    p.geometryFixes = simulationpb.GeometryFixes.GEOMETRY_FIXES_ON;
    p.geometryFixesMitigations = newAdFloat(0.25);
    p.preconditioning = simulationpb.Preconditioning.PRECONDITIONING_ON;
    if (value === CONSERVATIVE_SPATIAL_DISCRETIZATION_FLUID) {
      p.convectiveSchemesDensityBased = simulationpb.ConvectiveSchemesDensityBased.ROE;
      p.robustDissipation = simulationpb.RobustDissipation.ROBUST_DISS_ON;
      p.upwindSchemeOrder = simulationpb.UpwindSchemeOrder.SECOND;
      p.umusclChi = newAdFloat(0.0);
      p.orderBlend = newAdFloat(1.0);
      p.entropyEpsilon = newAdFloat(2.0);
      p.gradientMethod = simulationpb.GradientMethod.NODAL_GRADIENT;
      p.hlsqBlend = newAdFloat(2.0);
      p.limiter = simulationpb.Limiter.INVARIANT_VENKATAKRISHNAN_CV;
      p.limiterKappa = newAdFloat(0.1);
      p.geometryFixes = simulationpb.GeometryFixes.GEOMETRY_FIXES_ON;
      p.geometryFixesMitigations = newAdFloat(0.25);
      p.preconditioning = simulationpb.Preconditioning.PRECONDITIONING_ON;
    }
    if (value === HIGH_ACCURACY_SPATIAL_DISCRETIZATION_FLUID) {
      p.convectiveSchemesDensityBased = simulationpb.ConvectiveSchemesDensityBased.RHIE_CHOW;
      p.robustDissipation = simulationpb.RobustDissipation.ROBUST_DISS_OFF;
      p.upwindSchemeOrder = simulationpb.UpwindSchemeOrder.SECOND;
      p.umusclChi = newAdFloat(0.0);
      p.orderBlend = newAdFloat(1.0);
      p.entropyEpsilon = newAdFloat(2.0);
      p.gradientMethod = simulationpb.GradientMethod.NODAL_GRADIENT;
      p.hlsqBlend = newAdFloat(2.0);
      p.limiter = simulationpb.Limiter.INVARIANT_VENKATAKRISHNAN_CV;
      p.limiterKappa = newAdFloat(0.1);
      p.geometryFixes = simulationpb.GeometryFixes.GEOMETRY_FIXES_ON;
      p.geometryFixesMitigations = newAdFloat(0.0);
      p.preconditioning = simulationpb.Preconditioning.PRECONDITIONING_ON;
    }
  }
  // END GENERATED CODE
}

export function applyAllFluidDiscretizationPresets(
  simParam: simulationpb.SimulationParam,
  experimentConfig: string[],
  geoTags: GeometryTags,
  staticVolumes: StaticVolume[],
): void {
  simParam.physics.forEach((physics) => {
    applyFluidDiscretizationPreset(physics, simParam, experimentConfig, geoTags, staticVolumes);
  });
}
