// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import Tooltip from '../../components/Tooltip';
import { LuminaryToggleSwitch } from '../../components/controls/LuminaryToggleSwitch';
import { Logger } from '../../lib/observability/logs';
import * as rpc from '../../lib/rpc';
import * as status from '../../lib/status';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import { useCurrentUser } from '../../recoil/useAccountInfo';

const logger = new Logger('AccountPage/NotificationSettingsSection');

function handleNotificationsUpdate(notificationEnabled: boolean) {
  const req = new frontendpb.SetEmailNotificationsPreferenceRequest({
    sendEmailNotifications: notificationEnabled,
  });
  rpc.callRetry(
    'SetEmailNotificationsPreference',
    rpc.client.setEmailNotificationsPreference,
    req,
  ).catch((err: Error) => {
    logger.error(`SetEmailNotificationsPreference error: ${status.stringifyError(err)}`);
  });
}

export const NotificationSettingsSection = () => {
  const currentUser = useCurrentUser();

  const [
    emailNotificationEnabled,
    setEmailNotificationEnabled,
  ] = useState<boolean>(currentUser?.emailNotification || false);

  useEffect(() => {
    handleNotificationsUpdate(emailNotificationEnabled);
  }, [emailNotificationEnabled]);
  return (
    <div>
      <span style={{ display: 'inline-block', textAlign: 'left' }}>
        <Tooltip
          title="Sharing and Project Activity Notifications">
          <span style={{ textAlign: 'left' }}>

            <LuminaryToggleSwitch
              onChange={(ev) => {
                setEmailNotificationEnabled(ev);
              }}
              value={emailNotificationEnabled}
            />
          </span>
        </Tooltip>
      </span>
      <span>&ensp;&ensp;Collaborate and work more efficiently.
        Receive email notifications when simulations,
        design of experiments, and local sensitivity models are complete (or if they fail),
        and when a project has been shared with you.
      </span>
    </div>
  );
};
