// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { LCVFilterDisplayField, LCVPrimitiveType } from '@luminarycloudinternal/lcvis';

import { DisplayProps, DisplayPvVariable } from '../../../../pvproto/ParaviewRpc';
import { RgbColor, colors, hexToRgbList } from '../../../designSystem';
import { isTestingEnv } from '../../../testing/utils';
import { parseRepresentationType, remapComponentIndex } from '../../../visUtils';
import { Bounds, LcvModule, ViewProps } from '../../types';
import { LcvObject } from '../base/LcvObject';

import { LcvFilterType } from './filterUtils';

const SURFACE_LINE_COLOR = hexToRgbList(colors.edgeColor);

/**
 * The base class for an LCVis filter.
 * Filters are owned by the workspace, and each filter owns some set of surfaces.
 *
 * For some filters (e.g. the import dataset or farfield filters), we want to be able to set things
 * like the visibility, transparency, and color of individual surfaces.
 * For other filters (e.g. clip/slice/contour), we want to be able to set these properties for all
 * surfaces at once.
 */
export abstract class LcvFilter extends LcvObject {
  protected workspaceHandle: number;
  id: string;
  type: LcvFilterType;
  visible = true;
  viewProps?: ViewProps;

  constructor(
    lcv: LcvModule,
    handle: number,
    sessionHandle: number,
    workspaceHandle: number,
    type: LcvFilterType,
    id: string,
  ) {
    super(lcv, handle, sessionHandle);
    this.workspaceHandle = workspaceHandle;
    this.id = id;
    this.type = type;
  }

  /**
   * Set the display properties of the filter (surfaces, surface w/ lines, wireframe)
   */
  setDisplayProps(perSurface: boolean, displayProps: DisplayProps, color?: RgbColor): void {
    this.viewProps = {
      perSurface,
      displayProps,
    };
    if (displayProps.reprType === 'Wireframe') {
      // If the filter is set to show wireframe, we want to show the lines in white.
      this.setAllLinesColor([1, 1, 1]);
    } else {
      this.setAllLinesColor(SURFACE_LINE_COLOR);
    }
    // If we don't have surface lines we need to toggle their visibility
    // TODO (will/jared): Later when we have a CAD w/ neighbor data we can
    // set line visibility based on if any neighbor surface is visible
    if (!this.hasSurfaceLines()) {
      if (displayProps.reprType === 'Surface') {
        this.setDrawLines(false);
      } else if (displayProps.reprType === 'Surface With Edges') {
        this.setDrawSurfaces(true);
        this.setDrawLines(true);
      } else if (displayProps.reprType === 'Wireframe') {
        this.setDrawSurfaces(false);
        this.setDrawLines(true);
      }
    }

    if (color && displayProps.showColors) {
      this.setAllSurfacesColor(color);
    }
  }

  /**
   * Edit the view properties of the filter.
   * @param perSurface Whether the filter is to be edited per-surface or as a whole.
   * @param displayProps The display properties to set.
   * @param visible Whether the entire filter should be made visible. This will be ignored if
   * perSurface is true.
   * @param color The color to set the entire filter to.
   * @returns {void}
   */
  setViewProps(
    displayProps: DisplayProps,
    surfaceVisibility: Map<number, boolean> | boolean,
    color?: RgbColor,
  ): void {
    const { displayVariable } = displayProps;
    const perSurface = typeof surfaceVisibility !== 'boolean';
    this.viewProps = {
      perSurface,
      displayProps,
    };
    if (perSurface) {
      surfaceVisibility.forEach((surfaceVisible, id) => {
        this.updateSurfaceAndLineVisibility(id, surfaceVisible);
      });
    } else {
      this.updateAllSurfacesAndLinesVisibility(surfaceVisibility);
    }
    this.setDisplayProps(perSurface, displayProps, color);

    this.setDisplayVariable(displayVariable ?? null);
  }

  /**
   * @returns the value of the component that was actually set
   */
  setDisplayVariable(displayVariable: DisplayPvVariable | null): number {
    if (!displayVariable) {
      return 0;
    }
    const { displayDataName: fieldName, displayDataNameComponent: component } = displayVariable;

    let comp = component ?? 0;
    let nComponents = 0;
    let maxComponents = 1;
    if (
      fieldName !== LCVFilterDisplayField.kLCVFilterDisplayFieldNone
    ) {
      nComponents = this.lcv.getFieldComponents(
        this.sessionHandle,
        this.workspaceHandle,
        fieldName,
        0,
      ).ncomponents;
      if (nComponents === 3) {
        maxComponents = 4;
      } else {
        maxComponents = nComponents;
      }
    }
    if (comp >= maxComponents) {
      comp = 0;
    }
    // LCVis order is x, y, z, mag
    // Paraview order is mag, x, y, z
    if (nComponents === 3) {
      comp = remapComponentIndex(comp);
    }

    this.lcv.setDisplayField(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      fieldName,
      comp,
    );

    if (nComponents === 3) {
      return remapComponentIndex(comp, false);
    }
    return comp;
  }

  /**
   * Update the visibility of the whole filter (lines and surfaces). This will take the filter's
   * current view properties into account (e.g. if the filter is set to show surfaces only,
   * it will show all surfaces but no lines).
   * @param visible Whether the filter should be visible.
   * @returns {void}
   */
  updateAllSurfacesAndLinesVisibility(visible: boolean): void {
    if (!this.viewProps || this.viewProps.perSurface) {
      return;
    }
    const { displayProps } = this.viewProps;
    const { showLines, showSurfaces } = parseRepresentationType(displayProps.reprType);

    this.setDrawSurfaces(showSurfaces);
    this.setDrawLines(showLines);

    this.setAllSurfacesVisible(visible);
    this.setAllLinesVisible(visible);
    this.visible = visible;
  }

  /**
   * Update the visibility of a specific surface in the filter. This will take the filter's current
   * view properties into account (e.g. if the filter is set to show surfaces only, it will show
   * the surface but not the lines).
   * @param surfaceIndex The index of the surface to set the visibility of.
   * @param visible Whether the surface should be visible.
   * @returns {void}
   */
  updateSurfaceAndLineVisibility(surfaceIndex: number, visible: boolean): void {
    if (!this.viewProps?.perSurface) {
      return;
    }
    const { displayProps } = this.viewProps;
    const { showLines, showSurfaces } = parseRepresentationType(displayProps.reprType);

    this.setDrawSurfaces(showSurfaces);
    this.setDrawLines(showLines);

    this.setSurfaceVisible(surfaceIndex, visible);
    this.setLineVisible(surfaceIndex, visible);
  }

  /**
   * Get the bounds of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to get the bounds of.
   * @returns The bounds of the surface in the form [minX, minY, minZ, maxX, maxY, maxZ].
   */
  getSurfaceBounds(surfaceIndex: number): Bounds {
    return this.lcv.getPrimitiveBoundingBox(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      0,
    ).bounding_box;
  }

  /**
   * Set whether surfaces in the filter should be drawn if they're visible
   * @param visible Whether the filter should be visible.
   */
  private setDrawSurfaces(drawSurfaces: boolean) {
    this.lcv.setFilterDrawSurfaces(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      drawSurfaces ? 1 : 0,
    );
  }

  /**
   * Set whether lines in the filter should be drawn if they're visible
   * @param visible whether the lines should be visible
   */
  private setDrawLines(drawLines: boolean) {
    this.lcv.setFilterDrawLines(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      drawLines ? 1 : 0,
    );
  }

  /**
   * Set the visibility of all surfaces in the filter.
   * @param visible Whether the filter should be visible.
   */
  private setAllSurfacesVisible(visible: boolean) {
    this.lcv.setFilterSurfacesVisibility(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      visible ? 1 : 0,
    );
  }

  /**
   * Set the visibility of all surface lines in the filter
   * @param visible whether the lines should be visible
   */
  private setAllLinesVisible(visible: boolean) {
    this.lcv.setFilterLinesVisibility(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      visible ? 1 : 0,
    );
  }

  /**
   *  Set the transparency of all surfaces in the filter.
   * @param transparent Whether the filter should be transparent.
   */
  setAllSurfacesTransparent(transparent: boolean) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setFilterSurfacesTransparency(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      transparent ? 1 : 0,
    );
  }

  /**
   * Set the color of all surfaces in the filter.
   * @param color The color to set the surfaces to.
   */
  setAllSurfacesColor(color: RgbColor) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setFilterSurfacesColor(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      color,
    );
  }

  /**
   * Sets the visibility of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to set the visibility of.
   * @param visible Whether the surface should be visible.
   */
  private setSurfaceVisible(surfaceIndex: number, visible: boolean) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setPrimitiveVisibility(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      visible ? 1 : 0,
    );
  }

  private setLineVisible(surfaceIndex: number, visible: boolean) {
    if (this.hasSurfaces() && this.hasSurfaceLines()) {
      this.lcv.setSurfaceLineVisibility(
        this.sessionHandle,
        this.workspaceHandle,
        this.handle,
        surfaceIndex,
        visible ? 1 : 0,
      );
    } else if (this.hasLines()) {
      const firstLineId = this.lcv.getFirstPrimitiveId(
        this.sessionHandle,
        LCVPrimitiveType.kLCVPrimitiveTypeLine,
        0,
      ).first_id;
      if (surfaceIndex >= firstLineId) {
        this.lcv.setPrimitiveVisibility(
          this.sessionHandle,
          this.workspaceHandle,
          this.handle,
          surfaceIndex,
          visible ? 1 : 0,
        );
      }
    }
  }

  /**
   * Sets the color of the line of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to set the line color
   *        of or the line primitive ID if we don't have surface lines
   * @param color The color to set the line to.
   */
  setLineColor(surfaceIndex: number, color: RgbColor) {
    if (this.hasSurfaces() && this.hasSurfaceLines()) {
      this.lcv.setSurfaceLineColor(
        this.sessionHandle,
        this.workspaceHandle,
        this.handle,
        surfaceIndex,
        color,
      );
    } else {
      this.lcv.setPrimitiveColor(
        this.sessionHandle,
        this.workspaceHandle,
        this.handle,
        surfaceIndex,
        color,
      );
    }
  }

  /**
   * Sets the color of all lines in the filter.
   * @param color The color to set the lines to.
   */
  setAllLinesColor(color: RgbColor) {
    this.lcv.setFilterLinesColor(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      color,
    );
  }

  /**
   * Sets the transparency of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to set the transparency of.
   * @param transparent Whether the surface should be transparent.
   */
  setSurfaceTransparent(surfaceIndex: number, transparent: boolean) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setPrimitiveTransparency(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      transparent ? 1 : 0,
    );
  }

  /**
   * Sets the color of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to set the color of.
   * @param color The color to set the surface to.
   */
  setSurfaceColor(surfaceIndex: number, color: RgbColor) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setPrimitiveColor(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      color,
    );
  }

  /**
   * Enable or disable field color blending for the specified primitive
   * in the filter. If the filter has field data enabling this will cause
   * the primitive's color to be blended with its field colormap color.
   */
  setPrimitiveBlendFieldColor(primitiveId: number, enabled: boolean) {
    this.lcv.setPrimitiveBlendFieldColor(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      primitiveId,
      enabled ? 1 : 0,
    );
  }

  /**
   * Enable or disable field color blending for all primitives in the filter.
   * If the filter has field data enabling this will cause the primitive's
   * color to be blended with its field colormap color.
   */
  setAllBlendFieldColor(enabled: boolean) {
    this.lcv.setFilterBlendFieldColor(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      enabled ? 1 : 0,
    );
  }

  /**
   * Filters may be represented with surfaces, lines, or both. This function returns whether the
   * specified surface, or its lines, are visible.
   *
   * @param surfaceIndex The index of the surface to check the visibility of.
   * @returns Whether the surface or its lines are visible.
   */
  getSurfaceOrLineVisibility(surfaceIndex: number): boolean {
    return this.getSurfaceVisibility(surfaceIndex) || this.getSurfaceLineVisibility(surfaceIndex);
  }

  /**
   * Gets the visibility of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to get the visibility of.
   */
  private getSurfaceVisibility(surfaceIndex: number): boolean {
    if (!this.hasSurfaces()) {
      return false;
    }
    return !!this.lcv.getPrimitiveVisibility(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      0,
    ).visibility;
  }

  private getSurfaceLineVisibility(surfaceIndex: number): boolean {
    if (this.hasSurfaces() && this.hasSurfaceLines()) {
      return !!this.lcv.getSurfaceLineVisibility(
        this.sessionHandle,
        this.workspaceHandle,
        this.handle,
        surfaceIndex,
        0,
      ).visibility;
    }
    if (this.hasLines()) {
      return !!this.lcv.getPrimitiveVisibility(
        this.sessionHandle,
        this.workspaceHandle,
        this.handle,
        surfaceIndex,
        0,
      ).visibility;
    }
    return false;
  }

  /**
   * Gets the transparency of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to get the transparency of.
   */
  getSurfaceTransparency(surfaceIndex: number): boolean {
    if (!this.hasSurfaces()) {
      return false;
    }
    return !!this.lcv.getPrimitiveTransparency(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      0,
    ).transparency;
  }

  /**
   * Gets the color of a specific surface in the filter.
   * @param surfaceIndex The surface or line ID to get the color of.
   */
  getSurfaceColor(surfaceIndex: number): RgbColor {
    return this.lcv.getPrimitiveColor(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      0,
    ).color;
  }

  /**
   * Gets the name of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to get the name of.
   */
  getSurfaceName(surfaceIndex: number): string {
    if (!this.hasSurfaces()) {
      return '';
    }
    return this.lcv.getPrimitiveName(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      0,
    ).name;
  }

  getNumSurfaces(): number {
    if (isTestingEnv()) {
      return 0;
    }
    return this.lcv.getNumPrimitives(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      LCVPrimitiveType.kLCVPrimitiveTypeSurface,
      0,
    ).n_primitives;
  }

  getNumLines(): number {
    if (isTestingEnv()) {
      return 0;
    }
    return this.lcv.getNumPrimitives(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      LCVPrimitiveType.kLCVPrimitiveTypeLine,
      0,
    ).n_primitives;
  }

  /**
   * Get the number of surfaces the filter has
   */
  hasSurfaces() {
    return this.getNumSurfaces() > 0;
  }

  /**
   * Check if this filter has "surface lines", i.e. lines that are
   * associated with a specific surface. This is the case when rendering
   * a mesh, where we have mesh lines.
   */
  hasSurfaceLines() {
    return this.lcv.getSurfacesHaveLines(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      0,
    ).surfaces_have_lines;
  }

  /**
   * Check if the filter has separate line entities that are not
   * tied to a specific surface, e.g., CAD edges.
   */
  hasLines() {
    return this.getNumLines() > 0;
  }

  /**
   * Set the shading mode of the filter to either flat or smooth shading.
   * Flat shading is good for visualizing the tesselation, but smooth shading is good for showing
   * curves.
   * @param flat Whether the shading mode should be flat.
   */
  setFlatShading(flat: boolean) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setFilterShadingMode(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      flat ? 1 : 0,
    );
  }
}
