// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, createContext, useContext } from 'react';

import { ProjectContextType } from '../../lib/componentTypes/context';
import { useProjectModel } from '../../model/hooks/useProject';
import { useActiveElement } from '../../recoil/eventListenerState/useActiveElement';

export interface ProjectProps {
  projectId: string;
  workflowId: string;
  selectedJobId: string;
  geometryId?: string;
  readOnly?: boolean;
  children: ReactNode;
}

const ProjectContext = createContext<ProjectContextType | null>(null);

export function useProjectContext(): ProjectContextType {
  return useContext(ProjectContext)!;
}

const Project = (props: ProjectProps) => {
  const { projectId, workflowId, selectedJobId, geometryId, readOnly } = props;

  const projectModel = useProjectModel({
    projectId,
    workflowId,
    selectedJobId,
    geometryId,
    readOnly,
  });

  // Add a listener to track the currently focused element.
  useActiveElement();

  const context: ProjectContextType = {
    ...projectModel,
  };

  return (
    <ProjectContext.Provider value={context}>
      {props.children}
    </ProjectContext.Provider>
  );
};

export default Project;
