// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      padding: '8px 12px',

      // This is a bit hacky but there's no other easy way to increase the bottom padding from 8px
      // to 12px for when a CollapsiblePanel content is expanded in a property panel.
      '& > .CollapsiblePanel-root:not(.CollapsiblePanel-collapsed)': {
        paddingBottom: '4px',
      },
    },
  }),
  { name: 'PropertiesSection' },
);

export interface PropertiesSectionProps {
  children: ReactNode;
}

// A section of the properties panel.
export const PropertiesSection = (props: PropertiesSectionProps) => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      {props.children}
    </section>
  );
};

export default PropertiesSection;
