// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 30;
const baseHeight = 18;
export const RulerIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="ruler"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M3,18
           C2.175,18 1.469,17.706 0.881,17.119
           C0.294,16.531 0,15.825 0,15
           L0,3
           C0,2.175 0.294,1.469 0.881,0.881
           C1.469,0.294 2.175,0 3,0
           L27,0
           C27.825,0 28.531,0.294 29.119,0.881
           C29.706,1.469 30,2.175 30,3
           L30,15
           C30,15.825 29.706,16.531 29.119,17.119
           C28.531,17.706 27.825,18 27,18
           L3,18Z
           M3,15
           L27,15
           L27,3
           L22.5,3
           L22.5,9
           L19.5,9
           L19.5,3
           L16.5,3
           L16.5,9
           L13.5,9
           L13.5,3
           L10.5,3
           L10.5,9
           L7.5,9
           L7.5,3
           L3,3
           L3,15Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
