// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as frontendpb from '../proto/frontend/frontend_pb';
import * as feoutputpb from '../proto/frontend/output/output_pb';

import { Logger } from './observability/logs';
import { findOutputNodeById, findOutputNodeByName, removeDependencySuffix } from './outputNodeUtils';
import * as rpc from './rpc';
import { addRpcError } from './transientNotification';

const logger = new Logger('lib/derivedOutput');
const CHAR_LIMIT = 2000;

// When the user submits a new expression for a derived output, an Analyzer RPC is used to
// validate it before populating the DerivedNode message. The reply from the Analyzer
// contains a list of symbols identified in the expression, along with a list of
// error messages. If any errors are present, the expression is invalid, so the entire
// expression is copied into a single substring ExpressionElement, which is added to
// the DerivedNode along with the error messages.

// If no errors are present, we check the symbols to make sure that each symbol
// matches the display name of an OutputNode. If a symbol is found that doesn’t match,
// we again copy the expression into a single substring ExpressionElement and add it
// to the DerivedNode, along with a new error message indicating which symbols do
// not correspond to an existing output.

// After identifying the OutputNode corresponding to each of the symbols, we can
// fill in the ExpressionElements with substrings and dependencies,
// and leave the error messages list empty.
//-------------------------------------------------------------------------------

// Perform validation checks that don't require calling the parser (empty
// expressions and unbalanced quotation marks). Populate the request with
// expressions that pass these checks and update output nodes for the
// ones that don't.
export const generateExpressionValidationRequest = (
  projectId: string,
  expressions: Map<string, string>,
  outputNodes: feoutputpb.OutputNodes,
): { req: frontendpb.ValidateExpressionRequest, outputIds: string[] } => {
  const outputIds: string[] = [];
  const newExpressions: string[] = [];
  expressions.forEach((oldExpression, outputId) => {
    const newNode = findOutputNodeById(outputNodes, outputId)!;
    // Remove newline characters from expression
    const expression = oldExpression.replace(/\n/g, '');

    // No need to call expression validator if expression is empty.
    if (!expression) {
      newNode.nodeProps = { case: 'derived', value: new feoutputpb.DerivedNode() };
      return;
    }

    if (expression.length > CHAR_LIMIT) {
      newNode.nodeProps = {
        case: 'derived',
        value: new feoutputpb.DerivedNode({
          elements: [
            new feoutputpb.ExpressionElement({
              elementType: { case: 'substring', value: expression },
            }),
          ],
          errors: [`${expression.slice(0, 10)}... is too long to parse.`],
        }),
      };
      return;
    }

    // Check for unbalanced quotation marks. No need to call the expression validator
    // if quotation marks are unbalanced.
    const substrings = expression.split('"');
    if (!(substrings.length % 2)) {
      newNode.nodeProps = {
        case: 'derived',
        value: new feoutputpb.DerivedNode({
          elements: [
            new feoutputpb.ExpressionElement({
              elementType: { case: 'substring', value: expression },
            }),
          ],
          errors: [`${expression} has unbalanced quotation marks.`],
        }),
      };
      return;
    }

    newExpressions.push(expression);
    outputIds.push(outputId);
  });

  const req = new frontendpb.ValidateExpressionRequest({ projectId, expression: newExpressions });
  return { req, outputIds };
};

// For each symbol returned by the parser, find the corresponding output node
// and map the symbol to a DerivedNodeDependency for that node. If no node can
// be found, add an error indicating that the symbol is invalid.
export const matchExpressionSymbols = (
  symbols: string[],
  errors: string[],
  outputNodes: feoutputpb.OutputNodes,
): Map<string, feoutputpb.DerivedNodeDependency> => {
  // Create a map of valid symbols to a derived node dependency message
  const symbolToDep = new Map<string, feoutputpb.DerivedNodeDependency>();

  // The expression validator will return an error message for each invalid
  // symbol it finds. For the symbols that the validator determines to
  // be valid, we still need to confirm that the symbol matches the name
  // of an existing output node (in quotation marks)
  symbols.forEach((symbol, symbolIdx) => {
    if (!errors[symbolIdx]) {
      let nodeFound = false;
      const dependency = new feoutputpb.DerivedNodeDependency();
      let nodeName = '';
      if (symbol.startsWith('"') && symbol.endsWith('"')) {
        // First, check for an exact match between the symbol and a node name. This
        // captures the edge case of an output with one of the suffixes
        // i.e. (" - Average", " - Coefficient", or " - Coefficient Average")
        // at the end of the name
        const nodeNameRaw = symbol.slice(1, -1);
        let node = findOutputNodeByName(outputNodes, nodeNameRaw);
        if (node) {
          dependency.id = node.id;
          dependency.include = feoutputpb.OutputIncludes.OUTPUT_INCLUDE_BASE;
          nodeName = node.name;
          nodeFound = true;
        } else {
          // If no output node has a name that exactly matches the symbol name,
          // check if a suffix was added to the symbol name. If so, see if any
          // output node names match the symbol with the suffix removed.
          const {
            name: nodeNameSuffixRemoved,
            include,
          } = removeDependencySuffix(nodeNameRaw);
          if (nodeNameSuffixRemoved !== nodeNameRaw) {
            node = findOutputNodeByName(outputNodes, nodeNameSuffixRemoved);
            if (node) {
              dependency.id = node.id;
              dependency.include = include;
              nodeName = node.name;
              nodeFound = true;
            }
          }
        }
      }
      if (nodeFound) {
        if (
          outputNodes.nodes.filter(
            (outputNode) => outputNode.name === nodeName,
          ).length > 1
        ) {
          errors.push(`${nodeName} is used for multiple ` +
            `outputs. Output names used in an expression must be unique.`);
        } else {
          // Ensure any parentheses in the symbol don't interfere with
          // expression pattern matching
          symbolToDep.set(symbol.replace(/\(/g, '\\(').replace(/\)/g, '\\)'), dependency);
        }
      } else {
        errors.push(`${symbol} is not a valid symbol. ` +
          `Expression symbols must be output names in quotation marks.`);
      }
    }
  });
  return symbolToDep;
};

// If the expression is valid, we decompose it into ExpressionElements using the keys
// in symbolToDep and add it to the output node's derived properties. If there are
// any errors, the expression is invalid and we add the whole expression into a single
// ExpressionElement and add the errors to the derived properties.
export const updateDerivedNode = (
  errors: string[],
  newNode: feoutputpb.OutputNode,
  expression: string,
  symbolToDep: Map<string, feoutputpb.DerivedNodeDependency>,
) => {
  // If we found any errors, the expression is invalid
  const errorsFiltered = errors.filter((error) => !!error);
  if (errorsFiltered.length) {
    newNode.nodeProps = {
      case: 'derived',
      value: new feoutputpb.DerivedNode({
        elements: [
          new feoutputpb.ExpressionElement({
            elementType: { case: 'substring', value: expression },
          }),
        ],
        errors: errorsFiltered,
      }),
    };
  } else {
    const expressionElements: feoutputpb.ExpressionElement[] = [];
    const pattern = new RegExp(
      Array.from(symbolToDep.keys()).map((symbol) => `(${symbol})`).join('|'),
      'g',
    );
    const elements = expression.split(pattern);
    elements.forEach((element) => {
      if (element) {
        // Match portions of the expression against identified symbols
        // with escaped parentheses
        const elementEscape = element.replace(/\(/g, '\\(').replace(/\)/g, '\\)');
        if (symbolToDep.has(elementEscape)) {
          expressionElements.push(
            new feoutputpb.ExpressionElement({
              elementType: { case: 'dependency', value: symbolToDep.get(elementEscape)! },
            }),
          );
        } else {
          expressionElements.push(
            new feoutputpb.ExpressionElement({
              elementType: { case: 'substring', value: element },
            }),
          );
        }
      }
    });
    newNode.nodeProps = {
      case: 'derived',
      value: new feoutputpb.DerivedNode({ elements: expressionElements }),
    };
  }
};

export function validateExpressions(
  outputNodes: feoutputpb.OutputNodes,
  setOutputNodes: (newOutputsNodes: feoutputpb.OutputNodes) => void,
  projectId: string,
  expressions: Map<string, string>, // Maps output ID to expression for that output
) {
  const newOutputNodes = outputNodes.clone();
  const { req, outputIds } = generateExpressionValidationRequest(
    projectId,
    expressions,
    newOutputNodes,
  );

  if (req.expression.length) {
    rpc.callRetry('validateExpression', rpc.client.validateExpression, req).then(
      (reply: frontendpb.ValidateExpressionReply) => {
        reply.response.forEach((response, responseIdx) => {
          const newNode = findOutputNodeById(newOutputNodes, outputIds[responseIdx])!;
          const { errors, symbols } = response;

          const symbolToDep = matchExpressionSymbols(symbols, errors, outputNodes);
          updateDerivedNode(errors, newNode, req.expression[responseIdx], symbolToDep);
        });
        setOutputNodes(newOutputNodes);
        return null;
      },
    ).catch((err: Error) => {
      addRpcError('Failed to get expression validation', err);
      logger.warn(`rpc validateExpression error: ${err}`);
      return null;
    });
  } else {
    setOutputNodes(newOutputNodes);
  }
}
