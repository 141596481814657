// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { ActionLink } from '../components/Button/ActionLink';
import { createStyles, makeStyles } from '../components/Theme';
import { Table } from '../components/data/Table';
import { SaveEditLibraryItemDialog } from '../components/dialog/SaveEditLibraryItem';
import { MainPageLayout } from '../components/layout/page/Main';
import { BooksIcon } from '../components/svg/BooksIcon';
import { ColumnConfig, RowConfig } from '../lib/componentTypes/table';
import { LIBRARY_URL } from '../lib/constants';
import { colors } from '../lib/designSystem';
import { fromBigInt } from '../lib/number';
import * as rpc from '../lib/rpc';
import { addSuccess } from '../lib/transientNotification';
import * as librarypb from '../proto/library/library_pb';
import { usePersonalLibrary, useRefreshPersonalLibrary } from '../state/external/library/personalLibrary';
import { pushConfirmation, useSetConfirmations } from '../state/internal/dialog/confirmations';

const usePlaceholderStyles = makeStyles(
  () => createStyles({
    root: {
      backgroundColor: colors.neutral250,
      borderRadius: '4px',
      height: '415px',
      padding: '24px',
    },
    container: {
      gap: '20px',
      height: '326px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    content: {
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
      maxWidth: '800px',
    },
  }),
  { name: 'LibraryPlaceholder' },
);

const displayType = (typ: librarypb.LibraryItemType) => {
  if (typ === librarypb.LibraryItemType.PROJECT_SETTINGS) {
    return 'Project Settings';
  }
  return 'Unknown';
};

interface EditDialogState {
  open: boolean;
  item?: librarypb.LibraryItem;
}

const LibraryPlaceholder = () => {
  const classes = usePlaceholderStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <BooksIcon maxHeight={86} maxWidth={72} />
        <div className={classes.title}>
          Your Library is empty
        </div>
        <div className={classes.content}>
          Increase efficiency by sharing and reusing library items. You can add items
          to the library directly from your projects. Learn more about libraries
          and how to use them at&nbsp;
          <ActionLink href={LIBRARY_URL}>Luminary Learning</ActionLink>.
        </div>
      </div>
    </div>
  );
};

const columnConfigs: ColumnConfig[] = [
  { id: 'name', label: 'Name', type: 'string' },
  { id: 'description', label: 'Description', type: 'string' },
  { id: 'type', label: 'Type', type: 'string' },
  {
    id: 'creation',
    label: 'Created',
    type: 'number',
    format: 'datetime',
    displayOptions: { minimalWidth: true },
  },
];

const LibraryPageBody = () => {
  const [
    editDialogState,
    setEditDialogState,
  ] = useState<EditDialogState>({ open: false });

  const items = usePersonalLibrary();
  const refreshPersonalLibrary = useRefreshPersonalLibrary();
  const setConfirmStack = useSetConfirmations();

  async function handleDeleteLibraryItem(item: librarypb.LibraryItem) {
    const req = new librarypb.DeleteItemRequest({
      item,
    });
    await rpc.callRetry('DeleteLibraryItem', rpc.client.deleteLibraryItem, req).then(() => {
      addSuccess(item.name, 'Library item deleted');
      refreshPersonalLibrary();
    }).catch(() => { });
  }

  const queueDeleteLibraryItem = (item: librarypb.LibraryItem) => {
    pushConfirmation(setConfirmStack, {
      destructive: true,
      onContinue: () => handleDeleteLibraryItem(item),
      title: 'Delete Library Item',
      children: (
        <div>
          {`Are you sure you want to delete the library item ${item.name}? \
           This action cannot be undone.`}
        </div>
      ),
    });
  };

  // Convert the library items into valid table data
  const formattedRowData: RowConfig[] = items?.map((item) => {
    // Allows undefined time to return null, which will be replaced by placeholder in the table.
    const createdSeconds = item.createTime?.seconds ?? null;
    const createdTime = createdSeconds !== null ? fromBigInt(createdSeconds) * 1000 : null;

    return {
      id: item.id,
      values: {
        name: item.name,
        description: item.description,
        type: displayType(item.type),
        creation: createdTime,
      },
      menuItems: [
        {
          label: 'Edit',
          onClick: () => setEditDialogState({ open: true, item }),
        },
        {
          label: 'Delete',
          onClick: () => queueDeleteLibraryItem(item),
          destructive: true,
        },
      ],
    };
  }) ?? [];

  // Refresh the recoil state on page load.
  useEffect(() => {
    refreshPersonalLibrary();
  }, [refreshPersonalLibrary]);

  return (
    items && items.length > 0 ? (
      <>
        <Table
          asBlock
          columnConfigs={columnConfigs}
          defaultSort={{ columnId: 'creation', descending: true }}
          name="library-table"
          pagination={{ availablePageSizes: [25, 50, 100] }}
          rowConfigs={formattedRowData}
        />
        <SaveEditLibraryItemDialog
          {...editDialogState}
          onClose={() => {
            setEditDialogState({ open: false });
            refreshPersonalLibrary();
          }}
        />
      </>
    ) :
      <LibraryPlaceholder />
  );
};

const LibraryPage = () => (
  <MainPageLayout
    title="My Library">
    <LibraryPageBody />
  </MainPageLayout>
);

export default LibraryPage;
