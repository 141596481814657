// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { isGroupVisible } from '../../../lib/entityGroupUtils';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem } from '../../../lib/treeUtils';
import { useEntityGroupMap } from '../../../recoil/entityGroupState';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import { useLcvisVisibilityMapValue } from '../../../recoil/lcvis/lcvisVisibilityMap';
import { useToggleVisibility } from '../../../recoil/vis/useToggleVisibility';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useCopySimAnnotation } from '../../hooks/nodeDuplication/useCopySimAnnotation';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { ContextMenuSection, TreeRow, VisibilityControl } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'circle', maxHeight: 4.5, maxWidth: 4.5 };

// A row in the simulation tree representing a probe point
export const ProbePointTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { viewState, visibilityMap } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Recoil
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const isSelected = selectedNodeIds.includes(node.id);
  const toggleIds = new Set(isSelected ? selectedNodeIds : [node.id]);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();
  const visibilityV2 = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const isVisible = lcvisEnabled ? (
    isGroupVisible(visibilityV2, entityGroupMap, node.id)
  ) : (
    isGroupVisible(visibilityMap, entityGroupMap, node.id)
  );

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteProbePointNode, postDeleteNodeIds } = useNodeDeletion();
  const duplicateRow = useCopySimAnnotation();

  const toggleVis = useToggleVisibility(toggleIds, isVisible);

  const visibilityControl: VisibilityControl = {
    disabled: lcvisEnabled ? !lcvisReady : !viewState,
    show: isVisible,
    toggle: toggleVis,
  };

  const deleteRow = useCallback(async () => {
    if (await deleteProbePointNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteProbePointNode, node.id, postDeleteNodeIds]);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];

    const disabled = !canDelete(node.type, node.id);
    const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
    const duplicateItem = duplicateTreeNodeMenuItem(() => duplicateRow(node.id, 'point'), disabled);
    sections.push({ section: 'crud', menuItems: [duplicateItem, deleteItem] });

    return sections;
  }, [canDelete, deleteRow, node.id, node.type, duplicateRow]);

  return (
    <TreeRow
      {...props}
      canMultiSelect
      getExtraContextMenuItems={getExtraContextMenuItems}
      primaryIcon={PRIMARY_ICON}
      propertiesControl
      renaming={renaming}
      visibility={visibilityControl}
    />
  );
};
