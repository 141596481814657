// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import { getQuantityText, getQuantityUnit, relativeChord } from '../../QuantityDescriptor';
import { QuantityType } from '../../proto/quantity/quantity_pb';
import {
  Axis,
  Axis_Coordinate,
  Header,
  Header_Label,
  Metadata,
  Record,
  Record_Entry,
  RectilinearTable,
  TableType,
} from '../../proto/table/table_pb';
import { getAdValue, newAdFloat } from '../adUtils';
import assert from '../assert';
import { SparkLineSeries } from '../charting';
import { SelectOption } from '../componentTypes/form';
import { ColumnConfig, RowConfig } from '../componentTypes/table';
import { EPSILON } from '../constants';
import * as csv from '../csv';
import { Point } from '../geometry';

import {
  LabelDefinition,
  TableDefinition,
  generateDefinedTableHeaderProto,
  generateProfileBCTableHeaderProto,
  generateRecordTableHeaderProto,
  generateTableAxisCoordinateProto,
  generateTableRecordProto,
} from './model';

/** The rectilinearTable.util package exports general utility methods for
 * validating, filtering, or transforming RectilinearTable objects */

/** List of table types that can have an unlimited number of columns */
export const UNLIMITED_COL_SIZE = [TableType.PROFILE_BC, TableType.CUSTOM_SAMPLE_DOE];

/** List of table types that can have a user defined header in the csv */
export const USER_DEFINED_HEADER = [TableType.PROFILE_BC, TableType.CUSTOM_SAMPLE_DOE];

/**
 * Get the header (the first row) and the body (the rest of the rows) of a parsed CSV table
 */
export function getHeaderBody(rows: csv.RowData[]): [csv.RowData, csv.RowData[]] {
  return [rows[0], rows.slice(1)];
}

/**
 * Returns true if the row should be interpreted as a header (title for each column) or
 * false if as data
 *
 * Assumes every cell must be a number for the row to be considered data. If any cell
 * is a string, then it is considered as a header
 */
export function isGenericHeader(row: csv.RowData) {
  return !row.every((cell) => typeof cell === 'number' || !Number.isNaN(parseFloat(cell)));
}

/**
 * Format a given table to be a generic table
 *
 * If no header is supplied, then a generic header is added to the table. A generic header is in the
 * form of "Column ${index}" where `index` is 0 for the first column
 */
export function formatGenericTable(rows: csv.RowData[]): [csv.RowData, csv.RowData[]] {
  const headerBody = getHeaderBody(rows);
  let header = headerBody[0];
  const body = headerBody[1];
  // cells with empty values are replaced with generic header name
  header.forEach((cell, index) => {
    if (cell === '') {
      header[index] = `Column ${index}`;
    }
  });
  const firstCellText = `${header[0]}`;
  if (!isGenericHeader(header)) {
    // the first row contains data! add initial header to body and create a new generic header
    body.unshift(header.map((cell) => (typeof cell === 'string' ? parseFloat(cell) : cell)));
    header = header.map((_, index) => `Column ${index}`);
  } else if (firstCellText.startsWith('#')) {
    // remove `#` indicating comment row if necessary
    header[0] = firstCellText.slice(1).trim();
  }
  return [header, body];
}

// Convert CSV row data to a RectiliearTable proto object
export function dataToTable(
  rows: csv.RowData[],
  tableDefinition: TableDefinition,
): RectilinearTable {
  let header: Header;
  switch (tableDefinition.tableType) {
    case TableType.PROFILE_BC: {
      // use the header supplied by the csv
      const [rawHeader, body] = formatGenericTable(rows);
      // Profile BC needs a special header if no header is defined by the user
      header = generateProfileBCTableHeaderProto(
        rawHeader.map((label) => `${label}`),
        tableDefinition.recordLabels[0] ?? undefined,
      );
      rows = body; // removed the header from the rows
      break;
    }
    case TableType.CUSTOM_SAMPLE_DOE: {
      // use the header supplied by the csv
      const [rawHeader, body] = formatGenericTable(rows);
      header = generateRecordTableHeaderProto(
        rawHeader.map((label) => `${label}`),
        false,
        'Sample',
      );
      rows = body; // removed the header from the rows
      break;
    }
    default:
      header = generateDefinedTableHeaderProto(tableDefinition);
      break;
  }

  const axisCoordinates: Axis_Coordinate[] = [];
  const records: Record[] = [];

  rows.forEach((row: any[], index) => {
    const rowCopy = row.slice();
    let recordValues: (string | number)[] = [];
    switch (tableDefinition.tableType) {
      case TableType.PROFILE_BC: {
        const value = rowCopy.shift();
        axisCoordinates.push(generateTableAxisCoordinateProto(value as number));
        recordValues = rowCopy.map((val) => (val as number));
        break;
      }
      case TableType.CUSTOM_SAMPLE_DOE: {
        axisCoordinates.push(
          new Axis_Coordinate({ type: { case: 'adfloat', value: newAdFloat(index + 1) } }),
        );
        recordValues = rowCopy.map((val) => val as number);
        break;
      }
      default: {
        tableDefinition.axisLabels.forEach(() => {
          const value = rowCopy.shift();
          axisCoordinates.push(generateTableAxisCoordinateProto(value as number));
        });
        recordValues = tableDefinition.recordLabels.map((defn) => {
          const value = rowCopy.shift();
          if (defn.quantity) {
            return value as number;
          }
          return value as string;
        });
        header = generateDefinedTableHeaderProto(tableDefinition);
        break;
      }
    }
    records.push(generateTableRecordProto(...recordValues));
  });

  const axis = new Axis({ coordinate: axisCoordinates });
  const metadata = new Metadata({ tableType: tableDefinition.tableType });

  return new RectilinearTable({ header, axis: [axis], record: records, metadata });
}

// A table label may have a name or a quantity associated with it.  If it has a
// quantity, construct text from its text value and optional unit.  Otherwise,
// return its name.
export function getLabelText(label: Header_Label): string {
  let text = '';

  if (label.type.case === 'quantity') {
    const quantity = label.type.value;
    text = getQuantityText(quantity);

    const unit = getQuantityUnit(quantity);
    if (unit) {
      text += ` [${unit}]`;
    }
  } else if (label.type.case === 'name') {
    text = label.type.value;
  }

  return text;
}

// A TableDefinition object may have a name or a quantity associated with it.
// If it has a quantity, construct text from its text value and optional unit.
// Otherwise, return its name.
export function getLabelDefinitionText(label: LabelDefinition): string {
  if (label.quantity !== undefined) {
    const text = getQuantityText(label.quantity);
    const unit = getQuantityUnit(label.quantity);
    if (unit) {
      return `${text} [${unit}]`;
    }
    return text;
  }

  return label.name || 'unknown';
}

// Map each table header axis label to a SelectOption
export function getTableDataSelectOptions(
  table: RectilinearTable | null,
  selectedIndex: number,
): SelectOption<number>[] {
  return (table?.header?.axisLabel || []).map(
    (label, i) => ({
      value: i,
      name: getLabelText(label),
      selected: i === selectedIndex,
    }),
  );
}

/**
 * Get the list of labels of a table definition in the order in the expected order. In other words,
 * the axis labels are listed before the record labels.
 *
 * @param definition the table definition to get the labels from
 *
 * @returns the table definition labels
 */
export function getTableColumnLabels(definition: TableDefinition) {
  return [...definition.axisLabels, ...definition.recordLabels].map(getLabelDefinitionText);
}

// Return an error message if a table doesn't match the expected axis and record quantities.
export function tableCompatibilityError(
  table: RectilinearTable,
  tableDefinition: TableDefinition,
): string {
  const axisCount = tableDefinition.axisLabels.length;
  const columnCount = tableDefinition.recordLabels ? tableDefinition.recordLabels.length : 0;

  const { header } = table;
  const axisLabels = header?.axisLabel || [];
  const recordLabels = header?.recordLabel || [];

  const countErrorMessage = (count: number, name: string) => {
    const singular = count === 1;
    const itemName = `${name}${singular ? '' : 's'}`;
    const verb = singular ? 'is' : 'are';

    return `Exactly ${count} ${itemName} ${verb} required`;
  };

  if (axisLabels.length !== axisCount) {
    return countErrorMessage(axisCount, 'axis label');
  }

  const axisLabelMatch = tableDefinition.axisLabels.every((label, i) => {
    if (label.quantity) {
      return (
        axisLabels[i].type.case === 'quantity' &&
        axisLabels[i].type.value === label.quantity
      );
    }
    return (
      axisLabels[i].type.case === 'name' &&
      axisLabels[i].type.value === label.name
    );
  });

  if (!axisLabelMatch) {
    return 'Incompatible axis labels';
  }

  if (recordLabels.length !== columnCount) {
    return countErrorMessage(columnCount, 'record label');
  }

  const recordLabelMatch = tableDefinition.recordLabels ?
    tableDefinition.recordLabels.every((label, i) => {
      if (label.quantity) {
        return (
          recordLabels[i].type.case === 'quantity' &&
          recordLabels[i].type.value === label.quantity
        );
      }
      return (
        recordLabels[i].type.case === 'name' &&
        recordLabels[i].type.value === label.name
      );
    }) : false;

  if (!recordLabelMatch) {
    return 'Incompatible record quantities';
  }

  const axisList = table.axis || [];
  if (axisList.length !== axisCount) {
    return countErrorMessage(axisCount, 'axis list');
  }

  const axisCoordinates = axisList[0].coordinate;
  if (!axisCoordinates.length) {
    return 'Axis coordinates list may not be empty';
  }

  const recordList = table.record || [];
  if (!recordList.length) {
    return 'Records list may not be empty';
  }

  const correctRecordSize = recordList.every(
    (record) => (record.entry.length === columnCount),
  );

  if (!correctRecordSize) {
    return `Records must each contain ${columnCount} ${columnCount === 1 ? 'entry' : 'entries'}`;
  }

  return '';
}

// Return of the table matches the given table definition
export function verifyTableCompatibility(
  table: RectilinearTable,
  tableDefinition: TableDefinition,
) {
  return !tableCompatibilityError(table, tableDefinition);
}

// Return true if metaData.tableType matches tableDefinition.tableType
export function verifyMetadataCompatibility(metadata: Metadata, tableType: TableType) {
  return metadata.tableType === tableType;
}

export const radialDistributionAxisQuantities = [QuantityType.RELATIVE_RADIUS];
export const radialDistributionRecordQuantities = [
  QuantityType.THRUST_PROFILE, QuantityType.TORQUE_PROFILE, QuantityType.RADIAL_FORCE_PROFILE,
];

export function getCoordinateValue(coord: Axis_Coordinate | Record_Entry): number {
  // When the case is 'adfloat`, its value is guaranteed to be of type AdFloatType, so call
  // `getAdValue()` on that value.
  const value = coord.type.case === 'adfloat' ? getAdValue(coord.type.value) : undefined;
  // Now, use Number() to cast the result. Why?  Because Number.isNaN(undefined) === false, but
  // Number.isNaN(Number(undefined)) === true.  ¯\_(ツ)_/¯
  return Number(value);
}

// Converts a RectilinearTable to an array of SparkLine series, one per table column.
export function axisTableToSparkLineSeries(
  table: RectilinearTable, // table to plot
  axisIndex = 0, // index of axis to use for abscissa values
  xUnit?: string,
  yUnit?: string,
): SparkLineSeries[] {
  const { header } = table;
  const axisLabels = header?.axisLabel || [];
  const recordLabels = header?.recordLabel || [];

  const axisLabel = axisLabels[axisIndex];
  const axisColumn = table.axis[axisIndex];

  if (!axisLabel || !axisColumn) {
    throw Error(`Axis index ${axisIndex} is out of range`);
  }

  const axisValues = axisColumn.coordinate.map((coord) => getCoordinateValue(coord) || 0);
  const xLabel = `${getLabelText(axisLabel)}${xUnit ? ` (${xUnit})` : ''}`;

  return recordLabels.map((recordLabel, i) => {
    const yLabel = `${getLabelText(recordLabel)}${yUnit ? ` (${yUnit})` : ''}`;

    const points: Point[] = table.record.reduce((result, record, j) => {
      const x = Number(axisValues[j]);
      const y = getCoordinateValue(record.entry[i]);
      if (!Number.isNaN(x) && !Number.isNaN(y)) {
        result.push({ x, y });
      }

      return result;
    }, [] as Point[]);

    return { xLabel, yLabel, points };
  });
}

// Radial stations (or any parameterized axis values) must be ordered and between 0 and 1.
export function checkRadialStation(table: RectilinearTable, axisIndex = 0) {
  const axis = table.axis[axisIndex];

  let lastValue: number | null = null;
  let error = '';
  axis.coordinate.some((coord) => {
    const entryValue = getCoordinateValue(coord);

    if (Number.isNaN(entryValue)) {
      error = `Radial stations must be numeric: ${entryValue}`;
    } else if (entryValue < 0 || entryValue > 1) {
      error = `Radial stations must be between 0 and 1: ${entryValue} is out of range`;
    } else if (lastValue !== null) {
      if (entryValue < lastValue) {
        error = `Radial stations must be ordered: ${lastValue} precedes ${entryValue}`;
      } else if (entryValue === lastValue) {
        error = `Radial stations must be unique: ${entryValue} is repeated`;
      }
    }
    lastValue = entryValue;
    return !!error;
  });

  return error;
}

export function checkPositiveChord(table: RectilinearTable) {
  const chordIdx = table.header?.recordLabel.findIndex(
    (label) => label.type.value === relativeChord.quantityType,
  );
  if (chordIdx && chordIdx > -1) {
    const nonPositiveChord = table.record.some(
      (record) => getCoordinateValue(record.entry[chordIdx]) < EPSILON,
    );
    return nonPositiveChord ? 'Entries for relative chord must be positive.' : '';
  }
  return 'Relative chord not found in table definition';
}

export function checkBladeElementGeometryTable(table: RectilinearTable) {
  return checkRadialStation(table, 0) || checkPositiveChord(table);
}

/**
 * Check the first column of the table for correctness
 *
 * The table is deemed correct if the first column contains all numeric values and the values
 * are in ascending order.
 *
 * @param table the table to check for correctness
 * @param onlyNonnegative if true, return error if there are negative values
 *
 * @returns empty string when correct or an error message otherwise
 */
export function checkFirstColumnCorrectness(
  table: RectilinearTable,
  onlyNonnegative: boolean = false,
) {
  // check to see if the first column is correct
  const axis = table.axis[0];
  let lastValue: number | null = null;
  let error = '';
  axis.coordinate.some((coord) => {
    const entryValue = getCoordinateValue(coord);

    if (Number.isNaN(entryValue)) {
      error = 'The first column must contain numeric values';
    } else if (lastValue && lastValue >= entryValue) {
      error = 'Entries in the first column must be in ascending order';
    } else if (onlyNonnegative && entryValue < 0) {
      error = 'Entries in the first column must be nonnegative';
    }
    lastValue = entryValue;
    return !!error;
  });
  return error;
}

export function checkProfileBC(table: RectilinearTable, onlyNonnegative: boolean = false) {
  const firstColumnError = checkFirstColumnCorrectness(table, onlyNonnegative);
  if (firstColumnError) {
    return firstColumnError;
  }

  // check to see if the headers are unique
  const header = table.header?.recordLabel;
  const headerSet = new Set();
  header?.forEach((column) => {
    headerSet.add(getLabelText(column));
  });
  if ((new Set(headerSet)).size !== header?.length) {
    return 'Column headers must have unique names';
  }

  // check to see if the data is correct
  let dataError: string | undefined;
  const columnNumbers = table.record.map(() => 0);
  table.record.some((row) => {
    row.entry.forEach((entry, index) => {
      if (entry.type.case === 'string' || entry.type.case === 'empty') {
        dataError = 'Entries must be a numeric value';
      } else {
        const value = getCoordinateValue(entry);
        if (Number.isNaN(value)) {
          columnNumbers[index] += 1;
        }
      }
    });
    return !!dataError;
  });
  if (dataError) {
    return dataError;
  }

  if (columnNumbers.some((column) => column >= table.record.length)) {
    return 'Columns cannot be empty';
  }

  return '';
}

/**
 * Check the given fan curve table to verify whether it is a valid fan curve table
 *
 * @param table the fan curve table to verify
 *
 * @returns empty string or an errror message
 */
export function checkFanCurve(table: RectilinearTable) {
  return checkFirstColumnCorrectness(table);
}

/**
 * Check the given table to verify whether it is a valid temperature varying table
 *
 * @param table the temperature dependent table to verify
 *
 * @returns empty string or an errror message
 */
export function checkTempVary(table: RectilinearTable) {
  const firstColumnError = checkFirstColumnCorrectness(table, true);
  if (firstColumnError) {
    return firstColumnError;
  }
  let dataError: string | undefined;
  table.record.some((row) => {
    row.entry.forEach((entry) => {
      if (entry.type.case === 'empty') {
        dataError = 'The CSV file cannot contain missing data. Please ensure all fields are ' +
          'populated before uploading the file.';
      } else if (entry.type.case === 'string') {
        dataError = 'The CSV file cannot contain text data. Please ensure all fields are ' +
          'numeric before uploading the file.';
      } else if (
        entry.type.case === 'adfloat' &&
        entry.type.value.adTypes.case === 'value' &&
        entry.type.value.adTypes.value < 0
      ) {
        dataError = 'The inputs cannot be negative. Please enter non-negative values';
      }
    });
    return !!dataError;
  });
  return dataError ?? '';
}

/**
 * Converts a table proto to a column config for the Table component
 *
 * Each column's ID is its index stringified.
 *
 * @param table the table proto to convert to column config
 * @param options optional column config options that will be common to all resulting columns
 *
 * @returns a list of column configs
 */
export function tableToColumnConfig(
  table: RectilinearTable,
  options?: ColumnConfig,
): ColumnConfig[] {
  const { header } = table;
  if (!header) {
    return [];
  }
  const labelToConfig = (label: Header_Label, id: string): ColumnConfig => ({
    ...options,
    id,
    label: getLabelText(label),
    type: 'number' as const,
  });
  const axisColumns = header!.axisLabel.length;
  return [
    ...header!.axisLabel.map((label, index) => labelToConfig(label, `${index}`)),
    ...header!.recordLabel.map(
      (label, index) => labelToConfig(label, `${axisColumns + index}`),
    ),
  ];
}

/**
 * Converts a table proto to a row config for the Table component. The table proto
 * must have one axis coordinate and it is added as the first element to each row
 * where the rest of the row is from the record entries.
 *
 * Each row's ID is its index stringified
 *
 * @throws assertion error when there is zero or more than 1 axis coordinate.
 *
 * @returns a list of row configs
 */
export function tableToRowConfig(table: RectilinearTable): RowConfig[] {
  // This function is currently only appropriate for tables with one axis.
  // For example AIRFOIL_PERFORMANCE tables have two axes of length N and M with a total
  // of N x M record entries.
  // LC-18908
  assert(table.axis.length === 1, 'Only one axis allowed');
  return table.record.map((record, rowIndex) => {
    const id = `${rowIndex}`;
    const axisValues = table.axis.reduce((acc, axis, index) => {
      acc[index] = getCoordinateValue(axis.coordinate[rowIndex]);
      return acc;
    }, {} as RowConfig['values']);
    const axisColumns = table.axis.length;

    return {
      id,
      values: record.entry.reduce((acc, entry, index) => {
        acc[index + axisColumns] = getCoordinateValue(entry);
        return acc;
      }, axisValues),
    };
  });
}
