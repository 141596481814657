// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 21;
export const GroupActionIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="groupAction"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M3.938,21
           C4.094,21 4.227,20.945 4.336,20.836
           C4.445,20.727 4.5,20.594 4.5,20.438
           L4.5,19.875
           L19.5,19.875
           L19.5,20.438
           C19.5,20.594 19.555,20.727 19.664,20.836
           C19.773,20.945 19.906,21 20.063,21
           L23.438,21
           C23.594,21 23.727,20.945 23.836,20.836
           C23.945,20.727 24,20.594 24,20.438
           L24,17.063
           C24,16.906 23.945,16.773 23.836,16.664
           C23.727,16.555 23.594,16.5 23.438,16.5
           L22.875,16.5
           L22.875,4.5
           L23.438,4.5
           C23.594,4.5 23.727,4.445 23.836,4.336
           C23.945,4.227 24,4.094 24,3.938
           L24,0.563
           C24,0.406 23.945,0.273 23.836,0.164
           C23.727,0.055 23.594,0 23.438,0
           L20.063,0
           C19.906,0 19.773,0.055 19.664,0.164
           C19.555,0.273 19.5,0.406 19.5,0.563
           L19.5,1.125
           L4.5,1.125
           L4.5,0.563
           C4.5,0.406 4.445,0.273 4.336,0.164
           C4.227,0.055 4.094,0 3.938,0
           L0.563,0
           C0.406,0 0.273,0.055 0.164,0.164
           C0.055,0.273 0,0.406 0,0.563
           L0,3.938
           C0,4.094 0.055,4.227 0.164,4.336
           C0.273,4.445 0.406,4.5 0.563,4.5
           L1.125,4.5
           L1.125,16.5
           L0.563,16.5
           C0.406,16.5 0.273,16.555 0.164,16.664
           C0.055,16.773 0,16.906 0,17.063
           L0,20.438
           C0,20.594 0.055,20.727 0.164,20.836
           C0.273,20.945 0.406,21 0.563,21
           L3.938,21Z
           M22.5,3
           L21,3
           L21,1.5
           L22.5,1.5
           L22.5,3Z
           M3,3
           L1.5,3
           L1.5,1.5
           L3,1.5
           L3,3Z
           M19.5,17.625
           L4.5,17.625
           L4.5,17.063
           C4.5,16.906 4.445,16.773 4.336,16.664
           C4.227,16.555 4.094,16.5 3.938,16.5
           L3.375,16.5
           L3.375,4.5
           L3.938,4.5
           C4.094,4.5 4.227,4.445 4.336,4.336
           C4.445,4.227 4.5,4.094 4.5,3.938
           L4.5,3.375
           L19.5,3.375
           L19.5,3.938
           C19.5,4.094 19.555,4.227 19.664,4.336
           C19.773,4.445 19.906,4.5 20.063,4.5
           L20.625,4.5
           L20.625,16.5
           L20.063,16.5
           C19.906,16.5 19.773,16.555 19.664,16.664
           C19.555,16.773 19.5,16.906 19.5,17.063
           L19.5,17.625Z
           M18.938,16.5
           C19.094,16.5 19.227,16.445 19.336,16.336
           C19.445,16.227 19.5,16.094 19.5,15.938
           L19.5,8.063
           C19.5,7.906 19.445,7.773 19.336,7.664
           C19.227,7.555 19.094,7.5 18.938,7.5
           L15,7.5
           L15,5.063
           C15,4.906 14.945,4.773 14.836,4.664
           C14.727,4.555 14.594,4.5 14.438,4.5
           L5.063,4.5
           C4.906,4.5 4.773,4.555 4.664,4.664
           C4.555,4.773 4.5,4.906 4.5,5.063
           L4.5,12.938
           C4.5,13.094 4.555,13.227 4.664,13.336
           C4.773,13.445 4.906,13.5 5.063,13.5
           L9,13.5
           L9,15.938
           C9,16.094 9.055,16.227 9.164,16.336
           C9.273,16.445 9.406,16.5 9.563,16.5
           L18.938,16.5Z
           M13.125,11.625
           L6.375,11.625
           L6.375,6.375
           L13.125,6.375
           L13.125,11.625Z
           M17.625,14.625
           L10.875,14.625
           L10.875,13.5
           L14.438,13.5
           C14.594,13.5 14.727,13.445 14.836,13.336
           C14.945,13.227 15,13.094 15,12.938
           L15,9.375
           L17.625,9.375
           L17.625,14.625Z
           M3,19.5
           L1.5,19.5
           L1.5,18
           L3,18
           L3,19.5Z
           M22.5,19.5
           L21,19.5
           L21,18
           L22.5,18
           L22.5,19.5Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
