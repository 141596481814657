// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useAtom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const STORAGE_KEY = 'overlay-cards-width';
const DEFAULT_WIDTH = 250;

const leftOverlayCardsWidthBase = atomWithStorage(STORAGE_KEY, DEFAULT_WIDTH);

const leftOverlayCardsWidthState = atom(
  (get) => get(leftOverlayCardsWidthBase),
  (get, set, width: number) => {
    // Instead of directly calling lcvHandler, we'll use a callback
    if (typeof window !== 'undefined') {
      window.requestAnimationFrame(() => {
        // Dynamically import lcvHandler to avoid circular dependency
        import('../../../lib/lcvis/handler/LcvHandler').then(({ lcvHandler }) => {
          lcvHandler.queueDisplayFunction('update triad', (display) => {
            display.updateTriadPosition();
          });
        }).catch(() => { });
      });
    }
    set(leftOverlayCardsWidthBase, width);
  },
);

export const useLeftOverlayCardsWidth = () => useAtom(leftOverlayCardsWidthState);
export const useLeftOverlayCardsWidthValue = () => useAtomValue(leftOverlayCardsWidthState);

export const getLeftOverlayCardsWidth = () => {
  const storedValue = localStorage.getItem(STORAGE_KEY);
  return storedValue ? parseInt(storedValue, 10) : DEFAULT_WIDTH;
};
