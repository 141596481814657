// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

/** A utility package for functions that involve various, lower-level utilities associated with
 * simulation data (like boundaryConditionUtils, etc.).  To avoid circular dependencies, this
 * package may import from those lower-level utilities, but they should never import from this one.
 */
import { SetterOrUpdater } from 'recoil';

import { getQuantityTags } from '../QuantityDescriptor';
import * as cadmetadatapb from '../proto/cadmetadata/cadmetadata_pb';
import * as simulationpb from '../proto/client/simulation_pb';
import * as feoutputpb from '../proto/frontend/output/output_pb';
import * as meshgenerationpb from '../proto/meshgeneration/meshgeneration_pb';
import { QuantityTag } from '../proto/quantity/quantity_options_pb';
import { EntityGroupData } from '../recoil/entityGroupState';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import assert from './assert';
import {
  AnyBoundaryCondition,
  findFluidBoundaryCondition,
  findHeatBoundaryCondition,
  findParentPhysicsByBoundaryConditionId,
  getNonSlipWallSurfaces,
  getUnassignedSurfacesByPhysics,
  getWallSurfacesWithTags,
  hasOverset,
} from './boundaryConditionUtils';
import { MESH_MAX_CELLS } from './constants';
import { EntityGroupMap } from './entityGroupMap';
import { unionSet } from './lang';
import { nullableMeshing } from './mesh';
import { DEFAULT_ADAPTATION_BL } from './paramDefaults/boundaryLayerProfileState';
import { DEFAULT_BL, DEFAULT_MODEL } from './paramDefaults/meshingMultiPartState';
import { findParticleGroupById } from './particleGroupUtils';
import { getFluid, getPhysicsId, getSurfaceToPhysicsMap, isPhysicsFluid } from './physicsUtils';
import { assignSlidingInterfaceSurfaces } from './slidingInterfaceUtils';
import { wordsToList } from './text';

function getSurfaceOrGroupName(surface: string, entityGroupMap?: EntityGroupMap): string {
  if (entityGroupMap?.has(surface)) {
    return entityGroupMap.get(surface).name;
  }
  return surface;
}

function surfacesToWordList(surfaceIds: string[], entityGroupMap: EntityGroupMap) {
  const surfaceNames = surfaceIds.map(
    (surfaceId) => getSurfaceOrGroupName(surfaceId, entityGroupMap),
  );
  return wordsToList(
    surfaceNames.length > 5 ?
      [...surfaceNames.slice(0, 4), 'the other selected surfaces'] :
      surfaceNames,
  );
}

function isSimulationTransition(param: simulationpb.SimulationParam) {
  return param.physics.some((physics) => {
    const fluid = getFluid(physics);
    return (
      fluid?.basicFluid?.viscousModel === simulationpb.ViscousModel.RANS &&
      fluid?.turbulence?.transitionModel !== simulationpb.TransitionModel.NO_TRANSITION
    );
  });
}

// Assign surfaces to the source BC. surfaceIds is the set of surfaces that go in source BC. This
// edits the param value. Returns a string with an error message if there is any. entityGroupMap is
// optional just for error message displaying.
export function assignSurfacesToBoundaryCondition(
  surfaceIds: string[],
  sourceBC: AnyBoundaryCondition,
  param: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
  entityGroupData: EntityGroupData,
): string {
  const bcId = sourceBC.boundaryConditionName;
  const physics = findParentPhysicsByBoundaryConditionId(param, bcId);
  assert(!!physics, 'Cannot find parent physics for boundary condition');

  const surfacesToPhysics = getSurfaceToPhysicsMap(param, geometryTags, staticVolumes);
  const physicsId = getPhysicsId(physics);

  // We compile a pool of all the surfaces that are unassigned or in the source BC. These are all
  // the surfaces that are part of or that can be added to the BC.
  const surfacePool = unionSet(
    getUnassignedSurfacesByPhysics(param, physicsId, geometryTags, staticVolumes, entityGroupData),
    sourceBC.surfaces,
  );

  // Check which surfaces are already attached to another BC (and hence cannot be added again) and
  // leave only the surfaces that are available for attachment.
  const notThisPhysics: string[] = [];
  const alreadyAttached: string[] = [];
  const surfacesToAttach: string[] = [];
  surfaceIds.forEach((surface) => {
    const surfacesFromTags = geometryTags.surfacesFromTagEntityGroupId(surface);
    if (surfacePool.has(surface) || surfacesFromTags?.length) {
      surfacesToAttach.push(surface);
    } else if (surfacesToPhysics[surface] !== physicsId) {
      notThisPhysics.push(surface);
    } else {
      alreadyAttached.push(surface);
    }
  });

  const errors: string[] = [];

  // If there are some selected surfaces that are already attached somewhere, we must show an error
  // for them.
  if (alreadyAttached.length) {
    const attachedNames = surfacesToWordList(alreadyAttached, entityGroupData.groupMap);
    const verb = alreadyAttached.length === 1 ? 'is' : 'are';
    errors.push(`${attachedNames} ${verb} already defined.`);
  }
  // If there are some selected surfaces that aren't available to the physics, we must show an error
  // for them too.
  if (notThisPhysics.length) {
    const notThisPhysicsNames = surfacesToWordList(notThisPhysics, entityGroupData.groupMap);
    const verb = notThisPhysics.length === 1 ? 'is' : 'are';
    errors.push(`${notThisPhysicsNames} ${verb} not available to this physics.`);
  }

  // Assign the new set of surfaces.
  sourceBC.surfaces = surfacesToAttach;
  return errors.join('\n');
}

// Generate a warning if user tries to add a non-disk surface to an output with disk actuator
// quantity type
export function getNonDiskWarning(
  geometryIds: string[],
  outputNode: feoutputpb.OutputNode,
  param: simulationpb.SimulationParam,
  entityGroupMap: EntityGroupMap,
): string {
  if (
    (outputNode.nodeProps.case === 'force') &&
    getQuantityTags(outputNode.nodeProps.value.quantityType).includes(
      QuantityTag.TAG_ACTUATOR_DISK,
    )
  ) {
    // List of newly-added surfaces that are not actuator disks
    const newNonDisks = geometryIds.filter((geometryId) => (
      !outputNode.inSurfaces.includes(geometryId) &&
      findParticleGroupById(
        param,
        geometryId,
      )?.particleGroupType !== simulationpb.ParticleGroupType.ACTUATOR_DISK
    ));
    if (newNonDisks.length) {
      const predicate = newNonDisks.length === 1 ?
        'is not an actuator disk.' :
        'are not actuator disks.';
      const nonDiskNames = newNonDisks.map(
        (surface) => getSurfaceOrGroupName(surface, entityGroupMap),
      );
      return `${wordsToList(nonDiskNames)} ${predicate}`;
    }
  }
  return '';
}

// Compute the default volume params from the CAD metadata and boundary conditions.
export function defaultModelParams(
  metadata: cadmetadatapb.CadMetadata,
  simParam: simulationpb.SimulationParam,
) {
  const modelParams = DEFAULT_MODEL.clone();
  const geometryTags = new GeometryTags(undefined, metadata);
  if (modelParams.selection === meshgenerationpb.MeshingMultiPart_ModelParams_SelectionType.WALL) {
    modelParams.surfaces = getWallSurfacesWithTags(simParam, geometryTags);
  }
  modelParams.maxSize = metadata.modelMaxSizeM > 0 ?
    metadata.modelMaxSizeM :
    metadata.globalMaxSizeM;
  return modelParams;
}

// Compute the default boundary layer params from the boundary conditions.
export function defaultBoundaryLayerParams(simParam: simulationpb.SimulationParam) {
  const blParams = DEFAULT_BL.clone();
  if (
    blParams.selection === meshgenerationpb
      .MeshingMultiPart_BoundaryLayerParams_SelectionType
      .WALL_NO_SLIP
  ) {
    blParams.surfaces = getNonSlipWallSurfaces(simParam);
  }
  return blParams;
}

// Create default adaptation boundary layer params.
export function defaultAdaptationBoundaryLayerParams(
  param: simulationpb.SimulationParam,
  metadata: cadmetadatapb.CadMetadata,
) {
  const blParams = DEFAULT_ADAPTATION_BL.clone();
  const geometryTags = new GeometryTags(undefined, metadata);
  const wallSurfaces = getWallSurfacesWithTags(param, geometryTags);
  blParams.surfaces = wallSurfaces.length ? getNonSlipWallSurfaces(param) : [];
  return blParams;
}

// Compute the default boundary layer params from the boundary conditions.
export function defaultMeshComplexityParams() {
  return new meshgenerationpb.MeshingMultiPart_MeshComplexityParams({
    type: meshgenerationpb.MeshingMultiPart_MeshComplexityParams_ComplexityType.MAX,
    limitMaxCells: BigInt(MESH_MAX_CELLS),
  });
}

// Set default MeshingMode
export function defaultMeshingMode() {
  return new meshgenerationpb.MeshingMultiPart_MeshingMode({
    mode: {
      case: 'default',
      value: {},
    },
  });
}

// Update any meshing parameters that depend on the boundary conditions.
export function updateMeshingFromBc(
  oldParam: simulationpb.SimulationParam,
  newParam: simulationpb.SimulationParam,
  setMeshMultiPart: SetterOrUpdater<nullableMeshing>,
  geometryTags: GeometryTags,
) {
  setMeshMultiPart((oldMeshMultiPart) => {
    if (!oldMeshMultiPart) {
      return null;
    }
    const hasWallsOld = !!getWallSurfacesWithTags(oldParam, geometryTags).length;
    const hasWallsNew = !!getWallSurfacesWithTags(newParam, geometryTags).length;

    const newMeshMultiPart = oldMeshMultiPart.clone();
    const defaultBL = newMeshMultiPart.blParams[0];
    const BlSelectionType = meshgenerationpb.MeshingMultiPart_BoundaryLayerParams_SelectionType;
    // Switch to WALL if a wall was added and if it is still on the default value of NONE.
    if (
      !hasWallsOld && hasWallsNew &&
      oldMeshMultiPart.blParams.length === 1 &&
      defaultBL.selection === BlSelectionType.NONE
    ) {
      defaultBL.selection = BlSelectionType.WALL_NO_SLIP;
    }
    // Update default boundary layer, if it set to WALL_NO_SLIP.
    if (defaultBL.selection === BlSelectionType.WALL_NO_SLIP) {
      defaultBL.surfaces = getNonSlipWallSurfaces(newParam);
    }

    const defaultModel = newMeshMultiPart.modelParams[0];
    const ModelSelectionType = meshgenerationpb.MeshingMultiPart_ModelParams_SelectionType;
    // Switch to WALL if a wall was added and if it is still on the default value of ALL.
    if (
      !hasWallsOld && hasWallsNew &&
      oldMeshMultiPart.modelParams.length === 1 &&
      defaultModel.selection === ModelSelectionType.ALL
    ) {
      defaultModel.selection = ModelSelectionType.WALL;
    }

    // Update default model, if it set to WALL.
    if (defaultModel.selection === ModelSelectionType.WALL) {
      defaultModel.surfaces = getWallSurfacesWithTags(newParam, geometryTags);
    }
    return newMeshMultiPart;
  });
}

// This assumes the selectedNode in paraview context is a boundary condition. Sets the surfaces of
// that boundary conditions to a new value.
export function setBcSurfaces(
  surfaces: string[],
  bcName: string,
  param: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
  entityGroupData: EntityGroupData,
) {
  // Find the selected BC.
  const boundaryCondition = findFluidBoundaryCondition(param, bcName) ||
    findHeatBoundaryCondition(param, bcName);
  if (!boundaryCondition) {
    throw Error(`Boundary Condition ${bcName} not found`);
  }

  return assignSurfacesToBoundaryCondition(
    surfaces,
    boundaryCondition,
    param,
    geometryTags,
    staticVolumes,
    entityGroupData,
  );
}

// Assigns surfaces to a motion interface.  We also need to update the undefined boundary condition
// members by adding any old motion members and removing the members of the new assignment. If
// dryRun is true, we only check for errors and don't make any assignments.
export function setSlidingInterfaceSurfaces(
  surfaceIds: string[],
  interfaceId: string,
  sideA: boolean,
  param: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
  entityGroupData: EntityGroupData,
  dryRun: boolean,
): string {
  const errors = assignSlidingInterfaceSurfaces(
    param,
    interfaceId,
    sideA,
    surfaceIds,
    geometryTags,
    staticVolumes,
    entityGroupData,
    dryRun,
  );

  return errors.join(' ');
}

export function isSimulationSteady(param: simulationpb.SimulationParam) {
  return param.general?.flowBehavior === simulationpb.FlowBehavior.STEADY;
}

export function isSimulationTransient(param: simulationpb.SimulationParam) {
  return param.general?.flowBehavior === simulationpb.FlowBehavior.TRANSIENT;
}

export function isSimulationImplicitTime(param: simulationpb.SimulationParam) {
  return param.time?.timeMarching === simulationpb.TimeMarching.TIME_IMPLICIT;
}

/**
 * Check that the simulation is LMA compatible. Currently, LMA is only supported for simulations
 * with a single fluid physics without transition models (having no physics is also technically
 * compatible).
 *
 * @param param SimulationParam
 * @returns Boolean - true if LMA compatible
 */
export function isSimLMACompatible(param: simulationpb.SimulationParam) {
  const physics = param.physics;
  const overset = hasOverset(param);
  const isFluidOrEmpty = (
    physics.length === 0 ||
    (isPhysicsFluid(physics[0]) && !isSimulationTransition(param))
  );
  const isAdjoint = param.general?.floatType === simulationpb.FloatType.ADA1D;

  return physics.length <= 1 && isFluidOrEmpty && !overset && !isAdjoint;
}
