// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import vtkImageStream from '@kitware/vtk.js/IO/Core/ImageStream';
import vtkViewStream from '@kitware/vtk.js/IO/Core/ImageStream/ViewStream';
import vtkInteractorStyleRemoteMouse, {
  MouseEvent,
  WheelEvent,
} from '@kitware/vtk.js/Interaction/Style/InteractorStyleRemoteMouse';
import vtkRenderWindow from '@kitware/vtk.js/Rendering/Core/RenderWindow';
import vtkRenderWindowInteractor from '@kitware/vtk.js/Rendering/Core/RenderWindowInteractor';
import vtkRenderer from '@kitware/vtk.js/Rendering/Core/Renderer';
import vtkOpenGLRenderWindow from '@kitware/vtk.js/Rendering/OpenGL/RenderWindow';
// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import FileSaver from 'file-saver';

import { Client, ViewName } from '../../lib/ParaviewClient';
import { Logger } from '../../lib/observability/logs';
import { RawCameraInfo, toCameraState } from '../../lib/paraviewUtils';
import Renderer, { MouseClickEvent, RendererType } from '../../lib/renderer';
import * as status from '../../lib/status';
import { StatusCardProps } from '../../lib/statusCardProps';
import { addPvRpcError } from '../../lib/transientNotification';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';

import { setCameraState } from './cameraUtil';

const logger = new Logger('Paraview/ImageRenderer');

// Paraview rendering parameters.
const INTERACTIVE_QUALITY = 60; // jpeg compression during mouse drag; Kitware default is 60
const INTERACTIVE_RATIO = 1; // screen resolution downgrade during mouse drag
// The minimum interval between mouse events, for vtk.js
// Kitware default is 33.3 (approx. 30 mouse events per second)
// ours is lower because we implement admission control in the mouse queue controller
const THROTTLE_TIME = 0;
const SERVER_MAX_FPS = 60; // There is now no reason to artifically keep this low.
// JPEG compression in still mode. 80% quality reduces the image size by 3x,
// with little visual quality degradation.
const STILL_QUALITY = 80; // Kitware default is 100
// Amount of time (ms) to batch window resize events to reduce the frequence of
// redraws.
const RESIZE_DEBOUNCE_TIMEOUT = 300;
// The interval of time in milliseconds in which two clicks are considered a
// double click.
const DOUBLE_CLICK_INTERVAL = 250;

// The state of mouse interaction during pointer dragging.
interface MouseState {
  down: boolean; // is dragging active now?
  animating: boolean; // is the server in animation mode?
  lastEvent: MouseEvent | null; // the last mouse event.
}

// Check if two MouseStates are identical.
function mouseEventsEqual(e0: MouseEvent | null, e1: MouseEvent | null): boolean {
  if (!!e0 !== !!e1) {
    return false;
  }
  if (!e0 || !e1) {
    return true;
  }
  return (e0.action === e1.action &&
    e0.x === e1.x && e0.y === e1.y &&
    e0.buttonLeft === e1.buttonLeft &&
    e0.buttonMiddle === e1.buttonMiddle &&
    e0.buttonRight === e1.buttonRight &&
    e0.shiftKey === e1.shiftKey &&
    e0.altKey === e1.altKey &&
    e0.ctrlKey === e1.ctrlKey &&
    e0.metaKey === e1.metaKey);
}

// Create an empty MouseState.
function newMouseState(): MouseState {
  return {
    down: false,
    animating: false,
    lastEvent: null,
  };
}

// Returns true if this mouse click event should get us into box select mode.
export function triggerBoxSelect(event: MouseClickEvent) {
  return event.altKey || (event.shiftKey && event.button === 1);
}

// MouseQueueController handles admission control for remote mouse RPCs
// The queue controller does not actually track the queued events, but rather
// functions as a sort of counting semaphore to limit the number of outstanding
// mouse events at one time.
class MouseQueueController {
  private queueLen: number = 0;
  // The previous queue length (1) is too restrictive, it will make the fps dependent on the
  // latency, effectively keeping fps under 20 most of the time. We set this to 8 so that even under
  // a laggy network (200 ms ping), about 40 mouse events can be in flight at once, for an fps = 40.
  private maxQueueLen: number = 4;

  constructor() { }

  // join attempts to join the queue. returns true iff the caller successfully got a spot,
  // in which case the caller is responsible for calling leave() exactly once.
  public join(): boolean {
    if (this.queueLen >= this.maxQueueLen) {
      return false;
    }
    this.queueLen += 1;
    return true;
  }

  // leave leaves the queue, releasing the spot for other callers to join.
  public leave(): void {
    if (this.queueLen < 1) {
      logger.error('mouse queue underflow');
      return; // We would rather just reset the queue to empty than allow negative length.
    }
    this.queueLen -= 1;
  }
}

// ImageRenderer manages contents of a HTML <div> element using vtk.js.
//
// - It connects to a remote paraview server and displays scene through the
// image-stream protocol.
//
// - It also manages a local WebGL canvas to draw arbitrary vtk objects.
// Currently, it is not used.
//
// - It sends mouse events (screen [x, y] and button state) to the paraview
// server, which updates the scene, sends the new image to the client, and
// reports the new camera state. The client updates its camera with the state
// supplied by the server.
//
// - It provides a few convenience methods for updating the camera position.
//
// This class provides three main methods.
//
// - initialize() should be called as soon as the <div> element is mounted.
//   It will create child elements inside the <div> for drawing paraview
//   elements.
//
// - setClient() should be called called whenever a paraview session is
//   established, or is disconnected.
//
// - stop() should be called when the <div> element is unmounted.
//
// Legal call sequences are: (initialize, setClient*, stop)*
//
// This class almost acts like a React component, but it works as a stateful
// class, because it needs to provide provide camera control methods (e.g.,
// parallel vs orthographic switch) to components that aren't related to the
// paraview canvas.
class ImageRenderer implements Renderer {
  private renderWindow: vtkRenderWindow | null = null;
  private renderer: vtkRenderer | null = null;
  private openGL: vtkOpenGLRenderWindow | null = null;
  private client: Client | null = null;
  private interactor: vtkRenderWindowInteractor | null = null;
  private elem: HTMLDivElement | null = null;
  private resizeObserver: ResizeObserver | null = null;
  private onCameraUpdate: ((state: ParaviewRpc.CameraState) => void) | null = null;
  private onMouseClick: ((event: MouseClickEvent) => void) | null = null;
  private onMouseDoubleClick: ((event: MouseClickEvent) => void) | null = null;
  private onMouseDrag: ((event: MouseClickEvent) => void) | null = null;
  private onKeepalive: (() => void) | null = null;
  private deleter: (() => void)[] = [];
  private viewStream: vtkViewStream | null = null;
  // For resize debouncing.
  private resizeTimeout: ReturnType<typeof setTimeout> | null = null;

  private mouse = newMouseState();
  private mouseQueue = new MouseQueueController();
  private experimentConfig: string[] = [];
  private clickTimeout: ReturnType<typeof setTimeout> | null = null;
  public mostRecentCameraUpdate: number = Date.now();
  // Keeps track of whether the mouse event should be passed on to paraview or if it will be
  // intercepted somewhere else (e.g. for box select)
  private withholdMouseEvents: boolean = false;

  constructor() { }

  // Implements Renderer.
  public rendererType(): RendererType {
    return RendererType.IMAGE;
  }

  private clearClickTimeout(): void {
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
    }
    this.clickTimeout = null;
  }

  // Implements Renderer.
  public initialize(_: ViewName, elem: HTMLDivElement, experimentConfig: string[]): void {
    if (!elem) {
      throw Error('null elem');
    }
    if (this.elem === elem) {
      return;
    }

    this.stop();

    this.elem = elem;
    this.experimentConfig = experimentConfig;
    this.renderWindow = vtkRenderWindow.newInstance();
    this.renderer = vtkRenderer.newInstance();
    this.renderWindow.addRenderer(this.renderer);
    this.openGL = vtkOpenGLRenderWindow.newInstance();
    this.openGL.setContainer(elem);
    this.renderWindow.addView(this.openGL);

    // Catch window resize events.
    this.resizeObserver = new ResizeObserver(() => {
      if (!this.resizeTimeout) {
        this.resizeTimeout = setTimeout(() => {
          this.resizeTimeout = null;
          this.onResize();
        }, RESIZE_DEBOUNCE_TIMEOUT);
      }
    });
    this.resizeObserver.observe(elem);

    // Set up a mouse handler. It sends all the mouse events to the server
    // first. It then receives an updated camera setting from the server and
    // sets the local camera to it.
    this.interactor = vtkRenderWindowInteractor.newInstance();
    const style = vtkInteractorStyleRemoteMouse.newInstance();
    style.setThrottleDelay(THROTTLE_TIME);
    this.interactor.setInteractorStyle(style);
    // mouseRpc sends a mouse RPC to Paraview to notify it of the mouse event.
    // At most one mouse RPC with admissionControl=true may be outstanding at one time.  Other
    // intervening calls with admissionControl=true will be dropped by admission control.
    const mouseRpc = (
      methodName: string,
      event: MouseEvent | WheelEvent,
      admissionControl: boolean,
    ) => {
      const session = this.client?.connection?.getSession();
      if (!session) {
        return;
      }
      if (admissionControl) {
        if (!this.mouseQueue.join()) {
          return;
        }
      }
      session.call(methodName, [event])
        .then((info: RawCameraInfo) => {
          if (!this.renderer || !this.interactor) {
            return;
          }
          const camera = this.renderer!.getActiveCamera();
          const state = toCameraState(info);
          setCameraState(state, camera, null);
          this.renderWindow?.render();

          // Update the stored camera state only after a mouse up or end mouse wheel event to
          // avoid unnecessary callbacks.
          if (event.action === 'up' || event.type === 'EndMouseWheel') {
            this.onCameraUpdate?.(state);
            this.onKeepalive?.();
          }
        })
        .catch((err: status.ParaviewError) => {
          logger.error(`Error in ${methodName}`, err);
        })
        .finally(() => {
          if (admissionControl) {
            this.mouseQueue.leave();
          }
        });
    };
    const convertMouseEvent = (ev: MouseEvent) => {
      if (!this.interactor) {
        throw Error('interactor must be defined in convertMouseEvent');
      }
      // The mouse button was released without any pointer movement. The
      // vtkjs mouse coordinate is in range ([0,1], [0,1]), with (0,0) at
      // the lower left corner. Convert that to the HTML canvas coordinate.
      const [viewWidth, viewHeight] = this.interactor.getView().getSizeByReference();
      let button = 0;
      if (this.mouse.lastEvent?.buttonLeft) {
        button = 1;
      } else if (this.mouse.lastEvent?.buttonMiddle) {
        button = 2;
      } else if (this.mouse.lastEvent?.buttonRight) {
        button = 3;
      } else {
        logger.warn('Button not found on mouse up.');
      }
      return {
        button,
        screenX: ev.x * viewWidth,
        screenY: viewHeight - ev.y * viewHeight,
        ...ev,
      };
    };

    style.onRemoteMouseEvent((ev: MouseEvent) => {
      if (ev.action === 'down') {
        if (!this.mouse.down) {
          this.mouse.down = true;
        } else if (!mouseEventsEqual(this.mouse.lastEvent, ev)) {
          // Only after the user moves the mouse while pressing the button, put
          // the server in the animation mode.
          if (!this.mouse.animating) {
            this.mouse.animating = true;
            this.viewStream?.startInteraction();
          }
          const mouseClickEvent = convertMouseEvent(ev);
          if (triggerBoxSelect(mouseClickEvent)) {
            this.withholdMouseEvents = true;
          }
          if (!this.withholdMouseEvents) {
            // Now that the server can render more than 15 fps, the previous limit (1 mouse event in
            // flight at the maximum from the client to the server) will keep the framerate to
            // something under 20 fps. I have found that the admission control is no longer needed.
            mouseRpc('viewport.mouse.interaction', ev, false);
          }
          this.onMouseDrag?.(mouseClickEvent);
        }
      } else if (ev.action === 'up') {
        this.mouse.down = false;
        this.withholdMouseEvents = false;
        if (this.mouse.animating) {
          // Skip admission control for mouse up events -- we always want to send these.
          mouseRpc('viewport.mouse.interaction', ev, false);
          this.mouse.animating = false;
          this.viewStream?.endInteraction();
        } else if (this.clickTimeout) {
          // We distinguish double and single clicks by setting a timeout on the
          // first click. If another click is received before the time interval
          // finishes, this is a double click. Otherwise, the time will run out
          // and the single click handler is triggered.
          this.clearClickTimeout();
          if (this.onMouseDoubleClick) {
            this.onMouseDoubleClick(convertMouseEvent(ev));
          }
        } else if (this.interactor) {
          const convertedEvent = convertMouseEvent(ev);
          const onSingleClick = () => {
            if (this.onMouseClick) {
              this.onMouseClick(convertedEvent);
            }
            this.clearClickTimeout();
          };
          this.clickTimeout = setTimeout(onSingleClick, DOUBLE_CLICK_INTERVAL);
        }
      }
      this.mouse.lastEvent = ev;
    });
    style.onRemoteWheelEvent((event: WheelEvent) => {
      mouseRpc('viewport.mouse.zoom.wheel', event, false);
    });
    this.interactor.setView(this.openGL);
    this.interactor.initialize();
    this.interactor.bindEvents(elem);
  }

  // Called when the size of this.elem has changed. It propagates the new size
  // to both openGL and viewStream.
  private onResize() {
    if (!this.viewStream || !this.openGL || !this.elem) {
      return;
    }
    const rect = this.elem.getBoundingClientRect();
    this.openGL.setSize(rect.width, rect.height);
    this.viewStream.setSize(rect.width, rect.height);
    this.viewStream.invalidateCache();
    this.renderWindow!.render();
    this.onKeepalive!();
  }

  // setClient sets the paraview session object to be called to talk to the
  // server. If the paraview server is unavailable, the client should be
  // null. In that case, new interactions will be disabled, but the last scene
  // from the server will remain shown. This method may be called repeatedly,
  // in between initialize() and stop().
  //
  // onCameraUpdate will be called whenever the server reports a new camera
  // setting.  onMouseClick will be called if the user clicked a mouse without
  // dragging. onMouseDrag will be called when the user drags the
  // mouse. onKeepalive is called whenever user manipulates the mouse or the
  // window.
  public setClient(
    client: Client | null,
    onCameraUpdate: (state: ParaviewRpc.CameraState) => void,
    onMouseClick: (event: MouseClickEvent) => void,
    onMouseDoubleClick: (event: MouseClickEvent) => void,
    onMouseDrag: (event: MouseClickEvent) => void,
    onKeepalive: () => void,
  ): void {
    this.onCameraUpdate = onCameraUpdate;
    this.onMouseClick = onMouseClick;
    this.onMouseDoubleClick = onMouseDoubleClick;
    this.onMouseDrag = onMouseDrag;
    this.onKeepalive = onKeepalive;
    if (this.client === client) {
      return;
    }
    this.stopClient();
    this.client = client;
    if (!client) {
      return;
    }

    const session = client.connection?.getSession();
    if (!session) {
      throw Error('null session');
    }

    const { interactor } = this;
    if (!interactor) {
      throw Error('initialize not called');
    }

    // Arrange to add "view: viewId" to future mouse JSON events. It is assumed
    // by the remote mouse handler.
    interactor.getInteractorStyle().setRemoteEventAddOn({ view: client.viewId });

    // Set up a remote connection to the paraview server.
    const imageStream = vtkImageStream.newInstance();
    imageStream.connect(session);
    imageStream.setServerAnimationFPS(SERVER_MAX_FPS);
    this.viewStream = imageStream.createViewStream(client.viewId.toString());
    if (!this.viewStream) {
      throw Error(`null viewstream ${client.viewId}`);
    }
    this.viewStream.setStillQuality(STILL_QUALITY);
    this.viewStream.setInteractiveQuality(INTERACTIVE_QUALITY);
    this.viewStream.setInteractiveRatio(INTERACTIVE_RATIO);
    this.deleter.push(() => {
      this.openGL?.setViewStream(null as any);
      imageStream.delete(); // will delete viewStream too.
      this.viewStream = null;
    });
    // Start the rendering process.
    this.openGL!.setViewStream(this.viewStream);
    this.onResize(); // propagate the screen size to the backend.
    this.renderWindow!.render();
  }

  // Deletes resources created by setClient.
  private stopClient(): void {
    for (; ;) {
      const fn = this.deleter.pop();
      if (!fn) {
        break;
      }
      fn();
    }
    this.client = null;
    this.mouse = newMouseState();
  }

  // Deletes all internal objects. It's safe to call multiple times.  Must be
  // called once when the <div> element is unmounted.
  public stop(): void {
    logger.debug('imagerenderer: stop');
    this.stopClient();
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.elem!);
      this.resizeObserver = null;
    }
    if (this.interactor) {
      this.interactor.delete();
      this.interactor = null;
    }
    if (this.openGL) {
      this.openGL.delete();
      this.openGL = null;
    }
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = null;
    }

    this.renderer = null;
    this.renderWindow = null;
    this.elem = null;
  }

  public updateCameraState(state: ParaviewRpc.CameraState): void {
    if (!this.renderer || !this.interactor) {
      logger.error(`start not called: ${this.onCameraUpdate} ${this.renderer}`);
      return;
    }
    setCameraState(state, this.renderer!.getActiveCamera(), null);
    this.onCameraUpdate!(state);
    this.onKeepalive!();
    this.renderWindow!.render();
    this.mostRecentCameraUpdate = Date.now();
  }

  /**
   * Resets camera to default zoom, angle, and rotation values.
   */
  public resetCamera(): void {
    if (!this.client) {
      logger.debug('resetCamera: paraview disconnected');
    } else {
      ParaviewRpc.resetcamera(this.client)
        .then((result: ParaviewRpc.CameraState) => {
          this.updateCameraState(result);
        }).catch((err: status.ParaviewError) => {
          addPvRpcError('Could not reset the camera in Paraview', err);
        });
    }
  }

  public setCamera(camera: ParaviewRpc.CameraState): void {
    if (!this.client) {
      logger.debug('setCamera: paraview disconnected');
    } else {
      ParaviewRpc.setcamera(this.client, camera)
        .then(() => {
          this.updateCameraState(camera);
        }).catch((err: status.ParaviewError) => {
          addPvRpcError('Could not set the camera in Paraview', err);
        });
    }
  }

  /**
   * Zooms the camera to a box defined in VTK/ParaView coordinates.
   */
  public zoomToBox(minX: number, maxX: number, minY: number, maxY: number): void {
    if (!this.client) {
      logger.debug('zoomToBox: paraview disconnected');
    } else {
      ParaviewRpc.zoomtobox(this.client, null, minX, maxX, minY, maxY)
        .then((result: ParaviewRpc.CameraState) => {
          this.updateCameraState(result);
        }).catch((err: status.ParaviewError) => {
          addPvRpcError('Could not zoom to the box', err);
        });
    }
  }

  /**
   * Finds all the blocks within the given box. If useFrustum is false, only the block visible on
   * the screen will be selected. Else, blocks that have visibility ON and whose projection onto
   * the display space lay within the box will be selected.
   */
  public boxBlockSelect(
    minX: number,
    maxX: number,
    minY: number,
    maxY: number,
    useFrustum: boolean,
    onBlocksSelected: (block: string[]) => void,
  ): void {
    if (!this.client) {
      logger.debug('zoomToBox: paraview disconnected');
    } else {
      ParaviewRpc.boxblockselect(this.client, minX, minY, maxX, maxY, useFrustum)
        .then((blocks: string[]) => {
          onBlocksSelected(blocks);
        }).catch((err: status.ParaviewError) => {
          addPvRpcError('Could not box select.', err);
        });
    }
  }

  /**
   * Sets the camera center of rotation to a user selected point on the screen
   */
  public setCenterOfRotationCamera(screenX: number, screenY: number): void {
    if (!this.client) {
      logger.debug('setCenterOfRotationCamera: paraview disconnected');
    } else {
      ParaviewRpc.setcenterofrotationcamera(this.client, null, screenX, screenY)
        .then((result: ParaviewRpc.CameraState) => {
          this.updateCameraState(result);
        }).catch((err: status.ParaviewError) => {
          addPvRpcError('Could not set the camera center of rotation', err);
        });
    }
  }

  /**
   * Called to change the zoom level. Scale of 1.5 makes objects to be drawn
   * 1.5x larger without changing the camera angle.
   */
  public zoomCamera(scale: number): void {
    if (!this.client) {
      logger.debug('zoomCamera: paraview disconnected');
    } else {
      ParaviewRpc.zoomcamera(this.client, null, scale)
        .then((result: ParaviewRpc.CameraState) => {
          this.updateCameraState(result);
        }).catch((err: status.ParaviewError) => {
          addPvRpcError('Could not zoom the camera in Paraview', err);
        });
    }
  }

  /**
   * Rotate the camera. Axis is an enum that specifies the rotation axis.
   * ParaviewRpc.VIEW_UP (or VIEW_RIGHT) specifies the world-coord vector that
   * maps to the "up" (or right) direction on the screen. VIEW_TOWARD
   * specifies the world-coord vector that corresponds to the screen's normal
   * vector. Angle is specified in radians.
   */
  public rotateCamera(axis: ParaviewRpc.RotationAxisType, angle: number): void {
    if (!this.client) {
      logger.debug('rotateCamera: paraview disconnected');
    } else {
      ParaviewRpc.rotatecamera(this.client, null, axis, angle)
        .then((result: ParaviewRpc.CameraState) => {
          this.updateCameraState(result);
        }).catch((err: status.ParaviewError) => {
          addPvRpcError('Could not rotate the camera in Paraview', err);
        });
    }
  }

  public panCamera(direction: ParaviewRpc.PanDirectionType, amount: number): void {
    if (!this.client) {
      logger.debug('panCamera: paraview disconnected');
    } else {
      ParaviewRpc.pancamera(this.client, null, direction, amount)
        .then((result: ParaviewRpc.CameraState) => {
          this.updateCameraState(result);
        }).catch((err: status.ParaviewError) => {
          addPvRpcError('Could not change the camera position in Paraview', err);
        });
    }
  }

  // Changes the camera position and angle while keeping the zoom level unchanged.
  // ParallelProjection sets the camera's projection mode to either
  // perspective (false) or orthographic (true).
  // Args viewUp and cameraDirection must be either both null, or both set.
  // If they are null, the camera position and the focal point remain
  // unchanged.  viewUp sets the world-coord vector that corresponds to the
  // "up" direction on the screen. cameraDirection sets the direction of the
  // line that connects the camera's location and its focal point.
  public setCameraAngle(
    parallelProjection: boolean,
    viewUp: ParaviewRpc.Vector3 | null,
    cameraDirection: ParaviewRpc.Vector3 | null,
  ): void {
    if (!this.client) {
      logger.debug('setCameraAngle: paraview disconnected');
    } else {
      ParaviewRpc.setcameraangle(this.client, null, parallelProjection, viewUp, cameraDirection)
        .then((result: ParaviewRpc.CameraState) => {
          this.updateCameraState(result);
        }).catch((err: status.ParaviewError) => {
          addPvRpcError('Could not set the camera angle in Paraview', err);
        });
    }
  }

  // Implements Renderer.
  public getScreenVisibleDataRange(
    displayVariable: ParaviewRpc.DisplayPvVariable,
  ): Promise<[number, number] | null> {
    if (!this.client) {
      return Promise.reject(new Error('getScreenVisibleDataRange: disconnected'));
    }
    return ParaviewRpc.getscreenvisibledatarange(this.client, null, displayVariable);
  }

  // Implements Renderer.
  public getCellDataAtPoint(screenX: number, screenY: number): Promise<ParaviewRpc.CellState[]> {
    if (!this.client) {
      return Promise.reject(new Error('getCellDataAtPoint: disconnected'));
    }
    return ParaviewRpc.getcelldataatpoint(this.client, null, screenX, screenY);
  }

  public getSurfaceAtPoint(screenX: number, screenY: number): Promise<string> {
    if (!this.client) {
      return Promise.reject(new Error('getSurfaceAtPoint: disconnected'));
    }
    return ParaviewRpc.getsurfaceatpoint(this.client, null, screenX, screenY);
  }

  public showAxesGrid(show: boolean): Promise<ParaviewRpc.RpcResult> {
    if (!this.client) {
      return Promise.reject(new Error('showAxesGrid: disconnected'));
    }
    return ParaviewRpc.showgridaxes(this.client!, null, show);
  }

  public async stopMotionAnimation(): Promise<void> {
    if (!this.client) {
      return;
    }
    try {
      await this.client?.runRpc<void>('luminarycloud.animation.stop', []);
    } catch (err) {
      addPvRpcError('Could not stop motion animation', err);
    }
  }

  public handleMotionAnimation(
    param: ParaviewRpc.GridMotionParam,
    setStatusCardProps: (statusCardProps: StatusCardProps) => void,
    setMotionAnimation: (arg: boolean) => void,
    frameCallback: () => void = () => {},
  ): void {
    if (!this.client) {
      return;
    }
    // If any of the promises fails, we need to hide the status card. When cancelling the animation
    // we also need to cleanup the visualizer state.
    const onError = () => setStatusCardProps({ message: '', isVisible: false });
    const onCancel = () => {
      setStatusCardProps({ message: '', isVisible: false });
      this.client?.runRpc<void>('luminarycloud.animation.stop', []).then(
        () => ParaviewRpc.stopgridmotionsource(this.client!, null),
      ).catch((err: status.ParaviewError) => {
        addPvRpcError('Could not play motion animation', err);
      });
    };

    // Clear old coordinate axes that may be displayed on screen to avoid having two sets of
    // coordinate axes being represented on screen.
    ParaviewRpc.clearcoordinateaxes(this.client!).catch((reason: any) => {
      logger.error('Failed to clearcoordinateaxes: ', reason);
    });

    // The grid animation process is defined as follows:
    // 1. Generate the grid motion data source object in ParaView.
    // 2. Then (in the Promise sense) run the play animation.
    // 3. Then (in the Promise sense) subscribe to the stop event that is reported
    // by our custom ParaviewWeb protocol once the number of animation steps has
    // ended.
    // 4. When the stop event is signaled to the frontend, the grid motion source
    // is hidden via the stop motion RPC.
    // 5. Unsubscribe to the stop event (this is done automatically by subscribe).
    setStatusCardProps({
      message: 'Playing animation', isVisible: true, onCancel,
    });
    setMotionAnimation(true);
    ParaviewRpc.initializegridmotionsource(this.client!, null, param)
      .then(() => Promise.all([this.client!.subscribe(
        'pv.time.change',
        ({ timeStep }: { time: number, timeStep: number }) => {
          setStatusCardProps({
            message: 'Playing animation',
            isVisible: true,
            onCancel,
            // timeStep is reset to 0 when the animation finishes, so force it to be 100% when done
            progress: timeStep === 0 ? 1 : timeStep / param.numberOfSteps,
            transitionDuration: 200,
          });
        },
      ),
      // Start the animation after the VTK source has been built.
      this.client!.runRpc<void>('luminarycloud.animation.play', [this.client!.viewId]),
      ]))
      .then(([unsubscribeChange]) =>
        // Subscribe to the stop event.
        // eslint-disable-next-line implicit-arrow-linebreak
        this.client!.subscribeOne(
          'luminarycloud.animation.stopevent',
          (args: any[]) => {
            // Let the renderer handle any deletion/hide needed when the
            // animation stops.
            setMotionAnimation(false);
            ParaviewRpc.stopgridmotionsource(this.client!, null).then(() => {
              // Make sure to hide the status card.
              setStatusCardProps({ message: '', isVisible: false });
              return unsubscribeChange();
            }).catch((err: status.ParaviewError) => {
              onError();
              addPvRpcError('Could not play motion animation', err);
            });
            // Rerenders the frame axes after clearing them above if a motion node is selected
            frameCallback();
          },
        ))
      .catch((err: status.ParaviewError) => {
        onError();
        addPvRpcError('Could not play motion animation', err);
      });
  }

  public screenshot(text: string, transparentBackground: boolean): void {
    if (!this.client) {
      return;
    }
    ParaviewRpc.screenshot(this.client!, null, transparentBackground)
      .then((b64Bytes: string) => {
        const file = new File(
          [Uint8Array.from(atob(b64Bytes), (char) => char.charCodeAt(0))],
          `screenshot ${text}.png`,
        );
        return FileSaver.saveAs(file);
      })
      .catch((err: status.ParaviewError) => {
        addPvRpcError('Could not take screenshot', err);
      });
  }

  public registerOnUpdateWidgetHandler(
    fn: (state: ParaviewRpc.WidgetState) => void,
  ): Promise<() => void> {
    if (!this.client) {
      return Promise.reject(new Error('registerOnUpdateWidgetHandler: disconnected'));
    }
    return ParaviewRpc.onupdatewidget(this.client, fn);
  }

  public activateWidget(
    bounds: ParaviewRpc.Bounds,
    state: ParaviewRpc.WidgetState,
  ): void {
    if (!this.client) {
      return;
    }
    ParaviewRpc.activatewidget(this.client, bounds, state)
      .then((_: ParaviewRpc.RpcResult) => { })
      .catch((err: status.ParaviewError) => {
        addPvRpcError('Could not activate widget', err);
      });
  }

  public deleteWidget(): void {
    if (!this.client) {
      return;
    }
    ParaviewRpc.deletewidget(this.client)
      .then(() => { })
      .catch((err: status.ParaviewError) => {
        addPvRpcError('Could not delete widget', err);
      });
  }

  public setRefinementRegions(refinementRegions: Array<ParaviewRpc.RefinementRegionParam>): void {
    if (!this.client) {
      return;
    }
    ParaviewRpc.setrefinementregions(this.client, refinementRegions)
      .then(() => { })
      .catch((err: status.ParaviewError) => {
        logger.error('Error visualizing refinement regions: ', err);
      });
  }
}

export default ImageRenderer;
