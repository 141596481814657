// Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { RadioValue } from '../../../lib/componentTypes/form';
import { isUnmodifiedSpaceKey } from '../../../lib/event';

import './RadioButton.scss';

export interface RadioButtonProps<T extends RadioValue> {
  name: string;
  checked: boolean;
  value: T;
  onClick: () => void;
  disabled?: boolean;
}

export function RadioButton<T extends RadioValue>(props: RadioButtonProps<T>) {
  const { checked, disabled = false, name, onClick, value } = props;

  const enabled = !disabled;

  const attrs = enabled ? {
    onClick,
    onKeyUp: (event: KeyboardEvent | React.KeyboardEvent) => {
      if (isUnmodifiedSpaceKey(event)) {
        onClick();
      }
    },
    role: 'button',
    tabIndex: 0,
  } : {};

  return (
    <div
      className={cx('radioButton', { checked, disabled, enabled })}
      {...attrs}>
      <div className="input">
        <input
          defaultChecked={checked}
          disabled={disabled}
          name={name}
          tabIndex={-1}
          type="radio"
          value={`${value}`}
        />
      </div>
      <div className="display" />
    </div>
  );
}
