// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/*
  The ProbeOverlay component is a mostly transparent SVG canvas that sits on
  top of a Paraview canvas. It intercepts a mouse click, issues a cellatpoint
  RPC to paraview to get the cell information at the point, then prints the cell
  information on as a SVG text.
*/

import React, { useCallback, useState } from 'react';

import { EntityGroupMap } from '../../../lib/entityGroupMap';
import { getImposterId, isImposter } from '../../../lib/imposterFilteringUtils';
import { Logger } from '../../../lib/observability/logs';
import { traverseTreeNodes } from '../../../lib/paraviewUtils';
import { addRpcError } from '../../../lib/transientNotification';
import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import { useEntityGroupMap } from '../../../recoil/entityGroupState';
import { useProjectContext } from '../../context/ProjectContext';
import { useParaviewContext } from '../ParaviewManager';

import ClickableOverlay from './ClickableOverlay';

const logger = new Logger('Paraview/ProbeOverlay');

// Get the node corresponding to the surface/entity that was probed.
function findProbedNode(
  cell: ParaviewRpc.CellState,
  viewState: ParaviewRpc.ViewState | null,
): ParaviewRpc.TreeNode | undefined {
  if (!viewState) {
    return undefined;
  }
  let foundNode = viewState.root;
  const findTheClickedNode = (node: ParaviewRpc.TreeNode) => {
    if (node.name === cell.surfaceName) {
      foundNode = node;
    }
  };
  traverseTreeNodes(viewState.root, findTheClickedNode);
  return foundNode;
}

function drawReal(value: number): string {
  return `${value.toPrecision(4)}`;
}

function drawVector(vector: ParaviewRpc.Vector3): string {
  return `(${drawReal(vector.x)}, ${drawReal(vector.y)}, ${drawReal(vector.z)})`;
}

function drawData(
  key: string,
  x: number,
  y: number,
  metric: ParaviewRpc.CellMetric,
  anchor: 'start' | 'end',
) {
  let probeData = `${metric.name}`;

  if (metric.vector3) {
    const magnitude = Math.sqrt(
      metric.vector3.x ** 2 + metric.vector3.y ** 2 + metric.vector3.z ** 2,
    );
    probeData += ` : ${drawVector(metric.vector3)} , Magnitude: ${drawReal(magnitude)}`;
  } else {
    // Use drawReal
    probeData += ` : ${drawReal(metric.real!)}`;
  }

  return (
    <text
      fill="white"
      fontFamily="sans-serif"
      key={key}
      onClick={async () => {
        await navigator.clipboard.writeText(metric.real?.toString() ?? '');
      }}
      style={{ userSelect: 'text', cursor: 'copy' }}
      textAnchor={anchor}
      x={x}
      y={y}>
      {probeData}
    </text>
  );
}

const LINE_HEIGHT_PX = 20;

// Get the UI display name of a surface given its 'backend name'/id.
// If the entity isn't a surface, return nothing
const getEntityDisplayName = (nodeName: string | undefined, entityGroupMap: EntityGroupMap) => {
  if (nodeName && entityGroupMap.has(nodeName)) {
    return entityGroupMap.get(nodeName).name;
  }
  // If the node isn't in the entity group map, just return nodeName.
  return nodeName;
};

const drawCell = (
  cell: ParaviewRpc.CellState,
  viewState: ParaviewRpc.ViewState | null,
  containerWidth: number,
  entityGroupMap: EntityGroupMap,
) => {
  const key = `${cell.screenX}:${cell.screenY}`;
  // If the cell is close to the right edge of the PV window, right-align it to prevent overflow.
  const anchor = (cell.screenX > containerWidth * 0.75) ? 'end' : 'start';

  const probedNode = findProbedNode(cell, viewState);
  const displayVariableName = (
    probedNode?.displayProps?.displayVariable?.displayDataName ||
    viewState?.attrs.displayVariable?.displayDataName
  );

  const cellData = {
    ...cell,
    data: cell.data.filter((value) => value.name === displayVariableName),
  };
  const entityName = (probedNode && isImposter(probedNode)) ?
    getEntityDisplayName(getImposterId(probedNode)!, entityGroupMap) :
    getEntityDisplayName(cell.surfaceName, entityGroupMap);

  return (
    <React.Fragment key={key}>
      <text
        fill="white"
        fontFamily="sans-serif"
        key={key}
        style={{ userSelect: 'text' }}
        textAnchor={anchor}
        x={cellData.screenX}
        y={cellData.screenY}>
        {`Entity Name: ${entityName}`}
      </text>
      <text
        fill="white"
        fontFamily="sans-serif"
        key={key}
        style={{ userSelect: 'text' }}
        textAnchor={anchor}
        x={cellData.screenX}
        y={cellData.screenY + LINE_HEIGHT_PX}>
        {`x, y, z: ${drawVector(cellData.center)}`}
      </text>
      {cellData.data.map((metric, i) => drawData(
        `${key}:${i}`,
        cellData.screenX,
        cellData.screenY + (i + 2) * LINE_HEIGHT_PX,
        metric,
        anchor,
      ))}
    </React.Fragment>
  );
};

export interface ProbeOverlayProps {
  className: string;
}

const ProbeOverlay = (props: ProbeOverlayProps) => {
  const { className } = props;

  const { paraviewRenderer, viewState } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);

  const [cells, setCells] = useState<ParaviewRpc.CellState[]>([]);
  const [containerWidth, setContainerWidth] = useState(0);
  logger.debug(`Overlay: ${JSON.stringify(cells)}`);

  // Called on mouse click. (x,y) are screen coordinates.
  const onSelectPoint = useCallback((x: number, y: number, width: number, height: number) => {
    // Paraview's Y axis grows from bottom to top.
    const py = height - y;
    paraviewRenderer.getCellDataAtPoint(x, py).then((newCells: ParaviewRpc.CellState[]) => {
      // The Y axis grows bottom to top on the server, but top to bottom on
      // the client.
      const newCellStates = newCells.map((cell) => ({
        ...cell,
        screenY: y,
        screenX: x,
      }));
      setCells(newCellStates);
      setContainerWidth(width);
    }).catch((err: Error) => {
      addRpcError('Could not get solutions at point', err);
    });
  }, [paraviewRenderer, setCells, setContainerWidth]);

  return (
    <ClickableOverlay
      className={className}
      onClick={onSelectPoint}
      text="Select a point on a surface to probe. Press ESC to exit.">
      <g>
        {cells.map((cell) => drawCell(cell, viewState, containerWidth, entityGroupMap))}
      </g>
    </ClickableOverlay>
  );
};

export default ProbeOverlay;
