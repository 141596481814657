// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVType } from '@luminarycloudinternal/lcvis';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { colors, hexToRgbList } from '../../lib/designSystem';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';

const ARROW_COLOR = hexToRgbList(colors.probePointColor);

export type LcvisArrowStateType = {
  active: boolean;
  scale: number;
  // the coordinates of the point the probe touched.
  startCoordinates?: [number, number, number];
  endCoordinates?: [number, number, number];
}

const hideArrow = () => {
  const { display } = lcvHandler;
  display?.arrowList?.setParamAtIndex(0, 'visible', LCVType.kLCVDataTypeUint, 0);
};

const showArrow = (
  scale: number,
  startCoordinates?: [number, number, number],
  endCoordinates?: [number, number, number],
) => {
  const { display } = lcvHandler;
  if (!startCoordinates || !endCoordinates) {
    throw Error('Tried to show a probe point without valid coordinates');
  }
  display?.arrowList?.setParam('scale', LCVType.kLCVDataTypeFloat, scale);
  // Origin.
  display?.arrowList?.setParamAtIndex(0, 'points', LCVType.kLCVDataTypeFloat3, startCoordinates);
  // Direction that will be normalized and then scaled by the scale.
  display?.arrowList?.setParamAtIndex(0, 'directions', LCVType.kLCVDataTypeFloat3, endCoordinates);
  display?.arrowList?.setParamAtIndex(0, 'visible', LCVType.kLCVDataTypeUint, 1);
  display?.arrowList?.setParamAtIndex(0, 'colors', LCVType.kLCVDataTypeFloat3, ARROW_COLOR);
};

/**
 * The state of the lcvis arrow list.
 */
export const lcvisArrowState = atom<LcvisArrowStateType>({
  key: 'lcvisArrowState',
  default: {
    active: false,
    scale: 1,
  },
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        if (!newValue.active) {
          hideArrow();
          return;
        }
        showArrow(newValue.scale, newValue.startCoordinates, newValue.endCoordinates);
      });
    },
  ],
});

export const useLcvisArrowState = () => useRecoilState(lcvisArrowState);

export const useSetLcvisArrowState = () => useSetRecoilState(lcvisArrowState);

export const useLcvisArrowValue = () => useRecoilValue(lcvisArrowState);
