// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import cx from 'classnames';

import Tooltip from '../../Tooltip';

import './FormControlLabel.scss';

export interface FormControlLabelProps {
  label: string;
  help?: string;
  disabled?: boolean;
  alignItems?: 'center';
  children: ReactNode;
}

const FormControlLabel = (props: FormControlLabelProps) => {
  const { alignItems = '', disabled, label, help } = props;

  return (
    <div className={cx('formControlLabel', { disabled })} style={{ alignItems }}>
      <div className="control">
        {props.children}
      </div>
      <div className="label">
        <Tooltip title={help || ''}><div className="labelText">{label}</div></Tooltip>
      </div>
    </div>
  );
};

export default FormControlLabel;
