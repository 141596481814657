// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import { colors } from '../../../lib/designSystem';
import { isUnmodifiedEnterKey } from '../../../lib/event';
import { formatDateRelative } from '../../../lib/formatDate';
import { Notification, getNotificationIcon } from '../../../lib/notificationUtils';
import { useNotificationReadState } from '../../../recoil/useNotifications';
import { ActionButton } from '../../Button/ActionButton';
import { SvgIcon } from '../../Icon/SvgIcon';
import { createStyles, makeStyles } from '../../Theme';
import { DotToggle } from '../../controls/DotToggle';

import { NotificationMessage } from './messages/NotificationMessage';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      padding: '16px 8px',
      width: '350px',
      cursor: 'pointer',
      transition: 'background-color 0.1s',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'flex-start',
    },
    hovered: {
      backgroundColor: colors.neutral150,
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '16px',
      width: '16px',
    },
    body: {
      width: '254px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    timestamp: {
      color: colors.neutral650,
      fontSize: '13px',
      lineHeight: '16px',
      paddingBottom: '8px',
    },
    message: {
      fontSize: '13px',
      lineHeight: '16px',
      '&.read': {
        color: colors.lowEmphasisText,
      },
      '&.unread': {
        color: colors.highEmphasisText,
      },
    },
  }),
  { name: 'NotificationCard' },
);

interface NotificationCardProps {
  notification: Notification;
}

export const NotificationCard = (props: NotificationCardProps) => {
  const { notification } = props;
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();

  const [read, setRead] = useNotificationReadState(notification.id);

  const onClickCard = () => {
    setRead(true);
    if (notification.implicitLink) {
      navigate(notification.implicitLink);
    } else {
      notification.implicitAction?.();
    }
  };

  const onMouseEnter = () => setHovered(true);
  const onMouseLeave = () => setHovered(false);
  return (
    <div
      className={cx(
        classes.root,
        hovered ? classes.hovered : '',
      )}
      data-level={`notification-${notification.message.type}`}
      data-locator="notificationCard"
      onClick={onClickCard}
      onKeyUp={(ev) => {
        if (isUnmodifiedEnterKey(ev)) {
          onClickCard();
        }
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role="button"
      tabIndex={0}>
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          <SvgIcon
            color={colors.lowEmphasisText}
            maxHeight={12}
            maxWidth={12}
            name={getNotificationIcon(notification.message.type)}
          />
        </div>
        <div className={classes.body}>
          <div className={cx(classes.message, read ? 'read' : 'unread')}>
            <NotificationMessage {...notification.message} read={read} />
          </div>
          <div className={classes.timestamp}>
            {formatDateRelative(notification.timestamp)}
          </div>
          {!!notification.namedAction && (
            <ActionButton
              asBlock
              kind={read ? 'secondary' : 'primary'}
              onClick={(event) => {
                event.stopPropagation();
                setRead(true);
                if (notification.namedAction?.link) {
                  navigate(notification.namedAction.link);
                } else {
                  notification.namedAction?.onClick?.();
                }
              }}
              size="small">
              {notification.namedAction.name}
            </ActionButton>
          )}
        </div>
        <DotToggle
          borderVisible={hovered}
          onChange={((value) => setRead(!value))}
          tooltipTitle={!read ? 'Mark as read' : 'Mark as unread'}
          value={!read}
        />
      </div>
    </div>
  );
};
