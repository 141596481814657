// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as simulationpb from '../proto/client/simulation_pb';

import newInt from './intUtils';

/**
 * Turn off all tabular profiles in the sim param that are transient.
 *
 * Heat boundary conditions and heat sources may have transient tabular profiles that are not
 * compatible with a steady state simulation.  These must be turned off when the project becomes
 * steady state.
 *
 * @param simParam the sim param to modify
 * @returns the modified sim param
 */
export function disableTransientTabularProfile(simParam: simulationpb.SimulationParam) {
  simParam.physics.forEach((physics) => {
    if (physics.params.case === 'heat') {
      const heat = physics.params.value;
      heat.boundaryConditionsHeat.forEach((boundaryCondition) => {
        boundaryCondition.profileBc = false;
        boundaryCondition.heatFluxCol = newInt(0);
      });
      heat.heatSource.forEach((heatSource) => {
        heatSource.profileSource = false;
        heatSource.heatSourceCol = newInt(0);
      });
    }
  });
  return simParam;
}
