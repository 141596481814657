// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import { isUnmodifiedSpaceKey } from '../../lib/event';
import { useUserCanEdit } from '../../lib/projectRoles';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { useCreateExploration } from '../hooks/exploration/useCreateExploration';

const useStyles = makeStyles(
  () => createStyles({
    panel: {
      fontSize: '13px',
      margin: '14px 16px',
    },
    title: {
      fontSize: '14px',
    },
    subtitle: {
      color: colors.lowEmphasisText,
      margin: '18px 0',
    },
    divider: {
      background: colors.neutral450,
      marginTop: '12px',
      height: '2px',
      width: '100%',
    },
    creationPanel: {
      height: '166px',
      width: '100%',
      marginTop: '16px',
      cursor: 'pointer',
      borderRadius: '4px',
      border: '2px solid transparent',
      display: 'flex',
      alignItems: 'stretch',
      transition: 'border 500ms',
      '&.disabled': {
        cursor: 'not-allowed',
      },
      '&:not(.disabled):hover': {
        borderColor: colors.primaryCta,
      },
    },
    image: {
      flex: '0 0 60px',
      background: colors.purple100,
      borderRadius: '4px 0 0 4px',
    },
    creationTextPanel: {
      flex: '1 1 auto',
      background: colors.surfaceDark3,
      borderRadius: '0 4px 4px 0',
    },
    creationText: {
      height: '166px',
      padding: '13px',
      position: 'relative',
    },
    subheader: {
      color: colors.neutral650,
      marginTop: '6px',
    },
  }),
  { name: 'ExplorationSelection' },
);

interface CreationPanelProps {
  // Whether the panel can be selected and opened (it is disabled for read-only projects)
  disabled: boolean
  // The panel heading.
  heading: string;
  // The panel subheading.
  subheading: string;
  // Function called when one of the panels is selected.
  onSelect: () => void;
}

// A panel for creating an exploration.
const CreationPanel = (props: CreationPanelProps) => {
  const classes = useStyles();

  const handleSelect = () => {
    if (props.disabled) {
      return;
    }
    props.onSelect();
  };

  return (
    <div
      className={cx(classes.creationPanel, { disabled: props.disabled })}
      onClick={handleSelect}
      onKeyUp={(event) => {
        if (isUnmodifiedSpaceKey(event)) {
          handleSelect();
        }
      }}
      role="button"
      tabIndex={0}>
      <div className={classes.image} />
      <div className={classes.creationTextPanel}>
        <div className={classes.creationText}>
          <div className={classes.title}>
            {props.heading}
          </div>
          <div className={classes.subheader}>
            {props.subheading}
          </div>
        </div>
      </div>
    </div>
  );
};

// A panel for creating and selecting an exploration.
const ExplorationSelection = () => {
  const classes = useStyles();
  const { projectId } = useProjectContext();
  const projectMetadata = useProjectMetadata(projectId);
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);
  const selectExploration = useCreateExploration();

  // The creation panels should be disabled for read-only projects which don't have explorations
  const hasExplorations = projectMetadata?.workflow.some((workflow) => (
    !!workflow.config?.exploration?.var.length
  ));
  const disablePanel = !userCanEdit && !hasExplorations;

  const sensitivityDesc = 'Gain insight into the relationship between inputs ' +
    'and outputs from one simulation.';
  const doeDesc = 'Explore a full parameter space by running many ' +
    'simulations at once.';

  return (
    <div className={classes.panel}>
      <div className={classes.title}>
        Select an Exploration
      </div>
      <div className={classes.subtitle}>
        Create an exploration to understand how to improve your design
      </div>
      <div className={classes.divider} />
      <CreationPanel
        disabled={disablePanel}
        heading="Local Sensitivity Model"
        onSelect={() => selectExploration('sensitivityAnalysis')}
        subheading={sensitivityDesc}
      />
      <CreationPanel
        disabled={disablePanel}
        heading="Design of Experiments"
        onSelect={() => selectExploration('gridSearch')}
        subheading={doeDesc}
      />
    </div>
  );
};

export default ExplorationSelection;
