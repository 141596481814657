// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect } from 'react';

import * as Vector from '../../../../lib/Vector';
import { Logger } from '../../../../lib/observability/logs';
import { addRpcError } from '../../../../lib/transientNotification';
import { EditSource } from '../../../../lib/visUtils';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useEditState } from '../../../../recoil/paraviewState';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import { PlaneInput, PlaneParam } from '../../../visFilter/PlaneInput';
import { useSelectedFilterNode } from '../../../visFilter/useFilterNode';
import { FilterEditControl } from '../../FilterEditControl';
import PropertiesSection from '../../PropertiesSection';
import { FilterDisplayPanel } from '../shared/FilterDisplayPanel';

import { FilterPropertiesPanelProps } from './props';

const logger = new Logger('filter/IntersectionCurve');

function newPlaneParam(bounds: ParaviewRpc.Bounds): ParaviewRpc.PlaneParam {
  return {
    typ: 'Plane',
    // The initial center is the bounds center.
    origin: {
      x: 0.5 * (bounds[1] + bounds[0]),
      y: 0.5 * (bounds[3] + bounds[2]),
      z: 0.5 * (bounds[5] + bounds[4]),
    },
    // The default normal is the YZ plane.
    normal: { x: 1, y: 0, z: 0 },
  };
}

export function newIntersectionCurveParam(
  bounds: ParaviewRpc.Bounds,
): ParaviewRpc.IntersectionCurveParam {
  return {
    typ: ParaviewRpc.TreeNodeType.INTERSECTION_CURVE,
    plane: newPlaneParam(bounds),
  };
}

// Panel for displaying and modifying an intersection curve filter.
export const IntersectionCurvePropPanel = (props: FilterPropertiesPanelProps) => {
  const { displayProps, filterNode, nodeId } = props;
  const {
    getDataVisibilityBounds,
    paraviewClientState,
    paraviewMeshMetadata,
    paraviewRenderer,
  } = useParaviewContext();

  const [editState] = useEditState();
  const paraviewClient = paraviewClientState.client;
  const editSource = editState ? editState.editSource : EditSource.FORM;
  const { updateEditState } = useSelectedFilterNode();

  const onUpdate = (source: EditSource, newParam: ParaviewRpc.TreeNodeParam) => {
    updateEditState({
      editSource: source,
      param: newParam,
    });
  };
  const param = props.param as ParaviewRpc.IntersectionCurveParam;

  useEffect(() => {
    if (!editState) {
      return () => { };
    }

    let mounted = true;
    let unsubscribeFn: (() => void) | null = null;

    // Called when the user moves the widget interactively.
    const onPlaneWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION) {
        throw Error('only plane widget supported now');
      }
      if (!mounted) {
        return;
      }
      const planeParams = param.plane as ParaviewRpc.PlaneParam;
      if (
        !Vector.nearPv(newParam.plane.origin, planeParams.origin) ||
        !Vector.nearPv(newParam.plane.normal, planeParams.normal)
      ) {
        onUpdate(
          EditSource.PARAVIEW,
          {
            ...param,
            plane: {
              typ: 'Plane',
              origin: newParam.plane.origin,
              normal: newParam.plane.normal,
            },
          },
        );
      }
    };
    const startWidget = async (): Promise<void> => {
      if (!paraviewClient || !paraviewMeshMetadata) {
        return;
      }
      // Register the widget update handler.
      unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onPlaneWidgetUpdate);
      // Activate the widget.
      paraviewRenderer.activateWidget(
        getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
        {
          typ: ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
          plane: param.plane as ParaviewRpc.PlaneParam,
          bounds: getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
        },
      );
    };

    startWidget().then(() => { }).catch((err: Error) => {
      addRpcError('Could not activate widget', err);
    });
    return () => {
      mounted = false;
      if (unsubscribeFn) {
        unsubscribeFn();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!editState, nodeId]);

  useEffect(() => {
    if (editState && editSource !== EditSource.PARAVIEW && paraviewMeshMetadata) {
      logger.debug('Intersection Curve: set widget');
      // When the user manually updates the plane dialog, reflect the new plane
      // params to the widget.
      const ws: ParaviewRpc.ImplicitPlaneWidgetState = {
        typ: ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
        plane: param.plane as ParaviewRpc.PlaneParam,
        bounds: getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
      };
      paraviewRenderer.activateWidget(
        getDataVisibilityBounds(
          paraviewMeshMetadata.meshMetadata,
        ),
        ws,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editState, editSource, param, param.plane, nodeId]);

  return (
    <div>
      <FilterDisplayPanel filterNode={filterNode} />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={!!editState}
          expandWhenDisabled
          headerRight={(
            <FilterEditControl
              displayProps={displayProps}
              nodeId={nodeId}
              param={param}
            />
          )}
          heading="Visualization Input"
          nodeId={nodeId}
          panelName="input">
          <div>
            <PlaneInput
              onCommit={(newParam: PlaneParam) => onUpdate(
                EditSource.FORM,
                {
                  ...param,
                  plane: {
                    typ: 'Plane',
                    origin: Vector.toPvProto(newParam.origin),
                    normal: Vector.toPvProto(newParam.normal),
                  },
                },
              )}
              param={{
                origin: Vector.toProto((param.plane as ParaviewRpc.PlaneParam).origin),
                normal: Vector.toProto((param.plane as ParaviewRpc.PlaneParam).normal),
              }}
              readOnly={!editState}
            />
          </div>
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
