import { useCallback, useMemo } from 'react';

import { rollupGroups } from '../../../lib/entityGroupUtils';
import { assignSurfaceToBoundaryLayer, boundaryHeading, conflictingMeshBoundaryLayerSurfaces } from '../../../lib/mesh';
import { getOrCreateAdaptiveMeshRefinement } from '../../../lib/simulationParamUtils';
import { NodeType } from '../../../lib/simulationTree/node';
import { wordsToList } from '../../../lib/text';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { useProjectContext } from '../../context/ProjectContext';
import { useSimulationConfig } from '../useSimulationConfig';

const AVAILABLE_NODE_TYPES = [
  NodeType.SURFACE, NodeType.SURFACE_GROUP, NodeType.TAGS_CONTAINER, NodeType.TAGS_FACE,
];

/** Displays the first N conflicting items in the tooltip, followed by "and more" if there are
  * additional conflicts.
  */
const MAX_ITEMS_IN_TOOLTIP = 3;

export const useAdaptationBoundarySelection = ({ boundaryIndex }: { boundaryIndex: number }) => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const { simParam, saveParamAsync } = useSimulationConfig();
  const geometryTags = useGeometryTags(projectId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (!AVAILABLE_NODE_TYPES.includes(nodeType)) {
      return { related: false, disabled: true };
    }

    const conflictingLayerIndexes = conflictingMeshBoundaryLayerSurfaces(
      boundaryIndex,
      [nodeId],
      simParam.adaptiveMeshRefinement?.boundaryLayerProfile ?? [],
      geometryTags,
      entityGroupData,
    );

    const conflictingLayerNames = conflictingLayerIndexes.map(boundaryHeading);
    const shortenedLayerNames = conflictingLayerNames.length > MAX_ITEMS_IN_TOOLTIP ?
      [...conflictingLayerNames.slice(0, MAX_ITEMS_IN_TOOLTIP - 1), 'more'] :
      conflictingLayerNames;

    return {
      related: true,
      disabled: false,
      tooltip: conflictingLayerNames.length > 0 ?
        `This will remove some surfaces from ${wordsToList(shortenedLayerNames)}` :
        '',
    };
  }, [
    boundaryIndex,
    entityGroupData,
    geometryTags,
    simParam.adaptiveMeshRefinement?.boundaryLayerProfile,
  ]);

  const setSurfaces = useCallback(async (newSelection: string[]) => {
    await saveParamAsync((param) => {
      const amr = getOrCreateAdaptiveMeshRefinement(param);

      assignSurfaceToBoundaryLayer(
        amr.boundaryLayerProfile,
        boundaryIndex,
        newSelection,
        geometryTags,
        entityGroupData,
      );
    });
  }, [boundaryIndex, entityGroupData, geometryTags, saveParamAsync]);

  const rollup = useMemo(() => rollupGroups(entityGroupData), [entityGroupData]);
  const nodeIds = useMemo(
    () => (
      rollup(simParam.adaptiveMeshRefinement?.boundaryLayerProfile[boundaryIndex]?.surfaces || [])
    ),
    [boundaryIndex, rollup, simParam.adaptiveMeshRefinement?.boundaryLayerProfile],
  );

  return { nodeFilter, setSurfaces, nodeIds };
};
