// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, selectorFamily, useRecoilValue } from 'recoil';

import { surfacesFromVolumes } from '../lib/volumeUtils';

import { staticVolumesState } from './volumes';

// Represents the atom state for mesh-derived surfaces
export const meshSurfacesState = atomFamily<string[], string>({
  key: 'meshSurfacesState',
  default: selectorFamily<string[], string>({
    key: 'meshSurfacesState/Default',
    get: (projectId: string) => ({ get }) => {
      const volumes = get(staticVolumesState(projectId));
      return surfacesFromVolumes(volumes);
    },
  }),
});

export function useMeshSurfaces(projectId: string) {
  return useRecoilValue(meshSurfacesState(projectId));
}
