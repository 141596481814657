// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const FilterIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="filter"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M22.27,0
           L1.713,0
           C0.771,0 0,0.771 0,1.713
           C0,2.193 0.188,2.621 0.497,2.929
           L8.565,10.998
           L8.565,22.287
           C8.565,23.229 9.336,24 10.278,24
           C10.758,24 11.186,23.812 11.495,23.503
           L14.921,20.077
           C15.229,19.769 15.418,19.34 15.418,18.861
           L15.418,10.998
           L23.486,2.929
           C23.795,2.621 23.983,2.193 23.983,1.713
           C23.983,0.771 23.212,0 22.27,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
