// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import {
  getQuantityChildren,
  getQuantityName,
  getQuantityTags,
  getQuantityText,
} from '../QuantityDescriptor';
import * as outputpb from '../proto/output/output_pb';
import { QuantityTag } from '../proto/quantity/quantity_options_pb';
import { QuantityType } from '../proto/quantity/quantity_pb';

import { newNodeId } from './projectDataUtils';

// Identifies a particular output graph.
export interface OutputGraphId {
  // The ID for the output node.
  node: string;
  // Identifies the particular graph for a given output node.
  // One output node can have multiple graphs.
  graphIndex: number;
}

// Converts the given output to an output with the appropriate coefficient.
export function getOutputCoefficient(output: outputpb.Output): outputpb.Output {
  const outputCoefficient = output.clone();
  // Find the children of this quantity that is tagged as coefficient
  const child = getQuantityChildren(outputCoefficient.quantity);
  const coeff = child.find(
    (quantityType) => getQuantityTags(quantityType).includes(QuantityTag.TAG_COEFFICIENT),
  );
  if (coeff === undefined) {
    throw Error(
      `Coefficient value not found for quantity ${getQuantityName(outputCoefficient.quantity)}`,
    );
  }
  outputCoefficient.quantity = coeff;
  return outputCoefficient;
}

export function newBasicOutput(quantity: QuantityType, series: boolean, innerIters = false) {
  return new outputpb.Output({
    id: newNodeId(),
    name: getQuantityText(quantity),
    innerIters,
    outputProperties: { case: 'basicProperties', value: new outputpb.BasicType() },
    quantity,
    timeAnalysis: series ?
      outputpb.TimeAnalysisType.TIME_SERIES : outputpb.TimeAnalysisType.TIME_AVERAGE,
  });
}
