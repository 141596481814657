// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { Fragment, ReactNode } from 'react';

import * as d3 from 'd3';
import { ScaleLinear } from 'd3-scale';

import { colors } from '../lib/designSystem';
import * as outputpb from '../proto/output/output_pb';

// Step is either the solver iteration count (for steady-state simulation) or
// timestamp (for transient simulation).
type Step = number;

export interface TimeBarSettings {
  // The (closed) range of iterations over which to plot inner iteration residuals.
  // If null, a single iteration at currentIndex is chosen.
  innerItersWindow: outputpb.IterationRange | null
  // Currently selected solution. Index into steps. <0 if no solution is selected.
  currentIndex: number;
  // Set the current index.
  setIndex: (index: number) => void;
  // List of solutions produced by the solver.
  steps: Step[];
  // Solution iterations generated by the solver.
  iters: Step[];
  // If this this a transient simulation.
  isTransient: boolean;
  // The number of decimals to display after the decimal point.
  fixedPrecision: number;
  // Range of available iterations
  availableIterations: outputpb.IterationRange | null;
  // Swap the x and y axes.
  swapAxes: () => void;
  // If we are displaying an XY chart
  xyChart: boolean;
  // The X and Y headers of the XY chart, if applicable, to use in the exported csv header
  // The values here should only be undefined if a monitor plot is selected
  xyChartHeader?: { x: string | undefined, y: string | undefined };
  // True if current chart displays inner iteration residuals.
  hasInnerIters: boolean;
}

interface MarkerProps {
  // Translates the domain [0,maxStep] to the pixel range [minX, minX+width].
  //
  // REQUIRES: xScale's domain must be [0, maxStep], and range must be [minX, minX+width].
  xScale: ScaleLinear<number, number>,

  // currently selected soln iteration. Index into steps. <0 if not selected.
  currentIndex: number;
  // Solution iterations, generated by the solver.
  iters: Step[];
  // The marker opacity.
  opacity: number;
}

// Draw the main marker and markers for individual solutions.
const Marker = (props: MarkerProps) => {
  // Draw a marker for every solution iteration produced.
  const content: ReactNode[] = props.iters.map((step) => {
    const x = props.xScale(step);
    return (
      <line
        key={`marker${step}`}
        stroke={colors.neutral750}
        strokeDasharray="1,4"
        x1={x}
        x2={x}
        y1={0}
        y2={23}
      />
    );
  });

  // Mark the currently picked iteration, if any.
  if (props.currentIndex != null && props.currentIndex >= 0) {
    const x = props.xScale(props.iters[props.currentIndex]);
    content.push(
      <rect
        fill={colors.citronGreen600}
        height="23"
        key="time-marker-top"
        opacity={props.opacity}
        rx="5"
        width="5"
        x={x - 2.5}
        y={0}
      />,
    );
  }
  return (
    <g>
      {content}
    </g>
  );
};

export interface Props {
  // (Fixed) height of the canvas in pixels.
  height: number;
  // The main settings for the time bar.
  settings: TimeBarSettings;
  // How the x-values are scaled.
  xScale: ScaleLinear<number, number>;
  // The opacity of the marker for the current time.
  markerOpacity: number;
}

export interface NearestValue {
  // The index of the nearest value. This is -1 if there is no nearest value.
  index: number;
  // The value at the index.
  value?: number;
}

// Find the index of the element closest to "step".
// Assumes steps array is sorted in ascending order
export function pickNearestIndex(steps: Step[], step: Step): number {
  const index = d3.bisectLeft(steps, step);
  if (index === undefined) {
    return -1;
  }
  if (index > 0) {
    const left = steps[index - 1];
    const right = steps[index];
    if (step < (left + right) / 2 || index >= steps.length) {
      return index - 1;
    }
  }
  return index;
}

// Find the index and value of the element closest to "step".
export function pickNearestStep(steps: Step[], step: Step): NearestValue {
  const index = pickNearestIndex(steps, step);
  if (index < 0 || index >= steps.length) {
    return { index };
  }
  return { index, value: steps[index] };
}

// Find the index and value of the element closest to "step" for a 2D array.
export function pickNearestStep2D(steps2D: Step[][], step: Step): NearestValue {
  let nearest: NearestValue = { index: -1 };
  let nearestDistance = Infinity;
  steps2D.forEach((steps, index) => {
    const nearest1D = pickNearestStep(steps, step);
    if (nearest1D.index >= 0 && nearest1D.value !== undefined) {
      const distance = Math.abs(step - nearest1D.value);
      if (distance < nearestDistance) {
        nearest = { index, value: nearest1D.value };
        nearestDistance = distance;
      }
    }
  });
  return nearest;
}

const TimeBar = (props: Props) => {
  const {
    currentIndex,
    iters,
  } = props.settings;

  return (
    <div>
      <svg
        height={props.height}
        preserveAspectRatio="none"
        width="100%">
        <Fragment>
          <Marker
            currentIndex={currentIndex}
            iters={iters}
            opacity={props.markerOpacity}
            xScale={props.xScale}
          />
        </Fragment>
      </svg>
    </div>
  );
};

export default TimeBar;
