// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useRef } from 'react';

import cx from 'classnames';

import { adVec3ToVec3, vec3ToAdVec3 } from '../../../lib/adUtils';
import { FaultType } from '../../../lib/componentTypes/form';
import { VECTOR3_BREAK_POINT_WIDTH, checkBreakpointVertical } from '../../../lib/layoutUtils';
import useResizeObserver from '../../../lib/useResizeObserver';
import * as basepb from '../../../proto/base/base_pb';
import { QuantityType } from '../../../proto/quantity/quantity_pb';
import QuantityAdornment from '../../QuantityAdornment';
import { AxisAdornment } from '../../visual/AxisAdornment';
import { NumberInput } from '../NumberInput';

import './Vector3Input.scss';

type Axis = 'x' | 'y' | 'z';

export type Vec3FaultsType = { x?: FaultType, y?: FaultType, z?: FaultType };

export interface CoreProps<T extends basepb.AdVector3 | basepb.Vector3> {
  // The value to display
  value: T;
  // Can the value be modified by the user? Default false.
  disabled?: boolean;
  // Called when one of the scalars is committed
  onCommit: (value: T) => void;
  // Called when one of the scalars changes
  onChange?: (value: T) => void;
  // When true, present inputs in a vertical stack.
  vertical?: boolean;
  // Optional quantity to show after each input
  quantityType?: QuantityType;
  // If true, show an axis label before each input
  showAxisLabel?: boolean;
  // Fault types for each axis
  faultTypes?: Vec3FaultsType;
}

export type Vector3InputProps = CoreProps<basepb.Vector3>;

export const Vector3Input = (props: Vector3InputProps) => {
  // Props
  const {
    onChange,
    onCommit,
    disabled,
    quantityType,
    showAxisLabel = true,
    value,
    faultTypes,
  } = props;

  // Hooks
  const ref = useRef<HTMLDivElement>(null);
  const domSize = useResizeObserver(ref, { refreshPeriod: 0 });

  // Derived state
  const vertical = checkBreakpointVertical(
    props.vertical,
    domSize.width,
    VECTOR3_BREAK_POINT_WIDTH,
  );
  const endAdornment = quantityType ? <QuantityAdornment quantity={quantityType} /> : null;

  // Handlers
  const updateVector = (numValue: number, axis: Axis) => {
    const newVector = value.clone();
    switch (axis) {
      case 'x': {
        newVector.x = numValue;
        break;
      }
      case 'y': {
        newVector.y = numValue;
        break;
      }
      case 'z': {
        newVector.z = numValue;
        break;
      }
      default: {
        // no default - type exhausted
      }
    }
    return newVector;
  };

  const commitScalar = (numValue: number, axis: Axis) => {
    onCommit(updateVector(numValue, axis));
  };

  const changeScalar = (numValue: number, axis: Axis) => {
    onChange?.(updateVector(numValue, axis));
  };

  // We use a custom paste handler because we sometimes allow a user
  // to copy coordinates in the form of a JSON array [x, y, z], and
  // if they then paste into a Vector input, we want to correctly
  // populate all 3 inputs.
  const handlePaste = (event: React.ClipboardEvent) => {
    const pastedData = event.clipboardData.getData('Text');
    let parsedObj: any;
    try {
      parsedObj = JSON.parse(pastedData);
    } catch {
      // Not valid JSON, fall back to default paste handler.
      return;
    }
    if (!Array.isArray(parsedObj) || parsedObj.length !== 3) {
      // Not an array of length 3, fall back to default.
      return;
    }
    // We have an array of length 3, so let's split it up into the inputs!
    event.preventDefault();
    const [x, y, z] = parsedObj;
    onCommit(new basepb.Vector3({ x, y, z }));
  };

  return (
    <div className={cx('vector3Input', { vertical })} ref={ref}>
      <NumberInput
        asBlock
        disabled={disabled}
        endAdornment={endAdornment}
        faultType={faultTypes?.x}
        onChange={(numValue) => changeScalar(numValue, 'x')}
        onCommit={(numValue) => commitScalar(numValue, 'x')}
        onPaste={handlePaste}
        size="small"
        startAdornment={showAxisLabel ? (<AxisAdornment axis="x" />) : ''}
        value={value.x}
      />
      <NumberInput
        asBlock
        disabled={disabled}
        endAdornment={endAdornment}
        faultType={faultTypes?.y}
        onChange={(numValue) => changeScalar(numValue, 'y')}
        onCommit={(numValue) => commitScalar(numValue, 'y')}
        onPaste={handlePaste}
        size="small"
        startAdornment={showAxisLabel ? (<AxisAdornment axis="y" />) : ''}
        value={value.y}
      />
      <NumberInput
        asBlock
        disabled={disabled}
        endAdornment={endAdornment}
        faultType={faultTypes?.z}
        onChange={(numValue) => changeScalar(numValue, 'z')}
        onCommit={(numValue) => commitScalar(numValue, 'z')}
        onPaste={handlePaste}
        size="small"
        startAdornment={showAxisLabel ? (<AxisAdornment axis="z" />) : ''}
        value={value.z}
      />
    </div>
  );
};

export type AdVector3InputProps = CoreProps<basepb.AdVector3>;
export const AdVector3Input = (props: AdVector3InputProps) => {
  const { onChange, onCommit, value, ...vectorInputs } = props;

  return (
    <Vector3Input
      {...vectorInputs}
      onChange={(vec3: basepb.Vector3) => onChange?.(vec3ToAdVec3(vec3))}
      onCommit={(vec3: basepb.Vector3) => onCommit(vec3ToAdVec3(vec3))}
      value={adVec3ToVec3(props.value)}
    />
  );
};
