// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { AdFloatType } from '../proto/base/base_pb';
import * as feoutputpb from '../proto/frontend/output/output_pb';

import { getAdValue } from './adUtils';

// Represents the range and center of an axis.
export interface AxisRange {
  min: number,
  center: number,
  max: number
}

// Get the range of the y-axis from the computed outputs.
export function getYRange(outputIndex: number, computedOutputs: AdFloatType[][]): AxisRange {
  // Get the center value from the first column. The center value should be
  // the same for every column.
  let yCenter = 0;
  if (computedOutputs.length > 0 && outputIndex < computedOutputs[0].length) {
    yCenter = getAdValue(computedOutputs[0][outputIndex]);
  }
  if (Number.isNaN(yCenter)) {
    yCenter = 0;
  }
  if (yCenter !== 0) {
    return {
      min: 0.9 * yCenter,
      center: yCenter,
      max: 1.1 * yCenter,
    };
  }
  return {
    min: -0.1,
    center: 0,
    max: 0.1,
  };
}

export const disabledOutputCategories: feoutputpb.OutputNode['nodeProps']['case'][] = [
  'residual',
  'convMon',
];

// Get the derivative from the computed outputs.
export function getDerivative(
  inputIndex: number,
  outputIndex: number,
  computedOutputs: AdFloatType[][],
): number {
  let derivative = 0;
  if (
    inputIndex < computedOutputs.length &&
    outputIndex < computedOutputs[inputIndex].length
  ) {
    const computed = computedOutputs[inputIndex][outputIndex];
    if (computed.adTypes.case === 'firstOrder') {
      [derivative] = computed.adTypes.value.tangent;
    }
  }
  if (Number.isNaN(derivative)) {
    derivative = 0;
  }
  return derivative;
}
