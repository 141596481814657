// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 23;
const baseHeight = 19;
export const ParallelogramOutlinedIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="parallelogramOutlined"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M1.548,18.968
           C1.07,18.968 0.62,18.74 0.338,18.354
           C0.055,17.969 -0.026,17.471 0.118,17.015
           C1.377,13.035 4.203,4.102 5.169,1.048
           C5.366,0.424 5.945,0 6.599,0
           L21.453,0
           C21.931,0 22.38,0.228 22.663,0.614
           C22.945,0.999 23.027,1.497 22.883,1.952
           C21.624,5.932 18.798,14.865 17.832,17.92
           C17.634,18.544 17.056,18.968 16.401,18.968
           L1.548,18.968Z
           M15.491,16.468
           L3.091,16.468
           L7.52,2.468
           L19.92,2.468
           L15.491,16.468Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
