// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as entitygrouppb from '../proto/entitygroup/entitygroup_pb';
import { EntityGroupData } from '../recoil/entityGroupState';

import { CurrentView } from './componentTypes/context';
import { EntityGroup, EntityGroupMap } from './entityGroupMap';
import { isFarfield, isFarfieldChild } from './farfieldUtils';
import { intersects } from './lang';
import { NodeGroup, NodeGroupData } from './nodeGroupMap';
import { NodeType, SimulationTreeNode } from './simulationTree/node';

type GroupType = EntityGroup | NodeGroup;
type GroupMapDataType = EntityGroupData | NodeGroupData;

// Entity types that may be grouped
const groupableEntityTypes = [
  entitygrouppb.EntityType.SURFACE,
  entitygrouppb.EntityType.MIXED,
  entitygrouppb.EntityType.PARTICLE_GROUP,
];

// Returns true if the group map item is an EntityGroup type
function isEntityGroup(node: GroupType): node is EntityGroup {
  return (node as EntityGroup).entityType !== undefined;
}

// Returns true if the group map item is a NodeGroup type
function isNodeGroup(node: GroupType): node is NodeGroup {
  return (node as NodeGroup).nodeType !== undefined;
}

function isNodeTypeUngroupable(node: SimulationTreeNode) {
  switch (node.type) {
    case NodeType.CAMERA:
      return node.parent?.type === NodeType.CAMERA_GROUP;
    case NodeType.PARTICLE_GROUP:
    case NodeType.SURFACE:
      return node.parent?.type === NodeType.SURFACE_GROUP;
    case NodeType.CAMERA_GROUP:
    case NodeType.SURFACE_GROUP:
      return true;
    default:
      return false;
  }
}

export function canUngroupNode(
  node: SimulationTreeNode,
  groupData: GroupMapDataType,
  readOnly: boolean,
  currentView: CurrentView,
): boolean {
  const { groupMap } = groupData;

  if (currentView === CurrentView.GEOMETRY) {
    // We disallow grouping/ungrouping in the geometry tab.
    return false;
  }
  let ungroupable = true;
  if (groupMap instanceof EntityGroupMap) {
    ungroupable = (
      // entities cannot be ungrouped in readonly mode (in simulation)
      !readOnly &&
      !isFarfield(node.id) &&
      !isFarfieldChild(node.id, groupMap)
    );
  }

  return (
    !!node.id &&
    isNodeTypeUngroupable(node) &&
    ungroupable &&
    groupMap.has(node.id)
  );
}

export function canGroupNodes(
  nodeIds: string[],
  groupData: GroupMapDataType,
  readOnly: boolean,
  currentView: CurrentView,
) {
  const { groupMap, leafMap } = groupData;

  if (!nodeIds?.length) {
    return false;
  }

  if (currentView === CurrentView.GEOMETRY) {
    // We disallow grouping/ungrouping in the geometry tab.
    return false;
  }

  // Check that we're not trying to group an existing group and one of its children, which is
  // ill-defined
  const nodeIdSet = new Set(nodeIds);
  const cantGroup = nodeIds.some((nodeId) => {
    if (groupMap.has(nodeId) && groupMap.get(nodeId).children.size) {
      // nodeId represents an existing group
      const leafIds = leafMap.get(nodeId);
      // A group may itself be grouped, but only if none of its descendants are included in the list
      if (leafIds && intersects(leafIds, nodeIdSet)) {
        return true;
      }
    }
    return false;
  });

  if (cantGroup) {
    return false;
  }

  const reachedMaxLevel = nodeIds.some((id) => {
    if (!groupMap.has(id)) {
      return false;
    }
    const node = groupMap.get(id);
    if (node.maxLevel && node.level >= node.maxLevel) {
      return true;
    }
    return false;
  });

  if (reachedMaxLevel) {
    return false;
  }

  const groupableNodes = nodeIds.every(
    (nodeId) => {
      if (!groupMap.has(nodeId)) {
        return false;
      }

      const group = groupMap.get(nodeId);

      // If the group is an EntityGroup type, allow only type surface and particle types.
      if (isEntityGroup(group)) {
        // entities cannot be grouped in readonly mode (in simulation)
        if (readOnly) {
          return false;
        }
        return (
          groupableEntityTypes.includes(group.entityType!) &&
          !isFarfieldChild(nodeId, groupMap as EntityGroupMap)
        );
      }

      // If the group is a NodeGroup type, allow only CAMERA NodeTypes.
      if (isNodeGroup(group)) {
        return group.nodeType === NodeType.CAMERA;
      }

      return false;
    },
  );
  return groupableNodes;
}
