// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtom, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';

const uploadErrorAtomFamily = atomFamily((projectId: string) => atom(''));

export function useUploadError(projectId: string) {
  return useAtom(uploadErrorAtomFamily(projectId));
}

export function useSetUploadError(projectId: string) {
  return useSetAtom(uploadErrorAtomFamily(projectId));
}
