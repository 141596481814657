// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import { Point } from './geometry';

// A series is a set of points, plus labels for the x and y axes.
export interface SparkLineSeries {
  points: Point[];
  xLabel: string;
  yLabel: string;
}

// The TransformConstants and Transform interfaces encapsulate the constants
// used in mapping series points to path coordinates for the spark line.
interface TransformConstants {
  // Minimum value along an axis
  minSource: number;
  // Difference between minimum and maximum values along an axis
  deltaSource: number;
  // Minimum plot value along an axis
  minPlot: number;
  // Difference between minimum and maximum plot values along an axis
  deltaPlot: number;
}

export interface Transform {
  x: TransformConstants;
  y: TransformConstants;
}

// Maps input point from series data to plot point
export function transformPoint(point: Point, transform: Transform): Point {
  const {
    x: {
      minSource: minSourceX, deltaSource: deltaSourceX, minPlot: minPlotX, deltaPlot: deltaPlotX,
    },
    y: {
      minSource: minSourceY, deltaSource: deltaSourceY, minPlot: minPlotY, deltaPlot: deltaPlotY,
    },
  } = transform;

  const x = minPlotX + (point.x - minSourceX) * (deltaPlotX / deltaSourceX);
  const y = minPlotY + (point.y - minSourceY) * (deltaPlotY / deltaSourceY);

  return { x, y };
}
