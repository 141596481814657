// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { ParamName, paramDesc } from '../../../SimulationParamDescriptor';
import { findFluidBoundaryCondition } from '../../../lib/boundaryConditionUtils';
import { findPhysicalBehaviorById } from '../../../lib/physicalBehaviorUtils';
import { getCompatibleTablesMap, hasKeyReference, updateEntryReference } from '../../../lib/rectilinearTable/globalMap';
import { FanCurveTableDefinition } from '../../../lib/rectilinearTable/model';
import { checkFanCurve, getTableColumnLabels } from '../../../lib/rectilinearTable/util';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { InsertElement } from '../../ParamForm';
import ParamRow, { ParamRowProps } from '../../ParamRow';
import { useProjectContext } from '../../context/ProjectContext';
import { ChangeOperation } from '../../controls/TableMapInput';
import { useSimulationConfig } from '../useSimulationConfig';

export interface InletFanCurveResult {
  /** True when this boundary condition is an inlet fan curve */
  isInletFanCurve: boolean;
  /** True when this boundary condition is an outlet fan curve */
  isOutletFanCurve: boolean;
  /** True when the physical model is an internal fan */
  isInternalFanCurve: boolean;
  /**
   * True when this boundary condition is a fan curve
   *
   * If this is false, then all other properties will be undefined.
   * */
  isFanCurve: boolean;
  /**
   * Element for uploading and selecting a fan curve CSV table
   *
   * This is undefined when the fan curve is not enabled.
   * */
  fanCurveUploadElement: InsertElement | undefined
}

/**
 * This hook returns a fn which can be called with a boundaryCondition id or a physicalBehavior id.
 * The function returns the fanCurveUploadElement + some isInletFanCurve/isFanCurve/etc helpers.
 */
export function useGetFanCurve(): (id: string) => InletFanCurveResult {
  const { simParam, saveParam } = useSimulationConfig();
  const { projectId, readOnly } = useProjectContext();

  return useCallback((id: string) => {
    const boundaryCondition = findFluidBoundaryCondition(simParam, id);

    const physicalModel = findPhysicalBehaviorById(simParam, id);

    const isInletFanCurve = (
      boundaryCondition?.inletMomentum === simulationpb.InletMomentum.FAN_CURVE_INLET
    );

    const isOutletFanCurve = (
      boundaryCondition?.outletStrategy === simulationpb.OutletStrategy.FAN_CURVE_OUTLET
    );

    const isInternalFanCurve = (
      physicalModel?.actuatorDiskModel === simulationpb.ActuatorDiskModel.FAN_CURVE_INTERNAL
    );

    const isFanCurve = isInletFanCurve || isOutletFanCurve || isInternalFanCurve;

    const setValue = (value: ChangeOperation) => {
      saveParam((newParam) => {
        const newValue = isInternalFanCurve ?
          findPhysicalBehaviorById(newParam, id) : findFluidBoundaryCondition(newParam, id);
        if (newValue) {
          const metadata = 'metadata' in value ? value.metadata : undefined;
          const link = updateEntryReference(value.type, value.name, newParam, metadata);
          if (link) {
            newValue.fanCurveTableData = value.name;
          }
        }
      });
    };

    let fanCurveUploadElement: InsertElement | undefined;
    if (isFanCurve) {
      const allLabels = getTableColumnLabels(FanCurveTableDefinition);
      const fanCurveSelect: ParamRowProps = {
        inputOptions: {
          tableMapOptions: {
            dialogTitle: 'Fan Curve File Upload',
            dialogSubtitle: (
              <span>
                Upload a CSV file containing the fan curve data for your fan,
                including columns for Volume Flow Rate (m<sup>3</sup>/s) and Fan Static
                Pressure (Pa).
              </span>
            ),
            tableMap: getCompatibleTablesMap(simParam, FanCurveTableDefinition),
            tableDefinition: FanCurveTableDefinition,
            tableErrorFunc: (table) => checkFanCurve(table),
            nameErrorFunc: (name) => {
              if (hasKeyReference(simParam, name)) {
                return 'Name is already in use';
              }
              return '';
            },
            uploadHelp: (
              <>
                <div>The fan curve table must have the following columns in this order:</div>
                <ol style={{ margin: 0, marginTop: '0.5em' }}>
                  {allLabels.map((label) => (
                    <li key={label}>{label}</li>
                  ))}
                </ol>
              </>
            ),
            uploadOptions: {
              inputAccept: '.csv',
            },
          },
        },
        nestLevel: 0,
        projectId,
        param: paramDesc[ParamName.FanCurveTableData],
        readOnly,
        setValue,
        value: isInternalFanCurve ?
          physicalModel?.fanCurveTableData : boundaryCondition?.fanCurveTableData,
      };
      fanCurveUploadElement = {
        element: <ParamRow key="fan-inlet-file-select" {...fanCurveSelect} />,
        insert: isInletFanCurve ?
          paramDesc[ParamName.InletMomentum] : paramDesc[ParamName.OutletStrategy],
      };
    }

    return {
      isInletFanCurve,
      isOutletFanCurve,
      isInternalFanCurve,
      isFanCurve,
      fanCurveUploadElement,
    };
  }, [simParam, saveParam, projectId, readOnly]);
}
