// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { ParamType } from '../ProtoDescriptor';
import { paramGroupDesc } from '../SimulationParamDescriptor';
import * as simulationpb from '../proto/client/simulation_pb';
import * as workflowpb from '../proto/workflow/workflow_pb';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import { ParamScope, chainParamScopes, createParamScope } from './ParamScope';
import { filterExperimentVariables } from './explorationUtils';
import { initParamGroupProto } from './initParam';
import { CallbackArgs, GroupCallbackArgs, paramCallback } from './paramCallback';
import { findFluidPhysicsMaterial, isPhysicsFluid } from './physicsUtils';

/**
 * Update the client param field in workflow.Config.  It creates a new proto object without
 * modifying the old ones.
 */
export function updateSimulationParam(
  oldConfig: workflowpb.Config,
  newParam: simulationpb.SimulationParam,
  paramScope: ParamScope,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
): workflowpb.Config {
  const newJobConfig = oldConfig.jobConfigTemplate!.clone();
  newJobConfig.typ = {
    case: 'simulationParam',
    value: newParam,
  };
  const newConfig = new workflowpb.Config();
  if (oldConfig.exploration) {
    newConfig.exploration = filterExperimentVariables(
      oldConfig.exploration,
      newParam,
      paramScope,
      geometryTags,
      staticVolumes,
    );
  }
  newConfig.jobConfigTemplate = newJobConfig;
  return newConfig;
}

// Creates a scope/param pair where every choice in a multiple choice param is valid, i.e. all
// conditions for every value of a parameter evaluates to true.
export function createValidScope(
  simParam: simulationpb.SimulationParam,
  experimentConfig: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  // Callback function that sets values of multiple choice params in the proto to the new values
  // from the scope.
  const changeEnumValue = (
    { param, set, groupCallbackReturn: scope }: CallbackArgs<ParamScope>,
  ) => {
    if (param.type === ParamType.MULTIPLE_CHOICE) {
      set(scope.value(param) as number);
    }
  };

  // Creates a new scope for repeated param groups. If the group is not repeated it just returns
  // the parent scope.
  const createScope = (
    { groupDesc, proto, parentGroupCallbackReturn: parentScope }: GroupCallbackArgs<ParamScope>,
  ) => {
    if (groupDesc.isRepeated) {
      const protosForScope = [proto];
      if (groupDesc === paramGroupDesc.physics && isPhysicsFluid(proto as simulationpb.Physics)) {
        const phys = proto as simulationpb.Physics;
        const material =
          findFluidPhysicsMaterial(simParam, phys, geometryTags, staticVolumes);
        if (material) {
          protosForScope.push(material);
        }
      }
      const scope = chainParamScopes(protosForScope, experimentConfig, parentScope);
      scope.changeInvalidChoices();
      return scope;
    }
    return parentScope;
  };

  const paramScope = createParamScope(simParam, experimentConfig);
  const validParam = simParam.clone();
  paramScope.changeInvalidChoices();
  // Change the values of all enums in the proto to valid choices. Creates a new param scope
  // for every repeated param group.
  paramCallback<ParamScope>(
    validParam,
    paramGroupDesc.simulation_param,
    changeEnumValue,
    createScope,
    paramScope,
  );

  return { paramScope, validParam };
}

export function newSimulationParam() {
  return initParamGroupProto(new simulationpb.SimulationParam(), paramGroupDesc.simulation_param);
}
