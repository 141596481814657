// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import { colors } from '../../lib/designSystem';
import { StatusCardProps } from '../../lib/statusCardProps';
import { ActionButton } from '../Button/ActionButton';
import { createStyles, makeStyles } from '../Theme';
import { ProgressMessage } from '../visual/ProgressMessage';

const useStyles = makeStyles(() => createStyles({
  card: {
    background: colors.surfaceMedium2,
    borderRadius: '4px',
    zIndex: 3,
    padding: '12px 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '16px',
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}), { name: 'StatusCard' });

// A status card that displays a message and progress.
const StatusCard = (props: StatusCardProps) => {
  const { isVisible, message, onCancel, progress, transitionDuration } = props;

  const classes = useStyles();
  if (!isVisible) {
    return null;
  }
  return (
    <div className={classes.card}>
      <ProgressMessage
        details={[]}
        message={message || ''}
        progress={progress ?? null}
        transitionDuration={transitionDuration}
      />
      {onCancel && (
        <div className={classes.action}>
          <ActionButton
            kind="cancel"
            name="statusCancelButton"
            onClick={onCancel}
            size="small">
            Cancel
          </ActionButton>
        </div>
      )}
    </div>
  );
};

export default StatusCard;
