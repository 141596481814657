// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState } from 'recoil';

import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import { isTestingEnv } from '../lib/testing/utils';
import * as outputpb from '../proto/output/output_pb';

import { jobsState } from './jobState';

const DEFAULT_RANGE_SAWTOOTH = new outputpb.IterationRange({
  // For sawtooth plot, we use the begin field as the length
  // of the range instead of the startinig iteration index.
  begin: 10,
  end: 0,
});
export const MAX_TIME_STEPS_SAWTOOTH = 50;

export type RecoilKey = {
  projectId: string;
  jobId: string;
  outputId: string;
}

const innerItersWindowKey = (jobId: string, outputId: string) => (outputId ?
  `averagingWindow/jobId=${jobId}/outputId=${outputId}` : `averagingWindow/jobId=${jobId}`);

const legacyAveragingKey = 'averagingWindow';

const deserialize = (val: Uint8Array) => (
  val.length ? outputpb.IterationRange.fromBinary(val) : null
);

const innerItersWindowSelectorDefault = selectorFamily<
  outputpb.IterationRange | null,
  RecoilKey
>({
  key: 'innerItersWindowSelectorDefault',
  get: (key: RecoilKey) => () => (persist.getProjectState(
    key.projectId,
    [innerItersWindowKey(key.jobId, key.outputId), legacyAveragingKey],
    deserialize,
  )
  ),
});

const innerItersWindowDefault = isTestingEnv() ?
  DEFAULT_RANGE_SAWTOOTH : innerItersWindowSelectorDefault;

export const innerItersWindowState = atomFamily<outputpb.IterationRange | null, RecoilKey>({
  key: 'innerItersWindow',
  default: innerItersWindowDefault,
  effects: (key: RecoilKey) => [
    syncProjectStateEffect(
      key.projectId,
      innerItersWindowKey(key.jobId, key.outputId),
      deserialize,
      (val: outputpb.IterationRange | null) => (val ? val.toBinary() : EMPTY_UINT8_ARRAY),
    )],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

export const innerItersWindowSelector = selectorFamily<outputpb.IterationRange, RecoilKey>({
  key: 'innerItersWindowSelector',
  get: (key: RecoilKey) => ({ get }) => {
    const jobState = get(jobsState(key.jobId));
    const lastIter = jobState?.lastIter ?? 0;
    const innerItersWindow = get(innerItersWindowState(
      { projectId: key.projectId, jobId: key.jobId, outputId: key.outputId },
    ));

    // The inner iters window uses an IterationRange message but the Begin field
    // represents the length of the range instead of the actual starting iteration.
    const { begin, end } = innerItersWindow || DEFAULT_RANGE_SAWTOOTH;
    if (!begin || begin < 1) {
      throw Error('Averaging window must have length greater than 0.');
    }
    let newBegin: number = 0;
    let newEnd: number = 0;
    // Always return a range of length equal to the averaging window state Begin field
    // that starts at an iteration number greater than 0.
    if (end) {
      // Users can only update the End field after a simulation is done running.
      const endIter = lastIter !== 0 ? Math.min(lastIter, end) : end;
      newBegin = Math.max(endIter - begin + 1, 1);
      // Allow the end of the range to be greater than the number of available iterations
      // so that the length is displayed properly when using selector to populate
      // user input fields. Checks in useTimeSeriesOutput ensure that the iteration
      // range sent to the Analyzer is valid.
      newEnd = newBegin + begin - 1;
    } else {
      // If the End field is specified, return a window of [end-length+1,end] when
      // enough iterations are available and [1,length] otherwise (Begin field contains
      // length of range).
      const endIter = lastIter || 1;
      newEnd = Math.max(endIter, begin);
      newBegin = newEnd - begin + 1;
    }
    return new outputpb.IterationRange({ begin: newBegin, end: newEnd });
  },
  set: (key: RecoilKey) => ({ set }, newWindow) => {
    set(innerItersWindowState(
      { projectId: key.projectId, jobId: key.jobId, outputId: key.outputId },
    ), (prevWindow) => {
      const window = new outputpb.IterationRange();
      if (newWindow instanceof outputpb.IterationRange) {
        if (key.outputId) {
          const oldWindow = prevWindow || DEFAULT_RANGE_SAWTOOTH;
          // User can only set averaging window for sawtooth plots by updating Begin
          // or End fields individually. If Begin is populated, End will be undefined
          // and vice versa
          if (newWindow.begin) {
            // Don't allow sawtooth plots with too many time steps
            const newBegin = Math.min(newWindow.begin, MAX_TIME_STEPS_SAWTOOTH);
            window.begin = newBegin;
            // If End is currently undefined, leave it undefined. Also don't let
            // the end iteration be less than the range length
            window.end = oldWindow.end > 0 ? Math.max(oldWindow.end, newBegin) : oldWindow.end;
          } else if (newWindow.end) {
            window.begin = oldWindow.begin;
            // End iter must be greater than or equal to range length
            window.end = Math.max(newWindow.end, oldWindow.begin);
          }
          return window;
        }
        // Only executed for averaging windows not being used for sawtooth plots
        window.begin = newWindow.begin;
        window.end = newWindow.end;
        return window;
      }
      return prevWindow;
    });
  },
  dangerouslyAllowMutability: true,
});

export function useInnerItersWindow(projectId: string, jobId: string, outputId: string) {
  return useRecoilState(innerItersWindowSelector({ projectId, jobId, outputId }));
}
