// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

// The BoxSelectOverlay allows users to apply a box select to modify the
// current selection.

import React from 'react';

import { SelectionAction } from '../../../lib/selectionUtils';
import { OverlayMode, useSetOverlayMode } from '../../../state/internal/vis/overlayMode';
import { useSelectionContext } from '../../context/SelectionManager';
import { useParaviewContext } from '../ParaviewManager';

import DraggableBoxOverlay, { OnBoxSelectArguments } from './DraggableBoxOverlay';

interface Props {
  className: string;
  // If the box should be activated as soon as the mouse starts moving.
  // Otherwise, it requires a mouse down event.
  autoActivate: boolean;
}

const BoxSelectOverlay = (props: Props) => {
  // == Props
  const { className } = props;

  // == Contexts
  const { paraviewRenderer } = useParaviewContext();
  const { modifySelection, activeNodeTable } = useSelectionContext();

  // == Recoil
  const setOverlayMode = useSetOverlayMode();

  const onBoxSelect = (args: OnBoxSelectArguments) => {
    const onBlocksSelected = (blocks: string[]) => {
      setOverlayMode(OverlayMode.NONE);

      // The default behavior is to overwrite the previous selection. Holding
      // the CTRL key adds to it and the ALT key subtracts from it.
      let action = SelectionAction.OVERWRITE;
      if (args.event.ctrlKey) {
        // Clicking an individual point, toggles the surface.
        if (args.minX === args.maxX && args.minY === args.maxY) {
          action = SelectionAction.TOGGLE;
        } else {
          action = SelectionAction.ADD;
        }
      } else if (args.event.altKey) {
        action = SelectionAction.SUBTRACT;
      }
      modifySelection({
        action,
        modificationIds: blocks,
        nodeTableOverride: activeNodeTable,
        selectionSource: 'vis',
      });
    };
    // LC-12078: rectangles drawn from left to right on the x direction will use screen
    // visible-based box block selection. Else we'll rely on frustum based selection.
    const useFrustumBased = !args.increasingX;
    paraviewRenderer.boxBlockSelect(
      args.minX,
      args.maxX,
      args.minY,
      args.maxY,
      useFrustumBased,
      onBlocksSelected,
    );
  };

  return (
    <DraggableBoxOverlay
      autoActivate={props.autoActivate}
      className={className}
      onBoxSelect={onBoxSelect}
      text={`Drag left to right to select visible surfaces only or right to left to select both
        visible and underlying surfaces.`}
    />
  );
};

export default BoxSelectOverlay;
