// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { CurrentView, INTERMEDIATE_VIEWS } from '../../../lib/componentTypes/context';
import {
  ADVANCED_SOLVER_SETTINGS as ADVANCED_SOLVER_SETTINGS_NODE_ID,
  CAD_SOLID_OR_FLUID_NODE_ID,
  CREATE_FARFIELD_NODE_ID,
  GENERAL_SETTINGS_NODE_ID,
  ITERS_PER_OUTPUT_NODE_ID,
  NodeType,
  ROOT_SIMULATION_CONTAINER_ID,
  SOLVER_SETTINGS as SOLVER_SETTINGS_NODE_ID,
  STOPPING_CONDITIONS_NODE_ID,
  SimulationTreeNode,
  TRANSIENT_SETTINGS_NODE_ID,
  UPLOAD_CAD_OR_MESH_NODE_ID,
} from '../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../lib/simulationTree/sectionRecoilKey';
import { paraviewToSimulationNode } from '../../../lib/simulationTree/utils';
import { FlowBehavior } from '../../../proto/client/simulation_pb';
import { lcVisEnabledSelector } from '../../../recoil/lcvis/lcvisEnabledState';
import { controlPanelModeState_DEPRECATED } from '../../../recoil/useProjectPage';
import { TreeViewType, treeViewTypeState } from '../../../recoil/useTreeViewState';
import { viewStateAtomFamily_DEPRECATED } from '../../../recoil/useViewState';
import { filterStateSelector } from '../../../recoil/vis/filterState';
import { simulationParamState } from '../../external/project/simulation/param';
import { currentViewAtom_DEPRECATED } from '../global/currentView';

import { explorationTreeSelector } from './exploration';
import { solidOrFluidState } from './geometrySetupTree';
import { cameraSectionSelector } from './section/camera';
import { materialsSectionSelector } from './section/material';
import { meshSectionSelector } from './section/mesh';
import { motionSectionSelector } from './section/motion';
import { outputsSectionSelector } from './section/output';
import { physicsSectionSelector } from './section/physics';
import { plotSectionSelector } from './section/plot';

type IntermediateTreeInfo = {
  tree: SimulationTreeNode;
  nodeIds: Set<string>;
}
type IntermediateTreeKey = SectionRecoilKey & { currentView: CurrentView };
// selector for the childNodes of the simulation setup tree, based on the current view.
export const intermediateTreeSelector = selectorFamily<IntermediateTreeInfo, IntermediateTreeKey>({
  key: 'intermediateTreeSelector',
  get: (fullKey: IntermediateTreeKey) => async ({ get }) => {
    const { currentView, projectId, workflowId, jobId } = fullKey;
    const key = { projectId, workflowId, jobId };
    const [
      mesh,
      materials,
      physics,
      outputs,
      cameras,
      plot,
      motion,
      viewState,
      treeViewType,
      filterState,
      lcvisEnabled,
      simParam,
      solidOrFluid,
      explorationTree,
      controlPanelMode,
    ] = get(waitForAll([
      meshSectionSelector(key),
      materialsSectionSelector(key),
      physicsSectionSelector(key),
      outputsSectionSelector(key.projectId),
      cameraSectionSelector(key.projectId),
      plotSectionSelector(key.projectId),
      motionSectionSelector(key),
      viewStateAtomFamily_DEPRECATED(key.projectId),
      treeViewTypeState,
      filterStateSelector(key),
      lcVisEnabledSelector(key.projectId),
      simulationParamState(key),
      solidOrFluidState(projectId),
      explorationTreeSelector(key),
      controlPanelModeState_DEPRECATED,
    ]));

    const flowBehavior = simParam.general?.flowBehavior;
    const transientSettings = (
      flowBehavior === FlowBehavior.TRANSIENT ?
        new SimulationTreeNode(
          NodeType.TRANSIENT_SETTINGS,
          TRANSIENT_SETTINGS_NODE_ID,
          'Transient Settings',
        ) : null
    );

    const general = new SimulationTreeNode(
      NodeType.GENERAL_SETTINGS,
      GENERAL_SETTINGS_NODE_ID,
      'General',
    );

    const itersPerOutput = new SimulationTreeNode(
      NodeType.ITERS_PER_OUTPUT,
      ITERS_PER_OUTPUT_NODE_ID,
      'Iterations per output',
    );

    const stoppingConditions = new SimulationTreeNode(
      NodeType.STOPPING_CONDITIONS,
      STOPPING_CONDITIONS_NODE_ID,
      'Stopping Conditions',
    );

    const solverSettings = new SimulationTreeNode(
      NodeType.SOLVER_SETTINGS,
      SOLVER_SETTINGS_NODE_ID,
      'Solver Settings',
    );

    const advancedSolverSettings = new SimulationTreeNode(
      NodeType.ADVANCED_SOLVER_SETTINGS,
      ADVANCED_SOLVER_SETTINGS_NODE_ID,
      'Advanced Solver Settings',
    );

    const getVisChildren = () => {
      if (lcvisEnabled) {
        return [paraviewToSimulationNode(filterState)];
      }
      if (viewState) {
        return [paraviewToSimulationNode(viewState.root)];
      }
      return [];
    };

    const viewsNodes = [
      cameras,
      ...getVisChildren(),
    ];

    const childNodes: SimulationTreeNode[] = [];
    switch (currentView) {
      case CurrentView.PHYSICS:
        childNodes.push(
          general,
          materials,
          physics,
          motion,
          ...viewsNodes,
        );
        break;
      case CurrentView.MESH:
        childNodes.push(
          mesh,
          ...viewsNodes,
        );
        break;
      case CurrentView.OUTPUTS:
        childNodes.push(
          itersPerOutput,
          outputs,
          ...viewsNodes,
        );
        break;
      case CurrentView.SOLVER: {
        // update with LC-22632 (add new case for new CurrentView)
        if (controlPanelMode === 'exploration') {
          childNodes.push(...explorationTree.children);
        } else {
          childNodes.push(
            solverSettings,
            advancedSolverSettings,
          );
          if (transientSettings) {
            childNodes.push(transientSettings);
          }
          childNodes.push(
            stoppingConditions,
            plot,
            ...viewsNodes,
          );
        }
        break;
      }
      case CurrentView.SETUP:
        childNodes.push(
          general,
          mesh,
          materials,
          motion,
          physics,
          outputs,
          stoppingConditions,
          plot,
          ...viewsNodes,
        );
        break;
      case CurrentView.ANALYSIS: {
        switch (treeViewType) {
          case TreeViewType.POST_PROCESSING: {
            childNodes.push(
              outputs,
              plot,
              ...viewsNodes,
            );
            break;
          }
          case TreeViewType.SETUP: {
            childNodes.push(
              general,
              mesh,
              materials,
              motion,
              physics,
              outputs,
              stoppingConditions,
            );
            break;
          }
          default: {
            // node default, enum exhausted
          }
        }
        break;
      }
      case CurrentView.GEOMETRY:
        childNodes.push(
          new SimulationTreeNode(
            NodeType.UPLOAD_CAD_OR_MESH,
            UPLOAD_CAD_OR_MESH_NODE_ID,
            'Upload CAD or Mesh',
          ),
          new SimulationTreeNode(
            NodeType.CAD_SOLID_OR_FLUID,
            CAD_SOLID_OR_FLUID_NODE_ID,
            'Is your CAD solid or fluid?',
          ),
          // TODO: add this back once we have the geometry check trigger feature
          // new SimulationTreeNode(
          //   NodeType.GEOMETRY_CHECK,
          //   GEOMETRY_CHECK_NODE_ID,
          //   'Geometry Check',
          // ),
          // TODO: add this back once shrink wrap actually works
          // new SimulationTreeNode(
          //   NodeType.SHRINK_WRAP,
          //   SHRINK_WRAP_NODE_ID,
          //   'Shrink Wrap',
          // ),
        );
        if (solidOrFluid === 'solid') {
          childNodes.push(
            new SimulationTreeNode(
              NodeType.CREATE_FARFIELD,
              CREATE_FARFIELD_NODE_ID,
              'Create Farfield',
            ),
          );
        }
        childNodes.push(...viewsNodes);
        break;
      default: {
        // none
      }
    }

    const nodeIds = new Set<string>();
    childNodes.forEach((parent) => {
      parent.traverse((node) => {
        nodeIds.add(node.id);
      });
    });

    return {
      tree: new SimulationTreeNode(
        NodeType.ROOT_SIMULATION,
        ROOT_SIMULATION_CONTAINER_ID,
        'Simulation',
        childNodes,
      ),
      nodeIds,
    };
  },
  dangerouslyAllowMutability: true,
});

export const setupTreeSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'setupTreeSelector',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    const currentView = get(currentViewAtom_DEPRECATED);
    const intermediateTree = get(intermediateTreeSelector({ ...key, currentView }));
    return intermediateTree.tree;
  },
  dangerouslyAllowMutability: true,
});

export const intermediateTreeNodeIdsSelector = selectorFamily<
  Map<CurrentView, Set<string>>,
  SectionRecoilKey
>({
  key: 'intermediateTreeNodeIdsSelector',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    const views = INTERMEDIATE_VIEWS;
    const intermediateTrees = get(waitForAll(
      views.map((view) => intermediateTreeSelector({ ...key, currentView: view })),
    ));
    const res = new Map<CurrentView, Set<string>>();
    views.forEach((view, index) => {
      res.set(view, intermediateTrees[index]?.nodeIds ?? new Set());
    });
    return res;
  },
  dangerouslyAllowMutability: true,
});

export const useIntermediateTreeNodeIds = (
  key: SectionRecoilKey,
): Map<CurrentView, Set<string>> => useRecoilValue(intermediateTreeNodeIdsSelector(key));
