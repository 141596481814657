// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, useMemo } from 'react';

import { validateGtZero, validateGteZero } from '../../../../lib/inputValidationUtils';
import { COMMON_START_ICON, SIZE_ID, conflictingMeshParamVolumes, nullableMeshing, sizeHeading } from '../../../../lib/mesh';
import { NodeTableType } from '../../../../lib/nodeTableUtil';
import { SelectionAction } from '../../../../lib/selectionUtils';
import { wordsToList } from '../../../../lib/text';
import { allVolumesAssigned, mapIndicestoIds, volumeNodeId } from '../../../../lib/volumeUtils';
import * as cadmetadatapb from '../../../../proto/cadmetadata/cadmetadata_pb';
import * as meshgenerationpb from '../../../../proto/meshgeneration/meshgeneration_pb';
import { QuantityType } from '../../../../proto/quantity/quantity_pb';
import { useCadMetadata } from '../../../../recoil/useCadMetadata';
import { useMeshReadOnly } from '../../../../recoil/useMeshReadOnly';
import { WarningLocation, useMeshValidator } from '../../../../recoil/useMeshValidator';
import useMeshMultiPart, {
  defaultVolumeParams,
  useSetMeshMultiPart,
} from '../../../../recoil/useMeshingMultiPart';
import { useStaticVolumes } from '../../../../recoil/volumes';
import { pushConfirmation, useSetConfirmations } from '../../../../state/internal/dialog/confirmations';
import { ActionButton } from '../../../Button/ActionButton';
import { IconButton } from '../../../Button/IconButton';
import Form from '../../../Form';
import { DataField } from '../../../Form/DataSelect/DataField';
import { NumberField } from '../../../Form/NumberField';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import Divider from '../../../Theme/Divider';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { SectionMessage } from '../../../notification/SectionMessage';
import { ArrowUpRightIcon } from '../../../svg/ArrowUpRightIcon';
import { ResetIcon } from '../../../svg/ResetIcon';
import { TrashIcon } from '../../../svg/TrashIcon';
import NodeTable from '../../NodeTable';
import PropertiesSection from '../../PropertiesSection';
import { CustomCount } from '../shared/CustomCount';

type VolumeParams = meshgenerationpb.MeshingMultiPart_VolumeParams;
const SelectionType = meshgenerationpb.MeshingMultiPart_VolumeParams_SelectionType;

function getDefaultVolume(cadMetadata: cadmetadatapb.CadMetadata) {
  return new meshgenerationpb.MeshingMultiPart_VolumeParams({
    minSize: cadMetadata.globalMinSizeM,
    maxSize: cadMetadata.globalMaxSizeM,
    selection: SelectionType.SELECTED,
  });
}

interface SelectionChange {
  selection: string[],
  type: meshgenerationpb.MeshingMultiPart_VolumeParams_SelectionType,
  overlapIndices: number[],
}

interface MeshVolumeParamsProps {
  // The index of the volume params this is displaying.
  volumeIndex: number;
  // If the panel contains inputs rather than constant values.
  isInput: boolean;
  // Set a pending selection that must be confirmed before it is applied.
  setPendingChange?: (selectionChange: SelectionChange) => void;
}

// A panel displaying a single VolumeParams.
export const MeshVolumeParams = (props: MeshVolumeParamsProps) => {
  const { volumeIndex, isInput } = props;
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const {
    setSelection,
    setScrollTo,
    modifySelection,
    activeNodeTable,
  } = useSelectionContext();
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const [cadMetadata] = useCadMetadata(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const {
    disabledLevel,
    disabledReason,
    warningLocations,
  } = useMeshValidator(projectId, workflowId, jobId, readOnly);
  const meshReadOnly = useMeshReadOnly(projectId);

  const disabled = readOnly || meshReadOnly;

  const volume = meshMultiPart?.volumeParams[volumeIndex];
  const volumeIds = useMemo(() => (
    mapIndicestoIds(staticVolumes, volume?.volumes ?? [])
  ), [staticVolumes, volume]);
  if (!volume || !volumeIds) {
    return null;
  }

  // If the warning is intended for this panel location, display it
  let showWarning = isInput &&
    (warningLocations.includes(WarningLocation.MESH_SIZE_MIN) ||
      warningLocations.includes(WarningLocation.MESH_SIZE_MAX));
  if (showWarning && warningLocations.includes(WarningLocation.MESH_SIZE_MIN)) {
    showWarning = volume.maxSize - volume.minSize <= 0;
  }

  if (isInput && !props.setPendingChange) {
    throw new Error('setPendingChange expected for an input.');
  }
  const updateVolume = (diff: Partial<VolumeParams>) => {
    const newMeshMultiPart = meshMultiPart.clone();
    const newVolumeParams = new meshgenerationpb.MeshingMultiPart_VolumeParams({
      ...volume,
      ...diff,
    });
    newMeshMultiPart.volumeParams[volumeIndex] = newVolumeParams;
    setMeshMultiPart(newMeshMultiPart);
  };
  const selectionOptions = [
    {
      name: 'All Volumes',
      value: SelectionType.ALL,
      selected: volume.selection === SelectionType.ALL,
    },
    {
      name: 'Selected Volumes',
      value: SelectionType.SELECTED,
      selected: volume.selection === SelectionType.SELECTED,
    },
  ];

  const isDefault = (volumeIndex === 0);
  const headerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMeshMultiPart((oldMeshMultiPart: nullableMeshing) => {
      if (!oldMeshMultiPart) {
        return null;
      }
      const newMeshMultiPart = meshMultiPart.clone();
      if (isDefault) {
        // The default has a reset button that resets everything.
        newMeshMultiPart.volumeParams = [defaultVolumeParams(cadMetadata)];
      } else {
        // The other sections have a trash button that delete this particular volume.
        newMeshMultiPart.volumeParams.splice(volumeIndex, 1);
      }
      return newMeshMultiPart;
    });
  };
  const editClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSelection([SIZE_ID]);
    setScrollTo({ node: SIZE_ID });
  };
  const headerButton = isInput ? (
    <IconButton
      disabled={disabled}
      onClick={headerClick}>
      {isDefault ? (
        <ResetIcon maxHeight={13} />
      ) : (
        <TrashIcon maxHeight={13} />
      )}
    </IconButton>
  ) : (
    <ActionButton kind="minimal" onClick={editClick} size="small">
      {disabled ? 'View' : 'Edit'}
      <ArrowUpRightIcon maxHeight={9} />
    </ActionButton>
  );
  const changeAppliesTo = (newValue: number) => {
    // If new value is ALL, move all volumes into the default volume 0.
    if (newValue === SelectionType.ALL) {
      const newSelection: string[] = [];
      for (let i = 0; i < cadMetadata.nBodies; i += 1) {
        newSelection.push(volumeNodeId(i));
      }

      // Find all other meshing volume params (by index) whose volumes include one or more member
      // of `newSelection`
      const overlapIndices = conflictingMeshParamVolumes(
        volumeIndex,
        newSelection,
        meshMultiPart,
        staticVolumes,
      );

      // Require the change to be confirmed if there is some overlap with other volumes.
      if (overlapIndices.length) {
        props.setPendingChange!({ selection: newSelection, type: newValue, overlapIndices });
      } else {
        updateVolume({ selection: newValue });
        modifySelection({
          action: SelectionAction.OVERWRITE_EXCLUDE,
          modificationIds: newSelection,
          nodeTableOverride: { type: NodeTableType.MESHING_SIZE, index: 0 },
          updateHighlighting: false,
        });
      }
    } else {
      updateVolume({ selection: newValue });
    }
  };

  const showNodeTable = isInput && (!isDefault || volume.selection === SelectionType.SELECTED);
  return (
    <PropertiesSection>
      <CollapsibleNodePanel
        disabled={activeNodeTable.type === NodeTableType.MESHING_SIZE}
        headerRight={headerButton}
        heading={sizeHeading(volumeIndex)}
        nodeId={`${SIZE_ID}-${volumeIndex}`}
        panelName="main">
        <Form.LabeledInput label="Min Size">
          <NumberField
            asBlock
            disabled={disabled}
            faultType={showWarning && warningLocations.includes(WarningLocation.MESH_SIZE_MIN) ?
              'warning' : undefined}
            isInput={isInput}
            onCommit={(newValue: number) => {
              updateVolume({ minSize: newValue });
            }}
            quantity={QuantityType.LENGTH}
            readOnly={disabled}
            validate={(value: number) => {
              if (value >= volume.maxSize) {
                return { type: 'error', message: 'Must be < Max Size' };
              }
              return validateGteZero(value);
            }}
            value={volume.minSize}
          />
        </Form.LabeledInput>
        <Form.LabeledInput label="Max Size">
          <NumberField
            asBlock
            disabled={disabled}
            faultType={showWarning && warningLocations.includes(WarningLocation.MESH_SIZE_MAX) ?
              'warning' : undefined}
            isInput={isInput}
            onCommit={(newValue: number) => {
              updateVolume({ maxSize: newValue });
            }}
            quantity={QuantityType.LENGTH}
            readOnly={disabled}
            validate={(value: number) => {
              if (value <= volume.minSize) {
                return { type: 'error', message: 'Must be > Min Size' };
              }
              return validateGtZero(value);
            }}
            value={volume.maxSize}
          />
        </Form.LabeledInput>
        {showWarning && (
          <div style={{ marginTop: '8px' }}>
            <SectionMessage level={disabledLevel}>
              {disabledReason}
            </SectionMessage>
          </div>
        )}
        {isDefault && (
          <Form.LabeledInput label="Applies to">
            <DataField
              asBlock
              disabled={disabled}
              isInput={isInput}
              onChange={changeAppliesTo}
              options={selectionOptions}
              size="small"
            />
          </Form.LabeledInput>
        )}
        {showNodeTable && (
          <div style={{ paddingTop: '8px' }}>
            <NodeTable
              editable={!disabled}
              formGroups
              nodeIds={volumeIds}
              tableId={`meshing-size-${volumeIndex}`}
              tableIndex={volumeIndex}
              tableType={NodeTableType.MESHING_SIZE}
              title="Volumes"
            />
          </div>
        )}
        {!isInput && <CustomCount count={meshMultiPart.volumeParams.length - 1} />}
      </CollapsibleNodePanel>
    </PropertiesSection>
  );
};

// A panel for displaying the mesh size parameters.
export const MeshSizePropPanel = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const [cadMetadata] = useCadMetadata(projectId);
  const { modifySelection } = useSelectionContext();
  const setConfirmStack = useSetConfirmations();
  const meshReadOnly = useMeshReadOnly(projectId);
  if (!meshMultiPart) {
    return null;
  }

  const disabled = readOnly || meshReadOnly;

  const confirmChange = (pendingChange: SelectionChange) => {
    const headings = pendingChange.overlapIndices.map((idx) => sizeHeading(idx));
    pushConfirmation(setConfirmStack, {
      onContinue: () => {
        setMeshMultiPart((oldMeshMultiPart: nullableMeshing) => {
          const newMeshMultiPart = oldMeshMultiPart!.clone();
          newMeshMultiPart.volumeParams[0].selection = pendingChange.type;
          return newMeshMultiPart;
        });
        modifySelection({
          action: SelectionAction.OVERWRITE_EXCLUDE,
          modificationIds: pendingChange.selection,
          nodeTableOverride: { type: NodeTableType.MESHING_SIZE, index: 0 },
          updateHighlighting: false,
        });
      },
      subtitle: `This will remove some volumes from ${wordsToList(headings)}.
        Do you wish to continue?`,
      title: 'Confirm',
    });
  };

  const addCustomMeshSize = () => {
    setMeshMultiPart((oldMeshMultiPart: nullableMeshing) => {
      if (!oldMeshMultiPart) {
        return null;
      }
      const newMeshMultiPart = oldMeshMultiPart.clone();
      newMeshMultiPart.volumeParams.push(getDefaultVolume(cadMetadata));
      return newMeshMultiPart;
    });
  };
  const meshSizeList: ReactElement[] = [];
  for (let i = 0; i < meshMultiPart.volumeParams.length; i += 1) {
    meshSizeList.push(
      <MeshVolumeParams
        isInput
        key={`vol-${i}`}
        setPendingChange={confirmChange}
        volumeIndex={i}
      />,
    );
    meshSizeList.push(<Divider key={`div-${i}`} />);
  }
  const allAssigned = allVolumesAssigned(cadMetadata.nBodies, meshMultiPart);
  return (
    <div>
      {meshSizeList}
      <PropertiesSection>
        <ActionButton
          disabled={disabled}
          kind="secondary"
          onClick={addCustomMeshSize}
          size="small"
          startIcon={COMMON_START_ICON}>
          Custom Mesh Size
        </ActionButton>
        {!allAssigned && (
          <div style={{ marginTop: '16px' }}>
            <SectionMessage level="warning" message="Assign Mesh Size to All Volumes." />
          </div>
        )}
      </PropertiesSection>
    </div>
  );
};
