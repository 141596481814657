// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { BaseEditor, Descendant } from 'slate';
import { ReactEditor } from 'slate-react';

// Custom Types for slate.js
// More information: https://docs.slatejs.org/concepts/12-typescript

// Elements
export enum Type {
  Paragraph, Variable, Function, Preview
}

export type ParagraphElement = { type: Type.Paragraph, children: Descendant[] };
export type VariableElement = { type: Type.Variable, variable: string, children: Descendant[] };
export type FunctionElement = { type: Type.Function, func: string, children: Descendant[] };
export type PreviewElement = { type: Type.Preview, preview: string, children: Descendant[] };
export type CustomElement = ParagraphElement | VariableElement | FunctionElement | PreviewElement;

// Text
export type BasicText = { text: string };
export type CustomText = BasicText;

// Slate Module
declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}
