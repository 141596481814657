// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { CurrentView } from '../lib/componentTypes/context';
import { pendingWorkOrdersFixture } from '../lib/fixtures';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import { isTestingEnv } from '../lib/testing/utils';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { currentViewAtom_DEPRECATED } from '../state/internal/global/currentView';

const pendingWorkOrdersKey = 'pendingWorkOrders';

const DEFAULT_ORDERS = new frontendpb.PendingWorkOrders();

function serialize(val: frontendpb.PendingWorkOrders): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}

function deserialize(val: Uint8Array): frontendpb.PendingWorkOrders {
  return (val.length ?
    frontendpb.PendingWorkOrders.fromBinary(val) :
    DEFAULT_ORDERS);
}

const pendingWorkOrdersBase = selectorFamily<frontendpb.PendingWorkOrders, string>({
  key: 'pendingWorkOrdersSelectorBase',
  get: (projectId: string) => () => (
    persist.getProjectState(projectId, [pendingWorkOrdersKey], deserialize)
  ),
  dangerouslyAllowMutability: true,
});

const pendingWorkOrdersSelectorRpc = selectorFamily<frontendpb.PendingWorkOrders, string>({
  key: `${pendingWorkOrdersKey}/rpc`,
  get: (projectId: string) => ({ get }) => {
    // In the WIP geometry tab we want to try to avoid WorkOrderManager for now.
    const currentView = get(currentViewAtom_DEPRECATED);
    if (currentView === CurrentView.GEOMETRY) {
      return DEFAULT_ORDERS;
    }
    return get(pendingWorkOrdersBase(projectId));
  },
  dangerouslyAllowMutability: true,
});

const pendingWorkOrdersSelectorTesting = selectorFamily<frontendpb.PendingWorkOrders, string>({
  key: `${pendingWorkOrdersKey}/testing`,
  get: () => pendingWorkOrdersFixture,
  dangerouslyAllowMutability: true,
});

const pendingWorkOrdersSelector = isTestingEnv() ?
  pendingWorkOrdersSelectorTesting : pendingWorkOrdersSelectorRpc;

export const pendingWorkOrdersState = atomFamily<frontendpb.PendingWorkOrders, string>({
  key: pendingWorkOrdersKey,
  default: pendingWorkOrdersSelector,
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, pendingWorkOrdersKey, deserialize, serialize),
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

export const geometryCheckState = selectorFamily<boolean, string>({
  key: 'geometryCheckState',
  get: (projectId: string) => ({ get }) => {
    if (!projectId) {
      return false;
    }
    const pendingWorkOrders = get(pendingWorkOrdersState(projectId));
    return !!pendingWorkOrders.workOrders[frontendpb.WorkOrderType.CHECK_GEOMETRY];
  },
});

export const geometryPendingState = selectorFamily<boolean, string>({
  key: 'geometryPendingState',
  get: (projectId: string) => ({ get }) => {
    if (!projectId) {
      return false;
    }
    const pendingWorkOrders = get(pendingWorkOrdersState(projectId));
    return !!pendingWorkOrders.workOrders[frontendpb.WorkOrderType.GET_GEOMETRY];
  },
});

export const meshPendingState = selectorFamily<boolean, string>({
  key: 'meshPendingState',
  get: (projectId: string) => ({ get }) => {
    if (!projectId) {
      return false;
    }
    const pendingWorkOrders = get(pendingWorkOrdersState(projectId));
    return !!pendingWorkOrders.workOrders[frontendpb.WorkOrderType.GET_MESH];
  },
});

export const usePendingWorkOrders = (projectId: string) => (
  useRecoilState(pendingWorkOrdersState(projectId))
);

export const useSetPendingWorkOrders = (projectId: string) => (
  useSetRecoilState(pendingWorkOrdersState(projectId))
);

export const useIsGeometryCheck = (projectId: string) => (
  useRecoilValue(geometryCheckState(projectId))
);

export const useIsGeometryPending = (projectId: string) => (
  useRecoilValue(geometryPendingState(projectId))
);

export const useIsMeshPending = (projectId: string) => (
  useRecoilValue(meshPendingState(projectId))
);
