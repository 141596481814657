// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { getQuantityText } from '../../../../QuantityDescriptor';
import assert from '../../../../lib/assert';
import { MultiCheckBoxItemProps, RadioButtonOption } from '../../../../lib/componentTypes/form';
import { BaseSettingsProps } from '../../../../lib/componentTypes/output';
import { defaultPhysicsId, getAvailableResiduals, getDefaultPhysicsId } from '../../../../lib/outputNodeUtils';
import { useOutput } from '../../../../model/hooks/useOutput';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import * as feoutputpb from '../../../../proto/frontend/output/output_pb';
import * as outputpb from '../../../../proto/output/output_pb';
import { GeometryTags } from '../../../../recoil/geometry/geometryTagsObject';
import { useGeometryTags } from '../../../../recoil/geometry/geometryTagsState';
import { useEnabledExperiments } from '../../../../recoil/useExperimentConfig';
import { useMeshReadyState } from '../../../../recoil/useMeshReadyState';
import { StaticVolume, useStaticVolumes } from '../../../../recoil/volumes';
import Form from '../../../Form';
import LabeledInput from '../../../Form/LabeledInput';
import { RadioButtonGroup } from '../../../Form/RadioButtonGroup';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import Divider from '../../../Theme/Divider';
import { useProjectContext } from '../../../context/ProjectContext';
import PropertiesSection from '../../PropertiesSection';

import { InnerIterationContent } from './shared/InnerIterationContent';

const { RESIDUAL_ABSOLUTE, RESIDUAL_RELATIVE } = outputpb.ResidualType;

// Options for the residual type
const residualTypeOptions: RadioButtonOption<outputpb.ResidualType>[] = [
  {
    value: RESIDUAL_ABSOLUTE,
    help: 'Absolute values',
    label: 'Absolute',
  },
  {
    value: RESIDUAL_RELATIVE,
    help: 'Relative residual',
    label: 'Relative',
  },
];

interface ResidualContentProps {
  outputNode: feoutputpb.OutputNode;
  param: simulationpb.SimulationParam;
  geometryTags: GeometryTags;
  staticVolumes: StaticVolume[];
  projectId: string;
  jobId: string;
  jobActive: boolean;
  disabled: boolean;
}

const ResidualContent = (props: ResidualContentProps) => {
  const {
    geometryTags,
    staticVolumes,
    jobActive,
    jobId,
    outputNode,
    param,
    projectId,
    disabled,
  } = props;

  // == Recoil
  const experimentConfig = useEnabledExperiments();

  assert(
    outputNode.nodeProps.case === 'residual',
    'Residual content requires a residual output',
  );
  const outputId = outputNode.id;
  const residualNode = outputNode.nodeProps.value;
  const resProps = residualNode.props;
  const resEnabledMap = residualNode.resEnabled;
  const { updateOutputNode } = useOutput(projectId, outputId);
  if (!resProps || !resEnabledMap) {
    throw Error('Missing residual values');
  }

  // Get the currently selected physics id.
  const physicsId = defaultPhysicsId(residualNode, param) ?
    getDefaultPhysicsId(param) : residualNode.physicsId;
  const availableResiduals = physicsId ?
    getAvailableResiduals(param, physicsId, experimentConfig, geometryTags, staticVolumes) : [];
  const checkboxItems = availableResiduals
    .filter((res) => resEnabledMap[res] !== undefined)
    .map(
      (res): MultiCheckBoxItemProps => ({
        checked: resEnabledMap[res],
        disabled,
        onChange: (checked) => updateOutputNode((output) => {
          if (output.nodeProps.case === 'residual') {
            output.nodeProps.value.resEnabled[res] = checked;
          }
        }),
        optionText: getQuantityText(res),
      }),
    );
  return (
    <>
      <LabeledInput
        help="Select the residual type"
        label="Residual Type">
        <RadioButtonGroup
          disabled={disabled}
          kind="secondary"
          name="residualType"
          onChange={(newType) => updateOutputNode((newOutput) => {
            if (
              newOutput.nodeProps.case === 'residual' &&
              newOutput.nodeProps.value.props
            ) {
              newOutput.nodeProps.value.props.type = newType;
            }
          })}
          options={residualTypeOptions}
          value={resProps.type}
        />
      </LabeledInput>
      {!!checkboxItems.length && (
        <LabeledInput label="Include">
          <Form.MultiCheckBox
            checkBoxProps={checkboxItems}
          />
        </LabeledInput>
      )}
      <InnerIterationContent
        jobActive={jobActive}
        jobId={jobId}
        outputNode={outputNode}
        param={param}
        projectId={projectId}
        toolTip="Display a plot showing inner iteration residuals."
      />
    </>
  );
};

export interface ResidualOutputSettingsProps extends BaseSettingsProps {
  jobActive: boolean;
  jobId: string;
  param: simulationpb.SimulationParam;
}

export function ResidualOutputSettings(props: ResidualOutputSettingsProps) {
  const { jobActive, jobId, outputNode, param, projectId } = props;

  // == Contexts
  const { workflowId } = useProjectContext();

  // == Recoil
  const meshReadyState = useMeshReadyState(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  // == Data
  const nodeId = outputNode.id;

  return (
    <>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Options"
          nodeId={nodeId}
          panelName="options">
          <ResidualContent
            disabled={!meshReadyState}
            geometryTags={geometryTags}
            jobActive={jobActive}
            jobId={jobId}
            outputNode={outputNode}
            param={param}
            projectId={projectId}
            staticVolumes={staticVolumes}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </>
  );
}
