// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const WireframeIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="wireframe"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M12,0
           C5.37,0 0,5.37 0,12
           C0,18.63 5.37,24 12,24
           C18.63,24 24,18.63 24,12
           C24,5.37 18.63,0 12,0Z
           M19.755,7.5
           L16.095,7.5
           C15.78,5.835 15.33,4.455 14.73,3.465
           C16.875,4.155 18.645,5.61 19.755,7.5Z
           M15,12
           C15,13.095 14.925,14.085 14.82,15
           L9.18,15
           C9.075,14.085 9,13.095 9,12
           C9,10.905 9.075,9.915 9.18,9
           L14.82,9
           C14.925,9.915 15,10.905 15,12Z
           M12,3
           C13.005,3 14.04,4.65 14.595,7.5
           L9.405,7.5
           C9.96,4.65 10.995,3 12,3Z
           M9.27,3.465
           C8.67,4.455 8.205,5.835 7.905,7.5
           L4.245,7.5
           C5.355,5.61 7.125,4.155 9.27,3.465Z
           M3,12
           C3,10.95 3.195,9.945 3.525,9
           L7.665,9
           C7.56,9.93 7.5,10.92 7.5,12
           C7.5,13.08 7.56,14.07 7.665,15
           L3.525,15
           C3.195,14.055 3,13.05 3,12Z
           M4.245,16.5
           L7.905,16.5
           C8.22,18.165 8.67,19.545 9.27,20.535
           C7.125,19.845 5.355,18.39 4.245,16.5Z
           M12,21
           C10.995,21 9.96,19.35 9.405,16.5
           L14.595,16.5
           C14.04,19.35 13.005,21 12,21Z
           M14.73,20.535
           C15.33,19.545 15.78,18.165 16.095,16.5
           L19.755,16.5
           C18.645,18.39 16.875,19.845 14.73,20.535Z
           M20.475,15
           L16.335,15
           C16.44,14.07 16.5,13.08 16.5,12
           C16.5,10.92 16.44,9.93 16.335,9
           L20.475,9
           C20.805,9.945 21,10.95 21,12
           C21,13.05 20.805,14.055 20.475,15Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
