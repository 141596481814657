// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { QuantityType } from '../../../proto/quantity/quantity_pb';
import QuantityAdornment from '../../QuantityAdornment';
import ValidatedNumberSpinner, { ValidatedNumberSpinnerProps } from '../../controls/ValidatedNumberSpinner';
import LabeledInput from '../LabeledInput';

export interface RadiusProps extends ValidatedNumberSpinnerProps {
  label?: string;
  help?: string;
}

export interface SingleRadiusProps {
  type: 'single';
  inputProps: RadiusProps
}

export interface DoubleRadiusProps {
  type: 'double';
  innerProps: RadiusProps;
  outerProps: RadiusProps;
}

export type RadiusInputProps = SingleRadiusProps | DoubleRadiusProps;

export const RadiusInput = (props: RadiusInputProps) => {
  const { type } = props;

  if (type === 'single') {
    const { inputProps } = props;
    const { help, label = 'Radius' } = inputProps;

    const validate = (value: number): string => {
      if (value <= 0) {
        return 'Radius must be > 0';
      }
      return '';
    };

    return (
      <LabeledInput help={help} label={label}>
        <ValidatedNumberSpinner
          {...inputProps}
          endAdornment={<QuantityAdornment quantity={QuantityType.LENGTH} />}
          exclusiveMin
          messageOnError={validate}
          minimumValue={0}
          step={{ scaleFactor: 2 }}
        />
      </LabeledInput>
    );
  }

  const { innerProps, outerProps } = props;
  const { help: innerHelp, label: innerLabel = 'Inner Radius', value: innerValue } = innerProps;
  const { help: outerHelp, label: outerLabel = 'Outer Radius', value: outerValue } = outerProps;

  // The inner radius is allowed to be 0, but the outer radius must be greater than 0
  // The inner radius must be less than the outer radius.
  const validateInner = (value: number): string => {
    if (outerValue !== null && value >= outerValue) {
      return 'Inner radius must be < outer radius';
    }
    if (value < 0) {
      return 'Inner radius must be >= 0';
    }
    return '';
  };

  const validateOuter = (value: number): string => {
    if (innerValue !== null && value <= innerValue) {
      return 'Outer radius must be > inner radius';
    }
    if (value <= 0) {
      return 'Outer radius must be > 0';
    }
    return '';
  };

  return (
    <>
      <LabeledInput help={innerHelp} label={innerLabel}>
        <ValidatedNumberSpinner
          {...innerProps}
          endAdornment={<QuantityAdornment quantity={QuantityType.LENGTH} />}
          exclusiveMax
          maximumValue={outerValue ?? undefined}
          messageOnError={validateInner}
          minimumValue={0}
          step={{ scaleFactor: 2 }}
        />
      </LabeledInput>
      <LabeledInput help={outerHelp} label={outerLabel}>
        <ValidatedNumberSpinner
          {...outerProps}
          endAdornment={<QuantityAdornment quantity={QuantityType.LENGTH} />}
          exclusiveMin
          messageOnError={validateOuter}
          minimumValue={innerValue ?? 0}
          step={{ scaleFactor: 2 }}
        />
      </LabeledInput>
    </>
  );
};
