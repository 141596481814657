// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as simulationpb from '../proto/client/simulation_pb';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { FrontendGeometryContacts } from '../recoil/geometryContactsState';
import { StaticVolume } from '../recoil/volumes';

import { getPhysicsContainingSurfaces } from './physicsUtils';

/**
 * Search for a contact by ID and return the result or undefined if not found
 * @param contacts
 * @param id
 * @returns
 */
export function findContactById(contacts: FrontendGeometryContacts, id: string) {
  return contacts.contacts.find((item) => item.id === id);
}

/**
 * Return the list of physics that contain all of a contacts surfaces
 * @param contact
 * @param simParam
 * @param staticVolumes
 * @returns list of physics
 */
export function relatedPhysicsList(
  contact: frontendpb.GetGeometryContactsReply_GeoContact,
  simParam: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
): simulationpb.Physics[] {
  const surfaceIds = new Set([...contact.sideA, ...contact.sideB]);
  return getPhysicsContainingSurfaces(simParam, surfaceIds, geometryTags, staticVolumes);
}
