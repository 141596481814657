// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/frontend/output/output.proto (package luminary.proto.frontend.output, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { QuantityType } from "../../quantity/quantity_pb.js";
import { Vector3Component } from "../../base/base_pb.js";
import { BasicType, ForceProperties, PointProbeType, ResidualProperties, StoppingConditionOp, SurfaceAverageProperties, VolumeReductionProperties } from "../../output/output_pb.js";
import { ReferenceValues } from "../../output/reference_values_pb.js";

/**
 * Individual outputs included in an outputNode
 *
 * @generated from enum luminary.proto.frontend.output.OutputIncludes
 */
export const OutputIncludes = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.output.OutputIncludes",
  [
    {no: 0, name: "INVALID_OUTPUT_INCLUDE"},
    {no: 1, name: "OUTPUT_INCLUDE_BASE"},
    {no: 2, name: "OUTPUT_INCLUDE_TIME_AVERAGE"},
    {no: 3, name: "OUTPUT_INCLUDE_COEFFICIENT"},
    {no: 4, name: "OUTPUT_INCLUDE_COEFFICIENT_TIME_AVERAGE"},
    {no: 5, name: "OUTPUT_INCLUDE_RESIDUAL"},
    {no: 6, name: "OUTPUT_INCLUDE_MAX_DEV"},
    {no: 7, name: "OUTPUT_INCLUDE_INNER"},
  ],
);

/**
 * @generated from enum luminary.proto.frontend.output.CalculationType
 */
export const CalculationType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.output.CalculationType",
  [
    {no: 0, name: "INVALID_CALCULATION_TYPE"},
    {no: 1, name: "CALCULATION_AGGREGATE"},
    {no: 2, name: "CALCULATION_PER_SURFACE"},
    {no: 3, name: "CALCULATION_DIFFERENCE"},
  ],
);

/**
 * @generated from message luminary.proto.frontend.output.SurfaceAverageNode
 */
export const SurfaceAverageNode = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.SurfaceAverageNode",
  () => [
    { no: 1, name: "quantity_type", kind: "enum", T: proto3.getEnumType(QuantityType) },
    { no: 5, name: "vector_component", kind: "enum", T: proto3.getEnumType(Vector3Component) },
    { no: 2, name: "props", kind: "message", T: SurfaceAverageProperties },
    { no: 3, name: "include_time_average", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "include_instantaneous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.output.ForceNode
 */
export const ForceNode = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.ForceNode",
  () => [
    { no: 1, name: "quantity_type", kind: "enum", T: proto3.getEnumType(QuantityType) },
    { no: 2, name: "props", kind: "message", T: ForceProperties },
    { no: 3, name: "include_dimensional", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "include_coefficient", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "include_dimensional_average", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "include_coefficient_average", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.output.ResidualNode
 */
export const ResidualNode = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.ResidualNode",
  () => [
    { no: 1, name: "props", kind: "message", T: ResidualProperties },
    { no: 2, name: "res_type_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, repeated: true },
    { no: 3, name: "res_enabled", kind: "map", K: 5 /* ScalarType.INT32 */, V: {kind: "scalar", T: 8 /* ScalarType.BOOL */} },
    { no: 4, name: "physics_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.output.ConvergenceMonitorNode
 * @deprecated
 */
export const ConvergenceMonitorNode = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.ConvergenceMonitorNode",
  () => [
    { no: 1, name: "conv_monitor_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, repeated: true },
    { no: 2, name: "nodes", kind: "message", T: OutputNode, repeated: true },
  ],
);

/**
 * Basic quantity node that does not have any options
 *
 * @generated from message luminary.proto.frontend.output.BasicNode
 */
export const BasicNode = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.BasicNode",
  () => [
    { no: 1, name: "quantity_type", kind: "enum", T: proto3.getEnumType(QuantityType) },
    { no: 2, name: "props", kind: "message", T: BasicType },
  ],
);

/**
 * @generated from message luminary.proto.frontend.output.PointProbeNode
 */
export const PointProbeNode = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.PointProbeNode",
  () => [
    { no: 1, name: "quantity_type", kind: "enum", T: proto3.getEnumType(QuantityType) },
    { no: 3, name: "vector_component", kind: "enum", T: proto3.getEnumType(Vector3Component) },
    { no: 2, name: "props", kind: "message", T: PointProbeType },
  ],
);

/**
 * @generated from message luminary.proto.frontend.output.VolumeReductionNode
 */
export const VolumeReductionNode = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.VolumeReductionNode",
  () => [
    { no: 1, name: "quantity_type", kind: "enum", T: proto3.getEnumType(QuantityType) },
    { no: 3, name: "vector_component", kind: "enum", T: proto3.getEnumType(Vector3Component) },
    { no: 2, name: "props", kind: "message", T: VolumeReductionProperties },
  ],
);

/**
 * @generated from message luminary.proto.frontend.output.DerivedNodeDependency
 */
export const DerivedNodeDependency = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.DerivedNodeDependency",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "include", kind: "enum", T: proto3.getEnumType(OutputIncludes) },
  ],
);

/**
 * @generated from message luminary.proto.frontend.output.ExpressionElement
 */
export const ExpressionElement = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.ExpressionElement",
  () => [
    { no: 1, name: "substring", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "element_type" },
    { no: 2, name: "dependency", kind: "message", T: DerivedNodeDependency, oneof: "element_type" },
  ],
);

/**
 * @generated from message luminary.proto.frontend.output.DerivedNode
 */
export const DerivedNode = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.DerivedNode",
  () => [
    { no: 1, name: "elements", kind: "message", T: ExpressionElement, repeated: true },
    { no: 2, name: "errors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.output.OutputNode
 */
export const OutputNode = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.OutputNode",
  () => [
    { no: 1, name: "surface_average", kind: "message", T: SurfaceAverageNode, oneof: "node_props" },
    { no: 2, name: "force", kind: "message", T: ForceNode, oneof: "node_props" },
    { no: 3, name: "residual", kind: "message", T: ResidualNode, oneof: "node_props" },
    { no: 9, name: "conv_mon", kind: "message", T: ConvergenceMonitorNode, oneof: "node_props" },
    { no: 11, name: "basic", kind: "message", T: BasicNode, oneof: "node_props" },
    { no: 13, name: "point_probe", kind: "message", T: PointProbeNode, oneof: "node_props" },
    { no: 19, name: "derived", kind: "message", T: DerivedNode, oneof: "node_props" },
    { no: 20, name: "volume_reduction", kind: "message", T: VolumeReductionNode, oneof: "node_props" },
    { no: 4, name: "calc_type", kind: "enum", T: proto3.getEnumType(CalculationType) },
    { no: 5, name: "in_surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "out_surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "choice", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "type", kind: "enum", T: proto3.getEnumType(OutputNode_Type) },
    { no: 14, name: "include", kind: "map", K: 5 /* ScalarType.INT32 */, V: {kind: "scalar", T: 8 /* ScalarType.BOOL */} },
    { no: 15, name: "average_iters", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "analysis_iters", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 17, name: "trail_avg_iters", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 18, name: "frame_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from enum luminary.proto.frontend.output.OutputNode.Type
 */
export const OutputNode_Type = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.output.OutputNode.Type",
  [
    {no: 0, name: "INVALID_OUTPUT_TYPE"},
    {no: 1, name: "POINT_OUTPUT_TYPE"},
    {no: 2, name: "SURFACE_OUTPUT_TYPE"},
    {no: 3, name: "GLOBAL_OUTPUT_TYPE"},
    {no: 4, name: "DERIVED_OUTPUT_TYPE"},
    {no: 5, name: "VOLUME_OUTPUT_TYPE"},
  ],
);

/**
 * @generated from message luminary.proto.frontend.output.OutputNodes
 */
export const OutputNodes = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.OutputNodes",
  () => [
    { no: 1, name: "nodes", kind: "message", T: OutputNode, repeated: true },
    { no: 2, name: "initialized", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "reference_values", kind: "message", T: ReferenceValues },
    { no: 4, name: "use_ref_values", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.output.StoppingCondition
 */
export const StoppingCondition = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.StoppingCondition",
  () => [
    { no: 1, name: "legacy_node_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "threshold", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "n_iterations", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "start_at", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "node", kind: "message", T: OutputNode },
    { no: 6, name: "inner", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "n_analysis_iters", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.output.StoppingConditions
 */
export const StoppingConditions = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.output.StoppingConditions",
  () => [
    { no: 1, name: "cond", kind: "message", T: StoppingCondition, repeated: true },
    { no: 2, name: "max_iterations", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "max_physical_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "op", kind: "enum", T: proto3.getEnumType(StoppingConditionOp) },
    { no: 5, name: "max_inner_iterations", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

