// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 6;
export const HorizontalCirclesTripleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="horizontalCirclesTriple"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M6,3
           C6,4.657 4.657,6 3,6
           C1.343,6 0,4.657 0,3
           C0,1.343 1.343,-0 3,-0
           C4.657,-0 6,1.343 6,3Z
           M24,3
           C24,4.657 22.657,6 21,6
           C19.343,6 18,4.657 18,3
           C18,1.343 19.343,-0 21,-0
           C22.657,-0 24,1.343 24,3Z
           M12,6
           C13.657,6 15,4.657 15,3
           C15,1.343 13.657,-0 12,-0
           C10.343,-0 9,1.343 9,3
           C9,4.657 10.343,6 12,6Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
