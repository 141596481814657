// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const GeometrySubtractIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="geometrySubtract"
      height={finalHeight}
      ref={ref}
      style={{
        strokeMiterlimit: 2,
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        fill: getColor(0),
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M8,16C3.585,16 0,12.415 0,8C0,3.585 3.585,0 8,0C12.415,0 16,3.585 16,8L24,8L24,24L8,
           24L8,16ZM8,1.9C4.633,1.9 1.9,4.633 1.9,8C1.9,11.367 4.633,14.1 8,14.1C11.367,14.1 14.1,
           11.367 14.1,8C14.1,4.633 11.367,1.9 8,1.9Z"
      />
    </svg>
  );
});
