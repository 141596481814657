// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/meshgeneration/meshgeneration.proto (package luminary.proto.meshgeneration, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { AnnularCylinder, Cube, Cylinder, HalfSphere, OrientedCube, Sphere, SphereShell } from "../cad/shape_pb.js";
import { EntityGroups } from "../entitygroup/entitygroup_pb.js";
import { AdVector3, Vector3 } from "../base/base_pb.js";

/**
 * Internally configured settings for the mesh generation flow.
 *
 * @generated from message luminary.proto.meshgeneration.MeshGenerationSettings
 */
export const MeshGenerationSettings = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.MeshGenerationSettings",
  () => [
    { no: 1, name: "cad_preprocessing", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "meshing_options", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * User provided "geometry" information
 *
 * @generated from message luminary.proto.meshgeneration.UserGeometry
 */
export const UserGeometry = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.UserGeometry",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scaling", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 16, name: "allow_parasolid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "lcsurface_tessellation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * User requested "geometry" modifications
 *
 * @generated from message luminary.proto.meshgeneration.UserGeometryMod
 */
export const UserGeometryMod = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.UserGeometryMod",
  () => [
    { no: 1, name: "sphere", kind: "message", T: Sphere, oneof: "far_field" },
    { no: 2, name: "half_sphere", kind: "message", T: HalfSphere, oneof: "far_field" },
    { no: 3, name: "cube", kind: "message", T: Cube, oneof: "far_field" },
    { no: 4, name: "cylinder", kind: "message", T: Cylinder, oneof: "far_field" },
  ],
);

/**
 * User provided meshing parameters
 *
 * IMPORTANT: if making changes to the contents of UserMeshingParams, make sure
 * to keep MeshParams in proto/api/v0/luminarycloud/mesh/mesh.proto in sync and update
 * all the Golang code that writes/reads MeshParams from the DB.  This is needed
 * until the mesh params migration is done, likely as part of implementing
 * meshing in the API (LC-13297).  See details in LC-16188.
 *
 * @generated from message luminary.proto.meshgeneration.UserMeshingParams
 */
export const UserMeshingParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.UserMeshingParams",
  () => [
    { no: 1, name: "global_min_size_m", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "global_max_size_m", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "model_surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "model_edge_curvature_deg", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "model_face_curvature_deg", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "model_max_size", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "bl_surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "bl_n_layers", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 9, name: "bl_initial_size", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "bl_grow_rate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "entity_groups", kind: "message", T: EntityGroups },
    { no: 12, name: "proximity_layers", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 13, name: "mesh_refinements", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 14, name: "advanced_meshing_parameters", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "body_x_axis", kind: "message", T: AdVector3 },
    { no: 16, name: "body_y_axis", kind: "message", T: AdVector3 },
    { no: 17, name: "add_refinement", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "dualize", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "max_cells", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 20, name: "max_time", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * The meshing parameters for multi-part CAD meshing. This will eventually
 * replace UserMeshingParams. The difference is this allows different meshing
 * parameters for different parts. This proto is similar to BLMesh in
 * proto/operators/blmesh/blmesh.proto. We want to have parallel protos so that
 * the frontend and backend protos can be edited independently.
 *
 * IMPORTANT: if making changes to the contents of MeshingMultiPart, make sure
 * to keep MeshParams in proto/api/v0/luminarycloud/mesh/mesh.proto in sync and update
 * all the Golang code that writes/reads MeshParams from the DB.  This is needed
 * until the mesh params migration is done, likely as part of implementing
 * meshing in the API (LC-13297).  See details in LC-16188.
 *
 * @generated from message luminary.proto.meshgeneration.MeshingMultiPart
 */
export const MeshingMultiPart = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.MeshingMultiPart",
  () => [
    { no: 1, name: "volume_params", kind: "message", T: MeshingMultiPart_VolumeParams, repeated: true },
    { no: 2, name: "model_params", kind: "message", T: MeshingMultiPart_ModelParams, repeated: true },
    { no: 3, name: "bl_params", kind: "message", T: MeshingMultiPart_BoundaryLayerParams, repeated: true },
    { no: 4, name: "refinement_params", kind: "message", T: MeshingMultiPart_RefinementRegionParams, repeated: true },
    { no: 6, name: "overset_params", kind: "message", T: MeshingMultiPart_OversetParams, repeated: true },
    { no: 5, name: "complexity_params", kind: "message", T: MeshingMultiPart_MeshComplexityParams },
    { no: 7, name: "meshing_mode", kind: "message", T: MeshingMultiPart_MeshingMode },
  ],
);

/**
 * Volume meshing parameters
 *
 * @generated from message luminary.proto.meshgeneration.MeshingMultiPart.VolumeParams
 */
export const MeshingMultiPart_VolumeParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.MeshingMultiPart.VolumeParams",
  () => [
    { no: 1, name: "min_size", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "max_size", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "volumes", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "selection", kind: "enum", T: proto3.getEnumType(MeshingMultiPart_VolumeParams_SelectionType) },
  ],
  {localName: "MeshingMultiPart_VolumeParams"},
);

/**
 * The state of the "Applies to" dropdown menu. This is purely a frontend
 * state. The value in volumes should match this value.
 *
 * @generated from enum luminary.proto.meshgeneration.MeshingMultiPart.VolumeParams.SelectionType
 */
export const MeshingMultiPart_VolumeParams_SelectionType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.meshgeneration.MeshingMultiPart.VolumeParams.SelectionType",
  [
    {no: 0, name: "ALL"},
    {no: 1, name: "SELECTED"},
  ],
);

/**
 * Model meshing parameters
 *
 * @generated from message luminary.proto.meshgeneration.MeshingMultiPart.ModelParams
 */
export const MeshingMultiPart_ModelParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.MeshingMultiPart.ModelParams",
  () => [
    { no: 1, name: "curvature", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "max_size", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "selection", kind: "enum", T: proto3.getEnumType(MeshingMultiPart_ModelParams_SelectionType) },
  ],
  {localName: "MeshingMultiPart_ModelParams"},
);

/**
 * The state of the "Applies to" dropdown menu. This is purely a frontend
 * state. The value in surfaces should match this value.
 *
 * @generated from enum luminary.proto.meshgeneration.MeshingMultiPart.ModelParams.SelectionType
 */
export const MeshingMultiPart_ModelParams_SelectionType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.meshgeneration.MeshingMultiPart.ModelParams.SelectionType",
  [
    {no: 0, name: "ALL"},
    {no: 1, name: "WALL"},
    {no: 2, name: "SELECTED"},
    {no: 3, name: "NONE"},
  ],
);

/**
 * Boundary layer meshing parameters
 *
 * @generated from message luminary.proto.meshgeneration.MeshingMultiPart.BoundaryLayerParams
 */
export const MeshingMultiPart_BoundaryLayerParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.MeshingMultiPart.BoundaryLayerParams",
  () => [
    { no: 1, name: "n_layers", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "initial_size", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "growth_rate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "selection", kind: "enum", T: proto3.getEnumType(MeshingMultiPart_BoundaryLayerParams_SelectionType) },
  ],
  {localName: "MeshingMultiPart_BoundaryLayerParams"},
);

/**
 * The state of the "Applies to" dropdown menu. This is purely a frontend
 * state. The value in surfaces should match this value.
 *
 * @generated from enum luminary.proto.meshgeneration.MeshingMultiPart.BoundaryLayerParams.SelectionType
 */
export const MeshingMultiPart_BoundaryLayerParams_SelectionType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.meshgeneration.MeshingMultiPart.BoundaryLayerParams.SelectionType",
  [
    {no: 0, name: "ALL"},
    {no: 1, name: "WALL_NO_SLIP"},
    {no: 2, name: "SELECTED"},
    {no: 3, name: "NONE"},
  ],
);

/**
 * Refinement region parameters (duplicated from blmesh.proto)
 *
 * @generated from message luminary.proto.meshgeneration.MeshingMultiPart.RefinementRegionParams
 */
export const MeshingMultiPart_RefinementRegionParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.MeshingMultiPart.RefinementRegionParams",
  () => [
    { no: 1, name: "h_limit", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "sphere", kind: "message", T: Sphere, oneof: "shape" },
    { no: 3, name: "sphere_shell", kind: "message", T: SphereShell, oneof: "shape" },
    { no: 4, name: "cube", kind: "message", T: Cube, oneof: "shape" },
    { no: 5, name: "oriented_cube", kind: "message", T: OrientedCube, oneof: "shape" },
    { no: 6, name: "cylinder", kind: "message", T: Cylinder, oneof: "shape" },
    { no: 7, name: "annular_cylinder", kind: "message", T: AnnularCylinder, oneof: "shape" },
    { no: 8, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "rotation", kind: "message", T: Vector3 },
  ],
  {localName: "MeshingMultiPart_RefinementRegionParams"},
);

/**
 * Mesh complexity parameters
 *
 * @generated from message luminary.proto.meshgeneration.MeshingMultiPart.MeshComplexityParams
 */
export const MeshingMultiPart_MeshComplexityParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.MeshingMultiPart.MeshComplexityParams",
  () => [
    { no: 1, name: "target_cells", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "limit_max_cells", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(MeshingMultiPart_MeshComplexityParams_ComplexityType) },
  ],
  {localName: "MeshingMultiPart_MeshComplexityParams"},
);

/**
 * Which of the values are we using to define complexity. TARGET means
 * target_cells is being used and the limit_max_cells should be 0. This is
 * used to see the radio button in the UI.
 *
 * @generated from enum luminary.proto.meshgeneration.MeshingMultiPart.MeshComplexityParams.ComplexityType
 */
export const MeshingMultiPart_MeshComplexityParams_ComplexityType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.meshgeneration.MeshingMultiPart.MeshComplexityParams.ComplexityType",
  [
    {no: 0, name: "MAX"},
    {no: 1, name: "TARGET"},
    {no: 2, name: "MIN"},
  ],
);

/**
 * Overset meshing parameters
 *
 * @generated from message luminary.proto.meshgeneration.MeshingMultiPart.OversetParams
 */
export const MeshingMultiPart_OversetParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.MeshingMultiPart.OversetParams",
  () => [
    { no: 1, name: "surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "MeshingMultiPart_OversetParams"},
);

/**
 * Mesh mode
 *
 * @generated from message luminary.proto.meshgeneration.MeshingMultiPart.MeshingMode
 */
export const MeshingMultiPart_MeshingMode = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.MeshingMultiPart.MeshingMode",
  () => [
    { no: 1, name: "default", kind: "message", T: MeshingMultiPart_MeshingMode_Default, oneof: "mode" },
    { no: 2, name: "base", kind: "message", T: MeshingMultiPart_MeshingMode_Base, oneof: "mode" },
  ],
  {localName: "MeshingMultiPart_MeshingMode"},
);

/**
 * Additional parameters for default meshing mode
 *
 * @generated from message luminary.proto.meshgeneration.MeshingMultiPart.MeshingMode.Default
 */
export const MeshingMultiPart_MeshingMode_Default = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.MeshingMultiPart.MeshingMode.Default",
  [],
  {localName: "MeshingMultiPart_MeshingMode_Default"},
);

/**
 * Additional parameters for base meshing mode
 *
 * @generated from message luminary.proto.meshgeneration.MeshingMultiPart.MeshingMode.Base
 */
export const MeshingMultiPart_MeshingMode_Base = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.MeshingMultiPart.MeshingMode.Base",
  [],
  {localName: "MeshingMultiPart_MeshingMode_Base"},
);

/**
 * Tracks the visibility of each refinement region on a per-project basis.
 *
 * @generated from message luminary.proto.meshgeneration.RefinementRegionVisibilityMap
 */
export const RefinementRegionVisibilityMap = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.RefinementRegionVisibilityMap",
  () => [
    { no: 1, name: "visibilities", kind: "message", T: RefinementRegionVisibilityMap_Entry, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.meshgeneration.RefinementRegionVisibilityMap.Entry
 */
export const RefinementRegionVisibilityMap_Entry = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.meshgeneration.RefinementRegionVisibilityMap.Entry",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "visible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "RefinementRegionVisibilityMap_Entry"},
);

