// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { memo } from 'react';

import { Tooltip as MUITooltip, TooltipProps as MUITooltipProps } from '@mui/material';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  ({ palette }) => createStyles({
    arrow: {
      color: palette.common.black,
    },
    tooltip: {
      backgroundColor: palette.common.black,
      fontSize: '13px',
      padding: '8px 12px',
    },
    // This is styling for the tooltip when the arrow is hidden -- for example, in the dot toggle
    // of the notifications center.
    noArrowTooltip: {
      backgroundColor: colors.neutral250,
      fontSize: '13px',
      padding: '8px 12px',
      boxShadow: '0px 4px 8px rgba(24, 25, 30, 0.3)',
    },
    // This is a fix for LC-6983 where clicking the tab (in some cases) forces the tooltip to
    // detach from the tab and to "run away" to the top left corner. Hopefully this is a temporary
    // fix until we get rid of the Material UI Tooltip (make sure we don't regress then).
    popper: {
      '&[data-popper-reference-hidden]': {
        visibility: 'hidden',
        pointerEvents: 'none',
      },
    },
    noArrowPopper: {
      '&[data-popper-reference-hidden]': {
        pointerEvents: 'none',
      },
    },
  }),
  { name: 'Tooltip' },
);

export interface TooltipProps extends MUITooltipProps { }

/**
 * Styled version of MUI Tooltip component.
 * https://material-ui.com/components/tooltips/
 */
const Tooltip = memo((props: TooltipProps) => {
  const { disableInteractive = true, ...otherProps } = props;
  const classes = useStyles();
  const showArrow = props.arrow ?? true;
  return (
    <MUITooltip
      {...otherProps}
      arrow={showArrow}
      classes={{
        ...classes,
        tooltip: showArrow ? classes.tooltip : classes.noArrowTooltip,
        popper: showArrow ? classes.popper : classes.noArrowPopper,
      }}
      disableInteractive={disableInteractive}
    />
  );
});

Tooltip.displayName = 'Tooltip';

export default Tooltip;
