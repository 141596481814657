// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { MultipleChoiceParam } from '../../ProtoDescriptor';
import { ParamName, paramDesc } from '../../SimulationParamDescriptor';
import { disableTransientTabularProfile } from '../../lib/flowBehaviorUtils';
import { getOrCreateGeneral } from '../../lib/simulationParamUtils';
import * as simulationpb from '../../proto/client/simulation_pb';

import { useWorkflowConfig } from './useWorkflowConfig';

const { GRAVITY_OFF, GRAVITY_ON } = simulationpb.Gravity;
const flowBehaviorParam = paramDesc[ParamName.FlowBehavior] as MultipleChoiceParam;

/**
 * Model hook for managing the General data structure at the SimluationParam root that exposes
 * settings like FlowBehavior (Steady vs. Transient) and Gravity
 * @param projectId
 * @param workflowId
 * @param jobId
 * @param readOnly
 * @returns
 */
export const useGeneral = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
) => {
  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  const general = getOrCreateGeneral(simParam);
  const { flowBehavior, solverParams } = general;
  const gravityOn = general.gravity === simulationpb.Gravity.GRAVITY_ON;

  const setGeneral = useCallback(
    async (value: simulationpb.General) => saveParamAsync((newParam) => {
      newParam.general = value;
    }),
    [saveParamAsync],
  );

  const setFlowBehavior = useCallback(
    async (value: simulationpb.FlowBehavior) => saveParamAsync(
      (newParam) => {
        getOrCreateGeneral(newParam).flowBehavior = value;
        if (value === simulationpb.FlowBehavior.STEADY) {
          // if the user selects steady state, reset those settings that require a transient sim
          disableTransientTabularProfile(newParam);
        }
        return newParam;
      },
    ),
    [saveParamAsync],
  );

  const setGravityOn = useCallback(
    async (value: boolean) => saveParamAsync((newParam) => {
      getOrCreateGeneral(newParam).gravity = value ? GRAVITY_ON : GRAVITY_OFF;
    }),
    [saveParamAsync],
  );

  const setSolverParams = useCallback(
    async (value) => saveParamAsync((newParam) => {
      getOrCreateGeneral(newParam).solverParams = value;
    }),
    [saveParamAsync],
  );

  const setAdjoint = useCallback(
    async (value: simulationpb.Adjoint) => saveParamAsync((newParam) => {
      newParam.adjoint = value;
    }),
    [saveParamAsync],
  );

  return {
    flowBehavior,
    setFlowBehavior,
    flowBehaviorTypes: flowBehaviorParam.choices,
    gravityOn,
    setGravityOn,
    general,
    setGeneral,
    solverParams,
    setSolverParams,
    adjoint: simParam.adjoint,
    setAdjoint,
  };
};
