// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 16;
export const IntersectingRingsIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="intersectingRings"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M16,0
           C14.528,0 13.184,0.416 12,1.104
           C10.816,0.416 9.472,0 8,0
           C3.584,0 0,3.584 0,8
           C0,12.416 3.584,16 8,16
           C9.472,16 10.816,15.584 12,14.896
           C13.184,15.584 14.528,16 16,16
           C20.416,16 24,12.416 24,8
           C24,3.584 20.416,0 16,0Z
           M9.44,12.56
           C8.976,12.704 8.496,12.8 8,12.8
           C5.344,12.8 3.2,10.656 3.2,8
           C3.2,5.344 5.344,3.2 8,3.2
           C8.496,3.2 8.976,3.296 9.44,3.44
           C8.528,4.736 8,6.304 8,8
           C8,9.696 8.528,11.264 9.44,12.56Z
           M16,12.8
           C15.504,12.8 15.024,12.704 14.56,12.56
           C15.472,11.264 16,9.696 16,8
           C16,6.304 15.472,4.736 14.56,3.44
           C15.024,3.296 15.504,3.2 16,3.2
           C18.656,3.2 20.8,5.344 20.8,8
           C20.8,10.656 18.656,12.8 16,12.8Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
