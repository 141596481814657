// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { upperFirst } from '../../../lib/text';
import { ActionButton } from '../../Button/ActionButton';
import Tooltip from '../../Tooltip';
import Collapsible from '../../transition/Collapsible';

import { ListItem, ListItemContent } from './ListItem';

import './ListManager.scss';

const TRANSITION_DURATION = 350;

export type ListItemId = string;

// Configuration of each item passed to the ListManager component
export interface ListItemConfig {
  // A unique ID is required.
  id: ListItemId;
  // Describes how to render item content
  content: ListItemContent;
  // Optionally disable deletion for the item (default: true)
  canDelete?: boolean;
}

// Maintains operational state for each item
interface ListItemState {
  // The original, static list item config
  config: ListItemConfig;
  // Deleted items are collapsed before calling onDelete
  collapsed: boolean;
}

interface ListManagerOpState {
  listItems: ListItemState[];
  addedIds: ListItemId[];
}

export interface SortOptions {
  // Set to true to disable the Sort button
  isSorted: boolean;
  // Return just a list of reordered IDs for animation purposes
  getSortedIds: () => ListItemId[];
  // Sort items provided to ListManager
  sortItems: () => void;
  // Optional text to display in sorting button (default: 'Sort')
  buttonLabel?: string;
  // Optional help text to display as a tooltip over sorting button
  help?: string;
}

export interface ListManagerProps {
  // A list of current items to display and manage
  items: ListItemConfig[];
  // Called when the user clicks the add button
  onAdd: () => void;
  // Called when the user clicks an item's delete button
  onDelete: (id: ListItemId) => void;
  // If provided, this function triggers the appearance of up/down buttons to
  // move the item up or down in the list order, and it is called when either of
  // those buttons is clicked.
  onReorder?: (id: ListItemId, raiseIndex: boolean) => void;
  // Optionally expose a button to re-order entire list
  sorting?: SortOptions;
  // Optionally disable the add button (default: true)
  canAdd?: boolean;
  // Tooltip label for the add button if canAdd is false
  disabledReason?: ReactNode;
  // Optional label for buttons and tooltips
  itemLabel?: string;
  // Optionally disable all controls (overrides canAdd)
  disabled?: boolean;
  // Set animateNew to false if list item content isn't rendering consistently.
  // By default, ListManager attempts to animate (via Collapsible) new entries, including initial
  // list items.  Environments where multiple re-renders happen in succession seem to disrupt the
  // rendering of either list item content or the Collapsible wrapper.
  animateNew?: boolean;
}

export const ListManager = (props: ListManagerProps) => {
  const {
    animateNew = true,
    canAdd,
    disabledReason,
    disabled = false,
    items,
    onAdd,
    onDelete,
    onReorder,
    sorting,
  } = props;

  const itemLabel = props.itemLabel || 'item';
  // When an item is added, we need to track the new IDs so that the new content
  // can be expanded with animation.  Conversely, when an item is deleted, we
  // keep its ID around so that we can collapse the content with animation.
  // 'opState' lets us manage and track ephemoral item state independently of
  // the 'items' prop.
  const [opState, setOpState] = useState<ListManagerOpState>({
    listItems: [],
    addedIds: [],
  });

  // Track when items are being animated (from changing items)
  const [animating, setAnimating] = useState(false);
  // Track DOM nodes for each item, so that we can do some animation magic when
  // items are reordered.
  const itemRefs = useRef<Array<HTMLDivElement | null>>([]);
  // To animate reordering, we apply vertical offsets to items at specific
  // indices
  const [itemOffsets, setItemOffsets] = useState<Record<number, number>>({});

  useEffect(() => {
    // When we have new IDs, their 'collapsed' states will have been set to
    // true.  Here, we set collapsed to false for all items to open the new
    // item content.
    if (opState.addedIds.length) {
      const newStates = opState.listItems.map(
        (itemState) => ({ ...itemState, collapsed: false }),
      );

      setAnimating(true);
      setOpState({
        ...opState,
        listItems: newStates,
        addedIds: [],
      });

      setTimeout(() => {
        setAnimating(false);
      }, TRANSITION_DURATION);
    }
  }, [opState]);

  useEffect(() => {
    // When 'items' changes, we do the following:
    //   [a] Check for new IDs (for animation purposes)
    //   [b] Set animating to false
    //   [c] Reset offsets related to reordering animations
    const newIds: ListItemId[] = [];

    itemRefs.current = itemRefs.current.slice(0, items.length);

    setItemOffsets({});
    setAnimating(false);

    const stateMap = opState.listItems.reduce((result, itemState) => {
      result[itemState.config.id] = itemState;
      return result;
    }, {} as Record<ListItemId, ListItemState>);

    const newStates = items.map((item, i) => {
      const { id: itemId } = item;

      let collapsed = true;
      if (itemId in stateMap) {
        // Existing item; carry over collapsed state
        collapsed = stateMap[item.id].collapsed;
      } else {
        // New item
        if (animateNew) { // eslint-disable-line no-lonely-if
          newIds.push(itemId);
        } else {
          collapsed = false;
        }
      }

      return {
        config: item,
        collapsed,
      };
    });

    setOpState({
      listItems: newStates,
      addedIds: newIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animateNew, items]);

  const deleteItem = (id: ListItemId) => {
    const state = opState.listItems.find((itemState) => itemState.config.id === id);

    if (state?.collapsed) {
      // If it's already collapsed, call onDelete immediately.
      onDelete(id);
    } else {
      // First set the deleted item's collapsed state to true to animate its
      // disapperance.  Then call onDelete, so that applications can remove it
      // from 'items'.
      const newStates = opState.listItems.map((itemState) => {
        const newState = { ...itemState };

        if (newState.config.id === id) {
          newState.collapsed = true;
        }

        return newState;
      });

      setAnimating(true);
      setOpState({
        ...opState,
        listItems: newStates,
      });

      // Wait until the animation ends before calling onDelete
      setTimeout(() => onDelete(id), TRANSITION_DURATION);
    }
  };

  // Given a list item ID to reorder, return the index and DOM node for the list
  // item and the adjacent item.  These two items will switch places.
  const getReorderRefs = (id: ListItemId, raiseIndex: boolean) => {
    let itemRef: HTMLElement | null = null;
    let swapRef: HTMLElement | null = null;

    const itemIdx = items.findIndex((item) => (item.id === id));
    const swapIdx = items.findIndex((_, i) => {
      const locIdx = raiseIndex ? (i - 1) : (i + 1);
      return items[locIdx]?.id === id;
    });

    if ((itemIdx !== -1) || (swapIdx !== -1)) {
      itemRef = itemRefs.current[itemIdx];
      swapRef = itemRefs.current[swapIdx];
    }

    return [
      { idx: itemIdx, ref: itemRef },
      { idx: swapIdx, ref: swapRef },
    ];
  };

  // When reordering items, animate them switching places and then call the
  // onReorder callback so that applications can update their order in the
  // 'items' prop.
  const reorderItem = (id: ListItemId, raiseIndex: boolean) => {
    if (!onReorder) {
      return;
    }

    const [
      { idx: itemIdx, ref: itemRef },
      { idx: swapIdx, ref: swapRef },
    ] = getReorderRefs(id, raiseIndex);

    if (itemRef && swapRef) {
      const newOffsets: Record<number, number> = {};
      newOffsets[itemIdx] = (raiseIndex ? 1 : -1) * swapRef.offsetHeight;
      newOffsets[swapIdx] = (raiseIndex ? -1 : 1) * itemRef.offsetHeight;

      setAnimating(true);
      setItemOffsets({
        ...itemOffsets,
        ...newOffsets,
      });

      // Wait until the animation ends before calling onReorder
      setTimeout(() => onReorder(id, raiseIndex), TRANSITION_DURATION);
    } else {
      onReorder(id, raiseIndex);
    }
  };

  type IdNumberRecord = Record<ListItemId, number>;

  const sortItems = () => {
    if (!sorting) {
      return;
    }

    // Create a lookup record that maps item IDs to their current index
    const indexById = items.reduce((result, item, i) => {
      result[item.id] = i;
      return result;
    }, {} as IdNumberRecord);

    // Create a lookup record that maps item IDs to their DOM node heights
    const refHeightsById = items.reduce((result, item, i) => {
      result[item.id] = itemRefs.current[i]?.offsetHeight ?? 0;
      return result;
    }, {} as IdNumberRecord);

    // Create a lookup record that maps item IDs to their vertical offsets
    const currentPosnsById = items.reduce((result, item, i) => {
      if (i === 0) {
        result[item.id] = 0;
      } else {
        result[item.id] = items.slice(0, i).reduce(
          (sum, subItem) => sum + refHeightsById[subItem.id],
          0,
        );
      }
      return result;
    }, {} as IdNumberRecord);

    // Get sorted item IDs and generate offsets to animate position change
    const newOrderedIds = sorting.getSortedIds();
    const newOffsets: Record<number, number> = {};

    newOrderedIds.forEach((id, i) => {
      const newPosition = (i === 0) ?
        0 :
        newOrderedIds.slice(0, i).reduce((sum, id2) => sum + refHeightsById[id2], 0);

      const curIdx = indexById[id];
      newOffsets[curIdx] = newPosition - currentPosnsById[id];
    });

    setAnimating(true);
    setItemOffsets(newOffsets);

    // Call the function that triggers full sorting of items
    setTimeout(sorting.sortItems, TRANSITION_DURATION);
  };

  return (
    <div
      className={cx('listManager', { empty: !opState.listItems.length })}
      style={{
        '--transition-duration': `${TRANSITION_DURATION}ms`,
      } as CSSProperties}>
      <div className="itemsContent">
        {opState.listItems.map((itemState, i) => (
          <div
            className={cx('itemContainer', { animating })}
            key={itemState.config.id}
            ref={(el) => {
              itemRefs.current[i] = el;
            }}
            style={{
              transform: `translateY(${itemOffsets[i] || 0}px)`,
            }}>
            <Collapsible
              collapsed={itemState.collapsed}
              transitionPeriod={TRANSITION_DURATION}>
              <div className="itemWrapper">
                <ListItem
                  content={itemState.config.content}
                  renderArgs={{
                    canDelete: itemState.config.canDelete !== false,
                    canLowerIndex: i > 0,
                    canRaiseIndex: i < opState.listItems.length - 1,
                    disableControls: animating || disabled,
                    itemLabel,
                    onDelete: () => deleteItem(itemState.config.id),
                    onReorder: onReorder && (
                      (raiseIndex) => reorderItem(itemState.config.id, raiseIndex)
                    ),
                  }}
                />
              </div>
            </Collapsible>
          </div>
        ))}
      </div>
      <div className="controls">
        {sorting && (
          <ActionButton
            compact
            disabled={animating || disabled || sorting.isSorted}
            kind="minimal"
            name={`listManagerReorder::${itemLabel}`}
            onClick={sortItems}
            size="small"
            startIcon={{ name: 'listNumbered', maxHeight: 9 }}
            title={disabled ? '' : sorting.help}>
            {sorting.buttonLabel || 'Sort'}
          </ActionButton>
        )}
        <Tooltip title={disabledReason}>
          <span>
            <ActionButton
              compact
              disabled={animating || canAdd === false || disabled}
              kind="minimal"
              name={`listManagerAdd::${itemLabel}`}
              onClick={onAdd}
              size="small"
              startIcon={{ name: 'plus', maxHeight: 9 }}>
              Add {upperFirst(itemLabel)}
            </ActionButton>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
