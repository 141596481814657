// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { RecoilState } from 'recoil';

type SetterOrUpdater<T> = (newValue: ((currVal: T) => T) | T) => void;

// function to make Recoil SetterOrUpdater functions given state: an atom or selector
// example usage inside asyncModifySelection:
// const setDeferredOperations = makeSetterOrUpdater(set, deferredOperation);
export function makeSetterOrUpdater<T>(
  set: (recoilVal: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void,
  state: RecoilState<T>,
): SetterOrUpdater<T> {
  return (newValue: ((currVal: T) => T) | T) => {
    set(state, newValue);
  };
}

// function that takes an async function and returns it, but with an added
// check for errors. Useful for wrapping async recoil callbacks
export function asyncWrapper(asyncFun: (...args: any[]) => Promise<void>) {
  return (...args: any[]) => {
    asyncFun(...args).catch((error: Error) => {
      throw error;
    });
  };
}
