// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

// Parse a path and return its directory, basename, file name, and extension.
// NOTE: While the nodejs Path module is available in browser contexts, its
// parse() method is not.  This parse() implementation is based on the nodejs
// Path.parse() interface (see https://nodejs.org/api/path.html#pathparsepath),
// but it omits the 'root' member.
//
// Example:
//   parse('/home/johndoe/foo.txt')
//     ->
//   {
//     dir: '/home/johndoe',
//     base: 'foo.txt',
//     name: 'foo',
//     ext: '.txt',
//   }
export function parse(path: string) {
  let base = '';
  let dir = '';
  let ext = '';
  let name = '';

  const lastSlash = path.lastIndexOf('/');
  if (lastSlash <= 0) {
    base = path;
  } else {
    dir = path.substring(0, lastSlash);
    base = path.substring(lastSlash + 1);
  }

  const lastDot = base.lastIndexOf('.');
  if (lastDot <= 0) {
    name = base;
  } else {
    name = base.substring(0, lastDot);
    ext = base.substring(lastDot);
  }

  return { dir, base, name, ext };
}

// Extract the last component of the path. The path must not end with '/'.
//
// Example:
//   basename("http://foo/bar/baz.txt") -> "baz.txt"
export function basename(path: string): string {
  return parse(path).base;
}

// Extract a parameter from a URL.  A parameter can be separated by either '&'
// or '@', as defined in go/core/file, ParsedBase. Returns '' if the parameter
// is not found.
//
// Examples:
// urlParam("/foo/bar/sha256=12345&seq=1@rank=2&name=foo.txt", "seq") -> "1"
// urlParam("/foo/bar/sha256=12345&seq=1@rank=2&name=foo.txt", "rank") -> "2"
// urlParam("/foo/bar/sha256=12345&seq=1@rank=2&name=foo.txt", "sha256") -> "12345"
// urlParam("/foo/bar/sha256=12345&seq=1@rank=2&name=foo.txt", "name") -> "foo.txt"
// urlParam("http://foo.com/bar/?sha256=12345&name=foo.txt", "sha256") -> "12345"
// urlParam("sha256=12345&name=foo.txt", "sha256") -> "12345"
export function urlParam(url: string, searchParam: string): string {
  const paramsBase = basename(url);
  const paramsStripped = paramsBase.startsWith('?') ? paramsBase.substring(1) : paramsBase;
  const params = paramsStripped.split(/[&@]/);
  for (let i = 0; i < params.length; i += 1) {
    const param = params[i];
    if (param.startsWith(`${searchParam}=`)) {
      return param.substring(searchParam.length + 1);
    }
  }
  return '';
}

// Extract the part of the URL except for the trailing params.
// Used for extracting the front part of a URL, including the trailing slash.
//
// Example:
// urlPath("/foo/bar/sha256=12345&seq=1@rank=2&name=foo.txt") -> "/foo/bar/"
export function urlPath(url: string): string {
  const last = url.lastIndexOf('/');
  if (last <= 0) {
    return url;
  }
  return url.substring(0, last + 1);
}

// Extract the filename extension.
//
// Example:
//   extname("http://foo/bar/baz.txt") -> ".txt"
//   extname("http://foo/bar/baz") -> ""
export function extname(path: string): string {
  return parse(path).ext;
}

export function isLcMeshExtension(ext: string): boolean {
  return ext === '.lcmesh' || ext === '.lcmeshbundle';
}

export function isLcSolnExtension(ext: string): boolean {
  return ext === '.lcsoln' || ext === '.lcsolnbundle';
}

export function isLcExtension(ext: string): boolean {
  return isLcMeshExtension(ext) || isLcSolnExtension(ext);
}

/**
 * Get the URL up to the final slash.
 */
export const getUpToFinalSlash = (url: string): string => {
  const lastSlash = url.lastIndexOf('/');
  return url.substring(0, lastSlash);
};
