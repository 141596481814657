// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const RefreshIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="refresh"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M22.487,10.494
           C21.662,10.494 20.988,11.169 20.988,11.993
           C20.988,16.955 16.955,20.988 11.993,20.988
           C9.339,20.988 6.956,19.818 5.307,17.989
           L7.496,17.989
           C8.32,17.989 8.995,17.315 8.995,16.49
           C8.995,15.666 8.32,14.991 7.496,14.991
           L1.499,14.991
           C0.675,14.991 0,15.666 0,16.49
           L0,22.487
           C0,23.311 0.675,23.986 1.499,23.986
           C2.324,23.986 2.998,23.311 2.998,22.487
           L2.998,19.878
           C5.187,22.382 8.395,23.986 11.993,23.986
           C18.619,23.986 23.986,18.619 23.986,11.993
           C23.986,11.169 23.311,10.494 22.487,10.494Z
           M22.487,0
           C21.662,0 20.988,0.675 20.988,1.499
           L20.988,4.108
           C18.799,1.604 15.591,0 11.993,0
           C5.367,0 0,5.367 0,11.993
           C0,12.818 0.675,13.492 1.499,13.492
           C2.324,13.492 2.998,12.818 2.998,11.993
           C2.998,7.031 7.031,2.998 11.993,2.998
           C14.646,2.998 17.03,4.168 18.679,5.997
           L16.49,5.997
           C15.666,5.997 14.991,6.671 14.991,7.496
           C14.991,8.32 15.666,8.995 16.49,8.995
           L22.487,8.995
           C23.311,8.995 23.986,8.32 23.986,7.496
           L23.986,1.499
           C23.986,0.675 23.311,0 22.487,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
