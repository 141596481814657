// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Vector3 } from '../ProtoDescriptor';
import * as lcmeshpb from '../proto/lcn/lcmesh_pb';

export const DEGREES_TO_RADIANS = Math.PI / 180;

export interface Point {
  x: number;
  y: number;
}

export interface BoundingBox {
  min: Vector3;
  max: Vector3;
}

// Get the center-x value of an element's bounding box
export function getElementCenter(node: HTMLElement): Point {
  const box = node.getBoundingClientRect();
  return {
    x: box.left + (box.width / 2),
    y: box.top + (box.height / 2),
  };
}

// Applies a MeshStats bounding expansion to another bounding box and returns the result
export function expandBoundingBox(box: BoundingBox, expansion: lcmeshpb.MeshStats): BoundingBox {
  const newMin = { ...box.min };
  const newMax = { ...box.max };

  newMin.x = Math.min(expansion.minCoord!.x, newMin.x);
  newMin.y = Math.min(expansion.minCoord!.y, newMin.y);
  newMin.z = Math.min(expansion.minCoord!.z, newMin.z);
  newMax.x = Math.max(expansion.maxCoord!.x, newMax.x);
  newMax.y = Math.max(expansion.maxCoord!.y, newMax.y);
  newMax.z = Math.max(expansion.maxCoord!.z, newMax.z);

  return {
    min: newMin,
    max: newMax,
  };
}

// Return the bounding box that encapsulated both input boxes
export function mergeBoundingBoxes(box1: BoundingBox, box2: BoundingBox): BoundingBox {
  return {
    min: {
      x: Math.min(box1.min.x, box2.min.x),
      y: Math.min(box1.min.y, box2.min.y),
      z: Math.min(box1.min.z, box2.min.z),
    },
    max: {
      x: Math.max(box1.max.x, box2.max.x),
      y: Math.max(box1.max.y, box2.max.y),
      z: Math.max(box1.max.z, box2.max.z),
    },
  };
}

// Find the centroid of a bounding box by calculating midpoints for each axis and return the result
export function centroid(box: BoundingBox): Vector3 {
  return {
    x: (box.min.x + box.max.x) / 2,
    y: (box.min.y + box.max.y) / 2,
    z: (box.min.z + box.max.z) / 2,
  };
}
