// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as frontendpb from '../proto/frontend/frontend_pb';

export const SHARED_TO_SUPPORT_TOOLTIP = 'Luminary Support has access to this project.';
export const LUMINARY_SUPPORT_USER_LABEL = 'Luminary Support';

export const newProjectAclLCEntry = (
  lcUserId: string,
  roleList: string[],
  isSupport: boolean = false,
) => new frontendpb.ProjectAclEntry({
  subject: {
    id: isSupport ? '' : lcUserId,
    type: isSupport ? frontendpb.Subject_Type.SUPPORT : frontendpb.Subject_Type.LC_USER,
  },
  role: roleList,
});

export const isSupportAclEntry = (projectAcl: frontendpb.ProjectAclEntry) => (
  projectAcl.subject?.type === frontendpb.Subject_Type.SUPPORT
);

export const hasSupportAclEntry = (projectAclList: frontendpb.ProjectAclEntry[]) => (
  projectAclList.some((acl) => isSupportAclEntry(acl))
);
