// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, ReactNode } from 'react';

import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      gap: '8px',
      alignItems: 'baseline',
      justifyContent: 'flex-start',
    },
    bullet: {
      flex: '0 0 auto',
      width: 'var(--size)',
      height: '14px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '&:before': {
        content: '""',
        width: 'var(--size)',
        height: 'var(--size)',
        borderRadius: '50%',
        lineHeight: '14px',
        backgroundColor: 'var(--bullet-color, #fff)',
      },
    },
  }),
  { name: 'BulletedContent' },
);

/**
 * Primarily used by the data-driven Table component, the BulletedContent component lays out a
 * colored bullet next to some arbitrary content.
 */
export interface BulletedContentProps {
  // Required color
  bulletColor: string;
  // Optional size of the bullet (defaults to 8)
  // The bullet and content are laid out as a flexbox with 'baseline' alignment.  When `children`
  // is multi-lined content, we want the bullet to be more-or-less top-aligned, but when `children`
  // is a single line of text, they should be more-or-less centered.  Using 'baseline' alignment in
  // the flexbox CSS gets us pretty close, but the vertical centering for single-line content
  // depends entirely on the `children`'s font size.  Parent components can adjust the `size` prop
  // to tweak the vertical alignment.
  size?: number;
  children: ReactNode;
}

export const DEFAULT_SIZE = 8;

export const BulletedContent = (props: BulletedContentProps) => {
  const { bulletColor, children, size = DEFAULT_SIZE } = props;

  const classes = useStyles();
  return (
    <div
      className={classes.root}
      style={{ '--bullet-color': bulletColor, '--size': `${size}px` } as CSSProperties}>
      <div className={classes.bullet} />
      <div>{children}</div>
    </div>
  );
};
