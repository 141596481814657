// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const LogoutIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="logout"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M10.5,21
           L3,21
           L3,3
           L10.5,3
           C11.325,3 12,2.325 12,1.5
           C12,0.675 11.325,0 10.5,0
           L1.5,0
           C0.675,0 0,0.675 0,1.5
           L0,22.5
           C0,23.325 0.675,24 1.5,24
           L10.5,24
           C11.325,24 12,23.325 12,22.5
           C12,21.675 11.325,21 10.5,21Z
           M23.565,10.935
           L19.065,6.435
           C18.795,6.165 18.42,6 18,6
           C17.175,6 16.5,6.675 16.5,7.5
           C16.5,7.92 16.665,8.295 16.935,8.565
           L18.885,10.5
           L9,10.5
           C8.175,10.5 7.5,11.175 7.5,12
           C7.5,12.825 8.175,13.5 9,13.5
           L18.885,13.5
           L16.95,15.435
           C16.665,15.705 16.5,16.08 16.5,16.5
           C16.5,17.325 17.175,18 18,18
           C18.42,18 18.795,17.835 19.065,17.565
           L23.565,13.065
           C23.835,12.795 24,12.42 24,12
           C24,11.58 23.835,11.205 23.565,10.935Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
