// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useEffect, useRef, useState } from 'react';

import { camelCaseToWords } from './text';

export type FieldValue = string | boolean | number

interface Field {
  key: string,
  required?: boolean,
  requiredMessage?: string,
  invalidMessage?: string,
  value: FieldValue,
  validate?: (value: any) => boolean
}

interface FormErrors {
  [key: string]: string
}

interface ValidateOpts {
  isSubmit: boolean
}

// Validates a field independently based on its schema and returns either
// an error or an empty string. The order of checks is important. First
// it checks if the required fields are filled and only then it moves to
// any custom validation if it exists.
const getFieldError = (field: Field, opts: ValidateOpts): string => {
  if (field.required && (
    (typeof field.value === 'string' && !field.value.trim().length) ||
    (typeof field.value === 'boolean' && !field.value))
  ) {
    return field.requiredMessage || `${camelCaseToWords(field.key)} is required`;
  }

  // If the field has a custom validate fn, we should trigger it only
  // on form submits and not on keypresses. This is important, because
  // we don't want the "email is invalid" to appear on each keystroke.
  if (field.validate && !field.validate(field.value) && opts.isSubmit) {
    return field.invalidMessage || `${camelCaseToWords(field.key)} is invalid`;
  }

  return '';
};

const findChangedFieldIdx = (oldSchema: Field[], newSchema: Field[]) => {
  if (!oldSchema) {
    return -1;
  }
  return newSchema.findIndex((field, idx) => field.value !== oldSchema[idx].value);
};

const useFormValidation = (schema: Field[]) => {
  const [errors, setErrors] = useState<FormErrors>({});

  // This will validate either all fields (on submit) or an individual
  // field when its value changes (due to the useEffect below). It returns
  // a boolean value that's needed to allow/prevent the form submit.
  const validateFields = (fields: Field[], opts: ValidateOpts): boolean => {
    let isFormValid = true;

    const newErrors = fields.reduce((acc, field) => {
      const err = getFieldError(field, opts);
      acc[field.key] = err;
      if (err) {
        isFormValid = false;
      }
      return acc;
    }, <FormErrors>{});

    setErrors({ ...errors, ...newErrors });
    return isFormValid;
  };

  const clearErrors = useCallback(() => {
    setErrors({});
  }, []);

  // We keep the old schema so that we can compare it with the new schema
  // in order to find which field has been updated (while typing) and
  // to prevent validations on initial render.
  const schemasRef = useRef<Field[]>(schema);

  useEffect(() => {
    const changedIdx = findChangedFieldIdx(schemasRef.current, schema);

    if (schemasRef.current && changedIdx !== -1) {
      validateFields([schema[changedIdx]], { isSubmit: false });
    }

    schemasRef.current = schema;
  }, [schema]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    errors,
    validate: () => validateFields(schema, { isSubmit: true }),
    clearErrors,
  };
};

export default useFormValidation;
