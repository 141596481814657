// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { BoolValue } from '@bufbuild/protobuf';
import { atomFamily, useRecoilState } from 'recoil';

import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';

const projectWithResetGeometryKey = 'projectWithResetGeometry';

const deserialize = (value: Uint8Array) => BoolValue.fromBinary(value).value;
const serialize = (value: boolean) => new BoolValue({ value }).toBinary();

export const projectWithResetGeometryState = atomFamily<boolean, string>({
  key: projectWithResetGeometryKey,
  default: (projectId: string) => (
    persist.getProjectState(projectId, [projectWithResetGeometryKey], deserialize)
  ),
  effects: (projectId: string) => [
    syncProjectStateEffect(
      projectId,
      projectWithResetGeometryKey,
      deserialize,
      serialize,
    ),
  ],
  dangerouslyAllowMutability: true,
});

export const useProjectWithResetGeometry = (
  projectId: string,
) => useRecoilState(projectWithResetGeometryState(projectId));
