// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const NullIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="null"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M11.993,0
           C5.367,0 0,5.367 0,11.993
           C0,18.619 5.367,23.986 11.993,23.986
           C18.619,23.986 23.986,18.619 23.986,11.993
           C23.986,5.367 18.619,0 11.993,0Z
           M2.998,11.993
           C2.998,7.031 7.031,2.999 11.993,2.999
           C13.942,2.999 15.726,3.628 17.195,4.678
           L4.677,17.195
           C3.628,15.726 2.998,13.942 2.998,11.993Z
           M11.993,20.988
           C10.044,20.988 8.26,20.358 6.791,19.309
           L19.308,6.791
           C20.358,8.26 20.987,10.044 20.987,11.993
           C20.987,16.97 16.97,20.988 11.993,20.988Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
