// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { Vector3 } from '../../../proto/base/base_pb';
import { QuantityType } from '../../../proto/quantity/quantity_pb';
import LabeledInput from '../LabeledInput';
import { ValidVector3Input, ValidVector3InputProps, Vec3FaultInfo } from '../ValidatedInputs/ValidVector3Input';
import { Vec3FaultsType } from '../Vector3Input';

interface NormalInputProps extends ValidVector3InputProps {
  // The label for the input
  label?: string;
  // The help text for the label
  help?: string,
}

/**
 * A generic input component for a Normal vector.
 */
export const NormalInput = (props: NormalInputProps) => {
  const { label = 'Normal', help, validate } = props;

  // A normal must have a positive length
  const validateNormal = (value: Vector3): Vec3FaultInfo | undefined => {
    const magnitude = Math.sqrt(value.x ** 2 + value.y ** 2 + value.z ** 2);
    if (magnitude <= 0) {
      return {
        type: { x: 'error', y: 'error', z: 'error' } as Vec3FaultsType,
        message: 'Magnitude must be greater than 0',
      };
    }
    const nanValues = {
      x: Number.isNaN(value.x),
      y: Number.isNaN(value.y),
      z: Number.isNaN(value.z),
    };
    if (nanValues.x || nanValues.y || nanValues.z) {
      return {
        type: {
          x: nanValues.x ? 'warning' : undefined,
          y: nanValues.y ? 'warning' : undefined,
          z: nanValues.z ? 'warning' : undefined,
        } as Vec3FaultsType,
      };
    }
    return validate?.(value);
  };

  return (
    <LabeledInput help={help} label={label}>
      <ValidVector3Input
        {...props}
        quantityType={QuantityType.LENGTH}
        validate={validateNormal}
      />
    </LabeledInput>
  );
};
