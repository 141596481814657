// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { IconName } from '../../lib/componentTypes/svgIcon';
import { EARLY_ACCESS_URL } from '../../lib/constants';
import { colors } from '../../lib/designSystem';
import { ActionLink } from '../Button/ActionLink';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'inline-flex',
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      color: colors.primaryInteractive,
      transition: 'color 250ms',
      '&:hover': {
        color: colors.primaryCta,
      },

      '&.inheritColor': {
        color: 'inherit',
      },
    },
  }),
  { name: 'EarlyAccessLink' },
);

export interface EarlyAccessLinkProps {
  size?: number;
  // If fit is true, only the icon itself will be hoverable, without any extra space around it
  fit?: boolean;
  iconName?: IconName;
  inheritColor?: boolean;
}

export const EarlyAccessLink = (props: EarlyAccessLinkProps) => {
  const { size = 11, fit = false, iconName = 'beaker', inheritColor } = props;

  const classes = useStyles();
  const iconStyle = fit ? {} : {
    width: '16px',
    height: '16px',
  };

  return (
    <div className={classes.root}>
      <Tooltip
        disableInteractive={false}
        title={(
          <div>
            This item is under early<br />
            access. <ActionLink href={EARLY_ACCESS_URL}>Learn more.</ActionLink>
          </div>
        )}>
        <span className={cx(classes.icon, { inheritColor })} style={iconStyle}>
          <SvgIcon maxHeight={size} name={iconName} />
        </span>
      </Tooltip>
    </div>
  );
};
