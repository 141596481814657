// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { Circle, Line } from '@visx/shape';

import { colors } from '../../../lib/designSystem';
import { formatNumber } from '../../../lib/number';
import { createStyles, makeStyles } from '../../Theme';

const useStyles = makeStyles(
  () => createStyles({
    derivativeText: {
      bottom: '4px',
      left: '4px',
      position: 'absolute',
    },
    graph: {
      background: colors.surfaceDark3,
      borderRadius: '4px',
      margin: '24px 0',
      position: 'relative',
      height: '64px',
      width: '100%',
    },
    graphHoverText: {
      background: colors.surfaceDark3,
      borderRadius: '4px',
      color: colors.highEmphasisText,
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      opacity: '0.8',
      overflow: 'hidden',
      fontSize: '13px',
    },
    sensitivities: {
      color: colors.highEmphasisText,
      marginBottom: '26px',
    },
  }),
  { name: 'SensitivityGraph' },
);

// The range of the x-axis in pixels.
export const X_PIXELS = {
  min: 4,
  center: 99.5,
  max: 195,
};

// The range of the y-axis in pixels.
export const Y_PIXELS = {
  min: 4,
  center: 32,
  max: 60,
};

interface SensitivityGraphProps {
  // The derivative of the output with respect to the input.
  derivative: number;
  // The name of the input.
  inputName: string;
  // The name of the output.
  outputName: string;
  // The slope is the derivative adjusted for the different scales of x and y
  // axes and inverted.
  slope: number;
  // The x-position of the circle.
  xCircle: number;
  // Top margin added to the graph.
  marginTop?: number;
  // Bottom margin added to the graph.
  marginBottom?: number;
}

// A graph showing the derivative of an output with respect to an input.
export const SensitivityGraph = (props: SensitivityGraphProps) => {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);

  const textPrefix = props.derivative > 0 ? '+' : '';
  const text = textPrefix + formatNumber(props.derivative, { numDecimals: 2 });
  const halfWidth = X_PIXELS.max - X_PIXELS.center;
  const halfHeight = Y_PIXELS.max - Y_PIXELS.center;
  const cornerSlope = halfHeight / halfWidth;

  // Find where the line intersects the border of the box.
  let xLine = 0;
  let yLine = 0;
  if (Math.abs(props.slope) < cornerSlope) {
    xLine = halfWidth;
    yLine = halfWidth * props.slope;
  } else if (props.slope > 0) {
    yLine = halfHeight;
    xLine = halfHeight / props.slope;
  } else {
    yLine = -halfHeight;
    xLine = -halfHeight / props.slope;
  }
  const yCircle = props.slope * (props.xCircle - X_PIXELS.center) + Y_PIXELS.center;

  return (
    <div
      className={classes.graph}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      style={{ marginTop: props.marginTop ?? 24, marginBottom: props.marginBottom ?? 24 }}>
      <svg height="64px" width="199px">
        <g>
          <Line
            key="x-axis"
            stroke={colors.neutral450}
            strokeWidth="1"
            x1={X_PIXELS.min}
            x2={X_PIXELS.max}
            y1={Y_PIXELS.center}
            y2={Y_PIXELS.center}
          />
          <Line
            key="y-axis"
            stroke={colors.neutral450}
            strokeWidth="1"
            x1={X_PIXELS.center}
            x2={X_PIXELS.center}
            y1={Y_PIXELS.min}
            y2={Y_PIXELS.max}
          />
          <Line
            key="line"
            stroke={colors.highEmphasisText}
            strokeWidth="1"
            x1={X_PIXELS.center + xLine}
            x2={X_PIXELS.center - xLine}
            y1={Y_PIXELS.center + yLine}
            y2={Y_PIXELS.center - yLine}
          />
          <Circle cx={props.xCircle} cy={yCircle} fill={colors.highEmphasisText} r={4} />
        </g>
      </svg>
      <span className={classes.derivativeText}>
        {text}
      </span>
      {hovered && (
        <div className={classes.graphHoverText}>
          <div style={{ textAlign: 'center', margin: '14px auto', width: 'fit-content' }}>
            <div>
              <i>d</i>
              {`(${props.outputName})`}
            </div>
            <div style={{
              background: colors.highEmphasisText,
              height: '1px',
            }}
            />
            <div>
              <i>d</i>
              {`(${props.inputName})`}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
