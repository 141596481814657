// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const PatternLinearIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="patternLinear"
      fill="none"
      height={finalHeight}
      ref={ref}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 2C1 1.44772 1.44772 1 2 1H6C6.55228 1 7 1.44772 7 2V6C7 6.55228 6.55228 7 6
        7H2C1.44772 7 1 6.55228 1 6V2Z"
        fill={getColor(0)}
      />
      <path
        clipRule="evenodd"
        d="M14 2H10V6H14V2ZM10 1C9.44772 1 9 1.44772 9 2V6C9 6.55228 9.44772 7 10 7H14C14.5523 7 15
        6.55228 15 6V2C15 1.44772 14.5523 1 14 1H10Z"
        fill={getColor(0)}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6 10H2L2 14H6V10ZM2 9C1.44772 9 1 9.44772 1 10V14C1 14.5523 1.44772 15 2 15H6C6.55228 15
        7 14.5523 7 14V10C7 9.44772 6.55228 9 6 9H2Z"
        fill={getColor(0)}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14 10H10V14H14V10ZM10 9C9.44772 9 9 9.44772 9 10V14C9 14.5523 9.44772 15 10
        15H14C14.5523 15 15 14.5523 15 14V10C15 9.44772 14.5523 9 14 9H10Z"
        fill={getColor(0)}
        fillRule="evenodd"
      />
    </svg>
  );
});
