// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { Auth0Error } from 'auth0-js';
import { useNavigate } from 'react-router-dom';

import { LoginWithMfaLocationState, SetupSelect2FALocationState, getActiveMfaOptions, getMfaTokenExpiry } from '../AuthMFA';
import { removeLastOobForUser } from '../AuthV2';
import { routes } from '../navigation';
import { Logger } from '../observability/logs';

const logger = new Logger('login');

// Some webAuth.client methods like `webAuth.client.login` may
// return an error with { code: 'mfa_required' }. If that happens, we should call this hook to
// handle redirects propertly and in a centralized place (here).
export const useHandleMfaRequired = () => {
  const navigate = useNavigate();
  return async (err: Auth0Error) => {
    try {
      let mfaToken = err.original?.response?.body?.mfa_token;
      if (mfaToken == null) {
        logger.error(
          'mfa_required error response did not contain mfa_token, ' +
          'will fall back to JSON parse method; response body was: ',
          err.original?.response?.body,
        );
        // fallback to the json parsing which is known to be buggy/inconsistent
        // (LC-19223, LC-19598), as a last resort
        mfaToken = JSON.parse(err.original.message).mfa_token;
      }
      const mfaTokenExpiry = getMfaTokenExpiry();
      // User is successfully authenticated with email/pass, but MFA is required.
      // If MFA is configured, proceed to MFA authentication. Otherwise, proceed to setup.
      const { data } = await getActiveMfaOptions(mfaToken);
      const activeMfaList = data.filter((mfaOption) => mfaOption.active);
      if (activeMfaList.length) {
        const hasActiveSmsMfa = activeMfaList.some((mfa) => mfa.oob_channel === 'sms');
        if (hasActiveSmsMfa) {
          // Even if we have an OOB that was generated within the last < 5 minutes, we cannot reuse
          // it if the user opened the login page and clicked "Login" again. Each login attempt
          // generates a new MFA code which won't work with the old OOB so we must remove the old
          // OOB from the storage and let the login flow generates a new OOB and send a new SMS.
          removeLastOobForUser();
        }
        const navigateStateForLoginWith2FA: LoginWithMfaLocationState = {
          activeMfaList,
          mfaToken,
          mfaTokenExpiry,
        };
        navigate(hasActiveSmsMfa ? routes.loginSms2FA : routes.loginApp2FA, {
          state: navigateStateForLoginWith2FA,
        });
      } else {
        const setupSelect2faLocationState: SetupSelect2FALocationState = {
          mfaToken,
          mfaTokenExpiry,
        };
        navigate(routes.setupSelect2fa, { state: setupSelect2faLocationState });
      }
    } catch (caughtError) {
      logger.error('mfaRequired caught error: ', caughtError);
      logger.error('mfaRequired auth0 original error: ', err);
      navigate(routes.login, {
        state: {
          error: 'Internal error occurred with the multi-factor authentication',
        },
      });
    }
  };
};
