// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { forwardRef, memo } from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    '&.mini': {
      '--size': '18px',
      '--padding': '3px',
    },

    '&.small': {
      '--size': '28px',
      '--padding': '8px',
    },

    '&.medium': {
      '--size': '36px',
      '--padding': '10px',
    },

    '--bg-color': 'transparent',
    '--hover-bg-color': colors.surfaceLight2,

    '&.minimal': {
      '--hover-bg-color': colors.neutral400,
    },

    '&.engaged': {
      '--bg-color': colors.surfaceDark3,
      '--hover-bg-color': colors.surfaceDark3,

      '&.minimal': {
        '--bg-color': colors.surfaceLight1,
        '--hover-bg-color': colors.surfaceLight2,
      },
    },

    '&:disabled': {
      opacity: 0.5,
      color: 'currentColor',
    },

    color: 'white',
    width: 'var(--size)',
    height: 'var(--size)',
    padding: 'var(--padding)',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'var(--bg-color)',
    transition: 'opacity 500ms',
    '&:hover': {
      backgroundColor: 'var(--hover-bg-color)',
    },
    border: 'none',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}), { name: 'IconButton' });

/* eslint-disable */
// Addressing false positives - https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/no-unused-prop-types.md
interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  // The mini size can be used for TextAdornments or where we need extremely small buttons but in
  // most other cases we should use the small size which is the default for the IconButton. The
  // small/medium sizes match the small/medium height of the ActionButton.
  size?: 'mini' | 'small' | 'medium';
  // Indicates that some activity associated with the button is in progress
  engaged?: boolean;
  // Option to show a different style than the default one.
  // The minimal style tries to mimic the minimal style of the ActionButton and the DataSelect.
  kind?: 'minimal';
}
/* eslint-enable react/no-unused-prop-types */

const IconButton = memo(forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { engaged, kind, size = 'small', className, children, ...baseProps } = props;

  const classes = useStyles();

  return (
    <button
      type="button"
      {...baseProps}
      className={cx(classes.root, className, size, kind || '', { engaged })}
      ref={ref}>
      {children}
    </button>
  );
}));

IconButton.displayName = 'IconButton';

export { IconButton, IconButtonProps };
