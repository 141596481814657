// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as simulationpb from '../proto/client/simulation_pb';

import { getFluid } from './physicsUtils';
import { isSimulationSteady } from './simulationUtils';

// Currently accepted viscous model types in runtime estimation
const acceptedViscousModelTypes = [simulationpb.ViscousModel.RANS];

export function isCompatible(simParam: simulationpb.SimulationParam) {
  const amr = simParam.adaptiveMeshRefinement;
  const isAcceptableMeshType = (
    amr?.meshingMethod !== simulationpb.MeshingMethod.MESH_METHOD_AUTO
  );
  const isAcceptableModelType = simParam.physics.every((physics) => {
    const fluid = getFluid(physics);
    if (fluid?.basicFluid) {
      return acceptedViscousModelTypes.includes(fluid.basicFluid.viscousModel);
    }
    return true;
  });
  const isAcceptableFlowBehavior = isSimulationSteady(simParam);
  return isAcceptableMeshType && isAcceptableModelType && isAcceptableFlowBehavior;
}
