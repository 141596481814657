// Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { Kind, RadioButtonOption, RadioValue } from '../../../lib/componentTypes/form';
import Tooltip from '../../Tooltip';
import { RadioButton } from '../RadioButton';

export interface RadioButtonGroupProps<T extends RadioValue> {
  kind: Kind;
  disabled?: boolean;
  name: string;
  onChange: (value: T) => void;
  options: RadioButtonOption<T>[];
  value?: T;
  vertical?: boolean;
}

export function RadioButtonGroup<T extends RadioValue>(props: RadioButtonGroupProps<T>) {
  const { kind, name, onChange, options, disabled = false, value, vertical } = props;

  return (
    <div className="formLayout">
      <div className={cx('formElement controlSeries', { vertical })}>
        {options.map((option) => {
          const disabledOption = option.disabled || disabled;
          return (
            <label className="formElement control" key={`${option.value}`}>
              <RadioButton
                checked={option.value === value}
                disabled={disabledOption}
                name={name}
                onClick={() => onChange(option.value)}
                value={option.value}
              />
              <Tooltip title={option.help || ''}>
                <div
                  className={cx(
                    'formElement label',
                    kind,
                    { disabled: disabledOption },
                  )}>
                  {option.label || option.value}
                </div>
              </Tooltip>
            </label>
          );
        })}
      </div>
    </div>
  );
}
