// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { AnyMessage, FieldInfo, Message, PlainMessage, codegenInfo } from '@bufbuild/protobuf';

import * as ProtoDescriptor from '../ProtoDescriptor';
import { paramGroupDesc } from '../SimulationParamDescriptor';

import assert from './assert';
import { fromBigInt } from './number';
import { lowerFirst, toSnakeCase } from './text';

interface CondInfo {
  // Condition of the multiple choice parameter.
  paramCond: ProtoDescriptor.Cond | null,
  // Condition of the choice.
  choiceCond: ProtoDescriptor.Cond | null,
}

export type ChoicesMap = Map<number, CondInfo>;
export type NestLevelsMap = Map<string, number>;
export type ParamNestLevelsMap = Map<ProtoDescriptor.Param, number>;
export type ValuesMap = Map<string, any>;
export type ParamDescriptor = { [name: string]: ProtoDescriptor.Param }
export type ParamGroupDescriptor = { [name: string]: ProtoDescriptor.ParamGroup }
export type TagSet = Set<string>;

/**
 * Array containing the names of all groups that can be used in oneOf structures
 */
const oneOfGroups = Object.values(paramGroupDesc).filter((group) => group.isOneOf).flatMap(
  (group) => group.paramGroups,
);

// Calls a callback function for every item in a generic object. Fields in `excludeFields` will be
// skipped.
function walkObject<T extends unknown[]>(
  msg: object,
  includeRepeated: boolean,
  func: (val: unknown, key: string, ...args: T[]) => void,
  excludeFields: Set<string>,
) {
  const map = new Map(Object.entries(msg));
  map.forEach((value, key) => {
    if (!excludeFields.has(key)) {
      if (Array.isArray(value)) {
        if (!includeRepeated) {
          return;
        }
        value.forEach((arrayVal) => {
          walkObject(arrayVal, includeRepeated, func, excludeFields);
        });
      }
      if (typeof value === 'object') {
        walkObject(value, includeRepeated, func, excludeFields);
      }
      func(value, key);
    }
  });
}

// Fields with names "value", "x", "y", "z" are excluded because they are part of
// base.adFloatType/base.adVector3 for which we don't want to call the callback. Also we exclude any
// external groups.
function buildExcludeSet(
  groupDesc: { [name: string]: ProtoDescriptor.ParamGroup },
): Set<string> {
  const ret = new Set(['value', 'firstOrder', 'quantityType', 'secondOrder', 'x', 'y', 'z']);
  Object.values(groupDesc).forEach((group) => {
    if (!group.isExternal) {
      return;
    }
    ret.add(group.camelCaseName);
  });
  return ret;
}

const excludeSetsCache = new Map<ParamGroupDescriptor, Set<string>>();

function getExcludeSet(paramGroupDescriptor: ParamGroupDescriptor): Set<string> {
  if (!excludeSetsCache.has(paramGroupDescriptor)) {
    excludeSetsCache.set(paramGroupDescriptor, buildExcludeSet(paramGroupDescriptor));
  }
  return excludeSetsCache.get(paramGroupDescriptor)!;
}

export function initializeData(
  paramAsObject: object,
  paramDescriptor: ParamDescriptor,
  paramGroupDescriptor: ParamGroupDescriptor,
  nestLevels: NestLevelsMap,
  choices: ChoicesMap,
  values: ValuesMap,
  tags: TagSet | undefined,
) {
  const excludeSet = getExcludeSet(paramGroupDescriptor);
  // Loop through all entries (excluding any maps or arrays) in the object.
  walkObject(paramAsObject, false, (value, key) => {
    // Extract the corresponding param descriptor if it exists
    let param = paramDescriptor[toSnakeCase(key)];
    // If we cannot find the param descriptor by transforming the key to snake case (some params
    // do not have a standard snake case name) we have to search in the map for the
    // pascal case name.
    if (!param) {
      Object.values(paramDescriptor).some((desc) => {
        if (key.toUpperCase() === desc.pascalCaseName.toUpperCase()) {
          param = desc;
        }
        return !!param;
      });
    }
    if (param) {
      // Initialize the nest level for this parameter to 0.
      nestLevels.set(param.name, 0);
      // Set values
      values.set(param.name, value);
      // Store the conditional info for the choice of a multiple choice param.
      if (param.type === ProtoDescriptor.ParamType.MULTIPLE_CHOICE) {
        const choice = param.choices.find(({ enumNumber }) => enumNumber === value as number);
        if (choice) {
          choices.set(choice.enumNumber, {
            choiceCond: choice.cond ? choice.cond : null,
            paramCond: param.cond ? param.cond : null,
          });
        }
      }
    } else {
      const group = paramGroupDescriptor[toSnakeCase(key)];
      // Make sure that if a param cannot be found it must be a group.
      assert(!!group, `Cannot find ${key}`);
      // If the group is part of a oneOf structure add its name as tag so that
      // parameters/choices can be enabled based on the oneOf selection.
      if (value && oneOfGroups.includes(group.name)) {
        tags?.add(group.name);
      }
    }
  }, excludeSet);
}

function scalarZeroValue(field: FieldInfo) {
  if (field.kind === 'enum') {
    return 0;
  }
  if (field.kind === 'scalar') {
    return codegenInfo.scalarZeroValue(field.T, field.L);
  }
  return undefined;
}

function toPlainMessage<T extends Message<T>>(message: T | PlainMessage<T>): PlainMessage<T> {
  if (!(message instanceof Message)) {
    return message;
  }

  const type = message.getType();
  const target = {} as AnyMessage;
  type.fields.byMember().forEach((member) => {
    const { localName, kind, repeated } = member;

    // A few params's localName values (like CSaDesc) begin with upper case letters.  For backwards
    // compatibility with google protobuf, lower-case the first letter of the accessor.
    const newLocalName = lowerFirst(localName);

    const value = (message as AnyMessage)[localName];
    if (repeated) {
      target[newLocalName] = (value as any[]).map((val) => toPlainMessage(val));
    } else if (kind === 'map') {
      target[newLocalName] = Object.entries(value);
    } else if (kind === 'oneof') {
      member.fields.forEach((field) => {
        if (field.localName === value.case) {
          target[field.localName] = toPlainValue(value.value);
        } else {
          target[field.localName] = scalarZeroValue(field);
        }
      });
    } else {
      target[newLocalName] = toPlainValue(value);
    }
  });
  return target as PlainMessage<T>;
}

function toPlainValue(value: any) {
  if (value === undefined) {
    return value;
  }
  if (value instanceof Message) {
    return toPlainMessage(value);
  }
  if (value instanceof Uint8Array) {
    const uint8 = new Uint8Array(value.byteLength);
    uint8.set(value);
    return uint8;
  }
  if (value instanceof BigInt) {
    return fromBigInt(value as bigint);
  }
  return value;
}

export function protoToObject(message: Message) {
  return toPlainMessage(message);
}
