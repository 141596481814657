import React from 'react';

import { useNavigate } from 'react-router-dom';

import { geometryLink } from '../../lib/navigation';
import { ActionButton } from '../Button/ActionButton';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  }),
  { name: 'UnavailableStage' },
);

/**
 * A component to let the user know that this stage of project
 * setup is not yet available. They need to set up the previous
 * stage(s) first.
 */
export const UnavailableStage = () => {
  const classes = useStyles();
  const { projectId } = useProjectContext();
  const navigate = useNavigate();

  const goToGeometry = () => {
    navigate(geometryLink(projectId));
  };

  return (
    <div className={classes.root}>
      <h1>Unavailable Stage</h1>
      <p>
        This stage is not yet available. Please set up the previous stage(s) first.
      </p>
      <ActionButton onClick={goToGeometry}>Return to Geometry</ActionButton>
    </div>
  );
};
