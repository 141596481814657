// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 14;
export const TrashIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="trash"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M4.12004 1.66496
           C4.07398 1.66496 4.03838 1.70056 4.03838 1.74663
           V2.58163
           H7.95504
           V1.74663
           C7.95504 1.70056 7.91944 1.66496 7.87338 1.66496
           H4.12004ZM2.70838 2.58163
           V1.74663
           C2.70838 0.966025 3.33944 0.334961 4.12004 0.334961
           H7.87338
           C8.65398 0.334961 9.28504 0.966025 9.28504 1.74663
           V2.58163
           H11.2184
           L11.2179 2.58829
           H11.9984
           V3.91829
           H11.1151
           L10.5164 11.6643
           C10.5164 11.6644 10.5164 11.6642 10.5164 11.6643
           C10.4298 12.789 9.4891 13.665 8.36004 13.665
           H3.64004
           C2.50244 13.665 1.57016 12.7873 1.48368 11.6645
           C1.48368 11.6644 1.48369 11.6646 1.48368 11.6645
           L0.884973 3.91829
           H0.00170898
           V2.58829
           H0.782176
           L0.781661 2.58163
           H2.70838Z
           M2.21894 3.91829
           L2.80973 11.562
           C2.84335 11.9991 3.20438 12.335 3.64004 12.335
           H8.36004
           C8.79091 12.335 9.15686 11.9975 9.19034 11.5623
           L9.78115 3.91829
           H2.21894Z
           M5.35171 5.58829
           V10.665
           H4.02171
           V5.58829
           H5.35171Z
           M7.97838 5.58829
           V10.665
           H6.64838
           V5.58829
           H7.97838Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
