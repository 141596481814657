// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Message } from '@bufbuild/protobuf';

import * as explorationpb from '../proto/exploration/exploration_pb';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as workflowpb from '../proto/workflow/workflow_pb';

import { MessageConstructor, packProto, unpackProto } from './protoUtils';
import * as rpc from './rpc';

// Binary-serialize the given proto object then base64 encode it.  To be used
// in localStorage.
export function protoToBase64(proto: Message): string {
  // TODO(saito) Typescript assumes that fromCharCode takes a []number, but
  // in practice it also takes a UintArray.
  return btoa(String.fromCharCode.apply(null, proto.toBinary() as any));
}

// Base64-decodes the given string, then binary-serializes the result as a
// protocol-message type T.  It raises an exception if the string could is not a
// base64 string, or if the data could not be deserialized as the given proto
// type.
export function base64ToProto<T extends Message>(
  base64: string,
  deserialize: (uint8: Uint8Array) => T,
): T {
  const uint8 = Uint8Array.from(atob(base64), (char) => char.charCodeAt(0));
  return deserialize(uint8);
}

export function extractProtoField<T>(proto: Message, fieldName: string): T {
  const casted = proto as unknown as { [accessor: string]: any };
  return casted[fieldName] as T;
}

/**
   Convert a protocol message to a proto3-format json
   (https://developers.google.com/protocol-buffers/docs/proto3#json).  TypeUrl
   is the full URL of the message type, e.g., "luminary.proto.fvm.Param".
   If useStableApi is true, the stable API proto conversion will be applied to
   the input proto.
*/
export async function protoToJson(proto: Message, useStableApi: boolean = true): Promise<string> {
  const data = packProto(proto);
  const req = new frontendpb.ProtoToJsonRequest({ data, useStableApi });
  const reply = await rpc.callRetry('ProtoToJson', rpc.client.protoToJson, req);
  return reply.json;
}

/**
   Convert a proto3-format JSON string to a protocol message
   (https://developers.google.com/protocol-buffers/docs/proto3#json).  TypeUrl
   is the full URL of the message type, e.g., "lumniary.proto.fvm.Param".
   Deserialize is a function that parses a serialized protocol message.
*/
export async function jsonToProto<T extends Message>(
  json: string,
  MessageClass: MessageConstructor<T>,
): Promise<T> {
  const req = new frontendpb.JsonToProtoRequest({ json });
  const reply = await rpc.callRetry('JsonToProto', rpc.client.jsonToProto, req);
  return unpackProto(reply.data, MessageClass)!;
}

/**
   Update the "experiment" field in workflow.Config proto.  It creates a new
   proto object without modifying the old ones.
*/
export function updateExploration(
  oldConfig: workflowpb.Config,
  newExp: explorationpb.Exploration,
): workflowpb.Config {
  return new workflowpb.Config({
    exploration: newExp,
    jobConfigTemplate: oldConfig.jobConfigTemplate,
  });
}

/**
 * Clone a job config without modifying the old one, optionally updating batch mode or GPU
 * preferences.
 * */
export function copyJobConfig(oldConfig: workflowpb.Config): workflowpb.Config {
  const oldJobConfig = oldConfig.jobConfigTemplate!;
  const { priority, gpuPref } = oldJobConfig;
  const newJobConfig = new workflowpb.JobConfig({ gpuPref, priority });

  switch (oldJobConfig.typ.case) {
    case 'testjob':
    case 'simulationParam':
      newJobConfig.typ = oldJobConfig.typ;
      break;
    default:
      throw Error(`Invalid job config type: ${oldJobConfig.typ.case}`);
  }

  return new workflowpb.Config({
    exploration: oldConfig.exploration,
    jobConfigTemplate: newJobConfig,
  });
}
