// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 12;
export const PaperAirplaneIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="paperAirplane"
      height={finalHeight}
      ref={ref}
      style={{
        fill: 'none',
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0.190088 4.99925L2.57917 7.38833C2.81556 7.62471 3.19234 7.64271 3.44972
        7.43032L9.84121 2.15899L4.56987 8.54987C4.35748 8.80725 4.37548 9.18464 4.61187
        9.42042L7.00095 11.8095C7.33633 12.1449 7.9063 12.0183 8.06829 11.5725L11.9597
        0.870852C12.1475 0.353678 11.6465 -0.147897 11.1293 0.040494L0.427076 3.9319C-0.0187015
        4.09389 -0.145295 4.66386 0.190088 4.99925Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
