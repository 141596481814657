// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

type Justify = 'normal' | 'flex-start' | 'flex-end' | 'center' | 'space-between' |
  'space-around' | 'space-evenly' | 'stretch';

type Alignment = 'normal' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';

interface FlexProps {
  children?: ReactNode;
  gap?: number;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  alignItems?: Alignment;
  justifyContent?: Justify;
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  minWidth?: number;
}

export const Flex = (props: FlexProps) => {
  // Exclude all props from the otherStyles which are not existing style properties
  const { children, ...otherStyles } = props;

  return (
    <div style={{ display: 'flex', ...otherStyles }}>
      {children}
    </div>
  );
};
