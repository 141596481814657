// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/platformadmin/platformadmin.proto (package luminary.proto.platformadmin, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum luminary.proto.platformadmin.PlatformRole
 */
export const PlatformRole = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.platformadmin.PlatformRole",
  [
    {no: 0, name: "INVALID"},
    {no: 1, name: "ADMIN"},
    {no: 2, name: "SUPPORT"},
    {no: 3, name: "SAMPLE"},
    {no: 4, name: "BIZOPS"},
  ],
);

/**
 * @generated from message luminary.proto.platformadmin.PlatformAdminUser
 */
export const PlatformAdminUser = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.platformadmin.PlatformAdminUser",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "roles", kind: "enum", T: proto3.getEnumType(PlatformRole), repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.platformadmin.ListPlatformAdminReply
 */
export const ListPlatformAdminReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.platformadmin.ListPlatformAdminReply",
  () => [
    { no: 1, name: "users", kind: "message", T: PlatformAdminUser, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.platformadmin.UpdatePlatformAdminRequest
 */
export const UpdatePlatformAdminRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.platformadmin.UpdatePlatformAdminRequest",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "add_roles", kind: "enum", T: proto3.getEnumType(PlatformRole), repeated: true },
    { no: 3, name: "remove_roles", kind: "enum", T: proto3.getEnumType(PlatformRole), repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.platformadmin.GetPlatformAdminRoleReply
 */
export const GetPlatformAdminRoleReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.platformadmin.GetPlatformAdminRoleReply",
  () => [
    { no: 1, name: "roles", kind: "enum", T: proto3.getEnumType(PlatformRole), repeated: true },
  ],
);

