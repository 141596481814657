// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 21;
export const CrownIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="crown"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M3,7.5
           L7.5,10.5
           L12,4.5
           L16.5,10.5
           L21,7.5
           L19.5,16.5
           L4.5,16.5
           L3,7.5Z
           M12,0
           C12.828,0 13.5,0.672 13.5,1.5
           C13.5,2.328 12.828,3 12,3
           C11.172,3 10.5,2.328 10.5,1.5
           C10.5,0.672 11.172,0 12,0Z
           M1.5,3
           C2.328,3 3,3.672 3,4.5
           C3,5.328 2.328,6 1.5,6
           C0.672,6 0,5.328 0,4.5
           C0,3.672 0.672,3 1.5,3Z
           M22.5,3
           C23.328,3 24,3.672 24,4.5
           C24,5.328 23.328,6 22.5,6
           C21.672,6 21,5.328 21,4.5
           C21,3.672 21.672,3 22.5,3Z
           M4.5,18
           L19.5,18
           L19.5,21
           L4.5,21
           L4.5,18Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
