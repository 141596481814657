// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const ArrowsOutIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="arrowsOut"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M5.115,3
           L7.5,3
           C8.325,3 9,2.325 9,1.5
           C9,0.675 8.325,0 7.5,0
           L1.5,0
           C0.675,0 0,0.675 0,1.5
           L0,7.5
           C0,8.325 0.675,9 1.5,9
           C2.325,9 3,8.325 3,7.5
           L3,5.115
           L7.935,10.05
           C8.205,10.335 8.58,10.5 9,10.5
           C9.825,10.5 10.5,9.825 10.5,9
           C10.5,8.58 10.335,8.205 10.065,7.935
           L5.115,3Z
           M9,13.5
           C8.58,13.5 8.205,13.665 7.935,13.935
           L3,18.885
           L3,16.5
           C3,15.675 2.325,15 1.5,15
           C0.675,15 0,15.675 0,16.5
           L0,22.5
           C0,23.325 0.675,24 1.5,24
           L7.5,24
           C8.325,24 9,23.325 9,22.5
           C9,21.675 8.325,21 7.5,21
           L5.115,21
           L10.05,16.065
           C10.335,15.795 10.5,15.42 10.5,15
           C10.5,14.175 9.825,13.5 9,13.5Z
           M22.5,15
           C21.675,15 21,15.675 21,16.5
           L21,18.885
           L16.065,13.95
           C15.795,13.665 15.42,13.5 15,13.5
           C14.175,13.5 13.5,14.175 13.5,15
           C13.5,15.42 13.665,15.795 13.935,16.065
           L18.885,21
           L16.5,21
           C15.675,21 15,21.675 15,22.5
           C15,23.325 15.675,24 16.5,24
           L22.5,24
           C23.325,24 24,23.325 24,22.5
           L24,16.5
           C24,15.675 23.325,15 22.5,15Z
           M22.5,0
           L16.5,0
           C15.675,0 15,0.675 15,1.5
           C15,2.325 15.675,3 16.5,3
           L18.885,3
           L13.935,7.935
           C13.665,8.205 13.5,8.58 13.5,9
           C13.5,9.825 14.175,10.5 15,10.5
           C15.42,10.5 15.795,10.335 16.065,10.065
           L21,5.115
           L21,7.5
           C21,8.325 21.675,9 22.5,9
           C23.325,9 24,8.325 24,7.5
           L24,1.5
           C24,0.675 23.325,0 22.5,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
