import { DisplayPvVariable } from '../../../../pvproto/ParaviewRpc';
import { remapComponentIndex } from '../../../visUtils';
import { LcvModule } from '../../types';
import { LcvObject } from '../base/LcvObject';

export class LcvColorMap extends LcvObject {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    workspaceHandle: number,
    displayVariable: DisplayPvVariable,
  ) {
    const { displayDataName } = displayVariable;
    if (displayDataName === 'None') {
      throw new Error('displayDataName is None');
    }
    const ncomps = lcv.getFieldComponents(
      sessionHandle,
      workspaceHandle,
      displayVariable.displayDataName,
      0,
    ).ncomponents;
    let index = displayVariable.displayDataNameComponent;
    if (ncomps === 3) {
      index = remapComponentIndex(index);
    }
    super(
      lcv,
      lcv.getColorMap(
        sessionHandle,
        workspaceHandle,
        displayDataName,
        index,
        0,
      ).color_map,
      sessionHandle,
    );
  }
}
