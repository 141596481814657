// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
/**
 * This file contains functions that call into LCVis. The intent is to keep the internals of LCVis
 * hidden from the rest of the codebase, so callers won't have to worry about lcvHandler, widgets,
 * sessions, etc.
 */

import { useEffect, useState } from 'react';

import { SetterOrUpdater } from 'recoil';

import { Pattern, Transform } from '../../proto/geometry/geometry_pb';
import { TransparencySettings } from '../../recoil/lcvis/transparencySettings';
import { clamp } from '../number';
import { Logger } from '../observability/logs';
import * as random from '../random';

import { hidePreviewAnnotation, showPatternPreview, showTransformPreview } from './classes/annotations/transformAnnotationUtils';
import { LcvBoxWidgetState } from './classes/widgets/LcvClipBoxWidget';
import { LcvCylinderWidgetState } from './classes/widgets/LcvCylinderWidget';
import { LcvHalfSphereWidgetState } from './classes/widgets/LcvHalfSphereWidget';
import { LcvSphereWidgetState } from './classes/widgets/LcvSphereWidget';
import { lcvHandler } from './handler/LcvHandler';
import { LcVisVisibilityMap } from './types';

const logger = new Logger('src/lib/lcvis/api.ts');

/**
 * resets the camera using the bounds of the mesh. If LCVis is not ready, this will
 * queue the resetCamera function to be called once LCVis is ready.
 */
export const lcvResetCamera = () => {
  lcvHandler.queueDisplayFunction('resetCamera', (display) => {
    display.widgets.arcballWidget?.resetCamera();
  });
};

/**
 * resets the camera immediately using the currently available scene bounds. Note
 * that this may not include the final scene bounds being rendered if objects besides
 * filters have been added or visibility modified, as this reset can occur before the frame
 * has updated. lcvResetCamera should be preferred in general.
 */
export const lcvResetCameraImmediate = () => {
  lcvHandler.queueDisplayFunction('resetCameraImmediate', (display) => {
    display.widgets.arcballWidget?.resetCameraImmediate();
  });
};

/**
 * Given a list of surface ids to show and to hide, show and hide the corresponding surfaces
 * in LCVis.
 * @param toShow the surface ids of surfaces to show
 * @param toHide the ids of surfaces to hide
 */
export const applyVisibilities = (toShow: string[], toHide: string[]) => {
  // applyVisibilities works differently than other lcvis functions, since the changes to apply
  // come from diffing against the old state. So we don't want new calls to override pending ones.
  //  So we need to use a random string in the queueDisplayFunction id to
  // ensure every call goes through in order.
  lcvHandler.queueDisplayFunction(`applying visibilities ${random.string(16)}`, (display) => {
    const workspace = display.workspace;
    const [toShowSet, toHideSet] = [new Set(toShow), new Set(toHide)];
    workspace?.showSurfaces(toShowSet).then(
      () => workspace?.hideSurfaces(toHideSet),
    ).catch((error) => logger.error('error setting visibility in lcvis', error));

    const simAnnotationHandler = display.simAnnotationHandler;
    simAnnotationHandler?.showOrHideAnnotations(toShowSet, toHideSet);
  });
};

/** Given a full visibility map, apply all the entries to lcvis. */
export const applyFullVisibilityMap = (map: LcVisVisibilityMap) => {
  const [toShow, toHide]: [string[], string[]] = [[], []];
  map.forEach((val, key) => {
    if (val) {
      toShow.push(key);
    } else {
      toHide.push(key);
    }
  });
  applyVisibilities(toShow, toHide);
};

/**
 * Given a list of surface ids to make transparent, apply the transparency in LCVis
 * @param selected the list of surface ids to make transparent
 * @param invert whether to make the surfaces transparent or opaque. If true,
 * all surfaces except the ones in the list will be made transparent.
 */
export const applyTransparency = (
  selected: Set<string>,
  invert: boolean,
) => {
  lcvHandler.queueDisplayFunction(('set surfaces transparent'), (display) => {
    const allSurfaces = display.workspace?.getAllSurfaceIds();
    if (!allSurfaces) {
      return;
    }
    const nonSelected = allSurfaces.filter((surf) => !selected.has(surf));
    const toMakeTransparent = invert ? nonSelected : selected;
    const toMakeOpaque = invert ? selected : nonSelected;

    toMakeOpaque.forEach((surf) => {
      display.workspace?.setSurfaceOrFilterTransparent(surf, false);
    });
    toMakeTransparent.forEach((surf) => {
      display.workspace?.setSurfaceOrFilterTransparent(surf, true);
    });
  });
};

/**
 * Updates the opacity of all transparent surfaces in LCVis.
 */
export const setTransparentOpacity = (opacity: number) => {
  lcvHandler.queueDisplayFunction('set transparent opacity', (display) => {
    display.renderers[0].setTransparentOpacity(clamp(opacity, [0, 1]));
  });
};

/**
 * Given a list of selected surface ids, zoom to the bounding box of the selection.
 * @param selection the list of surface ids to zoom to
 */
export const zoomToSelection = (selection: string[]) => {
  lcvHandler.queueDisplayFunction('zoom to selection', (display) => {
    // bounds in the form [minX,minY,...,maxX]
    const bounds = display.workspace?.getUnionBounds(selection);

    if (bounds) {
      display.widgets.arcballWidget?.zoomToBoundingBox(bounds);
    }
  });
};

export const zoomAndMakeOthersTransparent = (
  selection: string[],
  setTransparencySettings: SetterOrUpdater<TransparencySettings>,
) => {
  const selectionSet = new Set(selection);
  setTransparencySettings({
    active: true,
    surfaces: selectionSet,
    // invert so that the selected surfaces are opaque
    invert: true,
  });
  zoomToSelection(selection);
};

function isTransform(transformation: Transform | Pattern): transformation is Transform {
  return (transformation as Transform).t !== undefined;
}

/**
 * Hook to show a preview of a geometrypb.transformation or geometrypb.pattern in LCVis.
 * @param transformation the transformation or pattern to preview
 * @param defaultShowPreview whether to show the preview by default
 * @param surfaceIds the surface ids to apply the transformation to in the preview
 * @returns a tuple containing the current visibility state of the
 * preview and a function to toggle it. The current state defaults to the value of
 * defaultShowPreview.
 */
export const usePreviewAnnotation = (
  transformation: Transform | Pattern,
  // Whether to show the preview by default
  defaultShowPreview: boolean,
  surfaceIds: string[],
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [showPreview, setShowPreview] = useState(defaultShowPreview);

  useEffect(() => {
    if (!showPreview) {
      return;
    }
    if (isTransform(transformation)) {
      showTransformPreview(transformation, surfaceIds);
    } else {
      showPatternPreview(transformation, surfaceIds);
    }
    return () => hidePreviewAnnotation();
  }, [
    transformation,
    surfaceIds,
    showPreview,
  ]);

  return [showPreview, setShowPreview];
};

/**
 * Shows the box widget in LCVis. Attaches a callback to the box widget to update whenever
 * the box widget is manipulated.
 * @param onChange a callback to be invoked whenever the box widget is manipulated.
 */
export function showBoxWidget(
  onChange: (newState: LcvBoxWidgetState, message?: string) => void,
) {
  lcvHandler.queueDisplayFunction('show box widget', (display) => {
    if (!display.widgets.boxWidget) {
      return;
    }
    display.activateWidget(display.widgets.boxWidget);
    display.widgets.boxWidget?.showControls();
    display.widgets.boxWidget?.setOnUpdateCallback(onChange);
  });
}

/**
 * Sets the state of the box widget in LCVis. This will not invoke its onUpdate callback.
 */
export function updateBoxWidgetState(state: LcvBoxWidgetState) {
  lcvHandler.queueDisplayFunction('update box widget state', (display) => {
    display.widgets.boxWidget?.setState(state);
  });
}

/**
 * Hides the box widget in LCVis and removes its callback.
 */
export function hideBoxWidget() {
  lcvHandler.queueDisplayFunction('hide box widget', (display) => {
    if (!display.widgets.boxWidget) {
      return;
    }
    display.deactivateWidget(display.widgets.boxWidget);
    display.widgets.boxWidget?.hideControls();
    display.widgets.boxWidget?.setOnUpdateCallback(null);
  });
}

/**
 * Show the sphere widget in LCVis. The callback provided will be attached to the
 * sphere widget and called each time the sphere is manipulated in the 3D view
 * by the user so that the UI can update displays of the sphere's properties
 * to match.
 */
export function showSphereWidget(
  onChange: (newState: LcvSphereWidgetState, message?: string) => void,
) {
  lcvHandler.queueDisplayFunction('show sphere widget', (display) => {
    if (!display.widgets.sphereWidget) {
      return;
    }
    display.activateWidget(display.widgets.sphereWidget);
    display.widgets.sphereWidget?.showControls();
    display.widgets.sphereWidget?.setOnUpdateCallback(onChange);
  });
}

/**
 * Update the sphere widget drawn by LCVis to the provided state
 */
export function updateSphereWidgetState(state: LcvSphereWidgetState) {
  lcvHandler.queueDisplayFunction('update sphere widget state', (display) => {
    display.widgets.sphereWidget?.setState(state);
  });
}

/**
 * Stop showing the sphere widget in LCVis
 */
export function hideSphereWidget() {
  lcvHandler.queueDisplayFunction('hide sphere widget', (display) => {
    if (!display.widgets.sphereWidget) {
      return;
    }
    display.deactivateWidget(display.widgets.sphereWidget);
    display.widgets.sphereWidget?.hideControls();
    display.widgets.sphereWidget?.setOnUpdateCallback(null);
  });
}

/**
 * Show the cylinder widget in LCVis. The callback provided will be attached to the
 * cylinder widget and called each time the cylinder is manipulated in the 3D view
 * by the user so that the UI can update displays of the cylinder's properties
 * to match.
 */
export function showCylinderWidget(
  onChange: (newState: LcvCylinderWidgetState, message?: string) => void,
) {
  lcvHandler.queueDisplayFunction('show cylinder widget', (display) => {
    if (!display.widgets.cylinderWidget) {
      return;
    }
    display.activateWidget(display.widgets.cylinderWidget);
    display.widgets.cylinderWidget?.showControls();
    display.widgets.cylinderWidget?.setOnUpdateCallback(onChange);
  });
}

/**
 * Update the cylinder widget drawn by LCVis to the provided state
 */
export function updateCylinderWidgetState(state: LcvCylinderWidgetState) {
  lcvHandler.queueDisplayFunction('update cylinder widget state', (display) => {
    display.widgets.cylinderWidget?.setState(state);
  });
}

/**
 * Stop showing the cylinder widget in LCVis
 */
export function hideCylinderWidget() {
  lcvHandler.queueDisplayFunction('hide cylinder widget', (display) => {
    if (!display.widgets.cylinderWidget) {
      return;
    }
    display.deactivateWidget(display.widgets.cylinderWidget);
    display.widgets.cylinderWidget?.hideControls();
    display.widgets.cylinderWidget?.setOnUpdateCallback(null);
  });
}

/**
 * Show the half-sphere widget in LCVis. The callback provided will be attached to the
 * half-sphere widget and called each time the half-sphere is manipulated in the 3D view
 * by the user so that the UI can update displays of the half-sphere's properties
 * to match.
 */
export function showHalfSphereWidget(
  onChange: (newState: LcvHalfSphereWidgetState, message?: string) => void,
) {
  lcvHandler.queueDisplayFunction('show half-sphere widget', (display) => {
    if (!display.widgets.halfSphereWidget) {
      return;
    }
    display.activateWidget(display.widgets.halfSphereWidget);
    display.widgets.halfSphereWidget?.showControls();
    display.widgets.halfSphereWidget?.setOnUpdateCallback(onChange);
  });
}

/**
 * Update the half-sphere widget drawn by LCVis to the provided state
 */
export function updateHalfSphereWidgetState(state: LcvHalfSphereWidgetState) {
  lcvHandler.queueDisplayFunction('update half-sphere widget state', (display) => {
    display.widgets.halfSphereWidget?.setState(state);
  });
}

/**
 * Stop showing the half-sphere widget in LCVis
 */
export function hideHalfSphereWidget() {
  lcvHandler.queueDisplayFunction('hide half-sphere widget', (display) => {
    if (!display.widgets.halfSphereWidget) {
      return;
    }
    display.deactivateWidget(display.widgets.halfSphereWidget);
    display.widgets.halfSphereWidget?.hideControls();
    display.widgets.halfSphereWidget?.setOnUpdateCallback(null);
  });
}
