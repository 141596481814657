// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import { DragConstraint } from './dragConstraints';
import { DragOffset } from './dragUtil';
import { ListenHandler, isUnmodifiedEscapeKey, listen } from './event';

/** The DragControl class manages basic state for dragging motions
 *
 * Note from Cedric: In my experience, every drag or drag-and-drop workflow
 *   has enough variation in constraints, behaviors, display, etc. that
 *   generalization can be elusive.  This class handles just the basics (mouse
 *   event handlers, mouse offset calculations, etc.) and is meant to reduce
 *   boilerplate in components that implement dragging; it's up to applications
 *   to impose any constraints on DOM element movements, generate optional
 *   avatars, implement special visualizations, etc.--any of the unique and
 *   (usually) specialized accoutrements of a drag-and-drop interface.
 */

// Represents a callback with drag offsets as an argument
type DragCallback = (offset: DragOffset) => void;

export class DragControl {
  // True if dragging has started (at least one 'mousemove' event has fired)
  public dragging: boolean;
  // How much the mouse has moved in the X direction
  private deltaX = 0;
  // How much the mouse has moved in the Y direction
  private deltaY = 0;
  // A reference value for X (from the initial mousedown event), from which deltaX is calculated
  private referenceX: number;
  // A reference value for Y (from the initial mousedown event), from which deltaY is calculated
  private referenceY: number;
  // A list of event handlers that are only active during the dragging motion
  private readonly handlers: ListenHandler[];
  // The mousedown event that triggered the dragging motion
  public readonly initialEvent: MouseEvent;
  // A callback that is invoked for the first 'mousemove' event
  private readonly onStart: () => void;
  // A callback that is invoked for each 'mousemove' event
  private readonly onDrag: DragCallback;
  // A callback that is invoked when the dragging motion ends
  private readonly onEnd: () => void;
  // An optional list of constraints imposed on the drag offset (this.deltas)
  private readonly constraints: DragConstraint[] = [];

  constructor(
    initialEvent: MouseEvent,
    onStart: () => void,
    onDrag: DragCallback,
    onEnd: () => void,
    constraints: DragConstraint[] = [],
  ) {
    this.initialEvent = initialEvent;
    this.onStart = onStart;
    this.onDrag = onDrag;
    this.onEnd = onEnd;
    this.constraints = constraints;

    // Initialize state
    this.dragging = false;
    this.handlers = [
      // Listen for 'mousemove' so that overall mouse movements can be calculated
      listen(window.document, 'mousemove', (event) => this.doDrag(event as MouseEvent)),
      // Listen for 'mouseup' to finish dragging
      listen(window.document, 'mouseup', (event) => this.endDrag(event as MouseEvent)),
      // Listen for the ESC key to stop the drag
      listen(window.document, 'keyup', (event) => this.handleKeyUp(event as KeyboardEvent)),
      // Prevent selection of content in the DOM while the mouse is being dragged
      listen(window.document, 'selectstart', (event) => event.preventDefault()),
    ];

    // TODO(cedric): There may be use cases where another measurement is desired
    // (screenX/screenY, pageX/pageY, etc.), so make this an argument.
    this.referenceX = initialEvent.clientX;
    this.referenceY = initialEvent.clientY;
  }

  // A method that is called with each 'mousemove' event to calculate new mouse
  // offsets
  private doDrag(event: MouseEvent) {
    event.stopPropagation();

    if (!this.dragging) {
      this.dragging = true;
      this.startDrag();
    }

    this.deltaX = event.clientX - this.referenceX;
    this.deltaY = event.clientY - this.referenceY;

    this.onDrag(this.deltas);
  }

  // A method that is called only once, after the first 'mousemove' event
  private startDrag() {
    this.onStart();
  }

  // A method that is called when the dragging motion is finished
  private endDrag(event?: MouseEvent /* an optional mouseup event */) {
    event?.stopPropagation();

    while (this.handlers.length) {
      const handler = this.handlers.pop();
      handler?.remove();
    }
    this.dragging = false;

    this.onEnd();
  }

  // Ends the dragging motion when the ESC key is pressed without modifier keys
  private handleKeyUp(event: KeyboardEvent) {
    if (isUnmodifiedEscapeKey(event)) {
      this.endDrag();
    }
  }

  public cancel() {
    this.endDrag();
  }

  get deltas(): DragOffset {
    const { deltaX, deltaY } = this;

    let offset = { deltaX, deltaY };
    if (this.constraints) {
      offset = this.constraints.reduce(
        (result, constraint) => (constraint.constrainOffset(result)),
        offset,
      );
    }

    return offset;
  }
}
