// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

/**
 * Create a confirmation dialog with the title "Warning", continue label "Delete", and destructive
 * preset design
 *
 * @param children the content of the confirmation dialog
 * @param onContinue called when the "Delete" button is clicked
 * @returns a confirmation dialog for node deletion
 */
export function nodeDeletionConfirmation(
  children: React.ReactNode,
  onContinue: () => void | Promise<void>,
) {
  return {
    title: 'Warning',
    children,
    continueLabel: 'Delete',
    onContinue,
    destructive: true,
  };
}

//
// Warning Text Constants
//
// Geometry Deleton
// - CAD entry
// - - has mesh, has simulations
export const GEOMETRY_CADENTRY_HASMESH_HASSIM_WARNING = (
  <>
    <p>
      Deleting the Geometry will remove your existing mesh (in Setup) as well as
      remove access to the existing Simulations you have run. If you are taking
      this action to reuse existing simulation settings with a different
      geometry, consider using the Library feature to share settings between
      Projects.
    </p>
    <p>Would you still like to proceed?</p>
  </>
);
// - - has mesh
export const GEOMETRY_CADENTRY_HASMESH_WARNING = 'Deleting the Geometry will also remove your ' +
  'existing mesh, because it is derived from this geometry. Would you still like to proceed?';
// - Mesh entry
// - - has mesh
export const GEOMETRY_MESHENTRY_WARNING = 'Deleting the Geometry will also remove your Mesh and ' +
  'will require uploading a new mesh or CAD geometry file. Would you still like to proceed?';
//
// Mesh Deletion
// - Mesh entry
// - - has mesh
export const MESH_MESHENTRY_WARNING = 'Deleting the Mesh will require uploading a new mesh or ' +
  'CAD geometry file. It will also remove all previously configured physics parameters. Would ' +
  'you still like to proceed?';
//
// Mesh or Geometry deletion
// - Mesh Entry
// -- has simulations
export const DELETE_MESHENTRY_WITHSIM_WARNING = (
  <>
    <p>
      Deleting the Geometry or Mesh will remove access to the existing Simulations you
      have run. If you are taking this action to reuse existing simulation settings with
      a different geometry, consider using the Library feature to share settings between
      Projects.
    </p>
    <p>Would you still like to proceed?</p>
  </>
);

export function deleteCameraWarning(name: string) {
  return (
    <div>
      Are you sure you want to delete <b>{name}</b>?  This camera is shared across your projects.
      This action cannot be undone.
    </div>
  );
}

export function replaceInterfaceWithContactsWarning(location: string) {
  return (
    <div>
      This will remove all the currently defined interfaces in {location}.  Are you sure you
      want to proceed?
    </div>
  );
}
