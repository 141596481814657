// Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';

import { checkBreakpointVertical } from '../../../lib/layoutUtils';
import { clamp } from '../../../lib/number';
import useResizeObserver from '../../../lib/useResizeObserver';
import { NumberSpinner } from '../../NumberSpinner';
import { SimpleSlider } from '../slider/SimpleSlider';

import './NumberSliderCombo.scss';

const DEFAULT_NUMBER_WIDTH = 100;
const BREAK_POINT_WIDTH = 275;

export interface NumberSliderComboProps {
  value: number;
  onChange: (value: number) => void;
  onCommit?: (value: number) => void;
  minimumValue: number;
  maximumValue: number;
  disabled?: boolean;
  // How much of the width should be taken up by the NumberSpinner.  Values >= 1
  // are interpreted as px values, while values < 1 are interpreted as a
  // proportion (and converted to '%').
  numberWidth?: number;
  // Causes values to be trimmed to a set number of significant digits
  signficantDigits?: number;
  // Optional spinner step value
  step?: number;
  // Optional vertical orientation
  vertical?: boolean;
}

export const NumberSliderCombo = (props: NumberSliderComboProps) => {
  const {
    disabled,
    maximumValue,
    minimumValue,
    numberWidth = DEFAULT_NUMBER_WIDTH,
    onChange,
    onCommit,
    signficantDigits,
    step,
    value,
  } = props;

  // This is a controller input (i.e. onChange is expected to update the value),
  // but applications may find it convenient to manage values through the
  // less-frequently fired onCommit callback.  Here, we maintain the current
  // value internally to make sure both controls' values are synced.
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const normalizeValue = (newValue: number) => {
    const normValue = Number.isNaN(newValue) ? minimumValue : newValue;
    if (signficantDigits !== undefined) {
      return parseFloat(normValue.toPrecision(signficantDigits));
    }
    return normValue;
  };

  const constrainedValue = useMemo(() => (
    clamp(currentValue, [minimumValue, maximumValue])
  ), [maximumValue, minimumValue, currentValue]);

  // It's important that each of the constituent components has its own onChange
  // handler.  Otherwise, React doesn't sync the values correctly (probably some
  // race condition).
  const changeSliderValue = (newValue: number, commit = false) => {
    const normValue = normalizeValue(newValue);
    setCurrentValue(normValue);
    onChange(normValue);
    commit && onCommit?.(normValue);
  };

  const changeSpinnerValue = (newValue: number, commit = false) => {
    const normValue = normalizeValue(newValue);
    setCurrentValue(normValue);
    onChange(normValue);
    commit && onCommit?.(normValue);
  };

  const numSpinWidth = numberWidth < 1 ? `${numberWidth * 100}%` : `${numberWidth}px`;

  const readoutConfig = {
    disabled: true,
  };

  const ref = useRef<HTMLDivElement>(null);
  const domSize = useResizeObserver(ref, { refreshPeriod: 0 });

  const vertical = checkBreakpointVertical(props.vertical, domSize.width, BREAK_POINT_WIDTH);

  return (
    <div
      className={cx('numberSliderCombo', { vertical })}
      ref={ref}
      style={{ '--number-spinner-width': numSpinWidth } as CSSProperties}>
      <div className="spinner">
        <NumberSpinner
          disabled={disabled}
          maximumValue={maximumValue}
          minimumValue={minimumValue}
          onChange={(newValue) => changeSpinnerValue(newValue)}
          onCommit={(newValue) => changeSpinnerValue(newValue, true)}
          step={step}
          value={currentValue}
        />
      </div>
      <div className="slider">
        <SimpleSlider
          disabled={disabled}
          max={maximumValue}
          min={minimumValue}
          onChange={(newValue) => changeSliderValue(newValue)}
          onCommit={(newValue) => changeSliderValue(newValue, true)}
          readoutConfig={readoutConfig}
          value={constrainedValue}
        />
      </div>
    </div>
  );
};
