// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 33;
const baseHeight = 35;
export const BisectIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="bisect"
      height={finalHeight}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve">
      <rect
        height="25"
        style={{
          fill: 'none',
          stroke: getColor(0),
          strokeWidth: '3px',
          strokeDasharray: '6,4',
        }}
        width="30"
        x="1.5"
        y="5"
      />
      <path
        d="M14,5L1.5,5L1.5,30L14,30"
        style={{
          fill: 'none',
          fillRule: 'nonzero',
          stroke: getColor(0),
          strokeWidth: '3px',
        }}
      />
      <path
        d="M16.5,0L16.5,35"
        style={{
          fill: 'none',
          fillRule: 'nonzero',
          stroke: getColor(0),
          strokeWidth: '3px',
        }}
      />
    </svg>
  );
});
