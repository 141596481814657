// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const RingOfCirclesIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="ringOfCircles"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M21.24,9.03
           C21.06,8.49 20.85,7.98 20.595,7.485
           C20.85,7.05 21,6.54 21,6
           C21,4.35 19.65,3 18,3
           C17.46,3 16.95,3.15 16.515,3.405
           C16.02,3.15 15.51,2.94 14.97,2.76
           C14.85,1.215 13.575,0 12,0
           C10.425,0 9.15,1.215 9.03,2.76
           C8.49,2.94 7.98,3.15 7.485,3.405
           C7.05,3.15 6.54,3 6,3
           C4.35,3 3,4.35 3,6
           C3,6.54 3.15,7.05 3.405,7.485
           C3.15,7.98 2.94,8.49 2.76,9.03
           C1.215,9.15 0,10.425 0,12
           C0,13.575 1.215,14.85 2.76,14.97
           C2.94,15.51 3.15,16.02 3.405,16.515
           C3.15,16.95 3,17.46 3,18
           C3,19.65 4.35,21 6,21
           C6.54,21 7.05,20.85 7.485,20.595
           C7.98,20.85 8.49,21.075 9.03,21.24
           C9.15,22.785 10.425,24 12,24
           C13.575,24 14.85,22.785 14.97,21.24
           C15.51,21.06 16.02,20.85 16.515,20.595
           C16.95,20.85 17.46,21 18,21
           C19.65,21 21,19.65 21,18
           C21,17.46 20.85,16.95 20.595,16.515
           C20.85,16.02 21.06,15.51 21.24,14.97
           C22.785,14.85 24,13.575 24,12
           C24,10.425 22.785,9.15 21.24,9.03Z
           M19.755,14.715
           C19.68,14.955 19.59,15.18 19.5,15.405
           C19.05,15.15 18.54,15 18,15
           C16.35,15 15,16.35 15,18
           C15,18.54 15.15,19.05 15.405,19.5
           C15.18,19.605 14.955,19.68 14.715,19.755
           C14.25,18.72 13.215,18 12,18
           C10.785,18 9.75,18.72 9.285,19.755
           C9.045,19.665 8.805,19.59 8.595,19.5
           C8.85,19.05 9,18.54 9,18
           C9,16.35 7.65,15 6,15
           C5.46,15 4.95,15.15 4.5,15.405
           C4.395,15.18 4.32,14.955 4.245,14.715
           C5.28,14.25 6,13.215 6,12
           C6,10.785 5.28,9.75 4.245,9.285
           C4.335,9.045 4.41,8.805 4.5,8.595
           C4.95,8.85 5.46,9 6,9
           C7.65,9 9,7.65 9,6
           C9,5.46 8.85,4.95 8.595,4.5
           C8.82,4.395 9.045,4.32 9.285,4.245
           C9.75,5.28 10.785,6 12,6
           C13.215,6 14.25,5.28 14.715,4.245
           C14.955,4.335 15.195,4.41 15.405,4.5
           C15.15,4.95 15,5.46 15,6
           C15,7.65 16.35,9 18,9
           C18.54,9 19.05,8.85 19.5,8.595
           C19.605,8.82 19.68,9.045 19.755,9.285
           C18.72,9.75 18,10.785 18,12
           C18,13.215 18.72,14.25 19.755,14.715Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
