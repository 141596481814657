import { LCVTransformPreviewType } from '@luminarycloudinternal/lcvis';

import { Pattern, Transform } from '../../../../proto/geometry/geometry_pb';
import { lcvHandler } from '../../handler/LcvHandler';

import { PreviewParam, TransformPreviewParam } from './LcvTransformAnnotation';

const showPreviewAnnotation = (
  surfaceIds: string[],
  previewParam: PreviewParam,
) => {
  lcvHandler.queueDisplayFunction('set transform preview', (display) => {
    const { transformAnnotation, workspace, frames } = display;
    if (!workspace || !transformAnnotation || !frames.length) {
      return;
    }
    // Assume that we only ever use the transform preview on the importDatasetFilter
    const indices = workspace.getImportDatasetIndices(surfaceIds);
    const filter = workspace.importDatasetFilter;
    const transformParam: TransformPreviewParam = {
      param: previewParam,
      filterHandle: filter.handle,
      surfaceIndices: indices,
    };

    transformAnnotation.setTransformParam(transformParam);
    transformAnnotation.show(frames[0]);
  });
};

export const showTransformPreview = (
  transf: Transform,
  surfaceIds: string[],
) => {
  if (transf.t.case === 'translation') {
    const vector = transf.t.value.displacement;
    if (vector.case === 'vector') {
      const origin: [number, number, number] = [vector.value.x, vector.value.y, vector.value.z];
      showPreviewAnnotation(
        surfaceIds,
        {
          typ: LCVTransformPreviewType.kLCVTransformPreviewTypeTranslate,
          origin,
        },
      );
    }
  } else if (transf.t.case === 'rotation') {
    const angle = transf.t.value.angle;
    const axis = transf.t.value.axis;
    const origin = axis.value?.origin;
    if (axis.case === 'arbitrary' && origin && axis.value.direction) {
      const axisVec: [number, number, number] = [
        axis.value.direction.x,
        axis.value.direction.y,
        axis.value.direction.z,
      ];
      const originVec: [number, number, number] = [origin.x, origin.y, origin.z];
      showPreviewAnnotation(
        surfaceIds,
        {
          typ: LCVTransformPreviewType.kLCVTransformPreviewTypeRotate,
          origin: originVec,
          axis: axisVec,
          angle,
        },
      );
    }
  } else if (transf.t.case === 'scaling') {
    const origin = transf.t.value.origin;
    const factor = transf.t.value.factor;
    if (origin.case === 'arbitrary' && factor.case === 'isotropic') {
      const originVec: [number, number, number] = [
        origin.value.x,
        origin.value.y,
        origin.value.z,
      ];
      showPreviewAnnotation(
        surfaceIds,
        {
          typ: LCVTransformPreviewType.kLCVTransformPreviewTypeScale,
          origin: originVec,
          scale_factor: factor.value,
        },
      );
    }
  } else if (transf.t.case === 'reflection') {
    const plane = transf.t.value.plane;
    if (plane.case === 'arbitrary' && plane.value?.origin && plane.value.direction) {
      const origin = plane.value.origin;
      const direction = plane.value.direction;
      const originVec: [number, number, number] = [origin.x, origin.y, origin.z];
      const directionVec: [number, number, number] = [direction.x, direction.y, direction.z];
      showPreviewAnnotation(
        surfaceIds,
        {
          typ: LCVTransformPreviewType.kLCVTransformPreviewTypeMirror,
          origin: originVec,
          axis: directionVec,
        },
      );
    }
  }
};

export const showPatternPreview = (
  pattern: Pattern,
  surfaceIds: string[],
) => {
  if (pattern.direction?.type.case === 'linearSpacing') {
    const displacement = pattern.direction.type.value.displacement;
    if (displacement.case !== 'magnitudeVector') {
      return;
    }
    const direction = displacement.value.direction;
    if (!direction) {
      return;
    }
    const magnitude = displacement.value.magnitude;
    const translation: [number, number, number] = [
      direction.x * magnitude,
      direction.y * magnitude,
      direction.z * magnitude,
    ];
    const param = {
      typ: LCVTransformPreviewType.kLCVTransformPreviewTypeLinearPattern as const,
      translation,
      pattern_symmetric: pattern.direction.symmetric ? 1 : 0,
      pattern_quantity: pattern.direction.quantity,
    };
    showPreviewAnnotation(surfaceIds, param);
  } else if (pattern.direction?.type.case === 'circularDistribution') {
    const rotation = pattern.direction.type.value.rotation;
    if (rotation?.axis.case !== 'arbitrary') {
      return;
    }
    const axis = rotation.axis.value;
    if (!axis?.direction || !axis.origin) {
      return;
    }
    const axisVec: [number, number, number] = [
      axis.direction.x,
      axis.direction.y,
      axis.direction.z,
    ];
    const origin: [number, number, number] = [axis.origin.x, axis.origin.y, axis.origin.z];
    const param = {
      typ: LCVTransformPreviewType.kLCVTransformPreviewTypeCircularPattern as const,
      axis: axisVec,
      origin,
      angle: rotation.angle,
      pattern_symmetric: pattern.direction.symmetric ? 1 : 0,
      pattern_quantity: pattern.direction.quantity,
      pattern_circular_full: pattern.direction.type.value.full ? 1 : 0,
    };
    showPreviewAnnotation(surfaceIds, param);
  }
};

export const hidePreviewAnnotation = () => {
  lcvHandler.queueDisplayFunction('hide transform preview', (display) => {
    const { transformAnnotation } = display;
    transformAnnotation?.hide();
  });
};
