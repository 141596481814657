// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ActionButton } from '../Button/ActionButton';
import { Flex } from '../visual/Flex';

export interface UnitVectorButtonsProps {
  onClick: (x: number, y: number, z: number) => void;
  disabled?: boolean;
}

export const UnitVectorButtons = (props: UnitVectorButtonsProps) => {
  const { onClick, disabled } = props;

  return (
    <Flex gap={4}>
      <ActionButton
        disabled={disabled}
        kind="minimal"
        onClick={() => onClick(1, 0, 0)}
        size="small"
        title="Set the axis direction to (1, 0, 0)">
        X
      </ActionButton>
      <ActionButton
        disabled={disabled}
        kind="minimal"
        onClick={() => onClick(0, 1, 0)}
        size="small"
        title="Set the axis direction to (0, 1, 0)">
        Y
      </ActionButton>
      <ActionButton
        disabled={disabled}
        kind="minimal"
        onClick={() => onClick(0, 0, 1)}
        size="small"
        title="Set the axis direction to (0, 0, 1)">
        Z
      </ActionButton>
    </Flex>
  );
};
