// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';

import { getMeshInfoFromMeshId } from '../lib/mesh';
import { getSimulationParam } from '../lib/simulationParamUtils';
import * as frontendpb from '../proto/frontend/frontend_pb';

import { meshUrlState } from './meshState';
import { currentConfigSelector } from './workflowConfig';

type RecoilKey = {
  projectId: string,
  workflowId?: string,
  jobId?: string,
}
/**
 * This selector pulls the full mesh object from the meshId on project meshState
 */
export const projectActiveMeshSelector = selectorFamily<frontendpb.Mesh | undefined, RecoilKey>({
  key: 'projectActiveMeshSelector',
  get: (key: RecoilKey) => async ({ get }) => {
    const { projectId, workflowId, jobId } = key;
    let meshId = '';
    if (workflowId && jobId) {
      // If we are here, we are on the results page and have passed in a specific workflow/job
      // This block ensures we are fetching the mesh associated with the selected result, not the
      // meshUrlState. The meshUrlState is tied to the project and the current in-progress simParam.
      const config = get(currentConfigSelector({ projectId, workflowId, jobId }));
      const simParam = getSimulationParam(config);
      meshId = simParam.input?.meshIdentifier?.id || '';
    } else {
      // If no workflowId or jobId is provided, we are on the Setup page working with a new simParam
      // Here, we can use the meshUrlState tied to the current project.
      const meshUrl = get(meshUrlState(projectId));
      meshId = meshUrl.meshId;
    }

    const mesh = await getMeshInfoFromMeshId(meshId);
    return mesh;
  },
  dangerouslyAllowMutability: true,
});

export const useProjectActiveMeshRefresher = (key: RecoilKey) => useRecoilRefresher_UNSTABLE(
  projectActiveMeshSelector(key),
);

export const useProjectActiveMesh = (
  projectId: string,
  workflowId: string = '',
  jobId: string = '',
) => useRecoilValue(projectActiveMeshSelector({ projectId, workflowId, jobId }));

export const useHasActiveMesh = (
  projectId: string,
  workflowId: string = '',
  jobId: string = '',
) => {
  const activeMesh = useRecoilValue(projectActiveMeshSelector({ projectId, workflowId, jobId }));
  return !!activeMesh;
};
