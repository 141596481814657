// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atom as atomRecoil } from 'recoil';

import { CurrentView } from '../../../lib/componentTypes/context';

/**
 * This is the currentView state implemented with Jotai. Temporarily we'll have both to allow
 * more states to be transitioned separately.
 */
export const currentViewState = atom<CurrentView>(CurrentView.SETUP);

/**
 * The current view. It is being set separately from each one of the top project pages. When changed
 * it aslo updates the jotai version of the state.
 */
export const currentViewAtom_DEPRECATED = atomRecoil<CurrentView>({
  key: 'currentView',
  default: CurrentView.SETUP,
});

export const useSetCurrentView = () => useSetAtom(currentViewState);

export const useCurrentView = () => useAtomValue(currentViewState);

export const useIsAnalysisView = () => useAtomValue(currentViewState) === CurrentView.ANALYSIS;

export const useIsGeometryView = () => useAtomValue(currentViewState) === CurrentView.GEOMETRY;

export const useIsResultsView = () => useAtomValue(currentViewState) === CurrentView.RESULTS;

export const useIsSetupView = () => useAtomValue(currentViewState) === CurrentView.SETUP;

export const useIsSolverView = () => useAtomValue(currentViewState) === CurrentView.SOLVER;
