// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useEffect, useRef } from 'react';

import cx from 'classnames';

import { Level } from '../../../lib/notificationUtils';
import { XIcon } from '../../svg/XIcon';
import { AlertIcon } from '../AlertIcon';
import { Link, PanelLinks } from '../PanelLinks';

import './MessagePanel.scss';

export type Variant = 'toast' | 'sectionMessage';

export interface CoreProps {
  // An optional panel title
  title?: string;
  // The panel message
  message?: ReactNode;
  // Arbitrary content (intended for CTA links)
  links?: Link[];
  // The level (info, success, warning, error) determines the panel's color scheme
  level: Level;
  // Override the default icon for the specified level and use another icon instead
  icon?: Level;
  // When onDismiss is provided, it causes a dismiss button to appear in the
  // upper right of the panel and is called when the button is clicked.
  onDismiss?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  // Sets an optional expiration time at which the panel should automatically
  // dismiss itself
  expiry?: Date;
  // Optional child content (takes precedence over 'message' prop)
  children?: ReactNode;
  // Optional project name that may exist on some notifications
  projectName?: string;
}

export interface MessagePanelProps extends CoreProps {
  variant: Variant;
}

export const MessagePanel = (props: MessagePanelProps) => {
  const {
    children, expiry, level, links = [], message, onDismiss, title, variant, icon, projectName,
  } = props;

  const autoDismiss = useRef<ReturnType<typeof setTimeout> | null>(null);

  // This useEffect updates the autoDismiss timeout based on the values of
  // expiry and onDismiss.
  useEffect(() => {
    if (autoDismiss.current) {
      clearTimeout(autoDismiss.current);
      autoDismiss.current = null;
    }
    if (expiry) {
      const delta = expiry.getTime() - Date.now();
      if (delta >= 0) {
        autoDismiss.current = setTimeout(() => {
          onDismiss?.();
        }, delta);
      } else {
        onDismiss?.();
      }
    }
  }, [expiry, onDismiss]);

  // This useEffect returns a function to clear the autoDismiss timeout; it is
  // executed only when the component unmounts.
  useEffect(() => () => {
    if (autoDismiss.current) {
      clearTimeout(autoDismiss.current);
      autoDismiss.current = null;
    }
  });

  const hasHeaderContent = title || projectName;

  return (
    <div className={cx('messagePanel', level, variant)}>
      <div className="header">
        <div className="icon">
          <AlertIcon level={icon ?? level} />
        </div>

        <div className="details">
          {title && <div className="title">{title}</div>}
          {projectName && <div className="message">Project: {projectName}</div>}
        </div>

        {onDismiss && (
          <div className="control">
            <button
              data-testid="dismissMessagePanel"
              onClick={(event) => onDismiss(event)}
              type="button">
              <XIcon maxHeight={8} maxWidth={8} />
            </button>
          </div>
        )}
      </div>

      <div className={cx('content', !hasHeaderContent && 'shifted')}>
        {(children || message) && <div className="message">{children ?? message}</div>}
      </div>

      {links.length > 0 && <div className="links"><PanelLinks links={links} /></div>}

    </div>
  );
};
