// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as meshgenerationpb from '../../proto/meshgeneration/meshgeneration_pb';

// The default parameter values for the model and boundary layer.
const MODEL_CURVATURE = 8;
const BL_NUM_LAYERS = 40;
const BL_INITIAL_SIZE = 0.000001;
const BL_GROWTH_RATE = 1.2;

export const DEFAULT_MODEL = new meshgenerationpb.MeshingMultiPart_ModelParams({
  curvature: MODEL_CURVATURE,
  selection: meshgenerationpb.MeshingMultiPart_ModelParams_SelectionType.WALL,
});

export const DEFAULT_BL = new meshgenerationpb.MeshingMultiPart_BoundaryLayerParams({
  selection: meshgenerationpb.MeshingMultiPart_BoundaryLayerParams_SelectionType.WALL_NO_SLIP,
  nLayers: BL_NUM_LAYERS,
  initialSize: BL_INITIAL_SIZE,
  growthRate: BL_GROWTH_RATE,
});

export const DEFAULT_MESHING_MULTIPART = new meshgenerationpb.MeshingMultiPart({
  modelParams: [DEFAULT_MODEL],
  volumeParams: [{}],
  blParams: [DEFAULT_BL],
});
