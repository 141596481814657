// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import { getQuantityUnit } from '../../QuantityDescriptor';
import { EMPTY_VALUE } from '../../lib/constants';
import { formatNumber } from '../../lib/number';
import { QuantityType } from '../../proto/quantity/quantity_pb';
import QuantityAdornment from '../QuantityAdornment';

import { ValidNumberInput, ValidNumberInputProps } from './ValidatedInputs/ValidNumberInput';

interface AutoAdornmentProps {
  adornment?: ReactNode;
  quantity?: QuantityType;
}

const AutoAdornment = (props: AutoAdornmentProps) => {
  const { adornment, quantity } = props;

  if (adornment) {
    return (
      <>{adornment}</>
    );
  }

  if (quantity) {
    return (
      <QuantityAdornment quantity={quantity} />
    );
  }

  return null;
};

export interface NumberFieldProps extends ValidNumberInputProps {
  // If the field is an input or just a static constant number.
  isInput: boolean;
  quantity?: QuantityType;
}

// The number field is a way to easily switch between a number input and a static text of the
// number.
export const NumberField = (props: NumberFieldProps) => {
  const { endAdornment, isInput, value, quantity } = props;

  if (isInput) {
    return (
      <ValidNumberInput
        {...props}
        endAdornment={(<AutoAdornment adornment={endAdornment} quantity={quantity} />)}
      />
    );
  }

  const units = quantity && (` ${getQuantityUnit(quantity)}`);
  if (value === null) {
    return <>{EMPTY_VALUE}</>;
  }
  return <>{`${formatNumber(value)}`}{units}</>;
};
