// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtomValue } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { Logger } from '../../../lib/observability/logs';
import * as rpc from '../../../lib/rpc';
import { isTestingEnv } from '../../../lib/testing/utils';
import * as supportpb from '../../../proto/support/support_pb';

const logger = new Logger('intercom');

/**
 * This gets a hashed with the user's id and the Secret Key from Intercom's Messenger.
 * This is needed if we want to enable the Identity Verification for the Messenger.
 * */
function rpcGenerateIntercomUserHMAC() {
  const req = new supportpb.GenerateIntercomHMACRequest();
  return rpc.callRetry('generateIntercomHMAC', rpc.client.generateIntercomHMAC, req);
}

const intercomUserHashState = atomFamily((userId: string | undefined) => atom(async () => {
  if (isTestingEnv() || !userId) {
    return '';
  }
  try {
    const reply = await rpcGenerateIntercomUserHMAC();
    return reply.hmac;
  } catch (err) {
    logger.error(err);
    return '';
  }
}));

export function useIntercomUserHash(userId: string) {
  return useAtomValue(intercomUserHashState(userId));
}
