// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import { getMeshMetadata } from '../lib/mesh';
import * as lcmeshpb from '../proto/lcn/lcmesh_pb';

import { meshUrlState } from './meshState';

/**
 * This selector pulls the mesh metadata using the meshId on project meshState
 */
export const projectActiveMeshMetadataSelector = selectorFamily<
  lcmeshpb.MeshFileMetadata | undefined, string
>({
  key: 'projectActiveMeshMetadataSelector',
  get: (projectId: string) => async ({ get }) => {
    const meshUrl = get(meshUrlState(projectId));
    const meshId = meshUrl.meshId;
    const metadata = await getMeshMetadata(meshId);
    return metadata;
  },
  dangerouslyAllowMutability: true,
});

const projectActiveMeshMetadataAtom = atomFamily<
  lcmeshpb.MeshFileMetadata | undefined, string
>({
  key: 'projectActiveMesh',
  default: projectActiveMeshMetadataSelector,
});

export function useProjectActiveMeshMetadata(projectId: string) {
  return useRecoilValue(projectActiveMeshMetadataAtom(projectId));
}

export function useSetProjectActiveMeshMetadata(projectId: string) {
  return useSetRecoilState(projectActiveMeshMetadataAtom(projectId));
}
