// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import { Options, useNodePanel } from '../../lib/useNodePanel';
import { PanelName } from '../../recoil/expandedPanels';

import { BaseProps, CollapsiblePanel } from './CollapsiblePanel';

export interface CollapsibleNodePanelProp extends BaseProps {
  nodeId: string;
  panelName: PanelName;
  panelOptions?: Options;
  children: ReactNode
}

export const CollapsibleNodePanel = (props: CollapsibleNodePanelProp) => {
  const { nodeId, panelName, panelOptions, ...baseProps } = props;

  const panel = useNodePanel(nodeId, panelName, panelOptions);

  return (
    <CollapsiblePanel
      {...baseProps}
      collapsed={panel.collapsed}
      onToggle={panel.toggle}
    />
  );
};
