// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

// Custom utility type that returns type T with members K marked as optional
export type Optional<T, K extends keyof T> = Omit<T, K> & Pick<Partial<T>, K>;

/**
 * Returns a Record object analogous to the defaultdict in Python.
 * If a field is not present but the user tries to access it, that field's value
 * will be set to an object of type T created by makeDefault.
 * @param makeDefault generates the default value for newly created fields.
 * @param initialValue holds any fields to add at initialization.
 */
export function defaultDict<T extends any>(
  makeDefault: () => T,
  initialValue: Record<string, T> = {},
): Record<string, T> {
  return new Proxy(initialValue, {
    get(target, key) {
      if (!(key in target)) {
        target[key as string] = makeDefault();
      }
      return target[key as string];
    },
  });
}

export type FixedSizeArray<N extends number, T> = N extends 0 ? never[] : {
  0: T;
  length: N;
} & Array<T>;

export type OptionalSpread<T> = T extends undefined ? [] : [T];

/**
 * A bidirectional Map class for efficient key -> value and value -> key lookups
 * using getByKey() or getByValue(). It supports this two-way mapping by enforcing uniqueness of
 * both its keys and values.
 *
 * @template K The type of keys in the map.
 * @template V The type of values in the map.
*/
export class TwoWayMap<K, V> {
  private keyToValueMap: Map<K, V>;
  private valueToKeyMap: Map<V, K>;

  constructor(iterable?: Iterable<[K, V]> | Map<K, V>) {
    this.keyToValueMap = new Map<K, V>();
    this.valueToKeyMap = new Map<V, K>();

    if (iterable) {
      if (iterable instanceof Map) {
        iterable.forEach((value, key) => {
          this.set(key, value);
        });
      } else {
        [...iterable].forEach(([key, value]) => {
          this.set(key, value);
        });
      }
    }
  }

  /**
   * Add a key-value pair to the map.
   * Throws an error if the value is already bound to a different key in the map.
  */
  set(key: K, value: V): void {
    if (this.valueToKeyMap.has(value) && this.valueToKeyMap.get(value) !== key) {
      throw Error(`Attempted to set a duplicate value ${value} in the map`);
    }
    this.delete(key);
    this.keyToValueMap.set(key, value);
    this.valueToKeyMap.set(value, key);
  }

  /**
   * forceSet allows you to add a new entry to the TwoWayMap without throwing an error if the
   * value is already bound to another key. This silently removes the key that the value was
   * previously bound to.
   */
  forceSet(key: K, value: V): void {
    const prevKey = this.getByValue(value);
    if (prevKey !== undefined) {
      this.keyToValueMap.delete(prevKey);
    }

    this.keyToValueMap.set(key, value);
    this.valueToKeyMap.set(value, key);
  }

  /** Get the value associated with a key */
  getByKey(key: K): V | undefined {
    return this.keyToValueMap.get(key);
  }

  /** Get the key associated with a value */
  getByValue(value: V): K | undefined {
    return this.valueToKeyMap.get(value);
  }

  /** Check if the map contains a key */
  hasKey(key: K): boolean {
    return this.keyToValueMap.has(key);
  }

  /** Check if the map contains a value */
  hasValue(value: V): boolean {
    return this.valueToKeyMap.has(value);
  }

  /** Delete a key-value pair from the map */
  delete(key: K): void {
    const value = this.keyToValueMap.get(key);
    this.keyToValueMap.delete(key);
    if (value !== undefined) {
      this.valueToKeyMap.delete(value);
    }
  }

  /** Get the size of the map */
  size(): number {
    return this.keyToValueMap.size;
  }

  /** Clear the map */
  clear(): void {
    this.keyToValueMap.clear();
    this.valueToKeyMap.clear();
  }

  /** Iterate through the entries of the TwoWayMap */
  forEach(callbackfn: (value: V, key: K) => void): void {
    this.keyToValueMap.forEach((value, key) => {
      callbackfn(value, key);
    });
  }

  /** Return an iterator over the map keys */
  keys(): IterableIterator<K> {
    return this.keyToValueMap.keys();
  }

  /** Return an iterator over the map values */
  values(): IterableIterator<V> {
    return this.keyToValueMap.values();
  }
}

export function recordToMap<T, U>(record: Record<string, T>): Map<U, T> {
  const map = new Map<U, T>();
  Object.entries(record).forEach(([key, value]) => {
    map.set(key as U, value);
  });

  return map;
}
