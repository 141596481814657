// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, useRecoilState, useSetRecoilState } from 'recoil';

import { frontendMenuStateFixture } from '../lib/fixtures';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { isTestingEnv } from '../lib/testing/utils';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';

export const frontendMenuStatePrefix = 'frontendMenu';

/**
 * @deprecated The method should not be used. Replaced by `persist.getProjectStateKey`
 */
function legacyFrontendMenuKey(workflowId: string) {
  return `frontendMenu/workflow=${workflowId || 'setup'}`;
}

/**
 * @deprecated The method should not be used. Replaced by `persist.getProjectStateKey`
 */
function legacyRecoilKey(projectId: string, workflowId: string): RecoilKey {
  return {
    projectId,
    keys: [
      legacyFrontendMenuKey(workflowId),
    ],
  };
}

type RecoilKey = {
  projectId: string;
  keys: string[];
}

export function serialize(val: projectstatepb.FrontendMenuState) {
  // Clear the deprecated stopping conditions and outputNodes message.
  val.outputNodes = undefined;
  val.stopConds = undefined;
  return val.toBinary();
}

function deserialize(val: Uint8Array): projectstatepb.FrontendMenuState {
  return projectstatepb.FrontendMenuState.fromBinary(val);
}

export const frontendMenuStateRpc = atomFamily<
  projectstatepb.FrontendMenuState,
  persist.RecoilProjectKey
>({
  key: 'frontendMenu',
  default: (key: persist.RecoilProjectKey) => (
    persist.getProjectState(
      key.projectId,
      [
        persist.getProjectStateKey(frontendMenuStatePrefix, key),
        persist.getProjectStateKey(frontendMenuStatePrefix, { ...key, jobId: '' }),
        ...legacyRecoilKey(key.projectId, key.workflowId).keys,
      ],
      deserialize,
    )
  ),
  effects: (key: persist.RecoilProjectKey) => [
    syncProjectStateEffect(
      key.projectId,
      persist.getProjectStateKey(frontendMenuStatePrefix, key),
      (val) => projectstatepb.FrontendMenuState.fromBinary(val),
      serialize,
    ),
  ],
  // Protobuf objects mutate themselves even in get*.
  dangerouslyAllowMutability: true,
});

const frontendMenuStateTesting = atomFamily<
  projectstatepb.FrontendMenuState,
  persist.RecoilProjectKey
>({
  key: 'frontendMenu',
  default: frontendMenuStateFixture,
  // Protobuf objects mutate themselves even in get*.
  dangerouslyAllowMutability: true,
});

export const frontendMenuState = isTestingEnv() ? frontendMenuStateTesting : frontendMenuStateRpc;

// Return the frontend menu state for the given project,
// and a setter for that state (like useRecoilState).
export function useFrontendMenuState(
  projectId: string,
  workflowId: string,
  jobId: string,
) {
  return useRecoilState(frontendMenuState({ projectId, workflowId, jobId }));
}

export function useSetFrontendMenuState(
  projectId: string,
  workflowId: string,
  jobId: string,
) {
  return useSetRecoilState(frontendMenuState({ projectId, workflowId, jobId }));
}
