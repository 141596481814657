// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 15;
const baseHeight = 15;

export const SuccessWithWarningIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="successWithWarning"
      height={finalHeight}
      ref={ref}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="m14.91 14.031.006-.005-3.935-6.75-.006.005A.544.544 0 0 0 10.497 7a.55.55 0 0 0-.478.281
           l-.005-.005-3.935 6.75.005.005a.534.534 0 0 0-.084.281c0 .31.253.563.562.563h7.87c.31 0
           .562-.253.562-.563a.534.534 0 0 0-.084-.28Z"
        fill="#FFA233"
      />
      <path
        clipRule="evenodd"
        d="m11.847 6.334.128.22A5.998 5.998 0 0 0 6 0C2.685-.001 0 2.684 0 5.999a5.998 5.998 0 0 0
        5.85 5.998l3.303-5.664a1 1 0 0 1 .127-.173c.271-.344.695-.598 1.22-.598.514 0 .954.246
        1.232.613.043.048.082.101.115.158ZM9 3.75a.748.748 0 0 0-.533.217L5.25 7.192 3.533 5.468
        a.752.752 0 0 0-1.065 1.065l2.25 2.25A.748.748 0 0 0 5.25 9c.21 0 .397-.082.532-.217
        l3.75-3.75A.752.752 0 0 0 9 3.75Z"
        fill={getColor(0)}
        fillRule="evenodd"
      />
    </svg>
  );
});
