// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 132;
const baseHeight = 132;
export const GeometryBooleanIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="geometryBoolean"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M101.876,52L132,52L132,132L52,132L52,101.824C53.334,101.941 54.684,102 56.048,102
           C81.436,102 102.048,81.388 102.048,56C102.048,54.652 101.99,53.318 101.876,52Z
           M52,89.761L52,52L89.815,52C89.969,53.312 90.048,54.647 90.048,56
           C90.048,74.765 74.813,90 56.048,90C54.678,90 53.327,89.919 52,89.761Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M40,91.611C17.442,88.668 0,69.355 0,46C0,20.612 20.612,0 46,0
           C69.355,0 88.668,17.442 91.611,40L40,40L40,91.611Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
