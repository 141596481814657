// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ActionLink } from '../Button/ActionLink';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      gap: '0.5em',
    },
  }),
  { name: 'UploadHelp' },
);

const cadLink = 'https://docs.luminarycloud.com/en/articles/9274255-upload-cad#h_8223900815';
const meshLink = 'https://docs.luminarycloud.com/en/articles/9275233-upload-a-mesh';

export const UploadHelp = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span>Luminary supports many common&nbsp;
        <ActionLink href={cadLink}>CAD</ActionLink>
        &nbsp;and&nbsp;
        <ActionLink href={meshLink}>mesh</ActionLink>
        &nbsp;formats.
      </span>
    </div>
  );
};
