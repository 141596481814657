// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { Suspense, useEffect, useState } from 'react';

import * as EmailValidator from 'email-validator';

import AuthForm from '../../components/Form/AuthForm';
import suspenseWidget from '../../components/SuspenseWidget';
import { AuthPageLayout } from '../../components/layout/page/AuthPageLayout';
import { getTempEmail, setTempEmail } from '../../lib/AuthV2';
import * as rpc from '../../lib/rpc';
import { isStorybookEnv } from '../../lib/testing/utils';
import * as frontendpb from '../../proto/frontend/frontend_pb';

export enum PageState {
  FORM,
  DONE,
}

interface ForgotPasswordPageProps {
  // Optional initial page state. This should be used ONLY for the storybook
  initialPageState?: PageState;
}

const ForgotPasswordPage = (props: ForgotPasswordPageProps) => {
  // Props
  const { initialPageState = PageState.FORM } = props;

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [pageState, setPageState] = useState<PageState>(initialPageState);

  const handleSubmit = async (data: Record<string, string>) => {
    // Save the email to the localstorage so that we can reuse it later when the user clicks the
    // link in their email inbox and comes back to the app.
    setTempEmail(data.email);

    if (!EmailValidator.validate(data.email)) {
      setError('Email is not valid');
      return;
    }
    setError('');
    setSubmitting(true);
    try {
      const req = new frontendpb.ResetPasswordRequest({ email: data.email });
      await rpc.callRetry('ResetPassword', rpc.client.resetPassword, req);
      setPageState(PageState.DONE);
    } catch (err) {
      setError(err.message || 'Resetting password failed. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!isStorybookEnv() && props.initialPageState !== undefined) {
      throw Error('Do not use the initialPageState outside of storybook env.');
    }
  }, [props.initialPageState]);

  if (pageState === PageState.DONE) {
    return (
      <AuthPageLayout
        backToLogin
        subtitle="If your email address is in our system, you'll receive an email with a link
          to reset your password. Be sure to check your spam or junk folders."
        title="Email Sent"
      />
    );
  }

  return (
    <AuthPageLayout
      back
      subtitle="Enter your email address to reset your password. You may need to check your spam
        folder or unblock the email sender no-reply@luminarycloud.com."
      title="Reset your password">
      <Suspense fallback={suspenseWidget}>
        <AuthForm
          fields={[
            {
              asBlock: true,
              autofocus: true,
              label: 'Email',
              disabled: submitting,
              name: 'email',
              placeholder: 'Enter your email',
              required: true,
              value: getTempEmail(),
            },
          ]}
          globalError={error}
          onSubmit={handleSubmit}
          submit={{
            disabled: submitting,
            showSpinner: submitting,
            label: 'Submit',
          }}
        />
      </Suspense>
    </AuthPageLayout>
  );
};

export default ForgotPasswordPage;
