// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { DownloadIcon } from '../svg/DownloadIcon';

import { ActionButton } from './ActionButton';

interface DownloadResponse {
  fileContents: Uint8Array;
  filename: string;
}

interface DownloadButtonProps {
  downloadFile: () => Promise<DownloadResponse>;
  disabled?: boolean;
  title?: string;
}

const DownloadButton = ({ downloadFile, disabled, title }: DownloadButtonProps) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleClick = async () => {
    if (isDownloading) {
      return;
    } // Prevent multiple clicks

    setIsDownloading(true);
    try {
      const response = await downloadFile();
      if (response?.fileContents && response?.filename) {
        const blob = new Blob([response.fileContents], { type: 'textcsv; charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = response.filename;
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } else {
        console.error('Download failed: response is null');
      }
    } catch (error) {
      console.error('Download failed:', error);
    } finally {
      setIsDownloading(false);
    }
  };
  return (
    <ActionButton
      disabled={disabled || isDownloading}
      kind="minimal"
      onClick={handleClick}
      showSpinner={isDownloading}
      size="small"
      title={title}>
      <DownloadIcon maxHeight={16} />
    </ActionButton>
  );
};

export default DownloadButton;
