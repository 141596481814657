// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue } from 'recoil';

import { getBoundaryConditionNames } from '../../../../../lib/boundaryConditionUtils';
import { RecoilProjectKey } from '../../../../../lib/persist';
import { simulationParamState } from '../param';

export type NamesRecord = Record<string, string>;

export const simulationBoundaryNamesState = selectorFamily<NamesRecord, RecoilProjectKey>({
  key: 'simulationBoundaryNamesState',
  get: (key: RecoilProjectKey) => ({ get }) => {
    const param = get(simulationParamState(key));
    return getBoundaryConditionNames(param);
  },
});

export function useSimulationBoundaryNames(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(simulationBoundaryNamesState({ projectId, workflowId, jobId }));
}
