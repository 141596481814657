// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

export interface FixedSizeWrapperProps {
  width: number;
  height: number;
  children: ReactNode;
}

/**
 * Primarily intended to wrap icons, which typically have upper bound widths and heights but can
 * have varying aspect ratios, FixedSizeWrapper creates a fixed size wrapper for content, centering
 * the child content inside it.
 *
 * Example usage:
 * <FixedSizeWrapper height={12} width={12}>
 *   <SvgIcon maxWidth={12} maxHeight={12} name="cubeOutline" />
 * </FixedSizeWrapper>
 *
 * Since the SVG icon has an aspect ration of 23:24, it will render at 11.5px (H) x 12px (W).  If
 * it's part of a grouping of other icons, the differing aspect ratios may cause uneven layouts.
 * FixedSizeWrapper can be used to normalized sizes.
 *
 * Note: this component should never add support for things (e.g. transforms, colors, etc.) that can
 * and should be handled inside the child content.
 */
export const FixedSizeWrapper = (props: FixedSizeWrapperProps) => {
  const { children, width, height } = props;

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {children}
    </div>
  );
};
