// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { Color } from '../../lib/componentTypes/svgIcon';
import { Point } from '../../lib/geometry';
import { pointsToPath } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

type Direction = 'down' | 'up' | 'left' | 'right';

interface TriangleIconProps {
  width: number;
  height: number;
  color?: Color | Color[]; // only HEX or RGB values supported
  direction?: Direction;
  angle?: number;
}

function getTriangleAngle(angle: number | undefined, direction: Direction) {
  if (angle === undefined) {
    switch (direction) {
      case 'up':
        return 180;
      case 'left':
        return 90;
      case 'right':
        return -90;
      default:
        return 0;
    }
  }

  return angle;
}

export const TriangleIcon = (props: TriangleIconProps) => {
  const { width, height, direction, color } = props;

  const { finalHeight, finalWidth, getColor } = useSvg(width, height, { scale: 1, color });

  // Points of a triangle with no direction or angle (pointing down by default)
  const refPoints: Point[] = [
    { x: 0, y: 0 },
    { x: width, y: 0 },
    { x: width / 2, y: height },
  ];

  const angle = getTriangleAngle(props.angle, direction ?? 'down') * (Math.PI / 180);

  // Some constants we'll reuse--center, angle sin/cos, etc.
  const cx = width / 2;
  const cy = height / 2;
  const cosAng = Math.cos(angle);
  const sinAng = Math.sin(angle);

  // Track extents so that final points can be clipped to bounding box
  let minX = cx;
  let maxX = cx;
  let minY = cy;
  let maxY = cy;

  const points: Point[] = refPoints.map(({ x, y }) => {
    // To rotate about center, first translate by [-cx, -cy], rotate about
    // origin, and then translate by [cx, cy].
    const xt = x - cx;
    const yt = y - cy;

    const x2 = (cosAng * xt) - (sinAng * yt) + cx;
    const y2 = (sinAng * xt) + (cosAng * yt) + cy;

    minX = Math.min(x2, minX);
    maxX = Math.max(x2, maxX);
    minY = Math.min(y2, minY);
    maxY = Math.max(y2, maxY);

    return { x: x2, y: y2 };
  });

  // Normalize the points so that their bounding box is anchored at [0, 0]
  const finalPoints: Point[] = points.map(({ x, y }) => ({ x: x - minX, y: y - minY }));

  // Build SVG path
  const path = pointsToPath(finalPoints, true);

  return (
    <svg
      height={finalHeight}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${width} ${height}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path d={path} fill={getColor(0)} />
    </svg>
  );
};
