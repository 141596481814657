// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useEffect, useState } from 'react';

import * as simulationpb from '../proto/client/simulation_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';

import { ClientState } from './ParaviewClient';
import { Logger } from './observability/logs';
import { appendFluidPhysics } from './physicsUtils';
import { protoToJson } from './proto';
import { addPvRpcError } from './transientNotification';

const logger = new Logger('useCoordinateVisualizer');

// Handles the RPCs needed to send the motion state to the visualizer and to set up its
// visualization
export const useCoordinateVisualizer = (
  simParam: simulationpb.SimulationParam,
  paraviewClientState: ClientState,
) => {
  const [showing, setShowing] = useState(false);

  // Clean up the coordinate axes on the visualizer
  const clear = useCallback((cleanup = false) => {
    if (paraviewClientState?.client && showing) {
      ParaviewRpc.clearcoordinateaxes(paraviewClientState.client).then(
        () => !cleanup && setShowing(false), // avoids state updates on unmounted components
      ).catch(
        (reason: any) => logger.error('Failed to call "clearcoordinateaxes"', reason),
      );
    }
  }, [paraviewClientState, setShowing, showing]);

  // Show coordinate axes for the given frame (by ID)
  const show = useCallback((frameId: string) => {
    if (!paraviewClientState?.client) {
      return;
    }

    // In order to reduce the size of the string to be sent to the visualizer, strip the initial
    // parameters as much as possible. The transformation from UI to param state needed to create
    // coordinate axes should not depend on the other param settings.
    const newParam = new simulationpb.SimulationParam({
      motionData: simParam.motionData,
      physics: [
        new simulationpb.Physics({
          params: {
            case: 'fluid',
            value: new simulationpb.Fluid(),
          },
        }),
      ],
    });
    appendFluidPhysics(newParam);

    // Convert the proto to json since the visualizer can only handle protos in json format.
    protoToJson(newParam, false).then((data: string) => {
      if (!paraviewClientState?.client) {
        // Nothing to do in this case since the visualizer is not connected
        return;
      }

      // Settings passed to the visualizer
      const settings: ParaviewRpc.GridMotionParam = {
        startTime: 0,
        endTime: 0,
        numberOfSteps: 1,
        fvmMotionData: data,
        drawAxes: true,
        transformSurfaces: false,
        drawAxesLinks: false,
        axesLength: 0.1,
        childFrameId: frameId,
      };

      // Render the coordinate axes
      ParaviewRpc.setcoordinateaxes(paraviewClientState.client, settings).then(
        () => setShowing(true),
      ).catch(
        (reason: any) => logger.error('Failed to setcoordinateaxes: ', reason),
      );
    }).catch(
      (err) => addPvRpcError('protoToJson', err),
    );
  }, [simParam, paraviewClientState, setShowing]);

  useEffect(() => () => clear(true), [clear]);

  return { show, clear };
};
