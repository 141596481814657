// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const RingCheckIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="ringCheck"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M13.057,6.447
           C12.787,6.162 12.413,5.997 11.993,5.997
           C11.168,5.997 10.494,6.671 10.494,7.496
           C10.494,7.916 10.659,8.29 10.929,8.56
           L13.927,11.558
           C14.197,11.828 14.571,11.993 14.991,11.993
           C15.411,11.993 15.786,11.828 16.055,11.558
           L23.551,4.063
           C23.821,3.793 23.986,3.418 23.986,2.999
           C23.986,2.174 23.311,1.499 22.487,1.499
           C22.067,1.499 21.692,1.664 21.422,1.934
           L14.991,8.38
           L13.057,6.447Z
           M20.883,10.959
           C20.928,11.303 20.988,11.633 20.988,11.993
           C20.988,16.955 16.955,20.988 11.993,20.988
           C7.031,20.988 2.998,16.955 2.998,11.993
           C2.998,7.031 7.031,2.998 11.993,2.998
           C13.207,2.998 14.376,3.253 15.441,3.688
           L17.689,1.439
           C15.995,0.525 14.062,0 11.993,0
           C5.367,0 0,5.367 0,11.993
           C0,18.619 5.367,23.986 11.993,23.986
           C18.619,23.986 23.986,18.619 23.986,11.993
           C23.986,10.749 23.791,9.535 23.446,8.41
           L20.883,10.959Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
