// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/library/library.proto (package luminary.proto.library, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * The type of library item (representing what settings it contains).  Valid types:
 *     PROJECT_SETTINGS: a bundle of simulation settings, meshing settings,
 *         visualization filters and saved cameras.
 *
 * @generated from enum luminary.proto.library.LibraryItemType
 */
export const LibraryItemType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.library.LibraryItemType",
  [
    {no: 0, name: "INVALID"},
    {no: 1, name: "PROJECT_SETTINGS"},
  ],
);

/**
 * LibraryItem represents an item saved in a library, which may be applied
 * to projects, overriding the project's settings with the saved settings.
 *
 * @generated from message luminary.proto.library.LibraryItem
 */
export const LibraryItem = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.library.LibraryItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(LibraryItemType) },
    { no: 5, name: "create_time", kind: "message", T: Timestamp },
  ],
);

/**
 * An identifier for settings a user can save.
 *
 * @generated from message luminary.proto.library.SettingsSource
 */
export const SettingsSource = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.library.SettingsSource",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scratch", kind: "message", T: SettingsSource_Scratch, oneof: "source" },
    { no: 3, name: "simulation", kind: "message", T: SettingsSource_Simulation, oneof: "source" },
  ],
);

/**
 * Scratch represents the settings in the setup tab/working settings
 *
 * @generated from message luminary.proto.library.SettingsSource.Scratch
 */
export const SettingsSource_Scratch = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.library.SettingsSource.Scratch",
  [],
  {localName: "SettingsSource_Scratch"},
);

/**
 * Simulation represents the settings in an already-run simulation
 *
 * @generated from message luminary.proto.library.SettingsSource.Simulation
 */
export const SettingsSource_Simulation = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.library.SettingsSource.Simulation",
  () => [
    { no: 1, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "SettingsSource_Simulation"},
);

/**
 * @generated from message luminary.proto.library.CreateItemRequest
 */
export const CreateItemRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.library.CreateItemRequest",
  () => [
    { no: 1, name: "settings_source", kind: "message", T: SettingsSource },
    { no: 2, name: "item", kind: "message", T: LibraryItem },
  ],
);

/**
 * @generated from message luminary.proto.library.DeleteItemRequest
 */
export const DeleteItemRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.library.DeleteItemRequest",
  () => [
    { no: 1, name: "item", kind: "message", T: LibraryItem },
  ],
);

/**
 * This type exists for when we have multiple kinds of library.
 * LibraryType library = 1;
 *
 * @generated from message luminary.proto.library.ListItemsRequest
 */
export const ListItemsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.library.ListItemsRequest",
  [],
);

/**
 * @generated from message luminary.proto.library.ListItemsReply
 */
export const ListItemsReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.library.ListItemsReply",
  () => [
    { no: 1, name: "items", kind: "message", T: LibraryItem, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.library.UpdateItemRequest
 */
export const UpdateItemRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.library.UpdateItemRequest",
  () => [
    { no: 1, name: "item", kind: "message", T: LibraryItem },
  ],
);

/**
 * @generated from message luminary.proto.library.ApplyItemRequest
 */
export const ApplyItemRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.library.ApplyItemRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "item", kind: "message", T: LibraryItem },
  ],
);

