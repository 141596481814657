// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const DuplicateIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="duplicate"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M22.5,0
           L7.5,0
           C6.675,0 6,0.675 6,1.5
           L6,4.5
           L9,4.5
           L9,3
           L21,3
           L21,13.5
           L19.5,13.5
           L19.5,16.5
           L22.5,16.5
           C23.325,16.5 24,15.825 24,15
           L24,1.5
           C24,0.675 23.325,0 22.5,0Z
           M16.5,6
           L1.5,6
           C0.675,6 0,6.675 0,7.5
           L0,22.5
           C0,23.325 0.675,24 1.5,24
           L16.5,24
           C17.325,24 18,23.325 18,22.5
           L18,7.5
           C18,6.675 17.325,6 16.5,6Z
           M15,21
           L3,21
           L3,9
           L15,9
           L15,21Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
