// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 120;
const baseHeight = 106;
export const MirroredTriangles = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      height={finalHeight}
      ref={ref}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <rect height="106" style={{ fill: getColor(0) }} width="10" x="55" y="0" />
      <path d="M76,10L120,96L76,96L76,10Z" style={{ fill: getColor(0) }} />
      <path
        d="M44,10L44,96L0,96L44,10ZM34,51.501L16.349,86L34,86L34,51.501Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
