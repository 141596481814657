// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import { RecoilSync } from 'recoil-sync';

import { getSessionStoreId, listenToSessionState, writeSessionState } from '../../lib/recoilSync';
import * as rpc from '../../lib/rpc';
import * as frontendpb from '../../proto/frontend/frontend_pb';

export interface StreamingRpcProps {
  projectId: string;
  children: ReactNode;
  // If set and not empty, now syncEffects will be added to the recoil atoms. This is needed
  // because in the geometry tab we do not want to listen or modify the session state kvstore.
  geometryId?: string;
}

const rpcPool = new rpc.StreamingRpcPool<
  frontendpb.SessionStateRequest,
  frontendpb.SessionStateStreamReply
>('SessionStateStream', rpc.client.sessionStateStream);

// Recoil sync component to sync states with the session state stream. Atoms can subscribe to
// updates using an atomEffect (see persist.syncProjectStateEffect).
const ProjectStateSync = (props: StreamingRpcProps) => (
  <RecoilSync
    // Listens to replies of SessionStateStream and updates atoms that are currently
    // subscribed.
    listen={listenToSessionState(rpcPool, props.projectId)}
    storeKey={getSessionStoreId(props.projectId)}
    // Writes back all states that have changed in the current recoil snapshot
    write={writeSessionState(props.projectId, props.geometryId)}>
    {props.children}
  </RecoilSync>
);

export default ProjectStateSync;
