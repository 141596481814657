// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 25;
const baseHeight = 15;
export const ChevronDownIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="chevronDown"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M22.5,0
           C21.8,0 21.175,0.275 20.725,0.725
           L12.5,8.975
           L4.275,0.725
           C3.825,0.275 3.2,0 2.5,0
           C1.125,0 0,1.125 0,2.5
           C0,3.2 0.275,3.825 0.725,4.275
           L10.725,14.275
           C11.175,14.725 11.8,15 12.5,15
           C13.2,15 13.825,14.725 14.275,14.275
           L24.275,4.275
           C24.725,3.825 25,3.2 25,2.5
           C25,1.125 23.875,0 22.5,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
