// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const PlayIconOutline = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      height={finalHeight}
      ref={ref}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}>
      <path
        clipRule="evenodd"
        d="
            M3.01431 1.41628
            C3.22782 1.29971 3.48795 1.30903 3.69257 1.44058
            L13.0259 7.44058
            C13.2162 7.56294 13.3313 7.77369 13.3313 7.99996
            C13.3313 8.22624 13.2162 8.43699 13.0259 8.55935
            L3.69257 14.5593
            C3.48795 14.6909 3.22782 14.7002 3.01431 14.5836
            C2.80079 14.4671 2.66797 14.2432 2.66797 14
            V1.99996
            C2.66797 1.7567 2.80079 1.53285 3.01431 1.41628ZM3.99797 3.21802
            V12.7819L11.4365 7.99996
            L3.99797 3.21802Z"
        fillRule="evenodd"
        style={{ fill: getColor(0) }}
      />

    </svg>
  );
});
