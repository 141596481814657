// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { toPositiveAbsoluteInteger } from '../../lib/number';

import { NumberInput, NumberInputProps } from './NumberInput';

export const PositiveAbsoluteIntegerInput = (props: NumberInputProps) => {
  const { value, onChange, onCommit } = props;

  return (
    <NumberInput
      {...props}
      onChange={(newValue) => {
        onChange?.(toPositiveAbsoluteInteger(newValue));
      }}
      onCommit={(newValue) => {
        onCommit?.(toPositiveAbsoluteInteger(newValue));
      }}
      value={value === null ? value : toPositiveAbsoluteInteger(value)}
    />
  );
};
