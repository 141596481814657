// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import streamSaver from 'streamsaver';

import mitmUrl from '../assets/static/mitm.html';
// mitm.html depends on sw.js
// We need to import it so webpack includes it in the bundle.
import '../assets/static/sw.js'; // eslint-disable-line import/extensions
import * as simulationpb from '../proto/client/simulation_pb';
import * as filepb from '../proto/file/file_pb';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as fesimulationpb from '../proto/frontend/simulation/simulation_pb';

import assert from './assert';
import { EntityGroupMap } from './entityGroupMap';
import { convertToProto } from './entityGroupUtils';
import * as jwt from './jwt';
import { fetchLink } from './navigation';
import { fromBigInt } from './number';
import * as rpc from './rpc';

// We check in certain artifacts from StreamSaver.js to our repo, due to CORS
// issues with fetching them from the usual location. When upgrading StreamSaver, we need
// to confirm if these self-hosted artifacts are up-to-date or need to be replaced.
// These artifacts are:
//   - assets/static/mitm.html
//   - assets/static/sw.js
assert(
  streamSaver.version.full === '2.0.5',
  'Detected StreamSaver.js upgrade.  Please confirm the validity of self-hosted artifacts.',
);

// Currently nonstandard (Chrome/Edge only), but in the future we can replace
// streamsaver with the file system API:
// https://developer.mozilla.org/en-US/docs/Web/API/File_System_API
//
// const newHandle = await window.showSaveFilePicker();
// const writableStream = await newHandle.createWritable();
// ... write to writable stream, for example using fetch().

// LC-17355 - configure streamSaver to use a custom man-in-the-middle (really just
// the default mitm, hosted by us) to avoid CORS errors.
streamSaver.mitm = mitmUrl;

/**
 * Fetch the provided URL, writing its contents to the provided writer.
 * @param url the url to fetch
 * @param fileWriter the stream writer to which contents of the fetched file will be piped
 * @returns the number of bytes written to the stream.
 */
async function fetchUrlToStream(
  url: string,
  fileWriter: WritableStreamDefaultWriter,
  init: RequestInit,
) {
  const response = await fetch(url, init);
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Download error: http code ${response.status}: ${errorText}`);
  }
  const reader = response.body!.getReader();
  let bytesWritten = 0;
  for (; ;) {
    const { done, value } = await reader.read();
    // Per documentation (https://developer.mozilla.org/en-US/docs/Web/API/ReadableStreamDefaultReader/read)
    // done === true iff value === undefined.
    if (done) {
      return bytesWritten;
    }
    assert(!!value, 'No value was read from url');
    await fileWriter.write(value);
    bytesWritten += value.length;
  }
}

/**
 * Download the requested file.  File name and size are determined from the file metadata,
 * and the contents are fetched either via signed URL or the fetch endpoint.
 * @param file the file to download
 * Requires: file.contents is either a signed URL or a file ID.
 */
export async function downloadFile(
  file: filepb.File,
) {
  let fileWriter: WritableStreamDefaultWriter | null = null;
  try {
    const metadata = file.metadata!;
    const fileSize = fromBigInt(metadata!.size);
    const fileName = `${metadata.name}${metadata.ext}`;

    const fileStream = streamSaver.createWriteStream(fileName, { size: fileSize });
    fileWriter = fileStream.getWriter();
    let fetchUrl = file.contents.case === 'signedUrl' ? file.contents.value : undefined;
    let init: RequestInit = {};
    if (!fetchUrl) {
      // If there was no signed URL, we should have gotten a file ID, which is downloaded
      // via the fetch endpoint using the auth header.
      assert(file.contents.case === 'fileId', 'File contents should have a file ID');
      fetchUrl = fetchLink(file.contents.value);
      init = {
        headers: {
          authorization: `Bearer ${localStorage.getItem(jwt.sessionKey)}`,
        },
      };
    }
    const written = await fetchUrlToStream(fetchUrl, fileWriter, init);
    if (written !== fileSize) {
      throw new Error(`Download error: received ${written} bytes out of ${fileSize}.`);
    }
  } catch (err) {
    if (fileWriter) {
      await fileWriter.abort();
    }
    throw err;
  }
  if (fileWriter) {
    await fileWriter.close();
  }
}

/**
 * Download a surface solution for a completed simulation by solution URL.
 * @param url the surface solution gs:// URL to download
 * @param entityGroupMap the entity group map for this solution (used for sub-file names)
 */
export async function exportSolution(
  url: string,
  entityGroupMap: EntityGroupMap,
): Promise<filepb.File> {
  const req = new frontendpb.ExportSolutionRequest({ url });
  Object.entries(convertToProto(entityGroupMap).groups).forEach(([id, entry]) => {
    req.surfaceName[id] = new simulationpb.SurfaceName({ surfaceName: entry.name });
  });
  const reply = await rpc.callRetry('ExportSolution', rpc.client.exportSolution, req);
  return reply.file!;
}

/**
 * Download the volume solution for a completed simulation by job ID (*not* simulation ID)
 * @param jobId the job whose volume solution will be downloaded
 * @param iteration the iteration to download for a transient simulation, or undefined if this is a
 * steady-state simulation
 */
export async function exportVolumeSolution(
  jobId: string,
  iteration?: number,
): Promise<filepb.File> {
  // first, we have to get the simulation ID from the job ID
  const simIdReq = new fesimulationpb.GetSimulationIdRequest({ jobId });
  const simIdReply = await rpc.callRetry('GetSimulationId', rpc.client.getSimulationId, simIdReq);

  // then do a chunked download of the volume solution
  const req = new fesimulationpb.GetSimulationVolumeSolutionRequest({
    id: simIdReply.simulationId,
  });
  if (iteration) {
    req.timeStep = iteration;
  }
  const reply = await rpc.callRetry(
    'GetSimulationVolumeSolution',
    rpc.client.getSimulationVolumeSolution,
    req,
  );
  return reply.file!;
}
