// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as ProtoDescriptor from '../ProtoDescriptor';
import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../SimulationParamDescriptor';
import * as simulationpb from '../proto/client/simulation_pb';
import { EntityGroupData } from '../recoil/entityGroupState';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';
import { NamesRecord } from '../state/external/project/simulation/param/boundaryNames';

import { newAdFloat } from './adUtils';
import { EntityGroupMap } from './entityGroupMap';
import { getSurfaceGroupSurfaces, unwrapSurfaceIds } from './entityGroupUtils';
import { initParamGroupProto } from './initParam';
import { intersectSet, intersects, subtractArray } from './lang';
import {
  findPhysicsById,
  getAvailableSurfaces,
  getFluid,
  getHeat,
  getPhysicsId,
  getSubPhysics,
  getSurfaceToPhysicsMap,
} from './physicsUtils';
import { newNodeId } from './projectDataUtils';
import { getVolumeIdsFromSurfaces } from './volumeUtils';

// LEGACY!  The undefined name is maintained for upgrading old projects but is no longer actively
// used
// -----------
// The value of simulationpb.BoundaryConditionsFluid.boundary_condition_name for the
// "Undefined" BC that contains all the surfaces that are not part of real
// BCs. It is shown in the top of the "Boundary Conditions" list.
//
// This name must be synced with protoutil/fvmparam.go
export const DEFAULT_BOUNDARY_CONDITION_NAME = ' @UNDEFINED@';

const { FARFIELD, INLET, INVALID_PHYSICAL_BOUNDARY, OVERSET, WALL } = simulationpb.PhysicalBoundary;
const { HEAT_BC_ISOTHERMAL } = simulationpb.HeatPhysicalBoundary;
const { NO_SLIP, WALL_MODEL } = simulationpb.WallMomentum;

export type AnyBoundaryCondition =
  | simulationpb.BoundaryConditionsFluid
  | simulationpb.BoundaryConditionsHeat;

// Return true if the fluid boundary condition has the given boundary type
export function hasFluidPhysicalBoundaryType(
  boundaryCondition: simulationpb.BoundaryConditionsFluid,
  type: simulationpb.PhysicalBoundary,
) {
  return boundaryCondition.physicalBoundary === type;
}

// Return true if the heat boundary condition has the given boundary type
export function hasHeatPhysicalBoundaryType(
  boundaryCondition: simulationpb.BoundaryConditionsHeat,
  type: simulationpb.HeatPhysicalBoundary,
) {
  return boundaryCondition.heatPhysicalBoundary === type;
}

// Return true if the boundary condition is of type FARFIELD
export function isFarfieldBoundaryCondition(
  boundaryCondition: simulationpb.BoundaryConditionsFluid,
) {
  return hasFluidPhysicalBoundaryType(boundaryCondition, FARFIELD);
}

// Return true if the boundary condition is of type INLET
export function isInletBoundaryCondition(boundaryCondition: simulationpb.BoundaryConditionsFluid) {
  return hasFluidPhysicalBoundaryType(boundaryCondition, INLET);
}

// Return true if the boundary condition is of type OVERSET
export function isOversetBoundaryCondition(
  boundaryCondition: simulationpb.BoundaryConditionsFluid,
) {
  return hasFluidPhysicalBoundaryType(boundaryCondition, OVERSET);
}

// Return true if the boundary condition is of type WALL
export function isWallBoundaryCondition(boundaryCondition: simulationpb.BoundaryConditionsFluid) {
  return hasFluidPhysicalBoundaryType(boundaryCondition, WALL);
}

// Return true if the boundary condition is of type HEAT_BC_ISOTHERMAL
export function isIsothermalBoundaryCondition(
  boundaryCondition: simulationpb.BoundaryConditionsHeat,
) {
  return hasHeatPhysicalBoundaryType(boundaryCondition, HEAT_BC_ISOTHERMAL);
}

// Return true if the boundary condition is of type INVALID_PHYSICAL_BOUNDARY
export function isUndefinedBoundaryCondition(
  boundaryCondition: simulationpb.BoundaryConditionsFluid,
) {
  return hasFluidPhysicalBoundaryType(boundaryCondition, INVALID_PHYSICAL_BOUNDARY);
}

// Return true if the boundary condition is dependent on a multiphysics coupling interface
export function isDependentBoundaryCondition(boundaryCondition: AnyBoundaryCondition) {
  return !!boundaryCondition.boundaryConditionInterfaceId;
}

export const boundRegex = /^(\d+\/bound\/)(\d+\/)?(.*)$/;

export function getAssignedSurfaces(
  param: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  const surfaces = new Set<string>();

  const unroll = (id: string) => {
    unwrapSurfaceIds([id], geometryTags, entityGroupData)
      .forEach((surfaceId) => surfaces.add(surfaceId));
  };

  param.physics.forEach((physics) => {
    // Scan fluid physics' boundary conditions
    getFluid(physics)?.boundaryConditionsFluid.forEach((boundaryCondition) => {
      boundaryCondition.surfaces.forEach((surfaceId) => unroll(surfaceId));
    });
    // Scan heat physics' boundary conditions
    getHeat(physics)?.boundaryConditionsHeat.forEach((boundaryCondition) => {
      boundaryCondition.surfaces.forEach((surfaceId) => unroll(surfaceId));
    });

    // Scan periodic pairs and sliding interface from either fluid or heat
    const subPhysics = getSubPhysics(physics);
    subPhysics?.periodicPair.forEach((pair) => {
      pair.boundA.forEach((surfaceId) => unroll(surfaceId));
      pair.boundB.forEach((surfaceId) => unroll(surfaceId));
    });
    subPhysics?.slidingInterfaces.forEach((slidingInterface) => {
      slidingInterface.slidingA.forEach((surfaceId) => unroll(surfaceId));
      slidingInterface.slidingB.forEach((surfaceId) => unroll(surfaceId));
    });
  });

  return [...surfaces];
}

// Return a list of surfaces known to a physics that are not assigned to a boundary condition
export function getUnassignedSurfacesByPhysics(
  param: simulationpb.SimulationParam,
  physicsId: string,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
  entityGroupData: EntityGroupData,
) {
  const surfaces = getAvailableSurfaces(param, physicsId, geometryTags, staticVolumes);
  return subtractArray(surfaces, getAssignedSurfaces(param, geometryTags, entityGroupData));
}

export function fluidPhysicalBoundaryChoices() {
  const {
    choices,
  } = paramDesc[ParamName.PhysicalBoundary] as ProtoDescriptor.MultipleChoiceParam;
  return choices;
}

export function heatPhysicalBoundaryChoices() {
  const {
    choices,
  } = paramDesc[ParamName.HeatPhysicalBoundary] as ProtoDescriptor.MultipleChoiceParam;
  return choices;
}

// Find the fluid boundary condition with the given name (ID).
export function findFluidBoundaryCondition(param: simulationpb.SimulationParam, name: string) {
  return param.physics.reduce(
    (result, physics) => (
      result ||
      getFluid(physics)?.boundaryConditionsFluid.find((item) => item.boundaryConditionName === name)
    ),
    undefined as simulationpb.BoundaryConditionsFluid | undefined,
  );
}

// Check for overset boundary conditions in the simulation parameters. If any of the boundaries has
// an Overset boundary condition defined, return true.
export function hasOverset(param: simulationpb.SimulationParam): boolean {
  return param.physics.some(
    (physics) => getFluid(physics)?.boundaryConditionsFluid.some(
      (item) => item.physicalBoundary === simulationpb.PhysicalBoundary.OVERSET,
    ),
  );
}

// Find and return the physics that contains a boundary condition identified by ID
export function findParentPhysicsByBoundaryConditionId(
  param: simulationpb.SimulationParam,
  id: string,
) {
  return param.physics.find((physics) => (
    getFluid(physics)?.boundaryConditionsFluid.some((item) => item.boundaryConditionName === id) ||
    getHeat(physics)?.boundaryConditionsHeat.some((item) => item.boundaryConditionName === id)
  ));
}

// Find the heat boundary condition with the given name (ID).
export function findHeatBoundaryCondition(param: simulationpb.SimulationParam, name: string) {
  return param.physics.reduce(
    (result, physics) => (
      result ||
      getHeat(physics)?.boundaryConditionsHeat.find((item) => item.boundaryConditionName === name)
    ),
    undefined as simulationpb.BoundaryConditionsHeat | undefined,
  );
}

export function getBoundaryConditionsByPhysics(physics: simulationpb.Physics) {
  return [
    ...getFluid(physics)?.boundaryConditionsFluid || [],
    ...getHeat(physics)?.boundaryConditionsHeat || [],
  ];
}

/**
 * Return periodic pairs for a (heat or fluid) physics
 * @param physics
 * @returns
 */
export function getPeriodicPairsByPhysics(physics: simulationpb.Physics) {
  return physics.params.value?.periodicPair ?? [];
}

// Return all boundary conditions, fluid or heat, from every physics in the param
export function getAllBoundaryConditions(param: simulationpb.SimulationParam) {
  return param.physics.reduce((result, physics) => {
    result.push(...getBoundaryConditionsByPhysics(physics));
    return result;
  }, [] as (AnyBoundaryCondition)[]);
}

/**
 * Return all periodic pairs from every physics in the param
 * @param param
 * @returns
 */
export function getAllPeriodicPairs(param: simulationpb.SimulationParam) {
  return param.physics.reduce((result, physics) => {
    result.push(...getPeriodicPairsByPhysics(physics));
    return result;
  }, [] as simulationpb.PeriodicPair[]);
}

// Removes a prefix to give the boundary conditions a more readable name.
export function removeBoundsPrefix(name: string): string {
  if (boundRegex.test(name)) {
    return RegExp.$3;
  }
  return name;
}

// Create a new empty fluid boundary condition
export function createFluidBoundaryCondition(boundaryType: simulationpb.PhysicalBoundary) {
  const boundaryCondition = initParamGroupProto(
    new simulationpb.BoundaryConditionsFluid(),
    paramGroupDesc[ParamGroupName.BoundaryConditionsFluid],
  );
  boundaryCondition.boundaryConditionName = newNodeId();
  boundaryCondition.physicalBoundary = boundaryType;
  return boundaryCondition;
}

// Create a new empty heat boundary condition
export function createHeatBoundaryCondition(boundaryType: simulationpb.HeatPhysicalBoundary) {
  const boundaryCondition = initParamGroupProto(
    new simulationpb.BoundaryConditionsHeat(),
    paramGroupDesc[ParamGroupName.BoundaryConditionsHeat],
  );
  boundaryCondition.boundaryConditionName = newNodeId();
  boundaryCondition.heatPhysicalBoundary = boundaryType;
  return boundaryCondition;
}

// Create a new empty boundary condition and append to a fluid physics
export function appendFluidBoundaryCondition(
  param: simulationpb.SimulationParam,
  fluidPhysicsId: string,
  boundaryType: simulationpb.PhysicalBoundary,
) {
  const physics = findPhysicsById(param, fluidPhysicsId);
  const fluid = physics ? getFluid(physics) : null;

  if (!physics || !fluid) {
    throw Error('Invalid fluid physics ID');
  }

  const boundaryCondition = createFluidBoundaryCondition(boundaryType);
  fluid.boundaryConditionsFluid.push(boundaryCondition);
  return boundaryCondition;
}

// Create a new empty boundary condition and append to a heat physics
export function appendHeatBoundaryCondition(
  param: simulationpb.SimulationParam,
  heatPhysicsId: string,
  boundaryType: simulationpb.HeatPhysicalBoundary,
) {
  const physics = findPhysicsById(param, heatPhysicsId);
  const heat = physics ? getHeat(physics) : null;

  if (!physics || !heat) {
    throw Error('Invalid heat physics ID');
  }

  const boundaryCondition = createHeatBoundaryCondition(boundaryType);
  heat.boundaryConditionsHeat.push(boundaryCondition);
  return boundaryCondition;
}

// Find and return the fluid boundary condition that's dependent on a given multiphysics interface
// (identified by ID), creating one if not found
export function getOrCreateDependentFluidBoundaryCondition(
  physics: simulationpb.Physics,
  multiphysicsInterfaceId: string,
) {
  if (!multiphysicsInterfaceId) {
    throw Error('Missing multiphysics interface ID');
  }
  let bc = getFluid(physics)?.boundaryConditionsFluid.find(
    (item) => item.boundaryConditionInterfaceId === multiphysicsInterfaceId,
  );

  if (!bc) {
    bc = createFluidBoundaryCondition(WALL);
    bc.wallEnergy = simulationpb.WallEnergy.FIXED_TEMPERATURE;
    bc.boundaryConditionInterfaceId = multiphysicsInterfaceId;
    getFluid(physics)?.boundaryConditionsFluid.push(bc);
  }
  return bc;
}

// Find and return the heat boundary condition that's dependent on a given multiphysics interface
// (identified by ID), creating one if not found
export function getOrCreateDependentHeatBoundaryCondition(
  physics: simulationpb.Physics,
  multiphysicsInterfaceId: string,
) {
  if (!multiphysicsInterfaceId) {
    throw Error('Missing multiphysics interface ID');
  }
  let bc = getHeat(physics)?.boundaryConditionsHeat.find(
    (item) => item.boundaryConditionInterfaceId === multiphysicsInterfaceId,
  );
  if (!bc) {
    bc = createHeatBoundaryCondition(simulationpb.HeatPhysicalBoundary.HEAT_BC_ISOTHERMAL);
    bc.boundaryConditionInterfaceId = multiphysicsInterfaceId;
    getHeat(physics)?.boundaryConditionsHeat.push(bc);
  }
  return bc;
}

// Rename a boundary condition (identified by ID), returning true if a rename took place
export function renameBoundaryCondition(
  param: simulationpb.SimulationParam,
  id: string,
  newName: string,
): boolean {
  return param.physics.some(
    (physics) => (
      getFluid(physics)?.boundaryConditionsFluid.some((boundaryCondition) => {
        if (boundaryCondition.boundaryConditionName === id) {
          boundaryCondition.boundaryConditionDisplayName = newName;
          return true;
        }
        return false;
      }) ||
      getHeat(physics)?.boundaryConditionsHeat.some((boundaryCondition) => {
        if (boundaryCondition.boundaryConditionName === id) {
          boundaryCondition.boundaryConditionDisplayName = newName;
          return true;
        }
        return false;
      })
    ),
  );
}

// Remove a boundary condition (identified by ID) from a simulation param, returning true of an
// object was successfully removed
export function removeBoundaryCondition(
  param: simulationpb.SimulationParam,
  id: string,
): boolean {
  return param.physics.some((physics) => {
    const fluid = getFluid(physics);
    const heat = getHeat(physics);
    if (fluid) {
      const oldBcs = fluid.boundaryConditionsFluid;
      const newBcs = oldBcs.filter((item) => item.boundaryConditionName !== id);
      if (newBcs.length < oldBcs.length) {
        fluid.boundaryConditionsFluid = newBcs;
        return true;
      }
    }
    if (heat) {
      const oldBcs = heat.boundaryConditionsHeat;
      const newBcs = oldBcs.filter((item) => item.boundaryConditionName !== id);
      if (newBcs.length < oldBcs.length) {
        heat.boundaryConditionsHeat = newBcs;
        return true;
      }
    }
    return false;
  });
}

// A list of the surfaces that could be part of a periodic pair. The surface must be the only
// surface in the group and not already part of another boundary condition, periodic pair, or
// sliding interface.
export function potentialPeriodicSurfaces(
  param: simulationpb.SimulationParam,
  physicsId: string,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
  entityGroupData: EntityGroupData,
): string[] {
  const availableSurfaces = getUnassignedSurfacesByPhysics(
    param,
    physicsId,
    geometryTags,
    staticVolumes,
    entityGroupData,
  );
  return availableSurfaces.filter((id) => {
    if (!entityGroupData.groupMap.has(id)) {
      return false;
    }
    const group = entityGroupData.groupMap.get(id);
    return !group.children.size;
  });
}

// Returns the farfield boundary condition if it exists.
export function findFarfield(param: simulationpb.SimulationParam) {
  return param.physics.reduce((result, physics) => (
    result || getFluid(physics)?.boundaryConditionsFluid.find(isFarfieldBoundaryCondition)
  ), undefined as simulationpb.BoundaryConditionsFluid | undefined);
}

// Returns true if there are more than one far field boundary condition
export function hasMultipleFarfields(param: simulationpb.SimulationParam) {
  const farfieldBcs = param.physics.flatMap((physics) => (
    (getFluid(physics)?.boundaryConditionsFluid ?? []).filter(isFarfieldBoundaryCondition)
  ));
  return farfieldBcs.length > 1;
}

// Check if the named surface is of the specified boundary condition type
export function isSurfaceBoundaryConditionType(
  param: simulationpb.SimulationParam,
  surfaceName: string,
  physicalBoundary: simulationpb.PhysicalBoundary,
): boolean {
  return param.physics.some((physics) => (
    getFluid(physics)?.boundaryConditionsFluid.some((bc) => (
      hasFluidPhysicalBoundaryType(bc, physicalBoundary) &&
      bc.surfaces.includes(surfaceName)
    ))
  ));
}

// Returns true if the surface belongs to a viscous wall boundary condition. Viscous walls are
// no-slip or wall model by definition. Boundary conditions are defined by the input param arg.
export function isSurfaceFromViscousWallBC(
  param: simulationpb.SimulationParam,
  surfaceName: string,
): boolean {
  return param.physics.some((physics) => (
    getFluid(physics)?.boundaryConditionsFluid.some((bc) => (
      isWallBoundaryCondition(bc) &&
      (bc.wallMomentum === NO_SLIP || bc.wallMomentum === WALL_MODEL) &&
      bc.surfaces.includes(surfaceName)
    ))
  ));
}

// Find all surfaces with a wall boundary condition
export function getWallSurfaces(param: simulationpb.SimulationParam) {
  return param.physics.reduce((result, physics) => {
    getFluid(physics)?.boundaryConditionsFluid.forEach((boundaryCondition) => {
      if (isWallBoundaryCondition(boundaryCondition)) {
        result.push(...boundaryCondition.surfaces);
      }
    });
    return result;
  }, [] as string[]);
}

// Find all wall surfaces unrolling the geometry tags if necessary.
export function getWallSurfacesWithTags(
  param: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
) {
  return param.physics.reduce((result, physics) => {
    getFluid(physics)?.boundaryConditionsFluid.forEach((boundaryCondition) => {
      if (isWallBoundaryCondition(boundaryCondition)) {
        boundaryCondition.surfaces.forEach((surface) => {
          if (geometryTags.isTagId(surface)) {
            geometryTags.surfacesFromTagEntityGroupId(surface)!.forEach((tagSurface) => {
              result.push(tagSurface);
            });
            return;
          }
          result.push(surface);
        });
      }
    });
    return result;
  }, [] as string[]);
}

// Find all surfaces with a non-slip wall boundary condition
export function getNonSlipWallSurfaces(param: simulationpb.SimulationParam) {
  const nonSlipMomentums = [NO_SLIP, WALL_MODEL];

  return param.physics.reduce((result, physics) => {
    getFluid(physics)?.boundaryConditionsFluid.forEach((boundaryCondition) => {
      if (
        isWallBoundaryCondition(boundaryCondition) &&
        nonSlipMomentums.includes(boundaryCondition.wallMomentum)
      ) {
        result.push(...boundaryCondition.surfaces);
      }
    });
    return result;
  }, [] as string[]);
}

// Return true if the boundary condition can have roughness properties
export function isBoundaryConditionCompatibleWithRoughness(
  boundaryCondition: simulationpb.BoundaryConditionsFluid,
) {
  if (isWallBoundaryCondition(boundaryCondition)) {
    const momentum = boundaryCondition.wallMomentum;
    return (momentum === NO_SLIP) || (momentum === WALL_MODEL);
  }
  return false;
}

// Update the roughness value property for a boundary condition
export function changeRoughnessValue(
  condition: simulationpb.BoundaryConditionsFluid,
  value: number,
) {
  condition.equivalentSandGrainRoughness = newAdFloat(value);
}

// Update the roughness control property for a boundary condition.  If it's off, then also reset
// the roughness value to 0.
export function changeRoughnessControl(
  condition: simulationpb.BoundaryConditionsFluid,
  on: boolean,
) {
  condition.roughnessControl = on;
  if (!on) {
    changeRoughnessValue(condition, 0);
  }
}

export function periodicPairId(physicsId: string, pairIndex: number) {
  return `periodic-bound-pair-${physicsId}-${pairIndex}`;
}

// Returns true if a periodic pair exists that includes the surface from the given surface group ID
export function hasPeriodicPair(
  param: simulationpb.SimulationParam,
  groupId: string,
  entityGroupMap: EntityGroupMap,
): boolean {
  // Periodic pairs currently only work for individual surfaces without children.  If the ID is for
  // a surface group, we know we can skip and return false.
  if (entityGroupMap.has(groupId) && entityGroupMap.get(groupId).children.size) {
    return false;
  }

  return param.physics.some((physics) => (
    getSubPhysics(physics)?.periodicPair.some((pair) => (
      pair.boundA.includes(groupId) || pair.boundB.includes(groupId)
    ))
  ));
}

// Rename the periodic pair
export function renamePeriodicBound(
  param: simulationpb.SimulationParam,
  id: string,
  name: string,
): boolean {
  return param.physics.some((physics) => {
    const physicsId = getPhysicsId(physics);
    return getSubPhysics(physics)?.periodicPair.some((pair, i) => {
      if (periodicPairId(physicsId, i) === id) {
        pair.periodicPairName = name;
        return true;
      }
      return false;
    });
  });
}

// Remove the periodic pair from the simulation parameters
export function removePeriodicBound(param: simulationpb.SimulationParam, id: string): boolean {
  return param.physics.some((physics) => {
    const subPhysics = getSubPhysics(physics);
    if (subPhysics) {
      const physicsId = getPhysicsId(physics);
      const oldPairs = subPhysics.periodicPair;
      const newPairs = oldPairs.filter((pair, i) => periodicPairId(physicsId, i) !== id);
      if (newPairs.length < oldPairs.length) {
        subPhysics.periodicPair = newPairs;
        return true;
      }
    }
    return false;
  });
}

export function findPeriodicPairIdBySurface(
  param: simulationpb.SimulationParam,
  surfaceId: string,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  return param.physics.reduce((result, physics) => {
    const physicsId = getPhysicsId(physics);
    const surfaces = new Set(getAvailableSurfaces(param, physicsId, geometryTags, staticVolumes));
    if (surfaces.has(surfaceId)) {
      const pairs = getSubPhysics(physics)?.periodicPair || [];
      const pairIndex = pairs.findIndex((item, i) => (
        item.boundA.includes(surfaceId) || item.boundB.includes(surfaceId)
      ));
      if (pairIndex >= 0) {
        return periodicPairId(physicsId, pairIndex);
      }
    }
    return result;
  }, undefined as string | undefined);
}

export function getPeriodicPairsForSurfaces(
  param: simulationpb.SimulationParam,
  surfaceIds: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  return param.physics.reduce((result, physics) => {
    const physicsId = getPhysicsId(physics);

    // Constrain our search to those surfaces available to the physics, just in case a periodic pair
    // incorrectly references a surface outside the physics
    const surfacePool = getAvailableSurfaces(param, physicsId, geometryTags, staticVolumes);
    const surfacesToCheck = intersectSet(surfacePool, surfaceIds);

    if (surfacesToCheck.size) {
      const pairs = getSubPhysics(physics)?.periodicPair || [];
      const pairIndex = pairs.findIndex((item, i) => (
        intersects(item.boundA, surfacesToCheck) ||
        intersects(item.boundB, surfacesToCheck)
      ));
      if (pairIndex >= 0) {
        result.push(periodicPairId(physicsId, pairIndex));
      }
    }
    return result;
  }, [] as string[]);
}

// Find and return the physics that contains a periodic pair identified by ID
export function findParentPhysicsByPeriodicPairId(param: simulationpb.SimulationParam, id: string) {
  return param.physics.find((physics) => {
    const physicsId = getPhysicsId(physics);
    return getSubPhysics(physics)?.periodicPair.some(
      (item, i) => periodicPairId(physicsId, i) === id,
    );
  });
}

// Get the periodic pair that has the given ID from the param
export function findPeriodicPairById(param: simulationpb.SimulationParam, nodeId: string) {
  return param.physics.reduce((result, physics) => {
    const physicsId = getPhysicsId(physics);
    return result || getSubPhysics(physics)?.periodicPair.find(
      (item, i) => periodicPairId(physicsId, i) === nodeId,
    );
  }, undefined as simulationpb.PeriodicPair | undefined);
}

// Add new `surfaces` to the periodic pair with the given ID in the param
// Returns an array of error strings
export function updatePeriodicPair(
  param: simulationpb.SimulationParam,
  nodeId: string,
  selectedSurfaces: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
  entityGroupData: EntityGroupData,
  sideA: boolean,
  dryRun: boolean,
) {
  const periodicPair = findPeriodicPairById(param, nodeId);
  if (!periodicPair) {
    return ['Periodic pair not found'];
  }
  const unrolledPreviousSurfaces = geometryTags.unrollFaceTags(
    sideA ? periodicPair.boundA : periodicPair.boundB,
  ).flatMap((identifier) => {
    const isGroup = entityGroupData.groupMap.has(identifier) &&
      entityGroupData.groupMap.get(identifier).children.size > 0;

    if (isGroup) {
      return [...getSurfaceGroupSurfaces(nodeId, entityGroupData)];
    }

    return [identifier];
  });
  const previousSet = new Set(unrolledPreviousSurfaces);

  const physics = findParentPhysicsByPeriodicPairId(param, nodeId);
  if (!physics) {
    // Should never happen
    return ['Periodic pair does not belong to a physics'];
  }

  const physicsId = getPhysicsId(physics);
  const unassignedSurfaces = getUnassignedSurfacesByPhysics(
    param,
    physicsId,
    geometryTags,
    staticVolumes,
    entityGroupData,
  );

  const surfacesToPhysics = getSurfaceToPhysicsMap(param, geometryTags, staticVolumes);

  // check to see if new surfaces are already assigned to something
  const newSurfaces = geometryTags
    .unrollFaceTags(selectedSurfaces)
    .flatMap((identifier) => {
      const isGroup = entityGroupData.groupMap.has(identifier) &&
        entityGroupData.groupMap.get(identifier).children.size > 0;

      if (isGroup) {
        return [...getSurfaceGroupSurfaces(identifier, entityGroupData)];
      }

      return [identifier];
    })
    .filter((surface) => !previousSet.has(surface));

  const errors: string[] = [];

  newSurfaces.forEach((surface) => {
    if (!unassignedSurfaces.includes(surface)) {
      const surfaceName =
        entityGroupData.groupMap.has(surface) ?
          entityGroupData.groupMap.get(surface).name :
          removeBoundsPrefix(surface);

      // If this new surface is in the unassigned list, then it's either not visible to the physics
      // (via the latter's volume selection) or it's already assigned to another BC.
      if (surfacesToPhysics[surface] !== physicsId) {
        errors.push(`Physics volume selections do not contain surface ${surfaceName}.`);
      } else {
        errors.push(`${surfaceName} is already defined.`);
      }
    }
  });
  // check to see if the surfaces are on the same volume
  const volumes = getVolumeIdsFromSurfaces(
    [...periodicPair.boundA, ...periodicPair.boundB, ...newSurfaces],
    staticVolumes,
  );
  if (volumes.length > 1) {
    errors.push('All surfaces in the periodic pair must belong to the same volume');
  }
  if (errors.length) {
    return errors;
  }

  if (dryRun) {
    return [];
  }

  if (sideA) {
    periodicPair.boundA = selectedSurfaces;
  } else {
    periodicPair.boundB = selectedSurfaces;
  }

  return [];
}

// Return a tooltip to show over the boundary condition ADD button if it should be disabled
export function getDisabledBoundaryConditionReason(
  availableSurfaceCount: number,
  assignedVolumeCount: number,
) {
  if (availableSurfaceCount) {
    // If there are available surfaces, return nothing (don't disable the button)
    return '';
  }
  if (!assignedVolumeCount) {
    // If the physics doesn't have any assigned volumes yet, then communicate this to the user
    return 'Make volume selections for this physics to enable boundary condition addition';
  }
  // Otherwise, if the physics has volumes but no available surfaces, then the surfaces have all
  // been assigned to boundary conditions already.
  return 'All available surfaces have already been assigned to boundary conditions';
}

/**
 * Given a surface, find the boundary condition, if it exists, to which the surface has been
 * assigned
 * @param simParam
 * @param surfaceId
 * @returns
 */
export function findBoundaryConditionBySurfaceId(
  simParam: simulationpb.SimulationParam,
  surfaceId: string,
) {
  return getAllBoundaryConditions(simParam).find(({ surfaces }) => surfaces.includes(surfaceId));
}

export function getBoundaryConditionNames(simParam: simulationpb.SimulationParam) {
  // A mapping of boundary condition ID to name
  const names: NamesRecord = {};

  const fluidTypeParam = (
    paramDesc[ParamName.PhysicalBoundary] as ProtoDescriptor.MultipleChoiceParam
  );
  const heatTypeParam = (
    paramDesc[ParamName.HeatPhysicalBoundary] as ProtoDescriptor.MultipleChoiceParam
  );

  // Collect names for each boundary type
  const fluidTypeNames = fluidTypeParam.choices.reduce((result, choice) => ({
    ...result,
    [choice.enumNumber]: choice.text,
  }), {} as Record<number, string>);
  const heatTypeNames = heatTypeParam.choices.reduce((result, choice) => ({
    ...result,
    [choice.enumNumber]: choice.text,
  }), {} as Record<number, string>);

  simParam.physics.forEach((physics) => {
    // Track counts by type
    const fluidTypeCounts: Map<simulationpb.PhysicalBoundary, number> = new Map();
    const heatTypeCounts: Map<simulationpb.HeatPhysicalBoundary, number> = new Map();

    const fluid = getFluid(physics);
    const heat = getHeat(physics);

    let dependentCt = 1;
    fluid?.boundaryConditionsFluid.forEach((bc, i) => {
      const type = bc.physicalBoundary;
      const bcId = bc.boundaryConditionName;
      const name = bc.boundaryConditionDisplayName;

      if (isDependentBoundaryCondition(bc)) {
        names[bcId] = `Multiphysics Interface ${dependentCt}`;
        dependentCt += 1;
      } else {
        if (name) {
          // Display name always takes precedence
          names[bcId] = name;
        } else {
          // If a display name isn't set, use the type + count
          names[bcId] = `${fluidTypeNames[type]} ${(fluidTypeCounts.get(type) ?? 0) + 1}`;
        }
        fluidTypeCounts.set(type, (fluidTypeCounts.get(type) ?? 0) + 1);
      }
    });

    heat?.boundaryConditionsHeat.forEach((bc, i) => {
      const type = bc.heatPhysicalBoundary;
      const bcId = bc.boundaryConditionName;
      const name = bc.boundaryConditionDisplayName;

      if (isDependentBoundaryCondition(bc)) {
        names[bcId] = `Multiphysics Interface ${dependentCt}`;
        dependentCt += 1;
      } else {
        if (name) {
          names[bcId] = name;
        } else {
          names[bcId] = `${heatTypeNames[type]} ${(heatTypeCounts.get(type) ?? 0) + 1}`;
        }
        heatTypeCounts.set(type, (heatTypeCounts.get(type) ?? 0) + 1);
      }
    });
  });

  return names;
}
