// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/file/file.proto (package luminary.proto.file, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Checksum } from "../base/base_pb.js";

/**
 * File formats for mesh/solution files.
 *
 * @generated from enum luminary.proto.file.MeshFileFormat
 */
export const MeshFileFormat = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.file.MeshFileFormat",
  [
    {no: 0, name: "INVALID"},
    {no: 1, name: "LCMESH"},
    {no: 2, name: "VTK"},
  ],
);

/**
 * FileSetManifest describes a set of files uploaded by a user as one unit.  It
 * is used to treat a mesh definition consisting of multiple files as one
 * logical file. As of 2021-04, OpenFOAM mesh
 * (https://cfd.direct/openfoam/user-guide/v6-basic-file-format/) uses this
 * format.
 *
 *
 * Example layout:
 *    rooturl/.lcfilesetmanifest
 *    rooturl/file0
 *    rooturl/dir0%2Ffile1
 *    rooturl/dir0%2Ffile2
 *
 * - rooturl is an arbitrary URL prefix. See go/core/jobmaster/filesetUploader
 *   for a real example of rooturl generation.
 *
 * - File .lcfilesetmanifest stores a binary-serialized FileSetManifest.
 * - The rest of the directory store a flat list of user-provided files.
 *   The filenames are URL-encoded pathnames.
 *
 * - In the above example, the contents of .lcfilesetmanifest will be:
 *
 *   entry: {
 *     rel_path: "file0"
 *     size: .. size of file0 ..
 *     checksum: .. SHA256 checksum of file0 ..
 *     encoded_rel_path: "file0"
 *   }
 *   entry: {
 *     rel_path: "dir0/file1"
 *     size: .. size of dir0/file1 ..
 *     checksum: .. SHA256 checksum of dir0/file1 ..
 *     encoded_rel_path: "dir0%2Ffile1"
 *   }
 *   entry: {
 *     rel_path: "dir0/file2"
 *     size: .. size of dir0/file2 ..
 *     checksum: .. SHA256 checksum of dir0/file2 ..
 *     encoded_rel_path: "dir0%2Ffile2"
 *   }
 *
 * @generated from message luminary.proto.file.FileSetManifest
 */
export const FileSetManifest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.file.FileSetManifest",
  () => [
    { no: 1, name: "entry", kind: "message", T: FileSetEntry, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.file.FileSetEntry
 */
export const FileSetEntry = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.file.FileSetEntry",
  () => [
    { no: 1, name: "rel_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "size", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "checksum", kind: "message", T: Checksum },
    { no: 4, name: "encoded_rel_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.file.FileMetadata
 */
export const FileMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.file.FileMetadata",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ext", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "size", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "sha256_checksum", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 5, name: "crc32c_checksum", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.file.ByteChunk
 */
export const ByteChunk = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.file.ByteChunk",
  () => [
    { no: 1, name: "start_offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "next_offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "last_chunk", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.file.File
 */
export const File = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.file.File",
  () => [
    { no: 1, name: "metadata", kind: "message", T: FileMetadata },
    { no: 2, name: "full_contents", kind: "scalar", T: 12 /* ScalarType.BYTES */, oneof: "contents" },
    { no: 4, name: "signed_url", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "contents" },
    { no: 6, name: "file_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "contents" },
  ],
);

