// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import assert from '../../../../lib/assert';
import { cadIdsToVolumeNodeIds, volumeNodeIdsToCadIds } from '../../../../lib/geometryUtils';
import { usePreviewAnnotation } from '../../../../lib/lcvis/api';
import * as random from '../../../../lib/random';
import * as rpc from '../../../../lib/rpc';
import { NodeType } from '../../../../lib/simulationTree/node';
import { defaultNodeFilter, mapVisualizerEntitiesToVolumes } from '../../../../lib/subselectUtils';
import { addRpcError } from '../../../../lib/transientNotification';
import * as geometryservicepb from '../../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as basepb from '../../../../proto/base/base_pb';
import * as transfpb from '../../../../proto/cad/transformation_pb';
import { Reflection, Rotation, Scaling, Translation } from '../../../../proto/cad/transformation_pb';
import * as geometrypb from '../../../../proto/geometry/geometry_pb';
import { QuantityType } from '../../../../proto/quantity/quantity_pb';
import {
  DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE,
  createOrUpdateFeature,
  useGeometrySelectedFeature,
  useGeometryState,
  useSetGeometryState,
} from '../../../../recoil/geometry/geometryState';
import { useCadMetadata } from '../../../../recoil/useCadMetadata';
import { useStaticVolumes } from '../../../../recoil/volumes';
import Form from '../../../Form';
import LabeledInput from '../../../Form/LabeledInput';
import { NumberInput } from '../../../Form/NumberInput';
import { Vector3Input } from '../../../Form/Vector3Input';
import QuantityAdornment from '../../../QuantityAdornment';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { ScaleButtons } from '../../../controls/ScaleButtons';
import { UnitVectorButtons } from '../../../controls/UnitVectorButtons';
import { useLcvCoordinateVisualizerGeo } from '../../../hooks/useLcvCoordinateVisualizer';
import { NodeSubselect } from '../../NodeSubselect';

import GeometryModificationPanelFooter from './GeometryModificationPanelFooter';
import { EditModificationMessage } from './GeometryModificationShared';

// Based on geometrypb.Transform.t.cases
export enum TransfType {
  MATRIX = 'matrix',
  TRANSLATION = 'translation',
  ROTATION = 'rotation',
  SCALING = 'scaling',
  MIRROR = 'reflection',
}

export const defaultTransf = (type: TransfType) => {
  const ret = new geometrypb.Transform({ t: { case: type, value: {} }, keep: false });

  switch (type) {
    case TransfType.MATRIX:
      ret.t.value = new transfpb.AugmentedMatrix({
        affine: { a: { x: 1 }, b: { y: 1 }, c: { z: 1 } }, translation: {},
      });
      break;

    case TransfType.TRANSLATION:
      ret.t.value = new transfpb.Translation({ displacement: { case: 'vector', value: {} } });
      break;

    case TransfType.ROTATION:
      // Maybe there's a better default for the axis of rotation
      ret.t.value = new transfpb.Rotation({
        axis: { case: 'arbitrary', value: { origin: {}, direction: { z: 1 } } },
      });
      break;

    case TransfType.SCALING:
      ret.t.value = new transfpb.Scaling({
        origin: { case: 'arbitrary', value: {} }, factor: { case: 'isotropic', value: 1 },
      });
      break;

    case TransfType.MIRROR:
      // This is the most common left-right orientation in aero/auto design
      ret.t.value = new transfpb.Reflection({
        plane: { case: 'arbitrary', value: { origin: {}, direction: { y: 1 } } },
      });
      ret.keep = true; // different behavior by design
      break;

    default:
      break;
  }
  return ret;
};

// Handles the transformation of bodies.
export const GeometryModificationTransformPropPanel = () => {
  // == Contexts
  const { projectId, geometryId, readOnly } = useProjectContext();
  const { selectedNode: node, setSelection } = useSelectionContext();
  assert(!!node, 'No selected geometry modification transform row');

  // == Recoil
  const geometryState = useGeometryState(projectId, geometryId);
  const setGeometryState = useSetGeometryState(projectId, geometryId);
  const [cadMetadata] = useCadMetadata(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const [, setSelectedFeature] = useGeometrySelectedFeature(geometryId);

  // == Hooks
  const propClasses = useCommonTreePropsStyles();

  // == Derived data
  const mod = useMemo(
    () => geometryState?.geometryFeatures.find((feature) => feature.id === node.id),
    [geometryState, node.id],
  );
  assert(!!mod, 'No selected geometry modification transform');
  const [transf, setTransf] = useState(mod.operation.value as geometrypb.Transform);
  assert(!!transf, 'Missing geometry transform');
  const initialBodies = useMemo(
    () => cadIdsToVolumeNodeIds(transf.body, staticVolumes, cadMetadata),
    [transf.body, staticVolumes, cadMetadata],
  );
  const isAcknowledgedFeature = useMemo(
    () => geometryState?.ackModifications.has(node.id),
    [geometryState, node.id],
  );

  // == State
  const [selectedBodiesNodeIds, setSelectedBodiesNodeIds] = useState<string[]>(initialBodies);

  const annotations = useLcvCoordinateVisualizerGeo(transf.t.value, node.id);
  useEffect(() => {
    annotations.show();
    return () => {
      annotations.clear();
    };
  }, [transf, annotations]);

  const surfaceIds = useMemo(() => selectedBodiesNodeIds.flatMap((id) => {
    const volume = staticVolumes.find((vol) => vol.id === id);
    return [...volume?.bounds ?? []];
  }), [selectedBodiesNodeIds, staticVolumes]);
  const [showPreview, setShowPreview] = usePreviewAnnotation(
    transf,
    // Show the preview by default if the feature is not acknowledged, otherwise, the user
    // has to explicitly enable it. This is because the tessellation isn't always ready in time when
    // we restore a feature from the server so we don't want to show a broken preview.
    !isAcknowledgedFeature,
    surfaceIds,
  );

  const onSetTransform = useCallback((transform: geometrypb.Transform) => {
    setTransf(transform);
    setGeometryState((oldGeometryState) => {
      if (oldGeometryState === undefined) {
        return oldGeometryState;
      }
      const newGeometryState = { ...oldGeometryState };
      newGeometryState.geometryFeatures.forEach((feature) => {
        if (feature.id !== mod.id) {
          return;
        }
        feature.operation.value = transform;
      });
      return newGeometryState;
    });
  }, [mod.id, setGeometryState]);

  const multiplyScaleFactor = useCallback((factor: number) => {
    const newTransf = transf.clone();
    const scaleFactor = (newTransf.t.value as Scaling).factor.value || 1;
    (newTransf.t.value as Scaling).factor.value = scaleFactor * factor;
    onSetTransform(newTransf);
  }, [transf, onSetTransform]);

  const nodeFilter = useCallback((nodeType: NodeType, nodeId: string) => {
    if (nodeType === NodeType.VOLUME) {
      return {
        related: true,
        disabled: selectedBodiesNodeIds.includes(nodeId),
      };
    }
    return defaultNodeFilter(nodeType);
  }, [selectedBodiesNodeIds]);

  // Generates a checkbox to keep the original volumes and a checkbox to toggle
  // the transformation preview.
  const checkboxes = (
    <>
      <div style={{ margin: '14px 0 18px' }}>
        <Form.MultiCheckBox checkBoxProps={[{
          checked: transf.keep,
          help: 'Copy the volumes before performing the transformation operation.',
          optionText: 'Copy Volumes',
          onChange: (checked) => {
            const newTransf = transf.clone();
            newTransf.keep = checked;
            onSetTransform(newTransf);
          },
        }]}
        />
      </div>
      <div style={{ margin: '14px 0 18px' }}>
        <Form.MultiCheckBox checkBoxProps={[{
          checked: showPreview,
          help: 'Show a preview of the pattern in the visualizer',
          optionText: 'Show Preview',
          onChange: (checked) => {
            setShowPreview(checked);
          },
        }]}
        />
      </div>
    </>
  );

  let parameterPanel: ReactNode;
  switch (transf.t.case) {
    case TransfType.TRANSLATION: {
      const vector = transf.t.value.displacement;
      assert(vector.case === 'vector', 'Vector case is not arbitrary');
      parameterPanel = (
        <>
          <LabeledInput
            help="Vector along which bodies are translated"
            label="Distance">
            <Vector3Input
              disabled={false}
              onCommit={(value) => {
                const newTransf = transf.clone();
                (newTransf.t.value as Translation).displacement = {
                  case: 'vector',
                  value,
                };
                onSetTransform(newTransf);
              }}
              value={vector.value!}
            />
          </LabeledInput>
          {checkboxes}
        </>
      );
      break;
    }
    case TransfType.ROTATION: {
      const angle = transf.t.value.angle;
      const axis = transf.t.value.axis;
      assert(axis.case === 'arbitrary', 'Axis case is not arbitrary');
      parameterPanel = (
        <>
          <LabeledInput
            help="Point on the axis of rotation"
            label="Axis Origin">
            <Vector3Input
              disabled={false}
              onCommit={(value) => {
                const newTransf = transf.clone();
                (newTransf.t.value as Rotation).axis.value!.origin = value;
                onSetTransform(newTransf);
              }}
              value={axis.value.origin!}
            />
          </LabeledInput>
          <LabeledInput
            help="Direction of the axis of rotation"
            label="Axis Direction">
            <Vector3Input
              disabled={false}
              onCommit={(value) => {
                const newTransf = transf.clone();
                (newTransf.t.value as Rotation).axis.value!.direction = value;
                onSetTransform(newTransf);
              }}
              value={axis.value.direction!}
            />
          </LabeledInput>
          <Form.LabeledInput label="">
            {readOnly ? null : (
              <Form.Group>
                <UnitVectorButtons
                  onClick={(x, y, z) => {
                    const newTransf = transf.clone();
                    const vector = new basepb.Vector3({ x, y, z });
                    (newTransf.t.value as Rotation).axis.value!.direction = vector;
                    onSetTransform(newTransf);
                  }}
                />
              </Form.Group>
            )}
          </Form.LabeledInput>
          <Form.LabeledInput
            help="Angle of rotation in degrees"
            label="Rotation Angle">
            <NumberInput
              asBlock
              disabled={false}
              endAdornment={<QuantityAdornment quantity={QuantityType.DEGREE} />}
              onCommit={(a) => {
                const newTransf = transf.clone();
                (newTransf.t.value as Rotation).angle = a;
                onSetTransform(newTransf);
              }}
              size="small"
              value={angle}
            />
          </Form.LabeledInput>
          {checkboxes}
        </>
      );
      break;
    }
    case TransfType.SCALING: {
      const origin = transf.t.value.origin;
      assert(origin.case === 'arbitrary', 'Origin case is not arbitrary');
      const factor = transf.t.value.factor;
      assert(factor.case === 'isotropic', 'Factor case is not isotropic');
      parameterPanel = (
        <>
          <Form.LabeledInput
            help=""
            label="Scale Factor">
            <NumberInput
              asBlock
              disabled={false}
              endAdornment={<QuantityAdornment quantity={QuantityType.IBLANK} />}
              onCommit={(feature) => {
                const newTransf = transf.clone();
                (newTransf.t.value as Scaling).factor.value = feature;
                onSetTransform(newTransf);
              }}
              size="small"
              value={factor.value}
            />
          </Form.LabeledInput>
          <Form.LabeledInput
            label="">
            <ScaleButtons
              getHelp={(value: string) => `Multiply the current scale factor by ${value}`}
              onClick={multiplyScaleFactor}
              scaleValues={[0.5, 2, 3]}
            />
          </Form.LabeledInput>
          <LabeledInput
            help="Point from which all dimensions are scaled"
            label="Scale Origin">
            <Vector3Input
              disabled={false}
              onCommit={(value) => {
                const newTransf = transf.clone();
                (newTransf.t.value as Scaling).origin.value = value;
                onSetTransform(newTransf);
              }}
              value={origin.value}
            />
          </LabeledInput>
          {checkboxes}
        </>
      );
      break;
    }
    case TransfType.MIRROR: {
      const plane = transf.t.value.plane;
      assert(plane.case === 'arbitrary', 'Plane case is not arbitrary');
      parameterPanel = (
        <>
          <LabeledInput
            help="Point on the plane of symmetry"
            label="Plane Origin">
            <Vector3Input
              disabled={false}
              onCommit={(value) => {
                const newTransf = transf.clone();
                (newTransf.t.value as Reflection).plane.value!.origin = value;
                onSetTransform(newTransf);
              }}
              value={plane.value.origin!}
            />
          </LabeledInput>
          <LabeledInput
            help="Normal of the plane of symmetry"
            label="Plane Normal">
            <Vector3Input
              disabled={false}
              onCommit={(value) => {
                const newTransf = transf.clone();
                (newTransf.t.value as Reflection).plane.value!.direction = value;
                onSetTransform(newTransf);
              }}
              value={plane.value.direction!}
            />
          </LabeledInput>
          <Form.LabeledInput label="">
            {readOnly ? null : (
              <Form.Group>
                <UnitVectorButtons
                  onClick={(x, y, z) => {
                    const newTransf = transf.clone();
                    const vector = new basepb.Vector3({ x, y, z });
                    (newTransf.t.value as Reflection).plane.value!.direction = vector;
                    onSetTransform(newTransf);
                  }}
                />
              </Form.Group>
            )}
          </Form.LabeledInput>
          {checkboxes}
        </>
      );
      break;
    }
    default:
      break;
  }

  const mapVisualizerEntities = useCallback(
    (ids: string[]) => mapVisualizerEntitiesToVolumes(ids, staticVolumes),
    [staticVolumes],
  );

  const onTransformSave = useCallback(async () => {
    const feature = new geometrypb.Feature({
      operation: mod.operation,
      id: node.id,
      featureName: mod.featureName,
    });
    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification: new geometrypb.Modification({
        modType: createOrUpdateFeature(geometryState, node.id),
        feature,
      }),
    });
    rpc.clientGeometry!.modifyGeometry(req).catch((err) => (
      addRpcError(`Server error ${err}`, err)
    ));

    // Focus out of the panel, to avoid weird rerenderings when calling setSelection.
    setSelectedFeature(DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE);

    // Remove the focus on the panel, the geometry being displayed may not be linked to the
    // modification.
    setSelection([]);
  }, [geometryId, geometryState, mod.featureName, mod.operation, node.id, setSelection,
    setSelectedFeature]);

  const onNodeChange = useCallback((nodeIds: string[]) => {
    setGeometryState((oldGeometryState) => {
      if (oldGeometryState === undefined) {
        return oldGeometryState;
      }
      const newGeometryState = { ...oldGeometryState };
      newGeometryState.geometryFeatures.forEach((feature) => {
        if (feature.id !== mod.id) {
          return;
        }
        const transfOut = feature.operation.value as geometrypb.Transform;
        const bodies = volumeNodeIdsToCadIds(nodeIds, staticVolumes, cadMetadata);
        transfOut.body = bodies;
      });
      return newGeometryState;
    });
    setSelectedBodiesNodeIds(nodeIds);
  }, [cadMetadata, mod.id, setGeometryState, staticVolumes]);

  return (
    <div className={propClasses.properties}>
      <EditModificationMessage nodeId={node.id} />
      <NodeSubselect
        autoStart={!isAcknowledgedFeature}
        iconNotFoundNodes={{ name: 'cubeOutline' }}
        id="transf"
        labels={['volumes']}
        mapVisualizerEntities={mapVisualizerEntities}
        nodeFilter={nodeFilter}
        nodeIds={selectedBodiesNodeIds}
        onChange={onNodeChange}
        readOnly={readOnly}
        referenceNodeIds={[node.id]}
        showNotFoundNodes
        title="Volumes to Transform"
        visibleTreeNodeTypes={[NodeType.VOLUME]}
      />
      {parameterPanel}
      <GeometryModificationPanelFooter
        featureId={node.id}
        onModificationSave={onTransformSave}
      />
    </div>
  );
};
