// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { JSXElementConstructor, ReactElement, useRef } from 'react';

import cx from 'classnames';

import { DraggableWrapper } from './DraggableWrapper';
import { Tab, TabProps, TabSize } from './Tab';
import { TabConfig, useDraggableTabs } from './useDraggableTabs';
import './Tabs.scss';

export interface DataTabsProps {
  size?: TabSize;
  tabs: TabConfig[];
  draggable?: boolean;
  orderPersistenceKey?: string;
}

const [MIN_TAB_WIDTH, MAX_TAB_WIDTH] = [64, 200];

// Data-driven tabs are generated via the `tabs` prop
export const DataTabs = (props: DataTabsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { size, tabs, draggable: areTabsDraggable, orderPersistenceKey = '' } = props;

  const {
    dragStopHandler,
    getDragHandler,
    currentlyDraggedId,
    updateWrapperSizes,
    tempShiftByIdentifier,
    minimumOffsetByIdentifier,
    sortedTabs,
  } = useDraggableTabs({ tabs, containerRef, orderPersistenceKey });

  return (
    <div className="luminaryTabs">
      <div className="content" ref={containerRef}>
        {sortedTabs.map(({ id, draggable: isTabDraggable, onClick, ...tabProps }) => {
          const isCurrentlyDragged = currentlyDraggedId === id;
          const isDraggable = isTabDraggable && areTabsDraggable;
          const isActive = isCurrentlyDragged || tabProps.foreground;

          const tabNode = (
            <Tab
              size={size}
              {...tabProps}
              enableCloseAnimation={isDraggable}
              foreground={isActive}
              maxWidth={MAX_TAB_WIDTH}
              minWidth={MIN_TAB_WIDTH}
              onClick={(...args) => {
                if (!currentlyDraggedId && onClick) {
                  onClick(...args);
                }
              }}
            />
          );

          return (
            <React.Fragment key={id}>
              {!isDraggable ? (
                <div
                  className={cx('luminaryTabWrapper', isActive && 'active')}>{tabNode}
                </div>
              ) : (
                <DraggableWrapper
                  className={
                    cx(
                      'luminaryTabWrapper',
                      !!currentlyDraggedId && 'dragged',
                      isActive && 'active',
                    )
                  }
                  isCurrentlyDragged={isCurrentlyDragged}
                  minOffset={minimumOffsetByIdentifier[id]}
                  offset={tempShiftByIdentifier[id]}
                  onSizeChange={updateWrapperSizes}
                  onTabDrag={getDragHandler(id)}
                  onTabDragStop={dragStopHandler}
                  tabId={id}>
                  {tabNode}
                </DraggableWrapper>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

type TabChild = ReactElement<TabProps, JSXElementConstructor<TabProps>>;
export interface TabsProps {
  children: TabChild | TabChild[];
}

// Markup tabs are generated by explicitly including <Tab> components under the <Tabs> parent.
// Note that the `children` prop is typed to prevent anything other than <Tab>, but this is
// currently not enforceable in TypeScript.
export const Tabs = (props: TabsProps) => (
  <div className="luminaryTabs">
    <div className="content">
      {props.children}
    </div>
  </div>
);
