// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { Choice } from '../../../../ProtoDescriptor';
import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../../../../SimulationParamDescriptor';
import assert from '../../../../lib/assert';
import { SelectOption } from '../../../../lib/componentTypes/form';
import { protoChoicesToSelectOptions } from '../../../../lib/form';
import { parsePhysicsIdFromSubId } from '../../../../lib/physicsUtils';
import { useFluidPhysics } from '../../../../model/hooks/useFluidPhysics';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { useSimulationParamScope } from '../../../../state/external/project/simulation/paramScope';
import { ActionButton } from '../../../Button/ActionButton';
import { DataSelect } from '../../../Form/DataSelect';
import LabeledInput from '../../../Form/LabeledInput';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { ParamForm } from '../../../ParamForm';
import ParamRow from '../../../ParamRow';
import Divider from '../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { AdvancedFluidPhysicsDialog } from '../../../dialog/AdvancedFluidPhysics';
import { useFluidPhysicsPresets } from '../../../hooks/useFluidPhysicsPresets';
import PropertiesSection from '../../PropertiesSection';

const paramViscousModel = paramDesc[ParamName.ViscousModel];
const paramTurbulence = paramGroupDesc[ParamGroupName.Turbulence];
const paramTurbulenceModel = paramDesc[ParamName.TurbulenceModel];
const paramSubGridScaleModel = paramDesc[ParamName.SubGridScaleModel];
const paramAdjointControls = paramGroupDesc[ParamGroupName.AdjointControlsFluid];

export const EquationsSection = (props: { physicsId: string }) => {
  const { physicsId } = props;
  // Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // Recoil
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // Hooks
  const {
    physics,
    getParamScope,
    adjointSolutionControls,
    viscousModel,
    setViscousModel,

    turbulence,
    setTurbulence,
    turbulenceModel,
    setTurbulenceModel,
    subGridScaleModel,
    setSubGridScaleModel,
  } = useFluidPhysics(projectId, workflowId, jobId, readOnly, physicsId);

  if (!physics || !turbulence || !adjointSolutionControls) {
    return null;
  }

  const physicsScope = getParamScope(paramScope);

  const turbulenceModelChoices = physicsScope.enabledChoices(paramTurbulenceModel);
  const turbulenceModelOptions: SelectOption<Choice['enumNumber']>[] = protoChoicesToSelectOptions(
    turbulenceModelChoices,
    turbulenceModel,
  );

  const subGridScaleModelChoices = physicsScope.enabledChoices(paramSubGridScaleModel);
  const subGridScaleModelOptions: SelectOption<Choice['enumNumber']>[] =
      protoChoicesToSelectOptions(
        subGridScaleModelChoices,
        subGridScaleModel,
      );

  const showTurbulence = !!viscousModel && [
    simulationpb.ViscousModel.DES,
    simulationpb.ViscousModel.RANS,
    simulationpb.ViscousModel.LES,
  ].includes(viscousModel);

  return (
    <>
      <PropertiesSection>
        <ParamRow
          enabledChoices={physicsScope.enabledChoices(paramViscousModel)}
          nestLevel={0}
          param={paramViscousModel}
          readOnly={readOnly}
          setValue={setViscousModel}
          value={viscousModel}
        />
      </PropertiesSection>
      {showTurbulence && (
      <>
        <Divider />
        <PropertiesSection>
          <CollapsibleNodePanel
            headerAsPanelRow
            headerRight={viscousModel === simulationpb.ViscousModel.LES ?
              (
                <DataSelect
                  asBlock
                  disabled={readOnly}
                  onChange={setSubGridScaleModel}
                  options={subGridScaleModelOptions}
                  size="small"
                />
              ) :
              (
                <DataSelect
                  asBlock
                  disabled={readOnly}
                  onChange={setTurbulenceModel}
                  options={turbulenceModelOptions}
                  size="small"
                />
              )}
            heading="Turbulence"
            nodeId={physicsId}
            panelName="turbulence"
            panelOptions={{ defaultExpanded: false }}>
            <ParamForm<simulationpb.Turbulence>
              group={paramTurbulence}
              onUpdate={setTurbulence}
              paramScope={physicsScope}
              proto={turbulence}
              readOnly={readOnly}
              removeParams={['TurbulenceModel', 'SubGridScaleModel']}
            />
          </CollapsibleNodePanel>
        </PropertiesSection>
      </>
      )}
    </>
  );
};

export const FluidSolverSettings = (props: { physicsId: string }) => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { physicsId } = props;
  const {
    physics,
    spatialDiscretizationPreset,
    setSpatialDiscretizationPreset,
    solutionControlsPreset,
    setSolutionControlsPreset,
    adjointSolutionControls,
    disablePresetsReason,
    turbulence,
  } = useFluidPhysics(projectId, workflowId, jobId, readOnly, physicsId);
  const {
    spatialDiscOptions,
    solnControlOptions,
  } = useFluidPhysicsPresets(spatialDiscretizationPreset, solutionControlsPreset, false);

  if (!physics || !turbulence || !adjointSolutionControls) {
    return null;
  }

  return (
    <>
      <LabeledInput
        label="Spatial Discretization">
        <DataSelect
          asBlock
          disabled={readOnly || !!disablePresetsReason}
          onChange={setSpatialDiscretizationPreset}
          options={spatialDiscOptions}
          size="small"
          tooltip={disablePresetsReason}
        />
      </LabeledInput>
      <LabeledInput
        label="Solution Controls">
        <DataSelect
          asBlock
          disabled={readOnly || !!disablePresetsReason}
          onChange={setSolutionControlsPreset}
          options={solnControlOptions}
          size="small"
          tooltip={disablePresetsReason}
        />
      </LabeledInput>
    </>
  );
};

export const PhysicsFluidPropPanel = () => {
  // Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected fluid physics row');
  const physicsId = parsePhysicsIdFromSubId(node.id);

  // Recoil
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // State
  const [advancedIsOpen, setAdvancedIsOpen] = useState(false);

  // Hooks
  const {
    physics,
    getParamScope,
    floatType,
    adjointSolutionControls,
    setAdjointSolutionControls,
    turbulence,
  } = useFluidPhysics(projectId, workflowId, jobId, readOnly, physicsId);
  const propClasses = useCommonTreePropsStyles();

  if (!physics || !turbulence || !adjointSolutionControls) {
    return null;
  }

  const physicsScope = getParamScope(paramScope);

  const showAdjoint = !!floatType && floatType === simulationpb.FloatType.ADA1D;

  return (
    <div className={propClasses.properties}>
      <EquationsSection physicsId={physicsId} />
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          headerRight={(
            <ActionButton
              asBlock
              kind="minimal"
              onClick={() => setAdvancedIsOpen(true)}
              size="small">
              {readOnly ? 'Inspect' : 'Customize'}
            </ActionButton>
          )}
          heading="Advanced Solver Settings"
          nodeId={node.id}
          panelName="advanced">
          <FluidSolverSettings physicsId={physicsId} />
        </CollapsibleNodePanel>
        <AdvancedFluidPhysicsDialog
          onClose={() => setAdvancedIsOpen(false)}
          open={advancedIsOpen}
          physicsId={physicsId}
        />
      </PropertiesSection>
      {showAdjoint && (
        <>
          <Divider />
          <PropertiesSection>
            <CollapsibleNodePanel
              heading="Adjoint Solver Settings"
              nodeId={node.id}
              panelName="advanced_adjoint">
              <ParamForm<simulationpb.AdjointControlsFluid>
                group={paramAdjointControls}
                onUpdate={setAdjointSolutionControls}
                paramScope={physicsScope}
                proto={adjointSolutionControls}
                readOnly={readOnly}
              />
            </CollapsibleNodePanel>
          </PropertiesSection>
        </>
      )}
    </div>
  );
};
