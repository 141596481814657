// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { selectorFamily } from 'recoil';

import GroupMap from '../../../../lib/GroupMap';
import { FrameGroup, getFrameGroupMap } from '../../../../lib/motionDataUtils';
import { GLOBAL_FRAME_NODE_ID, NodeType, SimulationTreeNode } from '../../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../../lib/simulationTree/sectionRecoilKey';
import { simulationParamState } from '../../../external/project/simulation/param';

const getChildRows = (
  frameGroup: GroupMap<FrameGroup>,
  children: Set<string>,
): SimulationTreeNode[] => (
  [...children].map((childId) => {
    const child = frameGroup.get(childId);
    const frame = child.frame;
    return new SimulationTreeNode(
      NodeType.MOTION_FRAME,
      frame.frameId,
      frame.frameName,
      getChildRows(frameGroup, child.children),
    );
  })
);

export const motionSectionSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'motionSection',
  get: (key: SectionRecoilKey) => ({ get }) => {
    const param = get(simulationParamState(key));
    const frameGroupMap = getFrameGroupMap(param);

    // Construct the root frame
    const children: SimulationTreeNode[] = [];
    if (frameGroupMap.root().children.size === 1) {
      const globalFrameId = [...frameGroupMap.root().children][0];
      const globalFrameGroup = frameGroupMap.get(globalFrameId);

      children.push(...getChildRows(frameGroupMap, globalFrameGroup.children));
    }

    return new SimulationTreeNode(
      NodeType.MOTION_GLOBAL_FRAME,
      GLOBAL_FRAME_NODE_ID,
      'Frames & Motion',
      children,
    );
  },
  dangerouslyAllowMutability: true,
});
