// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 18;
const baseHeight = 24;
export const FlagIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="flag"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M1.5,4.5
           C0.675,4.5 0,5.175 0,6
           L0,22.5
           C0,23.325 0.675,24 1.5,24
           C2.325,24 3,23.325 3,22.5
           L3,6
           C3,5.175 2.325,4.5 1.5,4.5Z
           M1.5,0
           C0.675,0 0,0.675 0,1.5
           C0,2.325 0.675,3 1.5,3
           C2.325,3 3,2.325 3,1.5
           C3,0.675 2.325,0 1.5,0Z
           M4.5,4.545
           L4.5,15.39
           C7.605,12.225 13.38,18.015 18,15.39
           L18,4.545
           C13.5,7.65 7.59,1.5 4.5,4.545Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
