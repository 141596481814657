// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const RingCircleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="ringCircle"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M12,0
           C5.37,0 0,5.37 0,12
           C0,18.63 5.37,24 12,24
           C18.63,24 24,18.63 24,12
           C24,5.37 18.63,0 12,0Z
           M12,21
           C7.035,21 3,16.965 3,12
           C3,7.035 7.035,3 12,3
           C16.965,3 21,7.035 21,12
           C21,16.965 16.965,21 12,21Z
           M12,7.5
           C9.51,7.5 7.5,9.51 7.5,12
           C7.5,14.49 9.51,16.5 12,16.5
           C14.49,16.5 16.5,14.49 16.5,12
           C16.5,9.51 14.49,7.5 12,7.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
