// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 18;
export const EyeOffIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="eyeOff"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M24,8.955
           L24,8.85
           C23.97,8.64 23.88,8.46 23.745,8.31
           C23.01,7.365 22.14,6.51 21.27,5.73
           L16.53,9.12
           C16.47,11.535 14.49,13.47 12.06,13.47
           C11.595,13.47 11.16,13.38 10.74,13.245
           L7.635,15.45
           C8.295,15.735 8.985,15.99 9.69,16.155
           C11.145,16.5 12.6,16.515 14.07,16.23
           C15.39,15.975 16.68,15.42 17.865,14.76
           C19.74,13.71 21.45,12.255 22.935,10.635
           C23.205,10.335 23.49,10.02 23.73,9.705
           C23.865,9.555 23.955,9.375 23.985,9.165
           L23.985,9.015
           C24,8.985 24,8.97 24,8.955Z
           M23.355,2.7
           C23.73,2.43 24,2.01 24,1.5
           C24,0.675 23.325,0 22.5,0
           C22.17,0 21.885,0.12 21.645,0.3
           L21.63,0.285
           L17.625,3.15
           C16.59,2.58 15.51,2.115 14.37,1.845
           C12.93,1.5 11.46,1.485 10.005,1.77
           C8.685,2.04 7.395,2.58 6.21,3.255
           C4.335,4.305 2.625,5.76 1.14,7.38
           C0.87,7.68 0.585,7.995 0.345,8.31
           C0,8.745 0,9.255 0.33,9.69
           C1.095,10.68 1.995,11.565 2.925,12.375
           C3.195,12.615 3.495,12.81 3.765,13.035
           L0.63,15.285
           L0.645,15.3
           C0.27,15.57 0,15.99 0,16.5
           C0,17.325 0.675,18 1.5,18
           C1.83,18 2.115,17.88 2.355,17.7
           L2.37,17.715
           L23.37,2.715
           L23.355,2.7Z
           M7.74,10.2
           C7.635,9.81 7.575,9.42 7.575,9
           C7.575,6.54 9.585,4.53 12.06,4.53
           C12.99,4.53 13.845,4.845 14.55,5.325
           L7.74,10.2Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
