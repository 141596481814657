// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import {
  FileSystemDirectoryEntry,
  FileSystemEntry,
  FileSystemFileEntry,
} from '../../lib/filesystem';
import { useSetDraggingFiles } from '../../recoil/useDragOver';
import { createStyles, makeStyles } from '../Theme';
import { CloudUploadIcon } from '../svg/CloudUploadIcon';
import Collapsible from '../transition/Collapsible';

import { Preset as MeshPreset } from './MeshImportDialog';
import MeshUploadControl from './MeshUploadControl';
import { UploadHelp } from './UploadHelp';

const useStyles = makeStyles(
  () => createStyles({
    dragBoxContainer: {
      maxWidth: '75%',
    },
    dragBox: {
      '--border-color': colors.neutral450,
      background: colors.neutral100,
      border: '2px dashed var(--border-color)',
      borderRadius: '4px',
      transition: 'border-color 500ms',
      padding: '63px 93px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dragBoxActive: {
      '--border-color': colors.primaryCta,
      '& > .testBox': {
        fontSize: '24px',
      },
    },
    uploadControl: {
      display: 'flex',
      justifyContent: 'center',
      padding: '16px 0',
    },
    dragAreaBackground: {
      background: colors.surfaceBackground,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    statusBox: {
      background: colors.surfaceDark3,
      border: `1px solid ${colors.neutral450}`,
      borderRadius: '4px',
      fontSize: '12px',
      marginTop: '24px',
      padding: '18px',
      width: '366px',
      height: '90px',
    },
    title: {
      color: colors.highEmphasisText,
      fontSize: '12px',
      textAlign: 'center',
    },
    or: {
      color: colors.highEmphasisText,
      fontSize: '12px',
      textAlign: 'center',
      paddingTop: '8px',
    },
    subtitle: {
      color: colors.lowEmphasisText,
      fontSize: '12px',
      textAlign: 'center',
    },
    error: {
    },
    errorMessage: {
      color: colors.errorMessageText,
      padding: '0 0 1.5em',
    },
    iconContainer: {
      margin: '0 auto 16px',
      width: '56px',
      transition: 'opacity 500ms',
    },
  }),
  { name: 'DragAndDropFiles' },
);

interface DragAndDropFilesProps {
  // Project ID
  projectId: string;
  // experiment (feature flag) UI tags for the current user.
  experimentConfig: string[];
}

// Use cases to support
// 1. User dragged a single file, in which case we set the file as a preset
//    in the dialog
// 2. User dragged a single directory, in which case we set the files/paths as a
//    preset in the dialog.
// NOTE: we don't support dragging multiple files; it's ambiguous and the OS's
//       file browser (launched in MeshImportDialog) doesn't allow it anyway.
function collectFilesFromEntry(
  entry: FileSystemEntry,
  path: string,
  callback: (files: File[], paths: string[]) => void,
  errback: (message: string) => void,
) {
  if (entry.isFile) {
    // File dropped
    (entry as FileSystemFileEntry).file((file: File) => {
      callback([file], [path]);
    });
  } else if (entry.isDirectory) {
    // Get folder contents
    const dirReader = (entry as FileSystemDirectoryEntry).createReader();
    const allFiles: File[] = [];
    const allPaths: string[] = [];
    // NOTE(paul): MDN says that readEntries is deprecated, but I've
    // successfully tested this on Chrome, Firefox, and Edge.
    dirReader.readEntries((entries: FileSystemEntry[]) => {
      let numResponses = 0;
      // This is a callback for an individual entry.
      const entryCallback = (entryFiles: File[], entryPaths: string[]) => {
        numResponses += 1;
        allFiles.push(...entryFiles);
        allPaths.push(...entryPaths);
        // Call the final callback once we have received a response from each
        // individual entry.
        if (numResponses === entries.length) {
          callback(allFiles, allPaths);
        }
      };
      [...entries].forEach(
        (item: FileSystemEntry) => collectFilesFromEntry(
          item,
          `${path + entry.name}/`,
          entryCallback,
          errback,
        ),
      );
    });
  }
}

// This component is a box on which the user can drag and drop files or
// directories and, which will then be preloaded into a mesh import dialog.
// Also includes a MeshUploadControl component that manages mesh imports.
const DragAndDropFiles = (props: DragAndDropFilesProps) => {
  // Tracks drag/dropped file or directory for prepopulating MeshUploadControl
  const [meshPreset, setMeshPreset] = useState<MeshPreset | undefined>();
  // Tracks whether MeshUploadControl has an open dialog so that we can avoid
  // dragging data when true
  // const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [dragError, setDragError] = useState<string>('');
  const classes = useStyles();

  const setDraggingFiles = useSetDraggingFiles();

  const handleDropFiles = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    // Wrap everything below in if (!dialogIsOpen)
    // if we don't want users to drag and drop files on dialog
    const { items } = event.dataTransfer;

    const collectCallback = (entry: FileSystemEntry) => (files: File[], paths: string[]) => {
      if (entry.isFile) {
        setMeshPreset(files[0]);
      } else if (entry.isDirectory) {
        setMeshPreset({ files, paths });
      }
    };

    if (items.length === 1) {
      const entry = items[0].webkitGetAsEntry() as FileSystemEntry;
      collectFilesFromEntry(entry, '', collectCallback(entry), setDragError);
    } else {
      setDragError('Please drag either a mesh or CAD file or an OpenFOAM directory');
    }
  };

  const handleToggleDialog = (open: boolean) => {
    if (open) {
      setDragError('');
    }
    // setDialogIsOpen(open);
  };

  return (
    <div
      className={classes.dragAreaBackground}
      onDragEnter={() => {
        setDragError('');
        setDragActive(true);
        setDraggingFiles(true);
      }}
      onDragLeave={() => {
        setDragActive(false);
      }}
      onDragOver={(event) => {
        event.preventDefault();
        setDragActive(true);
      }}
      onDrop={(event) => {
        handleDropFiles(event);
        setDraggingFiles(false);
      }}>
      <div className={classes.dragBoxContainer}>
        <div
          className={cx(classes.dragBox, {
            [classes.dragBoxActive]: dragActive,
          })}>
          <Collapsible collapsed={!dragError}>
            <div className={classes.error}>
              <div className={classes.errorMessage}>{dragError}</div>
            </div>
          </Collapsible>
          <div className={classes.iconContainer}>
            <CloudUploadIcon color={dragActive ? '#fff' : colors.neutral550} maxHeight={56} />
          </div>
          <div className={classes.title}>
            Drag & drop a file here
          </div>
          <div className={classes.or}>or</div>
          <div className={classes.uploadControl}>
            <div>
              <MeshUploadControl
                disabled={dragActive}
                onToggleDialog={handleToggleDialog}
                preset={meshPreset}
                {...props}
              />
            </div>
          </div>
          <div className={classes.subtitle}>
            <UploadHelp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DragAndDropFiles;
