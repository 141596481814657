// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { getAdValue, newAdFloat } from '../../../lib/adUtils';
import { FaultInfo, InputValidationFunction } from '../../../lib/inputValidationUtils';
import * as basepb from '../../../proto/base/base_pb';
import { InputDescription } from '../InputDescription';
import { NumberInput, NumberInputProps } from '../NumberInput';

export interface ValidNumberInputProps extends NumberInputProps {
  // A function that validates the input value. If the function returns a string,
  // the input is invalid and the string is displayed as an error message.
  // The validate function runs anytime the input changes.
  validate?: InputValidationFunction;
}

/**
 * A NumberInput component with validation. It takes an additional 'validate' prop.
 */
export const ValidNumberInput = (props: ValidNumberInputProps) => {
  const { validate, value, onChange, onCommit, ...inputProps } = props;

  const [faultInfo, setFaultInfo] = useState<FaultInfo | undefined>();

  const handleChange = (newValue: number) => {
    const newFaultInfo = validate?.(newValue);
    setFaultInfo(newFaultInfo);
    if (!newFaultInfo) {
      onChange?.(newValue);
    }
  };

  const handleCommit = (newValue: number) => {
    const newFaultInfo = validate?.(newValue);
    setFaultInfo(newFaultInfo);
    if (!newFaultInfo) {
      onCommit?.(newValue);
    }
  };

  return (
    <>
      <NumberInput
        {...inputProps}
        faultType={faultInfo?.type}
        onChange={handleChange}
        onCommit={handleCommit}
        value={value}
      />
      {!!faultInfo?.message && (
        <div style={{ paddingTop: '4px' }}>
          <InputDescription faultType={faultInfo.type} value={faultInfo.message} />
        </div>
      )}
    </>
  );
};

export interface ValidAdNumberInputProps extends Omit<ValidNumberInputProps, 'value' | 'onCommit'> {
  // The AdFloatType value to display
  value: basepb.AdFloatType | null;
  // Called when input value is committed (by hitting Enter or blurring the input)
  onCommit?: (value: basepb.AdFloatType) => void;
}

export const ValidAdNumberInput = (props: ValidAdNumberInputProps) => {
  const { onCommit, value, ...numInputProps } = props;
  return (
    <ValidNumberInput
      {...numInputProps}
      onCommit={(numValue) => onCommit?.(newAdFloat(numValue))}
      value={value === null ? null : getAdValue(value)}
    />
  );
};
