// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const PointerSparkleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="pointerSparkle"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M23.589,21.675
           C23.849,21.907 23.986,22.386 23.986,22.769
           C23.986,23.371 23.37,23.986 22.769,23.986
           C22.386,23.986 21.907,23.85 21.688,23.59
           L17.408,19.336
           L16.245,24
           L12.334,12.99
           C12.119,12.329 12.292,12.103 12.99,12.334
           L24,16.245
           L19.336,17.394
           L23.589,21.675Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M0.827,8.587
           C0.551,8.711 0.276,8.836 0,8.959
           L0,8.96
           C0.019,9.014 0.068,9.033 0.116,9.052
           C0.128,9.056 0.14,9.061 0.151,9.066
           C0.41,9.182 0.668,9.3 0.926,9.418
           C1.473,9.668 2.02,9.918 2.575,10.155
           C4.511,10.982 5.895,12.217 6.827,13.788
           C7.205,14.427 7.522,15.086 7.839,15.745
           C8.01,16.1 8.18,16.455 8.361,16.807
           C8.494,17.066 8.621,17.328 8.748,17.59
           C8.807,17.711 8.866,17.833 8.926,17.954
           C9.022,17.919 9.04,17.866 9.057,17.818
           C9.062,17.806 9.066,17.793 9.072,17.782
           C9.389,17.14 9.705,16.498 10.021,15.856
           C10.231,15.428 10.441,15 10.652,14.573
           C10.912,14.047 11.212,13.542 11.564,13.066
           L10.975,11.411
           C10.833,10.973 10.948,10.823 11.41,10.976
           L13.007,11.544
           C13.192,11.391 13.386,11.244 13.59,11.103
           C14.281,10.623 15.071,10.272 15.862,9.921
           C16.008,9.856 16.155,9.791 16.3,9.725
           L17.954,8.978
           C17.677,8.854 17.406,8.73 17.139,8.609
           C16.567,8.348 16.011,8.095 15.445,7.858
           C13.494,7.038 12.065,5.821 11.126,4.24
           C10.65,3.438 10.252,2.61 9.855,1.782
           C9.65,1.354 9.445,0.926 9.228,0.502
           C9.206,0.458 9.187,0.412 9.168,0.366
           C9.114,0.239 9.059,0.109 8.93,0
           C8.742,0.379 8.556,0.756 8.371,1.133
           C7.965,1.957 7.562,2.775 7.149,3.591
           C6.239,5.385 4.815,6.829 2.657,7.77
           C2.043,8.038 1.435,8.313 0.827,8.587Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
