// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVType } from '@luminarycloudinternal/lcvis';
import { DefaultValue, atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';

export type LcvisProbeStateType = {
  active: boolean;
  // if the user has clicked on a point on the screen, we place a monitor point there and
  // freeze the values in the probe card.
  placedProbe: boolean;
  // the id of the surface or entity that was probed.
  probedId: string;
  // the coordinates of the point the probe touched.
  coordinates?: [number, number, number];
}

const deactivateProbe = () => {
  const { display } = lcvHandler;
  display?.widgets.probeWidget?.detachFrame();
  display?.widgets.selectionWidget?.attachFrame(display.frames[0]);
};

const activateProbe = () => {
  const { display } = lcvHandler;
  display?.widgets.probeWidget?.attachFrame(display.frames[0]);
  display?.widgets.selectionWidget?.detachFrame();
};

const hideProbePoint = () => {
  const { display } = lcvHandler;
  display?.probePoint?.setParamAtIndex(0, 'visible', LCVType.kLCVDataTypeUint, 0);
};

const showProbePoint = (coordinates?: [number, number, number]) => {
  const { display } = lcvHandler;
  if (!coordinates) {
    throw Error('Tried to show a probe point without valid coordinates');
  }
  display?.probePoint?.setParamAtIndex(0, 'visible', LCVType.kLCVDataTypeUint, 1);
  display?.probePoint?.setParamAtIndex(0, 'points', LCVType.kLCVDataTypeFloat3, coordinates);
};

/**
 * The state of the lcvis probe widget. When active, we overlay text on the screen whenever
 * the user clicks a surface or entity in the visualizer.
 */
export const lcvisProbeState = atom<LcvisProbeStateType>({
  key: 'lcvisProbeState',
  default: {
    active: false,
    placedProbe: false,
    probedId: '',
  },
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newValue, oldValue) => {
        const isDefault = oldValue instanceof DefaultValue;
        if (isDefault) {
          deactivateProbe();
          hideProbePoint();
          return;
        }
        if (newValue.active && newValue.placedProbe) {
          showProbePoint(newValue.coordinates);
        }
        if (!newValue.placedProbe && oldValue.placedProbe) {
          hideProbePoint();
        }
        if (!oldValue.active && newValue.active) {
          try {
            activateProbe();
          } catch {
            // if another widget has focus, the lcvis api will throw an error if we try to attach
            // the probe widget. So if the widget can't get focus, don't attach it
            // but set the recoil state back to reflect that the probe isn't active yet.
            setSelf({ ...newValue, active: false });
          }
          if (!newValue.placedProbe) {
            hideProbePoint();
          }
        }
        if (!newValue.active) {
          deactivateProbe();
          hideProbePoint();
          setSelf({ ...newValue, placedProbe: false });
        }
      });
    },
  ],
});

export const useLcvisProbeState = () => useRecoilState(lcvisProbeState);

export const useSetLcvisProbeState = () => useSetRecoilState(lcvisProbeState);

export const useLcvisProbeValue = () => useRecoilValue(lcvisProbeState);
