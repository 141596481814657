// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { initialRemoveResult } from '../../lib/baseUtils';
import { unassignMaterialsFromDomains } from '../../lib/entityRelationships';
import {
  ConfigurableMaterialType,
  FIXED_FLUID_PRESETS,
  FIXED_SOLID_PRESETS,
  GROUPED_FLUID_PRESETS,
  GROUPED_SOLID_PRESETS,
  appendMaterial,
  getMaterialId,
  renameMaterial as rename,
} from '../../lib/materialUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useSimulationMaterials } from '../../state/external/project/simulation/param/materials';

import { useWorkflowConfig } from './useWorkflowConfig';

// Expose the material types that can be added
export const typesToAdd: ConfigurableMaterialType[] = ['materialFluid', 'materialSolid'];

/**
 * Model hook for managing a set of materials
 */
export const useMaterials = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
) => {
  const {
    saveParam,
    saveParamAsync,
  } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  const materialData = useSimulationMaterials(projectId, workflowId, jobId);

  // The material presets that have been used in the simulation param
  const usedFluidPresets = new Set<simulationpb.MaterialFluidPreset>();
  const usedSolidPresets = new Set<simulationpb.MaterialSolidPreset>();

  materialData.forEach(({ model }) => {
    const fluidPreset = (model.material.case === 'materialFluid') ?
      model.material.value.materialFluidPreset : null;
    const solidPreset = (model.material.case === 'materialSolid') ?
      model.material.value.materialSolidPreset : null;

    if (fluidPreset && FIXED_FLUID_PRESETS.includes(fluidPreset)) {
      usedFluidPresets.add(fluidPreset);
    } else if (solidPreset && FIXED_SOLID_PRESETS.includes(solidPreset)) {
      usedSolidPresets.add(solidPreset);
    }
  });

  // The material presets that may still be used in the simulation param (all presets minus any
  // "fixed" presets that have been used already)
  const availableFluidPresets = GROUPED_FLUID_PRESETS.map((group) => group.map((preset) => ({
    preset,
    used: usedFluidPresets.has(preset),
  })));
  const availableSolidPresets = GROUPED_SOLID_PRESETS.map((group) => group.map((preset) => ({
    preset,
    used: usedSolidPresets.has(preset),
  })));

  // Add a material to the simulation param and save the updated param
  const addMaterial = useCallback(async (type: ConfigurableMaterialType) => saveParamAsync(
    (newParam) => appendMaterial(newParam, type),
  ), [saveParamAsync]);

  // Remove a material (by ID) from a simulation param
  const removeMaterial = useCallback((param: simulationpb.SimulationParam, id: string) => {
    const { itemToDelete, newItems } = param.materialEntity.reduce(
      (result, materialEntity) => {
        if (getMaterialId(materialEntity) === id) {
          result.itemToDelete = materialEntity;
        } else {
          result.newItems.push(materialEntity);
        }
        return result;
      },
      initialRemoveResult<simulationpb.MaterialEntity>(),
    );

    param.materialEntity = newItems;
    if (itemToDelete) {
      unassignMaterialsFromDomains(param, new Set([id]));
    }

    return !!itemToDelete;
  }, []);

  // Delete a material (by ID) from a simulation param and save the updated param
  const deleteMaterial = async (id: string): Promise<boolean> => saveParamAsync(
    (newParam) => removeMaterial(newParam, id),
  );

  // Rename a material (identified by ID) and save the updated param
  const renameMaterial = useCallback((id: string, name: string) => {
    saveParam((newParam) => rename(newParam, id, name));
  }, [saveParam]);

  // Returns true if the preset is "fixed", meaning it prescribes fixed settings on the material
  const isFixedPreset = (value: number) => (
    FIXED_FLUID_PRESETS.includes(value) || FIXED_SOLID_PRESETS.includes(value)
  );

  return {
    availableFluidPresets,
    availableSolidPresets,
    addMaterial,
    appendMaterial,
    removeMaterial,
    deleteMaterial,
    renameMaterial,
    isFixedPreset,
  };
};
