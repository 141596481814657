// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useRecoilValue } from 'recoil';

/**
 * lcVisReady tracks whether or not the lcvis context is set up yet, for example we need
 * to have a canvas element, lcvis session, display, and frame ready for rendering.
 * We can't do any lcvis actions until those things have been set up.
 */
let lcVisReady = false;
type LcVisReadyCallback = ((val: boolean) => void)
const lcVisReadyCallbacks: LcVisReadyCallback[] = [];
export const setLcVisReady = (newVal: boolean) => {
  lcVisReady = newVal;
  lcVisReadyCallbacks.forEach((callback) => callback(newVal));
};
export const getLcVisReady = () => lcVisReady;
const addLcVisReadyCallback = (cb: LcVisReadyCallback) => lcVisReadyCallbacks.push(cb);
const removeLcVisReadyCallback = (cb: LcVisReadyCallback) => {
  const index = lcVisReadyCallbacks.indexOf(cb);
  if (index > -1) {
    lcVisReadyCallbacks.splice(index, 1);
  }
};
/** Whether or not the lcvis wasm context is ready to apply functions */
export const lcVisReadyState = atom({
  key: 'lcVisReadyAtom',
  default: getLcVisReady(),
  effects: [
    ({ setSelf }) => {
      const cb = (newVal: boolean) => {
        setSelf(newVal);
      };
      addLcVisReadyCallback(cb);
      return () => removeLcVisReadyCallback(cb);
    },
    ({ onSet, setSelf }) => {
      onSet((newVal, oldVal, isReset) => {
        if (isReset) {
          setSelf(getLcVisReady());
        }
      });
    },
  ],
});

export const useLcVisReadyValue = () => useRecoilValue(lcVisReadyState);
