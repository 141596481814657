// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

// Return a random item from an array
export function choice<T>(items: T[], random: () => number = Math.random): T {
  const idx = Math.floor(random() * items.length);
  return items[idx];
}

// Return a random number between min/max
export function number(min: number, max: number, random: () => number = Math.random) {
  if (min > max) {
    throw Error(`Can't generate a number between ${min} and ${max}`);
  }
  return min + random() * (max - min);
}

// Return a random integer between min/max
export function integer(min: number, max: number, random: () => number = Math.random) {
  const result = Math.floor(number(Math.ceil(min), Math.floor(max) + 1, random));

  if (result < min || result > max) {
    throw Error(`Can't generate an integer between ${min} and ${max}`);
  }

  return result;
}

// Shuffles an array in place
export function shuffle<T>(items: T[], random: () => number = Math.random): void {
  for (let i = items.length - 1; i > 0; i -= 1) {
    const j = Math.floor(random() * (i + 1));
    [items[i], items[j]] = [items[j], items[i]];
  }
}

// Returns a shuffled copy of an array
export function shuffled<T>(items: T[], random: () => number = Math.random): T[] {
  const newItems = [...items];
  shuffle(newItems, random);
  return newItems;
}

// Return a random subset of an array (like dealing a poker hand from a deck of cards)
export function subset<T>(items: T[], size: number, random: () => number = Math.random): T[] {
  return shuffled(items, random).slice(0, Math.min(items.length, size));
}

// Return a random string of 'len' size
export function string(len: number, random: () => number = Math.random): string {
  let result = '';
  while (result.length < len) {
    const rand = random().toString(36);
    // The first two letters of rand are "0x", so drop them.
    result += rand.substring(2);
  }
  return result.slice(0, len);
}

// LCG random number generator
export function seededRandom(seed: number): () => number {
  const modulus = 0x80000000; // 2**31
  const multiplier = 1103515245;
  const increment = 12345;
  let state = seed;

  return () => {
    state = (multiplier * state + increment) % modulus;
    return state / (modulus - 1);
  };
}
