// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useEffect, useRef } from 'react';

import Overlay, { OverlayProps } from './Overlay';

export type ClickableOverlayProps = OverlayProps & {
  className: string;
  // Handles a click at the given mouse position.
  onClick: (x: number, y: number, width: number, height: number) => void;
  children?: ReactNode;
};

const ClickableOverlay = (props: ClickableOverlayProps) => {
  const { onClick } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = ref?.current;
    const handleClick = (event: MouseEvent) => {
      el && onClick(event.offsetX, event.offsetY, el.clientWidth, el.clientHeight);
    };
    el?.addEventListener('click', handleClick);
    return () => {
      el?.removeEventListener('click', handleClick);
    };
  }, [onClick]);

  return (
    <div
      className={props.className}
      ref={ref}>
      <Overlay {...props} />
    </div>
  );
};

export default ClickableOverlay;
