// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import Intercom from '@intercom/messenger-js-sdk';

import { getAuthInfoV2 } from './AuthInfoCallbackV2';
import { isItarEnv, isProd, isStaging } from './RuntimeParams';
import { getLcUserIdFromToken } from './jwt';

const INTERCOM_APP_ID_PROD = 'nmpz6q71'; // prod, test0
const INTERCOM_APP_ID_TEST = 'py46mxgd'; // main, preview envs
const INTERCOM_APP_ID_ITAR_PROD = 'luzfwjzj'; // itar-prod, itar-staging
const INTERCOM_APP_ID_ITAR_TEST = 'x6mgm3z0'; // itar-main
const INTERCOM_POSITION = 'left';
export const INTERCOM_LAUNCHER_SELECTOR = '.intercomLauncher';

export function initializeIntercom(userHash: string) {
  const jwt = getAuthInfoV2().jwt;
  const userId = getLcUserIdFromToken(jwt);
  if (jwt && userId) {
    let appId;
    if (isProd || isStaging) {
      appId = isItarEnv ? INTERCOM_APP_ID_ITAR_PROD : INTERCOM_APP_ID_PROD;
    } else {
      appId = isItarEnv ? INTERCOM_APP_ID_ITAR_TEST : INTERCOM_APP_ID_TEST;
    }
    Intercom({
      app_id: appId,
      alignment: INTERCOM_POSITION,
      custom_launcher_selector: INTERCOM_LAUNCHER_SELECTOR,
      hide_default_launcher: true,
      user_id: userId,
      user_hash: userHash, // this enables the Messengers' Identity Verification
      name: `${jwt.given_name} ${jwt.family_name}`,
      email: jwt.email,
    });
  }
}

/**
 * There is not a reliable way to programatically check if the Intercom popup has been disabled from
 * the Intercom website. Luckily, Intercom will inject some DOM elements if the integration
 * is enabled. It's possible that these selectors might change in the future from Intercom but
 * there's no way around it and we'll have to update them if that happens.
 *
 * Note: If the "Enable user traffic for messenger" option is disabled in the Intercom website, this
 * check will NOT work.
 * This only works if the Messenger is enabled/disabled by adding/removing allowed
 * domains/subdomains in the "Keep your Messenger secure" -> "List your trusted domains" setting in
 * the Messenger's "General settings". See
 * https://app.intercom.com/a/apps/<ADD_APP_ID_HERE>/messenger/settings?section=security&tab=general
 * for details.
 */
export function isIntercomReady() {
  // These Intercom elements are in the DOM before we open the Intercom popup
  const elementsBeforePopup = ['.intercom-lightweight-app', '#intercom-lightweight-app-style'];

  // If we open the popup (even if we hide it afterwards), the elements above are no longer
  // available in the DOM and they are replaced with the elements below. Adding more elements
  // just in case.
  const elementsAfterPopup = [
    '#intercom-container',
    '.intercom-namespace',
    '.intercom-app',
    '#intercom-modal-container',
    '#intercom-css-container',
  ];

  return !!document.querySelector([...elementsBeforePopup, ...elementsAfterPopup].join(','));
}
