// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
/** Animation helper functions */

type translationDirection = 'X' | 'Y'

type elementType = HTMLDivElement

type easingFunctionType = (start: number, end: number, progress: number) => number

// Uses requestAnimationFrame to call callbackFn precisely once DELAY has passed.
// Can replace setTimeout when you need to time the end of an animation without flashing.
export const callAfterAnimation = (
  callbackFn: (...args: any) => void,
  delay: number,
  // optional: arguments to call with callbackFn
  ...callbackArgs: any
) => {
  let start: number;
  const checkTime = (timestamp: number) => {
    if (start === undefined) {
      start = timestamp;
    }
    if (timestamp - start > delay) {
      callbackFn(...callbackArgs);
      return;
    }
    requestAnimationFrame(checkTime);
  };
  const frame = requestAnimationFrame(checkTime);
  return () => cancelAnimationFrame(frame);
};

// linear easing (interpolation) function. gives the position that's {progress}% along
// the path from start to end. progress should be a number between 0 and 1.
export const lerp = (startPos: number, endPos: number, progress: number): number => {
  if (progress > 1) {
    // don't allow progressing past endPos
    return endPos;
  }
  return startPos + ((endPos - startPos) * progress);
};

/** An easing function that starts fast and ends slow. */
export const easeOutQuart: easingFunctionType = (start, end, progress) => {
  // Ensure progress is within the range [0, 1]
  progress = Math.max(0, Math.min(1, progress));
  return end - (end - start) * (1 - progress) ** 4;
};

// Easing function. Position doesn't change until progress = 0.5, then increases
// linearly during the last half.
export const pauseThenLerp = (startPos: number, endPos: number, progress: number): number => {
  if (progress < 0.5) {
    return startPos;
  }
  return lerp(startPos, endPos, (progress - 0.5) * 2);
};

/** Generic loop helper. Calls 'step' in a loop using requestAnimationFrame until startVar = endVar.
 * Every time step runs, the new current position (currVar) is calculated using easingFunction.
 * then perStepFn is called using currVar to update style in a user-defined function.
 * see translate, animateOpacity for example implementation. */
export const animationLoop = (
  startVar: number,
  endVar: number,
  // duration of animation in ms
  duration: number,
  // function to update desired style.
  perStepFn: (currVar: number) => void,
  // easing function to use. Defaults to lerp.
  easingFunction: easingFunctionType = lerp,
) => {
  let start: number | undefined;
  let previousTimeStamp = Date.now();
  let done = false;
  let frame = 0;

  // this is the function that gets called every repaint.
  function step(timestamp: number) {
    frame = requestAnimationFrame(step);

    if (start === undefined) {
      start = timestamp;
    }
    const elapsed = timestamp - start;

    // calculate how far to be translated at this timestep
    if (previousTimeStamp !== timestamp) {
      const progress = (elapsed) / duration;
      const currVar = easingFunction(startVar, endVar, progress);
      // call perStepFn every frame to update desired style.
      // ex) perStepFn = (currVar: number) => {element.style.opacity = `${currVar}`}
      perStepFn(currVar);
      if (currVar === endVar || elapsed >= duration) {
        done = true;
      }
    }
    if (elapsed < duration) {
      // Stop the animation after 'duration' ms have passed
      previousTimeStamp = timestamp;
    }
    if (done) {
      cancelAnimationFrame(frame);
    }
  }
  frame = requestAnimationFrame(step);
  return () => cancelAnimationFrame(frame);
};

// animate changing opacity from startOpacity to endOpacity.
export const animateOpacity = (
  element: elementType,
  startOpacity: number,
  endOpacity: number,
  duration: number,
  easingFunction: easingFunctionType = lerp,
) => {
  element.style.opacity = `${startOpacity}`;
  const perStep = (currentOpacity: number) => {
    element.style.opacity = `${currentOpacity}`;
  };
  animationLoop(startOpacity, endOpacity, duration, perStep, easingFunction);
};

/**
 *  generate a Regexp that matches `${baseString}` + (float + `${unit}`).
 *  ex) getFloatOrExpRegexp(`translate${direction}`, 'px')
 * will match both translateX(10.002px) or translateX(7.7e-5px)
*/
export const getFloatOrExpRegexp = (baseString: string, unit?: string) => {
  const unitStr = unit || '';
  return new RegExp(`${baseString}\\(-?\\d*\\.?\\d*${unitStr}\\)|` +
    `(${baseString}\\(-?\\d*\\.?\\d*e-?\\d+${unitStr}\\))`);
};

/**
 * The CSS Transform property is a string that can have multiple fields, e.g.
 * transform: translateX(10px) scale(0.9). This sets a specific one of those fields
 * without modifying the others.
*/
export const setTransform = (
  element: elementType,
  transformType: 'translateX' | 'translateY' | 'scale',
  value: number,
  units: string = '',
) => {
  const re = getFloatOrExpRegexp(transformType, units);
  const newProp = `${transformType}(${value}${units})`;
  if (element.style.transform.includes(transformType)) {
    element.style.setProperty(
      'transform',
      element.style.transform.replace(re, newProp),
    );
  } else {
    // translations should come before other types (like scale),
    // since transformations are applied in order left-to-right.
    const fullTransform = (transformType === 'scale') ?
      element.style.transform + newProp :
      newProp + element.style.transform;
    element.style.setProperty(
      'transform',
      fullTransform,
    );
  }
};

// translate element from startPosition to endPosition over duration ms along the Y axis.
export const translateY = (
  // element to translate
  element: elementType,
  startPosition: number,
  endPosition: number,
  // duration of animation in ms
  duration: number,
  // optional easing function. If undefined, will default to linear.
  easingFunction?: easingFunctionType,
) => {
  if (duration === 0) {
    setTransform(element, 'translateY', endPosition, 'px');
    return;
  }
  // initialize element transform to startPosition
  setTransform(element, 'translateY', startPosition, 'px');
  const perStep = (newPosition: number) => {
    setTransform(element, 'translateY', newPosition, 'px');
  };
  animationLoop(startPosition, endPosition, duration, perStep, easingFunction);
};

// translate element from startPosition to endPosition over duration ms along the X axis.
export const translateX = (
  // element to translate
  element: elementType,
  startPosition: number,
  endPosition: number,
  // duration of animation in ms
  duration: number,
  easingFunction?: easingFunctionType,
) => {
  if (duration === 0) {
    setTransform(element, 'translateX', endPosition, 'px');
    return;
  }
  // initialize element transform to startPosition
  setTransform(element, 'translateX', startPosition, 'px');
  const perStep = (newPosition: number) => {
    setTransform(element, 'translateX', newPosition, 'px');
  };
  animationLoop(startPosition, endPosition, duration, perStep, easingFunction);
};

// scale element, starting at startFactor and ending at endFactor.
// If startFactor is not the same as the scale factor when you start the animation,
// the element will jump to scale(startFactor) and go from there.
// Assumes that startFactor and endFactor are expressed as floats and not percentages.
export const scale = (
  // element to scale
  element: elementType,
  startFactor: number,
  endFactor: number,
  // duration of animation in ms
  duration: number,
  easingFunction: easingFunctionType = lerp,
) => {
  if (duration === 0) {
    setTransform(element, 'scale', endFactor);
    return;
  }
  // we need to initialize the scaling amount to startFactor.
  // ex) if startFactor is 0.8, set transform: scale(0.8).
  setTransform(element, 'scale', startFactor);
  const perStep = (currFactor: number) => {
    setTransform(element, 'scale', currFactor);
  };
  // animate changing scale.
  animationLoop(startFactor, endFactor, duration, perStep, easingFunction);
};

// regex which matches the first number (as a float) in a string
const floatRegex = /[+-]?\d+(\.\d+)?/;

// given an input string, return the first float
export const getFirstFloat = (
  input: string,
) => {
  const matches = input.match(floatRegex);
  if (matches?.length) {
    return parseFloat(matches[0]);
  }
  return null;
};

// Given a string input, returns the first float after the first instance
// of 'matchString' in the input.
// ex calling getFirstFloatAfterMatch('hi5.0hi6.0', 'hi') will return 5.0
export const getFirstFloatAfterMatch = (
  input: string,
  matchString: string,
) => {
  if (input.includes(matchString)) {
    const substr = input.substring(input.indexOf(matchString) + matchString.length);
    return getFirstFloat(substr);
  }
  return null;
};

// translate from current position to endPosition. If no transform is currently active,
// it will translate to endPosition from translate(0).
export const translateTo = (
  // element to translate
  element: elementType,
  direction: translationDirection,
  endPosition: number,
  // duration of animation in ms
  duration: number,
  easingFunction?: easingFunctionType,
) => {
  const transform = element.style.transform;
  const startPosition = getFirstFloatAfterMatch(transform, `translate${direction}`) || 0;
  if (direction === 'X') {
    translateX(element, startPosition, endPosition, duration, easingFunction);
  } else if (direction === 'Y') {
    translateY(element, startPosition, endPosition, duration, easingFunction);
  }
};

// returns the value X of an element with transform: scale(X) applied. Or if the element isn't
// scaled, returns 1.
export const getScaleFactor = (
  transform: string,
) => getFirstFloatAfterMatch(transform, 'scale(') || 1;

// scale from current scale factor to newFactor. If there's no scale modifier active,
// will scale from scale(1) to newFactor
export const scaleTo = (
  // element to scale
  element: elementType,
  newFactor: number,
  // duration of animation in ms
  duration: number,
  easingFunction?: easingFunctionType,
) => {
  const transform = element.style.transform;
  const startFactor = getScaleFactor(transform) || 1;
  return scale(element, startFactor, newFactor, duration, easingFunction);
};
