// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { ConnectError } from '@connectrpc/connect';

import * as frontendpb from '../proto/frontend/frontend_pb';
import * as uploadpb from '../proto/upload/upload_pb';

import { UploadProgress } from './UploadProgress';
import { Logger } from './observability/logs';
import * as rpc from './rpc';
import * as status from './status';
import { addRpcError } from './transientNotification';

const logger = new Logger('meshConversionStatus');

const rpcPool = new rpc.StreamingRpcPool<
  frontendpb.MeshConversionStatusRequest,
  frontendpb.MeshConversionStatusReply
>('GetMeshConversionStatus', rpc.client.meshConversionStatus);

export async function meshConversionStatus(
  projectId: string,
  userUrl: string,
  scaling: number,
  disconnect: boolean,
  onProgress: (progress: UploadProgress) => void,
  fileType: uploadpb.MeshType | undefined,
): Promise<string> {
  // RPC errors are automatically retried by the rpcPool, so this function never
  // invokes the reject callback.
  return new Promise<string>((resolve: (url: string) => void, reject: (err: Error) => void) => {
    let done = false;
    const cancelRpc = rpcPool.start(
      userUrl /* rpc key */,
      () => new frontendpb.MeshConversionStatusRequest({
        projectId,
        url: userUrl,
        scaling,
        disconnect,
        meshType: fileType,
      }),
      (reply: frontendpb.MeshConversionStatusReply) => {
        if (reply.typ.case === 'event') {
          const event = reply.typ.value;
          onProgress({
            done: false,
            message: event.event,
            progress: event.progressFraction,
          });
        }
        if (reply.status !== frontendpb.MeshConversionStatus.IN_PROGRESS) {
          if (!done) {
            done = true;
            cancelRpc();
            const err = reply.typ.case === 'err' ? reply.typ.value : undefined;
            onProgress({
              done: true,
              message: err && new status.Parser(err).headlineMessage(),
              progress: 1.0,
            });
            if (err) {
              const proto = status.fromProto(err)!;
              logger.warn(`Conversion error: ${status.stringifyError(proto)}`);
              reject(proto);
            } else {
              const url = reply.typ.case === 'url' ? reply.typ.value : '';
              resolve(url);
            }
          }
        }
      },
      (err: ConnectError) => addRpcError('GetMeshConversionStatus failed', err),
    );
  });
}
