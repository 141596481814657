// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/cad/boolean.proto (package luminary.proto.cad, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Union of bodies to create a single resulting body
 *
 * @generated from message luminary.proto.cad.RegularUnion
 */
export const RegularUnion = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.RegularUnion",
  () => [
    { no: 1, name: "bodies", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "keep_source_bodies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Subtraction of a list of bodies from larger bodies
 *
 * @generated from message luminary.proto.cad.RegularSubtraction
 */
export const RegularSubtraction = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.RegularSubtraction",
  () => [
    { no: 1, name: "bodies", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "tools", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 3, name: "keep_source_bodies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "keep_tool_bodies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Intersection of bodies to create a single resulting body
 *
 * @generated from message luminary.proto.cad.RegularIntersection
 */
export const RegularIntersection = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.RegularIntersection",
  () => [
    { no: 1, name: "bodies", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "keep_source_bodies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Chop of a list of bodies from larger bodies
 * Combination of a subtraction and an intersection
 *
 * @generated from message luminary.proto.cad.RegularChop
 */
export const RegularChop = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.RegularChop",
  () => [
    { no: 1, name: "bodies", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "tools", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 3, name: "keep_source_bodies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "keep_tool_bodies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

