// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { SvgProps, getComputedScale } from './svg';

const DEFAULT_COLOR = 'currentColor';

export const useSvg = (
  baseWidth: number,
  baseHeight: number,
  props: SvgProps,
) => {
  const { color = [], ...scaling } = props;

  const scale = getComputedScale(baseWidth, baseHeight, scaling);
  const finalWidth = baseWidth * scale;
  const finalHeight = baseHeight * scale;

  /**
   * Given a color or list of colors and an index, return a color.
   *   If a single color is provided, it will always be returned.
   *   If no color is provided, a default color will be returned.
   *   If index > color.length, index % color.length will be used as the index.
  */
  const colorPool = useMemo(() => (Array.isArray(color) ? color : [color]), [color]);
  const getColor = useCallback((index = 0) => {
    if (colorPool.length) {
      return colorPool[index % colorPool.length];
    }
    return DEFAULT_COLOR;
  }, [colorPool]);

  return {
    finalHeight,
    finalWidth,
    scale,
    getColor,
  };
};
