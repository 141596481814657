// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useAtom, useAtomValue } from 'jotai';

export const hoveredRowId = atom<string | null>(null);

export function useHoveredRowId() {
  return useAtom(hoveredRowId);
}

export function useHoveredRowIdValue() {
  return useAtomValue(hoveredRowId);
}

export const selectedRowId = atom<string | null>(null);

export function useSelectedRowId() {
  return useAtom(selectedRowId);
}

export function useSelectedRowIdValue() {
  return useAtomValue(selectedRowId);
}
