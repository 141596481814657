// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { Vector3 } from '../../../proto/base/base_pb';
import { CenterInput } from '../PrimitiveInputs/CenterInput';
import { NormalInput } from '../PrimitiveInputs/NormalInput';
import { RadiusInput } from '../PrimitiveInputs/RadiusInput';

type HalfSphere = {
  center: { x: number, y: number, z: number },
  radius: number,
  normal: { x: number, y: number, z: number },
}

export interface HalfSphereInputProps {
  value: HalfSphere,
  onCommit: (value: HalfSphere) => void,
  disabled?: boolean,
  onChange?: (value: HalfSphere) => void,
}

export const HalfSphereInput = (props: HalfSphereInputProps) => {
  const { value, onCommit, disabled = false, onChange } = props;

  const handleCenterCommit = (center: Vector3) => {
    onCommit({ ...value, center: { x: center.x, y: center.y, z: center.z } });
  };

  const handleRadiusCommit = (radius: number) => {
    onCommit({ ...value, radius });
  };

  const handleCenterChange = (center: Vector3) => {
    onChange?.({ ...value, center: { x: center.x, y: center.y, z: center.z } });
  };

  const handleRadiusChange = (radius: number) => {
    onChange?.({ ...value, radius });
  };

  const handleNormalCommit = (normal: Vector3) => {
    onCommit({ ...value, normal: { x: normal.x, y: normal.y, z: normal.z } });
  };

  const handleNormalChange = (normal: Vector3) => {
    onChange?.({ ...value, normal: { x: normal.x, y: normal.y, z: normal.z } });
  };

  return (
    <>
      <CenterInput
        disabled={disabled}
        label="Center"
        onChange={handleCenterChange}
        onCommit={handleCenterCommit}
        value={new Vector3({ ...value.center })}
      />
      <RadiusInput
        inputProps={{
          value: value.radius,
          onCommit: handleRadiusCommit,
          disabled,
          onChange: handleRadiusChange,
        }}
        type="single"
      />
      <NormalInput
        disabled={disabled}
        help="The normal of the plane cutting the half sphere."
        label="Normal"
        onChange={handleNormalChange}
        onCommit={handleNormalCommit}
        value={new Vector3({ ...value.normal })}
      />
    </>
  );
};
