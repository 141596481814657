// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties } from 'react';

import { IconName } from '../../lib/componentTypes/svgIcon';
import { ColorBase } from '../../lib/designSystem';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      width: 'var(--size)',
      height: 'var(--size)',
      borderRadius: '6px',
      backgroundColor: 'var(--bg-color)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { name: 'DialogSymbol' },
);

export interface DialogSymbolProps {
  colorBase: ColorBase;
  iconName: IconName;
  size: number;
}

export const DialogSymbol = (props: DialogSymbolProps) => {
  const { colorBase, iconName, size } = props;

  const classes = useStyles();

  const bgColor = `var(--color-${colorBase}-50)`;
  const iconColor = `var(--color-${colorBase}-300)`;

  return (
    <div
      className={classes.root}
      style={{
        '--size': `${size}px`,
        '--bg-color': bgColor,
      } as CSSProperties}>
      <SvgIcon color={iconColor} maxHeight={size / 2} maxWidth={size / 2} name={iconName} />
    </div>
  );
};
