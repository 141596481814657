// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState, useSetRecoilState, waitForAll } from 'recoil';

import { singlePhysicsWorkflowConfigFixture } from '../lib/fixtures';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { getSimulationParam } from '../lib/simulationParamUtils';
import { isSimulationTransient } from '../lib/simulationUtils';
import { DEPRECATED_NODE_ID } from '../lib/stoppingCondsUtils';
import { isTestingEnv } from '../lib/testing/utils';
import * as feoutputpb from '../proto/frontend/output/output_pb';
import * as outputpb from '../proto/output/output_pb';
import * as workflowpb from '../proto/workflow/workflow_pb';

import { frontendMenuState } from './frontendMenuState';
import { outputNodesState } from './outputNodes';
import { currentConfigSelector } from './workflowConfig';

const DEFAULT_STOPPING_CONDITIONS_VALUES = {
  threshold: 1e-5,
  maxInnerIterations: 5,
  maxIterations: 2000,
  maxTime: 1,
  maxTransientIterations: 2500,
};

export const stoppingConditionsPrefix = 'stoppingConditions';

const deserialize = (val: Uint8Array) => (
  val.length ? feoutputpb.StoppingConditions.fromBinary(val) : null
);

export const serialize = (val: feoutputpb.StoppingConditions) => val.toBinary();

// upgradeStopConds upgrades a legacy stopping conditions proto, and returns
// the latest version.
function upgradeStopConds(
  oldStopConds: feoutputpb.StoppingConditions | null,
  outputNodes: feoutputpb.OutputNodes,
): feoutputpb.StoppingConditions | null {
  if (!oldStopConds) {
    return null;
  }
  const newStopConds = oldStopConds.clone();
  // If any of the condition just stores the Id (which is not used anymore),
  // copy the corresponding node.
  newStopConds.cond.forEach((cond) => {
    if (cond.legacyNodeId !== DEPRECATED_NODE_ID) {
      const foundNode = outputNodes.nodes.find(
        (node) => node.id === cond.legacyNodeId,
      );
      if (foundNode) {
        cond.node = foundNode.clone();
        cond.legacyNodeId = DEPRECATED_NODE_ID;
      }
    }
  });
  return newStopConds;
}

// defaultStopConds creates the default stopping conditions.
export function defaultStopConds(config: workflowpb.Config): feoutputpb.StoppingConditions {
  const param = getSimulationParam(config);

  return new feoutputpb.StoppingConditions({
    op: outputpb.StoppingConditionOp.STOP_COND_OP_ALL,
    maxPhysicalTime: DEFAULT_STOPPING_CONDITIONS_VALUES.maxTime,
    maxInnerIterations: DEFAULT_STOPPING_CONDITIONS_VALUES.maxInnerIterations,
    maxIterations: isSimulationTransient(param) ?
      DEFAULT_STOPPING_CONDITIONS_VALUES.maxTransientIterations :
      DEFAULT_STOPPING_CONDITIONS_VALUES.maxIterations,
  });
}

const stoppingConditionBase = selectorFamily<
  feoutputpb.StoppingConditions | null,
  persist.RecoilProjectKey
>({
  key: 'stoppingConditionBase',
  get: (key: persist.RecoilProjectKey) => () => persist.getProjectState(
    key.projectId,
    [persist.getProjectStateKey(stoppingConditionsPrefix, key), stoppingConditionsPrefix],
    deserialize,
  ),
});

// stoppingConditionsSelector gets the stopping conditions state from the kvstore.
export const stoppingConditionsSelectorRpc = selectorFamily<
  feoutputpb.StoppingConditions | null, persist.RecoilProjectKey
>({
  key: 'stoppingConditionsSelector',
  get: (key: persist.RecoilProjectKey) => async ({ get }) => {
    if (key.workflowId) {
      const frontendMenu = get(frontendMenuState(key));
      // For older projects the stopping conditions for a specific workflow are part of the
      // frontendMenuState so we fetch them from there if it exists. This is indicated by a
      // proper (i.e. non-default) value for the stopping condition operation.
      if (frontendMenu.stopConds?.op) {
        return frontendMenu.stopConds!;
      }
    }
    return get(stoppingConditionBase(key));
  },
  dangerouslyAllowMutability: true,
});

const stoppingConditionsSelectorTesting = selectorFamily<
  feoutputpb.StoppingConditions | null, persist.RecoilProjectKey
>({
  key: 'stoppingConditionsSelector',
  get: () => () => defaultStopConds(singlePhysicsWorkflowConfigFixture().config),
  dangerouslyAllowMutability: true,
});

const stoppingConditionsSelector = isTestingEnv() ?
  stoppingConditionsSelectorTesting : stoppingConditionsSelectorRpc;

const updateStopConds = (
  stopConds: feoutputpb.StoppingConditions,
  outputNodes: feoutputpb.OutputNodes,
): feoutputpb.StoppingConditions => {
  const newConds = stopConds.clone();
  // Remove stopping condition if the corresponding output node cannot be
  // found anymore (e.g. if it was deleted or never assigned)
  newConds.cond = newConds.cond.filter(
    (cond) => outputNodes.nodes.find(
      (output) => !!cond.node && output.id === cond.node!.id,
    ),
  );
  const nodes = outputNodes.nodes;
  // Update the node stored in the condition
  newConds.cond.forEach((cond) => {
    const output = nodes.find((node) => node.id === cond.node!.id)!;
    cond.node = output.clone();
  });
  return newConds;
};

// Represents the atom state for stopping conditions. Performs upgrades upon reading
// data and sets default values
export const stoppingConditionsState = atomFamily<
  feoutputpb.StoppingConditions,
  persist.RecoilProjectKey
>({
  key: 'stoppingConditions',
  default: selectorFamily<feoutputpb.StoppingConditions, persist.RecoilProjectKey>({
    key: 'stoppingConditions/Default',
    get: (key: persist.RecoilProjectKey) => async ({ get }) => {
      // Fetch all pieces of dependent state in parallel.
      const [rawStopConds, config, outputNodes] = get(waitForAll([
        stoppingConditionsSelector(key),
        currentConfigSelector(key),
        outputNodesState(key),
      ]));
      // Upgrade the stopping conditions from the kvstore if need be.
      const stopConds = upgradeStopConds(rawStopConds, outputNodes);
      // If there are no stopping conditions stored, use the default ones.
      return stopConds || defaultStopConds(config);
    },
    dangerouslyAllowMutability: true,
  }),
  effects: (key: persist.RecoilProjectKey) => [
    syncProjectStateEffect(
      key.projectId,
      persist.getProjectStateKey(stoppingConditionsPrefix, key),
      (val) => feoutputpb.StoppingConditions.fromBinary(val),
      serialize,
    ),
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

// stoppingConditionsSelectorUpdate is the user-facing recoil state for the stopping conditions
// that performs updates depending on other states
export const stoppingConditionsSelectorUpdate = selectorFamily<
  feoutputpb.StoppingConditions, persist.RecoilProjectKey
>({
  key: 'stoppingConditionsSelectorUpdate',
  get: (key: persist.RecoilProjectKey) => async ({ get }) => {
    // Fetch all pieces of dependent state in parallel.
    const [rawStopConds, outputNodes] = get(waitForAll([
      stoppingConditionsState(key),
      outputNodesState(key),
    ]));
    // Remove those stopping conditions that depend on a deleted output node.
    return updateStopConds(rawStopConds, outputNodes);
  },
  set: (key: persist.RecoilProjectKey) => ({ set }, newValue) => {
    set(stoppingConditionsState(key), newValue);
  },
  dangerouslyAllowMutability: true,
});

export const useStoppingConditions = (projectId: string, workflowId: string, jobId: string) => (
  useRecoilState(stoppingConditionsSelectorUpdate({ projectId, workflowId, jobId }))
);

export const useSetStoppingConditions = (projectId: string, workflowId: string, jobId: string) => (
  useSetRecoilState(stoppingConditionsSelectorUpdate({ projectId, workflowId, jobId }))
);
