// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
// helper functions and objects for key bindings (keyboard shortcuts).
import { ARROW_LEFT_KEY, ARROW_RIGHT_KEY, AnyKeyboardEvent, keyboardEventsEqual } from './event';

const makeEvent = (key: KeyboardEventInit) => new KeyboardEvent('keydown', key);

type bindingDetailsType = {
  // the key combinations that define the shortcut. It's a list so we can potentially have
  // multiple key events that trigger the same shortcut action.
  events: KeyboardEventInit[],
  // text shown on TipWithBinding to describe the shortcut (seen by the user).
  helpText: string,
  // accessibility text to describe shortcut in aria-keyshortcuts.
  aria: string
}

// List of all shortcuts we're supporting. When you add a shortcut, add
// it here and its info in keyBindingsMap.
export const supportedShortcutsList = [
  'group',
  'ungroup',
  'exitOverlay',
  'zoomIn',
  'zoomOut',
  'resetCamera',
  'zoomSelection',
  'panRight',
  'panLeft',
  'panUp',
  'panDown',
  'rotateRight',
  'rotateLeft',
  'rotateUp',
  'rotateDown',
  'rotateClockwise',
  'rotateAntiClockwise',
] as const;

// supportedShortcuts is a union type of all shortcuts defined in supportedShortcutsList
type supportedShortcuts = typeof supportedShortcutsList[number]

type keyBindingsMapType = {
  [shortcutName in supportedShortcuts]: bindingDetailsType
}

// Map of all supported keyboard shortcuts.
export const keyBindingsMap: keyBindingsMapType = {
  group: {
    events: [{ key: 'g' }],
    helpText: 'G',
    aria: 'g',
  },
  ungroup: {
    events: [{ key: 'G', shiftKey: true }],
    helpText: '⇧G',
    aria: 'Shift+G',
  },
  exitOverlay: {
    events: [{ key: 'Escape' }],
    helpText: 'Esc',
    aria: 'Escape',
  },
  zoomIn: {
    // + key (with or without shift pressed) should zoom in
    events: [{ key: '+', shiftKey: true }, { key: '=' }],
    helpText: '+',
    aria: '+',
  },
  zoomOut: {
    events: [{ key: '-' }, { key: '_', shiftKey: true }],
    helpText: '-',
    aria: '-',
  },
  resetCamera: {
    events: [{ key: 'f' }],
    helpText: 'F',
    aria: 'f',
  },
  zoomSelection: {
    events: [{ key: 'F', shiftKey: true }],
    helpText: '⇧F',
    aria: 'Shift+F',
  },
  panRight: {
    events: [{ key: 'D', shiftKey: true }],
    helpText: '⇧D',
    aria: 'Shift+D',
  },
  panLeft: {
    events: [{ key: 'A', shiftKey: true }],
    helpText: '⇧A',
    aria: 'Shift+A',
  },
  panUp: {
    events: [{ key: 'W', shiftKey: true }],
    helpText: '⇧W',
    aria: 'Shift+W',
  },
  panDown: {
    events: [{ key: 'S', shiftKey: true }],
    helpText: '⇧S',
    aria: 'Shift+S',
  },
  rotateRight: {
    events: [{ key: 'd' }],
    helpText: 'D',
    aria: 'd',
  },
  rotateLeft: {
    events: [{ key: 'a' }],
    helpText: 'A',
    aria: 'a',
  },
  rotateUp: {
    events: [{ key: 'w' }],
    helpText: 'W',
    aria: 'w',
  },
  rotateDown: {
    events: [{ key: 's' }],
    helpText: 'S',
    aria: 's',
  },
  rotateClockwise: {
    events: [{ key: ARROW_RIGHT_KEY, shiftKey: true }],
    helpText: '⇧→',
    aria: '',
  },
  rotateAntiClockwise: {
    events: [{ key: ARROW_LEFT_KEY, shiftKey: true }],
    helpText: '⇧←',
    aria: '',
  },
};

// get all keyboard events that trigger the given shortcut name.
export const getEventsByName = (
  shortcutName: supportedShortcuts,
) => keyBindingsMap[shortcutName].events.map((eventInit) => makeEvent(eventInit));

// get the aria label to put in aria-keyshortcuts.
export const getAria = (shortcutName: supportedShortcuts) => keyBindingsMap[shortcutName].aria;

/**
 * @returns the readable shortcut binding to show in the UI for the given shortcut.
 * e.g. '⇧G' for 'ungroup'.
 */
export const getHelpText = (
  shortcutName: supportedShortcuts,
) => keyBindingsMap[shortcutName].helpText;

// check if event has a supported shortcut binding.
export const isEventTrigger = (
  eventName: supportedShortcuts,
  event: AnyKeyboardEvent,
) => getEventsByName(eventName).some(
  (supportedEvent) => keyboardEventsEqual(supportedEvent, event),
);
