// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import * as ProtoDescriptor from '../../../../ProtoDescriptor';
import { collateGpuTypes, getGPUType, pickNearestValue, updateGpuPref } from '../../../../lib/cloudSettingUtils';
import * as clusterconfigpb from '../../../../proto/clusterconfig/clusterconfig_pb';
import { QuantityType } from '../../../../proto/quantity/quantity_pb';
import useServerInfo from '../../../../recoil/useServerInfo';
import { useCurrentConfig } from '../../../../recoil/workflowConfig';
import ParamRow from '../../../ParamRow';
import { useProjectContext } from '../../../context/ProjectContext';

export const CloudSettings = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly, onNewWorkflowConfig } = useProjectContext();

  // == Recoil
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const serverInfo = useServerInfo();
  if (!serverInfo) {
    return <div>... server info not yet loaded</div>;
  }

  // == Data
  const allGpuTypes = collateGpuTypes(serverInfo.clusterConfig!.nodePools);
  const jobConfig = config.jobConfigTemplate;
  if (!jobConfig) {
    throw Error(`Job config missing in ${JSON.stringify(config)}`);
  }

  // If the user has explicitly chosen a GPU type, show it in the config.
  //
  // Otherwise, jobConfig.gpu_pref contains a list of possible GPUs that the job can chose from. We
  // just show "Auto" in the screen. The JobList display will show the actual GPU chosen.
  const gpuPrefs = jobConfig.gpuPref;
  const singleGpu = gpuPrefs.length === 1;
  const gpuType = singleGpu ? getGPUType(allGpuTypes, gpuPrefs[0].gpuType) : allGpuTypes[0];
  const nPu = singleGpu ? gpuPrefs[0].nPu : 0;

  const paramGpuType: ProtoDescriptor.Param = {
    camelCaseName: 'gpu',
    pascalCaseName: 'Gpu',
    choices:
      allGpuTypes.map((type) => ({
        enumNumber: type.gpuType,
        help: type.tooltip,
        name: type.text,
        text: type.text,
      })),
    parentGroups: [],
    quantityType: QuantityType.INVALID_QUANTITY_TYPE,
    help: 'Type of GPUs or GPUs to use',
    name: 'GPU',
    text: 'GPU',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  };
  const paramNumGpus: ProtoDescriptor.Param = {
    camelCaseName: 'gpuCount',
    pascalCaseName: 'GpuCount',
    choices: gpuType.puCount.map((i) => {
      const text = `${i}`;
      return {
        enumNumber: i,
        help: '',
        name: text,
        text,
      };
    }),
    parentGroups: [],
    quantityType: QuantityType.INVALID_QUANTITY_TYPE,
    help: '',
    name: 'Count',
    text: 'Count',
    type: ProtoDescriptor.ParamType.MULTIPLE_CHOICE,
  };

  return (
    <>
      <ParamRow
        nestLevel={0}
        // The CPU option causes huge credit consumption and is disabled (LC-11199). It is disabled
        // rather than being removed, to avoid a crash if we open an old project with the CPU type
        // selected.
        options={{ disableEnums: [clusterconfigpb.GPUType.CPU] }}
        param={paramGpuType}
        readOnly={readOnly}
        setValue={(newType: clusterconfigpb.GPUType) => onNewWorkflowConfig(
          updateGpuPref(config, newType, getGPUType(allGpuTypes, newType).puCount[0]),
        )}
        value={gpuType.gpuType}
      />
      {(gpuType.puCount.length > 0) && (
        <>
          <ParamRow
            nestLevel={0}
            param={paramNumGpus}
            readOnly={readOnly}
            setValue={(value: number) => onNewWorkflowConfig(updateGpuPref(
              config,
              gpuType.gpuType,
              value,
            ))}
            value={pickNearestValue(nPu, gpuType.puCount)}
          />
        </>
      )}
    </>
  );
};
