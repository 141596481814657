// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { useCommonUserTableStyles } from '../../components/Theme/commonStyles';
import { Table } from '../../components/data/Table';
import { MainPageLayout } from '../../components/layout/page/Main';
import { Emphasis } from '../../components/visual/Emphasis';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { ColumnConfig, RowConfig } from '../../lib/componentTypes/table';
import { Logger } from '../../lib/observability/logs';
import { PlatformAdminRoleType } from '../../lib/platformAdminUtils';
import * as rpc from '../../lib/rpc';
import * as status from '../../lib/status';
import { getUserName } from '../../lib/user';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as supportpb from '../../proto/support/support_pb';
import useAccountInfo from '../../recoil/useAccountInfo';
import { useEnabledPlatformAdminRoles } from '../../recoil/usePersonalPlatformAdmin';
import { useListSupportUsers } from '../../state/external/platform/supportUsers';
import { useIsStaff } from '../../state/external/user/frontendRole';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';

const logger = new Logger('platform/AdminSupportBody');

const columnConfigs: ColumnConfig[] = [
  { id: 'firstName', label: 'First Name', type: 'string' },
  { id: 'lastName', label: 'Last Name', type: 'string' },
  { id: 'email', label: 'Email', type: 'string' },
  {
    id: 'support_status',
    label: 'Support',
    type: 'string',
    displayOptions: { textTransform: 'upper', minimalWidth: true, emptyDisplay: '' },
    disableVisibility: true,
  },
];

type User = frontendpb.AccountInfoReply_UserInfo;

const AdminsTable = () => {
  const accountInfo = useAccountInfo();
  const users = (accountInfo?.user || []);
  const supportUsers = useListSupportUsers();

  const classes = useCommonUserTableStyles();
  const setConfirmStack = useSetConfirmations();

  async function handleModifySupportUser(supportUser: User, add: boolean) {
    const req = new supportpb.UpdateSupportUserRequest({
      userId: supportUser.lcUserId,
      supportUser: add,
    });
    await rpc.callRetry('UpdateSupportUser', rpc.client.updateSupportUser, req).then(() => {
      window.location.reload();
    }).catch((error) => {
      logger.info(`UpdateSupportUser error: ${status.stringifyError(error)}`);
    });
  }

  const queueAddSupportUser = (user: frontendpb.AccountInfoReply_UserInfo) => {
    const names = getUserName(user);
    pushConfirmation(setConfirmStack, {
      onContinue: () => handleModifySupportUser(user, true),
      title: 'Add Support User',
      children: (
        <div className={classes.confirmationContent}>
          Are you sure you want to add <Emphasis content={names.full} /> to the support team?
          This will grant <Emphasis content={names.given} /> all customer shared with support
          projects in this environment, if they have the project link.
        </div>
      ),
    });
  };
  const queueRemoveSupportUser = (user: frontendpb.AccountInfoReply_UserInfo) => {
    const names = getUserName(user);
    pushConfirmation(setConfirmStack, {
      destructive: true,
      onContinue: () => handleModifySupportUser(user, false),
      title: 'Remove Support User',
      children: (
        <div className={classes.confirmationContent}>
          Are you sure you want to delete <Emphasis content={names.full} />? This action cannot
          be undone, and support projects owned by <Emphasis content={names.given} /> will be
          reowned to you and you must be a member of suport team.
        </div>
      ),
    });
  };

  const adminRowData: RowConfig[] = users.map((user: frontendpb.AccountInfoReply_UserInfo) => {
    const supportUser = supportUsers?.includes(user.lcUserId);

    const menuItems: CommonMenuItem[] = [];
    if (supportUser) {
      menuItems.push(
        {
          destructive: true,
          label: 'Remove From Support Team',
          onClick: () => queueRemoveSupportUser(user),
        },
      );
    } else {
      menuItems.push(
        {
          label: 'Add To Support Team',
          onClick: () => queueAddSupportUser(user),
        },
      );
    }

    const supportIconsDisplay: SvgIconSpec[] = [];
    supportIconsDisplay.push({ name: 'headphones', title: 'Member of support team' });

    return {
      id: user.lcUserId,
      values: {
        firstName: user.givenName,
        lastName: user.familyName,
        email: user.email,
        support_status: supportUser ? 'support' : '',
      },
      cellDisplay: {
        support_status: [{ type: 'circleIcons', icons: supportIconsDisplay }],
      },
      menuItems,
    };
  });

  return (
    <Table
      asBlock
      columnConfigs={columnConfigs}
      defaultSort={{ columnId: 'support_status', descending: true }}
      name="support-table"
      pagination={{ availablePageSizes: [25, 50, 100] }}
      rowConfigs={adminRowData}
    />
  );
};

const PlatformAdminSupportPageBody = () => {
  const platformAdminRoles = useEnabledPlatformAdminRoles();
  const isStaff = useIsStaff();

  return (
    <MainPageLayout
      permission={(
        !!platformAdminRoles?.includes(PlatformAdminRoleType.SUPPORT) &&
        isStaff
      )}
      title="Support Team Administration">
      <AdminsTable />
    </MainPageLayout>
  );
};

export default PlatformAdminSupportPageBody;
