// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useRef } from 'react';

import cx from 'classnames';

import ColorMaps from '../../assets/color_maps.json';
import { RadioButtonOption, SelectOption } from '../../lib/componentTypes/form';
import { colors } from '../../lib/designSystem';
import { preventDefault } from '../../lib/event';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { Logger } from '../../lib/observability/logs';
import { addError, addRpcError, addWarning } from '../../lib/transientNotification';
import useResizeObserver from '../../lib/useResizeObserver';
import { displayVariableToTextFromData } from '../../lib/visUtils';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useUpdateLcvisColorMap, useViewStateOverflow } from '../../recoil/lcvis/viewStateOverflow';
import { ActionButton } from '../Button/ActionButton';
import Form from '../Form';
import { DataSelect } from '../Form/DataSelect';
import { NumberInput } from '../Form/NumberInput';
import { RadioButtonGroup } from '../Form/RadioButtonGroup';
import { NumberSpinner } from '../NumberSpinner';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { useProjectContext } from '../context/ProjectContext';
import { RangeSlider } from '../controls/slider/RangeSlider';
import { Dialog } from '../dialog/Base';

import ColorBar from './ColorBar';
import { useParaviewContext } from './ParaviewManager';

const logger = new Logger('Paraview/EditColorsBox');

// This dialog's overall width
const DIALOG_WIDTH = 450;
// Lateral padding on color bar/sliders area (room for labels and slider thumb overhang)
const COLOR_INPUT_LATERAL_PADDING = 8;

const useStyles = makeStyles(
  () => createStyles({
    formContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '8px',
    },
    formGroup: {
      gap: '16px',
    },
    minimalButtonGroup: {
      display: 'flex',
      gap: '4px',
    },
    inputRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      opacity: 1,
      transition: 'opacity 250ms',
      fontSize: '13px',
      '&.hidden': {
        opacity: 0,
      },
    },
    inputLabel: {
      flex: '0 0 auto',
      width: '33%',
      color: colors.lowEmphasisText,
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: 1,
      margin: 0,
    },
    inputContainer: {
      minWidth: '170px',
    },
    subRow: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '8px',
    },
    subRowLabel: {
      color: colors.highEmphasisText,
    },
    subRowInput: {
      flex: '0 0 auto',
      width: '80px',
    },
    minMaxLabel: {
      fontSize: '13px',
      marginRight: '8px',
    },
    minMaxInput: {
      fontSize: '13px',
      width: '68px',
    },
    colorBarInput: {
      paddingTop: '24px',
      display: 'flex',
      flexDirection: 'column',
      padding: `0 ${COLOR_INPUT_LATERAL_PADDING}px`,
      alignItems: 'stretch',
    },
    colorBarControl: {
    },
  }),
  { name: 'EditColorsBox' },
);

// The possible values for the type of range.
export enum RangeType {
  GLOBAL = 0,
  VISIBLE = 1,
}

// The possible values for the type of colormap discretization.
export enum DiscretizationType {
  CONTINUOUS = 0,
  DISCRETE = 1,
}

export enum OrientationType {
  HORIZONTAL = 0,
  VERTICAL = 1,
}

// The maximum and minimum number of discrete levels for color maps.
const MAX_BINS = 256;
const MIN_BINS = 1;

// The maximum number of ticks and labels used for a colorbar
const MAX_TICKS = 9;

// A description of a multiple choice parameter for the colormap discretization types.
const discretizationTypeOptions: RadioButtonOption<DiscretizationType>[] = [
  {
    value: DiscretizationType.CONTINUOUS,
    help: 'The default smooth colormap',
    label: 'Continous',
  },
  {
    value: DiscretizationType.DISCRETE,
    help: 'Specify the number of levels to discretize the colormap',
    label: 'Discrete',
  },
];

const orientationTypeOptions: RadioButtonOption<OrientationType>[] = [
  {
    value: OrientationType.HORIZONTAL,
    label: 'Horizontal',
  },
  {
    value: OrientationType.VERTICAL,
    label: 'Vertical',
  },
];

interface EditColorsBoxProps {
  // The name of the data type we are editing.
  displayVariable: ParaviewRpc.DisplayPvVariable;
  // Called when the dialog's continue button is clicked.
  onComplete: () => void;
  // Indicates dialog is being dragged
  isDragging?: boolean;
  // Optional handler for mousedown event on root node
  onMouseDown?: (event: React.MouseEvent) => void;

  // The state of the current colormap
  currentCmap: ParaviewRpc.ColorMap,
  setCurrentCmap: (currentCmap: ParaviewRpc.ColorMap) => void;

  cancelEdit: () => void;

  // The orientation of colorbar panel
  changeOrientation: (isVertical: boolean) => void;
  isVertical: boolean;
}

// A dialog for editing the range of color values. Shows a color bar and a set
// of buttons and sliders for modifying the values.
const EditColorsBox = (props: EditColorsBoxProps) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const [lcvisData] = useViewStateOverflow({ projectId, workflowId, jobId });
  const updateLcvisColorMap = useUpdateLcvisColorMap();

  const {
    updateColorMap: updateColorMapPv,
    displayVariableToText,
    paraviewRenderer,
    getGlobalScalarDataRange: getGlobalScalarDataRangePv,
  } = useParaviewContext();

  const getGlobalScalarDataRange = useCallback((
    displayVariable: ParaviewRpc.DisplayPvVariable,
    fieldType: ParaviewRpc.FieldAssociation,
  ) => {
    if (lcvisEnabled) {
      const range = lcvisData.data.find((item) => item.name === displayVariable.displayDataName &&
        item.type === fieldType)?.range[displayVariable.displayDataNameComponent];
      return range || null;
    }
    return getGlobalScalarDataRangePv(displayVariable, fieldType);
  }, [lcvisData, lcvisEnabled, getGlobalScalarDataRangePv]);

  const updateColorMap = lcvisEnabled ? updateLcvisColorMap : updateColorMapPv;

  const classes = useStyles();
  const formContentRef = useRef<HTMLDivElement>(null);
  const formContentSize = useResizeObserver(formContentRef);

  const setRangeLimit = (newType: RangeType) => {
    switch (newType) {
      case RangeType.GLOBAL: {
        let newRange: [number, number] = [0, 0];
        const newRangeCell: [number, number] | null = getGlobalScalarDataRange(
          props.displayVariable,
          ParaviewRpc.FieldAssociation.CELL,
        );
        if (!newRangeCell) {
          // We didn't find the range in the cell data so check the point
          // data too.
          const newRangePoint: [number, number] | null = getGlobalScalarDataRange(
            props.displayVariable,
            ParaviewRpc.FieldAssociation.POINT,
          );
          if (newRangePoint) {
            newRange = newRangePoint;
          } else {
            logger.error('Could not find global scalar range for ', props.displayVariable);
          }
        } else {
          newRange = newRangeCell;
        }
        const newCmap: ParaviewRpc.ColorMap = {
          ...props.currentCmap,
          range: newRange,
          globalRange: newRange,
        };
        updateColorMap(props.displayVariable, newCmap);
        props.setCurrentCmap(newCmap);
        break;
      }
      case RangeType.VISIBLE: {
        if (lcvisEnabled) {
          addWarning('Compute Visible Range: This feature is not yet supported.');
          return;
        }
        paraviewRenderer.getScreenVisibleDataRange(props.displayVariable)
          .then((visibleRange: [number, number] | null) => {
            if (visibleRange) {
              logger.debug(`Visible range : ${visibleRange[0]} to ${visibleRange[1]}`);
              const newCmap: ParaviewRpc.ColorMap = {
                ...props.currentCmap,
                range: visibleRange,
                globalRange: visibleRange,
              };
              updateColorMap(props.displayVariable, newCmap);
              props.setCurrentCmap(newCmap);
            } else {
              addError('Compute Visible Range Failed: Unable to compute visible range. ' +
                'Please adjust color bar extents manually.');
            }
          }).catch((err: Error) => {
            addRpcError('Could not get the screen visible data range', err);
          });
        break;
      }
      default:
        throw Error('Range type not defined.');
    }
  };

  const updateMinOrMax = (
    newMin: number,
    rangeComponent: 'min' | 'max',
    propagate: boolean,
  ) => {
    const newRange: [number, number] = [...props.currentCmap.range];
    const newRangeLimit: [number, number] = [...props.currentCmap.globalRange];
    const index = rangeComponent === 'min' ? 0 : 1;
    // Keep property that min <= max.
    newRangeLimit[index] = index === 0 ?
      Math.min(newMin, newRangeLimit[1]) :
      Math.max(newMin, newRangeLimit[0]);
    // Make the active "min" range the same as the "min" limit that was set from the input
    newRange[index] = newRangeLimit[index];
    const newCmap: ParaviewRpc.ColorMap = {
      ...props.currentCmap,
      range: newRange,
      globalRange: newRangeLimit,
    };

    updateColorMap(props.displayVariable, newCmap);

    if (propagate) {
      props.setCurrentCmap(newCmap);
    }
  };

  const rangeNumberInputs = (
    <div className={classes.inputRow}>
      <div>
        <span className={classes.minMaxLabel}>Min</span>
        <div className={classes.minMaxInput}>
          <NumberInput
            disabled={props.isDragging}
            onCommit={(newMin) => {
              updateMinOrMax(newMin, 'min', true);
            }}
            onEnter={preventDefault}
            readOnly={props.isDragging}
            size="small"
            value={props.currentCmap.globalRange[0]}
          />
        </div>
      </div>
      <div>
        <span className={classes.minMaxLabel}>Max</span>
        <div className={classes.minMaxInput}>
          <NumberInput
            disabled={props.isDragging}
            onCommit={(newMax) => {
              updateMinOrMax(newMax, 'max', true);
            }}
            onEnter={preventDefault}
            readOnly={props.isDragging}
            size="small"
            value={props.currentCmap.globalRange[1]}
          />
        </div>
      </div>
    </div>
  );

  const colorMapType = (
    <div className={classes.inputRow}>
      <span className={classes.inputLabel} />
      <div className={classes.minimalButtonGroup}>
        <ActionButton
          disabled={props.isDragging}
          kind="minimal"
          onClick={() => {
            setRangeLimit(RangeType.GLOBAL);
          }}
          size="small"
          startIcon={{ name: 'globe' }}>
          Global
        </ActionButton>
        <ActionButton
          disabled={lcvisEnabled || props.isDragging}
          kind="minimal"
          onClick={() => {
            setRangeLimit(RangeType.VISIBLE);
          }}
          size="small"
          startIcon={{ name: 'eyeCheck' }}
          title={lcvisEnabled ? 'Coming soon, currently requires server-side rendering' : ''}>
          Visible
        </ActionButton>
      </div>
    </div>
  );

  const colorMapNames = Object.keys(ColorMaps);

  const colorMapOptions: SelectOption<string>[] = colorMapNames.map((name) => ({
    name,
    value: name,
    selected: name === props.currentCmap.presetName,
  }));

  const colorMapSelection = (
    <div className={classes.inputRow}>
      <span className={classes.inputLabel}>Colormap</span>
      <div className={classes.inputContainer}>
        <DataSelect
          asBlock
          disabled={props.isDragging}
          onChange={(colorMapName) => {
            if (colorMapName) {
              const newCmap: ParaviewRpc.ColorMap = {
                ...props.currentCmap,
                presetName: colorMapName,
              };
              updateColorMap(props.displayVariable, newCmap);
              props.setCurrentCmap(newCmap);
            }
          }}
          options={colorMapOptions}
          size="small"
        />
      </div>
    </div>
  );

  const discretizationRadioButtons = (
    <div className={classes.inputRow}>
      <Tooltip title="Select the type of colormap">
        <span className={classes.inputLabel}>Set range to</span>
      </Tooltip>
      <RadioButtonGroup
        disabled={props.isDragging}
        kind="secondary"
        name="colorBarDiscretizationType"
        onChange={(value: number) => {
          const newType = value as DiscretizationType;
          const newCmap: ParaviewRpc.ColorMap = {
            ...props.currentCmap,
            discretize: newType === DiscretizationType.DISCRETE,
          };
          updateColorMap(props.displayVariable, newCmap);
          props.setCurrentCmap(newCmap);
        }}
        options={discretizationTypeOptions}
        value={props.currentCmap.discretize ?
          DiscretizationType.DISCRETE :
          DiscretizationType.CONTINUOUS}
      />
    </div>
  );

  const orientationRadioButtons = (
    <div className={classes.inputRow}>
      <span className={classes.inputLabel}>Orientation</span>
      <RadioButtonGroup
        disabled={props.isDragging}
        kind="secondary"
        name="orientationType"
        onChange={(value: number) => {
          const isVertical = (value as OrientationType) === OrientationType.VERTICAL;
          props.changeOrientation(isVertical);
        }}
        options={orientationTypeOptions}
        value={props.isVertical ? OrientationType.VERTICAL : OrientationType.HORIZONTAL}
      />
    </div>
  );

  const updateCmapBins = (newBins: number, commit: boolean) => {
    let inputBins = Math.ceil(newBins);
    if (newBins < MIN_BINS) {
      inputBins = MIN_BINS;
      addWarning('Invalid input. Setting number of lookup table values to 1.');
    } else if (newBins > MAX_BINS) {
      inputBins = MAX_BINS;
      addWarning('Invalid input. Setting number of lookup table values to 256');
    }
    const newCmap: ParaviewRpc.ColorMap = {
      ...props.currentCmap,
      bins: inputBins,
    };

    props.setCurrentCmap(newCmap);
    if (commit) {
      updateColorMap(props.displayVariable, newCmap);
    }
  };

  const numberOfLevels = (
    <div className={cx(classes.inputRow, { hidden: !props.currentCmap.discretize })}>
      <span className={classes.inputLabel} />
      <div className={classes.inputContainer}>
        <div className={classes.subRow}>
          <div className={classes.subRowLabel}>Bins</div>
          <div className={classes.subRowInput}>
            <NumberSpinner
              disabled={!props.currentCmap.discretize || props.isDragging}
              maximumValue={MAX_BINS}
              minimumValue={MIN_BINS}
              onChange={(newBins: number) => updateCmapBins(newBins, false)}
              onCommit={(newBins: number) => updateCmapBins(newBins, true)}
              value={props.currentCmap.bins}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const stepSize = (props.currentCmap.globalRange[1] - props.currentCmap.globalRange[0]) / 360;

  const updateCmapSlider = (newRange: [number, number]): ParaviewRpc.ColorMap => {
    // Limit the precision of numbers derived from the slider.
    let significantDigits = Math.floor(Math.log10(props.currentCmap.globalRange[1]));
    if (stepSize > 0) {
      significantDigits -= Math.floor(Math.log10(stepSize));
    }
    let precision = Math.max(significantDigits, 4);
    // significantDigits can be NaN - toPrecision() accepts values in [1, 100].
    if (Number.isNaN(precision)) {
      precision = 4;
    }
    newRange[0] = parseFloat(newRange[0].toPrecision(precision));
    newRange[1] = parseFloat(newRange[1].toPrecision(precision));
    const newCmap: ParaviewRpc.ColorMap = {
      ...props.currentCmap,
      range: newRange,
    };
    if (lcvisEnabled) {
      lcvHandler.queueDisplayFunction('updateColorMap', (display) => {
        if (props.displayVariable.displayDataName === 'None') {
          return;
        }
        display.workspace?.updateColorMap(
          props.displayVariable,
          newCmap,
        );
      });
    }
    props.setCurrentCmap(newCmap);
    return newCmap;
  };

  const sliders = (
    <div className={classes.colorBarControl}>
      <RangeSlider
        disabled={props.isDragging}
        gutterHeight={0}
        max={props.currentCmap.globalRange[1]}
        min={props.currentCmap.globalRange[0]}
        onChange={updateCmapSlider}
        onCommit={(newValue) => {
          const newCmap = updateCmapSlider(newValue);
          updateColorMap(props.displayVariable, newCmap);
        }}
        readoutConfig={{ offset: 16 }}
        thumbOffset={-10}
        value={props.currentCmap.range}
      />
    </div>
  );

  const title = lcvisEnabled ?
    `Adjust Color Bar - ${displayVariableToTextFromData(props.displayVariable, lcvisData.data)}` :
    `Adjust Color Bar - ${displayVariableToText(props.displayVariable)}`;

  return (
    <Dialog
      cancelButton={{ disabled: props.isDragging, label: 'Cancel' }}
      continueButton={{ disabled: props.isDragging, label: 'Done' }}
      draggable={!!props.onMouseDown}
      dragging={props.isDragging}
      onClose={props.cancelEdit}
      onContinue={props.onComplete}
      onFrameMousedown={props.onMouseDown}
      open
      title={title}
      width={`${DIALOG_WIDTH}px`}>
      <div className={classes.formContent} ref={formContentRef}>
        <div>
          {rangeNumberInputs}
          <div className={classes.colorBarInput}>
            <ColorBar
              bins={props.currentCmap.discretize ? props.currentCmap.bins : MAX_BINS}
              colorBounds={props.currentCmap.range}
              colorMapName={props.currentCmap.presetName}
              highlighted={false}
              isVertical={false}
              length={formContentSize.width - (2 * COLOR_INPUT_LATERAL_PADDING)}
              maxValue={props.currentCmap.globalRange[1]}
              minValue={props.currentCmap.globalRange[0]}
              numTicks={MAX_TICKS}
            />
            {sliders}
          </div>
        </div>
        <Form.Group className={classes.formGroup}>
          {colorMapType}
          {colorMapSelection}
          {orientationRadioButtons}
          {discretizationRadioButtons}
          {numberOfLevels}
        </Form.Group>
      </div>
    </Dialog>
  );
};

export default EditColorsBox;
