// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { colors } from '../../lib/designSystem';

import { ColoredCircle } from './ColoredCircle';

// Returns a simple numeric hash by summing all charcodes for a particular string
function simpleNumericHash(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash += str.charCodeAt(i);
  }
  return hash;
}

const bgColorsPool = [
  colors.violet800,
  colors.citronGreen800,
  colors.pink800,
  colors.teal800,
  colors.purpleRed800,
  colors.cyan800,
  colors.orange800,
  colors.yellow800,
  colors.purple800,
  colors.blue800,
];

const textColorsPool = [
  colors.violet50,
  colors.citronGreen50,
  colors.pink50,
  colors.teal50,
  colors.purpleRed50,
  colors.cyan50,
  colors.orange50,
  colors.yellow50,
  colors.purple50,
  colors.blue50,
];

if (bgColorsPool.length !== textColorsPool.length) {
  throw Error('bgColorsPool and textColorsPool should have the same length');
}

export interface ColoredAvatarProps {
  active?: boolean;
  name: string;
}

// This component determines a color palette that will be used for the passed `name` prop and then
// uses the ColoredCircle in the background for the rendering of the shape.
export const ColoredAvatar = (props: ColoredAvatarProps) => {
  const { active, name } = props;

  const colorIndex = useMemo(
    () => simpleNumericHash(name) % bgColorsPool.length,
    [name],
  );

  return (
    <ColoredCircle
      active={active}
      palette={[bgColorsPool[colorIndex], bgColorsPool[colorIndex], textColorsPool[colorIndex]]}>
      {name.substring(0, 1).toLocaleUpperCase()}
    </ColoredCircle>
  );
};
