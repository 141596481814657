// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { Vector3 } from '../../../../ProtoDescriptor';
import { EMPTY_VALUE } from '../../../../lib/constants';
import { formatNumber } from '../../../../lib/number';
import { useIsStaff } from '../../../../state/external/user/frontendRole';
import { createStyles, makeStyles } from '../../../Theme';
import Tooltip from '../../../Tooltip';

const useStyles = makeStyles(
  () => createStyles({
    label: {
      color: '#8e8e8f',
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    stat: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    row: {
      height: '30px',
      padding: '5px 0',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '8px',
      alignItems: 'baseline',
      overflow: 'hidden',
    },
    panel: {
      fontSize: '13px',
    },
  }),
  { name: 'MeshOrSurfacePanel' },
);

interface MeshRowProps {
  // A label describing the statistic.
  label: string;
  // The statistic.
  stat: string;
  // If active, adds a tooltip that will display the value
  tooltip?: boolean;
}

// A row display a single mesh statistic.
const MeshRow = (props: MeshRowProps) => {
  const classes = useStyles();
  if (props.stat === '0') {
    return null;
  }
  return (
    <div className={classes.row}>
      <span className={classes.label}>{props.label}</span>
      {props?.tooltip ? (
        <Tooltip title={props.stat}>
          <span className={classes.stat}>{props.stat}</span>
        </Tooltip>
      ) : <span className={classes.stat}>{props.stat}</span>}
    </div>
  );
};

interface MeshOrSurfacePanelProps {
  // The name of the mesh.
  fileName: string;
  // Bucket URL of the mesh being displayed.
  meshUrlOnlyStaff?: string
  // Minimum coordinate of all points for each spatial dimension.
  maxBoundingBox?: Vector3;
  // Maximum coordinate of all points for each spatial dimension.
  minBoundingBox?: Vector3;
  // The number of control volumes, points, faces, etc. in the mesh or surface.
  numCvs?: number;
  numPoints?: number;
  numFaces?: number;
  numEdges?: number;
  numBodies?: number;
  id?: string;
  // The name of the sizing strategy option used to generate the mesh.
  sizingStrategy?: string;
  strategyCellCount?: number;
}

// A panel for displaying information about a mesh or one surface of the mesh.
export const MeshOrSurfacePanel = (props: MeshOrSurfacePanelProps) => {
  const classes = useStyles();

  // Mesh bounding box options and variables.
  const hasBoundingBox = props.minBoundingBox && props.maxBoundingBox;
  // TODO(gonzalo): unit system may be defined by the users in the future, do not use a hardcoded
  // unit label for the mesh bounding box.
  const unitsBoundingBox = 'm';

  const toString = (num?: number) => (num || 0).toLocaleString('en-US');
  const staff = useIsStaff();

  return (
    <div className={classes.panel}>
      <MeshRow label="File Name" stat={props.fileName || EMPTY_VALUE} tooltip />
      {(staff && props.meshUrlOnlyStaff) &&
        <MeshRow label="Mesh URL (Staff)" stat={props.meshUrlOnlyStaff} tooltip />}
      {props.sizingStrategy && <MeshRow label="Sizing Strategy" stat={props.sizingStrategy} />}
      {!!props.strategyCellCount &&
        <MeshRow label="Strategy Cell Count" stat={toString(props.strategyCellCount)} />}
      <MeshRow label="Control Volumes" stat={toString(props.numCvs)} />
      <MeshRow label="Points" stat={toString(props.numPoints)} />
      <MeshRow label="Faces" stat={toString(props.numFaces)} />
      <MeshRow label="Edges" stat={toString(props.numEdges)} />
      <MeshRow label="Bodies" stat={toString(props.numBodies)} />
      {hasBoundingBox && (
        <MeshRow
          label="X-Range"
          stat={`${formatNumber(props.minBoundingBox!.x)}${unitsBoundingBox}
             to ${formatNumber(props.maxBoundingBox!.x)}${unitsBoundingBox}`}
        />
      )}
      {hasBoundingBox && (
        <MeshRow
          label="Y-Range"
          stat={`${formatNumber(props.minBoundingBox!.y)}${unitsBoundingBox}
             to ${formatNumber(props.maxBoundingBox!.y)}${unitsBoundingBox}`}
        />
      )}
      {hasBoundingBox && (
        <MeshRow
          label="Z-Range"
          stat={`${formatNumber(props.minBoundingBox!.z)}${unitsBoundingBox}
             to ${formatNumber(props.maxBoundingBox!.z)}${unitsBoundingBox}`}
        />
      )}
      {staff && props.id && <MeshRow label="Id (Staff Only)" stat={props.id} />}
    </div>
  );
};
