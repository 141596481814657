// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const DiskCheckIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="diskCheck"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M12,24
           C5.37,24 0,18.63 0,12
           C0,5.37 5.37,0 12,0
           C18.63,0 24,5.37 24,12
           C24,18.63 18.63,24 12,24Z
           M18,7.5
           C17.58,7.5 17.205,7.665 16.935,7.935
           L10.5,14.385
           L7.065,10.935
           C6.795,10.665 6.42,10.5 6,10.5
           C5.175,10.5 4.5,11.175 4.5,12
           C4.5,12.42 4.665,12.795 4.935,13.065
           L9.435,17.565
           C9.705,17.835 10.08,18 10.5,18
           C10.92,18 11.295,17.835 11.565,17.565
           L19.065,10.065
           C19.335,9.795 19.5,9.42 19.5,9
           C19.5,8.175 18.825,7.5 18,7.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
