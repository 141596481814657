// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 18;
export const EyeOnIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="eyeOn"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M12.004,7.504
           C11.18,7.504 10.506,8.181 10.506,9.008
           C10.506,9.835 11.18,10.512 12.004,10.512
           C12.827,10.512 13.501,9.835 13.501,9.008
           C13.501,8.181 12.827,7.504 12.004,7.504Z
           M23.985,8.963
           L23.985,8.858
           C23.955,8.647 23.865,8.467 23.73,8.316
           C22.967,7.309 22.053,6.421 21.125,5.609
           C19.133,3.85 16.856,2.376 14.34,1.789
           C12.872,1.428 11.42,1.413 9.952,1.699
           C8.619,1.97 7.346,2.511 6.133,3.188
           C4.261,4.256 2.539,5.714 1.041,7.354
           C0.771,7.669 0.502,7.97 0.247,8.301
           C-0.082,8.737 -0.082,9.264 0.247,9.7
           C1.011,10.707 1.924,11.595 2.853,12.407
           C4.845,14.166 7.121,15.64 9.637,16.227
           C11.09,16.573 12.558,16.588 14.026,16.287
           C15.358,16.016 16.632,15.475 17.845,14.798
           C19.717,13.73 21.439,12.271 22.937,10.632
           C23.206,10.331 23.491,10.016 23.745,9.685
           C23.88,9.534 23.97,9.339 24,9.143
           L24,8.993
           C24,8.978 23.985,8.978 23.985,8.963Z
           M12.004,13.52
           C9.518,13.52 7.511,11.504 7.511,9.008
           C7.511,6.511 9.518,4.496 12.004,4.496
           C14.49,4.496 16.497,6.511 16.497,9.008
           C16.497,11.504 14.49,13.52 12.004,13.52Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
