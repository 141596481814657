// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/assistant/assistant.proto (package luminary.proto.assistant, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * a request from user to assist.
 *
 * @generated from message luminary.proto.assistant.Request
 */
export const Request = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.Request",
  () => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cmd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "access", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * responses from assistant to request.
 *
 * @generated from message luminary.proto.assistant.Reply
 */
export const Reply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.Reply",
  () => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "action", kind: "message", T: Action, oneof: "step" },
    { no: 5, name: "response", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "step" },
  ],
);

/**
 * a request by assistant to a user visible action
 *
 * @generated from message luminary.proto.assistant.Action
 */
export const Action = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.Action",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "params", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * a response to a action request
 *
 * @generated from message luminary.proto.assistant.Outcome
 */
export const Outcome = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.Outcome",
  () => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "val", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "result" },
    { no: 4, name: "err", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "result" },
  ],
);

