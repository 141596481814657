// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement } from 'react';

import * as ProtoDescriptor from '../../../../ProtoDescriptor';
import { ParamScope } from '../../../../lib/ParamScope';
import assert from '../../../../lib/assert';
import { AnyBoundaryCondition, getAllBoundaryConditions, isDependentBoundaryCondition } from '../../../../lib/boundaryConditionUtils';
import { SelectOption, SelectOptionGroup } from '../../../../lib/componentTypes/form';
import { IconName } from '../../../../lib/componentTypes/svgIcon';
import {
  adToPrimal,
  explorationVariableNodeId,
  findNameByType,
  getCandidates,
  getDisplayableIntervalValues,
  newExplorationVar,
  primalToAd,
  varSpecToParamState,
} from '../../../../lib/explorationUtils';
import { getAllHeatSources } from '../../../../lib/heatSourceUtils';
import { getMaterialId } from '../../../../lib/materialUtils';
import { getAllPhysicalBehaviors } from '../../../../lib/physicalBehaviorUtils';
import { getPhysicsId } from '../../../../lib/physicsUtils';
import { updateExploration } from '../../../../lib/proto';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import * as explorationpb from '../../../../proto/exploration/exploration_pb';
import { GeometryTags } from '../../../../recoil/geometry/geometryTagsObject';
import { useGeometryTags } from '../../../../recoil/geometry/geometryTagsState';
import { StaticVolume, useStaticVolumes } from '../../../../recoil/volumes';
import { useCurrentConfig } from '../../../../recoil/workflowConfig';
import { useSimulationParam } from '../../../../state/external/project/simulation/param';
import { useSimulationBoundaryNames } from '../../../../state/external/project/simulation/param/boundaryNames';
import { useSimulationParamScope } from '../../../../state/external/project/simulation/paramScope';
import { IconButton } from '../../../Button/IconButton';
import Form from '../../../Form';
import { DataSelect } from '../../../Form/DataSelect';
import { NumberInput } from '../../../Form/NumberInput';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { ParamFieldInput } from '../../../ParamFieldInput';
import ParamRow from '../../../ParamRow';
import { createStyles, makeStyles } from '../../../Theme';
import Tooltip from '../../../Tooltip';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { PlusIcon } from '../../../svg/PlusIcon';
import { TrashIcon } from '../../../svg/TrashIcon';
import PropertiesSection from '../../PropertiesSection';

import { ColumnForm } from './ColumnForm';

type ValueTypCase = explorationpb.Var['valueTyp']['case'];

const { ACTUATOR_DISK } = simulationpb.ParticleGroupType;

const useStyles = makeStyles(
  () => createStyles({
    root: {},
    deletable: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    deleteIcon: {
      flex: '0 0 auto',
    },
    addControls: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '16px 5px 5px',
      cursor: 'pointer',
    },
    valueContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(68px, 1fr))',
      gap: '0 6px',
    },
    valueCell: {
      margin: 0,
      fontVariantNumeric: 'tabular-nums',
    },
  }),
  { name: 'ExplorationVariablePropPanel' },
);

// RangeForm is a dialog for editing parameters for explorationpb.Range.

interface RangeFormProps {
  // Spec for variable being edited.
  param: ProtoDescriptor.Param;
  // The current exploration definition.
  proto: explorationpb.Range;
  // Makes the dialog readonly.
  readOnly: boolean;
  // If set, fix the number of samples to this value.
  // The value is either undefined, or >1.
  // Set only in latin hypercube policy.
  nSamples: number | undefined;
  // Called when the range definition changes. Unused if readOnly.
  onChange: (proto: explorationpb.Range) => void;
}

const RangeForm = (props: RangeFormProps) => {
  const { param, proto, readOnly, nSamples, onChange } = props;
  const classes = useStyles();
  const type = param.type;

  return (
    <>
      <ParamRow
        nestLevel={0}
        param={{ ...param, text: 'Min', help: 'Range endpoint (inclusive)' }}
        readOnly={readOnly}
        setValue={(val) => {
          const newRange = proto.clone();
          newRange.min = adToPrimal(val, type);
          onChange(newRange);
        }}
        value={primalToAd(proto.min!, type)}
      />
      <ParamRow
        nestLevel={0}
        param={{ ...param, text: 'Max', help: 'Range endpoint (inclusive)' }}
        readOnly={readOnly}
        setValue={(val) => {
          const newRange = proto.clone();
          newRange.max = adToPrimal(val, type);
          onChange(newRange);
        }}
        value={primalToAd(proto.max!, type)}
      />
      {!nSamples && (
        <>
          <Form.LabeledInput
            help="Number of samples in the range including min and max (minimum 2 samples)"
            label="Number of Samples">
            <NumberInput
              asBlock
              disabled={readOnly}
              name="number of samples"
              onCommit={(val) => {
                const newVal = val < 2 ? 2 : val;
                const newProto = proto.clone();
                newProto.nSamples = newVal;
                onChange(newProto);
              }}
              size="small"
              value={proto.nSamples}
            />
          </Form.LabeledInput>
          <div style={{ marginTop: '16px' }}>
            <CollapsibleNodePanel
              heading="Preview"
              nodeId="NA"
              panelName="preview">
              <div
                className={
                  type === ProtoDescriptor.ParamType.VECTOR3 ? '' : classes.valueContainer
                }>
                {getDisplayableIntervalValues(proto, type).map((val, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <p className={classes.valueCell} data-testid="sample-value" key={i}>{val}</p>))}
              </div>
            </CollapsibleNodePanel>
          </div>
        </>
      )}
    </>
  );
};

// EnumeratedForm is a dialog for editing parameters for
// ExperiemntPb.Enumerated.

interface EnumeratedFormProps {
  // Spec for variable being edited.
  param: ProtoDescriptor.Param;
  // The current exploration definition.
  proto: explorationpb.Enumerated;
  // Makes the dialog readonly.
  readOnly: boolean;
  // Called when the range definition changes.
  onChange: (proto: explorationpb.Enumerated) => void;
  // TODO(saito) Pass the exploration policy and if the policy is latinhypercube,
  // force the user to input the correct number of values.
}

const EnumeratedForm = (props: EnumeratedFormProps) => {
  const { param, proto, readOnly, onChange } = props;

  const classes = useStyles();
  // Called to add a new row at the end.
  const onNewValue = () => {
    const values = proto.value;
    const lastValue = values[values.length - 1];
    const newProto = proto.clone();
    newProto.value.push(lastValue);
    onChange(newProto);
  };
  // Called to delete the entry #vi in proto.value.
  const onDeleteValue = (vi: number) => {
    // Enumeration requires >= 1 values.
    if (proto.value.length <= 1) {
      return;
    }
    const newProto = proto.clone();
    newProto.value = proto.value.filter((_, i) => i !== vi);
    onChange(newProto);
  };
  const { type } = param;
  const values = proto.value;
  const canDelete = (values.length > 1);
  return (
    <>
      {values.map((val: explorationpb.Value, vi: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Form.LabeledInput key={vi} label={`Value ${vi}:`}>
          <div className={classes.deletable}>
            <ParamFieldInput
              param={param}
              readOnly={readOnly}
              setValue={(newValue) => {
                const newProto = proto.clone();
                newProto.value = proto.value.map(
                  (value, i) => (i === vi ? adToPrimal(newValue, type) : value),
                );
                onChange(newProto);
              }}
              value={primalToAd(val, type)}
            />
            <div className={classes.deleteIcon}>
              <Tooltip title="Delete value">
                <span>
                  <IconButton disabled={!canDelete} onClick={() => onDeleteValue(vi)}>
                    <TrashIcon maxHeight={14} maxWidth={14} />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </div>
        </Form.LabeledInput>
      ))}
      {!readOnly && (
        <div className={classes.addControls}>
          <Tooltip title="Add new value">
            <IconButton onClick={onNewValue}>
              <PlusIcon maxHeight={14} maxWidth={14} />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </>
  );
};

//
// VarDetailForm is a dialog for specifying the range or enumerated values for
// an exploration variable. This does not apply to variables in a sensitivity
// analysis.
//
interface VarDetailFormProps {
  // Proto that contains all the parameters for all the dialogs, e.g.,
  // luminary.proto.client.SimulationParam. This proto is never modified by ParamForm.
  baseline: simulationpb.SimulationParam;
  // The exploration.
  exploration: explorationpb.Exploration;
  // The variable being edited.
  curVar: explorationpb.Var;
  // If readOnly, the dialog becomes readonly. onUpdate and
  // onChange will not be called.
  readOnly: boolean;
  // If set, fix the number of samples to this value.
  // The value is either undefined, or >1.
  // Set only in latin hypercube policy.
  nSamples: number | undefined;
  // Called after the variable spec has been updated.
  onChange: (newVar: explorationpb.Var) => void;
  paramScope: ParamScope,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
}

const VarDetailForm = (props: VarDetailFormProps) => {
  const varSpec = props.curVar.spec!;
  const curId = varSpec.id;
  const curType = varSpec.type;
  const paramState =
    varSpecToParamState(
      varSpec,
      props.baseline,
      props.paramScope,
      props.geometryTags,
      props.staticVolumes,
    );
  if (!paramState) {
    throw Error(`Exploration variable ${props.curVar} ${curId} not found`);
  }

  // Switch the value type, e.g., RANGE vs ENUMERATED
  const setValueType = (valueType: ValueTypCase) => {
    if (props.curVar.valueTyp.case === valueType) {
      return;
    }
    const newVar = newExplorationVar(
      props.baseline,
      paramState.param,
      props.paramScope,
      valueType,
      curType,
      curId,
      props.geometryTags,
      props.staticVolumes,
      props.exploration.policy.case === 'custom' ? props.exploration.policy.value.table : undefined,
    );
    props.onChange(newVar);
  };
  // detailForm is the component for editing the variable spec,
  // depending on the its type (range, enumerated, etc).
  let detailForm: ReactElement;
  switch (props.curVar.valueTyp.case) {
    case 'range':
      detailForm = (
        <RangeForm
          nSamples={props.nSamples}
          onChange={(newValue) => {
            const newVar = props.curVar.clone();
            newVar.valueTyp = { case: 'range', value: newValue };
            props.onChange(newVar);
          }}
          param={paramState.param}
          proto={props.curVar.valueTyp.value}
          readOnly={props.readOnly}
        />
      );
      break;
    case 'enumerated':
      detailForm = (
        <EnumeratedForm
          onChange={(newValue) => {
            const newVar = props.curVar.clone();
            newVar.valueTyp = { case: 'enumerated', value: newValue };
            props.onChange(newVar);
          }}
          param={paramState.param}
          proto={props.curVar.valueTyp.value}
          readOnly={props.readOnly}
        />
      );
      break;
    case 'column':
      detailForm = (
        <ColumnForm
          exploration={props.exploration}
          onChange={(newValue) => {
            const newVar = props.curVar.clone();
            newVar.valueTyp = { case: 'column', value: newValue };
            props.onChange(newVar);
          }}
          param={paramState.param}
          paramScope={props.paramScope}
          readOnly={props.readOnly}
          variable={props.curVar}
        />
      );
      break;
    default:
      throw Error('Wrong type: {props.curVar.valueTyp.case}');
  }

  const typeValue = props.curVar.valueTyp.case;
  return (
    <>
      {props.exploration.policy.case !== 'custom' && (
        <Form.LabeledInput label="Type">
          <DataSelect
            asBlock
            onChange={(newVal: ValueTypCase) => setValueType(newVal)}
            options={[
              { value: 'range', name: 'Uniform Range', selected: typeValue === 'range' },
              { value: 'enumerated', name: 'Enumerated', selected: typeValue === 'enumerated' },
            ]}
            size="small"
          />
        </Form.LabeledInput>
      )}
      {detailForm}
    </>
  );
};

interface VariableFormProps extends VarDetailFormProps {
  // The exploration.
  exploration: explorationpb.Exploration;
  // List of the physical behaviors.
  behaviors: simulationpb.PhysicalBehavior[];
  // List of the boundary conditions.
  bounds: AnyBoundaryCondition[];
  // List of the reference frames.
  frames: simulationpb.MotionData[];
  // List of materials.
  materials: simulationpb.MaterialEntity[];
  // Other exploration variables already defined.
  otherVars: explorationpb.Var[];
  // Particle groups
  particleGroups: simulationpb.ParticleGroup[];
  // Physics
  physics: simulationpb.Physics[];
  // Heat sources
  heatSources: simulationpb.HeatSource[];
}

//
// VariableForm is a dialog for specifying parameters for one exploration
// variable.
//
const VariableForm = (props: VariableFormProps) => {
  const { projectId, workflowId, jobId } = useProjectContext();

  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const bcNames = useSimulationBoundaryNames(projectId, workflowId, jobId);

  const isCustomPolicy = (props.exploration.policy.case === 'custom');
  const isSensitivityAnalysis = (props.exploration.policy.case === 'sensitivityAnalysis');

  const curVarSpec = props.curVar.spec!;
  const curId = curVarSpec.id;
  const curType = curVarSpec.type;

  interface OptionVarSpec {
    type: explorationpb.VarType,
    id: string,
  }

  // Creates options for the data select for the variable type selection.
  function makeOptionGroup<T>(
    label: string,
    type: explorationpb.VarType,
    items: T[],
    iconName: IconName,
    getId: (item: T, index: number) => string,
    disable?: (item: T, index: number) => boolean,
  ): SelectOptionGroup<OptionVarSpec> {
    return {
      label,
      options: items.map((item, index) => {
        const varSpec: OptionVarSpec = {
          id: getId(item, index),
          type,
        };
        // The option will be disabled if there are no parameters to select. This can happen either
        // because the dialog does not have any enabled parameters or all available parameters are
        // already used by other exploration variables.
        const disabled = getCandidates(
          varSpec.type,
          varSpec.id,
          props.baseline,
          props.paramScope,
          isSensitivityAnalysis,
          props.otherVars,
          props.geometryTags,
          props.staticVolumes,
        ).length === 0 || !!disable?.(item, index);
        return {
          name: findNameByType(simParam, getId(item, index), bcNames, varSpec.type)!,
          value: varSpec,
          selected: curType === type && varSpec.id === curId,
          icon: { name: iconName },
          description: disabled ? 'No parameters available.' : '',
          disabled,
        };
      }),
    };
  }

  const varTypeOptions: SelectOptionGroup<OptionVarSpec>[] = [];
  if (props.materials.length) {
    varTypeOptions.push(makeOptionGroup(
      'Materials',
      explorationpb.VarType.MATERIAL,
      props.materials,
      'horizontalWavesTriple',
      (material) => getMaterialId(material),
    ));
  }
  // Add variable selection options for boundaries.
  if (props.bounds.length > 0) {
    varTypeOptions.push(makeOptionGroup(
      'Boundary Conditions',
      explorationpb.VarType.BOUNDARY,
      props.bounds,
      'hash',
      (bc) => bc.boundaryConditionName,
      (bc) => isDependentBoundaryCondition(bc),
    ));
  }
  // Add variable selection options for reference frames.
  if (props.frames.length > 0) {
    varTypeOptions.push(makeOptionGroup(
      'Reference Frames',
      explorationpb.VarType.REFERENCE_FRAME,
      props.frames,
      'triAxes',
      (frame) => frame.frameId,
    ));
  }
  // Add variable selection options for physical behaviors.
  if (props.behaviors.length > 0) {
    varTypeOptions.push(makeOptionGroup(
      'Physical Behaviors',
      explorationpb.VarType.PHYSICAL_BEHAVIOR,
      props.behaviors,
      'intersectingDisks',
      (behavior) => behavior.physicalBehaviorId,
    ));
  }
  // Add variable selection options for physics.
  if (props.physics.length > 0) {
    varTypeOptions.push(makeOptionGroup(
      'Physics',
      explorationpb.VarType.PHYSICS,
      props.physics,
      'atom',
      (physics) => getPhysicsId(physics),
    ));
  }

  const actuatorDisks = props.particleGroups.filter(
    (particleGroup) => particleGroup.particleGroupType === ACTUATOR_DISK,
  );
  // Add variable selection options for particel groups.
  if (actuatorDisks.length > 0) {
    varTypeOptions.push(makeOptionGroup(
      'Actuator Disks',
      explorationpb.VarType.PARTICLE_GROUP,
      actuatorDisks,
      'circle',
      (particleGroup) => particleGroup.particleGroupId,
    ));
  }
  // Add heat sources
  if (props.heatSources.length > 0) {
    varTypeOptions.push(makeOptionGroup(
      'Heat Sources',
      explorationpb.VarType.HEAT_SOURCE,
      props.heatSources,
      'verticalWaves',
      (heatSource) => heatSource.heatSourceId,
    ));
  }
  // Add global selection option.
  varTypeOptions.push({
    label: '',
    options: [{
      value: { id: '', type: explorationpb.VarType.GLOBAL },
      name: 'Global',
      selected: curType === explorationpb.VarType.GLOBAL,
      icon: { name: 'globe' },
    }],
  });

  // Change the type of the current variable and set the selected param to the first
  // available candidate.
  const setVarType = (newVarSpec: OptionVarSpec) => {
    const newCandidates = getCandidates(
      newVarSpec.type,
      newVarSpec.id,
      props.baseline,
      props.paramScope,
      isSensitivityAnalysis,
      props.otherVars,
      props.geometryTags,
      props.staticVolumes,
    );
    if (newCandidates.length > 0) {
      const newVar = newExplorationVar(
        props.baseline,
        newCandidates[0],
        props.paramScope,
        isCustomPolicy ? 'column' : 'range',
        newVarSpec.type,
        newVarSpec.id,
        props.geometryTags,
        props.staticVolumes,
        props.exploration.policy.case === 'custom' ?
          props.exploration.policy.value.table : undefined,
      );
      props.onChange(newVar);
    }
  };

  // List of current parameter candidates.
  const candidates = getCandidates(
    curType,
    curId,
    props.baseline,
    props.paramScope,
    isSensitivityAnalysis,
    props.otherVars,
    props.geometryTags,
    props.staticVolumes,
  );

  // Change the field name of the current variable to the name of the selected parameter.
  const setFieldName = (newName: string) => {
    const newVar = newExplorationVar(
      props.baseline,
      candidates.find((param) => param.name === newName)!,
      props.paramScope,
      isCustomPolicy ? 'column' : 'range',
      curType,
      curId,
      props.geometryTags,
      props.staticVolumes,
      props.exploration.policy.case === 'custom' ? props.exploration.policy.value.table : undefined,
    );
    props.onChange(newVar);
  };

  const paramName = curVarSpec.field;
  // List of options for the parameter selection
  const paramOptions: SelectOption<string>[] = candidates.map((param) => (
    {
      value: param.name,
      name: param.text,
      selected: paramName === param.name,
      tooltip: param.help,
    }
  ));

  return (
    <PropertiesSection>
      <Form.LabeledInput label="Variable Type">
        <DataSelect
          asBlock
          onChange={(newVarSpec: OptionVarSpec) => setVarType(newVarSpec)}
          options={varTypeOptions}
          size="small"
        />
      </Form.LabeledInput>
      <Form.LabeledInput label="Parameter">
        <DataSelect
          asBlock
          onChange={(newVal: string) => setFieldName(newVal)}
          options={paramOptions}
          size="small"
        />
      </Form.LabeledInput>
      {!isSensitivityAnalysis && <VarDetailForm {...props} />}
    </PropertiesSection>
  );
};

// A panel displaying all the simulation settings for the selected node.
export const ExplorationVariablePanel = () => {
  const { projectId, workflowId, jobId, readOnly, onNewWorkflowConfig } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected exploration variable row');

  const config = useCurrentConfig(projectId, workflowId, jobId);
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  const exploration = config.exploration;
  assert(!!exploration, 'No selected exploration variable');

  const varIndex = exploration.var.findIndex(
    (_, i) => explorationVariableNodeId(i) === node.id,
  );
  const varList = exploration.var;
  const variable = varList[varIndex];
  const { simParam } = useSimulationConfig();

  const nSamples = exploration.policy.case === 'latinHypercube' ?
    exploration.policy.value.nSamples : undefined;

  return (
    <VariableForm
      baseline={simParam}
      behaviors={getAllPhysicalBehaviors(simParam)}
      bounds={getAllBoundaryConditions(simParam)}
      curVar={variable}
      exploration={exploration}
      frames={simParam.motionData}
      geometryTags={geometryTags}
      heatSources={getAllHeatSources(simParam)}
      materials={simParam.materialEntity}
      nSamples={nSamples}
      onChange={(newVar: explorationpb.Var) => {
        const newExploration = exploration.clone();
        newExploration.var = newExploration.var.map(
          (oldVar, index) => ((index === varIndex) ? newVar : oldVar),
        );
        onNewWorkflowConfig(
          updateExploration(config, newExploration),
        );
      }}
      otherVars={varList.filter((_, index) => varIndex !== index)}
      paramScope={paramScope}
      particleGroups={simParam.particleGroup}
      physics={simParam.physics}
      readOnly={readOnly}
      staticVolumes={staticVolumes}
    />
  );
};
