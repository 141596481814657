// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue } from 'recoil';

import { MaterialDataMap, MaterialDatum, getMaterialId, getMaterialName } from '../../../../../lib/materialUtils';
import { RecoilProjectKey } from '../../../../../lib/persist';
import { simulationParamState } from '../param';

/**
 * Derived from a simulation param, this state exposes a list of material data, where each item in
 * the list includes pre-calculated ID and name as well as the material entity proto message
 */
export const simulationMaterialsState = selectorFamily<MaterialDatum[], RecoilProjectKey>({
  key: 'simulationMaterialsState',
  get: (key) => ({ get }) => {
    const param = get(simulationParamState(key));
    const materials = param.materialEntity;
    return materials.map((materialEntity) => ({
      id: getMaterialId(materialEntity),
      name: getMaterialName(materialEntity, param),
      model: materialEntity,
    }));
  },
  dangerouslyAllowMutability: true,
});

/**
 * Transforms the list from simulationMaterialsState into a plain object keyed by ID
 */
export const simulationMaterialsMapState = selectorFamily<MaterialDataMap, RecoilProjectKey>({
  key: 'simulationMaterialsMapState',
  get: (key) => ({ get }) => {
    const data = get(simulationMaterialsState(key));
    return data.reduce((result, datum) => {
      result[datum.id] = datum;
      return result;
    }, {} as MaterialDataMap);
  },
  dangerouslyAllowMutability: true,
});

export function useSimulationMaterials(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(simulationMaterialsState({ projectId, workflowId, jobId }));
}

export function useSimulationMaterialsMap(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(simulationMaterialsMapState({ projectId, workflowId, jobId }));
}
