// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { CurrentView } from '../../../lib/componentTypes/context';
import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem } from '../../../lib/treeUtils';
import {
  applyVisibilityToNode,
  evaluateVisibility,
  hideAllFilters,
  isClipOrSlice,
  showAllLeaves,
} from '../../../lib/visUtils';
import { UrlType } from '../../../proto/projectstate/projectstate_pb';
import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcvisFilterStatusValue } from '../../../recoil/lcvis/lcvisFilterStatus';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import { useMeshUrlState } from '../../../recoil/meshState';
import { useFilterState } from '../../../recoil/vis/filterState';
import { useCurrentView } from '../../../state/internal/global/currentView';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { useProjectContext } from '../../context/ProjectContext';
import { useCopyVisFilter } from '../../hooks/nodeDuplication/useCopyVisFilter';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { useFilterNode } from '../../visFilter/useFilterNode';
import { ResetVisualizationsButton } from '../ResetVisualizationsButton';
import { ContextMenuSection, TreeRow, VisibilityControl } from '../TreeRow';

// A row displaying information about a clip, slice, or other filter.
export const FilterRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;
  const { name } = node;

  // == Contexts
  const {
    changeNodeVisibility,
    hideAllVisualizations,
    showAllLeafs,
    toggleLICGeometrySurfaceVisibility,
    viewState,
  } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const currentView = useCurrentView();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();
  const [filterState, setFilterState] = useFilterState({ projectId, workflowId, jobId });
  const [meshUrlState] = useMeshUrlState(projectId);
  const lcvisFilterStatus = useLcvisFilterStatusValue();

  // == Hooks
  const renaming = useNodeRenaming(node);
  // Note: Since the useFilterNode hook uses 'viewState' to look up the 'filterNode', 'filterNode'
  // and 'param' could be null, so the rest of this component treats them as if they may not be
  // defined.  Practically speaking, though, these filter rows are only displayed when viewState
  // is available; TS just can't determine that.
  const { filterNode, param, iconName } = useFilterNode(node);
  const { canDelete, deleteFilterNode, postDeleteNodeIds } = useNodeDeletion();
  const duplicateRow = useCopyVisFilter();

  // == Data
  const isGeometryView = currentView === CurrentView.GEOMETRY;
  const isMesh = meshUrlState.activeType === UrlType.MESH;

  // READER is the root Visualizations node
  const isReaderNode = useMemo(() => (param?.typ === ParaviewRpc.TreeNodeType.READER), [param]);

  const visStats = useMemo(() => {
    if (lcvisEnabled) {
      return evaluateVisibility(filterState);
    }
    return (viewState ? evaluateVisibility(viewState.root) : null);
  }, [viewState, lcvisEnabled, filterState]);

  const isVisible = useMemo(() => {
    if (isReaderNode) {
      return visStats?.anyVisible ?? false;
    }
    return filterNode?.visible ?? false;
  }, [visStats?.anyVisible, filterNode, isReaderNode]);

  const showReset = useMemo(
    () => {
      if (lcvisEnabled) {
        return isReaderNode && !isGeometryView;
      }
      return isReaderNode && viewState && !isGeometryView;
    },
    [isReaderNode, viewState, isGeometryView, lcvisEnabled],
  );

  // Handlers
  const toggleVisibility = () => {
    if (isReaderNode) {
      if (isVisible) {
        if (lcvisEnabled) {
          let newFilterState = hideAllFilters(filterState);
          if (lcvisReady) {
            newFilterState = lcvHandler.display!.filterHandler!.maybeUpdateVisibility(
              newFilterState,
            );
          }
          setFilterState(newFilterState);
          return;
        }
        hideAllVisualizations();
      } else {
        if (lcvisEnabled) {
          let newFilterState = showAllLeaves(filterState);
          if (lcvisReady) {
            newFilterState = lcvHandler.display!.filterHandler!.maybeUpdateVisibility(
              newFilterState,
            );
          }
          setFilterState(newFilterState);
          return;
        }
        showAllLeafs();
      }
    } else if (filterNode) {
      const currVisibility = filterNode.visible;
      if (lcvisEnabled) {
        let newFilterState = applyVisibilityToNode(
          filterState,
          filterNode.id,
          isClipOrSlice(filterNode),
          !currVisibility,
          isMesh,
        );
        if (lcvisReady) {
          // since lcvisReady is true, the display and filterHandler must already exist.
          newFilterState = lcvHandler.display!.filterHandler!.maybeUpdateVisibility(
            newFilterState,
          );
        }
        setFilterState(newFilterState);
        return;
      }
      changeNodeVisibility(filterNode.id, !currVisibility);
      toggleLICGeometrySurfaceVisibility(filterNode, currVisibility);
    }
  };

  const visibilityControl: VisibilityControl = {
    disabled: lcvisEnabled && !lcvisReady,
    show: isVisible,
    toggle: toggleVisibility,
  };

  const primaryIcon = useMemo(() => (iconName ? { name: iconName } : undefined), [iconName]);

  const deleteRow = useCallback(() => {
    if (deleteFilterNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteFilterNode, node.id, postDeleteNodeIds]);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];

    if (param && !isReaderNode) {
      const disabled = !canDelete(node.type, node.id);
      const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
      const duplicateItem = duplicateTreeNodeMenuItem(() => duplicateRow(node.id), disabled);
      sections.push({ section: 'crud', menuItems: [duplicateItem, deleteItem] });
    }

    return sections;
  }, [canDelete, deleteRow, isReaderNode, node.id, node.type, param, duplicateRow]);

  const status = lcvisFilterStatus.get(node.id);
  const dim = status ? !(status.status === 'completed') : false;
  const displayName = (() => {
    if (status?.status === 'completed') {
      return name;
    }
    if (status?.status === 'queued') {
      return `${name} (queued)`;
    }
    if (typeof status?.status === 'number') {
      return `${name} (${status.status}%)`;
    }
    return name;
  })();

  return (
    <TreeRow
      {...props}
      auxControl={showReset ? <ResetVisualizationsButton /> : undefined}
      dimmed={dim}
      getExtraContextMenuItems={getExtraContextMenuItems}
      label={displayName}
      primaryIcon={primaryIcon}
      renaming={renaming}
      visibility={visibilityControl}
    />
  );
};
