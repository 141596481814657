// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as ProtoDescriptor from '../ProtoDescriptor';
import * as simulationpb from '../proto/client/simulation_pb';

import { createParamScope } from './ParamScope';
import { newAdFloat } from './adUtils';
import { newInt } from './intUtils';
import { checkPresets } from './physicsHeatControlsPresets';

const DiscretizationPresetVersion: number = 71824;

const {
  CONSERVATIVE_SPATIAL_DISCRETIZATION_HEAT,
  CUSTOM_SPATIAL_DISCRETIZATION_HEAT,
  HIGH_ACCURACY_SPATIAL_DISCRETIZATION_HEAT,
} = simulationpb.SpatialDiscretizationHeatPreset;

// Sets the parameters for each discretization preset.
export function applyHeatDiscretizationPreset(
  physics: simulationpb.Physics,
  simParam: simulationpb.SimulationParam,
  experimentConfig: string[],
): void {
  // Only handles heat discretization presets.
  const heat = physics.params.case === 'heat' ? physics.params.value : null;
  if (!heat) {
    return;
  }
  const physicsScope = createParamScope(heat, experimentConfig);
  const paramScope = createParamScope(simParam, experimentConfig, physicsScope);

  /* eslint-disable-next-line id-length */
  const p = heat.spatialDiscretizationHeat;
  const value = p?.spatialDiscretizationHeatPreset;

  if (!p || value === CUSTOM_SPATIAL_DISCRETIZATION_HEAT) {
    return;
  }

  // Always tag the project with the current version. See LC-6227.
  p.discretizationPresetVersion = newInt(DiscretizationPresetVersion);

  // BEGIN GENERATED CODE (see gen/presets.py)
  const presets = new Map<string, ProtoDescriptor.Cond>();
  presets.set(
    'DISCR_HEAT_TRANSFER',
    {
      type: ProtoDescriptor.CondType.TRUE,
    },
  );
  checkPresets(presets, paramScope);
  if (paramScope.isEnabled(presets.get('DISCR_HEAT_TRANSFER')!)) {
    p.gradientMethod = simulationpb.GradientMethod.LC_HLSQ;
    p.hlsqBlend = newAdFloat(1.7);
    if (value === CONSERVATIVE_SPATIAL_DISCRETIZATION_HEAT) {
      p.gradientMethod = simulationpb.GradientMethod.GREEN_GAUSS;
    }
    if (value === HIGH_ACCURACY_SPATIAL_DISCRETIZATION_HEAT) {
      p.gradientMethod = simulationpb.GradientMethod.LC_HLSQ;
      p.hlsqBlend = newAdFloat(2.0);
    }
  }
  // END GENERATED CODE
}

export function applyAllHeatDiscretizationPresets(
  simParam: simulationpb.SimulationParam,
  experimentConfig: string[],
): void {
  simParam.physics.forEach((physics) => {
    applyHeatDiscretizationPreset(physics, simParam, experimentConfig);
  });
}
