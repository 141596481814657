// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 381;
const baseHeight = 381;
export const GridOnIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="gridOn"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M366,47
           C366,29.339 351.661,15 334,15
           L66,15
           C48.339,15 34,29.339 34,47
           L34,315
           C34,332.661 48.339,347 66,347
           L334,347
           C351.661,347 366,332.661 366,315
           L366,47Z
           M134,247
           L66,247
           L66,315
           L134,315
           L134,247Z
           M234,247
           L166,247
           L166,315
           L234,315
           L234,247Z
           M334,247
           L266,247
           L266,315
           L334,315
           L334,247Z
           M134,147
           L66,147
           L66,215
           L134,215
           L134,147Z
           M234,147
           L166,147
           L166,215
           L234,215
           L234,147Z
           M334,147
           L266,147
           L266,215
           L334,215
           L334,147Z
           M134,47
           L66,47
           L66,115
           L134,115
           L134,47Z
           M234,47
           L166,47
           L166,115
           L234,115
           L234,47Z
           M334,47
           L266,47
           L266,115
           L334,115
           L334,47Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
