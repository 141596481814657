// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as ProtoDescriptor from '../ProtoDescriptor';

import { ParamScope } from './ParamScope';
import assert from './assert';

// Defines a param and a corresponding fallback used for a weak comparison of param values in two
// sets of params with the weakCompareParams() routine.The fallback is used in case a strict
// equality check fails.
export interface ParamWithFallback {
  // Descriptor of the param option.
  desc: ProtoDescriptor.FullDescriptor,
  // Fallback param that should be checked if the equality check fails or the param is
  // not active in the other params.
  fallbackParamDesc?: ProtoDescriptor.FullDescriptor,
  // Fallback param values that are considered compatible with the param value in the first params.
  // The keys of the map are the values for the param defined by`desc` in the first params. The
  // values are possible option values for the option defined by `fallbackParamDesc` in the second
  // params.
  // Values are union of number and string to be able to use Object.values() to list all
  // enum values for an option
  fallBackParamValues?: Map<number, (number | string)[]>,
  // Message used to inform the users about why weakCompareParams failed when called using this
  // object. If set, it must start with a capital letter and it should not end with a separator
  // or delimiter.
  warningMessage?: string,
}

// Weak comparison of a param option between two sets of params.
// Returns true if  is satisfied
export function weakParamCompare(
  paramWithFallback: ParamWithFallback,
  paramScopeA: ParamScope,
  paramScopeB: ParamScope,
): boolean {
  // Make sure that the option is active in the paramA otherwise we just return
  // true (ignoring this constraint).
  const cond = paramWithFallback.desc.param.cond;
  // undefined conditionals imply that the params are always active.
  const paramEnabled = !cond || (cond && paramScopeA.isEnabled(cond));
  const otherParamEnabled = !cond || (cond && paramScopeB.isEnabled(cond));
  if (paramEnabled) {
    // Compare both param values, if they are not identical consider the fallback
    // if it exists.
    const paramValue = paramScopeA.value(paramWithFallback.desc.param) as number;
    const otherParamValue = paramScopeB.value(paramWithFallback.desc.param) as number;
    if (otherParamEnabled && (paramValue === otherParamValue)) {
      return true;
    }
    if (paramWithFallback.fallbackParamDesc) {
      assert(!!paramWithFallback.fallBackParamValues, 'Fallback param values required');
      const fallBackParam = paramScopeB.value(paramWithFallback.fallbackParamDesc.param) as number;
      // Check if the param value is in the possible values of the fallback option.
      if (paramWithFallback.fallBackParamValues.get(paramValue)?.includes(fallBackParam)) {
        return true;
      }
    }
    return false;
  }
  return true;
}
