// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { colors } from '../../lib/designSystem';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { RingXIcon } from '../svg/RingXIcon';

const GAP = 8;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      '--x-button-opacity': 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: `${GAP}px`,
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 400,

      height: '28px',
      minHeight: '28px',

      '&:hover': {
        '&.disabledXButton': {
          '--x-button-opacity': 0.4,
        },
        '&:not(.disabledXButton)': {
          '--x-button-opacity': 1,
        },
      },

      '& button': {
        // Reset styling for both buttons (tagMain and (x))
        backgroundColor: 'transparent',
        border: 0,
        outline: 0,
        boxShadow: 'none',
        '&:enabled': {
          cursor: 'pointer',
        },
      },
    },
    tagMain: {
      color: colors.highEmphasisText,
      maxWidth: `calc(100% - 24px - ${GAP}px)`, // 24px is the (x) at the end
    },
    tagContent: {
      display: 'flex',
      gap: `${GAP}px`,
      alignItems: 'center',
    },
    icon: {
      width: '12px',
      display: 'flex',
    },
    tagNameSection: {
      display: 'flex',
      gap: `${GAP / 2}px`,
      alignItems: 'baseline',
      overflow: 'hidden',
    },
    tagScope: {
      whiteSpace: 'nowrap',
      fontWeight: 600,
    },
    tagName: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    tagAux: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
      color: colors.inputPlaceholderText,
    },
    closeButton: {
      opacity: 'var(--x-button-opacity)',
      display: 'flex',
      alignItems: 'center',
      color: colors.lowEmphasisText,
      transition: 'color 250ms, opacity 500ms',
      '&:enabled:hover': {
        color: colors.highEmphasisText,
      },
    },
  }),
  { name: 'NodeSelection' },
);

interface NodeSelectionClosing {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  tooltip?: string;
}

interface NodeSelectionProps {
  // The text of the tag.
  text: string;
  // Auxiliary text to show after the text
  auxText?: string;
  // An optional tag scope.
  scope?: string;
  disabled?: boolean;
  // Function to call if any other part of the tag is clicked.
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  // Optionally configure a close button
  closing?: NodeSelectionClosing;
  // Optional tooltip text for main content
  tooltip?: string;
  icon?: SvgIconSpec;
}

/**
 * This is used for showing individual selected nodes in the NodeTable. It is very similar
 * in functionality to the Tag.tsx but has a different UI, specific to the NodeTable.
 */
export const NodeSelection = (props: NodeSelectionProps) => {
  const {
    auxText,
    closing,
    disabled,
    onClick,
    onMouseEnter,
    onMouseLeave,
    scope,
    text,
    tooltip = '',
    icon,
  } = props;

  const classes = useStyles();

  return (
    <div
      className={cx(classes.root, { disabled, disabledXButton: disabled || closing?.disabled })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <button
        className={cx(classes.tagMain)}
        data-locator="treePanelTagMain"
        disabled={disabled || !onClick}
        onClick={onClick}
        type="button">
        <Tooltip title={tooltip}>
          <div className={classes.tagContent}>
            {icon && <span className={classes.icon}><SvgIcon maxHeight={12} {...icon} /></span>}
            <div className={classes.tagNameSection}>
              {scope && <div className={classes.tagScope}>{scope}</div>}
              <div className={classes.tagName}>{text}</div>
              {auxText && (
                <div className={classes.tagAux}>{auxText}</div>
              )}
            </div>
          </div>
        </Tooltip>
      </button>
      {closing && (
        <Tooltip title={closing.tooltip}>
          <span>
            {/* Disabled buttons don't fire events and as a result they shouldn't be a direct
            children of the <Tooltip> component. That's why we wrap them with a <span>. */}
            <button
              className={classes.closeButton}
              disabled={disabled || closing.disabled}
              onClick={closing.onClick}
              type="button">
              <RingXIcon maxHeight={12} />
            </button>
          </span>
        </Tooltip>
      )}
    </div>
  );
};
