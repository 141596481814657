// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { CommonMenuItem } from '../../../../lib/componentTypes/menu';
import { NodeTableType } from '../../../../lib/nodeTableUtil';
import { MenuButton } from '../../../Button/MenuButton';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import NodeTable from '../../NodeTable';
import PropertiesSection from '../../PropertiesSection';

import { PanelHeading } from './PanelHeading';

interface UndefinedBoundariesProps {
  nodeId: string;
  surfaces: string[];
  typeMenuItems: CommonMenuItem[];
}

export const UndefinedBoundaries = (props: UndefinedBoundariesProps) => {
  const { nodeId, surfaces, typeMenuItems } = props;

  return (
    <>
      <PanelHeading
        subtitle={
          `These surfaces are missing boundary conditions and must be defined before running a
           simulation`
        }
        title="Undefined Boundaries"
      />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Geometry"
          nodeId={nodeId}
          panelName="surfaces">
          <NodeTable
            button={(
              <MenuButton
                kind="minimal"
                menuItems={typeMenuItems}
                size="small">
                Define All
              </MenuButton>
            )}
            editable={false}
            nodeIds={surfaces}
            tableId="boundary-condition-unassigned-surfaces"
            tableType={NodeTableType.NONE}
            title="Undefined Surfaces"
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </>
  );
};
