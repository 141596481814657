// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import axios, { AxiosError } from 'axios';

import * as frontendpb from '../proto/frontend/frontend_pb';

import { OobChallengeResponse, REFRESH_TOKEN_COUNTER_KEY, REFRESH_TOKEN_KEY } from './AuthV2';
import { authClientId, authDomain, authTenant } from './RuntimeParams';
import { milliseconds } from './formatDate';
import { Logger } from './observability/logs';
import * as rpc from './rpc';

const logger = new Logger('AuthMFA');

type MfaOption = {
  active: boolean;
  'authenticator_type': 'otp' | 'oob' | 'recovery-code';
  id: string; // looks like "totp|...." or "sms|....."
  'oob_channel'?: 'sms' | 'voice'; // only available for SMS or Voice-based MFAs
  name?: string; // only available for SMS or Voice-based MFAs
}

interface MfaTokenLocationState {
  mfaToken: string;
  mfaTokenExpiry: number;
}

export interface SetupSelect2FALocationState extends MfaTokenLocationState {
  idToken?: string;
}

/** This location.state must be set when we navigate from the SetupSelect2FA to the SetupApp2FA */
export interface SetupApp2FALocationState extends MfaTokenLocationState {
  idToken?: string;
}

/** This location.state must be set when we navigate from the SetupSelect2FA to the SetupSms2FA */
export interface SetupSms2FALocationState extends MfaTokenLocationState {
  phoneNumber?: string;
  oobSetup?: OobMfaSetupParams;
}

/**
 * This location.state must be set when we navigate from the useHandleMfaRequired hook to either
 * the LoginApp2FA or the LoginSms2FA.
 */
export interface LoginWithMfaLocationState extends MfaTokenLocationState {
  activeMfaList: MfaOption[];
}

/**
 * When the user enters their email/password and their account has MFA enabled, Auth0 generates an
 * MFA token that lasts for 10 minutes. That means the user has max of 10 minutes to enter their 2nd
 * auth method.
 * This fn calculates the expiration time so that we can save it and then later we can redirect to
 * the login page if the MFA expires.
 */
export const getMfaTokenExpiry = () => new Date().getTime() + milliseconds({ minutes: 10 });

/** Checks whether the MFA login token has expired. If so, we should redirect to the login page */
export const isMfaTokenExpired = (expiry?: number) => new Date().getTime() > (expiry ?? 0);
export const MFA_TOKEN_EXPIRED_ERROR = 'Login token expired, please try again.';
export const US_PHONE_PREFIX = '+1';

/**
 * This fn can be used to clean a user-entered mobile number from all non-digit chars and also to
 * append the US phone prefix to the rest of the number.
 */
export function modifyNumber(
  phoneNumber: string,
  opts: { clean?: boolean, appendUSPrefix?: boolean },
) {
  const prefix = opts.appendUSPrefix ? US_PHONE_PREFIX : '';
  const cleanNumber = opts.clean ? phoneNumber.replace(/\D/g, '') : phoneNumber;
  return `${prefix}${cleanNumber}`;
}

interface Auth0AxiosErrorResponse {
  'error_description'?: string;
  message?: string;
}

function getAuth0AxiosError(err: AxiosError<Auth0AxiosErrorResponse>, fallbackError: string) {
  const axiosErrorData = err.response?.data;
  const axiosError = axiosErrorData?.error_description || axiosErrorData?.message;
  logger.error('getAuth0AxiosError: ', err.response, err.message);
  if (err.response?.status === 429 && axiosError?.includes('SMS')) {
    return `You have reached the SMS hourly limit. Please try again later or contact your admin to
      reset your MFA.`;
  }
  if (axiosError === 'mfa_token is expired') {
    return MFA_TOKEN_EXPIRED_ERROR;
  }
  return axiosError || err.message || fallbackError;
}

/**
 * This is called when we submit the code from the user's authenticator app for an app-based MFA.
 * If it succeeds, we'll proceed with the getLoginTokenWithOtpMfa.
 */
export async function startOtpLogin(mfatoken: string, authenticatorId: string) {
  const options = {
    method: 'POST',
    url: `https://${authTenant}/mfa/challenge`,
    data: {
      client_id: authClientId,
      challenge_type: 'otp',
      mfa_token: mfatoken,
      authenticator_id: authenticatorId,
    },
  };

  return axios.request(options).then((response: { data: any; }) => {
    if (!(response.data.challenge_type === 'otp')) {
      throw Error('Wrong challenge type');
    }
  }).catch((err: AxiosError<Auth0AxiosErrorResponse>) => {
    logger.error('MFA/Challenge failure for startOtpLogin: ', err);
    throw Error(getAuth0AxiosError(err, 'Signing in with the 2FA code failed. Please try again.'));
  });
}

/**
 * This is called when we submit the received code via SMS during login with SMS-based MFA.
 * If it succeeds, we'll proceed with the getLoginTokenWithOobMfa.
 */
export async function startOobLogin(mfatoken: string, authenticatorId: string) {
  const options = {
    method: 'POST',
    url: `https://${authTenant}/mfa/challenge`,
    data: {
      client_id: authClientId,
      challenge_type: 'oob',
      mfa_token: mfatoken,
      authenticator_id: authenticatorId,
    },
  };

  return axios.request(options).then(
    (response: { data: OobChallengeResponse; }) => response.data,
  ).catch((err: AxiosError<Auth0AxiosErrorResponse>) => {
    logger.error('MFA/Challenge failure for startOobLogin: ', err);
    throw Error(getAuth0AxiosError(err, 'Signing in with the SMS code failed. Please try again.'));
  });
}

export async function getActiveMfaOptions(mfatoken: string): Promise<{ data: MfaOption[] }> {
  const options = {
    method: 'GET',
    url: `https://${authDomain}/mfa/authenticators`,
    headers: { authorization: `Bearer ${mfatoken}`, 'content-type': 'application/json' },
  };

  return axios.request(options);
}

export interface OtpMfaSetupParams {
  secret: string;
  barcodeURI: string;
  recoveryCodes?: string[];
}

/**
 * This gets the initial OTP code that's used for setting up an app authenticator based MFA.
 * See https://auth0.com/docs/secure/multi-factor-authentication/authenticate-using-ropg-flow-with-mfa/enroll-and-challenge-otp-authenticators.
 *
 * @param mfatoken obtained from the webAuth.client.login.
 * @returns OtpMfaSetupParams object with a secret (the setup OTP code), barcodeURI (same code but
 * encoded as a QR) and recoveryCodes (they can be used for login w/o the authenticator).
 */
export async function getOtpMfaSetupCode(mfatoken: string): Promise<OtpMfaSetupParams> {
  const options = {
    method: 'POST',
    url: `https://${authTenant}/mfa/associate`,
    headers: { authorization: `Bearer ${mfatoken}`, 'content-type': 'application/json' },
    data: { authenticator_types: ['otp'] },
  };

  return axios.request(options).then((response: { data: any }) => ({
    authenticatorType: response.data.authenticator_type,
    secret: response.data.secret,
    barcodeURI: response.data.barcode_uri,
    recoveryCodes: response.data.recovery_codes,
  })).catch((err: AxiosError<Auth0AxiosErrorResponse>) => {
    logger.error('MFA/Associate failure for getOtpMfaSetupCode: ', err);
    throw Error(getAuth0AxiosError(err, 'Authentication error: MFA associate failed.'));
  });
}

// Auth0's oob mfa supports SMS authentication and voice authentication but we are only using SMS
export interface OobMfaSetupParams {
  oobCode: string;
  recoveryCodes?: string[];
}

/**
 * This gets the initial oob code needed for setting up the SMS based MFA. It will also send an SMS
 * message to the provided phone number. Later, both of these codes will be used to setup the MFA
 * by issuing a request to https://{authTenant}/oauth/token.
 * See https://auth0.com/docs/secure/multi-factor-authentication/authenticate-using-ropg-flow-with-mfa/enroll-challenge-sms-voice-authenticators
 *
 * @param mfatoken obtained from the webAuth.client.login.
 * @param phoneNumber US-number that will receive the setup code and will be used for the SMS MFA
 * @returns OobMfaSetupParams object with an oobCode and recoveryCodes.
 */
export async function getOobMfaSetupCode(
  mfaToken: string,
  phoneNumber: string,
): Promise<OobMfaSetupParams> {
  const options = {
    method: 'POST',
    url: `https://${authTenant}/mfa/associate`,
    headers: { authorization: `Bearer ${mfaToken}`, 'content-type': 'application/json' },
    data: { authenticator_types: ['oob'], oob_channels: ['sms'], phone_number: phoneNumber },
  };

  return axios.request(options).then((response: { data: any }) => ({
    oobCode: response.data.oob_code,
    recoveryCodes: response.data.recovery_codes,
  })).catch((err: AxiosError<Auth0AxiosErrorResponse>) => {
    logger.error('MFA/Associate failure for getOobMfaSetupCode: ', err);
    throw Error(getAuth0AxiosError(err, 'Authentication error: MFA associate failed'));
  });
}

// This function will get the login token with the refres token needed for session refresh
export async function getLoginTokenForGoogleAuth(
  authorizationCode: string,
  redirectUri: string,
  rememberLogin: boolean,
) {
  const req = new frontendpb.LoginCodeTokenRequest({ authorizationCode, redirectUri });
  const reply = await rpc.callRetry('LoginCodeToken', rpc.client.loginCodeToken, req);
  // if remember login is enabled,
  // we will store the refresh token which can be used to get a new login token
  if (rememberLogin) {
    localStorage.setItem(REFRESH_TOKEN_COUNTER_KEY, '0');
    localStorage.setItem(REFRESH_TOKEN_KEY, reply.refreshToken);
  }
  return reply;
}

async function callLoginToken(
  req: frontendpb.LoginTokenRequest,
  rememberLogin: boolean,
) {
  const reply = await rpc.callRetry('LoginToken', rpc.client.loginToken, req);
  // if remember login is enabled, we store the refresh token in local storage.
  // this will be used in getting subsequent idtoken
  if (rememberLogin) {
    localStorage.setItem(REFRESH_TOKEN_COUNTER_KEY, '0');
    localStorage.setItem(REFRESH_TOKEN_KEY, reply.refreshToken);
  }
  return reply.loginToken;
}

// Should be used only for the OTP authenticator type
export async function getLoginTokenWithOtpMfa(
  mfaToken: string,
  otpCode: string,
  idToken: string,
  rememberLogin: boolean,
) {
  const req = new frontendpb.LoginTokenRequest({
    mfaToken,
    idToken,
    authenticatorType: {
      case: 'otpAuthenticatorType',
      value: new frontendpb.LoginTokenRequest_OtpAuthenticatorType({ otpCode }),
    },
  });
  return callLoginToken(req, rememberLogin);
}

// Should be used for SMS (and in the future Voice, Email and Push notification)
// authenticator types
export async function getLoginTokenWithOobMfa(
  mfaToken: string,
  idToken: string,
  oobCode: string,
  bindingCode: string,
  rememberLogin: boolean,
) {
  const req = new frontendpb.LoginTokenRequest({
    mfaToken,
    idToken,
    authenticatorType: {
      case: 'oobAuthenticatorType',
      value: new frontendpb.LoginTokenRequest_OobAuthenticatorType({ oobCode, bindingCode }),
    },
  });
  return callLoginToken(req, rememberLogin);
}

export async function doRecoveryLogin(mfaToken: string, recoveryCode: string) {
  const req = new frontendpb.LoginMfaRecoveryCodeRequest({ recoveryCode, mfaToken });
  return rpc.callRetry('RecoveryLogin', rpc.client.loginMfaRecoveryCode, req);
}
