// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { ColumnState } from '../../../lib/componentTypes/table';
import { isUnmodifiedSpaceKey } from '../../../lib/event';
import { ActionButton } from '../../Button/ActionButton';
import Form from '../../Form';
import Tooltip from '../../Tooltip';

import { TableContext } from './context';
import { getColumnLabelLines } from './util';

import './ColumnSettingsPanel.scss';

function getColumnDisabledReason(column: ColumnState, visibleColumnCt: number) {
  if (column.config.disableVisibility) {
    return 'Column may not be hidden';
  }
  if (!column.hidden && visibleColumnCt <= 1) {
    return 'At least one column must always be visible';
  }

  return '';
}

export const ColumnSettingsPanel = () => (
  <TableContext.Consumer>
    {(context) => {
      const { allColumns = [], changeColumnVisibility } = context;

      const visibleColumnCt = allColumns.reduce((result, column) => {
        if (!column.hidden) {
          return result + 1;
        }
        return result;
      }, 0);

      return (
        <div className="columnSettingsPanel">
          <div className="title">View</div>
          <div className="settings">
            {allColumns.map((column) => {
              const { config: { id, label, description }, hidden } = column;

              const labelContent = getColumnLabelLines(id, label).join(' ');

              if (!labelContent.trim()) {
                return null;
              }

              const disabledReason = getColumnDisabledReason(column, visibleColumnCt);

              return (
                <Tooltip
                  key={id}
                  title={disabledReason}>
                  <div
                    className={cx('columnSetting', { disabled: !!disabledReason })}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (!disabledReason) {
                        changeColumnVisibility?.(id, hidden);
                      }
                    }}
                    onKeyUp={(event) => {
                      if (isUnmodifiedSpaceKey(event)) {
                        event.stopPropagation();
                        if (!disabledReason) {
                          changeColumnVisibility?.(id, hidden);
                        }
                      }
                    }}
                    role="button"
                    tabIndex={0}>
                    <div className="control">
                      <Form.CheckBox
                        checked={!hidden}
                        disabled={!!disabledReason}
                        onChange={() => { }}
                      />
                    </div>
                    <div className="content">
                      <div className="label">{labelContent}</div>
                      {description && (
                        <div className="description">{description}</div>
                      )}
                    </div>
                  </div>
                </Tooltip>
              );
            })}
          </div>
          <div className="divider" />
          <div className="actions">
            <ActionButton
              kind="minimal"
              onClick={(event) => {
                event.stopPropagation();
                context.resetColumnVisibility?.();
              }}
              size="small">
              Restore Defaults
            </ActionButton>
          </div>
        </div>
      );
    }}
  </TableContext.Consumer>
);
