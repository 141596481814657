// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily } from 'recoil';

import { NodeType, PLOT_CONTAINER_ID, SimulationTreeNode } from '../../../../lib/simulationTree/node';
import { plotNodesState } from '../../../../recoil/plotNodes';

export const plotSectionSelector = selectorFamily<SimulationTreeNode, string>({
  key: 'plotSection',
  get: (projectId: string) => ({ get }) => {
    const plots = get(plotNodesState(projectId));
    const plotNodes = plots.plots.map(
      ({ id, name }) => new SimulationTreeNode(NodeType.PLOT, id, name),
    );
    return new SimulationTreeNode(
      NodeType.PLOT_CONTAINER,
      PLOT_CONTAINER_ID,
      '2D Plots',
      plotNodes,
    );
  },
  dangerouslyAllowMutability: true,
});
