// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const SearchIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="search"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M23.326,20.148
           L19.323,16.131
           C20.373,14.497 20.988,12.563 20.988,10.494
           C20.988,4.692 16.295,0 10.494,0
           C4.692,0 0,4.692 0,10.494
           C0,16.296 4.692,20.988 10.494,20.988
           C12.578,20.988 14.511,20.358 16.13,19.324
           L20.148,23.326
           C20.553,23.731 21.122,23.986 21.737,23.986
           C22.981,23.986 23.986,22.981 23.986,21.737
           C23.986,21.123 23.731,20.553 23.326,20.148Z
           M10.494,17.99
           C6.356,17.99 2.998,14.632 2.998,10.494
           C2.998,6.357 6.356,2.999 10.494,2.999
           C14.631,2.999 17.989,6.357 17.989,10.494
           C17.989,14.632 14.631,17.99 10.494,17.99Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
