// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { CurrentView, getPathFromView } from '../../lib/componentTypes/context';
import { NODE_LINK_KEY, useSetNodeLinkState } from '../../recoil/useNodeLink';
import { TreeViewType, useTreeViewType } from '../../recoil/useTreeViewState';
import { useCurrentView } from '../../state/internal/global/currentView';
import { useIntermediateTreeNodeIds } from '../../state/internal/tree/setup';
import { useProjectContext } from '../context/ProjectContext';

import { useGetNodeFromAnyTree } from './useTree';

export const useNodeSelect = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Hooks
  const navigate = useNavigate();

  // == State
  const getNodeFromAnyTree = useGetNodeFromAnyTree();
  const setNodeLinkIds = useSetNodeLinkState();
  const [treeViewType, setTreeViewType] = useTreeViewType();
  const currentView = useCurrentView();
  const intermediateTreeNodeIds = useIntermediateTreeNodeIds({ projectId, workflowId, jobId });

  return useCallback((nodeId: string) => {
    let switchTabs = false;
    const nodeIsInTree = !!getNodeFromAnyTree(nodeId);
    // If we are in the Post-Processing view in the Analysis page, there's a chance that the
    // NodeLink is trying to link to a node that is not present in the current view and is only
    // available in the Setup Details view. If that seems to be the case, we should first open the
    // Setup Details view.
    if (
      treeViewType === TreeViewType.POST_PROCESSING &&
      currentView === CurrentView.ANALYSIS &&
      !nodeIsInTree
    ) {
      setTreeViewType(TreeViewType.SETUP);
    } else if (!nodeIsInTree) {
      let view = -1;
      let cancel = false;
      intermediateTreeNodeIds.forEach((nodeIdSet, index) => {
        if (nodeIdSet.has(nodeId)) {
          if (view !== -1) {
            cancel = true;
          }
          view = index;
        }
      });
      if (!cancel && view !== -1 && view !== currentView) {
        localStorage.setItem(NODE_LINK_KEY, JSON.stringify([nodeId]));
        navigate(getPathFromView(view, projectId));
        switchTabs = true;
      }
    }

    if (!switchTabs) {
      setNodeLinkIds([nodeId]);
    }
  }, [
    currentView,
    getNodeFromAnyTree,
    intermediateTreeNodeIds,
    navigate,
    projectId,
    setNodeLinkIds,
    setTreeViewType,
    treeViewType,
  ]);
};
