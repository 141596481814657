// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import { DialogSymbol } from '../visual/DialogSymbol';

import { ControlState, Dialog } from './Base';

export interface ConfirmationDialogProps {
  // Whether the dialog is open.
  open: boolean;
  // Called when the "OK" button is clicked
  onContinue: () => void | Promise<void>;
  // Called when the "Cancel" button is clicked
  onClose: () => void;
  // The dialog title
  title: string;
  // The dialog subtitle (optional)
  subtitle?: string;
  // The dialog content
  children?: ReactNode;
  // Indicate that the action is destructive
  destructive?: boolean;
  // Optional label for "Cancel" button (default: 'Cancel')
  cancelLabel?: string;
  // Optional label for "OK" button (default: 'Continue')
  continueLabel?: string;
  // Optionally set dialog's controlState (normal/disabled/working)
  controlState?: ControlState;
  // Optionally set a custom DialogSymbol
  symbol?: ReactNode;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const symbol = props.destructive ? (
    <DialogSymbol colorBase="red" iconName="diskExclamation" size={40} />
  ) : null;

  return (
    <Dialog
      {...props}
      cancelButton={{ label: props.cancelLabel, name: 'confirmationCancelButton' }}
      continueButton={{ label: props.continueLabel, name: 'confirmationContinueButton' }}
      modal
      symbol={props.symbol || symbol}>
      {props.children}
    </Dialog>
  );
};
