// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const CloudUploadIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="cloudUpload"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M13.065,10.935
           C12.795,10.665 12.42,10.5 12,10.5
           C11.58,10.5 11.205,10.665 10.935,10.935
           L6.435,15.435
           C6.165,15.705 6,16.08 6,16.5
           C6,17.325 6.675,18 7.5,18
           C7.92,18 8.295,17.835 8.565,17.565
           L10.5,15.615
           L10.5,22.5
           C10.5,23.325 11.175,24 12,24
           C12.825,24 13.5,23.325 13.5,22.5
           L13.5,15.615
           L15.435,17.55
           C15.705,17.835 16.08,18 16.5,18
           C17.325,18 18,17.325 18,16.5
           C18,16.08 17.835,15.705 17.565,15.435
           L13.065,10.935Z
           M18,6
           C17.955,6 17.895,6 17.85,6.015
           C17.16,2.58 14.13,0 10.5,0
           C6.36,0 3,3.36 3,7.5
           C3,7.665 3.015,7.83 3.03,7.995
           C1.245,8.835 0,10.65 0,12.75
           C0,14.895 1.29,16.74 3.135,17.55
           C3.06,17.205 3,16.86 3,16.5
           C3,15.255 3.51,14.13 4.32,13.32
           L8.82,8.82
           C9.63,8.01 10.755,7.5 12,7.5
           C13.245,7.5 14.37,8.01 15.18,8.82
           L19.68,13.32
           C20.49,14.13 21,15.255 21,16.5
           C21,16.74 20.97,16.98 20.925,17.205
           C22.755,16.17 24,14.25 24,12
           C24,8.685 21.315,6 18,6Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
