// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { MultipleChoiceParam } from '../ProtoDescriptor';
import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../SimulationParamDescriptor';
import * as simulationpb from '../proto/client/simulation_pb';

import { initParamGroupProto } from './initParam';
import { prefixNameGen, uniqueSequenceName } from './name';
import { findPhysicsById, getHeat } from './physicsUtils';
import { newNodeId } from './projectDataUtils';

export type ConfigurableHeatSourceType =
  | simulationpb.HeatSourceType.HEAT_SOURCE_TYPE_POWER
  | simulationpb.HeatSourceType.HEAT_SOURCE_TYPE_POWER_PER_UNIT_OF_VOLUME

/**
 * Generate a new heat source name
 * @param heat physics
 * @return new heat source name
 */
export function newName(heat: simulationpb.Heat): string {
  const existingNames: string[] = [];

  heat.heatSource.forEach((heatSource) => {
    existingNames.push(heatSource.heatSourceName);
  });
  return uniqueSequenceName(existingNames, prefixNameGen('Heat Source'));
}

/**
 * Returns a list of all heat source
 * @param param
 * @returns
 */
export function getAllHeatSources(param: simulationpb.SimulationParam) {
  return param.physics.reduce((result, physics) => {
    if (physics.params.case === 'heat') {
      result.push(...physics.params.value.heatSource);
    }
    return result;
  }, [] as (simulationpb.HeatSource)[]);
}

/**
 * Returns type-based prefix for naming a new physics
 * @param type
 * @returns
 */
export function getTypeLabel(type: ConfigurableHeatSourceType) {
  const param = paramDesc[ParamName.HeatSourceType] as MultipleChoiceParam;
  const choice = param.choices.find((item) => item.enumNumber === type);

  if (!choice) {
    throw Error(`Invalid heat source type ${type}`);
  }

  return choice.text;
}

/**
 * Find and return a heat source identified by ID
 * @param param
 * @param id
 * @returns
 */
export function findHeatSourceById(param: simulationpb.SimulationParam, id: string) {
  return param.physics.reduce((result, physics) => {
    if (result) {
      return result;
    }
    if (physics.params.case === 'heat') {
      return physics.params.value.heatSource.find((source) => source.heatSourceId === id);
    }
    return result;
  }, undefined as simulationpb.HeatSource | undefined);
}

/**
 * Find and return the heat transfer physics containing the heat source identified by ID
 * @param param
 * @param id
 * @returns
 */
export function findParentPhysicsByHeatSourceId(param: simulationpb.SimulationParam, id: string) {
  return param.physics.find((physics) => {
    if (physics.params.case === 'heat') {
      return physics.params.value.heatSource.some(
        (source) => source.heatSourceId === id,
      );
    }
    return false;
  });
}

/**
 * Create a new heat source and append it to the provided (heat transfer) physics
 * @param physics
 * @returns
 */
export function appendHeatSource(param: simulationpb.SimulationParam, physicsId: string) {
  const physics = findPhysicsById(param, physicsId);
  if (physics?.params.case === 'heat') {
    const heatSource = initParamGroupProto(
      new simulationpb.HeatSource(),
      paramGroupDesc[ParamGroupName.HeatSource],
    );

    heatSource.heatSourceId = newNodeId();
    heatSource.heatSourceName = newName(physics.params.value);

    physics.params.value.heatSource.push(heatSource);

    return heatSource;
  }

  return null;
}

/**
 * Remove a heat source (identified by ID) from its parent heat physics in a simulation param
 * @param param
 * @param id
 * @returns true iff an item was deleted
 */
export function removeHeatSource(param: simulationpb.SimulationParam, id: string): boolean {
  return param.physics.some((physics) => {
    if (physics.params.case === 'heat') {
      const heat = physics.params.value;
      const oldHeatSources = heat.heatSource;
      const newHeatSources = oldHeatSources.filter((source) => source.heatSourceId !== id);
      if (newHeatSources.length < oldHeatSources.length) {
        heat.heatSource = newHeatSources;
        return true;
      }
    }
    return false;
  });
}

/**
 * Detach domain (volume) for all heat sources in all physics
 * @param param
 * @param domain
 */
export function detachHeatSourceDomain(param: simulationpb.SimulationParam, domain: string): void {
  param.physics.forEach((physics) => {
    getHeat(physics)?.heatSource.forEach((source) => {
      source.heatSourceZoneIds = source.heatSourceZoneIds.filter((zoneId) => zoneId !== domain);
    });
  });
}

/**
 * Attach a domain (volume) to a heat source, returning true if successful
 * @param param
 * @param domain
 * @param heatSourceId
 * @returns
 */
export function attachHeatSourceDomain(
  param: simulationpb.SimulationParam,
  domain: string,
  heatSourceId: string,
) {
  const heatSource = findHeatSourceById(param, heatSourceId);
  if (heatSource) {
    // Since a volume may be associated with, at most, one heat source, detach it from any other
    // heat sources first.
    detachHeatSourceDomain(param, domain);

    if (!heatSource.heatSourceZoneIds.includes(domain)) {
      heatSource.heatSourceZoneIds.push(domain);
      return true;
    }
  }

  return false;
}
