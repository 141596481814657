// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { selectorFamily, waitForAll } from 'recoil';

import { AnyBoundaryCondition, isDependentBoundaryCondition, periodicPairId } from '../../../../lib/boundaryConditionUtils';
import { getPhysicsDomainsWithoutUnroll } from '../../../../lib/entityRelationships';
import { getMultiphysicsInterfaceName } from '../../../../lib/multiphysicsInterfaceUtils';
import {
  getPhysicsId,
  getPhysicsName,
  makeBoundaryConditionsId,
  makeHeatSourcesId,
  makeInitializationId,
  makePhysicalModelsId,
  makeVolumeSelectionId,
} from '../../../../lib/physicsUtils';
import { NodeType, PHYSICS_CONTAINER_NODE_ID, SimulationTreeNode } from '../../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../../lib/simulationTree/sectionRecoilKey';
import { getBoundaryCondName } from '../../../../lib/simulationTree/utils';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { simulationParamState } from '../../../external/project/simulation/param';
import { NamesRecord, simulationBoundaryNamesState } from '../../../external/project/simulation/param/boundaryNames';

export const MULTI_PHYSICS_NODE_ID = '__singletonMultiPhysics';

function sortBoundaryConditions<T extends AnyBoundaryCondition>(a: T, b: T) {
  const depA = isDependentBoundaryCondition(a);
  if (depA !== isDependentBoundaryCondition(b)) {
    return depA ? 1 : -1;
  }
  return 0;
}

function getPhysicsSubNodes(
  simParam: simulationpb.SimulationParam,
  physics: simulationpb.Physics,
  boundaryConditionNames: NamesRecord,
): SimulationTreeNode[] {
  const physicsId = getPhysicsId(physics);

  const nodes: SimulationTreeNode[] = [
    new SimulationTreeNode(
      NodeType.PHYSICS_VOLUME_SELECTION,
      makeVolumeSelectionId(physicsId),
      'Volume Selection',
    ),
  ];

  const hasVolumes = !!getPhysicsDomainsWithoutUnroll(simParam, physicsId).size;

  if (physics.params.case === 'fluid') {
    const fluid = physics.params.value;
    const boundaryConditions = fluid.boundaryConditionsFluid;
    boundaryConditions.sort(sortBoundaryConditions);

    const boundaryConditionChildren: SimulationTreeNode[] = [];
    const physicalModelChildren: SimulationTreeNode[] = fluid.physicalBehavior.map(
      (behavior) => new SimulationTreeNode(
        NodeType.PHYSICAL_BEHAVIOR,
        behavior.physicalBehaviorId,
        behavior.physicalBehaviorName,
      ),
    );

    if (hasVolumes) {
      physicalModelChildren.push(
        ...fluid.porousBehavior.map((model) => (
          new SimulationTreeNode(
            NodeType.POROUS_MODEL,
            model.porousBehaviorId,
            model.porousBehaviorName,
          )
        )) || [],
      );
      boundaryConditionChildren.push(
        ...boundaryConditions.map(
          (bc) => new SimulationTreeNode(
            NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION,
            bc.boundaryConditionName,
            getBoundaryCondName(boundaryConditionNames, bc),
          ),
        ),
        ...fluid.periodicPair.map((pair, i) => new SimulationTreeNode(
          NodeType.PHYSICS_PERIODIC_PAIR,
          periodicPairId(physicsId, i),
          pair.periodicPairName || `Periodic Pair ${i + 1}`,
        )) || [],
        ...fluid.slidingInterfaces.map(
          (intf, i) => new SimulationTreeNode(
            NodeType.PHYSICS_SLIDING_INTERFACE,
            intf.slidingInterfaceId,
            intf.slidingInterfaceName,
          ),
        ) || [],
      );
    }

    nodes.push(
      new SimulationTreeNode(
        NodeType.PHYSICS_FLUID_PHYSICAL_MODEL_CONTAINER,
        makePhysicalModelsId(physicsId),
        'Physical Models',
        physicalModelChildren,
      ),
      new SimulationTreeNode(
        NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION_CONTAINER,
        makeBoundaryConditionsId(physicsId),
        'Boundary Conditions',
        boundaryConditionChildren,
      ),
      new SimulationTreeNode(
        NodeType.PHYSICS_FLUID_INITIALIZATION,
        makeInitializationId(physicsId),
        'Initialization',
      ),
    );
  }

  if (physics.params.case === 'heat') {
    const heat = physics.params.value;
    const boundaryConditions = heat.boundaryConditionsHeat || [];
    boundaryConditions.sort(sortBoundaryConditions);

    const heatSourceChildren: SimulationTreeNode[] = [];
    const boundaryConditionChildren: SimulationTreeNode[] = [];

    if (hasVolumes) {
      heatSourceChildren.push(
        ...heat.heatSource.map((source, i) => new SimulationTreeNode(
          NodeType.PHYSICS_HEAT_HEAT_SOURCE,
          source.heatSourceId,
          source.heatSourceName,
        )) || [],
      );
      boundaryConditionChildren.push(
        ...boundaryConditions.map(
          (bc) => new SimulationTreeNode(
            NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION,
            bc.boundaryConditionName,
            getBoundaryCondName(boundaryConditionNames, bc),
          ),
        ),
        ...heat.periodicPair.map((pair, i) => new SimulationTreeNode(
          NodeType.PHYSICS_PERIODIC_PAIR,
          periodicPairId(physicsId, i),
          pair.periodicPairName || `Periodic Pair ${i + 1}`,
        )) || [],
        ...heat.slidingInterfaces.map(
          (intf, i) => new SimulationTreeNode(
            NodeType.PHYSICS_SLIDING_INTERFACE,
            intf.slidingInterfaceId,
            intf.slidingInterfaceName,
          ),
        ) || [],
      );
    }

    nodes.push(
      new SimulationTreeNode(
        NodeType.PHYSICS_HEAT_HEAT_SOURCE_CONTAINER,
        makeHeatSourcesId(physicsId),
        'Heat Sources',
        heatSourceChildren,
      ),
      new SimulationTreeNode(
        NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION_CONTAINER,
        makeBoundaryConditionsId(physicsId),
        'Boundary Conditions',
        boundaryConditionChildren,
      ),
      new SimulationTreeNode(
        NodeType.PHYSICS_HEAT_INITIALIZATION,
        makeInitializationId(physicsId),
        'Initialization',
      ),
    );
  }

  return nodes;
}

export const physicsSectionSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'physicsSection',
  get: (key: SectionRecoilKey) => ({ get }) => {
    const [boundaryConditionNames, param] = get(waitForAll([
      simulationBoundaryNamesState(key),
      simulationParamState(key),
    ]));
    const allPhysics = param.physics;

    // Construct the root frame
    const children = allPhysics.reduce((result, physics) => {
      const id = getPhysicsId(physics);
      const name = getPhysicsName(physics, param);
      const subNodes = getPhysicsSubNodes(param, physics, boundaryConditionNames);
      switch (physics.params.case) {
        case 'fluid': {
          result.push(new SimulationTreeNode(NodeType.PHYSICS_FLUID, id, name, subNodes));
          break;
        }
        case 'heat': {
          result.push(new SimulationTreeNode(NodeType.PHYSICS_HEAT, id, name, subNodes));
          break;
        }
        default: {
          // no default, enum exhausted
        }
      }
      return result;
    }, [] as SimulationTreeNode[]);

    if (allPhysics.length > 1) {
      children.push(
        new SimulationTreeNode(
          NodeType.PHYSICS_MULTI,
          MULTI_PHYSICS_NODE_ID,
          'Multiphysics Coupling',
          param.interfaces.map((multiphysicsInterface, i) => new SimulationTreeNode(
            NodeType.PHYSICS_MULTI_INTERFACE,
            multiphysicsInterface.slidingInterfaceId,
            getMultiphysicsInterfaceName(multiphysicsInterface, i),
          )),
        ),
      );
    }

    return new SimulationTreeNode(
      NodeType.PHYSICS_CONTAINER,
      PHYSICS_CONTAINER_NODE_ID,
      'Physics',
      children,
    );
  },
  dangerouslyAllowMutability: true,
});
