// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import cx from 'classnames';

import * as flags from '../../flags';
import { isIntermediateView } from '../../lib/componentTypes/context';
import { FormControlSize } from '../../lib/componentTypes/form';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { BATCH_PROCESSING_URL } from '../../lib/constants';
import { colors } from '../../lib/designSystem';
import { copyJobConfig } from '../../lib/proto';
import { getRunButtonText, getSimCount } from '../../lib/simulationTree/utils';
import * as workflowpb from '../../proto/workflow/workflow_pb';
import { useNoCredits } from '../../recoil/useAccountInfo';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useIsBaselineMode, useIsExplorationStarterPlan } from '../../recoil/useProjectPage';
import { useBatchModeChecked, useCurrentConfig } from '../../recoil/workflowConfig';
import { analytics } from '../../services/analytics';
import { useRunSimulationPending } from '../../state/external/project/simulation/runSimulationPending';
import { useSetSetupSummaryOpened } from '../../state/external/project/simulation/setupSummaryOpened';
import { useCurrentView, useIsGeometryView, useIsSetupView } from '../../state/internal/global/currentView';
import { useWorkflowFlagValue } from '../../workflowFlag';
import { SplitButton, SplitKind } from '../Button/SplitButton';
import { RadioButton } from '../Form/RadioButton';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { useProjectContext } from '../context/ProjectContext';
import { useIsLMAActive } from '../hooks/useMesh';
import { useClickRunSimulationButton, useRunSimulationButtonProps } from '../hooks/useRunSimulation';
import { useRuntimeEstimate } from '../hooks/useRuntimeEstimate';
import { LeveledMessageList } from '../notification/LeveledMessageList';
import { ClockResetIcon } from '../svg/ClockResetIcon';
import { DiskInfoIcon } from '../svg/DiskInfoIcon';

const useStyles = makeStyles(
  () => createStyles({
    runControl: {
      flex: '0 0 auto',
      display: 'flex',

      '&.padding': {
        padding: '8px',
      },
    },
    runButton: {
      flex: '1 1 auto',
    },
    batchDelayIcon: {
      display: 'flex',
    },
    optionsMenuItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    radioOptionsIcon: {
      flexGrow: 1,
    },
    batchProcessingLink: {
      display: 'flex',
      alignItems: 'center',
    },
    setupSummaryDropdownLabel: {
      // this matches the radio button we show for the other dropdown items
      paddingLeft: '24px',
    },
  }),
  { name: 'RunSimulationButton' },
);

function updateBatchPriority(oldConfig: workflowpb.Config, batch: boolean): workflowpb.Config {
  const newConfig = copyJobConfig(oldConfig);
  const priority = new workflowpb.Priority({ batch });
  newConfig.jobConfigTemplate!.priority = priority;
  return newConfig;
}

interface RunSimulationButtonProps {
  size?: FormControlSize;
  kind?: SplitKind;
  padding?: boolean;
}

const RunSimulationButton = (props: RunSimulationButtonProps) => {
  const { size = 'small', kind = 'primary', padding } = props;
  const {
    projectId,
    workflowId,
    jobId,
    onNewWorkflowConfig,
  } = useProjectContext();
  const { disabled, messages } = useRunSimulationButtonProps();
  const [runSimulationPending] = useRunSimulationPending();
  const setSummaryOpened = useSetSetupSummaryOpened();
  const currentConfig = useCurrentConfig(projectId, workflowId, jobId);
  const classes = useStyles();
  const experimentConfig = useEnabledExperiments();
  const runtimeEstimate = useRuntimeEstimate();
  const isGeometryView = useIsGeometryView();
  const isSetupView = useIsSetupView();
  const currentView = useCurrentView();
  const workflowFlag = useWorkflowFlagValue();
  const clickRunSimulationButton = useClickRunSimulationButton();
  const isBaselineMode = useIsBaselineMode();
  const showBatchMode = !isGeometryView;
  const batchModeChecked = useBatchModeChecked(projectId, workflowId, jobId);
  const isLMA = useIsLMAActive();
  const exploration = currentConfig.exploration;
  const isSensitivityAnalysis = exploration?.policy.case === 'sensitivityAnalysis';

  const runButtonText = getRunButtonText(
    currentView,
    isBaselineMode,
    isSensitivityAnalysis,
    currentConfig,
    isLMA,
  );

  const hasNoCredits = useNoCredits();
  const isExplorationButtonDisabled = useIsExplorationStarterPlan();

  // The setup summary should be present only when creating an individual simulation
  const hasSetupSummary = (
    isSetupView || (workflowFlag && isIntermediateView(currentView))
  ) && isBaselineMode;

  let runtimeText = '';
  if (experimentConfig.includes(flags.runtimeEstimate) && getSimCount(currentConfig) === 1) {
    switch (runtimeEstimate.state) {
      case 'hasValue': {
        const duration = runtimeEstimate.contents.duration;
        if (duration > 30) {
          runtimeText = 'Runtime: >30 min';
        } else if (duration > 0) {
          runtimeText = `Runtime: <${Math.ceil(duration)} min`;
        } else {
          runtimeText = 'Runtime: Not available';
        }
      }
        break;
      case 'loading':
        runtimeText = 'Estimating Runtime ...';
        break;
      case 'hasError':
      default:
        throw Error('Error estimating runtime');
    }
  }

  const tooltip = messages.length ?
    <LeveledMessageList maxToShow={10} messages={messages} /> :
    runtimeText;

  const menuItems: CommonMenuItem[] = [];
  if (hasSetupSummary) {
    menuItems.push({
      label: <div className={classes.setupSummaryDropdownLabel}>Setup Summary</div>,
      onClick: () => {
        setSummaryOpened(true);
        analytics.track('Setup Summary Opened');
      },
    });
  }
  if (showBatchMode) {
    if (menuItems.length) {
      menuItems.push({ separator: true });
    }
    menuItems.push(
      {
        label: (
          <div className={classes.optionsMenuItem}>
            <RadioButton
              checked={!batchModeChecked}
              name="runMode"
              onClick={() => { }}
              value="normal"
            />
            Run Immediately
          </div>
        ),
        nameAttribute: 'run-option-normal',
        onClick: () => {
          onNewWorkflowConfig(updateBatchPriority(currentConfig, false));
          analytics.track('Batch Mode Toggled', { enabled: false });
        },
      },
      {
        label: (
          <div className={classes.optionsMenuItem}>
            <RadioButton
              checked={batchModeChecked}
              name="runMode"
              onClick={() => { }}
              value="batch"
            />
            <div className={classes.radioOptionsIcon}>
              Delayed Start and Save
            </div>
            <Tooltip title={`Delay the start of your simulation using Batch Processing and save
            credits. Click to learn more`}>
              <a
                className={classes.batchProcessingLink}
                href={BATCH_PROCESSING_URL}
                onClick={(event) => event.stopPropagation()}
                rel="noopener noreferrer"
                target="_blank">
                <DiskInfoIcon color={colors.lowEmphasisText} maxHeight={12} maxWidth={12} />
              </a>
            </Tooltip>
          </div>
        ),
        nameAttribute: 'run-option-batch',
        onClick: () => {
          onNewWorkflowConfig(updateBatchPriority(currentConfig, true));
          analytics.track('Batch Mode Toggled', { enabled: true });
        },
      },
    );
  }

  return (
    <div className={cx(classes.runControl, { padding })}>
      <div className={classes.runButton}>
        <SplitButton
          asBlock
          dataLocator="runSimulationButton"
          disabled={runSimulationPending || disabled || hasNoCredits || isExplorationButtonDisabled}
          kind={kind}
          menuItems={menuItems}
          onClick={clickRunSimulationButton}
          showSpinner={runSimulationPending}
          size={size}
          splitIcon={{ name: workflowFlag ? 'gear' : 'verticalControls', maxHeight: 12 }}>
          {batchModeChecked && (
            <Tooltip title="Delayed Start and Save Active">
              <span className={classes.batchDelayIcon} data-locator="runSimulationBatchDelayIcon">
                <ClockResetIcon maxHeight={12} />
              </span>
            </Tooltip>
          )}
          <Tooltip title={tooltip}>
            <span>{runButtonText.label}</span>
          </Tooltip>
        </SplitButton>
      </div>
    </div>
  );
};

export default RunSimulationButton;
