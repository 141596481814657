// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ParamGroupName, paramGroupDesc } from '../../../SimulationParamDescriptor';
import { colors } from '../../../lib/designSystem';
import { useSolutionOutput } from '../../../model/hooks/useSolutionOutput';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { useSimulationParamScope } from '../../../state/external/project/simulation/paramScope';
import { ParamForm } from '../../ParamForm';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import PropertiesSection from '../PropertiesSection';

export const ItersPerOutputPropPanel = () => {
  // Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // Recoil
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // Hooks
  const {
    solutionOutput,
    setSolutionOutput,
  } = useSolutionOutput(projectId, workflowId, jobId, readOnly);
  const propClasses = useCommonTreePropsStyles();

  return (
    <>
      <div className={propClasses.properties}>
        <div style={{ padding: '8px', fontSize: '13px', color: colors.lowEmphasisText }}>
          Iterations per output for entire fluid volume solution.
          <br />
          We recomment 250 iterations/output.
        </div>
        <PropertiesSection>
          <ParamForm<simulationpb.Output>
            group={paramGroupDesc[ParamGroupName.Output]}
            onUpdate={setSolutionOutput}
            paramScope={paramScope}
            proto={solutionOutput}
            readOnly={readOnly}
          />
        </PropertiesSection>
      </div>
    </>
  );
};
