// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { MultipleChoiceParam } from '../ProtoDescriptor';
import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../SimulationParamDescriptor';
import * as entitypb from '../proto/client/entity_pb';
import * as simulationpb from '../proto/client/simulation_pb';

import assert from './assert';
import { createIdentifier } from './baseParamUtils';
import { initParamGroupProto } from './initParam';
import { ModelDataMap, ModelDatum } from './modelUtils';
import { uniqueSequenceName } from './name';

export type MaterialDatum = ModelDatum<simulationpb.MaterialEntity>;
export type MaterialDataMap = ModelDataMap<simulationpb.MaterialEntity>;

export type ConfigurableMaterialType = 'materialFluid' | 'materialSolid';

const { IDEAL_GAS } = simulationpb.DensityRelationship;
const { CUSTOM_MATERIAL_FLUID, STANDARD_AIR, WATER_NTP } = simulationpb.MaterialFluidPreset;
const {
  CUSTOM_MATERIAL_SOLID,
  ALUMINUM,
  COPPER,
  IRON,
  NICKEL,
  TITANIUM,
} = simulationpb.MaterialSolidPreset;

export interface MaterialsByType {
  solids: MaterialDatum[];
  fluids: MaterialDatum[];
}

// The set of fluid material presets that are considered "fixed"; that is, they prescribe field
// values in a fluid material and should only be used once per project.
export const FIXED_FLUID_PRESETS: simulationpb.MaterialFluidPreset[] = [STANDARD_AIR, WATER_NTP];

/**
 * The set of solid material presets that are considered "fixed"; that is, they prescribe field
 * values in a solid material and should only be used once per project.
 */
export const FIXED_SOLID_PRESETS: simulationpb.MaterialSolidPreset[] = [
  ALUMINUM,
  COPPER,
  IRON,
  NICKEL,
  TITANIUM,
];

// The presets available to fluid materials
export const GROUPED_FLUID_PRESETS = [
  [STANDARD_AIR, WATER_NTP],
  [CUSTOM_MATERIAL_FLUID],
];

/** The presets available for solid materials */
export const GROUPED_SOLID_PRESETS = [
  [ALUMINUM, COPPER, IRON, NICKEL, TITANIUM],
  [CUSTOM_MATERIAL_SOLID],
];

const paramDensity = paramDesc[ParamName.DensityRelationship] as MultipleChoiceParam;
export const FLUID_PRESET_CHOICES = (
  paramDesc[ParamName.MaterialFluidPreset] as MultipleChoiceParam
).choices;
export const SOLID_PRESET_CHOICES = (
  paramDesc[ParamName.MaterialSolidPreset] as MultipleChoiceParam
).choices;

/**
 * Returns type-based prefix for naming a new material
 */
export function getTypeLabel(type: simulationpb.MaterialEntity['material']['case']) {
  switch (type) {
    case 'materialFluid': {
      return 'Fluid';
    }
    case 'materialSolid': {
      return 'Solid';
    }
    case undefined: {
      return 'Unknown';
    }
    default:
      throw Error('Invalid material type');
  }
}

/**
 * Given a material object, return its EntityIdentifier object, creating one if necessary
 * @param material
 * @returns EntityIdentifier
 */
export function getOrCreateMaterialIdentifier(
  material: simulationpb.MaterialEntity,
): entitypb.EntityIdentifier {
  if (!material.materialIdentifier) {
    material.materialIdentifier = new entitypb.EntityIdentifier();
  }
  return material.materialIdentifier!;
}

/**
 * If a material entity is of the 'materialFluid' type, return the MaterialFluid value; return
 * undefined otherwise
 * @param materialEntity
 * @returns
 */
export function getMaterialFluid(
  materialEntity: simulationpb.MaterialEntity,
): simulationpb.MaterialFluid | undefined {
  if (materialEntity.material.case === 'materialFluid') {
    return materialEntity.material.value;
  }
  return undefined;
}

/**
 * If a material entity is of the 'materialSolid' type, return the MaterialSolid value; return
 * undefined otherwise
 * @param materialEntity
 * @returns
 */
export function getMaterialSolid(
  materialEntity: simulationpb.MaterialEntity,
): simulationpb.MaterialSolid | undefined {
  if (materialEntity.material.case === 'materialSolid') {
    return materialEntity.material.value;
  }
  return undefined;
}

/**
 * Since the material identifier is optional (a proto thing), this wrapper function is used both to
 * return a material ID and throw if it isn't set.
 */
export function getMaterialId(materialEntity: simulationpb.MaterialEntity): string {
  const id = materialEntity.materialIdentifier?.id;
  assert(!!id, 'Material is missing an ID');
  return id;
}

/**
 * Returns the name of any fixed preset associated with a material
 * @param material
 * @returns
 */
export function getMaterialPresetName(material: simulationpb.MaterialEntity): string {
  const fluidPreset = (material.material.case === 'materialFluid') ?
    material.material.value.materialFluidPreset : null;
  if (fluidPreset && FIXED_FLUID_PRESETS.includes(fluidPreset)) {
    const presetChoice = FLUID_PRESET_CHOICES.find((choice) => choice.enumNumber === fluidPreset);
    return presetChoice!.text;
  }

  const solidPreset = (material.material.case === 'materialSolid') ?
    material.material.value.materialSolidPreset : null;
  if (solidPreset && FIXED_SOLID_PRESETS.includes(solidPreset)) {
    const presetChoice = SOLID_PRESET_CHOICES.find((choice) => choice.enumNumber === solidPreset);
    return presetChoice!.text;
  }

  return '';
}
/**
 * Returns the name to display for a material.  If the material uses a fixed preset, the preset's
 * name is returned instead of the user-configured name
 */
export function getMaterialName(
  material: simulationpb.MaterialEntity,
  param: simulationpb.SimulationParam,
  options: { skipPresets?: boolean } = {},
) {
  if (!options.skipPresets) {
    const presetName = getMaterialPresetName(material);
    if (presetName) {
      return presetName;
    }
  }

  const name = material.materialIdentifier?.name || '';
  if (name) {
    return name;
  }

  const materialCase = material.material.case;
  const label = `${getTypeLabel(materialCase)}`;

  const id = getMaterialId(material);
  const materialsOfType = param.materialEntity.filter(
    (item) => item.material.case === materialCase,
  );
  const index = materialsOfType.findIndex((item) => getMaterialId(item) === id);

  if (index < 0) {
    return `[${label}]`;
  }

  return `[${label} ${index + 1}]`;
}

/**
 * Looks for a material entity in a simulation and returns the result or undefined
 */
export function findMaterialEntityById(param: simulationpb.SimulationParam, id: string) {
  return param.materialEntity.find((item) => getMaterialId(item) === id);
}

/**
 * Return true iff the material type is Fluid
 * @param material
 * @returns
 */
export function isMaterialFluid(material: simulationpb.MaterialEntity): boolean {
  return material.material.case === 'materialFluid';
}

/**
 * Return true iff the material type is Solid
 * @param material
 * @returns
 */
export function isMaterialSolid(material: simulationpb.MaterialEntity): boolean {
  return material.material.case === 'materialSolid';
}

/**
 * Return an object of materials bucketed by keys 'fluids' and 'solids'
 * @param entities
 * @returns
 */
export function bucketMaterialsByType(materialData: MaterialDatum[]): MaterialsByType {
  const result: MaterialsByType = { fluids: [], solids: [] };

  materialData.forEach((materialDatum) => {
    if (isMaterialFluid(materialDatum.model)) {
      result.fluids.push(materialDatum);
    } else if (isMaterialSolid(materialDatum.model)) {
      result.solids.push(materialDatum);
    }
  });

  return result;
}

/**
 * Generates an EntityIdentifier for a material type (FLUID or SOLID), automatically generating a
 * unique, incrementally numbered name for that material type
 */
function createMaterialIdentifier(
  param: simulationpb.SimulationParam,
  type: ConfigurableMaterialType,
) {
  const existingNames: string[] = param.materialEntity.reduce((result, material) => {
    if (material.material.case === type) {
      const name = material.materialIdentifier?.name;
      if (name) {
        result.push(name);
      }
    }
    return result;
  }, [] as string[]);

  const prefix = getTypeLabel(type);
  const newName = uniqueSequenceName(existingNames, (count: number) => `${prefix} ${count}`);

  return createIdentifier(newName);
}

/**
 * Creates a new material by type (not exported, used internally)
 */
function createMaterial(type: ConfigurableMaterialType) {
  switch (type) {
    case 'materialFluid': {
      return initParamGroupProto(
        new simulationpb.MaterialFluid(),
        paramGroupDesc[ParamGroupName.MaterialFluid],
      );
    }
    case 'materialSolid': {
      return initParamGroupProto(
        new simulationpb.MaterialSolid(),
        paramGroupDesc[ParamGroupName.MaterialSolid],
      );
    }
    default: {
      throw Error('Invalid material type');
    }
  }
}

/**
 * Creates a new material entity by type (not exported, used internally)
 *   A material entity is a wrapper for a fluid material or a solid material, and it allows the
 *   root to define a single-type list for materials, since protos don't support union type lists--
 *   e.g. (MaterialFluid | MaterialSolid)[].
 */
function createMaterialEntity(
  simParam: simulationpb.SimulationParam,
  type: ConfigurableMaterialType,
) {
  const entity = initParamGroupProto(
    new simulationpb.MaterialEntity(),
    paramGroupDesc[ParamGroupName.MaterialEntity],
  );
  entity.materialIdentifier = createMaterialIdentifier(simParam, type);
  return entity;
}

/**
 * Append a fluid material to the simulation param
 */
export function appendFluidMaterial(simParam: simulationpb.SimulationParam) {
  const entity = createMaterialEntity(simParam, 'materialFluid');
  const material = createMaterial('materialFluid');
  entity.material = { case: 'materialFluid', value: material as simulationpb.MaterialFluid };
  simParam.materialEntity.push(entity);
  return getMaterialId(entity);
}

/**
 * Append a solid material to the simulation param
 */
export function appendSolidMaterial(simParam: simulationpb.SimulationParam) {
  const entity = createMaterialEntity(simParam, 'materialSolid');
  const material = createMaterial('materialSolid');
  entity.material = { case: 'materialSolid', value: material as simulationpb.MaterialSolid };
  simParam.materialEntity.push(entity);
  return getMaterialId(entity);
}

export function appendMaterial(
  simParam: simulationpb.SimulationParam,
  type: ConfigurableMaterialType,
) {
  switch (type) {
    case 'materialFluid': {
      return appendFluidMaterial(simParam);
    }
    case 'materialSolid': {
      return appendSolidMaterial(simParam);
    }
    default:
      // Shouldn't get here, type enum is exhausted, but TS demands it
      throw Error('Unknown material type');
  }
}

/**
 * Return a list or enum numbers that should be disabled in a Material form, based on the preset.
 * For example, when the preset is Water, the density relationship drop-down should be enabled, so
 * the user can select between constant density and constant density with energy equation, but the
 * ideal gas option doesn't make sense as an option.
 * @param preset
 * @returns
 */
export function disabledEnumValues(preset: simulationpb.MaterialFluidPreset) {
  return paramDensity.choices.reduce((result, choice) => {
    if (preset === WATER_NTP && choice.enumNumber === IDEAL_GAS) {
      result.push(choice.enumNumber);
    }
    return result;
  }, [] as number[]);
}

export function getMaterialsByIdFromMap(
  materialsMap: MaterialDataMap,
  ids: string[] | Set<string>,
) {
  const result: MaterialDatum[] = [];

  ids.forEach((id) => {
    const datum = materialsMap[id];
    if (datum) {
      result.push(datum);
    }
  });

  return result;
}

/**
 * Renames a material (identified by ID), returning true if a rename took place
 */
export function renameMaterial(
  param: simulationpb.SimulationParam,
  id: string,
  name: string,
): boolean {
  const material = findMaterialEntityById(param, id);
  if (material) {
    getOrCreateMaterialIdentifier(material).name = name;
    return true;
  }
  return false;
}
