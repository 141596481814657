// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

// WarmClusterDialog shows a dialog box which allows the user
// to warm some nodes in the cluster.  This feature is only available
// for staff users.

import React, { useEffect, useState } from 'react';

import { Empty } from '@bufbuild/protobuf';
import cx from 'classnames';

import { ColumnConfig } from '../../lib/componentTypes/table';
import { colors } from '../../lib/designSystem';
import { fromBigInt } from '../../lib/number';
import * as rpc from '../../lib/rpc';
import { addRpcError } from '../../lib/transientNotification';
import * as clusterconfigpb from '../../proto/clusterconfig/clusterconfig_pb';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import useServerInfo from '../../recoil/useServerInfo';
import { useAuthInfoV2Value } from '../../state/external/auth/authInfo';
import { ActionButton } from '../Button/ActionButton';
import { IconButton } from '../Button/IconButton';
import { DataSelect } from '../Form/DataSelect';
import { makeStyles } from '../Theme';
import { Table } from '../data/Table';
import { ChevronDownIcon } from '../svg/ChevronDownIcon';
import { ChevronUpIcon } from '../svg/ChevronUpIcon';
import Collapsible from '../transition/Collapsible';

import { ControlState, Dialog } from './Base';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '8px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    justifyItems: 'stretch',
    alignItems: 'center',
    width: '100%',
    transition: 'opacity 250ms',
    '&.busy': {
      opacity: 0.5,
    },
  },
  gridCell: {
    fontWeight: 400,
    fontSize: '14px',
    '&.header': {
      fontWeight: 600,
      fontSize: '13px',
      alignSelf: 'end',
    },
    '&.spanned': {
      gridColumn: 'span 2',
    },
    '&.spanned, &.header': {
      borderBottom: `1px solid ${colors.surfaceLight1}`,
    },
  },
  gridCellContent: {
    padding: '4px',
  },
  innerTable: {
    padding: '0 4px',
    transition: 'padding 250ms',
    '&.open': {
      padding: '4px 4px 8px',
    },
  },
}), { name: 'WarmClusterDialog' });

const reservationColumnConfigs: ColumnConfig[] = [
  { id: 'gpu_type', label: 'GPU Type', type: 'number' },
  { id: 'node_pool', label: 'Node Pool', type: 'string' },
  { id: 'expiry', label: 'Expiry', type: 'number', format: 'datetime' },
];

interface ReservationRow {
  user: string;
  reservation: clusterconfigpb.NodeReservationState[] | null;
}

interface GridRowProps extends ReservationRow {
  busy: boolean;
}

const GridRow = (props: GridRowProps) => {
  const { busy, reservation, user } = props;

  const [open, setOpen] = useState(false);

  const classes = useStyles();

  if (!reservation) {
    return (<></>);
  }

  const rowConfigs = reservation.map((res, index) => {
    // The unix timestamp should always be in safe range.
    const expiry = fromBigInt(res.request!.expiry);

    return {
      id: `${index}`,
      values: {
        gpu_type: res.request?.gpuType,
        node_pool: res.nodePool,
        expiry: expiry === undefined ? expiry : expiry * 1000,
      },
    };
  });

  return (
    <>
      <div className={classes.gridCell}>
        <div className={classes.gridCellContent}>
          <IconButton disabled={busy} onClick={() => setOpen(!open)}>
            {open ? <ChevronDownIcon maxWidth={12} /> : <ChevronUpIcon maxWidth={12} />}
          </IconButton>
        </div>
      </div>
      <div className={classes.gridCell}>
        <div className={classes.gridCellContent}>{user}</div>
      </div>
      <div className={cx(classes.gridCell, 'spanned')}>
        <Collapsible collapsed={!open}>
          <div className={cx(classes.innerTable, { open })}>
            <Table
              asBlock
              columnConfigs={reservationColumnConfigs}
              disableColumnSettings
              disableSorting
              name={`user-${user}-reservations`}
              rowConfigs={rowConfigs}
            />
          </div>
        </Collapsible>
      </div>
    </>
  );
};

interface ReservationsGridProps {
  busy: boolean;
  rows: ReservationRow[];
}

const ReservationsGrid = (props: ReservationsGridProps) => {
  const { busy, rows } = props;
  const classes = useStyles();
  return (
    <div className={cx(classes.grid, { busy })}>
      <div className={cx(classes.gridCell, 'header')} />
      <div className={cx(classes.gridCell, 'header')}>
        <div className={classes.gridCellContent}>User</div>
      </div>
      {rows.map((row) => (<GridRow key={row.user} {...row} busy={busy} />))}
    </div>
  );
};

function reservationList(gpuType: clusterconfigpb.GPUType): clusterconfigpb.NodeReservation[] {
  // For now, just use default settings that will work for our demos.
  // TODO(bamo) Allow custom configuration of node pools, number of nodes,
  // and warm cluster duration.
  const reservation: clusterconfigpb.NodeReservation[] = [];

  // By default, a "Warm Cluster" request lasts for an hour.
  const inOneHour = BigInt(Math.floor(Date.now() / 1000) + 3600);

  const nodesToReserve = [
    { typ: gpuType, nPu: 1, nNode: 8 },
    { typ: gpuType, nPu: 8, nNode: 2 },
  ];
  nodesToReserve.forEach(({ typ, nPu, nNode }) => {
    for (let i = 0; i < nNode; i += 1) {
      const req = new clusterconfigpb.NodeReservation({
        gpuType: typ,
        nPu,
        expiry: inOneHour,
      });
      reservation.push(req);
    }
  });
  return reservation;
}

export interface WarmClusterProps {
  open: boolean;
  onClose: () => void;
}

export const WarmClusterDialog = (props: WarmClusterProps) => {
  const [
    reservedNodes,
    setReservedNodes,
  ] = useState<clusterconfigpb.NodeReservationState[] | null>(null);
  const serverInfo = useServerInfo();
  const [controlState, setControlState] = useState<ControlState>('normal');
  const [refreshing, setRefreshing] = useState(false);
  const [gpuType, setGpuType] = useState<clusterconfigpb.GPUType>(clusterconfigpb.GPUType.V100);
  const authInfoV2 = useAuthInfoV2Value();

  const classes = useStyles();

  const refreshUserReservation = (
    callback: (reservation: clusterconfigpb.NodeReservationState[]) => void,
  ) => {
    setRefreshing(true);
    rpc.callRetry('ReservedNodes', rpc.client.reservedNodes, new Empty()).then(
      (reply: frontendpb.ReservedNodesReply) => callback(reply.reservation),
    ).catch((err: Error) => {
      addRpcError('Error fetching user reservation', err);
    }).finally(() => setRefreshing(false));
  };

  useEffect(() => {
    let updateState = true;
    refreshUserReservation((nodes) => {
      if (updateState) {
        setReservedNodes(nodes);
      }
    });

    // Do not try to update the state after the component is unmounted
    return () => {
      updateState = false;
    };
  }, []);

  const activeReservation = reservedNodes && reservedNodes.length > 0;
  const buttonText = activeReservation ? 'Cool Cluster' : 'Warm Cluster';
  const buttonHelp = activeReservation ?
    'Cool down already-warmed nodes in the cluster.' :
    'Warm up some nodes in the cluster.';

  const user = authInfoV2.jwt ? authInfoV2.jwt.email : '';

  const warmCluster = async () => {
    setControlState('working');
    const nodesToWarm = reservationList(gpuType);
    const req = new frontendpb.ReserveNodesRequest({
      reservation: nodesToWarm,
    });
    if (!serverInfo?.clusterConfig) {
      throw Error('WarmCluster: No server info available');
    }

    await rpc.callRetry('ReserveNodes', rpc.client.reserveNodes, req).catch(
      (err: Error) => addRpcError('Error warming cluster', err),
    ).finally(() => {
      refreshUserReservation(setReservedNodes);
      setControlState('normal');
    });
  };

  const coolCluster = async () => {
    setControlState('working');
    await rpc.callRetry('CancelReservedNodes', rpc.client.cancelReservedNodes, new Empty()).catch(
      (err: Error) => addRpcError('Error cooling cluster', err),
    ).finally(() => {
      refreshUserReservation(setReservedNodes);
      setControlState('normal');
    });
  };

  const handleContinue = async () => {
    if (activeReservation) {
      await coolCluster();
    } else {
      await warmCluster();
    }
  };

  return (
    <Dialog
      cancelButton={{ label: 'Close' }}
      continueButton={{ label: buttonText, help: buttonHelp }}
      controlState={controlState}
      modal
      onClose={props.onClose}
      onContinue={handleContinue}
      open={props.open}
      title={buttonText}>
      <div>
        {!activeReservation && (
          <>
            <div>
              Warm machines with GPU Type:
            </div>
            <DataSelect
              disabled={controlState !== 'normal'}
              onChange={setGpuType}
              options={[
                {
                  value: clusterconfigpb.GPUType.A100,
                  name: 'A100',
                  selected: gpuType === clusterconfigpb.GPUType.A100,
                },
                {
                  value: clusterconfigpb.GPUType.V100,
                  name: 'V100',
                  selected: gpuType === clusterconfigpb.GPUType.V100,
                },
              ]}
            />
          </>
        )}
        {activeReservation && (
          <div className={classes.root}>
            <ReservationsGrid
              busy={refreshing}
              rows={[{ user, reservation: reservedNodes }]}
            />
            <ActionButton
              disabled={refreshing}
              kind="secondary"
              onClick={() => refreshUserReservation(setReservedNodes)}>
              Reload
            </ActionButton>
          </div>
        )}
      </div>
    </Dialog>
  );
};
