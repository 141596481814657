// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import cx from 'classnames';

import {
  Banner,
  ColumnConfig,
  ColumnId,
  ColumnState,
  PaginationDefinition,
  RowConfig,
  SortDefinition,
  TableContextValues,
  TableDensity,
  TableVariant,
} from '../../../lib/componentTypes/table';
import { sumByBoolean } from '../../../lib/number';
import { usePageTracker } from '../../../lib/usePagination';
import { ColumnOpsRecord, useDataTable, useSetDataTableSelectedRows } from '../../../state/internal/component/dataTable';
import { PaginationControl } from '../PaginationControl';

import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';
import { TableContext } from './context';
import { executeSearch } from './search';
import { sortRows } from './sorting';
import { generateColumnState, getVisibleColumns } from './util';

import './Table.scss';

export interface TableProps {
  // A unique name, used for persisting state
  name: string;
  // Optionally render table as a block (full width) element vs. the default inline layout
  asBlock?: boolean;
  // A set of column configurations
  columnConfigs: ColumnConfig[];
  // An optional list of banners
  banners?: Banner[];
  // A set of row configurations
  rowConfigs: RowConfig[];
  // Optionally disable sorting for the table
  disableSorting?: boolean;
  // Optionally disable highlighting the sorted column
  disableSortHighlighting?: boolean;
  // Optionally disable hiding/showing columns
  disableColumnSettings?: boolean;
  // Optionally enable row selection
  enableRowSelection?: boolean;
  // Optionally specify default sort column; otherwise, the first column is used
  defaultSort?: SortDefinition;
  // Optionally configure pagination
  pagination?: PaginationDefinition;
  searchText?: string;
  // Optionally specify the density, default is 'default'
  density?: TableDensity;
  // Optionally specify the variant, default is 'default'
  variant?: TableVariant;
}

const LINK_NEIGHBORHOOD = 1;
/**
 * Table is a data-driven component that renders a table structure, using the native HTML <table>
 * element.  High-level spec can be found at
 * https://docs.google.com/document/d/1PfTnqsEythZ4vjSPpkFcmcInSSqy8hwVvmhGt7ydjHk
 */
export const Table = (props: TableProps) => {
  // == Props
  const {
    asBlock,
    banners,
    columnConfigs,
    defaultSort,
    disableSorting = false,
    disableSortHighlighting = false,
    disableColumnSettings = false,
    enableRowSelection,
    name,
    pagination,
    rowConfigs,
    searchText = '',
    density = 'default',
    variant = 'default',
  } = props;

  // == Recoil
  const [tableState, setTableState] = useDataTable(name);
  const setSelectedRows = useSetDataTableSelectedRows(name);

  const currentPageSize = useMemo(() => {
    // When `Infinity` is serialized to JSON for persistence in session storage, the result is
    // `null`.  So null should be mapped to `Infinity`.
    if (tableState.pageSize === null) {
      return Infinity;
    }
    return tableState.pageSize ?? pagination?.availablePageSizes[0];
  }, [pagination, tableState]);

  // Merge column configs (which are static) with persisted, dynamic column state (order, hidden,
  // etc.).
  const allColumns = useMemo<ColumnState[]>(
    () => generateColumnState(columnConfigs, tableState.columns),
    [columnConfigs, tableState.columns],
  );

  const visibleColumns = useMemo(() => getVisibleColumns(allColumns), [allColumns]);

  const columnIds = useMemo(() => columnConfigs.map((column) => column.id), [columnConfigs]);

  const currentSort = useMemo((): SortDefinition => {
    if (tableState.sorting && columnIds.includes(tableState.sorting.columnId)) {
      return tableState.sorting;
    }
    if (defaultSort && columnIds.includes(defaultSort.columnId)) {
      return defaultSort;
    }

    return { columnId: columnIds[0] };
  }, [columnIds, defaultSort, tableState.sorting]);

  const filteredRows = executeSearch(rowConfigs, allColumns, searchText);

  const {
    currentPage,
    indexRange,
    updateCurrentPage,
  } = usePageTracker(name, filteredRows.length, currentPageSize ?? Infinity, !!pagination?.persist);

  const sortedRows = useMemo(() => {
    const columnToSort = allColumns.find((item) => item.config.id === currentSort.columnId);
    if (columnToSort) {
      return sortRows(filteredRows, columnToSort, currentSort.descending);
    }
    return sortRows(filteredRows, allColumns[0]);
  }, [allColumns, currentSort, filteredRows]);

  const paginatedRows = useMemo(
    () => sortedRows.slice(indexRange[0], indexRange[1] + 1),
    [indexRange, sortedRows],
  );

  const sortOnColumn = useCallback((column: ColumnState, descending: boolean) => {
    if (column.config.disableSorting || disableSorting) {
      return;
    }

    const columnId = column.config.id;

    setSelectedRows(new Set());
    setTableState((oldValue) => ({
      ...oldValue,
      sorting: {
        columnId,
        descending,
      },
    }));
  }, [disableSorting, setSelectedRows, setTableState]);

  const clickColumnHeaderCell = useCallback((column: ColumnState) => {
    const columnId = column.config.id;

    // When clicking the currently sorted column, toggle 'descending', but when clicking a new
    // column, reset 'descending' to false.
    const descending = currentSort.columnId === columnId ? !currentSort.descending : false;

    sortOnColumn(column, descending);
  }, [currentSort, sortOnColumn]);

  const changeColumnVisibility = useCallback((columnId: ColumnId, show: boolean) => {
    if (!show && visibleColumns.length <= 1) {
      // Don't allow user to hide all columns
      return;
    }
    setTableState((oldValue) => {
      const columnRecord = allColumns.reduce((result, column, i) => {
        result[column.config.id] = {
          hidden: (columnId === column.config.id) ? !show : column.hidden,
          order: i,
        };

        return result;
      }, {} as ColumnOpsRecord);

      return {
        ...oldValue,
        columns: columnRecord,
      };
    });
  }, [allColumns, setTableState, visibleColumns]);

  const resetColumnVisibility = useCallback(() => {
    setTableState((oldValue) => {
      const columnRecord = allColumns.reduce((result, column, i) => {
        result[column.config.id] = {
          hidden: column.config.defaultHidden ?? false,
          order: i,
        };

        return result;
      }, {} as ColumnOpsRecord);

      return {
        ...oldValue,
        columns: columnRecord,
      };
    });
  }, [allColumns, setTableState]);

  const showControlColumn = useMemo(() => {
    if (!disableColumnSettings) {
      return true;
    }
    return paginatedRows.some((row) => !!row.menuItems?.length);
  }, [disableColumnSettings, paginatedRows]);

  const showSelectionColumn = useMemo(() => {
    if (!enableRowSelection) {
      return false;
    }
    return paginatedRows.some((row) => row.canSelect !== false);
  }, [enableRowSelection, paginatedRows]);

  const columnCount = (
    visibleColumns.length +
    sumByBoolean([showControlColumn, showSelectionColumn])
  );

  const selectableRowIds = useMemo(
    () => paginatedRows.filter((row) => row.canSelect !== false).map((row) => row.id),
    [paginatedRows],
  );

  // To avoid passing common table settings and methods down to each row and cell, use a context
  // provider.
  const contextValues: TableContextValues = {
    currentSort,
    disableSorting,
    disableSortHighlighting,
    clickColumnHeaderCell,
    sortOnColumn,

    allColumns,
    disableColumnSettings,
    showControlColumn,
    showSelectionColumn,
    changeColumnVisibility,
    resetColumnVisibility,

    selectableRowIds,

    density,
    variant,

    columnCount,
  };

  return (
    <TableContext.Provider value={contextValues}>
      <div className={cx('dataTable', { asBlock }, `${variant}`)}>
        <div className="tableContainer">
          <table data-table-name={name}>
            <TableHeader
              columns={visibleColumns}
              tableName={name}
            />
            <TableBody
              banners={banners}
              columns={visibleColumns}
              rows={paginatedRows}
              tableName={name}
            />
          </table>
        </div>
        {pagination && currentPageSize && (
          <div className="accessories">
            <PaginationControl
              availablePageSizes={pagination.availablePageSizes}
              currentPage={currentPage}
              currentPageSize={currentPageSize}
              linkNeighborhood={LINK_NEIGHBORHOOD}
              onChangePage={(page: number) => {
                setSelectedRows(new Set());
                updateCurrentPage(page);
              }}
              onChangePageSize={(pageSize) => {
                if (tableState.pageSize !== pageSize) {
                  setSelectedRows(new Set());
                  setTableState((oldValue) => ({
                    ...oldValue,
                    pageSize,
                  }));
                }
              }}
              total={filteredRows.length}
            />
          </div>
        )}
      </div>
    </TableContext.Provider>
  );
};
