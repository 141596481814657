// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { isDev, isProd, isStaging } from './RuntimeParams';

export function getEnvironmentLabel(): string {
  let environment = 'UNKNOWN';
  if (isProd) {
    environment = 'Production';
  } else if (isStaging) {
    environment = 'Staging';
  } else if (isDev) {
    environment = 'Development';
  }
  return environment;
}
