// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as entitypb from '../proto/client/entity_pb';
import * as simulationpb from '../proto/client/simulation_pb';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

/**
 * Return an EntityRelationship object from the param's root, creating one if it doesn't yet exist
 * @param simParam
 * @returns simulationpb.EntityRelationship
 */
export function getOrCreateEntityRelationship(
  simParam: simulationpb.SimulationParam,
): simulationpb.EntityRelationships {
  if (!simParam.entityRelationships) {
    simParam.entityRelationships = new simulationpb.EntityRelationships();
  }
  return simParam.entityRelationships!;
}

/**
 * Return a Set of domains (volumes) that are assigned to a material
 * @param simParam
 * @param materialId
 * @param geometryTags geometry tags object used to resolve domains from tags.
 * @returns Set<string> of the domains assigned to the material (with unrolling of tags).
 */
export function getMaterialDomains(
  simParam: simulationpb.SimulationParam,
  materialId: string,
  geometryTags: GeometryTags,
) {
  const rels = simParam.entityRelationships?.volumeMaterialRelationship || [];
  return rels.reduce((result, rel) => {
    const mid = rel.materialIdentifier?.id;
    const domain = rel.volumeIdentifier?.id;
    if (!domain) {
      return result;
    }
    const domainsFromTag = geometryTags.domainsFromTagEntityGroupId(domain) || [domain];
    domainsFromTag.forEach((domainFromTag) => {
      if (mid === materialId) {
        result.add(domainFromTag);
      }
    });
    return result;
  }, new Set<string>());
}

/**
 * Return a Set of domains (volumes) that are assigned to a physics
 * @param simParam
 * @param physics
 * @param geometryTags geometry tags object used to resolve domains from tags.
 * @returns Set<string>
 */
export function getPhysicsDomains(
  simParam: simulationpb.SimulationParam,
  physicsId: string,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  const rels = simParam.entityRelationships?.volumePhysicsRelationship || [];
  return rels.reduce((result, rel) => {
    const vid = rel.volumeIdentifier?.id;
    const pid = rel.physicsIdentifier?.id;
    if (!vid || !pid || pid !== physicsId) {
      return result;
    }

    const domainsFromTag = geometryTags.domainsFromTagEntityGroupId(vid) || [vid];
    domainsFromTag.forEach((domain) => result.add(domain));
    return result;
  }, new Set<string>());
}

/**
 * Returns a set of domains or tags that are assigned to a physics. No unrolling of the tags is
 * done. Use only if you need a fast way to lookup the if a physics is associated with a tag or
 * domain.
 * @param simParam
 * @param physicsId
 * @returns
 */
export function getPhysicsDomainsWithoutUnroll(
  simParam: simulationpb.SimulationParam,
  physicsId: string,
) {
  const rels = simParam.entityRelationships?.volumePhysicsRelationship || [];
  return rels.reduce((result, rel) => {
    const vid = rel.volumeIdentifier?.id;
    const pid = rel.physicsIdentifier?.id;

    if (vid && pid && pid === physicsId) {
      result.add(vid);
    }
    return result;
  }, new Set<string>());
}

/**
 * Return a set of material IDs associated with a physics via the volumes (domains) assigned to a
 * given physics.
 * @param simParam
 * @param physics
 * @param geometryTags geometry tags object used to resolve domains from tags.
 * @param staticVolume list of static volumes used as helper to resolve volume indices and domains.
 * @returns Set<string>
 */
export function getPhysicsMaterialIds(
  simParam: simulationpb.SimulationParam,
  physicsId: string,
  geometryTags: GeometryTags,
  staticVolume: StaticVolume[],
) {
  const rels = simParam.entityRelationships?.volumeMaterialRelationship || [];
  const domains = getPhysicsDomains(simParam, physicsId, geometryTags, staticVolume);
  return rels.reduce((result, rel) => {
    const mid = rel.materialIdentifier?.id;
    const vid = rel.volumeIdentifier?.id;

    if (!vid || !mid) {
      return result;
    }

    const domainsFromTag = geometryTags.domainsFromTagEntityGroupId(vid) || [vid];
    domainsFromTag.forEach((domain) => {
      if (domains.has(domain)) {
        result.add(mid);
      }
    });
    return result;
  }, new Set<string>());
}

/**
 * Return a Set of domains that have been assigned to any material, excluding the material
 * identified by excludingMaterialId, if provided.
 * @param simParam
 * @param geometryTags geometry tags object used to resolve domains from tags.
 * @param materialId
 * @param including, if true, include the material identified by materialId in the result. Else,
 * exclude it.
 * @returns Set<string>
 */
export function getAssignedMaterialDomains(
  simParam: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
  materialId?: string,
  including: boolean = false,
) {
  const rels = simParam.entityRelationships?.volumeMaterialRelationship || [];
  return rels.reduce((result, rel) => {
    const mid = rel.materialIdentifier?.id;
    const vid = rel.volumeIdentifier?.id;
    if (!vid) {
      return result;
    }

    const domainsFromTag = geometryTags.domainsFromTagEntityGroupId(vid) || [vid];
    domainsFromTag.forEach((domain) => {
      if (!including && mid && mid !== materialId) {
        result.add(domain);
      } else if (including && mid && mid === materialId) {
        result.add(domain);
      }
    });
    return result;
  }, new Set<string>());
}

/**
 * Return the ID of the physics to which a domain (volume) is assigned
 * @param simParam
 * @param domain
 * @param geometryTags geometry tags object used to resolve domains from tags.
 * @returns string | undefined
 */
export function findPhysicsIdByDomain(
  simParam: simulationpb.SimulationParam,
  domain: string,
  geometryTags: GeometryTags,
) {
  const rels = simParam.entityRelationships?.volumePhysicsRelationship || [];
  return rels.reduce(
    (result, rel) => {
      const vid = rel.volumeIdentifier?.id;
      const pid = rel.physicsIdentifier?.id;
      if (!vid || !pid) {
        return result;
      }

      const domainTags = geometryTags.domainsFromTagEntityGroupId(vid) || [vid];
      let retValue: string | undefined;
      domainTags.forEach((domainFromTag) => {
        if (domain === domainFromTag) {
          retValue = pid;
        }
      });
      if (retValue) {
        return retValue;
      }
      return result;
    },
    undefined as string | undefined,
  );
}

/**
 * Return the ID of the material to which a domain (volume) is assigned
 * @param simParam
 * @param domain
 * @param geometryTags geometry tags object used to resolve domains from tags.
 */
export function findMaterialIdByDomain(
  simParam: simulationpb.SimulationParam,
  domain: string,
  geometryTags: GeometryTags,
) {
  const rels = simParam.entityRelationships?.volumeMaterialRelationship || [];
  return rels.reduce((result, rel) => {
    const mid = rel.materialIdentifier?.id;
    const vid = rel.volumeIdentifier?.id;
    if (!vid || !mid) {
      return result;
    }

    const domainsFromTag = geometryTags.domainsFromTagEntityGroupId(vid) || [vid];
    let retValue: string | undefined;
    domainsFromTag.forEach((domainFromTag) => {
      if (domain === domainFromTag) {
        retValue = mid;
      }
    });
    if (retValue) {
      return retValue;
    }
    return result;
  }, undefined as string | undefined);
}

/**
 * Assign a Set of domains (volumes) to a material
 * @param simParam
 * @param domains
 * @param materialId
 */
export function assignDomainsToMaterial(
  simParam: simulationpb.SimulationParam,
  domains: Set<string>,
  materialId: string,
) {
  const entityRels = getOrCreateEntityRelationship(simParam);
  entityRels.volumeMaterialRelationship = [
    ...entityRels.volumeMaterialRelationship.filter((rel) => {
      const vid = rel.volumeIdentifier?.id;
      const mid = rel.materialIdentifier?.id;
      if (mid === materialId) {
        // Remove any pairs that reference the material ID
        return false;
      }
      if (!vid || domains.has(vid)) {
        // Remove any pairs that reference the domains
        return false;
      }
      return true;
    }),
    // Add back domain relationships with materialId
    ...[...domains].map((domain) => new simulationpb.VolumeMaterialRelationship({
      materialIdentifier: new entitypb.EntityIdentifier({ id: materialId }),
      volumeIdentifier: new entitypb.EntityIdentifier({ id: domain }),
    })),
  ];
}

/**
 * Assign a Set of domains (volumes) to a physics
 * @param simParam
 * @param domains
 * @param materialId
 */
export function assignDomainsToPhysics(
  simParam: simulationpb.SimulationParam,
  domains: Set<string>,
  physicsId: string,
) {
  const entityRels = getOrCreateEntityRelationship(simParam);
  entityRels.volumePhysicsRelationship = [
    ...entityRels.volumePhysicsRelationship.filter((rel) => {
      const vid = rel.volumeIdentifier?.id;
      const pid = rel.physicsIdentifier?.id;
      if (pid === physicsId) {
        // Remove any pairs that reference the physics ID
        return false;
      }
      if (!vid || domains.has(vid)) {
        // Remove any pairs that reference the domains
        return false;
      }
      return true;
    }),
    // Add back domain relationships with physicsId
    ...[...domains].map((domain) => new simulationpb.VolumePhysicsRelationship({
      physicsIdentifier: new entitypb.EntityIdentifier({ id: physicsId }),
      volumeIdentifier: new entitypb.EntityIdentifier({ id: domain }),
    })),
  ];
}

/**
 * Unassign a domain (volume) from any materials
 * @param simParam
 * @param domain
 */
export function unassignDomainFromMaterials(
  simParam: simulationpb.SimulationParam,
  domain: string,
) {
  const entityRels = getOrCreateEntityRelationship(simParam);

  entityRels.volumeMaterialRelationship = entityRels.volumeMaterialRelationship.filter(
    (rel) => rel.volumeIdentifier?.id !== domain,
  );
}

/**
 * Unassign a material from any domains (volumes)
 * @param simParam
 * @param materialId
 */
export function unassignMaterialsFromDomains(
  simParam: simulationpb.SimulationParam,
  materialIds: Set<string>,
) {
  const entityRels = getOrCreateEntityRelationship(simParam);

  entityRels.volumeMaterialRelationship = entityRels.volumeMaterialRelationship.filter(
    (rel) => {
      const materialId = rel.materialIdentifier?.id;
      return materialId ? !materialIds.has(materialId) : true;
    },
  );
}

/**
 * Unassign a domain (volume) from any physics
 * @param simParam
 * @param domain
 */
export function unassignDomainFromPhysics(
  simParam: simulationpb.SimulationParam,
  domain: string,
) {
  const entityRels = getOrCreateEntityRelationship(simParam);

  entityRels.volumePhysicsRelationship = entityRels.volumePhysicsRelationship.filter(
    (rel) => rel.volumeIdentifier?.id !== domain,
  );
}

/**
 * Unassign a set of physics from any domains (volumes)
 * @param simParam
 * @param physicsIds
 */
export function unassignPhysicsSetFromDomains(
  simParam: simulationpb.SimulationParam,
  physicsIds: Set<string>,
) {
  const entityRels = getOrCreateEntityRelationship(simParam);

  entityRels.volumePhysicsRelationship = entityRels.volumePhysicsRelationship.filter(
    (rel) => {
      const physicsId = rel.physicsIdentifier?.id;
      return physicsId ? !physicsIds.has(physicsId) : true;
    },
  );
}
