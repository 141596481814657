// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import {
  CellJustify,
  ColumnConfig,
  ColumnDisplayOptions,
  ColumnId,
  ColumnLabel,
  ColumnState,
  MultiRowHeaderConfig,
  RawValue,
  SortDefinition,
} from '../../../lib/componentTypes/table';
import { CurrencyCode } from '../../../lib/currency';
import { formatBytesHuman } from '../../../lib/number';
import { upperFirst } from '../../../lib/text';
import { ColumnOpsRecord } from '../../../state/internal/component/dataTable';

const DEFAULT_CURRENCY: CurrencyCode = CurrencyCode.USD;

/** Display options to stylize a column to be a header for each row */
export const HEADING_COLUMN_OPTIONS: ColumnDisplayOptions = {
  minimalWidth: true,
  headerStyle: true,
  justify: 'center',
};

// Return only those columns that should be shown
export function getVisibleColumns(columns: ColumnState[]) {
  return columns.filter((column) => !column.hidden);
}

// Merge persisted, dynamic column state (order, hidden, etc.) with static column configs.  The
// result is sorted and any hidden columns are filtered out.
export function generateColumnState(columnConfigs: ColumnConfig[], columnOps: ColumnOpsRecord) {
  const columns = columnConfigs.map((config, i) => {
    const { id } = config;
    const ops = columnOps[id] || { order: i, hidden: config.defaultHidden ?? false };

    return { config, ...ops };
  });

  columns.sort((a, b) => a.order - b.order);

  return columns;
}

// Return true if any of the columns has a super column
export function hasSuperColumns(columns: ColumnState[]) {
  return columns.some(({ config }) => !!config.superLabel);
}

// Generate a data structure that groups together any consecutive columns that share a superLabel
export function getMultiRowHeaderConfig(columns: ColumnState[]) {
  const headerConfig: MultiRowHeaderConfig[] = [];

  columns.forEach((column, i) => {
    const { config: { id, superLabel = '' } } = column;

    const lastConfig = headerConfig.length ? headerConfig[headerConfig.length - 1] : null;
    if (lastConfig && lastConfig.superLabel === superLabel) {
      lastConfig.columns.push(column);
    } else {
      headerConfig.push({
        key: `${superLabel || 'blank'}-${id}`,
        superLabel: superLabel || '',
        columns: [column],
      });
    }
  });

  return headerConfig;
}

// Normalize a label to arrays of strings (each rendered on its own line in the table header cell).
export function labelToLines(label: ColumnLabel): string[] {
  if (Array.isArray(label)) {
    return label;
  }

  return [label];
}

// The `getColumnLabelLines` method gets a label from a column config, using ID as a default.  The
// result is normalized as an array of strings (lines).
export function getColumnLabelLines(id: ColumnId, label?: ColumnLabel): string[] {
  // Label is optional; use ID if it's undefined.
  const columnLabel = label ?? id;

  return labelToLines(columnLabel);
}

// Return true if the column is of type 'number' and is formatted as a date or datetime
export function isDateColumn(column: ColumnState) {
  if (column.config.type === 'number') {
    return ['date', 'datetime'].includes(column.config.format || '');
  }
  return false;
}

// Each cell's default justification (horizontal alignment) depends on its type.  Number columns'
// cells are right-aligned, while string/boolean columns' cells are left-aligned.
export function getDefaultJustify(column: ColumnState): CellJustify {
  if (column.config.type === 'number' && !isDateColumn(column)) {
    return 'end';
  }

  return 'start';
}

// Return true if the 'nowrap' should be applied to the cell.  If the column's displayOptions
// specify a 'nowrap' value of true/false, always respect that.  Otherwise, default to true for
// certain column types/formats (like number/date(time) columns).
export function isColumnNowrap(column: ColumnState): boolean {
  const { config: { displayOptions } } = column;
  if (displayOptions?.nowrap !== undefined) {
    return displayOptions.nowrap;
  }

  if (
    column.config.type === 'boolean' ||
    column.config.type === 'number'
  ) {
    return true;
  }

  return false;
}

function getAlignmentClasses(column: ColumnState) {
  const { config: { displayOptions } } = column;

  const classes: string[] = [];

  if (displayOptions?.justify) {
    classes.push(`align${upperFirst(displayOptions.justify)}`);
  } else {
    classes.push(`align${upperFirst(getDefaultJustify(column))}`);
  }

  return classes;
}

export function getBodyCellClasses(column: ColumnState) {
  return getAlignmentClasses(column);
}

// Classes that control cell sizing
export function getCellSizingClasses(column: ColumnState) {
  const classes: string[] = [];

  if (column.config.displayOptions?.minimalWidth) {
    classes.push('minimalWidth');
  }

  return classes;
}

// Some display options can be effected through CSS classes.  The getCellContentClasses method
// returns a set of classes to be applied to the table cell, depending on the column config's
// display options.
export function getCellContentClasses(column: ColumnState, body: boolean) {
  const { config: { displayOptions } } = column;

  const classes: string[] = getAlignmentClasses(column);

  if (body) {
    if (isColumnNowrap(column)) {
      classes.push('nowrap');
    }

    if (displayOptions?.textTransform) {
      classes.push(`text${upperFirst(displayOptions.textTransform)}`);
    }
  }

  return classes;
}

// Row values are typically primitives, but null and undefined are also supported for convenience,
// for special casing, and for inducing an "empty" display (like dashes).
export function isValueNullish(value: RawValue) {
  return ((value === null) || (value === undefined));
}

export function getNormalizedValue(value: RawValue, column: ColumnState): RawValue {
  const nullish = isValueNullish(value);

  switch (column.config.type) {
    case 'boolean':
      // If a column is of type 'boolean', always coerce the row value to true or false.
      return !!value;
    case 'number':
      // If a column is of type 'number', return null (for "empty" display) or a numerical value.
      return nullish ? null : Number(value);
    default:
      // If a column is of type 'string', always coerce the row value to a string.
      return nullish ? '' : `${value}`;
  }
}

// A stub for now, but the intent is to perform any string mapping, number scaling, etc. here.
export function getTransformedValue(value: RawValue, column: ColumnState): RawValue {
  switch (column.config.type) {
    case 'boolean':
      return value;
    case 'number':
      return value;
    default:
      return value;
  }
}

// Apply formatting to row values
export function getFormattedValue(
  value: RawValue,
  column: ColumnState,
  options?: {
    locales?: Intl.LocalesArgument,
    timeZone?: string,
  },
): string {
  const { type, format } = column.config;

  if (isValueNullish(value)) {
    return '';
  }

  switch (type) {
    case 'boolean': {
      if (column.config.valueMap?.has(!!value)) {
        return column.config.valueMap.get(!!value)!;
      }
      break;
    }
    case 'number':
      switch (format) {
        case 'date':
          return (new Date(value as number)).toLocaleString(options?.locales, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            timeZone: options?.timeZone,
          });
        case 'datetime':
          return (new Date(value as number)).toLocaleString(options?.locales, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: options?.timeZone,
          });
        case 'currency':
          return (value as number).toLocaleString(options?.locales, {
            style: 'currency',
            currency: `${column.config.formatOptions?.currency ?? DEFAULT_CURRENCY}`,
          });
        case 'bytes':
          return formatBytesHuman(value as number, { binary: true });
        default: {
          // no default
        }
      }
      break;
    default: {
      // no default
    }
  }

  return `${value}`;
}

export function getCellSortClasses(currentSort: SortDefinition, column: ColumnState) {
  const classes: string[] = [];

  if (currentSort.columnId === column.config.id) {
    classes.push('sorted');
    if (currentSort.descending) {
      classes.push('descending');
    }
  }

  return classes;
}
