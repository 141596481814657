// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import * as frontendpb from '../../../../proto/frontend/frontend_pb';

export const stopCondReplyState = atomFamily(
  (projectJobUrl: string) => atom<frontendpb.StoppingConditionReply | null>(null),
);

export function useStopCondReply(projectJobUrl: string) {
  return useAtom(stopCondReplyState(projectJobUrl));
}
