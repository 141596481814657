// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useCallback, useEffect, useRef, useState } from 'react';

import QRCode from 'qrcode';
import { useLocation, useNavigate } from 'react-router-dom';

import { ActionLink } from '../../components/Button/ActionLink';
import AuthForm, { DataValues } from '../../components/Form/AuthForm';
import { AuthPageLayout, useAuthPageLayoutStyles } from '../../components/layout/page/AuthPageLayout';
import { SectionMessage } from '../../components/notification/SectionMessage';
import { DiskCheckIcon } from '../../components/svg/DiskCheckIcon';
import { Flex } from '../../components/visual/Flex';
import { MFA_TOKEN_EXPIRED_ERROR, OtpMfaSetupParams, SetupApp2FALocationState, getLoginTokenWithOtpMfa, getOtpMfaSetupCode, isMfaTokenExpired } from '../../lib/AuthMFA';
import { handleAuthenticatedV2 } from '../../lib/AuthV2';
import { AUTH0_OTP_MFA_APPS_URL } from '../../lib/constants';
import { getUserErrorMessage } from '../../lib/errors';
import { routes } from '../../lib/navigation';
import { Logger } from '../../lib/observability/logs';
import { isStorybookEnv } from '../../lib/testing/utils';

import Setup2FABackup from './Setup2FABackup';
import Setup2FAComplete from './Setup2FAComplete';

const logger = new Logger('SetupApp2FA');

export enum PageState {
  INITIALIZING,
  FORM,
  BACKUP,
  DONE,
}

interface SetupApp2FAProps {
  // Optional initial page state. This should be used ONLY for the storybook
  initialPageState?: PageState;
  // Optional initial otp. This should be used ONLY for the storybook
  initialOtp?: {
    authenticatorType: string,
    secret: string,
    barcodeURI: string,
    recoveryCodes: string[],
  }
}

// This page is where the user configures their authenticator app for a MFA method. First, the user
// is shown a QR code (or secret for manual enter) and is expected to enter a 6-digit code generated
// from their authenticator app. If the code is correct, the next step is to show a recovery code
// that the user has to write down or download. The last step is just a transient success page that
// will be shown for 5 seconds before the user is redirected into the app.
const SetupApp2FA = (props: SetupApp2FAProps) => {
  // Props
  const { initialPageState = PageState.INITIALIZING, initialOtp } = props;

  // Hooks
  const locationState = useLocation().state as SetupApp2FALocationState;
  const authClasses = useAuthPageLayoutStyles();
  const navigate = useNavigate();

  // State
  const [pageState, setPageState] = useState(initialPageState);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [fieldsError, setFieldsError] = useState<{ code?: string }>({});
  const [manual, setManual] = useState(false);
  const [otp, setOtp] = useState<OtpMfaSetupParams | undefined>(initialOtp);
  const [copyDone, setCopyDone] = useState(false);
  const [loginToken, setLoginToken] = useState('');

  // Refs
  const ref = useRef<HTMLCanvasElement>(null);

  // Effects
  useEffect(() => {
    if (isStorybookEnv()) {
      return;
    }

    if (props.initialPageState !== undefined || props.initialOtp !== undefined) {
      throw Error('Do not use the initialPageState/initialOtp outside of storybook env.');
    }

    const handleOtpMfa = async () => {
      if (!locationState?.mfaToken) {
        navigate(routes.login, {
          state: {
            error: 'Authentication error: missing MFA token for setup',
          },
        });
        return;
      }

      // Try to get the otp data for the initial setup. If there isn't mfaToken in the url,
      // redirect to login because we won't be able to get the otp data without it.
      setOtp(await getOtpMfaSetupCode(locationState.mfaToken));
    };

    handleOtpMfa().catch((err) => {
      navigate(routes.login, { state: { error: 'Authentication error: could not get OTP code' } });
    });
  }, [props.initialOtp, props.initialPageState, locationState, navigate, setOtp]);

  // When we have the otp data, show the form for entering the 6-digit code
  useEffect(() => {
    if (isStorybookEnv()) {
      return;
    }

    if (otp) {
      setPageState(PageState.FORM);
    }
  }, [otp, setPageState]);

  // Sometimes the otp request does not return a recovery code. If that is the case, there's no
  // reason to show the recovery page and we can immediatelly go to the next step.
  useEffect(() => {
    if (isStorybookEnv()) {
      return;
    }

    if (otp && pageState === PageState.BACKUP && !otp.recoveryCodes?.[0]) {
      setPageState(PageState.DONE);
    }
  }, [otp, pageState, setPageState]);

  // Re-draw the QRcode everytime the canvas might get rerendered
  useEffect(() => {
    if (manual || !otp) {
      return;
    }

    QRCode.toCanvas(ref.current, otp.barcodeURI, { margin: 1.75, width: 160 }, (err) => {
      if (err) {
        logger.error('SetupApp2FA/QRCodeCanvas error: ', err);
        if (ref.current) {
          setError('QR code generation failed');
        }
      }
    });
  }, [manual, otp, pageState, setError]);

  // If the user has clicked the "Copy code", we'll show a checkmark icon for 3 seconds
  useEffect(() => {
    if (copyDone) {
      const timeout = setTimeout(() => {
        setCopyDone(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }

    return () => { };
  }, [copyDone]);

  const handleSubmit = async (data: DataValues) => {
    setFieldsError({});
    setSubmitting(true);

    if (isMfaTokenExpired(locationState?.mfaTokenExpiry)) {
      navigate(routes.login, { state: { error: MFA_TOKEN_EXPIRED_ERROR } });
      return;
    }

    try {
      // Get the login token and go to the next step of writing down the recovery code. The final
      // step should pass the loginToken to the handleAuthenticatedV2. It should then set
      // the AuthState to AuthState.AUTHENTICATED and the useEffect in the main index.tsx should
      // redirect to the projects page.
      // If the user is authenticating with Google, the idToken should be in the location, set from
      // the GoogleAuthCallback.
      // For user/email authentication, we should manually get the token from the server.
      if (locationState?.idToken) {
        setLoginToken(locationState?.idToken);
      } else {
        const token = await getLoginTokenWithOtpMfa(
          locationState.mfaToken,
          data.code,
          '',
          true,
          // data.remember === 'true',
        );
        setLoginToken(token);
      }

      if (otp?.recoveryCodes) {
        setPageState(PageState.BACKUP);
      } else {
        setPageState(PageState.DONE);
      }
    } catch (err) {
      logger.error('SetupApp2FA failed during get login token: ', err);
      setFieldsError({ code: getUserErrorMessage(err) });
    }
    setSubmitting(false);
  };

  // Automatically submit the form when 6 symbols are entered
  const handleChange = async (data: DataValues) => {
    if (data.code.length === 6) {
      await handleSubmit(data);
    }
  };

  // This will be called on the last step step.
  const handleComplete = useCallback(() => {
    if (loginToken) {
      // When we pass the loginToken to the handleAuthenticatedV2, it will set the AuthState to
      // authenticated. This will be interecpted by the main useEffect in the index.tsx and the user
      // will be redirected to the app.
      handleAuthenticatedV2(loginToken).catch(() => { });
    }
  }, [loginToken]);

  // Render one of the possible 4 page states
  if (pageState === PageState.INITIALIZING) {
    return <AuthPageLayout loading />;
  }

  if (pageState === PageState.BACKUP) {
    return (
      <Setup2FABackup
        onContinue={() => {
          setPageState(PageState.DONE);
        }}
        recoveryCode={otp?.recoveryCodes?.[0] || ''}
      />
    );
  }

  // This is the last step. After it's shown for a while, the AuthState will be updated with the
  // loginToken and the user will be redirected into the app.
  if (pageState === PageState.DONE) {
    return <Setup2FAComplete onComplete={handleComplete} />;
  }

  const subtitle = manual ? (
    <div>
      Manually enter the following code into your preferred authenticator app and then enter
      the provided time-based one-time code below.
    </div>
  ) : (
    <Flex flexDirection="column" gap={16}>
      <div>
        1. Install or open a third party authenticator app on your mobile device. We support Google
        Authenticator, Microsoft Authenticator, Authy, and&nbsp;
        <ActionLink href={AUTH0_OTP_MFA_APPS_URL}>
          more
        </ActionLink>.
      </div>
      <div>
        2. Scan QR code below with the authenticator.
      </div>
      <div>
        3. Enter the 6-digit code you see in the authenticator.
      </div>
    </Flex>
  );

  const handleCopy = async () => {
    await navigator.clipboard.writeText(otp?.secret || '');
    setCopyDone(true);
  };

  return (
    <AuthPageLayout
      back
      subtitle={subtitle}
      title="Set up authenticator app">
      <Flex alignItems="center" flexDirection="column" gap={16} justifyContent="center">
        {manual ? (
          <div className={authClasses.secretSection}>
            <div className={authClasses.secret}>
              {/* Show the secret in more readable 4-letter chunks */}
              {(otp?.secret.match(/.{1,4}/g) || []).join(' - ')}
            </div>
            <ActionLink onClick={handleCopy}>
              <Flex alignItems="center" gap={8}>
                Copy 2FA KEY
                {copyDone && <DiskCheckIcon maxHeight={12} />}
              </Flex>
            </ActionLink>
          </div>
        ) : (
          <>
            {error && <SectionMessage level="error" title={error} />}
            {!error && <canvas ref={ref} />}
          </>
        )}

        <ActionLink bold onClick={() => setManual(!manual)}>
          {manual ? 'Scan the QR code instead' : 'Can\'t scan the QR code?'}
        </ActionLink>
      </Flex>

      <AuthForm
        fields={[
          {
            asBlock: true,
            autofocus: true,
            label: '6-digit Code',
            disabled: submitting,
            name: 'code',
            placeholder: '0 0 0 0 0 0',
            required: true,
          },
          // The "remember" option stopped worked after the Auth0 Actions migration.
          // Hiding the checkbox until LC-19810 is resolved.
          // {
          //   label: 'Remember this device',
          //   disabled: submitting,
          //   name: 'remember',
          //   type: 'checkbox',
          // },
        ]}
        fieldsError={fieldsError}
        onChange={handleChange}
        onSubmit={handleSubmit}
        resetFieldsOnError={['code']}
        submit={{
          disabled: submitting,
          showSpinner: submitting,
          label: 'Activate',
        }}
      />
    </AuthPageLayout>
  );
};

export default SetupApp2FA;
