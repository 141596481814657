// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { Choice } from '../../ProtoDescriptor';
import { SelectOption } from '../../lib/componentTypes/form';
import { protoChoicesToSelectOptions } from '../../lib/form';
import { SOLUTION_CONTROLS_PRESET_CHOICES, SPATIAL_DISCRETIZATION_PRESET_CHOICES } from '../../lib/physicsUtils';
import * as simulationpb from '../../proto/client/simulation_pb';

const { CUSTOM_SPATIAL_DISCRETIZATION_FLUID } = simulationpb.SpatialDiscretizationFluidPreset;
const { CUSTOM_SOLUTION_CONTROLS_FLUID } = simulationpb.SolutionControlsFluidPreset;

export const useFluidPhysicsPresets = (
  spatialDiscretizationPreset: simulationpb.SpatialDiscretizationFluidPreset | undefined,
  solutionControlsPreset: simulationpb.SolutionControlsFluidPreset | undefined,
  allowCustom = true,
) => {
  // When allowCustom is false, only show the "Custom" choice if it's the current selection, but
  // always disable it
  const spatialDiscChoices = SPATIAL_DISCRETIZATION_PRESET_CHOICES.filter((choice) => (
    allowCustom ||
    choice.enumNumber === spatialDiscretizationPreset ||
    choice.enumNumber !== CUSTOM_SPATIAL_DISCRETIZATION_FLUID
  ));
  const solnControlChoices = SOLUTION_CONTROLS_PRESET_CHOICES.filter((choice) => (
    allowCustom ||
    choice.enumNumber === solutionControlsPreset ||
    choice.enumNumber !== CUSTOM_SOLUTION_CONTROLS_FLUID
  ));

  const spatialDiscOptions: SelectOption<Choice['enumNumber']>[] = protoChoicesToSelectOptions(
    spatialDiscChoices,
    spatialDiscretizationPreset,
    { explicitHelp: true, disabledEnums: allowCustom ? [] : [CUSTOM_SPATIAL_DISCRETIZATION_FLUID] },
  );

  const solnControlOptions: SelectOption<Choice['enumNumber']>[] = protoChoicesToSelectOptions(
    solnControlChoices,
    solutionControlsPreset,
    { explicitHelp: true, disabledEnums: allowCustom ? [] : [CUSTOM_SOLUTION_CONTROLS_FLUID] },
  );

  return {
    spatialDiscOptions,
    solnControlOptions,
  };
};
