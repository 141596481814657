// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 12;
export const SparkleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="sparkle"
      height={finalHeight}
      ref={ref}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}>
      <path
        d="M 0,5.9884421
           C 0.59063621,5.7236968 1.1797402,5.4535326 1.7756716,5.1935368
           C 3.2183368,4.5641684 4.1702653,3.5988632 4.7780463,2.4000253
           C 5.1805768,1.6059663 5.5687579,0.80701895 5.9683705,0
           C 6.0862484,0.09865263 6.1114611,0.22432421 6.1680379,0.33524211
           C 6.5921684,1.1676126 6.9533179,2.02104 7.4361095,2.8336295
           C 8.0640884,3.8904884 9.0186695,4.7040505 10.323259,5.2520589
           C 10.877659,5.4848842 11.417722,5.7397263 12,6.0005558
           C 11.614598,6.17472 11.25456,6.3373263 10.894522,6.4999326
           C 10.270863,6.7818063 9.6304926,7.0412463 9.0834568,7.4206484
           C 8.1694358,8.0547537 7.5583074,8.8506316 7.1195495,9.7400842
           C 6.7668884,10.454905 6.4171705,11.170484 6.06312,11.884926
           C 6.0452842,11.921053 6.0470905,11.970063 5.9655789,12
           C 5.8396295,11.743453 5.7179874,11.487032 5.5878442,11.233137
           C 5.2435579,10.561389 4.9519326,9.8720842 4.5626274,9.2154063
           C 3.94008,8.1652547 3.0146274,7.3399705 1.7207747,6.7873768
           C 1.1744463,6.5539958 0.64051832,6.3022105 0.10073848,6.0592168
           C 0.06228227,6.0419368 0.01616274,6.0331579 0,5.9884421 Z"
        fill={getColor(0)}
      />
    </svg>
  );
});
