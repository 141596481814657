// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useRef } from 'react';

import { colors } from '../../../../lib/designSystem';
import { isUnmodifiedSpaceKey } from '../../../../lib/event';
import { formatNumber } from '../../../../lib/number';
import { addWarning } from '../../../../lib/transientNotification';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useEditState } from '../../../../recoil/paraviewState';
import { ActionButton } from '../../../Button/ActionButton';
import Form from '../../../Form';
import { DataSelect } from '../../../Form/DataSelect';
import { NumberInput } from '../../../Form/NumberInput';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { DataComponentSelect } from '../../../Paraview/DataComponentSelect';
import { createStyles, makeStyles } from '../../../Theme';
import Divider from '../../../Theme/Divider';
import { useSelectedFilterNode } from '../../../visFilter/useFilterNode';
import { FilterEditControl } from '../../FilterEditControl';
import PropertiesSection from '../../PropertiesSection';
import Tag from '../../Tag';
import { FilterDisplayPanel } from '../shared/FilterDisplayPanel';

import { FilterPropertiesPanelProps } from './props';

// "Contour" is the name that Paraview calls this filters and how it is
// described throughout our codebase, but to our users it is displayed as
// "Isosurface".

/** Create the param filled with default values, to be used when creating a new
    filter off the given parent. */
export function newContourParam(
  parent: ParaviewRpc.TreeNode,
): ParaviewRpc.ContourParam {
  const data = parent.pointData.filter(
    (item: ParaviewRpc.ArrayInformation) => item.dim === 1 || item.dim === 3,
  );
  return {
    typ: ParaviewRpc.TreeNodeType.CONTOUR,
    contourVariable: {
      displayDataName: data[0].name,
      displayDataNameComponent: 0,
    },
    isosurfaces: [],
    nlevels: 0,
    lowerboundrange: 0,
    upperboundrange: 0,
  };
}

const useStyles = makeStyles(
  ({ spacing }) => createStyles({
    addButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    helperText: {
      color: colors.neutral400,
    },
    isosurfaceTable: {
      fontSize: '13px',
      background: colors.surfaceDark3,
      borderRadius: '4px',
      padding: '8px',
      border: `1px solid ${colors.transparent}`,
      '&:hover': {
        borderColor: colors.neutral400,
      },
    },
    isosurfaceTableAndHelp: {
      display: 'flex',
      padding: '2px 4px 2px 0',
      gap: '4px',
      flexWrap: 'wrap',
      alignSelf: 'start',
    },
    input: {
      background: colors.surfaceDark3,
      border: 'none',
      outline: 'none',
      color: colors.highEmphasisText,
      fontSize: '13px',
      maxWidth: '100%',
    },
  }),
  { name: 'ContourPropPanel' },
);

// Parameter-input dialog for the contour filter.
export const ContourPropPanel = (props: FilterPropertiesPanelProps) => {
  const { displayProps, filterNode, nodeId, parentFilterNode, viewState } = props;

  const [editState] = useEditState();
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const { updateEditState } = useSelectedFilterNode();

  const onUpdate = (newParam: ParaviewRpc.TreeNodeParam) => {
    updateEditState({ param: newParam });
  };

  const param = props.param as ParaviewRpc.ContourParam;
  const readOnly = !editState;

  // Contour works only for scalar and vector data. Its extension to tensors
  // should be straightforwarad.
  const data = parentFilterNode.pointData.filter(
    (item: ParaviewRpc.ArrayInformation) => item.dim === 1 || item.dim === 3,
  );
  if (data.length <= 0) {
    throw Error(`Contour: no scalar data array found in ${parentFilterNode.name}`);
  }
  const contourVar = param.contourVariable;
  const paramData = data.find(({ name }) => (name === contourVar.displayDataName));

  const setDataName = (newName: string) => {
    const contourVariable: ParaviewRpc.DisplayPvVariable = {
      ...contourVar,
      displayDataName: newName,
      displayDataNameComponent: 0,
    };
    onUpdate({
      ...param,
      contourVariable,
    });
  };

  const setDataNameComponent = (newComponent: number) => {
    const contourVariable: ParaviewRpc.DisplayPvVariable = {
      ...contourVar,
      displayDataNameComponent: newComponent,
    };
    onUpdate({
      ...param,
      contourVariable,
    });
  };

  const deleteIsosurface = (index: number) => {
    onUpdate({
      ...param,
      isosurfaces: param.isosurfaces.filter((_, i) => i !== index),
    });
  };

  const addNIsosurfaceLevels = (count: number, start: number, end: number) => {
    const isovalues: number[] = [];
    if (count <= 0) {
      addWarning('Could not generate N isovalues. Enter a value of N greater than 0');
    }
    if (count === 1) {
      isovalues.push(start);
    } else if (count > 1) {
      const offset = (end - start) / (count - 1);
      for (let i = 0; i < count; i += 1) {
        const value = start + (offset * i);
        // Remove numbers after the 15 decimal point to fix floating point errors like
        // 0.1 + 0.2 = 0.30000000000000004 or 1 / 10 * 6 = 0.6000000000000001.
        isovalues.push(Number(Number(value).toFixed(15)));
      }
    }
    onUpdate({
      ...param,
      isosurfaces: [...new Set([...param.isosurfaces, ...isovalues])], // keep only unique values
      nlevels: 0,
      lowerboundrange: 0,
      upperboundrange: 0,
    });
  };

  const addIsosurface = (value: number) => {
    addNIsosurfaceLevels(1, value, value);
  };

  const setNlevels = (newValue: number) => {
    onUpdate({
      ...param,
      nlevels: newValue,
    });
  };

  const setLowerBoundRange = (newValue: number) => {
    onUpdate({
      ...param,
      lowerboundrange: newValue,
    });
  };

  const setUpperBoundRange = (newValue: number) => {
    onUpdate({
      ...param,
      upperboundrange: newValue,
    });
  };

  return (
    <div>
      <FilterDisplayPanel filterNode={filterNode} />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={!!editState}
          expandWhenDisabled
          headerRight={(
            <FilterEditControl
              disableApply={param.isosurfaces.length < 1}
              displayProps={displayProps}
              nodeId={nodeId}
              param={param}
            />
          )}
          heading="Visualization Input"
          nodeId={nodeId}
          panelName="input">
          <Form.LabeledInput label="Isosurface Field">
            <DataSelect
              asBlock
              disabled={readOnly}
              onChange={setDataName}
              options={data.map(({ name }) => ({
                name,
                value: name,
                selected: name === contourVar.displayDataName,
              }))}
              size="small"
            />
          </Form.LabeledInput>
          <DataComponentSelect
            disabled={readOnly}
            displayVariable={contourVar}
            fullRow
            onChange={(component: number) => {
              setDataNameComponent(component);
            }}
            viewState={viewState}
          />
          <div>
            <Form.LabeledInput label="Isovalue" lead />
            <div
              className={classes.isosurfaceTable}
              onClick={() => {
                inputRef.current?.focus();
              }}
              onKeyUp={(event) => {
                if (isUnmodifiedSpaceKey(event)) {
                  inputRef.current?.focus();
                }
              }}
              role="button"
              tabIndex={0}>
              <div className={classes.isosurfaceTableAndHelp}>
                {
                  param.isosurfaces.map((value: number, index: number) => (
                    <Tag
                      closing={{ onClick: () => deleteIsosurface(index) }}
                      disabled={readOnly}
                      key={value}
                      text={formatNumber(value)}
                    />
                  ))
                }
                {!readOnly && <span className={classes.helperText}>Enter values ↵</span>}
              </div>
              {!readOnly && (
                <input
                  className={classes.input}
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    // Adds the value after pressing ENTER.
                    if (event.keyCode === 13) {
                      const parsedValue = parseFloat((event.target as HTMLInputElement).value);
                      // Add only numeric values to the table which are not already added
                      if (!Number.isNaN(parsedValue) && !param.isosurfaces.includes(parsedValue)) {
                        addIsosurface(parsedValue);
                      }
                      if (inputRef.current) {
                        inputRef.current.value = '';
                      }
                    }
                  }}
                  ref={inputRef}
                  type="text"
                />
              )}
            </div>
            <Form.LabeledInput label="">
              <div style={{ color: colors.neutral650 }}>
                Min, Max [
                {paramData ?
                  paramData.range[contourVar.displayDataNameComponent][0].toPrecision(5) :
                  'none'}
                ,&nbsp;
                {paramData ?
                  paramData.range[contourVar.displayDataNameComponent][1].toPrecision(5) :
                  'none'}
                ]
              </div>
            </Form.LabeledInput>
            <Form.LabeledInput label="Value Generation">
              <div className={classes.addButton}>
                <ActionButton
                  compact
                  disabled={readOnly}
                  kind="minimal"
                  onClick={() => {
                    addNIsosurfaceLevels(
                      param.nlevels,
                      param.lowerboundrange,
                      param.upperboundrange,
                    );
                  }}
                  size="small"
                  startIcon={{ name: 'ringPlus', maxHeight: 14 }}
                  title="Specify number of isovalues, Range start, and Range end
                below, and click to generate evenly-spaced isovalues in the range.">
                  Add
                </ActionButton>
              </div>
            </Form.LabeledInput>
            <Form.LabeledInput
              help="Range start (inclusive)"
              label="Range Start">
              <NumberInput
                asBlock
                disabled={readOnly}
                onCommit={(value) => setLowerBoundRange(value)}
                size="small"
                value={param.lowerboundrange}
              />
            </Form.LabeledInput>
            <Form.LabeledInput
              help="Range end (inclusive)"
              label="Range End">
              <NumberInput
                asBlock
                disabled={readOnly}
                onCommit={(value) => setUpperBoundRange(value)}
                size="small"
                value={param.upperboundrange}
              />
            </Form.LabeledInput>
            <Form.LabeledInput
              help="Number of isovalues to add between the start and end range."
              label="Number of Values">
              <NumberInput
                asBlock
                disabled={readOnly}
                onCommit={(value) => setNlevels(value)}
                size="small"
                value={param.nlevels}
              />
            </Form.LabeledInput>
          </div>
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
    </div>
  );
};
