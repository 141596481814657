import { useMemo } from 'react';

import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useStaticVolumes } from '../../recoil/volumes';
import { useProjectContext } from '../context/ProjectContext';

export const useTagSurfaceIds = (nodeId: string) => {
  // == Context
  const { projectId } = useProjectContext();

  // == Recoil
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  const tagSurfaceIds = useMemo(() => {
    if (!geometryTags.isTagId(nodeId)) {
      return [];
    }

    const tagDomains = geometryTags.domainsFromTag(nodeId);

    const volumeSurfaces = tagDomains.flatMap((domain) => {
      const volume = staticVolumes.find((item) => item.domain === domain);

      return [...volume?.bounds || []];
    });

    const tagSurfaces = geometryTags.surfacesFromTagEntityGroupId(nodeId) || [];

    // returns surfaces directly from tags and surfaces from volumes associated with each tag
    return [...new Set([...volumeSurfaces, ...tagSurfaces])];
  }, [geometryTags, nodeId, staticVolumes]);

  return tagSurfaceIds;
};
