// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { getTertiaryColor } from '../../lib/color';
import useTimeSeriesOutput from '../../lib/useTimeSeriesOutput';
import * as simulationpb from '../../proto/client/simulation_pb';
import * as outputpb from '../../proto/output/output_pb';
import { QuantityType } from '../../proto/quantity/quantity_pb';
import { JobState } from '../../recoil/jobState';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useProjectContext } from '../context/ProjectContext';

import OutputChart, { DataConfig } from './OutputChart';
import { Scale } from './constants';

interface ResidualChartProps {
  // The chart height in pixels.
  height: number;
  // Sorted in increasing order of iterations/timesteps.
  jobState: JobState;

}

const RESIDUAL_DEFAULT: DataConfig[] = [
  {
    name: 'Density',
    color: getTertiaryColor(0),
  },
  {
    name: 'MomentumX',
    color: getTertiaryColor(1),
  },
  {
    name: 'MomentumY',
    color: getTertiaryColor(2),
  },
  {
    name: 'MomentumZ',
    color: getTertiaryColor(3),
  },
  {
    name: 'Energy',
    color: getTertiaryColor(4),
  },
  {
    name: 'Turbulence',
    color: getTertiaryColor(5),
  },
];

const { INVISCID, LAMINAR } = simulationpb.ViscousModel;

function newOutputList(param: simulationpb.SimulationParam): outputpb.Output[] {
  const outputList: outputpb.Output[] = [];
  const addNewQuantity = (type: QuantityType) => {
    const newOutput = new outputpb.Output({
      quantity: type,
      range: new outputpb.IterationRange(),
      timeAnalysis: outputpb.TimeAnalysisType.TIME_SERIES,
      outputProperties: {
        case: 'residualProperties',
        value: new outputpb.ResidualProperties({ type: outputpb.ResidualType.RESIDUAL_RELATIVE }),
      },
    });
    outputList.push(newOutput);
  };

  addNewQuantity(QuantityType.RESIDUAL_DENSITY);
  addNewQuantity(QuantityType.RESIDUAL_X_MOMENTUM);
  addNewQuantity(QuantityType.RESIDUAL_Y_MOMENTUM);
  addNewQuantity(QuantityType.RESIDUAL_Z_MOMENTUM);
  addNewQuantity(QuantityType.RESIDUAL_ENERGY);

  // TODO: LC-18639
  // Assume a single, fluid physics for now until we have a better story around sensitivity analysis
  // and multi-physics.
  const physics = param.physics[0];
  const viscous = physics.params.case === 'fluid' ?
    physics.params.value.basicFluid?.viscousModel : null;
  if (viscous !== INVISCID && viscous !== LAMINAR) {
    addNewQuantity(QuantityType.RESIDUAL_SA_VARIABLE);
  }
  return outputList;
}

const ResidualChart = (props: ResidualChartProps) => {
  const { projectId, workflowId, jobId } = useProjectContext();

  const simParam = useSimulationParam(projectId, workflowId, jobId);

  // TODO(albring): move to OutputPanel/OutputNode
  const outputList = useMemo<outputpb.Output[]>(
    () => newOutputList(simParam),
    [simParam],
  );

  const { data, iters } = useTimeSeriesOutput(
    projectId,
    workflowId,
    jobId,
    outputList,
    simParam,
    props.jobState,
    false,
  );

  return (
    <OutputChart
      data={data}
      dataConfig={RESIDUAL_DEFAULT}
      dataOnY={false}
      height={props.height}
      positions={[iters]}
      sawtooth={false}
      timeBarSettings={null}
      xyChart={false}
      yScale={Scale.LOG}
    />
  );
};

export default ResidualChart;
