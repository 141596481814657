// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 120;
const baseHeight = 98;
export const CameraIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="camera"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M112.5,15.25
           L93.075,15.25
           L80.25,2.425
           C78.975,1.075 77.1,0.25 75,0.25
           L45,0.25
           C42.9,0.25 41.025,1.075 39.675,2.425
           L26.925,15.25
           L7.5,15.25
           C3.375,15.25 0,18.625 0,22.75
           L0,75.25
           C0,79.375 3.375,82.75 7.5,82.75
           L26.7,82.75
           C34.95,91.9 46.725,97.75 60,97.75
           C73.275,97.75 85.05,91.9 93.3,82.75
           L112.5,82.75
           C116.625,82.75 120,79.375 120,75.25
           L120,22.75
           C120,18.625 116.625,15.25 112.5,15.25Z
           M22.5,37.75
           L7.5,37.75
           L7.5,30.25
           L22.5,30.25
           L22.5,37.75Z
           M60,82.75
           C43.425,82.75 30,69.325 30,52.75
           C30,36.175 43.425,22.75 60,22.75
           C76.575,22.75 90,36.175 90,52.75
           C90,69.325 76.575,82.75 60,82.75Z
           M60,37.75
           C51.75,37.75 45,44.5 45,52.75
           C45,61 51.75,67.75 60,67.75
           C68.25,67.75 75,61 75,52.75
           C75,44.5 68.25,37.75 60,37.75Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
