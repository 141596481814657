// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { DragControl } from '../../../lib/DragControl';
import {
  DiscreteValuesConstraint,
  DragConstraint,
  MinMaxDeltaConstraint,
} from '../../../lib/dragConstraints';
import { DragOffset } from '../../../lib/dragUtil';
import { isArrowKey } from '../../../lib/event';
import { clamp, generateRange } from '../../../lib/number';

import { Readout } from './Readout';
import { SliderThumb } from './Thumb';
import {
  CommonSliderProps,
  DEFAULT_GUTTER_HEIGHT,
  DEFAULT_INCREMENT_STEPS,
  DEFAULT_READOUT_TIMEOUT,
  DEFAULT_THUMB_RADIUS,
  isArrowDecrement,
} from './common';
import {
  ThumbValue,
  calculateStopValues,
  constrainToStopValues,
  subsetStopValues,
  useSliderState,
} from './util';

import './Slider.scss';

/**
 * The SimpleSlider component is an implementation of the slider mocked up in
 * the Figma designs at:
 * https://www.figma.com/design/jgngRpKr9HPeHuI8hKQfbS/Luminary-Component-Library-V2?node-id=3791-2317&m=dev
 * in the box marked Right Pane.

 */
export type SimpleSliderProps = CommonSliderProps<number>

export const SimpleSlider = (props: SimpleSliderProps) => {
  const {
    disabled = false,
    gutterHeight = DEFAULT_GUTTER_HEIGHT,
    incrementSteps = DEFAULT_INCREMENT_STEPS,
    min,
    max,
    onChange,
    onCommit,
    readoutConfig,
    showStops,
    stopCount,
    thumbRadius = DEFAULT_THUMB_RADIUS,
    thumbOffset = 0,
    value,
  } = props;

  // How far above the gutter should the readout appear
  const readoutOffset = readoutConfig?.offset ?? gutterHeight;

  // The useSliderState hook includes helper refs, functions, and state used
  // for common slider operations
  const {
    rootNodeRef,
    readoutActive, setReadoutActive,
    readoutTimer, setReadoutTimer,
    dragOffset, setDragOffset,
    dragStatus, setDragStatus,
    thumbDragState, setThumbDragState,
    stopValues, setStopValues,
    canClick,
    startDrag,
    endDrag,
    scheduleDeactivateReadout,
    prepareMouseInteraction,
  } = useSliderState();

  // Track DOM node containing the thumb control
  const thumbNode = useRef<HTMLDivElement>(null);
  // Wrap value in ThumbValue class for easier access to derived values (like percentage)
  const [thumbValue, setThumbValue] = useState(new ThumbValue(value, min, max));

  // Call onChange only if the new value differs from the existing value and
  // call onCommit when `commit` is true (i.e. when dragging ends or when the
  // value is changed by a simple click event).
  const announceValue = useCallback((newValue: number, commit = false) => {
    if (newValue !== value) {
      onChange(newValue);
    }
    if (commit && onCommit) {
      onCommit(newValue);
    }
  }, [onChange, onCommit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStopValues(calculateStopValues(min, max, stopCount));
  }, [max, min, setStopValues, stopCount]);

  useEffect(() => {
    setThumbValue(new ThumbValue(value, min, max));
  }, [max, min, value]);

  useEffect(() => {
    if (dragOffset && thumbDragState) {
      // Given a drag offset and a parent box width, extrapolate a `real` value
      const deltaValue = (dragOffset.deltaX / thumbDragState.boxWidth) * (max - min);
      const newValue = thumbDragState.initialValue.value + deltaValue;

      announceValue(newValue);
    }
  }, [announceValue, dragOffset, max, min, thumbDragState, value]);

  // When dragStatus is set to 'completed', set it to null and trigger a call to
  // onCommit
  useEffect(() => {
    if (dragStatus === 'completed') {
      setDragStatus(null);
      announceValue(value, true);
    }
  }, [announceValue, dragStatus, setDragStatus, value]);

  const handleClickRoot = (event: React.MouseEvent) => {
    if (!canClick) {
      return;
    }
    // If prepareMouseInteraction returns true, then handle clicks anywhere in
    // the DOM structure by moving the thumb to the x-coordinate of the click.
    if (!prepareMouseInteraction(event, disabled)) {
      return;
    }

    event.preventDefault();

    // event.target should be the root node, because the inner children all
    // have pointer-events set to none in the CSS or their onClick handlers
    // stop the event from bubbling
    const box = (event.target as HTMLElement).getBoundingClientRect();
    const xPosn = clamp(event.nativeEvent.offsetX, [0, box.width]);
    let newValue = ThumbValue.fromParameterized(xPosn / box.width, min, max).value;

    if (stopValues) {
      newValue = constrainToStopValues(newValue, stopValues).value;
    }

    scheduleDeactivateReadout(DEFAULT_READOUT_TIMEOUT);
    announceValue(newValue, true);
  };

  const handleMouseDownThumb = (event: React.MouseEvent) => {
    // Start a drag event if prepareMouseInteraction returns true
    if (!prepareMouseInteraction(event, disabled)) {
      return;
    }

    if (thumbNode.current && rootNodeRef.current) {
      // Constrain the drag operation to the left/right edges of the root node
      const refBox = rootNodeRef.current.getBoundingClientRect();
      const targetBox = thumbNode.current.getBoundingClientRect();
      const minDeltaX = refBox.left - (targetBox.left + thumbRadius);
      const maxDeltaX = refBox.right - (targetBox.right - thumbRadius);
      const constraints: DragConstraint[] = [];

      if (stopValues) {
        const initPoint = { x: thumbValue.pixelsFrom(refBox), y: 0 };
        const stops = stopValues.map((stop) => ({ x: stop.pixelsFrom(refBox), y: 0 }));
        constraints.push(
          new DiscreteValuesConstraint(initPoint, stops, 'horizontal'),
        );
      } else {
        constraints.push(
          new MinMaxDeltaConstraint(minDeltaX, maxDeltaX, 'horizontal'),
        );
      }

      // Initiate drag
      const dragControl = new DragControl(
        event.nativeEvent,
        () => {
          // When drag starts, activate the readout
          setReadoutActive(true);
          setDragStatus('started');
          startDrag();
        },
        (offset: DragOffset) => {
          // When dragging, update the dragOffset
          setDragOffset(offset);
        },
        () => {
          // When drag ends, reset the drag state and schedule the readout for deactivation
          setDragOffset(null);
          setThumbDragState(null);
          setDragStatus('completed');
          scheduleDeactivateReadout(DEFAULT_READOUT_TIMEOUT);
          endDrag();
        },
        constraints,
      );

      setThumbDragState({
        dragControl,
        boxWidth: refBox.width,
        initialValue: thumbValue,
      });
    }
  };

  const handleKeyDownThumb = (event: React.KeyboardEvent) => {
    // When the user clicks the left or right arrows while focused on the thumb,
    // move the thumb by increments defined by the `steps` prop
    if (disabled) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey) {
      return;
    }

    event.stopPropagation();

    if (isArrowKey(event)) {
      if (readoutTimer) {
        clearTimeout(readoutTimer);
        setReadoutTimer(null);
      }

      // If user is holding down SHIFT, multiply increments by 10
      const multiplier = event.shiftKey ? 10 : 1;

      const moveLeft = isArrowDecrement(event);
      const divisor = stopValues ? (stopValues.length - 1) : incrementSteps;
      const deltaValue = ((moveLeft ? -1 : 1) * multiplier * (max - min)) / divisor;

      let newValue = clamp(value + deltaValue, [min, max]);

      if (stopValues) {
        let subStops = subsetStopValues(
          stopValues,
          moveLeft ? null : value,
          moveLeft ? value : null,
          true,
        );
        if (event.shiftKey) {
          subStops = moveLeft ? subStops.slice(0, -9) : subStops.slice(9);
        }
        // If subStops is empty, then we're already at the far left or far right
        if (subStops.length) {
          const idx = moveLeft ? subStops.length - 1 : 0;
          newValue = subStops[idx].value;
        }
      }

      setReadoutActive(true);
      scheduleDeactivateReadout(DEFAULT_READOUT_TIMEOUT);

      announceValue(newValue, true);
    }
  };

  // When a thumb acquires focus, show the readout for a short time
  const handleFocusThumb = () => {
    // If thumbDragState is truthy, then the user has focused the thumb by
    // clicking to drag, and the readout state is already being managed.
    if (thumbDragState) {
      return;
    }

    // If we're here, then the user has focused the thumb by tabbing, and it's
    // useful to flash the readout for a brief moment.
    if (readoutTimer) {
      clearTimeout(readoutTimer);
      setReadoutTimer(null);
    }
    setReadoutActive(true);
    scheduleDeactivateReadout(DEFAULT_READOUT_TIMEOUT);
  };

  const dragging = !!thumbDragState;

  return (
    <div
      className={cx('simpleSlider', 'sliderCommon', { disabled, dragging })}
      onClick={(event) => handleClickRoot(event)}
      onKeyUp={() => { }}
      ref={rootNodeRef}
      role="button"
      style={{
        '--thumb-radius': `${thumbRadius}px`,
        '--thumb-offset': `${thumbOffset}px`,
        '--gutter-height': `${gutterHeight}px`,
      } as CSSProperties}
      tabIndex={0}>
      <div className="gutter">
        <div
          className="indicator"
          style={{
            width: `${thumbValue.percentageClamped}%`,
          }}
        />
        {showStops && stopValues && (
          <div className="stops">
            {generateRange(stopValues.length - 2).map((i) => (
              <div
                className="stopMark"
                key={`${i}`}
                style={{ left: `${(100 * (i + 1)) / (stopValues.length - 1)}%` }}
              />
            ))}
          </div>
        )}
      </div>
      <SliderThumb
        dragging={dragging}
        leftPercentage={thumbValue.percentageClamped}
        max={max}
        min={min}
        onFocus={handleFocusThumb}
        onKeyDown={handleKeyDownThumb}
        onMouseDown={handleMouseDownThumb}
        ref={thumbNode}
        value={value}
      />
      <Readout
        active={readoutActive}
        disabled={readoutConfig?.disabled || disabled}
        formatValue={readoutConfig?.formatValue}
        thumbValue={thumbValue}
        verticalOffset={readoutOffset}
      />
    </div>
  );
};
