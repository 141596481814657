// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 21;
export const ChartArrowRightIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="chartArrowRight"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M15.99,10.38
           C16.65,11.19 17.595,11.745 18.69,11.925
           L14.565,16.05
           C14.295,16.335 13.92,16.5 13.5,16.5
           C13.08,16.5 12.705,16.335 12.435,16.05
           L7.5,11.115
           L3,15.615
           L3,18
           L22.5,18
           C23.325,18 24,18.675 24,19.5
           C24,20.325 23.325,21 22.5,21
           L1.5,21
           C0.675,21 0,20.325 0,19.5
           L0,4.5
           C0,3.675 0.675,3 1.5,3
           C2.325,3 3,3.675 3,4.5
           L3,11.385
           L6.435,7.935
           C6.705,7.665 7.08,7.5 7.5,7.5
           C7.92,7.5 8.295,7.665 8.565,7.935
           L13.5,12.885
           L15.99,10.38Z
           M18.45,6.45
           L18.9,6
           L12,6
           C11.1,6 10.5,5.4 10.5,4.5
           C10.5,3.6 11.1,3 12,3
           L18.9,3
           L18.45,2.55
           C18.15,2.25 18,1.95 18,1.5
           C18,0.6 18.75,0 19.5,0
           C19.95,0 20.25,0.15 20.55,0.45
           L23.55,3.45
           C23.85,3.75 24,4.05 24,4.5
           C24,4.95 23.85,5.25 23.55,5.55
           L20.55,8.55
           C20.25,8.85 19.95,9 19.5,9
           C18.6,9 18,8.4 18,7.5
           C18,7.05 18.15,6.75 18.45,6.45Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
