// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState, useSetRecoilState } from 'recoil';

import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import { isTestingEnv } from '../lib/testing/utils';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';

import { selectedGeometryKey } from './selectedGeometryKey';

function serialize(val: projectstatepb.SelectedGeometry): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}

function deserialize(val: Uint8Array): projectstatepb.SelectedGeometry {
  return (val.length ?
    projectstatepb.SelectedGeometry.fromBinary(val) :
    new projectstatepb.SelectedGeometry());
}

const selectedGeometryStateRpc = selectorFamily<projectstatepb.SelectedGeometry, string>({
  key: `${selectedGeometryKey}/rpc`,
  get: (projectId: string) => () => (
    persist.getProjectState(projectId, [selectedGeometryKey], deserialize)
  ),
});

const selectedGeometryStateTesting = selectorFamily<projectstatepb.SelectedGeometry, string>({
  key: selectedGeometryKey,
  get: () => () => new projectstatepb.SelectedGeometry(),
});

const selectedGeometryStateSelector = isTestingEnv() ?
  selectedGeometryStateTesting : selectedGeometryStateRpc;

// Currently selected geometry in the setup tab.
export const selectedGeometryState = atomFamily<projectstatepb.SelectedGeometry, string>({
  key: `${selectedGeometryKey}/final`,
  default: selectedGeometryStateSelector,
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, selectedGeometryKey, deserialize, serialize),
  ],
});

export const useSelectedGeometry = (projectId: string) => (
  useRecoilState(selectedGeometryState(projectId))
);

export const useSetSelectedGeometry = (projectId: string) => (
  useSetRecoilState(selectedGeometryState(projectId))
);
