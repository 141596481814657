// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';

import PropertiesSection, { PropertiesSectionProps } from './PropertiesSection';

const useStyles = makeStyles(
  () => createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      gap: '8px',
    },
    label: {
      fontSize: '13px',
      fontWeight: 600,
      color: colors.neutral650,
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'LabeledSection' },
);

export interface LabeledSectionProps extends PropertiesSectionProps {
  label: string;
}

export const LabeledSection = (props: LabeledSectionProps) => {
  const { children, label } = props;

  const classes = useStyles();

  return (
    <PropertiesSection>
      <div className={classes.container}>
        <div className={classes.label}>{label}</div>
        {children}
      </div>
    </PropertiesSection>
  );
};
