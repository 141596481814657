import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { newAdFloat } from '../../../lib/adUtils';
import assert from '../../../lib/assert';
import { macroEmitter } from '../../../lib/assistantMacros';
import newInt from '../../../lib/intUtils';
import { clampMaxMeshCount } from '../../../lib/meshingStatusUtils';
import { projectLink, projectsLink } from '../../../lib/navigation';
import { Logger } from '../../../lib/observability/logs';
import { protoToJson } from '../../../lib/proto';
import { getOrCreateAdaptiveMeshRefinement } from '../../../lib/simulationParamUtils';
import sleep from '../../../lib/sleep';
import { useIsMeshPending } from '../../../recoil/pendingWorkOrders';
import { MeshPanelType, useSetMeshPanelState } from '../../../recoil/useMeshPanelState';
import { useMeshValidator } from '../../../recoil/useMeshValidator';
import { useProjectMeshList } from '../../../recoil/useProjectMeshList';
import { useProjectContext } from '../../context/ProjectContext';
import { useAssistantSyncData } from '../../hooks/assistant/useAssistantSyncData';
import { useZoomToFit } from '../../hooks/useCamera';
import { useIsLMAActive, useIsMinimalMeshMode, useSetLMA } from '../../hooks/useMesh';
import { useNodeSelect } from '../../hooks/useNodeSelect';
import { useClickRunSimulationButton, useRunSimulationButtonProps } from '../../hooks/useRunSimulation';
import { useSimulationConfig } from '../../hooks/useSimulationConfig';
import { useOnParamUpload, usePrepareSimulationSettings } from '../../treePanel/SimulationTreeMoreMenu';
import { useGenerateMesh } from '../../treePanel/propPanel/mesh/components/GenerateMeshButton';

const logger = new Logger('MacroSubscriber');

const useGenerateMinimalMesh = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const generateMesh = useGenerateMesh();
  const meshWarning = useMeshValidator(projectId, workflowId, jobId, readOnly);
  const [meshList] = useProjectMeshList(projectId);
  const isMinimalMeshMode = useIsMinimalMeshMode(projectId);
  const { disabledReason } = meshWarning;

  const generateMinimalMesh = useCallback(async () => {
    if (disabledReason) {
      throw new Error('Cannot generate minimal mesh due to warnings');
    }
    if (meshList.length) {
      throw new Error(`The project already has ${isMinimalMeshMode ?
        'a minimal mesh selected' : 'existing meshes'
      }. There is no need to generate a new minimal mesh.`);
    }
    await sleep(0);
    await generateMesh();
  }, [generateMesh, disabledReason, isMinimalMeshMode, meshList]);

  return generateMinimalMesh;
};

type AdaptMeshSettings = {
  maxMeshSize: number,
  adaptBoundaryLayerCount: number,
  initialSize: number,
  growthRate: number,
}

const useCreateLumiMeshAdaptation = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setLMA } = useSetLMA();
  const meshWarning = useMeshValidator(projectId, workflowId, jobId, readOnly);
  const isLMAActive = useIsLMAActive();
  const { saveParam } = useSimulationConfig();
  const setMeshPanel = useSetMeshPanelState(projectId);
  const meshGenerating = useIsMeshPending(projectId);

  const [triggerAdapt, setTriggerAdapt] = useState<null | AdaptMeshSettings>(null);
  const { disabledReason } = meshWarning;

  const adaptLumiMesh = useCallback(async (
    _projectId: string,
    maxMeshSize: number = 10,
    adaptBoundaryLayerCount: number = 30,
    initialSize: number = 0.000005,
    growthRate: number = 1.2,
  ) => {
    if (disabledReason) {
      throw new Error('Cannot create lumi mesh adaptation due to warnings');
    }
    if (meshGenerating) {
      throw new Error(`Cannot create lumi mesh adaption while the mesh is being generated.
        Try again after the mesh is generated.`);
    }
    setLMA(true);
    setTriggerAdapt({
      maxMeshSize,
      adaptBoundaryLayerCount,
      initialSize,
      growthRate,
    });

    // If we are in the mesh select or mesh edit panel, we should get away from it when entering LMA
    setMeshPanel(MeshPanelType.DETAILS);
  }, [setLMA, disabledReason, setMeshPanel, meshGenerating]);

  useEffect(() => {
    if (isLMAActive && triggerAdapt) {
      saveParam((newParam) => {
        const amr = getOrCreateAdaptiveMeshRefinement(newParam).clone();

        // Update the Max Mesh Count size
        amr.targetCvMillions = clampMaxMeshCount(triggerAdapt.maxMeshSize);

        // Update the rest of the props for the default Adaptation Boundary Layer
        const blp = amr.boundaryLayerProfile[0];
        blp.nLayers = newInt(triggerAdapt.adaptBoundaryLayerCount);
        blp.initialSize = newAdFloat(triggerAdapt.initialSize);
        blp.growthRate = newAdFloat(triggerAdapt.growthRate);
        newParam.adaptiveMeshRefinement = amr;
        return newParam;
      });

      // Make sure we do not run this effect again accidentally
      setTriggerAdapt(null);
    }
  }, [isLMAActive, triggerAdapt, saveParam]);

  return adaptLumiMesh;
};

const useMacroSubscription = () => {
  const navigate = useNavigate();
  const onParamUpload = useOnParamUpload();
  const prepareSettings = usePrepareSimulationSettings();
  const zoomToFit = useZoomToFit();
  const assistantSyncData = useAssistantSyncData();
  const runSimulation = useClickRunSimulationButton();
  const runSumulationProps = useRunSimulationButtonProps();
  const createLumiMeshAdaptation = useCreateLumiMeshAdaptation();
  const select = useNodeSelect();

  const goToProjectList = useCallback(() => {
    navigate(projectsLink());
    window.location.reload();
  }, [navigate]);

  const openProject = useCallback((projectId: string) => {
    navigate(projectLink(projectId));
  }, [navigate]);

  const uploadCadOrMesh = useCallback((projectId: string) => {
    navigate(projectLink(projectId));
    window.location.reload();
  }, [navigate]);

  const minimalMesh = useGenerateMinimalMesh();

  const pullSettings = useCallback(async () => {
    const settings = prepareSettings();
    const json = await protoToJson(settings);
    return JSON.stringify(json);
  }, [prepareSettings]);

  useEffect(() => {
    const macroSubscription = macroEmitter.subscribe(async (macroData) => {
      if (!macroData) {
        return;
      }
      const { action, args, resolve, reject } = macroData;

      try {
        let result: any;

        switch (action) {
          case 'pullUiState':
            result = assistantSyncData;
            break;
          case 'home':
            goToProjectList();
            break;
          case 'openProject':
            assert(!!args, 'Expected argument for openProject');
            openProject(args[0]);
            break;
          case 'selectNode':
            assert(!!args, 'Expected arguments for selectNode');
            result = select(args[0]);
            break;
          case 'uploadCadOrMesh':
            assert(!!args, 'Expected arguments for uploadCadOrMesh');
            result = uploadCadOrMesh(args[0]);
            break;
          case 'zoomToFit':
            result = zoomToFit();
            break;
          case 'minimalMesh':
            result = await minimalMesh();
            break;
          case 'setMeshAdaptation':
            assert(!!args, 'Expected arguments for setMeshAdaptation');
            result = createLumiMeshAdaptation(
              ...args as [string, number, number, number, number],
            );
            break;
          case 'pullSettings':
            result = pullSettings();
            break;
          case 'pushSettings': {
            assert(!!args, 'Expected arguments for pushSettings');
            try {
              JSON.parse(args[0]);
              const file = new File([args[0]], 'Assistant Settings', { type: 'application/json' });
              result = await onParamUpload(file);
            } catch (err) {
              throw new Error(err);
            }
            break;
          }
          case 'createSimulation':
            if (runSumulationProps.disabled) {
              throw new Error(`There are some errors that need to be fixed before
                running the simulation.`);
            }
            result = await runSimulation();
            break;

          // The following cases are not yet implemented.
          default:
            logger.error(`Unsupported macro: ${action}`);
            break;
        }
        resolve(result);
      } catch (err) {
        logger.error(`Error executing macro: ${action}`, err);
        reject(err);
      }
      // Reset the BehaviorSubject to prevent the same macro from being executed again.
      macroEmitter.next(null);
    });

    return () => {
      macroSubscription.unsubscribe();
    };
  }, [
    assistantSyncData,
    openProject,
    runSimulation,
    runSumulationProps.disabled,
    prepareSettings,
    goToProjectList,
    minimalMesh,
    pullSettings,
    onParamUpload,
    createLumiMeshAdaptation,
    uploadCadOrMesh,
    zoomToFit,
    select,
  ]);
};

/**
 * The assistant macros live in assistantMacros.ts. But the assistant macros
 * need to be able to call into React state and hooks.
 *
 * The MacroSubscriber is a React component that subscribes to the macros
 * called by the assistant, and calls the appropriate React hooks to update the
 * app state. It doesn't render anything.
 */
export const MacroSubscriber = () => {
  useMacroSubscription();

  return <></>;
};
