// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const ListBulletedIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="listBulleted"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M3,9
           C1.35,9 0,10.35 0,12
           C0,13.65 1.35,15 3,15
           C4.65,15 6,13.65 6,12
           C6,10.35 4.65,9 3,9Z
           M9,4.5
           L22.5,4.5
           C23.325,4.5 24,3.825 24,3
           C24,2.175 23.325,1.5 22.5,1.5
           L9,1.5
           C8.175,1.5 7.5,2.175 7.5,3
           C7.5,3.825 8.175,4.5 9,4.5Z
           M3,18
           C1.35,18 0,19.35 0,21
           C0,22.65 1.35,24 3,24
           C4.65,24 6,22.65 6,21
           C6,19.35 4.65,18 3,18Z
           M22.5,10.5
           L9,10.5
           C8.175,10.5 7.5,11.175 7.5,12
           C7.5,12.825 8.175,13.5 9,13.5
           L22.5,13.5
           C23.325,13.5 24,12.825 24,12
           C24,11.175 23.325,10.5 22.5,10.5Z
           M22.5,19.5
           L9,19.5
           C8.175,19.5 7.5,20.175 7.5,21
           C7.5,21.825 8.175,22.5 9,22.5
           L22.5,22.5
           C23.325,22.5 24,21.825 24,21
           C24,20.175 23.325,19.5 22.5,19.5Z
           M3,0
           C1.35,0 0,1.35 0,3
           C0,4.65 1.35,6 3,6
           C4.65,6 6,4.65 6,3
           C6,1.35 4.65,0 3,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
