// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 114;
const baseHeight = 124;
export const DoubleArrowLeftRightIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="doubleArrowLeftRight"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeMiterlimit: 2,
        strokeLinejoin: 'round',
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M84.899,0
        C84.899,0 102.967,18.067 111.95,27.05
        C113.263,28.363 114,30.143 114,32
        C114,33.857 113.263,35.637 111.95,36.95
        C102.967,45.933 84.899,64 84.899,64
        L77.121,56.222
        L95.843,37.5
        L0,37.5
        L0,26.5
        L95.843,26.5
        L77.121,7.619
        L84.899,0Z"
        fill={getColor(0)}
      />
      <path
        d="M29.101,124
        C29.101,124 11.033,105.933 2.05,96.95
        C0.737,95.637 0,93.857 0,92
        C0,90.143 0.737,88.363 2.05,87.05
        C11.033,78.067 29.101,60 29.101,60
        L36.879,67.778
        L18.157,86.5
        L114,86.5
        L114,97.5
        L18.157,97.5
        L36.879,116.381
        L29.101,124Z"
        fill={getColor(0)}
      />
    </svg>
  );
});
