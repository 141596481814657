// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 30;
const baseHeight = 28;
export const BarChartIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="barChart"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M14,22
           L16,22
           C17.1,22 18,21.1 18,20
           L18,2
           C18,0.9 17.1,-0 16,-0
           L14,-0
           C12.9,-0 12,0.9 12,2
           L12,20
           C12,21.1 12.9,22 14,22Z
           M24,22
           L26,22
           C27.1,22 28,21.1 28,20
           L28,8
           C28,6.9 27.1,6 26,6
           L24,6
           C22.9,6 22,6.9 22,8
           L22,20
           C22,21.1 22.9,22 24,22Z
           M28,24
           L2,24
           C0.9,24 -0,24.9 -0,26
           C-0,27.1 0.9,28 2,28
           L28,28
           C29.1,28 30,27.1 30,26
           C30,24.9 29.1,24 28,24Z
           M4,22
           L6,22
           C7.1,22 8,21.1 8,20
           L8,12
           C8,10.9 7.1,10 6,10
           L4,10
           C2.9,10 2,10.9 2,12
           L2,20
           C2,21.1 2.9,22 4,22Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
