// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/*
 Blank page to be shown before a user is authenticated.
*/
import React from 'react';

import BaseLayout from '../components/layout/BaseLayout';

const LandingPage = () => (
  <BaseLayout title="Login | Luminary Cloud" />
);

export default LandingPage;
