// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useState } from 'react';

import * as frontendpb from '../../../../proto/frontend/frontend_pb';

export const useProjectDialogState = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [projectId, setProjectId] = useState<string | undefined>();
  const [values, setValues] = useState({ name: '', description: '' });

  const newProject = () => {
    setIsOpen(true);
    setIsEdit(false);
    setProjectId(undefined);
    setValues({ name: '', description: '' });
  };

  const editProject = (project: frontendpb.ProjectSummary) => {
    setIsOpen(true);
    setIsEdit(true);
    setProjectId(project.projectId);
    setValues({ name: project.name, description: project.description });
  };

  const close = () => {
    setIsOpen(false);
  };

  return {
    newProject,
    editProject,
    close,
    props: {
      isOpen,
      isEdit,
      onCancel: close,
      projectId,
      ...values,
    },
  };
};
