// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const RingExclamationIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="ringExclamation"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M12,24
           C5.373,24 0,18.627 0,12
           C0,5.373 5.373,0 12,0
           C18.627,0 24,5.373 24,12
           C24,18.627 18.627,24 12,24Z
           M12,21
           C16.971,21 21,16.971 21,12
           C21,7.029 16.971,3 12,3
           C7.029,3 3,7.029 3,12
           C3,16.971 7.029,21 12,21Z
           M13.5,18
           L10.5,18
           L10.5,15
           L13.5,15
           L13.5,18Z
           M13.5,13.5
           L10.5,13.5
           L10.5,6
           L13.5,6
           L13.5,13.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
