// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useContentOverflow } from '../../../lib/useContentOverflow';
import { useSyncInfoCardDims } from '../../../recoil/lcvis/infoCardDims';
import { IconButton } from '../../Button/IconButton';
import Tooltip from '../../Tooltip';
import { OverflowGradient } from '../../common/OverflowGradient';
import { DuplicateIcon } from '../../svg/DuplicateIcon';
import { ResetIcon } from '../../svg/ResetIcon';

import { OverlayValue, formatOverlayValue } from './util';

import './Card.scss';

// the number of ms to show the tooltip text as 'Copied' after copying a probe entry.
const COPY_TEXT_TIMEOUT = 2000;

interface CardRowProps {
  label: string;
  value: OverlayValue;
  unit?: string;
}

/** The row component displayed for each copyable property. */
const CardRow = ({ label, value, unit = '' }: CardRowProps) => {
  // the key of the item that was just copied to the clipboard
  const [isRecentlyCopied, setIsRecentlyCopied] = useState(false);
  const copyTimeout = useRef<ReturnType<typeof setTimeout> | number>(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const hasContentOverflow = useContentOverflow(contentRef);

  const clearCopyTimeout = () => {
    copyTimeout.current && clearTimeout(copyTimeout.current);
  };

  // Copy the data from props.data to the clipboard, and set copiedEntry to its key.
  const copyValue = useCallback(async () => {
    // when we copy to clipboard, copy the raw value and not the formatted entry.
    await navigator.clipboard.writeText(JSON.stringify(value));
    clearCopyTimeout();
    setIsRecentlyCopied(true);

    copyTimeout.current = setTimeout(() => setIsRecentlyCopied(false), COPY_TEXT_TIMEOUT);
  }, [value]);

  // cancel the timeout if it's still active when we unmount.
  useEffect(() => () => clearCopyTimeout(), []);

  return (
    <div className="entry row">
      <div className="key">{label}</div>
      <div className="value">
        <span className="content" ref={contentRef}>
          {formatOverlayValue(value)}{unit ? ` ${unit}` : ''}
          {hasContentOverflow && <OverflowGradient />}
        </span>

        <Tooltip
          arrow={false}
          placement="top"
          title={isRecentlyCopied ? 'Copied' : 'Copy Values'}>
          <IconButton
            className="copyButton"
            onClick={() => copyValue()}>
            <DuplicateIcon maxHeight={12} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

interface CardProps {
  title: string;
  onReset: () => void;
  // The data to display in the Card.
  data: Map<string, OverlayValue>;
  disableReset?: boolean;
  unit: string;
  containerTestId: string;
  resetTestId: string;
  clearTitle: string;
}

/** The card component displayed when the user is probing a mesh. */
const Card = (props: CardProps) => {
  const {
    title = 'Probed Values',
    onReset,
    data,
    disableReset,
    unit,
    containerTestId,
    resetTestId,
    clearTitle,
  } = props;
  const ref = useSyncInfoCardDims();

  return (
    <div className="cardContainer" data-testid={containerTestId} ref={ref}>
      <div className="header row">
        <div className="title">{title}</div>
        <Tooltip
          arrow={false}
          placement="top"
          title={clearTitle}>
          <span>
            <IconButton
              data-testid={resetTestId}
              disabled={disableReset}
              onClick={onReset}>
              <ResetIcon maxHeight={12} />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      <div className="data">
        {[...data.entries()].map(([key, value]) => (
          <CardRow key={key} label={key} unit={unit} value={value} />
        ))}
      </div>
    </div>
  );
};

export default Card;
