// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVObject, LCVType } from '@luminarycloudinternal/lcvis';

import { DEFAULT_POINT_RADIUS, LcvModule } from '../../types';
import { LcvDisplay } from '../LcvDisplay';

import { LcvWidget } from './LcvWidget';

export type LcvisMeasureCallback = (
  length: number,
  mousePos: [number, number],
  pointToUdpdate: number,
  startPoint: [number, number, number],
  endPoint: [number, number, number],
) => void

export class LcvMeasureWidget extends LcvWidget {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, sessionHandle, 'line_widget');
    this.setParam('point_size', LCVType.kLCVDataTypeFloat, DEFAULT_POINT_RADIUS);
    this.setParam('draw_text', LCVType.kLCVDataTypeInt, 1);
    // There is a base size text and the default scaling is 1.
    this.setParam('text_scaling', LCVType.kLCVDataTypeFloat, 0.5);
    // TODO(matt): I never actually hooked this up in lcvis.
    // this.setParam('min_callback_freq_ms', LCVType.kLCVDataTypeUint, 100);
  }

  reset() {
    this.setParam('reset', LCVType.kLCVDataTypeInt, 1);
  }

  setCallback(callback: null | LcvisMeasureCallback, display: LcvDisplay) {
    const internalCallback = callback ? (
      _lcv: LcvModule,
      _session: LCVObject,
      obj: LCVObject,
      message: string,
    ) => {
      const { workspace, simAnnotationHandler } = display;
      if (!workspace || !simAnnotationHandler) {
        return;
      }
      const length = this.getProperty('length', LCVType.kLCVDataTypeFloat);
      const pointToUpdate = this.getProperty('point_to_update', LCVType.kLCVDataTypeInt);
      const mousePos = this.getProperty('mouse_pos', LCVType.kLCVDataTypeInt2);
      const startPoint = this.getProperty('point_0', LCVType.kLCVDataTypeFloat3);
      const endPoint = this.getProperty('point_1', LCVType.kLCVDataTypeFloat3);
      callback(length, mousePos, pointToUpdate, startPoint, endPoint);
    } : null;

    this.setParam(
      'line_updated_callback',
      LCVType.kLCVDataTypeFunction,
      internalCallback,
    );
  }
}
