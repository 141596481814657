// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { findMaterialIdByDomain, findPhysicsIdByDomain, getAssignedMaterialDomains } from '../../../lib/entityRelationships';
import { NodeType } from '../../../lib/simulationTree/node';
import { defaultNodeFilter, mapVisualizerEntitiesToVolumes } from '../../../lib/subselectUtils';
import { mapDomainsToIds } from '../../../lib/volumeUtils';
import { useMaterialEntity } from '../../../model/hooks/useMaterialEntity';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { useProjectContext } from '../../context/ProjectContext';

function getDomainDisabledReason(
  domain: string,
  usedDomains: Set<string>,
  materialAssigned: boolean,
  physicsAssigned: boolean,
) {
  if (usedDomains.has(domain)) {
    return 'Volume is already assigned to another material';
  }
  if (materialAssigned && physicsAssigned) {
    return 'Volume assigned to a physics cannot be removed from its material';
  }
  return '';
}
/**
 * Custom hook that returns NodeSubselect props to be used when managing a material's volumes
 */
export const useMaterialVolumes = (materialId: string) => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const staticVolumes = useStaticVolumes(projectId);
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId);

  // == Models
  const {
    setAssignedVolumeNodeIds,
    assignedVolumeNodeIds,
  } = useMaterialEntity(projectId, workflowId, jobId, readOnly, materialId);

  const domainAssignmentDisabledReasons = useMemo(() => {
    const disabledReasons: Record<string, string> = {};
    const usedDomains = getAssignedMaterialDomains(simParam, geometryTags, materialId);

    staticVolumes.forEach(({ domain, id }) => {
      const assignedMaterialId = findMaterialIdByDomain(simParam, domain, geometryTags);
      const assignedPhysicsId = findPhysicsIdByDomain(simParam, domain, geometryTags);
      disabledReasons[id] = getDomainDisabledReason(
        domain,
        usedDomains,
        !!assignedMaterialId,
        !!assignedPhysicsId,
      );
    });
    return disabledReasons;
  }, [geometryTags, materialId, simParam, staticVolumes]);

  /** Memoize the nodeFilter object passed to NodeSubselect to avoid infinite looping */
  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (nodeType === NodeType.VOLUME) {
      const disabledReason = domainAssignmentDisabledReasons[nodeId] || '';
      return {
        related: true,
        tooltip: disabledReason,
        disabled: !!disabledReason,
      };
    }
    if (nodeType === NodeType.SURFACE_GROUP) {
      const domains = geometryTags.domainsFromTagEntityGroupId(nodeId);
      if (domains?.length) {
        const disabledReason = mapDomainsToIds(staticVolumes, domains)
          .map((volumeId) => domainAssignmentDisabledReasons[volumeId]);

        if (disabledReason.length === domains.length) {
          return {
            related: true,
            tooltip: disabledReason.join(', '),
            disabled: disabledReason.some((reason) => !!reason),
          };
        }
        return {
          related: true,
          disabled: false,
        };
      }
    }
    return defaultNodeFilter(nodeType);
  }, [domainAssignmentDisabledReasons, geometryTags, staticVolumes]);

  /**
   * Memoize referenceNodeIds array passed to NodeSubselect to avoid triggering useEffect that
   * calls onChange
   */
  const referenceNodeIds = useMemo(() => [materialId], [materialId]);

  /**
   * A callback for mapping entities clicked in the visualizer to volumes that can be toggled
   */
  const mapVisualizerEntities = useCallback(
    (ids: string[]) => mapVisualizerEntitiesToVolumes(ids, staticVolumes),
    [staticVolumes],
  );

  return {
    setAssignedVolumeNodeIds,
    assignedVolumeNodeIds,
    nodeFilter,
    referenceNodeIds,
    mapVisualizerEntities,
  };
};
