// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';

/**
 * This atom contains the ID of the last selected mesh on a given project
 */
const previouslySelectedMeshAtom = atomFamily((projectId: string) => atom(''));

export function useSetPreviouslySelectedMesh(projectId: string) {
  return useSetAtom(previouslySelectedMeshAtom(projectId));
}

export function usePreviouslySelectedMesh(projectId: string) {
  return useAtomValue(previouslySelectedMeshAtom(projectId));
}
