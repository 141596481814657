// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { NodeFilter, SimulationSubselect } from '../recoil/simulationTreeSubselect';
import { StaticVolume } from '../recoil/volumes';

import { intersects } from './lang';
import { GEOMETRY_TREE_NODE_TYPES, NodeType, SimulationTreeNode } from './simulationTree/node';

// Subselect implementations can compute on the fly whether nodes can be selected and what
// tooltips they should have, but they may also generate a data structure in advance for looking
// up a node's status, using this interface.
export interface NodeFilterData<T extends NodeType> {
  type: T;
  disabled: boolean;
  tooltip?: string;
}

// In most cases, a subselect will configure nodeFilter for a particular set of geometry node types.
// Use the following to apply defaults to the remaining node types.
export function defaultNodeFilter(nodeType: NodeType) {
  // Usually, irrelevant geometry rows should be hidden during subselect, but the root Geometry node
  // always appears and needs to be dimmed.  Just to be safe, apply the following to any other
  // remaining geometry nodes.
  if (GEOMETRY_TREE_NODE_TYPES.has(nodeType)) {
    return {
      related: true,
      disabled: true,
    };
  }
  return { related: false };
}

export function isSubselectActive(treeSubselect: SimulationSubselect, id: string) {
  return treeSubselect.active && id === treeSubselect.id;
}

export function canSubselectTypeAndId(nodeFilter: NodeFilter, type: NodeType, id: string) {
  const result = nodeFilter(type, id);
  if (result.related) {
    return !result.disabled;
  }
  return false;
}

export function isNodeDisabled(nodeFilter: NodeFilter, node: SimulationTreeNode): boolean {
  const result = nodeFilter(node.type, node.id);
  return result.related && !!result.disabled;
}

// Return true if a simulation tree node is allowed to be sub-selected
export function canSubselectNode(nodeFilter: NodeFilter, node: SimulationTreeNode) {
  return canSubselectTypeAndId(nodeFilter, node.type, node.id);
}

// Walk the node, adding to the idsToSelect and selectableIds arrays as needed
function findSelectableRows(node: SimulationTreeNode, filter: NodeFilter, selectableIds: string[]) {
  if (canSubselectNode(filter, node)) {
    // This node should be revealed in the tree
    selectableIds.push(node.id);
  }
  node.children.forEach(
    (child) => findSelectableRows(child, filter, selectableIds),
  );
}

// Get and return the rows that should initially be selected and those that should be initially
// expanded
export function getSelectableRows(treeRoot: SimulationTreeNode, nodeFilter: NodeFilter) {
  const selectableIds: string[] = [];
  findSelectableRows(treeRoot, nodeFilter, selectableIds);

  return selectableIds;
}

// Find the volumes that contain surfaces matching the selected IDs
export function mapVisualizerEntitiesToVolumes(ids: string[], staticVolumes: StaticVolume[]) {
  const volumes = staticVolumes.filter(
    (staticVolume) => intersects(staticVolume.bounds, ids),
  );
  return volumes.map((volume) => volume.id);
}
