// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as FileSaver from 'file-saver';

import { addRpcError } from './transientNotification';

// Saves text in txt format.
export function saveStringAsTxt(string: any, filename: string): void {
  const saver = async () => {
    const blob = new Blob([string], { type: 'text/plain;charset=utf-8' });
    return FileSaver.saveAs(blob, filename);
  };
  saver().catch((err: Error) => {
    addRpcError(`Save ${filename} failed`, err);
  });
}
