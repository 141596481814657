// Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import { DefaultContent } from './DefaultContent';
import { ContentRenderArgs } from './common';

// Default content requires separate 'title' and 'body' values
export interface ListItemDefaultContent {
  title: string;
  body: ReactNode;
  titleContent?: ReactNode;
}

// Custom content has a render function that returns a ReactNode
export interface ListItemCustomContent {
  render: (args: ContentRenderArgs) => ReactNode;
}

export type ListItemContent = ListItemDefaultContent | ListItemCustomContent;

export interface ListItemProps {
  // Default or custom content
  content: ListItemContent;
  // Arguments for list item content rendering
  renderArgs: ContentRenderArgs;
}

export const ListItem = (props: ListItemProps) => {
  const {
    content,
    renderArgs,
  } = props;

  const childContent = 'render' in content ?
    content.render(renderArgs) :
    (
      <DefaultContent
        body={content.body}
        renderArgs={renderArgs}
        title={content.title}
        titleContent={content.titleContent}
      />
    );

  return (
    <div className="listItem">{childContent}</div>
  );
};
