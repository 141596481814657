// Copyright 2020-2021 Luminary Cloud, Inc. All Rights Reserved.

// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/projectstate/projectstate.proto (package luminary.proto.projectstate, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { OutputNodes, StoppingConditions } from "../frontend/output/output_pb.js";
import { Exploration } from "../exploration/exploration_pb.js";

/**
 * @generated from enum luminary.proto.projectstate.MaterialPreset
 */
export const MaterialPreset = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.projectstate.MaterialPreset",
  [
    {no: 0, name: "CUSTOM"},
    {no: 1, name: "STANDARD_AIR"},
    {no: 2, name: "WATER_NTP"},
  ],
);

/**
 * @generated from enum luminary.proto.projectstate.ControlsPreset
 */
export const ControlsPreset = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.projectstate.ControlsPreset",
  [
    {no: 0, name: "CUSTOM_CONTROLS"},
    {no: 1, name: "DEFAULT_CONTROLS"},
    {no: 2, name: "INTERMEDIATE"},
    {no: 3, name: "AGGRESSIVE"},
  ],
);

/**
 * @generated from enum luminary.proto.projectstate.DiscretizationPreset
 */
export const DiscretizationPreset = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.projectstate.DiscretizationPreset",
  [
    {no: 0, name: "CUSTOM_DISCRETIZATION"},
    {no: 1, name: "DEFAULT_DISCRETIZATION"},
    {no: 2, name: "CONSERVATIVE"},
    {no: 3, name: "HIGH_ACCURACY"},
  ],
);

/**
 * @generated from enum luminary.proto.projectstate.UrlType
 */
export const UrlType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.projectstate.UrlType",
  [
    {no: 0, name: "MESH"},
    {no: 1, name: "GEOMETRY"},
  ],
);

/**
 * @generated from enum luminary.proto.projectstate.CheckGeometryStatus
 */
export const CheckGeometryStatus = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.projectstate.CheckGeometryStatus",
  [
    {no: 0, name: "UNCHECKED"},
    {no: 1, name: "RECHECKING"},
    {no: 2, name: "SUCCESSFUL"},
  ],
);

/**
 * @generated from message luminary.proto.projectstate.InitializationState
 */
export const InitializationState = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.projectstate.InitializationState",
  () => [
    { no: 1, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "iter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.projectstate.FrontendMenuState
 */
export const FrontendMenuState = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.projectstate.FrontendMenuState",
  () => [
    { no: 1, name: "init_state", kind: "message", T: InitializationState },
    { no: 2, name: "material_preset", kind: "enum", T: proto3.getEnumType(MaterialPreset) },
    { no: 3, name: "controls_preset", kind: "enum", T: proto3.getEnumType(ControlsPreset) },
    { no: 4, name: "discretization_preset", kind: "enum", T: proto3.getEnumType(DiscretizationPreset) },
    { no: 5, name: "output_nodes", kind: "message", T: OutputNodes },
    { no: 6, name: "stop_conds", kind: "message", T: StoppingConditions },
    { no: 7, name: "mesh_scaling", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * For key "meshUrl". Stores the mesh URLs for the setup screen. The URLs below
 * can all have the same value.
 *
 * @generated from message luminary.proto.projectstate.MeshUrl
 */
export const MeshUrl = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.projectstate.MeshUrl",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "geometry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "mesh", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "active_type", kind: "enum", T: proto3.getEnumType(UrlType) },
    { no: 5, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "preview", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * For key "experiments"
 *
 * @generated from message luminary.proto.projectstate.ExplorationSet
 */
export const ExplorationSet = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.projectstate.ExplorationSet",
  () => [
    { no: 1, name: "exploration", kind: "message", T: Exploration, repeated: true },
    { no: 2, name: "index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * For paraview-related configurations.  The payload is encoded as JSON.
 *
 * @generated from message luminary.proto.projectstate.ParaviewSetting
 */
export const ParaviewSetting = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.projectstate.ParaviewSetting",
  () => [
    { no: 1, name: "json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * The information for creating a tab.
 *
 * @generated from message luminary.proto.projectstate.TabInfo
 */
export const TabInfo = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.projectstate.TabInfo",
  () => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "closable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.projectstate.ClosableTabs
 */
export const ClosableTabs = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.projectstate.ClosableTabs",
  () => [
    { no: 3, name: "tab", kind: "message", T: TabInfo, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.projectstate.JobNameMap
 */
export const JobNameMap = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.projectstate.JobNameMap",
  () => [
    { no: 1, name: "names", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 2, name: "num_simulations", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "num_design_of_experiments", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "num_sensitivity_analysis", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * Records the state of check geometry.
 *
 * @generated from message luminary.proto.projectstate.CheckedUrls
 */
export const CheckedUrls = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.projectstate.CheckedUrls",
  () => [
    { no: 1, name: "urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(CheckGeometryStatus) },
  ],
);

/**
 * The name of the initial file the user uploaded. It could be a CAD, lcmesh, or
 * other file.
 *
 * @generated from message luminary.proto.projectstate.InputFilename
 */
export const InputFilename = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.projectstate.InputFilename",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * A message only used for the testing-specific testonly kvstore key.
 *
 * @generated from message luminary.proto.projectstate.TestOnly
 */
export const TestOnly = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.projectstate.TestOnly",
  () => [
    { no: 1, name: "test", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * LCVis settings like colorsOn, showEdges. The payload is encoded as JSON.
 * Store 'unimportant' settings here which may be reset if the schema changes.
 *
 * @generated from message luminary.proto.projectstate.LCVisSettings
 */
export const LCVisSettings = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.projectstate.LCVisSettings",
  () => [
    { no: 1, name: "json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * The geometry id being active in the setup tab.
 *
 * @generated from message luminary.proto.projectstate.SelectedGeometry
 */
export const SelectedGeometry = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.projectstate.SelectedGeometry",
  () => [
    { no: 1, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "geometry_version_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

