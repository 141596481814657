// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement } from 'react';

import { BarRounded, Line } from '@visx/shape';
import cx from 'classnames';

import ColorMaps from '../../assets/color_maps.json';
import { colors } from '../../lib/designSystem';
import { formatNumber } from '../../lib/number';
import * as random from '../../lib/random';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    tickLabels: {
      fontSize: '12px',
      fontWeight: 500,
      width: 'fit-content',
      color: colors.lowEmphasisText,
      position: 'absolute',
      transition: 'color 250ms',
      whiteSpace: 'nowrap',
      '&.highlighted': {
        color: colors.highEmphasisText,
      },
    },
  }),
  { name: 'ColorBar' },
);

function createGradient(name: string, bins: number): ReactElement[] {
  // Set the maximum number of bins to 128. Input will be between 1 to 256.
  // We can expect 128 colors to select from the ColorMaps object.
  const colorVals = ColorMaps[name];
  bins = Math.min(bins, colorVals.length);
  const binSize = colorVals.length / bins;

  // The subset of colors array of size bins used for the colorbar.
  const colorSet: string[] = [];

  if (bins === 1) {
    // Select the middle color in the colormap to match Paraview.
    colorSet.push(colorVals[Math.floor(colorVals.length / 2)]);
  } else {
    // If the number of bins is greater than 1, include first and last color.
    const offset = (colorVals.length - 1) / (bins - 1);
    for (let i = 0; i < bins; i += 1) {
      colorSet.push(colorVals[Math.floor(offset * i)]);
    }
  }

  const discretePoints = colorVals.map((_: string, i: number) => {
    const id = Math.floor(i / binSize);
    const percent = 100 * (i / (colorVals.length - 1));
    const style = { stopColor: colorSet[id] } as React.CSSProperties;
    return (
      <stop
        key={`${percent}`}
        offset={`${percent}%`}
        style={style}
      />
    );
  });

  // This is old behavior to select colormap without any bin number specified
  //  const points = colors.map((color: string, index: number) => {
  //   const percent = 100 * (index / (colors.length - 1));
  //   const style = { stopColor: color } as React.CSSProperties;
  //   return (
  //     <stop
  //       key={`${percent}`}
  //       offset={`${percent}%`}
  //       style={style}
  //     />
  //   );
  // });
  // This is the old range behavior when the current range is
  // inside the global range, i.e., add grey at the ends of the
  // gradient. This was sort of misleading, since paraview didn't
  // do this too. I checked with Tracy and she is ok with the new
  // behavior.
  // <stop offset="-1e10%" style={{ stopColor: '#acacb0' }} />
  // <stop offset="-0.01%" style={{ stopColor: '#acacb0' }} />
  // <stop offset="100.01%" style={{ stopColor: '#acacb0' }} />
  // <stop offset="1e10%" style={{ stopColor: '#acacb0' }} />
  return discretePoints;
}

// Create a set of tick marks. The bar is describe in terms of the coordinates
// u and v where u is oriented along the long direction of the bar. v along the
// short direction.
function createTicks(
  isVertical: boolean,
  numTicks: number,
  uMin: number,
  uMax: number,
): ReactElement[] {
  const ticks: ReactElement[] = [];
  // v1 and v2 is the start and end of the tick line segment.
  const v1 = isVertical ? 4 : 8;
  const v2 = isVertical ? 10 : 14;
  for (let i = 0; i < numTicks; i += 1) {
    const uVal = uMin + (i / (numTicks - 1)) * (uMax - uMin);
    ticks.push(
      <Line
        key={(`tick ${i}`)}
        stroke={colors.lowEmphasisText}
        strokeWidth="1"
        x1={isVertical ? v1 : uVal}
        x2={isVertical ? v2 : uVal}
        y1={!isVertical ? v1 : uVal}
        y2={!isVertical ? v2 : uVal}
      />,
    );
  }
  return ticks;
}

interface ColorBarProps {
  // Name of the color map.
  colorMapName: string;
  // True if the bar is orientation vertically. Fasle if horizontal.
  isVertical: boolean;
  // The length of the bar. This is the width or height depending on the
  // orientation.
  length: number;
  // The number of ticks.
  numTicks: number;
  // The minumum value for the bar.
  minValue: number;
  // The maximum value for the bar.
  maxValue: number;
  // If the text is highlighted.
  highlighted: boolean;
  // The values at which to bound the colored region. If empty, the colored
  // region extends through the full length of the bar.
  colorBounds: number[];
  // The number of bins to discretize the colormap.
  bins: number;
}

// A color bar with labeled tick marked oriented vertical or horizontally.
const ColorBar = (props: ColorBarProps) => {
  const classes = useStyles();

  // The bar can be oriented either vertically or horizontally. The bar is
  // describe in terms of the coordinates u and v where u is oriented along
  // the full length of the bar. v along its width.
  const uMin = 0;
  const uMax = props.length;
  const vCenter = 14;
  const vMax = 22;

  // Where a value appears as a percentage of the full length of the bar.
  const barPercent = (value: number) => {
    const parameter = ((value - props.minValue) / (props.maxValue - props.minValue));
    return (parameter * (uMax - uMin) + uMin) * (100 / props.length);
  };

  let minPercent = '0%';
  let maxPercent = '100%';
  if (props.colorBounds.length === 2) {
    minPercent = `${barPercent(props.colorBounds[0])}%`;
    maxPercent = `${barPercent(props.colorBounds[1])}%`;
  }

  const ticks = createTicks(props.isVertical, props.numTicks, uMin, uMax);
  const tickLabels: ReactElement[] = [];
  // For a horizontal bar, only show every other label.
  const labelStep = props.isVertical ? 1 : 2;
  for (let i = 0; i < props.numTicks; i += labelStep) {
    const tVal = i / (props.numTicks - 1);
    const uVal = uMin + tVal * (uMax - uMin) - 8;
    const value = props.maxValue - tVal * (props.maxValue - props.minValue);
    const xTranslate = 20 + tVal * (80 - 20);
    tickLabels.push(
      <span
        className={cx(classes.tickLabels, { highlighted: props.highlighted })}
        key={(`tick - label - ${i} `)}
        style={{
          right: !props.isVertical ? `${(uVal + 38)}px` : '0',
          top: props.isVertical ? `${uVal}px` : '',
          transform: !props.isVertical ? `translate(${xTranslate}%,-8px)` : '',
        }}>
        {Math.abs(value) > 1e6 ? value.toExponential(3) : formatNumber(value)}
      </span>,
    );
  }

  const gradientId = random.string(8);
  const gradient = createGradient(props.colorMapName, props.bins);
  const bar = (
    <>
      <span style={props.isVertical ? { position: 'absolute' } : {}}>
        {tickLabels}
      </span>
      <svg
        height={props.isVertical ? props.length : vMax}
        style={
          props.isVertical ?
            { position: 'relative' } :
            { float: 'left' }
        }
        width={!props.isVertical ? props.length : vMax}>
        <defs>
          <linearGradient
            id={gradientId}
            x1={props.isVertical ? '0%' : minPercent}
            x2={props.isVertical ? '0%' : maxPercent}
            y1={props.isVertical ? maxPercent : '0%'}
            y2={props.isVertical ? minPercent : '0%'}>
            {gradient}
          </linearGradient>
        </defs>
        {ticks}
        <BarRounded
          all
          fill={`url(#${gradientId})`}
          height={props.isVertical ? props.length : 8}
          radius={4}
          width={!props.isVertical ? props.length : 8}
          x={props.isVertical ? vCenter : 0}
          y={!props.isVertical ? vCenter : 0}
        />
      </svg>
    </>
  );
  return bar;
};

export default ColorBar;
