// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 42;
const baseHeight = 45;
export const CubePlusIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="cubePlus"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="m 36.415351,5.7410535 h 2.799864 c 1.546451,0 2.800156,1.28211 2.800156,2.8636497
           0,1.5815408 -1.253705,2.8636208 -2.800156,2.8636208 h -2.799864 v 2.86365 c 0,1.58154
           -1.253705,2.86362 -2.800156,2.86362 -1.546158,0 -2.799863,-1.28208 -2.799863,-2.86362 v
           -2.86365 h -2.799863 c -1.546452,0 -2.800127,-1.28208 -2.800127,-2.8636208 0,-1.5815397
           1.253675,-2.8636497 2.800127,-2.8636497 h 2.799863 v -2.86362 c 0,-1.581555
           1.253705,-2.86365 2.799863,-2.86365 1.546451,0 2.800156,1.282095 2.800156,2.86365 z m
           -13.619097,-3.86832 c -1.93808,1.57371 -3.180932,4.00437 -3.180932,6.7319097 0,3.8766908
           2.510725,7.1536508 5.959881,8.2228208 0.0494,0.16668 0.103605,0.33123 0.162477,0.49344 L
           19.615322,21.234344 2.2728615,10.148954 18.226127,0.82559549 c 0.860814,-0.50306999
           1.917576,-0.50306999 2.778391,0 z M 39.215215,20.735414 v 11.98017 c 0,1.0278
           -0.538266,1.9764 -1.410639,2.4864 l -16.789234,9.8118 v -21.29727 l 6.123061,-3.91386 c
           1.540585,1.90584 3.870235,3.12027 6.476792,3.12027 2.151597,0 4.113995,-0.82716
           5.60002,-2.18751 z M 0.17274412,12.183554 18.215361,23.716484 v 21.2973 l
           -16.7891758,-9.8118 c -0.87240158,-0.51 -1.41081428,-1.4586 -1.41081428,-2.4864 v
           -19.58541 c 0,-0.32742 0.054648,-0.64686 0.1573732,-0.94662 z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
