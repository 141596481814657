// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atom as atomRecoil } from 'recoil';

const def: Record<string, string | undefined> = {};

// Atom to store routing parameters.
export const routeParamsState = atom(def);

export const routeParamsAtom_DEPRECATED = atomRecoil({
  key: 'routeParamsAtom',
  default: def,
});

export function useRouteParamsValue() {
  return useAtomValue(routeParamsState);
}

export function useSetRouteParams() {
  return useSetAtom(routeParamsState);
}
