// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, RefObject, useRef } from 'react';

import { getTitleForFeature } from '../../lib/geometryUtils';
import { NodeType, SimulationTreeNode } from '../../lib/simulationTree/node';
import { OVERLAY_CARD_WIDTH } from '../../lib/visUtils';
import { useGeometryState } from '../../recoil/geometry/geometryState';
import { useFloatingPanelTopRight } from '../../recoil/lcvis/infoCardDims';
import { useDraggable } from '../Paraview/draggable';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { Dialog } from '../dialog/Base';

const GEOMETRY_DRAG_ID = 'geometry-mod-panel';
const GEOMETRY_NODE_TYPES = [
  NodeType.GEOMETRY_MODIFICATION,
];

interface GeometryModificationDraggablePanelProps {
  node: SimulationTreeNode | null;
  overlayRef: RefObject<HTMLDivElement>;
  children: ReactNode;
}

const GeometryModificationDraggablePanel = (props: GeometryModificationDraggablePanelProps) => {
  const { children, overlayRef, node } = props;
  const { projectId, geometryId } = useProjectContext();

  const { setSelection } = useSelectionContext();

  const { initDrag, draggedPositions, dragPositionToStyle, currentDragId } = useDraggable();
  const dialogNode = useRef<HTMLDivElement>(null);
  const isDragging = currentDragId === GEOMETRY_DRAG_ID;
  const geometryState = useGeometryState(projectId, geometryId);
  const mod = geometryState?.geometryFeatures.find((item) => item.id === node?.id);

  const topRight = useFloatingPanelTopRight(dialogNode);

  const onMouseDown = (event: any) => {
    if (overlayRef.current && dialogNode.current) {
      initDrag(
        GEOMETRY_DRAG_ID,
        event,
        dialogNode.current,
        overlayRef.current,
      );
    }
  };
  return (
    <div
      ref={dialogNode}
      style={{
        position: 'absolute',
        zIndex: 1000,
        transition: 'top 0.2s',
        ...topRight,
        ...dragPositionToStyle(draggedPositions[GEOMETRY_DRAG_ID]),
      }}>
      <Dialog
        compact
        draggable={!!onMouseDown}
        dragging={isDragging}
        onClose={() => {
          // IGEO TODO: Delete the in-progress modification
          setSelection([]);
        }}
        onFrameMousedown={onMouseDown}
        open={!!node && GEOMETRY_NODE_TYPES.includes(node.type)}
        title={getTitleForFeature(mod) || ''}
        width={OVERLAY_CARD_WIDTH}>
        {children}
      </Dialog>
    </div>
  );
};

export default GeometryModificationDraggablePanel;
