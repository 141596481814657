// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const GlobeIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="globe"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M6.675,11.745
           C6.285,11.745 6.06,12.06 6.06,12.435
           L6.06,15.06
           C6.06,15.45 6.3,15.75 6.675,15.75
           L7.11,15.75
           L7.11,18.405
           C7.11,18.78 7.47,19.08 7.845,19.08
           C8.22,19.08 8.58,18.78 8.58,18.405
           L8.58,16.8
           L8.565,16.8
           C8.955,16.8 9.225,16.59 9.225,16.2
           L9.225,15.75
           L9.435,15.75
           C9.825,15.75 10.08,15.45 10.08,15.06
           L10.08,14.175
           C10.08,14.175 10.095,14.16 10.08,14.16
           L7.71,11.76
           L6.675,11.76
           L6.675,11.745Z
           M12.765,5.85
           L13.095,5.85
           C13.185,5.85 13.275,5.835 13.275,5.745
           C13.275,5.655 13.2,5.64 13.095,5.64
           L12.765,5.64
           C12.675,5.64 12.585,5.655 12.585,5.745
           C12.6,5.835 12.675,5.85 12.765,5.85Z
           M9.27,5.775
           C9.375,5.67 9.375,5.49 9.27,5.385
           L8.52,4.635
           C8.415,4.53 8.235,4.53 8.13,4.635
           C8.025,4.74 8.025,4.92 8.13,5.025
           L8.88,5.775
           C8.985,5.88 9.165,5.88 9.27,5.775Z
           M13.86,7.11
           C13.965,7.11 14.07,7.02 14.07,6.93
           L14.07,6.465
           C14.07,6.36 13.965,6.285 13.86,6.285
           C13.755,6.285 13.65,6.375 13.65,6.465
           L13.65,6.93
           C13.65,7.035 13.755,7.11 13.86,7.11Z
           M12,0
           C5.37,0 0,5.37 0,12
           C0,18.63 5.37,24 12,24
           C18.63,24 24,18.63 24,12
           C24,5.37 18.63,0 12,0Z
           M12,21
           C7.035,21 3,16.965 3,12
           C3,11.175 3.15,10.395 3.345,9.645
           L3.51,9.645
           L3.51,10.35
           C3.51,10.65 3.78,10.905 4.095,10.905
           C4.14,10.905 4.215,10.89 4.26,10.875
           L5.43,12.03
           C5.55,12.15 5.73,12.15 5.85,12.03
           C5.97,11.91 5.97,11.73 5.85,11.61
           L4.725,10.44
           C4.725,10.41 4.785,10.38 4.785,10.35
           L4.785,10.17
           C4.785,9.93 4.92,9.84 5.16,9.63
           L5.85,9.63
           C5.985,9.63 6.105,9.615 6.21,9.555
           L6.24,9.555
           C6.27,9.54 6.285,9.525 6.315,9.51
           C6.33,9.495 6.33,9.495 6.345,9.495
           L6.375,9.465
           L8.76,7.095
           C9.03,6.825 9.03,6.405 8.76,6.135
           C8.49,5.865 8.055,5.91 7.785,6.18
           L7.335,6.69
           L6.48,6.69
           L6.48,5.97
           C6.48,5.955 6.555,6.045 6.555,5.835
           L7.515,5.835
           C7.695,5.835 7.845,5.7 7.845,5.52
           C7.845,5.34 7.695,5.205 7.515,5.205
           L6.15,5.205
           C6.42,4.98 6.66,4.74 6.96,4.545
           L6.975,4.53
           C7.29,4.32 7.65,4.155 7.995,3.975
           C8.22,3.87 8.43,3.75 8.655,3.66
           C8.91,3.555 9.18,3.495 9.45,3.405
           C9.72,3.33 9.975,3.225 10.245,3.165
           C10.815,3.06 11.4,3 12,3
           C13.23,3 14.385,3.255 15.45,3.69
           C15.525,3.72 15.6,3.765 15.69,3.81
           C16.065,3.975 16.41,4.17 16.755,4.395
           C17.13,4.635 17.49,4.905 17.82,5.22
           L15.9,5.22
           C15.9,5.22 15.9,5.175 15.885,5.175
           C15.825,5.175 15.75,5.175 15.69,5.22
           L14.925,5.985
           C14.835,6.075 14.835,6.24 14.925,6.33
           C15.015,6.42 15.18,6.42 15.27,6.33
           L15.9,5.67
           L15.915,5.64
           L16.29,5.64
           C16.29,5.85 16.185,5.775 16.185,5.82
           L16.185,5.925
           C16.185,6.255 15.96,6.48 15.645,6.48
           L15.075,6.48
           C14.79,6.48 14.505,6.795 14.505,7.08
           L14.505,7.335
           L14.355,7.335
           C14.175,7.335 14.055,7.425 14.055,7.605
           L14.055,7.98
           L13.71,7.98
           C13.455,7.98 13.26,8.145 13.26,8.4
           C13.26,8.655 13.455,8.79 13.71,8.79
           C13.815,8.79 13.92,8.835 13.995,8.625
           L14.055,8.64
           L14.79,7.95
           L15.045,7.95
           L15.63,8.505
           C15.675,8.55 15.75,8.535 15.81,8.49
           C15.855,8.445 15.855,8.31 15.81,8.265
           L15.33,7.74
           L15.675,7.74
           L15.81,7.92
           C16.08,8.19 16.53,8.175 16.8,7.905
           L16.935,7.755
           L17.535,7.755
           C17.565,7.755 17.655,7.83 17.655,7.83
           L17.655,8.19
           L17.58,8.175
           L17.04,8.175
           C16.875,8.175 16.725,8.325 16.725,8.49
           C16.725,8.655 16.86,8.805 17.04,8.805
           L17.655,8.805
           L17.655,9.03
           C17.445,9.345 17.295,9.66 16.98,9.66
           L15.57,9.66
           L15.57,9.645
           L14.91,8.985
           C14.64,8.715 14.19,8.715 13.92,8.985
           L13.29,9.63
           L13.29,9.645
           L12.9,9.645
           C12.51,9.645 12.165,9.96 12.165,10.335
           L12.165,11.715
           C12.165,12.105 12.51,12.39 12.9,12.39
           L14.25,12.39
           C14.76,12.6 15.105,12.915 15.33,13.425
           L15.33,15.945
           C15.33,16.335 15.585,16.605 15.96,16.605
           L17.04,16.605
           C17.43,16.605 17.64,16.335 17.64,15.945
           L17.64,13.5
           L18.975,12.21
           L19.02,12.18
           L19.05,12.165
           L19.095,12.165
           C19.2,12.045 19.32,11.88 19.32,11.7
           L19.32,10.335
           C19.32,10.065 19.08,9.855 18.855,9.645
           L19.5,9.645
           C19.515,10.065 19.815,10.275 20.19,10.275
           L20.82,10.275
           C20.94,10.83 21,11.415 21,12
           C21,16.965 16.965,21 12,21Z
           M18.81,14.595
           C18.66,14.595 18.495,14.715 18.495,14.865
           L18.495,15.72
           C18.495,15.87 18.66,15.99 18.81,15.99
           C18.96,15.99 19.125,15.87 19.125,15.72
           L19.125,14.865
           C19.125,14.715 18.96,14.595 18.81,14.595Z
           M12.555,4.785
           C12.555,4.41 12.255,4.155 11.865,4.155
           L11.055,4.155
           C10.68,4.155 10.425,4.425 10.425,4.8
           C10.425,4.845 10.275,4.86 10.5,4.92
           L10.5,5.625
           C10.5,5.85 10.59,6.03 10.815,6.03
           C11.04,6.03 11.13,5.85 11.13,5.625
           L11.13,5.415
           L11.88,5.415
           C12.24,5.415 12.555,5.175 12.555,4.785Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
