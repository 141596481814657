// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 14;
const baseHeight = 16;
export const DocumentClippedIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="documentClipped"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M7.09,4.828
           L7.09,0
           L13.585,0
           C13.814,0 13.998,0.184 14,0.413
           L14,15.587
           C14,15.816 13.816,16 13.587,16
           L0.413,16
           C0.184,16 0,15.816 0,15.587
           L0,0.413
           C0,0.184 0.184,0 0.411,0
           L3.3,0
           L3.3,4.828
           C3.3,5.639 3.709,6.723 5.195,6.723
           C6.62,6.723 7.09,5.639 7.09,4.828Z
           M4.149,0
           L6.245,0
           L6.245,4.828
           C6.245,5.406 5.775,5.876 5.197,5.876
           C4.619,5.876 4.149,5.406 4.149,4.828
           L4.149,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
