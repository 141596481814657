// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
/**
 * Utils for managing simulation annotations, i.e. annotations that come from the simulation
 * param, like monitor points, planes, or actuator disks.
 */

import { deepEqual } from 'fast-equals';

import * as ParaviewRpc from '../pvproto/ParaviewRpc';

import { ProbePoint } from './particleGroupUtils';

export type SimAnnotationParam = ParaviewRpc.ActuatorDiskParam | ParaviewRpc.MonitorPointParam

export type ModificationList ={
  removed: string[],
  added: SimAnnotationParam[],
  edited: SimAnnotationParam[],
}

/** Get the id of a simulation annotation param */
export function getAnnotationId(param: SimAnnotationParam) {
  switch (param.typ) {
    case 'ActuatorDisk':
      return param.particleGroupId;
    case 'MonitorPoint':
      return param.id;
    default:
      throw Error('invalid param');
  }
}

/**
 * Given a list of simAnnotationParams and a map of {id: simAnnotationParam},
 * return the differences between the 2 datasets.
 */
export function findAnnotationModifications(
  newAnnotations: SimAnnotationParam[],
  prevAnnotations: Map<string, SimAnnotationParam>,
): ModificationList {
  const modifications: ModificationList = { removed: [], added: [], edited: [] };
  const newIds = new Set<string>();

  // check if any entities were edited or added
  newAnnotations.forEach((simAnnotation) => {
    const id = getAnnotationId(simAnnotation);
    if (prevAnnotations.has(id)) {
      if (!deepEqual(prevAnnotations.get(id)!, simAnnotation)) {
        modifications.edited.push(simAnnotation);
      }
    } else {
      modifications.added.push(simAnnotation);
    }
    newIds.add(id);
  });

  // check if any entities were deleted
  prevAnnotations.forEach((simAnnotation) => {
    const id = getAnnotationId(simAnnotation);
    if (!newIds.has(id)) {
      modifications.removed.push(id);
    }
  });

  return modifications;
}

/** Given a ProbePoint, convert it to a MonitorPointParam. */
export function simAnnotationMonitorPoint(
  point: ProbePoint,
): ParaviewRpc.MonitorPointParam {
  // In LCVis we use a relative radius so this field is unused.
  const radius = 0;
  return {
    typ: ParaviewRpc.TreeNodeType.MONITOR_POINT,
    point: {
      typ: 'Sphere',
      center: {
        x: point.x,
        y: point.y,
        z: point.z,
      },
      radius,
    },
    id: point.id,
  };
}
