// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: { position: 'absolute', top: 0, right: 0, height: '100%' },
  }),
  { name: 'OverflowGradient' },
);

export interface OverflowGradientProps {
  gradientColor?: string;
  width?: number;
}

/**
 * This component renders a rectangle with a gradient background that transitions
 * smoothly from transparency to a solid color. It's typically used as an alternative
 * to the text-ellipsis effect when truncating text within a container.
 * Instead of abruptly cutting off the content, it provides a smooth fading effect.
 *
 * A practical use case for this component can be found in the `<Tab>` component,
 * where it is used to gracefully indicate that the text overflows.
 */
export const OverflowGradient = (props: OverflowGradientProps) => {
  const { gradientColor = 'var(--color-neutral-150)', width = 8 } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        background: `linear-gradient(90deg, transparent 0%, ${gradientColor} 80%)`,
        width: `${width}px`,
      }}
    />
  );
};
