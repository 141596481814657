// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useRef } from 'react';

import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getSessionStorageData } from '../../lib/browserStorage';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { sessionStorageEffect } from '../../lib/persist';

const CLIP_HIDE_KEY = 'lcvis-clip-hide';

const updateClipHide = (clipHide: boolean) => {
  lcvHandler.queueDisplayFunction('toggle clip hide', (display) => {
    const { filterHandler } = display;
    if (clipHide === false) {
      filterHandler?.setClipTransparent();
    } else {
      filterHandler?.setClipHide();
    }
  });
};

/** Whether to hide clipped surfaces in LCVis, or show them as transparent. */
const clipHideEnabledState = atom<boolean>({
  key: 'clipHideEnabled',
  default: getSessionStorageData(CLIP_HIDE_KEY, false),
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newVal) => {
        setSelf(newVal);
        updateClipHide(newVal);
      });
    },
    sessionStorageEffect(CLIP_HIDE_KEY),
  ],
});

export const useLcVisFilterClipHideEnabled = () => useRecoilState(clipHideEnabledState);
export const useLcVisFilterClipHideEnabledValue = () => useRecoilValue(clipHideEnabledState);
export const useSetLcVisFilterClipHideEnabled = () => useSetRecoilState(clipHideEnabledState);

/**
 * Update the clip hide setting in LCVis on mount.
 */
export const useUpdateClipHide = () => {
  const clipHide = useLcVisFilterClipHideEnabledValue();
  const ref = useRef<boolean>(clipHide);

  useEffect(() => {
    updateClipHide(ref.current);
  }, []);
};
