// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import * as jwt from './jwt';

// Auth0 authenticator status.
//
// AUTHENTICATED means that the browser assumes that it has a valid
// authentication token. This is the initial state.
// The browser may issue RPCs to the backend.  If an RPC
// fails with an authentication error, the authstate transitions to
// UNAUTHENTICATED.
//
// UNAUTHENTICATED means that auth0 has been initialized, and the browser
// doesn't have a valid authentication token.  When UNAUTHENTICATED, the browser
// must start a login process. After a successful login, the authstate
// transitions to AUTHENTICATED.
export enum AuthState {
  AUTHENTICATED = 0,
  UNAUTHENTICATED = 1,
  USER_NOT_FOUND = 2,
  // new user needs to accept terms of service for the first time
  AUTHENTICATION_PENDING_REGISTRATION = 3,
  AUTHENTICATION_PENDING = 4,
  // exisiting user needs to re-accept update terms of service
  AUTHENTICATION_PENDING_REACCEPT_TERMS = 5,

  AUTHENTICATION_EXPIRED = 6,
}

export interface AuthInfo {
  authState: AuthState,
  jwt: jwt.Jwt | null,
}

export type AuthInfoCallback = (arg0: AuthInfo) => void;
