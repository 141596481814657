// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { selectorFamily } from 'recoil';

import { MATERIAL_CONTAINER_NODE_ID, NodeType, SimulationTreeNode } from '../../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../../lib/simulationTree/sectionRecoilKey';
import { simulationMaterialsState } from '../../../external/project/simulation/param/materials';

export const materialsSectionSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'materialsSection',
  get: (key: SectionRecoilKey) => ({ get }) => {
    const materialData = get(simulationMaterialsState(key));

    // Construct the root frame
    const children = materialData.reduce((result, { id, name, model }) => {
      switch (model.material.case) {
        case 'materialFluid': {
          result.push(new SimulationTreeNode(NodeType.MATERIAL_FLUID, id, name));
          break;
        }
        case 'materialSolid': {
          result.push(new SimulationTreeNode(NodeType.MATERIAL_SOLID, id, name));
          break;
        }
        default: {
          // no default, enum exhausted
        }
      }
      return result;
    }, [] as SimulationTreeNode[]);

    return new SimulationTreeNode(
      NodeType.MATERIAL_CONTAINER,
      MATERIAL_CONTAINER_NODE_ID,
      'Materials',
      children,
    );
  },
  dangerouslyAllowMutability: true,
});
