// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 16;
export const DoubleUniqueFramesIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="doubleUniqueFrames"
      height={finalHeight}
      ref={ref}
      style={{
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M4.29,0L12,4.342L12,14.545L4.29,10.22L4.29,0ZM5.667,2.357L5.667,9.413L10.622,
           12.193L10.622,5.148L5.667,2.357Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M0,1.455L7.71,5.797L7.71,16L0,11.674L0,1.455Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
