// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { FaultType } from '../../lib/componentTypes/form';
import { colors } from '../../lib/designSystem';
import { meshParamsUrl } from '../../lib/mesh';
import * as meshgenerationpb from '../../proto/meshgeneration/meshgeneration_pb';
import useMeshGeneration, { useSetMeshGeneration } from '../../recoil/useMeshGeneration';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useIsStaff } from '../../state/external/user/frontendRole';
import Form from '../Form';
import FormGroup from '../Form/FormGroup';
import { TextInput } from '../Form/TextInput';
import { AutoCollapsiblePanel } from '../Panel/AutoCollapsiblePanel';
import { useProjectContext } from '../context/ProjectContext';

// A panel for editing the mesh parameters.
export const AdvancedMeshingPanel = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const meshGeneration = useMeshGeneration(projectId, meshParamsUrl(simParam, readOnly));
  const setMeshGeneration = useSetMeshGeneration(projectId);
  // only show polyhedral dual to staff, regardless of advanced meshing parameters flag
  const polyhedralDual = useIsStaff();

  // == State
  const [faultType, setFaultType] = useState<FaultType | undefined>();

  if (!meshGeneration) {
    return null;
  }

  // Apply some change to the mesh generation parameters.
  const applyChange = (diff: Partial<meshgenerationpb.UserMeshingParams>) => {
    meshGeneration && setMeshGeneration((oldValue) => new meshgenerationpb.UserMeshingParams({
      ...oldValue,
      ...diff,
    }));
  };

  const handleAdvancedMeshingInputChange = (value: string) => {
    applyChange({ advancedMeshingParameters: value || '' });
    setFaultType(undefined);
    if (value) {
      try {
        JSON.parse(value);
      } catch (error) {
        setFaultType('error');
      }
    }
  };

  return (
    <AutoCollapsiblePanel
      heading="Advanced Meshing Parameters"
      headingIcon={{
        name: 'warning',
        color: colors.yellow500,
        tooltip: 'Internal Use Only',
      }}
      initialCollapsed>
      <FormGroup>
        {polyhedralDual && (
          <Form.LabeledInput
            help="Dualize the final mesh of tetrahedra, prisms & pyramids to polyhedra."
            label="Use polyhedral dual">
            <Form.CheckBox
              checked={meshGeneration.dualize}
              disabled={readOnly}
              onChange={(newValue: boolean) => {
                applyChange({ dualize: newValue });
              }}
            />
          </Form.LabeledInput>
        )}
        <TextInput
          asBlock
          dataPrivate
          disabled={readOnly}
          faultType={faultType}
          multiline
          name="advanced meshing parameters"
          onChange={handleAdvancedMeshingInputChange}
          placeholder="Advanced mesh parameters JSON"
          rows={8}
          value={meshGeneration.advancedMeshingParameters}
        />
      </FormGroup>
    </AutoCollapsiblePanel>
  );
};
