// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const showRowChildrenCountKey = 'showRowChildrenCount';

/** Whether to show the number of children of each surface group in the geometry tree. */
const showRowChildrenCountState = atomWithStorage<boolean>(showRowChildrenCountKey, false);

export const useShowRowChildrenCountValue = () => useAtomValue(showRowChildrenCountState);

export const useShowRowChildrenCountState = () => useAtom(showRowChildrenCountState);

export const useSetShowRowChildrenCount = () => useSetAtom(showRowChildrenCountState);
