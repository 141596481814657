// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 12;
export const XIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="x"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M5.05956 6.00001
           L0.0297852 0.970237
           L0.970237 0.0297852
           L6.00001 5.05956
           L11.0298 0.0297852
           L11.9702 0.970237
           L6.94046 6.00001
           L11.9702 11.0298
           L11.0298 11.9702
           L6.00001 6.94046
           L0.970237 11.9702
           L0.0297852 11.0298
           L5.05956 6.00001Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
