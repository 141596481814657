// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue } from 'recoil';

import { RecoilProjectKey } from '../lib/persist';
import { MeshingMethod } from '../proto/client/simulation_pb';
import { simulationParamState } from '../state/external/project/simulation/param';

/**
 * This selector determines if the mesh section should be read only. Currently only read only if a
 * mesh is being generated or if a generated mesh is selected.
 */
export const isLMAState = selectorFamily<boolean, RecoilProjectKey>({
  key: 'meshReadOnlyState',
  get: (key: RecoilProjectKey) => async ({ get }) => {
    const param = get(simulationParamState(key));

    const meshMethod = param?.adaptiveMeshRefinement?.meshingMethod;
    return meshMethod === MeshingMethod.MESH_METHOD_AUTO;
  },
});

export const useIsLMA = (key: RecoilProjectKey) => useRecoilValue(isLMAState(key));
