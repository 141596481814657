// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { listen } from '../../event';
import { spanProcessor } from '../tracing';

/**
 * A hook that listens for changes in the path or visibility of the page. When the path changes, or
 * the visibility of the page changes, it will call endActiveSpan and then maybeStartSpan.
 *
 * @param endActiveSpan a function that ends the active span. This will be called when the path
 * changes or when the page visibility changes to hidden. This should be idempotent since it may be
 * called multiple times.
 * @param maybeStartSpan a function that starts a new span. This will be called when the path
 * changes after a call to endActiveSpan, or when the page visibility changes to visible.
 */
export const useOnPathOrVisibiltyChange = (
  endActiveSpan: () => void,
  maybeStartSpan: (path: string) => void,
) => {
  const location = useLocation();
  const { pathname } = location;

  // This will be called after the path changes, after onPathExit is called.
  const onPathEnter = useCallback(() => {
    endActiveSpan();
    maybeStartSpan(pathname);
  }, [endActiveSpan, maybeStartSpan, pathname]);

  // This will be called when the path changes
  const onPathExit = useCallback(() => {
    endActiveSpan();
    spanProcessor.forceFlush().catch((error) => {
      throw error;
    });
  }, [endActiveSpan]);

  // This will be called when the page visibility changes
  const onVisibilityChange = useCallback((visibilityState: DocumentVisibilityState) => {
    if (visibilityState === 'hidden') {
      endActiveSpan();
      // Force flush the span processor to ensure the span is sent to the collector. This is
      // needed because we use a batch span processor which may not immediately send the span (but
      // we need it to, e.g. when the page is closed).
      spanProcessor.forceFlush().catch((error) => {
        throw error;
      });
    } else if (visibilityState === 'visible') {
      endActiveSpan();
      maybeStartSpan(pathname);
    }
  }, [endActiveSpan, maybeStartSpan, pathname]);

  useEffect(() => {
    onPathEnter();

    const { remove } = listen(document, 'visibilitychange', () => {
      onVisibilityChange(document.visibilityState);
    });

    return () => {
      remove();
      onPathExit();
    };
  }, [pathname, onPathEnter, onPathExit, onVisibilityChange]);
};
