// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ActionButton } from '../Button/ActionButton';
import { useSelectionContext } from '../context/SelectionManager';

interface NodeIdsSelectButtonProps {
  nodeIds: string[];
  disabled?: boolean;
  help?: string;
}

export const NodeIdsSelectButton = (props: NodeIdsSelectButtonProps) => {
  const { disabled, help, nodeIds } = props;

  const { setScrollTo, setSelection } = useSelectionContext();

  return (
    <>
      {!!nodeIds.length && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ActionButton
            compact
            disabled={disabled}
            kind="minimal"
            onClick={() => {
              setSelection(nodeIds);
              setScrollTo({ node: nodeIds[0] });
            }}
            title={help}>
            Select
          </ActionButton>
        </div>
      )}
    </>
  );
};
