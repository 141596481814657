// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtomValue, useSetAtom } from 'jotai';

/**
 * This state keeps the distance between the clicked tree row's top edge and the top edge
 * of the 3D viewer. This is used to show the property panel of a clicked tree row next to the row.
 */
const clickedTreeRowVisOffset = atom(0);

export const useSetClickedTreeRowVisOffset = () => useSetAtom(clickedTreeRowVisOffset);
export const useClickedTreeRowVisOffsetValue = () => useAtomValue(clickedTreeRowVisOffset);
