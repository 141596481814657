// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { colors } from '../lib/designSystem';
import { useLeftNavExpandedState } from '../state/internal/component/leftNav';

import { IconButton } from './Button/IconButton';
import { createStyles, makeStyles } from './Theme';
import Tooltip from './Tooltip';
import { ChevronRightIcon } from './svg/ChevronRightIcon';

export const TRIGGER_WIDTH = 16;
export const [TRANSITION_TIME, TRANSITION_DELAY] = [300, 50];

const useStyles = makeStyles(
  () => createStyles({
    root: {
      position: 'absolute',
      top: '75%',
      left: 'var(--nav-width)',
      transition: `left ${TRANSITION_TIME}ms, opacity ${TRANSITION_TIME}ms`,
      transitionDelay: `${TRANSITION_DELAY}ms`,
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
      opacity: 'var(--nav-toggler-opacity)',

      '&:hover': {
        backgroundColor: colors.neutral100,
      },
      '&:active': {
        backgroundColor: colors.neutral150,
      },
    },
    iconWrapper: {
      display: 'flex',

      '&.rotated': {
        transform: 'scaleX(-1)',
      },

      '& > svg': {
        minWidth: '5px',
      },
    },

  }),
  { name: 'LeftNavToggle' },
);

export const LeftNavToggle = () => {
  const classes = useStyles();
  const [leftNavExpanded, setLeftNavExpanded] = useLeftNavExpandedState();

  return (
    <Tooltip title={leftNavExpanded ? 'Collapse Panel' : 'Expand Panel'}>
      <IconButton
        className={classes.root}
        engaged
        onClick={() => {
          setLeftNavExpanded((value) => !value);
        }}>
        <div className={cx(classes.iconWrapper, leftNavExpanded && 'rotated')}>
          <ChevronRightIcon maxHeight={8} />
        </div>
      </IconButton>
    </Tooltip>
  );
};
