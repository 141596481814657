// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import { ARROW_DOWN_KEY, ARROW_LEFT_KEY } from '../../../lib/event';

export type RangeSliderSide = 'left' | 'right';

export interface ReadoutConfig {
  // Disable the readout
  disabled?: boolean;
  // By default, the number in the readout is formatted to 4 significant digits.
  // Use this function to override the formatting
  formatValue?: (value: number) => string;
  // Optional vertical offset of the readout
  offset?: number;
}

// Both SimpleSlider and RangeSlider use this common set of props,
// differentiated only by T, a generic representing the value's type.
export interface CommonSliderProps<T> {
  // The slider's current value
  value: T;
  // The slider's minimum value
  min: number;
  // The slider's maximum value
  max: number;
  // A function that's called for any value change (including as the user drags
  // a thumb)
  onChange: (value: T) => void;
  // A function that's called for any low-frequency value change, such as when
  // the user releases the mouse
  onCommit?: (value: T) => void;
  // Optionally disable the control
  disabled?: boolean;
  // Optionally override the gutter height
  gutterHeight?: number;
  // When thumbs have focus, their values may be incremented or decremented by
  // hitting the left or right arrow keys (on the keyboard).  The amount each
  // keystroke changes the value is equal to (max - min) / incrementSteps.
  incrementSteps?: number;
  // Instead of allowing continuous slider values, allow only discrete values
  // defined by (max - min) / stopCount.
  stopCount?: number;
  // Whether to show markers when stopCount is supplied
  showStops?: boolean;
  readoutConfig?: ReadoutConfig;
  // Whether thumbs should use the simple style (circles) or the range style (pointed sliders)
  simpleStyling?: boolean;
  // Radius of simple style slider circles
  thumbRadius?: number;
  // Vertical offset of thumbs (mainly for EditColorsBox)
  thumbOffset?: number;
}

// The default height of the slider's gutter
export const DEFAULT_GUTTER_HEIGHT = 6;
// The default radius of the thumb
export const DEFAULT_THUMB_RADIUS = 6;
// The default number of steps (for arrow key increment/decrement operations)
export const DEFAULT_INCREMENT_STEPS = 50;
// How long after a user interaction to keep the readout alive before deactivating
export const DEFAULT_READOUT_TIMEOUT = 3000;

export function isArrowDecrement(event: React.KeyboardEvent) {
  return [ARROW_DOWN_KEY, ARROW_LEFT_KEY].includes(event.key);
}
