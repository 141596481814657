// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const PointerOutlinedBoxDashedIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="pointerOutlinedBoxDashed"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M22.949,14.297L17.34,16.659C17.033,16.784 16.784,17.033 16.659,17.34L14.297,22.949
           L8.934,8.934L22.949,14.297Z"
        style={{
          fill: 'none',
          fillRule: 'nonzero',
          stroke: getColor(0),
          strokeWidth: '2.1px',
        }}
      />
      <path
        d="M22.073,8.058L22.073,1.051L1.051,1.051L1.051,22.073L8.934,22.073"
        style={{
          fill: 'none',
          fillRule: 'nonzero',
          stroke: getColor(0),
          strokeWidth: '2.1px',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeDasharray: '4.2,3.5',
        }}
      />
      <path
        d="M16.818,16.818L22.949,22.949"
        style={{
          fill: 'none',
          fillRule: 'nonzero',
          stroke: getColor(0),
          strokeWidth: '2.1px',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
        }}
      />
    </svg>
  );
});
