// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ParamGroupName, paramGroupDesc } from '../../../SimulationParamDescriptor';
import { useFluidPhysics } from '../../../model/hooks/useFluidPhysics';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { useSimulationParamScope } from '../../../state/external/project/simulation/paramScope';
import { DataSelect } from '../../Form/DataSelect';
import LabeledInput from '../../Form/LabeledInput';
import { ParamForm } from '../../ParamForm';
import { useProjectContext } from '../../context/ProjectContext';
import { useFluidPhysicsPresets } from '../../hooks/useFluidPhysicsPresets';
import { NavContentDialog, NavContentDialogItem } from '../NavContentDialog';

const paramDisc = paramGroupDesc[ParamGroupName.SpatialDiscretizationFluid];
const paramCtrl = paramGroupDesc[ParamGroupName.SolutionControlsFluid];

const { CUSTOM_SPATIAL_DISCRETIZATION_FLUID } = simulationpb.SpatialDiscretizationFluidPreset;
const { CUSTOM_SOLUTION_CONTROLS_FLUID } = simulationpb.SolutionControlsFluidPreset;

export interface AdvancedFluidPhysicsDialogProps {
  physicsId: string;
  onClose: () => void;
  open: boolean;
}

export const AdvancedFluidPhysicsDialog = (props: AdvancedFluidPhysicsDialogProps) => {
  // == Prop
  const { onClose, open, physicsId } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // == Model
  const {
    spatialDiscretization,
    spatialDiscretizationPreset,
    setSpatialDiscretization,
    setSpatialDiscretizationPreset,
    solutionControls,
    solutionControlsPreset,
    setSolutionControls,
    setSolutionControlsPreset,
    disablePresetsReason,
    getParamScope,
  } = useFluidPhysics(projectId, workflowId, jobId, readOnly, physicsId);
  const {
    spatialDiscOptions,
    solnControlOptions,
  } = useFluidPhysicsPresets(spatialDiscretizationPreset, solutionControlsPreset, true);

  // == Data
  const physicsScope = getParamScope(paramScope);

  if (!spatialDiscretization || !solutionControls) {
    return null;
  }

  const data: NavContentDialogItem[] = [
    {
      nav: 'Spatial Discretization',
      description: `Spatial Discretization controls solution accuracy when compared to the most
        realistic solution possible.  Higher accuracy may result in instability in the
        solution process.`,
      content: (
        <>
          <LabeledInput
            label="Preset">
            <DataSelect
              asBlock
              disabled={readOnly || !!disablePresetsReason}
              onChange={setSpatialDiscretizationPreset}
              options={spatialDiscOptions}
              size="small"
              tooltip={disablePresetsReason}
            />
          </LabeledInput>
          <ParamForm<simulationpb.SpatialDiscretizationFluid>
            group={paramDisc}
            onUpdate={setSpatialDiscretization}
            paramScope={physicsScope}
            proto={spatialDiscretization}
            readOnly={
              readOnly ||
              !!disablePresetsReason ||
              spatialDiscretizationPreset !== CUSTOM_SPATIAL_DISCRETIZATION_FLUID
            }
            removeParams={['SpatialDiscretizationFluidPreset']}
          />
        </>
      ),
    },
    {
      nav: 'Solution Controls',
      description: `Solution Controls controls convergence speed. The quicker the desired
        convergence, the more unstable the process may be.`,
      content: (
        <>
          <LabeledInput
            label="Preset">
            <DataSelect
              asBlock
              disabled={readOnly || !!disablePresetsReason}
              onChange={setSolutionControlsPreset}
              options={solnControlOptions}
              size="small"
              tooltip={disablePresetsReason}
            />
          </LabeledInput>
          <ParamForm<simulationpb.SolutionControlsFluid>
            group={paramCtrl}
            onUpdate={setSolutionControls}
            paramScope={physicsScope}
            proto={solutionControls}
            readOnly={
              readOnly ||
              !!disablePresetsReason ||
              solutionControlsPreset !== CUSTOM_SOLUTION_CONTROLS_FLUID
            }
            removeParams={['SolutionControlsFluidPreset']}
          />
        </>
      ),
    },
  ];

  return (
    <NavContentDialog
      collapsible
      data={data}
      onClose={onClose}
      onContinue={onClose}
      open={open}
      title="Advanced Solver Settings"
    />
  );
};
