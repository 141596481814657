// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import { IconButton } from '../Button/IconButton';
import { createStyles, makeStyles } from '../Theme';
import { CaretLeftIcon } from '../svg/CaretLeftIcon';
import { CaretRightIcon } from '../svg/CaretRightIcon';

const useStyles = makeStyles(
  () => createStyles({
    toggle: {
      '--icon-opacity': 0.5,
      '--background-color': colors.neutral150,
      '&:hover': {
        '--icon-opacity': 1,
        '--background-color': colors.surfaceLight2,
      },
      background: 'var(--background-color)',
      borderRadius: '4px 0 0 4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2,
      transition: 'opacity 500ms',
      '&.dimmed': {
        opacity: 0.25,
      },
    },
    button: {
      margin: 0,
      padding: 0,
      display: 'flex',
      width: '16px',
      height: '28px',
    },
    icon: {
      display: 'flex',
      color: colors.mediumEmphasisText,
      opacity: 'var(--icon-opacity)',
      transition: 'opacity 250ms',
      position: 'relative',
      left: '1px',
    },
  }),
  { name: 'PaneCollapseToggle' },
);

interface PaneCollapseToggleProps {
  expanded: boolean;
  setExpanded: (mode: boolean) => void;
}

// Draw a button for hiding or showing the right pane.
const PaneCollapseToggle = (props: PaneCollapseToggleProps) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.toggle)}>
      <IconButton
        className={classes.button}
        onClick={() => props.setExpanded(!props.expanded)}
        title={props.expanded ? 'Hide Panel' : 'Show Panel'}>
        <div className={classes.icon}>
          {props.expanded ? (
            <CaretRightIcon maxHeight={6} />
          ) : (
            <CaretLeftIcon maxHeight={6} />
          )}
        </div>
      </IconButton>
    </div>
  );
};

export default PaneCollapseToggle;
