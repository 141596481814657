// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
// Sets up logrocket logging.

import LogRocket from 'logrocket';

import { isDev, isProd, isStaging } from './RuntimeParams';

const LR_CONFIG = {
  console: {
    // Capture log, info, warning and error messages but not debug.
    isEnabled: {
      debug: false,
    },
    shouldAggregateConsoleErrors: true,
  },
  network: {
    // Sanitize requests & responses, as documented at
    // https://docs.logrocket.com/reference/network#sanitize-a-network-request
    // TODO(bamo, LC-15476): Scrub the session replay video
    // TODO(bamo, LC-15477): Whitelist things we know to be safe.
    // TODO(bamo): What are these types (request/response?)
    requestSanitizer: (request: any) => {
      // For now, always scrub the body to be on the safe side.
      request.body = null;
      // Always scrub the authorization header, which contains tokens.
      Object.keys(request.headers).forEach((header) => {
        // HTTP headers are case-insensitive, but we can still get different
        // capitalizations of the same header.
        if (header.toLowerCase() === 'authorization') {
          delete request.headers[header];
        }
      });
      return request;
    },
    responseSanitizer: (response: any) => {
      // Same deal with response body.
      response.body = null;
      return response;
    },
  },
};

// Naming of the projects is a bit confusing, but its correct this way
const PROJECT_NAME_PROD = 'qkp6al/test0-v01ep';
const PROJECT_NAME_STAGING = 'qkp6al/test';
const PROJECT_NAME_DEV = 'qkp6al/main-javuw';

// Tracks the LogRocket session URL (if a LogRocket session exists).
// undefined if no LogRocket session exists.
let logRocketSession: string | undefined;

export function sessionUrl() {
  return logRocketSession;
}

function init(projectName: string) {
  LogRocket.init(projectName, LR_CONFIG);
  LogRocket.getSessionURL((url) => {
    logRocketSession = url;
  });
}

export function setup(): void {
  if (isProd) {
    init(PROJECT_NAME_PROD);
  } else if (isStaging) {
    init(PROJECT_NAME_STAGING);
  } else if (isDev) {
    init(PROJECT_NAME_DEV);
  }
}

// Identify a user by id, email and name.
// May be called up to 10 times per session; further calls are ignored.
// Info from all calls will be associated with the session, although only
// the most recent will show up in the LogRocket UI by default.
export function identify(id: string, email: string, name: string) {
  LogRocket.identify(id, { email, name });
}
