// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, useState } from 'react';

import { SparkLineSeries, Transform, transformPoint } from '../../../lib/charting';
import { Point } from '../../../lib/geometry';
import { pointsToPath } from '../../../lib/svg';
import Tooltip from '../../Tooltip';

import './SparkLine.scss';

/** The SparkLine component maps the set of points in a data series to a space
 * between [0, 0] and [width, height].
 *
 * An SVG <path> element is used to render the sparkline.  Because the path sets
 * a stroke-linecap value of "round", some space must be reserved for the path's
 * rounded edges.  Additionally, when the user's mouse is near a point, a
 * tooltip is shown and an SVG <circle> element with a diameter larger than the
 * path stroke is rendered at the point's location.
 *
 * To make room for the round line caps and for the drawn points, some inset
 * (proportional to the 'stroke' prop) is calculated, so that the actual spark
 * line is drawn in a space between [inset, inset] and
 * [width - inset, height - inset]. */

// A pair of points, the raw series data and coordinates in the plot
interface PointData {
  sourcePoint: Point;
  plotPoint: Point;
}

// Represents point data (coordinates, raw series data, and labels) for tooltips
interface HoverPoint extends PointData {
  xFormatted: string;
  yFormatted: string;
}

export interface SparkLineProps {
  // Overall width of plot
  width: number;
  // Overall height of plot
  height: number;
  // The SVG path stroke-width
  strokeWidth?: number;
  // How close the mouse must be to a point on the path before showing a tooltip
  hoverRadius?: number;
  // The data to plot
  series: SparkLineSeries;
  // The color to apply to the path
  color: string;
  // Optionally format and modify the Spark Line
  options?: {
    // Format the x and y values in the hover tooltip
    format?: Intl.NumberFormatOptions
  }
}

// Default width/height of the plot area
const DEFAULT_DIM = 100;
// Default stroke of plots
const DEFAULT_STROKE_WIDTH = 2;
// Default radius threshold for showing tooltips on the nearest hovered point
const DEFAULT_HOVER_RADIUS = 50;

// Calculate the point in 'pointData' that's nearest to the reference point,
// excluding points that aren't within a given radius from the reference point,
// returning null if no points are found.
function nearestPoint(
  pointData: PointData[],
  refPoint: Point,
  radius: number,
): PointData | null {
  const { x: refX, y: refY } = refPoint;

  // Calculating the actual distance requires taking the square root, but that's
  // unnecessary, since comparing the squares is sufficient and less expensive.
  const rSquared = radius ** 2;

  let closest = rSquared;

  return pointData.reduce((result, data) => {
    const { plotPoint } = data;
    const dSquared = (plotPoint.x - refX) ** 2 + (plotPoint.y - refY) ** 2;
    if (dSquared < closest) {
      closest = dSquared;
      return data;
    }
    return result;
  }, null as PointData | null);
}

export const SparkLine = (props: SparkLineProps) => {
  const {
    width = DEFAULT_DIM,
    height = DEFAULT_DIM,
    hoverRadius = DEFAULT_HOVER_RADIUS,
    strokeWidth = DEFAULT_STROKE_WIDTH,
    series,
    color,
    options,
  } = props;

  const [hoverPoint, setHoverPoint] = useState<HoverPoint | null>(null);

  // For visual balance, don't allow scale to exceed 10% of the smaller dimension
  const minDim = Math.min(width, height);
  const stroke = Math.min(minDim / 10, strokeWidth);

  const inset = stroke * 2;

  const rootStyle = {
    '--width': `${width}px`,
    '--height': `${height}px`,
  } as CSSProperties;

  const { xLabel, yLabel, points } = series;

  let [minX, maxX, minY, maxY] = points.reduce((result, point) => (
    [
      result[0] === null ? point.x : Math.min(result[0], point.x),
      result[1] === null ? point.x : Math.max(result[1], point.x),
      result[2] === null ? point.y : Math.min(result[2], point.y),
      result[3] === null ? point.x : Math.max(result[3], point.y),
    ]
  ), [Infinity, -Infinity, Infinity, -Infinity]);

  // If the series represents a vertical line, center it horizontally in a 1-pixel range
  if (minX === maxX) {
    minX -= 0.5;
    maxX += 0.5;
  }

  // If the series represents a horizontal line, center it vertically in a 1-pixel range
  if (minY === maxY) {
    minY -= 0.5;
    maxY += 0.5;
  }

  // Constants used for mapping data series points to plot points
  const transform: Transform = {
    x: {
      minSource: minX,
      deltaSource: maxX - minX,
      minPlot: inset,
      deltaPlot: (width - 2 * inset),
    },
    y: {
      minSource: minY,
      deltaSource: maxY - minY,
      minPlot: inset,
      deltaPlot: (height - 2 * inset),
    },
  };

  // A list of plot points to be converted to an SVG path
  const coords: Point[] = [];
  // A list of raw/plot point pairs to be saved for calculating nearest points
  // to the mouse when hovering
  const pointData: PointData[] = [];

  points.forEach((point) => {
    const coordinates = transformPoint(point, transform);

    // Invert y, since the Y-axis runs top-to-bottom in HTML
    coordinates.y = height - coordinates.y;

    coords.push(coordinates);
    pointData.push({
      sourcePoint: point,
      plotPoint: coordinates,
    });
  });

  const svgPath = pointsToPath(coords);

  const doHover = (event: React.MouseEvent<HTMLDivElement>) => {
    const nearPoint = nearestPoint(
      pointData,
      {
        x: event.nativeEvent.offsetX,
        y: event.nativeEvent.offsetY,
      },
      hoverRadius,
    );

    if (nearPoint) {
      setHoverPoint({
        ...nearPoint,
        xFormatted: nearPoint.sourcePoint.x.toLocaleString(undefined, options?.format),
        yFormatted: nearPoint.sourcePoint.y.toLocaleString(undefined, options?.format),
      });
    } else {
      setHoverPoint(null);
    }
  };

  const endHover = () => {
    setHoverPoint(null);
  };

  const renderPoint = (pt: Point) => (
    <circle
      cx={pt.x}
      cy={pt.y}
      fill={color}
      paintOrder="stroke" // sets stroke outside rather than inside
      r={stroke * 1.5}
      stroke="var(--color-surface-background)"
      strokeWidth={2}
    />
  );

  return (
    <div
      className="sparkLine"
      onMouseLeave={() => endHover()}
      onMouseMove={(event) => doHover(event)}
      style={rootStyle}>
      <Tooltip
        arrow
        followCursor
        placement="top"
        title={(
          <div className="sparkLineTooltip">
            <div className="charts">
              <div className="recordLabel">{xLabel || 'x'}</div>
              <div className="recordValue">{hoverPoint?.xFormatted}</div>
              <div className="recordLabel">{yLabel || 'y'}</div>
              <div className="recordValue">{hoverPoint?.yFormatted}</div>
            </div>
          </div>
        )}>
        <div className="plot">
          <svg
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}>
            <g>
              {coords.length === 1 ?
                renderPoint(coords[0]) : (
                  <path
                    d={svgPath}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeWidth={stroke}
                  />
                )}
            </g>
            {hoverPoint && (
              <g>
                {renderPoint(hoverPoint.plotPoint)}
              </g>
            )}
          </svg>
        </div>
      </Tooltip>
    </div>
  );
};
