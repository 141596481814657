import { useCallback, useMemo } from 'react';

import { findPeriodicPairById, updatePeriodicPair } from '../../../lib/boundaryConditionUtils';
import { getSurfaceGroupSurfaces } from '../../../lib/entityGroupUtils';
import { Logger } from '../../../lib/observability/logs';
import { NodeType } from '../../../lib/simulationTree/node';
import { defaultNodeFilter } from '../../../lib/subselectUtils';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useSimulationConfig } from '../useSimulationConfig';

const logger = new Logger('SelectionManager');

export const usePeriodicPairSelection = (currentSide: 'sideA' | 'sideB') => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNode } = useSelectionContext();

  // == Recoil
  const { simParam, saveParamAsync } = useSimulationConfig();
  const staticVolumes = useStaticVolumes(projectId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId);

  const sideA = currentSide === 'sideA';

  const assignedSurfaces = useMemo(() => {
    const periodicPair = findPeriodicPairById(simParam, selectedNode?.id || '');
    if (!periodicPair) {
      return new Set<string>();
    }
    const bound = sideA ? periodicPair.boundA : periodicPair.boundB;
    return new Set(bound);
  }, [selectedNode?.id, sideA, simParam]);

  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (nodeType === NodeType.TAGS_BODY) {
      return { related: false, disabled: true };
    }

    const getSurfaceErrors = (surfaceId: string) => {
      if (assignedSurfaces.has(surfaceId)) {
        // This allows the user to remove the surface from the periodic pair. If not, we return an
        // error and the NodeSubselect will disallow removing the surface.
        return [];
      }
      return updatePeriodicPair(
        simParam,
        selectedNode?.id || '',
        [surfaceId],
        geometryTags,
        staticVolumes,
        entityGroupData,
        sideA,
        true,
      );
    };

    if (nodeType === NodeType.SURFACE) {
      const errors = getSurfaceErrors(nodeId);

      return {
        related: true,
        disabled: errors.length > 0,
        tooltip: errors.join(', '),
      };
    }

    if (nodeType === NodeType.SURFACE_GROUP) {
      const isTag = geometryTags.isTagId(nodeId);
      const isGroup = entityGroupData.groupMap.has(nodeId) &&
        entityGroupData.groupMap.get(nodeId).children.size > 0;

      if (!isTag && !isGroup) {
        return { related: false, disabled: true };
      }

      const targetSurfaces = isTag ?
        geometryTags.surfacesFromTagEntityGroupId(nodeId) || [] :
        [...getSurfaceGroupSurfaces(nodeId, entityGroupData)];

      if (!targetSurfaces.length) {
        const targetName = isTag ?
          geometryTags.tagNameFromId(nodeId) :
          entityGroupData.groupMap.get(nodeId).name;

        return {
          related: true,
          disabled: true,
          tooltip: `${isTag ? 'Tag' : 'Group'} ${targetName} does not contain any surfaces`,
        };
      }

      const surfaceErrors = new Set(
        targetSurfaces.flatMap((surfaceId) => getSurfaceErrors(surfaceId)),
      );

      if (surfaceErrors.size > 0 && !assignedSurfaces.has(nodeId)) {
        return {
          related: true,
          disabled: true,
          tooltip: [...surfaceErrors].join(', '),
        };
      }

      return { related: true, disabled: false };
    }

    return defaultNodeFilter(nodeType);
  }, [
    assignedSurfaces,
    entityGroupData,
    geometryTags,
    selectedNode?.id,
    sideA,
    simParam,
    staticVolumes,
  ]);

  const setSurfaces = useCallback(async (surfaceIds: string[]) => {
    await saveParamAsync((newParam) => {
      const errors = updatePeriodicPair(
        newParam,
        selectedNode?.id || '',
        surfaceIds,
        geometryTags,
        staticVolumes,
        entityGroupData,
        sideA,
        false,
      );

      if (errors.length) {
        logger.warn('Could not update surfaces', errors);

        // prevent saving params when there's an error
        throw new Error(errors[0]);
      }
    }).catch(() => { });
  }, [
    entityGroupData,
    geometryTags,
    saveParamAsync,
    selectedNode?.id,
    sideA,
    staticVolumes,
  ]);

  return { nodeFilter, setSurfaces };
};
