// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { HTMLProps } from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { DiskInfoIcon } from '../svg/DiskInfoIcon';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      color: colors.highEmphasisText,
      display: 'flex',
      alignItems: 'baseline',
      gap: '0.5em',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
    },
  }),
  { name: 'Label' },
);

export interface LabelProps extends HTMLProps<HTMLLabelElement> {
  tooltipText?: string;
}

/**
 * Basic styled label for use with form fields.
 */
const Label = (props: LabelProps) => {
  const { children, className, tooltipText } = props;

  const classes = useStyles();

  return (
    <label className={cx(classes.root, className)}>
      {children}
      {tooltipText && (
        <Tooltip title={tooltipText}>
          <div>
            <DiskInfoIcon maxHeight={12} maxWidth={12} />
          </div>
        </Tooltip>
      )}
    </label>
  );
};

export default Label;
