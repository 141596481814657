// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as quantitypb from '../../proto/quantity/quantity_pb';
import * as tablepb from '../../proto/table/table_pb';
import { newAdFloat } from '../adUtils';

const {
  ANHEDRAL_ANGLE,
  DEGREE,
  DRAG_COEFFICIENT,
  LENGTH,
  LIFT_COEFFICIENT,
  MACH,
  PITCHING_MOMENT_COEFFICIENT,
  PRESSURE_RISE,
  RADIAL_FORCE_PROFILE,
  RELATIVE_CHORD,
  RELATIVE_RADIUS,
  SWEEP_ANGLE,
  THRUST_PROFILE,
  TORQUE_PROFILE,
  TWIST_ANGLE,
  VOLUME_FLOW_RATE,
} = quantitypb.QuantityType;

/** The rectilinearTable.model package exports methods and classes that manage
 * low level RectilinearTable access and construction. */

// Axis labels and record labels can be strings or quantities
export interface LabelDefinition {
  name?: string;
  quantity?: quantitypb.QuantityType;
}

// A table is defined by its axis labels and record labels
export interface TableDefinition {
  axisLabels: LabelDefinition[];
  recordLabels: LabelDefinition[];
  tableType: tablepb.TableType;
}

function generateNameHeaderLabel(value: string) {
  return new tablepb.Header_Label({ type: { case: 'name', value } });
}

function generateQuantityHeaderLabel(value: quantitypb.QuantityType) {
  return new tablepb.Header_Label({ type: { case: 'quantity', value } });
}

// For convenience, define the radial distribution table in one place
export const RadialDistributionTableDefinition: TableDefinition = {
  axisLabels: [{ quantity: RELATIVE_RADIUS }],
  recordLabels: [
    { quantity: THRUST_PROFILE },
    { quantity: TORQUE_PROFILE },
    { quantity: RADIAL_FORCE_PROFILE },
  ],
  tableType: tablepb.TableType.RADIAL_DISTRIBUTION,
};

// For convenience, define the probe points table in one place
export const ProbePointsTableDefinition: TableDefinition = {
  axisLabels: [],
  recordLabels: [
    { quantity: LENGTH },
    { quantity: LENGTH },
    { quantity: LENGTH },
    { name: 'name' },
    { name: 'id' },
  ],
  tableType: tablepb.TableType.MONITOR_POINTS,
};

// For convenience, define the blade geometry table definition in one place
export const BladeGeometryTableDefinition: TableDefinition = {
  axisLabels: [{ quantity: RELATIVE_RADIUS }],
  recordLabels: [
    { quantity: TWIST_ANGLE },
    { quantity: SWEEP_ANGLE },
    { quantity: ANHEDRAL_ANGLE },
    { quantity: RELATIVE_CHORD },
  ],
  tableType: tablepb.TableType.BLADE_GEOMETRY,
};

// For convenience, define the airfoil performance table definition in one place
export const AirfoilPerformanceTableDefinition: TableDefinition = {
  axisLabels: [
    { quantity: DEGREE },
    { quantity: MACH },
  ],
  recordLabels: [
    { quantity: LIFT_COEFFICIENT },
    { quantity: DRAG_COEFFICIENT },
    { quantity: PITCHING_MOMENT_COEFFICIENT },
  ],
  tableType: tablepb.TableType.AIRFOIL_PERFORMANCE,
};

// For convenience, define a profile BC table definition in one place
export const ProfileBCTableDefintion: TableDefinition = {
  axisLabels: [],
  recordLabels: [],
  tableType: tablepb.TableType.PROFILE_BC,
};

// For convenience, define a fan curve table definition in one place
export const FanCurveTableDefinition: TableDefinition = {
  recordLabels: [{ quantity: PRESSURE_RISE }],
  axisLabels: [{ quantity: VOLUME_FLOW_RATE }],
  tableType: tablepb.TableType.FAN_CURVE,
};

// For convenience, define a custom sampling for DOE table definition in one place
export const CustomSampleDOETableDefintion: TableDefinition = {
  axisLabels: [],
  recordLabels: [],
  tableType: tablepb.TableType.CUSTOM_SAMPLE_DOE,
};

export const TempVaryingConductivityTableDefinition: TableDefinition = {
  axisLabels: [{ quantity: quantitypb.QuantityType.TEMPERATURE }],
  recordLabels: [{ quantity: quantitypb.QuantityType.THERMAL_CONDUCTIVITY }],
  tableType: tablepb.TableType.TEMP_VARYING,
};

export const TempVaryingViscosityTableDefinition: TableDefinition = {
  axisLabels: [{ quantity: quantitypb.QuantityType.TEMPERATURE }],
  recordLabels: [{ quantity: quantitypb.QuantityType.VISCOSITY }],
  tableType: tablepb.TableType.TEMP_VARYING,
};

/**
 * @param coordinateLabel The header label for the first column of the record columns
 * @returns a Profile BC table definition
 */
export function getProfileBCTableDefintion(coordinateLabel: string) {
  return {
    axisLabels: [],
    recordLabels: [{ name: coordinateLabel }],
    tableType: tablepb.TableType.PROFILE_BC,
  };
}

/**
 * @returns true if the tableType can have an unlimited number of columns, false otherwise
 */
export function isGenericTableType(tableType: tablepb.TableType) {
  return [tablepb.TableType.PROFILE_BC, tablepb.TableType.CUSTOM_SAMPLE_DOE].includes(tableType);
}

// Generate an axis coordinate proto message from a numeric value
export function generateTableAxisCoordinateProto(value: number) {
  return new tablepb.Axis_Coordinate({ type: { case: 'adfloat', value: newAdFloat(value) } });
}

// Generate a table record proto message from a list of values (string or number)
export function generateTableRecordProto(...values: (string | number)[]) {
  const entryList = values.map((value) => {
    const entry = new tablepb.Record_Entry();
    if (value === '') {
      // Ignore empty values by setting them as an empty value
      entry.type = { case: 'empty', value: new tablepb.Record_Entry_Empty() };
    } else if (Number.isNaN(Number(value))) {
      entry.type = { case: 'string', value: value as string };
    } else {
      entry.type = { case: 'adfloat', value: newAdFloat(value as number) };
    }
    return entry;
  });

  return (new tablepb.Record({ entry: entryList }));
}

// Generate a table header lable from a label definition
export function generateTableHeaderLabelProto(defn: LabelDefinition) {
  if (defn.name) {
    return generateNameHeaderLabel(defn.name);
  } if (defn.quantity) {
    return generateQuantityHeaderLabel(defn.quantity);
  }
  return new tablepb.Header_Label();
}

// Generate a table header proto message from the axis and record labels in a
// table definition
export function generateDefinedTableHeaderProto(tableDefinition: TableDefinition) {
  const axisLabelList = tableDefinition.axisLabels.map(
    (labelDefn) => generateTableHeaderLabelProto(labelDefn),
  );
  const recordLabelList = tableDefinition.recordLabels.map(
    (labelDefn) => generateTableHeaderLabelProto(labelDefn),
  );
  return (new tablepb.Header({
    axisLabel: axisLabelList,
    recordLabel: recordLabelList,
  }));
}

// Generate a table header proto message from the header row in the table file
export function generateProfileBCTableHeaderProto(
  columnNames: string[],
  defaultLabel?: LabelDefinition,
) {
  const axisLabelList: tablepb.Header_Label[] = [];
  if (!columnNames[0] || columnNames[0] === 'Column 0') {
    // default header is `Column ${index}` if no header was initially supplied
    // set to LENGTH type if no header is given
    axisLabelList.push(generateNameHeaderLabel(defaultLabel?.name ?? 'Coordinate [m]'));
  } else {
    axisLabelList.push(generateNameHeaderLabel(columnNames[0]));
  }
  // ignore first column for the data columns
  const recordLabelList = columnNames.slice(1).map(generateNameHeaderLabel);
  return new tablepb.Header({
    axisLabel: axisLabelList,
    recordLabel: recordLabelList,
    allowMissingEntries: true,
  });
}

/**
 * Generates a Header proto from the column names set to only the record label list.
 *
 * @param columnNames the names of the column
 * @param allowMissingEntries sets the Header proto to allow missing entries, defaults to False
 * @param indexColumn if not undefined, adds an index column to the start of the header as an axis
 *
 * @returns a Header proto with the given columns to only the Record label list
 */
export function generateRecordTableHeaderProto(
  columnNames: string[],
  allowMissingEntries?: boolean,
  indexColumn?: string,
) {
  // ignore first column for the data columns
  const recordLabelList = columnNames.map(generateNameHeaderLabel);
  return new tablepb.Header({
    axisLabel: [...indexColumn === undefined ? [] : [generateNameHeaderLabel(indexColumn)]],
    recordLabel: recordLabelList,
    allowMissingEntries,
  });
}

// A geometry point record has five columns (three with quantities LENGTH,
// followed by two named columns for label and ID). This function constructs
// a record which may be used to build a record list in a table.
export function generateProbePointsRecordProto(
  id: string,
  name: string,
  x: number,
  y: number,
  z: number,
) {
  return generateTableRecordProto(x, y, z, name, id);
}

// A geometry point table has no axes and five columns as described in the
// function above.  This function constructs a table header, which may be used
// in the construction of a table.
export function generateProbePointsTableHeader() {
  return generateDefinedTableHeaderProto(ProbePointsTableDefinition);
}

// Generate an empty geometry point table with header
export function generateProbePointsTable() {
  const header = generateProbePointsTableHeader();
  return (new tablepb.RectilinearTable({ header }));
}

// Table record entries are numbers or strings
type RecordEntryValue = string | number;
// Access maps are used to translate a name to an index.  For example, if an
// access map is ['x', 'y', 'z'], a record entry at index 2 may be referred to
// by name 'z'.
type RecordAccessMap = string[];

// An abstract model class that simplifies access to member records
class TableRecordModel {
  // The original Record proto
  public readonly recordProto: tablepb.Record;

  // A list of names to map to entry indices.  For example, if a record has
  // length 3, an accessMap value of ['x', 'y', 'z'] would allow accessing each
  // each entry by name (x, y, or z) rather than by index (0, 1, or 2).  See
  // getNamedEntry() and setNamedEntry() methods below.
  private readonly accessMap: RecordAccessMap = [];

  // A table's record labels prescribe the record entries' data types.  Each
  // label has either a quantity or a name.  If the label an recordLabels[i] has
  // a quantity, the record entry at index i is treated as a number; if the
  // label has a name, the record entry is treated as a string.
  private readonly recordLabels: tablepb.Header_Label[];

  constructor(
    recordProto: tablepb.Record,
    recordLabels: tablepb.Header_Label[],
    accessMap: RecordAccessMap,
  ) {
    this.recordProto = recordProto;
    this.recordLabels = recordLabels;
    this.accessMap = accessMap;
  }

  get entries(): tablepb.Record_Entry[] {
    return this.recordProto.entry;
  }

  private getStringEntry(index: number): string {
    const entry = this.entries[index];
    if (entry.type.case === 'string') {
      return entry.type.value;
    }
    return '';
  }

  private getFloatEntry(index: number): number {
    const entry = this.entries[index];
    if (
      entry.type.case === 'adfloat' &&
      entry.type.value.adTypes.case === 'value'
    ) {
      return entry.type.value.adTypes.value;
    }
    return 0;
  }

  private setStringEntry(index: number, value: string) {
    this.entries[index].type = { case: 'string', value };
  }

  private setFloatEntry(index: number, value: number) {
    this.entries[index].type = { case: 'adfloat', value: newAdFloat(value) };
  }

  public getNamedEntry(name: string): RecordEntryValue {
    const index = this.accessMap.findIndex((accessor) => accessor === name);
    if (index === -1) {
      throw Error(`Can't access entry named ${name}`);
    }
    if (this.recordLabels[index].type.case === 'quantity') {
      return this.getFloatEntry(index);
    }
    return this.getStringEntry(index);
  }

  public setNamedEntry(name: string, value: RecordEntryValue) {
    const index = this.accessMap.findIndex((accessor) => accessor === name);
    if (index === -1) {
      throw Error(`Can't set entry named ${name}`);
    }

    if (this.recordLabels[index].type.case === 'quantity') {
      this.setFloatEntry(index, value as number);
    } else {
      this.setStringEntry(index, value as string);
    }
  }
}

/** The TableModel class provides an abstract interface for working with
 * RectilinearTable protos.  Using an access map (an array of strings) allows
 * applications to access record entries by name rather than by index. */
abstract class TableModel {
  // The root table proto object
  public readonly tableProto: tablepb.RectilinearTable;

  // A list of names to map to record entry indices.  For example, if table
  // records have length 3, a value of ['x', 'y', 'z'] would allow accessing
  // each record entry by name (x, y, or z) rather than by index (0, 1, or 2).
  public readonly accessMap: RecordAccessMap;

  // recordLabels is derived from the table proto, but it's stored for
  // efficiency
  public readonly recordLabels: tablepb.Header_Label[];

  // A list of TableRecordModel objects, reprenting the table proto's recordList
  public records: TableRecordModel[];

  constructor(tableProto: tablepb.RectilinearTable, accessMap: RecordAccessMap) {
    this.tableProto = tableProto;
    this.accessMap = accessMap;

    const { header, record } = tableProto;
    this.recordLabels = header?.recordLabel || [];
    this.records = record.map(
      (recordProto) => new TableRecordModel(recordProto, this.recordLabels, this.accessMap),
    );
  }

  // Return a subset of records whose named entry (as defined by accessMap) has
  // the given value
  public filterRecords(entryName: string, value: RecordEntryValue) {
    return this.records.filter((record) => record.getNamedEntry(entryName) === value);
  }

  // Return a single record whose named entry (as defined by accessMap) has the
  // given value
  public getRecord(entryName: string, value: RecordEntryValue) {
    return this.records.find((record) => record.getNamedEntry(entryName) === value);
  }

  // Called internally when records are added or deleted
  protected updateRecords(records: TableRecordModel[]) {
    this.records = records;

    const recordProtos = records.map((record) => record.recordProto);
    this.tableProto.record = recordProtos;
  }

  // Delete any record whose named entry (as defined by accessMap) has the given
  // value
  public deleteRecords(entryName: string, value: string) {
    const newRecords = this.records.filter((record) => record.getNamedEntry(entryName) !== value);
    this.updateRecords(newRecords);
  }
}

// Represents a table of probe points
export class ProbePointsTableModel extends TableModel {
  constructor(tableProto: tablepb.RectilinearTable) {
    super(tableProto, ['x', 'y', 'z', 'name', 'id']);
  }

  public addRecord(id: string, name: string, x: number, y: number, z: number) {
    const recordProto = generateProbePointsRecordProto(id, name, x, y, z);
    const records = [
      ...this.records,
      new TableRecordModel(recordProto, this.recordLabels, this.accessMap),
    ];

    this.updateRecords(records);

    return recordProto;
  }
}
