// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { DataSelect } from '../../components/Form/DataSelect';
import { Table } from '../../components/data/Table';
import { MainPageLayout, useMainCommonStyles } from '../../components/layout/page/Main';
import { ColumnConfig, RowConfig } from '../../lib/componentTypes/table';
import { validRoles } from '../../lib/systemRoles';
import * as userstatepb from '../../proto/userstate/userstate_pb';
import { useExperimentConfig, useSetExperimentConfig } from '../../recoil/useExperimentConfig';
import { useFrontendRole } from '../../state/external/user/frontendRole';

const columnConfigs: ColumnConfig[] = [
  { id: 'feature', label: 'Feature', type: 'string' },
  { id: 'id', label: 'ID', type: 'number' },
  { id: 'enabled', label: 'Enabled', type: 'boolean' },
];

const SettingsPageBody = () => {
  const commonClasses = useMainCommonStyles();
  const experimentPreferences = useExperimentConfig();
  const setExperimentPreferences = useSetExperimentConfig();
  const experimentsList = useMemo(() => (
    Object.values(experimentPreferences.experiments)
  ), [experimentPreferences]);

  const handleExperimentToggle = (meta: userstatepb.ExperimentMeta, enabled: boolean) => (
    setExperimentPreferences((oldPrefs) => {
      /* newPrefs.experiments is empty when all experimental features are
      turned on (default), so in the case that the feature does not already exist
      in the map, we need to add it before calling setOverride */
      const newPrefs = oldPrefs.clone();
      const id = `${meta.id}`;
      if (!newPrefs.experiments[id]) {
        newPrefs.experiments[id] = meta.clone();
      }
      newPrefs.experiments[id].override = !enabled;
      return newPrefs;
    })
  );

  const rowConfigs: RowConfig[] = experimentsList.map((meta: userstatepb.ExperimentMeta) => {
    const id = `${meta.id}`;
    return {
      id,
      values: {
        feature: meta.name,
        id,
        enabled: !meta.override,
      },
      cellDisplay: {
        enabled: {
          type: 'toggle',
          onChange: (value: boolean) => {
            handleExperimentToggle(meta, value);
          },
        },
      },
    };
  });
  const [frontendRole, setFrontendRole] = useFrontendRole();
  const roles = validRoles();

  return (
    <MainPageLayout permission={roles.length > 1} title="Settings">
      <div className={commonClasses.title}>Platform Role</div>
      <DataSelect
        onChange={setFrontendRole}
        options={roles.map((role) => ({
          value: role.proto,
          name: role.name,
          selected: frontendRole === role.proto,
        }))}
      />
      <div className={commonClasses.title}>Experimental Features</div>
      <Table
        asBlock
        columnConfigs={columnConfigs}
        defaultSort={{ columnId: 'id', descending: false }}
        name="experimental-features-table"
        rowConfigs={rowConfigs}
      />
    </MainPageLayout>
  );
};

export default SettingsPageBody;
