import React from 'react';

import { createStyles, makeStyles } from '../../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
    },
  }),
  { name: 'ShrinkWrapPropPanel' },
);

export const ShrinkWrapPropPanel = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2>Shrink Wrap</h2>
    </div>
  );
};
