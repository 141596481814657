import { useCallback } from 'react';

import { unwrapSurfaceIds } from '../../../lib/entityGroupUtils';
import { attachSurfaceToFrameError, attachVolumeToFrameError, findFrameById, replaceFrameGeometry, replaceFrameSurfaces, replaceFrameVolumes } from '../../../lib/motionDataUtils';
import { Logger } from '../../../lib/observability/logs';
import { NodeType } from '../../../lib/simulationTree/node';
import { defaultNodeFilter } from '../../../lib/subselectUtils';
import { mapDomainsToIds } from '../../../lib/volumeUtils';
import { useWorkflowConfig } from '../../../model/hooks/useWorkflowConfig';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { useAutoSelectSurfacesValue } from '../../../state/internal/tree/autoSelectSurfaces';
import { useProjectContext } from '../../context/ProjectContext';

const logger = new Logger('useMotionFrameSelection');

export const useMotionFrameSelection = (selectedNodeId: string) => {
  // == Contexts

  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const staticVolumes = useStaticVolumes(projectId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const autoSelectSurfaces = useAutoSelectSurfacesValue(projectId);
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const { saveParamAsync } = useWorkflowConfig(projectId, workflowId, jobId, false);

  const geometryTags = useGeometryTags(projectId);

  // Cannot add a volume to a frame if the volume or any of its surfaces is already added
  const getVolumeError = useCallback((volumeId: string) => {
    try {
      return attachVolumeToFrameError(
        volumeId,
        selectedNodeId,
        simParam,
        entityGroupData,
        staticVolumes,
        autoSelectSurfaces,
        geometryTags,
      );
    } catch (error) {
      logger.warn(error);
      return 'Cannot add item';
    }
  }, [
    autoSelectSurfaces,
    entityGroupData,
    selectedNodeId,
    simParam,
    staticVolumes,
    geometryTags,
  ]);

  const volumeNodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (nodeType === NodeType.VOLUME) {
      const volumeFrameError = getVolumeError(nodeId);

      if (volumeFrameError) {
        return { related: true, disabled: true, tooltip: volumeFrameError };
      }

      return { related: true, disabled: false };
    }

    if (nodeType === NodeType.SURFACE_GROUP) {
      if (!geometryTags.isTagId(nodeId)) {
        return { related: true, disabled: true };
      }

      const domains = geometryTags.domainsFromTag(nodeId);

      if (domains.length === 0) {
        const tagName = geometryTags.tagNameFromId(nodeId);
        return {
          related: true,
          disabled: true,
          tooltip: `Tag ${tagName} does not contain any volumes`,
        };
      }

      const volumeErrors = mapDomainsToIds(staticVolumes, domains)
        .map((volumeId) => getVolumeError(volumeId))
        .filter(Boolean);

      return { related: true, disabled: volumeErrors.length > 0, tooltip: volumeErrors.join(', ') };
    }

    return defaultNodeFilter(nodeType);
  }, [geometryTags, getVolumeError, staticVolumes]);

  // If some surface or surface group cannot be selected for a frame, we'll return an error
  // that will be shown as a tooltip and we'll disable the row
  const getSurfaceError = useCallback((surfaceId: string) => attachSurfaceToFrameError(
    surfaceId,
    selectedNodeId,
    simParam,
    entityGroupData,
    geometryTags,
  ), [entityGroupData, selectedNodeId, simParam, geometryTags]);

  const surfaceNodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (nodeType === NodeType.SURFACE) {
      const attachSurfaceError = getSurfaceError(nodeId);

      if (attachSurfaceError) {
        return {
          related: true,
          disabled: true,
          tooltip: attachSurfaceError,
        };
      }

      return {
        related: true,
        disabled: false,
      };
    }

    if (nodeType === NodeType.SURFACE_GROUP) {
      const surfacesWithinContainer = unwrapSurfaceIds([nodeId], geometryTags, entityGroupData);

      if (surfacesWithinContainer.length === 0) {
        const subjectName = geometryTags.isTagId(nodeId) ?
          `Tag ${geometryTags.tagNameFromId(nodeId)}` :
          `Group ${entityGroupData.groupMap.get(nodeId).name}`;

        return {
          related: true,
          disabled: true,
          tooltip: `${subjectName} does not contain any surfaces`,
        };
      }

      const errors = surfacesWithinContainer.map(getSurfaceError).filter(Boolean);

      if (errors.length > 0) {
        return {
          related: true,
          disabled: true,
          tooltip: errors.join(', '),
        };
      }

      return { related: true, disabled: false };
    }

    return defaultNodeFilter(nodeType);
  }, [entityGroupData, geometryTags, getSurfaceError]);

  const setVolumes = useCallback(async (zones: string[]) => {
    await saveParamAsync((param) => {
      const newSelectionSet = new Set(zones);

      const frame = findFrameById(param, selectedNodeId);
      const attachedDomainsCount = (frame?.attachedDomains || []).length;
      const adding = zones.length > attachedDomainsCount;

      if (autoSelectSurfaces && adding) {
        const boundingSurfaces = zones.reduce((result, zoneId) => {
          const domains = geometryTags.domainsFromTag(zoneId);

          const domainVolumes = domains
            .flatMap((domain) => staticVolumes.filter((volume) => volume.domain === domain));
          const zoneVolume = staticVolumes.find((volume) => volume.id === zoneId);

          const surfaceIds = [...domainVolumes, ...(zoneVolume ? [zoneVolume] : [])]
            .flatMap((item) => [...item?.bounds || new Set()]);

          surfaceIds.forEach((surfaceId) => {
            result.add(surfaceId);
          });

          return result;
        }, new Set<string>());

        replaceFrameGeometry(
          param,
          selectedNodeId,
          newSelectionSet,
          boundingSurfaces,
          staticVolumes,
          geometryTags,
          entityGroupData,
        );
      } else {
        replaceFrameVolumes(
          param,
          selectedNodeId,
          newSelectionSet,
          staticVolumes,
          geometryTags,
          entityGroupData,
        );
      }
    });
  }, [
    autoSelectSurfaces,
    entityGroupData,
    geometryTags,
    saveParamAsync,
    selectedNodeId,
    staticVolumes,
  ]);

  const setSurfaces = useCallback(async (newSelection: string[]) => {
    await saveParamAsync((newParam) => {
      replaceFrameSurfaces(
        newParam,
        selectedNodeId,
        new Set(newSelection),
        staticVolumes,
        geometryTags,
        entityGroupData,
      );
    });
  }, [entityGroupData, geometryTags, saveParamAsync, selectedNodeId, staticVolumes]);

  return { volumeNodeFilter, setVolumes, surfaceNodeFilter, setSurfaces };
};
