// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useEffect, useState } from 'react';

/**
 * Hook that determines if the scrollable content within a given element exceeds the visible area.
 * Returns `true` if the content is larger than the displayed area, otherwise `false`.
 *
 * @param {React.RefObject<HTMLDivElement>} ref - A reference to the scrollable element.
 * @param {boolean} [enabled] - Optional flag to enable the content overflow check, true by default.
 * @returns {boolean} `true` if the content overflows the container, otherwise `false`.
 */
export function useContentOverflow(ref: React.RefObject<HTMLDivElement>, enabled = true) {
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    const element = ref.current;

    if (!element || !enabled) {
      return () => {};
    }

    const updateOverflowState = () => {
      setHasOverflow(element.scrollWidth > element.offsetWidth);
    };

    const resizeObserver = new ResizeObserver(updateOverflowState);
    updateOverflowState();

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, [enabled, ref]);

  return hasOverflow;
}
