import React from 'react';

import * as shapepb from '../../../proto/cad/shape_pb';
import * as geometrypb from '../../../proto/geometry/geometry_pb';
import { ActionButton } from '../../Button/ActionButton';
import { createStyles, makeStyles } from '../../Theme';
import { useGeometryStatus } from '../../hooks/useGeometryStatus';
import { useCreateNewModification } from '../../hooks/useInteractiveGeometry';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      // padding on bottom left and right
      padding: '16px 12px 12px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
  }),
  { name: 'CreateFarfieldPropPanel' },
);

export const CreateFarfieldPropPanel = () => {
  const classes = useStyles();
  const { working } = useGeometryStatus();
  const createNewModification = useCreateNewModification();

  const handleAddFarfield = () => {
    createNewModification({
      case: 'farfield',
      value: new geometrypb.Farfield({
        create: new geometrypb.Create({
          shape: {
            case: 'sphere',
            value: new shapepb.Sphere(),
          },
        }),
      }),
    });
  };

  return (
    <div className={classes.root}>
      Adding a far-field will both create a far-field and do a subtraction.
      <ActionButton
        disabled={working}
        kind="primary"
        onClick={handleAddFarfield}
        startIcon={{ name: 'farfield' }}>
        Add Far-Field
      </ActionButton>
    </div>
  );
};
