// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const DesktopMonitorIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="desktopMonitor"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M22.5,0
           L1.5,0
           C0.675,0 0,0.675 0,1.5
           L0,16.5
           C0,17.325 0.675,18 1.5,18
           L8.625,18
           L7.875,21
           L6,21
           C5.175,21 4.5,21.675 4.5,22.5
           C4.5,23.325 5.175,24 6,24
           L18,24
           C18.825,24 19.5,23.325 19.5,22.5
           C19.5,21.675 18.825,21 18,21
           L16.125,21
           L15.375,18
           L22.5,18
           C23.325,18 24,17.325 24,16.5
           L24,1.5
           C24,0.675 23.325,0 22.5,0Z
           M21,15
           L3,15
           L3,3
           L21,3
           L21,15Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
