// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { isTestingEnv } from '../lib/testing/utils';

export enum TreeViewType {
  SETUP,
  POST_PROCESSING,
}

// In the analysis page we have a dropdown which sets a `Setup` vs `Post-processing` view for the
// tree. This in turn filters/changes some of the tree nodes so that users do not see nodes that
// don't make much sense in the view they are in.
export const treeViewTypeState = atom<TreeViewType>({
  key: 'treeViewTypeState',
  // Use the Setup view in the tests because it has more nodes that can be tested
  default: isTestingEnv() ? TreeViewType.SETUP : TreeViewType.POST_PROCESSING,
});

export const useTreeViewType = () => useRecoilState(treeViewTypeState);
export const useTreeViewTypeValue = () => useRecoilValue(treeViewTypeState);
export const useSetTreeViewType = () => useSetRecoilState(treeViewTypeState);
