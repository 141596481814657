// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { DateSelect } from '../DateSelect';

export interface DateRangeSelectProps {
  selectedBeginDate: Date | null;
  selectedEndDate: Date | null;
  onBeginDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
  beginDatePlaceholder?: string;
  endDatePlaceholder?: string;
  maxRange?: { value: number; unit: 'day' | 'month' | 'year' } | null;
  alwaysShowEndDateSelector?: boolean;
  spacing?: number;
  label?: string | null;
  minimumBeginDate?: Date | null;
}

export function DateRangeSelect({
  selectedBeginDate,
  selectedEndDate,
  onBeginDateChange,
  onEndDateChange,
  beginDatePlaceholder = 'Start Date',
  endDatePlaceholder = 'End Date',
  maxRange = null,
  alwaysShowEndDateSelector = false,
  spacing = 8,
  label,
  minimumBeginDate,
}: DateRangeSelectProps) {
  const [lastSelectedBeginDate, setLastSelectedBeginDate] = useState<Date | null>(null);

  const getMaxEndDate = (): Date | null => {
    if (!selectedBeginDate) {
      return null;
    }

    if (!maxRange) {
      return null;
    }

    const maxDate = new Date(selectedBeginDate);

    switch (maxRange.unit) {
      case 'day':
        maxDate.setDate(maxDate.getDate() + maxRange.value);
        break;
      case 'month':
        maxDate.setMonth(maxDate.getMonth() + maxRange.value);
        maxDate.setDate(maxDate.getDate() - 1);
        break;
      case 'year':
        maxDate.setFullYear(maxDate.getFullYear() + maxRange.value);
        maxDate.setDate(maxDate.getDate() - 1);
        break;
      default:
        return null;
    }

    return maxDate;
  };

  useEffect(() => {
    setLastSelectedBeginDate(selectedBeginDate);
  }, [selectedBeginDate, selectedEndDate, onEndDateChange, lastSelectedBeginDate]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {label && <span style={{ marginRight: '8px' }}>{label}</span>}

      <div style={{ marginRight: `${spacing}px` }}>
        <DateSelect
          minDate={minimumBeginDate}
          onChange={onBeginDateChange}
          placeholderText={beginDatePlaceholder}
          selected={selectedBeginDate}
        />
      </div>

      {(alwaysShowEndDateSelector || selectedBeginDate) && (
        <div>
          <DateSelect
            maxDate={getMaxEndDate()}
            minDate={selectedBeginDate}
            onChange={onEndDateChange}
            placeholderText={endDatePlaceholder}
            selected={selectedEndDate}
          />
        </div>
      )}
    </div>
  );
}
