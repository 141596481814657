// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import { isUnmodifiedEnterKey, isUnmodifiedEscapeKey } from '../../lib/event';
import { useContentOverflow } from '../../lib/useContentOverflow';
import { useEditableTextSelectionState, useEditableTextState } from '../../state/internal/component/editableText';
import { IconButton } from '../Button/IconButton';
import { createStyles, makeStyles } from '../Theme';
import { OverflowGradient } from '../common/OverflowGradient';
import { PencilIcon } from '../svg/PencilIcon';

export const EDITABLE_TEXT_BORDER = 1;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      '--cursor': 'pointer',
      '--border-color': 'transparent',
      '--display': 'inline-flex',
      '--button-opacity': 0,
      '&.active': {
        '--cursor': 'text',
      },
      '&.asBlock': {
        '--display': 'flex',
      },
      '&.hoverOutline:hover': {
        '--border-color': colors.neutral450,
      },
      '&:hover': {
        '--button-opacity': 1,
      },
      '&.disabled': {
        '--cursor': 'unset',
      },
      display: 'var(--display)',
      overflow: 'hidden',
      maxWidth: '100%',
      position: 'relative',
      alignItems: 'center',
      gap: '3px',
    },
    wrapper: {
      display: 'flex',
      position: 'relative',
      overflow: 'hidden',
    },
    display: {
      '--bg-color': 'transparent',
      '&:focus': {
        '--bg-color': colors.surfaceBackground,
        '--border-color': colors.primaryCta,
      },
      flex: '1 1 auto',
      lineHeight: 'normal',
      borderRadius: 4,
      userSelect: 'none',
      padding: '3px 2px',
      margin: 0,
      outline: 0,
      backgroundColor: 'var(--bg-color)',
      border: `${EDITABLE_TEXT_BORDER}px solid var(--border-color)`,
      transition: 'background-color 250ms, border-color 250ms',
      cursor: 'var(--cursor)',
      '&.truncate': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '&:not(:focus)': {
          boxSizing: 'border-box',
          '&.ellipsis': {
            textOverflow: 'ellipsis',
          },
        },
      },
    },
    editButton: {
      opacity: 'var(--button-opacity)',
      '&.editing': {
        visibility: 'hidden',
      },
    },
  }),
  { name: 'EditableText' },
);

export const CONTENT_EDITABLE_DATA_LOCATOR = 'editableTextDisplay';

type InitialSelection =
  // Place caret at end of the content
  | 'end'
  // Select all content
  | 'selectall';

interface EditableTextProps {
  // Whether the content is editable
  active: boolean;
  // prevents double-clicking on the element from making the component editable.
  disabled?: boolean;
  // The text to display
  value: string;
  // Called when the input-like DOM node blurs or the user hits ENTER
  onChange: (value: string) => void;
  // Truncate content with periods of ellipsis and with nowrap
  truncate?: boolean;
  // Called when the display area is single-clicked
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  // Called when the display area is double-clicked
  onDoubleClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  // Optionally specify the initial content selection or caret placement
  initialSelection?: InitialSelection;
  hoverOutline?: boolean;
  asBlock?: boolean;
  overflowStrategy?: 'ellipsis' | 'gradient';
  gradientColor?: string;
  editButton?: boolean;
}

// This gets some text selection data that we can use to restore
// the caret position and any text selection that has been made.
function getSelectionInfo(content: string | null | undefined) {
  const sel = document.getSelection() as Selection;
  if (sel?.focusNode) {
    // If the caret is at the end of the text and we press right arrow (which should do nothing),
    // the focusOffset node gets changed and the offset value is incorrectly returned as 0. To
    // work around that and to prevent the jump to the beginning of the text (after rerender) we
    // can handle this by forcing the caret position at the end if the the node is no longer text.
    const movingAtTheEnd = sel.focusNode.nodeName !== '#text';
    // This fixes another edge case where the rerender happens during typing at the end which
    // would cause the caret to be moved 1 char before the end, interrupiting the typing flow.
    const typingAtTheEnd = sel.anchorOffset === content?.length;
    return {
      type: sel.type,
      anchorOffset: sel.anchorOffset,
      focusOffset: sel.focusOffset,
      moveToEnd: movingAtTheEnd || typingAtTheEnd,
    };
  }
  return null;
}

// A row displaying text that can be edited.
// The component uses a div with contentEditable attr set to "false".  Parent components must
// maintain the `active` prop to make the component editable.  Parent components should implement
// either onClick or onDoubleClick to set `active` to true and should set it to false in the
// onChange callback.
export const EditableText = (props: EditableTextProps) => {
  const {
    active,
    asBlock,
    hoverOutline,
    initialSelection,
    onChange,
    onClick,
    onDoubleClick,
    truncate,
    value,
    overflowStrategy = 'ellipsis',
    gradientColor,
    editButton = false,
    disabled,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const hasContentOverflow = useContentOverflow(ref, overflowStrategy === 'gradient');

  // This is to keep track of the currently typed (but not saved) text which in some cases
  // might be lost due to rerenders caused by parent elements. If that happens, the useEffect
  // below will restore the temporary value.
  const [tempText, setTempText] = useEditableTextState();

  // This is used for restoring the caret position or the selection within the content.
  const [selectionInfo, setSelectionInfo] = useEditableTextSelectionState();

  const [isFocused, setIsFocused] = useState(false);

  const classes = useStyles();

  const handleInput = (event: React.FormEvent<HTMLDivElement>) => {
    setTempText(event.currentTarget.textContent);
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    const trimmedText = (event.target.textContent as string).trim();
    if (trimmedText === '') {
      // Restore the original text, if the newText is empty.
      event.target.textContent = value;
    } else {
      // Update the name after a blur event.
      onChange(trimmedText);
    }
    event.target.scrollLeft = 0;
    setTempText(null);
    setSelectionInfo(null);
    setIsFocused(false);
  };

  const handleKeydown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Parent components (like the DataTree row) may have their own keyboard handlers, so don't
    // propagate this keydown event.
    if (active) {
      event.stopPropagation();
    }

    // Pressing ENTER will blur the field which will trigger the update
    if (isUnmodifiedEnterKey(event)) {
      // Don't add a new line to the field
      event.preventDefault();
      ref.current?.blur();
      // Pressing ESC will blur the trigger but first we'll restore the original value
    } else if (isUnmodifiedEscapeKey(event)) {
      (event.target as HTMLDivElement).textContent = value;
      ref.current?.blur();
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Parent components (like the DataTree row) may have their own keyboard handlers, so don't
    // propagate this keyup event.
    if (active) {
      event.stopPropagation();
    }

    if (active) {
      window.requestAnimationFrame(() => {
        setSelectionInfo(getSelectionInfo(ref.current?.textContent));
      });
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(event);
  };

  const handleDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) {
      return;
    }

    onDoubleClick?.(event);
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    if (active) {
      window.requestAnimationFrame(() => {
        setSelectionInfo(getSelectionInfo(ref.current?.textContent));
      });
    }
  };

  const handleFocus = (event: React.FocusEvent) => {
    setIsFocused(true);

    window.requestAnimationFrame(() => {
      if (!ref.current) {
        return;
      }
      // If we have a temporary value (from an edit in transition that is lost due to rerender)
      // we can restore that value so that we don't interrupt the edit.
      if (tempText !== null) {
        (ref.current as HTMLDivElement).textContent = tempText;
      }

      const selection = window.getSelection();
      const nodeLength = event.target.firstChild?.nodeValue?.length || 0;

      const moveCaretToEnd = () => {
        selection?.selectAllChildren(event.target as HTMLDivElement);
        selection?.collapseToEnd();
      };

      // For single-click triggered fields the caret is properly positioned where the user clicks.
      // For double-click triggered fields all default behaviours are disabled and the browser puts
      // the caret at the start when we activate the edit and focus it.  This restores any
      // selections the user might have effected (in case of re-renders).  If no user selection
      // exists, either the caret will be positioned or the content will be pre-selected, depending
      // on the `caret` prop.
      switch (selectionInfo?.type) {
        // Restore caret position
        case 'Caret':
          if (nodeLength === 0 || selectionInfo.moveToEnd) {
            moveCaretToEnd();
          } else {
            const caretPos = Math.min(nodeLength, selectionInfo.anchorOffset);
            selection?.collapse(event.target.firstChild, caretPos);
          }
          break;
        // Restore range position
        case 'Range': {
          const anchorOffset = Math.min(nodeLength, selectionInfo.anchorOffset);
          const focusOffset = Math.min(nodeLength, selectionInfo.focusOffset);
          selection?.setBaseAndExtent(
            event.target.firstChild!,
            anchorOffset,
            event.target.firstChild!,
            focusOffset,
          );
          break;
        }
        default:
          switch (initialSelection) {
            case 'end': {
              moveCaretToEnd();
              break;
            }
            case 'selectall':
            default: {
              selection?.selectAllChildren(event.target as HTMLDivElement);
              break;
            }
          }
      }
    });
  };

  useEffect(() => {
    if (active) {
      ref.current?.focus();
    }
  }, [active]);

  const showOverflowGradient = hasContentOverflow && overflowStrategy === 'gradient' && !isFocused;
  const enableEditing = handleDoubleClick;

  return (
    <div className={cx(classes.root, { active, hoverOutline, asBlock, disabled })}>
      <div className={classes.wrapper}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          autoCapitalize="off"
          autoCorrect="off"
          className={cx(classes.display, { truncate, ellipsis: overflowStrategy === 'ellipsis' })}
          contentEditable={active}
          data-locator={CONTENT_EDITABLE_DATA_LOCATOR}
          onBlur={handleBlur}
          onDoubleClick={handleDoubleClick}
          onFocus={handleFocus}
          onInput={handleInput}
          onKeyDown={handleKeydown}
          onKeyUp={handleKeyUp}
          onMouseDown={handleClick}
          onMouseUp={handleMouseUp}
          ref={ref}
          spellCheck="false"
          suppressContentEditableWarning>
          {value}
        </div>
        {showOverflowGradient && <OverflowGradient gradientColor={gradientColor} />}
      </div>
      {editButton && !disabled && (
        <IconButton
          className={cx(classes.editButton, { editing: active })}
          onClick={enableEditing as any}>
          <PencilIcon maxHeight={10} maxWidth={10} />
        </IconButton>
      )}
    </div>
  );
};
