// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const TableOutlinedIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="tableOutlined"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M3.6,24
           L20.4,24
           C21.355,24 22.27,23.621 22.946,22.946
           C23.621,22.27 24,21.355 24,20.4
           L24,3.6
           C24,2.645 23.621,1.73 22.946,1.054
           C22.27,0.379 21.355,0 20.4,0
           L3.6,0
           C2.645,0 1.73,0.379 1.054,1.054
           C0.379,1.73 0,2.645 0,3.6
           L0,20.4
           C0,21.355 0.379,22.27 1.054,22.946
           C1.73,23.621 2.645,24 3.6,24Z
           M7.2,21.6
           L7.2,9.6
           L2.4,9.6
           L2.4,20.4
           C2.4,20.719 2.527,21.024 2.75,21.25
           C2.976,21.473 3.281,21.6 3.6,21.6
           L7.2,21.6Z
           M21.6,9.6
           L9.6,9.6
           L9.6,21.6
           L20.4,21.6
           C20.719,21.6 21.024,21.473 21.25,21.25
           C21.473,21.024 21.6,20.719 21.6,20.4
           L21.6,9.6Z
           M7.2,2.4
           L3.6,2.4
           C3.281,2.4 2.976,2.527 2.75,2.75
           C2.527,2.976 2.4,3.281 2.4,3.6
           L2.4,7.2
           L7.2,7.2
           L7.2,2.4Z
           M9.6,2.4
           L9.6,7.2
           L21.6,7.2
           L21.6,3.6
           C21.6,3.281 21.473,2.976 21.247,2.75
           C21.024,2.527 20.719,2.4 20.4,2.4
           L9.6,2.4Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
