// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue } from 'recoil';

import { meshPendingState } from './pendingWorkOrders';
import { projectActiveMeshSelector } from './useProjectActiveMesh';

/**
 * This selector determines if the mesh section should be read only. Currently only read only if a
 * mesh is being generated or if a generated mesh is selected.
 */
export const meshReadOnlyState = selectorFamily<boolean, string>({
  key: 'meshReadOnlyState',
  get: (projectId: string) => async ({ get }) => {
    const activeMesh = get(projectActiveMeshSelector({ projectId }));
    const meshGenerating = get(meshPendingState(projectId));

    return !!activeMesh || !!meshGenerating;
  },
});

export const useMeshReadOnly = (projectId: string) => useRecoilValue(meshReadOnlyState(projectId));
