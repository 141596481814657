// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

//  The ZoomToBoxOverlay will zoom the camera to any box region the user selects.

import React from 'react';

import { OverlayMode, useSetOverlayMode } from '../../../state/internal/vis/overlayMode';
import { useHandleManualCameraViewChange } from '../../hooks/useHandleManualCameraViewChange';
import { useParaviewContext } from '../ParaviewManager';

import DraggableBoxOverlay, { OnBoxSelectArguments } from './DraggableBoxOverlay';

interface ZoomToBoxOverlayProps {
  className: string;
}

const ZoomToBoxOverlay = (props: ZoomToBoxOverlayProps) => {
  const { className } = props;
  const { paraviewRenderer } = useParaviewContext();
  const onSelect = useHandleManualCameraViewChange();
  const setOverlayMode = useSetOverlayMode();
  const onBoxSelect = (args: OnBoxSelectArguments) => {
    // Sanity checks to avoid rpc errors when the user clicks and release
    // instantaneously the mouse.
    if (args.maxX > args.minX && args.maxY > args.minY) {
      paraviewRenderer.zoomToBox(args.minX, args.maxX, args.minY, args.maxY);
      onSelect();
    }
    setOverlayMode(OverlayMode.NONE);
  };

  return (
    <DraggableBoxOverlay
      autoActivate={false}
      className={className}
      constrainBox
      onBoxSelect={onBoxSelect}
      text="Draw a box on screen by dragging the mouse. Press ESC to exit."
    />
  );
};

export default ZoomToBoxOverlay;
