// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
// tells us how many modals are open
// if any are open, we disable the allowEventListener selector in useAllowEventListener.

import { useMemo } from 'react';

import { atom, useRecoilState, useSetRecoilState } from 'recoil';

export const modalsActive = atom<number>({
  key: 'modalsActive',
  default: 0,
});

export const useModalsActive = () => useRecoilState(modalsActive);

// use to get increment and decrement functions for modalsActive
// e.g. const [incrementModals, decrementModals] = useSetModalsActive();
export const useSetModalsActive = () => {
  const set = useSetRecoilState(modalsActive);
  return useMemo(() => [
    () => set((modalsOpen) => modalsOpen + 1),
    () => set((modalsOpen) => modalsOpen - 1),
  ], [set]);
};
