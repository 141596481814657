// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as frontendpb from '../proto/frontend/frontend_pb';

export type WorkOrderReply =
  frontendpb.GetGeometryReply |
  frontendpb.CheckGeometryReply |
  frontendpb.GetMeshReply;

export type WorkOrderDone =
  frontendpb.GetGeometryReply_Done |
  frontendpb.CheckGeometryReply_Done |
  frontendpb.GetMeshReply_Done;

export type WorkOrderRequest =
  frontendpb.GetGeometryRequest |
  frontendpb.CheckGeometryRequest |
  frontendpb.GetMeshRequest |
  frontendpb.ComputeGeometryContactsRequest;

const WorkOrderType = frontendpb.WorkOrderType;
type wOrderKey = frontendpb.WorkOrderType;

// The work order types ranked in order of which happens first. GET_GEOMETRY will complete before
// CHECK_GEOMETRY if both are present.
export const WORK_ORDER_TYPES: wOrderKey[] = [
  WorkOrderType.GET_GEOMETRY,
  WorkOrderType.CHECK_GEOMETRY,
  WorkOrderType.GET_MESH,
  WorkOrderType.COMPUTE_GEOMETRY_CONTACTS,
];
