// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const SphereIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="sphere"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeMiterlimit: 2,
        fill: getColor(0),
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M15.895,9.298C15.275,13.099 11.976,16 8,16C4.024,16 0.725,13.099 0.105,9.298C0.552,9.652
           1.099,9.961 1.714,10.225C3.352,10.927 5.575,11.347 8,11.347C10.425,11.347 12.648,10.927
           14.286,10.225C14.901,9.961 15.448,9.652 15.895,9.298ZM15.973,7.337C15.916,7.586 15.765,
           7.852 15.491,8.13C15.126,8.501 14.569,8.86 13.836,9.174C12.371,9.802 10.309,10.204 8,
           10.204C5.691,10.204 3.629,9.802 2.164,9.174C1.431,8.86 0.874,8.501 0.509,8.13C0.235,
           7.852 0.084,7.586 0.027,7.337C0.364,3.229 3.805,0 8,0C12.195,0 15.636,3.229 15.973,
           7.337Z"
      />
    </svg>
  );
});
