// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { copiedName } from '../../../lib/name';
import { findParentPhysicsByPhysicalBehaviorId, findPhysicalBehaviorById } from '../../../lib/physicalBehaviorUtils';
import { findParentPhysics, findPorousModelById } from '../../../lib/porousModelUtils';
import { newNodeId } from '../../../lib/projectDataUtils';
import { useWorkflowConfig } from '../../../model/hooks/useWorkflowConfig';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';

/**
 * @returns a callback which takes an id and duplicates the disk model with that id.
 */
export const useCopyDiskModel = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection, setScrollTo } = useSelectionContext();

  const { saveParamAsync } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  const copyPhysicalBehavior = useCallback(async (id: string) => {
    const newId = await saveParamAsync((param) => {
      const model = findPhysicalBehaviorById(param, id);
      if (!model) {
        return;
      }
      const physics = findParentPhysicsByPhysicalBehaviorId(param, id);
      if (physics?.params.case !== 'fluid') {
        return;
      }
      const originalName = model.physicalBehaviorName;
      const newPhysicalBehavior = model.clone();
      newPhysicalBehavior.physicalBehaviorId = newNodeId();
      newPhysicalBehavior.physicalBehaviorName = copiedName(originalName);
      physics.params.value.physicalBehavior.push(newPhysicalBehavior);

      return newPhysicalBehavior.physicalBehaviorId;
    });
    if (newId) {
      setSelection([newId]);
      setScrollTo({ node: newId });
    }
  }, [saveParamAsync, setSelection, setScrollTo]);

  return copyPhysicalBehavior;
};

/**
 * @returns a callback which takes an id and duplicates the porous model with that id.
 */
export const useCopyPorousModel = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection, setScrollTo } = useSelectionContext();

  const { saveParamAsync } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  const copyPorousModel = useCallback(async (id: string) => {
    const newId = await saveParamAsync((param) => {
      const model = findPorousModelById(param, id);
      if (!model) {
        return;
      }
      const physics = findParentPhysics(param, id);
      if (physics?.params.case !== 'fluid') {
        return;
      }
      const originalName = model.porousBehaviorName;
      const newPorousModel = model.clone();
      newPorousModel.porousBehaviorId = newNodeId();
      newPorousModel.porousBehaviorName = copiedName(originalName);
      newPorousModel.zoneIds = [];
      physics.params.value.porousBehavior.push(newPorousModel);

      return newPorousModel.porousBehaviorId;
    });
    if (newId) {
      setSelection([newId]);
      setScrollTo({ node: newId });
    }
  }, [saveParamAsync, setSelection, setScrollTo]);

  return copyPorousModel;
};
