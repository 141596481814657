// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { copiedName } from '../../../lib/name';
import { findOutputNodeById } from '../../../lib/outputNodeUtils';
import { newNodeId } from '../../../lib/projectDataUtils';
import { initializeNewNode, useSetNewNodes } from '../../../recoil/nodeSession';
import { useOutputNodes } from '../../../recoil/outputNodes';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';

/** @returns a callback to duplicate the output node with the given id. */
export const useCopyOutput = () => {
  const { projectId } = useProjectContext();
  const [outputNodes, setOutputNodes] = useOutputNodes(projectId, '', '');
  const { setSelection, setOutputGraphId } = useSelectionContext();
  const setNewNodes = useSetNewNodes();

  const duplicateRow = useCallback((id: string) => {
    const newOutputNodes = outputNodes.clone();
    const oldOutput = findOutputNodeById(newOutputNodes, id);
    if (oldOutput) {
      const newOutput = oldOutput?.clone();
      newOutput.id = newNodeId();
      newOutput.name = copiedName(oldOutput.name);
      newOutputNodes.nodes.push(newOutput);
      setOutputNodes(newOutputNodes);
      const nodeId = newOutput.id;
      setSelection([nodeId]);
      setNewNodes((nodes) => [...nodes, initializeNewNode(nodeId)]);
      setOutputGraphId({ node: nodeId, graphIndex: 0 });
    }
  }, [outputNodes, setNewNodes, setOutputGraphId, setOutputNodes, setSelection]);

  return duplicateRow;
};
