// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { shallowEqual } from 'fast-equals';
import { useAtom, useAtomValue } from 'jotai';
import { atomFamily, atomWithStorage, createJSONStorage } from 'jotai/utils';

import { RecoilProjectKey } from '../../../lib/persist';

// Generate a unique key for the project and job
function getStorageKey(key: RecoilProjectKey) {
  return `run-status-visible-${key.projectId}/${key.workflowId}/${key.jobId}`;
}

// Switch to sessionStorage instead of localStorage
const storage = createJSONStorage(() => sessionStorage);

const isRunStatusVisibleState = atomFamily(
  (key: RecoilProjectKey) => atomWithStorage(getStorageKey(key), true, storage),
  shallowEqual,
);

export function useIsRunStatusVisible(key: RecoilProjectKey) {
  return useAtom(isRunStatusVisibleState(key));
}

export function useIsRunStatusVisibleValue(key: RecoilProjectKey) {
  return useAtomValue(isRunStatusVisibleState(key));
}
