// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { memo, useRef } from 'react';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';

import { LcVisCanvas } from './LcVisCanvas';
import { LcVisEventHandler } from './LcVisEventHandler';
import { LcVisOverlayHandler } from './LcVisOverlayHandler';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
      zIndex: 1,
    },
    // <div> that wraps Renderer and the various overlays
    rendererRoot: {
      /* Explicitly set the position of this div to a non-static value.
         Otherwise, the children of this element will bypass this div when
         finding the containing block for absolute placement.

         Ref: // CSS2 9.3.2
         (https://www.w3.org/TR/CSS2/visuren.html#absolute-positioning)
         and CSS the definitive guide (4th ed), Ch 11. */
      position: 'relative',
      // Take over the remaining height of the viewport.
      flexGrow: 1,
      minHeight: 0,
      backgroundColor: colors.surfaceBackground,
    },
    // Renderer and the overlays.
    rendererChild: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      minHeight: 0,
      boxSizing: 'border-box',
      overflow: 'hidden',
      filter: `drop-shadow(2px 2px 1px ${colors.neutral100})`,
    },
  }),
  { name: 'LcVisManager' },
);

/**
 * A component which contains all lcvis-related dom elements, mounts the canvas and
 * overlays on page load, and runs any side effects related to lcvis rendering.
 */
export const LcVisManager = memo(() => {
  // == Hooks
  const classes = useStyles();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  return (
    <div className={classes.root}>
      <div className={classes.rendererRoot}>
        <LcVisCanvas ref={canvasRef} />
        <LcVisEventHandler className={classes.rendererChild} />
        <LcVisOverlayHandler className={classes.rendererChild} ref={canvasRef} />
      </div>
    </div>
  );
});
