// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import BoxOrSingleSelectSwitcher from './BoxOrSingleSelectSwitcher';
import SurfaceOrVolumeSelectDropdown from './SurfaceOrVolumeSelectDropdown';

/** A group of DataSelects to show in the Toolbar for setting selection options.  */
const SelectionControlBar = () => (
  <div
    data-locator="selection-control-bar"
    style={{
      display: 'flex',
      alignItems: 'center',
      flex: '1 1 auto',
    }}>
    <SurfaceOrVolumeSelectDropdown />
    <BoxOrSingleSelectSwitcher />
  </div>
);

export default SelectionControlBar;
