// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { colors } from '../../../lib/designSystem';
import { createStyles, makeStyles } from '../../Theme';
import { DocumentIcon } from '../../svg/DocumentIcon';

const useStyles = makeStyles(
  () => createStyles({
    cell: {
      backgroundColor: colors.neutral100,
      border: `1px solid ${colors.neutral100}`,
      borderRadius: '4px',
      cursor: 'pointer',
      maxWidth: '232px',
      maxHeight: '180px',
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      '&$disabled&$selected': {
        border: `1px solid ${colors.disabledType}`,
      },
    },
    emptyCell: {
      padding: '16px',
    },
    blankImg: {
      backgroundColor: colors.neutral0,
      color: colors.neutral800,
      borderRadius: '4px',
      height: '112px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    imgInner: {
      borderRadius: '4px',
      maxWidth: '100%',
      maxHeight: '112px',
    },
    imgOuter: {
      display: 'table-cell',
      width: '198px',
      height: '112px',
    },
    selected: {
      border: `1px solid ${colors.primaryInteractive}`,
    },
    disabled: {
      cursor: 'auto',
    },
  }),
  { name: 'ImageSelectionCell' },
);

export interface ImageCellData {
  // The image source URL.
  imgSrc: string;
  // The text above the image.
  text: string;
  // A description of the image.
  desc?: string;
}

export interface ImageSelectionCellProps {
  cellData: ImageCellData;
  selected: boolean;
  onClick: () => void;
  disabled: boolean;
}

const ImageSelectionCell = (props: ImageSelectionCellProps) => {
  const { disabled, cellData, selected } = props;
  const classes = useStyles();

  return (
    <div
      className={cx(classes.cell, {
        [classes.selected]: selected,
        [classes.disabled]: disabled,
      })}
      onClick={props.onClick}
      onKeyDown={props.onClick}
      role="button"
      tabIndex={-1}>
      {cellData.text}
      <div>
        <div className={classes.imgOuter}>
          {props.cellData.imgSrc ? (
            <img
              alt={cellData.text}
              className={classes.imgInner}
              crossOrigin="anonymous" // LC-17265 sample images are not same-origin.
              draggable={false}
              src={props.cellData.imgSrc}
            />
          ) : (
            <div className={classes.blankImg}>
              <DocumentIcon maxHeight={24} maxWidth={18} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ImageSelectionEmptyCell = () => {
  const classes = useStyles();

  return (
    <div className={cx(classes.emptyCell)}>
      <div className={classes.imgOuter} />
    </div>
  );
};

export default ImageSelectionCell;
