// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const DottedCircleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="dottedCircle"
      height={finalHeight}
      ref={ref}
      style={{
        fill: 'none',
        strokeWidth: 1.2,
        strokeDasharray: '2 2',
        stroke: getColor(0),
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <circle cx={baseWidth / 2} cy={baseHeight / 2} r={baseWidth / 2 - 2} />
    </svg>
  );
});
