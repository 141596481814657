// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import * as ProtoDescriptor from '../ProtoDescriptor';
import { getQuantityUnit } from '../QuantityDescriptor';
import { ParamInputOptions } from '../lib/componentTypes/form';
import { protoChoicesToRadioOptions, protoChoicesToSelectOptions } from '../lib/form';
import newInt from '../lib/intUtils';
import { fromBigInt } from '../lib/number';
import { Logger } from '../lib/observability/logs';
import * as basepb from '../proto/base/base_pb';
import { Metadata, TableType } from '../proto/table/table_pb';

import { DataSelect } from './Form/DataSelect';
import { InputDescription } from './Form/InputDescription';
import { AdNumberInput, NumberInput } from './Form/NumberInput';
import { RadioButtonGroup } from './Form/RadioButtonGroup';
import { TextInput } from './Form/TextInput';
import { AdVector3Input } from './Form/Vector3Input';
import { NumberSpinner } from './NumberSpinner';
import QuantityAdornment from './QuantityAdornment';
import { createStyles, makeStyles } from './Theme';
import { LuminaryToggleSwitch } from './controls/LuminaryToggleSwitch';
import { NumberSliderCombo } from './controls/NumberSliderCombo';
import { TableMapConfig, TableMapInput } from './controls/TableMapInput';

const logger = new Logger('ParamFieldInput');

const useStyles = makeStyles(
  () => createStyles({
    radioButtons: {
      padding: '4px 2px',
    },
  }),
  { name: 'ParamFieldInput' },
);

export enum MultipleChoiceDisplay {
  DROPDOWN, // Present a dropdown list
  RADIO, // Present radio buttons
}

// Options for working with TableMapInput
export type TableMapOptions = TableMapConfig;

export interface NumberOptions {
  inputType?: 'spinner' | 'slidercombo';
  max?: number;
  min?: number;
  step?: number;
}

export interface TextInputOptions {
  /** Prevent the text input field from committing the value when the field is blurred */
  disableCommitOnBlur?: boolean;
}

export interface InputOptions {
  verticalVectors?: boolean;
  showAxisLabel?: boolean;
  showUnits?: boolean;
  // Display multiple choice fields differently from the default (drop-downs)
  multipleChoiceDisplay?: MultipleChoiceDisplay;
  // When radio buttons are displayed, each radio button (and label) is laid out
  // vertically.  When this option is true, each option will instead be laid out
  // horizontally (wrapping, if necessary).
  horizontalRadioButtons?: boolean;
  // Where possible, expose help text directly rather than via a tooltip
  explicitHelp?: boolean;
  // Used when param type is TABLE
  tableMapOptions?: TableMapOptions;
  // Currently implemented for INTEGER data types to force a number spinner
  // instead of a plain input.
  numberOptions?: NumberOptions;
  /** Options for the param type uses a text input field */
  textInputOptions?: TextInputOptions;
}

export interface ParamFieldInputProps {
  /** When readOnly is true, the parameters cannot be changed. */
  readOnly: boolean;
  /** Parameter to render. */
  param: ProtoDescriptor.Param;
  // The current value of the parameter. The dynamic type of this field is that
  // of the corresponding proto field.
  // E.g., if the field is a double, it is basepb.AdFloatType.
  // If it is an integer, it is base.Int.
  value: any;
  /** Function to set the value of the parameter. */
  setValue: (newValue: any) => void;
  /** Special options to disable some choices. */
  options?: ParamInputOptions;
  /** Options for inputs; currently applies only to Vector3 inputs. */
  inputOptions?: InputOptions;
  // Currently required for table inputs
  projectId?: string;
  // Enabled choices based on the current set of selected choices
  enabledChoices?: ProtoDescriptor.Choice[];
  /** Callback for onFocus event for real and interger number input */
  onFocus?: () => void;
  /** Callback for onBlur event for real and interger number input */
  onBlur?: () => void;
  /** Callback for onChange event for real and interger number input */
  onChange?: (value: any) => void;
  /** Callback for onCommit event for real and interger number input */
  onCommit?: (value: any) => void;
}

// ParamFieldInput draws one input field, either numeric or multiple choice.
export const ParamFieldInput = (props: ParamFieldInputProps) => {
  const {
    param,
    readOnly,
    value,
    projectId,
    setValue,
    options,
    inputOptions = {},
    enabledChoices,
    onFocus,
    onBlur,
    onChange,
    onCommit,
  } = props;

  const {
    explicitHelp,
    multipleChoiceDisplay,
    numberOptions,
    showAxisLabel,
    showUnits = true,
    tableMapOptions,
    verticalVectors,
    textInputOptions,
  } = inputOptions;

  const classes = useStyles();
  const [warning, setWarning] = useState('');

  if (param.type === ProtoDescriptor.ParamType.MULTIPLE_CHOICE) {
    const choices = enabledChoices ?
      param.choices.filter((choice) => enabledChoices.includes(choice)) : param.choices;

    const disabledEnums = options?.disableEnums || [];

    if (multipleChoiceDisplay === MultipleChoiceDisplay.RADIO) {
      const radioOptions = protoChoicesToRadioOptions(choices, disabledEnums);
      return (
        <div className={classes.radioButtons}>
          <RadioButtonGroup
            disabled={readOnly}
            kind="secondary"
            name={param.name}
            onChange={setValue}
            options={radioOptions}
            value={value}
          />
        </div>
      );
    }

    // If the selected choice is not enabled then this means that we have a bug
    // in the param dependency management. Error in that case to ease debugging.
    // Also, make sure that this check is only active for valid enums.
    if (
      value &&
      value > 0 &&
      choices.length &&
      !choices.some((choice) => choice.enumNumber === value)
    ) {
      throw Error(`Invalid choice selected ${value}`);
    }

    const selectOptions = protoChoicesToSelectOptions(
      choices,
      value,
      { disabledEnums, explicitHelp },
    );

    return (
      <DataSelect
        asBlock
        disabled={readOnly}
        locator={param.name}
        onChange={setValue}
        options={selectOptions}
        size="small"
      />
    );
  }

  const { quantityType } = param;
  const units = (showUnits && quantityType) ? getQuantityUnit(quantityType) : '';

  const endAdornment = showUnits ?
    <QuantityAdornment quantity={quantityType} /> : null;

  switch (param.type) {
    case ProtoDescriptor.ParamType.BOOL:
      return (
        <LuminaryToggleSwitch
          disabled={readOnly}
          onChange={setValue}
          small
          value={value}
        />
      );
    case ProtoDescriptor.ParamType.STRING:
      return (
        <TextInput
          dataPrivate
          disabled={readOnly}
          endAdornment={units}
          onCommit={setValue}
          size="small"
          value={value}
          {...textInputOptions}
        />
      );
    case ProtoDescriptor.ParamType.REAL: {
      if (numberOptions?.inputType === 'slidercombo') {
        if ((numberOptions.max !== undefined) && (numberOptions.min !== undefined)) {
          return (
            <NumberSliderCombo
              disabled={readOnly}
              maximumValue={numberOptions.max}
              minimumValue={numberOptions.min}
              onChange={() => { }}
              onCommit={(newValue) => setValue(newValue as number)}
              step={numberOptions.step}
              value={value}
            />
          );
        }
        logger.warn('Slidercombo specificied without min & max');
      }
      return (
        <>
          <AdNumberInput
            asBlock
            disabled={readOnly}
            endAdornment={endAdornment}
            faultType={warning ? 'warning' : undefined}
            onBlur={() => {
              onBlur?.();
              setWarning('');
            }}
            onChange={(newVal: number) => {
              onChange?.(newVal);
              setWarning(ProtoDescriptor.checkBounds(param, newVal));
            }}
            onCommit={(newValue) => {
              if (!warning) {
                onCommit?.(newValue);
                setValue(newValue);
              }
            }}
            onFocus={onFocus}
            size="small"
            value={value}
            {...textInputOptions}
          />
          {warning && (
            <InputDescription faultType="warning" value={warning} />
          )}
        </>
      );
    }
    case ProtoDescriptor.ParamType.INT:
      if (numberOptions?.inputType === 'spinner') {
        return (
          <NumberSpinner
            disabled={readOnly}
            maximumValue={numberOptions.max}
            minimumValue={numberOptions.min}
            onCommit={(newValue: number) => setValue(newInt(Math.trunc(newValue)))}
            step={numberOptions.step}
            value={fromBigInt((value as basepb.Int).value)}
          />
        );
      }
      return (
        <NumberInput
          asBlock
          disabled={readOnly}
          endAdornment={endAdornment}
          onBlur={onBlur}
          onChange={onChange}
          onCommit={(newValue: number) => {
            const newIntValue = newInt(newValue);
            setValue(newIntValue);
            onCommit?.(newIntValue);
          }}
          onFocus={onFocus}
          size="small"
          value={fromBigInt((value as basepb.Int).value)}
          {...textInputOptions}
        />
      );
    case ProtoDescriptor.ParamType.VECTOR3:
      return (
        <AdVector3Input
          disabled={readOnly}
          onCommit={(newValue) => setValue(newValue)}
          quantityType={showUnits ? quantityType : undefined}
          showAxisLabel={showAxisLabel}
          value={value || new basepb.AdVector3()}
          vertical={verticalVectors}
        />
      );
    case ProtoDescriptor.ParamType.TABLE: {
      // projectId is required for table inputs and the actual table needs to be
      // read using RPC requiring projectId
      if (!projectId) {
        throw Error('projectId must be defined for ParamType.TABLE');
      }

      const {
        dialogTitle = 'Upload File',
        dialogSubtitle,
        tableDefinition = { axisLabels: [], recordLabels: [], tableType: TableType.INVALID },
        nameErrorFunc = () => '',
        tableMap = new Map<string, Metadata>(),
        tableErrorFunc,
        uploadOptions,
        uploadHelp,
        disableHelp,
        unlinkTooltip,
        gridPreview,
        dialogPreview,
      } = tableMapOptions || {};

      return (
        <TableMapInput
          dialogPreview={dialogPreview}
          dialogSubtitle={dialogSubtitle}
          dialogTitle={dialogTitle}
          disabled={readOnly}
          disableHelp={disableHelp}
          gridPreview={gridPreview}
          nameErrorFunc={nameErrorFunc}
          onChange={setValue}
          projectId={projectId}
          tableDefinition={tableDefinition}
          tableErrorFunc={tableErrorFunc}
          tableMap={tableMap}
          unlinkTooltip={unlinkTooltip}
          uploadHelp={uploadHelp}
          uploadOptions={uploadOptions}
          value={value || ''}
        />
      );
    }
    default:
      throw Error(`invalid param type: ${param}`);
  }
};
