// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { CurrentView } from '../../../../../lib/componentTypes/context';
import { createIncludeOption } from '../../../../../lib/output/formUtil';
import { isIncluded } from '../../../../../lib/outputNodeUtils';
import { isSimulationImplicitTime, isSimulationTransient } from '../../../../../lib/simulationUtils';
import { useOutput } from '../../../../../model/hooks/useOutput';
import * as simulationpb from '../../../../../proto/client/simulation_pb';
import { OutputIncludes } from '../../../../../proto/frontend/output/output_pb';
import * as feoutputpb from '../../../../../proto/frontend/output/output_pb';
import * as outputpb from '../../../../../proto/output/output_pb';
import { useInnerItersWindow } from '../../../../../recoil/useInnerItersWindow';
import { useCurrentView } from '../../../../../state/internal/global/currentView';
import Form from '../../../../Form';
import LabeledInput from '../../../../Form/LabeledInput';
import { PositiveAbsoluteIntegerInput } from '../../../../Form/PositiveAbsoluteIntegerInput';
import { useCommonMultiInputLines } from '../../../../Theme/commonStyles';
import Tooltip from '../../../../Tooltip';

export interface InnerIterationContentProps {
  jobActive: boolean;
  jobId: string;
  outputNode: feoutputpb.OutputNode;
  param: simulationpb.SimulationParam;
  projectId: string;
  toolTip: string;
}

export function InnerIterationContent(props: InnerIterationContentProps) {
  const { jobActive, jobId, outputNode, param, projectId, toolTip } = props;

  const currentView = useCurrentView();

  const nodeId = outputNode.id;
  const { updateOutputNode } = useOutput(projectId, nodeId);
  const showInnerOption = (
    currentView === CurrentView.ANALYSIS &&
    isSimulationTransient(param) &&
    isSimulationImplicitTime(param)
  );

  const [innerItersWindow, setInnerItersWindow] = useInnerItersWindow(projectId, jobId, nodeId);
  const multiInputClasses = useCommonMultiInputLines();

  return (
    <>
      {showInnerOption && (
        <LabeledInput label="">
          <div className={multiInputClasses.root}>
            <label className={multiInputClasses.line}>
              <div className={multiInputClasses.control}>
                <Form.CheckBox {...createIncludeOption(
                  [OutputIncludes.OUTPUT_INCLUDE_INNER],
                  '',
                  outputNode,
                  updateOutputNode,
                )}
                />
              </div>
              <Tooltip title={toolTip}>
                <div className={multiInputClasses.label}>Plot Inner Iterations</div>
              </Tooltip>
            </label>
            {isIncluded(outputNode, OutputIncludes.OUTPUT_INCLUDE_INNER) && (
              <>
                {!jobActive && (
                  <label className={multiInputClasses.line}>
                    <div className={multiInputClasses.input}>
                      <PositiveAbsoluteIntegerInput
                        onCommit={(wholeValue) => {
                          setInnerItersWindow(new outputpb.IterationRange({ end: wholeValue }));
                        }}
                        size="small"
                        value={innerItersWindow.end}
                      />
                    </div>
                    <Tooltip title="End time step for inner iterations plot.">
                      <div className={multiInputClasses.label}>End Time Step</div>
                    </Tooltip>
                  </label>
                )}
                <label className={multiInputClasses.line}>
                  <div className={multiInputClasses.input}>
                    <PositiveAbsoluteIntegerInput
                      onCommit={(wholeValue) => {
                        setInnerItersWindow(new outputpb.IterationRange({ begin: wholeValue }));
                      }}
                      size="small"
                      value={innerItersWindow.end - innerItersWindow.begin + 1}
                    />
                  </div>
                  <Tooltip title="Number of time steps to include in inner iterations plot.">
                    <div className={multiInputClasses.label}>Time Steps</div>
                  </Tooltip>
                </label>
              </>
            )}
          </div>
        </LabeledInput>
      )}
    </>
  );
}
