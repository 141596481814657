// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect } from 'react';

import { CurrentView } from '../../lib/componentTypes/context';
import { validExploration } from '../../lib/explorationUtils';
import { useControlPanelMode } from '../../recoil/useProjectPage';
import { useWorkflowMap } from '../../recoil/workflowState';
import { useCurrentView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useOnStartExplorationSetup, useUpdateControlPanelMode } from '../hooks/exploration/useCreateExploration';
import { DataTabs } from '../layout/Tabs';
import { TabConfig } from '../layout/Tabs/useDraggableTabs';

// Draw several buttons for switching between modes in the right pane.
const RightPaneSwitcher = () => {
  const [mode, setMode] = useControlPanelMode();
  const updateControlPanelMode = useUpdateControlPanelMode();
  const { isTreeModal } = useSelectionContext();
  const { projectId, workflowId } = useProjectContext();
  const currentView = useCurrentView();
  const workflowReply = useWorkflowMap(projectId, [workflowId])[workflowId];
  const onStartExplorationSetup = useOnStartExplorationSetup();
  const exploration = workflowReply?.config?.exploration;

  // Disable the exploration button if there is no valid exploration to view in analysis mode.
  const explorationDisabled = (
    currentView === CurrentView.ANALYSIS && !validExploration(exploration)
  );

  // Switch out of exploration mode if it is disabled.
  useEffect(() => {
    if (mode === 'exploration' && explorationDisabled) {
      setMode('simulation');
    }
  }, [mode, setMode, explorationDisabled]);

  const tabProps: TabConfig[] = [
    {
      disabled: isTreeModal,
      onClick: () => updateControlPanelMode('simulation'),
      foreground: mode === 'simulation',
      id: 'simulation',
      label: 'Simulation',
      help: 'Simulation Settings',
    },
    {
      disabled: isTreeModal || explorationDisabled,
      onClick: () => {
        onStartExplorationSetup();
        updateControlPanelMode('exploration');
      },
      foreground: mode === 'exploration',
      id: 'exploration',
      label: 'Exploration',
      help: 'Exploration Settings',
    },
  ];

  return <DataTabs tabs={tabProps} />;
};

export default RightPaneSwitcher;
