// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 15;
export const VideoCameraIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="videoCamera"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M22.5,1.5
           C22.08,1.5 21.705,1.665 21.435,1.935
           L18,5.385
           L18,1.5
           C18,0.675 17.325,0 16.5,0
           L1.5,0
           C0.675,0 0,0.675 0,1.5
           L0,13.5
           C0,14.325 0.675,15 1.5,15
           L16.5,15
           C17.325,15 18,14.325 18,13.5
           L18,9.615
           L21.435,13.05
           C21.705,13.335 22.08,13.5 22.5,13.5
           C23.325,13.5 24,12.825 24,12
           L24,3
           C24,2.175 23.325,1.5 22.5,1.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
