// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, ReactNode, useCallback, useEffect, useReducer, useRef } from 'react';

import cx from 'classnames';

import { useNextAnimationFrame } from '../../lib/useNextAnimationFrame';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      opacity: 0,
      transition: 'width var(--duration, 250ms) ease-out, opacity var(--duration, 250ms)',
      overflow: 'hidden',
      '&.open': {
        opacity: 1,
      },
    },
    content: {
      display: 'flex',
    },
  }),
  { name: 'Shutter' },
);

/**
 * In general, opening and closing a DOM node is straightforward, but animating the behavior
 * requires a bit of state management for a few reasons.
 *
 * - We want the dimension (width in this case) to be set to 'auto' nominally, so that when the
 *   container is open, its content flows with the rest of the document.  We don't want to prescibe
 *   a value for that dimension except while we're animating it, which requires transitioning from
 *   one numerical value to another (0 and the content's natural size).
 * - When the component is opened (by definition, from a closed state), its width should already be
 *   set to 0.  In this case, we just get the content's natural size (using node.scrollWidth in this
 *   case) and set the root width to the same value.  Once the animation is complete, we set the
 *   root width to 'auto' so that normal flow resumes.
 * - When the component is closed (by definition, from an opened state), its width should be 'auto',
 *   so we first need to set the root width to the content's natural size.  Then, in the next
 *   animation frame, we set the root size to 0, so that it can animate.
 * - There are some corner cases around the initial state (no animation) and around opening or
 *   closing while the previous animation is still in progress.
 *
 * To meet these requirements, this component uses the useReducer hook for state managmement.
 *
 * */

// This component has several lifecycle stages, each corresponding to where the component is in the
// animations/transitions.
type LifecycleStage =
  // In the 'initial' stage, we want to initialize the layout without any animation.
  'initial' |
  // In the 'opening' stage, the width is animating from 0 to the content's scroll width.
  'opening' |
  // In the 'opened' stage, the animation from the 'opening' stage is complete, and the width is set
  // to its final value of 'auto'.
  'opened' |
  // In the 'pre-closing' stage, we must set the width to the content's scroll width first, so that
  // it can start animating to 0 (auto -> 0 won't animate) in the next animation frame.
  'pre-closing' |
  // In the 'closing' stage, the width is animating from the content's scroll width to 0.
  'closing' |
  // In the 'closed' stage, the animation in 'closing' is complete, and the width remains 0.
  'closed';

interface LifecycleAction {
  type: LifecycleActionType;
}

// We can perform three actions on the state object.  Use 'open' to open the shutter; use 'close'
// to close the shutter, and use 'advance' to cycle to the next lifecycle stage.
type LifecycleActionType = 'open' | 'close' | 'advance';

function lifecycleReducer(stage: LifecycleStage, action: LifecycleAction): LifecycleStage {
  switch (action.type) {
    case 'open': {
      // Handle the 'open' action
      switch (stage) {
        case 'initial':
          // If we're starting from the 'initial' stage, go straight to 'opened', so that nothing
          // animates
          return 'opened';
        case 'pre-closing':
        case 'closing':
        case 'closed':
          // If we're in any of the closing stages, width should already be 0, so we can jump
          // straight to the 'opening' stage, where another numerical value (the content's natural
          // width) will be set for animation
          return 'opening';
        default: {
          // If we're here, that means we're already opened or opening, so there's nothing to do.
          // Fall-through to the end, where the current state is returned.
        }
      }
      break;
    }
    case 'close': {
      // Handle the 'close' action
      switch (stage) {
        case 'initial':
          // If we're starting from the 'initial' stage, go straight to 'closed', so that nothing
          // animates
          return 'closed';
        case 'opening':
          // If we're in the 'opening' stage, then we're already animating between two numerical
          // values.  Skip to 'closing' so that the width will get set back to 0.
          return 'closing';
        case 'opened':
          // If we're in the 'opened' stage, then the width will be 'auto', so we need to start at
          // the 'pre-closing' stage where the content's natural width is first set on the root
          // node.
          return 'pre-closing';
        default: {
          // If we're here, that means we're already closed or closing, so there's nothing to do.
          // Fall-through to the end, where the current state is returned.
        }
      }
      break;
    }
    case 'advance': {
      // Most of the stages are temporary and should be advanced to a 'next' state until its reached
      // 'opened' or 'closed'
      switch (stage) {
        case 'opening':
          return 'opened';
        case 'pre-closing':
          return 'closing';
        case 'closing':
          return 'closed';
        default: {
          // If we're here, that means we're in the initial state or we're done with any animations,
          // so there's nothing to do.  Fall-through to the end, where the current state is
          // returned.
        }
      }
      break;
    }
    default: {
      // no default, union type has been exhausted
    }
  }

  return stage;
}

export interface ShutterProps {
  open: boolean;
  transitionDuration?: number;
  fixedWidth?: number;
  children: ReactNode;
}

export const Shutter = (props: ShutterProps) => {
  const { fixedWidth, open, transitionDuration = 250 } = props;

  const contentRef = useRef<HTMLDivElement | null>(null);
  const [stage, dispatch] = useReducer(lifecycleReducer, 'initial');
  const classes = useStyles();
  const { requestNextFrame } = useNextAnimationFrame();

  // When the 'open' prop changes, dispatch the appropriate action to the stage reducer
  useEffect(() => {
    if (contentRef.current) {
      dispatch({ type: open ? 'open' : 'close' });
    }
  }, [open]);

  // When the stage changes, schedule an 'advance' action dispatch at some future time
  useEffect(() => {
    let tmtId: (ReturnType<typeof setTimeout> | null) = null;

    switch (stage) {
      case 'pre-closing': {
        // At pre-closing, we set the width to the content's natural width; from here, we want to
        // advance to the next stage (closing)--where the width will be set to 0 and animation can
        // begin--immediately.
        requestNextFrame(() => dispatch({ type: 'advance' }));
        break;
      }
      case 'opening':
      case 'closing': {
        // In the 'opening' or 'closing' stage, the root node's width is animating, so we advance
        // to the 'opened' or 'closed' stage, respectively, after the animation duration has
        // elapsed.
        tmtId = setTimeout(() => {
          dispatch({ type: 'advance' });
          tmtId = null;
        }, transitionDuration);
        break;
      }
      default: {
        // No default, because the remaining stages don't require advancement.
      }
    }

    // If the component unmounts while an action is scheduled, cancel the scheduled event.
    return () => {
      if (tmtId) {
        clearTimeout(tmtId);
      }
    };
  }, [transitionDuration, requestNextFrame, stage]);

  // Here we send the first dispatch only after the callback ref in the JSX has executed and we can
  // be sure that contentRef.current is populated.
  const initializeDomNode = useCallback((el: HTMLDivElement) => {
    if (!contentRef.current) {
      contentRef.current = el;
      dispatch({ type: open ? 'open' : 'close' });
    }
  }, [open]);

  const isOpenCss = () => ['opening', 'opened'].includes(stage);

  // Width is a function of 'stage' and 'contentRef'.
  const getWidth = () => {
    if (contentRef.current) {
      switch (stage) {
        case 'opening':
        case 'pre-closing':
          return `${contentRef.current.scrollWidth}px`;
        case 'closing':
        case 'closed':
          return '0';
        default: // no default
      }
    }
    return 'auto';
  };

  return (
    <div
      className={cx(classes.root, { open: isOpenCss() })}
      style={{ width: getWidth(), '--duration': `${transitionDuration}ms` } as CSSProperties}>
      <div
        className={classes.content}
        ref={(element) => element && initializeDomNode(element)}
        style={{ width: fixedWidth ? `${fixedWidth}px` : '' }}>
        {props.children}
      </div>
    </div>
  );
};
