// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { useRecoilCallback } from 'recoil';

import assert from '../../../lib/assert';
import { CurrentView } from '../../../lib/componentTypes/context';
import { EMPTY_VALUE } from '../../../lib/constants';
import { formatNumber, fromBigInt } from '../../../lib/number';
import * as feoutputpb from '../../../proto/frontend/output/output_pb';
import { useOutputNodes } from '../../../recoil/outputNodes';
import { useSelectedSolution } from '../../../recoil/selectedSolution';
import { useInTimeUnitsValue } from '../../../recoil/useInTimeUnits';
import { ScalarOutput, scalarResult } from '../../../recoil/useOutputResults';
import { useWorkflowState } from '../../../recoil/workflowState';
import { useCurrentView } from '../../../state/internal/global/currentView';
import LabeledInput from '../../Form/LabeledInput';
import OutputCell from '../../JobPanel/OutputCell';
import { CollapsibleNodePanel } from '../../Panel/CollapsibleNodePanel';
import { createStyles, makeStyles } from '../../Theme';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { BarChartIcon } from '../../svg/BarChartIcon';
import { ClockIcon } from '../../svg/ClockIcon';
import PropertiesSection from '../PropertiesSection';

import { EmptyPropPanel } from './Empty';

const useStyles = makeStyles(
  () => createStyles({
    titleIcon: {
      display: 'inline-block',
      width: '13px',
      height: '13px',
      marginLeft: '4px',
      marginRight: '4px',
      transform: 'translate(0px, 2px)',
    },
    outputsProps: {
      marginTop: '16px',
    },
    sectionTitle: {
      fontSize: '12px',
      fontWeight: 700,
      marginTop: '8px',
    },
    valueColumn: {
      textAlign: 'right',
    },
  }),
  { name: 'OutputsPropPanel' },
);

function getOutputHeaderText(inTimeUnits: boolean, time: number, iter: number) {
  if (inTimeUnits) {
    if (time) {
      return `${formatNumber(time)}s`;
    }
  } else if (iter) {
    return iter;
  }
  return EMPTY_VALUE;
}

type OutputSectionProps = {
  outputNode: feoutputpb.OutputNode,
  iteration: number,
}

const OutputSection = (props: OutputSectionProps) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const classes = useStyles();
  const outputId = props.outputNode.id;

  // LC-20789 and PR #16517 (LC-19718)
  // The scalarResult selector has some side effects which causes some Recoil state to constantly
  // update which causes this component to constantly re-render. To prevent these re-renders, we
  // use a snapshot of the Recoil state similar to the JobTable fix in the mentioned PR
  const [results, setResults] = useState<ScalarOutput[]>([]);
  const getOutputResults = useRecoilCallback(({ snapshot }) => () => snapshot.getPromise(
    scalarResult({
      projectId,
      workflowId,
      jobId,
      outputId,
      iteration: props.iteration,
    }),
  ), [projectId, workflowId, jobId, outputId, props.iteration]);

  useEffect(() => {
    getOutputResults().then((outputResults) => {
      setResults(outputResults);
    }).catch((error) => {
      throw error;
    });
  }, [getOutputResults, setResults]);

  const header = props.outputNode.name;
  const labelValueList: { label: string, value?: JSX.Element, key: string }[] = [];
  results.forEach((scalarOutput, index) => {
    let marginBottom = 0;
    // Add extra margin at the bottom if its the last item in the category
    if (index === results.length - 1) {
      marginBottom = 16;
    }
    const shortName = scalarOutput.shortName;
    const name = scalarOutput.name;
    labelValueList.push({
      label: shortName.length > 0 ? `${shortName}/${name}` : name,
      value: (
        <OutputCell
          key="single-output"
          marginBottom={marginBottom}
          marginTop={0}
          result={scalarOutput.baseValue}
          status={scalarOutput.status}
        />),
      key: `${outputId}-${scalarOutput.name}-${scalarOutput.shortName}`,
    });
  });
  return (
    <React.Fragment key={outputId}>
      {results.length > 0 && <div className={classes.sectionTitle}>{header}</div>}
      {labelValueList.map(({ label, value, key }) => (
        <LabeledInput help={label} key={key} label={label}>
          <div className={classes.valueColumn}>
            {value}
          </div>
        </LabeledInput>
      ))}
    </React.Fragment>
  );
};

// A container that shows all Outputs results for a particular simulation page.
// Previously we showed these results in a dedicated Outputs window on the left.
const OutputsResults = () => {
  // Hooks and contexts
  const classes = useStyles();
  const { projectId, workflowId, jobId } = useProjectContext();
  const workflow = useWorkflowState(projectId, workflowId);
  const inTimeUnits = useInTimeUnitsValue({ projectId, workflowId, jobId });
  const outputNodes = useOutputNodes(projectId, '', '')[0];

  const selectedSolution = useSelectedSolution(
    projectId,
    workflowId,
    jobId,
  );
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected outputs row');

  const job = workflow?.job[jobId]!;

  // If no solution file has been written yet (which means no solution is selected
  // in the time bar, i.e. selectedSolution ===  null) we use the data from the last
  // available iteration.
  const iter = fromBigInt(selectedSolution?.iter ?? job.latestIter);
  const time = selectedSolution?.time ?? job.latestTime;

  // Compute the output data categories.
  const outputDataCategories = outputNodes.nodes.map((outputNode) => (
    <OutputSection
      iteration={(iter)}
      key={`category-${outputNode.id}`}
      outputNode={outputNode}
    />
  ));

  const headerText = getOutputHeaderText(inTimeUnits, time, iter);
  const headerIcon = inTimeUnits ? (
    <ClockIcon maxHeight={13} maxWidth={13} />
  ) : (
    <BarChartIcon maxHeight={13} maxWidth={13} />
  );

  return (
    <PropertiesSection>
      <CollapsibleNodePanel
        heading={(
          <>
            <span>Outputs at</span>
            <div className={classes.titleIcon}>
              {headerIcon}
            </div>
            {headerText}
          </>
        )}
        nodeId={node.id}
        panelName="outputsPostProcessing">
        <div className={classes.outputsProps} data-locator="outputsResults">
          {outputDataCategories}
        </div>
      </CollapsibleNodePanel>
    </PropertiesSection>
  );
};

// A panel that is either empty (if we are in the Setup page) or that shows the Output results
// for a particular simulation.
export const OutputsPropPanel = () => {
  const currentView = useCurrentView();

  if (currentView !== CurrentView.ANALYSIS && currentView !== CurrentView.RESULTS) {
    return <EmptyPropPanel />;
  }

  return <OutputsResults />;
};
