// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 60;
const baseHeight = 59;
export const PointerBoxIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="pointerBox"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M58.883,52.518
           C59.591,53.151 59.963,54.454 59.963,55.496
           C59.963,57.134 58.288,58.809 56.65,58.809
           C55.607,58.809 54.304,58.437 53.709,57.73
           L42.056,46.152
           L38.892,58.847
           L28.245,28.878
           C27.661,27.079 28.131,26.463 30.032,27.092
           L60,37.739
           L47.305,40.866
           L58.883,52.518Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M39.725,4.89
           L4.736,4.89
           L4.736,39.878
           L22.231,39.878
           L29.653,39.782
           L31.278,44.568
           L22.231,44.615
           L0,44.615
           L0,0.153
           L44.461,0.153
           L44.461,29.848
           L39.725,28.152
           L39.725,4.89Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
