// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import cx from 'classnames';

import { ChevronLeftIcon } from '../../svg/ChevronLeftIcon';
import { ChevronRightIcon } from '../../svg/ChevronRightIcon';

export interface DirectionControlProps {
  direction: 'left' | 'right';
  onClick: () => void;
  enabled: boolean;
}

/**
 * In a paginated data set, the DirectionControl component is a button used to increment or
 * decrement the page number.
 */
export const DirectionControl = (props: DirectionControlProps) => {
  const { direction, enabled, onClick } = props;

  return (
    <div className={cx('pageControl', direction)}>
      <button
        className="controlButton"
        disabled={!enabled}
        onClick={onClick}
        type="button">
        {direction === 'left' ? (
          <ChevronLeftIcon maxHeight={8} maxWidth={8} />
        ) : (
          <ChevronRightIcon maxHeight={8} maxWidth={8} />
        )}
      </button>
    </div>
  );
};
