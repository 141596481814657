// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Any, createRegistry } from '@bufbuild/protobuf';
import { Code, ConnectError } from '@connectrpc/connect';
import { codeToString } from '@connectrpc/connect/protocol-connect';

import * as basepb from '../proto/base/base_pb';

import { Logger } from './observability/logs';
import { packProto, unpackProto } from './protoUtils';
import { upperFirst } from './text';

const logger = new Logger('status');

// ConnectError.metadata key that stores the base64-encoded base.Status.
const RPC_STATUS_DETAILS_HEADER_KEY = 'grpc-status-details-bin';

// Maps Luminary base.Subcode -> string.
const SUBCODE_NAMES: string[] = [
  'Unknown',
  'User not found',
];

// Translates a connectrpc status code to a string.
export function codeString(code: number): string {
  const str = codeToString(code);
  return str || `Error code ${code}`;
}

// Translates a base.Subcode to a string.
export function subcodeString(code: number): string {
  const str = SUBCODE_NAMES[code];
  return str || `Error subcode ${code}`;
}

// Structure of errors reported by Paraview jsonrpc.
export interface ParaviewError extends Error {
  code: number, // typically -32001
  message: string, // typically "Exception raised"
  data: {
    method: string, // E.g., "luminarycloud.syncnodes"
    exception?: string, // Python exception
    trace?: string // multi-line stack backtrace
  }
}

// Creates a new ConnectError object. subcode is typically basepb.Subcode.SUBCODE_UNKNOWN. Arg
// details is the list of details.
// If details is nonempty, its last element should be the topline message displayed on the screen.0
// See base.proto for more details about the encoding.
export function newGrpcError(
  code: number,
  message: string,
  subcode: basepb.Subcode,
  details: basepb.StatusPayloadDetail[],
): ConnectError {
  const metadata = new Headers();
  if (subcode || details.length) {
    const payload = new basepb.StatusPayload({ detail: details });
    if (subcode) {
      payload.subcode = subcode;
    }
    const packedPayload = packProto(payload);

    const status = new basepb.Status({ code, message, details: [packedPayload] });
    metadata.set(
      RPC_STATUS_DETAILS_HEADER_KEY,
      btoa(String.fromCharCode.apply(null, status.toBinary() as any)),
    );
  }
  return new ConnectError(message, code, metadata);
}

// Given an error from connectrpc, reconstruct the ConnectError object. It returns null if the error
// is not from connectrpc; that is, it doesn't conform to shape {code: number, message: string}.
export function getGrpcError(err: any): ConnectError | null {
  if (typeof err.code === 'number' && typeof err.message === 'string') {
    if (
      err.message.includes('Http response at 400 or 500 level') ||
      // This error is returned by the load balancer when jobmaster redeploys and goes down
      // temporarily.
      err.stack?.startsWith('Error: Unknown Content-type received.\n') ||
      err.message.includes('Unknown Content-type received.') ||
      // These ones were introduced with connect-es.
      err.message.includes('[unknown] missing trailer') ||
      err.message.includes('[unknown] Failed to fetch') ||
      err.message.includes('[unknown] network error')
    ) {
      // These errors have code UNKNOWN, but they happen when the server is unavailable. So
      // translate the code to UNAVAILABLE to allow the client to retry.
      const connErr = new ConnectError(err.rawMessage, Code.Unavailable, err.metadata);
      connErr.name = err.name;
      return connErr;
    }
    return err;
  }
  return null;
}

// Extract connectrpc.Code from the error. If the error is not from connectprc, it returns
// connectrpc.Code.Unknown.
export function getCode(err: Error): number {
  const gerr = getGrpcError(err);
  return gerr ? gerr.code : Code.Unknown;
}

// Extracts the basepb.Status proto object embedded in the error.  It returns null if the error is
// not from connectrpc.
export function toProto(err: Error): basepb.Status | null {
  const gerr = getGrpcError(err);
  if (!gerr) {
    return null;
  }
  if (!gerr.metadata) {
    logger.warn('connectrpc error null metadata err:', err, ' gerr:', gerr);
    return null;
  }
  const base64Payload = gerr.metadata.get(RPC_STATUS_DETAILS_HEADER_KEY);
  if (!base64Payload) {
    return null;
  }
  return basepb.Status.fromBinary(
    Uint8Array.from(atob(base64Payload), (char) => char.charCodeAt(0)),
  );
}

// Translates basepb.Status to an ConnectError object. The return value is null iff. status==null.
export function fromProto(status: basepb.Status | null): ConnectError | null {
  if (!status) {
    return null;
  }
  const data = btoa(String.fromCharCode.apply(null, status.toBinary() as any));
  const metadata = new Headers({ [RPC_STATUS_DETAILS_HEADER_KEY]: data });
  return new ConnectError(status.message, status.code, metadata);
}

function parsePayloadInProto(status: basepb.Status): basepb.StatusPayload | null {
  let found: basepb.StatusPayload | null = null;
  status.details.forEach((data: Any) => {
    if (data.is(basepb.StatusPayload)) {
      found = unpackProto(data, basepb.StatusPayload);
    }
  });
  return found;
}

// A helper for extracting components of basepb.Status.
export class Parser {
  readonly payload: basepb.StatusPayload | null = null;
  constructor(readonly proto: basepb.Status) {
    this.payload = parsePayloadInProto(proto);
  }

  // Reports a concise message that summarizes the error.
  headlineMessage(): string {
    let message: string;
    const detail = this.payload?.detail;
    if (!detail) {
      message = this.proto.message;
    } else {
      const last = detail[detail.length - 1];
      message = last.message;
    }
    const code = this.proto.code;
    if (code === Code.Unknown) {
      return message;
    }
    return `${upperFirst(codeString(code))}: ${message}`;
  }

  // Reports the message from the details that correspond to the headlineMessage, if available.
  headlineDetailsMessage(): string {
    const details = this.payload?.detail;
    if (!details) {
      return '';
    }
    if (details.length < 1) {
      return '';
    }
    return details[details.length - 1].message;
  }

  // Reports basepb.StatusPayload.subcode embedded in the error.  If subcode is not found, it
  // reports zero.
  subcode(): number {
    if (!this.payload) {
      return 0;
    }
    return this.payload.subcode;
  }

  // Reports the list of StatusPayloadDetails embedded in the error.  If no detail is found, it
  // reports an empty array. The caller shall not modify the returned object.
  details(): basepb.StatusPayloadDetail[] {
    if (!this.payload) {
      return [];
    }
    return this.payload.detail;
  }
}

// Produces a JSON string of the given error. Use this function instead of JSON.stringify, since
// most Error fields are owned.
export function stringifyError(err: any): string {
  return JSON.stringify(err, Object.getOwnPropertyNames(err));
}

export function getPayload(err: any): basepb.StatusPayload | null {
  const status = toProto(err);
  return status ? parsePayloadInProto(status) : null;
}

/** Returns true if the caller should retry the operation after a wait.  This function must be kept
    in sync with go/core/status/ShouldRetry and cc/base/ShouldRetry.
    NOTE: the Aborted error is used to tag certain GPU errors in the backend. This is used by
    nodemanager to retry certain jobs.
*/
export function shouldRetry(err: ConnectError) {
  if (
    err.code === Code.Unavailable ||
    err.code === Code.Aborted ||
    err.code === Code.DeadlineExceeded
  ) {
    return true;
  }
  if (err.code === Code.Canceled) {
    const pattern = 'grpc: the client connection is closing';
    if (err.message.includes(pattern)) {
      return true;
    }
    const payload = getPayload(err);
    if (payload) {
      const found = payload.detail.some(
        (detail: basepb.StatusPayloadDetail) => detail.message.includes(pattern),
      );
      if (found) {
        return true;
      }
    }
  }
  // These errors come from the protobufes/connectes fetch usage. We don't know how to fix them so
  // we are going to suppose that they are retriable and pray. The errors appear as follows:
  // [unknown] Load failed.
  if (err.code === Code.Unknown) {
    if (err.message.includes('Load failed') || err.message.includes('Error in input stream') ||
      err.message.includes('NetworkError when attempting to fetch')) {
      return true;
    }
  }
  return false;
}

// TODO(cmh) If we use TextDecoder (see ./stringarray.arrayToString), the result includes some
// characters that cause btoa() to throw an exception like "Uncaught DOMException: String contains
// an invalid character" For now, this inefficient method should suffice
export function binaryToString(b: Uint8Array): string {
  // Previous implementation built an array of characters and then joined with '', but in most
  // browsers, string concatentation is generally faster.  Plus, modern Javascript engines will
  // internally optimize string concatentation in a loop.
  return b.reduce((result, value) => result + String.fromCodePoint(value), '');
}

// Updates the message found in "err" so that it becomes a string of form
//
//     "{where}: {errormessage}"
//
// Details: If the error is not a connectrpc/absl error, then this function changes the error
// message to "{where}: {err}".
//
// If the error is a connectrpc/absl error, then it extracts the last (the most-high-level)
// StatusPayload.detail message from the error, and arranges to show "{where}: {lasterrormessage}".
export function forUser(err: any, where: string): ConnectError {
  const gerr = (
    getGrpcError(err) ||
    newGrpcError(
      Code.Unknown,
      stringifyError(err),
      basepb.Subcode.UNKNOWN,
      [],
    )
  );

  let status = toProto(gerr);
  if (!status) {
    status = new basepb.Status({ code: gerr.code, message: gerr.rawMessage });
  }
  let payload = parsePayloadInProto(status);
  if (!payload) {
    payload = new basepb.StatusPayload();
  }
  let message = status.message;
  const details = payload.detail;
  if (details && details.length > 0) {
    const lastDetail = details[details.length - 1];
    message = lastDetail.message;
  }
  const newDetail = new basepb.StatusPayloadDetail({ message, where });
  payload.detail.push(newDetail);

  const newData = packProto(payload);
  const newStatus = status.clone();
  newStatus.details = newStatus.details.filter((data: Any) => data.is(basepb.StatusPayload));
  newStatus.details.push(newData);
  const data = btoa(binaryToString(newStatus.toBinary()));

  return new ConnectError(
    gerr.rawMessage,
    gerr.code,
    new Headers({
      ...gerr.metadata,
      [RPC_STATUS_DETAILS_HEADER_KEY]: data,
    }),
    gerr.findDetails(createRegistry()),
    gerr.cause,
  );
}
