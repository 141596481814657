// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { AddOutputButton } from '../SimulationNodeAddButtons';
import { TreeRow } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'target' };

export const OutputContainerTreeRow = (props: SimulationRowProps) => (
  <TreeRow
    {...props}
    addControl={<AddOutputButton />}
    primaryIcon={PRIMARY_ICON}
  />
);
