// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atom as atomRecoil } from 'recoil';

import { ControlPanelMode } from '../lib/componentTypes/controlPanel';

import { useIsStarterPlan } from './useAccountInfo';

export const controlPanelModeState = atom<ControlPanelMode>('simulation');
export const controlPanelModeState_DEPRECATED = atomRecoil<ControlPanelMode>({
  key: 'controlPanelModeState',
  default: 'simulation',
});

export function useControlPanelMode() {
  return useAtom(controlPanelModeState);
}

export function useControlPanelModeValue() {
  return useAtomValue(controlPanelModeState);
}

export function useSetControlPanelMode() {
  return useSetAtom(controlPanelModeState);
}

export function useIsBaselineMode() {
  const [controlPanelMode] = useControlPanelMode();
  return controlPanelMode === 'simulation';
}

export function useIsExploration() {
  const isBaselineMode = useIsBaselineMode();
  return !isBaselineMode;
}

export function useIsExplorationStarterPlan() {
  const isInExploration = useIsExploration();
  const isInStarerPlan = useIsStarterPlan();
  return isInExploration && isInStarerPlan;
}

const motionFrameToDeleteState = atom<string>('');

export function useMotionFrameToDelete() {
  return useAtom(motionFrameToDeleteState);
}

export function useSetMotionFrameToDelete() {
  return useSetAtom(motionFrameToDeleteState);
}
