// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import assert from '../../../../lib/assert';
import { colors } from '../../../../lib/designSystem';
import {
  DEFAULT_GLOBAL_FRAME_ID,
  GLOBAL_FRAME_NAME,
  getOrCreateGlobalFrame,
  orderedFrames,
} from '../../../../lib/motionDataUtils';
import { SimulationTreeNode } from '../../../../lib/simulationTree/node';
import { wordsToList } from '../../../../lib/text';
import { volumeNodeId } from '../../../../lib/volumeUtils';
import { useEntityGroupMap } from '../../../../recoil/entityGroupState';
import { useSimulationParam } from '../../../../state/external/project/simulation/param';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { createStyles, makeStyles } from '../../../Theme';
import { useProjectContext } from '../../../context/ProjectContext';
import NodeLink from '../../NodeLink';

const useStyles = makeStyles(
  () => createStyles({
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '6px 0',
    },
    text: {
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '16px',
      color: colors.inputPlaceholderText,
      margin: '8px 0',
    },
    frameColumn: {
      flex: 1,
      color: colors.neutral800,
    },
    surfaceColumn: {
      flex: 2,
      textAlign: 'right',
    },
  }),
  { name: 'FrameSurfacePanel' },
);

export interface FrameSurfacePanelProps {
  node: SimulationTreeNode;
  /** The list of surface IDs */
  surfaces?: string[];
  /** The list of volume indexes */
  volumes?: string[];
}

interface FrameSpec {
  /** The ID of the frame */
  id: string;
  /** The name of the frame */
  name: string;
  /** The list of surface or volume IDs */
  geometries: string[];
}

/**
 * A properties panel which displays the related reference frames for the given geometry list.
 *
 * The given geometry must either be a list of surfaces IDs or volume indexes, but not both.
 */
export function FrameSurfacePanel(props: FrameSurfacePanelProps) {
  // Props
  const { node, surfaces, volumes } = props;
  assert(
    (!!surfaces && !volumes) || (!surfaces && !!volumes),
    'FrameSurfacePanel must have surfaces or volumes, but not both or neither at the same time',
  );

  // Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);

  // Hooks
  const classes = useStyles();

  // Must provide at least either an empty list of surfaces IDs or volumes indexes
  if (!surfaces && !volumes) {
    return null;
  }

  // The empty state of the panel
  let content = (
    <div className={classes.text}>
      {`Select ${surfaces ? 'surfaces' : 'volumes'} to see assigned frames.`}
    </div>
  );
  let collapsedContent = content;

  const allFrames = simParam.motionData;
  const currentFrames: FrameSpec[] = [];
  if (surfaces?.length || volumes?.length) {
    if (!allFrames.length) {
      // There are no frames, and the global has not been initialized yet.  Fake the global frame as
      // a special case:
      currentFrames.push({
        id: DEFAULT_GLOBAL_FRAME_ID,
        name: GLOBAL_FRAME_NAME,
        geometries: surfaces || volumes!.map((volumeIndex) => volumeNodeId(Number(volumeIndex))),
      });
    } else {
      const geometryMap = new Map<string, boolean>();
      if (surfaces) {
        surfaces.forEach((surface) => geometryMap.set(surface, false));
      } else {
        volumes?.forEach((volume) => geometryMap.set(volume, false));
      }
      orderedFrames(simParam).frames.forEach((frame) => {
        const attachedGeometry = surfaces ? frame.attachedBoundaries : frame.attachedDomains;
        const frameGeometries = attachedGeometry.filter((geometry) => {
          if (geometryMap.has(geometry)) {
            geometryMap.set(geometry, true);
            return true;
          }
          return false;
        });
        if (frameGeometries.length) {
          currentFrames.push({
            id: frame.frameId,
            name: frame.frameName,
            geometries: surfaces ?
              frameGeometries :
              frameGeometries.map((volumeIndex) => volumeNodeId(Number(volumeIndex))),
          });
        }
      });
      const leftOverGeometries = [...geometryMap].filter(([geometry, hasFrame]) => !hasFrame);
      if (leftOverGeometries.length) {
        // No frame had the required geometry. This means the geometries are part of the global
        // frame which should exist at this point
        const globalFrame = getOrCreateGlobalFrame(simParam);
        currentFrames.push({
          id: globalFrame.frameId,
          name: globalFrame.frameName,
          geometries: leftOverGeometries.map(([geometry]) => (surfaces ?
            geometry :
            volumeNodeId(Number(geometry)))),
        });
      }
    }
  }
  if (currentFrames.length) {
    content = (
      <>
        {currentFrames.map((frame) => (
          <div className={classes.row} key={frame.id}>
            <div className={classes.frameColumn}>{frame.name}</div>
            <div className={classes.surfaceColumn}>
              {frame.geometries.map((geometry, index) => (
                <React.Fragment key={geometry}>
                  <NodeLink
                    nodeIds={[geometry]}
                    text={entityGroupMap.get(geometry).name}
                  />
                  {index < frame.geometries.length - 1 ? ', ' : undefined}
                </React.Fragment>
              ))}
            </div>
          </div>
        ))}
      </>
    );
    collapsedContent = (
      <div className={classes.text}>
        {wordsToList(currentFrames.map((frame) => frame.name))}
      </div>
    );
  }

  return (
    <CollapsibleNodePanel
      collapsedContent={collapsedContent}
      heading="Reference Frames"
      nodeId={node.id}
      panelName="refframes">
      {content}
    </CollapsibleNodePanel>
  );
}
