// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useMemo } from 'react';

import { atomFamily, useRecoilStateLoadable } from 'recoil';

import { runtimeEstimate as runtimeEstimateFlag } from '../../flags';
import assert from '../../lib/assert';
import { fromBigInt } from '../../lib/number';
import { Logger } from '../../lib/observability/logs';
import { callRetry } from '../../lib/rpc';
import * as rpc from '../../lib/rpc';
import { isCompatible } from '../../lib/runtimeEstimation';
import { getSimulationParam } from '../../lib/simulationParamUtils';
import { updateStoppingConds } from '../../lib/stoppingCondsUtils';
import { debounce } from '../../lib/utils';
import { EstimateSimulationRunTimeRequest } from '../../proto/frontend/simulation/simulation_pb';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useOutputNodes } from '../../recoil/outputNodes';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useStoppingConditions } from '../../recoil/useStoppingConditions';
import { useCurrentConfig } from '../../recoil/workflowConfig';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useProjectContext } from '../context/ProjectContext';

const logger = new Logger('recoil/runtimeEstimation');

type RuntimeEstimate = {
  duration: number,
}

const runtimeEstimateState = atomFamily<RuntimeEstimate, string>({
  key: 'runtimeEstimate',
});

// Minimum time between updates of the runtime estimate state
const DEBOUNCE_PERIOD_MS = 1000;

export const useRuntimeEstimate = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  // Runtime estimate is a loadable so that rendering can continue while we update the state in
  // the background.
  const [
    runtimeEstimate,
    setRuntimeEstimate,
  ] = useRecoilStateLoadable(runtimeEstimateState(projectId));
  const experimentConfig = useEnabledExperiments();
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const simParam = useSimulationParam(projectId, workflowId, jobId);

  // Conditions that will limit display of runtime estimates
  // Currently must not be LMA and must be both steady state and RANS
  const isCompatibleSimulation = useMemo(() => isCompatible(simParam), [simParam]);

  const [stopConds] = useStoppingConditions(projectId, workflowId, jobId);
  const [outputNodes] = useOutputNodes(projectId, workflowId, jobId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  // Debounce the updates of the runtime estimate to not send rpcs too often.
  useEffect(
    () => debounce(async () => {
      if (experimentConfig.includes(runtimeEstimateFlag) && isCompatibleSimulation) {
        const params = getSimulationParam(config);
        // NOTE: we need a valid mesh URL in the params; otherwise, the backend will return an
        // error. Same with the amount of physics.
        if (params.input?.url && params.physics.length) {
          const newParams = updateStoppingConds(stopConds, params, outputNodes, entityGroupData);
          const req = new EstimateSimulationRunTimeRequest({
            simulationParam: newParams,
            projectId,
          });
          try {
            const reply = await callRetry(
              'EstimateSimulationRunTime',
              rpc.client.estimateSimulationRunTime,
              req,
            );
            const { estimate } = reply;
            assert(!!estimate, 'Estimate not available');
            setRuntimeEstimate({ duration: fromBigInt(estimate.seconds) / 60 });
            return;
          } catch (error) {
            logger.error(`Error in EstimateSimulationRunTimeRequest RPC, error=${error}`);
            setRuntimeEstimate({ duration: -1 });
            return;
          }
        }
      }
      setRuntimeEstimate({ duration: -1 });
    }, DEBOUNCE_PERIOD_MS)(),
    [
      config, experimentConfig, stopConds, outputNodes, projectId,
      isCompatibleSimulation, setRuntimeEstimate, entityGroupData,
    ],
  );
  return runtimeEstimate;
};
