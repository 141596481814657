// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 12;
export const BoxSelectIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="boxSelect"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M9.6,7.082
           L8.4,7.082
           L8.4,8.4
           L7.082,8.4
           L7.082,9.6
           L8.4,9.6
           L8.4,12
           L9.6,12
           L9.6,9.6
           L12,9.6
           L12,8.4
           L9.6,8.4
           L9.6,7.082Z
           M5.878,9.6
           L3.722,9.6
           L3.722,8.4
           L5.878,8.4
           L5.878,9.6Z
           M1.2,8.4
           L2.518,8.4
           L2.518,9.6
           L0,9.6
           L0,7.082
           L1.2,7.082
           L1.2,8.4Z
           M1.2,5.878
           L0,5.878
           L0,3.722
           L1.2,3.722
           L1.2,5.878Z
           M9.6,5.878
           L8.4,5.878
           L8.4,3.722
           L9.6,3.722
           L9.6,5.878Z
           M2.518,0
           L2.518,1.2
           L1.2,1.2
           L1.2,2.518
           L0,2.518
           L0,0
           L2.518,0Z
           M9.6,2.518
           L8.4,2.518
           L8.4,1.2
           L7.082,1.2
           L7.082,0
           L9.6,0
           L9.6,2.518Z
           M5.878,1.2
           L3.722,1.2
           L3.722,0
           L5.878,0
           L5.878,1.2Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
