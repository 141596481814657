// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const FileBoxIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="fileBox"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M6,10
           L10,10
           C10.55,10 11,9.55 11,9
           C11,8.45 10.55,8 10,8
           L6,8
           C5.45,8 5,8.45 5,9
           C5,9.55 5.45,10 6,10Z
           M15.93,5.63
           L15.93,5.61
           L13.94,0.63
           C13.78,0.26 13.42,0 13,0
           L3,0
           C2.58,0 2.22,0.26 2.07,0.63
           L0.08,5.61
           L0.07,5.63
           C0.03,5.74 0,5.87 0,6
           L0,15
           C0,15.55 0.45,16 1,16
           L15,16
           C15.55,16 16,15.55 16,15
           L16,6
           C16,5.87 15.97,5.74 15.93,5.63Z
           M9,2
           L12.32,2
           L13.52,5
           L9,5
           L9,2Z
           M3.68,2
           L7,2
           L7,5
           L2.48,5
           L3.68,2Z
           M14,14
           L2,14
           L2,7
           L14,7
           L14,14Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
