// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { findFrameById } from '../../../lib/motionDataUtils';
import { copiedName } from '../../../lib/name';
import { newNodeId } from '../../../lib/projectDataUtils';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { initializeNewNode, useSetNewNodes } from '../../../recoil/nodeSession';
import { useSelectionContext } from '../../context/SelectionManager';
import { useSimulationConfig } from '../useSimulationConfig';

/**
 * @returns a callback to duplicate the motion frame node with the given id.
 * If the frame being copied is the body frame, the new frame will not be the body frame, since
 * there can be only 1 body frame at a time.
 * */
export const useCopyFrame = () => {
  // == Contexts
  const { setSelection, setScrollTo } = useSelectionContext();

  // == Recoil
  const setNewNodes = useSetNewNodes();

  // == Hooks
  const { saveParamAsync } = useSimulationConfig();

  const duplicateFrame = useCallback(async (id: string) => {
    const copiedFrame = await saveParamAsync(
      (newParam) => {
        const frameToDuplicate = findFrameById(newParam, id);
        if (!frameToDuplicate) {
          return null;
        }
        const newFrame = frameToDuplicate.clone();
        newParam.motionData.push(newFrame);

        newFrame.frameId = newNodeId();
        newFrame.frameName = copiedName(frameToDuplicate.frameName);
        return newFrame;
      },
    );
    if (!copiedFrame) {
      return;
    }

    const { motionType, frameId: nodeId } = copiedFrame;
    const tags = motionType === simulationpb.MotionType.NO_MOTION ? [] : ['motion'];

    setNewNodes((oldValue) => [...oldValue, initializeNewNode(nodeId, tags)]);
    setSelection([nodeId]);
    setScrollTo({ node: nodeId });
  }, [saveParamAsync, setNewNodes, setScrollTo, setSelection]);

  return duplicateFrame;
};
