// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const TargetIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="target"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M22.5,10.5
           L22.365,10.5
           C21.705,5.925 18.09,2.31 13.5,1.65
           L13.5,1.5
           C13.5,0.675 12.825,0 12,0
           C11.175,0 10.5,0.675 10.5,1.5
           L10.5,1.635
           C5.925,2.295 2.31,5.91 1.65,10.5
           L1.5,10.5
           C0.675,10.5 0,11.175 0,12
           C0,12.825 0.675,13.5 1.5,13.5
           L1.65,13.5
           C2.31,18.09 5.91,21.705 10.5,22.365
           L10.5,22.5
           C10.5,23.325 11.175,24 12,24
           C12.825,24 13.5,23.325 13.5,22.5
           L13.5,22.365
           C18.075,21.705 21.69,18.09 22.35,13.5
           L22.5,13.5
           C23.325,13.5 24,12.825 24,12
           C24,11.175 23.325,10.5 22.5,10.5Z
           M13.47,19.35
           C13.395,18.6 12.78,18 12,18
           C11.22,18 10.605,18.6 10.53,19.35
           C7.575,18.75 5.235,16.425 4.65,13.47
           C5.4,13.395 6,12.78 6,12
           C6,11.22 5.4,10.605 4.65,10.53
           C5.235,7.575 7.575,5.25 10.53,4.65
           C10.605,5.4 11.22,6 12,6
           C12.78,6 13.395,5.4 13.47,4.65
           C16.425,5.235 18.75,7.575 19.35,10.53
           C18.6,10.605 18,11.22 18,12
           C18,12.78 18.6,13.395 19.35,13.47
           C18.765,16.425 16.425,18.75 13.47,19.35Z
           M12,9
           C10.35,9 9,10.35 9,12
           C9,13.65 10.35,15 12,15
           C13.65,15 15,13.65 15,12
           C15,10.35 13.65,9 12,9Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
