// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const PointerOutlinedTargetIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="pointerOutlinedTarget"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M21.115,7.636C20.748,8.868 19.777,9.839 18.545,10.206L18.545,13.091L16.363,13.091
           L16.363,10.205C15.132,9.839 14.161,8.868 13.794,7.636L10.909,7.636L10.909,5.455
           L13.794,5.455C14.161,4.223 15.132,3.252 16.364,2.885L16.364,0L18.546,0L18.545,2.885
           C19.777,3.252 20.748,4.223 21.115,5.455L24,5.455L24,7.636L21.115,7.636ZM19.091,6.545
           C19.091,5.642 18.358,4.909 17.455,4.909C16.551,4.909 15.818,5.642 15.818,6.545
           C15.818,7.449 16.551,8.182 17.455,8.182C18.358,8.182 19.091,7.449 19.091,6.545Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M3.712,6.531C3.712,6.531 14.571,17.173 14.571,17.173
           C14.888,17.484 14.985,17.954 14.817,18.364C14.652,18.769 14.261,19.036 13.825,19.043
           C13.821,19.05 13.818,19.053 13.818,19.054L6.625,19.054L1.851,23.691
           C1.537,23.997 1.07,24.085 0.666,23.914C0.262,23.743 0,23.347 0,22.909L0,8.07
           C0,6.164 2.328,5.197 3.705,6.525L3.712,6.531ZM11.146,16.872
           C8.035,13.823 2.191,8.095 2.191,8.095C2.185,8.09 2.183,8.076 2.182,8.07L2.182,20.328
           L5.422,17.18C5.625,16.982 5.898,16.872 6.182,16.872L11.146,16.872Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
