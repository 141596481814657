// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

/** Breakpoint for when a Vector 3 input should change from vertically stacked to horizontal */
export const VECTOR3_BREAK_POINT_WIDTH = 275;

export function checkBreakpointVertical(
  vertical: boolean | undefined,
  width: number,
  limit: number,
) {
  return (vertical === undefined) ? width < limit : vertical;
}

/**
 * Check if two DOMRects overlap.
 * @param rectA The first DOMRect.
 * @param rectB The second DOMRect.
 * @returns True if the two DOMRects overlap, false otherwise.
 */
export function rectsOverlap(rectA: DOMRect, rectB: DOMRect) {
  return !(
    rectA.right < rectB.left ||
    rectA.left > rectB.right ||
    rectA.bottom < rectB.top ||
    rectA.top > rectB.bottom
  );
}
