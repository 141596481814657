// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 107;
const baseHeight = 120;
export const ShieldIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="shield"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M52.69,119.839
           C52.912,119.954 53.212,120 53.503,120
           L53.519,119.985
           C53.818,119.985 54.109,119.938 54.332,119.824
           C64.322,114.759 73.752,108.851 82.046,101.284
           C96.663,87.932 105.356,71.603 106.43,51.858
           C106.878,43.702 106.848,35.527 106.817,27.351
           C106.804,23.912 106.79,20.473 106.814,17.035
           C106.829,15.593 106.361,14.342 104.988,13.559
           C103.857,12.917 102.743,12.239 101.628,11.562
           C99.359,10.183 97.088,8.803 94.675,7.712
           C82.299,2.11 69.079,0.353 55.613,0.031
           L55.568,0.03
           C54.87,0.015 54.187,0 53.503,0
           C52.805,0 52.099,0.015 51.393,0.031
           C37.927,0.361 24.707,2.11 12.308,7.727
           C9.911,8.808 7.635,10.191 5.361,11.574
           C4.244,12.253 3.126,12.932 1.996,13.575
           C0.63,14.357 0.17,15.608 0.17,17.051
           C0.198,20.553 0.188,24.058 0.178,27.563
           C0.155,35.675 0.132,43.787 0.576,51.874
           C1.651,71.618 10.344,87.947 24.976,101.3
           C33.255,108.866 42.685,114.774 52.69,119.839Z
           M54.163,109.433
           C53.987,109.518 53.756,109.564 53.503,109.564
           L53.472,109.564
           C53.242,109.564 52.989,109.518 52.813,109.433
           C44.564,105.244 36.769,100.364 29.917,94.109
           C17.833,83.075 10.643,69.592 9.761,53.278
           C9.386,46.513 9.411,39.722 9.436,32.931
           C9.446,30.123 9.456,27.315 9.439,24.51
           C9.423,23.328 9.807,22.277 10.942,21.64
           C11.86,21.115 12.764,20.565 13.669,20.015
           C15.569,18.858 17.467,17.702 19.475,16.798
           C29.702,12.17 40.621,10.712 51.746,10.451
           C52.322,10.421 52.912,10.421 53.488,10.421
           C53.776,10.421 54.067,10.428 54.359,10.436
           C54.65,10.444 54.942,10.451 55.23,10.451
           C66.355,10.712 77.274,12.17 87.501,16.798
           C89.474,17.693 91.329,18.82 93.185,19.948
           C94.129,20.522 95.073,21.096 96.033,21.64
           C97.169,22.284 97.537,23.32 97.537,24.51
           C97.517,27.374 97.525,30.239 97.534,33.104
           C97.555,39.833 97.575,46.561 97.215,53.278
           C96.318,69.592 89.143,83.082 77.058,94.109
           C70.222,100.371 62.427,105.244 54.163,109.433Z
           M54.954,20.182
           C54.47,20.151 53.979,20.151 53.496,20.151
           L53.511,20.143
           C53.028,20.159 52.537,20.174 52.053,20.174
           C40.659,20.45 32.235,22.093 24.731,25.484
           C23.211,26.183 21.692,27.096 20.073,28.086
           C19.966,28.151 19.859,28.216 19.752,28.281
           C19.431,28.477 19.11,28.673 18.777,28.868
           C18.787,30.335 18.783,31.808 18.78,33.268
           C18.778,33.993 18.777,34.714 18.777,35.429
           C18.746,41.422 18.731,47.607 19.053,53.585
           C19.759,66.622 25.214,77.603 35.703,87.196
           C40.521,91.601 46.36,95.591 53.504,99.358
           C60.647,95.591 66.486,91.601 71.304,87.196
           C81.793,77.612 87.249,66.607 87.954,53.585
           C88.263,47.841 88.247,41.913 88.233,36.161
           C88.232,35.919 88.231,35.678 88.23,35.437
           L88.23,28.876
           C87.897,28.68 87.576,28.485 87.255,28.289
           C87.148,28.224 87.042,28.159 86.934,28.093
           C85.315,27.103 83.796,26.175 82.276,25.492
           C74.772,22.085 66.355,20.458 54.954,20.182Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
