// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties } from 'react';

import { ringPath } from '../../lib/svg';
import { createStyles, makeStyles } from '../Theme';

const DEFAULT_SIZE = 100;
const DEFAULT_STROKE_RATIO = 0.05;
const DEFAULT_ROTATION_DURATION = 1000;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      animationName: '$spin',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
  { name: 'CircularLoader' },
);

export interface CircularLoaderProps {
  size?: number;
  strokeRatio?: number;
  // Duration in milliseconds for a full 360-degree rotation, defaults to 1000
  duration?: number;
}

export const CircularLoader = (props: CircularLoaderProps) => {
  const {
    size = DEFAULT_SIZE,
    strokeRatio = DEFAULT_STROKE_RATIO,
    duration = DEFAULT_ROTATION_DURATION,
  } = props;

  const classes = useStyles();

  // Nominal path is based on default size (see svg viewBox attribute below).  The only variable
  // is the strokeRatio.  Overall scaling is handled by setting svg height/width to
  const path = ringPath(DEFAULT_SIZE, strokeRatio);

  return (
    <div
      className={classes.root}
      style={{
        '--size': `${size}px`,
        animationDuration: `${duration}ms`,
      } as CSSProperties}>
      <svg
        height={size}
        viewBox="0 0 100 100"
        width={size}>
        <defs>
          <linearGradient gradientTransform="rotate(90)" id="luminaryGradient">
            <stop offset="5%" stopColor="var(--color-purple-700)" />
            <stop offset="50%" stopColor="var(--color-purple-500)" />
            <stop offset="95%" stopColor="var(--color-purple-200)" />
          </linearGradient>
        </defs>
        <path
          d={path}
          fill="url('#luminaryGradient')"
          strokeWidth="0"
        />
      </svg>
    </div>
  );
};
