// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 83;
const baseHeight = 110;
export const VerticalPlanesFilledDoubleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="verticalPlanesFilledDouble"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M83,29.85L29.997,0L29.997,17.951L60.003,34.849L60.003,87.088L83,99.99L83,29.85Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M0,9.999L53.003,39.849L53.003,109.989L0,80.253L0,9.999Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
