// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { xxhash32 } from 'hash-wasm';

import * as outputpb from '../../proto/output/output_pb';
import * as refvaluepb from '../../proto/output/reference_values_pb';
import { sendOutputRequest } from '../../recoil/useOutputResults';

export async function computeOutputsReply(
  projectId: string,
  jobId: string,
  outputList: outputpb.Output[],
  refValues: refvaluepb.ReferenceValues,
) {
  const key = (
    await Promise.all(
      [
        ...outputList.map((output) => xxhash32(output.toBinary())),
        xxhash32(refValues.toBinary()),
        xxhash32(jobId),
      ],
    )
  ).reduce((previous, current) => previous + current);

  // We always want a separate queue and request so we choose the same key as rpc and output key
  return sendOutputRequest(
    key,
    key,
    projectId,
    jobId,
    outputList,
    refValues,
  );
}
