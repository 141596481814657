// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

// Adapted from https://github.com/open-telemetry/opentelemetry-js-contrib/blob/main/metapackages/auto-instrumentations-web/README.md
import { CloudPropagator } from '@google-cloud/opentelemetry-cloud-trace-propagator';
import { Context, Tracer as OTelTracer, Span, SpanOptions, TimeInput, context, trace } from '@opentelemetry/api';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { CompositePropagator, W3CTraceContextPropagator } from '@opentelemetry/core';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { SpanExporter } from '@opentelemetry/sdk-trace-base';
import { BatchSpanProcessor, InMemorySpanExporter, WebTracerProvider } from '@opentelemetry/sdk-trace-web';

import { getLcUserId } from '../jwt';
import { sessionUrl } from '../logrocketSetup';
import * as random from '../random';

import { SESSION_ID, localhost, resource } from './constants';

const otelExporterOtlpTracesEndpoint = `https://${window.location.hostname}/v1/traces`;

const provider = new WebTracerProvider({
  resource,
});
let exporter: SpanExporter = new OTLPTraceExporter({
  url: otelExporterOtlpTracesEndpoint,
});
// To reduce noise when running on localhost, just keep traces in memory and don't send them
// anywhere.
if (window.location.hostname === localhost) {
  exporter = new InMemorySpanExporter();
}
export const spanProcessor = new BatchSpanProcessor(exporter);

provider.addSpanProcessor(spanProcessor);
provider.register({
  contextManager: new ZoneContextManager(),
  propagator: new CompositePropagator({
    propagators: [
      new W3CTraceContextPropagator(),
      new CloudPropagator(),
    ],
  }),
});

registerInstrumentations({ instrumentations: [getWebAutoInstrumentations()] });

const sessionTracer = trace.getTracer('Session');

// Start a span to trace the whole browser session.
// This span is never explicitly ended since we can't synchronously flush the endSpan event
// on page unload (this is a known issue with OpenTelemetry JS):
// https://github.com/open-telemetry/opentelemetry-js/issues/2613
// This span will just show as a span with 'missing ID'
// in the trace explorer (none of its attributes, events, name, etc will be accessible).
// But it is still useful since it contains all the other spans during the session.
const sessionSpan = sessionTracer.startSpan(`Session start.`);

/**
 * The Context corresponding to the active browser session span.
 * This can be used to create nested spans with the active session span as the parent.
 */
const browserSessionSpanContext = trace.setSpan(context.active(), sessionSpan);

type SpanContent = {
  name: string,
  spanOptions?: SpanOptions,
}

/**
 * A class to manage creating spans in the frontend. It accepts a scope and a parent Tracer.
 * If a parent Tracer is provided, all spans started with this Tracer will be children of the
 * parent Tracer's active span.
 *
 * There can only be 1 active span per Tracer. If a new span is started while there is already an
 * active span, the active span will be ended before starting the new span.
 */
export class Tracer {
  readonly scope: string;
  readonly tracer: OTelTracer;
  readonly id = random.string(32)
  activeSpan: Span | null = null;

  readonly parent?: Tracer;
  readonly needsParentSpan: boolean;

  deferredSpan: SpanContent | null = null;
  children: Map<string, Tracer> = new Map();

  /**
   * @param scope the scope of the Tracer.
   * @param parent the parent Tracer. If provided, all spans started with this Tracer will be
   * children of the parent Tracer's active span.
   * @param needsParentSpan if true, then when startSpan is called, if the immediate parent Tracer
   * does not have an active span, the new span will not be started. Instead, it will be deferred to
   * start once the parent Tracer has an active span. If endActiveSpan is called before the
   * parent Tracer has an active span, the deferred span will be dropped.
   * We use this to handle cases where we may call startSpan before its intended parent span starts,
   * e.g. in useEffects, where the child component's useEffect is called before the parent's.
   */
  constructor(scope: string, parent?: Tracer, needsParentSpan = false) {
    this.scope = scope;
    this.tracer = trace.getTracer(scope);
    this.parent = parent;
    this.needsParentSpan = needsParentSpan;
    if (this.needsParentSpan && !this.parent) {
      throw new Error('If needsParentSpan is true, a parent Tracer must be provided.');
    }
    if (this.parent) {
      this.parent.children.set(this.id, this);
    }
  }

  /**
   * Get the current context. If there is an active span, return the context with the active span.
   * If there is no active span, return the parent's context. If there is no parent, return the
   * browser session span context.
   */
  private getContext(): Context {
    if (this.activeSpan) {
      return trace.setSpan(context.active(), this.activeSpan);
    }
    return this.getParentContext();
  }

  /**
   * Get the parent context for this Tracer. This is the context that will be used to start new
   * spans so that they are children of the parent's active span.
   *
   * @returns the context of the first ancestor Tracer that has an active span, or the browser
   * session span context if there is no parent.
   */
  private getParentContext(): Context {
    if (!this.parent) {
      return browserSessionSpanContext;
    }
    return this.parent.getContext();
  }

  /**
   * Defer starting a span until the parent Tracer has an active span.
   */
  private deferStartSpan(spanContent: SpanContent) {
    this.deferredSpan = spanContent;
  }

  /**
   * Start a deferred span if there is one.
   */
  private startDeferredSpan() {
    if (this.deferredSpan) {
      this.startSpan(this.deferredSpan.name, this.deferredSpan.spanOptions);
      this.deferredSpan = null;
    }
  }

  /**
   * Start a new span with the provided name. The span will be a child of the parent's active span.
   * If the parent has no active span, the span will be a child of that parent's parent's active
   * span, and so on. If there is no parent, the span will be a child of the browser session span.
   *
   * We only allow one span to be active at a time. If there is already an active span, this method
   * will end that span before starting the new span.
   *
   * @param name the name of the span.
   * @param options the options for the span.
   * @returns the span that was started.
   */
  startSpan(name: string, options?: SpanOptions): Span | null {
    this.endActiveSpan();
    // if this Tracer needs a parent span and its parent doesn't have an active span, defer starting
    // the span until the parent has an active span.
    if (this.needsParentSpan && !this.parent!.activeSpan) {
      this.deferStartSpan({ name, spanOptions: options });
      return null;
    }
    const newOptions = {
      ...options,
      attributes: {
        ...options?.attributes,
        sessionId: SESSION_ID,
        userId: getLcUserId(),
        logrocketSessionUrl: sessionUrl(),
      },
    };
    // use the parent context to start the new span
    const newSpan = context.with(
      this.getParentContext(),
      () => this.tracer.startSpan(name, newOptions),
    );
    const temp = newSpan.end;
    newSpan.end = (endTime?: TimeInput | undefined) => {
      temp.call(newSpan, endTime);
      this.activeSpan = null;
    };
    this.activeSpan = newSpan;
    // now that this Tracer has an active span, if any of its children have deferred spans, we
    // can start them now.
    this.children.forEach((child) => {
      child.startDeferredSpan();
    });
    return newSpan;
  }

  /**
   * End the active span for this Tracer. If there is no active span, do nothing. This can also be
   * done by calling the end() method on the active span directly.
   */
  endActiveSpan() {
    if (this.activeSpan) {
      this.activeSpan.end();
      this.activeSpan = null;
    }
    this.deferredSpan = null;
    this.children.forEach((child) => {
      // End the active span for any children which require a parent span.
      if (child.needsParentSpan) {
        child.endActiveSpan();
      }
    });
  }
}
