// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import AuthForm, { DataValues } from '../../components/Form/AuthForm';
import { AuthPageLayout, useAuthPageLayoutStyles } from '../../components/layout/page/AuthPageLayout';
import { doRecoveryLogin } from '../../lib/AuthMFA';
import { handleAuthenticatedV2 } from '../../lib/AuthV2';
import { getUserErrorMessage } from '../../lib/errors';
import { routes } from '../../lib/navigation';
import { isStorybookEnv } from '../../lib/testing/utils';

import Setup2FABackup from './Setup2FABackup';

export enum PageState {
  FORM,
  DONE,
}

interface LoginBackupProps {
  // Optional initial page state. This should be used ONLY for the storybook
  initialPageState?: PageState;
  // Optional initial recovery code. This should be used ONLY for the storybook
  initialRecoveryCode?: string
}

// This page will be used if the user has entered their password correctly but doesn't have access
// to their mobile device with their configured MFA. In that case they must provide their
// recovery/backup code.
// Once they do provide a correct backup code, they will be presented with a new backup code for
// their next login (if they do not reset their MFA in the settings) and when they click "continue",
// they will be redirected to the app.
const LoginBackup = (props: LoginBackupProps) => {
  // Props
  const { initialPageState = PageState.FORM, initialRecoveryCode } = props;

  // Hooks
  const authClasses = useAuthPageLayoutStyles();
  const location = useLocation();
  const navigate = useNavigate();

  // State
  const [pageState, setPageState] = useState(initialPageState);
  const [submitting, setSubmitting] = useState(false);
  const [fieldsError, setFieldsError] = useState<DataValues>({});
  const [loginRecoveryData, setLoginRecoveryData] = useState({
    newRecoveryCode: initialRecoveryCode || '',
    idToken: '',
  });

  // Handlers
  const handleSubmit = async (data: DataValues) => {
    if (!location.state?.mfaToken) {
      navigate(routes.login, {
        state: {
          error: 'Authentication error: missing MFA token for recovery login',
        },
      });
      return;
    }

    setFieldsError({});
    setSubmitting(true);

    try {
      const reply = await doRecoveryLogin(location.state?.mfaToken, data.code.replace(/[ -]/g, ''));
      setSubmitting(false);
      // Keep the reply data so we can reuse it the next steps
      setLoginRecoveryData({
        newRecoveryCode: reply.newRecoveryCode,
        idToken: reply.loginToken,
      });
      setPageState(PageState.DONE);
    } catch (err) {
      setSubmitting(false);
      setFieldsError({ code: getUserErrorMessage(err) });
    }
  };

  useEffect(() => {
    if (!isStorybookEnv() && (
      props.initialPageState !== undefined || props.initialRecoveryCode !== undefined
    )) {
      throw Error('Do not use the initialPageState/initialRecoveryCode outside of storybook env.');
    }
  }, [props.initialPageState, props.initialRecoveryCode]);

  if (pageState === PageState.DONE) {
    return (
      <Setup2FABackup
        onContinue={() => {
          // When we pass the idToken to the handleAuthenticatedV2, it will set the AuthState to
          // authenticated. This will be interecpted by the main useEffect in the index.tsx and
          // the user will be redirected to the app.
          handleAuthenticatedV2(loginRecoveryData.idToken).catch(() => { });
        }}
        recoveryCode={loginRecoveryData.newRecoveryCode}
      />
    );
  }

  return (
    <AuthPageLayout
      back
      subtitle={(
        <>
          To help keep your account safe, enter your one-time backup code to verify your identity
          and log into your account.
        </>
      )}
      title="Enter your security backup code">
      <div className={authClasses.secretSection}>
        Don&apos;t have access to your device or backup code?<br />
        Contact your system admin to reset 2FA
      </div>

      <AuthForm
        fields={[
          {
            asBlock: true,
            autofocus: true,
            label: 'Backup Code',
            disabled: submitting,
            name: 'code',
            placeholder: '0 0 0 0 - 0 0 0 0 - 0 0 0 0 - 0 0 0 0 - 0 0 0 0 - 0 0 0 0',
            required: true,
          },
        ]}
        fieldsError={fieldsError}
        onSubmit={handleSubmit}
        resetFieldsOnError={['code']}
        submit={{
          disabled: submitting,
          showSpinner: submitting,
          label: 'Continue',
        }}
      />
    </AuthPageLayout>
  );
};

export default LoginBackup;
