// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 332;
const baseHeight = 332;
export const GridIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="grid"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M332,33.2
           C332,14.876 317.124,0 298.8,0
           L33.2,0
           C14.876,0 0,14.876 0,33.2
           L0,298.8
           C0,317.124 14.876,332 33.2,332
           L298.8,332
           C317.124,332 332,317.124 332,298.8
           L332,33.2Z
           M100,232
           L32,232
           L32,300
           L100,300
           L100,232Z
           M200,232
           L132,232
           L132,300
           L200,300
           L200,232Z
           M300,232
           L232,232
           L232,300
           L300,300
           L300,232Z
           M100,132
           L32,132
           L32,200
           L100,200
           L100,132Z
           M200,132
           L132,132
           L132,200
           L200,200
           L200,132Z
           M300,132
           L232,132
           L232,200
           L300,200
           L300,132Z
           M100,32
           L32,32
           L32,100
           L100,100
           L100,32Z
           M200,32
           L132,32
           L132,100
           L200,100
           L200,32Z
           M300,32
           L232,32
           L232,100
           L300,100
           L300,32Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
