// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { StatusCardProps } from '../../../lib/statusCardProps';

const statusCardPropsState = atomFamily(
  (projectId: string) => atom<StatusCardProps>({ isVisible: false }),
);

export const useStatusCardProps = (
  projectId: string,
) => useAtom(statusCardPropsState(projectId));

export const useSetStatusCardProps = (
  projectId: string,
) => useSetAtom(statusCardPropsState(projectId));

export const useStatusCardPropsValue = (
  projectId: string,
) => useAtomValue(statusCardPropsState(projectId));
