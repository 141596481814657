// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, ReactNode } from 'react';

import cx from 'classnames';

import { FaultType } from '../../lib/componentTypes/form';
import { colors } from '../../lib/designSystem';
import { parseString } from '../../lib/html';
import { createStyles, makeStyles } from '../Theme';
import { useCommonPropPanelRowStyles } from '../Theme/commonStyles';
import { TaggedTooltip } from '../Tooltip/TaggedTooltip';
import { EarlyAccessLink } from '../common/EarlyAccessLink';
import { WarningIcon } from '../svg/WarningIcon';

import Label from './Label';

const useStyles = makeStyles(
  () => createStyles({
    label: {
      color: colors.lowEmphasisText,
      fontSize: '13px',
      fontWeight: 400,
      margin: 0,
      overflow: 'hidden',
    },
    labelCell: {
      paddingLeft: 'calc(16px * var(--nest-level, 0))',
      display: 'flex',
      overflow: 'auto',
      '&:hover': {
        overflow: 'visible',
      },
    },
    labelContainer: {
      display: 'flex',
      overflow: 'hidden',
      gap: '6px',
    },
    labelIcon: {
      flex: '0 0 auto',
    },
    labelText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'LabeledInput' },
);

interface LabelCellProps {
  label: string | JSX.Element;
  help?: string;
  nestLevel?: number;
  suppressTooltip?: boolean;
  faultType?: FaultType;
  earlyAccess?: boolean;
}

// Same as LabeledInput, but not a complete row.
const LabelCell = (props: LabelCellProps) => {
  const { earlyAccess, faultType, label, help, suppressTooltip } = props;
  const classes = useStyles();
  const nestLevel = props.nestLevel || 0;

  const sourceTooltip = suppressTooltip ? '' : parseString(help);
  const innerRef = React.useRef<HTMLDivElement>(null);
  const outerRef = React.useRef<HTMLDivElement>(null);

  return (
    <div
      className={classes.labelCell}
      data-locator="labeledInputLabelCell"
      ref={outerRef}
      style={{ '--nest-level': `${nestLevel}` } as CSSProperties}>
      <Label className={classes.label}>
        <div
          className={classes.labelContainer}
          ref={innerRef}>
          <TaggedTooltip
            innerRef={innerRef}
            outerRef={outerRef}
            tag={label}
            title={sourceTooltip}>
            <div
              className={classes.labelText}
              ref={innerRef}>
              {typeof label === 'string' ? parseString(label) : label}
            </div>
          </TaggedTooltip>
          {earlyAccess && (
            <EarlyAccessLink />
          )}
          {faultType && (
            <TaggedTooltip
              innerRef={innerRef}
              outerRef={outerRef}
              tag={label}
              title={sourceTooltip}>
              <div className={classes.labelIcon}>
                <WarningIcon
                  color={faultType === 'warning' ? colors.yellow500 : colors.red500}
                  maxHeight={12}
                  maxWidth={12}
                />
              </div>
            </TaggedTooltip>
          )}
        </div>
      </Label>
    </div>
  );
};

export interface LabeledInputProps extends LabelCellProps {
  // A consecutive series of LabeledInputs is spaced by putting top margin on all but the first one.
  // Occasionally, however, a first child needs top margin too (such as when it's wrapped in another
  // element).  Set `lead` to true to maintain spacing in this case.
  lead?: boolean;
  // The default layout uses 40% of the space for the label and 60% for the input(s).  Set layout
  // to 'favorLabel' to allow the label to take up as much room as it can.
  layout?: 'default' | 'favorLabel';
  children?: ReactNode;
}

// Contains a label on the left and the input on the right.
export const LabeledInput = (props: LabeledInputProps) => {
  const { children, layout = 'default', lead } = props;
  const classes = useCommonPropPanelRowStyles();

  return (
    <div className={cx(classes.root, layout, { lead })} data-locator="labeledInputRow">
      <LabelCell {...props} />
      {/* The div wrapper is used for styling, do not remove it */}
      <div>
        {children}
      </div>
    </div>
  );
};

export default LabeledInput;
