// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';

import { parseString } from '../../../lib/html';
import { debounce } from '../../../lib/utils';
import Collapsible from '../../transition/Collapsible';
import { ButtonAction, Dialog } from '../Base';
import './NavContentDialog.scss';

type TableDataValueOnly = string | number;
export type TableDataValueWithUnit = [TableDataValueOnly, string];
type TableDataValue = TableDataValueOnly | TableDataValueWithUnit | React.ReactChild;
type TableDataLink = {
  label: string,
  // the idx of the data object we want to scroll to. If it's empty, we'll use the label to find a
  // section with a matching nav item.
  idx?: number,
};

export type TableDataRow = {
  key: string,
  value?: TableDataValue,
  values?: [TableDataValue, TableDataValue],
  links?: TableDataLink[],
};

export interface TableData {
  // Unique id that can be used as a react key for tables with identical headings
  id?: string;
  // Optional heading label before the table
  label?: string;
  // Optional header row for the table
  heading?: string;
  rows: TableDataRow[];
}

export interface NavContentDialogItem {
  nav: string;
  subitems?: Array<{
    id: string,
    label: string,
  }>;
  description?: string;
  // The content is either passed as ReactNode or as a structured data that will be used to build
  // a tabular data. Use either one or the other.
  content?: ReactNode;
  tables?: TableData[];
}

interface NavContentDialogProps {
  cancelButton?: ButtonAction;
  continueButton?: ButtonAction;
  collapsible?: boolean;
  footer?: ReactNode;
  data: Array<NavContentDialogItem | undefined>;
  onClose: () => void;
  onContinue?: () => void;
  open: boolean;
  title: string;
  width?: number;
  navWidth?: number;
}

const QuantityColumn = ({ value }: { value: TableDataValue }) => {
  let val;
  let unit;
  if (Array.isArray(value)) {
    val = value[0];
    unit = value[1];
  } else {
    val = value;
  }

  return (
    <div className="column">
      <div className="quantity">
        <span>{val}</span>
        {unit && <span className="unit">{unit}</span>}
      </div>
    </div>
  );
};

export const NavContentDialog = (props: NavContentDialogProps) => {
  // == Prop
  const {
    cancelButton = { label: 'Cancel' },
    continueButton = { label: 'Done' },
    collapsible,
    footer,
    onClose,
    onContinue = onClose,
    open,
    title,
    width = 700,
    navWidth = 170,
  } = props;

  // == State
  const [nav, setNav] = useState({ idx: 0, scroll: true });
  const navRef = useRef<HTMLDivElement>(null);
  const sectionsRef = useRef<HTMLDivElement>(null);

  // == Data
  // Filter any undefined data items or they will break the idx-based navigation
  const data = props.data.filter((item): item is NavContentDialogItem => !!item);

  useEffect(() => {
    if (open) {
      setNav({ idx: 0, scroll: true });
    }
  }, [open]);

  useEffect(() => {
    if (nav.scroll) {
      if (collapsible) {
        sectionsRef.current?.scrollTo({
          top: 0,
        });
      } else {
        sectionsRef.current?.querySelectorAll('section')[nav.idx]?.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [nav, collapsible]);

  // On scrolling, this will highlight the nav item that's closest to the top of the scrolling area
  const onScroll = useCallback(() => {
    // Get the top position of the sections container relative to the viewport
    const parentY = sectionsRef.current?.getBoundingClientRect().y ?? 0;

    for (let i = data.length - 1; i >= 0; i -= 1) {
      // Get the top position of each section relative to the viewport
      const childY = document.getElementById(data[i].nav)?.getBoundingClientRect().y ?? 0;

      // Calculating "childY - parentY" gives us where the current section's top edge is
      // relative to the scrolling viewport ("0" means we've scrolled to the start of the section).
      // So starting from the back, find the section which top edge is either in the first
      // 50px of the scrolling viewport (which means we've scrolled near the beginning of it)
      // or below 0px (which means the beginning of the section is above the viewport but some
      // of its content is still around the top of the scrolling viewport).
      if (childY - parentY < 50) {
        setNav({ idx: i, scroll: false });
        navRef.current?.querySelectorAll('button')[i]?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
        break;
      }
    }
  }, [data]);

  const debouncedOnScroll = useMemo(() => debounce(onScroll), [onScroll]);

  useEffect(() => {
    if (collapsible) {
      return;
    }
    const scrollEl = sectionsRef.current;
    scrollEl?.addEventListener('scroll', debouncedOnScroll, { passive: true });

    // clean up code
    return () => scrollEl?.removeEventListener('scroll', debouncedOnScroll);
  }, [data, debouncedOnScroll, collapsible]);

  return (
    <Dialog
      cancelButton={cancelButton}
      continueButton={continueButton}
      footer={footer}
      modal
      onClose={onClose}
      onContinue={onContinue}
      open={open}
      title={title}
      width={`${width}px`}>
      <div className="navContentDialog">
        <nav ref={navRef} style={{ width: navWidth }}>
          {data.map((item, idx) => (
            <button
              className={cx({ active: nav.idx === idx })}
              key={item.nav}
              onClick={() => setNav({ idx, scroll: true })}
              type="button">
              {item.nav}
              {item.subitems?.length ? (
                <span className="subitems">
                  {item.subitems.map((subitem) => (
                    <span className="subitem" key={subitem.id}>{subitem.label}</span>
                  ))}
                </span>
              ) : null}
            </button>
          ))}
        </nav>
        <div className={cx('sections', { collapsible })} ref={sectionsRef}>
          {data.map((item, idx) => {
            const content = (
              <section id={item.nav} key={item.nav}>
                {item.description && <div className="description">{item.description}</div>}
                {/* The `content` is just a previously constructed ReactNode */}
                {item.content && <div>{item.content}</div>}
                {/* The `tables` is a structured data for displaying tabular data */}
                {item.tables?.map((table) => (
                  <div className="tableSection" key={table.id || table.label}>
                    {table.label && <div className="tableLabel">{table.label}</div>}
                    <div className="table">
                      {table.heading && <div className="tableHeading">{table.heading}</div>}
                      <div className="rows">
                        {table.rows.map((row) => (
                          <div className="row" key={row.key}>
                            <div className="column">{parseString(row.key)}</div>
                            {row.value !== undefined && <QuantityColumn value={row.value} />}
                            {row.values?.map((column) => (
                              <QuantityColumn key={`${column}`} value={column} />
                            ))}
                            {row.links && (
                              <div className="column">
                                {row.links?.map((link) => (
                                  <button
                                    className="link"
                                    key={link.label}
                                    onClick={() => {
                                      const scrollToIdx = link.idx ? link.idx : data.findIndex(
                                        (dataItem) => dataItem?.nav === link.label,
                                      );
                                      setNav({ idx: scrollToIdx, scroll: true });
                                    }}
                                    type="button">
                                    {link.label}
                                  </button>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </section>
            );

            if (collapsible) {
              return (
                <Collapsible collapsed={nav.idx !== idx} key={item.nav}>
                  {content}
                </Collapsible>
              );
            }
            return content;
          })}
        </div>
      </div>
    </Dialog>
  );
};
