// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useMemo } from 'react';

import { ParamScope, chainParamScopes, createParamScope } from '../../lib/ParamScope';
import {
  findHeatBoundaryCondition,
  findParentPhysicsByBoundaryConditionId,
  isDependentBoundaryCondition,
} from '../../lib/boundaryConditionUtils';
import { findMultiphysicsInterfaceById } from '../../lib/multiphysicsInterfaceUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';

import { usePhysicsSet } from './usePhysicsSet';
import { useWorkflowConfig } from './useWorkflowConfig';

/**
 * A model hook for managing an individual heat boundary condition
 * @param projectId
 * @param workflowId
 * @param jobId
 * @param readOnly
 * @param id
 * @returns
 */
export const useHeatBoundaryCondition = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
  id: string, // Boundary condition ID
) => {
  // == Recoil
  const experimentConfig = useEnabledExperiments();

  // == Model hooks
  const { saveBoundaryConditionAsync } = usePhysicsSet(projectId, workflowId, jobId, readOnly);

  // == Custom hooks
  const { simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  // The physics object that owns this boundary condition
  const physics = useMemo(
    () => findParentPhysicsByBoundaryConditionId(simParam, id),
    [id, simParam],
  );

  // Memoize the boundary condition object itself
  const boundaryCondition = useMemo(() => findHeatBoundaryCondition(simParam, id), [id, simParam]);

  // Replaces the boundary condition object with a new one, preserving order and saving the updated
  // param
  const replaceBoundaryCondition = useCallback(
    async (newBc: simulationpb.BoundaryConditionsHeat) => saveBoundaryConditionAsync(
      (newParam) => {
        const newPhysics = findParentPhysicsByBoundaryConditionId(newParam, id);
        if (newPhysics?.params.case === 'heat') {
          const heat = newPhysics.params.value;
          heat.boundaryConditionsHeat = heat.boundaryConditionsHeat.map((oldBc) => {
            if (oldBc.boundaryConditionName === id) {
              return newBc;
            }
            return oldBc;
          });
          return true;
        }
        return false;
      },
    ),
    [id, saveBoundaryConditionAsync],
  );

  // Apply an update function on the boundary condition and save the updated param
  const saveBoundaryCondition = useCallback(
    async (update: (newBc: simulationpb.BoundaryConditionsHeat) => void) => (
      saveBoundaryConditionAsync(
        (newParam) => {
          const newBoundaryCondition = findHeatBoundaryCondition(newParam, id);
          if (newBoundaryCondition) {
            update(newBoundaryCondition);
            return true;
          }
          return false;
        },
      )
    ),
    [id, saveBoundaryConditionAsync],
  );

  // Dependent boundary conditions are created as side effects of multiphysics coupling interfaces,
  // and the user's ability to modify them is limited.
  const isDependent = useMemo(
    () => (!!boundaryCondition && isDependentBoundaryCondition(boundaryCondition)),
    [boundaryCondition],
  );

  const couplingInterface = useMemo(() => {
    const interfaceId = boundaryCondition?.boundaryConditionInterfaceId;
    if (interfaceId) {
      return findMultiphysicsInterfaceById(simParam, interfaceId);
    }
    return undefined;
  }, [boundaryCondition, simParam]);

  const getParamScope = useCallback((paramScope: ParamScope) => {
    const heatPhysics = physics ?
      createParamScope(physics, experimentConfig, paramScope) :
      paramScope;
    return chainParamScopes([boundaryCondition], experimentConfig, heatPhysics);
  }, [boundaryCondition, experimentConfig, physics]);

  return {
    boundaryCondition,
    getParamScope,
    isDependent,
    replaceBoundaryCondition,
    saveBoundaryCondition,
    couplingInterface,
  };
};
