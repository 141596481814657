// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem } from '../../../lib/treeUtils';
import { useProjectContext } from '../../context/ProjectContext';
import { useCopyDiskModel } from '../../hooks/nodeDuplication/useCopyPhysicalModel';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { ContextMenuSection, TreeRow } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'intersectingDisks' };

export const PhysicalBehaviorTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { readOnly } = useProjectContext();

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deletePhysicalBehaviorNode, postDeleteNodeIds } = useNodeDeletion();

  const copyRow = useCopyDiskModel();

  const deleteRow = useCallback(async () => {
    if (await deletePhysicalBehaviorNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deletePhysicalBehaviorNode, node.id, postDeleteNodeIds]);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];

    const disabled = !canDelete(node.type, node.id);
    const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
    const duplicateItem = duplicateTreeNodeMenuItem(() => copyRow(node.id), readOnly);
    sections.push({ section: 'crud', menuItems: [duplicateItem, deleteItem] });

    return sections;
  }, [canDelete, deleteRow, node.id, node.type, copyRow, readOnly]);

  return (
    <TreeRow
      {...props}
      getExtraContextMenuItems={getExtraContextMenuItems}
      primaryIcon={PRIMARY_ICON}
      renaming={renaming}
    />
  );
};
