// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Descendant, createEditor } from 'slate';
import { ReactEditor, withReact } from 'slate-react';

import { validateExpressions } from '../../../../lib/derivedOutput';
import { colors } from '../../../../lib/designSystem';
import { AutocompleteEditor, MATH_FUNCTIONS } from '../../../../lib/expressionInput/AutocompleteEditor';
import { moveCursorToEnd, setEditorValue } from '../../../../lib/expressionInput/util';
import { DERIVED_DEPENDENCY_SUFFIXES, UNIDENTIFIED_OUTPUT_ID, findOutputNodeById } from '../../../../lib/outputNodeUtils';
import * as feoutputpb from '../../../../proto/frontend/output/output_pb';
import { useOutputNodes } from '../../../../recoil/outputNodes';
import { ExpressionInput } from '../../../ExpressionInput/ExpressionInput';
import { makeStyles } from '../../../Theme';
import { useCommonMultiInputLines } from '../../../Theme/commonStyles';
import Tooltip from '../../../Tooltip';
import { EditButtons } from '../../../controls/EditButtons';

const INCLUDE_TO_SUFFIX = new Map<feoutputpb.OutputIncludes, string>();
DERIVED_DEPENDENCY_SUFFIXES.forEach((include, suffix) => {
  INCLUDE_TO_SUFFIX.set(include, suffix);
});
INCLUDE_TO_SUFFIX.set(feoutputpb.OutputIncludes.OUTPUT_INCLUDE_BASE, '');

function displayExpression(
  expressionElements: feoutputpb.ExpressionElement[],
  outputNodes: feoutputpb.OutputNodes,
): string {
  let expression = '';

  expressionElements.forEach((element) => {
    switch (element.elementType.case) {
      case 'dependency': {
        const dep = element.elementType.value;
        const node = findOutputNodeById(outputNodes, dep.id);
        const dependencyName = node ? node.name : UNIDENTIFIED_OUTPUT_ID;
        expression += `"${dependencyName}${INCLUDE_TO_SUFFIX.get(dep.include)}"`;
      } break;
      case 'substring':
        expression += `${element.elementType.value}`;
        break;
      default:
        throw Error('Unrecognized expression element type case.');
    }
  });
  return expression;
}

// Custom hook for updating expressions
const useValidateExpressions = (projectId: string) => {
  const [outputNodes, setOutputNodes] = useOutputNodes(projectId, '', '');
  return useCallback((expressions: Map<string, string>) => {
    validateExpressions(outputNodes, setOutputNodes, projectId, expressions);
  }, [projectId, outputNodes, setOutputNodes]);
};

const useStyles = makeStyles(() => ({
  headingLayout: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  sectionHeading: {
    fontSize: '13px',
    fontWeight: 600,
    color: colors.neutral650,
  },
}), { name: 'ExpressionInput' });

export interface ExpressionPanelProps {
  outputNode: feoutputpb.OutputNode;
  outputNodes: feoutputpb.OutputNodes;
  projectId: string;
  error?: boolean;
}

export function ExpressionPanel(props: ExpressionPanelProps) {
  const { error, outputNode, outputNodes, projectId } = props;

  const multiInputClasses = useCommonMultiInputLines();
  const updateExpression = useValidateExpressions(projectId);
  const classes = useStyles();

  // UDE editor
  const [editor] = useState(() => AutocompleteEditor.withAutocomplete(withReact(createEditor())));
  const [isEditing, setIsEditing] = useState(false);
  const preEditState = useRef<Descendant[]>(editor.children);

  const options = useMemo(
    () => outputNodes.nodes.reduce((acc, node) => {
      if (node.type !== feoutputpb.OutputNode_Type.GLOBAL_OUTPUT_TYPE) {
        acc.push(node.name);
      }
      return acc;
    }, [] as string[]),
    [outputNodes],
  );

  useEffect(() => {
    // whenever the output node changes, replace the entire editor state
    // we cannot depend on the editor's `initialValue` as once it is set for an editor it cannot
    // be changed
    // LC-18844
    const elements = outputNode.nodeProps.case === 'derived' ?
      outputNode.nodeProps.value.elements : [];
    const value = AutocompleteEditor.deserialize(
      displayExpression(elements, outputNodes),
      options,
      MATH_FUNCTIONS,
    );
    setEditorValue(editor, value);
  }, [editor, options, outputNode, outputNodes]);

  useEffect(() => {
    if (!editor) {
      return;
    }
    if (isEditing) {
      ReactEditor.focus(editor);
    } else {
      ReactEditor.blur(editor);
    }
  }, [isEditing, editor]);

  const onStart = () => {
    setIsEditing(true);
    preEditState.current = editor.children;
    moveCursorToEnd(editor);
  };

  const onSave = () => {
    setIsEditing(false);
    const expression = AutocompleteEditor.serialize(editor);
    updateExpression(new Map<string, string>().set(outputNode.id, expression));
  };

  const onCancel = () => {
    setIsEditing(false);
    editor.children = preEditState.current;
  };

  const placeholderText = 'Use existing outputs, math operators, and numeric values to create an ' +
    'expression.\nExample: max(0.0, "Friction Coefficient")';

  return (
    <div className={multiInputClasses.root}>
      <div className={classes.headingLayout}>
        <Tooltip title={'Define a custom output using an expression with ' +
          'other outputs in quotation marks.'}>
          <div className={classes.sectionHeading}>Expression</div>
        </Tooltip>
        <EditButtons
          editMode={isEditing}
          onCancel={onCancel}
          onSave={onSave}
          onStartEdit={onStart}
        />
      </div>
      <ExpressionInput
        editor={editor}
        faultType={error ? 'error' : undefined}
        functions={MATH_FUNCTIONS}
        minHeight={100}
        options={options}
        placeholder={placeholderText}
        readOnly={!isEditing}
      />
    </div>
  );
}
