// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { cadIdsToVolumeNodeIds, volumeNodeIdsToCadIds } from '../../../../lib/geometryUtils';
import { usePreviewAnnotation } from '../../../../lib/lcvis/api';
import { Logger } from '../../../../lib/observability/logs';
import * as random from '../../../../lib/random';
import * as rpc from '../../../../lib/rpc';
import { NodeType } from '../../../../lib/simulationTree/node';
import { defaultNodeFilter, mapVisualizerEntitiesToVolumes } from '../../../../lib/subselectUtils';
import { addRpcError } from '../../../../lib/transientNotification';
import * as geometryservicepb from '../../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as basepb from '../../../../proto/base/base_pb';
import * as transfpb from '../../../../proto/cad/transformation_pb';
import * as geometrypb from '../../../../proto/geometry/geometry_pb';
import { QuantityType } from '../../../../proto/quantity/quantity_pb';
import {
  DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE,
  createOrUpdateFeature,
  useGeometrySelectedFeature,
  useGeometryState,
  useSetGeometryState,
} from '../../../../recoil/geometry/geometryState';
import { useCadMetadata } from '../../../../recoil/useCadMetadata';
import { useStaticVolumes } from '../../../../recoil/volumes';
import Form from '../../../Form';
import LabeledInput from '../../../Form/LabeledInput';
import { NumberInput } from '../../../Form/NumberInput';
import { Vector3Input } from '../../../Form/Vector3Input';
import QuantityAdornment from '../../../QuantityAdornment';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { UnitVectorButtons } from '../../../controls/UnitVectorButtons';
import ValidatedNumberSpinner from '../../../controls/ValidatedNumberSpinner';
import { useLcvCoordinateVisualizerGeo } from '../../../hooks/useLcvCoordinateVisualizer';
import { NodeSubselect } from '../../NodeSubselect';

import GeometryModificationPanelFooter from './GeometryModificationPanelFooter';
import { EditModificationMessage } from './GeometryModificationShared';

// Based on geometrypb.Transform.t.cases
export enum PatternType {
  LINEAR = 'linearSpacing',
  CIRCULAR = 'circularDistribution',
}

export const defaultPattern = (type: PatternType) => {
  const direction = new geometrypb.Pattern_Direction({ quantity: 2 });
  if (type === PatternType.LINEAR) {
    direction.type = {
      case: 'linearSpacing',
      value: new transfpb.Translation({
        displacement: { case: 'magnitudeVector', value: { direction: { x: 1 }, magnitude: 1 } },
      }),
    };
  } else if (type === PatternType.CIRCULAR) {
    direction.type = {
      case: 'circularDistribution',
      value: new geometrypb.Pattern_Direction_Circular({
        full: true,
        rotation: new transfpb.Rotation({
          angle: 180,
          axis: { case: 'arbitrary', value: { direction: { z: 1 }, origin: {} } },
        }),
      }),
    };
  }

  return new geometrypb.Pattern({ direction });
};

// Node subselect constructs
function nodeFilter(nodeType: NodeType, nodeId: string) {
  if (nodeType === NodeType.VOLUME) {
    return { related: true };
  }
  return defaultNodeFilter(nodeType);
}

// Handles the pattern of bodies.
export const GeometryModificationPatternPropPanel = () => {
  // == Contexts
  const { projectId, geometryId, readOnly } = useProjectContext();
  const { selectedNode: node, setSelection } = useSelectionContext();

  // == Recoil
  const geometryState = useGeometryState(projectId, geometryId);
  const setGeometryState = useSetGeometryState(projectId, geometryId);
  const [cadMetadata] = useCadMetadata(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const [, setSelectedFeature] = useGeometrySelectedFeature(geometryId);

  const mapVisualizerEntities = useCallback(
    (ids: string[]) => mapVisualizerEntitiesToVolumes(ids, staticVolumes),
    [staticVolumes],
  );

  // == Hooks
  const propClasses = useCommonTreePropsStyles();

  // == Derived data
  const mod = geometryState?.geometryFeatures.find((feature) => feature.id === node?.id);
  const [pattern, setPattern] = useState(mod?.operation.value as geometrypb.Pattern);
  const initialBodies = cadIdsToVolumeNodeIds(pattern.body, staticVolumes, cadMetadata);
  const isAcknowledgedFeature = geometryState?.ackModifications.has(node?.id || '');

  // == State
  const [selectedBodiesNodeIds, setSelectedBodiesNodeIds] = useState<string[]>(initialBodies);

  const annotations = useLcvCoordinateVisualizerGeo(
    pattern.direction!.type.case === 'linearSpacing' ?
      pattern.direction!.type.value :
      pattern.direction!.type.value!.rotation,
    node?.id || '',
  );
  useEffect(() => {
    annotations.show();
    return () => {
      annotations.clear();
    };
  }, [pattern, annotations]);

  const surfaceIds = useMemo(() => selectedBodiesNodeIds.flatMap((id) => {
    const volume = staticVolumes.find((vol) => vol.id === id);
    return [...volume?.bounds ?? []];
  }), [selectedBodiesNodeIds, staticVolumes]);
  const [showPreview, setShowPreview] = usePreviewAnnotation(
    pattern,
    // Show the preview by default if the feature is not acknowledged, otherwise, the user
    // has to explicitly enable it. This is because the tessellation isn't always ready in time when
    // we restore a feature from the server so we don't want to show a broken preview.
    !isAcknowledgedFeature,
    surfaceIds,
  );

  // Checks
  const logger = new Logger('GeometryModificationPatternPropPanel');
  if (!node) {
    logger.error('No selected geometry modification pattern row');
    return <></>;
  }
  if (!mod) {
    logger.error('No selected geometry modification pattern');
    return <></>;
  }
  if (!pattern) {
    logger.error('Missing geometry pattern');
    return <></>;
  }
  if (!pattern.direction) {
    logger.error('Missing direction');
    return <></>;
  }
  if (!pattern.direction!.type) {
    logger.error('Missing direction type');
    return <></>;
  }

  const onSetPattern = (newPattern: geometrypb.Pattern) => {
    setPattern(newPattern);
    setGeometryState((oldGeometryState) => {
      if (oldGeometryState === undefined) {
        return oldGeometryState;
      }
      const newGeometryState = { ...oldGeometryState };
      newGeometryState.geometryFeatures.forEach((feature) => {
        if (feature.id !== mod.id) {
          return;
        }
        feature.operation.value = newPattern;
      });
      return newGeometryState;
    });
  };

  const quantityPanel = (
    <Form.LabeledInput
      help="Total number of instances for each body"
      label="Quantity">
      <ValidatedNumberSpinner
        minimumValue={2}
        onCommit={(quantity) => {
          const newPattern = pattern.clone();
          newPattern.direction!.quantity = Math.max(quantity, 2);
          onSetPattern(newPattern);
        }}
        size="small"
        step={1}
        value={pattern.direction!.quantity}
      />
    </Form.LabeledInput>
  );

  const symmetricPanel = (
    <div style={{ margin: '14px 0 18px' }}>
      <Form.MultiCheckBox checkBoxProps={[{
        checked: pattern.direction!.symmetric,
        help: 'Make the pattern symmetric around the original bodies.',
        optionText: 'Symmetric',
        onChange: (checked) => {
          const newPattern = pattern.clone();
          newPattern.direction!.symmetric = checked;
          onSetPattern(newPattern);
        },
      }]}
      />
    </div>
  );

  const previewPanel = (
    <div style={{ margin: '14px 0 18px' }}>
      <Form.MultiCheckBox checkBoxProps={[{
        checked: showPreview,
        help: 'Show a preview of the pattern in the visualizer',
        optionText: 'Show Preview',
        onChange: (checked) => {
          setShowPreview(checked);
        },
      }]}
      />
    </div>
  );

  let parameterPanel: ReactNode;
  switch (pattern.direction!.type.case) {
    case PatternType.LINEAR: {
      const displ = pattern.direction!.type.value.displacement;
      if (displ.case !== 'magnitudeVector') {
        logger.error('Vector case is not magnitudeVector');
        return <></>;
      }
      parameterPanel = (
        <>
          <LabeledInput
            help="Direction along which bodies are translated, with Spacing dictating the distance \
            between instances"
            label="Direction">
            <Vector3Input
              onCommit={(value) => {
                const newPattern = pattern.clone();
                ((newPattern.direction!.type.value as transfpb.Translation)
                  .displacement.value as transfpb.MagnitudeVector3).direction = value;
                onSetPattern(newPattern);
              }}
              value={displ.value.direction!}
            />
          </LabeledInput>
          {quantityPanel}
          <Form.LabeledInput
            help="Distance between instances"
            label="Spacing">
            <NumberInput
              asBlock
              endAdornment={<QuantityAdornment quantity={QuantityType.LENGTH} />}
              onCommit={(magnitude) => {
                const newPattern = pattern.clone();
                ((newPattern.direction!.type.value as transfpb.Translation)
                  .displacement.value as transfpb.MagnitudeVector3).magnitude = magnitude;
                onSetPattern(newPattern);
              }}
              size="small"
              value={displ.value.magnitude}
            />
          </Form.LabeledInput>
          {symmetricPanel}
          {previewPanel}
        </>
      );
      break;
    }
    case PatternType.CIRCULAR: {
      const angle = pattern.direction!.type.value.rotation!.angle;
      const axis = pattern.direction!.type.value.rotation!.axis;
      if (axis.case !== 'arbitrary') {
        logger.error('Axis case is not arbitrary');
        return <></>;
      }
      parameterPanel = (
        <>
          <LabeledInput
            help="Point on the axis of rotation"
            label="Axis Origin">
            <Vector3Input
              onCommit={(value) => {
                const newPattern = pattern.clone();
                ((newPattern.direction!.type.value as geometrypb.Pattern_Direction_Circular)
                  .rotation as transfpb.Rotation).axis.value!.origin = value;
                onSetPattern(newPattern);
              }}
              value={axis.value.origin!}
            />
          </LabeledInput>
          <LabeledInput
            help="Direction of the axis of rotation"
            label="Axis Direction">
            <Vector3Input
              onCommit={(value) => {
                const newPattern = pattern.clone();
                ((newPattern.direction!.type.value as geometrypb.Pattern_Direction_Circular)
                  .rotation as transfpb.Rotation).axis.value!.direction =
                  value;
                onSetPattern(newPattern);
              }}
              value={axis.value.direction!}
            />
          </LabeledInput>
          <Form.LabeledInput label="">
            {readOnly ? null : (
              <Form.Group>
                <UnitVectorButtons
                  onClick={(x, y, z) => {
                    const newPattern = pattern.clone();
                    const vector = new basepb.Vector3({ x, y, z });
                    ((newPattern.direction!.type.value as geometrypb.Pattern_Direction_Circular)
                      .rotation as transfpb.Rotation).axis.value!
                      .direction = vector;
                    onSetPattern(newPattern);
                  }}
                />
              </Form.Group>
            )}
          </Form.LabeledInput>
          {quantityPanel}
          <Form.LabeledInput
            help="Angle over which copies are distributed"
            label="Distribution">
            <Form.MultiCheckBox checkBoxProps={[{
              checked: pattern.direction!.type.value.full,
              help: 'Distribute instances over the full circle',
              optionText: 'Full',
              onChange: (checked) => {
                const newPattern = pattern.clone();
                (newPattern.direction!.type.value as geometrypb.Pattern_Direction_Circular).full =
                  checked;
                onSetPattern(newPattern);
              },
            }]}
            />
            {!pattern.direction!.type.value.full && (
              <div style={{ marginTop: 8 }}>
                <NumberInput
                  asBlock
                  endAdornment={<QuantityAdornment quantity={QuantityType.DEGREE} />}
                  onCommit={(a) => {
                    const newPattern = pattern.clone();
                    ((newPattern.direction!.type.value as geometrypb.Pattern_Direction_Circular)
                      .rotation as transfpb.Rotation).angle = a;
                    onSetPattern(newPattern);
                  }}
                  size="small"
                  value={angle}
                />
              </div>
            )}
          </Form.LabeledInput>
          {symmetricPanel}
          {previewPanel}
        </>
      );
      break;
    }
    default:
      break;
  }

  const onPatternSave = async () => {
    const feature = new geometrypb.Feature({
      operation: mod.operation,
      id: node.id,
      featureName: mod.featureName,
    });
    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification: new geometrypb.Modification({
        modType: createOrUpdateFeature(geometryState, node.id),
        feature,
      }),
    });
    rpc.clientGeometry!.modifyGeometry(req).catch((err) => (
      addRpcError(`Server error ${err}`, err)
    ));

    // Focus out of the panel, to avoid weird rerenderings when calling setSelection.
    setSelectedFeature(DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE);

    // Remove the focus on the panel, the geometry being displayed may not be linked to the
    // modification.
    setSelection([]);
  };

  const onNodeChange = (nodeIds: string[]) => {
    setGeometryState((oldGeometryState) => {
      if (oldGeometryState === undefined) {
        return oldGeometryState;
      }
      const newGeometryState = { ...oldGeometryState };
      newGeometryState.geometryFeatures.forEach((feature) => {
        if (feature.id !== mod.id) {
          return;
        }
        const patternOut = feature.operation.value as geometrypb.Pattern;
        const bodies = volumeNodeIdsToCadIds(nodeIds, staticVolumes, cadMetadata);
        patternOut.body = bodies;
      });
      return newGeometryState;
    });
    setSelectedBodiesNodeIds(nodeIds);
  };

  return (
    <div className={propClasses.properties}>
      <EditModificationMessage nodeId={node.id} />
      <NodeSubselect
        autoStart={!isAcknowledgedFeature}
        iconNotFoundNodes={{ name: 'cubeOutline' }}
        id="pattern"
        labels={['volumes']}
        mapVisualizerEntities={mapVisualizerEntities}
        nodeFilter={nodeFilter}
        nodeIds={selectedBodiesNodeIds}
        onChange={onNodeChange}
        readOnly={readOnly}
        referenceNodeIds={[node.id]}
        showNotFoundNodes
        title="Volumes to Pattern"
        visibleTreeNodeTypes={[NodeType.VOLUME]}
      />
      {parameterPanel}
      <GeometryModificationPanelFooter
        featureId={node.id}
        onModificationSave={onPatternSave}
      />
    </div>
  );
};
