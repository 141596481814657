// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import * as rpc from '../../lib/rpc';
import { isTestingEnv } from '../../lib/testing/utils';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as cadmetadatapb from '../../proto/cadmetadata/cadmetadata_pb';
import { selectedGeometryState } from '../selectedGeometry';
import { cadMetadataState } from '../useCadMetadata';

import { geometryListState_DEPRECATED } from './geometryListState';
import { getGeoState, onGeometryTabSelector } from './geometryState';
import { GeometryTags } from './geometryTagsObject';

// TODO(LC-21795): Figure out a better key.
type RecoilKey = {
  projectId: string,
}

const geometryTagsStateTesting = selectorFamily<
  GeometryTags,
  RecoilKey
>({
  key: 'geometryTags/testing',
  get: () => () => new GeometryTags(undefined, new cadmetadatapb.CadMetadata()),
  dangerouslyAllowMutability: true,
});

// List of geometries in a given project.
export const geometryTagsStateNonTesting = selectorFamily<
  GeometryTags,
  RecoilKey
>({
  key: 'geometryTags',
  get: (key: RecoilKey) => async ({ get }) => {
    // Don't start the RPC if the flag is not enabled.
    const geometryList = get(geometryListState_DEPRECATED(key.projectId));
    const geoUsesTags = geometryList?.geometries[0]?.usesTags ?? false;
    if (!geoUsesTags) {
      return new GeometryTags(undefined, new cadmetadatapb.CadMetadata());
    }
    const { projectId } = key;
    const geoTab = get(onGeometryTabSelector);
    if (geoTab) {
      const geoState = getGeoState(get, key.projectId);
      if (!geoState) {
        return new GeometryTags(undefined, new cadmetadatapb.CadMetadata());
      }
      return new GeometryTags(geoState.tags, geoState.cadMetadata);
    }
    const [selectedGeometry, cadMetadata] = get(waitForAll([
      selectedGeometryState(projectId), cadMetadataState(projectId),
    ]));
    if (!rpc.clientGeometry || !projectId || !selectedGeometry.geometryId) {
      return new GeometryTags(undefined, cadMetadata);
    }
    try {
      const response = await rpc.callRetryWithClient(
        rpc.clientGeometry,
        'GetTags',
        rpc.clientGeometry.getTags,
        new geometryservicepb.GetTagsRequest({
          geometryId: selectedGeometry.geometryId,
          geometryVersionId: selectedGeometry.geometryVersionId,
        }),
      );
      return new GeometryTags(response.tags, cadMetadata);
    } catch (error) {
      console.error('Failed to get tags', error);
      return new GeometryTags(undefined, cadMetadata);
    }
  },
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});

export const geometryTagsState = isTestingEnv() ?
  geometryTagsStateTesting : geometryTagsStateNonTesting;

export function useGeometryTags(projectId: string) {
  return useRecoilValue(geometryTagsState({ projectId }));
}
