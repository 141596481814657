// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
// Name-generating utilities.

// A standard name-generating policy for prefixed names.  Returns a
// function that generates names of the form "prefix 1", "prefix 2", ...
// If noSeq=true, the first generated name will be "prefix", not "prefix 1".
export const prefixNameGen = (prefix: string, noSeq?: boolean) => (count: number) => (
  noSeq && count === 1 ? prefix : `${prefix} ${count}`
);

type UniqueSequenceSettings = {
  // When 'recycleNumbers' is true, counting starts from 1 instead of from existing.length.
  recycleNumbers?: true;
}

// Return a new name of the  form nameGen(N), where N is determined by the
// length of existing names.  If that name already exists, continue to increment
// N until the name is unique.
export const uniqueSequenceName = (
  existing: string[] | Map<string, boolean>,
  nameGen: (count: number) => string,
  settings?: UniqueSequenceSettings,
) => {
  // Construct a map for that O(n) goodness.
  let existingMap = new Map<string, boolean>();
  if (Array.isArray(existing)) {
    existing.forEach((name) => existingMap!.set(name, true));
  } else {
    existingMap = existing;
  }

  let count = settings?.recycleNumbers ? 1 : existingMap.size + 1;
  let result = nameGen(count);
  let unique = false;

  const duplicateName = () => existingMap.get(result);

  while (!unique) {
    if (duplicateName()) {
      count += 1;
      result = nameGen(count);
    } else {
      unique = true;
    }
  }

  return result;
};

/** Given some name, return '<name> (copy)'.
 * This is the default name for nodes that have been copied. */
export const copiedName = (initialName: string) => `${initialName} (copy)`;

const nameCopyRegex = /\(Copy (\d*)\)/g;

/**
 * Given the name of an item being copied, returns a new iterated name for the copy.
 * <name> -> <name> (Copy 1) -> <name> (Copy 2), etc.
 *
 * @param parentName Name of the copied item
 * @param nameList List of existing names
 * @returns New name for the copy
 */
export function getCopyName(parentName: string, nameList: string[]): string {
  // Get the base name (removes (Copy N) from the name)
  const parentBaseName = parentName.replaceAll(nameCopyRegex, '').trim();
  // Check for other instances of the parent name
  const parentInstances = nameList.filter(
    (name) => (
      name.match(nameCopyRegex) &&
      name.replaceAll(nameCopyRegex, '').trim() === parentBaseName
    ),
  );

  // If no matches, this is the first copy
  if (parentInstances.length === 0) {
    return `${parentBaseName} (Copy 1)`;
  }

  // Else there exist copies and the count must be incremented
  // Filter out the copy number from all matchs, sort them, and get the highest
  const highestCopyNumber = parentInstances.map(
    (name) => {
      // There may exist names with multiple copy tags. e.g. <name> (Copy 1) (Copy 2)
      const copyNMatches = name.match(nameCopyRegex);
      const highestMatch = copyNMatches!.map(
        (x) => parseInt(x.match(/\d+/)![0] ?? '', 10),
      ).sort().pop();
      return highestMatch;
    },
  ).sort().pop();

  return `${parentBaseName} (Copy ${highestCopyNumber! + 1})`;
}
