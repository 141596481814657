// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 18;
export const ChartLineIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="chartLine"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M23.25,18
           C23.469,18 23.648,17.93 23.789,17.789
           C23.93,17.648 24,17.469 24,17.25
           L24,16.5
           C24,16.281 23.93,16.102 23.789,15.961
           C23.648,15.82 23.469,15.75 23.25,15.75
           L2.25,15.75
           L2.25,0.75
           C2.25,0.531 2.18,0.352 2.039,0.211
           C1.898,0.07 1.719,0 1.5,0
           L0.75,0
           C0.531,0 0.352,0.07 0.211,0.211
           C0.07,0.352 0,0.531 0,0.75
           L0,16.5
           C0,16.906 0.148,17.258 0.445,17.555
           C0.742,17.852 1.094,18 1.5,18
           L23.25,18Z
           M13.5,11.766
           C13.719,11.766 13.891,11.703 14.016,11.578
           L19.219,6.375
           L20.578,7.781
           C20.797,8 21.062,8.109 21.375,8.109
           C21.688,8.109 21.953,8 22.172,7.781
           C22.391,7.563 22.5,7.297 22.5,6.984
           L22.5,2.25
           C22.5,2.031 22.43,1.852 22.289,1.711
           C22.148,1.57 21.969,1.5 21.75,1.5
           L17.016,1.5
           C16.703,1.5 16.438,1.609 16.219,1.828
           C16,2.047 15.891,2.313 15.891,2.625
           C15.891,2.938 16,3.203 16.219,3.422
           L17.625,4.781
           L13.5,8.906
           L9.516,4.922
           C9.391,4.797 9.219,4.734 9,4.734
           C8.781,4.734 8.609,4.797 8.484,4.922
           L4.969,8.438
           C4.844,8.563 4.781,8.734 4.781,8.953
           C4.781,9.172 4.844,9.344 4.969,9.469
           L5.531,10.031
           C5.656,10.156 5.828,10.219 6.047,10.219
           C6.266,10.219 6.438,10.156 6.563,10.031
           L9,7.594
           L12.984,11.578
           C13.109,11.703 13.281,11.766 13.5,11.766Z
           M23.25,18
           C23.469,18 23.648,17.93 23.789,17.789
           C23.93,17.648 24,17.469 24,17.25
           L24,16.5
           C24,16.281 23.93,16.102 23.789,15.961
           C23.648,15.82 23.469,15.75 23.25,15.75
           L2.25,15.75
           L2.25,0.75
           C2.25,0.531 2.18,0.352 2.039,0.211
           C1.898,0.07 1.719,0 1.5,0
           L0.75,0
           C0.531,0 0.352,0.07 0.211,0.211
           C0.07,0.352 0,0.531 0,0.75
           L0,16.5
           C0,16.906 0.148,17.258 0.445,17.555
           C0.742,17.852 1.094,18 1.5,18
           L23.25,18Z
           M13.5,11.766
           C13.719,11.766 13.891,11.703 14.016,11.578
           L19.219,6.375
           L20.578,7.781
           C20.797,8 21.062,8.109 21.375,8.109
           C21.688,8.109 21.953,8 22.172,7.781
           C22.391,7.563 22.5,7.297 22.5,6.984
           L22.5,2.25
           C22.5,2.031 22.43,1.852 22.289,1.711
           C22.148,1.57 21.969,1.5 21.75,1.5
           L17.016,1.5
           C16.703,1.5 16.438,1.609 16.219,1.828
           C16,2.047 15.891,2.313 15.891,2.625
           C15.891,2.938 16,3.203 16.219,3.422
           L17.625,4.781
           L13.5,8.906
           L9.516,4.922
           C9.391,4.797 9.219,4.734 9,4.734
           C8.781,4.734 8.609,4.797 8.484,4.922
           L4.969,8.438
           C4.844,8.563 4.781,8.734 4.781,8.953
           C4.781,9.172 4.844,9.344 4.969,9.469
           L5.531,10.031
           C5.656,10.156 5.828,10.219 6.047,10.219
           C6.266,10.219 6.438,10.156 6.563,10.031
           L9,7.594
           L12.984,11.578
           C13.109,11.703 13.281,11.766 13.5,11.766Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
