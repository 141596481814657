// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

// Track the expanded state of the left nav and persist it in the sessionStorage.
// Also store the expanded sub sections within the nav, persisting them is not necessary.

import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

const LEFT_NAV_EXPANDED_KEY = 'lcLeftNavExpanded';
const LEFT_NAV_HOVERED_KEY = 'lcLeftNavHovered';

type ExpandedValue = boolean;

// Switch to sessionStorage instead of localStorage
const storage = createJSONStorage<ExpandedValue>(() => sessionStorage);

// State for the expanded state of the whole side
export const leftNavExpandedStore = atomWithStorage<ExpandedValue>(
  LEFT_NAV_EXPANDED_KEY,
  true,
  storage,
);

export function useLeftNavExpandedState() {
  return useAtom(leftNavExpandedStore);
}
export function useLeftNavExpandedValue() {
  return useAtomValue(leftNavExpandedStore);
}

// the layout is re-mounted when the page changes, we need to preserve the state to prevent
// the menu from collapsing during page transitions
export const leftNavHoveredStore = atomWithStorage<ExpandedValue>(
  LEFT_NAV_HOVERED_KEY,
  false,
  storage,
);

export function useLeftNavHoveredState() {
  return useAtom(leftNavHoveredStore);
}

export function useSetLeftNavHovered() {
  return useSetAtom(leftNavHoveredStore);
}

// State for the expanded state of the individual sub sections
export type LeftNavSectionsState = {
  administration?: ExpandedValue,
  platform?: ExpandedValue,
  myaccount?: ExpandedValue,
}

const leftNavExpandedSectionsStore = atom<LeftNavSectionsState>({});

export function useLeftNavExpandedSectionsState() {
  return useAtom(leftNavExpandedSectionsStore);
}
export function useLeftNavExpandedSectionsValue() {
  return useAtomValue(leftNavExpandedSectionsStore);
}
