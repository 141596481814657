// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/api/v0/luminarycloud/common/common.proto (package luminary.proto.api.v0.luminarycloud.common, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.common.FileMetadata
 */
export const FileMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.common.FileMetadata",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ext", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "size", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "sha256_checksum", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.common.File
 */
export const File = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.common.File",
  () => [
    { no: 1, name: "metadata", kind: "message", T: FileMetadata },
    { no: 2, name: "full_contents", kind: "scalar", T: 12 /* ScalarType.BYTES */, oneof: "contents" },
    { no: 4, name: "signed_url", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "contents" },
    { no: 5, name: "file_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "contents" },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.common.FileChunk
 */
export const FileChunk = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.common.FileChunk",
  () => [
    { no: 1, name: "metadata", kind: "message", T: FileMetadata },
    { no: 2, name: "bytes", kind: "message", T: ByteChunk },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.common.ByteChunk
 */
export const ByteChunk = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.common.ByteChunk",
  () => [
    { no: 1, name: "start_offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "next_offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "last_chunk", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.common.Vector3
 */
export const Vector3 = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.common.Vector3",
  () => [
    { no: 1, name: "x", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "y", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "z", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

