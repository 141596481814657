// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { KeyboardEventHandler } from 'react';

import cx from 'classnames';

import { CheckBoxProps, MultiCheckBoxProps } from '../../lib/componentTypes/form';
import { colors } from '../../lib/designSystem';
import { isUnmodifiedSpaceKey } from '../../lib/event';
import objectId from '../../lib/objectId';
import { createStyles, makeStyles } from '../Theme';
import { useCommonMultiInputLines } from '../Theme/commonStyles';
import Tooltip from '../Tooltip';
import { CheckmarkIcon } from '../svg/CheckmarkIcon';
import { DashIcon } from '../svg/DashIcon';

// Checkbox is styled according to Figma design:
// https://www.figma.com/file/jgngRpKr9HPeHuI8hKQfbS/Luminary-Component-Library-V2?node-id=6%3A7026
const useStyles = makeStyles(
  () => createStyles({
    root: {
      '--bg-color': colors.neutral150,
      '--border-color': colors.neutral650,
      '--mark-color': colors.neutral900,
      '--mark-opacity': 0,
      '&:hover': {
        '--bg-color': colors.neutral150,
        '--border-color': colors.neutral650,
      },
      '&:active': {
        '--bg-color': colors.neutral250,
        '--border-color': 'transparent',
      },
      '&.disabled': {
        '--bg-color': colors.neutral350,
        '--border-color': colors.neutral400,
        '--mark-color': colors.neutral750,
      },
      '&.engaged': {
        '--border-color': 'transparent',
        '--mark-opacity': 1,
        '&.disabled': {
          '--mark-opacity': 0.65,
        },
        '&:not(.disabled)': {
          '--bg-color': colors.primaryCta,
          '&:hover': {
            '--bg-color': colors.purple500,
          },
          '&:active': {
            '--bg-color': colors.purple400,
          },
        },
      },
      '&:focus, &:focus-within': {
        '&:not(.disabled):not(:active)': {
          '--border-color': colors.primaryInteractive,
        },
      },
      width: '16px',
      height: '16px',
      borderRadius: '3px',
      border: '1px solid var(--border-color)',
      backgroundColor: 'var(--bg-color)',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      transitionDuration: '250ms',
      transitionProperty: 'background-color, border-color',
      verticalAlign: 'middle',
      outline: 0,
      '&:hover, &:active': {
        '&:not(.engaged):not(.disabled)': {
          boxShadow: `inset 0px -1px 0px ${colors.neutral300},
                      inset 0px 1px 4px rgba(24, 25, 30, 0.1)`,
        },
      },
      '& > .icons': {
        width: '10px',
        height: '10px',
        position: 'relative',
        '& > .icon': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          flex: '0 0 auto',
          opacity: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'var(--mark-color)',
          transition: 'opacity 250ms',
          '&.active': {
            opacity: 'var(--mark-opacity)',
          },
        },
      },
    },
  }),
  { name: 'Checkbox' },
);

const CheckBox = (props: CheckBoxProps) => {
  const { checked, disabled, dataLocator = 'formInputCheckbox', indeterminate, onChange } = props;

  const classes = useStyles();

  const handleKeyUp: KeyboardEventHandler = (event) => {
    // Hitting the spacebar (unmodified) on an enabled checkbox should actuate it
    if (disabled) {
      return;
    }

    if (isUnmodifiedSpaceKey(event)) {
      onChange(!checked);
    }
  };

  return (
    <div
      aria-checked={checked}
      className={cx(classes.root, { engaged: checked || indeterminate, disabled })}
      data-locator={dataLocator}
      onClick={() => !disabled && onChange(indeterminate ? false : !checked)}
      onDoubleClick={(event) => event.stopPropagation()}
      onKeyUp={handleKeyUp}
      role="checkbox"
      tabIndex={0}>
      <div className="icons">
        <div className={cx('icon checkmark', { active: !indeterminate })}>
          <CheckmarkIcon maxHeight={10} maxWidth={10} />
        </div>
        <div className={cx('icon minus', { active: indeterminate })}>
          <DashIcon maxHeight={10} maxWidth={10} />
        </div>
      </div>
      <input
        checked={checked}
        disabled
        onChange={() => { }}
        ref={(input) => {
          if (input) {
            input.indeterminate = indeterminate || false;
          }
        }}
        style={{ display: 'none' }}
        type="checkbox"
      />
    </div>
  );
};

// A group of checkboxes with one common label
export const MultiCheckBox = (props: MultiCheckBoxProps) => {
  const classes = useCommonMultiInputLines();

  return (
    <div className={classes.root}>
      {props.checkBoxProps.map((checkBox) => (
        <label className={classes.line} key={objectId(checkBox)}>
          <div className={classes.control}>
            <CheckBox
              checked={checkBox.checked}
              disabled={checkBox.disabled}
              onChange={checkBox.onChange}
            />
          </div>
          <div
            className={classes.label}
            onClick={() => checkBox.onChange(!checkBox.checked)}
            role="presentation">
            <Tooltip title={checkBox.help || ''}>
              <div>{checkBox.optionText || ''}</div>
            </Tooltip>
          </div>
        </label>
      ))}
    </div>
  );
};

export default CheckBox;
