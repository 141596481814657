// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const BoxSelectCursorIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="boxSelect"
      height={finalHeight}
      ref={ref}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M4 2.335H1.335v2.332h1.33V3.665H4v-1.33Z
                M6 3.665h4v-1.33H6v1.33Z
                M12 3.665h1.335V4.5h1.33V2.335H12v1.33Z
                M14.665 6h-1.33v1.5h1.33V6Z
                M2.665 9.667V6.333h-1.33v3.334h1.33Z
                M2.665 11.333v1.002h1.002v1.33H1.335v-2.332h1.33Z
                M7 12.335H5.333v1.33H7v-1.33Z
                M14.5 10.178l-2.38 1.002 2.35 2.35-.94.94-2.35-2.35-1.002 2.38
                L7.82 8.337a.4.4 0 0 1 .517-.517l6.163 2.358Z"
        style={{ fill: getColor(0) }}

      />
    </svg>
  );
});
