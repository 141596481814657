// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { getOrCreateTime } from '../../lib/simulationParamUtils';
import * as simulationpb from '../../proto/client/simulation_pb';

import { useWorkflowConfig } from './useWorkflowConfig';

/**
 * Model hook for managing the Time data structure at the SimluationParam root that exposes time
 * stepping settings
 * @param projectId
 * @param workflowId
 * @param jobId
 * @param readOnly
 * @returns
 */
export const useTime = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
) => {
  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  const time = getOrCreateTime(simParam);

  const setTime = useCallback(
    async (value: simulationpb.Time) => saveParamAsync((newParam) => {
      newParam.time = value;
    }),
    [saveParamAsync],
  );

  return {
    time,
    setTime,
  };
};
