// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 124;
const baseHeight = 114;
export const DoubleArrowUpDownIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="doubleArrowUpDown"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeMiterlimit: 2,
      }}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0,29.101
        C0,29.101 18.067,11.033 27.05,2.05
        C28.363,0.737 30.143,0 32,0
        C33.857,0 35.637,0.737 36.95,2.05
        C45.933,11.033 64,29.101 64,29.101
        L56.222,36.879
        L37.5,18.157
        L37.5,114
        L26.5,114
        L26.5,18.157
        L7.619,36.879
        L0,29.101Z"
        fill={getColor(0)}
        strokeLinejoin="round"
      />
      <path
        d="M124,84.899
        C124,84.899 105.933,102.967 96.95,111.95
        C95.637,113.263 93.857,114 92,114
        C90.143,114 88.363,113.263 87.05,111.95
        C78.067,102.967 60,84.899 60,84.899
        L67.778,77.121
        L86.5,95.843
        L86.5,0
        L97.5,0
        L97.5,95.843
        L116.381,77.121
        L124,84.899Z"
        fill={getColor(0)}
        strokeLinejoin="round"
      />
    </svg>
  );
});
