// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { getQuantityTags, getQuantityText, getQuantityUnit } from '../../../../QuantityDescriptor';
import assert from '../../../../lib/assert';
import { CheckBoxProps } from '../../../../lib/componentTypes/form';
import { BaseSettingsProps, StoppingConditionSpec } from '../../../../lib/componentTypes/output';
import { colors } from '../../../../lib/designSystem';
import { DEFAULT_GLOBAL_FRAME_ID, orderedFrames } from '../../../../lib/motionDataUtils';
import { createIncludeOption } from '../../../../lib/output/formUtil';
import { getOutputQuantity } from '../../../../lib/outputNodeUtils';
import { OUTPUT_REFERENCE_VALUE_NODE_ID } from '../../../../lib/simulationTree/node';
import { useOutput } from '../../../../model/hooks/useOutput';
import * as basepb from '../../../../proto/base/base_pb';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import * as feoutputpb from '../../../../proto/frontend/output/output_pb';
import { OutputIncludes } from '../../../../proto/frontend/output/output_pb';
import * as outputpb from '../../../../proto/output/output_pb';
import { QuantityTag } from '../../../../proto/quantity/quantity_options_pb';
import Form from '../../../Form';
import { DataSelect } from '../../../Form/DataSelect';
import LabeledInput from '../../../Form/LabeledInput';
import { AdVector3Input } from '../../../Form/Vector3Input';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { createStyles, makeStyles } from '../../../Theme';
import Divider from '../../../Theme/Divider';
import NodeLink from '../../NodeLink';
import PropertiesSection from '../../PropertiesSection';

import { AverageSettings } from './shared/AverageSettings';
import { OutputSurfaces } from './shared/OutputSurfaces';

const { FORCE_DIRECTION_BODY_ORIENTATION_AND_FLOW_DIR } = outputpb.ForceDirectionType;

const useStyles = makeStyles(() => createStyles({
  link: {
    flex: '1 0 60%',
    color: colors.lowEmphasisText,
    fontSize: '13px',
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
    outline: 'none',
  },
}), { name: 'ForcePanelOutput' });

export interface ForceOptionsProps {
  outputNode: feoutputpb.OutputNode;
  param: simulationpb.SimulationParam;
  projectId: string;
}

export function ForceOptions(props: ForceOptionsProps) {
  const { outputNode, param, projectId } = props;

  const quantity = getOutputQuantity(outputNode);
  if (!quantity) {
    throw Error('No quantity found.');
  }
  const { updateOutputNode } = useOutput(projectId, outputNode.id);

  const classes = useStyles();

  assert(outputNode.nodeProps.case === 'force', 'Node must contain forceNode');
  const forceNode = outputNode.nodeProps.value;

  const autoDir = getQuantityTags(quantity).includes(QuantityTag.TAG_AUTO_DIRECTION);
  const force = getQuantityTags(quantity).includes(QuantityTag.TAG_FORCE);
  const moment = getQuantityTags(quantity).includes(QuantityTag.TAG_MOMENT);
  const disk = getQuantityTags(quantity).includes(QuantityTag.TAG_ACTUATOR_DISK);
  const forceProps = forceNode.props!;
  const forceDirType = forceProps.forceDirType;
  const frames = orderedFrames(param).frames;
  const frameId = outputNode.frameId;

  const quantityName = getQuantityText(quantity);
  const quantityUnit = getQuantityUnit(quantity);

  const createOption = (includes: OutputIncludes[], text: string, disabled?: boolean) => (
    createIncludeOption(includes, text, outputNode, updateOutputNode, disabled)
  );

  const includeOptions: CheckBoxProps[] = [];

  includeOptions.push(createOption(
    [OutputIncludes.OUTPUT_INCLUDE_BASE],
    `${quantityName} ${quantityUnit ? `(${quantityUnit})` : ''}`,
  ));

  // TODO(LC-6878): coefficient include option removed for
  // actuator disk outputs for now. Add back in later with coefficient
  // calculated based on blade tip speed for disk actuators
  if (!disk) {
    includeOptions.push(createOption(
      [OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT],
      `${quantityName} - Coefficient`,
    ));
  }

  return (
    <>
      <LabeledInput
        help="Select reference frame to display output in."
        label="Reference Frame">
        <DataSelect
          asBlock
          disabled={!frames.length && !frameId}
          onChange={(frameIdx) => updateOutputNode((newOutput) => {
            newOutput.frameId = frames.length ? frames[frameIdx].frameId : '';
          })}
          options={frames.length ? frames.map((frame, frameIdx) => ({
            name: frame.frameName,
            value: frameIdx,
            selected: frameId ?
              frame.frameId === frameId :
              frameIdx === 0,
          })) : [
            { name: 'Global', value: 0, selected: !frameId || frameId === DEFAULT_GLOBAL_FRAME_ID },
          ]}
          size="small"
        />
      </LabeledInput>
      {!autoDir && (
        <>
          {force && (
            <LabeledInput
              help="Vector specifying the direction of the force"
              label="Force Direction">
              <AdVector3Input
                disabled={forceDirType === FORCE_DIRECTION_BODY_ORIENTATION_AND_FLOW_DIR}
                onCommit={(direction) => {
                  updateOutputNode((newOutput) => {
                    assert(
                      newOutput.nodeProps.case === 'force',
                      'Force direction may only be set on a force output',
                    );
                    if (newOutput.nodeProps.value.props) {
                      newOutput.nodeProps.value.props.forceDirection = direction;
                    }
                  });
                }}
                value={forceProps.forceDirection || new basepb.AdVector3()}
              />
            </LabeledInput>
          )}
          {moment && (
            <LabeledInput
              help="Axis of the moment"
              label="Moment Axis">
              <AdVector3Input
                disabled={forceDirType === FORCE_DIRECTION_BODY_ORIENTATION_AND_FLOW_DIR}
                onCommit={(center) => {
                  updateOutputNode((newOutput) => {
                    assert(
                      newOutput.nodeProps.case === 'force',
                      'Force direction may only be set on a force output',
                    );
                    if (newOutput.nodeProps.value.props) {
                      newOutput.nodeProps.value.props.forceDirection = center;
                    }
                  });
                }}
                value={forceProps.forceDirection || new basepb.AdVector3()}
              />
            </LabeledInput>
          )}
        </>
      )}
      {/* Moment center can be found automatically using actuator disk position for actuator disk
          outputs, but cannot be automatically inferred for other output quantities */}
      {moment && !disk && (
        <LabeledInput
          help="Point about which the force causes rotation"
          label="Moment Center">
          <AdVector3Input
            onCommit={(center) => {
              updateOutputNode((newOutput) => {
                assert(
                  newOutput.nodeProps.case === 'force',
                  'Moment center may only be set on a force output',
                );
                if (newOutput.nodeProps.value.props) {
                  newOutput.nodeProps.value.props.momentCenter = center;
                }
              });
            }}
            value={forceProps.momentCenter || new basepb.AdVector3()}
          />
        </LabeledInput>
      )}
      <LabeledInput
        help="Include the non-dimensional coefficient of this quantity."
        label="Include">
        <Form.MultiCheckBox checkBoxProps={includeOptions} />
      </LabeledInput>
      <div>
        <div className={classes.link}>
          <NodeLink asBlock nodeIds={[OUTPUT_REFERENCE_VALUE_NODE_ID]} text="Update References" />
        </div>
      </div>
    </>
  );
}

interface ForcePanelProps extends BaseSettingsProps {
  param: simulationpb.SimulationParam;
  showConvergenceMonitor: boolean;
  warnStoppingCondition?: StoppingConditionSpec;
}

export function ForcePanel(props: ForcePanelProps) {
  const { outputNode, param, projectId, showConvergenceMonitor, warnStoppingCondition } = props;

  const nodeId = outputNode.id;
  const idBase = 'force';

  return (
    <>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Options"
          nodeId={nodeId}
          panelName="options">
          <ForceOptions
            outputNode={outputNode}
            param={param}
            projectId={projectId}
          />
          <AverageSettings
            outputNode={outputNode}
            projectId={projectId}
            showConvergenceMonitor={showConvergenceMonitor}
            warnStoppingCondition={warnStoppingCondition}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Geometry"
          nodeId={nodeId}
          panelName="surface">
          <OutputSurfaces
            idBase={idBase}
            outputNode={outputNode}
            projectId={projectId}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </>
  );
}
