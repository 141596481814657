// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 21;
export const GroupIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="group"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M20.082,0
           L23.417,0
           C23.739,0 24,0.261 24,0.583
           L24,3.918
           C24,4.24 23.739,4.501 23.417,4.501
           L20.082,4.501
           C19.76,4.501 19.499,4.24 19.499,3.918
           L19.499,0.583
           C19.499,0.261 19.76,0 20.082,0Z
           M20.994,1.495
           L20.994,3.006
           L22.505,3.006
           L22.505,1.495
           L20.994,1.495Z
           M0.583,0
           L3.918,0
           C4.24,0 4.501,0.261 4.501,0.583
           L4.501,3.918
           C4.501,4.24 4.24,4.501 3.918,4.501
           L0.583,4.501
           C0.261,4.501 0,4.24 0,3.918
           L0,0.583
           C0,0.261 0.261,0 0.583,0Z
           M1.495,1.495
           L1.495,3.006
           L3.006,3.006
           L3.006,1.495
           L1.495,1.495Z
           M0.583,16.493
           L3.918,16.493
           C4.24,16.493 4.501,16.755 4.501,17.077
           L4.501,20.411
           C4.501,20.733 4.24,20.994 3.918,20.994
           L0.583,20.994
           C0.261,20.994 0,20.733 0,20.411
           L0,17.077
           C0,16.755 0.261,16.493 0.583,16.493Z
           M1.495,17.989
           L1.495,19.499
           L3.006,19.499
           L3.006,17.989
           L1.495,17.989Z
           M20.082,16.493
           L23.417,16.493
           C23.739,16.493 24,16.755 24,17.077
           L24,20.411
           C24,20.733 23.739,20.994 23.417,20.994
           L20.082,20.994
           C19.76,20.994 19.499,20.733 19.499,20.411
           L19.499,17.077
           C19.499,16.755 19.76,16.493 20.082,16.493Z
           M20.994,17.989
           L20.994,19.499
           L22.505,19.499
           L22.505,17.989
           L20.994,17.989Z
           M1.136,6.49
           L3.35,6.49
           L3.35,14.49
           L1.136,14.49
           L1.136,6.49Z
           M20.65,6.49
           L22.864,6.49
           L22.864,14.49
           L20.65,14.49
           L20.65,6.49Z
           M17.465,1.151
           L17.465,3.364
           L6.505,3.364
           L6.505,1.151
           L17.465,1.151Z
           M17.465,17.645
           L17.465,19.858
           L6.505,19.858
           L6.505,17.645
           L17.465,17.645Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
