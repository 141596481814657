// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect } from 'react';

import { ConnectError } from '@connectrpc/connect';
import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import { jobStateFixture } from '../lib/fixtures';
import { fromBigInt } from '../lib/number';
import * as rpc from '../lib/rpc';
import { isTestingEnv } from '../lib/testing/utils';
import { addRpcError } from '../lib/transientNotification';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as workflowpb from '../proto/workflow/workflow_pb';
import { analytics } from '../services/analytics';

// JobState aggregates incremental GetJobReply response fragments sent by the server.
export interface JobState {
  // Copy of the latest GetJobReply.last_incarnation.
  lastIncarnation: number;
  // Copy of the latest GetJobReply.last_incarnation_stat.
  lastIncarnationStat: workflowpb.JobIncarnationStat;
  lastIter: number;
  // The full list of solutions generated for the job.
  solutions: frontendpb.Solution[];
}

// Map of fetched jobs, keyed by jobId.
//
export const jobsState = atomFamily<JobState | null, string>({
  key: 'jobstate',
  default: isTestingEnv() ? jobStateFixture : null,

  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

const rpcPool = new rpc.StreamingRpcPool<
  frontendpb.GetJobRequest,
  frontendpb.GetJobReply
>('GetJob', rpc.client.getJob);

export const startJobStateRpc = (
  projectId: string,
  workflowId: string,
  jobId: string,
  setJobState: (state: JobState) => void,
) => {
  if (!projectId || !workflowId || !jobId) {
    return () => { };
  }
  let lastState: JobState | null = null;

  const cancelRpc = rpcPool.start(
    jobId,
    () => new frontendpb.GetJobRequest({
      jobSpec: new frontendpb.JobSpec({ projectId, workflowId, jobId }),
    }),
    (reply: frontendpb.GetJobReply) => {
      if (!reply.incrementalUpdate) { // full update
        lastState = {
          lastIncarnation: 0,
          lastIter: fromBigInt(reply.latestIter),
          lastIncarnationStat: new workflowpb.JobIncarnationStat(),
          solutions: [],
        };
      }
      if (!lastState) {
        throw Error('incremental response without full update');
      }
      const solutions = [...lastState.solutions];
      reply.solution.forEach((soln) => solutions.push(soln));
      lastState = {
        lastIter: fromBigInt(reply.latestIter),
        lastIncarnation: reply.lastIncarnation,
        lastIncarnationStat: reply.lastIncarnationStat || lastState.lastIncarnationStat,
        solutions,
      };
      setJobState(lastState);
    },
    (err: ConnectError) => addRpcError('GetJob failed', err),
  );
  return cancelRpc;
};
// Start polling for new job status. It returns the most uptodate job state, or
// null if it hasn't been fetched yet.  This function must be called inside a
// component.
export function useJobState(
  projectId: string,
  workflowId: string,
  jobId: string,
): JobState | null {
  const setJobState = useSetRecoilState(jobsState(jobId));
  useEffect(
    () => {
      analytics.updateUserProperties({ jobId });
      startJobStateRpc(projectId, workflowId, jobId, setJobState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, workflowId, jobId],
  );
  return useRecoilValue(jobsState(jobId));
}
