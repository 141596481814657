// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { isGroupVisible, toggleVisibility } from '../../../lib/entityGroupUtils';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem } from '../../../lib/treeUtils';
import { useEntityGroupMap } from '../../../recoil/entityGroupState';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useCopySimAnnotation } from '../../hooks/nodeDuplication/useCopySimAnnotation';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { ContextMenuSection, TreeRow, VisibilityControl } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'parallelogramOutline' };

// A row in the simulation tree representing a monitor plane
export const MonitorPlaneTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { viewState, visibilityMap, setVisibility } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Recoil
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteMonitorPlaneNode, postDeleteNodeIds } = useNodeDeletion();
  const duplicateRow = useCopySimAnnotation();

  // == Data
  const isVisible = isGroupVisible(visibilityMap, entityGroupMap, node.id);
  const isSelected = selectedNodeIds.includes(props.node.id);
  const toggleIds = new Set(isSelected ? selectedNodeIds : [node.id]);

  const visibilityControl: VisibilityControl = {
    toggle: () => {
      setVisibility(toggleVisibility(visibilityMap, entityGroupMap, toggleIds, !isVisible));
    },
    disabled: !viewState,
    show: isVisible,
  };

  const deleteRow = useCallback(async () => {
    if (await deleteMonitorPlaneNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteMonitorPlaneNode, node.id, postDeleteNodeIds]);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];

    const disabled = !canDelete(node.type, node.id);
    const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
    const duplicateItem = duplicateTreeNodeMenuItem(() => duplicateRow(node.id, 'plane'), disabled);
    sections.push({ section: 'crud', menuItems: [duplicateItem, deleteItem] });

    return sections;
  }, [canDelete, deleteRow, node.id, node.type, duplicateRow]);

  return (
    <TreeRow
      {...props}
      canMultiSelect
      getExtraContextMenuItems={getExtraContextMenuItems}
      primaryIcon={PRIMARY_ICON}
      propertiesControl
      renaming={renaming}
      visibility={visibilityControl}
    />
  );
};
