// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useParaviewContext } from '../Paraview/ParaviewManager';

import { useVisFilterParentNode } from './useVisFilterParentNode';

// This hooks returns a function which checks whether a new filter can be created depending on the
// provided ParaviewRpc.TreeNodeType argument.
export const useIsFilterDisabled = () => {
  // Context
  const { paraviewClientState, viewState } = useParaviewContext();

  // Hooks
  // The parent to which the new node will be added as a child. If the currently selected node is a
  // filter node, return it. Otherwise, return the root.
  const parentNode = useVisFilterParentNode();

  // Other props
  const clientDisconnected = paraviewClientState.client === null;

  // If our parent node isn't the root, we have selected a filter node,
  // so we should disable certain buttons
  const isFilterNodeSelected = !viewState || (parentNode !== viewState.root);

  // Data from topologically 1 dimensional filter objects is used as chart data series.
  // We do not support any use cases of further filtering these objects.
  const topo1dFilterNodeSelected = (
    isFilterNodeSelected &&
    (
      (parentNode?.param.typ === ParaviewRpc.TreeNodeType.LINE) ||
      (parentNode?.param.typ === ParaviewRpc.TreeNodeType.INTERSECTION_CURVE)
    )
  );

  // Identify whether the selected node is a specific type in order to disable certain filters.
  // Such a measure can reduce citizen user error.
  // Data from a surface LIC object includes a LIC field.
  // Child node filters would need to apply the texture to the child geometry.
  // However, there are not very strong use cases of further filtering the surface LIC.
  // Retain ability to create child filters - vector glyph and intersection curve.
  const licNodeSelected = (
    isFilterNodeSelected &&
    parentNode?.param.typ === ParaviewRpc.TreeNodeType.SURFACE_L_I_C
  );
  // Similarly, streamlines objects do not need further filtering
  // with the exception of applying a clip or vector glyph.
  const streamlinesNodeSelected = (
    isFilterNodeSelected &&
    parentNode?.param.typ === ParaviewRpc.TreeNodeType.STREAMLINES
  );

  return (type: ParaviewRpc.TreeNodeType) => {
    switch (type) {
      case ParaviewRpc.TreeNodeType.CLIP:
        return clientDisconnected || topo1dFilterNodeSelected || licNodeSelected;
      case ParaviewRpc.TreeNodeType.GLYPH:
        return clientDisconnected || topo1dFilterNodeSelected;
      case ParaviewRpc.TreeNodeType.LINE:
      case ParaviewRpc.TreeNodeType.STREAMLINES:
      case ParaviewRpc.TreeNodeType.SURFACE_L_I_C:
      case ParaviewRpc.TreeNodeType.MULTI_SLICE:
      case ParaviewRpc.TreeNodeType.EXTRACT_SURFACES:
        return clientDisconnected || isFilterNodeSelected;
      case ParaviewRpc.TreeNodeType.INTERSECTION_CURVE:
        return clientDisconnected || topo1dFilterNodeSelected || streamlinesNodeSelected;
      case ParaviewRpc.TreeNodeType.CONTOUR:
      case ParaviewRpc.TreeNodeType.SLICE:
      case ParaviewRpc.TreeNodeType.THRESHOLD:
        return (
          clientDisconnected ||
          topo1dFilterNodeSelected ||
          licNodeSelected ||
          streamlinesNodeSelected
        );
      case ParaviewRpc.TreeNodeType.STREAM_TRACER:
        return clientDisconnected;
      default:
        return false;
    }
  };
};
