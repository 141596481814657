// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { listToPv } from '../../lib/Vector';
import { applyParamToBoxWidget, applyParamToPlaneWidget } from '../../lib/lcvis/classes/widgets/widgetUtils';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { newPlaneParam } from '../../lib/visUtils';
import { BoxClipParam, PlaneParam } from '../../pvproto/ParaviewRpc';

export type GeoClipStateType = {
  // whether or not we are showing the clip
  active: boolean;
  // whether the controls for the clip should be shown or not
  showControls: boolean;
  // the current state of the clip
  param: PlaneParam | BoxClipParam | null;

  // the source of the last edit passed to the clip
  editSource: 'lcvis' | 'ui'
};

/**
 * In the geometry tab, we have 1 active clip at a time. The clip can be either a box or a plane.
 * This is the state that stores the clip.
 */
const geometryClipState = atomFamily<GeoClipStateType, string>({
  key: 'geometryClipState',
  default: {
    active: false,
    showControls: true,
    editSource: 'ui',
    param: null,
  },
  effects: [
    ({ onSet }) => {
      onSet((newVal, prevVal) => {
        if (newVal.editSource === 'lcvis') {
          return;
        }
        lcvHandler.queueDisplayFunction('update geo clip', (display) => {
          const { planeWidget, boxClipWidget } = display.widgets;
          const { param, active, showControls } = newVal;
          if (!planeWidget || !boxClipWidget) {
            return;
          }
          if (!active) {
            display.deactivateWidget(boxClipWidget);
            display.deactivateWidget(planeWidget);
            return;
          }
          if (showControls) {
            boxClipWidget.showControls();
            planeWidget.showControls();
          } else {
            boxClipWidget.hideControls();
            planeWidget.hideControls();
          }
          switch (param?.typ) {
            case 'BoxClip': {
              display.activateWidget(boxClipWidget);
              applyParamToBoxWidget(boxClipWidget, param);
              display.deactivateWidget(planeWidget);
              break;
            }
            case 'Plane': {
              display.activateWidget(planeWidget);
              applyParamToPlaneWidget(planeWidget, param);
              display.deactivateWidget(boxClipWidget);
              break;
            }
            case undefined: {
              display.deactivateWidget(boxClipWidget);
              display.deactivateWidget(planeWidget);
              break;
            }
            default: // none
          }
        });
      });
    },
  ],
});

export const useSetGeoClipState = (
  projectId: string,
) => useSetRecoilState(geometryClipState(projectId));
export const useGeoClipState = (projectId: string) => useRecoilState(geometryClipState(projectId));
export const useGeoClipValue = (projectId: string) => useRecoilValue(geometryClipState(projectId));

/**
 * @param projectId the project id
 * @returns a callback to activate or deactivate the geometry clip widget.
 */
export const useToggleGeometryClip = (projectId: string) => {
  const [geoClipState, setGeoClipState] = useGeoClipState(projectId);

  const startGeoClip = useCallback(() => {
    lcvHandler.queueDisplayFunction('set clip callbacks', (display) => {
      const { planeWidget, boxClipWidget } = display.widgets;
      if (!planeWidget || !boxClipWidget) {
        return;
      }
      if (planeWidget.hasCallback && boxClipWidget.hasPositionCallback) {
        return;
      }
      if (geoClipState.active) {
        planeWidget.setEditStateCallback(null);
        boxClipWidget.setEditStateCallback(null);
        return;
      }

      planeWidget.setCallback((newState) => {
        setGeoClipState((oldState) => ({
          ...oldState,
          editSource: 'lcvis',
          param: {
            typ: 'Plane',
            origin: listToPv(newState.position),
            normal: listToPv(newState.normal),
          },
        }));
      });

      boxClipWidget.setPositionUpdatedCallback((newState) => {
        const { center, size, rotation } = newState;
        setGeoClipState((oldState) => ({
          ...oldState,
          editSource: 'lcvis',
          param: {
            typ: 'BoxClip',
            position: listToPv(center),
            length: listToPv(size),
            rotation: listToPv(rotation),
          },
        }));
      });
    });

    setGeoClipState((oldState) => {
      const newState = {
        ...oldState,
        active: !oldState.active,
        editSource: 'ui',
      } as GeoClipStateType;

      if (oldState.param === null) {
        // The param will only be null if we are activating the clip for the first time.
        const bounds = lcvHandler.display?.getCurrentDatasetBounds() ?? [0, 0, 0, 0, 0, 0];
        const newParam = newPlaneParam(bounds);
        newState.param = newParam;
      }
      return newState;
    });
  }, [geoClipState.active, setGeoClipState]);

  return startGeoClip;
};
