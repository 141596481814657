// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { CaretDownIcon } from '../../svg/CaretDownIcon';
import './FolderTriangle.scss';

/**
 * FolderTriangle is a simple component that encapsulates styling and functionality of a triangle
 * that appears next to a folder.  It points right when the folder is closed, and it points down
 * when the folder is open. Parent components may control the color by simply setting the color CSS
 * property; the arrow (being an CaretDownIcon instance) will inherit the parent color. */

export interface FolderTriangleProps {
  open: boolean;
}

export const FolderTriangle = (props: FolderTriangleProps) => {
  const { open } = props;

  return (
    <div
      className={cx('folderTriangle', { open })}
      data-locator={open ? 'folderOpened' : 'folderClosed'}>
      <CaretDownIcon maxWidth={6} />
    </div>
  );
};
