// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { formatDateToHHMM } from './formatDate';

export const ttl = 1.44E7; // 4hrs in ms
export const fetchInterval = 5 * 60 * 1_000; // 15 minutes

// Interfaces to parse status page JSON response into.
interface Page {
  'id': string,
  'time_zone': string,
  [key: string]: any
}

export interface Maintenance {
  'id': string,
  'scheduled_for': string,
  'scheduled_until': string,
  [key: string]: any
}

export interface StatusPageResponse {
  'page': Page,
  'scheduled_maintenances': Maintenance[],
  [key: string]: any
}

const NOTIF_ENTRY_KEY_PREFIX = 'lc-notif-';

// User-defined type guards
function isPage(obj: any): obj is Page {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'id' in obj &&
    'time_zone' in obj &&
    obj.id !== null
  );
}

function isMaintenance(obj: any): obj is Maintenance {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'id' in obj &&
    'scheduled_for' in obj &&
    'scheduled_until' in obj &&
    obj.id !== null
  );
}

export function isStatusPageResponse(obj: any): obj is StatusPageResponse {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'page' in obj &&
    'scheduled_maintenances' in obj &&
    isPage(obj.page) &&
    Array.isArray(obj.scheduled_maintenances) &&
    obj.scheduled_maintenances.every(isMaintenance)
  );
}

// Stores whether a user has dismissed certain prod notifications.
// This is stored in local storage for each maintenance.
export interface NotifState {
  // Maintenace id
  id: string,
  // Stores whether user has dismissed a notification.
  // ith index corresponds to i+1 th notification.
  // (i.e 0th index -> firstNotification)
  dismissed: boolean[],
  // Unix time in ms
  expiration: number,
}

// Creates notification message given the scheduledFor date and duration of
// the maintenace (in ms).
export function createNotifMessage(scheduledFor: Date, duration: number) {
  const hhmm = formatDateToHHMM(scheduledFor);
  const durationStr = (duration / (60_000)).toFixed(0);
  return `Luminary Cloud will be offline for regular updates at ${hhmm}. ` +
    `Downtime is expected to be about ${durationStr} minutes.`;
}

export function notifEntryKey(statusId: string): string {
  return `${NOTIF_ENTRY_KEY_PREFIX}${statusId}`;
}

function isNotifEntry(statusId: string): boolean {
  return (
    statusId.startsWith(NOTIF_ENTRY_KEY_PREFIX) &&
    statusId.length > NOTIF_ENTRY_KEY_PREFIX.length
  );
}

// Removes expired "notifState" entries from local storage if needed.
export const cleanup = () => {
  const curTime = new Date().getTime();
  const expKeys = [];
  const numEntries = localStorage.length;
  for (let i = 0; i < numEntries; i += 1) {
    const key = localStorage.key(i);
    if (key && isNotifEntry(key)) {
      const item = localStorage.getItem(key);
      const entry = JSON.parse(item as string);
      if (curTime >= entry.expiration) {
        expKeys.push(key);
      }
    }
  }
  expKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
};
