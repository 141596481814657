// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 56;
const baseHeight = 54;
export const RedoIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="redo"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M43,0.045L55.121,12.167C56.293,13.338 56.293,15.238 55.121,16.409L43,28.531L38.757,24.288
           L45.757,17.288L23,17.288C13.37,17.288 6,24.387 6,32.621C6,40.855 13.37,47.955 23,47.955
           L39.333,47.955L39.333,53.955L23,53.955C10.563,53.955 0,44.654 0,32.621
           C0,20.589 10.563,11.288 23,11.288L45.757,11.288L38.757,4.288L43,0.045Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
