// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { useLcvisCancelEdit } from '../../recoil/lcvis/hooks/useLcvisCancelEdit';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useProjectContext } from '../context/ProjectContext';

/**
 * Cancel edit state, considering whether LcVis is enabled
 * @returns cancel function
 */
export const useCancelEdit = () => {
  const { cancelEditState } = useParaviewContext();
  const { projectId } = useProjectContext();

  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisCancelEdit = useLcvisCancelEdit();

  return useCallback(() => {
    if (lcvisEnabled) {
      lcvisCancelEdit();
    } else {
      cancelEditState();
    }
  }, [cancelEditState, lcvisCancelEdit, lcvisEnabled]);
};
