// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect } from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';
import { CheckmarkIcon } from '../svg/CheckmarkIcon';

const MINIMUM_LENGTH = 8;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      padding: '12px 20px',
      backgroundColor: colors.surfaceMedium2,
      border: `1px solid ${colors.surfaceLight1}`,
      borderRadius: '8px',
      fontSize: '12px',
      lineHeight: '16px',
    },
    intro: {
      color: colors.highEmphasisText,
    },
    item: {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      paddingLeft: '2px',
      color: colors.lowEmphasisText,

      '&.passed': {
        color: colors.highEmphasisText,
      },
    },
  }),
  { name: 'PasswordStrength' },
);

interface Condition {
  label: string;
  validate: (value: string) => boolean;
}

export const conditions: Condition[] = [
  {
    label: `At least ${MINIMUM_LENGTH} characters in length`,
    validate: (value) => value.length >= MINIMUM_LENGTH,
  },
  {
    label: 'At least 1 lower case letter (a-z)',
    validate: (value) => /[a-z]/.test(value),
  },
  {
    label: 'At least 1 upper case letter (A-Z)',
    validate: (value) => /[A-Z]/.test(value),
  },
  {
    label: 'At least 1 number (i.e. 0-9)',
    validate: (value) => /\d/.test(value),
  },
  {
    label: 'At least 1 special character (e.g. !@#$%&*)',
    validate: (value) => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~]/.test(value),
  },
];

export interface PasswordStrengthProps {
  password: string;
  onValidation?: (isValid: boolean) => void;
}

export const PasswordStrength = (props: PasswordStrengthProps) => {
  // Props
  const { password, onValidation } = props;

  // Hooks
  const classes = useStyles();

  // Effects
  useEffect(() => {
    if (onValidation) {
      onValidation(conditions.every((cond) => cond.validate(password)));
    }
  }, [password]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <div className={classes.intro}>Password must include:</div>
      {conditions.map((cond) => {
        const condValid = cond.validate(password);

        return (
          <div className={cx(classes.item, { passed: condValid })} key={cond.label}>
            <CheckmarkIcon
              color={condValid ? colors.green500 : colors.disabledType}
              maxWidth={8}
            />
            {cond.label}
          </div>
        );
      })}
    </div>
  );
};
