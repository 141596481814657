// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import * as Vector from '../../../lib/Vector';
import { QuantityType } from '../../../proto/quantity/quantity_pb';
import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import Form from '../../Form';
import { InputDescription } from '../../Form/InputDescription';
import { Vector3Input } from '../../Form/Vector3Input';
import { PlaneInput, PlaneParam } from '../PlaneInput';

export type ClipSliceInputType = 'Clip' | 'Slice' | 'BoxClip';

interface ClipSliceInputProps {
  onPlaneChange: (newParam: PlaneParam) => void;
  onPlaneCommit: (newParam: PlaneParam) => void;
  readonly: boolean;
  normalWarning?: string;
  type: ClipSliceInputType;
  param: ParaviewRpc.PlaneParam | ParaviewRpc.BoxClipParam;
  onBoxCommit?: (newParam: ParaviewRpc.BoxClipParam) => void;
}

export const ClipSliceInput = (props: ClipSliceInputProps) => {
  const {
    onPlaneChange,
    onPlaneCommit,
    readonly,
    normalWarning,
    param,
    type,
    onBoxCommit,
  } = props;

  switch (type) {
    case 'Slice':
    case 'Clip': {
      return (
        <div>
          <PlaneInput
            onChange={onPlaneChange}
            onCommit={onPlaneCommit}
            param={{
              origin: Vector.toProto((param as ParaviewRpc.PlaneParam).origin),
              normal: Vector.toProto((param as ParaviewRpc.PlaneParam).normal),
            }}
            readOnly={readonly}
          />
          {!!normalWarning && (
            <div style={{ paddingTop: '8px' }}>
              <InputDescription faultType="error" value={normalWarning} />
            </div>
          )}
        </div>
      );
    }
    case 'BoxClip':
      return (
        <div>
          <Form.LabeledInput help="Center position of the box clip" label="Box Center">
            <Vector3Input
              disabled={readonly}
              onCommit={(value) => {
                const newFilterParams: ParaviewRpc.BoxClipParam =
                {
                  ...(param as ParaviewRpc.BoxClipParam),
                  position: Vector.toPvProto(value),
                };
                onBoxCommit?.(newFilterParams);
              }}
              quantityType={QuantityType.LENGTH}
              value={Vector.toProto((param as ParaviewRpc.BoxClipParam).position)}
            />
          </Form.LabeledInput>
          <Form.LabeledInput help="Length of the box clip along each axis." label="Length">
            <Vector3Input
              disabled={readonly}
              onCommit={(value) => {
                const newFilterParams: ParaviewRpc.BoxClipParam =
                {
                  ...(param as ParaviewRpc.BoxClipParam),
                  length: Vector.toPvProto(value),
                };
                onBoxCommit?.(newFilterParams);
              }}
              quantityType={QuantityType.LENGTH}
              value={Vector.toProto((param as ParaviewRpc.BoxClipParam).length)}
            />
          </Form.LabeledInput>
          <Form.LabeledInput help="Rotation of the box around each axis." label="Rotation">
            <Vector3Input
              disabled={readonly}
              onCommit={(value) => {
                const newFilterParams: ParaviewRpc.BoxClipParam =
                {
                  ...(param as ParaviewRpc.BoxClipParam),
                  rotation: Vector.toPvProto(value),
                };
                onBoxCommit?.(newFilterParams);
              }}
              quantityType={QuantityType.DEGREE}
              value={Vector.toProto((param as ParaviewRpc.BoxClipParam).rotation)}
            />
          </Form.LabeledInput>
        </div>
      );
    default:
      throw Error(`Invalid clip / slice type ${type}`);
  }
};
