// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 14;
const baseHeight = 14;

export const TransparencyIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="transparency"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M1 9.5C0.723858 9.5 0.5 9.27614 0.5 9V1C0.5 0.723858 0.723858 0.5 1 0.5H9C9.27614 0.5 9.5
        0.723858 9.5 1V9C9.5 9.00756 9.49983 9.01508 9.4995 9.02255C9.48773 9.28825 9.2686 9.50004 9
        9.50004L1 9.5ZM1.83 8.17V1.83H8.17V4.5H5C4.72386 4.5 4.5 4.72386 4.5 5V8.17H1.83Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M4.5 13V10.83H9C10.0107 10.83 10.83 10.0107 10.83 9.00004V4.5H13C13.2761 4.5 13.5 4.72386
        13.5 5V13C13.5 13.2761 13.2761 13.5 13 13.5H5C4.72386 13.5 4.5 13.2761 4.5 13Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
