// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useRecoilCallback } from 'recoil';

import { asyncWrapper, makeSetterOrUpdater } from '../../lib/contextUtils';
import { copyToSetupCallback, onParamUpdateCallback } from '../../lib/projectContextUtils';
import { useUserCanEdit } from '../../lib/projectRoles';
import { pickAnyJobId } from '../../lib/workflowUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import * as workflowpb from '../../proto/workflow/workflow_pb';
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { useMeshReadyState } from '../../recoil/useMeshReadyState';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { projectConfigState } from '../../recoil/workflowConfig';
import { useWorkflowState } from '../../recoil/workflowState';
import { useIsGeometryView } from '../../state/internal/global/currentView';

export interface ProjectInputs {
  projectId: string;
  workflowId: string;
  selectedJobId: string;
  geometryId?: string;
  readOnly?: boolean;
}

export interface ProjectDescriptors {
  projectId: string;
  workflowId: string;
  jobId: string;
  // Indicates the geometryId being modified on screen. If '', we are in the geometry tab. Only
  // meaningful if currentView === CurrentView.GEOMETRY.
  geometryId: string;
  readOnly: boolean;
}

export interface ProjectOperations {
  // Called to update the simulation parameters.
  onParamUpdate: (newParam: simulationpb.SimulationParam) => void;
  asyncOnParamUpdate: (newParam: simulationpb.SimulationParam) => Promise<void>;
  // Called to update the workflow config.
  onNewWorkflowConfig: (newConfig: workflowpb.Config) => void;
  copyToSetup: () => Promise<void>;
}

export const useProjectOperations = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
): ProjectOperations => {
  const asyncOnNewWorkflowConfig = useRecoilCallback(({
    snapshot: { getPromise }, set,
  }) => async (newConfig: workflowpb.Config) => {
    const setWorkflowConfig = makeSetterOrUpdater(set, projectConfigState(projectId));
    setWorkflowConfig(newConfig);
  });

  const copyToSetup = useRecoilCallback(copyToSetupCallback);

  const onParamUpdate = useRecoilCallback(onParamUpdateCallback);
  const wrapOnParamUpdate = async (newParam: simulationpb.SimulationParam) => {
    // In read-only mode we ignore calls to this function since by design we should not update
    // simulation-related configs in read-only mode. Allowing this could lead to state corruption
    // and weird interactions between the simulation and setup tabs.
    !readOnly && await onParamUpdate(
      { projectId, workflowId, jobId },
      newParam,
      undefined,
    );
  };

  return {
    onParamUpdate: asyncWrapper(wrapOnParamUpdate),
    onNewWorkflowConfig: asyncWrapper(asyncOnNewWorkflowConfig),
    asyncOnParamUpdate: wrapOnParamUpdate,
    copyToSetup: async () => copyToSetup({ projectId, workflowId, jobId }),
  };
};

/**
 * The project model transforms a set of project inputs into common project descriptors and project
 * persistence operations.
 */
export const useProjectModel = (inputs: ProjectInputs): ProjectDescriptors & ProjectOperations => {
  // == Unpack
  const { projectId, workflowId, selectedJobId, geometryId } = inputs;

  // == Recoil
  const workflow = useWorkflowState(projectId, workflowId);
  const projectMetadata = useProjectMetadata(projectId);
  const isGeometryPending = useIsGeometryPending(projectId);
  const isGeometryView = useIsGeometryView();

  // == Data
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);
  // `selectedJobId` might be empty if it not provided with the URL. If that is the case, we set a
  // jobId here.
  const jobId = selectedJobId || (workflow && pickAnyJobId(workflow)) || '';

  const meshReadyState = useMeshReadyState(projectId, workflowId, jobId);

  const readOnly = (
    !meshReadyState ||
    inputs.readOnly ||
    (isGeometryPending && !isGeometryView) ||
    !userCanEdit
  );

  const operations = useProjectOperations(projectId, workflowId, jobId, readOnly);

  return {
    projectId,
    workflowId,
    jobId,
    geometryId: geometryId || '',
    readOnly,
    ...operations,
  };
};
