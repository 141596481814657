// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import {
  DefaultValue,
  atomFamily,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { LcvisCameraStateType, defaultLcvCamera } from '../../lib/lcvis/types';
import * as persist from '../../lib/persist';
import { syncProjectStateEffect } from '../../lib/recoilSync';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import { activeVisUrlState } from '../vis/activeVisUrl';

import { LcvisKvStoreKey, makeLcvMeshKeys } from './lcvisPersistUtils';

function serializeCameraState(val: LcvisCameraStateType): Uint8Array {
  const newVal: LcvisCameraStateType = { ...val, editSource: 'KVStore' };
  const value = new frontendpb.LCVisCameraState({ json: JSON.stringify(newVal) });
  return value.toBinary();
}

function deserializeCameraState(val: Uint8Array): LcvisCameraStateType {
  const defaultCam = defaultLcvCamera();
  let cameraState = (val.length ?
    JSON.parse(frontendpb.LCVisCameraState.fromBinary(val).json) :
    defaultCam
  ) as LcvisCameraStateType;
  // For some (unknown) reason, the JSON that comes back may have values set to null.
  // These get recasted to 0 and can create invalid params to LCVis, causing stuff to crash.
  // So our solution is just to reset the camera if any of the returned values are null.
  Object.values(cameraState).some((value) => {
    if (Array.isArray(value) && value.includes(null)) {
      cameraState = defaultCam;
      return true;
    }
    return false;
  });
  cameraState.isDefault ??= false;

  if (val.length) {
    // Check that the camera state from the parsed JSON is of the correct type. If there are any
    // missing or added fields, revert to the default camera state. This allows us to
    // change the LcvisCameraState type as needed and not worry about changing the proto, since
    // any old values will be automatically overwritten with the new default.
    if (!Object.entries(defaultCam).every(([key, setting]) => (
      key in cameraState &&
      typeof cameraState[key as keyof typeof defaultCam] === typeof setting
    )) || Object.keys(defaultCam).length !== Object.keys(cameraState).length) {
      cameraState = defaultCam;
    }
  }

  return cameraState;
}

export const lcvisCameraState = atomFamily<LcvisCameraStateType, LcvisKvStoreKey>({
  key: 'lcvisCameraState',
  default: (key: LcvisKvStoreKey) => {
    if (!key.lcvMeshKeys.length) {
      return defaultLcvCamera();
    }
    return persist.getProjectState(key.projectId, key.lcvMeshKeys, deserializeCameraState);
  },
  effects: (key: LcvisKvStoreKey) => [
    ({ onSet }) => {
      onSet((newVal: LcvisCameraStateType) => {
        lcvHandler.queueDisplayFunction('updateCameraState', (display) => {
          if (newVal.isDefault) {
            display.widgets.arcballWidget?.resetCamera();
          } else if (newVal.editSource !== 'LCVis') {
            // only apply the camera changes to LCVis if the update came from outside LCVis. (it
            // wouldn't make sense to update LCVis from recoil if recoil was just updated by LCVis)
            display.widgets.arcballWidget?.setCameraState(newVal);
          }
        });
      });
    },
    key.lcvMeshKeys.length ?
      syncProjectStateEffect(
        key.projectId,
        key.lcvMeshKeys[0],
        deserializeCameraState,
        serializeCameraState,
      ) : () => { },
  ],
});

export const lcvisCameraSelector = selectorFamily<LcvisCameraStateType, persist.RecoilProjectKey>({
  key: 'lcvisCameraSelector',
  get: (key: persist.RecoilProjectKey) => ({ get }) => {
    const { projectId } = key;
    const activeVisUrl = get(activeVisUrlState(key));
    const lcvMeshKeys = makeLcvMeshKeys('lcvisCamera', activeVisUrl);
    return get(lcvisCameraState({ projectId, lcvMeshKeys }));
  },
  set: (key: persist.RecoilProjectKey) => (
    { get, set },
    newVal: LcvisCameraStateType | DefaultValue,
  ) => {
    const { projectId } = key;
    let activeVisUrl: string = '';
    try {
      activeVisUrl = get(activeVisUrlState(key));
    } catch {
      // activeVisUrlState is an async state, so this call can fail for timing reasons.
      // If it does, ignore it.
      return;
    }
    const lcvMeshKeys = makeLcvMeshKeys('lcvisCamera', activeVisUrl);
    if (newVal instanceof DefaultValue) {
      // newVal will only be DefaultValue if someone calls resetRecoilState. So in that case
      // set the state to the default lcvCamera.
      set(lcvisCameraState({ projectId, lcvMeshKeys }), defaultLcvCamera());
      return;
    }
    set(lcvisCameraState({ projectId, lcvMeshKeys }), newVal);
  },
});

export const useLcvisCameraState = (
  key: persist.RecoilProjectKey,
) => useRecoilState(lcvisCameraSelector(key));

export const useSetLcvisCameraState = (
  key: persist.RecoilProjectKey,
) => useSetRecoilState(lcvisCameraSelector(key));

export const useLcvisCameraValue = (
  key: persist.RecoilProjectKey,
) => useRecoilValue(lcvisCameraSelector(key));
