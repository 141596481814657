// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { LcvModule } from '../../../types';
import { LcvObject } from '../../base/LcvObject';
import { AnnotationCategory, AnnotationParam, AnnotationType } from '../types';

export abstract class LcvRefinementRegionAnnotation extends LcvObject {
  annotationCategory: AnnotationCategory;

  // realType (AnnotationRealType) is the concrete class we instantiate,
  // eg. refinement box or sphere, triad or grid axes
  // type (AnnotationType) is the category of annotation used,
  // eg. refinement region or coordinate information
  // We use the type (category) information to filter out certain types of annotations as needed,
  // eg. to perform an operation on only refinement regions.
  constructor(
    lcv: LcvModule,
    realType: AnnotationType,
    category: AnnotationCategory,
    sessionHandle: number,
  ) {
    super(lcv, lcv.newAnnotation(sessionHandle, realType.toString(), 0).annotation, sessionHandle);
    this.annotationCategory = category;
  }

  abstract updateAnnotationParam(param: AnnotationParam): void;
}
