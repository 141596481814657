// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 92;
const baseHeight = 100;
export const CubeSparkleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="cubeSparkle"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M86.657,32.347L81.478,34.424C80.85,34.681 80.258,35.005 79.709,35.386
           C79.554,35.494 79.403,35.606 79.254,35.723C78.922,35.986 78.609,36.271 78.315,36.576
           L78.315,35.069L66.71,41.786L65.773,39.469C65.525,38.863 65.215,38.29 64.851,37.758
           C64.753,37.615 64.651,37.474 64.545,37.337C64.082,36.735 63.547,36.193 62.952,35.723
           C62.816,35.616 62.677,35.512 62.535,35.412C62.31,35.254 62.077,35.106 61.838,34.968
           L74.522,27.626L64.063,21.589C64.242,21.395 64.413,21.193 64.574,20.985
           C64.688,20.838 64.798,20.687 64.903,20.534C65.268,20.001 65.577,19.428 65.825,18.823
           L67.742,14.081L76.665,19.232C76.878,19.687 77.127,20.123 77.407,20.534
           C77.505,20.677 77.608,20.818 77.713,20.955C78.176,21.556 78.712,22.098 79.307,22.569
           C79.443,22.676 79.582,22.78 79.724,22.88C80.283,23.272 80.888,23.605 81.53,23.867
           L86.657,25.935L86.657,32.347ZM55.584,7.062L43.35,0L0.043,25L0.043,75L43.35,100L86.657,75
           L86.657,51.273L85.502,54.131C84.009,57.904 81.397,60.585 78.315,62.173L78.315,70.184
           L47.521,87.96L47.521,52.892L54.557,48.819L53.643,46.558L46.671,43.746L43.35,45.668
           L12.178,27.626L43.35,9.632L49.735,13.318L53.697,11.729L55.584,7.062ZM8.384,70.184
           L8.384,35.069L39.179,52.892L39.179,87.96L8.384,70.184Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M90.943,27.664L81.53,23.867C79.242,22.931 77.422,21.111 76.486,18.823L72.637,9.306
           C72.117,7.954 70.193,7.954 69.673,9.306L65.825,18.823
           C64.889,21.111 63.069,22.931 60.78,23.867L51.315,27.664
           C49.963,28.184 49.963,30.108 51.315,30.628L60.728,34.424
           C63.017,35.36 64.837,37.181 65.773,39.469L69.621,48.986
           C70.141,50.338 72.065,50.338 72.585,48.986L76.434,39.469
           C77.37,37.181 79.19,35.36 81.478,34.424L90.943,30.628
           C92.295,30.108 92.295,28.184 90.943,27.664Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
