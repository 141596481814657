// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { useRecoilCallback } from 'recoil';

import { useProjectContext } from '../../components/context/ProjectContext';
import * as flags from '../../flags';
import assert from '../../lib/assert';
import { setupLink } from '../../lib/navigation';
import { Logger } from '../../lib/observability/logs';
import * as rpc from '../../lib/rpc';
import { addRpcError } from '../../lib/transientNotification';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import { analytics } from '../../services/analytics';
import { entityGroupState } from '../entityGroupState';
import { useSetImportedModel } from '../importedModelState';
import { useEnabledExperiments } from '../useExperimentConfig';

import { useGeometryList } from './geometryListState';
import { geometryState, useGeometryState } from './geometryState';

const logger = new Logger('useGeometryMesh');

const KeepType = frontendpb.LoadToSetupBackendOperationsRequest_KeepGeometricalEntities;

export const useLoadToSetup = () => {
  const { projectId, geometryId } = useProjectContext();
  const geoState = useGeometryState(projectId, geometryId);
  const geometryList = useGeometryList(projectId);
  const experimentConfig = useEnabledExperiments();
  const setImportedModel = useSetImportedModel();

  const lcsurfaceTessellation = experimentConfig.includes(flags.lcsurfaceTessellation);
  const geoUsesTags = geometryList?.geometries[0]?.usesTags ?? false;

  // This function resets the appropriate kvstore state to move the current geometry into the setup
  // tab by calling a backend RPC.
  const loadAndNavToSetup = useRecoilCallback(({ set, refresh, snapshot }) => async () => {
    // Make sure that the geometry state is not stale.
    const geoStateSnapshot = await snapshot.getPromise(geometryState({ projectId, geometryId }));
    if (!geoStateSnapshot) {
      return;
    }

    setImportedModel(true);
    const lastEntry = geoState?.geometryHistory[geoStateSnapshot.geometryHistory.length - 1];
    const geometryVersionId = lastEntry?.historyEntry?.geometryVersionNewId;
    assert(!!geometryVersionId, 'Missing geometry version ID');
    // When loading to set up, we try to preserve tags by default for now. Only if the fflag is on.
    const keepMode = geoUsesTags ?
      KeepType.KEEP_TAG_GEOMETRICAL_ENTITIES : KeepType.DO_NOT_KEEP_GEOMETRICAL_ENTITIES;

    try {
      // This RPC will clear out all the kvstore and resources so that we can transition to the
      // setup tab.
      await rpc.callRetry(
        'LoadToSetupBackendOperations',
        rpc.client.loadToSetupBackendOperations,
        new frontendpb.LoadToSetupBackendOperationsRequest({
          projectId,
          geometryId,
          geometryVersionId,
          allowLcSurfaceTessellation: lcsurfaceTessellation,
          keepGeometricalEntities: keepMode,
        }),
      );

      // Track the successful load to setup operation
      analytics.track('Load to Setup', {
        projectId,
        geometryId,
        geometryVersionId,
        allowLcSurfaceTessellation: lcsurfaceTessellation,
      });
    } catch (err) {
      logger.error('Failed to reset geometry', err);
      addRpcError('Could not reset geometry', new Error());

      // Track the failed load to setup operation
      analytics.track('Load to Setup Failed', {
        projectId,
        geometryId,
        geometryVersionId,
        allowLcSurfaceTessellation: lcsurfaceTessellation,
        error: err?.message || 'Unknown error',
      });
      return;
    }
    // This refresh makes sure that all the other dependencies are updated and reduces issues when
    // going to setup.
    refresh(entityGroupState({ projectId, jobId: '', workflowId: '' }));
    // Force a reload when navigating to flush all the recoil states.
    window.location.href = setupLink(projectId);
  });

  return loadAndNavToSetup;
};
