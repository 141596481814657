// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 15;
export const IntersectingDisksIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="intersectingDisks"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M9.9,0.45
           C9.15,0.15 8.4,0 7.5,0
           C3.3,0 0,3.3 0,7.5
           C0,11.7 3.3,15 7.5,15
           C8.4,15 9.15,14.85 9.9,14.55
           C7.95,12.9 6.75,10.35 6.75,7.5
           C6.75,4.65 7.95,2.1 9.9,0.45Z
           M12,1.5
           C10.2,2.85 9,5.1 9,7.5
           C9,9.9 10.2,12.15 12,13.5
           C13.8,12.15 15,10.05 15,7.5
           C15,4.95 13.8,2.85 12,1.5Z
           M16.5,0
           C15.6,0 14.85,0.15 14.1,0.45
           C16.05,2.25 17.25,4.8 17.25,7.5
           C17.25,10.2 16.05,12.9 14.1,14.55
           C14.85,14.85 15.6,15 16.5,15
           C20.7,15 24,11.7 24,7.5
           C24,3.3 20.7,0 16.5,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
