// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { Loadable, noWait, selectorFamily, waitForAll } from 'recoil';

import { existingSolutionChoiceInParam } from '../lib/initializationUtils';
import { RecoilProjectKey } from '../lib/persist';
import { getSimulationParam } from '../lib/simulationParamUtils';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';
import * as workflowpb from '../proto/workflow/workflow_pb';

import { frontendMenuState } from './frontendMenuState';
import { currentConfigSelector } from './workflowConfig';
import { workflowState } from './workflowState';

export type InitData = {
  initState?: projectstatepb.InitializationState;
  initReference?: {
    initConfig: Loadable<workflowpb.Config>;
    workflowReply: Loadable<frontendpb.GetWorkflowReply | undefined>;
  }
}

export const initDataSelector = selectorFamily<InitData, RecoilProjectKey>({
  key: 'initDataSelector',
  get: (key: RecoilProjectKey) => ({ get }) => {
    const { projectId } = key;
    const [config, frontendMenu] = get(waitForAll([
      currentConfigSelector(key),
      frontendMenuState(key),
    ]));

    // If we have init state set (i.e. we have "existing solution" as initialization), we fetch the
    // param of the simulation we want to initialize from here. Also we need the status of the
    // simulation to check for divergence.
    const initState = frontendMenu.initState;
    const data: InitData = { initState };

    const simParam = getSimulationParam(config);
    const initExistingSolution = existingSolutionChoiceInParam(simParam);

    if (initState && initExistingSolution) {
      const { workflowId, jobId } = initState;
      // We don't want to block the UI and trigger a suspense while the data is being fetched.
      data.initReference = {
        initConfig: get(noWait(currentConfigSelector({ projectId, workflowId, jobId }))),
        workflowReply: get(noWait(workflowState({ projectId, workflowId }))),
      };
    }
    return data;
  },
});
