// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const DashedBoxIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="dashedBox"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M19.5,16.5
           L22.5,16.5
           L22.5,7.5
           L19.5,7.5
           L19.5,16.5Z
           M4.5,7.5
           L1.5,7.5
           L1.5,16.5
           L4.5,16.5
           L4.5,7.5Z
           M21,6
           C22.65,6 24,4.65 24,3
           C24,1.35 22.65,0 21,0
           C19.35,0 18,1.35 18,3
           C18,4.65 19.35,6 21,6Z
           M3,18
           C1.35,18 0,19.35 0,21
           C0,22.65 1.35,24 3,24
           C4.65,24 6,22.65 6,21
           C6,19.35 4.65,18 3,18Z
           M21,18
           C19.35,18 18,19.35 18,21
           C18,22.65 19.35,24 21,24
           C22.65,24 24,22.65 24,21
           C24,19.35 22.65,18 21,18Z
           M7.5,4.5
           L16.5,4.5
           L16.5,1.5
           L7.5,1.5
           L7.5,4.5Z
           M3,0
           C1.35,0 0,1.35 0,3
           C0,4.65 1.35,6 3,6
           C4.65,6 6,4.65 6,3
           C6,1.35 4.65,0 3,0Z
           M7.5,22.5
           L16.5,22.5
           L16.5,19.5
           L7.5,19.5
           L7.5,22.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
