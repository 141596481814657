// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { MainPageLayout } from '../../components/layout/page/Main';
import { useIsAdmin } from '../../recoil/useAccountInfo';

import { UsageSection } from './UsageSection';

const UsagePageBody = () => {
  const isAdmin = useIsAdmin();
  return (
    <MainPageLayout title="Account Usage">
      <UsageSection isAdmin={isAdmin} />
    </MainPageLayout>
  );
};

export default UsagePageBody;
