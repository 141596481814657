// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect } from 'react';

import {
  DefaultValue,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import { RgbColor, defaultVisBackgroundColor, rgbToRgba } from '../../lib/designSystem';
import { viewStateFixture } from '../../lib/fixtures';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { RecoilProjectKey } from '../../lib/persist';
import { isTestingEnv } from '../../lib/testing/utils';
import { meshMetadataSelector } from '../meshState';
import { newMeshKeys, paraviewInitialSettingsState, paraviewSettingsKeyPrefix } from '../paraviewState';

import { activeVisUrlState } from './activeVisUrl';

/**
 * A selector to get and set just the visualization background color.
 * It depends on the paraview initial settings state, which we use to persist the color choice.
*/
export const backgroundColorSelector = selectorFamily<RgbColor, RecoilProjectKey>({
  key: 'backgroundColorSelector',
  get: (key: RecoilProjectKey) => ({ get }) => {
    if (isTestingEnv()) {
      return viewStateFixture().attrs.backgroundColor ?? defaultVisBackgroundColor;
    }
    const activeUrl = get(activeVisUrlState(key));
    const metadata = get(meshMetadataSelector({ projectId: key.projectId, meshUrl: activeUrl }));
    const meshKeys = newMeshKeys(paraviewSettingsKeyPrefix, activeUrl, metadata);

    const paraviewState = get(paraviewInitialSettingsState({
      projectId: key.projectId,
      meshKeys,
    }));

    const backgroundColor = paraviewState.attrs.backgroundColor ?? defaultVisBackgroundColor;
    // Arrays, thus the conversion to string
    const shoudlToggleBodyClass = backgroundColor.join(',') !== defaultVisBackgroundColor.join(',');
    document.body.classList.toggle('lcvis-light-background', shoudlToggleBodyClass);
    return backgroundColor;
  },
  set: (key: RecoilProjectKey) => ({ get, set }, newVal) => {
    const newColor = newVal instanceof DefaultValue ? defaultVisBackgroundColor : newVal;
    const shouldToggleBodyClass = newColor.join(',') !== defaultVisBackgroundColor.join(',');
    document.body.classList.toggle('lcvis-light-background', shouldToggleBodyClass);

    // Now persist it via the paraview initial settings.
    const activeUrl = get(activeVisUrlState(key));
    const metadata = get(meshMetadataSelector({ projectId: key.projectId, meshUrl: activeUrl }));
    const meshKeys = newMeshKeys(paraviewSettingsKeyPrefix, activeUrl, metadata);

    const pvSettings = paraviewInitialSettingsState({ projectId: key.projectId, meshKeys });
    set(pvSettings, (settings) => ({
      ...settings,
      attrs: {
        ...settings.attrs,
        backgroundColor: newColor,
      },
    }));
  },
});

export const useBackgroundColorState = (key: RecoilProjectKey) => (
  useRecoilState(backgroundColorSelector(key))
);

export const useBackgroundColorLcvEffect = (
  key: RecoilProjectKey,
  lcvisReady: boolean,
) => {
  const backgroundColor = useRecoilValue(backgroundColorSelector(key));
  useEffect(() => {
    if (lcvisReady) {
      lcvHandler.queueDisplayFunction(
        'setBackgroundColor',
        (display) => display.setBackgroundColor(rgbToRgba(backgroundColor)),
      );
    }
  }, [backgroundColor, lcvisReady]);
};
