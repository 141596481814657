// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as feoutputpb from '../../proto/frontend/output/output_pb';
import * as referencevaluespb from '../../proto/output/reference_values_pb';
import { newAdFloat } from '../adUtils';

export const DEFAULT_OUTPUT_NODES = (
  (): feoutputpb.OutputNodes => new feoutputpb.OutputNodes({
    initialized: true,
    useRefValues: true,
    referenceValues: new referencevaluespb.ReferenceValues({
      referenceValueType: referencevaluespb.ReferenceValueType.REFERENCE_PRESCRIBE_VALUES,
      areaRef: newAdFloat(1.0),
      lengthRef: newAdFloat(1.0),
      pRef: newAdFloat(101325),
      tRef: newAdFloat(288.15),
      vRef: newAdFloat(1.0),
      useAeroMomentRefLengths: false,
      lengthRefPitch: newAdFloat(1.0),
      lengthRefYaw: newAdFloat(1.0),
      lengthRefRoll: newAdFloat(1.0),
    }),
  })
)();
