// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
// Boolean toggle to allow/disallow event listeners added with useEventListener.
// Will be set to false when a TextInput is in focus to prevent keydown conflicts.

import { selector, useRecoilValue } from 'recoil';

import { activeElementState } from './useActiveElement';
import { modalsActive } from './useModalsActive';

// Names of element nodeNames that, when focused, should disable key bindings.
const inputNames = ['INPUT', 'TEXTAREA'];

const allowEventListener = selector<boolean>({
  key: 'allowEventListener',
  get: ({ get }) => {
    const activeElement = get(activeElementState);
    let inputActive = false;
    const nodeName = activeElement?.nodeName;
    if (nodeName === 'DIV') {
      inputActive = (activeElement as HTMLDivElement).isContentEditable;
    } else {
      inputActive = !!nodeName && inputNames.includes(nodeName);
    }

    const openModals = get(modalsActive);
    return (!openModals && !inputActive);
  },
});

export const useAllowEventListener = () => useRecoilValue(allowEventListener);
