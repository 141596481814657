// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.

import { SvgIconSpec } from '../componentTypes/svgIcon';

// Returns default icon dimensions only if both maxWidth and maxHeight are undefined
export function getIconDims(
  maxHeight: number | undefined,
  maxWidth: number | undefined,
  defaultMaxHeight: number,
  defaultMaxWidth: number,
) {
  if (maxHeight === undefined && maxWidth === undefined) {
    return {
      maxHeight: defaultMaxHeight,
      maxWidth: defaultMaxWidth,
    };
  }

  return {
    ...maxHeight !== undefined ? { maxHeight } : undefined,
    ...maxWidth !== undefined ? { maxWidth } : undefined,
  };
}

export function getIconSpecDims(
  icon: SvgIconSpec,
  defaultMaxHeight: number,
  defaultMaxWidth: number,
) {
  return getIconDims(icon.maxHeight, icon.maxWidth, defaultMaxHeight, defaultMaxWidth);
}
