// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

const storage = createJSONStorage<boolean>(() => sessionStorage);
const hasImportedModelState = atomWithStorage('hasImportedModel', false, storage);

export function useHasImportedModel() {
  return useAtomValue(hasImportedModelState);
}

export function useSetImportedModel() {
  return useSetAtom(hasImportedModelState);
}
