// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVType } from '@luminarycloudinternal/lcvis';

import assert from '../../assert';
import { Bounds, LcvModule } from '../types';

import { LcvCamera } from './LcvCamera';
import { LcvDisplay } from './LcvDisplay';
import { LcvRenderer } from './LcvRenderer';
import { LcvObject } from './base/LcvObject';
import { LcvWorkspace } from './filters/LcvWorkspace';

// A helper function to re-order bounds from the lcvis [minX,minY,...,maxZ] to
// what we use in the UI: [minX,maxX,minY,maxY,minZ,maxZ].
const reorder = (lcvisBounds: Bounds): Bounds => {
  const outBounds: Bounds = [0, 0, 0, 0, 0, 0];
  outBounds[0] = lcvisBounds[0];
  outBounds[1] = lcvisBounds[3];
  outBounds[2] = lcvisBounds[1];
  outBounds[3] = lcvisBounds[4];
  outBounds[4] = lcvisBounds[2];
  outBounds[5] = lcvisBounds[5];
  return outBounds;
};

export class LcvFrame extends LcvObject {
  camera!: LcvCamera;
  renderer!: LcvRenderer;
  display!: LcvDisplay;
  workspace!: LcvWorkspace;

  // A map of annotation id to annotation object that is specific to this frame.
  // When the frame is released, all attached annotations will be released as well.
  annotations: Map<string, LcvObject> = new Map();

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    displaySize: Array<number>,
    camera: LcvCamera,
    display: LcvDisplay,
    workspace: LcvWorkspace,
    renderer: LcvRenderer,
  ) {
    super(lcv, lcv.newFrame(sessionHandle, displaySize[0], displaySize[1], 0).frame, sessionHandle);
    this.setCamera(camera);
    this.setWorkspace(workspace);
    this.setRenderer(renderer);
    this.setDisplay(display);
    this.setParam(
      'display_region',
      LCVType.kLCVDataTypeInt4,
      [0, 0, displaySize[0], displaySize[1]],
    );
  }

  /* Gets the best bounds for the current dataset and annotations. We return the visible bounds
  * if valid, and otherwise return the total bounds. Return value is in the form
  * [minX,maxX,minY,maxY,minZ,maxZ].
  */
  getCurrentBounds(): Bounds {
    // The bounds of all visible surfaces.
    const visible: Bounds = this.getProperty('visible_bounds', LCVType.kLCVDataTypeFloat3x2);
    assert(Array.isArray(visible) && visible.length === 6, 'Incorrectly formed visibility');
    // The total bounds of all surfaces visible or not.
    const total: Bounds = this.getProperty('bounds', LCVType.kLCVDataTypeFloat3x2);
    assert(Array.isArray(total) && total.length === 6, 'Incorrectly formed total');
    if (visible[0] > visible[3] || visible[1] > visible[4] || visible[2] > visible[5]) {
      return reorder(total);
    }
    return reorder(visible);
  }

  /* Gets the best bounds for the current dataset only not including annotations
   * or other things we render. This is useful for things like widget placement,
   * which only need the bounds of the data not including monitor points,
   * refinement regions, etc. We return the visible bounds if valid, and
   * otherwise return the total bounds. Return value is in the form
   * [minX,maxX,minY,maxY,minZ,maxZ].
   */
  getCurrentDatasetBounds(): Bounds {
    // The bounds of all visible surfaces.
    const visible: Bounds = this.getProperty(
      'visible_dataset_bounds',
      LCVType.kLCVDataTypeFloat3x2,
    );
    assert(Array.isArray(visible) && visible.length === 6, 'Incorrectly formed visibility');
    // The total bounds of all surfaces visible or not.
    const total: Bounds = this.getProperty('dataset_bounds', LCVType.kLCVDataTypeFloat3x2);
    assert(Array.isArray(total) && total.length === 6, 'Incorrectly formed total');
    if (visible[0] > visible[3] || visible[1] > visible[4] || visible[2] > visible[5]) {
      return reorder(total);
    }
    return reorder(visible);
  }

  setCamera(camera: LcvCamera) {
    this.setParam('camera', LCVType.kLCVDataTypeCamera, camera.handle);
    this.camera = camera;
  }

  setWorkspace(workspace: LcvWorkspace) {
    this.setParam('workspace', LCVType.kLCVDataTypeWorkspace, workspace.handle);
    this.workspace = workspace;
  }

  setDisplay(display: LcvDisplay) {
    this.setParam('display', LCVType.kLCVDataTypeDisplay, display.handle);
    this.display = display;
  }

  setRenderer(renderer: LcvRenderer) {
    this.setParam('renderer', LCVType.kLCVDataTypeRenderer, renderer.handle);
    this.renderer = renderer;
  }

  async render() {
    this.setParam('force_rerender', LCVType.kLCVDataTypeInt, 0);
    this.lcv.clearDisplay(this.sessionHandle, this.display.handle);
    await this.lcv.renderFrame(this.sessionHandle, this.handle);
    this.lcv.displayFrame(this.sessionHandle, this.handle);
  }

  // Call attachAnnotation and add an annotation to the frame's map. The key is the annotation id.
  attachAnnotation(key: string, annotation: LcvObject) {
    this.lcv.attachAnnotation(this.sessionHandle, annotation.handle, this.handle);
    this.annotations.set(key, annotation);
  }

  detachAnnotation(key: string) {
    const annotation = this.annotations.get(key);
    if (annotation) {
      this.lcv.detachAnnotation(this.sessionHandle, annotation.handle, this.handle);
      this.annotations.delete(key);
    }
  }

  release() {
    super.release();
  }
}
