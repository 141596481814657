// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';

import { ProgressBar } from './ProgressBar';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    topLine: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    progressMessage: {
      color: colors.highEmphasisText,
      fontSize: '13px',
      whiteSpace: 'pre-line',
    },
    stepCount: {
      color: colors.lowEmphasisText,
    },
    progressBar: {
      alignSelf: 'stretch',
      margin: '4px 0',
    },
    details: {
      color: colors.lowEmphasisText,
      fontSize: '12px',
    },
  }),
  { name: 'DialogProgress' },
);

export interface ProgressMessageProps {
  // The colors of the progress bar and background.
  barColor?: string;
  backgroundColor?: string;
  progress: number | null;
  transitionDuration?: number;
  message: string;
  details: string[];
  stepCount?: string;
}

export const ProgressMessage = (props: ProgressMessageProps) => {
  const { message, details, stepCount } = props;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.topLine}>
        <div
          className={classes.progressMessage}
          data-locator="progressMessage">
          {message}
        </div>
        <div className={classes.stepCount}>
          {stepCount}
        </div>
      </div>
      <div className={classes.progressBar}>
        <ProgressBar {...props} height={6} />
      </div>
      <div className={classes.details}>
        {details.map((detail: string) => (
          <div key={detail}>{detail}</div>
        ))}
      </div>
    </div>
  );
};
