import { LCVMapMode, LCVTransformPreviewType, LCVType } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';
import { LcvFrame } from '../LcvFrame';
import { LcvObject } from '../base/LcvObject';

type Vec3 = [number, number, number]

/**
 * Maps the keys of the transform preview parameters to their corresponding LCVTypes
 * when calling setParam.
 */
const keyTypeMap = new Map<string, LCVType>([
  ['origin', LCVType.kLCVDataTypeFloat3],
  ['axis', LCVType.kLCVDataTypeFloat3],
  ['angle', LCVType.kLCVDataTypeFloat],
  ['scale_factor', LCVType.kLCVDataTypeFloat],
  ['translation', LCVType.kLCVDataTypeFloat3],
  ['pattern_symmetric', LCVType.kLCVDataTypeUint],
  ['pattern_quantity', LCVType.kLCVDataTypeUint],
  ['pattern_circular_full', LCVType.kLCVDataTypeUint],
]);
export type TranslateParam = {
  typ: LCVTransformPreviewType.kLCVTransformPreviewTypeTranslate
  origin: Vec3,
}
export type ScaleParam = {
  typ: LCVTransformPreviewType.kLCVTransformPreviewTypeScale
  origin: Vec3,
  scale_factor: number,
}
export type RotateParam = {
  typ: LCVTransformPreviewType.kLCVTransformPreviewTypeRotate
  origin: Vec3,
  axis: Vec3,
  angle: number,
}
export type MirrorParam = {
  typ: LCVTransformPreviewType.kLCVTransformPreviewTypeMirror
  origin: Vec3,
  axis: Vec3,
}
export type LinearPatternParam = {
  typ: LCVTransformPreviewType.kLCVTransformPreviewTypeLinearPattern
  translation: Vec3,
  pattern_symmetric: number,
  pattern_quantity: number,
}
export type CircularPatternParam = {
  typ: LCVTransformPreviewType.kLCVTransformPreviewTypeCircularPattern
  origin: Vec3,
  axis: Vec3,
  angle: number,
  pattern_symmetric: number,
  pattern_quantity: number,
  pattern_circular_full: number,
}

export type PreviewParam = (
  TranslateParam |
  ScaleParam |
  RotateParam |
  MirrorParam |
  LinearPatternParam |
  CircularPatternParam
)

export type TransformPreviewParam = {
  param: PreviewParam,
  filterHandle: number,
  surfaceIndices: number[]
}

/**
 * A transform_preview annotation class wrapper for LCVis. This annotation handles showing a preview
 * of geometry transformations and patterns.
 */
export class LcvTransformAnnotation extends LcvObject {
  transformParam: TransformPreviewParam | null = null;
  frame: LcvFrame | null = null;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, lcv.newAnnotation(sessionHandle, 'transform_preview', 0).annotation, sessionHandle);
  }

  /**
   * Shows the transform preview annotation on the given frame. If frame is null, the annotation
   * will be hidden. Currently only one frame can have a transform preview annotation at a time.
   * @param frame the frame to show the transform preview on
   * @returns void
   */
  show(frame: LcvFrame | null) {
    if (this.frame === frame) {
      return;
    }
    this.frame?.detachAnnotation('transform_preview');
    this.frame = frame;
    if (frame) {
      frame?.attachAnnotation('transform_preview', this);
    }
  }

  /**
   * Hides the transform preview annotation.
   */
  hide() {
    this.show(null);
  }

  /**
   * Sets the transform preview parameters for this annotation. If newParam is null, the annotation
   * will be hidden.
   * @param newParam the new transform preview parameters
   * @returns void
   */
  setTransformParam(newParam: TransformPreviewParam | null) {
    this.transformParam = newParam;
    if (!newParam) {
      this.hide();
      return;
    }

    const { filterHandle, surfaceIndices, param } = newParam;
    this.setFilter(filterHandle);
    this.setSurfaces(surfaceIndices);
    Object.entries(param).forEach(([key, val]) => {
      if (key === 'typ') {
        this.setTransformType(val as LCVTransformPreviewType);
        return;
      }
      if (keyTypeMap.has(key)) {
        this.setParam(key, keyTypeMap.get(key)!, val);
      }
    });
  }

  private setTransformType(type: LCVTransformPreviewType) {
    this.setParam('transform_type', LCVType.kLCVDataTypeUint, type);
  }

  private setFilter(filterHandle: number) {
    this.setParam('filter', LCVType.kLCVDataTypeFilter, filterHandle);
  }

  private setSurfaces(surfaceIndices: number[]) {
    const numSurfaces = surfaceIndices.length;
    const data = this.lcv.newData1D(
      this.sessionHandle,
      LCVType.kLCVDataTypeUint,
      numSurfaces,
      0,
    ).data;
    const { mapping, size } = this.lcv.mapData(
      this.sessionHandle,
      data,
      LCVMapMode.kLCVMapModeWrite,
      0,
      0,
    );
    new Uint32Array(this.lcv.memory(), mapping, size).set(surfaceIndices);
    this.lcv.unmapData(this.sessionHandle, data);
    this.lcv.setParameter(
      this.sessionHandle,
      this.handle,
      'surface_ids',
      LCVType.kLCVDataTypeData1D,
      data,
    );
    this.lcv.release(this.sessionHandle, data, 0);
  }
}
