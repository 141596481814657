// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 30;
const baseHeight = 24;
export const ScalpelIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="scalpel"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0,24
           C2.375,24 4.664,23.617 6.867,22.852
           C9.07,22.086 11.047,20.984 12.797,19.547
           L12.844,19.547
           C14,18.578 14.766,17.313 15.141,15.75
           L15.656,15.75
           C16.188,15.75 16.625,15.547 16.969,15.141
           L24.563,6.234
           C25.313,5.391 25.617,4.414 25.477,3.305
           C25.336,2.195 24.813,1.313 23.906,0.656
           C23.281,0.219 22.594,0 21.844,0
           C21.281,0 20.726,0.125 20.18,0.375
           C19.633,0.625 19.172,0.969 18.797,1.406
           L7.781,14.297
           C7.625,14.484 7.555,14.688 7.57,14.906
           C7.586,15.125 7.672,15.313 7.828,15.469
           L0,24Z
           M15.422,13.5
           L11.438,13.5
           L20.484,2.906
           C20.859,2.469 21.313,2.25 21.844,2.25
           C22.125,2.25 22.375,2.328 22.594,2.484
           C22.969,2.734 23.188,3.094 23.25,3.563
           C23.313,4.031 23.188,4.438 22.875,4.781
           L15.422,13.5Z
           M6,20.766
           L10.594,15.75
           L12.844,15.75
           C12.531,16.594 12.047,17.281 11.391,17.813
           C9.797,19.094 8,20.078 6,20.766Z
           M22.875,24
           C22.969,24 23.055,23.961 23.133,23.883
           C23.211,23.805 23.25,23.719 23.25,23.625
           L23.25,22.125
           C23.25,22.031 23.211,21.945 23.133,21.867
           C23.055,21.789 22.969,21.75 22.875,21.75
           L19.125,21.75
           C19.031,21.75 18.945,21.789 18.867,21.867
           C18.789,21.945 18.75,22.031 18.75,22.125
           L18.75,23.625
           C18.75,23.719 18.789,23.805 18.867,23.883
           C18.945,23.961 19.031,24 19.125,24
           L22.875,24Z
           M16.125,24
           C16.219,24 16.305,23.961 16.383,23.883
           C16.461,23.805 16.5,23.719 16.5,23.625
           L16.5,22.125
           C16.5,22.031 16.461,21.945 16.383,21.867
           C16.305,21.789 16.219,21.75 16.125,21.75
           L12.375,21.75
           C12.281,21.75 12.195,21.789 12.117,21.867
           C12.039,21.945 12,22.031 12,22.125
           L12,23.625
           C12,23.719 12.039,23.805 12.117,23.883
           C12.195,23.961 12.281,24 12.375,24
           L16.125,24Z
           M29.625,24
           C29.719,24 29.805,23.961 29.883,23.883
           C29.961,23.805 30,23.719 30,23.625
           L30,22.125
           C30,22.031 29.961,21.945 29.883,21.867
           C29.805,21.789 29.719,21.75 29.625,21.75
           L25.875,21.75
           C25.781,21.75 25.695,21.789 25.617,21.867
           C25.539,21.945 25.5,22.031 25.5,22.125
           L25.5,23.625
           C25.5,23.719 25.539,23.805 25.617,23.883
           C25.695,23.961 25.781,24 25.875,24
           L29.625,24Z
           M0,24
           C2.375,24 4.664,23.617 6.867,22.852
           C9.07,22.086 11.047,20.984 12.797,19.547
           L12.844,19.547
           C14,18.578 14.766,17.313 15.141,15.75
           L15.656,15.75
           C16.188,15.75 16.625,15.547 16.969,15.141
           L24.563,6.234
           C25.313,5.391 25.617,4.414 25.477,3.305
           C25.336,2.195 24.813,1.313 23.906,0.656
           C23.281,0.219 22.594,0 21.844,0
           C21.281,0 20.726,0.125 20.18,0.375
           C19.633,0.625 19.172,0.969 18.797,1.406
           L7.781,14.297
           C7.625,14.484 7.555,14.688 7.57,14.906
           C7.586,15.125 7.672,15.313 7.828,15.469
           L0,24Z
           M15.422,13.5
           L11.438,13.5
           L20.484,2.906
           C20.859,2.469 21.313,2.25 21.844,2.25
           C22.125,2.25 22.375,2.328 22.594,2.484
           C22.969,2.734 23.188,3.094 23.25,3.563
           C23.313,4.031 23.188,4.438 22.875,4.781
           L15.422,13.5Z
           M6,20.766
           L10.594,15.75
           L12.844,15.75
           C12.531,16.594 12.047,17.281 11.391,17.813
           C9.797,19.094 8,20.078 6,20.766Z
           M22.875,24
           C22.969,24 23.055,23.961 23.133,23.883
           C23.211,23.805 23.25,23.719 23.25,23.625
           L23.25,22.125
           C23.25,22.031 23.211,21.945 23.133,21.867
           C23.055,21.789 22.969,21.75 22.875,21.75
           L19.125,21.75
           C19.031,21.75 18.945,21.789 18.867,21.867
           C18.789,21.945 18.75,22.031 18.75,22.125
           L18.75,23.625
           C18.75,23.719 18.789,23.805 18.867,23.883
           C18.945,23.961 19.031,24 19.125,24
           L22.875,24Z
           M16.125,24
           C16.219,24 16.305,23.961 16.383,23.883
           C16.461,23.805 16.5,23.719 16.5,23.625
           L16.5,22.125
           C16.5,22.031 16.461,21.945 16.383,21.867
           C16.305,21.789 16.219,21.75 16.125,21.75
           L12.375,21.75
           C12.281,21.75 12.195,21.789 12.117,21.867
           C12.039,21.945 12,22.031 12,22.125
           L12,23.625
           C12,23.719 12.039,23.805 12.117,23.883
           C12.195,23.961 12.281,24 12.375,24
           L16.125,24Z
           M29.625,24
           C29.719,24 29.805,23.961 29.883,23.883
           C29.961,23.805 30,23.719 30,23.625
           L30,22.125
           C30,22.031 29.961,21.945 29.883,21.867
           C29.805,21.789 29.719,21.75 29.625,21.75
           L25.875,21.75
           C25.781,21.75 25.695,21.789 25.617,21.867
           C25.539,21.945 25.5,22.031 25.5,22.125
           L25.5,23.625
           C25.5,23.719 25.539,23.805 25.617,23.883
           C25.695,23.961 25.781,24 25.875,24
           L29.625,24Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
