// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const RingDashIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="ringDash"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M16.49,10.494
           L7.495,10.494
           C6.671,10.494 5.996,11.169 5.996,11.993
           C5.996,12.818 6.671,13.492 7.495,13.492
           L16.49,13.492
           C17.315,13.492 17.989,12.818 17.989,11.993
           C17.989,11.169 17.315,10.494 16.49,10.494Z
           M11.993,0
           C5.367,0 0,5.367 0,11.993
           C0,18.619 5.367,23.986 11.993,23.986
           C18.619,23.986 23.986,18.619 23.986,11.993
           C23.986,5.367 18.619,0 11.993,0Z
           M11.994,20.988
           C7.032,20.988 2.999,16.955 2.999,11.993
           C2.999,7.031 7.032,2.998 11.994,2.998
           C16.956,2.998 20.988,7.031 20.988,11.993
           C20.988,16.955 16.971,20.988 11.994,20.988Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
