// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { RenderElementProps, useFocused, useSelected } from 'slate-react';

import assert from '../../lib/assert';
import { colors } from '../../lib/designSystem';
import { FunctionElement, Type } from '../../lib/expressionInput/slate';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(() => createStyles({
  root: {
    color: '#40BACF',
    borderRadius: '4px',
    borderWidth: '1px',
  },
}), { name: 'FunctionElement' });

export function Function(props: RenderElementProps) {
  const { attributes, element, children } = props;

  assert(element.type === Type.Function, 'Element is not a function');
  const variableElement = element as FunctionElement;

  const classes = useStyles();
  const selected = useSelected();
  const focused = useFocused();

  return (
    <span
      {...attributes}
      className={classes.root}
      contentEditable={false}
      data-slate-inline
      style={{
        boxShadow: selected && focused ? `0 0 0 1px ${colors.highEmphasisBorder}` : undefined,
      }}>
      {variableElement.func}
      {children}
    </span>
  );
}
