// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as frontendpb from '../proto/frontend/frontend_pb';

import { fromBigInt } from './number';

// Convert the progress info into a single number equal to fraction of completed units.
// Returns undefined if it is indeterminate or any data is missing.
export function progressFraction(
  progressInfo?: frontendpb.ProgressInfo,
): number | undefined {
  const total = progressInfo?.totalUnits;
  const completed = progressInfo?.completedUnits;
  const determinate = progressInfo ? !progressInfo.indeterminate : false;
  return determinate && total ? fromBigInt((completed || 0n) / total) : undefined;
}

export interface StatusText {
  message: string,
  details: string,
}
