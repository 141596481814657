// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

export interface backoffParams {
  // Random perturbance
  jitter: number,
  // Delay growth factor per retry
  exp: number,
  // Minimum backoff period in ms.
  minBackoff: number,
  // Max backoff period as a multiple of minBackoff
  maxMultiplier: number
}

export const defaultBackoff: backoffParams = {
  jitter: 1.2,
  exp: 1.2,
  maxMultiplier: 240.0,
  minBackoff: 500,
};

// Computes the amount of time (ms) the caller should wait before
// retrying an operation.  This function just computes the duration; the caller
// should sleep the prescribed amount on its own.
//
// The backoff duration increases exponentially with nRetries.
//
// Logic should be in sync with cc/base/backoff/backoff.h and
// go/core/backoff/backoff.go
export default function computeBackoff(
  nRetry: number,
  params: backoffParams = defaultBackoff,
): number {
  if (nRetry < 0) {
    throw Error('invalid argument');
  }
  const mult = Math.min(params.exp ** nRetry, params.maxMultiplier);
  const minJitter = 1 / params.jitter;
  const maxJitter = params.jitter;
  return params.minBackoff * mult * (minJitter + Math.random() * (maxJitter - minJitter));
}
