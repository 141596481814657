// Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SelectOption } from '../../../lib/componentTypes/form';

import { DataSelect, DataSelectProps } from '.';

export interface DataFieldProps<T> extends DataSelectProps<T> {
  // If the field is a select or just a static constant number.
  isInput: boolean;
}

// The data field is a way to easily switch between a multiple choice DataSelect and a static text
// with the selected value.
export function DataField<T>(props: DataFieldProps<T>) {
  if (props.isInput) {
    return <DataSelect {...props} />;
  }
  const selected = props.options.find((option) => (option as SelectOption<T>).selected);
  if (selected) {
    return <div>{(selected as SelectOption<T>).name}</div>;
  }
  return null;
}
