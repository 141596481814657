// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

// Constant identifier for annotation type
export const REFINEMENT_REGION = 'refinement_region';

// Constant identifier for refinement region types
export const REFINEMENT_SPHERE = 'refinement_sphere';
export const REFINEMENT_BOX = 'refinement_box';
export const REFINEMENT_CYLINDER = 'refinement_cylinder';

// Types used for the categorization of annotations
export type AnnotationCategory = typeof REFINEMENT_REGION;

// Types used for concrete classes of annotations
export type AnnotationType =
  typeof REFINEMENT_SPHERE | typeof REFINEMENT_CYLINDER | typeof REFINEMENT_BOX;

// Common type for param options of all annotations
export type ParamOptions =
  RefinementSphereParamOptions | RefinementBoxParamOptions | RefinementCylinderParamOptions;

// Annotation meta data including id and general representation controls
export interface AnnotationParam {
  // Type of annotation
  typ: AnnotationCategory;
  // Unique identifier for the annotation
  id: string;
  // If we should render this annotation as a wireframe or transparent solid.
  wireframe: boolean;
  // If the current annotation is selected by the user.
  selected: boolean;
  // If the current annotation should be shown or hidden in the visualizer.
  visible: boolean;
  // The params of specific to annotations
  param: ParamOptions;
}

/* The annotation params for the refinement sphere. */
type RefinementSphereParamOptions = {
  typ: typeof REFINEMENT_SPHERE,
  center: [number, number, number],
  outerRadius: number,
  innerRadius: number,
}

/* The annotation params for the refinement box. */
type RefinementBoxParamOptions = {
  typ: typeof REFINEMENT_BOX,
  start: [number, number, number],
  extents: [number, number, number],
  orientation: [number, number, number],
}

/* The annotation params for the refinement box. */
type RefinementCylinderParamOptions = {
  typ: typeof REFINEMENT_CYLINDER,
  start: [number, number, number],
  end: [number, number, number],
  outerRadius: number,
  innerRadius: number,
}
