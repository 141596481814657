// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseHeight = 12;
const baseWidth = 12;
export const SortAzIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      height={finalHeight}
      style={{
        fill: 'none',
      }}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}>
      <path
        clipRule="evenodd"
        d="M4.5 8.99999
          C4.29 8.99999 4.1025 9.08249 3.9675 9.21749
          L3.75 9.4425V6.74999
          C3.75 6.33749 3.4125 5.99999 3 5.99999
          C2.5875 5.99999 2.25 6.33749 2.25 6.74999
          V9.4425
          L2.0325 9.22499
          C1.8975 9.08249 1.71 8.99999 1.5 8.99999
          C1.0875 8.99999 0.75 9.33749 0.75 9.74999
          C0.75 9.95999 0.8325 10.1475 0.9675 10.2825
          L2.4675 11.7825
          C2.61 11.9175 2.7975 12 3 12
          C3.21 12 3.3975 11.9175 3.5325 11.7825
          L5.0325 10.2825
          C5.1675 10.1475 5.25 9.95999 5.25 9.74999
          C5.2575 9.33749 4.92 8.99999 4.5 8.99999Z
          M10.4475 8.28755
          V7.50755
          H6.9375
          V8.34005
          H9.1425
          L6.75
          11.2201
          V12.0001
          H10.515
          V11.1676
          H8.0625
          L10.4475 8.28755Z
          M9.3825 4.65755
          L9.8475 5.99255
          H11.25
          L8.955 0.0075531
          H7.575
          L5.25 5.99255
          H6.6075
          L7.0875 4.65755
          H9.3825Z
          M8.24252 1.47756
          H8.25752
          L9.03002 3.67506
          H7.44002
          L8.24252 1.47756Z"
        style={{ fill: getColor(0), fillRule: 'evenodd', clipRule: 'evenodd' }}
      />
    </svg>
  );
});
