// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVType } from '@luminarycloudinternal/lcvis';
import { atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { lcvHandler } from '../lib/lcvis/handler/LcvHandler';

export type QuantitySelectionType = 'singleSelect' | 'boxSelect'
export type EntitySelectionType = 'surface' | 'volume'

/** A state to track whether we are doing single selections on-click,
 * or always-on drag to box select. */
export const quantitySelectionState = atomFamily<QuantitySelectionType, string>({
  key: 'quantitySelectionState',
  default: 'singleSelect',
  effects: (projectId: string) => [
    ({ onSet }) => {
      onSet((newVal, _) => {
        lcvHandler.queueDisplayFunction('set box select mode', (display) => {
          display.widgets.selectionWidget?.setParam(
            'box_select_mode_on',
            LCVType.kLCVDataTypeUint,
            newVal === 'boxSelect' ? 1 : 0,
          );
        });
      });
    },
  ],
});

/**
 * A state to track the type of entity that will be selected on a single click in the visualizer.
 */
export const entitySelectionState = atomFamily<EntitySelectionType, string>({
  key: 'entitySelectionState',
  default: 'surface',
});

export const useQuantitySelectionState = (
  projectId: string,
) => useRecoilState(quantitySelectionState(projectId));

export const useQuantitySelectionValue = (
  projectId: string,
) => useRecoilValue(quantitySelectionState(projectId));

export const useSetQuantitySelection = (
  projectId: string,
) => useSetRecoilState(quantitySelectionState(projectId));

export const useEntitySelectionState = (
  projectId: string,
) => useRecoilState(entitySelectionState(projectId));

export const useEntitySelectionValue = (
  projectId: string,
) => useRecoilValue(entitySelectionState(projectId));

export const useSetEntitySelection = (
  projectId: string,
) => useSetRecoilState(entitySelectionState(projectId));
