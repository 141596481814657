// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/podconfig/podconfig.proto (package luminary.proto.podconfig, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Describes a network port used by the pod. Its meaning depends on whether the
 * pod is managed by k8s or manager.
 *
 * - If the pod runs in k8s, the port is exposed as a ClusterIP kube service,
 * and kube_port must be set by the user.
 *
 * - If the pod runs in nodemanager, the port field is ignored. It is treated
 *   the same as InternalPort with n_ports=1..
 *
 * @generated from message luminary.proto.podconfig.ServicePort
 */
export const ServicePort = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.podconfig.ServicePort",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "port", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * Describes ports to be reserved for use by pods.  This field is ignored by
 * k8s-based jobs. For nodemanager-based jobs, the nodemanager reserves a
 * contiguous non-ephemeral port range that can be used by every pod in the job,
 * and sets the range to nodelet.JobConfig.job_port.  Every pod in the job is
 * assigned the same port number.
 *
 * @generated from message luminary.proto.podconfig.InternalPort
 */
export const InternalPort = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.podconfig.InternalPort",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "n_ports", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * Config defines parameters for creating a kube pod.
 *
 * @generated from message luminary.proto.podconfig.PodConfig
 */
export const PodConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.podconfig.PodConfig",
  () => [
    { no: 2, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "service_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cmd_template", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "service_port", kind: "message", T: ServicePort, repeated: true },
    { no: 6, name: "internal_port", kind: "message", T: InternalPort, repeated: true },
  ],
);

