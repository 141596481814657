// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/exploration/exploration.proto (package luminary.proto.exploration, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Metadata } from "../table/table_pb.js";
import { AdFloatType, AdVector3, IntVector3, JobStatus } from "../base/base_pb.js";

/**
 * Type of the exploration variable.
 *
 * @generated from enum luminary.proto.exploration.VarType
 */
export const VarType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.exploration.VarType",
  [
    {no: 0, name: "VAR_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "VAR_TYPE_BOUNDARY", localName: "BOUNDARY"},
    {no: 2, name: "VAR_TYPE_GLOBAL", localName: "GLOBAL"},
    {no: 3, name: "VAR_TYPE_PHYSICAL_BEHAVIOR", localName: "PHYSICAL_BEHAVIOR"},
    {no: 4, name: "VAR_TYPE_REFERENCE_FRAME", localName: "REFERENCE_FRAME"},
    {no: 5, name: "VAR_TYPE_MATERIAL", localName: "MATERIAL"},
    {no: 6, name: "VAR_TYPE_PHYSICS", localName: "PHYSICS"},
    {no: 7, name: "VAR_TYPE_PARTICLE_GROUP", localName: "PARTICLE_GROUP"},
    {no: 8, name: "VAR_TYPE_HEAT_SOURCE", localName: "HEAT_SOURCE"},
  ],
);

/**
 * Baseline is a placeholder policy to be used for empty experiment, that is,
 * single, baseline solver run.  It is used iff. len(Experiment.var)==0.
 *
 * empty for now
 *
 * @generated from message luminary.proto.exploration.Baseline
 */
export const Baseline = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.Baseline",
  [],
);

/**
 * empty for now
 *
 * @generated from message luminary.proto.exploration.GridSearch
 */
export const GridSearch = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.GridSearch",
  [],
);

/**
 * @generated from message luminary.proto.exploration.LatinHypercube
 */
export const LatinHypercube = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.LatinHypercube",
  () => [
    { no: 1, name: "n_samples", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * empty for now
 *
 * @generated from message luminary.proto.exploration.SensitivityAnalysis
 */
export const SensitivityAnalysis = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.SensitivityAnalysis",
  [],
);

/**
 * @generated from message luminary.proto.exploration.CustomSamples
 */
export const CustomSamples = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.CustomSamples",
  () => [
    { no: 1, name: "n_samples", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "table", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Experiment define all explored parameters.
 *
 * Currently gridsearch is the only policy implemented. It tries every possible
 * combination of parameters independently.
 *
 * @generated from message luminary.proto.exploration.Exploration
 */
export const Exploration = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.Exploration",
  () => [
    { no: 1, name: "baseline", kind: "message", T: Baseline, oneof: "policy" },
    { no: 2, name: "grid_search", kind: "message", T: GridSearch, oneof: "policy" },
    { no: 3, name: "latin_hypercube", kind: "message", T: LatinHypercube, oneof: "policy" },
    { no: 4, name: "sensitivity_analysis", kind: "message", T: SensitivityAnalysis, oneof: "policy" },
    { no: 5, name: "custom", kind: "message", T: CustomSamples, oneof: "policy" },
    { no: 16, name: "var", kind: "message", T: Var, repeated: true },
    { no: 17, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "schema", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "table_references", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Metadata} },
  ],
);

/**
 * VarSpec specifies either a standalone parameter name (e.g., material
 * parameter), or a parameter that is part of a dialog in an array (i.e. a
 * parameter of a boundary condition).
 * The "field" specifies only the leaf part of the param name, since leaf field
 * names are guaranteed to be unique. For example, use "c_vreman" for
 * fvm/Turbulence.c_vreman.
 *
 * @generated from message luminary.proto.exploration.VarSpec
 */
export const VarSpec = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.VarSpec",
  () => [
    { no: 1, name: "field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(VarType) },
    { no: 4, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Deprecated.
 * VarName specifies either a standalone parameter name (e.g., material
 * parameter), or a parameter for a bound.  The "field" specifies only the leaf
 * part of the param name, since leaf field names are guaranteed to be unique.
 * For example, use "c_vreman" for fvm/Turbulence.c_vreman.
 *
 * The bound name should specify a name that appears in
 * fvm/Var.boundary_condition keys.
 *
 * @generated from message luminary.proto.exploration.VarName
 * @deprecated
 */
export const VarName = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.VarName",
  () => [
    { no: 1, name: "bound", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Var specifies a set of values to explore for one parameter.
 *
 * @generated from message luminary.proto.exploration.Var
 */
export const Var = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.Var",
  () => [
    { no: 1, name: "name", kind: "message", T: VarName },
    { no: 5, name: "spec", kind: "message", T: VarSpec },
    { no: 2, name: "range", kind: "message", T: Range, oneof: "value_typ" },
    { no: 3, name: "enumerated", kind: "message", T: Enumerated, oneof: "value_typ" },
    { no: 6, name: "column", kind: "message", T: TableColumn, oneof: "value_typ" },
    { no: 4, name: "synthetic", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * A concrete parameter value.
 *
 * @generated from message luminary.proto.exploration.Value
 */
export const Value = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.Value",
  () => [
    { no: 1, name: "int", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "typ" },
    { no: 2, name: "real", kind: "message", T: AdFloatType, oneof: "typ" },
    { no: 3, name: "vector3", kind: "message", T: AdVector3, oneof: "typ" },
  ],
);

/**
 * List of values chosen for a solver run. Written in DB column job(values).
 *
 * @generated from message luminary.proto.exploration.Values
 */
export const Values = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.Values",
  () => [
    { no: 1, name: "value", kind: "message", T: Value, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.exploration.Range
 */
export const Range = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.Range",
  () => [
    { no: 1, name: "min", kind: "message", T: Value },
    { no: 2, name: "max", kind: "message", T: Value },
    { no: 3, name: "n_interval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "n_samples", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * Explicit enumeration of values.
 *
 * @generated from message luminary.proto.exploration.Enumerated
 */
export const Enumerated = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.Enumerated",
  () => [
    { no: 1, name: "value", kind: "message", T: Value, repeated: true },
  ],
);

/**
 * Sample points selected from a table column
 *
 * @generated from message luminary.proto.exploration.TableColumn
 */
export const TableColumn = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.TableColumn",
  () => [
    { no: 1, name: "table", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "column_index", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "selected_columns" },
    { no: 4, name: "column_indexes", kind: "message", T: IntVector3, oneof: "selected_columns" },
    { no: 3, name: "value", kind: "message", T: Value, repeated: true },
  ],
);

/**
 * The state of an ongoing design exploration process. Used internally by the
 * experiment policy functions. This object is typically stores in a workflow
 * checkpoint object (luminary.proto.workflow.Checkpoint).
 *
 * @generated from message luminary.proto.exploration.Checkpoint
 */
export const Checkpoint = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.Checkpoint",
  () => [
    { no: 3, name: "job", kind: "message", T: Checkpoint_Job, repeated: true },
  ],
);

/**
 * Job (solver instance) state.
 *
 * @generated from message luminary.proto.exploration.Checkpoint.Job
 */
export const Checkpoint_Job = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.exploration.Checkpoint.Job",
  () => [
    { no: 2, name: "value", kind: "message", T: Value, repeated: true },
    { no: 5, name: "status", kind: "message", T: JobStatus },
    { no: 4, name: "metric", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "Checkpoint_Job"},
);

