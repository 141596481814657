// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 48;
const baseHeight = 33;
export const GridBlocksIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="gridBlocks"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0,21
           L15,21
           L15,12
           L0,12
           L0,21Z
           M3,15
           L12,15
           L12,18
           L3,18
           L3,15Z
           M45,-0
           L33,-0
           L33,9
           L48,9
           L48,3
           C48,1.35 46.65,-0 45,-0Z
           M45,6
           L36,6
           L36,3
           L45,3
           L45,6Z
           M0,3
           L0,9
           L15,9
           L15,-0
           L3,-0
           C1.35,-0 0,1.35 0,3Z
           M0,30
           C0,31.65 1.35,33 3,33
           L15,33
           L15,24
           L0,24
           L0,30Z
           M18,9
           L30,9
           L30,-0
           L18,-0
           L18,9Z
           M18,33
           L30,33
           L30,24
           L18,24
           L18,33Z
           M21,27
           L27,27
           L27,30
           L21,30
           L21,27Z
           M33,33
           L45,33
           C46.65,33 48,31.65 48,30
           L48,24
           L33,24
           L33,33Z
           M33,21
           L48,21
           L48,12
           L33,12
           L33,21Z
           M18,21
           L30,21
           L30,12
           L18,12
           L18,21Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
