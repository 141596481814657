// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { Empty } from '@bufbuild/protobuf';
import { atom, useAtomValue } from 'jotai';

import * as rpc from '../../../lib/rpc';

export const sampleProjectsState = atom(() => rpc.callRetry(
  'listSampleProjects',
  rpc.client.listSampleProjects,
  new Empty(),
));

export function useSampleProjectsValue() {
  return useAtomValue(sampleProjectsState);
}
