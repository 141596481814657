// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtom, useSetAtom } from 'jotai';

import { ConfirmationDialogProps } from '../../../components/dialog/Confirmation';
import { Optional } from '../../../lib/tsUtils';

// A confirmation is essentially an object of type ConfirmationDialogProps, but with 'open'
// excluded and 'onClose' made optional.
export type ConfirmationConfig = Optional<Omit<ConfirmationDialogProps, 'open'>, 'onClose'>;

// The confirmation stack can hold both ConfirmationConfig objects and functions that return
// ConfirmationConfig objects.
export type ConfirmationItem = ConfirmationConfig | (() => ConfirmationConfig);

const confirmationStack = atom<ConfirmationItem[]>([]);

export function useConfirmations() {
  return useAtom(confirmationStack);
}

export function useSetConfirmations() {
  return useSetAtom(confirmationStack);
}

export function pushConfirmation(
  setConfirmations: (update: (prev: ConfirmationItem[]) => ConfirmationItem[]) => void,
  item: ConfirmationItem,
) {
  setConfirmations((prevValue) => [...prevValue, item]);
}
