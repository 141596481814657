// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtom } from 'jotai';

export type ProjectCopiesInProgress = Array<{
  name: string;
  id: string;
}>

const projectCopiesInProgressState = atom<ProjectCopiesInProgress>([]);

export function useProjectCopiesInProgress() {
  return useAtom(projectCopiesInProgressState);
}
