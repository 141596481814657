// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 21;
export const PencilLinesIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="pencilLines"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M1.5,10.5
           L7.14,10.5
           L10.14,7.5
           L1.5,7.5
           C0.675,7.5 0,8.175 0,9
           C0,9.825 0.675,10.5 1.5,10.5Z
           M23.235,4.485
           C23.7,4.005 24,3.345 24,2.625
           C24,1.17 22.83,0 21.375,0
           C20.655,0 19.995,0.3 19.515,0.765
           L17.355,2.925
           L21.06,6.63
           L23.235,4.485Z
           M1.5,4.5
           L13.14,4.5
           L16.14,1.5
           L1.5,1.5
           C0.675,1.5 0,2.175 0,3
           C0,3.825 0.675,4.5 1.5,4.5Z
           M1.5,13.5
           C0.675,13.5 0,14.175 0,15
           C0,15.72 0.525,16.29 1.2,16.44
           L4.14,13.5
           L1.5,13.5Z
           M16.425,3.855
           L6.39,13.89
           L10.095,17.595
           L20.13,7.56
           L16.425,3.855Z
           M22.8,7.56
           L19.86,10.5
           L22.5,10.5
           C23.325,10.5 24,9.825 24,9
           C24,8.28 23.475,7.71 22.8,7.56Z
           M3,21
           L8.79,18.915
           L5.1,15.255
           L3,21Z
           M22.5,13.5
           L16.86,13.5
           L13.86,16.5
           L22.5,16.5
           C23.325,16.5 24,15.825 24,15
           C24,14.175 23.325,13.5 22.5,13.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
