// Copyright 2022 Luminary Cloud, Inc. All Rights Reserved.
export interface LengthUnit {
  name: string;
  abbreviation: string;
  valueInMeters: number;
}

const baseLengthUnits: LengthUnit[] = [
  {
    name: 'meters',
    abbreviation: 'm',
    valueInMeters: 1.0,
  },
  {
    name: 'centimeters',
    abbreviation: 'cm',
    valueInMeters: 0.01,
  },
  {
    name: 'millimeters',
    abbreviation: 'mm',
    valueInMeters: 0.001,
  },
  {
    name: 'inches',
    abbreviation: 'in',
    valueInMeters: 0.0254,
  },
];

export function allLengthUnits(): LengthUnit[] {
  return baseLengthUnits.slice();
}
