// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { ReactElement, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { ERRORS, parseError } from '../../lib/errors';
import { getLcUserId } from '../../lib/jwt';
import { isInProject, projectLink, projectsLink } from '../../lib/navigation';
import { useUserHasProjectRole } from '../../lib/projectRoles';
import {
  rpcDeleteProject,
  rpcRemoveProjectAccess,
  rpcUpdateProject,
  useCopyProject,
} from '../../lib/projectUtils';
import { addRpcError } from '../../lib/transientNotification';
import { ProjectSummary } from '../../proto/frontend/frontend_pb';
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import useAccountInfo from '../../recoil/useAccountInfo';
import { useProjectCopiesInProgress } from '../../recoil/useProjectCopiesInProgress';
import {
  rpcRefreshSharedState,
  useForceViewStateSync,
  useProjectShareDialog,
} from '../../state/external/project/sharing';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import Dropdown from '../Dropdown';
import ProjectDialog from '../dialog/Project';
import { useProjectDialogState } from '../project/controller/dialog/useProjectDialogState';

interface ProjectMenuProps {
  projectSummary: ProjectSummary | undefined;
  // This will be the visible content which would trigger the menu when it's clicked
  children: ReactElement;
  // A few generic events that apply for all menu items or the menu as a whole
  onOpen?: () => void;
  onClose?: () => void;
  onClick?: () => void;
  // Action-specific events that will be called only when the respective action is performed
  onCopy?: () => void;
  onDelete?: () => void;
  onLeave?: () => void;
  onUpdate?: () => void;
}

export const ProjectMenu = (props: ProjectMenuProps) => {
  const {
    projectSummary,
    children,
    onOpen,
    onClose,
    onClick,
    onCopy,
    onDelete,
    onLeave,
    onUpdate,
  } = props;

  const projectId = projectSummary?.projectId || '';

  const location = useLocation();
  const navigate = useNavigate();
  const dialogState = useProjectDialogState();

  const inProject = useMemo(() => isInProject(location.pathname), [location]);
  const isUserOwner = useUserHasProjectRole(projectSummary, 'admin');
  const isUserReader = useUserHasProjectRole(projectSummary, 'reader');
  const setConfirmStack = useSetConfirmations();
  const copyProject = useCopyProject();
  const forceViewStateSync = useForceViewStateSync(projectId);
  const [shareProjectDialog, setProjectShareDialog] = useProjectShareDialog();
  const [copiesInProgress] = useProjectCopiesInProgress();
  const accountInfo = useAccountInfo();
  const isGeometryPending = useIsGeometryPending(projectId);

  const userId = getLcUserId();
  const projectName = useMemo(
    () => projectSummary?.name || '',
    [projectSummary],
  );

  const sharingOptionDisabled = shareProjectDialog.open || !accountInfo;
  // If the GetGeometry operation is pending, we disable the copy project because it leaves the
  // project in an inconsitent state with the setup tab empty.
  const copyOptionDisabled = copiesInProgress.map((copy) => copy.name).includes(projectName) ||
    isGeometryPending;

  const leaveProject = async () => {
    try {
      await rpcRemoveProjectAccess(projectId, userId, []);
      if (inProject) {
        navigate(projectsLink());
      }
      onLeave?.();
    } catch (err) {
      addRpcError(
        parseError(ERRORS.RemoveAccessProject, [projectName]),
        err,
        { projectId, projectName },
      );
    }
  };

  const deleteProject = async () => {
    try {
      await rpcDeleteProject(projectId);
      if (inProject) {
        navigate(projectsLink());
      }
      onDelete?.();
    } catch (err) {
      addRpcError(
        parseError(ERRORS.DeleteProject, [projectName]),
        err,
        { projectId, projectName },
      );
    }
  };

  const updateProject = async (
    id: string,
    name: string,
    description: string,
    isEdit: boolean,
  ) => {
    try {
      await rpcUpdateProject(id, name, description);
      onUpdate?.();
    } catch (err) {
      addRpcError(
        parseError(ERRORS.EditProject, [projectName]),
        err,
        { projectId, projectName },
      );
    }
    dialogState.close();
  };

  const handleEditItem = () => {
    if (projectSummary) {
      // Open edit dialog
      dialogState.editProject(projectSummary);
      onClick?.();
    }
  };

  // Handlers for clicking items from the project menu
  const handleOpenItem = () => {
    navigate(projectLink(projectId));
    onClick?.();
  };

  const handleShareItem = () => {
    setProjectShareDialog({
      open: true,
      projectId,
    });
    onClick?.();
  };

  const handleDeleteItem = () => {
    pushConfirmation(setConfirmStack, {
      continueLabel: 'Delete',
      destructive: true,
      onContinue: () => deleteProject(),
      title: 'Delete Project',
      children: (
        <div>
          Are you sure you want to delete
          project <span className="fontWeight700">{projectName}</span>?
        </div>
      ),
    });
    onClick?.();
  };

  const handleSyncProjectItem = async () => {
    await rpcRefreshSharedState(projectId);
    forceViewStateSync();
    onClick?.();
  };

  const handleProjectCopyItem = async () => {
    if (inProject) {
      navigate(projectsLink());
    }
    onCopy?.();
    await copyProject(projectId, projectName);
    onClick?.();
  };

  const handleLeaveProjectItem = () => {
    pushConfirmation(setConfirmStack, {
      continueLabel: 'Leave Project',
      onContinue: () => leaveProject(),
      title: 'Leave Project',
      children: (
        <div>
          Are you sure you want to leave <span className="fontWeight700">{projectName}</span>?
          You will no longer have access to this project unless the owner shares it with you again.
        </div>
      ),
    });
    onClick?.();
  };

  const menuItems: CommonMenuItem[] = [];

  if (!inProject) {
    menuItems.push({ label: 'Open', onClick: handleOpenItem });
  }

  if (isUserOwner) {
    menuItems.push(
      { label: 'Share', onClick: handleShareItem, disabled: sharingOptionDisabled },
      { label: 'Edit', onClick: handleEditItem },
      { label: 'Make a Copy', onClick: handleProjectCopyItem, disabled: copyOptionDisabled },
      { label: 'Delete', onClick: handleDeleteItem },
    );
  } else if (isUserReader && inProject) {
    menuItems.push(
      { label: 'Sync Project', onClick: handleSyncProjectItem },
      { label: 'Make a Copy', onClick: handleProjectCopyItem, disabled: copyOptionDisabled },
      { label: 'Leave Project', onClick: handleLeaveProjectItem },
    );
    // This is for users that don't have an explicit reader role but can still see the project. The
    // only case for this is when a member of the Support team checks a project that is shared with
    // support.
  } else {
    menuItems.push(
      { label: 'Make a Copy', onClick: handleProjectCopyItem, disabled: copyOptionDisabled },
    );
  }

  return (
    <div>
      <Dropdown
        menuItems={menuItems}
        onClose={onClose}
        onOpen={onOpen}
        position="below-left"
        toggle={children}
      />
      <ProjectDialog
        {...dialogState.props}
        onSubmit={updateProject}
      />
    </div>
  );
};
