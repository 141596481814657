// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 20;
const baseHeight = 20;
export const IntersectingPlanesIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="intersectingPlanes"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M11.735,0.032
           C12.443,-0.134 13.144,0.353 13.339,1.148
           L13.596,2.197
           L16.384,1.561
           C17.452,1.317 18.333,2.517 17.937,3.674
           L16.528,7.782
           L19.848,15.188
           C20.263,16.115 19.788,17.238 18.893,17.444
           L7.904,19.969
           C7.293,20.109 6.676,19.764 6.399,19.128
           L5.581,17.251
           L2.989,17.915
           C1.946,18.182 1.045,17.05 1.379,15.891
           L2.816,10.902
           L0.144,4.767
           C-0.258,3.843 0.215,2.736 1.1,2.528
           L11.735,0.032Z
           M3.689,12.906
           L2.688,16.381
           L4.95,15.802
           L3.689,12.906Z
           M4.301,10.78
           L7.632,18.428
           L18.621,15.903
           L15.145,8.433
           C15.052,8.234 15.039,7.999 15.109,7.788
           L16.654,3.102
           L5.799,5.579
           L4.301,10.78Z
           M11.96,9.219
           C12.017,9.617 11.78,9.992 11.431,10.056
           C11.082,10.12 10.754,9.85 10.698,9.452
           L10.682,9.341
           C10.626,8.943 10.863,8.569 11.211,8.504
           C11.56,8.44 11.888,8.711 11.945,9.109
           L11.96,9.219Z
           M6.909,10.149
           C6.966,10.547 6.728,10.922 6.38,10.986
           C6.031,11.05 5.703,10.78 5.646,10.382
           L5.631,10.271
           C5.575,9.873 5.812,9.499 6.16,9.434
           C6.509,9.37 6.837,9.641 6.894,10.039
           L6.909,10.149Z
           M9.435,9.684
           C9.491,10.082 9.254,10.457 8.905,10.521
           C8.557,10.585 8.228,10.315 8.172,9.917
           L8.156,9.806
           C8.1,9.408 8.337,9.034 8.686,8.969
           C9.035,8.905 9.363,9.176 9.419,9.574
           L9.435,9.684Z
           M14.486,8.754
           C14.542,9.152 14.305,9.527 13.956,9.591
           C13.608,9.655 13.279,9.385 13.223,8.987
           L13.208,8.876
           C13.151,8.478 13.388,8.104 13.737,8.04
           C14.086,7.975 14.414,8.246 14.47,8.644
           L14.486,8.754Z
           M3.429,8.776
           L4.491,5.088
           C4.646,4.55 5.042,4.149 5.529,4.038
           L12.242,2.506
           L12.012,1.572
           L1.378,4.067
           L3.429,8.776Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
