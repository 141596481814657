// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 15;
const baseHeight = 25;
export const ChevronRightIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="chevronRight"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M14.275,10.725
           L4.275,0.725
           C3.825,0.275 3.2,0 2.5,0
           C1.125,0 0,1.125 0,2.5
           C0,3.2 0.275,3.825 0.725,4.275
           L8.975,12.5
           L0.75,20.725
           C0.275,21.175 0,21.8 0,22.5
           C0,23.875 1.125,25 2.5,25
           C3.2,25 3.825,24.725 4.275,24.275
           L14.275,14.275
           C14.725,13.825 15,13.2 15,12.5
           C15,11.8 14.725,11.175 14.275,10.725Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
