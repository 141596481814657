// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useRef, useState } from 'react';

import cx from 'classnames';

import * as ProtoDescriptor from '../../../ProtoDescriptor';
import { getQuantityUnit } from '../../../QuantityDescriptor';
import { tertiaryColors } from '../../../lib/color';
import { axisTableToSparkLineSeries, getLabelText } from '../../../lib/rectilinearTable/util';
import useResizeObserver from '../../../lib/useResizeObserver';
import { RectilinearTable } from '../../../proto/table/table_pb';
import QuantityAdornment from '../../QuantityAdornment';
import Tooltip from '../../Tooltip';
import { SparkLine } from '../../charting/SparkLine';
import { FixedSizeWrapper } from '../../layout/FixedSizeWrapper';
import { RingXIcon } from '../../svg/RingXIcon';
import { TableOutlinedIcon } from '../../svg/TableOutlinedIcon';
import Collapsible from '../../transition/Collapsible';
import { FolderTriangle } from '../FolderTriangle';

export interface SelectedColumnInputProps {
  /** Table proto correspondin to the selected column */
  table: RectilinearTable;
  /** Param this column is selected for */
  param: ProtoDescriptor.Param;
  /** Selected column index from the table */
  columnIndex: number;
  /** Overwrite the existing column name */
  columnName?: string;
  /** Callback for unlinking the selected column */
  unlinkColumn: () => void;
  /** Tooltip text for unliking the column */
  unlinkTooltip: string;
  /** When true, the table icon is hidden */
  hideIcon?: boolean;
  /** When true, the quantity label is hidden */
  hideQuantity?: boolean;
  /** True if the inputs should be disabled */
  disabled?: boolean;
}

export function SelectedColumnInput(props : SelectedColumnInputProps) {
  const {
    table, param, columnIndex, columnName, unlinkColumn, unlinkTooltip, hideIcon, disabled,
    hideQuantity,
  } = props;

  // whether the selector is expanded to show the graph
  const [detailsCollapsed, setDetailsCollapsed] = useState(true);

  // cannot be a reference to the chart div itself, because when the chart expands, then the size
  // of the chart can never shrink. This must be a reference to a different element that can
  // freely expand and shrink when the sidebar expands and shrinks
  const chartSizeRef = useRef<HTMLDivElement>(null);
  const chartSize = useResizeObserver(chartSizeRef);

  const columnPlot = () => {
    if (table) {
      const series = axisTableToSparkLineSeries(
        table,
        0,
        undefined,
        param.quantityType ? getQuantityUnit(param.quantityType) : undefined,
      )[columnIndex - 1];
      return (
        <div className={cx('chart', 'columnChart')} key={series.yLabel}>
          <SparkLine
            color={tertiaryColors[columnIndex - 1 % tertiaryColors.length]}
            height={Math.min(chartSize.width, 196)}
            options={{ format: { maximumFractionDigits: 6 } }}
            series={series}
            strokeWidth={3}
            width={Math.min(chartSize.width, 196)}
          />
        </div>
      );
    }
    return null;
  };

  const columnNames = table.header?.recordLabel.map(getLabelText);
  const selectedColumnName = columnNames?.[columnIndex - 1] ?? `Column ${columnIndex}`;

  return (
    <div className="tableMapInput">
      <div className={cx('tableinfo', 'columnTable', { collapsed: detailsCollapsed })}>
        <div className="header" ref={chartSizeRef}>
          {/* chart ref here since this div should always have the same width as the chart and
          can freely shrink when the sidebar shrinks */}
          {columnIndex > 0 && (
            <button
              className="control"
              onClick={() => setDetailsCollapsed(!detailsCollapsed)}
              type="button">
              <Tooltip title="Show or hide details">
                <div className="controlIcon">
                  <FolderTriangle open={!detailsCollapsed} />
                </div>
              </Tooltip>
            </button>
          )}
          {!hideIcon && (
            <FixedSizeWrapper height={12} width={12}>
              <TableOutlinedIcon
                color={tertiaryColors[(columnIndex - 1) % tertiaryColors.length]}
                maxHeight={12}
                maxWidth={12}
              />
            </FixedSizeWrapper>
          )}
          <div className="name">
            {columnName ?? selectedColumnName}
            {!hideQuantity && <QuantityAdornment quantity={param.quantityType} />}
          </div>
          {!props.disabled && columnIndex !== 0 && (
            <div className="controls">
              <button
                className="control"
                disabled={disabled}
                onClick={unlinkColumn}
                type="button">
                <Tooltip title={disabled ? '' : unlinkTooltip}>
                  <div className="controlIcon">
                    <RingXIcon maxHeight={12} maxWidth={12} />
                  </div>
                </Tooltip>
              </button>
            </div>
          )}
        </div>
        {columnIndex > 0 && (
          <Collapsible collapsed={detailsCollapsed} transitionPeriod={250}>
            <div className="body">
              <div className="divider" />
              <div className="records">
                {columnPlot()}
              </div>
            </div>
          </Collapsible>
        )}
      </div>
    </div>
  );
}
