// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import Card from '../Card';
import { OverlayValue } from '../util';

interface MeasureCardProps {
  title: string;
  onReset: () => void;
  // The data to display in the measure card.
  data: Map<string, OverlayValue>;
  disableReset?: boolean;
}

/** The card component displayed when the user is probing a mesh. */
const MeasureCard = (props: MeasureCardProps) => {
  const {
    title = 'Measure Values',
    onReset,
    data,
    disableReset,
  } = props;

  return (
    <Card
      clearTitle="Clear Measure"
      containerTestId="measureCard"
      data={data}
      disableReset={disableReset}
      onReset={onReset}
      resetTestId="resetMeasureButton"
      title={title}
      unit="m"
    />
  );
};

export default MeasureCard;
