// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import { colors } from '../../../lib/designSystem';
import { useOpenItarEnvDialog } from '../../../state/internal/dialog/itar';
import { createStyles, makeStyles } from '../../Theme';
import { ItarEnvironmentDialog } from '../../dialog/ItarEnvironment';

export const ITAR_BAR_LINE_HEIGHT = 6;
const BAR_COLOR = colors.cyan600;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      height: ITAR_BAR_LINE_HEIGHT,
      backgroundColor: BAR_COLOR,
      zIndex: 20, // must be larger than MainPageLayout's .appHeader which is 10
      position: 'relative',
    },
    content: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    button: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '44px',
      height: '22px',
      top: '6px',
      right: '2px',
      fontSize: '12px',
      fontWeight: 600,
      cursor: 'pointer',
      border: 'none',
      background: 'none',
      color: 'inherit',

      '&:focus-visible': {
        outline: `1px solid ${colors.primaryCta}`,
      },
    },
  }),
  { name: 'ItarBar' },
);

export const ItarBar = () => {
  const classes = useStyles();
  const openItarEnvDialog = useOpenItarEnvDialog();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <svg
          height="30"
          viewBox="0 0 63 30"
          width="63"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M63 0H0V6H4.228C5.89538 6 7.38786 7.03429 7.97332 8.59551L15.0267 27.4045C15.6121
            28.9657 17.1046 30 18.772 30H63V0Z"
            fill={BAR_COLOR}
          />
        </svg>
        <button
          className={classes.button}
          data-locator="itar-banner"
          onClick={() => openItarEnvDialog()}
          type="button">
          ITAR
        </button>
      </div>
      <ItarEnvironmentDialog />
    </div>
  );
};
