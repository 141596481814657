// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { MainPageLayout } from '../../components/layout/page/Main';

import { UsageSection } from './UsageSection';

const UsagePageBody = () => (
  <MainPageLayout title="Usage">
    <UsageSection isAdmin={false} />
  </MainPageLayout>
);

export default UsagePageBody;
