// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 64;
const baseHeight = 64;
const baseColors = ['#ffffff'];

const OpenedLetterWarning = (props: SvgProps) => {
  const { color = baseColors, ...scaling } = props;

  const {
    finalHeight,
    finalWidth,
    getColor,
  } = useSvg(baseWidth, baseHeight, { ...scaling, color });

  return (
    <svg
      fill="none"
      height={finalHeight}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48 38.9678C54.6274 38.9678 60 33.5952 60 26.9678C60 20.3404 54.6274 14.9678
           48 14.9678C41.3726 14.9678 36 20.3404 36 26.9678C36 33.5952 41.3726 38.9678
           48 38.9678Z"
        fill={getColor(0)}
        fillOpacity="0.2"
        stroke={getColor(0)}
        strokeWidth="2"
      />
      <path
        d="M56 44.6666V48C56 50.1217 55.1571 52.1565 53.6569 53.6568C52.1566 55.1571 50.1217
           56 48 56H16C13.8783 56 11.8434 55.1571 10.3431 53.6568C8.84285 52.1565 8 50.1217
           8 48V27.6278C8 26.8088 8.18859 26.0009 8.55115 25.2666C8.9137 24.5323 9.44049
           23.8914 10.0907 23.3934L28.7573 9.09894C29.6881 8.38622 30.8277 8 31.9999 8C33.1722
           8 34.3118 8.38622 35.2425 9.09894L37.9998 11.2104M35.2425 41.5677C34.3118 42.2805
           33.1722 42.6667 31.9999 42.6667C30.8277 42.6667 29.688 42.2805 28.7573
           41.5677L8.29259 25.8963"
        opacity="0.5"
        stroke={getColor(0)}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M48.0261 25.7679V22.4346"
        stroke={getColor(0)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M47.9999 31.7412L48.0132 31.7545L47.9999 31.7679L47.9866 31.7545L47.9999 31.7412Z"
        stroke={getColor(0)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default OpenedLetterWarning;
