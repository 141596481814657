// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, memo } from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import { colors } from '../../lib/designSystem';
import { isUnmodifiedEnterKey } from '../../lib/event';
import { createStyles, makeStyles } from '../Theme';
import { OpenIcon } from '../svg/OpenIcon';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      '--main-color': colors.primaryInteractive,
      '--aux-color': colors.primaryCta,
      '&.secondary': {
        '--main-color': colors.highEmphasisText,
        '--aux-color': colors.primaryInteractive,
      },
      cursor: 'pointer',
      color: 'var(--main-color)',
      display: 'inline-flex',
      gap: '8px',
      alignItems: 'center',
      textDecoration: 'none',
      transition: 'color 250ms, box-shadow 350ms',
      '&.bold': {
        fontWeight: 600,
      },

      '&:visited': {
        color: 'var(--main-color)',
      },

      '&:hover': {
        color: 'var(--aux-color)',

        '&.link': {
          boxShadow: `inset 0 -1px 0 0 ${'var(--aux-color)'}`,
        },
      },

      '&:focus-visible': {
        outline: `1px solid ${'var(--aux-color)'}`,
        borderRadius: '1px',
      },
    },
  }),
  { name: 'ActionLink' },
);

interface ActionLinkProps {
  onClick?: () => void;
  href?: string;
  children: ReactNode;
  // Passing true will use the native <a> instead of the <Link>, even if have an internal href.
  // This is needed to make internal links from the ErrorBoundary to work.
  reload?: boolean;
  // If true, an extra icon will be shown at the end, indicating that this is an external link
  externalIcon?: boolean;
  // If true, the text will be bold
  bold?: boolean;
  // Optionally specify a variant (default is 'primary')
  variant?: 'primary' | 'secondary';
  // We stop the event propagation when clicking the link by default, but this can allow it
  allowPropagation?: boolean;
}

// A helper component for rendering a text-like link that uses the brand colors. The href can be
// either internal or external. In can also act as a button, if we only use the onClick prop.
// All links will change colors on hover and will become underlined.
// Non-links will only change color.
export const ActionLink = memo((props: ActionLinkProps) => {
  const {
    bold,
    children,
    externalIcon,
    href,
    reload,
    onClick,
    variant = 'primary',
    allowPropagation = false,
  } = props;
  const classes = useStyles();

  if (href) {
    if (href.startsWith('/') && !reload) {
      return (
        <Link
          className={cx(classes.root, { link: true, secondary: variant === 'secondary' })}
          onClick={() => onClick?.()}
          to={href}>
          {children}
        </Link>
      );
    }

    const external = href?.startsWith('http') || href?.startsWith('mailto:');
    return (
      <a
        className={cx(
          classes.root,
          variant,
          { bold, link: true, secondary: variant === 'secondary' },
        )}
        // since we use a hashrouter, if there's an internal link we need to prepend /# to it.
        // Otherwise the a tag navigates to the link without the hash.
        href={href}
        onClick={(event) => {
          !allowPropagation && event.stopPropagation();
          onClick?.();
          // for internal links, force a refresh since setting the route to /# will just change
          // the url but not reload automatically.
          reload && !external && setTimeout(window.location.reload.bind(window.location), 1);
        }}
        onKeyUp={(event) => event.stopPropagation()}
        rel={external ? 'noopener noreferrer' : undefined}
        target={external ? '_blank' : undefined}>
        {children}
        {externalIcon && <OpenIcon maxHeight={12} />}
      </a>
    );
  }

  const handleInternalButtonAction = (
    event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
  ) => {
    !allowPropagation && event.stopPropagation();
    onClick?.();
  };

  return (
    <div
      className={cx(classes.root, { bold })}
      onClick={handleInternalButtonAction}
      onKeyUp={(event) => {
        if (isUnmodifiedEnterKey(event)) {
          handleInternalButtonAction(event);
        }
      }}
      role="button"
      tabIndex={0}>
      {children}
    </div>
  );
});

ActionLink.displayName = 'ActionLink';
