// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 18;
const baseHeight = 21;
export const ArrowUpIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="arrowUp"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M17.55,7.95
           L10.05,0.45
           C9.75,0.15 9.45,0 9,0
           C8.55,0 8.25,0.15 7.95,0.45
           L0.45,7.95
           C0.15,8.25 0,8.55 0,9
           C0,9.9 0.75,10.5 1.5,10.5
           C1.95,10.5 2.25,10.35 2.55,10.05
           L7.5,5.1
           L7.5,19.5
           C7.5,20.4 8.1,21 9,21
           C9.9,21 10.5,20.4 10.5,19.5
           L10.5,5.1
           L15.45,10.05
           C15.75,10.35 16.05,10.5 16.5,10.5
           C17.4,10.5 18,9.9 18,9
           C18,8.55 17.85,8.25 17.55,7.95Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
