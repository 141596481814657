// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback } from 'react';

import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useTransparencySettings } from '../../recoil/lcvis/transparencySettings';
import { useIsAnalysisView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';

/* Applies transparency to the farfield in lcvis. */
export const useFarfieldTransparency = () => {
  const [, setTransparencySettings] = useTransparencySettings();
  const { projectId } = useProjectContext();
  const geometryTags = useGeometryTags(projectId);
  const analysisView = useIsAnalysisView();
  return useCallback(() => geometryTags.tagIds().forEach((tagId) => {
    if (analysisView) {
      return;
    }
    if (tagId.includes('Farfield')) {
      const toMakeTransparent = new Set(
        geometryTags.surfacesFromTagEntityGroupId(tagId) || [],
      );
      setTransparencySettings({
        // Do not show the panel. If we set this to true we will see the ghost mode panel.
        active: false,
        surfaces: toMakeTransparent,
        invert: false,
      });
    }
  }), [analysisView, geometryTags, setTransparencySettings]);
};
