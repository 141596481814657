// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 21;
const baseHeight = 24;
export const BellSolidIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="bellSolid"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M10.5,24
           C12.15,24 13.5,22.65 13.5,21
           L7.5,21
           C7.5,22.65 8.85,24 10.5,24Z
           M19.5,16.5
           C18.675,16.5 18,15.825 18,15
           L18,9
           C18,5.355 15.405,2.325 11.97,1.65
           C11.97,1.59 12,1.56 12,1.5
           C12,0.675 11.325,0 10.5,0
           C9.675,0 9,0.675 9,1.5
           C9,1.56 9.03,1.59 9.03,1.65
           C5.595,2.325 3,5.355 3,9
           L3,15
           C3,15.825 2.325,16.5 1.5,16.5
           C0.675,16.5 0,17.175 0,18
           C0,18.825 0.675,19.5 1.5,19.5
           L19.5,19.5
           C20.325,19.5 21,18.825 21,18
           C21,17.175 20.325,16.5 19.5,16.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
