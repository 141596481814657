// This file defines the format of lcmesh files.
//
// A lcmesh file authoritatively describes an unstructured grid.  It is created
// by meshconverter from *.su2, *.msh, etc. Typically, one large lcmesh file
// fully defines a grid. MPI ranks of a FVM solver or ParaView job will
// range-shard the file first, then invoke (par)metis to topologically partition
// the mesh, then shuffles the CVs, faces, and points between ranks so that the
// topology of each partition is self-contained.

// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/lcn/lcmesh.proto (package luminary.proto.lcn, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { EntityGroups } from "../entitygroup/entitygroup_pb.js";
import { Vector3 } from "../base/base_pb.js";
import { PhysicalBoundary } from "../fvm/param_pb.js";

/**
 * Defines the encoding of a grid.
 *
 * @generated from enum luminary.proto.lcn.GridType
 */
export const GridType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.lcn.GridType",
  [
    {no: 0, name: "InvalidGridType"},
    {no: 2, name: "Polyhedral"},
  ],
);

/**
 * @generated from enum luminary.proto.lcn.GenerationMode
 */
export const GenerationMode = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.lcn.GenerationMode",
  [
    {no: 0, name: "InvalidGenerationMode"},
    {no: 1, name: "Unknown"},
    {no: 2, name: "Generative"},
    {no: 3, name: "Fast"},
    {no: 4, name: "Adapt"},
    {no: 5, name: "Smallest"},
    {no: 6, name: "Minimal"},
    {no: 7, name: "External"},
    {no: 8, name: "Tesselation"},
    {no: 9, name: "MesherMixed"},
    {no: 10, name: "LcSurface"},
    {no: 11, name: "MeshGems"},
  ],
);

/**
 * MeshFileMetadata is stored in FileIndex.metadata. It defines the logical
 * structure of a mesh.
 *
 * @generated from message luminary.proto.lcn.MeshFileMetadata
 */
export const MeshFileMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.MeshFileMetadata",
  () => [
    { no: 1, name: "zone", kind: "message", T: ZoneMetadata, repeated: true },
    { no: 2, name: "parent_mesh_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lccad_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "computational_mesh_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "tet_mesh_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "tet_metric_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "entity_groups", kind: "message", T: EntityGroups },
    { no: 8, name: "mesh_bcs", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "description", kind: "message", T: MeshDescription },
  ],
);

/**
 * Zone defines a mesh + boundaries.  Most mesh files have one zone, named "0".
 *
 * @generated from message luminary.proto.lcn.ZoneMetadata
 */
export const ZoneMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.ZoneMetadata",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "point_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "face_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "main_grid_type", kind: "enum", T: proto3.getEnumType(GridType) },
    { no: 7, name: "bound", kind: "message", T: BoundMetadata, repeated: true },
    { no: 8, name: "cv_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "face_patch_id_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "walldistance_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "stats", kind: "message", T: MeshStats },
    { no: 12, name: "zone_id_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "volume_display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.lcn.MeshStats
 */
export const MeshStats = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.MeshStats",
  () => [
    { no: 1, name: "n_points", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "n_faces", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "n_cvs", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "patch_ids", kind: "map", K: 5 /* ScalarType.INT32 */, V: {kind: "scalar", T: 3 /* ScalarType.INT64 */} },
    { no: 5, name: "min_coord", kind: "message", T: Vector3 },
    { no: 6, name: "max_coord", kind: "message", T: Vector3 },
  ],
);

/**
 * Boundary surface definition.
 *
 * @generated from message luminary.proto.lcn.BoundMetadata
 */
export const BoundMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.BoundMetadata",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "grid_type", kind: "enum", T: proto3.getEnumType(GridType) },
    { no: 3, name: "face_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "point_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "stats", kind: "message", T: MeshStats },
    { no: 6, name: "bound_type", kind: "enum", T: proto3.getEnumType(PhysicalBoundary) },
    { no: 7, name: "global_face_id_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "global_point_id_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "start_allbound_face_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "allbound_point_id_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "surface_id_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.lcn.MeshDescription
 */
export const MeshDescription = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.MeshDescription",
  () => [
    { no: 1, name: "mode", kind: "enum", T: proto3.getEnumType(GenerationMode) },
  ],
);

