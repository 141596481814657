// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { getAdValue, newAdFloat } from '../../lib/adUtils';
import { FaultType } from '../../lib/componentTypes/form';
import { FormatOptions, formatNumber, parseLocale } from '../../lib/number';
import * as basepb from '../../proto/base/base_pb';
import { QuantityType } from '../../proto/quantity/quantity_pb';
import QuantityAdornment from '../QuantityAdornment';

import { TextInput, TextInputProps } from './TextInput';

function formatNullableNum(value: number | null, format?: FormatOptions): string {
  if (value === null || Number.isNaN(value)) {
    return '';
  }
  return formatNumber(value, format);
}

type BaseProps = Omit<TextInputProps, 'multiline' | 'onChange' | 'onCommit' | 'value'>;
export interface NumberInputProps extends BaseProps {
  // The numerical value to display
  value: number | null;
  // Called when input value is committed (by hitting Enter or blurring the input)
  onCommit?: (value: number) => void;
  // Called when value in textbox is changed
  onChange?: (value: number) => void;
  // The formatting options for the number.
  format?: FormatOptions;
  // Optional quantity to show after each input
  quantityType?: QuantityType;
}

export const NumberInput = (props: NumberInputProps) => {
  const { onChange, onCommit, onBlur, onFocus, format, value, quantityType, ...inputProps } = props;

  // Track faultType prop for TextInput
  const [faultType, setFaultType] = useState<FaultType | undefined>();
  // The displayValue keeps the TextInput synced for cases where the user enters non-numeric values
  // and onCommit never fires.
  const [displayValue, setDisplayValue] = useState('');

  // This component is used by Vector3Input, which may already pass in an endAdornment. If there is
  // no quantityType directly passed to NumberInput, use the endAdornment in the props (which may
  // be undefined).
  const endAdornment = quantityType ? (
    <QuantityAdornment quantity={quantityType} />
  ) : inputProps?.endAdornment;

  const commitValue = (newValue: string) => {
    // This component sets TextInput values to formatted strings (via formatNumber, which uses
    // locale-aware Intl.NumberFormat). To restore an actual numeric value, parse the string
    // value with locale awareness.
    const numValue = parseLocale(newValue);
    if (!Number.isNaN(numValue)) {
      onCommit?.(numValue);
    }
  };

  // Reset displayValue to track value prop after the input has blurred
  const onBlurFormat = () => {
    onBlur?.();
    setDisplayValue(formatNullableNum(value, format));
  };

  // Set displayValue to the raw value when the input is focused
  const onFocusFormat = () => {
    onFocus?.();
    setDisplayValue(value === null ? '' : `${value}`);
  };

  // When TextInput changes, its value may be numeric or not; adjust faultType and displayValue
  // accordingly.
  const changeValue = (textValue: string) => {
    const numValue = parseLocale(textValue);
    if (Number.isNaN(numValue)) {
      setFaultType('warning');
    } else {
      setFaultType(undefined);
    }
    // Don't apply formatNumber yet, since the user is still entering numbers
    setDisplayValue(textValue);
    onChange?.(numValue);
  };

  useEffect(() => {
    if (Number.isNaN(value)) {
      setFaultType('warning');
      setDisplayValue('');
    } else {
      setFaultType(undefined);
      setDisplayValue(formatNullableNum(value, format));
    }
  }, [format, value]);

  return (
    <TextInput
      {...inputProps}
      endAdornment={endAdornment}
      faultType={inputProps.faultType || faultType}
      justify="end"
      onBlur={onBlurFormat}
      onChange={changeValue}
      onCommit={commitValue}
      onFocus={onFocusFormat}
      value={displayValue}
    />
  );
};

export interface AdNumberInputProps extends Omit<NumberInputProps, 'value' | 'onCommit'> {
  // The AdFloatType value to display
  value: basepb.AdFloatType | null;
  // Called when input value is committed (by hitting Enter or blurring the input)
  onCommit?: (value: basepb.AdFloatType) => void;
}

export const AdNumberInput = (props: AdNumberInputProps) => {
  const { onCommit, value, ...numInputProps } = props;
  return (
    <NumberInput
      {...numInputProps}
      onCommit={(numValue) => onCommit?.(newAdFloat(numValue))}
      value={value === null ? null : getAdValue(value)}
    />
  );
};
