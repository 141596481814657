// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ComponentProps, useEffect, useRef } from 'react';

import Draggable, { type DraggableEventHandler } from 'react-draggable';

interface DraggableWrapperProps extends ComponentProps<'div'> {
  tabId: string;
  onTabDrag: DraggableEventHandler;
  onTabDragStop: DraggableEventHandler;
  onSizeChange: () => void;
  minOffset?: number;
  children: React.ReactNode;
  isCurrentlyDragged: boolean;
  offset: number;
}

/**
 * This component wraps draggable tabs, handling all behavior-related properties such as
 * offset bounds, zIndex, and drag handlers. It also monitors tab width to ensure the
 * dragging mechanism adjusts correctly when the tab name changes.
 */
export function DraggableWrapper(props: DraggableWrapperProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    onTabDrag,
    onTabDragStop,
    isCurrentlyDragged,
    onSizeChange,
    tabId,
    offset,
    minOffset,
    ...divProps
  } = props;

  useEffect(() => {
    const element = wrapperRef.current;

    if (!element) {
      return () => {};
    }

    const resizeObserver = new ResizeObserver(onSizeChange);

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, [onSizeChange]);

  return (
    <Draggable
      axis="x"
      bounds={{ left: minOffset }}
      onDrag={onTabDrag}
      onStop={onTabDragStop}
      position={{ x: offset, y: 0 }}>
      <div
        data-tab-id={tabId}
        ref={wrapperRef}
        {...divProps}
        style={{ ...divProps.style, zIndex: isCurrentlyDragged ? 1 : undefined }}
      />
    </Draggable>
  );
}
