// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { CallbackInterface, useRecoilCallback, waitForAll } from 'recoil';

import { RecoilProjectKey } from '../../lib/persist';

import { lcvisExplodeFactor } from './explodeFactor';
import { lcvisCameraSelector } from './lcvisCameraState';
import { lcvisMeasureState } from './lcvisMeasureState';
import { lcvisProbeState } from './lcvisProbeState';

export type ActiveOverlayType = 'zoomToBox' | 'centerOfRotation' | 'probe' | 'explode' |
                                'measure' | 'none'

/**
 * There are several sources that can trigger an overlay on LCVis.
 * for example, the probe overlay is triggered when lcvisProbeState.active is true,
 * and the zoom to box and center of rotation are triggered by lcvisCameraSelector attributes.
 * @returns a function that deactivates any overlay sources that might be active.
 *
 * Calling the returned function is a noop if no overlays are active.
 */
export const useRemoveOverlays = (
  key: RecoilProjectKey,
) => useRecoilCallback(({ set, snapshot: { getPromise } }: CallbackInterface) => async (
  // If excludeType is specified, the function will remove all overlays other than that type.
  // By default, all overlays will be removed.
  options: { excludeType: ActiveOverlayType } = { excludeType: 'none' },
) => {
  const { excludeType } = options;
  const [oldCamera, oldProbe, oldMeasure] = await getPromise(waitForAll([
    lcvisCameraSelector(key),
    lcvisProbeState,
    lcvisMeasureState,
  ]));

  set(lcvisCameraSelector(key), (() => {
    let edited = false;
    const newCamera = { ...oldCamera };
    if (oldCamera.center_of_rotation_modifier && excludeType !== 'centerOfRotation') {
      newCamera.center_of_rotation_modifier = 0;
      edited = true;
    }
    if (oldCamera.zoom_to_box_modifier && excludeType !== 'zoomToBox') {
      newCamera.zoom_to_box_modifier = 0;
      edited = true;
    }
    // if the camera wasn't edited, return the old camera so that its reference doesn't change
    // and we don't trigger any new renders.
    return edited ? newCamera : oldCamera;
  })());

  set(lcvisProbeState, (() => {
    if (oldProbe.active && excludeType !== 'probe') {
      return { ...oldProbe, active: false, probedId: '', placedProbe: false };
    }
    // return oldProbe if no edits occurred, so the state reference doesn't change.
    return oldProbe;
  })());

  set(lcvisMeasureState, (() => {
    if (oldMeasure.active && excludeType !== 'measure') {
      return { ...oldMeasure, active: false, pointToUdpdate: 0, length: undefined };
    }
    // return old measure if no edits occurred, so the state reference doesn't change.
    return oldMeasure;
  })());

  if (excludeType !== 'explode') {
    set(lcvisExplodeFactor(key.projectId), null);
  }
}, []);
