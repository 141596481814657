// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useMemo } from 'react';

import cx from 'classnames';

import { CurrentView } from '../../lib/componentTypes/context';
import { colors } from '../../lib/designSystem';
import objectId from '../../lib/objectId';
import { useCurrentView } from '../../state/internal/global/currentView';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { FixedSizeWrapper } from '../layout/FixedSizeWrapper';
import { CircleIcon } from '../svg/CircleIcon';
import { DiskCheckIcon } from '../svg/DiskCheckIcon';
import { DottedCircleIcon } from '../svg/DottedCircleIcon';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      gap: '16px',

      '--active-border-size': '4px',
      '--font-size': '14px',
      '--font-weight': 400,
      '--line-height': '20px',
      '--padding': '14px 8px 10px',

      '&.compact': {
        '--active-border-size': '2px',
        '--font-size': '13px',
        '--font-weight': 600,
        '--line-height': '16px',
        '--padding': '7px 0',
      },
    },
    span: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      borderBottom: 'var(--active-border-size) solid transparent',

      '&.selected': {
        borderBottomColor: colors.primaryCta,
        color: 'white',
      },
    },
    icon: {
      paddingTop: '4px',
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      color: colors.lowEmphasisText,
      fontSize: 'var(--font-size)',
      fontWeight: 'var(--font-weight)',
      lineHeight: 'var(--line-height)',
      padding: 'var(--padding)',
      margin: 0,
      outline: 0,
      border: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',

      '&.disabled': {
        color: colors.disabledType,
      },
    },
  }),
  { name: 'PaneSwitcher' },
);

export interface PaneButtonProps {
  // Whether the button reflects the current mode
  selected: boolean;
  // Button click handler
  onClick: () => void;
  // The text of the button.
  text: ReactNode;
  // The title that appears in the tooltip.
  title?: string;
  // Disables the button.
  disabled?: boolean;
  // Uses a smaller font size and padding
  compact?: boolean;
  // if defined, will show an empty circle if false, and a check if true
  completedPhase?: boolean;
  startIcon?: ReactNode;
}

// Draw a single button for switching to a particular mode.
const PaneButton = (props: PaneButtonProps) => {
  const { disabled, selected, compact, onClick, text, title = '', completedPhase } = props;
  const classes = useStyles();
  const currentView = useCurrentView();
  const isGeoTab = currentView === CurrentView.GEOMETRY;

  const phaseIcon = useMemo(() => {
    // In the geometry tab, the kvstore isn't used, so we're not able to accurately get the
    // warnings/validation state for any other tabs. So we should just show a generic icon
    // till the user leaves that phase.
    if (isGeoTab && completedPhase !== undefined) {
      return (
        <CircleIcon color={colors.lowEmphasisText} />
      );
    }
    return (completedPhase !== undefined || props.startIcon) && (
      <>
        {completedPhase ?
          <DiskCheckIcon color={colors.green500} /> :
          <DottedCircleIcon />}
        {props.startIcon}
      </>
    );
  }, [completedPhase, isGeoTab, props.startIcon]);

  const tooltipTitle = props.title ?? (
    completedPhase !== undefined && isGeoTab ?
      `Continue to ${text} after completing the geometry phase` :
      ''
  );

  return (
    <div>
      <Tooltip title={disabled ? '' : tooltipTitle}>
        <span className={cx(classes.span, { selected })}>
          {phaseIcon && (
            <div className={classes.icon}>
              <FixedSizeWrapper height={12} width={12}>
                {phaseIcon}
              </FixedSizeWrapper>
            </div>
          )}
          <button
            className={cx(classes.button, { disabled, selected, compact })}
            data-pane-button={text}
            disabled={disabled}
            onClick={onClick}
            title={title}
            type="button">
            {text}
          </button>
        </span>
      </Tooltip>
    </div>
  );
};

interface PaneSwitcherProps {
  buttons: PaneButtonProps[];
  compact?: boolean;
}

// Draw several buttons for switching between modes in the right pane.
const PaneSwitcher = (props: PaneSwitcherProps) => {
  const { buttons, compact } = props;

  const classes = useStyles();

  return (
    <div className={cx(classes.root, { compact })}>
      {buttons.map((buttonProps) => (
        <PaneButton {...buttonProps} key={objectId(buttonProps)} />
      ))}
    </div>
  );
};

export default PaneSwitcher;
