// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as codespb from '../proto/lcstatus/codes_pb';
import * as geometrypb from '../proto/lcstatus/details/geometry/geometry_pb';
import * as lcstatuspb from '../proto/lcstatus/lcstatus_pb';

import { EntityGroupMap } from './entityGroupMap';
import { formatVector } from './motionDataFormat';
import { formatNumber } from './number';
import { unpackProto } from './protoUtils';
import { wordsToList } from './text';
import { volumeNodeId } from './volumeUtils';

// Provides a list of supported error codes. Errors not in this list will be filtered out.
// This also provides the desired order of the error codes. The exact order here is not so
// important, but it is important to group the errors and be consistent.
export const SUPPORTED_ERROR_CODES = [
  // Errors
  codespb.Code.GEO_VERTEX_DUPLICATE,
  codespb.Code.GEO_FACE_FACE_INTERSECTION,
  codespb.Code.GEO_FACE_SELF_INTERSECTION,
  codespb.Code.GEO_VOLUME_OPEN,
  codespb.Code.GEO_VOLUME_NON_MANIFOLD,
  codespb.Code.GEO_VOLUME_UNMESHABLE,
  codespb.Code.GEO_EDGE_UNMESHABLE,
  codespb.Code.GEO_FACE_UNMESHABLE,

  // Warnings
  codespb.Code.GEO_FACE_EDGES_TOO_CLOSE,
  codespb.Code.GEO_EDGE_NOT_SMOOTH,
  codespb.Code.GEO_EDGE_LARGE_TOLERANCE,
  codespb.Code.GEO_FACE_NOT_SMOOTH,
  codespb.Code.GEO_FACE_LARGE_TOLERANCE,
  codespb.Code.GEO_FACE_EDGE_TOO_SMALL,
  codespb.Code.GEO_FACE_EDGES_TOO_CLOSE,
  codespb.Code.GEO_FACE_EDGE_CROSS,
];

// Get the title of an issue.
export function getTitle(issue: lcstatuspb.LCStatus): string {
  switch (issue.code) {
    case codespb.Code.GEO_VERTEX_DUPLICATE:
      return 'Duplicate vertices';
    case codespb.Code.GEO_EDGE_UNMESHABLE:
      return 'Unmeshable edge';
    case codespb.Code.GEO_FACE_UNMESHABLE:
      return 'Unmeshable face';
    case codespb.Code.GEO_FACE_EDGES_TOO_CLOSE:
      return 'Sliver faces';
    case codespb.Code.GEO_FACE_EDGE_TOO_SMALL:
      return 'Tiny edge';
    case codespb.Code.GEO_FACE_FACE_INTERSECTION:
      return 'Face-face intersection';
    case codespb.Code.GEO_FACE_SELF_INTERSECTION:
      return 'Face self-intersection';
    case codespb.Code.GEO_VOLUME_OPEN:
      return 'Open volume';
    case codespb.Code.GEO_VOLUME_NON_MANIFOLD:
      return 'Non-manifold volume';
    case codespb.Code.GEO_VOLUME_UNMESHABLE:
      return 'Unmeshable volume';
    case codespb.Code.GEO_EDGE_NOT_SMOOTH:
      return 'Edge not smooth';
    case codespb.Code.GEO_EDGE_LARGE_TOLERANCE:
      return 'Edge with large tolerance';
    case codespb.Code.GEO_FACE_NOT_SMOOTH:
      return 'Face not smooth';
    case codespb.Code.GEO_FACE_LARGE_TOLERANCE:
      return 'Face with large tolerance';
    case codespb.Code.GEO_FACE_EDGE_CROSS:
      return 'Two edges cross on a face';
    default:
      return 'Unknown';
  }
}

// Returns the surface name, given the ID and entityGroupMap. This also guards against a crash when
// the ID is not in the entityGroupMap. This can happen temporarily if entityGroupMap is updated
// while the geometryHealth card is being removed.
function getSurfaceName(id: string, entityGroupMap: EntityGroupMap) {
  return entityGroupMap.has(id) ? entityGroupMap.get(id).name : '?';
}

function getEdgeSurfaceNames(edge: geometrypb.Edge, entityGroupMap: EntityGroupMap) {
  return wordsToList(
    edge.adjacentSurfaces.map((id) => getSurfaceName(id, entityGroupMap)),
  );
}

// Get the description of an issue.
export function getDescription(issue: lcstatuspb.LCStatus, entityGroupMap: EntityGroupMap): string {
  switch (issue.code) {
    case codespb.Code.GEO_VERTEX_DUPLICATE: {
      const details = unpackProto(issue.details, geometrypb.GeoVertexDuplicateDetails);
      const coords1 = details?.vertex1?.coords;
      const coords2 = details?.vertex2?.coords;
      formatVector(coords1, undefined);
      return `Space is too small between vertex ${formatVector(coords1, undefined)} and vertex
        ${formatVector(coords2, undefined)}. Please modify the definition of these nodes.`;
    }
    case codespb.Code.GEO_EDGE_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeUnmeshableDetails);
      const surfaceIds = details?.edge?.adjacentSurfaces;
      const names = surfaceIds?.map((surfaceId) => getSurfaceName(surfaceId, entityGroupMap)) || [];
      return `Could not interpret the underlying geometry of the edge at the intersection of
        ${wordsToList(names)}; please modify the definition of this edge in CAD.`;
    }
    case codespb.Code.GEO_FACE_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceUnmeshableDetails);
      const surfaceId = details?.surfaceId || '';
      const name = getSurfaceName(surfaceId, entityGroupMap);
      return `Could not robustly interpret the underlying geometry of ${name}; please modify the
        definition of this face in CAD.`;
    }
    case codespb.Code.GEO_FACE_EDGES_TOO_CLOSE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgesTooCloseDetails);
      const surfaceId = details?.surfaceId || '';
      const name = getSurfaceName(surfaceId, entityGroupMap);
      const intro = details?.aspectRatio && details.aspectRatio > 0 ?
        `has an approximate aspect ratio of ${formatNumber(details.aspectRatio)}` :
        'has bounding edges that are very close together';
      return `${name} ${intro}, which may introduce undesirably small elements in your computational
        mesh. This can negatively impact the robustness and accuracy of the solver.`;
    }
    case codespb.Code.GEO_FACE_EDGE_TOO_SMALL: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeTooSmallDetails);
      const surfaceId = details?.surfaceId || '';
      const name = getSurfaceName(surfaceId, entityGroupMap);
      return `${name} has a very small edge, which may introduce undesirably small elements in your
        computational mesh. This can negatively impact the robustness and accuracy of the solver.`;
    }
    case codespb.Code.GEO_FACE_FACE_INTERSECTION: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceFaceIntersectionDetails);
      const name1 = getSurfaceName(details!.surface1Id, entityGroupMap);
      const name2 = getSurfaceName(details!.surface2Id, entityGroupMap);
      return `A non-manifold geometry intersection was detected between ${name1} and ${name2};
        please modify the definition of these faces so they do not intersect.`;
    }
    case codespb.Code.GEO_FACE_SELF_INTERSECTION: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceSelfIntersectionDetails);
      const name = getSurfaceName(details!.surfaceId, entityGroupMap);
      return `A non-manifold geometry self-intersection was detected in ${name};
        please modify the definition of this face so it does not self-intersect.`;
    }
    case codespb.Code.GEO_VOLUME_OPEN: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeOpenDetails);
      const name = entityGroupMap.get(volumeNodeId(details!.volumesId)).name;
      return `${name} is not a properly closed volume and could not be meshed. Please modify the
        definition of this volume in CAD.`;
    }
    case codespb.Code.GEO_VOLUME_NON_MANIFOLD: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeNonManifoldDetails);
      const name = entityGroupMap.get(volumeNodeId(details!.volumeId)).name;
      return `${name} is a non-manifold volume and could not be meshed. Please modify the
        definition of this volume in CAD.`;
    }
    case codespb.Code.GEO_VOLUME_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeUnmeshableDetails);
      const name = entityGroupMap.get(volumeNodeId(details!.volumeId)).name;
      return `Could not robustly interpret the underlying geometry of ${name}; please modify the
        definition of this volume in CAD.`;
    }
    case codespb.Code.GEO_EDGE_NOT_SMOOTH: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeNotSmoothDetails);
      const surfaces = getEdgeSurfaceNames(details!.edge!, entityGroupMap);
      return `A discontinuity was detected in the edge between ${surfaces} which may introduce
        undesirably small mesh elements. This can negatively impact the robustness and accuracy of
        the solver.`;
    }
    case codespb.Code.GEO_EDGE_LARGE_TOLERANCE: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeLargeToleranceDetails);
      const tolerance = formatNumber(details?.tolerance ?? 0);
      const surfaces = getEdgeSurfaceNames(details!.edge!, entityGroupMap);
      return `A large geometric tolerance (${tolerance}) was detected in the edge between
        ${surfaces} which may introduce undesirable behavior during mesh generation. This can
        negatively impact the robustness and accuracy of the solver.`;
    }
    case codespb.Code.GEO_FACE_NOT_SMOOTH: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceNotSmoothDetails);
      const name = getSurfaceName(details!.surfaceId, entityGroupMap);
      return `A discontinuity was detected in the face ${name} which may introduce undesirably small
        mesh elements. This can negatively impact the robustness and accuracy of the solver.`;
    }
    case codespb.Code.GEO_FACE_LARGE_TOLERANCE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceLargeToleranceDetails);
      const tolerance = formatNumber(details?.tolerance ?? 0);
      const name = getSurfaceName(details!.surfaceId, entityGroupMap);
      return `A large geometric tolerance (${tolerance}) was detected in the ${name} which may
        introduce undesirable behavior during mesh generation. This could negatively impact the
        local mesh size and/or quality.`;
    }
    case codespb.Code.GEO_FACE_EDGE_CROSS: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeCrossDetails);
      const name = getSurfaceName(details!.surfaceId, entityGroupMap);
      return `Two edges bounding ${name} cross each other, which may introduce poorly defined mesh
        elements. This can negatively impact the robustness and accuracy of the solver.`;
    }
    default:
      return 'Unknown';
  }
}

function verticesToSurfacesIds(vertices: (geometrypb.Vertex | undefined)[]): string[] {
  return vertices.map((vertex) => vertex?.adjacentSurfaces ?? []).flat();
}

// Get a list of surface IDs from the status proto.
export function getNodeIds(issue: lcstatuspb.LCStatus): string[] {
  switch (issue.code) {
    case codespb.Code.GEO_VERTEX_DUPLICATE: {
      const details = unpackProto(issue.details, geometrypb.GeoVertexDuplicateDetails);
      return verticesToSurfacesIds([details?.vertex1, details?.vertex2]);
    }
    case codespb.Code.GEO_EDGE_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeUnmeshableDetails);
      const surfaceIds = details?.edge?.adjacentSurfaces || [];
      return surfaceIds;
    }
    case codespb.Code.GEO_FACE_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceUnmeshableDetails);
      const surfaceId = details?.surfaceId || '';
      return [surfaceId];
    }
    case codespb.Code.GEO_FACE_EDGES_TOO_CLOSE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgesTooCloseDetails);
      const surfaceId = details?.surfaceId || '';
      return [surfaceId];
    }
    case codespb.Code.GEO_FACE_EDGE_TOO_SMALL: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeTooSmallDetails);
      const surfaceId = details?.surfaceId || '';
      return [surfaceId];
    }
    case codespb.Code.GEO_FACE_FACE_INTERSECTION: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceFaceIntersectionDetails);
      return [details!.surface1Id, details!.surface2Id];
    }
    case codespb.Code.GEO_FACE_SELF_INTERSECTION: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceSelfIntersectionDetails);
      return [details!.surfaceId];
    }
    case codespb.Code.GEO_VOLUME_OPEN: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeOpenDetails);
      return [volumeNodeId(details!.volumesId)];
    }
    case codespb.Code.GEO_VOLUME_NON_MANIFOLD: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeNonManifoldDetails);
      return [volumeNodeId(details!.volumeId)];
    }
    case codespb.Code.GEO_VOLUME_UNMESHABLE: {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeUnmeshableDetails);
      return [volumeNodeId(details!.volumeId)];
    }
    case codespb.Code.GEO_EDGE_NOT_SMOOTH: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeNotSmoothDetails);
      return details!.edge!.adjacentSurfaces;
    }
    case codespb.Code.GEO_EDGE_LARGE_TOLERANCE: {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeLargeToleranceDetails);
      return details!.edge!.adjacentSurfaces;
    }
    case codespb.Code.GEO_FACE_NOT_SMOOTH: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceNotSmoothDetails);
      return [details!.surfaceId];
    }
    case codespb.Code.GEO_FACE_LARGE_TOLERANCE: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceLargeToleranceDetails);
      return [details!.surfaceId];
    }
    case codespb.Code.GEO_FACE_EDGE_CROSS: {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeCrossDetails);
      return [details!.surfaceId];
    }
    default:
      return [];
  }
}

const GEOM_HEALTH_ID_PREFIX = 'geom-health-card-';

export function geomHealthIndexToNodeId(cardIndex: number) {
  return `${GEOM_HEALTH_ID_PREFIX}${cardIndex}`;
}

export function geomHealthNodeIdtoIndex(id: string) {
  const regexPattern = new RegExp(`^${GEOM_HEALTH_ID_PREFIX}(\\d+)+$`);
  if (regexPattern.test(id)) {
    return parseInt(RegExp.$1, 10);
  }
  return -1;
}

export function isGeomHealthId(id: string) {
  return id.startsWith(GEOM_HEALTH_ID_PREFIX);
}

export const GEOM_HEALTH_SUBTITLES = {
  errors: `The following errors were identified in your CAD file. Please fix the errors and
      re-upload the file to proceed.`,
  warnings: `The following warnings were found in your CAD file. We recommend fixing the issues
      before proceeding.`,
  // This happens when there are no errors to report, but check geometry failed. Ideally, we would
  // report all errors, but currently this can happen.
  notOk: `Errors were found in your CAD file. Please fix the errors and re-upload the file
      to proceed.`,
  ok: 'Geometry is clean.',

  // iGeo project
  igeoErrors: `The following errors were identified in your geometry. Please fix the errors in the
    Geometry tab to proceed.`,
  igeoWarnings: `The following warnings were found in your geometry. We recommend fixing the issues
    before proceeding.`,
  igeoNotOk: `Errors were found in your geometry. Please fix the errors in the Geometry tab to
    proceed.`,
} as const;

/**
 * Gets the subtitle for the geometry health card based on whether there are warnings, errors, or
 * if the geometry is ok. If the project is an iGeo project, the subtitle will be different.
 */
export const getGeomHealthSubtitle = (
  hasWarnings: boolean,
  hasErrors: boolean,
  ok: boolean,
  isIgeoProject: boolean,
) => {
  let subtitle = '';

  if (isIgeoProject) {
    if (hasErrors) {
      subtitle = GEOM_HEALTH_SUBTITLES.igeoErrors;
    } else if (!ok) {
      subtitle = GEOM_HEALTH_SUBTITLES.igeoNotOk;
    } else if (hasWarnings) {
      subtitle = GEOM_HEALTH_SUBTITLES.igeoWarnings;
    } else {
      subtitle = GEOM_HEALTH_SUBTITLES.ok;
    }
    return subtitle;
  }

  if (hasErrors) {
    subtitle = GEOM_HEALTH_SUBTITLES.errors;
  } else if (!ok) {
    // This happens when there are no errors to report, but check geometry failed. Ideally, we would
    // report all errors, but currently this can happen.
    subtitle = GEOM_HEALTH_SUBTITLES.notOk;
  } else if (hasWarnings) {
    subtitle = GEOM_HEALTH_SUBTITLES.warnings;
  } else {
    subtitle = GEOM_HEALTH_SUBTITLES.ok;
  }
  return subtitle;
};
