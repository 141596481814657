// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect } from 'react';

import * as Vector from '../../../../lib/Vector';
import { RadioButtonOption } from '../../../../lib/componentTypes/form';
import { Logger } from '../../../../lib/observability/logs';
import { addRpcError } from '../../../../lib/transientNotification';
import { EditSource } from '../../../../lib/visUtils';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useEditState } from '../../../../recoil/paraviewState';
import Form from '../../../Form';
import { DataSelect } from '../../../Form/DataSelect';
import { NumberInput } from '../../../Form/NumberInput';
import { RadioButtonGroup } from '../../../Form/RadioButtonGroup';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import { LineInput, LineParam } from '../../../visFilter/LineInput';
import { SphereInput, SphereParam } from '../../../visFilter/SphereInput';
import { useSelectedFilterNode } from '../../../visFilter/useFilterNode';
import { FilterEditControl } from '../../FilterEditControl';
import PropertiesSection from '../../PropertiesSection';
import { CommonFilterMessages } from '../shared/CommonFilterMessages';
import { FilterDisplayPanel } from '../shared/FilterDisplayPanel';

import { FilterPropertiesPanelProps } from './props';

const logger = new Logger('filter/StreamTracer');

function defaultSphereWidgetSeed(parent: ParaviewRpc.TreeNode): ParaviewRpc.SphereParam {
  const radius = (parent?.bounds && parent.bounds.length > 2) ?
    parseFloat(((Math.abs(parent.bounds[0]) + Math.abs(parent.bounds[1])) / 10).toPrecision(4)) :
    10.0;

  return {
    typ: 'Sphere',
    center: {
      x: 0,
      y: 0,
      z: 0,
    },
    radius,
  };
}

function defaultLineWidgetSeed(): ParaviewRpc.LineParam {
  return {
    typ: 'Line',
    point1: {
      x: 0,
      y: 0,
      z: 0,
    },
    point2: {
      x: 1,
      y: 0,
      z: 0,
    },
  };
}

function defaultSphereWidgetParam(parent: ParaviewRpc.TreeNode, dataName: string):
  ParaviewRpc.StreamTracerParam {
  return {
    typ: ParaviewRpc.TreeNodeType.STREAM_TRACER,
    dataName,
    seedType: ParaviewRpc.StreamTracerParamType.SPHERE,
    seed: defaultSphereWidgetSeed(parent),
    maxLength: 20,
    width: 1,
    resolution: 100,
  };
}

/** Create the param filled with default values, to be used when creating a new
    filter off the given parent. */
export function newStreamTracerParam(
  parent: ParaviewRpc.TreeNode,
): ParaviewRpc.StreamTracerParam {
  const data = parent.pointData.filter((item: ParaviewRpc.ArrayInformation) => item.dim === 3);
  return defaultSphereWidgetParam(parent, data[0].name);
}

// Parameter-input dialog for the stream tracer filter.
export const StreamTracerPropPanel = (props: FilterPropertiesPanelProps) => {
  const { displayProps, filterNode, nodeId, parentFilterNode } = props;

  const { paraviewClientState, paraviewRenderer } = useParaviewContext();

  const [editState] = useEditState();
  const paraviewClient = paraviewClientState.client;
  const editSource = editState ? editState.editSource : EditSource.FORM;
  const { updateEditState } = useSelectedFilterNode();
  const onUpdate = (source: EditSource, newParam: ParaviewRpc.TreeNodeParam) => (
    updateEditState({
      editSource: source,
      param: newParam,
    })
  );

  const param = props.param as ParaviewRpc.StreamTracerParam;
  const readOnly = !editState;
  // Stream tracer works only for vector data.
  const data = parentFilterNode.pointData.filter(
    (item: ParaviewRpc.ArrayInformation) => item.dim === 3,
  );
  const { seed, seedType } = param;
  const empty = !data || data.length <= 0;

  const setDataName = (newName: string) => {
    onUpdate(
      EditSource.FORM,
      {
        ...param,
        dataName: newName,
      },
    );
  };

  const setSeedType = (newSeedType: ParaviewRpc.StreamTracerParamType) => {
    onUpdate(
      EditSource.FORM,
      {
        ...param,
        seedType: newSeedType,
        seed: (newSeedType === ParaviewRpc.StreamTracerParamType.LINE) ?
          defaultLineWidgetSeed() : defaultSphereWidgetSeed(filterNode),
      },
    );
  };

  const setStreamlineLength = (newLength: number) => {
    onUpdate(
      EditSource.FORM,
      {
        ...param,
        maxLength: newLength,
      },
    );
  };

  const setStreamlineWidth = (newWidth: number) => {
    onUpdate(
      EditSource.FORM,
      {
        ...param,
        width: newWidth,
      },
    );
  };
  const setStreamlineResolution = (newRes: number) => {
    onUpdate(
      EditSource.FORM,
      {
        ...param,
        resolution: newRes,
      },
    );
  };

  useEffect(() => {
    if (!editState) {
      return () => { };
    }

    let mounted = true;
    let unsubscribeFn: (() => void) | null = null;
    // Called when the user moves the widget interactively.
    const onLineWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION) {
        throw Error(`only line widget supported now: ${JSON.stringify(newParam)}`);
      }
      if (!mounted) {
        return;
      }
      if (param.seedType !== ParaviewRpc.StreamTracerParamType.LINE) {
        return;
      }
      const line = newParam.line ? newParam.line : defaultLineWidgetSeed();
      const newPoint1 = Vector.toProto(line.point1);
      const newPoint2 = Vector.toProto(line.point2);
      onUpdate(
        EditSource.PARAVIEW,
        {
          ...param,
          seed: {
            typ: 'Line',
            point1: Vector.toPvProto(newPoint1),
            point2: Vector.toPvProto(newPoint2),
          },
        },
      );
    };

    const onSphereWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.SPHERE_WIDGET_REPRESENTATION) {
        throw Error(`only sphere widget supported now: ${JSON.stringify(newParam)}`);
      }
      if (!mounted) {
        return;
      }
      if (param.seedType !== ParaviewRpc.StreamTracerParamType.SPHERE) {
        return;
      }
      const newCenter = Vector.toProto(newParam.sphere.center);
      const newRadius = newParam.sphere.radius;
      onUpdate(
        EditSource.PARAVIEW,
        {
          ...param,
          seed: {
            typ: 'Sphere',
            center: Vector.toPvProto(newCenter),
            radius: newRadius,
          },
        },
      );
    };

    const startWidget = async (): Promise<void> => {
      if (!paraviewClient) {
        return;
      }
      if (param.seedType === ParaviewRpc.StreamTracerParamType.LINE) {
        // Register the widget update handler.
        unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onLineWidgetUpdate);
        const lineParam = param.seed as ParaviewRpc.LineParam;
        // Activate the widget.
        paraviewRenderer.activateWidget(filterNode.bounds!, {
          typ: ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION,
          line: lineParam,
          resolution: 1000.0,
        });
      } else if (param.seedType === ParaviewRpc.StreamTracerParamType.SPHERE) {
        // Register the widget update handler.
        unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onSphereWidgetUpdate);
        const sphereParam = param.seed as ParaviewRpc.SphereParam;
        paraviewRenderer.activateWidget(filterNode.bounds!, {
          typ: ParaviewRpc.WidgetType.SPHERE_WIDGET_REPRESENTATION,
          sphere: sphereParam,
        });
      }
    };
    startWidget().then(() => {
    }).catch((err: Error) => {
      addRpcError('Could not activate streamtracer widget', err);
    });
    return () => {
      mounted = false;
      if (unsubscribeFn) {
        unsubscribeFn();
      }
    };
  }, [!!editState, nodeId, seedType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editState && editSource !== EditSource.PARAVIEW) {
      logger.debug('StreamTracer: set widget');
      // When the user manually updates the dialog, reflect the new
      // params to the widget.
      if (param.seedType === ParaviewRpc.StreamTracerParamType.LINE) {
        const lineParam = param.seed as ParaviewRpc.LineParam;
        const ws: ParaviewRpc.LineSourceWidgetState = {
          typ: ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION,
          line: lineParam,
          resolution: 1000.0,
        };
        paraviewRenderer.activateWidget(filterNode.bounds!, ws);
      } else if (param.seedType === ParaviewRpc.StreamTracerParamType.SPHERE) {
        const sphereParam = param.seed as ParaviewRpc.SphereParam;
        const ws: ParaviewRpc.SphereWidgetState = {
          typ: ParaviewRpc.WidgetType.SPHERE_WIDGET_REPRESENTATION,
          sphere: sphereParam,
        };
        paraviewRenderer.activateWidget(filterNode.bounds!, ws);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!editState, editSource, seed, nodeId]);

  const options: RadioButtonOption<string>[] = [{
    disabled: readOnly,
    label: 'Line',
    value: ParaviewRpc.StreamTracerParamType.LINE,
  }, {
    disabled: readOnly,
    label: 'Sphere',
    value: ParaviewRpc.StreamTracerParamType.SPHERE,
  }];

  return (
    <div>
      <CommonFilterMessages emptyFilter={empty} />
      <FilterDisplayPanel filterNode={filterNode} />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={!!editState}
          expandWhenDisabled
          headerRight={(
            <FilterEditControl
              disableEdit={empty}
              displayProps={displayProps}
              nodeId={nodeId}
              param={param}
            />
          )}
          heading="Visualization Input"
          nodeId={nodeId}
          panelName="input">
          <Form.LabeledInput label="Streamline Field">
            <DataSelect
              asBlock
              disabled={readOnly}
              onChange={setDataName}
              options={data.map(({ name }) => ({
                name,
                value: name,
                selected: name === param.dataName,
              }))}
              size="small"
            />
          </Form.LabeledInput>
          <Form.LabeledInput label="Seed Type">
            <RadioButtonGroup
              disabled={readOnly}
              kind="secondary"
              name="streamTracerTypes"
              onChange={(type) => setSeedType(type as ParaviewRpc.StreamTracerParamType)}
              options={options}
              value={param.seedType}
            />
          </Form.LabeledInput>
          {param.seedType === ParaviewRpc.StreamTracerParamType.LINE ? (
            <LineInput
              onCommit={(newParam: LineParam) => onUpdate(
                EditSource.FORM,
                {
                  ...param,
                  seed: {
                    typ: 'Line',
                    point1: Vector.toPvProto(newParam.point1),
                    point2: Vector.toPvProto(newParam.point2),
                  },
                },
              )}
              param={{
                point1: Vector.toProto((param.seed as ParaviewRpc.LineParam).point1 ?
                  (param.seed as ParaviewRpc.LineParam).point1 :
                  { x: 0, y: 0, z: 0 }),
                point2: Vector.toProto((param.seed as ParaviewRpc.LineParam).point2 ?
                  (param.seed as ParaviewRpc.LineParam).point2 :
                  { x: 1, y: 0, z: 0 }),
              }}
              readOnly={readOnly}
            />
          ) : (
            <SphereInput
              onCommit={(newParam: SphereParam) => onUpdate(
                EditSource.FORM,
                {
                  ...param,
                  seed: {
                    typ: 'Sphere',
                    center: Vector.toPvProto(newParam.center),
                    radius: newParam.radius,
                  },
                },
              )}
              param={{
                center: Vector.toProto((param.seed as ParaviewRpc.SphereParam).center ?
                  (param.seed as ParaviewRpc.SphereParam).center : { x: 0, y: 0, z: 0 }),
                radius: (param.seed as ParaviewRpc.SphereParam).center ?
                  (param.seed as ParaviewRpc.SphereParam).radius : 10.0,
              }}
              readOnly={readOnly}
            />
          )}
          <Form.LabeledInput label="Max Length">
            <NumberInput
              asBlock
              disabled={readOnly}
              onCommit={(value) => setStreamlineLength(value)}
              size="small"
              value={(param as ParaviewRpc.StreamTracerParam).maxLength}
            />
          </Form.LabeledInput>
          <Form.LabeledInput label="Line Width">
            <NumberInput
              asBlock
              disabled={readOnly}
              endAdornment="pt"
              onCommit={(value) => setStreamlineWidth(value)}
              size="small"
              value={(param as ParaviewRpc.StreamTracerParam).width}
            />
          </Form.LabeledInput>
          <Form.LabeledInput label="Resolution">
            <NumberInput
              asBlock
              disabled={readOnly}
              onCommit={(value) => setStreamlineResolution(value)}
              size="small"
              value={(param as ParaviewRpc.StreamTracerParam).resolution}
            />
          </Form.LabeledInput>
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
