// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import { Level } from '../../../lib/notificationUtils';
import Collapsible from '../../transition/Collapsible';

import './AutoCollapsingMessage.scss';

/** AutoCollapsingMessage component
 *
 * AutoCollapsingMessage is a component that displays a simple message (or set
 * of messages), collapsing when the message is empty and expanding otherwise.
 *
 * A common problem with this kind of animated collapse/expand behavior is that
 * if an application sets the message to '' to induce collapsing, the message
 * itself disappears before the collapsing animation even starts, resulting in
 * a jumpy display.  This simple component solves that by maintaining a separate
 * display value and never setting that display value to '' after
 * initialization. */

export interface AutoCollapsingMessageProps {
  // A string to display--an empty string will collapse the display
  message: string;
  // Mirrors the tooltip designs in Figma:
  // https://www.figma.com/file/8rNGeZ9HiJ4Zl6pVaEk5Lq/Luminary-Component-Library?node-id=463%3A63588
  level?: Level;
  // True if the message should be center aligned
  centered?: boolean;
}

export const AutoCollapsingMessage = (props: AutoCollapsingMessageProps) => {
  const { message, level, centered } = props;

  const [collapsed, setCollapsed] = useState(true);
  const [display, setDisplay] = useState('');

  useEffect(() => {
    if (message) {
      setDisplay(message);
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [message]);

  return (
    <div className={cx('autoCollapsingMessage', level)}>
      <Collapsible collapsed={collapsed}>
        <div className={cx('message', centered && 'centered')}>{display}</div>
      </Collapsible>
    </div>
  );
};
