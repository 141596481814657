// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { useMeshReadyState } from '../../recoil/useMeshReadyState';
import { useProjectContext } from '../context/ProjectContext';

export const useGeometryStatus = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const geometryPending = useIsGeometryPending(projectId);
  const meshReady = useMeshReadyState(projectId, workflowId, jobId);

  const working = !meshReady || geometryPending;

  return {
    working,
  };
};
