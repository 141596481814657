// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { Vector3 } from '../../../proto/base/base_pb';
import { QuantityType } from '../../../proto/quantity/quantity_pb';
import LabeledInput from '../LabeledInput';
import { ValidVector3Input, ValidVector3InputProps, Vec3FaultInfo } from '../ValidatedInputs/ValidVector3Input';

interface LengthVectorInputProps extends ValidVector3InputProps {
  // The label for the input
  label?: string;
  // The help text for the label
  help?: string,
}

/**
 * A generic input component for a Vector3 field of length values.
 */
export const LengthVectorInput = (props: LengthVectorInputProps) => {
  const { label = 'Side Length', help, validate } = props;

  // A side length must be strictly greater than 0
  const validateLength = (value: Vector3): Vec3FaultInfo | undefined => {
    const error = { type: { x: undefined, y: undefined, z: undefined } } as Vec3FaultInfo;
    if (value.x <= 0) {
      error.type.x = 'error';
    }
    if (value.y <= 0) {
      error.type.y = 'error';
    }
    if (value.z <= 0) {
      error.type.z = 'error';
    }
    if (error.type.x || error.type.y || error.type.z) {
      error.message = 'Length must be greater than 0';
      return error;
    }
    return validate?.(value);
  };

  return (
    <LabeledInput help={help} label={label}>
      <ValidVector3Input
        {...props}
        quantityType={QuantityType.LENGTH}
        validate={validateLength}
      />
    </LabeledInput>
  );
};
