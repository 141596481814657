// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 43;
const baseHeight = 48;
export const LeftClickMouseIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="leftClickMouse"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M39.83,24.004
           C39.83,35.942 31.301,45 21.5,45
           C11.699,45 3.17,35.942 3.17,24.004
           C3.17,23.716 3.175,23.419 3.185,23.115
           L39.814,23.115
           C39.824,23.419 39.83,23.716 39.83,24.004Z
           M39.581,20.115
           C39.361,18.252 38.998,16.266 38.503,14.343
           C37.622,10.92 36.416,8.059 35.137,6.429
           C33.725,4.629 32.029,3.787 29.858,3.371
           C27.863,2.989 25.67,2.994 23,3.004
           L23,20.115
           L39.581,20.115Z
           M20,20.115
           L20,3.004
           C17.33,2.994 15.137,2.989 13.142,3.371
           C10.971,3.787 9.275,4.629 7.863,6.429
           C6.584,8.059 5.378,10.92 4.497,14.343
           C4.002,16.266 3.639,18.252 3.419,20.115
           L20,20.115Z
           M20.14,0.004
           C20.52,0.006 20.903,0.008 21.288,0.008
           L21.712,0.008
           C22.097,0.008 22.48,0.006 22.86,0.004
           C28.681,-0.023 33.869,-0.047 37.497,4.577
           C40.816,8.807 42.83,17.919 42.83,24.004
           C42.83,37.256 33.28,48 21.5,48
           C9.72,48 0.17,37.256 0.17,24.004
           C0.17,17.919 2.184,8.807 5.503,4.577
           C9.131,-0.047 14.319,-0.023 20.14,0.004Z
           M18.397,4.824
           L18.397,18.658
           L5.646,18.658
           C6.186,14.334 7.355,9.642 9.696,7.308
           C12.038,4.975 14.075,4.824 18.397,4.824Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
