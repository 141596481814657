// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect } from 'react';

import * as flags from '../../flags';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { IconName } from '../../lib/componentTypes/svgIcon';
import { extname, isLcSolnExtension } from '../../lib/path';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../recoil/lcvis/lcvisReadyState';
import { useLcvisSettingsState } from '../../recoil/lcvis/lcvisSettings';
import { useMeshUrlState } from '../../recoil/meshState';
import { useIsEnabled } from '../../recoil/useExperimentConfig';
import Dropdown from '../Dropdown';
import { ToolbarButton } from '../Toolbar/ToolbarButton';
import { useProjectContext } from '../context/ProjectContext';

import { useParaviewContext } from './ParaviewManager';

interface RepresentationItem {
  name?: string,
  type: ParaviewRpc.RepresentationType,
  iconName: IconName,
}

const allRepresentations: RepresentationItem[] = [
  { type: 'Surface With Edges', iconName: 'wireframeInverted' },
  { type: 'Surface', iconName: 'circle' },
  { type: 'Wireframe', iconName: 'wireframe' },
  { type: 'Points', iconName: 'ringOfCircles' },
];

const geomRepresentations: RepresentationItem[] = [
  { type: 'Surface', iconName: 'circle' },
];

const geomRepresentationsWithEdges: RepresentationItem[] = [
  { type: 'Surface', iconName: 'circle' },
  {
    name: 'Surface With Edges (Staff)',
    type: 'Surface With Edges',
    iconName: 'wireframeInverted',
  },
];

// Draw dropdown menu that selects "Surface"/"Wireframe"/etc.
const GeomRepresentationMenu = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const { setViewAttrs, viewState } = useParaviewContext();
  const [meshUrlState] = useMeshUrlState(projectId);
  const surfaceEdgesEnabled = useIsEnabled(flags.geomSurfaceWithEdges);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();

  const [lcvisSettings, setLcvisSettings] = useLcvisSettingsState({ projectId, workflowId, jobId });

  const hasViewAttrs = !!viewState?.attrs;

  // The currently selected representation type.
  const currentType = (
    lcvisEnabled ?
      lcvisSettings.displayProps.reprType :
      hasViewAttrs && viewState!.attrs!.reprType
  ) || 'Surface';

  const menuIcon = (
    allRepresentations.find((item) => item.type === currentType)?.iconName || 'circle'
  );

  const onRepresentationChanged = useCallback((reprType: ParaviewRpc.RepresentationType) => {
    if (lcvisEnabled) {
      setLcvisSettings((prev) => ({
        ...prev,
        displayProps: { ...prev.displayProps, reprType },
      }));
    } else {
      setViewAttrs({ reprType });
    }
  }, [setViewAttrs, lcvisEnabled, setLcvisSettings]);
  const disabled = !hasViewAttrs && !(lcvisEnabled && lcvisReady);

  // If we are postprocessing a solution file, then all representation types are
  // available. Else, the available representations are determined with the
  // meshUrlState.
  const isSolution = viewState &&
    isLcSolnExtension(extname((viewState.root.param as ParaviewRpc.ReaderParam).url));

  let representations = allRepresentations;

  if (!isSolution && meshUrlState.activeType === projectstatepb.UrlType.GEOMETRY) {
    representations = surfaceEdgesEnabled ? geomRepresentationsWithEdges : geomRepresentations;
  }

  if (lcvisEnabled) {
    // LCVis doesn't support a 'Points' representation because it is essentially unused by
    // customers in Paraview. See stats on LC-19974
    representations = allRepresentations.filter((item) => item.type !== 'Points');
  }

  useEffect(() => {
    if (!representations.some((item) => item.type === currentType)) {
      onRepresentationChanged(representations[0].type);
    }
  }, [representations, currentType, onRepresentationChanged]);

  const menuItems: CommonMenuItem[] = [
    ...representations.map((repr: RepresentationItem) => ({
      disabled,
      startIcon: { name: repr.iconName },
      label: repr.name || repr.type,
      onClick: () => onRepresentationChanged(repr.type),
      engaged: repr.type === currentType,
    })),
  ];

  if (lcvisEnabled) {
    menuItems.push({ separator: true });
    menuItems.push({
      label: 'Show Colors',
      disabled: lcvisSettings.displayProps.reprType === 'Wireframe',
      onClick: () => {
        setLcvisSettings((prev) => ({
          ...prev,
          displayProps: {
            ...prev.displayProps,
            showColors: !prev.displayProps.showColors,
          },
        }));
      },
      engaged: !!lcvisSettings.displayProps.showColors,
      toggleable: true,
      disabledReason: 'Colors are not available for Wireframe representation',
    });
  }

  return (
    <Dropdown
      disableCloseOnClick
      flushAlign
      key="geomRepresentationDropdown"
      menuItems={menuItems}
      position="below-right"
      toggle={(
        <ToolbarButton
          disabled={disabled}
          dropdown
          icon={{ name: menuIcon, maxHeight: 14 }}
          key="geomRepresentationButton"
          locator="toolbar-geom-representation"
          onClick={() => { }}
          title="Geometry Representation"
        />
      )}
    />
  );
};

export default GeomRepresentationMenu;
