// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { colors } from '../../../lib/designSystem';
import { createStyles, makeStyles } from '../../Theme';
import { useSelectionContext } from '../../context/SelectionManager';
import { PropertiesPanelPosition, usePropertiesPanelPosition } from '../../hooks/usePropertiesPanel';
import { ListBulletedIcon } from '../../svg/ListBulletedIcon';

const useStyles = makeStyles(
  () => createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '24px',
      height: '100%',
      overflow: 'hidden',

      '&.extraPadding': {
        padding: '8px 12px 24px',
      },
    },
    boldText: {
      display: 'flex',
      flexDirection: 'column',
      color: colors.neutral800,
      textAlign: 'center',
      padding: '0px 16px',
      gap: '8px',
      fontSize: '14px',
    },
  }),
  { name: 'EmptyPropPanel' },
);

export const EmptyPropPanel = () => {
  const classes = useStyles();
  const { selectedNodeIds } = useSelectionContext();
  const propertiesPanelPosition = usePropertiesPanelPosition();

  const extraPadding = propertiesPanelPosition === PropertiesPanelPosition.POPPED_OUT_RIGHT;
  const multipleSelected = selectedNodeIds.length > 1;
  const title = multipleSelected ? 'Multiple Selected' : 'No Properties';
  const subtitle = multipleSelected ?
    'Select an individual item in the tree to view its properties.' :
    'Select an item in the tree to view properties.';

  return (
    <div className={cx(classes.container, { extraPadding })}>
      <div>
        <ListBulletedIcon
          color={colors.neutral550}
          maxHeight={54}
          maxWidth={54}
        />
      </div>
      <div className={classes.boldText}>
        <div>
          <b>{title}</b>
        </div>
        <div style={{ fontSize: '13px' }}>
          {subtitle}
        </div>
      </div>
    </div>
  );
};
