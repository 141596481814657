// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { DateTime, Duration, DurationObject } from 'luxon';

/**
 * A shorter Luxon wrapper that returns the amount of milliseconds for a duration object.
 */
export function milliseconds(duration: DurationObject): number {
  return Duration.fromObject(duration).toMillis();
}

/**
 * Convert a unix timestamp (in seconds) to a locale-aware date/time string.
 */
export function formatDate(seconds: number): string {
  return DateTime
    .fromSeconds(seconds)
    .toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    });
}

/**
 * Convert a Date object to a date string.
 */
export function formatDay(date: Date): string {
  return date.toLocaleString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

/**
 * Convert a unix timestamp to a date string.
 */
export function formatDayUnix(seconds: number): string {
  return formatDay(new Date(seconds * 1000));
}

/**
 * Convert a unix timestamp to a time string.
 */
export function formatTime(seconds: number): string {
  return DateTime
    .fromSeconds(seconds)
    .toLocaleString({
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    });
}

/**
 * Convert a unix timestamp to a simple datetime, i.e. "Oct 14, 1983, 9:30 AM" depending on locales.
 */
export function toSimpleDateTime(seconds: number): string {
  return DateTime.fromSeconds(seconds).toLocaleString(DateTime.DATETIME_MED);
}

/**
 * Convert a unix timestamp to a simple time, i.e. 1:30 PM or 13:30, depending on locales.
 */
export function toSimpleTime(seconds: number): string {
  return DateTime.fromSeconds(seconds).toLocaleString(DateTime.TIME_SIMPLE);
}

const secondsPerMinute = 60;
const secondsPerHour = secondsPerMinute * 60;
const secondsPerDay = secondsPerHour * 24;

// Generate a human-readable duration string. The arg is in seconds.
export function formatDuration(duration: number): string {
  const seconds = Math.floor(duration);
  if (seconds < secondsPerDay) {
    return Duration.fromMillis(seconds * 1000).toFormat('hh:mm:ss');
  }
  const day = `${Math.floor(seconds / secondsPerDay)} day${seconds < secondsPerDay * 2 ? '' : 's'}`;
  const time = seconds % secondsPerDay;
  if (time === 0) {
    return day;
  }
  return `${day} ${Duration.fromMillis(time * 1000).toFormat('hh:mm:ss')}`;
}

// Generate a human-readable representation of a time a specified number of seconds in the past.
// Any duration less than a minute will return "just now"; otherwise, it will return something
// like "5m ago", "4h ago" or "2d ago" (maxing out at the unit of days).
export function formatDurationRelative(duration: number): string {
  const seconds = Math.floor(duration);
  if (seconds < secondsPerMinute) {
    return 'just now';
  } if (seconds < secondsPerHour) {
    const value = Math.floor(seconds / secondsPerMinute);
    return `${value}m ago`;
  } if (seconds < secondsPerDay) {
    const value = Math.floor(seconds / secondsPerHour);
    return `${value}h ago`;
  }
  const value = Math.floor(seconds / secondsPerDay);
  return `${value}d ago`;
}

// Format a date as a string representing how long before a reference date it is.
// If no reference date is provided, uses the current date as reference.
export function formatDateRelative(event: Date, reference?: Date): string {
  if (!reference) {
    reference = new Date();
  }
  // Get the number of seconds between event date and reference date.
  const duration = (reference.getTime() - event.getTime()) / 1000;
  return formatDurationRelative(duration);
}

function defaultTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
}

// Converts a Date object into "HH:MM AM/PM TZ" format using the local timezone. Defaults to using
// "UTC" if timezone data in not found.
export function formatDateToHHMM(date: Date, timeZone?: string): string {
  // Using DateTimeFormat object for better caching compared to toLocaleString.
  return date.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: timeZone || defaultTimeZone(),
    timeZoneName: 'short',
  });
}
