// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import { selectorFamily, useRecoilValue } from 'recoil';

import * as rpc from '../lib/rpc';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { RectilinearTable } from '../proto/table/table_pb';

type RecoilKey = {
  projectId: string
  tableUrl: string | null,
}

export const tableSelector = selectorFamily<RectilinearTable | null, RecoilKey>({
  key: 'tableSelector',
  get: (key: RecoilKey) => async () => {
    const { projectId, tableUrl } = key;
    if (!tableUrl) {
      return null;
    }
    const req = new frontendpb.GetRectilinearTableRequest({ projectId, url: tableUrl });
    const reply = await rpc.callRetry('GetRectilinearTable', rpc.client.getRectilinearTable, req);
    const { table } = reply;
    if (!table) {
      throw Error('No table in reply');
    }
    return table;
  },
  dangerouslyAllowMutability: true,
});

const useTableState = (projectId: string, tableUrl: string | null) => (
  useRecoilValue(tableSelector({ projectId, tableUrl }))
);

export default useTableState;
