// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useEffect } from 'react';

import { atom, useAtomValue, useSetAtom } from 'jotai';

import * as rpc from '../lib/rpc';
import { debounce } from '../lib/utils';
import * as frontendpb from '../proto/frontend/frontend_pb';

const remainingCredits = atom<number | null>(null);

export const CREDITS_POLLING_TIME_IN_SECONDS = 15;

/**
 * This hook returns the number of credits, which is regularly fetched using the useCreditsPoller.
 * The poller should be placed globally to avoid making multiple requests.
 */
export const useRemainingCredits = () => useAtomValue(remainingCredits);

/**
 * This hook is intended to be used globally within the app to periodically fetch
 * the user's credits from the API. It should only be initialized once, and only
 * when the user is authenticated.
 *
 * The poller will automatically start fetching data at regular intervals once
 * it's called. Make sure that this hook is attached to a top-level component
 * and ensure it's only executed when the user is authorized to prevent unnecessary
 * API requests.
 */
export const useCreditsPoller = (enabled = false) => {
  const setRemainingCredits = useSetAtom(remainingCredits);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateRemainingCredits = useCallback(debounce(() => {
    const reqCreditsRemaining = new frontendpb.CreditsRemainingRequest();
    const getCreditsRemaining =
      () => rpc.callRetry('CreditsRemaining', rpc.client.creditsRemaining, reqCreditsRemaining);

    getCreditsRemaining().then((value) => {
      setRemainingCredits(value?.creditsRemaining?.value ?? null);
    }).catch(() => {});
  }, 500), []);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    updateRemainingCredits();
    const intervalId = setInterval(updateRemainingCredits, CREDITS_POLLING_TIME_IN_SECONDS * 1_000);

    return () => {
      clearTimeout(intervalId);
    };
  }, [enabled, setRemainingCredits, updateRemainingCredits]);
};
