import React from 'react';

import { getPhysicsId, getPhysicsName } from '../../../lib/physicsUtils';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { CollapsibleNodePanel } from '../../Panel/CollapsibleNodePanel';
import { useProjectContext } from '../../context/ProjectContext';
import NodeLink from '../NodeLink';
import PropertiesSection from '../PropertiesSection';

import { FluidSolverSettings } from './physics/Fluid';
import { HeatSolverSettings } from './physics/Heat';

export const SolverSettingsPropPanel = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const simParam = useSimulationParam(projectId, workflowId, jobId);

  const allPhysics = simParam.physics;

  return (
    <>
      <PropertiesSection>
        {allPhysics.map((physics) => {
          const physicsId = getPhysicsId(physics);
          const physicsName = getPhysicsName(physics, simParam);
          return (
            <React.Fragment key={getPhysicsId(physics)}>
              <div style={{ padding: '8px 12px' }}>
                <CollapsibleNodePanel
                  heading={(
                    <NodeLink
                      nodeIds={[physicsId]}
                      text={physicsName}
                    />
                )}
                  nodeId={`solversettings-${physicsId}`}
                  panelName={physicsName}>
                  {physics.params.case === 'fluid' ? (
                    <FluidSolverSettings physicsId={physicsId} />
                  ) : (
                    <HeatSolverSettings physicsId={physicsId} />
                  )}
                </CollapsibleNodePanel>
              </div>
            </React.Fragment>
          );
        })}
      </PropertiesSection>
    </>
  );
};
