// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { ActionLink } from '../../components/Button/ActionLink';
import AuthForm, { DataValues } from '../../components/Form/AuthForm';
import { AuthPageLayout } from '../../components/layout/page/AuthPageLayout';
import { LoginWithMfaLocationState, MFA_TOKEN_EXPIRED_ERROR, getLoginTokenWithOtpMfa, isMfaTokenExpired, startOtpLogin } from '../../lib/AuthMFA';
import { handleAuthenticatedV2 } from '../../lib/AuthV2';
import { getUserErrorMessage } from '../../lib/errors';
import { routes } from '../../lib/navigation';
import { isStorybookEnv } from '../../lib/testing/utils';

const LoginApp2FA = () => {
  // This can be undefined only if we open the page directly. Otherwise, the useHandleMfaRequired
  // would have set the state when navigating to this page.
  const locationState = useLocation().state as LoginWithMfaLocationState | undefined;
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [fieldsError, setFieldsError] = useState<{ code?: string }>({});

  const handleSubmit = async (data: DataValues) => {
    setSubmitting(true);
    setFieldsError({});

    if (isMfaTokenExpired(locationState?.mfaTokenExpiry)) {
      navigate(routes.login, { state: { error: MFA_TOKEN_EXPIRED_ERROR } });
      return;
    }

    const optAuthenticator = locationState?.activeMfaList?.find(
      (mfaOption) => (mfaOption.authenticator_type === 'otp'),
    );
    const authenticatorId = optAuthenticator?.id || '';

    // Start the otp challenge
    try {
      await startOtpLogin(locationState?.mfaToken || '', authenticatorId);
    } catch (err) {
      navigate(routes.login, { state: { error: err.message } });
      return;
    }

    // Get the login token and pass it to the handleAuthenticatedV2. It should set the AuthState
    // to AuthState.AUTHENTICATED and the useEffect in the main index.tsx should redirect to the
    // projects page.
    try {
      const loginToken = await getLoginTokenWithOtpMfa(
        locationState?.mfaToken || '',
        data.code,
        '',
        true,
        // data.remember === 'true',
      );
      await handleAuthenticatedV2(loginToken);
    } catch (err) {
      setFieldsError({ code: getUserErrorMessage(err) });
      setSubmitting(false);
    }
  };

  // Automatically submit the form when 6 symbols are entered
  const handleChange = async (data: DataValues) => {
    if (data.code.length === 6) {
      await handleSubmit(data);
    }
  };

  // We should never really come in this component if not redirected with these params from the
  // useHandleMfaRequired, but add some protection just in case.
  useEffect(() => {
    if (isStorybookEnv()) {
      return;
    }

    if (!locationState?.mfaToken || !locationState.activeMfaList) {
      navigate(routes.login, { state: { error: 'Authentication error: no MFA state for login' } });
    }
  }, [locationState, navigate]);

  return (
    <AuthPageLayout
      back
      footer={(
        <>
          Having problems?&nbsp;
          <ActionLink onClick={() => {
            navigate(routes.loginBackup, { state: { mfaToken: locationState?.mfaToken } });
          }}>
            Try other options
          </ActionLink>.
        </>
      )}
      subtitle="This helps keep your account secure by verifying it’s you."
      title="Enter your one-time code from authenticator app">
      <AuthForm
        fields={[
          {
            asBlock: true,
            autofocus: true,
            label: '6-digit code',
            disabled: submitting,
            name: 'code',
            placeholder: '000000',
            required: true,
          },
          // The "remember" option stopped worked after the Auth0 Actions migration.
          // Hiding the checkbox until LC-19810 is resolved.
          // {
          //   label: 'Remember this device',
          //   disabled: submitting,
          //   name: 'remember',
          //   type: 'checkbox',
          // },
        ]}
        fieldsError={fieldsError}
        onChange={handleChange}
        onSubmit={handleSubmit}
        resetFieldsOnError={['code']}
        submit={{
          disabled: submitting,
          showSpinner: submitting,
          label: 'Continue',
        }}
      />
    </AuthPageLayout>
  );
};

export default LoginApp2FA;
