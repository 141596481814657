// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { Suspense } from 'react';

import { useLocation } from 'react-router-dom';

import { isInExploration } from '../../lib/navigation';
import { isSensitivityAnalysis } from '../../lib/workflowUtils';
import { useWorkflowState } from '../../recoil/workflowState';
import JobPanel from '../JobPanel/JobPanel';
import suspenseWidget from '../SuspenseWidget';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';

const useStyles = makeStyles(() => createStyles({
  root: {
    borderRadius: '4px',
  },
}), { name: 'ExplorationPanel' });

// This panel is shown when checking the results of a Design of Experiments. It lists the inputs
// that were used for the DoE with their values for the particular simulation run.
export const ExplorationPanel = () => {
  // Hooks
  const classes = useStyles();
  const location = useLocation();
  const { projectId, workflowId } = useProjectContext();
  const workflow = useWorkflowState(projectId, workflowId);

  // Other props
  const isExploration = isInExploration(location.pathname);
  const isSensitivity = isExploration && !!workflow && isSensitivityAnalysis(workflow);

  // Render
  if (workflow && isExploration && !isSensitivity) {
    return (
      <div className={classes.root}>
        <Suspense fallback={suspenseWidget}>
          <JobPanel isExploration={isExploration} />
        </Suspense>
      </div>
    );
  }

  return null;
};
