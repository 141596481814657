// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../../../../SimulationParamDescriptor';
import assert from '../../../../lib/assert';
import { useMultiCoupling } from '../../../../model/hooks/useMultiCoupling';
import { usePhysicsSet } from '../../../../model/hooks/usePhysicsSet';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { useSimulationParamScope } from '../../../../state/external/project/simulation/paramScope';
import LabeledInput from '../../../Form/LabeledInput';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { ParamForm } from '../../../ParamForm';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { ToggleSwitch } from '../../../controls/ToggleSwitch';
import PropertiesSection from '../../PropertiesSection';
import { EmptyPropPanel } from '../Empty';

export const PhysicsMultiPropPanel = () => {
  // Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'Node not present.');

  // Recoil
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // Hooks
  const {
    coupling,
    setCoupling,

    enhancedCouplingOn,
    setEnhancedCouplingOn,
  } = useMultiCoupling(projectId, workflowId, jobId, readOnly);
  const { isCht } = usePhysicsSet(projectId, workflowId, jobId, readOnly);
  const propClasses = useCommonTreePropsStyles();

  if (!isCht) {
    return <EmptyPropPanel />;
  }

  const enhancedDesc = paramDesc[ParamName.MpCouplingLinSolCoupling];

  return (
    <div className={propClasses.properties}>
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="CHT Coupling"
          nodeId={node.id}
          panelName="chtCoupling"
          panelOptions={{ defaultExpanded: true }}>
          <>
            <LabeledInput help={enhancedDesc.help} label={enhancedDesc.text}>
              <ToggleSwitch
                onChange={setEnhancedCouplingOn}
                value={enhancedCouplingOn}
              />
            </LabeledInput>
            <ParamForm<simulationpb.MultiPhysicsCouplingOptions>
              group={paramGroupDesc[ParamGroupName.MpCouplingOptions]}
              onUpdate={setCoupling}
              paramScope={paramScope}
              proto={coupling}
              readOnly={readOnly}
              removeParams={['MpCouplingLinSolCoupling']}
            />
          </>
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
