// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 21;
const baseHeight = 18;
export const ArrowLeftIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="arrowLeft"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M19.5,7.5
           L5.13,7.5
           L10.065,2.565
           C10.335,2.295 10.5,1.92 10.5,1.5
           C10.5,0.675 9.825,0 9,0
           C8.58,0 8.205,0.165 7.935,0.435
           L0.435,7.935
           C0.165,8.22 0,8.595 0,9
           C0,9.405 0.165,9.795 0.435,10.065
           L7.935,17.565
           C8.205,17.835 8.58,18 9,18
           C9.825,18 10.5,17.325 10.5,16.5
           C10.5,16.08 10.335,15.705 10.065,15.435
           L5.13,10.5
           L19.515,10.5
           C20.34,10.5 21.015,9.825 21.015,9
           C21.015,8.175 20.325,7.5 19.5,7.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
