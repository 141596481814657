// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Empty } from '@bufbuild/protobuf';
import { atomFamily, selectorFamily, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import {
  JobNameKey,
  JobNameMap,
  NewJobNameMap,
} from '../lib/jobNameMap';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import * as rpc from '../lib/rpc';
import { isStorybookEnv } from '../lib/testing/utils';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';

import { WorkflowMap, useWorkflowMap, workflowIdsSelector, workflowMapSelector } from './workflowState';

// This might seem confusing now, but eventually, we will just have one key.
const jobNameMapProtoKey = 'jobNameMap'; // for `jobNameMapProtoState` (to be removed)
const newJobNameMapKey = 'newJobNameMap'; // for `newJobNameMapSelector`
const jobNameMapKey = 'jobNameMapSelector'; // for `jobNameMapSelector` (temporary)

const DEFAULT_LEGACY_MAP = new projectstatepb.JobNameMap(); // empty JobNameMap proto
const DEFAULT_MAP: JobNameMap = new NewJobNameMap(new Map(), () => { }); // empty NewJobNameMap

// Serializes the JobNameMap proto.
// Used by syncProjectStateEffect in jobNameMapProtoState. To be removed.
function serialize(val: projectstatepb.JobNameMap): Uint8Array {
  return val.toBinary();
}

// Deserializes the JobNameMap proto.
// Used by syncProjectStateEffect in jobNameMapProtoState. To be removed.
function deserialize(val: Uint8Array): projectstatepb.JobNameMap {
  return (val.length ?
    projectstatepb.JobNameMap.fromBinary(val) :
    DEFAULT_LEGACY_MAP);
}

// Creates a a Map relating workflow and job IDs to names from a WorkflowMap.
function mapFromWorkflowMap(workflowMap: WorkflowMap): Map<string, string> {
  const nameMap = new Map();
  Object.entries(workflowMap).forEach(([workflowId, workflow]) => {
    if (workflow !== undefined) {
      nameMap.set(workflowId, workflow.name);
      Object.entries(workflow.job).forEach(([jobId, job]) => {
        nameMap.set(jobId, job.name);
      });
    }
  });
  return nameMap;
}

// Sets the name for a given workflow by its ID. Makes a frontend RPC call.
function renameWorkflow(projectId: string, workflowId: string, newName: string): Promise<Empty> {
  const req = new frontendpb.RenameWorkflowRequest({ projectId, workflowId, name: newName });
  return rpc.client.renameWorkflow(req);
}

// Sets the name for a given job by its ID. Makes a frontend RPC call.
function renameJob(projectId: string, jobId: string, newName: string): Promise<Empty> {
  const req = new frontendpb.RenameJobRequest({ projectId, jobId, name: newName });
  return rpc.client.renameJob(req);
}

// Gets the new implementation of JobNameMap, which gets job/workflow names from frontend RPC calls
// (that are not SessionState).
const newJobNameMapSelector = selectorFamily<JobNameMap, string>({
  key: newJobNameMapKey,
  get: (projectId: string) => ({ get, getCallback }) => {
    if (isStorybookEnv()) {
      return DEFAULT_MAP;
    }
    const workflowIds = get(workflowIdsSelector(projectId));
    const workflowMap = get(workflowMapSelector({ projectId, workflowIds }));
    const initMap = mapFromWorkflowMap(workflowMap);
    const renameCallback = getCallback(() => (key: JobNameKey, newName: string) => {
      const { jobId, workflowId } = key;
      return (jobId ?
        renameJob(projectId, jobId, newName) :
        renameWorkflow(projectId, workflowId, newName)
      );
    });
    return new NewJobNameMap(initMap, renameCallback);
  },
});

// Represents the JobNameMap proto stored in the SessionState for the given project.
export const jobNameMapProtoState = atomFamily<projectstatepb.JobNameMap, string>({
  key: jobNameMapProtoKey,
  default: (projectId: string) => (
    !isStorybookEnv() ?
      persist.getProjectState(projectId, [jobNameMapProtoKey], deserialize) :
      new projectstatepb.JobNameMap()
  ),
  effects: (projectId: string) => [
    !isStorybookEnv() ?
      syncProjectStateEffect(projectId, jobNameMapProtoKey, deserialize, serialize) :
      () => { },
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

// Gets the JobNameMap for the given project.
// Currently, this JobNameMap writes to both the feAPI and the SessionState. It reads from the feAPI
// if the `jobnamemap` experiment flag is set. Otherwise, it reads from SessionState.
export const jobNameMapSelector = selectorFamily<JobNameMap, string>({
  key: jobNameMapKey,
  get: (projectId: string) => ({ get, getCallback }) => get(newJobNameMapSelector(projectId)),
  // legacyJobNameMap modifies itself. this will not be necessary once it is removed.
  dangerouslyAllowMutability: true,
});

// Wrapper hook.
export function useJobNameMap(projectId: string): JobNameMap {
  const workflowIds = useRecoilValue(workflowIdsSelector(projectId));
  useWorkflowMap(projectId, workflowIds); // start listening to workflow states.
  return useRecoilValue(jobNameMapSelector(projectId));
}

// Deprecated: this is not using proper async state (see src/recoil/README.md).
// However, for unclear reasons using async state in the TabPanel breaks the entire
// ProjectPage.  Therefore, I am leaving this legacy version for that use-case only.
// Returns null before JobNameMap is ready.
export function useJobNameMapLoadable(projectId: string): JobNameMap | null {
  const workflowIds = useRecoilValue(workflowIdsSelector(projectId));
  useWorkflowMap(projectId, workflowIds); // start listening to workflow states.
  const info = useRecoilValueLoadable(jobNameMapSelector(projectId));
  if (info.state !== 'hasValue') {
    return null;
  }
  return info.contents;
}
