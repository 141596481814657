// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVManipulationMode, LCVObject, LCVType } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';

import { LcvWidget } from './LcvWidget';

export interface LcvCylinderWidgetState {
  start: [number, number, number],
  end: [number, number, number],
  radius: number,
}

export class LcvCylinderWidget extends LcvWidget {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, sessionHandle, 'cylinder');
  }

  setState(newState: Partial<LcvCylinderWidgetState>) {
    if (newState.start) {
      this.setParam(
        'start',
        LCVType.kLCVDataTypeFloat3,
        newState.start,
      );
    }
    if (newState.end) {
      this.setParam(
        'end',
        LCVType.kLCVDataTypeFloat3,
        newState.end,
      );
    }
    if (newState.radius) {
      this.setParam(
        'radius',
        LCVType.kLCVDataTypeFloat,
        newState.radius,
      );
    }
  }

  getState(): LcvCylinderWidgetState {
    return {
      start: this.getProperty('start', LCVType.kLCVDataTypeFloat3),
      end: this.getProperty('end', LCVType.kLCVDataTypeFloat3),
      radius: this.getProperty('radius', LCVType.kLCVDataTypeFloat),
    };
  }

  showControls() {
    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      LCVManipulationMode.kLCVManipulationModeAll,
    );
  }

  hideControls(): void {
    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      LCVManipulationMode.kLCVManipulationModeNone,
    );
  }

  /**
   * Set the callback to be invoked whenever the box widget state changes.
   * @param callback a callback to be invoked whenever the box widget state changes.
   */
  setOnUpdateCallback(
    callback: ((newState: LcvCylinderWidgetState, message?: string) => void) | null,
  ) {
    if (!callback) {
      this.setParam('updated_callback', LCVType.kLCVDataTypeFunction, null);
      return;
    }
    const internalCallback = (
      _lcv: LcvModule,
      _session: LCVObject,
      _obj: LCVObject,
      message: string,
    ) => {
      const newState = this.getState();
      callback(newState, message);
    };
    this.setParam('updated_callback', LCVType.kLCVDataTypeFunction, internalCallback);
  }
}
