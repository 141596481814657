// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 20;
export const GridUnevenIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="gridUneven"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M2.571,0
           C1.161,0 0,1.225 0,2.712
           L0,17.289
           C0,18.775 1.161,20 2.571,20
           L21.429,20
           C22.839,20 24,18.775 24,17.289
           L24,2.712
           C24,1.225 22.839,0 21.429,0
           L2.571,0Z
           M2.571,1.808
           L6.857,1.808
           L6.857,9.1
           L1.714,9.1
           L1.714,2.712
           C1.714,2.203 2.089,1.808 2.571,1.808Z
           M8.571,1.808
           L15.429,1.808
           L15.429,9.1
           L8.571,9.1
           L8.571,1.808Z
           M17.143,1.808
           L21.429,1.808
           C21.911,1.808 22.286,2.203 22.286,2.712
           L22.286,9.1
           L17.143,9.1
           L17.143,1.808Z
           M1.714,10.908
           L6.857,10.908
           L6.857,18.192
           L2.571,18.192
           C2.089,18.192 1.714,17.797 1.714,17.289
           L1.714,10.908Z
           M8.571,10.908
           L15.429,10.908
           L15.429,18.192
           L8.571,18.192
           L8.571,10.908Z
           M17.143,10.908
           L22.286,10.908
           L22.286,17.289
           C22.286,17.797 21.911,18.192 21.429,18.192
           L17.143,18.192
           L17.143,10.908Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
