// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/output/reference_values.proto (package luminary.proto.output, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { AdFloatType } from "../base/base_pb.js";

/**
 * @generated from enum luminary.proto.output.ReferenceValueType
 */
export const ReferenceValueType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.output.ReferenceValueType",
  [
    {no: 0, name: "REFERENCE_VALUES_INVALID"},
    {no: 1, name: "REFERENCE_PRESCRIBE_VALUES"},
    {no: 2, name: "REFERENCE_FARFIELD_VALUES"},
  ],
);

/**
 * Define reference values needed for computing forces, moments and other non-dimensional output quantities.
 *
 * @generated from message luminary.proto.output.ReferenceValues
 */
export const ReferenceValues = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.output.ReferenceValues",
  () => [
    { no: 1, name: "reference_value_type", kind: "enum", T: proto3.getEnumType(ReferenceValueType) },
    { no: 2, name: "area_ref", kind: "message", T: AdFloatType },
    { no: 3, name: "length_ref", kind: "message", T: AdFloatType },
    { no: 7, name: "use_aero_moment_ref_lengths", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "length_ref_pitch", kind: "message", T: AdFloatType },
    { no: 9, name: "length_ref_roll", kind: "message", T: AdFloatType },
    { no: 10, name: "length_ref_yaw", kind: "message", T: AdFloatType },
    { no: 4, name: "p_ref", kind: "message", T: AdFloatType },
    { no: 5, name: "t_ref", kind: "message", T: AdFloatType },
    { no: 6, name: "v_ref", kind: "message", T: AdFloatType },
  ],
);

