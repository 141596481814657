// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { Message } from '@bufbuild/protobuf';

import * as ProtoDescriptor from '../ProtoDescriptor';
import { paramDesc, paramGroupDesc } from '../SimulationParamDescriptor';

import assert from './assert';
import {
  ChoicesMap,
  NestLevelsMap,
  ParamDescriptor,
  ParamGroupDescriptor,
  ParamNestLevelsMap,
  TagSet,
  ValuesMap,
  initializeData,
  protoToObject,
} from './paramScopeUtils';

// Defines a scope for parameters to determine which parameters and choices are enabled based
// on the currently selected choices. Scopes can be nested by passing a parent scope. Choices in all
// ancestor scopes are then included in the evaluation.
export class ParamScope {
  // Map containing the choices and their condition info of all multiple choice parameters of
  // this proto message and its ancestors. The key is the enum number of the choice.
  private choices: ChoicesMap;
  // Map containing the nesting level for each parameter. Key is the parameter name (snake case).
  private nestLevels: NestLevelsMap;
  // Values for parameters
  private values: ValuesMap;
  private paramGroupDescriptor: ParamGroupDescriptor;
  private tags: TagSet | undefined;

  constructor(
    paramAsObject: object,
    private experimentConfig: string[],
    private paramDescriptor: ParamDescriptor,
    paramGroupDescriptor?: ParamGroupDescriptor,
    private parentScope?: ParamScope,
    tags?: string[],
  ) {
    // If a paramGroupDescriptor is provided, use it, otherwise just use the standard one (and don't
    // rebuild the exclude set in that case).
    this.paramGroupDescriptor = paramGroupDescriptor ?? paramGroupDesc;

    // Initialize nestLevels, choices, and values Maps and tags Set
    this.nestLevels = new Map();
    this.choices = new Map();
    this.values = new Map();
    this.tags = tags && new Set(tags);
    initializeData(
      paramAsObject,
      paramDescriptor,
      this.paramGroupDescriptor,
      this.nestLevels,
      this.choices,
      this.values,
      this.tags,
    );

    // Update the choice map to include all choices of the parent scope
    this.choices = new Map(
      [...this.unrollParents()].flatMap((helper) => Array.from(helper.choices)),
    );
    // Update the values map to include all values of the parent scope
    this.values = new Map(
      [...this.unrollParents()].flatMap((helper) => Array.from(helper.values)),
    );
    // Update tags to include values of parent scope
    const parentTags = this.unrollParents().filter((parent) => !!parent.tags).flatMap(
      (parent) => Array.from(parent.tags!),
    );
    if (parentTags.length && this.tags) {
      this.tags = new Set(parentTags);
    }
    // Update the nest levels of each parameter
    this.nestLevels.forEach((_, paramName, map) => {
      const param = this.paramDescriptor[paramName];
      // Find the parent param based on the conditions that control the visibility of
      // this param, to then compute the nesting level as "parent + 1".
      // For complex conditions, i.e. based on a list of simpler conditions, we find
      // the first param listed by the programmer (when writing the condition), as a
      // way to disambiguate between the multiple params this one depends on.
      // This is done by recursively taking the first condition in lists of logical
      // conditions. Since the logical operators we have are commutative, this
      // strategy allows the programmer to control the desired parent.
      if (param.cond) {
        let cond0 = param.cond;
        while (cond0.type === ProtoDescriptor.CondType.ALL ||
          cond0.type === ProtoDescriptor.CondType.ANY) {
          cond0 = cond0.list[0];
        }
        if (cond0.type === ProtoDescriptor.CondType.CHOICE) {
          const parent = this.paramDescriptor[cond0.param];
          const parentNestLevel = map.get(cond0.param);
          // If the parent belongs to same group, then show the parameter nested underneath the
          // parent.
          if (parent.parentGroups[0] === param.parentGroups[0]) {
            map.set(paramName, parentNestLevel! + 1);
          }
        }
      }
    });
  }

  // Recursively evaluates a condition and returns its value. If cond is undefined it returns true.
  public isEnabled(cond: ProtoDescriptor.Cond | undefined): boolean {
    if (!cond) {
      return true;
    }
    switch (cond.type) {
      case ProtoDescriptor.CondType.ALL:
        return cond.list.length ? cond.list.every((childCond) => this.isEnabled(childCond)) : true;
      case ProtoDescriptor.CondType.ANY:
        return cond.list.length ? cond.list.some((childCond) => this.isEnabled(childCond)) : true;
      case ProtoDescriptor.CondType.TRUEFALSE:
        return this.isEnabled(this.paramDescriptor[cond.param].cond) &&
          !!this.values.get(cond.param);
      case ProtoDescriptor.CondType.CHOICE: {
        const conditions = this.choices.get(cond.choice);
        // If the choice is not in the list of all selected choices it is false
        if (conditions === undefined) {
          return false;
        }
        // A choice is true if its own condition is true AND the condition of the multiple
        // choice parameter that this choice is part of is true.
        const choiceEnabled = conditions.choiceCond ?
          this.isEnabled(conditions.choiceCond) : true;
        const dependencyEnabled = conditions.paramCond ?
          this.isEnabled(conditions.paramCond) : true;
        return choiceEnabled && dependencyEnabled;
      }
      case ProtoDescriptor.CondType.FALSE:
        return false;
      case ProtoDescriptor.CondType.NOT:
        return !this.isEnabled(cond.cond);
      case ProtoDescriptor.CondType.EXPERIMENT:
        return this.experimentConfig.includes(cond.name);
      case ProtoDescriptor.CondType.TAG:
        // Tag conditions always evaluate to true if this.tags is not defined.
        return this.tags?.has(cond.tagName) ?? true;
      default:
        return true;
    }
  }

  // Returns a list of parameters that are enabled based on the current set of choices.
  public enabledParams(paramGroup: ProtoDescriptor.ParamGroup, recursive: boolean) {
    const params: ProtoDescriptor.Param[] = [];
    const enabledParamsRec = (group: ProtoDescriptor.ParamGroup) => {
      group.params.forEach((param) => {
        if (this.isEnabled(param.cond)) {
          params.push(param);
        }
      });
      if (recursive) {
        group.paramGroups.forEach((groupName) => {
          const groupDesc = this.paramGroupDescriptor[groupName];
          if (!groupDesc.isMap && !groupDesc.isRepeated) {
            enabledParamsRec(groupDesc);
          }
        });
      }
    };
    enabledParamsRec(paramGroup);
    return params;
  }

  // Returns a list of choices that are enabled based on the current set of choices.
  public enabledChoices(param: ProtoDescriptor.Param) {
    const choices: ProtoDescriptor.Choice[] = [];
    if (param.type === ProtoDescriptor.ParamType.MULTIPLE_CHOICE) {
      param.choices.forEach((choice) => {
        if (this.isEnabled(choice.cond) && this.isEnabled(param.cond)) {
          choices.push(choice);
        }
      });
    }
    return choices;
  }

  // Returns a list of choices of a param group that are enabled based
  // on the current set of choices.
  public enabledChoicesInGroup(paramGroup: ProtoDescriptor.ParamGroup) {
    const choices: ProtoDescriptor.Choice[] = [];
    paramGroup.params.forEach((param) => choices.push(
      ...this.enabledChoices(param),
    ));
    return choices;
  }

  // Returns an array containing the calling instance of ParamScope and all of its ancestors
  private unrollParents(): ParamScope[] {
    if (this.parentScope) {
      return [...this.parentScope.unrollParents(), this];
    }
    return [this];
  }

  // Returns the nesting level of a parameter
  public nestLevel(param: ProtoDescriptor.Param) {
    return this.nestLevels.get(param.name);
  }

  public value(param: ProtoDescriptor.Param): ProtoDescriptor.Vector3 | number | string {
    const value = this.values.get(param.name);
    if (value === undefined) {
      throw Error(`Param not found: ${param.name}`);
    }
    switch (param.type) {
      case ProtoDescriptor.ParamType.BOOL:
      case ProtoDescriptor.ParamType.INT:
      case ProtoDescriptor.ParamType.REAL:
        return value.value as number;
      case ProtoDescriptor.ParamType.STRING:
        return value as string;
      case ProtoDescriptor.ParamType.MULTIPLE_CHOICE:
        return value as number;
      case ProtoDescriptor.ParamType.VECTOR3:
        return {
          x: value.x?.value ?? 0,
          y: value.y?.value ?? 0,
          z: value.z?.value ?? 0,
        } as ProtoDescriptor.Vector3;
      default:
        throw Error('Undefined param type');
    }
  }

  // Returns a map that contains the nesting level for each parameter in a group.
  public nestLevelsInGroup(paramGroup: ProtoDescriptor.ParamGroup): ParamNestLevelsMap {
    const nestLevels: ParamNestLevelsMap = new Map();
    paramGroup.params.forEach((param) => nestLevels.set(param, this.nestLevels.get(param.name)!));
    return nestLevels;
  }

  // Modifies values of muliple choice parameters so that all selections are valid (i.e. all
  // conditions are true).
  public changeInvalidChoices(): ProtoDescriptor.Param[] {
    const MAX_ITERATIONS = 1000;
    let i = 0;
    const changedParams = new Set<ProtoDescriptor.Param>();
    // A parameter can possibly be processed before its dependencies so we have to repeat changing
    // invalid choices until there are no changes in any parameters anymore.
    while (i < MAX_ITERATIONS) {
      // Loop over all params and check whether the choices of multiple choice params are enabled.
      // If not, change it to the first enabled choice.
      const changed = Array.from(this.values).some(([paramName, value]) => {
        const desc = this.paramDescriptor[paramName];
        if (desc.type === ProtoDescriptor.ParamType.MULTIPLE_CHOICE && this.isEnabled(desc.cond)) {
          const choices = (desc as ProtoDescriptor.MultipleChoiceParam).choices;
          const choice = choices.find(({ enumNumber }) => enumNumber === value as number);
          // If a choice cannot be found (usually that happens when a parameter has proto default
          // values) or if it is enabled we don't do anything and just return false so that the loop
          // over the parameters continues.
          if (!choice || this.isEnabled(choice.cond)) {
            return false;
          }
          // Find a choice in the list of possible values that is enabled.
          const foundValidChoice = choices.some((newChoice) => {
            // If a valid choice has been found we set it in the values and choices map and
            // also remove the old choice.
            if (this.isEnabled(newChoice.cond)) {
              this.values.set(paramName, newChoice.enumNumber);
              this.choices.delete(choice.enumNumber);
              this.choices.set(
                newChoice.enumNumber,
                { choiceCond: newChoice.cond ?? null, paramCond: desc.cond ?? null },
              );
              // Add it to the set of changed parameters that are going to be
              // returned from this function
              changedParams.add(desc);
              return true;
            }
            return false;
          });
          // This should not happen but emit a warning if it does.
          if (!foundValidChoice) {
            console.warn('No valid choice found for param', paramName);
            this.choices.delete(choice.enumNumber);
          }
          return true;
        }
        return false;
      });
      // If there are no changes anymore break the loop.
      if (!changed) {
        break;
      }
      i += 1;
    }
    assert(i < MAX_ITERATIONS, 'Max iterations exceeded in ParamScope');
    return Array.from(changedParams);
  }
}

// Helper to create a ParamScope in the product
export function createParamScope(
  paramProto: Message,
  experimentConfig: string[],
  parentScope?: ParamScope,
) {
  return new ParamScope(
    protoToObject(paramProto),
    experimentConfig,
    paramDesc,
    undefined,
    parentScope,
    [],
  );
}

// Helper to create a ParamScope from a list of potential protos by continuously adding child scopes
export function chainParamScopes(
  paramProtos: (Message | undefined)[],
  experimentConfig: string[],
  parentScope: ParamScope,
) {
  return paramProtos.reduce((result, paramProto) => {
    if (paramProto) {
      return createParamScope(paramProto, experimentConfig, result);
    }
    return result;
  }, parentScope);
}
