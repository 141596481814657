// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const BallAsteriskIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="ballAsterisk"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M21,14.25
           C20.16,14.25 19.41,14.595 18.87,15.135
           L13.485,12
           L18.87,8.865
           C19.41,9.405 20.16,9.75 21,9.75
           C22.65,9.75 24,8.4 24,6.75
           C24,5.1 22.65,3.75 21,3.75
           C19.35,3.75 18,5.1 18,6.75
           C18,7.035 18.045,7.305 18.12,7.56
           L12.75,10.695
           L12.75,5.895
           C14.04,5.565 15,4.395 15,3
           C15,1.35 13.65,0 12,0
           C10.35,0 9,1.35 9,3
           C9,4.395 9.96,5.565 11.25,5.895
           L11.25,10.695
           L5.88,7.56
           C5.955,7.305 6,7.035 6,6.75
           C6,5.1 4.65,3.75 3,3.75
           C1.35,3.75 0,5.1 0,6.75
           C0,8.4 1.35,9.75 3,9.75
           C3.84,9.75 4.59,9.405 5.13,8.865
           L10.515,12
           L5.13,15.135
           C4.59,14.595 3.84,14.25 3,14.25
           C1.35,14.25 0,15.6 0,17.25
           C0,18.9 1.35,20.25 3,20.25
           C4.65,20.25 6,18.9 6,17.25
           C6,16.965 5.955,16.695 5.88,16.44
           L11.25,13.305
           L11.25,18.105
           C9.96,18.435 9,19.605 9,21
           C9,22.65 10.35,24 12,24
           C13.65,24 15,22.65 15,21
           C15,19.605 14.04,18.435 12.75,18.105
           L12.75,13.305
           L18.12,16.44
           C18.045,16.695 18,16.965 18,17.25
           C18,18.9 19.35,20.25 21,20.25
           C22.65,20.25 24,18.9 24,17.25
           C24,15.6 22.65,14.25 21,14.25Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
