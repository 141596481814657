// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, forwardRef } from 'react';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';
import { ProgressBar } from '../visual/ProgressBar';

import { CollapsiblePanel, CollapsiblePanelProps } from './CollapsiblePanel';

const useStyles = makeStyles(
  () => createStyles({
    panel: {
      position: 'relative',
      background: colors.surfaceDark1,
      borderRadius: '4px',
      fontSize: '13px',
      overflow: 'hidden',
    },
    summary: {
      color: colors.lowEmphasisText,
      display: 'flex',
      flexDirection: 'column',
      padding: '0 12px 16px 12px',
      gap: '12px',
    },
    body: {
      background: colors.surfaceDark2,
      borderRadius: '0 0 4px 4px',
      overflow: 'hidden',
    },
    progress: {
      position: 'absolute',
      bottom: '0px',
      width: '100%',
    },
  }),
  { name: 'SummaryPanel' },
);

export interface SummaryPanelProps extends CollapsiblePanelProps {
  summary?: ReactElement;
  // an additional progress bar that is shown when the panel is collapsed where `progress`
  // is the amount of progress that should be shown [0, 1]. This progress is displayed when
  // the panel is collapsed
  // if null, an indeterminate progress bar is displayed when the panel is collapsed
  // if undefined, no progress bar is displayed when the panel is collapsed
  progress?: number | null;
}

/**
 * The Summary Panel is a collapsible panel with two parts: a summary section and a body section.
 */
export const SummaryPanel = forwardRef<HTMLDivElement, SummaryPanelProps>((props, ref) => {
  const classes = useStyles();

  return (
    <div className={classes.panel} ref={ref}>
      <CollapsiblePanel {...props} primaryHeading>
        {props.summary && <div className={classes.summary}>{props.summary}</div>}
        <div className={classes.body}>{props.children}</div>
      </CollapsiblePanel>
      {props.collapsed && props.progress !== undefined && (
        <div className={classes.progress}>
          <ProgressBar
            backgroundColor={colors.surfaceLight2}
            height={4}
            progress={props.progress}
          />
        </div>
      )}
    </div>
  );
});
