// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import cx from 'classnames';

import { ActionButton } from '../../components/Button/ActionButton';
import { ActionLink } from '../../components/Button/ActionLink';
import { createStyles, makeStyles } from '../../components/Theme';
import Tooltip from '../../components/Tooltip';
import { Table } from '../../components/data/Table';
import { MainPageLayout, useMainCommonStyles } from '../../components/layout/page/Main';
import { DiskInfoIcon } from '../../components/svg/DiskInfoIcon';
import { ColumnConfig, RowConfig } from '../../lib/componentTypes/table';
import { colors } from '../../lib/designSystem';
import { INTERCOM_LAUNCHER_SELECTOR } from '../../lib/intercom';
import { fromBigInt } from '../../lib/number';
import { Logger } from '../../lib/observability/logs';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import useAccountInfo, { useIsStarterPlan, useUserRemainingCredits } from '../../recoil/useAccountInfo';

const LEARN_MORE_ABOUT_PLANS_URL = 'https://www.luminarycloud.com/pricing';

const logger = new Logger('AccountPage/BillingPageBody');

const formatDate = (ts: number) => (
  new Date(ts * 1000).toLocaleDateString(
    'en-US',
    { year: 'numeric', day: 'numeric', month: 'long' },
  ));

const formatCents = (value: number) => (
  (value / 100).toLocaleString('en-US', { currency: 'USD', style: 'currency' })
);

function getStatus(invoice: frontendpb.AccountInfoReply_Invoice): string {
  if (invoice.totalCharges === BigInt(0)) {
    return 'No Usage';
  }
  if (invoice.status === 'paid') {
    return 'Paid';
  }
  if (invoice.status !== 'open') {
    logger.error(`Invoice ${invoice.id}: unknown status ${invoice.status}`);
    return invoice.status;
  }
  if (new Date(fromBigInt(invoice.dueDate) * 1000) < new Date()) {
    return 'OVERDUE';
  }
  return 'DUE';
}

function getStatusColor(status: string): string {
  switch (status) {
    case 'No Usage':
      return colors.neutral500;
    case 'Paid':
      return colors.greenYellow600;
    case 'OVERDUE':
      return colors.red500;
    case 'DUE':
      return colors.yellow500;
    default:
      return colors.purple400;
  }
}

const billingTableColumnConfigs: ColumnConfig[] = [
  { id: 'invoiceDate', label: 'Invoice Date', type: 'number', format: 'date' },
  { id: 'status', label: 'Status', type: 'string' },
  { id: 'openingBalance', label: 'Opening Balance', type: 'number', format: 'currency' },
  { id: 'totalConsumed', label: 'Total Consumed', type: 'number', format: 'currency' },
  { id: 'endingBalance', label: 'Ending Balance', type: 'number', format: 'currency' },
  { id: 'amountDue', label: 'Amount Due', type: 'number', format: 'currency' },
];

const useStyles = makeStyles(() => createStyles({
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  iconWrapper: {
    display: 'flex',
  },
  actionWithHint: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  hint: {
    fontWeight: 'normal',
  },
}), { name: 'BillingPageBody' });

const BillingPageStarterPlan = () => {
  const commonClasses = useMainCommonStyles();
  const classes = useStyles();
  const remainingCredits = useUserRemainingCredits();

  return (
    <div>
      <div className={cx(commonClasses.summaryContainer, 'autoHeight')}>
        <div className={cx(commonClasses.summary, 'fullWidth', 'autoHeight')}>
          <div className={classes.cardContainer}>
            <div>
              <span>Current Plan</span>
              <div className={cx(commonClasses.summaryMain, 'noMargin')}>
                Starter
              </div>
            </div>

            <div className={classes.rightSection}>
              <ActionLink href={LEARN_MORE_ABOUT_PLANS_URL} variant="secondary">
                Learn about Plans
              </ActionLink>
              <ActionButton disabled title="Coming soon">Upgrade</ActionButton>
            </div>
          </div>
        </div>
      </div>

      <div className={cx(commonClasses.summaryContainer, 'smallerGap')}>
        <div className={cx(commonClasses.summary, 'fullWidth')}>
          <span>Billing Model</span>
          <div className={cx(commonClasses.summaryMain, classes.iconContainer)}>
            Prepaid
            <Tooltip title="Buy credits as you need them">
              <div className={classes.iconWrapper}>
                <DiskInfoIcon color="#dadada" maxWidth={16} />
              </div>
            </Tooltip>
          </div>
          <span>
            <ActionButton disabled title="On-demand (monthly billing) coming soon">
              Change
            </ActionButton>
          </span>
        </div>

        <div className={cx(commonClasses.summary, 'fullWidth')}>
          <span>Credits remaining</span>
          <div className={commonClasses.summaryMain}>
            {Math.max(0, remainingCredits ?? 0)}
          </div>
          <span className={classes.actionWithHint}>
            <span className={INTERCOM_LAUNCHER_SELECTOR.replace('.', '')}>
              <ActionButton>
                Add Credits via Chat
              </ActionButton>
            </span>
            <div className={classes.hint}>
              Message us to manually add credits
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

const BillingPageNonStarterPlan = () => {
  const accountInfo = useAccountInfo();
  const commonClasses = useMainCommonStyles();

  const latestInvoice = useMemo(() => (
    accountInfo?.billing?.invoice[0]
  ), [accountInfo]);

  const balance = useMemo(() => (
    fromBigInt(accountInfo?.billing?.balance || BigInt(0))
  ), [accountInfo]);

  const billingTableRowData: RowConfig[] = accountInfo?.billing?.invoice?.map(
    (invoice) => {
      const invoiceStatusDisplay = getStatus(invoice);
      return {
        id: invoice.id,
        values: {
          invoiceDate: fromBigInt(invoice.date) * 1000,
          status: invoiceStatusDisplay,
          openingBalance: -1 * fromBigInt(invoice.startingBal) / 100 || 0,
          totalConsumed: fromBigInt(invoice.totalCharges) / 100,
          endingBalance: -1 * fromBigInt(invoice.endingBal) / 100 || 0,
          amountDue: fromBigInt(invoice.amountDue) / 100,
        },
        cellDisplay: {
          status: [
            { type: 'bullet', color: getStatusColor(invoiceStatusDisplay) },
          ],
        },
        menuItems: [
          {
            label: 'Download',
            onClick: () => window.open(invoice.pdfUrl),
            startIcon: { name: 'pdf' },
          },
        ],
      };
    },
  ) ?? [];

  return (
    <>
      <div className={commonClasses.summaryContainer}>
        <div className={commonClasses.summary}>
          <span>Current Owed Balance</span>
          <div className={commonClasses.summaryMain}>
            {formatCents(Math.max(0, balance))}
          </div>
          <span>
            {latestInvoice &&
              `Current invoice: ${formatCents(fromBigInt(latestInvoice.amountDue))}`}
          </span>
        </div>
        <div className={commonClasses.summary}>
          <span>Next Payment Due</span>
          <div className={commonClasses.summaryMain}>
            {latestInvoice?.status === 'open' ?
              formatDate(fromBigInt(latestInvoice.dueDate)) :
              'No Payment Due'}
          </div>
        </div>
        <div className={commonClasses.summary}>
          <span>Remaining Funds</span>
          <div className={commonClasses.summaryMain}>
            {formatCents(Math.max(0, -1 * balance))}
          </div>
          <span>
            {balance >= 0 && '* Call sales to add funds'}
          </span>
        </div>
      </div>
      <div className={commonClasses.title}>Setup</div>
      <div className={commonClasses.summaryContainer}>
        <div className={commonClasses.summary}>
          <span>Billing Contact</span>
          <div className={commonClasses.summaryMain}>
            {accountInfo?.billing?.email}
          </div>
          <span>{accountInfo?.billing?.address}</span>
        </div>
      </div>
      <div className={commonClasses.title} style={{ marginBottom: '16px' }}>Invoices</div>
      <Table
        asBlock
        columnConfigs={billingTableColumnConfigs}
        defaultSort={{ columnId: 'invoiceDate', descending: true }}
        name="invoices-table"
        rowConfigs={billingTableRowData}
      />
    </>
  );
};

const BillingPageBody = () => {
  const accountInfo = useAccountInfo();
  const isStarterPlan = useIsStarterPlan();

  return (
    <MainPageLayout permission={!!accountInfo?.billing} title="Plans and Billing">
      {isStarterPlan ? <BillingPageStarterPlan /> : <BillingPageNonStarterPlan />}
    </MainPageLayout>
  );
};

export default BillingPageBody;
