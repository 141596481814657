// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState, useSetRecoilState } from 'recoil';

import { checkedUrlsStateFixture } from '../lib/fixtures';
import { DEFAULT_URLS } from '../lib/paramDefaults/checkedUrls';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import { isTestingEnv } from '../lib/testing/utils';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';

import { meshUrlState } from './meshState';

const checkedUrlsKey = 'checkedUrls';

function serialize(val: projectstatepb.CheckedUrls): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}

function deserialize(val: Uint8Array): projectstatepb.CheckedUrls {
  return (val.length ?
    projectstatepb.CheckedUrls.fromBinary(val) :
    DEFAULT_URLS);
}

const checkedUrlsStateRpc = selectorFamily<projectstatepb.CheckedUrls, string>({
  key: checkedUrlsKey,
  get: (projectId: string) => () => (
    persist.getProjectState(projectId, [checkedUrlsKey], deserialize)
  ),
});

const checkedUrlsStateTesting = selectorFamily<projectstatepb.CheckedUrls, string>({
  key: checkedUrlsKey,
  get: () => checkedUrlsStateFixture,
});

const checkedUrlsStateSelector = isTestingEnv() ? checkedUrlsStateTesting : checkedUrlsStateRpc;

export const checkedUrlsState = atomFamily<projectstatepb.CheckedUrls, string>({
  key: `${checkedUrlsKey}/final`,

  default: selectorFamily<projectstatepb.CheckedUrls, string>({
    key: `${checkedUrlsKey}/Default`,
    get: (projectId: string) => ({ get }) => {
      const checkedUrlsValue = get(checkedUrlsStateSelector(projectId));
      const meshUrl = get(meshUrlState(projectId));
      // Update the status for backwards compatibility. Older projects do not have a status.
      // Update status to successful if the URL is in the URL list.
      if (checkedUrlsValue.urls.includes(meshUrl.url)) {
        return new projectstatepb.CheckedUrls({
          ...checkedUrlsValue,
          status: projectstatepb.CheckGeometryStatus.SUCCESSFUL,
        });
      }
      return checkedUrlsValue;
    },
  }),
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, checkedUrlsKey, deserialize, serialize),
  ],
});

export const useCheckedUrls = (projectId: string) => (
  useRecoilState(checkedUrlsState(projectId))
);

export const useSetCheckedUrls = (projectId: string) => (
  useSetRecoilState(checkedUrlsState(projectId))
);
