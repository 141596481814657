// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';

import { AuthInfoV2, getAuthInfoV2 } from '../../../lib/AuthInfoCallbackV2';

// A copy of Auth.getAuthInfo(). The two values
// are synchronized using AuthSubscriber in the toplevel index.tsx.
export const authInfoV2 = atom<AuthInfoV2>(getAuthInfoV2());

export function useAuthInfoV2() {
  return useAtom(authInfoV2);
}

export function useAuthInfoV2Value() {
  return useAtomValue(authInfoV2);
}

export function useSetAuthInfoV2() {
  return useSetAtom(authInfoV2);
}
