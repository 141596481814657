// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 89;
const baseHeight = 100;
export const CubeFacesOutlineIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="cubeFacesOutline"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M4.648,23.009L44.5,-0L84.352,23.009L44.5,46.018L4.648,23.009ZM20.629,23.009L44.5,36.791
           L68.371,23.009L44.5,9.227L20.629,23.009Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M88.951,30.974L88.951,76.991L49.099,100L49.099,53.982L88.951,30.974ZM80.96,44.814
           L57.089,58.596L57.089,86.16L80.96,72.378L80.96,44.814Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M39.901,100L0.049,76.991L0.049,30.974L39.901,53.982L39.901,100ZM31.911,86.16
           L31.911,58.596L8.04,44.814L8.04,72.378L31.911,86.16Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
