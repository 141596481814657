// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/*
 Blank page to be shown before a user is authenticated.
*/
import React, { useState } from 'react';

import { ActionButton } from '../components/Button/ActionButton';
import { createStyles, makeStyles } from '../components/Theme';
import BaseLayout from '../components/layout/BaseLayout';
import { BrowserShieldIcon } from '../components/svg/BrowserShieldIcon';
import { LcLogoIcon } from '../components/svg/LcLogoIcon';
import { colors } from '../lib/designSystem';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      margin: 'auto',
      maxWidth: 'fit-content',
      width: '600px',
    },
    logo: {
      left: '32px',
      position: 'absolute',
      top: '32px',
    },
    contentBox: {
      alignItems: 'center',
      display: 'inline-flex',
      flexDirection: 'column',
      gap: '24px',
    },
    header: {
      color: colors.highEmphasisText,
      fontSize: '24px',
      fontWeight: '600',
      lineHeight: '32px',
      margin: 0,
      textAlign: 'center',
    },
    p200: {
      fontSize: '14px',
      lineHeight: '20px',
      margin: 0,
      textAlign: 'center',
    },
    codeBox: {
      alignItems: 'center',
      alignSelf: 'stretch',
      background: colors.neutral200,
      border: `1px solid ${colors.neutral350}`,
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px',
    },
    code: {
      color: colors.highEmphasisText,
      flex: '1 0 0',
      fontSize: '14px',
      lineHeight: '20px',
    },
    hint: {
      alignSelf: 'stretch',
      color: colors.highEmphasisText,
      fontSize: '14px',
      lineHeight: '20px',
      margin: 0,
      textAlign: 'center',
    },
  }),
  { name: 'AuthCodePage' },
);

const AuthCodePage = () => {
  const classes = useStyles();
  const [buttonText, setButtonText] = useState('Copy');

  // Can't use useSearchParams() to get params added via redirect because we use HashRouter.
  const authcode = new URLSearchParams(window.location.search).get('code');

  function copyCode() {
    navigator.clipboard.writeText(authcode || '')
      .then(() => {
        setButtonText('Copied!');
      })
      .catch(() => {
        setButtonText('Failed to copy');
      })
      .finally(() => {
        setTimeout(() => {
          setButtonText('Copy');
        }, 2000);
      });
  }

  return (
    <BaseLayout title="Luminary Cloud SDK Remote Login">
      <div className={classes.logo}>
        <LcLogoIcon maxHeight={32} />
      </div>
      <div className={classes.root}>
        <div className={classes.contentBox}>
          <BrowserShieldIcon color={[colors.neutral650, colors.neutral350, colors.neutral900]} />
          <h1 className={classes.header}>
            Sign in to Luminary Cloud
          </h1>
          <p className={classes.p200}>
            You are seeing this page because you are attempting to log in via the Luminary Cloud SDK
            from this or another machine. If this is not the case, close this tab.
          </p>
          <p className={classes.p200}>
            Enter the following verification code when prompted by the SDK on the machine you want
            to log into. This is a credential similar to your password and
            <b> should not be shared with others</b>.
          </p>
          <div className={classes.codeBox}>
            <code className={classes.code}>
              {authcode}
            </code>
            <ActionButton
              kind="primary"
              onClick={() => copyCode()}>
              {buttonText}
            </ActionButton>
          </div>
          <p className={classes.hint}>
            You can close this tab when you’re done.
          </p>
        </div>
      </div>
    </BaseLayout>
  );
};

export default AuthCodePage;
