// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { AuthPageLayout } from '../../components/layout/page/AuthPageLayout';
import { SetupSelect2FALocationState, getActiveMfaOptions, getLoginTokenForGoogleAuth, getMfaTokenExpiry } from '../../lib/AuthMFA';
import { ACCESS_DENIED_ERROR, handleAuthenticatedV2 } from '../../lib/AuthV2';
import { isProd } from '../../lib/RuntimeParams';
import { locationOriginRoute, routes } from '../../lib/navigation';
import { Logger } from '../../lib/observability/logs';

const logger = new Logger('GoogleAuthCallback');

const GoogleAuthCallbackPage = () => {
  // Hooks
  const navigate = useNavigate();

  // Handlers
  async function handleCallback() {
    const params = new URLSearchParams(window.location.search);
    if (params.get('code')) {
      try {
        const reply = await getLoginTokenForGoogleAuth(
          params.get('code') || '',
          locationOriginRoute(),
          true,
        );
        // Here user is already successfully authenticated with google.
        // If it is their first login they won't have any active MFA and we must force it upon
        // them because all accounts in prop should have active MFA.
        // If they have MFA and they get to this point, this means they have already passed the
        // MFA check in the Auth0's Universal MFA page (login MFA happens outside of our app)
        // and we can safely authenticated them.
        const { data: mfaOptions } = await getActiveMfaOptions(reply.accessToken);
        const noActiveMfa = !mfaOptions.some((mfaOption) => mfaOption.active);

        if (noActiveMfa && isProd) {
          // Todo: Make exception for non-prod: LC-14776
          const setupSelect2faLocationState: SetupSelect2FALocationState = {
            mfaTokenExpiry: getMfaTokenExpiry(),
            mfaToken: reply.accessToken,
            idToken: reply.idToken,
          };
          navigate(routes.setupSelect2fa, {
            replace: true,
            state: setupSelect2faLocationState,
          });
        } else {
          handleAuthenticatedV2(reply.idToken).catch((handleAuthErr) => {
            logger.error('GoogleAuthCallbackPage/handleAuthenticatedV2', handleAuthErr);
            navigate(routes.login, {
              state: {
                error: 'Internal error occurred with the multi-factor authentication',
              },
            });
          });
        }
      } catch (err) {
        logger.error('GoogleAuthCallback: ', err);
        navigate(routes.login, {
          state: {
            error: 'Internal error occurred with the multi-factor authentication',
          },
        });
      }
    } else if (params.get('error')) {
      logger.error(params.get('error')!);
      if (params.get('error_description')) {
        logger.error(params.get('error_description')!);
      }
      navigate(routes.login, {
        state: {
          error: ACCESS_DENIED_ERROR,
        },
      });
    }
  }

  // Effects
  useEffect(() => {
    handleCallback().catch(() => { });
    // Even if we wrap the handleCallback with useCallback, this effect still runs more than once
    // when the user is redirected here after google authorization. And this should never happen
    // because that will cause a bunch of bad redirects and errors. Explicitly use an empty array
    // for the  array to gaurantee that this never runs more than once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthPageLayout loading rightSection={false} />
  );
};

export default GoogleAuthCallbackPage;
