// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import './Tab.scss';

export type TabSize = 'xsmall' | 'small' | 'medium';

export interface TabProps {
  first?: boolean;
  last?: boolean;
  label: string;
  name?: string;
  selected?: boolean;
  onClick: () => void;
  disabled?: boolean;
  size?: TabSize;
}

export const Tab = (props: TabProps) => {
  const { disabled, first, label, last, name = '', onClick, selected, size } = props;

  return (
    <button
      className={cx('buttonTab', size, { disabled, first, last, selected })}
      data-name={name}
      disabled={disabled}
      onClick={onClick}
      role="tab"
      type="button">
      {label}
    </button>
  );
};
