// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { NodeType } from '../../../lib/simulationTree/node';
import { defaultNodeFilter, isSubselectActive } from '../../../lib/subselectUtils';
import { NodeFilter, useSimulationTreeSubselect } from '../../../recoil/simulationTreeSubselect';

const VISIBLE_TYPES = new Set([NodeType.SURFACE, NodeType.SURFACE_GROUP, NodeType.PARTICLE_GROUP]);

export const useVisualizationSurfacesSubselect = (subselectId: string, allowDisks = true) => {
  const treeSubselect = useSimulationTreeSubselect();

  const isActive = useMemo(
    () => isSubselectActive(treeSubselect, subselectId),
    [subselectId, treeSubselect],
  );

  const validTypes = useMemo(() => {
    const types = new Set(VISIBLE_TYPES);
    if (!allowDisks) {
      types.delete(NodeType.PARTICLE_GROUP);
    }
    return types;
  }, [allowDisks]);

  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (validTypes.has(nodeType)) {
      return { related: true };
    }
    return defaultNodeFilter(nodeType);
  }, [validTypes]);

  return {
    isActive,
    nodeFilter,
  };
};
