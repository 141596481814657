// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { getQuantityUnit } from '../QuantityDescriptor';
import * as basepb from '../proto/base/base_pb';
import { QuantityType } from '../proto/quantity/quantity_pb';

import { newOriginProto } from './Vector';
import { adVec3ToVec3 } from './adUtils';
import { formatNumber, formatNumberList } from './number';

function getQuantitySuffix(quantity: QuantityType) {
  const unit = getQuantityUnit(quantity);
  if (quantity === QuantityType.DEGREE) {
    return unit;
  }

  return ` ${unit}`;
}

// Format FloatType value as string with both numeric value and units
export function formatFloat(adFloat: number | undefined): string {
  if (Number.isNaN(Number(adFloat))) {
    return '—'; // mdash
  }

  if (adFloat) {
    return formatNumber(adFloat);
  }

  return '0';
}

// Format Vector3 value as a comma-separated string of formatted values, wrapped in parentheses
export function formatVector(
  vector: basepb.Vector3 | undefined,
  quantity?: QuantityType,
): string {
  if (vector) {
    const { x, y, z } = vector;
    const suffix = quantity ? getQuantitySuffix(quantity) : '';
    return formatNumberList([x, y, z], undefined, suffix);
  }

  return '';
}

export function formattedVelocity(value: basepb.AdVector3 | undefined, quantity: QuantityType) {
  return formatVector(
    value ? adVec3ToVec3(value) : newOriginProto(),
    quantity,
  );
}
