// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { ReactNode, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { PopoverPosition } from '@mui/material';
import cx from 'classnames';
import LogRocket from 'logrocket';

import { referenceValues } from '../../flags';
import GroupMap from '../../lib/GroupMap';
import { ViewName, keepalive } from '../../lib/ParaviewClient';
import assert from '../../lib/assert';
import { CurrentView } from '../../lib/componentTypes/context';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { colors, hexToRgbList } from '../../lib/designSystem';
import { getImposterId, updateFarFieldImposter, updateImposters } from '../../lib/imposterFilteringUtils';
import { getHelpText } from '../../lib/keyBindings';
import { areArraysNear } from '../../lib/lang';
import { findFrameById } from '../../lib/motionDataUtils';
import { NodeTableType } from '../../lib/nodeTableUtil';
import { Logger } from '../../lib/observability/logs';
import {
  mainGridName,
  newNode,
  traverseTreeNodes,
  updateTreeNodes,
} from '../../lib/paraviewUtils';
import { protoToJson } from '../../lib/proto';
import {
  getReferenceValues,
  validReferenceValueSelection,
} from '../../lib/referenceValueUtils';
import { MouseClickEvent } from '../../lib/renderer';
import { SelectionAction, VOLUME_TABLES, allowedSelection } from '../../lib/selectionUtils';
import { NodeType } from '../../lib/simulationTree/node';
import * as status from '../../lib/status';
import { addPvRpcError, addRpcError } from '../../lib/transientNotification';
import { useCoordinateVisualizer } from '../../lib/useCoordinateVisualizer';
import useResizeObserver from '../../lib/useResizeObserver';
import { debounce } from '../../lib/utils';
import {
  DEFAULT_FILTER_ROOT,
  OVERLAY_CARD_WIDTH,
  VIEWER_PADDING,
  orderDisplayVars,
  removeResultsFilter,
  setCurrentVisUrl,
  toggleSmoothClips,
  visComputeFilterWrapper,
} from '../../lib/visUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useEntityGroupMap } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useMeshUrlState } from '../../recoil/meshState';
import { useOutputNodes } from '../../recoil/outputNodes';
import {
  CameraMode,
  useCameraPosition,
  useEditStateValue,
  useParaviewInitialSettings,
} from '../../recoil/paraviewState';
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { useEntitySelectionValue } from '../../recoil/selectionOptions';
import { useSetSelectedVisualizerEntities, useSimulationTreeSubselect } from '../../recoil/simulationTreeSubselect';
import { useCadModifier } from '../../recoil/useCadModifier';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useRefinementRegionsVisEffect } from '../../recoil/useRefinementRegions';
import { StaticVolume, useStaticVolumes } from '../../recoil/volumes';
import { useForceViewStateSyncKeyValue } from '../../state/external/project/sharing';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useSetStatusCardProps } from '../../state/internal/component/statusCard';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { useCurrentView } from '../../state/internal/global/currentView';
import {
  useMotionAnimationParamState,
  useMotionAnimationPlaying,
  useShowMotionAnimationSettings,
} from '../../state/internal/vis/motionAnimation';
import { OverlayMode, useOverlayMode } from '../../state/internal/vis/overlayMode';
import { useSetVisHeight } from '../../state/internal/vis/visHeight';
import { useWorkflowFlagValue } from '../../workflowFlag';
import { CommonMenu } from '../Menu/CommonMenu';
import FloatingToolbar from '../Pane/FloatingToolbar';
import { SCROLLBAR_WIDTH, createStyles, makeStyles } from '../Theme';
import { BUTTON_SIZE } from '../Toolbar/ToolbarButton';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useHandleManualCameraViewChange } from '../hooks/useHandleManualCameraViewChange';
import { useNodeGrouping } from '../hooks/useNodeGrouping';
import { LeftOverlayCards } from '../project/LeftOverlayCards';
import { RightOverlayCards } from '../project/RightOverlayCards';
import { FloatingPropertiesPanel } from '../treePanel/FloatingPropertiesPanel';
import { useSubselectControl, useSubselectVisualizerMenuItems } from '../treePanel/NodeSubselect/control';

import CameraControlPanel from './CameraControlPanel';
import ColorBarPanel from './ColorBarPanel';
import { useColorPanelState } from './ColorPanelManager';
import EditColorsBox from './EditColorsBox';
import { triggerBoxSelect } from './ImageRenderer';
import MotionAnimationSettings from './MotionAnimationSettings';
import BoxSelectOverlay from './Overlay/BoxSelectOverlay';
import CenterOfRotationCameraOverlay from './Overlay/CenterOfRotationCameraOverlay';
import ProbeOverlay from './Overlay/ProbeOverlay';
import ZoomToBoxOverlay from './Overlay/ZoomToBoxOverlay';
import { useParaviewContext } from './ParaviewManager';
import StatusOverlay from './StatusOverlay';
import TipsOverlay from './TipsOverlay';
import { useDraggable } from './draggable';

const logger = new Logger('Paraview');

const EDIT_COLOR_DRAG_ID = 'color-dialog';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
      zIndex: 1,
    },
    // <div> that wraps Renderer and the various overlays
    rendererRoot: {
      /* Explicitly set the position this div to a non-static value.
         Otherwise, the children of this element will bypass this div when
         finding the containing block for absolute placement.

         Ref: // CSS2 9.3.2
         (https://www.w3.org/TR/CSS2/visuren.html#absolute-positioning)
         and CSS the definitive guide (4th ed), Ch 11. */
      position: 'relative',
      // Take over the remaining height of the viewport.
      flexGrow: 1,
      minHeight: 0,
      backgroundColor: colors.surfaceBackground,
    },
    // Renderer and the overlays.
    rendererChild: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      minHeight: 0,
      boxSizing: 'border-box',
      overflow: 'hidden',
      filter: `drop-shadow(2px 2px 1px ${colors.neutral100})`,
      // Prevent the Paraview window from being highlighted.
      userSelect: 'none',
    },
    motionDialog: {
      position: 'absolute',
      right: `${VIEWER_PADDING}px`,
      top: `${BUTTON_SIZE + 2 * VIEWER_PADDING}px`,
      // Should be higher than FloatingToolbar's zIndex
      zIndex: 4,
    },
    colorDialog: {
      position: 'absolute',
      right: '10px',
      top: '10px',
      // Should be higher than FloatingToolbar's zIndex
      zIndex: 4,
    },
    colorPanelsContainer: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      padding: '0px',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: '0px',
      '&.noPointerEvents': {
        pointerEvents: 'none',
      },
    },
    colorPanel: {
      position: 'relative',
      zIndex: 1,
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
      pointerEvents: 'auto',
      padding: '30px 20px 30px 60px',
      backgroundColor: 'transparent',
      filter: `drop-shadow(2px 2px 1px ${colors.neutral100})`,
      transition: 'background-color 500ms, backdrop-filter 500ms',
      '&.dragging': {
        backgroundColor: 'rgb(0, 0, 0, 0.5)',
        backdropFilter: 'blur(3px)',
        zIndex: 2,
      },
    },
  }),
  { name: 'Paraview' },
);

function defaultReprTypeForUrl(url: string): ParaviewRpc.RepresentationType {
  if (url.endsWith('.lcmesh')) {
    return 'Surface With Edges';
  }
  return 'Surface';
}

/** Wraps the main paraview render window inside React component. */
const ParaView = () => {
  // == Contexts
  const {
    paraviewProjectId,
    paraviewMeshMetadata,
    paraviewViewName,
    paraviewActiveUrl,
    paraviewClientState,
    paraviewRenderer,
    setSyncing,
    syncing,
    resetViewState,
    cameraMode,
    setCameraMode,
    viewState,
    setViewAttrs,
    syncOnlyMeshSoln,
    colorMapsVisibility,
    onRpcSuccess,
    changeNodeVisibility,
    activeEdit,
    backgroundColor,
    setBackgroundColor,
  } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const {
    selectedNode,
    selectedNodeIds,
    setSelection,
    activeNodeTable,
    modifySelection,
    setNodeTableWarning,
    highlightedInVisualizer,
    setScrollTo,
  } = useSelectionContext();

  // == Recoil
  const [overlayMode, setOverlayMode] = useOverlayMode();
  const experimentConfig = useEnabledExperiments();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const currentView = useCurrentView();
  const setStatusCardProps = useSetStatusCardProps(projectId);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId);
  const [meshUrlState] = useMeshUrlState(projectId);
  const entitySelectionType = useEntitySelectionValue(projectId);
  const [motionAnimationPlaying, setMotionAnimationPlaying] = useMotionAnimationPlaying();
  const [
    showMotionAnimationSettings,
    setShowMotionAnimationSettings,
  ] = useShowMotionAnimationSettings();
  const coordinateVisualizer = useCoordinateVisualizer(simParam, paraviewClientState);
  const workflowFlag = useWorkflowFlagValue();

  // The current parameters for a motion animation
  const [gridMotionParam] = useMotionAnimationParamState(projectId);
  const treeSubselect = useSimulationTreeSubselect();
  const { endSubselect } = useSubselectControl();
  const getSubselectMenuItems = useSubselectVisualizerMenuItems();
  const setSelectedVisualizerEntities = useSetSelectedVisualizerEntities();
  const setVisHeight = useSetVisHeight();
  const setConfirmStack = useSetConfirmations();
  const [perJobOutputNodes] = useOutputNodes(projectId, workflowId, jobId);
  // The persisted camera state (angle, zoom, etc.)
  const [cameraPosition, setCameraPosition] = useCameraPosition(
    paraviewProjectId,
    paraviewActiveUrl,
    paraviewMeshMetadata,
  );
  // We need to update imposters here so we need potential imposters
  // such as the far field.
  const [cadModifier] = useCadModifier(projectId);
  const isGeometryPending = useIsGeometryPending(projectId);
  const editState = useEditStateValue();
  const [paraviewInitialSettings] = useParaviewInitialSettings(
    paraviewProjectId,
    paraviewActiveUrl,
    paraviewMeshMetadata,
  );
  const forceViewStateSyncKeyValue = useForceViewStateSyncKeyValue(projectId);

  // == State
  // A menu appears when you right-click on a surface. We store here the
  // position of the menu and the surface that it applies to.
  const [rightMenuPos, setRightMenuPos] = useState<PopoverPosition | null>(null);
  const [rightMenuSurface, setRightMenuSurface] = useState<string>('');
  const [pointerEventsDisabled, setPointerEventsDisabled] = useState<boolean>(true);
  const [displayVariables, setDisplayVariables] = useState<ParaviewRpc.DisplayPvVariable[]>([]);
  const rendererRootNode = useRef<HTMLDivElement>(null);
  const panelContainer = useRef<HTMLDivElement>(null);
  const panelNodes = useRef<Array<HTMLDivElement | null>>([]);
  const dialogNode = useRef<HTMLDivElement>(null);
  const [forceSync, setForceSync] = useState<number>(0);
  const pvRef = useRef<HTMLDivElement>(null);
  const cachedEditState = useRef(editState);
  // State to track when we have successfully converted the simulation params proto to json.
  const [paramState, setParamState] = useState<string | null>(null);
  // We need a way to sync the view state on demand.
  // This is needed for when a Viewer needs to receive the Owner's state of a project -
  // on initial page load and also later, if they click on a re-sync state button.
  const syncKeyRef = useRef(0);
  const geometryTags = useGeometryTags(projectId);

  // == Hooks
  const {
    colorDialogState,
    closeEditState,
    cancelEdit,
    updateCmap,
    initColorDialogState,
    colorBarsToShow,
    MAX_BINS,
    MAX_TICKS,
    changeOrientation,
    getVertical,
  } = useColorPanelState();

  const { initDrag, draggedPositions, dragPositionToStyle, currentDragId } = useDraggable();
  const classes = useStyles();
  const pvSize = useResizeObserver(pvRef);
  const handleManualCameraViewChange = useHandleManualCameraViewChange();

  // == Derived Data
  const paraviewClient = paraviewClientState.client;
  const connected = paraviewClient?.connection;
  // Syncing occurs when we connect disconnect from one paraview and
  // reconnected to another (likely the same paraview) when switching in
  // between simulation tabs. When we switch, not all progress is reported via
  // the paraview client state message. If the mesh is large the time spent
  // sycning with no message to display can be seconds, and the old simulation
  // is displayed. This is confusing to the user. If we are in this state, and
  // there is no message, show the overlay.
  const showStatusOverlay = !connected || !!paraviewClientState.message || syncing;
  const displaySmoothClips = meshUrlState.activeType === projectstatepb.UrlType.GEOMETRY;
  // Rely on areArraysNear to cover potential floating point error.
  const lightBackground = useMemo(
    () => areArraysNear(backgroundColor, hexToRgbList(colors.lightBackgroundColor)),
    [backgroundColor],
  );
  const showTipsOverlay = isGeometryPending && !meshUrlState.preview;
  const isSetupOrSolutionAndParamsReady = !!paramState || paraviewViewName === ViewName.SETUP;
  const settingsReady = !!paraviewInitialSettings;

  const toggleBackground = () => {
    const newColor = lightBackground ? colors.darkBackgroundColor : colors.lightBackgroundColor;
    setBackgroundColor(hexToRgbList(newColor));
    setRightMenuSurface('');
    setRightMenuPos(null);
  };

  const handleSyncFail = (
    root: ParaviewRpc.TreeNode | null,
    attrs: ParaviewRpc.ViewAttrs | null,
  ) => {
    pushConfirmation(setConfirmStack, {
      destructive: true,
      onContinue: async () => {
        await syncOnlyMeshSoln(root, attrs);
      },
      title: 'Reset Visualization State',
      children: (
        <div>
          An error has occurred with the visualization system.
          Resetting the visualization state could resolve the error,
          but all your visualization filters will be deleted.
          Alternatively, please contact support for assistance.
        </div>
      ),
    });
  };

  useEffect(() => {
    logger.debug(`paraview: state=${paraviewClientState.connState} ` +
      `msg=${paraviewClientState.message}, ` +
      `progress=${paraviewClientState.progress} syncing=${syncing}`);
  }, [paraviewClientState, syncing]);

  // A mouse-click handler. It highlights the surface at the pointer.
  // It is a ref since it is updated every time selectedNode or selectedNodeIds
  // changes.
  const onMouseClick = useRef<((event: MouseClickEvent, numClicks: number) => void) | null>(null);

  // Dragging the mouse changes may cause the camera position to change. To
  // handle this, we change the camera position to a more generic name.
  // X+, Y-, Z+, etc to become "Orthographic".
  // "Perspective" does not change.
  const onMouseDrag = useRef<((event: MouseClickEvent) => void) | null>(null);
  useEffect(() => {
    onMouseDrag.current = (event: MouseClickEvent) => {
      if (triggerBoxSelect(event)) {
        setOverlayMode(OverlayMode.BOX_SELECT);
      } else if (cameraMode !== CameraMode.PERSPECTIVE && cameraMode !== CameraMode.ORTHOGRAPHIC) {
        setCameraMode(CameraMode.ORTHOGRAPHIC);
      }
    };
    return () => {
      onMouseDrag.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraMode]);

  useLayoutEffect(() => {
    setVisHeight(pvSize.height);
  }, [pvSize, setVisHeight]);

  // This effect will run on component mount.
  useEffect(() => {
    if (!pvRef.current) {
      throw Error('null elem');
    }
    paraviewRenderer.initialize(paraviewViewName, pvRef.current, experimentConfig);
    return () => {
      paraviewRenderer.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paraviewRenderer]);

  // This effect will run every time a depenency changes. Note that
  // the above effect always run before this effect, so
  // at the point of setClient call, the renderer has always been initialized.
  useEffect(() => {
    paraviewRenderer.setClient(
      paraviewClient,
      setCameraPosition,
      (event: MouseClickEvent) => onMouseClick.current?.(event, 1),
      (event: MouseClickEvent) => onMouseClick.current?.(event, 2),
      (event: MouseClickEvent) => {
        onMouseDrag.current?.(event);
        handleManualCameraViewChange();
      },
      () => keepalive(paraviewClientState),
    );
  }, [
    paraviewClientState,
    paraviewRenderer,
    setCameraPosition,
    paraviewClient,
    handleManualCameraViewChange,
  ]);

  useEffect(() => {
    cachedEditState.current = editState;
  }, [editState]);

  // Note(matt): we only need these params in cases where we have a result with moving parts.
  // That said, I wanted to keep the logic simple, so just do this everytime. On the paraview side,
  // we skip any derived field calculations if the required data isn't present. If we want to make
  // the logic more complicated, paraview can handle this string being null.
  useEffect(() => {
    if (
      simParam &&
      paraviewViewName === ViewName.SOLUTION &&
      validReferenceValueSelection(perJobOutputNodes, simParam)
    ) {
      // TODO(LC-10464): Use the new reference value message instead of the params
      const newParam = simParam.clone();
      const refValues = getReferenceValues(
        perJobOutputNodes,
        simParam,
        experimentConfig,
        geometryTags,
        staticVolumes,
      );
      if (experimentConfig.includes(referenceValues)) {
        newParam.referenceValues = new simulationpb.ReferenceValues({ ...refValues });
      }
      // Convert the proto to json since the visualizer can only handled proto in json format.
      protoToJson(newParam, false).then((jsonData: string) => {
        setParamState(jsonData);
      }).catch((err) => {
        addPvRpcError('Could not convert to JSON', err);
      });
    }
  }, [geometryTags, simParam, perJobOutputNodes, paraviewViewName, experimentConfig,
    staticVolumes]);

  // First, we make sure to show the "Syncing" overlay on page load and when the user clicks the
  // re-sync button. Both actions would trigger that effect.
  useEffect(() => {
    setSyncing(true);
  }, [forceViewStateSyncKeyValue, setSyncing]);

  // Then, we listen for any changes in the paraviewInitialSettings and if the
  // forceViewStateSyncKeyValue has changed until our last manual sync request, we can
  // safely sync again by changing the forceSync var. It will trigger another useEffect that does
  // the actual sync.
  useEffect(() => {
    if (syncKeyRef.current !== forceViewStateSyncKeyValue) {
      syncKeyRef.current = forceViewStateSyncKeyValue;
      setForceSync((oldValue) => oldValue + 1);
      resetViewState();
    }
  }, [paraviewInitialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  // Sync the view state after connection (re-)establishment.
  useEffect(() => {
    if (
      !paraviewClient ||
      !paraviewActiveUrl ||
      !settingsReady ||
      !isSetupOrSolutionAndParamsReady
    ) {
      return;
    }
    // Disable the motion animation.
    if (paraviewViewName === ViewName.SOLUTION) {
      setShowMotionAnimationSettings(false);
      // Make sure to hide the status card.
      setStatusCardProps({ message: '', isVisible: false });
    }
    let root: ParaviewRpc.TreeNode | null = null;
    let attrs: ParaviewRpc.ViewAttrs | null = null;
    if (viewState?.root && viewState?.path === paraviewActiveUrl) {
      // Reconnecting to paraview after disconnection.
      // Force my state onto the server.
      root = viewState.root;
      attrs = viewState.attrs;
    } else {
      // New session.
      const param: ParaviewRpc.TreeNodeParam = {
        typ: ParaviewRpc.TreeNodeType.READER,
        url: paraviewActiveUrl,
        fvmparams: null,
        customexp: null,
      };
      const displayProps: ParaviewRpc.DisplayProps = {
        reprType: 'Surface',
        displayVariable: null,
      };
      root = {
        ...newNode(param, null, false, displayProps),
        name: 'Reader',
        id: DEFAULT_FILTER_ROOT,
        child: paraviewInitialSettings.filters,
      };
      attrs = {
        ...paraviewInitialSettings.attrs,
        reprType: paraviewInitialSettings.attrs.reprType ||
          defaultReprTypeForUrl(paraviewActiveUrl),
      };

      // We now have visualization filters that represent params (e.g., actuator disks): imposters.
      // We share visualization state between tabs (simulations within a project), which allows
      // users to compare simulation results. Since the imposters are based on parameters which can
      // be different between simulations, we need to add/remove imposters based on the the current
      // simulation parameter.
      root = updateImposters(simParam, root, attrs);
    }

    // If there's no mesh, make all clips smooth. If there is a mesh, make all clips crinkle-clips,
    // since smooth clips in Paraview don't work with volumes.
    root = toggleSmoothClips(root, displaySmoothClips);

    // We encountered an issue(LC-10755) where a bad range made it into the view state.  Since the
    // view state is currently shared in all simulations in a project, this breaks the entire
    // project. The view state is "validated" only when we send over the data.  This removes all
    // ranges from the viewState to break the death cycle. We rely on paraview to populate this
    // data, so we don't need to send it when we sync nodes.
    root = updateTreeNodes(
      root,
      (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => ({ ...node, pointData: [] }),
    );

    if (paraviewViewName === ViewName.SETUP) {
      // LC-16562 When users clicks between many tabs (setup/results and other
      // pages), the UI receives rpc responses from all the paraview instances.
      // We do check to see if the response URL matches what we currently have,
      // but there is a gap in the logic that ended up putting results filters
      // in setup. This results in repeated errors being generated, to avoid this,
      // we will just strip them.
      root = removeResultsFilter(root);
    }

    // We don't have a way to know what the current URL is inside paraview manager, since its a
    // single component that communicates with various client.  The call sets a global state so we
    // can ingore replies that are no longer applicable.  This is the one place in the code where
    // we switch urls. Consequently this is the only place that should use this call.
    setCurrentVisUrl(paraviewActiveUrl);

    // Update the extracts to blank urls to indicate that we are re-calculating. Additionally, add
    // the current params string to the reader for derived fields.
    root = updateTreeNodes(
      root,
      (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
        if (node.param.typ === ParaviewRpc.TreeNodeType.READER) {
          if (paraviewViewName === ViewName.SOLUTION) {
            const param: ParaviewRpc.ReaderParam = {
              ...node.param,
              fvmparams: paramState,
              customexp: null,
            };
            return { ...node, param };
          }
        }
        if (node.param.typ === ParaviewRpc.TreeNodeType.MULTI_SLICE) {
          // Blank out the url while we calculate the multislice which will
          // create a invisible dataset while its calculating.
          const param: ParaviewRpc.MultiSliceParam = {
            ...node.param,
            url: '',
          };
          return { ...node, param };
        }
        if (node.param.typ === ParaviewRpc.TreeNodeType.STREAMLINES) {
          // Blank out the url while we calculate the streamlines which will
          // create a invisible dataset while its calculating.
          const param: ParaviewRpc.VisStreamlinesParam = {
            ...node.param,
            url: '',
          };
          return { ...node, param };
        }
        if (node.param.typ === ParaviewRpc.TreeNodeType.SURFACE_L_I_C) {
          // Blank out the url while we calculate the surface lic which will
          // create a invisible dataset while its calculating.
          const param: ParaviewRpc.SurfaceLICParam = {
            ...node.param,
            url: '',
          };
          return { ...node, param };
        }
        return node;
      },
    );

    // TODO(LC-4760): Remove when fixed.
    LogRocket.log('LC-4760 syncnodes: ' +
      `paraviewClient=${JSON.stringify(paraviewClient)} ` +
      `activeUrl=${paraviewActiveUrl} ` +
      `root=${JSON.stringify(root)}`);

    logger.debug('syncnodes: ' +
      `view=${paraviewClient.viewName} ` +
      `activeUrl=${paraviewActiveUrl} ` +
      `id=${paraviewClient.viewId} ` +
      `root=${JSON.stringify(root)} ` +
      `attrs=${JSON.stringify(attrs)}`);

    // Upon re-connection, we need to cleanup the ParaView state, this includes
    // stopping/cleaning up animations and the deletion of all widgets on screen that could
    // be remnant of an older session before the re-connection. Note that the users
    // do not have any mechanism to delete the widgets after a re-connection. Once
    // that is done, we proceed to set the renderer state.
    // NOTE: start cleaning up the ParaviewWeb global state which is not accessible
    // from the Handler object. Then proceed to call the Handler's cleanup function.
    paraviewClient.runRpc<void>('luminarycloud.animation.cleanup', []).then(() => {
    }).then(() => ParaviewRpc.cleanup(paraviewClient)).then(
      () => ParaviewRpc.syncnodes(paraviewClient, root, attrs, cameraPosition),
    )
      .then((result: ParaviewRpc.RpcResult) => {
        onRpcSuccess('syncnodes', result);
        // We have to wait to issue the vis service RPCs till after we get the
        // response back from paraview. Otherwise, it is possible to get the vis
        // service response back before the viewState url is updated, resulting
        // in the response being rejected because of a mismatched url.
        root?.child.forEach((value: ParaviewRpc.TreeNode) => {
          if (value.param.typ === ParaviewRpc.TreeNodeType.MULTI_SLICE ||
            value.param.typ === ParaviewRpc.TreeNodeType.STREAMLINES ||
            value.param.typ === ParaviewRpc.TreeNodeType.SURFACE_L_I_C) {
            // This function will automatically update the result url when the
            // async response is received if the current mesh url matches the mesh
            // url of the request.
            visComputeFilterWrapper(
              paraviewProjectId,
              paraviewActiveUrl,
              value.param,
              value.id,
              activeEdit,
            );
          }
        });

        if (cadModifier && isGeometryPending) {
          updateFarFieldImposter(paraviewRenderer, cadModifier, false, paraviewClientState);
        }

        if (!cameraPosition) {
          // The cameraPosition in recoil is null for new projects or when the previous geometry
          // was deleted if the user hasn't made any mouse interaction or camera changes in the
          // 3d viewer. We need to populate the cameraPosition if it's empty so that it doesn't
          // cause errors if the user tries to save that initial camera view or if the
          // cameraPosition is needed for another component.
          // The camera position must be set after the RPC request to `syncnodes`, because
          // `syncnodes` will reset the camera position when the cameraPosition recoil is null
          // If the cameraPosition is set before this reset, then stale camera data is sent and
          // saved by the `syncnodes` request
          ParaviewRpc.getcamerastate(paraviewClientState.client!).then((newPosition) => {
            setCameraPosition(newPosition);
          }).catch((err: status.ParaviewError) => {
            addPvRpcError('Could not get the camera state', err);
          });
        }
      })
      .catch((err: status.ParaviewError) => {
        addPvRpcError('Could not sync the visualization tree with Paraview', err);
        handleSyncFail(root, attrs);
      })
      .finally(() => {
        setSyncing(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paraviewClient,
    paraviewActiveUrl,
    settingsReady,
    isSetupOrSolutionAndParamsReady,
    forceSync,
    paramState,
  ]);

  // show refinement regions in the visualizer
  useRefinementRegionsVisEffect(
    { projectId, workflowId, jobId },
    paraviewRenderer,
    syncing,
    paraviewClient,
  );

  useEffect(() => {
    const refElem = pvRef.current;
    if (!refElem) {
      throw Error('null ref');
    }
    if (overlayMode !== OverlayMode.NONE || !simParam) {
      return () => { };
    }

    // Stop the motion animation on any click. This is intentional in order to simplify the
    // interaction between the different renderer components and the animation being displayed.
    if (motionAnimationPlaying) {
      paraviewRenderer.stopMotionAnimation();
    }

    onMouseClick.current = (event: MouseClickEvent, numClicks: number) => {
      // Do not select a surface while an edit is in progress, unless its extract
      // surfaces which needs to be able to select surfaces in the 3d window.
      if (
        !paraviewClient ||
        (cachedEditState.current &&
          cachedEditState.current.param.typ !== ParaviewRpc.TreeNodeType.EXTRACT_SURFACES) ||
        !meshUrlState.geometry
      ) {
        return;
      }
      // Paraview's Y axis grows from bottom to top.
      const x = Math.floor(event.screenX);
      const py = Math.floor(refElem.clientHeight - event.screenY);
      // Toggle is for active node table or when CTRL is pressed.
      const toggleMode = event.ctrlKey || (activeNodeTable.type !== NodeTableType.NONE);
      paraviewRenderer.getSurfaceAtPoint(x, py).then((entityAtPoint: string) => {
        if (event.button === 3) {
          const rootHeight = rendererRootNode.current?.scrollHeight || 0;
          setRightMenuPos({ left: event.screenX, top: event.screenY - rootHeight });
          let entityId = '';
          if (viewState?.root) {
            traverseTreeNodes(viewState?.root, (node) => {
              if (node.name === entityAtPoint) {
                entityId = node.id;
              }
            });
          }
          if (entityId) {
            setRightMenuSurface(entityId);
          } else {
            setRightMenuSurface(entityAtPoint === 'Not found' ? '' : entityAtPoint);
          }
          return;
        }
        if (entityAtPoint && entityAtPoint !== 'Not found') {
          // Handle left-click
          let clickedId = '';
          if (viewState?.root) {
            let id = entityAtPoint;
            if (VOLUME_TABLES.includes(activeNodeTable.type)) {
              // Meshing Size Table expects volumes. Convert from the surface to its volume.
              const staticVolume = staticVolumes.find((item) => item.bounds.has(id));
              id = staticVolume?.id ?? '';
            } else if (!entityGroupMap.has(id)) {
              // If the entity cannot be found in the group map it must be an imposter or a
              // vis extract so we have to traverse the pv tree nodes and find the node with
              // the correct name
              traverseTreeNodes(viewState.root, (node) => {
                if (node.name === entityAtPoint) {
                  const imposterId = getImposterId(node);
                  if (imposterId) {
                    id = imposterId;
                  } else {
                    id = node.id;
                  }
                }
              });
              assert(id !== entityAtPoint, `imposter/vis extract not found: ${id}`);
            }

            if (treeSubselect.active) {
              setSelectedVisualizerEntities({ ids: [id] });
            } else {
              // Check whether it is allowed to select the entity/node
              const warning = allowedSelection(
                id,
                selectedNode?.id,
                activeNodeTable.type,
                simParam,
                entityGroupMap,
                geometryTags,
                staticVolumes,
              );
              if (warning) {
                setNodeTableWarning(warning);
              } else if (numClicks === 2 && entityGroupMap.has(id)) {
                // For entity groups a single click events act on the whole group and double
                // click events act on individual entities.
                const parent = entityGroupMap.get(id).parentId!;
                clickedId = GroupMap.isRoot(parent) ? id : parent;
              } else {
                clickedId = id;
              }
            }
          }
          if (clickedId) {
            // Default action is to overwrite.
            let action = SelectionAction.OVERWRITE;
            if (toggleMode) {
              action = SelectionAction.TOGGLE;
            } else if (event.altKey) {
              // ALT triggers the subtract action.
              action = SelectionAction.SUBTRACT;
            }
            modifySelection({
              action,
              modificationIds: [clickedId],
              nodeTableOverride: activeNodeTable,
              selectionSource: 'vis',
            });
            // if the entitySelectionType is 'volume', then modifySelection will select the volume
            // instead of the surface. So we should scroll to that instead.
            if (entitySelectionType === 'volume') {
              const volumeToSelect = staticVolumes.find(
                (volume) => volume.bounds.has(clickedId),
              );
              if (volumeToSelect) {
                setScrollTo({ node: volumeToSelect.id, fast: true });
              }
            } else {
              setScrollTo({ node: clickedId, fast: true });
            }
          }
        } else if (entityAtPoint === 'Not found') {
          if (toggleMode) {
            // If we are in NodeTable selection mode and we click on the empty space in the viewer,
            // we should cancel the NodeTable's selection.
            if (activeNodeTable.type !== NodeTableType.NONE) {
              modifySelection({
                action: SelectionAction.HIGHLIGHT_CURRENT,
                nodeTableOverride: { type: NodeTableType.NONE },
                updateActiveNodeTable: true,
              });
              // Blur the NodeTable manually as it doesn't lose its focus on its own
              (document.activeElement as HTMLElement)?.blur();
            } else {
              // Explicit NoOp otherwise
            }
            // End the NodeSubselect selection mode as well if we click the empty space in the
            // viewer.
          } else if (treeSubselect.active) {
            endSubselect(treeSubselect.referenceNodeIds);
          } else {
            // If surface is not found and Ctrl key is not pressed - clear the selection.
            setSelection([]);
          }
        } else {
          // NoOp if surface string is ''
        }
      }).catch((err: Error) => {
        addRpcError('Failed finding the surface', err);
      });
    };
    return () => {
      onMouseClick.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    overlayMode,
    activeNodeTable,
    paraviewClient,
    selectedNode,
    selectedNodeIds,
    viewState,
  ]);

  // Update the highlighting whenever the selected node changes.
  useEffect(() => {
    const blockHighlighted: { [blockName: string]: boolean } = {};
    highlightedInVisualizer.forEach((surface) => {
      blockHighlighted[surface] = true;
    });
    setViewAttrs({
      blockHighlighted,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedInVisualizer]);

  // Initialize blockVisibility. If it is null or the visibility is undefined
  // for any surfaces set the visibility to true by default. Do not change the
  // visibility of any surfaces explicitly set to true or false.
  useEffect(() => {
    if (!paraviewClient || !viewState || !paraviewMeshMetadata) {
      return;
    }
    let modified = false;
    const oldVisible = viewState.attrs.blockVisibility || {};
    const newVisible: { [name: string]: boolean } = {};
    const setNewValue = (name: string) => {
      // Set visibility to true if undefined, otherwise keep old values.
      if (oldVisible[name] === undefined) {
        modified = true;
        newVisible[name] = true;
      } else {
        newVisible[name] = oldVisible[name];
      }
    };
    paraviewMeshMetadata.meshMetadata.zone.forEach((zone) => {
      const gridName = mainGridName(zone);
      setNewValue(gridName);
      zone.bound.forEach((bound) => {
        setNewValue(bound.name);
      });
    });
    // Update viewAttrs if any of the visibility has been modified.
    if (modified) {
      setViewAttrs({ blockVisibility: newVisible });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paraviewClient, viewState, paraviewMeshMetadata]);

  // This is a set of all display names in the TreeNodes.  Color bars are added
  // by selecting non-default fields inside the filter tree nodes.  Since we
  // share viewAttrs between simulation tabs and each simulation tab may have a
  // different set of available variables, hide the color maps that cannot be
  // displayed in the current simulation tab.
  // TODO(vis): consider removing this extra logic if we assign an independent
  // viewAttrs to each simulation tab.
  const displayVarsIn = [...colorMapsVisibility.keys()];
  const displayVarsUnordered: ParaviewRpc.DisplayPvVariable[] = [];
  if (viewState) {
    viewState!.data.forEach(
      (arr: ParaviewRpc.ArrayInformation) => {
        // To avoid duplicates only search for Cell-associated arrays.
        if (arr.type === ParaviewRpc.FieldAssociation.CELL) {
          displayVarsIn.forEach((displayVariable: ParaviewRpc.DisplayPvVariable) => {
            if (displayVariable && displayVariable.displayDataName === arr.name &&
              colorMapsVisibility.get(displayVariable)) {
              displayVarsUnordered.push(displayVariable);
            }
          });
        }
      },
    );
    // Now search for anything we may have missed in the CELL data. Aux data such as fields
    // from actuator disks do not appear in cell data.
    viewState!.data.forEach(
      (arr: ParaviewRpc.ArrayInformation) => {
        if (arr.type === ParaviewRpc.FieldAssociation.POINT) {
          displayVarsIn.forEach((displayVariable: ParaviewRpc.DisplayPvVariable) => {
            if (displayVariable && displayVariable.displayDataName === arr.name &&
              colorMapsVisibility.get(displayVariable) &&
              !displayVarsUnordered.includes(displayVariable)) {
              displayVarsUnordered.push(displayVariable);
            }
          });
        }
      },
    );
  }
  // Order the display variables, to keep the same ordering as before whenever possible.
  const displayVars = orderDisplayVars(displayVariables, displayVarsUnordered);
  if (displayVars.changed) {
    // Update display variables if anything changed.
    setDisplayVariables(displayVars.ordered);
  }

  const colorPanels = colorBarsToShow(displayVars.ordered);

  useEffect(() => {
    panelNodes.current = panelNodes.current.slice(0, colorPanels.length);
  }, [colorPanels.length]);

  const displayEmpty = displayVars.ordered.filter(
    (x: ParaviewRpc.DisplayPvVariable) => x.displayDataName !== 'None',
  ).length === 0;

  const showColorSettings = !!(
    viewState?.data.length &&
    viewState.attrs &&
    !displayEmpty &&
    currentView === CurrentView.ANALYSIS
  );

  // Hide the highlighted surfaces.
  const onHideSelected = () => {
    if (viewState) {
      const currentlyHighlighted = viewState.attrs.blockHighlighted;
      const visibility = viewState.attrs.blockVisibility;
      if (visibility) {
        const newVisibility = { ...visibility };
        currentlyHighlighted && Object.entries(currentlyHighlighted).forEach((entry) => {
          if (entry[1]) {
            newVisibility[entry[0]] = false;
          }
        });
        setViewAttrs({ blockVisibility: newVisibility, blockHighlighted: {} });
        setSelection([]);
      }
    }
    setRightMenuSurface('');
    setRightMenuPos(null);
  };

  // Hide the surface/visualization extract that was clicked.
  const onHideSurface = () => {
    if (viewState) {
      const visibility = viewState.attrs.blockVisibility;
      if (visibility && viewState.blocks.includes(rightMenuSurface)) {
        // Handling of single unhighlighted BC selection
        const newVisibility = { ...visibility };
        newVisibility[rightMenuSurface] = false;
        setViewAttrs({ blockVisibility: newVisibility });
      } else {
        // Change node visibility to hide visualization extracts
        changeNodeVisibility(rightMenuSurface, false);
      }
    }
    setRightMenuSurface('');
    setRightMenuPos(null);
  };

  // Hide unselected surfaces.
  const onHideUnselected = () => {
    if (viewState) {
      const currentlyHighlighted = viewState.attrs.blockHighlighted;
      const visibility = viewState.attrs.blockVisibility;
      if (visibility) {
        const newVisibility = { ...visibility };
        // Hide the unselected blocks.
        currentlyHighlighted && Object.keys(visibility).forEach((key) => {
          if (!currentlyHighlighted[key]) {
            newVisibility[key] = false;
          }
        });
        setViewAttrs({ blockVisibility: newVisibility });
      }
    }
    setRightMenuSurface('');
    setRightMenuPos(null);
  };

  // Show everything surfaces.
  const onShowAll = () => {
    if (viewState) {
      const visibility = viewState.attrs.blockVisibility;
      if (visibility) {
        const newVisibility = { ...visibility };
        Object.keys(visibility).forEach((key) => {
          newVisibility[key] = true;
        });
        setViewAttrs({ blockVisibility: newVisibility });
      }
    }
    setRightMenuSurface('');
    setRightMenuPos(null);
  };

  let overlay: ReactNode | null = null;
  switch (overlayMode) {
    case OverlayMode.CENTER_OF_ROTATION:
      overlay = (
        <CenterOfRotationCameraOverlay
          className={classes.rendererChild}
        />
      );
      break;
    case OverlayMode.PROBE:
      overlay = <ProbeOverlay className={classes.rendererChild} />;
      break;
    case OverlayMode.ZOOM_TO_BOX:
      overlay = (
        <ZoomToBoxOverlay
          className={classes.rendererChild}
        />
      );
      break;
    case OverlayMode.BOX_SELECT:
      overlay = (
        <BoxSelectOverlay
          autoActivate
          className={classes.rendererChild}
        />
      );
      break;
    default:
      break;
  }

  const {
    canGroupSelectedNodes,
    canUngroupSelectedNode,
    groupEntities,
    ungroupEntities,
  } = useNodeGrouping();

  /**
   * Get the items to show in the Paraview context menu. We don't calculate this until we're
   * about to render the menu, since the list might be long.
   */
  const getContextMenuItems = () => {
    const contextMenuItems: CommonMenuItem[] = [];
    if (rightMenuSurface) {
      // if the user is hovering a surface when the menu is opened, allow them to select either
      // the surface itself or the volume it bounds.
      const boundingVolume: StaticVolume | undefined = staticVolumes.find(
        (staticVolume) => staticVolume.bounds.has(rightMenuSurface),
      );
      if (treeSubselect.active) {
        contextMenuItems.push(...getSubselectMenuItems(rightMenuSurface, boundingVolume, false));
      } else {
        const hasActiveNodeTable = (activeNodeTable.type !== NodeTableType.NONE);
        const action = hasActiveNodeTable ? SelectionAction.ADD : SelectionAction.OVERWRITE;
        contextMenuItems.push({
          label: 'Select surface',
          onClick: () => {
            modifySelection({
              action,
              modificationIds: [rightMenuSurface],
              nodeTableOverride: activeNodeTable,
            });
            setScrollTo({ node: rightMenuSurface, fast: true });
            setRightMenuPos(null);
          },
        });
        if (boundingVolume) {
          contextMenuItems.push({
            label: 'Select volume',
            onClick: () => {
              modifySelection({
                action,
                modificationIds: [boundingVolume.id],
                nodeTableOverride: activeNodeTable,
              });
              setScrollTo({ node: boundingVolume.id, fast: true });
              setRightMenuPos(null);
            },
          });
        }
        contextMenuItems.push({ separator: true });
      }
      contextMenuItems.push(
        {
          label: 'Hide Surface',
          onClick: onHideSurface,
        },
      );
    }
    if (Object.values(viewState?.attrs?.blockHighlighted || {}).some((value) => value)) {
      contextMenuItems.push(
        {
          label: 'Hide Selection',
          onClick: onHideSelected,
        },
      );
    }
    contextMenuItems.push(
      {
        label: 'Hide Others',
        onClick: onHideUnselected,
      },
      {
        label: 'Show All',
        onClick: onShowAll,
      },
      { separator: true },
    );
    const groupingSection: CommonMenuItem[] = [];
    if (canGroupSelectedNodes) {
      groupingSection.push({
        label: 'Group Selection',
        keyboardShortcut: getHelpText('group'),
        onClick: () => groupEntities(selectedNodeIds),
      });
    }
    if (selectedNode && canUngroupSelectedNode) {
      groupingSection.push({
        label: 'Ungroup Selection',
        keyboardShortcut: getHelpText('ungroup'),
        onClick: () => ungroupEntities(selectedNode?.id),
      });
    }
    if (groupingSection.length) {
      groupingSection.push({ separator: true });
      contextMenuItems.push(...groupingSection);
    }
    contextMenuItems.push(
      {
        label: lightBackground ? 'Dark Background' : 'Light Background',
        onClick: toggleBackground,
      },
    );
    return contextMenuItems;
  };

  const contextMenu = (
    rightMenuPos && (
      <CommonMenu
        anchorEl={rendererRootNode.current}
        menuItems={getContextMenuItems()}
        onClose={() => setRightMenuPos(null)}
        open={!!rightMenuPos}
        position="below-right"
        positionTransform={rightMenuPos}
      />
    )
  );

  // Recoil callback used to start the grid motion preview animation.
  const gridMotionAnimation = () => {
    // Hide the motion animation settings panel.
    setShowMotionAnimationSettings(false);

    // Translate UI motion state to param state. Note that we need the current param specification
    // since the translation depends on the boundary conditions specification and the flow
    // behavior.
    assert(!!simParam, 'Motion preview requires simulation param');

    // If no motion, or motion warnings exist, the translator returns early.  Attempting a motion
    // animation with an empty motion data list fails and can corrupt state.  Checks upstream should
    // prevent this code path, but we add it here as a fail safe
    if (!simParam.motionData.length) {
      return;
    }

    // In order to reduce the size of the string to be sent to the visualizer, strip the initial
    // parameters as much as possible. The transformation from UI to param state needed to create
    // coordinate axes should not depend on the other param settings.
    const newParam = new simulationpb.SimulationParam({
      motionData: simParam.motionData,
      physics: [
        new simulationpb.Physics({ params: { case: 'fluid', value: new simulationpb.Fluid() } }),
      ],
    });

    const isMotionSelected = selectedNode?.type === NodeType.MOTION_FRAME;
    const frame = isMotionSelected ? findFrameById(simParam, selectedNode.id) : undefined;

    // Convert the param to string. Do not use the stable api conversion since the
    // resulting string representation of the proto cannot be read by cpp code.
    protoToJson(newParam, false).then((param: string) => {
      // Start the animation when the conversion ended.
      paraviewRenderer.handleMotionAnimation(
        { ...gridMotionParam, fvmMotionData: param },
        setStatusCardProps,
        (arg: boolean) => {
          setMotionAnimationPlaying(arg);
        },
        () => frame && coordinateVisualizer.show(frame.frameId),
      );
    }).catch((err) => {
      addPvRpcError('Could not convert to JSON', err);
    });
  };

  // The color panels are shown inside a container that is above the visualiser
  // but in order to enable the visualiser mouse actions, the mouse mouse actions
  // for the colorPanelsContainer are disabled (via pointerEvents: none).
  // We want to keep track of where the mouse is, so that we can reenable the
  // mouse events in case the user wants to scroll the color panels.
  // The function is optimized and debounced so that it doesn't run on every
  // mouseMove but only when the mouse move stops and 150ms are passed.
  const onMouseMoveDebounced = useMemo(() => debounce((event: React.MouseEvent<HTMLElement>) => {
    if (!rendererRootNode.current?.clientWidth) {
      return;
    }

    const isHoveringScrollingArea = event.clientX > rendererRootNode.current.clientWidth -
      SCROLLBAR_WIDTH;
    const isHoveringColorPanel = panelNodes.current.some(
      (colorPanel) => colorPanel?.contains(event.target as HTMLElement),
    );

    if (isHoveringScrollingArea || isHoveringColorPanel) {
      setPointerEventsDisabled(false);
    } else {
      setPointerEventsDisabled(true);
    }
  }, 150), [panelNodes, setPointerEventsDisabled]);

  // Only include the mouse listener if it's needed (if there are color panels).
  const rendererExtraProps: { [key: string]: any } = {};
  if (showColorSettings) {
    rendererExtraProps.onMouseMove = (event: React.MouseEvent<HTMLElement>) => {
      onMouseMoveDebounced(event);
    };
  }

  const renderMotionPanel = !!(showMotionAnimationSettings && simParam && viewState);

  return (
    <div className={classes.root}>
      <div
        className={classes.rendererRoot}
        onKeyDown={() => { }}
        ref={rendererRootNode}
        role="presentation"
        {...rendererExtraProps}>
        <FloatingToolbar />
        <div
          className={classes.rendererChild}
          data-locator="paraviewCanvasPlaceholder"
          ref={pvRef}
          style={{ zIndex: 0, opacity: showTipsOverlay ? 0 : 1 }}
        />
        <LeftOverlayCards />
        <RightOverlayCards />
        <FloatingPropertiesPanel hasMotionPanel={renderMotionPanel} />
        {showTipsOverlay && <TipsOverlay />}
        {!showTipsOverlay && showStatusOverlay && (
          <StatusOverlay
            clientState={paraviewClientState}
            syncing={syncing}
          />
        )}
        {overlay}
        {showColorSettings && (
          <div
            className={cx(classes.colorPanelsContainer, {
              noPointerEvents: pointerEventsDisabled,
            })}
            ref={panelContainer}>
            {colorPanels.map(({ colorMap, displayVar, dragId, label }, i) => (
              // eslint-disable-next-line jsx-a11y/interactive-supports-focus
              <div
                className={cx(
                  classes.colorPanel,
                  { dragging: currentDragId === dragId },
                )}
                key={label}
                onKeyUp={() => { }}
                onMouseDown={(event) => {
                  if (panelContainer.current && panelNodes.current[i]) {
                    initDrag(
                      dragId,
                      event,
                      panelNodes.current[i] as HTMLElement,
                      panelContainer.current as HTMLElement,
                    );
                  }
                }}
                ref={(el) => {
                  panelNodes.current[i] = el;
                }}
                role="button"
                style={{
                  ...dragPositionToStyle(draggedPositions[dragId]),
                  right: workflowFlag ? OVERLAY_CARD_WIDTH : 0,
                }}>
                <ColorBarPanel
                  bins={colorMap.discretize ? colorMap.bins : MAX_BINS}
                  colorMapName={colorMap.presetName}
                  hidden={!colorMap.visible}
                  isVertical={getVertical(label)}
                  label={label}
                  max={colorMap.range[1]}
                  min={colorMap.range[0]}
                  numTicks={colorMap.discretize &&
                    colorMap.bins < MAX_TICKS ?
                    colorMap.bins + 1 : MAX_TICKS}
                  onClick={() => {
                    if (!currentDragId) {
                      initColorDialogState(displayVar);
                    }
                  }}
                  selected={currentDragId === dragId}
                />
              </div>
            ))}
          </div>
        )}
        {colorDialogState && (
          <div
            className={classes.colorDialog}
            data-locator="colorDialog"
            ref={dialogNode}
            style={dragPositionToStyle(draggedPositions[EDIT_COLOR_DRAG_ID])}>
            <EditColorsBox
              cancelEdit={cancelEdit}
              changeOrientation={changeOrientation}
              currentCmap={colorDialogState.editedCmap}
              displayVariable={colorDialogState.displayVariable}
              isDragging={currentDragId === EDIT_COLOR_DRAG_ID}
              isVertical={getVertical(colorDialogState.name)}
              onComplete={closeEditState}
              onMouseDown={(event) => {
                if (rendererRootNode.current && dialogNode.current) {
                  initDrag(EDIT_COLOR_DRAG_ID, event, dialogNode.current, rendererRootNode.current);
                }
              }}
              setCurrentCmap={updateCmap}
            />
          </div>
        )}
        {renderMotionPanel && (
          <div
            className={classes.motionDialog}
            data-locator="motionAnimationSettings">
            <MotionAnimationSettings
              cancelEdit={() => setShowMotionAnimationSettings(false)}
              onClose={gridMotionAnimation}
            />
          </div>
        )}
        { /* Camera buttons are disabled in probe mode. We attach text to a
        specific world coordinate and we cannot react to world coord <-> view
        coord changes. */}
        <CameraControlPanel
          disabled={!viewState || overlayMode === OverlayMode.PROBE}
        />
        {contextMenu}
      </div>
    </div>
  );
};

export default ParaView;
