import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { getLocalStorageData } from './lib/browserStorage';
import { localStorageEffect } from './lib/persist';

/**
 * Whether to show the UI for the new workflow-based project view.
 * It's persisted in localstorage.
 */
export const workflowFlagState = atom({
  key: 'workflowFlag',
  default: getLocalStorageData('workflowFlag', true),
  effects: [
    localStorageEffect('workflowFlag'),
  ],
});

export const useWorkflowFlagValue = () => useRecoilValue(workflowFlagState);

export const useSetWorkflowFlag = () => useSetRecoilState(workflowFlagState);
