// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, ReactNode } from 'react';

import cx from 'classnames';

import { FormControlSize } from '../../lib/componentTypes/form';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'inline-flex',
      borderRadius: '4px',
      padding: '0.15em 0.65em',
      backgroundColor: 'var(--bg-color)',
      color: 'var(--fg-color)',
      whiteSpace: 'nowrap',
      fontSize: '13px',
      fontWeight: 600,
      transition: 'color 250ms, background-color 250ms, border-radius 250ms',
      '&.small': {
        borderRadius: '3px',
        fontSize: '11px',
      },
    },
  }),
  { name: 'VisualTag' },
);

export const DEFAULT_BACKGROUND_COLOR = 'var(--color-high-emphasis-text)';
export const DEFAULT_FOREGROUND_COLOR = 'var(--color-surface-dark1)';

export interface TagProps {
  backgroundColor?: string;
  foregroundColor?: string;
  size?: FormControlSize;
  children: ReactNode;
}

export const Tag = (props: TagProps) => {
  const {
    backgroundColor = DEFAULT_BACKGROUND_COLOR,
    foregroundColor = DEFAULT_FOREGROUND_COLOR,
    children,
    size = 'medium',
  } = props;

  const classes = useStyles();

  return (
    <div
      className={cx(classes.root, { small: size === 'small' })}
      style={{
        '--bg-color': backgroundColor,
        '--fg-color': foregroundColor,
      } as CSSProperties}>
      {children}
    </div>
  );
};
