import React, { useState } from 'react';

import { getPhysicsId, getPhysicsName } from '../../../lib/physicsUtils';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { ActionButton } from '../../Button/ActionButton';
import LabeledInput from '../../Form/LabeledInput';
import Tooltip from '../../Tooltip';
import { useProjectContext } from '../../context/ProjectContext';
import { AdvancedFluidPhysicsDialog } from '../../dialog/AdvancedFluidPhysics';
import { AdvancedHeatPhysicsDialog } from '../../dialog/AdvancedHeatPhysics';
import NodeLink from '../NodeLink';
import PropertiesSection from '../PropertiesSection';

export const AdvancedSolverSettingsPropPanel = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  // the id of the currently opened advanced settings dialog
  const [openSettings, setOpenSettings] = useState<string | null>(null);
  const allPhysics = simParam.physics;

  return (
    <>
      <PropertiesSection>
        {allPhysics.map((physics) => {
          const physicsId = getPhysicsId(physics);
          const physicsName = getPhysicsName(physics, simParam);

          return (
            <React.Fragment key={physicsId}>
              <LabeledInput
                key={physicsId}
                label={(
                  <NodeLink
                    help={`Navigate to the Physics tab to view ${physicsName}`}
                    nodeIds={[physicsId]}
                    text={physicsName}
                  />
                )}>
                <Tooltip title={readOnly ? 'View advanced settings' : 'Edit advanced settings'}>
                  <span>
                    <ActionButton
                      asBlock
                      kind="minimal"
                      onClick={() => setOpenSettings(physicsId)}
                      size="small">
                      {readOnly ? 'Inspect' : 'Customize'}
                    </ActionButton>
                  </span>
                </Tooltip>
              </LabeledInput>
              {physics.params.case === 'fluid' ? (
                <AdvancedFluidPhysicsDialog
                  onClose={() => setOpenSettings(null)}
                  open={openSettings === physicsId}
                  physicsId={physicsId}
                />
              ) : (
                <AdvancedHeatPhysicsDialog
                  onClose={() => setOpenSettings(null)}
                  open={openSettings === physicsId}
                  physicsId={physicsId}
                />
              )}
            </React.Fragment>
          );
        })}

      </PropertiesSection>
    </>
  );
};
