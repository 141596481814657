// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback } from 'react';

import * as simulationpb from '../../proto/client/simulation_pb';
import { useSimulationParam } from '../../state/external/project/simulation/param';

import { useProjectOperations } from './useProject';

export type ParamOperation<T> = (param: simulationpb.SimulationParam) => T;

/**
 * Expose a simulation param object as well as functions for saving param changes (either
 * synchronously or asynchronously), centralizing the boilerplate around cloning the params and
 * calling onParamUpdate/asyncOnParamUpdate.  The callback function that updates the params may
 * itself return a value for subsequent use in a component.
 *
 * E.g.
 *
 * const newId = saveParam((newParam) => {
 *   // create new foo and add to newParam
 *   const newFoo = new Foo();
 *   newParam.addFooList(newFoo);
 *   return newFoo.getId();
 * });
 * doSomething([newId]);
 */
export const useWorkflowConfig = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
) => {
  const {
    onParamUpdate,
    asyncOnParamUpdate,
  } = useProjectOperations(projectId, workflowId, jobId, readOnly);

  const simParam = useSimulationParam(projectId, workflowId, jobId);

  const saveParam = useCallback(<T>(modifyParam: ParamOperation<T>) => {
    const newParam = simParam.clone();
    const result: T = modifyParam(newParam);
    onParamUpdate(newParam);
    return result;
  }, [simParam, onParamUpdate]);

  // The first callback is given a `newParam` object, where calling code can update the data before
  // it's persisted.
  const saveParamAsync = useCallback(
    async <T>(modifyParam: ParamOperation<T>) => {
      const newParam = simParam.clone();
      const result: T = modifyParam(newParam);
      await asyncOnParamUpdate(newParam);
      return result;
    },
    [simParam, asyncOnParamUpdate],
  );

  return { simParam, saveParam, saveParamAsync };
};
