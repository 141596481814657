// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const AtomIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="atom"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M11.273,23.122
           C10.712,22.732 10.2,22.275 9.749,21.762
           C9.595,21.59 9.387,21.346 9.292,21.222
           C9.197,21.097 9.097,20.972 9.002,20.842
           C8.829,20.61 8.616,20.301 8.497,20.105
           C8.378,19.91 8.26,19.713 8.147,19.512
           C7.841,18.986 7.562,18.445 7.311,17.891
           C7.857,18.098 8.45,18.282 9.043,18.449
           L9.143,18.645
           C9.226,18.793 9.28,18.847 9.398,19.072
           C9.517,19.298 9.636,19.482 9.754,19.666
           C10.003,20.037 10.273,20.394 10.561,20.735
           C10.721,20.919 10.875,21.085 11.035,21.245
           C11.354,21.555 11.701,21.833 12.073,22.077
           C12.61,22.472 13.252,22.699 13.917,22.73
           C14.077,22.73 14.236,22.712 14.392,22.676
           C14.843,22.567 15.254,22.333 15.578,22
           C15.998,21.567 16.333,21.06 16.568,20.504
           C16.736,20.133 16.874,19.75 16.983,19.357
           C17.042,19.143 17.095,18.918 17.149,18.687
           C17.238,18.265 17.315,17.819 17.368,17.351
           C17.4,17.129 17.42,16.904 17.427,16.68
           C17.424,16.625 17.424,16.569 17.427,16.514
           C17.457,16.181 17.457,15.849 17.457,15.486
           C17.457,14.874 17.422,14.24 17.356,13.586
           C17.327,13.278 17.291,12.963 17.244,12.648
           C17.167,12.084 17.078,11.538 16.947,10.944
           C16.788,10.215 16.59,9.496 16.354,8.788
           C16.266,8.509 16.17,8.23 16.069,7.963
           C15.862,7.398 15.631,6.864 15.387,6.359
           C15.257,6.074 15.121,5.807 14.979,5.546
           L14.872,5.35
           C14.794,5.207 14.741,5.112 14.623,4.922
           C14.505,4.732 14.386,4.542 14.267,4.364
           C14.018,3.99 13.746,3.631 13.455,3.29
           C13.301,3.111 13.146,2.939 12.986,2.784
           C12.688,2.48 12.363,2.204 12.014,1.959
           C11.478,1.563 10.836,1.336 10.17,1.306
           C10.01,1.307 9.851,1.325 9.696,1.36
           C9.245,1.47 8.834,1.704 8.509,2.037
           C8.088,2.469 7.75,2.977 7.513,3.533
           C7.351,3.905 7.214,4.289 7.104,4.68
           C7.045,4.899 6.985,5.125 6.938,5.356
           C6.841,5.797 6.768,6.243 6.719,6.692
           C6.686,6.914 6.667,7.138 6.659,7.363
           L6.659,7.529
           C6.191,8.022 5.746,8.527 5.331,9.049
           L5.331,8.55
           C5.331,8.23 5.319,7.826 5.331,7.594
           C5.343,7.363 5.378,7.131 5.378,6.906
           C5.42,6.431 5.485,5.973 5.562,5.535
           C5.604,5.302 5.651,5.066 5.704,4.846
           C5.758,4.626 5.817,4.406 5.876,4.199
           C6.181,3.039 6.772,1.975 7.596,1.105
           C8.1,0.599 8.734,0.245 9.429,0.083
           C9.676,0.029 9.929,0.001 10.182,0
           C11.11,0.029 12.008,0.335 12.762,0.879
           C13.321,1.273 13.833,1.731 14.286,2.244
           C14.44,2.411 14.593,2.595 14.742,2.779
           L15.027,3.159
           C15.199,3.396 15.418,3.699 15.537,3.895
           C15.655,4.091 15.768,4.287 15.887,4.489
           C16.183,5 16.461,5.54 16.722,6.11
           C16.848,6.365 16.966,6.674 17.084,6.959
           C17.294,7.505 17.492,8.069 17.677,8.651
           C17.766,8.925 17.831,9.144 17.932,9.518
           C18.033,9.892 18.128,10.278 18.211,10.67
           C18.251,10.794 18.277,10.921 18.289,11.05
           C18.354,11.377 18.413,11.703 18.466,12.024
           C18.573,12.689 18.65,13.342 18.692,13.983
           C18.692,14.328 18.692,14.666 18.704,14.951
           L18.704,15.45
           C18.704,15.777 18.716,16.174 18.704,16.406
           C18.692,16.638 18.657,16.869 18.657,17.095
           C18.615,17.57 18.55,18.027 18.472,18.467
           C18.425,18.698 18.372,18.935 18.324,19.155
           C18.277,19.374 18.217,19.595 18.158,19.808
           C17.851,20.966 17.26,22.03 16.438,22.902
           C15.934,23.408 15.297,23.76 14.6,23.917
           C14.353,23.971 14.1,23.999 13.847,24
           C12.921,23.971 12.024,23.665 11.273,23.122Z
           M4.756,21.554
           C4.232,21.379 3.757,21.085 3.367,20.694
           C2.977,20.304 2.683,19.828 2.508,19.304
           C2.324,18.761 2.234,18.191 2.241,17.618
           C2.253,16.88 2.362,16.147 2.567,15.438
           C2.923,15.711 3.303,15.973 3.712,16.228
           C3.609,16.684 3.553,17.15 3.546,17.618
           C3.538,18.043 3.604,18.467 3.742,18.87
           C3.858,19.203 4.049,19.504 4.3,19.751
           C4.551,19.998 4.855,20.184 5.189,20.295
           C5.592,20.428 6.015,20.492 6.44,20.485
           C6.909,20.481 7.376,20.425 7.833,20.319
           C8.017,20.651 8.254,20.972 8.474,21.305
           L8.622,21.5
           C7.912,21.703 7.178,21.811 6.44,21.821
           L6.381,21.821
           C5.828,21.821 5.279,21.731 4.756,21.554Z
           M10.36,19.405
           L10.716,19.227
           L11.143,18.989
           C11.807,19.12 12.471,19.221 13.129,19.292
           C12.547,19.689 11.943,20.054 11.32,20.384
           L11.179,20.45
           C10.884,20.12 10.61,19.77 10.36,19.405Z
           M15.478,18.752
           L14.985,18.752
           L13.977,18.698
           C13.337,18.651 12.679,18.579 12.014,18.473
           L11.042,18.294
           L10.662,18.217
           C10.241,18.134 9.856,18.033 9.476,17.932
           L8.616,17.683
           C8.035,17.505 7.471,17.303 6.937,17.089
           C6.676,16.97 6.362,16.851 6.083,16.727
           C5.597,16.501 5.129,16.27 4.69,16.02
           L4.459,15.884
           L3.865,15.527
           C3.496,15.281 3.14,15.015 2.798,14.732
           C2.609,14.583 2.431,14.429 2.258,14.274
           C1.743,13.825 1.287,13.313 0.901,12.749
           C0.347,12.003 0.033,11.106 -0,10.177
           C-0.001,9.924 0.025,9.671 0.077,9.423
           C0.239,8.728 0.592,8.092 1.097,7.589
           C1.646,7.041 2.294,6.604 3.006,6.3
           C3.602,6.047 4.219,5.848 4.851,5.706
           C4.773,6.152 4.719,6.621 4.684,7.102
           L4.578,7.131
           C3.634,7.372 2.765,7.846 2.051,8.509
           C1.718,8.833 1.482,9.245 1.37,9.696
           C1.336,9.852 1.32,10.012 1.323,10.171
           C1.346,10.839 1.574,11.484 1.975,12.018
           C2.216,12.368 2.49,12.694 2.793,12.992
           C2.953,13.157 3.122,13.313 3.298,13.461
           C3.5,13.633 3.713,13.805 3.938,13.971
           C4.075,14.081 4.22,14.18 4.371,14.268
           C4.575,14.376 4.773,14.495 4.964,14.624
           C5.153,14.759 5.351,14.88 5.557,14.986
           C5.812,15.122 6.085,15.265 6.364,15.396
           C6.887,15.652 7.424,15.88 7.971,16.078
           L8.784,16.357
           C9.493,16.593 10.213,16.791 10.943,16.951
           C11.518,17.076 12.052,17.171 12.645,17.248
           C12.954,17.307 13.267,17.345 13.582,17.361
           C14.234,17.426 14.869,17.462 15.497,17.462
           C15.841,17.462 16.173,17.432 16.499,17.432
           L16.671,17.432
           C16.611,17.895 16.535,18.341 16.44,18.75
           L15.478,18.752Z
           M19.332,16.941
           L19.439,16.941
           C20.382,16.696 21.25,16.22 21.965,15.557
           C22.197,15.343 22.381,15.083 22.507,14.794
           C22.632,14.504 22.696,14.192 22.694,13.877
           C22.666,13.21 22.438,12.567 22.042,12.03
           C21.799,11.681 21.526,11.355 21.223,11.056
           C21.431,10.623 21.609,10.195 21.763,9.774
           C22.275,10.226 22.731,10.738 23.121,11.299
           C23.672,12.056 23.979,12.963 23.999,13.9
           C24.001,14.154 23.974,14.407 23.916,14.654
           C23.761,15.352 23.409,15.99 22.902,16.495
           C22.351,17.022 21.708,17.442 21.005,17.736
           C20.412,17.992 19.797,18.191 19.166,18.329
           C19.238,17.885 19.291,17.416 19.332,16.941Z
           M15.466,16.798
           C15.074,16.798 14.689,16.78 14.262,16.774
           C14.683,16.388 15.086,16.002 15.519,15.587
           C15.936,15.186 16.332,14.764 16.705,14.322
           C16.739,14.717 16.753,15.113 16.747,15.51
           L16.747,16.252
           L16.48,16.531
           L16.201,16.798
           L15.466,16.798Z
           M6.09,14.53
           C5.989,14.156 5.894,13.77 5.805,13.378
           C5.782,13.253 5.758,13.129 5.728,12.998
           C6.042,12.464 6.398,11.929 6.784,11.401
           C6.861,11.959 6.956,12.523 7.074,13.099
           C7.246,13.847 7.441,14.566 7.667,15.261
           C7.116,15.035 6.588,14.791 6.09,14.53Z
           M4.305,13.408
           C4.052,13.222 3.809,13.024 3.576,12.814
           L3.647,12.671
           C3.976,12.058 4.338,11.463 4.732,10.89
           C4.928,10.617 5.118,10.337 5.326,10.065
           C5.711,9.554 6.126,9.043 6.577,8.544
           C6.79,8.303 7.026,8.048 7.283,7.779
           C7.371,7.689 7.461,7.6 7.55,7.505
           C7.639,7.409 7.734,7.327 7.823,7.238
           C8.066,7 8.309,6.775 8.552,6.555
           C9.05,6.109 9.56,5.694 10.07,5.308
           C10.349,5.095 10.622,4.905 10.901,4.715
           C11.472,4.319 12.066,3.956 12.68,3.628
           L12.821,3.557
           C13.118,3.889 13.393,4.24 13.645,4.608
           C13.52,4.667 13.402,4.721 13.283,4.786
           L12.862,5.017
           C12.601,5.178 12.293,5.344 12.008,5.528
           C11.487,5.867 10.959,6.24 10.437,6.65
           C10.194,6.834 9.951,7.036 9.707,7.244
           C9.28,7.629 8.871,8.015 8.444,8.431
           C8.017,8.847 7.626,9.268 7.258,9.695
           C7.057,9.939 6.861,10.183 6.665,10.426
           C6.256,10.948 5.882,11.477 5.55,11.993
           C5.366,12.26 5.194,12.569 5.034,12.855
           C4.957,12.991 4.868,13.157 4.803,13.276
           C4.738,13.395 4.684,13.513 4.625,13.626
           C4.518,13.556 4.406,13.485 4.305,13.408Z
           M18.971,11.151
           C19.053,11.009 19.149,10.842 19.208,10.724
           C19.267,10.605 19.279,10.563 19.38,10.373
           C19.481,10.183 19.575,9.981 19.664,9.78
           C19.842,9.369 19.996,8.949 20.127,8.521
           C20.199,8.295 20.258,8.069 20.305,7.85
           C20.408,7.394 20.464,6.928 20.472,6.46
           C20.468,6.042 20.396,5.626 20.258,5.231
           C20.145,4.894 19.956,4.588 19.706,4.335
           C19.45,4.085 19.142,3.894 18.805,3.776
           C18.403,3.644 17.982,3.579 17.559,3.586
           C17.09,3.59 16.623,3.646 16.165,3.753
           C15.97,3.422 15.756,3.103 15.525,2.797
           L15.377,2.601
           C16.087,2.398 16.821,2.29 17.559,2.281
           C18.132,2.274 18.701,2.364 19.243,2.548
           C19.762,2.729 20.234,3.023 20.625,3.409
           C21.014,3.8 21.308,4.275 21.485,4.798
           C21.671,5.341 21.763,5.911 21.758,6.485
           C21.743,7.222 21.633,7.954 21.432,8.664
           C21.372,8.889 21.301,9.115 21.224,9.341
           C21.082,9.756 20.91,10.184 20.714,10.611
           C20.619,10.79 20.525,10.997 20.418,11.205
           L20.346,11.347
           C20.021,11.964 19.663,12.563 19.272,13.14
           C19.208,12.481 19.1,11.816 18.971,11.151Z
           M18.49,9.067
           C18.294,8.473 18.104,7.915 17.897,7.321
           C18.39,7.547 18.852,7.779 19.297,8.028
           L19.529,8.164
           C19.407,8.593 19.259,9.014 19.084,9.423
           C18.882,9.305 18.691,9.18 18.49,9.067Z
           M13.076,7.084
           C12.501,6.959 11.937,6.864 11.38,6.787
           C11.895,6.407 12.43,6.055 12.981,5.73
           L13.355,5.807
           C13.747,5.914 14.125,6.008 14.511,6.098
           C14.779,6.612 15.021,7.139 15.234,7.678
           C14.526,7.441 13.805,7.243 13.076,7.084Z
           M7.347,6.633
           C7.399,6.189 7.477,5.749 7.578,5.314
           C7.893,5.285 8.213,5.285 8.539,5.285
           L9.031,5.308
           C8.516,5.724 8.005,6.169 7.513,6.633
           L7.347,6.633Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M13.999,12
           C13.999,13.105 13.104,14 12,14
           C10.895,14 10,13.105 10,12
           C10,10.895 10.895,10 12,10
           C13.104,10 13.999,10.895 13.999,12Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
