// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { EntityGroupMap } from '../../../lib/entityGroupMap';
import { copiedName } from '../../../lib/name';
import { ProbePoint, findParticleGroupById, getOrCreateProbePointsTable, getProbePoints } from '../../../lib/particleGroupUtils';
import { newNodeId } from '../../../lib/projectDataUtils';
import { ProbePointsTableModel } from '../../../lib/rectilinearTable/model';
import { useSetEntityGroupMap } from '../../../recoil/entityGroupState';
import { useSetLcvisVisibilityMap } from '../../../recoil/lcvis/lcvisVisibilityMap';
import { initializeNewNode, useSetNewNodes } from '../../../recoil/nodeSession';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useSimulationConfig } from '../useSimulationConfig';

type SimAnnotationType = 'point' | 'disk' | 'plane';

/**
 * @returns a callback to duplicate the simulation annotation with the given id.
 * The simulation annotation is a monitor point, actuator disk, or monitor plane.
 * */
export const useCopySimAnnotation = () => {
  // == Contexts
  const { setSelection, setScrollTo } = useSelectionContext();
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const setNewNodes = useSetNewNodes();

  // == Hooks
  const { saveParamAsync } = useSimulationConfig();
  const setLCVisVisibility = useSetLcvisVisibilityMap({ projectId, workflowId, jobId });
  const setEntityGroupMap = useSetEntityGroupMap(projectId, workflowId, jobId);

  const duplicateAnnotation = useCallback(async (id: string, type: SimAnnotationType) => {
    const nodeId = await saveParamAsync(
      (newParam) => {
        switch (type) {
          case 'plane': {
            const planes = newParam.monitorPlane;
            const planeToDuplicate = planes.find((plane) => plane.monitorPlaneId === id);
            if (!planeToDuplicate) {
              return '';
            }

            const newPlane = planeToDuplicate.clone();
            newPlane.monitorPlaneId = newNodeId();
            newPlane.monitorPlaneName = copiedName(planeToDuplicate.monitorPlaneName);

            newParam.monitorPlane.push(newPlane);
            return newPlane.monitorPlaneId;
          }
          case 'disk': {
            const diskToDuplicate = findParticleGroupById(newParam, id);
            if (!diskToDuplicate) {
              return '';
            }

            const newDisk = diskToDuplicate.clone();
            const newName = copiedName(diskToDuplicate.particleGroupName);
            newDisk.particleGroupId = newNodeId();
            newDisk.particleGroupName = newName;
            newParam.particleGroup.push(newDisk);

            // Disks may be part of a group. We should put the duplicate disk into the same group.
            setEntityGroupMap((prev) => {
              const newMap = new EntityGroupMap(prev);
              const nodeToDuplicate = prev.get(id);
              if (!nodeToDuplicate) {
                return prev;
              }
              const { parentId, level, maxLevel, entityType } = nodeToDuplicate;
              newMap.add({
                id: newDisk.particleGroupId,
                name: newName,
                entityType,
                parentId,
                level,
                maxLevel,
                children: new Set(),
              });
              return newMap;
            });
            return newDisk.particleGroupId;
          }
          case 'point': {
            const pointToDuplicate = getProbePoints(newParam).find((point) => point.id === id);
            if (!pointToDuplicate) {
              return '';
            }
            const table = getOrCreateProbePointsTable(newParam);
            const tableModel = new ProbePointsTableModel(table);
            const { x, y, z } = pointToDuplicate;
            const newPoint = new ProbePoint(
              newNodeId(),
              copiedName(pointToDuplicate.name),
              x,
              y,
              z,
            );
            tableModel.addRecord(newPoint.id, newPoint.name, newPoint.x, newPoint.y, newPoint.z);
            return newPoint.id;
          }
          default: // none
            return '';
        }
      },
    );
    if (!nodeId) {
      return;
    }

    setLCVisVisibility((prev) => {
      const newMap = new Map(prev);
      newMap.set(nodeId, true);
      return newMap;
    });
    setNewNodes((oldValue) => [...oldValue, initializeNewNode(nodeId)]);
    setSelection([nodeId]);
    setScrollTo({ node: nodeId });
  }, [
    saveParamAsync,
    setNewNodes,
    setScrollTo,
    setSelection,
    setLCVisVisibility,
    setEntityGroupMap,
  ]);

  return duplicateAnnotation;
};
