// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { geometryListState_DEPRECATED, useGeometryList } from '../../recoil/geometry/geometryListState';
import { enabledExperimentsSelector_DEPRECATED, useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { projectMetadataMapState_DEPRECATED, useProjectMetadataValue } from '../../recoil/useProjectMetadata';
import { controlPanelModeState_DEPRECATED, useControlPanelModeValue } from '../../recoil/useProjectPage';
import { routeParamsAtom_DEPRECATED, useRouteParamsValue } from '../../recoil/useRouteParams';
import { useViewState, viewStateAtomFamily_DEPRECATED } from '../../recoil/useViewState';
import { currentViewAtom_DEPRECATED, useCurrentView } from '../../state/internal/global/currentView';

/**
 * This is a helper component that we can use temporarily to sync between a particular Recoil state
 * and its alternative Jotai state. This is needed so that some states can continue to use the old
 * Recoil state while some new states (migrated to Jotai) can start using the Jotai alternative.
 * When we fully migrate to Jotai, we can remove this helper function and the old Recoil states.
 */
interface RecoilJotaiSyncProps {
  projectId: string,
}

export const RecoilJotaiSync = (props: RecoilJotaiSyncProps) => {
  const { projectId } = props;

  const currentView = useCurrentView();
  const setCurrentViewDeprecated = useSetRecoilState(currentViewAtom_DEPRECATED);

  const controlPanelMode = useControlPanelModeValue();
  const setControlPanelModeDeprecated = useSetRecoilState(controlPanelModeState_DEPRECATED);

  const routeParams = useRouteParamsValue();
  const setRouteParamsDeprecated = useSetRecoilState(routeParamsAtom_DEPRECATED);

  const [viewState] = useViewState(projectId);
  const setViewStateDeprecated = useSetRecoilState(viewStateAtomFamily_DEPRECATED(projectId));

  const projectMetadata = useProjectMetadataValue(projectId);
  const setProjectMetadataDeprecated = useSetRecoilState(
    projectMetadataMapState_DEPRECATED(projectId),
  );
  const geometryList = useGeometryList(projectId);
  const setGeometryListDeprecated = useSetRecoilState(geometryListState_DEPRECATED(projectId));

  const enabledExpriments = useEnabledExperiments();
  const setEnabledExperimentsDeprecated = useSetRecoilState(enabledExperimentsSelector_DEPRECATED);

  // Sync the currentView state
  useEffect(() => {
    setCurrentViewDeprecated(currentView);
  }, [currentView, setCurrentViewDeprecated]);

  useEffect(() => {
    setControlPanelModeDeprecated(controlPanelMode);
  }, [controlPanelMode, setControlPanelModeDeprecated]);

  useEffect(() => {
    setRouteParamsDeprecated(routeParams);
  }, [routeParams, setRouteParamsDeprecated]);

  useEffect(() => {
    setViewStateDeprecated(viewState);
  }, [viewState, setViewStateDeprecated]);

  useEffect(() => {
    setProjectMetadataDeprecated(projectMetadata);
  }, [projectMetadata, setProjectMetadataDeprecated]);

  useEffect(() => {
    setGeometryListDeprecated(geometryList);
  }, [geometryList, setGeometryListDeprecated]);

  useEffect(() => {
    setEnabledExperimentsDeprecated(enabledExpriments);
  }, [enabledExpriments, setEnabledExperimentsDeprecated]);

  return null;
};
