// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 18;
const baseHeight = 18;
export const ArrowUpLeftIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="arrowUpLeft"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M17.565,15.435
           L5.115,3
           L12,3
           C12.825,3 13.5,2.325 13.5,1.5
           C13.5,0.675 12.825,0 12,0
           L1.5,0
           C0.675,0 0,0.675 0,1.5
           L0,12
           C0,12.825 0.675,13.5 1.5,13.5
           C2.325,13.5 3,12.825 3,12
           L3,5.115
           L15.435,17.55
           C15.705,17.835 16.08,18 16.5,18
           C17.325,18 18,17.325 18,16.5
           C18,16.08 17.835,15.705 17.565,15.435Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
