// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { colors } from '../../lib/designSystem';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as geometry from '../../proto/geometry/geometry_pb';
import { useGeometryBusyMessageSelector, useGeometryBusyState, useIsGeoServerCreatingFeature } from '../../recoil/geometry/geometryServerStatus';
import { useGeometryState } from '../../recoil/geometry/geometryState';
import { analytics } from '../../services/analytics';
import { SummaryPanel } from '../Panel/SummaryPanel';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { LoadingEllipsis } from '../visual/LoadingEllipsis';
import { ProgressMessage } from '../visual/ProgressMessage';

const useStyles = makeStyles(
  () => createStyles({
    stepProgressMessages: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      padding: '12px',
    },
  }),
  { name: 'GeometryProgress' },
);

const heading = 'Geometry Preparation';
const summary = `This may take a few minutes. You can modify the geometry once import is
  completed. You can keep this project open or close it for later - we will continue to process your
  file in the background.`;

export const GeometryProgress = () => {
  const classes = useStyles();
  const { projectId, geometryId } = useProjectContext();

  const geometryState = useGeometryState(projectId, geometryId);
  const geometryBusyMessage = useGeometryBusyMessageSelector(geometryId);
  const [geometryBusyState] = useGeometryBusyState(geometryId);
  const isGeoServerCreatingFeature = useIsGeoServerCreatingFeature(geometryId);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [prevBusyMessage, setPrevBusyMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isGeoServerCreatingFeature) {
      analytics.track('Geometry Preparation Started', { projectId, geometryId });
    }
  }, [isGeoServerCreatingFeature, projectId, geometryId]);

  useEffect(() => {
    if (geometryBusyMessage !== prevBusyMessage) {
      analytics.track('Geometry Busy Message Changed', {
        projectId,
        geometryId,
        message: geometryBusyMessage,
      });
      setPrevBusyMessage(geometryBusyMessage);
    }
  }, [geometryBusyMessage, prevBusyMessage, projectId, geometryId]);

  /** Progress reporting is only valid when the status is busy and when the progress messages
  * is not empty. This is because the server may report a busy state without a progress
  * message. */
  if (!isGeoServerCreatingFeature || !geometryBusyMessage) {
    return null;
  }

  const isFeatureProgress = geometryBusyState?.BusyStateType?.case === 'featureProgress';
  const { featureId = '' } = geometryBusyState?.BusyStateType?.value as
    geometryservicepb.SubscribeGeometryResponse_BusyState_FeatureProgress;

  const featureInProgress = geometryState?.geometryFeatures.find(
    (feature) => feature.id === featureId,
  );

  // Only show the progress panel if the feature is an import operation or during the initial load
  // step.
  if (!isFeatureProgress) {
    return null;
  }
  if (!(featureInProgress?.operation?.value instanceof geometry.Import) && geometryState) {
    return null;
  }

  const handleToggleCollapse = () => {
    setCollapsed((prev) => !prev);
    analytics.track('Geometry Progress Panel Toggled', {
      projectId,
      geometryId,
      collapsed: !collapsed,
    });
  };

  return (
    <>
      <SummaryPanel
        collapsed={collapsed}
        heading={collapsed ? <>{heading}<LoadingEllipsis /></> : heading}
        onToggle={handleToggleCollapse}
        summary={<>{summary}</>}>
        <div className={classes.stepProgressMessages}>
          <ProgressMessage
            backgroundColor={colors.surfaceLight2}
            details={[]}
            key="geometryBusyMessage"
            message={geometryBusyMessage}
            // Indeterminate progress bar by design.
            progress={null}
          />
        </div>
      </SummaryPanel>
    </>
  );
};
