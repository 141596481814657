// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { CommonMenuListItem } from '../../lib/componentTypes/menu';
import { EntityGroupMap } from '../../lib/entityGroupMap';
import { compareAlphanumeric } from '../../lib/sorting';
import { useSetEntityGroupMap } from '../../recoil/entityGroupState';
import { useProjectContext } from '../context/ProjectContext';

export const useNodeSorting = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const setEntityGroupMap = useSetEntityGroupMap(projectId, workflowId, jobId);

  // TODO (LC-18420): add the icons for alphanumeric and reverse alphanumeric sorting
  const getSortingItems = useCallback((nodeId: string): CommonMenuListItem[] => [
    {
      label: 'Alphanumeric',
      startIcon: { name: 'sortAz' },
      onClick: () => {
        setEntityGroupMap((prev) => {
          const newMap = new EntityGroupMap(prev);
          newMap.sortChildrenRecursive(
            nodeId,
            (g1, g2) => compareAlphanumeric(g1.name, g2.name),
          );
          return newMap;
        });
      },
    },
    {
      label: 'Reverse Alphanumeric',
      startIcon: { name: 'sortZa' },
      onClick: () => {
        setEntityGroupMap((prev) => {
          const newMap = new EntityGroupMap(prev);
          newMap.sortChildrenRecursive(
            nodeId,
            (g1, g2) => -compareAlphanumeric(g1.name, g2.name),
          );
          return newMap;
        });
      },
    },
    {
      label: 'Group Size Descending',
      startIcon: { name: 'sortDesc' },
      onClick: () => {
        setEntityGroupMap((prev) => {
          const newMap = new EntityGroupMap(prev);
          newMap.sortChildrenRecursive(
            nodeId,
            (g1, g2) => g2.children.size - g1.children.size,
          );
          return newMap;
        });
      },
    },
    {
      label: 'Group Size Ascending',
      startIcon: { name: 'sortAsc' },
      onClick: () => {
        setEntityGroupMap((prev) => {
          const newMap = new EntityGroupMap(prev);
          newMap.sortChildrenRecursive(
            nodeId,
            (g1, g2) => g1.children.size - g2.children.size,
          );
          return newMap;
        });
      },
    },
  ], [setEntityGroupMap]);

  return getSortingItems;
};
