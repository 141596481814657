// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useMemo } from 'react';

import { LCVKeyModifier, LCVMouseButton, LCVMouseEvent, LCVType } from '@luminarycloudinternal/lcvis';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useProjectContext } from '../../components/context/ProjectContext';
import { useSelectionContext } from '../../components/context/SelectionManager';
import { useTagsInteractiveGeometry } from '../../components/hooks/useInteractiveGeometry';
import { useNodeGrouping } from '../../components/hooks/useNodeGrouping';
import { useSubselectVisualizerMenuItems } from '../../components/treePanel/NodeSubselect/control';
import { CommonMenuItem, CommonMenuListItem, CommonMenuPositionTransform } from '../../lib/componentTypes/menu';
import { IconName } from '../../lib/componentTypes/svgIcon';
import { colors, hexToRgbList } from '../../lib/designSystem';
import { expandGroups } from '../../lib/entityGroupUtils';
import { getHelpText } from '../../lib/keyBindings';
import { areArraysNear } from '../../lib/lang';
import { lcvResetCamera } from '../../lib/lcvis/api';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { NodeTableType } from '../../lib/nodeTableUtil';
import { traverseTreeNodes, updateTreeNodes } from '../../lib/paraviewUtils';
import { SelectionAction } from '../../lib/selectionUtils';
import { VisibilityInfo, getVisibilityInfo, isClipOrSlice } from '../../lib/visUtils';
import { UrlType } from '../../proto/projectstate/projectstate_pb';
import { TreeNode } from '../../pvproto/ParaviewRpc';
import { useEntityGroupData } from '../entityGroupState';
import { useGeoShowSurfacesValue } from '../geometry/geoShowSurfaces';
import { useGeoClipValue } from '../geometry/geometryClipState';
import { useGeometryList } from '../geometry/geometryListState';
import { useMeshUrlState } from '../meshState';
import { useEditStateValue } from '../paraviewState';
import { useEntitySelectionValue } from '../selectionOptions';
import { useSimulationTreeSubselect } from '../simulationTreeSubselect';
import { useSetCreateTagModalOpen } from '../useCreateTagModal';
import { useBackgroundColorState } from '../vis/backgroundColor';
import { useFilterState } from '../vis/filterState';
import { StaticVolume, useStaticVolumes } from '../volumes';

import { useLcvisExplodeFactorState } from './explodeFactor';
import { useLcVisFilterClipHideEnabled } from './lcvisClipHide';
import { useLcVisReadyValue } from './lcvisReadyState';
import { useLcvisVisibilityMap } from './lcvisVisibilityMap';
import { TRANSPARENCY_MODE_SUBTITLE, defaultTransparencySettings, useTransparencySettings } from './transparencySettings';

export type LcvisContextMenuSettings = {
  // The id of the surface being hovered when the context menu was triggered.
  clickedId: string,
  menuOpen: boolean,
  transform: CommonMenuPositionTransform,
};

/** Recoil atom which informs the placement and open state of the LCVis context menu. */
export const lcvisMenuSettings = atom<LcvisContextMenuSettings>({
  key: 'lcvisContextMenuProps',
  default: {
    clickedId: '',
    menuOpen: false,
    transform: {},
  },
});

export const useLcvisMenuSettings = () => useRecoilState(lcvisMenuSettings);

export const useLcvisMenuSettingsValue = () => useRecoilValue(lcvisMenuSettings);

export const useSetLcvisMenuSettings = () => useSetRecoilState(lcvisMenuSettings);

type OptionalMenuItem = {
  itemConfig: CommonMenuListItem;
  shouldShow: boolean;
}

// Given a list of OptionalMenuItems, return a list of the ones that should be shown.
// If an iconName is provided, that icon gets added to just the first item.
function filteredWithLeadIcon(
  iconName: IconName | null,
  items: OptionalMenuItem[],
): CommonMenuListItem[] {
  const shownItems = items.filter((item) => item.shouldShow).map((item) => item.itemConfig);
  if (shownItems.length && iconName) {
    shownItems[0].startIcon = { name: iconName };
  }
  return shownItems;
}

/**
 * Returns the items to populate the LCVis context menu on right click.
 * Exits early if the context menu isn't open.
*/
export const useLcvisContextMenuItems = (): CommonMenuItem[] => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const selectionContext = useSelectionContext();
  const { groupMap: entityGroupMap, leafMap } = useEntityGroupData(projectId, workflowId, jobId);
  const [visibilityMap, setVisibilityMap] = useLcvisVisibilityMap({ projectId, workflowId, jobId });
  const { clickedId, menuOpen, transform } = useLcvisMenuSettingsValue();
  const [
    backgroundColor,
    setBackgroundColor,
  ] = useBackgroundColorState({ projectId, workflowId, jobId });
  const {
    activeNodeTable,
    modifySelection,
    selectedNode,
    selectedNodeIds,
    setScrollTo,
    highlightedInVisualizer,
    setSelection,
  } = selectionContext;
  const staticVolumes = useStaticVolumes(projectId);
  const lcvisReady = useLcVisReadyValue();
  const [explodeFactor, setExplodeFactor] = useLcvisExplodeFactorState(projectId);
  const geoClip = useGeoClipValue(projectId);

  const setCreateTagModalOpen = useSetCreateTagModalOpen();

  const geoClipActive = !!geoClip.active;
  const {
    canGroupSelectedNodes,
    canUngroupSelectedNode,
    groupEntities,
    ungroupEntities,
  } = useNodeGrouping();

  const { isCreateTagDisabled } = useTagsInteractiveGeometry();
  const treeSubselect = useSimulationTreeSubselect();
  const getSubselectMenuItems = useSubselectVisualizerMenuItems();
  const [clipHide, setClipHide] = useLcVisFilterClipHideEnabled();
  const editState = useEditStateValue();
  const showSurfaces = useGeoShowSurfacesValue(projectId);
  const [filterState, setFilterState] = useFilterState({ projectId, workflowId, jobId });
  const [meshUrlState] = useMeshUrlState(projectId);
  const isMesh = meshUrlState.activeType === UrlType.MESH;
  const highlightedInVisSet = useMemo(
    () => new Set(highlightedInVisualizer),
    [highlightedInVisualizer],
  );
  const [transparencySettings, setTransparencySettings] = useTransparencySettings();
  const entitySelectionState = useEntitySelectionValue(projectId);

  const lightBackground = useMemo(() => (
    areArraysNear(backgroundColor, hexToRgbList(colors.lightBackgroundColor))
  ), [backgroundColor]);
  const toggleBackground = useCallback(() => {
    const newColor = lightBackground ? colors.darkBackgroundColor : colors.lightBackgroundColor;
    setBackgroundColor(hexToRgbList(newColor));
  }, [lightBackground, setBackgroundColor]);

  const anyClipSliceVisible = useMemo(() => {
    let visibleClipSlice = false;
    const callback = (node: TreeNode) => {
      if (node.visible && isClipOrSlice(node)) {
        visibleClipSlice = true;
      }
    };
    traverseTreeNodes(filterState, callback);
    return visibleClipSlice;
  }, [filterState]);

  // if any selected surfaces are hidden, show 'Show'.
  // if any selected surfaces are visible, show 'Hide'.
  // if any deselected surfaces are visible, show 'Hide Others (Isolate)'.
  // if any deselected surfaces are hidden, show 'Show Others'.
  // if any surfaces are hidden, show 'Show all'.
  const visibilities: VisibilityInfo = useMemo(() => getVisibilityInfo(
    isMesh,
    filterState,
    highlightedInVisSet,
    visibilityMap,
    entityGroupMap,
    staticVolumes,
  ), [isMesh, filterState, highlightedInVisSet, visibilityMap, entityGroupMap, staticVolumes]);

  const updateVisibility = useCallback((show: boolean, ids: Set<string>) => {
    setVisibilityMap((oldMap) => {
      const newMap = new Map(oldMap);
      ids.forEach((id) => {
        newMap.set(id, show);
      });
      return newMap;
    });
    if (isMesh) {
      setFilterState((prev) => updateTreeNodes(prev, (node) => {
        if (ids.has(node.id)) {
          return { ...node, visible: show };
        }
        return node;
      }));
    }
  }, [setVisibilityMap, setFilterState, isMesh]);

  const showAll = () => updateVisibility(true, new Set([
    ...visibilities.selectedHidden,
    ...visibilities.deselectedHidden,
  ]));
  const showOthers = () => updateVisibility(true, visibilities.deselectedHidden);
  const hideOthers = () => updateVisibility(false, visibilities.deselectedVisible);
  const showSelection = () => updateVisibility(true, visibilities.selectedHidden);
  const hideSelection = () => updateVisibility(false, visibilities.selectedVisible);

  const selectionInVisualizer = !!highlightedInVisualizer.length;

  const geometryList = useGeometryList(projectId);
  const geoUsesTags = geometryList?.geometries[0]?.usesTags ?? false;

  // if the user is hovering a surface when the menu is opened, allow them to select either
  // the surface itself or the volume it bounds.
  const boundingVolume: StaticVolume | undefined = useMemo(() => {
    if (!clickedId || !menuOpen) {
      return undefined;
    }
    return staticVolumes.find((staticVolume) => staticVolume.bounds.has(clickedId));
  }, [clickedId, staticVolumes, menuOpen]);

  if (!menuOpen) {
    return [];
  }

  const selectionItems: CommonMenuItem[] = [];
  if (selectionInVisualizer) {
    selectionItems.push(
      {
        label: 'Clear Selection',
        onClick: () => {
          modifySelection({
            action: SelectionAction.OVERWRITE,
            modificationIds: [],
            nodeTableOverride: activeNodeTable,
          });
        },
      },
    );
    if (!isCreateTagDisabled && geoUsesTags) {
      selectionItems.push({
        label: 'Create tag (selected)',
        onClick: () => {
          setCreateTagModalOpen(true);
        },
      });
    }
  }
  if (clickedId) {
    if (treeSubselect.active) {
      selectionItems.push(...getSubselectMenuItems(clickedId, boundingVolume, true));
    } else {
      const hasActiveNodeTable = (activeNodeTable.type !== NodeTableType.NONE);
      const action = hasActiveNodeTable ? SelectionAction.ADD : SelectionAction.OVERWRITE;
      if (showSurfaces && !highlightedInVisualizer.includes(clickedId)) {
        selectionItems.push({
          label: 'Select Surface',
          onClick: () => {
            modifySelection({
              action,
              modificationIds: [clickedId],
              nodeTableOverride: activeNodeTable,
            });
            setScrollTo({ node: clickedId, fast: true });
          },
          startIcon: { name: 'cubeOutline' },
        });
        if (!isCreateTagDisabled && geoUsesTags) {
          selectionItems.push({
            label: 'Create tag (hovered)',
            onClick: () => {
              setCreateTagModalOpen(true);
              setSelection([clickedId]);
            },
          });
        }
      }
      if (boundingVolume?.id && !highlightedInVisualizer.includes(boundingVolume.id)) {
        selectionItems.push({
          label: 'Select Volume',
          onClick: () => {
            modifySelection({
              action,
              modificationIds: [boundingVolume.id],
              nodeTableOverride: activeNodeTable,
            });
            setScrollTo({ node: boundingVolume.id, fast: true });
          },
          startIcon: { name: 'cubeSolid' },
        });
      }
    }
  }

  const canMakeSelectionTransparent = Boolean(
    entitySelectionState === 'surface' &&
    selectedNodeIds.length &&
    entityGroupMap.has(selectedNodeIds[0]),
  );

  const transparencyItems = [
    ...filteredWithLeadIcon('transparency', [
      {
        itemConfig: {
          label: 'Transparency Mode',
          help: TRANSPARENCY_MODE_SUBTITLE,
          onClick: () => setTransparencySettings({
            ...defaultTransparencySettings(),
            active: true,
          }),
        },
        shouldShow: !transparencySettings.active && !canMakeSelectionTransparent,
      },
      {
        itemConfig: {
          label: 'Exit Transparency Mode',
          help: TRANSPARENCY_MODE_SUBTITLE,
          onClick: () => setTransparencySettings(defaultTransparencySettings()),
        },
        shouldShow: transparencySettings.active,
      },
      {
        itemConfig: {
          label: 'Make Transparent',
          help: TRANSPARENCY_MODE_SUBTITLE,
          onClick: () => setTransparencySettings({
            active: true,
            surfaces: new Set(expandGroups(leafMap)(selectedNodeIds)),
            invert: false,
          }),
        },
        shouldShow: !transparencySettings.active && canMakeSelectionTransparent,
      },
      {
        itemConfig: {
          label: 'Make Others Transparent (Reveal)',
          help: TRANSPARENCY_MODE_SUBTITLE,
          onClick: () => setTransparencySettings({
            active: true,
            surfaces: new Set(expandGroups(leafMap)(selectedNodeIds)),
            invert: true,
          }),
        },
        shouldShow: !transparencySettings.active && canMakeSelectionTransparent,
      },
    ]),
  ];

  const visibilityItems = [
    ...filteredWithLeadIcon('eyeOff', [
      {
        itemConfig: { label: 'Hide', onClick: hideSelection },
        shouldShow: selectionInVisualizer && visibilities.selectedVisible.size > 0,
      },
      {
        itemConfig: { label: 'Hide Others (Isolate)', onClick: hideOthers },
        shouldShow: selectionInVisualizer && visibilities.deselectedVisible.size > 0,
      },
    ]),
    ...filteredWithLeadIcon('eyeOn', [
      {
        itemConfig: { label: 'Show', onClick: showSelection },
        shouldShow: selectionInVisualizer && visibilities.selectedHidden.size > 0,
      },
      {
        itemConfig: { label: 'Show All', onClick: showAll },
        shouldShow: visibilities.deselectedHidden.size > 0 || visibilities.selectedHidden.size > 0,
      },
      {
        itemConfig: { label: 'Show Others', onClick: showOthers },
        shouldShow: selectionInVisualizer && visibilities.deselectedHidden.size > 0,
      },
    ]),
    ...filteredWithLeadIcon(null, [
      {
        itemConfig: {
          label: clipHide ? 'Show Clipped Surfaces' : 'Hide Clipped Surfaces',
          onClick: () => setClipHide(!clipHide),
          help: (
            'Clipped surfaces are transparent by default. ' +
            'Transparent surfaces cannot be selected or probed in the visualizer.'
          ),
        },
        shouldShow: (anyClipSliceVisible && !isMesh) || geoClipActive || !!editState,
      },
    ]),
  ];

  const groupItems = filteredWithLeadIcon(null, [
    {
      itemConfig: {
        label: 'Group',
        onClick: () => groupEntities(selectedNodeIds),
        keyboardShortcut: getHelpText('group'),
        startIcon: { name: 'groupAction' },
      },
      shouldShow: canGroupSelectedNodes,
    },
    {
      itemConfig: {
        label: 'Ungroup',
        onClick: () => ungroupEntities(selectedNode!.id),
        keyboardShortcut: getHelpText('ungroup'),
        startIcon: { name: 'ungroupAction' },
      },
      shouldShow: canUngroupSelectedNode,
    },
  ]);

  const cameraItems: CommonMenuItem[] = [
    {
      label: explodeFactor === null ? 'Exploded View' : 'Exit Exploded View',
      onClick: () => {
        setExplodeFactor((prev) => {
          if (prev === null) {
            return 0;
          }
          return null;
        });
      },
      startIcon: { name: 'cubeFacesOutline' },
    },
    {
      label: 'Zoom to Fit',
      onClick: () => {
        lcvResetCamera();
      },
      startIcon: { name: 'arrowsOut' },
      keyboardShortcut: getHelpText('resetCamera'),
    },
    {
      label: 'Set Center of Rotation',
      onClick: () => {
        if (!lcvisReady || !lcvHandler.display) {
          return;
        }
        lcvHandler.display.widgets.arcballWidget?.setParam(
          'center_of_rotation_modifier',
          LCVType.kLCVDataTypeInt,
          1,
        );
        // send a mouse event at the clicked location in the next animation frame, since LCVis
        // takes 1 frame to apply the new param before a new click will set the center of rot.
        requestAnimationFrame(() => {
          lcvHandler.display?.invokeEvent.call(
            lcvHandler.display!,
            new LCVMouseEvent(
              LCVMouseButton.kLCVMouseButtonLeft,
              LCVKeyModifier.kLCVKeyModifierNone,
              transform.left!,
              transform.top!,
            ),
            'mouseup',
          );
        });
      },
      startIcon: { name: 'ringCircle' },
    },
  ];

  const backgroundColorItems: CommonMenuItem[] = [
    {
      label: lightBackground ? 'Dark background' : 'Light background',
      onClick: toggleBackground,
    },
  ];

  return combineSections(
    selectionItems,
    visibilityItems,
    transparencyItems,
    groupItems,
    cameraItems,
    backgroundColorItems,
  );
};

function combineSections(
  ...sections: CommonMenuItem[][]
): CommonMenuItem[] {
  const nonEmptySections = sections.filter((section) => section.length > 0);
  const items: CommonMenuItem[] = [];

  nonEmptySections.forEach((section) => {
    items.push(...section);
    items.push({ separator: true });
  });

  return items.slice(0, -1);
}
