// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/notification/notification.proto (package luminary.proto.notification, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Message severity; part of Notification.
 *
 * @generated from enum luminary.proto.notification.Level
 */
export const Level = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.notification.Level",
  [
    {no: 0, name: "INFO"},
    {no: 1, name: "WARN"},
    {no: 2, name: "ERROR"},
    {no: 3, name: "SUCCESS"},
  ],
);

/**
 * The type of event which the notification is for.
 *
 * @generated from enum luminary.proto.notification.EventType
 */
export const EventType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.notification.EventType",
  [
    {no: 0, name: "INVALID"},
    {no: 1, name: "SIMULATION_COMPLETE"},
    {no: 2, name: "MOVIE_COMPLETE"},
    {no: 3, name: "DOE_PROGRESS"},
    {no: 4, name: "PROJECT_SHARING"},
  ],
);

/**
 * These correspond to row types in the JobTable, but are also used
 * in other places that interact with JobNameMap.
 *
 * @generated from enum luminary.proto.notification.JobType
 */
export const JobType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.notification.JobType",
  [
    {no: 0, name: "INVALID_JOBTYPE"},
    {no: 1, name: "DESIGN_OF_EXPERIMENTS"},
    {no: 2, name: "SENSITIVITY_ANALYSIS"},
    {no: 3, name: "EXPLORATION_JOB"},
    {no: 4, name: "SIMULATION_JOB"},
    {no: 5, name: "SENSITIVITY_JOB"},
  ],
);

/**
 * @generated from message luminary.proto.notification.Metadata
 */
export const Metadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.notification.Metadata",
  () => [
    { no: 1, name: "simulation_complete_metadata", kind: "message", T: Metadata_SimulationComplete, oneof: "meta" },
    { no: 2, name: "doe_progress_metadata", kind: "message", T: Metadata_DoeProgress, oneof: "meta" },
    { no: 3, name: "project_sharing_metadata", kind: "message", T: Metadata_ProjectSharing, oneof: "meta" },
  ],
);

/**
 * @generated from message luminary.proto.notification.Metadata.SimulationComplete
 */
export const Metadata_SimulationComplete = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.notification.Metadata.SimulationComplete",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "job_type", kind: "enum", T: proto3.getEnumType(JobType) },
  ],
  {localName: "Metadata_SimulationComplete"},
);

/**
 * @generated from message luminary.proto.notification.Metadata.DoeProgress
 */
export const Metadata_DoeProgress = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.notification.Metadata.DoeProgress",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "total_jobs", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "jobs_succeeded", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "jobs_failed", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
  {localName: "Metadata_DoeProgress"},
);

/**
 * @generated from message luminary.proto.notification.Metadata.ProjectSharing
 */
export const Metadata_ProjectSharing = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.notification.Metadata.ProjectSharing",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "owner_user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "Metadata_ProjectSharing"},
);

/**
 * A notification from the Luminary app.
 *
 * @generated from message luminary.proto.notification.Notification
 */
export const Notification = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.notification.Notification",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "level", kind: "enum", T: proto3.getEnumType(Level) },
    { no: 3, name: "event_type", kind: "enum", T: proto3.getEnumType(EventType) },
    { no: 5, name: "create_time", kind: "message", T: Timestamp },
    { no: 6, name: "read", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "metadata", kind: "message", T: Metadata },
  ],
);

/**
 * @generated from message luminary.proto.notification.ListRequest
 */
export const ListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.notification.ListRequest",
  () => [
    { no: 1, name: "start_time", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message luminary.proto.notification.ListReply
 */
export const ListReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.notification.ListReply",
  () => [
    { no: 1, name: "notifications", kind: "message", T: Notification, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.notification.UpdateRequest
 */
export const UpdateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.notification.UpdateRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "read", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.notification.ListenReply
 */
export const ListenReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.notification.ListenReply",
  () => [
    { no: 1, name: "latest_notifications", kind: "message", T: Notification, repeated: true },
  ],
);

