// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { RecoilValueReadOnly } from 'recoil';

import * as plotpb from '../proto/plots/plots_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';
import { ViewState } from '../pvproto/ParaviewRpc';
import { plotDataSelector } from '../recoil/plotDataSelector';

import { traverseTreeNodes } from './paraviewUtils';

// Ensure this plot's properties will receive a valid response from the rpc
const isValidPlotParam = (node: plotpb.PlotSettings | undefined) => {
  const plot = node?.plot.case === 'xyPlot' ? node.plot.value : null;
  return plot &&
    plot.dataIds.length !== 0 &&
    plot.yAxis?.displayDataName !== '';
};

// Create a string that changes only when the chart needs to be updated, ensuring
// the selector will only re-evaluate when needed
const constructPlotInfoString = (
  viewState: ParaviewRpc.ViewState | null,
  node?: plotpb.PlotSettings,
): string => {
  let plotInfoString = '';
  if (node === undefined || !viewState || !isValidPlotParam(node)) {
    return plotInfoString;
  }
  const plot = node.plot.case === 'xyPlot' ? node.plot.value : null;
  const getTreeNodeData = (treeNode: ParaviewRpc.TreeNode) => {
    plotInfoString += `${treeNode.id}`;
    if (plot?.dataIds.includes(treeNode.id)) {
      plotInfoString += `${treeNode.bounds}`;
      plotInfoString += `${treeNode.pointData}`;
      plotInfoString += `${treeNode.paramSeq}`;
      plotInfoString += viewState.path;
    }
  };

  traverseTreeNodes(viewState.root, getTreeNodeData);
  return plotInfoString;
};

export const selectivelyUpdateRpc = (
  projectId: string,
  workflowId: string,
  jobId: string,
  plotId: string,
  viewState: ViewState | null,
  plotNodes: plotpb.Plots,
): RecoilValueReadOnly<ParaviewRpc.PlotData | null> => {
  const node = plotNodes.plots.find((plotNode) => plotNode.id === plotId);
  const plotInfoString = constructPlotInfoString(viewState, node);
  return plotDataSelector({
    projectId,
    workflowId,
    jobId,
    plotId,
    plotInfoString,
  });
};
