// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue } from 'recoil';

import { ParamScope } from '../../../../lib/ParamScope';
import { newSimulationParam } from '../../../../lib/paramUtils';
import { RecoilProjectKey } from '../../../../lib/persist';
import { getSimulationParam } from '../../../../lib/simulationParamUtils';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { onGeometryTabSelector } from '../../../../recoil/geometry/geometryState';
import { currentConfigSelector } from '../../../../recoil/workflowConfig';

export interface ParamData {
  param: simulationpb.SimulationParam;
  paramScope: ParamScope;
}

/**
 * Shared state for the simulation param, derived from the current config
 */
export const simulationParamState = selectorFamily<
  simulationpb.SimulationParam,
  RecoilProjectKey
>({
  key: 'simulationParamState',
  get: (key) => ({ get }) => {
    if (get(onGeometryTabSelector)) {
      return newSimulationParam();
    }
    const config = get(currentConfigSelector(key));
    return getSimulationParam(config);
  },
  dangerouslyAllowMutability: true,
});

export function useSimulationParam(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(simulationParamState({ projectId, workflowId, jobId }));
}
