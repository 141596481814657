// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { ParamName, paramDesc } from '../../../SimulationParamDescriptor';
import { adVec3ToVec3, equalsZero, getAdValue } from '../../../lib/adUtils';
import assert from '../../../lib/assert';
import { getParticleGroupImposter } from '../../../lib/imposterFilteringUtils';
import { findParticleGroupById } from '../../../lib/particleGroupUtils';
import { useUserCanEdit } from '../../../lib/projectRoles';
import { useNodePanel } from '../../../lib/useNodePanel';
import { EditSource } from '../../../lib/visUtils';
import { AdFloatType, AdVector3 } from '../../../proto/base/base_pb';
import * as basepb from '../../../proto/base/base_pb';
import * as simulationpb from '../../../proto/client/simulation_pb';
import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import { useSetEditState } from '../../../recoil/paraviewState';
import useProjectMetadata from '../../../recoil/useProjectMetadata';
import { useWorkflowState } from '../../../recoil/workflowState';
import { ActionButton } from '../../Button/ActionButton';
import { CollapsiblePanel } from '../../Panel/CollapsiblePanel';
import { MultipleChoiceDisplay } from '../../ParamFieldInput';
import ParamRow, { ParamRowProps } from '../../ParamRow';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import Divider from '../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { ModelSelector } from '../../controls/ModelSelector';
import { useDiskBehaviorModel } from '../../hooks/useDiskBehaviorModel';
import { useSimulationConfig } from '../../hooks/useSimulationConfig';
import { SectionMessage } from '../../notification/SectionMessage';
import { useVisFilterParentNode } from '../../visFilter/useVisFilterParentNode';
import { LabeledSection } from '../LabeledSection';
import PropertiesSection from '../PropertiesSection';

import { newExtractSurfacesParam } from './filter/ExtractSurfaces';
import { ParentSelector } from './shared/ParentSelector';
import { ParticleGroupMetaPanel } from './shared/ParticleGroupMetaPanel';

const { ACTUATOR_DISK_SPECIFY_NORMAL_VECTOR } = simulationpb.ActuatorDiskOrientationSelection;

// A panel displaying all the settings for the selected particle group node.
export const ParticleGroupPropPanel = () => {
  const { readOnly } = useProjectContext();
  const {
    addNode,
    activeEdit,
    viewState,
    paraviewClientState,
  } = useParaviewContext();

  const { selectedNode: node, setSelection } = useSelectionContext();
  assert(!!node, 'No selected actuator disk row');

  const setEditState = useSetEditState();

  const { simParam, saveParam } = useSimulationConfig();
  const particleGroup = findParticleGroupById(simParam, node.id);
  const [particlesImposter, setImposter] = useState<ParaviewRpc.TreeNode | null>(null);
  const { projectId, workflowId } = useProjectContext();
  const workflowState = useWorkflowState(projectId, workflowId);

  const projectMetadata = useProjectMetadata(projectId);
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);
  const behaviorModel = useDiskBehaviorModel(node.id);

  // Store the treenode of the imposter used to represent this particle group. The
  // imposter will be updated if this props panel changes to a different group.
  // TODO(matt): we need a unique identifier to provide the imposter with a link to
  // the particle group. Also, the creation of the node and the updates happen asynchronously,
  // which could cause some race conditions if the user somehow manages to click buttons with
  // super human speed.
  // We need to change the current reference to the imposter if we haven't created one before or
  // if the current imposter is not the imposter for this actuator disk.
  useEffect(() => {
    setImposter(getParticleGroupImposter(
      particleGroup,
      node.id,
      viewState,
      simParam,
      addNode,
    ));
  }, [viewState, particleGroup, node.id, addNode, simParam]);

  const [specErrors, setSpecErrors] = useState<string[]>([]);
  const [posnErrors, setPosnErrors] = useState<string[]>([]);

  const specPanel = useNodePanel(node.id, 'specification');
  const posnPanel = useNodePanel(node.id, 'position');

  const commonClasses = useCommonTreePropsStyles();

  const parentNode = useVisFilterParentNode();

  useEffect(() => {
    // Validate properties
    const newSpecErrors: string[] = [];
    const newPosnErrors: string[] = [];

    if (particleGroup?.particleGroupType === simulationpb.ParticleGroupType.ACTUATOR_DISK) {
      const innerRadius = getAdValue(particleGroup.actuatorDiskInnerRadius);
      const outerRadius = getAdValue(particleGroup.actuatorDiskOuterRadius);
      const orientation = particleGroup.actuatorDiskOrientationSelection;

      if (innerRadius > outerRadius) {
        newSpecErrors.push('Inner radius cannot be greater than outer radius.');
      }

      if (orientation === ACTUATOR_DISK_SPECIFY_NORMAL_VECTOR) {
        const normal = particleGroup?.actuatorDiskNormalVector;
        if (!normal || equalsZero(normal)) {
          newPosnErrors.push('Normal vector must be defined with magnitude > 0.');
        }
      }
    }

    setSpecErrors(newSpecErrors);
    setPosnErrors(newPosnErrors);
  }, [particleGroup]);

  if (!particleGroup) {
    return <></>;
  }

  const particleGroupType = particleGroup.particleGroupType;
  const orientation = particleGroup.actuatorDiskOrientationSelection;
  const vectorOrientation = (orientation === ACTUATOR_DISK_SPECIFY_NORMAL_VECTOR);

  const updateParticlesImposter = (newParticleGroup: simulationpb.ParticleGroup) => {
    if (particlesImposter && newParticleGroup) {
      const center = adVec3ToVec3(newParticleGroup.actuatorDiskCenter!);
      const innerRadius = newParticleGroup.actuatorDiskInnerRadius!;
      const outerRadius = newParticleGroup.actuatorDiskOuterRadius!;
      const param = particlesImposter.param as ParaviewRpc.ActuatorDiskParam;
      const normal = adVec3ToVec3(newParticleGroup.actuatorDiskNormalVector!);
      const euler = adVec3ToVec3(newParticleGroup.actuatorDiskRotationAngle!);
      const useNormal = (
        newParticleGroup.actuatorDiskOrientationSelection === ACTUATOR_DISK_SPECIFY_NORMAL_VECTOR
      );
      const rot = useNormal ? normal : euler;
      activeEdit(
        particlesImposter.id,
        {
          ...param,
          plane: {
            typ: 'Plane',
            origin: { ...center },
            normal: { ...rot },
          },
          usenormal: useNormal,
          innerradius: getAdValue(innerRadius),
          outerradius: getAdValue(outerRadius),
        },
      );
    }
  };

  const updateField = (updateValue: (particleGroup: simulationpb.ParticleGroup) => void) => {
    const newItem = saveParam((newParam) => {
      const newParticleGroup = findParticleGroupById(newParam, node.id);
      if (newParticleGroup) {
        updateValue(newParticleGroup);
        return newParticleGroup;
      }
      return null;
    });
    if (newItem) {
      updateParticlesImposter(newItem);
    }
  };

  const changeInnerRadius = (value: any) => updateField((newParticle) => {
    newParticle.actuatorDiskInnerRadius = value as AdFloatType;
  });

  const changeOuterRadius = (value: any) => updateField((newParticle) => {
    newParticle.actuatorDiskOuterRadius = value as AdFloatType;
  });

  const changeDiskCenter = (value: any) => updateField((newParticle) => {
    newParticle.actuatorDiskCenter = value as AdVector3;
  });

  const changeOrientationType = (value: any) => updateField((newParticle) => {
    const selectionValue = value as simulationpb.ActuatorDiskOrientationSelection;
    newParticle.actuatorDiskOrientationSelection = selectionValue;
  });

  const changeRotationAngle = (value: any) => updateField((newParticle) => {
    newParticle.actuatorDiskRotationAngle = value as AdVector3;
  });

  const changeNormalVector = (value: any) => updateField((newParticle) => {
    newParticle.actuatorDiskNormalVector = value as AdVector3;
  });

  const specCellProps: ParamRowProps[] = [];
  const posnCellProps: ParamRowProps[] = [];

  switch (particleGroupType) {
    case simulationpb.ParticleGroupType.ACTUATOR_DISK: {
      specCellProps.push(
        {
          nestLevel: 0,
          inputOptions: { showUnits: true },
          suppressTooltip: true,
          param: paramDesc[ParamName.ActuatorDiskInnerRadius],
          readOnly: readOnly || specPanel.collapsed,
          setValue: changeInnerRadius,
          value: particleGroup.actuatorDiskInnerRadius,
        },
        {
          nestLevel: 0,
          inputOptions: { showUnits: true },
          suppressTooltip: true,
          param: paramDesc[ParamName.ActuatorDiskOuterRadius],
          readOnly: readOnly || specPanel.collapsed,
          setValue: changeOuterRadius,
          value: particleGroup.actuatorDiskOuterRadius,
        },
      );
      posnCellProps.push(
        {
          nestLevel: 0,
          inputOptions: { showUnits: true },
          param: paramDesc[ParamName.ActuatorDiskCenter],
          readOnly: readOnly || posnPanel.collapsed,
          setValue: changeDiskCenter,
          value: particleGroup.actuatorDiskCenter,
        },
        {
          nestLevel: 0,
          inputOptions: {
            multipleChoiceDisplay: MultipleChoiceDisplay.RADIO,
            horizontalRadioButtons: true,
          },
          label: 'Normal',
          param: paramDesc[ParamName.ActuatorDiskOrientationSelection],
          readOnly: readOnly || specPanel.collapsed,
          setValue: changeOrientationType,
          value: orientation,
        },
      );
      if (vectorOrientation) {
        posnCellProps.push({
          nestLevel: 0,
          inputOptions: { showUnits: true },
          label: '',
          param: paramDesc[ParamName.ActuatorDiskNormalVector],
          readOnly: readOnly || posnPanel.collapsed,
          setValue: changeNormalVector,
          value: particleGroup.actuatorDiskNormalVector,
        });
      } else {
        posnCellProps.push({
          nestLevel: 0,
          inputOptions: { showUnits: true },
          label: '',
          param: paramDesc[ParamName.ActuatorDiskRotationAngle],
          readOnly: readOnly || posnPanel.collapsed,
          setValue: changeRotationAngle,
          value: particleGroup.actuatorDiskRotationAngle,
        });
      }
      break;
    }
    case simulationpb.ParticleGroupType.ACTUATOR_LINE: {
      break;
    }
    case simulationpb.ParticleGroupType.SOURCE_POINTS: {
      break;
    }
    case simulationpb.ParticleGroupType.PROBE_POINTS: {
      break;
    }
    case simulationpb.ParticleGroupType.INVALID_PARTICLE_GROUP_TYPE: {
      break;
    }
    // no default
  }

  const addExtractSurfaces = () => {
    if (!parentNode) {
      return;
    }
    // Create a new extract surface node.
    // Select the new node and enable editing on it.
    const newParam = newExtractSurfacesParam();
    newParam.disks = [node.id];

    const defaultDisplayProps: ParaviewRpc.DisplayProps = {
      reprType: 'Surface',
      displayVariable: null,
    };

    setSelection([parentNode.id]);

    setEditState({
      newNode: true,
      nodeId: parentNode.id,
      param: newParam,
      displayProps: defaultDisplayProps,
      editSource: EditSource.FORM,
    });
  };

  const workflowActive = (
    workflowState && (workflowState.status!.typ === basepb.JobStatusType.Active)
  );
  const clientDisconnected = paraviewClientState.client === null;

  const disableDiskExtract = (
    viewState?.surfaceData.length === 0 ||
    clientDisconnected ||
    !userCanEdit
  );

  let extractSurfacesTooltipMessage = 'Add an Extract Surface filter';
  if (disableDiskExtract) {
    // If we have no surface data or no visualizer, the button is disabled. Show a different tooltip
    // if the button is disabled due to being in Setup view, because we're running a simulation,
    // or because the vizualizer hasn't loaded yet.
    if (!readOnly) {
      extractSurfacesTooltipMessage = 'You cannot extract disk surfaces in the Setup tab';
    } else if (workflowActive) {
      extractSurfacesTooltipMessage = 'Waiting for simulation to enable extract surfaces';
    } else if (clientDisconnected) {
      extractSurfacesTooltipMessage = 'Waiting for visualizer to enable extract surfaces';
    } else if (!userCanEdit) {
      extractSurfacesTooltipMessage = 'You cannot extract surfaces in view only project';
    } else {
      // This would be the case where surfaceData is missing from the viewstate but none of the
      // above conditions are met. This should not be possible (except perhaps in brief moments
      // while the viewstate loads) but we'll put a generic message here just in case
      extractSurfacesTooltipMessage = 'Missing surface data';
    }
  }

  return (
    <div className={commonClasses.properties}>
      <ParticleGroupMetaPanel particleGroupType={particleGroupType} />
      {!!specCellProps.length && (
        <>
          <Divider />
          <PropertiesSection>
            <CollapsiblePanel
              collapsed={specPanel.collapsed}
              heading="Specification"
              onToggle={specPanel.toggle}>
              {specCellProps.map((specCellProp) => (
                <ParamRow key={`${node.id}-${specCellProp.param.name}`} {...specCellProp} />
              ))}
              {!!specErrors?.length && (
                <div className={commonClasses.sectionMessageContainer}>
                  <div className={commonClasses.sectionMessages}>
                    {specErrors.map((error) => (
                      <SectionMessage
                        key={error}
                        level="warning"
                        message={error}
                      />
                    ))}
                  </div>
                </div>
              )}
            </CollapsiblePanel>
          </PropertiesSection>
        </>
      )}
      {!!posnCellProps.length && (
        <>
          <Divider />
          <PropertiesSection>
            <CollapsiblePanel
              collapsed={posnPanel.collapsed}
              heading="Position"
              onToggle={posnPanel.toggle}>
              {posnCellProps.map((posnCellProp) => (
                <ParamRow key={`${node.id}-${posnCellProp.param.name}`} {...posnCellProp} />
              ))}
              {!!posnErrors?.length && (
                <div className={commonClasses.sectionMessageContainer}>
                  <div className={commonClasses.sectionMessages}>
                    {posnErrors.map((error) => (
                      <SectionMessage
                        key={error}
                        level="warning"
                        message={error}
                      />
                    ))}
                  </div>
                </div>
              )}
            </CollapsiblePanel>
          </PropertiesSection>
        </>
      )}
      <Divider />
      <LabeledSection label="Physical Model">
        <ModelSelector
          creators={behaviorModel.creators}
          disabled={readOnly}
          icon={{ name: 'intersectingDisks' }}
          models={behaviorModel.modelData}
          onSelect={({ model }) => behaviorModel.attachBehavior(model)}
          onUnselect={() => behaviorModel.attachBehavior()}
          selected={behaviorModel.selected}
          tooltip={
            readOnly ?
              '' :
              'Assign this disk to an existing physical behavior or create a new one'
          }
        />
      </LabeledSection>
      <Divider />
      <PropertiesSection>
        <ParentSelector node={node} />
      </PropertiesSection>
      <Divider />
      <LabeledSection label="Visualization">
        <div>
          <ActionButton
            aria-haspopup="true"
            disabled={disableDiskExtract}
            justifyContent="space-between"
            onClick={addExtractSurfaces}
            size="small"
            title={extractSurfacesTooltipMessage}>
            Add
          </ActionButton>
        </div>
      </LabeledSection>
    </div>
  );
};
