// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import cx from 'classnames';
import * as ReactDOM from 'react-dom';
import { atom, useRecoilState } from 'recoil';

import { callAfterAnimation, setTransform } from '../../../lib/animationUtils';
import { SECONDS_IN_DAY } from '../../../lib/constants';
import { isUnmodifiedEnterKey } from '../../../lib/event';
import { XIcon } from '../../svg/XIcon';
import { Toast, ToastProps } from '../Toast';

import './Toaster.scss';

// Duration for slide-in, slide-up, slide-down animations
const ANIMATION_DURATION = 200;
// Amount (in px) that the 2 toasts behind the front one should "stick up" and be visible
const PEEKOVER_HEIGHT = 8;
// Size of gap between toasts in toasterRoot
const GAP_SIZE = 5;

export interface ToastPropsWithId extends ToastProps {
  id: string;
}

export interface ToasterProps {
  toastInfoList: ToastPropsWithId[];
  maxHeight?: string;
  width?: number;
  bottom?: number;
  left?: number;
  /** If true, renders the toaster in the #toaster node above modals */
  portal?: boolean;
}

/**
 * Attributes for each toastWrapper that we can
 * modify and which will be animated whenever they change.
 * */
interface EditableAttrs {
  bottom: number,
  left: number,
  scale?: number,
  opacity?: number,
}

// The collapsed state needs to be stored in recoil to persist between pages/tabs.
const collapsedState = atom<boolean>({
  key: 'toasterCollapsedState',
  default: true,
});

/** Manages and animates a stack of Toast notifications. */
export const Toaster = (props: ToasterProps) => {
  // Props
  const { toastInfoList, maxHeight = '80vh', bottom = 50, left = 200, width = 300, portal } = props;

  // Refs
  const rootRef = useRef<HTMLDivElement | null>(null);
  // Controls ref, used for hiding/displaying the controls
  const controlsRef = useRef<HTMLDivElement | null>(null);
  // Stores the dimensions of each toast in the component.
  const listRef = useRef<HTMLDivElement[]>([]);
  // The previous length of the toastInfoList.
  const prevLength = useRef(toastInfoList.length);

  // States
  const [collapsed, setCollapsed] = useRecoilState(collapsedState);
  const [allowPointerEvents, setAllowPointerEvents] = useState(false);
  // Whether to apply a clip-path to hide the bottom of the second and third toasts when animating
  const [hideBottom, setHideBottom] = useState([false, false]);
  // Whether to apply transitions. On the first render, transition-duration should be 0s
  // because if we just switched pages (and didn't add toasts),
  // we don't want to perform the animations extra times.
  const [applyTransitions, setApplyTransitions] = useState(false);

  // Reference to #menus node in the main index.html file where we attach the portals to.
  const toasterNode = document.getElementById('toaster');

  // Callbacks
  // Update the editableAttrs associated with each toast. Called whenever the toastInfoList changes
  // or the Toaster is collapsed.
  const updatePositions = useCallback(() => {
    const newPositions = new Array(listRef.current.length);
    if (!collapsed) {
      listRef.current.forEach((el: HTMLDivElement, index: number) => {
        const newVal: EditableAttrs = { scale: 1, bottom: 0, left: 0, opacity: 1 };
        if (index === 1) {
          newVal.bottom = (listRef.current[0]?.offsetHeight || 0) + GAP_SIZE;
        } else if (index > 1) {
          newVal.bottom = (
            newPositions[index - 1]?.bottom +
            (listRef.current[index - 1]?.offsetHeight || 0) +
            GAP_SIZE
          );
        }
        newPositions[index] = newVal;
      });
    } else if (collapsed) {
      // set positions to collapsed
      const topHeight = listRef.current[0]?.offsetHeight || 0;
      listRef.current.forEach((el: HTMLDivElement, index: number) => {
        const newVal: EditableAttrs = { bottom: 0, left: 0, scale: 1, opacity: 1 };
        const currHeight = el.offsetHeight || 0;
        if ([1, 2, 3].includes(index)) {
          // Transform the 2nd-4th elements so that each shows exactly PEEKOVER_HEIGHT px above
          // the next element. The 4th element will only show when it moves forwards
          // to become the 3rd element, but we scale/translate it here too to prevent jumpiness
          // when it becomes visible.
          const scaleFactor = 1 - 0.1 * index;
          newVal.bottom = topHeight + (index * PEEKOVER_HEIGHT) - (currHeight * scaleFactor);
          newVal.scale = scaleFactor;
          newVal.opacity = (index === 3) ? 0 : 1;
        } else if (index > 3) {
          newVal.bottom = 0;
          newVal.scale = Math.max(1 - (0.05 * index), 0.5);
          newVal.opacity = 0;
        }
        newPositions[index] = newVal;
      });
    }
    requestAnimationFrame(() => {
      listRef.current.forEach((el: HTMLDivElement, index: number) => {
        setTransform(el, 'translateX', width, 'px');
        setTransform(el, 'translateY', -(newPositions[index]?.bottom || 0), 'px');
        setTransform(el, 'scale', newPositions[index]?.scale ?? 1);
        el.style.setProperty('opacity', `${newPositions[index]?.opacity ?? el.style.opacity}`);
      });
    });
  }, [collapsed, width]);

  // Ref callback passed to each toastWrapper element. Runs every time
  // each toastWrapper component mounts (with el=<the toastWrapper HTMLDiv>)
  // and when it unmounts (with el=null).
  const listRefCallback = useCallback(
    (el: HTMLDivElement | null, index: number) => {
      if (!el) {
        return;
      }
      if (index > listRef.current.length - 1) {
        listRef.current.push(el);
      } else if (index < listRef.current.length) {
        if (!listRef.current[index].isEqualNode(el)) {
          // if the element at this index is different from it was before, change it.
          listRef.current[index] = el;
        }
        if (index === toastInfoList.length - 1 && listRef.current.length > toastInfoList.length) {
          // we're at the end of the current props list but the previous list was longer.
          listRef.current.length = toastInfoList.length;
        }
      }
    },
    [toastInfoList.length],
  );

  const setControlsVisibility = (isOpen: boolean) => {
    const translateQuantity = isOpen ? 0 : -10;
    const newOpacity = isOpen ? '1' : '0';
    const newVisibility = isOpen ? 'visible' : 'hidden';
    setTransform(controlsRef.current as HTMLDivElement, 'translateY', translateQuantity, 'px');
    controlsRef.current?.style.setProperty('opacity', newOpacity);
    controlsRef.current?.style.setProperty('visibility', newVisibility);
  };

  // Effects
  // If a toast was deleted while in collapsed state, hide the bottom of the
  // 3rd (new 2nd) toast from view until the new topmost toast has come forward.
  // Also handle display/hiding of the controls if adding/removing toasts
  useLayoutEffect(() => {
    if (collapsed && toastInfoList.length < prevLength.current) {
      setHideBottom((curr) => [true, curr[1]]);
      const cancel = callAfterAnimation(
        () => setHideBottom((curr) => [false, curr[1]]),
        ANIMATION_DURATION,
      );
      prevLength.current = toastInfoList.length;
      return () => {
        cancel();
        setHideBottom((curr) => [false, curr[1]]);
      };
    }
    prevLength.current = toastInfoList.length;

    // If there is only one toast, or we delete down to one toast, hide controls
    if (toastInfoList.length === 1) {
      setControlsVisibility(false);
    }
    // If the toast list is expanded and a second toast appears, show controls
    if (toastInfoList.length > 1 && !collapsed) {
      setControlsVisibility(true);
    }

    return () => { };
  }, [collapsed, toastInfoList.length]);

  // Set the transitions to be applied only after the initial render.
  useEffect(() => callAfterAnimation(() => setApplyTransitions(true), ANIMATION_DURATION), []);

  // When collapsing the stack, don't apply the clip-path immediately. Wait until the stack
  // is collapsed first before hiding the bottom of the third toast to avoid jumpiness.
  useEffect(() => {
    if (collapsed) {
      callAfterAnimation(() => setHideBottom((curr) => [curr[0], true]), ANIMATION_DURATION);
    } else {
      setHideBottom([false, false]);
    }
  }, [collapsed]);

  // Reset the positions of each toast whenever collapsed is toggled or props change.
  useEffect(() => {
    updatePositions();
  }, [collapsed, updatePositions, toastInfoList]);

  // Reset listRef.current.length if the list is empty.
  useEffect(() => {
    if (toastInfoList.length === 0) {
      listRef.current.length = 0;
    }
  }, [toastInfoList.length]);

  // Check if the toasts exceed the height of their container. If they do, allow pointer
  // events on the toaster root to allow scrolling.
  // Otherwise, pointer events on the root should pass through/be disabled.
  useEffect(() => {
    if (!toastInfoList.length || collapsed) {
      setAllowPointerEvents(false);
    } else {
      let total = listRef.current.reduce((all, curr) => all + (curr.offsetHeight || 0), 0);
      total += (GAP_SIZE * (listRef.current.length - 1));
      setAllowPointerEvents((rootRef.current?.offsetHeight || 0) < total);
    }
  }, [collapsed, toastInfoList.length]);

  // Event handlers
  const handleClick = () => {
    if (toastInfoList.length > 1) {
      setControlsVisibility(collapsed);
    }
    setCollapsed((curr) => !curr);
  };

  const onDismiss = (
    index: number,
    toastInfo: ToastPropsWithId,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event?.stopPropagation();
    setTransform(listRef.current[index], 'translateX', 0, 'px');
    listRef.current[index]?.style.setProperty('opacity', '0');
    // Once the toast has left the screen, dismiss it for real.
    callAfterAnimation(() => toastInfo.onDismiss(event), ANIMATION_DURATION);
  };

  const onDismissAll = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // Dismiss all toasts
    toastInfoList.forEach((toastInfo, index) => onDismiss(index, toastInfo, event));
    // Set state to collapsed and hide controls after toasts are gone
    callAfterAnimation(handleClick, ANIMATION_DURATION);
  };

  const rootContent = (
    <>
      <div
        className={cx('toasterRoot', { collapsed })}
        data-testid="toasterRoot"
        ref={rootRef}
        style={{
          gap: GAP_SIZE,
          maxHeight: `${maxHeight}`,
          height: `${maxHeight}`,
          bottom: `${bottom}px`,
          left: `${left}px`,
          width: `${width}px`,
          pointerEvents: allowPointerEvents ? 'auto' : 'none',
        }}>
        {toastInfoList.map((toastInfo, index) => (
          <div
            aria-hidden
            className={cx('toastWrapper', {
              applyTransitions,
              hideBottom:
                collapsed && ((index === 1 && hideBottom[0]) || (index === 2 && hideBottom[1])),
            })}
            data-testid="toastWrapper"
            key={toastInfo.id}
            onClick={handleClick}
            onKeyDown={(event) => {
              if (isUnmodifiedEnterKey(event)) {
                handleClick();
              }
            }}
            ref={(el) => listRefCallback(el, index)}
            style={{
              zIndex: 150 - index,
              left: `${-width}px`,
            }}
            tabIndex={-1}>
            <Toast
              {...toastInfo}
              expiry={
                !collapsed || index === 0 ?
                  toastInfo.expiry :
                  new Date(Date.now() + SECONDS_IN_DAY * 1000)
              }
              key={toastInfo.id}
              onDismiss={(
                event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
              ) => onDismiss(index, toastInfo, event)}
            />
          </div>
        ))}
      </div>
      <div
        className={cx('toasterControls')}
        ref={controlsRef}
        style={{
          bottom: `calc(${bottom}px - 30px)`,
          left: `${left}px`,
          width: `${width}px`,
        }}>
        <button data-testid="toastControlCollapse" onClick={() => handleClick()} type="button">
          Collapse
        </button>
        <button
          data-testid="toastControlClearAll"
          onClick={(event) => {
            event.stopPropagation();
            onDismissAll(event);
          }}
          type="button">
          Clear All
          <XIcon maxHeight={8} maxWidth={8} />
        </button>
      </div>
    </>
  );

  return portal && toasterNode ? ReactDOM.createPortal(rootContent, toasterNode) : rootContent;
};
