// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 18;
export const EnvelopeIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="envelope"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0,1.59
           L0,16.41
           L7.41,9
           L0,1.59Z
           M22.41,0
           L1.59,0
           L12,10.41
           L22.41,0Z
           M12.795,12.795
           C12.585,13.005 12.315,13.125 12,13.125
           C11.685,13.125 11.415,13.005 11.205,12.795
           L9,10.59
           L1.59,18
           L22.41,18
           L15,10.59
           L12.795,12.795Z
           M16.59,9
           L24,16.41
           L24,1.59
           L16.59,9Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
