// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { HTMLProps } from 'react';

import cx from 'classnames';

import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  ({ spacing }) => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
      '& + &': {
        marginTop: spacing(3),
      },
    },
    horizontal: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
  }),
  { name: 'FormGroup' },
);

export interface FormGroupProps extends HTMLProps<HTMLDivElement> {
  horizontal?: boolean;
}

/**
 * Used for grouping a form field with eg: a label, help text, etc. Provides
 * consistent spacing when using multiple FormGroups.
 */
const FormGroup = (props: FormGroupProps) => {
  const { children, horizontal, ...htmlProps } = props;
  const classes = useStyles();

  return (
    <div className={cx(classes.root, htmlProps.className, { [classes.horizontal]: horizontal })}>
      {props.children}
    </div>
  );
};

export default FormGroup;
