// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';

/**
 * Connected: The server is connected and ready to accept requests.
 * Busy: Either the server is busy or the connection is being established (i.e. we don't
 * distinguish between both states).
 * Disconnected: the server has dropped the streaming RPC connection.
 */
export type GeometryServerStatus = 'connected' | 'busy' | 'disconnected';

// Used to track the state of the server associated with a given geometryId.
export const geometryServerStatusAtom = atomFamily<GeometryServerStatus, string>({
  key: 'geometryServerStatusAtom',
  // Default to busy so that we don't show a reconnect button when starting the geometry streaming
  // RPC.
  default: 'busy',
});

export function useSetGeometryServerStatus(geometryId: string) {
  return useSetRecoilState(geometryServerStatusAtom(geometryId));
}

export function useGeometryServerStatus(geometryId: string) {
  return useRecoilState(geometryServerStatusAtom(geometryId));
}

export function useIsGeometryServerActive(geometryId: string) {
  const [status] = useGeometryServerStatus(geometryId);
  return status === 'connected';
}

export type BusyState = {
  message: string;
  BusyStateType: {
    value: { featureId?: string };
    case: geometryservicepb.SubscribeGeometryResponse_BusyState['BusyStateType']['case'];
  }
}

export const emptyBusyState: BusyState = {
  message: '',
  BusyStateType: {
    value: {},
    case: undefined,
  },
};

// Geometry busy state. One of: FeatureProgress, FeatureTessellation, UndoRedo, Reloading,
// DeleteFeature
export const geometryBusyState = atomFamily<BusyState, string>({
  key: 'geometryBusyState',
  default: emptyBusyState,
});

export function useGeometryBusyState(geometryId: string) {
  return useRecoilState(geometryBusyState(geometryId));
}
/**
 * Updates the geometry server status and wipe the busy state if needed.
 */
export function useUpdateGeometryServerStatus(geometryId: string) {
  const setGeometryServerStatus = useSetGeometryServerStatus(geometryId);
  const [, setGeometryBusyState] = useGeometryBusyState(geometryId);

  return (status: GeometryServerStatus) => {
    if (status !== 'busy') {
      setGeometryBusyState(emptyBusyState);
    }
    setGeometryServerStatus(status);
  };
}

export function useIsGeoServerCreatingFeature(geometryId: string) {
  const geoBusyState = useRecoilValue(geometryBusyState(geometryId));
  return geoBusyState?.BusyStateType?.case === 'featureProgress';
}

export function useIsGeoServerTessellating(geometryId: string) {
  const geoBusyState = useRecoilValue(geometryBusyState(geometryId));
  return geoBusyState?.BusyStateType?.case === 'featureTessellation';
}

export function useIsGeoServerUndoRedo(geometryId: string) {
  const geoBusyState = useRecoilValue(geometryBusyState(geometryId));
  return geoBusyState?.BusyStateType?.case === 'undoRedo';
}

export function useIsGeoServerReloading(geometryId: string) {
  const geoBusyState = useRecoilValue(geometryBusyState(geometryId));
  return geoBusyState?.BusyStateType?.case === 'reloading';
}

export function useIsGeoServerDeleting(geometryId: string) {
  const geoBusyState = useRecoilValue(geometryBusyState(geometryId));
  return geoBusyState?.BusyStateType?.case === 'deleteFeature';
}

// Message attached to the geometry busy state.
const geometryBusyMessageSelector = selectorFamily<string, string>({
  key: 'geometryBusyMessage',
  get: (geometryId: string) => ({ get }) => {
    const geoBusyState = get(geometryBusyState(geometryId));
    return geoBusyState?.message || '';
  },
});

export const useGeometryBusyMessageSelector = (geometryId: string) => (
  useRecoilValue(geometryBusyMessageSelector(geometryId))
);
