// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useRef } from 'react';

import assert from '../../../../lib/assert';
import { useGeometryServerStatus } from '../../../../recoil/geometry/geometryServerStatus';
import { DEFAULT_SELECTED_FEATURE, DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE, useGeometrySelectedFeature, useGeometryState } from '../../../../recoil/geometry/geometryState';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { CircularLoader } from '../../../visual/CircularLoader';
import { EmptyPropPanel } from '../Empty';

import { GeometryModificationBooleanPropPanel } from './GeometryModificationBoolean';
import { GeometryModificationDeleteBodyPropPanel } from './GeometryModificationDeleteBody';
import { GeometryModificationImportPropPanel } from './GeometryModificationImport';
import { GeometryModificationImprintPropPanel } from './GeometryModificationImprint';
import { GeometryModificationPatternPropPanel } from './GeometryModificationPattern';
import { GeometryModificationShapePropPanel } from './GeometryModificationShape';
import { GeometryModificationShrinkwrapPropPanel } from './GeometryModificationShrinkwrap';
import { GeometryModificationTransformPropPanel } from './GeometryModificationTransform';

/**
 * Factory pattern used to instantiate the prop panels of the different modification types of a
 * geometry.
 */
export const GeometryModificationPropPanel = () => {
  // == Contexts
  const { projectId, geometryId } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected geometry modification row');

  // == Recoil
  const [geometryServerStatus] = useGeometryServerStatus(geometryId);
  const geometryState = useGeometryState(projectId, geometryId);
  const [selectedFeature] = useGeometrySelectedFeature(geometryId);

  // == Derived data
  const mod = geometryState?.geometryFeatures.find((feature) => feature.id === node.id);
  const shutdownRef = useRef(geometryServerStatus);

  useEffect(() => {
    shutdownRef.current = geometryServerStatus;
  }, [geometryServerStatus]);

  if (mod === undefined) {
    return <EmptyPropPanel />;
  }

  // This avoids some transient artifacts when the prop panel after clicking apply.
  if (selectedFeature === DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE ||
    selectedFeature === DEFAULT_SELECTED_FEATURE) {
    return <></>;
  }

  // Disallow interacting with the prop panels if the server is busy. This helps to avoid concurrent
  // updates.
  if (geometryServerStatus === 'busy') {
    return <CircularLoader size={40} />;
  }

  if (geometryServerStatus === 'disconnected') {
    return (
      <>
        <div>
          The server is disconnected.
        </div>
      </>
    );
  }

  switch (mod.operation.case) {
    case 'create':
      return <GeometryModificationShapePropPanel />;
    case 'farfield':
      return <GeometryModificationShapePropPanel />;
    case 'boolean':
      return <GeometryModificationBooleanPropPanel />;
    case 'imprint':
      return <GeometryModificationImprintPropPanel />;
    case 'import':
      return <GeometryModificationImportPropPanel />;
    case 'delete':
      return <GeometryModificationDeleteBodyPropPanel />;
    case 'transform':
      return <GeometryModificationTransformPropPanel />;
    case 'shrinkwrap':
      return <GeometryModificationShrinkwrapPropPanel />;
    case 'pattern':
      return <GeometryModificationPatternPropPanel />;
    default:
      throw Error('Not supported');
  }
};
