// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { colors } from '../../../../../lib/designSystem';
import { formatNumberCondensed } from '../../../../../lib/number';
import { createStyles, makeStyles } from '../../../../Theme';
import { Dialog } from '../../../../dialog/Base';
import { useGetAMRSchedule } from '../../../../hooks/useMesh';
import { DiskInfoIcon } from '../../../../svg/DiskInfoIcon';

const useStyles = makeStyles(
  (theme) => createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      flexGrow: '1',
      color: colors.mediumEmphasisText,
      justifyContent: 'center',
    },
    table: {
      borderCollapse: 'collapse',
    },
    tableHeader: {
      minWidth: '108px',
      padding: '6px 8px',
      textAlign: 'start',
      color: colors.lowEmphasisText,
      fontSize: '12px',
      fontWeight: 400,
    },
    tableCell: {
      padding: '6px 8px',
      fontSize: '12px',
      color: colors.neutral900,
    },
    borderBottom: {
      borderBottomColor: colors.neutral350,
      borderBottom: '1px solid',
    },
    centerColumnBorder: {
      borderRightColor: colors.neutral350,
      borderLeftColor: colors.neutral350,
      borderRight: '1px solid',
      borderLeft: '1px solid',
    },
    card: {
      display: 'flex',
      gap: '8px',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: colors.neutral250,
      padding: '8px',
      color: colors.lowEmphasisText,
    },
    icon: {
      width: '16px',
      height: '16px',
    },
    cardText: {
      margin: 0,
      fontSize: '12px',
    },
  }),
  { name: 'AdaptationDetailsModal' },
);

interface AdaptationDetailsModalProps {
  open: boolean;
  onClose: () => void;
}

export const AdaptationDetailsModal = (props: AdaptationDetailsModalProps) => {
  const { open, onClose } = props;
  const classes = useStyles();
  const { getAMRSchedule } = useGetAMRSchedule();
  const schedule = getAMRSchedule();

  return (
    <Dialog
      modal
      onClose={onClose}
      open={open}
      secondaryButtons={[
        {
          key: 'done',
          label: 'Done',
          onClick: onClose,
        },
      ]}
      title="Adaptation Details"
      width="440px">
      <div className={classes.container}>
        <table className={classes.table}>
          <thead>
            <tr className={classes.borderBottom}>
              <th className={classes.tableHeader}>Iteration</th>
              <th className={cx(classes.tableHeader, classes.centerColumnBorder)}>Mesh</th>
              <th className={classes.tableHeader}>Size</th>
            </tr>
          </thead>
          <tbody>
            {schedule.map((i) => (
              <tr className={classes.borderBottom} key={i.name}>
                <td className={classes.tableCell}>{i.iteration}</td>
                <td className={cx(classes.tableCell, classes.centerColumnBorder)}>{i.name}</td>
                <td className={classes.tableCell}>{`${formatNumberCondensed(i.size)} CVs`}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className={classes.card}>
          <div className={classes.icon}>
            <DiskInfoIcon color={colors.primaryCta} maxHeight={12} maxWidth={12} />
          </div>
          <p className={classes.cardText}>
            To change how often remeshing occurs, adjust either the base mesh size or the maximum
            size for adaptation.
          </p>
        </div>
      </div>
    </Dialog>
  );
};
