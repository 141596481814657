// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { ReactElement, useEffect } from 'react';

import * as Sentry from '@sentry/react';

import { ActionLink } from '../components/Button/ActionLink';
import EmptyState from '../components/EmptyState';
import BaseLayout from '../components/layout/BaseLayout';
import { formatDate } from '../lib/formatDate';
import { projectsLink } from '../lib/navigation';
import { Logger } from '../lib/observability/logs';
import { isProjectAccessDeniedError, isProjectDeletedError } from '../lib/projectUtilsErrors';
import useAccountInfo from '../recoil/useAccountInfo';
import { useIsStaff } from '../state/external/user/frontendRole';

interface ErrorPageProps {
  /**
   * The uncaught error to handle. Only shown in dev.
   */
  error: Error;
}

const logger = new Logger('ErrorPage');

const baseSupportLink = 'https://support.luminarycloud.com/hc/en-us/requests/new';

const makeSupportLink = (userId: string | undefined) => {
  // The time when this error page first renders. We don't get the timestamp
  // from an error, so this is an approximation of when the error occurred
  const time = formatDate(Date.now() / 1000);

  // TODO (LC-11299): Make this the trace id or projectId/workflowId
  // once we start sanitizing messages on the backend.
  const description = `User ID: ${userId ?? 'not found'}. Approximate time: ${time}`;
  const subject = 'Luminary Application Crash';
  return `${baseSupportLink}?tf_subject=${subject}&tf_description=${description}`;
};

const getTitle = (error: string) => {
  if (isProjectAccessDeniedError(error)) {
    return 'You need permission to view this project';
  }

  if (isProjectDeletedError(error)) {
    return 'This project is no longer available';
  }

  return 'The Luminary Cloud platform encountered a problem.';
};

const getSubtitle = (error: string, mainLink: ReactElement, reportLink: ReactElement) => {
  if (isProjectAccessDeniedError(error)) {
    return (
      <div>
        Return to the {mainLink} or if the problem persists,<br />
        you can {reportLink}.
      </div>
    );
  }

  if (isProjectDeletedError(error)) {
    return (
      <div>
        This project has been deleted by its owner.
        Return to the {mainLink} or if the problem persists, you can {reportLink}.
      </div>
    );
  }

  return (
    <div>
      Please try refreshing your browser or return to the {mainLink}.
      If the problem persists, you can {reportLink}.
    </div>
  );
};

/**
 * Fallback page displayed when an uncaught error occurs in the app.
 */
const ErrorPage = ({ error }: ErrorPageProps) => {
  const isStaff = useIsStaff();
  const accountInfo = useAccountInfo();
  const userId = accountInfo?.user[0].lcUserId;
  const userEmail = accountInfo?.user[0].email;
  // For the projects link, we should reload the page (and not use the Link or navigate()) because
  // the error boundary causes the whole tree to be unmounted. This can be alleviated if we call
  // resetErrorBoundary (which would rerender the tree), but then, depending on the error,
  // we may see a bunch of "Failed to get session state", "Failed to get project session state" and
  // "SessionState failed" errors. Reloading the page clears them all.
  const mainLink = <ActionLink href={projectsLink()} reload>projects page</ActionLink>;
  const reportLink = <ActionLink href={makeSupportLink(userId)}>report this issue</ActionLink>;

  useEffect(() => {
    logger.error(
      `The Luminary app crashed. UserId: ${userId}, Email: ${userEmail}. Error
      message: ${error.message}. Stack trace: ${error.stack}`,
    );
    Sentry.captureException(error);
  }, [userId, userEmail, error]);

  return (
    <BaseLayout centered title="Error">
      <EmptyState
        icon={{ name: 'refresh', maxHeight: 24 }}
        subtitle={(
          <>
            {getSubtitle(error.message, mainLink, reportLink)}
            {isStaff && (
              <div style={{ paddingTop: '20px' }}>
                The following error occurred (this is visible to LC staff only): {error.message}
              </div>
            )}
          </>
        )}
        title={getTitle(error.message)}
      />
    </BaseLayout>
  );
};
export default ErrorPage;
