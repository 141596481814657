// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 18;
const baseHeight = 18;
export const ArrowDownLeftIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="arrowDownLeft"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M18,1.5
           C18,0.675 17.325,0 16.5,0
           C16.08,0 15.705,0.165 15.435,0.435
           L3,12.885
           L3,6
           C3,5.175 2.325,4.5 1.5,4.5
           C0.675,4.5 0,5.175 0,6
           L0,16.5
           C0,17.325 0.675,18 1.5,18
           L12,18
           C12.825,18 13.5,17.325 13.5,16.5
           C13.5,15.675 12.825,15 12,15
           L5.115,15
           L17.55,2.565
           C17.835,2.295 18,1.92 18,1.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
