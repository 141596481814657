// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as basepb from '../proto/base/base_pb';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as lcmeshpb from '../proto/lcn/lcmesh_pb';
import * as workflowpb from '../proto/workflow/workflow_pb';

import computeBackoff from './backoff';
import { Logger } from './observability/logs';
import * as rpc from './rpc';
import { stringifyError } from './status';

const logger = new Logger('WorkflowConfigValidator');

// A helper class for issuing ValidateWorkflowConfig RPCs.  This is designed to be a
// singleton. There should be a single validator object for each project setup
// page.
//
// The newParam() method will kick off an RPC, and the result will be
// communicated by the onResult callback. This class guarantees that at most one
// RPC is in flight.
export default class WorkflowConfigValidator {
  private rpcRunning = false;
  private req = new frontendpb.ValidateWorkflowConfigRequest();
  private hasNewParam = false;
  private nRetry = 0;
  private retryTimer: ReturnType<typeof setTimeout> | null = null;

  constructor(private readonly onResult: (err: basepb.Status) => Promise<void>) { }

  // Stop must be called once to destroy this object.  No other method shall be
  // called after stop(). It is typically called when the component is unmounted.
  stop(): void {
    this.hasNewParam = false;
    if (this.retryTimer) {
      clearTimeout(this.retryTimer);
      this.retryTimer = null;
    }
  }

  // Runs the validator using an async rpc. Allows to use await to sync the result
  // of the validator. Should not be used in conjunction with newParam.
  async checkAsync(
    config: workflowpb.Config,
    meshMetadata: lcmeshpb.MeshFileMetadata,
  ): Promise<void> {
    this.req.config = config;
    this.req.meshMeta = meshMetadata;
    const reply = await rpc.callRetry(
      'ValidateWorkflowConfig',
      rpc.client.validateWorkflowConfig,
      this.req,
    );
    await this.onResult(reply.error!);
  }

  // newParam notifies the validator that a new parameter has been generated by
  // the user. It issues an RPC in the background and invokes onResult when the
  // result comes back. Note that newParam and onResult calls may not match one
  // to one. In particular, if newParam is called multiple times while an RPC is
  // already in flight, only the last parameter will be validated and reported
  // through onResult.
  newParam(
    config: workflowpb.Config,
    meshMetadata: lcmeshpb.MeshFileMetadata,
  ): void {
    this.req.config = config;
    this.req.meshMeta = meshMetadata;
    this.hasNewParam = true;
    if (!this.rpcRunning) {
      this.start();
    }
  }

  private start(): void {
    if (this.rpcRunning) {
      throw Error('rpcRunning');
    }
    if (this.retryTimer) {
      clearTimeout(this.retryTimer);
      this.retryTimer = null;
    }
    this.rpcRunning = true;
    this.hasNewParam = false;
    logger.debug('ValidateWorkflowConfig: start');
    rpc.callRetry('ValidateWorkflowConfig', rpc.client.validateWorkflowConfig, this.req).then(
      (reply: frontendpb.ValidateWorkflowConfigReply) => {
        logger.debug(`ValidateWorkflowConfig: done ${reply.toJsonString()}`);
        this.nRetry = 0;
        if (!this.rpcRunning) {
          throw Error('!rpcRunning');
        }
        this.rpcRunning = false;
        if (this.hasNewParam) {
          this.start();
        }
        this.onResult(reply.error!).catch(
          (err: Error) => {
            throw Error(`Error on result ${err.message}`);
          },
        );
      },
    )
      .catch((err: Error) => {
        this.nRetry += 1;
        logger.warn(`ValidateWorkflowConfig: error ${stringifyError(err)}`);
        if (this.retryTimer) {
          throw Error('retry');
        }
        this.retryTimer = setTimeout(() => {
          if (!this.rpcRunning) {
            throw Error('!rpcRunning');
          }
          if (!this.retryTimer) {
            throw Error('!retry');
          }
          this.retryTimer = null;
          this.rpcRunning = false;
          this.start();
        }, computeBackoff(this.nRetry));
      });
  }
}

/**
 * The maximum character length to be shown in a validator message. Excess will be
 * truncated and replaced with '...'
  */
const MAX_VALIDATOR_MSG_LENGTH = 700;

/**
 * The message returned by the validator usually looks something like
 * 'protoutil.go:713: Invalid luminary.proto.workflow.Config:
 * luminary.proto.client.BoundaryConditionsFluid.total_temperature:
 * value -3000.000000 not in range (0.000000,∞)'
 *
 * getValidatorMessage returns a message that looks much less like a raw stack trace.
 * We can trust that the error message from WorkflowConfigValidator is not sensitive info.
 * It is explicitly returned in the ValidateWorkflowReply and not as a grpc error to be caught.
 */
export const formatValidatorMessage = (err: basepb.Status) => {
  const errorMsg = err.message.replace(
    // remove stack trace lines (ex. protoutil.go:713:)
    /.+\.go:\d+:/g,
    '',
  ).replace(
    'Invalid luminary.proto.workflow.Config:',
    '',
  ).replace(
    // remove luminary.proto. prefix from error locations
    /luminary\.proto\./g,
    '',
  );

  const msg = `One or more errors occurred when validating your workflow: ${errorMsg}`;
  if (msg.length > MAX_VALIDATOR_MSG_LENGTH) {
    return msg.substring(0, MAX_VALIDATOR_MSG_LENGTH).concat('…');
  }
  return msg;
};
