// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { GetWorkflowReply, WorkflowMetadata } from '../proto/frontend/frontend_pb';

/**
 * Determine whether a given workflow is a baseline or an experiment.
 * Workflows with empty `experiment` values are baselines.
 */
//
// TODO(saito) change the arg to be workflowpb.Config.
export function isBaseline(workflow: WorkflowMetadata | GetWorkflowReply): boolean {
  return workflow.config!.exploration!.policy.case === 'baseline';
}

/**
 * Determine whether a given workflow is a sensitivity analysis.
 */
export function isSensitivityAnalysis(workflow: WorkflowMetadata | GetWorkflowReply): boolean {
  return workflow.config!.exploration!.policy.case === 'sensitivityAnalysis';
}

/**
 * Given a workflow id, find the matching workflow in a list if it exists.
 */
export function findWorkflow(
  workflowId: string,
  workflows: WorkflowMetadata[],
): WorkflowMetadata | null {
  return workflows.find((workflow) => workflow.workflowId === workflowId) || null;
}

// Return an unspecified job ID in the workflow, or null if the workflow is not
// set. Used when the user hasn't picked a job to display yet.
export function pickAnyJobId(workflow: GetWorkflowReply): string | null {
  if (!workflow) {
    return null;
  }
  const jobIds = Object.keys(workflow.job);
  const jobIdIterator = jobIds[Symbol.iterator]();
  const iter = jobIdIterator.next();
  return iter.done ? null : iter.value;
}
