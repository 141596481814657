// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useLayoutEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import { isInExploration } from '../../lib/navigation';
import useResizeObserver from '../../lib/useResizeObserver';
import { VIEWER_PADDING } from '../../lib/visUtils';
import { isSensitivityAnalysis } from '../../lib/workflowUtils';
import { useActiveVisUrlValue } from '../../recoil/vis/activeVisUrl';
import { useWorkflowState } from '../../recoil/workflowState';
import { useSetToolbarMaxWidth } from '../../state/internal/component/toolbar';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { createStyles, makeStyles } from '../Theme';
import GeometryToolbar from '../Toolbar/GeometryToolbar';
import Toolbar from '../Toolbar/Toolbar';
import { useProjectContext } from '../context/ProjectContext';

import { useToolbarMargin } from './hooks/useFloatingToolbarMargin';

const useStyles = makeStyles(() => createStyles({
  root: {
    position: 'absolute',
    top: `${VIEWER_PADDING}px`,
    // Should be lower than Paraview's colorDialog zIndex and higher than StatusOverlay's zIndex
    zIndex: 3,
    // Firefox shows scrollbar on top of element so we add enough height for toolbar + scrollbar
    height: '45px',
    overflow: 'hidden',

    '&:hover': {
      overflowX: 'auto',
    },
  },
  groups: {
    // Center the groups horizontally in the root container
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    gap: `${VIEWER_PADDING}px`,
  },
}), { name: 'FloatingToolbar' });

// The component will be shown in the 3d viewer, horizontally centered at the top.
// If there are any left panes (status, outputs, pending workorders) or right panes (color settings)
// the toolbar will have more left/right margin so that they don't overlap.
const FloatingToolbar = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Hooks
  const classes = useStyles();
  const location = useLocation();

  // == Recoil
  const isGeometryView = useIsGeometryView();

  const workflow = useWorkflowState(projectId, workflowId);
  const activeVisUrl = useActiveVisUrlValue({ projectId, workflowId, jobId });
  const setToolbarWidth = useSetToolbarMaxWidth({ projectId, workflowId, jobId });

  // == Data
  const rootRef = useRef<HTMLDivElement>(null);
  const rootWidth = useResizeObserver(rootRef).width;
  const isExploration = isInExploration(location.pathname);
  const isSensitivity = isExploration && !!workflow && isSensitivityAnalysis(workflow);

  const showBars = (!!activeVisUrl && !isSensitivity) || isGeometryView;

  const toolbar = showBars && (
    <Toolbar />
  );
  const rootStyles = useToolbarMargin();

  useLayoutEffect(() => {
    setToolbarWidth(rootWidth);
  }, [rootWidth, setToolbarWidth]);

  return (
    <div className={classes.root} data-locator="floatingToolbar" ref={rootRef} style={rootStyles}>
      <div className={classes.groups}>
        {isGeometryView ? <GeometryToolbar /> : (
          <>
            {toolbar}
          </>
        )}
      </div>
    </div>
  );
};

export default FloatingToolbar;
