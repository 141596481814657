// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LcvModule } from '../../types';
import { LcvFrame } from '../LcvFrame';

import { LcvWidget } from './LcvWidget';

export class LcvTriadWidget extends LcvWidget {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    frame: LcvFrame,
  ) {
    super(lcv, sessionHandle, 'triad');
    this.attachFrame(frame);
  }
}
