// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import cx from 'classnames';

import { isUnmodifiedSpaceKey } from '../../lib/event';
import { createStyles, makeStyles } from '../Theme';

import ColorBar from './ColorBar';

const BAR_HEIGHT = 209;

const useStyles = makeStyles(
  () => createStyles({
    panel: {
      '--panel-flex-direction': 'column',
      '&.vertical': {
        '--panel-flex-direction': 'row',
      },
      cursor: 'pointer',
      flexDirection: 'var(--panel-flex-direction)' as any,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    label: {
      '--label-height': 'auto',
      '--label-text-decoration': 'none',
      '--label-transform': 'rotate(0deg)',
      '--label-writing-mode': `horizontal-tb`,
      '&.vertical': {
        '--label-height': `${BAR_HEIGHT}px`,
        '--label-transform': 'rotate(180deg)',
        '--label-writing-mode': `vertical-rl`,
      },
      '&.highlighted': {
        '--label-text-decoration': 'underline',
      },
      height: 'var(--label-height)',
      textDecoration: 'var(--label-text-decoration)',
      transform: 'var(--label-transform)',
      writingMode: 'var(--label-writing-mode)' as any,
      fontSize: '13px',
      lineHeight: 1.0,
      fontWeight: 600,
      textAlign: 'center',
    },
  }),
  { name: 'ColorBarPanel' },
);

interface ColorBarPanelProps {
  // The name of the color map
  colorMapName: string;
  // The label for the color bar.
  label: string;
  // The number of ticks.
  numTicks: number;
  // The minumum value for the bar.
  min: number;
  // The maximum value for the bar.
  max: number;
  // If the panel is selected.
  selected: boolean;
  // Handles a click on the color bar panel.
  onClick: () => void;
  // The number of bins used to discretize the colormap.
  bins: number;
  // Boolean indicating if this color bar should be rendered.
  hidden?: boolean;
  // Boolean indicating if colorbar is vertical
  isVertical: boolean;
}

// A panel showing a color bar and a label. The panel can be hovered and
// clicked.
const ColorBarPanel = (props: ColorBarPanelProps) => {
  const classes = useStyles();
  const [hovered, setHovered] = useState<boolean>(false);
  const highlighted = props.selected || hovered;

  if (props.hidden) {
    return (null);
  }

  return (
    <div
      className={cx(classes.panel, { vertical: props.isVertical })}
      onClick={props.onClick}
      onKeyUp={(event) => {
        if (isUnmodifiedSpaceKey(event)) {
          props.onClick();
        }
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      role="button"
      tabIndex={0}>
      <div className="bar">
        <ColorBar
          bins={props.bins}
          colorBounds={[]}
          colorMapName={props.colorMapName}
          highlighted={highlighted}
          isVertical={props.isVertical}
          length={BAR_HEIGHT}
          maxValue={props.max}
          minValue={props.min}
          numTicks={props.numTicks}
        />
      </div>
      <div
        className={cx(classes.label, { vertical: props.isVertical, highlighted })}>
        {props.label}
      </div>
    </div>
  );
};

export default ColorBarPanel;
