// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const RingXIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="ringX"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M17.989,7.496
           C17.989,6.671 17.315,5.997 16.49,5.997
           C16.07,5.997 15.696,6.162 15.426,6.431
           L11.993,9.879
           L8.56,6.446
           C8.29,6.162 7.915,5.997 7.495,5.997
           C6.671,5.997 5.996,6.671 5.996,7.496
           C5.996,7.916 6.161,8.29 6.431,8.56
           L9.864,11.993
           L6.431,15.426
           C6.161,15.696 5.996,16.071 5.996,16.49
           C5.996,17.315 6.671,17.99 7.495,17.99
           C7.915,17.99 8.29,17.825 8.56,17.555
           L11.993,14.122
           L15.426,17.555
           C15.696,17.825 16.07,17.99 16.49,17.99
           C17.315,17.99 17.989,17.315 17.989,16.49
           C17.989,16.071 17.824,15.696 17.555,15.426
           L14.121,11.993
           L17.555,8.56
           C17.824,8.29 17.989,7.916 17.989,7.496Z
           M11.993,0
           C5.367,0 0,5.367 0,11.993
           C0,18.619 5.367,23.986 11.993,23.986
           C18.619,23.986 23.986,18.619 23.986,11.993
           C23.986,5.367 18.619,0 11.993,0Z
           M11.994,20.988
           C7.032,20.988 2.999,16.955 2.999,11.993
           C2.999,7.031 7.032,2.998 11.994,2.998
           C16.956,2.998 20.988,7.031 20.988,11.993
           C20.988,16.955 16.971,20.988 11.994,20.988Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
