// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import assert from '../../../lib/assert';
import { getParticleGroupImposter } from '../../../lib/imposterFilteringUtils';
import {
  getProbePoints,
  getProbePointsParticleGroup,
  getProbePointsTable,
} from '../../../lib/particleGroupUtils';
import { ProbePointsTableModel } from '../../../lib/rectilinearTable/model';
import { Vector3 } from '../../../proto/base/base_pb';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { QuantityType } from '../../../proto/quantity/quantity_pb';
import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import LabeledInput from '../../Form/LabeledInput';
import { Vector3Input } from '../../Form/Vector3Input';
import { CollapsibleNodePanel } from '../../Panel/CollapsibleNodePanel';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import Divider from '../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useSimulationConfig } from '../../hooks/useSimulationConfig';
import PropertiesSection from '../PropertiesSection';

import { ParticleGroupMetaPanel } from './shared/ParticleGroupMetaPanel';

// A panel displaying all the settings for the selected probe point node.
export const ProbePointPropPanel = () => {
  const { readOnly } = useProjectContext();
  const { addNode, activeEdit, viewState } = useParaviewContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected probe point row');

  const [imposter, setImposter] = useState<ParaviewRpc.TreeNode | null>(null);
  const propClasses = useCommonTreePropsStyles();

  const { simParam, saveParam } = useSimulationConfig();
  const particleGroup = getProbePointsParticleGroup(simParam);

  useEffect(() => {
    setImposter(getParticleGroupImposter(
      particleGroup,
      node.id,
      viewState,
      simParam,
      addNode,
    ));
  }, [viewState, particleGroup, node.id, addNode, simParam]);

  const changeValue = (value: Vector3) => {
    const { x, y, z } = value;
    const record = saveParam((newParam) => {
      const table = getProbePointsTable(newParam);
      if (table) {
        // Use a TableModel for easier access
        const tableModel = new ProbePointsTableModel(table);
        const pointRecord = tableModel.getRecord('id', node.id);
        if (pointRecord) {
          pointRecord.setNamedEntry('x', x);
          pointRecord.setNamedEntry('y', y);
          pointRecord.setNamedEntry('z', z);
        }
        return pointRecord;
      }
      return null;
    });

    if (record && imposter) {
      const vParam = imposter.param as ParaviewRpc.MonitorPointParam;
      activeEdit(
        imposter.id,
        {
          ...vParam,
          point: {
            typ: 'Sphere',
            center: { x, y, z },
            radius: vParam.point.radius,
          },
          id: node.id,
        },
      );
    }
  };

  return (
    <div className={propClasses.properties}>
      <ParticleGroupMetaPanel particleGroupType={simulationpb.ParticleGroupType.PROBE_POINTS} />
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Definition"
          nodeId={node.id}
          panelName="definition">
          <LabeledInput
            label="(x, y, z)">
            <Vector3Input
              disabled={readOnly}
              onCommit={changeValue}
              quantityType={QuantityType.LENGTH}
              value={getProbePoints(simParam).find((point) => node.id === point.id)!.vector}
            />
          </LabeledInput>
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
    </div>
  );
};
