// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useRecoilState, useRecoilValue } from 'recoil';

import { getLocalStorageData } from '../../../lib/browserStorage';
import { localStorageEffect } from '../../../lib/persist';

const RIGHT_STORAGE_KEY = 'right-overlay-cards-width';
const DEFAULT_RIGHT_WIDTH = 272;

export const rightOverlayCardsWidth = atom<number>({
  key: 'rightOverlayCardsWidth',
  default: getLocalStorageData<number>(RIGHT_STORAGE_KEY, DEFAULT_RIGHT_WIDTH),
  effects: [
    localStorageEffect(RIGHT_STORAGE_KEY),
  ],
});

export const useRightOverlayCardsWidth = () => useRecoilState(rightOverlayCardsWidth);
export const useRightOverlayCardsWidthValue = () => useRecoilValue(rightOverlayCardsWidth);
