// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { FaultType } from './componentTypes/form';

export type FaultInfo = {
  type: FaultType;
  message?: string;
};

export type InputValidationFunction = (value: number) => FaultInfo | undefined;

/**
 * Input validation function that checks if the value is an integer and strictly greater than 0.
 */
export const validateGtZeroInt = (value: number): FaultInfo | undefined => {
  if (value <= 0) {
    return { type: 'error', message: 'Must be > 0' };
  } if (!Number.isInteger(value)) {
    return { type: 'error', message: 'Must be an integer' };
  }
  return undefined;
};

/**
 * Input validation function that checks if the value is strictly greater than 0.
 */
export const validateGtZero = (value: number): FaultInfo | undefined => {
  if (value <= 0) {
    return { type: 'error', message: 'Must be > 0' };
  }
  return undefined;
};

/**
 * Input validation function that checks if the value is greater than or equal to 0.
 */
export const validateGteZero = (value: number): FaultInfo | undefined => {
  if (value < 0) {
    return { type: 'error', message: 'Must be >= 0' };
  }
  return undefined;
};

/**
 * Input validation function that checks if the value is greater than or equal to 1.
 */
export const validateGteOne = (value: number): FaultInfo | undefined => {
  if (value < 1) {
    return { type: 'error', message: 'Must be >= 1' };
  }
  return undefined;
};
