// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import * as basepb from '../../../../proto/base/base_pb';
import * as quantitypb from '../../../../proto/quantity/quantity_pb';
import LabeledInput from '../../../Form/LabeledInput';
import { Vector3Input } from '../../../Form/Vector3Input';

export interface FrameCoordinatesProps {
  origin: basepb.Vector3;
  orientation: basepb.Vector3;
  setOrigin: (value: basepb.Vector3) => void;
  setOrientation: (value: basepb.Vector3) => void;
  readOnly?: boolean;
  global?: boolean;
}

export const FrameCoordinates = (props: FrameCoordinatesProps) => {
  const {
    global = false,
    orientation,
    origin,
    readOnly = false,
    setOrientation,
    setOrigin,
  } = props;

  return (
    <>
      <LabeledInput
        help={global ? 'Global origin' : 'Origin of frame relative to parent'}
        label="Origin">
        <Vector3Input
          disabled={readOnly}
          onCommit={setOrigin}
          quantityType={quantitypb.QuantityType.LENGTH}
          value={origin}
        />
      </LabeledInput>
      <LabeledInput
        help={global ? 'Global orientation' : 'Orientation of local frame relative to parent'}
        label="Orientation">
        <Vector3Input
          disabled={readOnly}
          onCommit={setOrientation}
          quantityType={quantitypb.QuantityType.DEGREE}
          value={orientation}
        />
      </LabeledInput>
    </>
  );
};
