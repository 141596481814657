// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { escapeRegExp } from './text';

// Encapsulates a search term parsed from a search string
export class SearchTerm {
  private testRe: RegExp;

  constructor(
    // Raw search term from which this object was derived (e.g. '-"foo bar"')
    private rawTerm: string,
    // Text to match (e.g. 'foo bar)
    private matchTerm: string,
    // Whether to exclude rather than include matches
    private negated: boolean,
    // Whether to perform case-sensitive matching
    private caseSensitive: boolean,
    // Optionally scope search (not yet implemented but will be useful for column-based searching
    // of a table, for instance)
    private scope: string | undefined = undefined,
  ) {
    const reFlags = caseSensitive ? '' : 'i';
    this.testRe = new RegExp(escapeRegExp(matchTerm), reFlags);
  }

  // Return true if a list of keywords matches this search term
  public match(keywords: string[]): boolean {
    const matchesSome = keywords.some((keyword) => this.testRe.test(keyword));
    return this.negated ? !matchesSome : matchesSome;
  }
}

type QuoteChar = '"' | "'";

// QuotedContext is maintained as the search text parser traverses a list of space-separated words.
interface QuotedContext {
  // Tracks which quotation character was used to start the quoted context
  quoteChar: QuoteChar;
  // A stack of raw text in the quoted context
  rawTerms: string[];
  // A stack of terms used for matching (quotes, negation, etc. stripped)
  matchTerms: string[];
  // True if the initial quote was preceded by a dash
  negated: boolean;
  // Optional scope
  scope?: string;
}

// Converts a QuotedContext object into a SearchTerm object
function getQuotedSearchTerm(context: QuotedContext, scoped: boolean) {
  const { rawTerms, matchTerms, negated, scope } = context;
  const rawTerm = rawTerms.join(' ');
  const matchTerm = matchTerms.join(' ');

  return new SearchTerm(rawTerm, matchTerm, negated, true, scope);
}

/** Parse a search string according to the following rules
 * A plain word indicates a case-insensitive search.
 *   Ex. foo
 * A quoted word indicates a case-sensitive search.
 *   Ex. "foo bar"
 * Words can be quoted with either single or double quotes.
 *   Exs. "foo bar" 'volume 1'
 * Quote characters that are not at word boundaries are ignored.
 *   Ex. foo"bar (treated as a plain word)
 * A dash in front of a plain or quoted word indicates negation.
 *   Exs. -foo -"foo bar"
 * An alphanumeric prefix followed by a colon followed by text yields a scoped search.
 *   Exs. foo:bar -"foo:bar donut"
 * White space between words, both inside and outside of quotes, is collapsed and treated as a
 * single space.
 *   Ex. "foo\t  \n bar" => "foo bar"
 *
 * Returns an array of SearchTerm objects that capture matching instructions for each "word" in the
 * search string
 */
export function parseSearchString(value: string, scoped = false) {
  const results: SearchTerm[] = [];

  const rawTerms = value.trim().split(/\s+/);

  let quotedContext: QuotedContext | undefined;

  rawTerms.forEach((rawTerm) => {
    if (quotedContext) {
      if (rawTerm.endsWith(quotedContext.quoteChar)) {
        // We've reached the end of the quote.
        quotedContext.rawTerms.push(rawTerm);
        quotedContext.matchTerms.push(rawTerm.slice(0, -1));
        results.push(getQuotedSearchTerm(quotedContext, scoped));
        quotedContext = undefined;
      } else {
        // We're still in the quote, so push the whole raw term onto the stack(s).
        quotedContext.rawTerms.push(rawTerm);
        quotedContext.matchTerms.push(rawTerm);
      }
    } else {
      let scope: string | undefined;
      let negated = false;
      let tempTerm = rawTerm;

      if (/^-+(.*)$/.test(rawTerm)) {
        negated = true;
        tempTerm = RegExp.$1;
      }

      if (scoped && /^(\w+):(.+)$/.test(tempTerm)) {
        scope = RegExp.$1;
        tempTerm = RegExp.$2;
      }

      const firstChar = tempTerm[0];
      const lastChar = tempTerm[tempTerm.length - 1];

      if (firstChar === '"' || firstChar === "'") {
        if (lastChar === firstChar) {
          // Single word, quoted
          if (tempTerm.slice(1, -1)) {
            // Make sure term has length
            results.push(
              new SearchTerm(rawTerm, tempTerm.slice(1, -1), negated, true, scope),
            );
          }
        } else {
          // Start quote
          quotedContext = {
            quoteChar: firstChar,
            rawTerms: [rawTerm],
            matchTerms: [tempTerm.slice(1)],
            negated,
            scope,
          };
        }
      } else {
        results.push(
          new SearchTerm(rawTerm, tempTerm, negated, false, scope),
        );
      }
    }
  });

  if (quotedContext?.matchTerms.length) {
    results.push(getQuotedSearchTerm(quotedContext, scoped));
    quotedContext = undefined;
  }

  return results;
}

// Returns true if EACH search term matches at least one keyword
export function matchSearchTerms(searchTerms: SearchTerm[], keywords: string[]) {
  return searchTerms.every((term) => term.match(keywords));
}
