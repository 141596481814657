// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const WireframeInvertedIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="wireframeInverted"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M12,0
           C5.37,0 0,5.37 0,12
           C0,18.63 5.37,24 12,24
           C18.63,24 24,18.63 24,12
           C24,5.37 18.63,0 12,0Z
           M22.5,12
           C22.5,17.799 17.799,22.5 12,22.5
           C6.201,22.5 1.5,17.799 1.5,12
           C1.5,6.201 6.201,1.5 12,1.5
           C17.799,1.5 22.5,6.201 22.5,12Z
           M16.095,7.501
           L19.755,7.501
           C18.645,5.611 16.875,4.156 14.73,3.466
           C15.33,4.456 15.78,5.836 16.095,7.501Z
           M15,12
           C15,13.095 14.925,14.085 14.82,15
           L9.18,15
           C9.075,14.085 9,13.095 9,12
           C9,10.905 9.075,9.915 9.18,9
           L14.82,9
           C14.925,9.915 15,10.905 15,12Z
           M14.595,7.5
           C14.04,4.65 13.005,3 12,3
           C10.995,3 9.96,4.65 9.405,7.5
           L14.595,7.5Z
           M9.27,3.466
           C8.67,4.456 8.205,5.836 7.905,7.501
           L4.245,7.501
           C5.355,5.611 7.125,4.156 9.27,3.466Z
           M3.525,9
           C3.195,9.945 3,10.95 3,12
           C3,13.05 3.195,14.055 3.525,15
           L7.665,15
           C7.56,14.07 7.5,13.08 7.5,12
           C7.5,10.92 7.56,9.93 7.665,9
           L3.525,9Z
           M4.245,16.5
           L7.905,16.5
           C8.22,18.165 8.67,19.545 9.27,20.535
           C7.125,19.845 5.355,18.39 4.245,16.5Z
           M9.405,16.5
           C9.96,19.35 10.995,21 12,21
           C13.005,21 14.04,19.35 14.595,16.5
           L9.405,16.5Z
           M14.73,20.535
           C15.33,19.545 15.78,18.165 16.095,16.5
           L19.755,16.5
           C18.645,18.39 16.875,19.845 14.73,20.535Z
           M16.335,15
           L20.475,15
           C20.805,14.055 21,13.05 21,12
           C21,10.95 20.805,9.945 20.475,9
           L16.335,9
           C16.44,9.93 16.5,10.92 16.5,12
           C16.5,13.08 16.44,14.07 16.335,15Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
