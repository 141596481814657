// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { MainPageLayout } from '../../components/layout/page/Main';
import { PlatformAdminRoleType } from '../../lib/platformAdminUtils';
import { useEnabledPlatformAdminRoles } from '../../recoil/usePersonalPlatformAdmin';

const PlatformAdminBizopsPageBody = () => {
  const platformAdminRoles = useEnabledPlatformAdminRoles();

  return (
    <MainPageLayout
      permission={!!platformAdminRoles?.includes(PlatformAdminRoleType.BIZOPS)}
      title="Bizops Administration">
      <span>Not implemented</span>
    </MainPageLayout>
  );
};

export default PlatformAdminBizopsPageBody;
