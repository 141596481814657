// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as flags from '../../flags';
import { deleteFarFieldImposter } from '../../lib/imposterFilteringUtils';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as meshgenerationpb from '../../proto/meshgeneration/meshgeneration_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import { useSetCheckedUrls } from '../../recoil/checkedGeometryUrls';
import { useFrontendMenuState } from '../../recoil/frontendMenuState';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useMeshUrlState } from '../../recoil/meshState';
import { useSetPendingWorkOrders } from '../../recoil/pendingWorkOrders';
import { useSetCadModifier } from '../../recoil/useCadModifier';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useProjectContext } from '../context/ProjectContext';

const GET_GEOMETRY = frontendpb.WorkOrderType.GET_GEOMETRY;

/** Special handling for far fields */
export const useFarfield = () => {
  const { paraviewClientState, paraviewRenderer } = useParaviewContext();
  const { projectId } = useProjectContext();

  const experimentConfig = useEnabledExperiments();
  const [frontendMenuState] = useFrontendMenuState(projectId, '', '');
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const [meshUrlState] = useMeshUrlState(projectId);
  const setCadModifier = useSetCadModifier(projectId);
  const setCheckedUrls = useSetCheckedUrls(projectId);
  const setPendingWorkOrders = useSetPendingWorkOrders(projectId);

  // TODO(LC-6917): remove when Parasolid meshing is enabled for all
  const allowParasolid = experimentConfig.includes(flags.parasolidMeshing);
  const lcsurfaceTessellation = experimentConfig.includes(flags.lcsurfaceTessellation);

  // Appends a work order that generates the default geometry (i.e. without any
  // cad modifications)
  const requestDefaultGeometry = (workOrders: frontendpb.PendingWorkOrders) => {
    const newWorkOrders = workOrders.clone();
    const workOrdersMap = newWorkOrders.workOrders;
    if (!workOrdersMap[GET_GEOMETRY]) {
      const workOrder = new frontendpb.PendingWorkOrder({
        typ: {
          case: 'getGeometry',
          value: new frontendpb.GetGeometryRequest({
            projectId,
            userGeo: new meshgenerationpb.UserGeometry({
              url: meshUrlState.url,
              scaling: frontendMenuState.meshScaling,
              allowParasolid,
              lcsurfaceTessellation,
            }),
          }),
        },
      });
      workOrdersMap[GET_GEOMETRY] = workOrder;
    }
    return newWorkOrders;
  };

  const deleteFarfieldGeometry = () => {
    if (lcvisEnabled) {
      lcvHandler.queueDisplayFunction('remove far field', (display) => {
        display.workspace?.removeFarField().catch(
          (error) => console.warn(error),
        );
      });
    }
    setCadModifier(null);
    // Recheck the geometry since it is changing.
    setCheckedUrls((checkedUrls) => {
      const newUrlList = checkedUrls.urls.filter((url) => url !== meshUrlState.url);
      return new projectstatepb.CheckedUrls({
        urls: newUrlList,
        status: projectstatepb.CheckGeometryStatus.UNCHECKED,
      });
    });
    setPendingWorkOrders(requestDefaultGeometry);
  };

  const deleteFarfieldNode = (): boolean => {
    // The farfield geometry has not yet been added to the model. We delete the imposter and reset
    // the camera.
    setCadModifier(null);
    if (lcvisEnabled) {
      lcvHandler.queueDisplayFunction('remove far field', (display) => {
        display.workspace?.removeFarField().catch(
          (error) => console.warn(error),
        );
      });
    } else {
      deleteFarFieldImposter(paraviewClientState);
      paraviewRenderer.resetCamera();
    }
    return true;
  };

  return {
    deleteFarfieldGeometry,
    deleteFarfieldNode,
  };
};
