// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { Empty } from '@bufbuild/protobuf';
import { ConnectError } from '@connectrpc/connect';
import { AtomEffect, atom, selector, useRecoilValue } from 'recoil';

import { platformAdminRoleFixture } from '../lib/fixtures';
import { EMPTY_PLATFORM_ADMIN_ROLE_REPLY } from '../lib/paramDefaults/platformAdminRoles';
import { GetPlatformAdminRole, PlatformAdminRoleType } from '../lib/platformAdminUtils';
import * as rpc from '../lib/rpc';
import { isTestingEnv } from '../lib/testing/utils';
import { addRpcError } from '../lib/transientNotification';
import * as papb from '../proto/platformadmin/platformadmin_pb';

const platformAdminRoleKey = 'platformAdmin';

const rpcPool = new rpc.StreamingRpcPool<
  Empty,
  papb.GetPlatformAdminRoleReply
>('PlatformAdminRole', rpc.client.getPlatformAdminRole);

const fetchPlatformAdminRoles = (): AtomEffect<
  papb.GetPlatformAdminRoleReply
> => ({ setSelf }): (() => void) => rpcPool.start(
  'PlatformAdmin',
  () => new Empty(),
  (reply: papb.GetPlatformAdminRoleReply) => {
    setSelf(reply);
  },
  (err: ConnectError) => {
    addRpcError('PlatformAdmin failed', err);
    setSelf(EMPTY_PLATFORM_ADMIN_ROLE_REPLY);
  },
);

const platformAdminRoleReplyStateRpc = atom<papb.GetPlatformAdminRoleReply>({
  key: platformAdminRoleKey,
  effects: [fetchPlatformAdminRoles()],
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});

const platformAdminRoleReplyTesting = atom<papb.GetPlatformAdminRoleReply>({
  key: `${platformAdminRoleKey}/testing`,
  default: platformAdminRoleFixture(),
  dangerouslyAllowMutability: true,
});

const platformAdminRoleReplyState = isTestingEnv() ?
  platformAdminRoleReplyTesting : platformAdminRoleReplyStateRpc;

const enabledPlatformAdminRoles = selector<PlatformAdminRoleType[]>({
  key: 'enabledPersonalPlatformRoles',
  get: ({ get }) => {
    const tags: PlatformAdminRoleType[] = [];
    const platformRoles = get(platformAdminRoleReplyState);
    platformRoles.roles.forEach((role: papb.PlatformRole) => {
      tags.push(GetPlatformAdminRole(role));
    });
    return tags;
  },
});

/**
 * Returns the platform admin roles for this user. For non-Luminary users this will be an empty
 * list. For most Luminary users it will be empty.  Some Luminary users, there will be roles
 *
 * ADMIN : can grant others these roles
 * SUPPORT: can manage who is on the support team
 * SAMPLE: can manage sample projects
 * BIZOPS: can manage accounts and billing
 */
export const useEnabledPlatformAdminRoles = () => useRecoilValue(enabledPlatformAdminRoles);
