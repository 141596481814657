// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as frontendpb from '../proto/frontend/frontend_pb';

import GroupMap from './GroupMap';
import { NodeGroupMap } from './nodeGroupMap';

// The separator is used to split the camera label into a groupid and groupname parts. It is auto
// generated when we group some cameras and when we construct the groupid⋮⋮⋮groupname string that we
// save into the camera label field. The separator is a special symbol that is unlikely to be used
// in real life by users but in the rare case that they do, we should escape/remove it during group
// renames just to be extra safe. By using the symbol thrice, we also allow the character to be
// normally used by the user.
export const CAMERA_LABEL_SEPARATOR = '⋮⋮⋮';

export function getGroupIdFromLabel(label: string) {
  return label.split(CAMERA_LABEL_SEPARATOR)[0];
}

export function getGroupNameFromLabel(label: string) {
  if (label.includes(CAMERA_LABEL_SEPARATOR)) {
    return label.split(CAMERA_LABEL_SEPARATOR)[1];
  }
  // Initially the label contained just the group name, so if there isn't CAMERA_LABEL_SEPARATOR
  // in the label now, treat the label as both a name and an id to have a backwards compatibility.
  return label;
}

// The camera group should be timestamp-based so that we can sort on their order of creation.
export function generateCameraGroupId() {
  return `group-${new Date().getTime()}`;
}

export function generateCameraLabel({ id, name }: { id: string, name: string }) {
  return `${id}${CAMERA_LABEL_SEPARATOR}${name}`;
}

export function getUpdatedCameraLabel(label: string, newName: string) {
  if (label.includes(CAMERA_LABEL_SEPARATOR)) {
    const oldName = label.split(CAMERA_LABEL_SEPARATOR)[1];
    return label.replace(oldName, newName);
  }
  // Initially the label contained just the group name, so if there isn't CAMERA_LABEL_SEPARATOR
  // in the label now, create a new label that has both an id and a name.
  return generateCameraLabel({ id: generateCameraGroupId(), name: newName });
}

// The camera has a numeric id in the db so we should transform it into a non-conficting
// string which can be used for identifying the node in the control panel.
export function cameraNodeId(cameraIndex: bigint) {
  return `camera-${cameraIndex}`;
}

export const CAMERA_ID_REGEXP = /^camera-(\d+)$/;

export function isValidCameraNodeId(id: string) {
  return CAMERA_ID_REGEXP.test(id);
}

export function cameraIndexFromNodeId(id: string) {
  if (CAMERA_ID_REGEXP.test(id)) {
    return BigInt(parseInt(RegExp.$1, 10));
  }
  throw Error(`Cannot extract camera index from ID ${id}`);
}

export function getParentNodeIdForCamera(
  camera: frontendpb.CameraInfo,
  groupMap: NodeGroupMap,
) {
  return groupMap.getGroups().find(
    (group) => camera.label && group.id === getGroupIdFromLabel(camera.label),
  )?.id || GroupMap.rootId;
}
