// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { AddPhysicsButton } from '../AddPhysicsButton';
import { TreeRow } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'atom' };

export const PhysicsContainerTreeRow = (props: SimulationRowProps) => (
  <TreeRow
    {...props}
    addControl={<AddPhysicsButton />}
    primaryIcon={PRIMARY_ICON}
  />
);
