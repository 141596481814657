// Metadata definition for *.lcsoln files. They store FVM solutions.

// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/lcn/lcsoln.proto (package luminary.proto.lcn, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { QuantityType } from "../quantity/quantity_pb.js";

/**
 * @generated from enum luminary.proto.lcn.AuxSolnFileType
 */
export const AuxSolnFileType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.lcn.AuxSolnFileType",
  [
    {no: 0, name: "INVALID_AUX_SOLN_FILE_TYPE"},
    {no: 2, name: "DISK_SOLN_V1"},
  ],
);

/**
 * min and max range of a given solution field. For non-scalar quantities, the
 * range of the magnitude field will be stored at the end of the min/max arrays.
 *
 * @generated from message luminary.proto.lcn.FieldRange
 */
export const FieldRange = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.FieldRange",
  () => [
    { no: 1, name: "min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
    { no: 2, name: "max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.lcn.AuxiliaryFile
 */
export const AuxiliaryFile = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.AuxiliaryFile",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(AuxSolnFileType) },
  ],
);

/**
 * SolnFileMetadata is stored in FileIndex.metadata.
 *
 * @generated from message luminary.proto.lcn.SolnFileMetadata
 */
export const SolnFileMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.SolnFileMetadata",
  () => [
    { no: 1, name: "mesh_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "zone", kind: "message", T: SolnZoneMetadata, repeated: true },
    { no: 3, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "aux_files", kind: "message", T: AuxiliaryFile, repeated: true },
    { no: 5, name: "iteration", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "simulation_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message luminary.proto.lcn.SolnZoneMetadata
 */
export const SolnZoneMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.SolnZoneMetadata",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bound", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: SolnBoundMetadata} },
    { no: 16, name: "quantity", kind: "enum", T: proto3.getEnumType(QuantityType), repeated: true },
    { no: 3, name: "ranges", kind: "message", T: FieldRange, repeated: true },
    { no: 4, name: "quantity_fallback_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.lcn.SolnBoundMetadata
 */
export const SolnBoundMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.SolnBoundMetadata",
  () => [
    { no: 16, name: "quantity", kind: "enum", T: proto3.getEnumType(QuantityType), repeated: true },
    { no: 1, name: "ranges", kind: "message", T: FieldRange, repeated: true },
    { no: 2, name: "quantity_fallback_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

