// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 16;
export const BillIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="bill"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M4.061,16
           C4.018,16 3.959,15.967 3.872,15.892
           C3.341,15.438 2.809,14.986 2.277,14.533
           C2.259,14.518 2.239,14.504 2.215,14.487
           C2.205,14.481 2.193,14.472 2.18,14.463
           L2.108,14.412
           L1.767,14.701
           C1.556,14.88 1.345,15.057 1.134,15.236
           C1.055,15.303 0.976,15.372 0.897,15.441
           C0.714,15.6 0.524,15.764 0.329,15.912
           C0.284,15.946 0.203,15.973 0.144,15.973
           C0.126,15.973 0.114,15.97 0.111,15.969
           C0.077,15.953 0.017,15.848 0.012,15.764
           C-0.003,15.519 -0.001,15.266 0.002,15.022
           C0.002,14.925 0.004,14.829 0.004,14.732
           L0.004,0.334
           C0.004,0.028 0.031,0 0.329,0
           L11.681,0
           C11.981,0 12.008,0.028 12.008,0.332
           L12.008,15.674
           C12.008,15.689 12.01,15.705 12.011,15.721
           C12.011,15.73 12.014,15.737 12.014,15.745
           C12.002,15.771 11.99,15.799 11.979,15.827
           C11.962,15.87 11.928,15.951 11.907,15.966
           C11.905,15.966 11.891,15.97 11.865,15.97
           C11.804,15.97 11.726,15.946 11.687,15.914
           C11.298,15.597 10.91,15.265 10.534,14.944
           C10.4,14.83 10.266,14.715 10.131,14.6
           C10.096,14.57 10.058,14.54 10.019,14.509
           L9.887,14.403
           L9.329,14.877
           C8.943,15.204 8.563,15.527 8.185,15.851
           C8.064,15.954 7.996,15.995 7.949,15.995
           C7.902,15.995 7.837,15.955 7.722,15.857
           C7.535,15.698 7.348,15.539 7.161,15.38
           C6.845,15.111 6.519,14.833 6.2,14.558
           C6.158,14.521 6.091,14.472 6.006,14.472
           C5.921,14.472 5.859,14.518 5.813,14.558
           C5.468,14.855 5.115,15.156 4.773,15.447
           C4.598,15.596 4.423,15.744 4.248,15.894
           C4.162,15.967 4.105,16 4.061,16Z
           M5.645,10.568
           L6.57,10.568
           L6.57,9.603
           C7.792,9.445 8.546,8.741 8.546,7.747
           C8.546,6.472 7.389,6.147 6.57,5.946
           L6.57,4.445
           C6.993,4.509 7.408,4.656 7.779,4.874
           L7.893,4.939
           L8.326,3.871
           L8.248,3.82
           C7.801,3.531 7.195,3.34 6.571,3.289
           L6.571,2.336
           L5.645,2.336
           L5.645,3.31
           C4.203,3.487 3.686,4.378 3.686,5.167
           C3.686,6.453 4.833,6.777 5.645,6.976
           L5.645,8.463
           C5.068,8.388 4.507,8.165 4.127,7.855
           L4.012,7.761
           L3.535,8.833
           L3.598,8.889
           C4.041,9.273 4.818,9.547 5.645,9.615
           L5.645,10.568Z
           M5.763,4.34
           L5.763,5.856
           C5.278,5.712 4.918,5.524 4.918,5.115
           C4.918,4.74 5.169,4.434 5.763,4.34Z
           M6.448,7.064
           C6.95,7.209 7.317,7.405 7.317,7.822
           C7.317,8.188 7.058,8.478 6.448,8.572
           L6.448,7.064Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
