// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { shallowEqual } from 'fast-equals';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { RecoilProjectKey } from '../../../lib/persist';

/**
 * The states in this file check the width of the possible max width of the toolbar, check what
 * elements of the toolbar are present and then show/hide as many elements as can fit in the
 * available width. The hidden elements will only be visible in the toolbox button.
 * At the moment this is done only for the paraview toolbar.
 */

/**
 * State that will keep the width of the available space for the toolbar.
 */
const toolbarMaxWidthState = atomFamily((key: RecoilProjectKey) => atom(0), shallowEqual);

export const useToolbarMaxWidthValue = (
  key: RecoilProjectKey,
) => useAtomValue(toolbarMaxWidthState(key));

export const useSetToolbarMaxWidth = (
  key: RecoilProjectKey,
) => useSetAtom(toolbarMaxWidthState(key));

export const DEFAULT_STATE_GROUPS_WIDTHS = {
  mainButtons: 0,
  mainFilters: 0,
  moreFilters: 0,
  toolbox: 0,
};

/**
 * State that will keep the width for the different toolbar floating groups and the total
 * available space so we can later figure whether we can show all groups or if'd need to add some of
 * them to the toolbox item.
 */
const toolbarGroupsWidthsState = atomFamily(
  (key: RecoilProjectKey) => atom(DEFAULT_STATE_GROUPS_WIDTHS),
  shallowEqual,
);

export const useToolbarGroupsWidths = (
  key: RecoilProjectKey,
) => useAtom(toolbarGroupsWidthsState(key));

export const useSetToolbarGroupsWidths = (
  key: RecoilProjectKey,
) => useSetAtom(toolbarGroupsWidthsState(key));

export const DEFAULT_STATE_HIDDEN_GROUPS = {
  mainButtons: false,
  mainFilters: false,
  moreFilters: false,
};

/**
 * State that will keep the info whether we hide some toolbar groups because there is
 * not enought space in the toolbar.
 */
const toolbarHiddenGroupsState = atomFamily(
  (key: RecoilProjectKey) => atom(DEFAULT_STATE_HIDDEN_GROUPS),
  shallowEqual,
);

export const useToolbarHiddenGroups = (
  key: RecoilProjectKey,
) => useAtom(toolbarHiddenGroupsState(key));
