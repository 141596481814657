// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo, useState } from 'react';

import { CommonMenuItem, CommonMenuListItem } from '../../lib/componentTypes/menu';
import { SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { colors } from '../../lib/designSystem';
import { parseString } from '../../lib/html';
import { IconButton } from '../Button/IconButton';
import { MenuButton } from '../Button/MenuButton';
import { createStyles, makeStyles } from '../Theme';
import { CONTROL_HEIGHT } from '../Theme/commonStyles';
import Tooltip from '../Tooltip';
import { XIcon } from '../svg/XIcon';
import { NodeLink } from '../treePanel/NodeLink';

/** ModelSelector generalizes the display, selection, and creation of a set of
 * models.
 *
 * When a selected model is provided, it is displayed as a link (to navigate to
 * the tree node) along with an icon button for unselecting the item.
 *
 * When no selection model is provided, an Add drop-down button is shown with
 * a menu item for every listed model.  An additional menu item for creating
 * a new model may also be configured.
*/

const useStyles = makeStyles(
  () => createStyles({
    root: {
      height: `${CONTROL_HEIGHT}px`,
      padding: '8px 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
      gap: '16px',
    },
    selected: {
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      overflow: 'hidden',
      gap: '8px',
      color: colors.lowEmphasisText,
    },
    displayName: {
      fontSize: '13px',
      fontWeight: 500,
      color: colors.highEmphasisText,
    },
  }),
  { name: 'ModelSelector' },
);

// This is how we represent a 'model'.  It must have an ID, a label, and a node
// ID for the control panel, aka the simulation tree.
export interface ModelData<T> {
  // The model itself (e.g. a boundary condition)
  model: T;
  // A unique identifier for the model
  id: string;
  // A label for the model
  label: string;
  // Optionally override icon prop in ModelSelectorProps below
  icon?: SvgIconSpec;
  // Optional icon to be configured in the menu items
  auxIcon?: SvgIconSpec;
  // Optionally disable the model in the drop-down list
  disabled?: boolean;
  // Optionally show a tooltip when disabled
  disabledReason?: string;
  // Optional title to add to menu
  title?: string;
  // Whether this datum should be excluded from the drop down menu
  hideFromMenu?: boolean;
}

// Configuration that supports a 'New item' menu item
export interface ModelCreator {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  disabledReason?: string;
  icon?: SvgIconSpec | null;
  subCreators?: ModelCreator[];
  tooltip?: string;
}

export interface ModelSelectorProps<T> {
  // A list of models that may be selected
  models: ModelData<T>[];
  // The already-selected model ID(s) (optional)
  // Set to multiple values to induce a 'mixed' display instead of a NodeLink.
  selected?: (string | undefined)[];
  // Called when the user clicks the 'x' IconButton
  onUnselect: () => void;
  // Called when the user selectes a model from the menu
  onSelect: (data: ModelData<T>) => void;
  // Effect and configure a 'New model' menu option
  creators?: ModelCreator[];
  // Optional icon to be configured in the menu items
  icon?: SvgIconSpec;
  disabled?: boolean;
  tooltip?: string;
}

function creatorStartIcon(icon?: SvgIconSpec | null) {
  if (icon === null) {
    return undefined;
  }
  return icon ?? { name: 'plus', maxHeight: 9 };
}

function creatorToMenuItem(creator: ModelCreator): CommonMenuListItem {
  const { disabled, disabledReason, icon, label, onClick, subCreators, tooltip } = creator;

  return {
    label: `${label || 'New item'}`,
    startIcon: creatorStartIcon(icon),
    onClick,
    disabled,
    disabledReason,
    items: subCreators?.map(creatorToMenuItem),
    help: tooltip,
  };
}

export function ModelSelector<T>(props: ModelSelectorProps<T>) {
  const {
    creators = [],
    disabled,
    icon,
    models,
    onUnselect,
    onSelect,
    selected = [],
    tooltip = '',
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  const classes = useStyles();

  const isSelected = useCallback(
    (id: string) => selected.some((selectedId) => selectedId === id),
    [selected],
  );

  const findModel = useCallback(
    (modelId: string) => models.find(({ id }) => id === modelId),
    [models],
  );

  // useCallback here prevents infinite looping in MenuButton
  const handleToggleMenu = useCallback((open: boolean) => setMenuOpen(open), []);

  // Generate menu of selectable models, excluding the currently selected one
  const menuItems = useMemo(() => {
    let runningTitle = '';
    const items = models.reduce((result, modelData: ModelData<T>) => {
      if (!isSelected(modelData.id) && !modelData.hideFromMenu) {
        if (modelData.title && modelData.title !== runningTitle) {
          if (result.length) {
            result.push({ separator: true });
          }
          result.push({ title: modelData.title });
          runningTitle = modelData.title;
        }
        result.push({
          label: modelData.label,
          onClick: () => onSelect(modelData),
          startIcon: modelData.icon ?? icon,
          endIcon: modelData.auxIcon,
          disabled: modelData.disabled,
          disabledReason: modelData.disabledReason,
        });
      }
      return result;
    }, [] as CommonMenuItem[]);

    // Configure optional menu item for creating a new model
    if (creators.length) {
      if (items.length) {
        items.push({ separator: true });
      }
      items.push(...creators.map(creatorToMenuItem));
    }

    return items;
  }, [creators, icon, isSelected, models, onSelect]);

  // Representation of the selected value, which appears when the `selected` prop contains at least
  // one item
  const selectionDisplay = useMemo(() => {
    if (selected.length > 1) {
      return {
        label: 'Mixed',
        model: undefined,
      };
    }
    if (selected.length === 1) {
      if (selected[0]) {
        const datum = findModel(selected[0]);
        if (datum) {
          const { id, label, model } = datum;
          return { id, label, model };
        }
      }
      return {
        label: 'Unknown',
        model: undefined,
      };
    }

    return null;
  }, [findModel, selected]);

  return (
    <div className={classes.root}>
      {selectionDisplay ? (
        <div className={classes.selected}>
          {selectionDisplay.id ? (
            <NodeLink asBlock nodeIds={[selectionDisplay.id]} text={selectionDisplay.label} />
          ) : (
            <div className={classes.displayName}>{selectionDisplay.label}</div>
          )}
          <Tooltip title={parseString(tooltip)}>
            <span>
              <IconButton
                disabled={disabled}
                onClick={onUnselect}>
                <XIcon maxHeight={10} />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      ) : (
        <Tooltip title={menuOpen ? '' : parseString(tooltip)}>
          <span>
            <MenuButton
              disabled={disabled}
              endIcon={{ name: 'caretDown', maxHeight: 4 }}
              kind="minimal"
              menuItems={menuItems}
              menuProps={{ closeOnSelect: true }}
              onToggle={handleToggleMenu}
              position="below-left"
              size="small">
              Add
            </MenuButton>
          </span>
        </Tooltip>
      )}
    </div>
  );
}
