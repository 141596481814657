// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { useLcvisMeasureValue } from '../../../../recoil/lcvis/lcvisMeasureState';
import { ActionButton } from '../../../Button/ActionButton';
import { RulerIcon } from '../../../svg/RulerIcon';
import '../Label.scss';

interface MeasureLabelProps {
  onExit: () => void;
}

export const MeasureLabel = (props: MeasureLabelProps) => {
  const { onExit } = props;
  let subtitleText = '';
  const lcvisMeasureValue = useLcvisMeasureValue();
  switch (lcvisMeasureValue.pointToUdpdate) {
    case 0: {
      subtitleText = 'Click on a surface to start the measure';
      break;
    }
    case 1: {
      subtitleText = 'Click on another surface to finish';
      break;
    }
    case 2: {
      subtitleText = 'Measure complete';
      break;
    }
    default: {
      // This shouldn't happen.
      subtitleText = 'Internal error';
    }
  }
  return (
    <div className="labelContainer">
      <span className="iconWrapper">
        <RulerIcon maxHeight={12} maxWidth={12} />
      </span>
      <div className="header">Measure Mode</div>
      <div className="subtitle">{subtitleText}</div>
      <ActionButton onClick={onExit} size="small">Exit</ActionButton>
    </div>
  );
};
