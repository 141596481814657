// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseHeight = 12;
const baseWidth = 12;
export const SortZaIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      height={finalHeight}
      style={{
        fill: 'none',
      }}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}>
      <path
        d="M4.49243 8.99244
          C4.28243 8.99244 4.09493 9.07494 3.95993 9.20994
          L3.74243 9.42744
          V6.74244
          C3.74243 6.32994 3.40493 5.99244 2.99243 5.99244
          C2.57993 5.99244 2.24243 6.32994 2.24243 6.74244
          V9.43494
          L2.02493 9.21744
          C1.88993 9.08244 1.70243 8.99994 1.49243 8.99994
          C1.07993 8.99994 0.742432 9.33744 0.742432 9.74994
          C0.742432 9.95994 0.824932 10.1474 0.959932 10.2824
          L2.45993 11.7824
          C2.59493 11.9174 2.78243 11.9999 2.99243 11.9999
          C3.20243 11.9999 3.38993 11.9174 3.52493 11.7824
          L5.02493 10.2824
          C5.15993 10.1474 5.24243 9.95994 5.24243 9.74994
          C5.24243 9.32994 4.90493 8.99244 4.49243 8.99244Z
          M9.52499 7.5
          H8.48999
          L6.74999 11.9925
          H7.76999
          L8.12999 10.995
          H9.85499
          L10.2 11.9925
          H11.25
          L9.52499 7.5Z
          M8.39238 10.2526
          L8.99238 8.60257
          H9.00738
          L9.58488 10.2526
          H8.39238Z
          M11.2424 4.875
          H7.81493
          L11.1524 1.035
          V0
          H6.25493
          V1.11
          H9.32993
          L5.99243 4.9425
          V5.985
          H11.2424
          V4.875Z"
        style={{ fill: getColor(0), fillRule: 'evenodd', clipRule: 'evenodd' }}
      />
    </svg>
  );
});
