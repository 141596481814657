// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { NodeType } from '../../../lib/simulationTree/node';
import { defaultNodeFilter } from '../../../lib/subselectUtils';
import { mapDomainsToIds, mapIdsToDomains } from '../../../lib/volumeUtils';
import { useHeatSource } from '../../../model/hooks/useHeatSource';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useProjectContext } from '../../context/ProjectContext';

export const useHeatSourceVolumes = (heatSourceId: string) => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const staticVolumes = useStaticVolumes(projectId);
  const geometryTags = useGeometryTags(projectId);

  // == Model hooks
  const {
    disabledDomainReason,
    setHeatSourceDomains,
  } = useHeatSource(projectId, workflowId, jobId, readOnly, heatSourceId);

  const domainAssignmentDisabledReasons = useMemo(() => {
    const reasons: Record<string, string> = {};
    staticVolumes.forEach(({ domain, id }) => {
      reasons[id] = disabledDomainReason(domain);
    });
    return reasons;
  }, [disabledDomainReason, staticVolumes]);

  // Memoize the nodeFilter object passed to NodeSubselect to avoid infinite looping
  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (nodeType === NodeType.VOLUME) {
      const disabledReason = domainAssignmentDisabledReasons[nodeId] ?? '';
      return {
        related: true,
        tooltip: disabledReason,
        disabled: !!disabledReason,
      };
    }

    if (nodeType === NodeType.SURFACE_GROUP) {
      const domains = geometryTags.domainsFromTagEntityGroupId(nodeId);

      if (domains?.length) {
        const disabledReason = mapDomainsToIds(staticVolumes, domains)
          .map((volumeId) => domainAssignmentDisabledReasons[volumeId]);

        // If there's at least one volume with issues in this tag, disable the group. Otherwise we
        // have to implement complex logic regarding the relationships between tags, volumes and
        // materials, i.e. filter out bodies in the tag that are not assigned to a correct material.
        if (disabledReason.length > 0) {
          return {
            related: true,
            tooltip: disabledReason.join(', '),
            disabled: disabledReason.some((reason) => !!reason),
          };
        }
        return {
          related: true,
          disabled: false,
        };
      }
    }

    return defaultNodeFilter(nodeType);
  }, [domainAssignmentDisabledReasons, geometryTags, staticVolumes]);

  // Update the list of volumes assigned to the heat source
  const setAssignedVolumeNodeIds = useCallback(async (volumeIds: string[]) => {
    const domains = volumeIds.flatMap((volumeId) => {
      const tag = geometryTags.domainsFromTag(volumeId);
      if (tag.length) {
        return [volumeId];
      }
      return mapIdsToDomains(staticVolumes, [volumeId]);
    });

    await setHeatSourceDomains(domains);
  }, [geometryTags, setHeatSourceDomains, staticVolumes]);

  return {
    nodeFilter,
    setAssignedVolumeNodeIds,
  };
};
