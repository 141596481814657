// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 50;
const baseHeight = 40;
export const ScreenshotIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="screenshot"
      height={finalHeight}
      ref={ref}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}>
      <title>screenshot</title>
      <g clipRule="evenodd" fill={getColor(0)} fillRule="evenodd" strokeWidth="0">
        <path
          d="M50 4
             C50 1.792 48.208 0 46 0
             L4 0
             C1.792 0 0 1.792 0 4
             L0 36
             C0 38.208 1.792 40 4 40
             L46 40
             C48.208 40 50 38.208 50 36
             L50 4Z
             M46 4
             L4 4
             L4 36
             L46 36
             L46 4Z"
        />
        <path
          d="M17 10
             L17 7
             L7 7
             L7 17
             L10 17
             L10 10
             L17 10Z"
        />
        <path
          d="M33 30
             L33 33
             L43 33
             L43 23
             L40 23
             L40 30
             L33 30Z"
        />
      </g>
    </svg>
  );
});
