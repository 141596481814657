// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 21;
const baseHeight = 18;
export const ArrowRightIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="arrowRight"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M20.565,7.95
           L13.065,0.45
           C12.795,0.165 12.42,0 12,0
           C11.175,0 10.5,0.675 10.5,1.5
           C10.5,1.92 10.665,2.295 10.935,2.565
           L15.87,7.5
           L1.5,7.5
           C0.675,7.5 0,8.175 0,9
           C0,9.825 0.675,10.5 1.5,10.5
           L15.885,10.5
           L10.95,15.435
           C10.68,15.705 10.515,16.08 10.515,16.5
           C10.515,17.325 11.19,18 12.015,18
           C12.435,18 12.81,17.835 13.08,17.565
           L20.58,10.065
           C20.85,9.795 21.015,9.42 21.015,9
           C21.015,8.58 20.835,8.22 20.565,7.95Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
