// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.

import { LcvModule } from '../types';

/**
 * The lcvis session. It does not extend the LcvObject base class, we don't need to worry about
 * calling retain/release on it.
*/
export class LcvSession {
  handle: number;
  lcv: LcvModule;
  constructor(lcv: LcvModule, jwt: string) {
    this.lcv = lcv;
    this.handle = lcv.newSession(jwt, 0).session;
  }
}
