// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const PdfIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="pdf"
      height={finalHeight}
      ref={ref}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlns="http://www.w3.org/2000/svg">
      <g
        clipRule="evenodd"
        fill={getColor(0)}
        fillRule="evenodd"
        strokeWidth="0">
        <path
          d="M6 10.521
             L0 10.521
             L0 19.521
             L6 19.521
             L6 22.5
             C6 23.325 6.675 24 7.5 24
             L22.5 24
             C23.325 24 24 23.325 24 22.5
             L24 7.5
             L16.5 0
             L7.5 0
             C6.675 0 6 0.675 6 1.5
             L6 10.521Z
             M21 19.521
             L21 21
             L9 21
             L9 19.521
             L21 19.521Z
             M1.784 12.373
             L3.888 12.373
             C4.181 12.373 4.459 12.399 4.72 12.453
             C4.981 12.506 5.209 12.598 5.404 12.729
             C5.599 12.859 5.753 13.035 5.868 13.257
             C5.983 13.478 6.04 13.757 6.04 14.093
             C6.04 14.423 5.987 14.701 5.88 14.925
             C5.773 15.149 5.627 15.327 5.44 15.461
             C5.253 15.594 5.032 15.689 4.776 15.745
             C4.52 15.801 4.243 15.829 3.944 15.829
             L3.032 15.829
             L3.032 18.037
             L1.784 18.037
             L1.784 12.373Z
             M7.624 12.373
             L9.496 12.373
             C9.949 12.373 10.38 12.423 10.788 12.525
             C11.196 12.626 11.552 12.79 11.856 13.017
             C12.16 13.243 12.401 13.538 12.58 13.901
             C12.759 14.263 12.848 14.703 12.848 15.221
             C12.848 15.679 12.761 16.083 12.588 16.433
             C12.415 16.782 12.181 17.075 11.888 17.313
             C11.595 17.55 11.256 17.73 10.872 17.853
             C10.488 17.975 10.085 18.037 9.664 18.037
             L7.624 18.037
             L7.624 12.373Z
             M14.544 12.373
             L18.344 12.373
             L18.344 13.525
             L15.792 13.525
             L15.792 14.677
             L18.152 14.677
             L18.152 15.829
             L15.792 15.829
             L15.792 18.037
             L14.544 18.037
             L14.544 12.373Z
             M8.872 16.885
             L9.52 16.885
             C9.808 16.885 10.076 16.855 10.324 16.797
             C10.572 16.738 10.787 16.641 10.968 16.505
             C11.149 16.369 11.292 16.19 11.396 15.969
             C11.5 15.747 11.552 15.477 11.552 15.157
             C11.552 14.879 11.5 14.638 11.396 14.433
             C11.292 14.227 11.152 14.058 10.976 13.925
             C10.8 13.791 10.593 13.691 10.356 13.625
             C10.119 13.558 9.869 13.525 9.608 13.525
             L8.872 13.525
             L8.872 16.885Z
             M3.032 14.773
             L3.864 14.773
             C3.976 14.773 4.084 14.762 4.188 14.741
             C4.292 14.719 4.385 14.683 4.468 14.633
             C4.551 14.582 4.617 14.513 4.668 14.425
             C4.719 14.337 4.744 14.226 4.744 14.093
             C4.744 13.949 4.711 13.833 4.644 13.745
             C4.577 13.657 4.492 13.589 4.388 13.541
             C4.284 13.493 4.168 13.462 4.04 13.449
             C3.912 13.435 3.789 13.429 3.672 13.429
             L3.032 13.429
             L3.032 14.773Z
             M21 10.521
             L9 10.521
             L9 3
             L15 3
             L15 9
             L21 9
             L21 10.521Z"
        />
      </g>
    </svg>
  );
});
