// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as meshgenerationpb from '../../proto/meshgeneration/meshgeneration_pb';

export const DEFAULT_MESH_GENERATION = new meshgenerationpb.UserMeshingParams({
  globalMinSizeM: 0.0001,
  globalMaxSizeM: 512,
  modelEdgeCurvatureDeg: 8,
  modelFaceCurvatureDeg: 8,
  modelMaxSize: 4.5,
  blNLayers: 40,
  blInitialSize: 0.000001,
  blGrowRate: 1.2,
  proximityLayers: 1,
});
