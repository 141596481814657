// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
// Adapted from https://www.npmjs.com/package/@opentelemetry/exporter-metrics-otlp-http
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http';
import {
  AggregationTemporality,
  ExponentialHistogramAggregation,
  InMemoryMetricExporter,
  MeterProvider,
  PeriodicExportingMetricReader,
  PushMetricExporter,
  View,
} from '@opentelemetry/sdk-metrics';

import { pageVersion } from '../RuntimeParams';

import { localhost, resource, serviceName } from './constants';

const otelExporterOtlpMetricsEndpoint = `https://${window.location.hostname}/v1/metrics`;

const provider = new MeterProvider({
  resource,
  views: [
    new View({
      aggregation: new ExponentialHistogramAggregation(),
      instrumentName: 'ParaviewClient_connection_latency',
    }),
  ],
});
let exporter: PushMetricExporter = new OTLPMetricExporter({
  url: otelExporterOtlpMetricsEndpoint,
});
// To reduce noise when running on localhost, just keep the metrics in memory and don't send them
// anywhere.
if (window.location.hostname === localhost) {
  exporter = new InMemoryMetricExporter(AggregationTemporality.DELTA);
}
provider.addMetricReader(new PeriodicExportingMetricReader({
  exporter,
  exportIntervalMillis: 5000,
}));

export const meter = provider.getMeter(serviceName, pageVersion);
