// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { Empty } from '@bufbuild/protobuf';

import * as frontendpb from '../proto/frontend/frontend_pb';

import assert from './assert';
import { Logger } from './observability/logs';
import * as rpc from './rpc';

const logger = new Logger('ProjectListPoller');

const POLL_INTERVAL_MS = 60 * 1000;

type UpdateCallback = (projects: frontendpb.ListProjectsReply) => void;

export function poll(onUpdate: UpdateCallback) {
  rpc.callRetry('listProjects', rpc.client.listProjects, new Empty()).then(
    (reply: frontendpb.ListProjectsReply) => {
      onUpdate(reply);
    },
  ).catch((err: Error) => {
    logger.warn(`projectlist error: ${JSON.stringify(err)}`);
  });
}

/**
   ProjectListPoller issues ListProjectsList RPC periodically and calls the
    onUpdate function.  The user can also manually issue an RPC by calling
    startRefresh(). A singleton object.

    Legal call sequence: (startRefresh* stop)*
*/
class ProjectListPoller {
  private timerId: ReturnType<typeof setInterval> | null = null;
  private nRef = 0; // #(calls to start) - #(calls to stop)

  constructor(private readonly onUpdate: UpdateCallback) { }

  // Start the async poll timer.
  public start = (): void => {
    if (this.nRef === 0) {
      assert(this.timerId === null, 'Another timer still exists');
      this.timerId = setInterval(() => poll(this.onUpdate), POLL_INTERVAL_MS);
    }
    this.nRef += 1;
  };

  // Stop must be called exactly once when the component is unmounted.
  public stop = (): void => {
    this.nRef -= 1;
    assert(this.nRef >= 0, 'Cannot stop poller if start counter is below zero');
    if (this.nRef === 0) {
      if (this.timerId == null) {
        throw Error('null timerId');
      }
      clearInterval(this.timerId);
      this.timerId = null;
    }
  };

  // Manually refresh the project list in the background.
  public startRefresh = (): void => {
    assert(this.nRef > 0, 'Cannot start poller if start counter is below or equal to zero');
    poll(this.onUpdate);
  };
}

export default ProjectListPoller;
