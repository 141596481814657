// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { getQuantityText, getQuantityUnit } from '../../QuantityDescriptor';
import * as feoutputpb from '../../proto/frontend/output/output_pb';
import { CheckBoxProps, MultiCheckBoxItemProps } from '../componentTypes/form';
import { getOutputQuantity, isIncluded, setIncluded } from '../outputNodeUtils';

// The utils in this file are for output data and form components

export function createIncludeOption(
  includes: feoutputpb.OutputIncludes[],
  text: string,
  outputNode: feoutputpb.OutputNode,
  updateField: (changeParam: (newOutput: feoutputpb.OutputNode) => void) => void,
  disabled?: boolean,
): MultiCheckBoxItemProps {
  return {
    onChange: (checked) => updateField((output) => (
      includes.forEach((inc) => setIncluded(output, inc, checked))
    )),
    optionText: text,
    checked: !disabled && isIncluded(outputNode, includes[0]),
    disabled,
  };
}

export function getIncludeOptions(
  outputNode: feoutputpb.OutputNode,
  updateField: (changeParam: (newOutput: feoutputpb.OutputNode) => void) => void,
): CheckBoxProps[] {
  const quantity = getOutputQuantity(outputNode);
  if (!quantity) {
    return [];
  }
  const quantityName = getQuantityText(quantity);
  const quantityUnit = getQuantityUnit(quantity);

  return [
    createIncludeOption(
      [feoutputpb.OutputIncludes.OUTPUT_INCLUDE_BASE],
      `${quantityName} ${quantityUnit ? `(${quantityUnit})` : ''}`,
      outputNode,
      updateField,
    ),
  ];
}
