// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/lcstatus/lcstatus.proto (package luminary.proto.lcstatus, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Any, proto3 } from "@bufbuild/protobuf";
import { Code } from "./codes_pb.js";
import { Level } from "./levels_pb.js";

/**
 * LCStatus conveys a specific piece of information that's typically the result
 * of some operation or processing.
 *
 * @generated from message luminary.proto.lcstatus.LCStatus
 */
export const LCStatus = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.LCStatus",
  () => [
    { no: 1, name: "code", kind: "enum", T: proto3.getEnumType(Code) },
    { no: 2, name: "level", kind: "enum", T: proto3.getEnumType(Level) },
    { no: 3, name: "details", kind: "message", T: Any },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "where", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

