// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import assert from '../../../../../lib/assert';
import { SelectOption } from '../../../../../lib/componentTypes/form';
import {
  defaultPhysicsId,
  getDefaultPhysicsId,
  updateOutputParam,
  updateResidualChoices,
} from '../../../../../lib/outputNodeUtils';
import { findPhysicsById } from '../../../../../lib/physicsUtils';
import * as feoutputpb from '../../../../../proto/frontend/output/output_pb';
import { useGeometryTags } from '../../../../../recoil/geometry/geometryTagsState';
import { useOutputNodes } from '../../../../../recoil/outputNodes';
import { useEnabledExperiments } from '../../../../../recoil/useExperimentConfig';
import { useStaticVolumes } from '../../../../../recoil/volumes';
import { DataSelect } from '../../../../Form/DataSelect';
import LabeledInput from '../../../../Form/LabeledInput';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useSimulationConfig } from '../../../../hooks/useSimulationConfig';

export interface PhysicsSelectionProps {
  outputNode: feoutputpb.OutputNode;
}

export function PhysicsSelection(props: PhysicsSelectionProps) {
  const { outputNode } = props;

  const { projectId } = useProjectContext();
  const { simParam } = useSimulationConfig();
  const [outputNodes, setOutputNodes] = useOutputNodes(projectId, '', '');
  const experimentConfig = useEnabledExperiments();
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  assert(
    outputNode.nodeProps.case === 'residual',
    'Physics selection content requires a residual output',
  );
  const residualNode = outputNode.nodeProps.value;
  const availablePhysics = simParam.physics;
  const useDefaultPhysicsId = defaultPhysicsId(residualNode, simParam);
  const selectedId = useDefaultPhysicsId ?
    getDefaultPhysicsId(simParam) : residualNode.physicsId;
  const options: SelectOption<string>[] = availablePhysics.reduce((acc, physics) => {
    const identifier = physics.physicsIdentifier;
    if (identifier) {
      const { id, name } = identifier;
      acc.push({
        name,
        value: id,
        selected: selectedId === id,
      });
    }
    return acc;
  }, [] as SelectOption<string>[]);
  const selectedNotFound = !!selectedId && !findPhysicsById(simParam, selectedId);

  return (
    <LabeledInput
      help="Select the physics to output"
      label="Physics">
      <DataSelect
        asBlock
        // Disable the selection if we use the default physics id
        disabled={useDefaultPhysicsId}
        faultType={selectedNotFound ? 'warning' : undefined}
        locator="Physics"
        onChange={(id: string) => {
          const updateNode = (newOutputNode: feoutputpb.OutputNode) => {
            assert(
              newOutputNode.nodeProps.case === 'residual',
              'Physics may only be set on a residual output',
            );
            newOutputNode.nodeProps.value.physicsId = id;
            updateResidualChoices(
              newOutputNode.nodeProps.value,
              simParam,
              experimentConfig,
              geometryTags,
              staticVolumes,
              true,
            );
          };
          updateOutputParam(outputNodes, outputNode.id, updateNode, setOutputNodes);
        }}
        options={options}
        size="small"
      />
    </LabeledInput>
  );
}
