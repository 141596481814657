// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as simulationpb from '../proto/client/simulation_pb';

import { newAdFloat } from './adUtils';

const { CUSTOM_MATERIAL_FLUID, STANDARD_AIR, WATER_NTP } = simulationpb.MaterialFluidPreset;
const { CONSTANT_DENSITY, IDEAL_GAS } = simulationpb.DensityRelationship;
const { LAMINAR_CONSTANT_VISCOSITY, TEMPERATURE_DEPENDENT_LAMINAR_VISCOSITY } =
    simulationpb.LaminarViscosityModelNewtonian;
const { LAMINAR_CONSTANT_THERMAL_CONDUCTIVITY, TEMPERATURE_DEPENDENT_THERMAL_CONDUCTIVITY } =
    simulationpb.LaminarThermalConductivity;

// Sets the parameters for each material preset.
export function applyFluidMaterialPreset(material: simulationpb.MaterialFluid): boolean {
  const preset = material.materialFluidPreset;
  if (preset === CUSTOM_MATERIAL_FLUID) {
    return true;
  }

  if (material.laminarViscosityModelNewtonian === TEMPERATURE_DEPENDENT_LAMINAR_VISCOSITY) {
    material.laminarViscosityModelNewtonian = LAMINAR_CONSTANT_VISCOSITY;
    material.dynamicViscosityTableData = '';
  }
  if (material.laminarThermalConductivity === TEMPERATURE_DEPENDENT_THERMAL_CONDUCTIVITY) {
    material.laminarThermalConductivity = LAMINAR_CONSTANT_THERMAL_CONDUCTIVITY;
    material.thermalConductivityTableData = '';
  }

  if (preset === STANDARD_AIR) {
    material.laminarConstantViscosityConstant = newAdFloat(1.7894e-5);
    material.sutherlandViscosityRef = newAdFloat(1.716e-5);
    material.sutherlandViscosityTempRef = newAdFloat(273.11);
    material.sutherlandConstant = newAdFloat(110.56);
    material.laminarConstantThermalPrandtlConstant = newAdFloat(0.72);
    material.laminarConstantThermalConductivityConstant = newAdFloat(0.0257);
    material.molecularWeight = newAdFloat(28.966);
    material.specificHeatCp = newAdFloat(1006.43);
    material.constantDensityValue = newAdFloat(1.225);
    material.boussinesqTempRef = newAdFloat(288.15);
    material.thermalExpansionCoefficient = newAdFloat(0.0035);
  } else if (preset === WATER_NTP) {
    // From https://onlinelibrary.wiley.com/doi/pdf/10.1002/9781118534892.app2
    // If constant density (with or without energy) was already selected we do
    // not override the choice. Otherwise, we switch to constant density because
    // the ideal gas model is not suitable for liquid water.
    if (material.densityRelationship === IDEAL_GAS) {
      material.densityRelationship = CONSTANT_DENSITY;
    }
    material.constantDensityValue = newAdFloat(998.2);
    // Sutherland is not valid for liquids.
    material.laminarViscosityModelNewtonian = LAMINAR_CONSTANT_VISCOSITY;
    material.laminarConstantViscosityConstant = newAdFloat(1.002e-3);
    material.molecularWeight = newAdFloat(18.0153);
    material.specificHeatCp = newAdFloat(4183);
    // Currently, any type of thermal conductivity may be valid.
    material.laminarConstantThermalPrandtlConstant = newAdFloat(7.152);
    material.boussinesqTempRef = newAdFloat(293.15);
    material.thermalExpansionCoefficient = newAdFloat(0.00021);
  }
  return true;
}

/** Sets the parameters for each solid material preset  */
export function applySolidMaterialPreset(material: simulationpb.MaterialSolid): boolean {
  const preset = material.materialSolidPreset;
  switch (preset) {
    case simulationpb.MaterialSolidPreset.ALUMINUM: {
      // https://www.matweb.com/search/DataSheet.aspx?MatGUID=0cd1edf33ac145ee93a0aa6fc666c0e0
      material.constantDensityValueSolid = newAdFloat(2698);
      material.specificHeatCpSolid = newAdFloat(900);
      material.thermalConductivityConstantSolid = newAdFloat(210);
      material.thermalConductivityTableData = '';
      return true;
    }
    case simulationpb.MaterialSolidPreset.COPPER: {
      // https://www.matweb.com/search/DataSheet.aspx?MatGUID=9aebe83845c04c1db5126fada6f76f7e
      material.constantDensityValueSolid = newAdFloat(8960);
      material.specificHeatCpSolid = newAdFloat(385);
      material.thermalConductivityConstantSolid = newAdFloat(385);
      material.thermalConductivityTableData = '';
      return true;
    }
    case simulationpb.MaterialSolidPreset.IRON: {
      // https://www.matweb.com/search/DataSheet.aspx?MatGUID=654ca9c358264b5392d43315d8535b7d
      material.constantDensityValueSolid = newAdFloat(7870);
      material.specificHeatCpSolid = newAdFloat(440);
      material.thermalConductivityConstantSolid = newAdFloat(76);
      material.thermalConductivityTableData = '';
      return true;
    }
    case simulationpb.MaterialSolidPreset.NICKEL: {
      // https://www.matweb.com/search/DataSheet.aspx?MatGUID=e6eb83327e534850a062dbca3bc758dc
      material.constantDensityValueSolid = newAdFloat(8880);
      material.specificHeatCpSolid = newAdFloat(460);
      material.thermalConductivityConstantSolid = newAdFloat(61);
      material.thermalConductivityTableData = '';
      return true;
    }
    case simulationpb.MaterialSolidPreset.TITANIUM: {
      // https://www.matweb.com/search/DataSheet.aspx?MatGUID=66a15d609a3f4c829cb6ad08f0dafc01
      material.constantDensityValueSolid = newAdFloat(4500);
      material.specificHeatCpSolid = newAdFloat(528);
      material.thermalConductivityConstantSolid = newAdFloat(17);
      material.thermalConductivityTableData = '';
      return true;
    }
    case simulationpb.MaterialSolidPreset.INVALID_MATERIAL_SOLID_PRESET:
    case simulationpb.MaterialSolidPreset.CUSTOM_MATERIAL_SOLID:
    default:
      return true;
  }
}

export function applyAllMaterialPresets(simParam: simulationpb.SimulationParam) {
  simParam.materialEntity.forEach((materialEntity) => {
    if (materialEntity.material.case === 'materialFluid') {
      applyFluidMaterialPreset(materialEntity.material.value);
    }
    if (materialEntity.material.case === 'materialSolid') {
      applySolidMaterialPreset(materialEntity.material.value);
    }
  });
}
