// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useRef, useState } from 'react';

import * as ProtoDescriptor from '../../../ProtoDescriptor';
import { getAdValue } from '../../../lib/adUtils';
import { tertiaryColors } from '../../../lib/color';
import { CommonMenuItem } from '../../../lib/componentTypes/menu';
import { getLabelText } from '../../../lib/rectilinearTable/util';
import * as basepb from '../../../proto/base/base_pb';
import { AdFloatType } from '../../../proto/base/base_pb';
import { Metadata } from '../../../proto/table/table_pb';
import useTableState from '../../../recoil/tableState';
import { CommonMenu } from '../../Menu/CommonMenu';
import { ParamFieldInput } from '../../ParamFieldInput';

import { SelectedColumnInput } from './SelectedColumnInput';
import './TableMapInput.scss';

export interface TableMapColumnSelectorProps {
  /** Used for reading tables using RPC */
  projectId: string;
  /** Metadata for the selected table */
  selectedTableData: Metadata;
  /** Param to be modified */
  param: ProtoDescriptor.Param;
  /** Constant value of the param field */
  value: AdFloatType;
  /** Updator for the constant param value */
  updateValue: (newValue: AdFloatType) => void;
  /** Index of a selected column in the table */
  columnIndex: number;
  /** Updator for table column selection */
  updateColumnIndex: (idx: number) => void;
  /** Column indexes that are disabled */
  disabledColumns?: number[];
  /** Optionally disable the input */
  disabled?: boolean;
}

/**
 * TableMapColumnSelector is an input-like component that allows for a param field to be updated
 * using either a constant value or a column from a global simulation param table-map (to be treated
 * as a profile).
 */
export const TableMapColumnSelector = (props: TableMapColumnSelectorProps) => {
  const {
    projectId,
    selectedTableData,
    param,
    value,
    updateValue,
    columnIndex,
    updateColumnIndex,
    disabledColumns,
    disabled,
  } = props;

  // whether the column selector is visible
  const [isFocused, setIsFocused] = useState(false);
  // whether the input field has been modified
  const [isDirty, setIsDirty] = useState(false);
  // whether the mouse is currently hovering over the column selector
  const [mouseOver, setMouseOver] = useState(false);
  // whether to disable committing the input value on blur
  const [disableCommitOnBlur, setDisableCommitOnBlur] = useState(false);
  // the initial value of the input field used to determine whether the input is dirty
  const initialInput = useRef(getAdValue(value));

  const controlRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  const tableProto = useTableState(projectId, selectedTableData?.url || null);
  const columnNames = tableProto?.header?.recordLabel.map(getLabelText);

  const unlinkColumn = () => {
    updateColumnIndex(0);
  };

  const linkColumn = (idx: number) => {
    updateColumnIndex(idx);
  };

  useEffect(() => {
    if (disableCommitOnBlur && columnIndex) {
      // once a column has been selected, then we need to reset the state
      setDisableCommitOnBlur(false);
    }
  }, [columnIndex, disableCommitOnBlur]);

  const menuItems: CommonMenuItem[] = [];
  menuItems.push({
    title: tableProto ?
      'Select a column or type a value.' :
      'Boundary condition profile must be uploaded',
  });
  columnNames?.forEach((col, idx) => menuItems.push(
    {
      label: col,
      onClick: () => {
        linkColumn(idx + 1);
        setIsFocused(false);
        setMouseOver(false);
      },
      // necessary to prevent the focus from going from the input to the common menu during the
      // first click on the list item
      onMouseDown: (event) => event.preventDefault(),
      selected: columnIndex === idx + 1,
      startIcon: { name: 'tableOutlined', color: tertiaryColors[idx % tertiaryColors.length] },
      disabled: disabledColumns?.includes(idx + 1),
    },
  ));
  // true if input is a value and not a column from the table
  const fieldInput = columnIndex === 0 || !selectedTableData || !columnNames?.length;

  const columnSelectorOpen = (isFocused || mouseOver) && !isDirty;

  return (
    <div
      className="tableMapInput"
      ref={controlRef}>
      {fieldInput ? (
        <div className="fieldInput">
          <div ref={inputRef}>
            <ParamFieldInput
              inputOptions={{ textInputOptions: { disableCommitOnBlur } }}
              onBlur={() => {
                if (!mouseOver) {
                  // do not hide the column selector if the user clicks on it, otherwise this would
                  // blur the input and therefore hide the column selector
                  setIsFocused(false);
                  setIsDirty(false);
                }
              }}
              onChange={(newValue: number) => {
                if (Number.isNaN(newValue)) {
                  // if the user erased the input, then display the columns
                  setIsDirty(false);
                } else if (newValue !== initialInput.current) {
                  // if the user types anything, then the field is now dirty so the column selector
                  // cannot be shown
                  setIsDirty(true);
                  setDisableCommitOnBlur(false);
                }
              }}
              onCommit={(newValue: basepb.AdFloatType) => {
                // update the initial input so a fresh value is used to determine if the input is
                // dirty
                initialInput.current = getAdValue(newValue);
              }}
              onFocus={() => {
                setIsFocused(true);
                setIsDirty(false);
                setDisableCommitOnBlur(true);
              }}
              param={param}
              readOnly={!!disabled}
              setValue={(newValue: any): void => {
                if (disabled) {
                  return;
                }
                updateValue(newValue);
              }}
              value={value}
            />
          </div>
          <div
            className="columnSelector"
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}>
            <CommonMenu
              anchorEl={inputRef.current}
              disableFocus // the common menu takes over the focus from the input
              menuItems={menuItems}
              modal={false}
              onClose={() => { }}
              open={columnSelectorOpen}
              wrapHeading
            />
          </div>
        </div>
      ) : (
        <SelectedColumnInput
          columnIndex={columnIndex}
          param={param}
          table={tableProto!}
          unlinkColumn={unlinkColumn}
          unlinkTooltip={`Unlink ${columnNames[columnIndex - 1]} profile`}
        />
      )}
    </div>
  );
};
