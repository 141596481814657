// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { KeyboardEvent, useRef, useState } from 'react';

import cx from 'classnames';

import { isUnmodifiedSpaceKey } from '../../../lib/event';
import { useContentOverflow } from '../../../lib/useContentOverflow';
import Tooltip from '../../Tooltip';
import { OverflowGradient } from '../../common/OverflowGradient';
import { EditableText } from '../../controls/EditableText';
import { XIcon } from '../../svg/XIcon';
import { CircularLoader } from '../../visual/CircularLoader';

import './Tab.scss';

export type TabSize = 'small' | 'medium';

export interface TabClose {
  onClose: () => void;
  disabled?: boolean;
}

export interface TabProps {
  label: string;
  foreground?: boolean;
  onClick?: () => void;
  onRename?: (name: string) => void;
  // If true, render a loading spinner before the label (default false).
  loading?: boolean;
  size?: TabSize; // defaults to 'medium'
  minWidth?: number | string; // optional minimum width of the tab
  maxWidth?: number | string; // optional maximum width of the tab
  close?: TabClose; // configure an optional close button
  help?: string; // optional tooltip
  elastic?: boolean; // configures tabs to grow/shrink based on available space
  dataTabClickableLocator?: string; // locator for the ui_tests
  disabled?: boolean;
  gradientColor?: string;
  enableCloseAnimation?: boolean;
}

export const Tab = (props: TabProps) => {
  const {
    close,
    disabled,
    foreground,
    help,
    label,
    loading = false,
    onClick,
    onRename,
    size = 'medium',
    minWidth,
    maxWidth,
    elastic,
    dataTabClickableLocator = label,
    gradientColor,
    enableCloseAnimation = false,
  } = props;

  const renderEditableText = !disabled && onRename;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const isLabelOverflowing = useContentOverflow(labelRef, !renderEditableText);
  // State
  const [renamingActive, setRenamingActive] = useState(false);
  const [isShrinking, setIsShrinking] = useState(false);

  // The Tab design includes outwardly rounded corners on the bottom left and bottom right.  To
  // achieve this, the tab is rendered as a 3x2 grid:

  //  ________________________________________
  // |        |                      |        |
  // |        |                      |        |
  // | spacer |       tab body       | spacer |
  // |        |                      |        |
  // |________|______________________|________|
  // | lip    |         foot         |    lip |
  // |_______◢|______________________|◣_______|
  //
  // Each .lip element draws a rectangle to hide the existing border and then with the :after
  // pseudo class it draws the actual rounded border for the active/hovered tab

  const body = (
    <>
      <div className="spacer" />
      <div className="main">
        {loading && <CircularLoader duration={2000} size={12} strokeRatio={0.2} />}
        {renderEditableText ? (
          <EditableText
            active={renamingActive}
            gradientColor={gradientColor}
            initialSelection="end"
            onChange={onRename}
            onDoubleClick={(event) => {
              event?.stopPropagation();
              setRenamingActive(true);
            }}
            overflowStrategy="gradient"
            truncate
            value={label}
          />
        ) : (
          <div className="label" ref={labelRef}>
            {label}
            {isLabelOverflowing && (<OverflowGradient gradientColor={gradientColor} />)}
          </div>
        )}
        {close && (
          <div className="control">
            <button
              disabled={disabled || close.disabled}
              onClick={(event) => {
                event.stopPropagation?.();
                if (!enableCloseAnimation) {
                  close.onClose();
                  return;
                }

                const wrapper = wrapperRef.current;
                if (!wrapper) {
                  return;
                }

                setIsShrinking(true);
                const animation = wrapper.animate(
                  [{ maxWidth: 0 }],
                  { duration: 150, fill: 'forwards' },
                );

                animation.addEventListener('finish', () => {
                  close.onClose();
                });
              }}
              type="button">
              <XIcon maxHeight={8} maxWidth={8} />
            </button>
          </div>
        )}
      </div>
      <div className="spacer" />
      <div className="divider" />
    </>
  );

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  const bodyAttrs = {
    'data-tab-clickable': dataTabClickableLocator,
    role: 'button',
    tabIndex: 0,
    onClick: handleClick,
    onKeyUp: (event: KeyboardEvent) => {
      if (isUnmodifiedSpaceKey(event)) {
        handleClick();
      }
    },
  };

  return (
    <div
      className={cx('luminaryTab', size, { foreground, closable: close, elastic, disabled })}
      data-locator="tab"
      ref={wrapperRef}
      style={{ minWidth: isShrinking ? 0 : minWidth, maxWidth }}>
      <Tooltip title={disabled ? '' : help || ''}>
        <div className="body" {...bodyAttrs}>{body}</div>
      </Tooltip>
      <div className="foot">
        <div className="lip left" />
        <div className="base" />
        <div className="lip right" />
      </div>
    </div>
  );
};
