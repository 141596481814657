// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { Vector3 } from '../../../proto/base/base_pb';
import { CenterInput } from '../PrimitiveInputs/CenterInput';
import { RadiusInput } from '../PrimitiveInputs/RadiusInput';

type Sphere = {
  center: { x: number, y: number, z: number },
  radius: number,
}

export interface SphereInputProps {
  value: Sphere,
  onCommit: (value: Sphere) => void,
  onChange?: (value: Sphere) => void,
  disabled?: boolean,
}

export const SphereInput = (props: SphereInputProps) => {
  const { value, onCommit, disabled = false, onChange } = props;

  const handleCenterCommit = (center: Vector3) => {
    onCommit({ ...value, center: { x: center.x, y: center.y, z: center.z } });
  };

  const handleRadiusCommit = (radius: number) => {
    onCommit({ ...value, radius });
  };

  const handleCenterChange = (center: Vector3) => {
    onChange?.({ ...value, center: { x: center.x, y: center.y, z: center.z } });
  };

  const handleRadiusChange = (radius: number) => {
    onChange?.({ ...value, radius });
  };

  return (
    <>
      <CenterInput
        disabled={disabled}
        label="Center"
        onChange={handleCenterChange}
        onCommit={handleCenterCommit}
        value={new Vector3({ ...value.center })}
      />
      <RadiusInput
        inputProps={{
          value: value.radius,
          onCommit: handleRadiusCommit,
          disabled,
          onChange: handleRadiusChange,
        }}
        type="single"
      />
    </>
  );
};
