// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ParamGroupName, paramGroupDesc } from '../../../../../SimulationParamDescriptor';
import * as flags from '../../../../../flags';
import assert from '../../../../../lib/assert';
import { fluidConstraints, meshConstraints } from '../../../../../lib/initializationUtils';
import { parsePhysicsIdFromSubId } from '../../../../../lib/physicsUtils';
import { useFluidPhysics } from '../../../../../model/hooks/useFluidPhysics';
import * as simulationpb from '../../../../../proto/client/simulation_pb';
import { useEnabledExperiments } from '../../../../../recoil/useExperimentConfig';
import { useSimulationParamScope } from '../../../../../state/external/project/simulation/paramScope';
import { ParamForm } from '../../../../ParamForm';
import { useCommonTreePropsStyles } from '../../../../Theme/commonStyles';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useSelectionContext } from '../../../../context/SelectionManager';
import PropertiesSection from '../../../PropertiesSection';
import { ExistingSolution } from '../../shared/ExistingSolution';

const paramGroup = paramGroupDesc[ParamGroupName.InitializationFluid];

export const PhysicsFluidInitializationPropPanel = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'Initialization Panel should only display when a node is selected.');
  const physicsId = parsePhysicsIdFromSubId(node.id);

  // == Recoil
  const experimentConfig = useEnabledExperiments();
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // == Hooks
  const {
    getParamScope,
    initialization,
    setInitialization,
  } = useFluidPhysics(projectId, workflowId, jobId, readOnly, physicsId);
  const propClasses = useCommonTreePropsStyles();

  if (!initialization) {
    return null;
  }

  // == Data
  const initializationType = initialization?.initializationType;
  const physicsScope = getParamScope(paramScope);
  const allowDifferentMeshes = experimentConfig.includes(flags.solutionInterpolation);

  return (
    <div className={propClasses.properties}>
      <PropertiesSection>
        {initializationType === simulationpb.InitializationType.EXISTING_SOLUTION ? (
          <ExistingSolution
            constraints={[...fluidConstraints, ...meshConstraints(allowDifferentMeshes)]}
            paramScope={physicsScope}
            physicsId={physicsId}
          />
        ) : (
          <ParamForm<simulationpb.InitializationFluid>
            group={paramGroup}
            onUpdate={setInitialization}
            paramScope={physicsScope}
            proto={initialization}
            readOnly={readOnly}
          />
        )}
      </PropertiesSection>
    </div>
  );
};
