// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useRecoilState } from 'recoil';

export const runSimulationPendingState = atom<boolean>({
  key: 'runSimulationPending',
  default: false,
});

export function useRunSimulationPending() {
  return useRecoilState(runSimulationPendingState);
}
