// Copyright 2022 Luminary Cloud, Inc. All Rights Reserved.

// Represents how much the mouse has moved
export interface DragOffset {
  deltaX: number;
  deltaY: number;
}

export type ScalarInset = number;
export interface FullInset {
  top: ScalarInset;
  right: ScalarInset;
  bottom: ScalarInset;
  left: ScalarInset;
}

// In much the same way a single padding value (e.g. 10px) is shorthand for a
// full padding value (e.g. 10px 10px 10px 10px), a ScalarInset is shorthand for
// a FullInset value with all four sides set to the same scalar value.  This
// method simply expresses any inset as a FullInset value for ease of use.
export const normalizeInset = (inset: ScalarInset | FullInset): FullInset => {
  if (typeof inset === 'object') {
    return inset;
  }

  return { top: inset, right: inset, bottom: inset, left: inset };
};
