// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useCallback, useEffect, useMemo } from 'react';

import { useUserHasProjectRole } from '../../../lib/projectRoles';
import { SHARED_TO_SUPPORT_TOOLTIP } from '../../../lib/projectShareUtils';
import { getFullName } from '../../../lib/user';
import * as frontendpb from '../../../proto/frontend/frontend_pb';
import useAccountInfo from '../../../recoil/useAccountInfo';
import { useIsProjectSharedWithSupport, useProjectMetadataValue } from '../../../recoil/useProjectMetadata';
import {
  rpcRefreshSharedState,
  useForceViewStateSync,
  useProjectShareDialog,
} from '../../../state/external/project/sharing';
import { ActionButton } from '../../Button/ActionButton';
import Dropdown from '../../Dropdown';
import { createStyles, makeStyles } from '../../Theme';
import Tooltip from '../../Tooltip';
import { ProjectShareDialog } from '../../dialog/ProjectShare';
import { ColoredAvatar } from '../../visual/ColoredAvatar';
import { ColoredCircle } from '../../visual/ColoredCircle';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      gap: '24px',
      marginRight: '16px',
    },
    userAvatars: {
      display: 'flex',
      gap: '12px',
      flexDirection: 'row-reverse',
      alignItems: 'center',
    },
    userAvatarIcon: {
      // if we don't have a flex container, the support icon do not align vertically with the others
      display: 'flex',
    },
    moreUsersToggle: {
      cursor: 'pointer',
    },
    userInDropdown: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },
  }),
  { name: 'ProjectShareSection' },
);

// This is the maximum users we'll show before showing a generic (+X) avatar for the extra users.
// Note that if we have 5 users, we'll still show the 5th avatar in order to avoid a dummy +1 avatar
const MAX_USERS = 4;

export interface ProjectShareSectionProps {
  projectId: string;
}

export const ProjectShareSection = (props: ProjectShareSectionProps) => {
  const { projectId } = props;

  const classes = useStyles();
  const accountInfo = useAccountInfo();
  const projectMetadata = useProjectMetadataValue(projectId);
  const isUserOwner = useUserHasProjectRole(projectMetadata?.summary, 'admin');
  const forceViewStateSync = useForceViewStateSync(projectId);
  const [projectShareDialog, setProjectShareDialog] = useProjectShareDialog();
  const isProjectSharedWithSupport = useIsProjectSharedWithSupport(projectId);

  // Keep a {userId: [...permissions]} map with all the users that have access to the project. We'll
  // use that map to sort the users later by their project role.
  const userRolesMap = useMemo(
    () => projectMetadata?.summary?.acl.reduce((obj, entry) => {
      const userId = entry.subject?.id;
      if (userId) {
        obj[userId] = entry.role;
      }
      return obj;
    }, {} as Record<string, string[]>) || {},
    [projectMetadata],
  );

  // Sort ProjectAclEntry items and put the entries with an "admin" role first.
  const sortUsersByAdminRole = useCallback((
    a: frontendpb.AccountInfoReply_UserInfo,
    b: frontendpb.AccountInfoReply_UserInfo,
  ) => {
    const ADMIN_ROLE = 'admin';
    const aPriority = userRolesMap[a.lcUserId].includes(ADMIN_ROLE) ? 1 : 0;
    const bPriority = userRolesMap[b.lcUserId].includes(ADMIN_ROLE) ? 1 : 0;
    return bPriority - aPriority;
  }, [userRolesMap]);

  const usersWithAccess = useMemo(() => {
    const users = projectMetadata?.summary?.acl?.reduce((acc, acl) => {
      const user = accountInfo?.user.find((item) => item.lcUserId === acl.subject?.id);
      if (user) {
        acc.push(user);
      }
      return acc;
    }, [] as frontendpb.AccountInfoReply_UserInfo[]) || [];

    // First sort the users by name (so that the ordering is consistent) and then order by the
    // project role by putting the admin first. Note, that this sort by admin is not sorting by
    // the user role (user vs admin) but by the project role (viewer vs admin).
    return users.sort((a, b) => (
      a.givenName.localeCompare(b.givenName, undefined, { sensitivity: 'base' })
    )).sort(sortUsersByAdminRole);
  }, [accountInfo, projectMetadata, sortUsersByAdminRole]);

  useEffect(() => {
    if (projectMetadata === null || !projectId || isUserOwner) {
      return;
    }
    rpcRefreshSharedState(projectId).then(() => {
      forceViewStateSync();
    }).catch((err) => { });
  }, [projectId, projectMetadata, isUserOwner, forceViewStateSync]);

  // If the users are only 1 more than the MAX_USERS, show the extra user as well, to avoid a (+1)
  // placeholder (the placeholder takes the same space as the user so show the user instead).
  const sliceUsersEnd = usersWithAccess.length === MAX_USERS + 1 ? MAX_USERS + 1 : MAX_USERS;

  return (
    <div className={classes.root}>
      {(isProjectSharedWithSupport || usersWithAccess.length > 1) && (
        <div className={classes.userAvatars}>
          {isProjectSharedWithSupport && (
            <Tooltip
              title={SHARED_TO_SUPPORT_TOOLTIP}>
              <span className={classes.userAvatarIcon}>
                <ColoredCircle icon={{ name: 'headphones', maxWidth: 16 }} />
              </span>
            </Tooltip>
          )}
          {usersWithAccess.slice(0, sliceUsersEnd).map((user) => (
            <Tooltip
              key={user.lcUserId}
              title={getFullName(user)}>
              <span className={classes.userAvatarIcon}>
                <ColoredAvatar name={getFullName(user)} />
              </span>
            </Tooltip>
          ))}
          {usersWithAccess.length > MAX_USERS + 1 && (
            <Dropdown
              menuItems={usersWithAccess.slice(MAX_USERS).map((user) => ({
                label: (
                  <div className={classes.userInDropdown}>
                    <ColoredAvatar name={getFullName(user)} />
                    {getFullName(user)}
                  </div>
                ),
                onClick: () => { },
              }))}
              position="below-left"
              toggle={(
                <span className={classes.moreUsersToggle}>
                  <ColoredCircle>
                    {usersWithAccess.length > 99 + MAX_USERS ?
                      '99+' :
                      `+${usersWithAccess.length - MAX_USERS}`}
                  </ColoredCircle>
                </span>
              )}
            />
          )}
        </div>
      )}
      {projectId && isUserOwner && (
        <Tooltip title="Share this project">
          <span>
            <ActionButton
              disabled={projectShareDialog.open || !accountInfo}
              kind="secondary"
              name="shcButton"
              onClick={() => {
                setProjectShareDialog({
                  open: true,
                  projectId: projectId || '',
                });
              }}
              size="small"
              startIcon={usersWithAccess.length > 1 ? { name: 'people' } : undefined}>
              Share
            </ActionButton>
          </span>
        </Tooltip>
      )}

      <ProjectShareDialog
        accountInfo={accountInfo}
        onCancel={() => setProjectShareDialog({
          open: false,
          projectId: '',
        })}
        open={projectShareDialog.open}
        projectId={projectShareDialog.projectId}
      />
    </div>
  );
};
