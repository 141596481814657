// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 10;
export const WavesQuadrupleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="wavesQuadruple"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0,10
           L0,8.857
           C0.617,8.857 0.924,8.691 1.234,8.529
           C1.675,8.299 2.118,8.071 3,8.071
           C3.718,8.071 4.148,8.225 4.521,8.405
           C4.922,8.599 5.227,8.857 6,8.857
           C6.773,8.857 7.078,8.599 7.48,8.405
           C7.853,8.225 8.283,8.071 9,8.071
           C9.718,8.071 10.146,8.225 10.519,8.405
           C10.919,8.6 11.224,8.857 12,8.857
           L12,10
           C11.114,10 10.671,9.773 10.231,9.542
           C9.923,9.38 9.617,9.214 9,9.214
           C8.228,9.214 7.923,9.472 7.522,9.666
           C7.148,9.847 6.718,10 6,10
           C5.282,10 4.852,9.847 4.479,9.666
           C4.077,9.472 3.773,9.214 3,9.214
           C2.227,9.214 1.921,9.472 1.519,9.666
           C1.146,9.847 0.716,10 0,10Z
           M0,7.31
           L0,6.167
           C0.617,6.167 0.924,6.001 1.234,5.839
           C1.675,5.608 2.118,5.381 3,5.381
           C3.718,5.381 4.148,5.534 4.521,5.715
           C4.922,5.909 5.227,6.167 6,6.167
           C6.773,6.167 7.078,5.909 7.48,5.715
           C7.853,5.534 8.283,5.381 9,5.381
           C9.718,5.381 10.146,5.534 10.519,5.715
           C10.919,5.909 11.224,6.167 12,6.167
           L12,7.31
           C11.114,7.31 10.671,7.082 10.231,6.851
           C9.923,6.689 9.617,6.524 9,6.524
           C8.228,6.524 7.923,6.782 7.522,6.976
           C7.148,7.156 6.718,7.31 6,7.31
           C5.282,7.31 4.852,7.156 4.479,6.976
           C4.077,6.782 3.773,6.524 3,6.524
           C2.227,6.524 1.921,6.782 1.519,6.976
           C1.146,7.156 0.716,7.31 0,7.31Z
           M0,4.619
           L0,3.476
           C0.617,3.476 0.924,3.31 1.234,3.149
           C1.675,2.918 2.118,2.69 3,2.69
           C3.718,2.69 4.148,2.844 4.521,3.024
           C4.922,3.218 5.227,3.476 6,3.476
           C6.773,3.476 7.078,3.218 7.48,3.024
           C7.853,2.844 8.283,2.69 9,2.69
           C9.718,2.69 10.146,2.844 10.519,3.024
           C10.919,3.219 11.224,3.476 12,3.476
           L12,4.619
           C11.114,4.619 10.671,4.392 10.231,4.161
           C9.923,3.999 9.617,3.833 9,3.833
           C8.228,3.833 7.923,4.091 7.522,4.286
           C7.148,4.466 6.718,4.619 6,4.619
           C5.282,4.619 4.852,4.466 4.479,4.285
           C4.077,4.091 3.773,3.833 3,3.833
           C2.227,3.833 1.921,4.091 1.519,4.286
           C1.146,4.466 0.716,4.619 0,4.619Z
           M0,1.929
           L0,0.786
           C0.617,0.786 0.924,0.62 1.234,0.458
           C1.675,0.227 2.118,0 3,0
           C3.718,0 4.148,0.153 4.521,0.334
           C4.922,0.528 5.227,0.786 6,0.786
           C6.773,0.786 7.078,0.528 7.48,0.334
           C7.853,0.153 8.283,0 9,0
           C9.718,0 10.146,0.153 10.519,0.334
           C10.919,0.528 11.224,0.786 12,0.786
           L12,1.929
           C11.114,1.929 10.671,1.701 10.231,1.47
           C9.923,1.309 9.617,1.143 9,1.143
           C8.228,1.143 7.923,1.401 7.522,1.595
           C7.148,1.775 6.718,1.929 6,1.929
           C5.282,1.929 4.852,1.775 4.479,1.595
           C4.077,1.401 3.773,1.143 3,1.143
           C2.227,1.143 1.921,1.401 1.519,1.595
           C1.146,1.776 0.716,1.929 0,1.929Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
