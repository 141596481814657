// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const TreeIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="tree"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M22.5,12
           L22.5,16.5
           L19.5,16.5
           L19.5,13.5
           L13.5,13.5
           L13.5,16.5
           L10.5,16.5
           L10.5,13.5
           L4.5,13.5
           L4.5,16.5
           L1.5,16.5
           L1.5,12
           C1.5,11.172 2.172,10.5 3,10.5
           L10.5,10.5
           L10.5,7.5
           L13.5,7.5
           L13.5,10.5
           L21,10.5
           C21.828,10.5 22.5,11.172 22.5,12Z
           M1.5,18
           L4.5,18
           C5.328,18 6,18.672 6,19.5
           L6,22.5
           C6,23.328 5.328,24 4.5,24
           L1.5,24
           C0.672,24 0,23.328 0,22.5
           L0,19.5
           C0,18.672 0.672,18 1.5,18Z
           M19.5,18
           L22.5,18
           C23.328,18 24,18.672 24,19.5
           L24,22.5
           C24,23.328 23.328,24 22.5,24
           L19.5,24
           C18.672,24 18,23.328 18,22.5
           L18,19.5
           C18,18.672 18.672,18 19.5,18Z
           M10.5,18
           L13.5,18
           C14.328,18 15,18.672 15,19.5
           L15,22.5
           C15,23.328 14.328,24 13.5,24
           L10.5,24
           C9.672,24 9,23.328 9,22.5
           L9,19.5
           C9,18.672 9.672,18 10.5,18Z
           M10.5,0
           L13.5,0
           C14.328,0 15,0.672 15,1.5
           L15,4.5
           C15,5.328 14.328,6 13.5,6
           L10.5,6
           C9.672,6 9,5.328 9,4.5
           L9,1.5
           C9,0.672 9.672,0 10.5,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
