// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useAtomValue, useSetAtom } from 'jotai';
import { atomFamily, atomWithRefresh } from 'jotai/utils';
import { atomFamily as atomFamilyRecoil } from 'recoil';

import * as rpc from '../../lib/rpc';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';

// List of geometries in a given project.
export const geometryListState = atomFamily((projectId: string) => atomWithRefresh(async () => {
  if (!rpc.clientGeometry || !projectId) {
    return null;
  }
  try {
    const response = await rpc.callRetryWithClient(
      rpc.clientGeometry,
      'listGeometries',
      rpc.clientGeometry.listGeometries,
      new geometryservicepb.ListGeometriesRequest({ projectId }),
    );
    return response;
  } catch (error) {
    console.error('Failed to list geometries', error);
    return null;
  }
}));

export function useGeometryList(projectId: string) {
  return useAtomValue(geometryListState(projectId));
}

export function useRefreshGeometryList(projectId: string) {
  return useSetAtom(geometryListState(projectId));
}

export const geometryListState_DEPRECATED = atomFamilyRecoil<
  geometryservicepb.ListGeometriesResponse | null,
  string
>({
  key: 'geometryListState_DEPRECATED',
  default: null,
  dangerouslyAllowMutability: true,
});
