// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 22;
export const FunctionIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="function"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M12.18,5.61
           L10.47,5.61
           C10.965,3.675 11.595,2.25 12.39,1.35
           C12.885,0.795 13.35,0.51 13.815,0.51
           C13.905,0.51 13.98,0.54 14.04,0.585
           C14.1,0.645 14.13,0.72 14.13,0.81
           C14.13,0.885 14.07,1.035 13.935,1.245
           C13.8,1.455 13.74,1.665 13.74,1.845
           C13.74,2.115 13.845,2.34 14.04,2.535
           C14.25,2.73 14.505,2.82 14.82,2.82
           C15.15,2.82 15.435,2.7 15.66,2.475
           C15.885,2.235 16.005,1.92 16.005,1.53
           C16.005,1.08 15.84,0.705 15.495,0.42
           C15.15,0.135 14.61,0 13.86,0
           C12.69,0 11.625,0.33 10.68,1.005
           C9.72,1.68 8.82,2.805 7.965,4.35
           C7.665,4.89 7.395,5.235 7.125,5.385
           C6.855,5.535 6.435,5.61 5.85,5.61
           L5.46,6.96
           L7.08,6.96
           L4.695,16.14
           C4.29,17.655 4.035,18.585 3.885,18.93
           C3.675,19.44 3.375,19.875 2.955,20.235
           C2.79,20.385 2.595,20.46 2.355,20.46
           C2.295,20.46 2.235,20.445 2.19,20.4
           L2.13,20.325
           C2.13,20.28 2.19,20.205 2.31,20.085
           C2.43,19.965 2.49,19.785 2.49,19.545
           C2.49,19.275 2.4,19.05 2.205,18.885
           C2.025,18.705 1.755,18.615 1.395,18.615
           C0.975,18.615 0.63,18.735 0.375,18.96
           C0.135,19.17 0,19.44 0,19.755
           C0,20.085 0.15,20.385 0.465,20.64
           C0.78,20.895 1.26,21.015 1.92,21.015
           C2.97,21.015 3.9,20.745 4.725,20.205
           C5.535,19.665 6.255,18.825 6.855,17.7
           C7.47,16.575 8.085,14.76 8.73,12.255
           L10.095,6.945
           L11.745,6.945
           L12.18,5.61Z
           M20.325,7.89
           C20.625,7.665 20.94,7.545 21.255,7.545
           C21.375,7.545 21.6,7.59 21.93,7.68
           C22.26,7.77 22.545,7.815 22.785,7.815
           C23.13,7.815 23.415,7.695 23.64,7.47
           C23.88,7.23 24,6.93 24,6.555
           C24,6.165 23.88,5.85 23.655,5.625
           C23.43,5.4 23.1,5.28 22.665,5.28
           C22.29,5.28 21.915,5.37 21.585,5.55
           C21.24,5.73 20.82,6.12 20.295,6.72
           C19.905,7.17 19.335,7.935 18.57,9.045
           C18.27,7.68 17.745,6.42 16.995,5.28
           L12.915,5.97
           L12.825,6.405
           C13.125,6.345 13.38,6.315 13.59,6.315
           C13.995,6.315 14.325,6.48 14.595,6.825
           C15.015,7.365 15.6,9 16.35,11.715
           C15.765,12.495 15.36,12.99 15.15,13.23
           C14.79,13.62 14.49,13.86 14.265,13.98
           C14.085,14.07 13.89,14.115 13.65,14.115
           C13.485,14.115 13.2,14.025 12.81,13.845
           C12.54,13.725 12.3,13.665 12.09,13.665
           C11.685,13.665 11.37,13.785 11.1,14.04
           C10.845,14.295 10.71,14.61 10.71,15
           C10.71,15.375 10.83,15.66 11.07,15.9
           C11.31,16.125 11.625,16.245 12.03,16.245
           C12.42,16.245 12.78,16.17 13.125,16.005
           C13.47,15.84 13.905,15.495 14.415,14.97
           C14.94,14.445 15.645,13.62 16.56,12.465
           C16.905,13.56 17.22,14.34 17.475,14.835
           C17.73,15.33 18.03,15.69 18.36,15.9
           C18.69,16.125 19.11,16.23 19.605,16.23
           C20.085,16.23 20.58,16.065 21.075,15.72
           C21.735,15.27 22.395,14.505 23.085,13.425
           L22.695,13.2
           C22.23,13.845 21.885,14.25 21.66,14.4
           C21.51,14.505 21.33,14.55 21.135,14.55
           C20.895,14.55 20.655,14.4 20.415,14.1
           C20.01,13.59 19.485,12.195 18.825,9.9
           C19.425,8.88 19.92,8.205 20.325,7.89Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
