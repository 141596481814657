// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import * as flags from '../../../../flags';
import { UploadProgress } from '../../../../lib/UploadProgress';
import assert from '../../../../lib/assert';
import * as random from '../../../../lib/random';
import * as rpc from '../../../../lib/rpc';
import { addRpcError } from '../../../../lib/transientNotification';
import { CAD_FILE_TYPES, DIRECTORY_FILE_TYPES, fileTypesToSelectOption } from '../../../../lib/upload/fileTypes';
import { isInteractiveGeometryFile } from '../../../../lib/upload/uploadUtils';
import * as geometryservicepb from '../../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as frontendpb from '../../../../proto/frontend/frontend_pb';
import * as geometrypb from '../../../../proto/geometry/geometry_pb';
import * as uploadpb from '../../../../proto/upload/upload_pb';
import { DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE, createOrUpdateFeature, useGeometrySelectedFeature, useGeometryState } from '../../../../recoil/geometry/geometryState';
import { useIsEnabled } from '../../../../recoil/useExperimentConfig';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import Tooltip from '../../../Tooltip';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import MeshImportDialog from '../../../project/MeshImportDialog';
import PropertiesSection from '../../PropertiesSection';

import GeometryModificationPanelFooter from './GeometryModificationPanelFooter';
import { EditModificationMessage } from './GeometryModificationShared';

const FILE_TYPE_OPTIONS = fileTypesToSelectOption(CAD_FILE_TYPES);

export function getNameFromUrl(url: string) {
  const urlParams = new URLSearchParams(url);
  return urlParams.get('name') || 'Unknown Filename';
}

// Handles the import of a geometry within the current geometry.
export const GeometryModificationImportPropPanel = () => {
  // == Contexts
  const { projectId, geometryId, readOnly } = useProjectContext();
  const { selectedNode: node, setSelection } = useSelectionContext();
  assert(!!node, 'No selected geometry modification import row');

  // == Recoil
  const geometryState = useGeometryState(projectId, geometryId);
  const [, setSelectedFeature] = useGeometrySelectedFeature(geometryId);
  const discreteEnabled = useIsEnabled(flags.interactiveGeometryDiscrete);

  // == Hooks
  const propClasses = useCommonTreePropsStyles();

  // == State
  const [openDialog, setOpenDialog] = useState(false);

  // == Derived data
  const mod = geometryState?.geometryFeatures.find((feature) => feature.id === node.id);
  assert(!!mod, 'No selected geometry modification import');
  const acknowledgedMod = geometryState?.ackModifications.has(mod?.id || '');
  useEffect(() => {
    setOpenDialog(!acknowledgedMod);
  }, [acknowledgedMod]);

  const featureIn = mod.operation.value as geometrypb.Import;
  const urlFeature = featureIn.geometryUrl;
  const fileUrl = getNameFromUrl(urlFeature);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelection([]);
  };

  const processInputFile = async (
    url: string,
    scaling: number,
    conversion: frontendpb.MeshConversionStatus,
    setUploadProgress: (value: React.SetStateAction<UploadProgress>) => void,
    fileNames: string[],
    fileTyp: uploadpb.MeshType | undefined,
  ) => {
    if (readOnly) {
      return null;
    }
    if (!fileNames.every((name) => isInteractiveGeometryFile(name, discreteEnabled))) {
      addRpcError('Invalid file type(s)', new Error());
      return null;
    }
    const feature = new geometrypb.Feature({
      id: node.id,
      operation: {
        case: 'import',
        value: new geometrypb.Import({
          geometryUrl: url,
          scaling,
        }),
      },
      // This is consistent with how the backend generates the first import feature name (i.e. w/o
      // the extension).
      featureName: `Import ${fileNames[0]}`,
    });
    const req = new geometryservicepb.ModifyGeometryRequest({
      geometryId,
      modification: new geometrypb.Modification({
        modType: createOrUpdateFeature(geometryState, node.id),
        feature,
      }),
    });
    req.requestId = random.string(32);

    rpc.clientGeometry!.modifyGeometry(req).catch((err) => {
      console.error('Failed to import geometry', err);
    });

    // Focus out of the panel, to avoid weird rerenderings when calling setSelection.
    setSelectedFeature(DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE);

    setSelection([]);
    return null;
  };

  return (
    <>
      <MeshImportDialog
        acceptNumericExtensions
        directoryTypeOptions={DIRECTORY_FILE_TYPES}
        fileTypeOptions={FILE_TYPE_OPTIONS}
        onClose={handleDialogClose}
        open={openDialog}
        processInputFile={processInputFile}
        projectId={projectId}
        type="CAD"
      />
      <PropertiesSection>
        <div className={propClasses.properties}>
          <EditModificationMessage nodeId={node.id} />
          <div className={propClasses.typeRow}>
            <div className={propClasses.typeKey} style={{ whiteSpace: 'nowrap' }}>File Name:</div>
            <Tooltip title={fileUrl}>
              <span
                className={propClasses.typeValue}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>
                {fileUrl}
              </span>
            </Tooltip>
          </div>
          <div className={propClasses.typeRow}>
            <div className={propClasses.typeKey}>Scaling:</div>
            <div className={propClasses.typeValue}>{featureIn.scaling}</div>
          </div>
        </div>
      </PropertiesSection>
      <GeometryModificationPanelFooter
        featureId={node.id}
      />
    </>
  );
};
