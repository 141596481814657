// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const PencilIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="pencil"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M3.871,15.872
           L8.1,20.101
           L19.554,8.647
           L15.342,4.4
           L3.871,15.872Z
           M0.001,23.97
           L6.61,21.59
           L2.398,17.413
           L0.001,23.97Z
           M20.975,-0
           C20.153,-0 19.4,0.342 18.852,0.873
           L16.387,3.339
           L20.616,7.568
           L23.081,5.102
           C23.629,4.554 23.954,3.818 23.954,2.979
           C23.971,1.353 22.636,-0 20.975,-0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
