// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import assert from '../../../lib/assert';
import { SelectOption } from '../../../lib/componentTypes/form';
import { toPositiveAbsoluteInteger } from '../../../lib/number';
import { STOP_COND_OUTPUT_NODES } from '../../../lib/outputNodeUtils';
import { isSimulationImplicitTime, isSimulationTransient } from '../../../lib/simulationUtils';
import { newStopCond } from '../../../lib/stoppingCondsUtils';
import * as feoutputpb from '../../../proto/frontend/output/output_pb';
import * as outputpb from '../../../proto/output/output_pb';
import { useOutputNodes } from '../../../recoil/outputNodes';
import { useSetStoppingConditions, useStoppingConditions } from '../../../recoil/useStoppingConditions';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import Form from '../../Form';
import { DataSelect } from '../../Form/DataSelect';
import { NumberInput } from '../../Form/NumberInput';
import { CollapsibleNodePanel } from '../../Panel/CollapsibleNodePanel';
import Divider from '../../Theme/Divider';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { ListItemId, ListManager } from '../../controls/ListManager';
import PropertiesSection from '../PropertiesSection';

export const conditionBaseOptions: SelectOption<outputpb.StoppingConditionOp>[] = [
  {
    value: outputpb.StoppingConditionOp.STOP_COND_OP_ALL,
    name: 'ALL conditions are met',
    tooltip: 'The solver will stop if all conditions are met.',
  },
  {
    value: outputpb.StoppingConditionOp.STOP_COND_OP_ANY,
    name: 'ANY condition is met',
    tooltip: 'The solver will stop if any condition is met.',
  },
];

const helpResidualTolerance = 'Condition is met when the residual drops below this tolerance.';
const helpNotResidualTolerance = `Condition is met when the moving average in the monitored output
  deviates by less than this percentage of its current moving average over the specified number of
  iterations.`;
const helpForceSurfaceAverage = `Monitor the percent deviation in the moving average of this output
  over the specified number of iterations from its current moving average.`;
const helpResidual = 'Monitor the residuals selected by this output';
const helpStart = `The starting iteration for this condition. The condition will evaluate to false
  before this iteration is reached.`;
const outputNameHelp = `The output monitored for this stopping condition.  If the selected output
  defines multiple quantities, the condition is applied to all of them individually based on the
  selection of the "Stop If" option`;
const conditionHelp = 'Behavior if multiple stopping conditions are defined';

function outputNodeHelp(node: feoutputpb.OutputNode): string {
  if (node.nodeProps.case === 'residual') {
    return helpResidual;
  }
  if (STOP_COND_OUTPUT_NODES.includes(node.nodeProps.case)) {
    return helpForceSurfaceAverage;
  }

  throw Error('Help not found');
}

// A functional hook that returns a function for saving stopping conditions
const useSaveStoppingConditions = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const setStopConds = useSetStoppingConditions(projectId, workflowId, jobId);

  // Function for applying some change to a set of conditions.
  // Takes a changeConds function, applies it to the conditions,
  // and saves the result.
  return (changeConds: (conds: feoutputpb.StoppingConditions) => void) => {
    setStopConds((oldConds) => {
      const newConds = oldConds.clone();
      changeConds(newConds);
      return newConds;
    });
  };
};

export const ConditionListItemBody = (props: { condition: feoutputpb.StoppingCondition }) => {
  const { condition } = props;
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  const [outputNodes] = useOutputNodes(projectId, workflowId, jobId);
  const updateConds = useSaveStoppingConditions();

  const condId = condition.id;
  const isResidual = condition.node?.nodeProps.case === 'residual';
  const scale = isResidual ? 1 : 100;

  const stopNodes = outputNodes.nodes.filter(
    (node) => STOP_COND_OUTPUT_NODES.includes(node.nodeProps.case),
  );

  const findCondition = (id: string, conds: feoutputpb.StoppingConditions) => (
    conds.cond.find((item) => item.id === id)
  );

  const selectedCondNodeId = condition.node?.id;
  const nodeSelectOptions: SelectOption<string>[] = stopNodes.map((node) => ({
    value: node.id,
    name: node.name,
    tooltip: outputNodeHelp(node),
    selected: node.id === selectedCondNodeId,
  }));

  return (
    <>
      <Form.LabeledInput
        help={outputNameHelp}
        label="Output Name">
        <DataSelect
          asBlock
          disabled={readOnly}
          onChange={(newId) => {
            updateConds(
              (conds) => {
                const cond = findCondition(condId, conds);
                const node = outputNodes.nodes.find(({ id }) => id === newId);
                if (cond) {
                  cond.node = node?.clone();
                }
              },
            );
          }}
          options={nodeSelectOptions}
          size="small"
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help={isResidual ? helpResidualTolerance : helpNotResidualTolerance}
        label="Tolerance">
        <NumberInput
          asBlock
          disabled={readOnly}
          endAdornment={isResidual ? '' : '%'}
          onCommit={(newValue) => updateConds((conds) => {
            const cond = findCondition(condId, conds);
            if (cond) {
              cond.threshold = Math.abs(newValue / scale);
            }
          })}
          size="small"
          value={scale * condition.threshold}
        />
      </Form.LabeledInput>
      <Form.LabeledInput
        help={helpStart}
        label="Start at Iteration">
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={(newValue) => updateConds((conds) => {
            const cond = findCondition(condId, conds);
            if (cond) {
              cond.startAt = toPositiveAbsoluteInteger(newValue);
            }
          })}
          size="small"
          value={toPositiveAbsoluteInteger(condition.startAt)}
        />
      </Form.LabeledInput>
      {(!!condition.node && condition.node.nodeProps.case !== 'residual') && (
        <>
          <Form.LabeledInput
            help={'Trailing average window length. The monitored value is averaged before the ' +
              'tolerance check is applied.'}
            label="Averaging Iterations">
            <NumberInput
              asBlock
              disabled={readOnly}
              onCommit={(newValue) => updateConds((conds) => {
                const cond = findCondition(condId, conds);
                if (cond) {
                  cond.nIterations = toPositiveAbsoluteInteger(newValue);
                }
              })}
              size="small"
              value={toPositiveAbsoluteInteger(condition.nIterations)}
            />
          </Form.LabeledInput>
          <Form.LabeledInput
            help={'Number of iterations to consider when determining ' +
              'maximum percent deviation from the current value.'}
            label="Iterations to Consider">
            <NumberInput
              asBlock
              disabled={readOnly}
              onCommit={(newValue) => updateConds((conds) => {
                const cond = findCondition(condId, conds);
                if (cond) {
                  cond.nAnalysisIters = toPositiveAbsoluteInteger(newValue);
                }
              })}
              size="small"
              value={toPositiveAbsoluteInteger(condition.nAnalysisIters)}
            />
          </Form.LabeledInput>
        </>
      )}
    </>
  );
};

export const StoppingConditionsPropPanel = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected stopping conditions row');

  // == Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const [outputNodes] = useOutputNodes(projectId, workflowId, jobId);
  const [stopConds] = useStoppingConditions(projectId, workflowId, jobId);
  const updateConds = useSaveStoppingConditions();

  const transient = isSimulationTransient(simParam);
  const implicitTime = transient && isSimulationImplicitTime(simParam);

  const condItemConfigs = useMemo(() => stopConds.cond.map((cond, i) => {
    const id = cond.id;
    return {
      id,
      content: {
        title: `${i + 1}.`,
        body: <ConditionListItemBody condition={cond} />,
      },
    };
  }), [stopConds]);

  const addStoppingCondition = () => {
    updateConds(
      (conds) => {
        if (!outputNodes.nodes.length) {
          // No output nodes to choose from.
          return;
        }
        const newCond = newStopCond();
        conds.cond.push(newCond);
        // Use the first output in the list as default.
        const firstNode = outputNodes.nodes[0].clone();
        newCond.node = firstNode;
        if (firstNode.nodeProps.case === 'residual') {
          newCond.threshold = 1e-5;
        }
      },
    );
  };

  const removeStoppingCondition = (id: ListItemId) => {
    updateConds((conds) => {
      conds.cond = conds.cond.filter((cond) => (cond.id !== id));
    });
  };

  const canAddStoppingCond = outputNodes.nodes.length > 0;

  const conditionOptions = conditionBaseOptions.map((option) => ({
    ...option,
    selected: option.value === stopConds.op,
  }));

  return (
    <div>
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="General"
          nodeId={node.id}
          panelName="general">
          <Form.LabeledInput
            help={transient ?
              'Maximum number of time-steps for transient simulations. ' +
              'The solver terminates if the maximum is reached.' :
              'Maximum number of nonlinear iterations for steady-state ' +
              'simulations. The solver terminates if the maximum is reached.'}
            label={transient ? 'Max Time Steps' : 'Max Iterations'}>
            <NumberInput
              asBlock
              disabled={readOnly}
              onCommit={(newValue) => updateConds((conds) => {
                conds.maxIterations = toPositiveAbsoluteInteger(newValue);
              })}
              size="small"
              value={toPositiveAbsoluteInteger(stopConds.maxIterations)}
            />
          </Form.LabeledInput>
          {// Max physical time input.
            transient && (
              <Form.LabeledInput
                help="Maximum physical time. The solver terminates if the maximum is reached."
                label="Max Physical Time">
                <NumberInput
                  asBlock
                  disabled={readOnly}
                  onCommit={(newValue) => updateConds((conds) => {
                    conds.maxPhysicalTime = Math.abs(newValue);
                  })}
                  size="small"
                  value={stopConds.maxPhysicalTime}
                />
              </Form.LabeledInput>
            )
          }
          {// Max inner iterations.
            implicitTime && (
              <Form.LabeledInput
                help={'Maximum number of inner iterations for implicit transient ' +
                  'simulations. The inner loop terminates if the maximum is reached.'}
                label="Max Inner Iterations">
                <NumberInput
                  asBlock
                  disabled={readOnly}
                  onCommit={(newValue) => updateConds((conds) => {
                    conds.maxInnerIterations = toPositiveAbsoluteInteger(newValue);
                  })}
                  size="small"
                  value={toPositiveAbsoluteInteger(stopConds.maxInnerIterations)}
                />
              </Form.LabeledInput>
            )
          }
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading={implicitTime ? 'Inner Iteration Conditions' : 'Conditions'}
          nodeId={node.id}
          panelName="conditions">
          <Form.LabeledInput
            help={conditionHelp}
            label="Stop If">
            <DataSelect
              asBlock
              disabled={readOnly}
              onChange={(newValue) => updateConds((conds) => {
                conds.op = newValue;
              })}
              options={conditionOptions}
              size="small"
            />
          </Form.LabeledInput>
          <div style={{ paddingTop: '8px' }}>
            <ListManager
              canAdd={canAddStoppingCond}
              disabled={readOnly}
              disabledReason={
                canAddStoppingCond ?
                  undefined : (
                    <>
                      Please define an output before <br />
                      adding a stopping condition.
                    </>
                  )
              }
              itemLabel="Stopping Condition"
              items={condItemConfigs}
              onAdd={addStoppingCondition}
              onDelete={removeStoppingCondition}
            />
          </div>
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
