// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/* ParamForm component implements a table that contains a collection of input
   fields. The list of fields and their types are defined by a protocol message
   and its ProtoDescriptors, as generated by generate_param_files.py.
*/
import * as React from 'react';

import { Message } from '@bufbuild/protobuf';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import * as ProtoDescriptor from '../ProtoDescriptor';
import { ParamScope } from '../lib/ParamScope';
import { ParamInputOptions } from '../lib/componentTypes/form';
import { initParamGroupProto } from '../lib/initParam';
import { setParamValue } from '../lib/paramCallback';
import { extractProtoField } from '../lib/proto';

import { ActionButton } from './Button/ActionButton';
import ParamRow from './ParamRow';
import { SectionMessage } from './notification/SectionMessage';

export function reorderParams(params: ProtoDescriptor.Param[], reorder: string[]) {
  reorder.forEach((paramName, newIndex) => {
    const curIndex = params.findIndex((param) => param.name === paramName);
    if (curIndex !== -1) {
      params.splice(newIndex, 0, params.splice(curIndex, 1)[0]);
    }
  });
}

export type InsertElement = {
  element: React.ReactElement,
  insert: ProtoDescriptor.Param,
  replace?: boolean
}

export function paramsToShow(
  paramScope: ParamScope,
  group: ProtoDescriptor.ParamGroup,
  removeParams: string[] = [],
) {
  return paramScope.enabledParams(group, false).filter(
    (param) => !removeParams.includes(param.pascalCaseName),
  );
}

export interface ParamFormProps<T extends Message> {
  // Proto message used to set/get values to/from
  proto: T;
  /**
   * Allow ignoreNestLevel to be set on the Form level, which passes this flag
   * to each created ParamRow
   */
  ignoreNestLevel?: boolean;
  /**
   * To be called when any field in the proto is updated. proto is a copy of
   * allProto, with some fields updated. This is a noop when readOnly is true.
   */
  onUpdate: (proto: T) => void;
  /** When readOnly is true, the parameters cannot be changed. */
  readOnly: boolean;
  /**
   * An optional set of field names that are excluded from the read-only rule.
   * The main motivation for this is to allow some fields to be modified even with
   * a selected preset. If the project context dictates "read-only" this must be
   * left undefined by the client component.
   */
  alwaysWritable?: Set<string>;
  /** Special options to disable some choices. */
  options?: ParamInputOptions;
  // Scope of the params to show.
  paramScope: ParamScope;
  // Group of params to show in the form.
  group: ProtoDescriptor.ParamGroup;
  // List of params to remove from the group.
  removeParams?: string[];
  // List of params that correspond to labels we don't want to display
  skipLabelParams?: string[];
  // List of params that should be explicitly enabled, disabling all other params
  enableParams?: string[];
  /**
   * Insert a React element into the list. The element will either be inserted below
   * the param listed in the `insert` property or replace the `insert` property
   * if `replace` is true
   */
  insertElement?: InsertElement[];
  /**
   * List of param names that specify the order they should appear in the
   * ParaForm. If there are params not named here, they will be ordered by
   * their default order, after everything in this list
   */
  reorderElements?: string[];
  earlyAccessNames?: string[];
}

export function ParamForm<T extends Message>(props: ParamFormProps<T>) {
  const {
    earlyAccessNames = [],
    paramScope,
    group,
    removeParams,
    reorderElements,
    skipLabelParams = [],
    enableParams,
  } = props;

  const params = paramsToShow(paramScope, group, removeParams);
  if (reorderElements) {
    reorderParams(params, reorderElements);
  }
  const nestLevels = paramScope.nestLevelsInGroup(group);

  // returns as an array of elements to be inserted after the param with the given name
  const findInsertElements = (name: string) => {
    const insertElements = props.insertElement?.filter((element) => element.insert.name === name);
    return insertElements || null;
  };

  // Fallback that is rendered when an error occurs in any of the params. Allows the user to reset
  // the values to default.
  const fallbackRender = ({ error, resetErrorBoundary }: FallbackProps) => (
    <SectionMessage level="error" title="An error occured while loading the panel.">
      <div>{error.message}</div>
      <ActionButton
        compact
        disabled={props.readOnly}
        kind="minimal"
        onClick={() => {
          const newProto = initParamGroupProto(props.proto, props.group);
          props.onUpdate(newProto as T);
          // Wait a bit before resetting the error boundary to make sure the param changes
          // been propagated.
          setTimeout(() => resetErrorBoundary(), 500);
        }}>
        Reset panel
      </ActionButton>
    </SectionMessage>
  );
  return (
    <ErrorBoundary fallbackRender={fallbackRender}>
      {params.map((param) => {
        const insertElements = findInsertElements(param.name);
        const paramRow = (
          <ParamRow
            earlyAccess={earlyAccessNames.includes(param.name)}
            enabledChoices={paramScope.enabledChoices(param)}
            label={skipLabelParams.includes(param.pascalCaseName) ? '' : param.text}
            nestLevel={props.ignoreNestLevel ? 0 : nestLevels.get(param)!}
            options={props.options}
            param={param}
            readOnly={
              (
                props.readOnly ||
                (!!enableParams && !enableParams.includes(param.name))
              ) &&
              !props.alwaysWritable?.has(param.name)
            }
            setValue={(newValue: any): void => {
              if (props.readOnly && !props.alwaysWritable?.has(param.name)) {
                return;
              }
              const newProto = props.proto as T;
              setParamValue(newProto, param, newValue);
              props.onUpdate(newProto); // propagate the change globally.
            }}
            value={extractProtoField(props.proto, param.camelCaseName)}
          />
        );

        return (
          <React.Fragment key={param.name}>
            {!insertElements?.some((element) => element.replace) && paramRow}
            {insertElements ? insertElements.map((element) => element.element) : null}
          </React.Fragment>
        );
      })}
    </ErrorBoundary>
  );
}
