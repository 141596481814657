// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 1348;
const baseHeight = 274;
export const LcLogoIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="lcLogo"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M233.53,40.13
           C179.34,-14.04 92.91,-12.7 40.07,40.13
           C10.75,69.43 0,105.84 0,136.83
           C0,198.13 38.85,243.94 83.55,262.83
           C117.48,277.18 156.06,277.2 190.05,262.83
           C243.19,240.36 273.6,189.13 273.6,136.83
           C273.6,98.35 257.75,64.34 233.53,40.13Z
           M53.03,56.97
           C74.77,34.21 104.94,21.22 136.42,21.12
           C167.48,21.02 196.7,33.07 218.65,55.01
           C247.27,83.62 251.95,117.17 252.54,132.28
           C252.6,133.85 250.43,134.32 249.84,132.86
           C246,123.43 237.82,107.81 221.02,93.31
           C198.41,73.78 168.44,63.02 136.64,63.02
           C104.84,63.02 74.87,73.78 52.26,93.31
           C35.68,107.63 27.63,122.7 23.78,131.96
           C23.18,133.41 21.01,132.93 21.08,131.36
           C21.78,116.74 26.5,84.73 53.02,56.97
           L53.03,56.97Z
           M232.97,187.94
           C232.57,189.35 230.55,189.27 230.25,187.84
           C228.98,181.78 226.74,175.89 223.54,170.22
           C218.58,161.43 211.55,153.58 202.65,146.91
           C184.94,133.63 161.56,126.32 136.81,126.32
           C112.06,126.32 88.68,133.63 70.97,146.91
           C62.07,153.59 55.04,161.43 50.08,170.22
           C46.78,176.07 44.49,182.17 43.24,188.45
           C42.95,189.89 40.93,189.98 40.52,188.58
           C38.61,182.06 37.59,175.25 37.59,168.23
           C37.59,121.83 82.03,84.07 136.66,84.07
           C191.29,84.07 235.73,121.82 235.73,168.23
           C235.73,175.02 234.78,181.62 232.98,187.95
           L232.97,187.94Z
           M123.91,250.64
           C113.01,247.28 105.23,239.87 105.23,231.51
           C105.23,220.11 119.69,210.47 136.8,210.47
           C153.91,210.47 168.37,220.1 168.37,231.51
           C168.37,239.84 160.65,247.23 149.83,250.6
           C144.85,252.15 133.42,253.57 123.91,250.64Z
           M172.88,200.78
           C163.12,193.46 150.31,189.43 136.8,189.43
           C123.29,189.43 110.48,193.46 100.72,200.78
           C84.86,212.67 83.86,227.35 84.26,233.8
           C84.34,235.02 82.94,235.75 81.99,234.99
           C76.25,230.44 63.14,218.09 63.14,199.96
           C63.14,186.49 70.4,173.63 83.59,163.74
           C97.68,153.18 116.58,147.36 136.8,147.36
           C157.02,147.36 175.92,153.18 190.01,163.74
           C203.2,173.63 210.46,186.49 210.46,199.96
           C210.46,218.11 197.35,230.43 191.61,234.99
           C190.66,235.74 189.27,235.02 189.34,233.81
           C189.74,227.38 188.74,212.69 172.88,200.79
           L172.88,200.78Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M526.4,159.61
           C526.4,189.73 512.07,207.71 488.06,207.71
           C466.06,207.71 453.94,192.88 453.94,165.94
           L453.94,91.2
           L430.42,91.2
           L430.42,167.25
           C430.42,221.31 464.22,229.38 484.37,229.38
           C500.81,229.38 514.22,224.16 524.29,213.84
           C525.27,212.84 526.97,213.34 527.21,214.73
           C528.07,219.75 529.45,228 529.45,228
           L549.93,228
           L549.93,91.2
           L526.41,91.2
           L526.41,159.61
           L526.4,159.61Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M724.03,89.83
           C704.45,89.83 689.01,97.36 679.17,111.65
           C678.37,112.82 676.66,112.74 675.94,111.52
           C667.84,97.69 653.07,89.83 634.9,89.83
           C619.96,89.83 608.05,94.99 598.6,105.58
           C597.63,106.67 595.84,106.16 595.62,104.73
           L593.52,91.2
           L573.04,91.2
           L573.04,228
           L596.56,228
           L596.56,155.92
           C596.56,128.94 610.16,111.51 631.21,111.51
           C652.26,111.51 662.69,125.41 662.69,150.64
           L662.69,228
           L686.21,228
           L686.21,154.86
           C686.21,128.12 699.69,111.51 721.39,111.51
           C741.35,111.51 752.35,125.41 752.35,150.64
           L752.35,228
           L775.87,228
           L775.87,149.32
           C775.87,129.11 770.74,113.68 760.63,103.46
           C751.81,94.54 739.15,89.83 724.03,89.83Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M912.49,89.83
           C891.59,89.83 878.71,97.9 871.16,105.67
           C870.17,106.69 868.46,106.15 868.24,104.76
           L866.1,91.2
           L845.62,91.2
           L845.62,228
           L869.14,228
           L869.14,159.61
           C869.14,129.49 883.97,111.51 908.8,111.51
           C931.99,111.51 944.24,125.95 944.24,153.28
           L944.24,228
           L967.76,228
           L967.76,151.96
           C967.76,97.9 933.13,89.83 912.49,89.83Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M1159.3,105.88
           L1157.23,91.2
           L1136.73,91.2
           L1136.73,228
           L1160.25,228
           L1160.25,158.29
           C1160.25,121.14 1182.12,114.2 1195.16,114.2
           L1206.92,114.2
           L1206.92,91.2
           L1199.9,91.2
           C1178.66,91.2 1168.26,99.33 1162.31,106.71
           C1161.37,107.88 1159.49,107.37 1159.28,105.88
           L1159.3,105.88Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M1323,91.2
           L1285.14,192.78
           C1284.59,194.26 1282.51,194.27 1281.94,192.8
           L1243.06,91.81
           L1242.83,91.2
           L1217.85,91.2
           L1270.01,225.88
           C1270.54,227.21 1270.42,228.28 1270.08,229.04
           L1266.81,236.7
           C1261.77,249.43 1259.77,251.92 1248.18,251.92
           L1227.98,251.92
           L1227.98,273.6
           L1254.77,273.6
           C1274.19,273.6 1279.59,264.73 1288.09,242.94
           L1347.49,92.5
           L1348,91.2
           L1323,91.2Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M388.54,196.15
           L388.54,45.6
           L364.8,45.6
           L364.8,196.36
           C364.8,216.47 375.53,228 394.23,228
           L410.4,228
           L410.4,206.54
           L398.1,206.54
           C390.2,206.54 388.54,200.89 388.54,196.15Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M1102.75,196.16
           L1102.75,139.49
           C1102.75,108.05 1082.7,90.02 1047.74,90.02
           C1014.7,90.02 993.05,106.01 989.84,132.79
           L989.71,133.85
           L1013.19,133.85
           L1013.34,133.08
           C1015.94,119.57 1028.72,111.17 1046.69,111.17
           C1067.07,111.17 1079.23,121.26 1079.23,138.17
           L1079.23,145.03
           C1079.23,145.82 1078.59,146.45 1077.8,146.45
           L1040.62,146.45
           C1005.15,146.45 985.61,161.68 985.61,189.33
           C985.61,213.78 1005.44,229.57 1036.14,229.57
           C1036.58,229.57 1037.01,229.57 1037.45,229.55
           L1037.73,229.55
           C1054.83,229.55 1069.16,224.34 1080.35,214.07
           C1081.27,213.23 1082.72,213.51 1083.31,214.61
           C1088.03,223.38 1096.66,227.99 1108.45,227.99
           L1118.15,227.99
           L1118.15,206.54
           L1112.32,206.54
           C1104.42,206.54 1102.77,200.89 1102.77,196.16
           L1102.75,196.16Z
           M1077.8,166.55
           C1078.59,166.55 1079.23,167.19 1079.23,167.97
           L1079.23,172.19
           C1079.23,195.03 1063.32,209.21 1037.72,209.21
           C1020.52,209.21 1009.4,200.89 1009.4,188.01
           C1009.4,173.77 1019.2,166.54 1038.51,166.54
           L1077.8,166.54
           L1077.8,166.55Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <g>
        <path
          d="M798.95,228
             L822.44,228
             L822.44,227.99
             L822.5,227.99
             L822.5,91.2
             L798.98,91.2
             L798.95,228Z"
          style={{ fill: getColor(0), fillRule: 'nonzero' }}
        />
        <path
          d="M810.96,45.6
             C802.46,45.6 796.06,52.01 796.06,60.5
             C796.06,68.99 802.47,75.4 810.96,75.4
             C819.45,75.4 825.86,68.99 825.86,60.5
             C825.86,52.01 819.45,45.6 810.96,45.6Z"
          style={{ fill: getColor(0), fillRule: 'nonzero' }}
        />
      </g>
    </svg>
  );
});
