// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { getTypeLabel } from '../../lib/materialUtils';
import { typesToAdd } from '../../model/hooks/useMaterials';
import { useProjectContext } from '../context/ProjectContext';
import { useGeometryStatus } from '../hooks/useGeometryStatus';
import { useMaterialAdd } from '../hooks/useMaterialAdd';

import { AddNodeMenuButton } from './AddNodeButton';

export const AddMaterialButton = () => {
  // == Contexts
  const { readOnly } = useProjectContext();

  // == Hooks
  const { addMaterialNode } = useMaterialAdd();
  const { working } = useGeometryStatus();

  if (readOnly) {
    return null;
  }

  const menuItems: CommonMenuItem[] = typesToAdd.map((type) => ({
    label: getTypeLabel(type),
    onClick: () => addMaterialNode(type),
  }));

  return (
    <AddNodeMenuButton disabled={working} menuItems={menuItems} />
  );
};
