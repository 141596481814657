// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import {
  atomFamily,
  constSelector,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';

import { meshMetadataFixture, meshUrlFixture } from '../lib/fixtures';
import { MeshMetadata, readMeshMetadata } from '../lib/mesh';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { isTestingEnv } from '../lib/testing/utils';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';

import { getGeoState } from './geometry/geometryState';

type RecoilKey = {
  projectId: string;
  meshUrl: string;
}

// Cache of mesh metadata reported by ReadFileIndex RPC.  We assume mesh files are immutable, so
// entries can stay in the cache as long as there is enough memory.
const meshMetadataSelectorRpc = selectorFamily<MeshMetadata | null, RecoilKey>({
  key: 'meshMetadataSelector',
  get: (key: RecoilKey) => async ({ get }) => {
    const geoState = getGeoState(get, key.projectId);
    if (geoState) {
      return {
        meshMetadata: geoState.metadata,
        solnMetadata: null,
      };
    }
    if (!key.meshUrl) {
      return null;
    }
    return readMeshMetadata(key.projectId, key.meshUrl);
  },
  dangerouslyAllowMutability: true,
});

const meshMetadataSelectorTesting = selectorFamily<MeshMetadata | null, RecoilKey>({
  key: 'meshMetadataSelector',
  get: () => meshMetadataFixture,
  dangerouslyAllowMutability: true,
});

export const meshMetadataSelector = isTestingEnv() ?
  meshMetadataSelectorTesting : meshMetadataSelectorRpc;

// Return the mesh metadata for the given mesh. It caches the metadata in recoil
// on the first read. If the state is not in recoil, issues an RPC to read the
// metadata.  If !meshUrl, it always returns null.
// This is async state; see src/recoil/README for more information.
export function useMeshMetadata(projectId: string, meshUrl: string): MeshMetadata | null {
  return useRecoilValue(meshMetadataSelector({ projectId, meshUrl }));
}

const meshKey = 'meshUrl';

const DEFAULT_URLS = new projectstatepb.MeshUrl();

const serialize = (val: projectstatepb.MeshUrl) => val.toBinary();

function deserialize(val: Uint8Array): projectstatepb.MeshUrl {
  return (val.length ?
    projectstatepb.MeshUrl.fromBinary(val) :
    DEFAULT_URLS.clone());
}

const meshUrlSelectorTesting = selectorFamily<projectstatepb.MeshUrl, string>({
  key: `${meshKey}/testing`,
  get: () => meshUrlFixture,
  dangerouslyAllowMutability: true,
});

const meshUrlSelectorRpc = selectorFamily<projectstatepb.MeshUrl, string>({
  key: `${meshKey}/rpc`,
  get: (projectId: string) => () => (
    persist.getProjectState(projectId, [meshKey], deserialize)
  ),
  dangerouslyAllowMutability: true,
});

const meshUrlSelector = isTestingEnv() ? meshUrlSelectorTesting : meshUrlSelectorRpc;

// The persisted mesh URL for a project.
export const meshUrlState = atomFamily<projectstatepb.MeshUrl, string>({
  key: 'meshUrl',
  default: meshUrlSelector,
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, meshKey, deserialize, serialize),
  ],
  dangerouslyAllowMutability: true,
});

// Return [mesh URL used in the setup page, setter for the mesh URL].
// This is async state; see src/recoil/README for more information.
export const useMeshUrlState = (projectId: string) => useRecoilState(meshUrlState(projectId));
// Return the setter for a project's meshUrl.
export const useSetMeshUrlState = (projectId: string) => (
  useSetRecoilState(meshUrlState(projectId))
);

export function getActiveUrl(meshUrl: projectstatepb.MeshUrl) {
  const activeUrl = meshUrl.activeType === projectstatepb.UrlType.GEOMETRY ?
    meshUrl.geometry : meshUrl.mesh;

  // Use preview if other URLs are not available.
  return activeUrl || meshUrl.preview;
}

/**
 * Get the base file url from the meshUrl state. It points to the either a mesh file or a cad file
 * depending on what was uploaded.
 * @param meshUrl meshUrl state
 * @returns url
 */
export function getBaseFileUrl(meshUrl: projectstatepb.MeshUrl) {
  return meshUrl.url;
}

// Returns null if the hook is used with projectId = ''. Returns true or false for actual projects.
export const useIsMeshReady = (projectId: string) => {
  const meshUrl = useRecoilValueLoadable(projectId ? meshUrlState(projectId) : constSelector(null));
  const metaUrl = meshUrl.state === 'hasValue' ? meshUrl.contents?.mesh ||
    meshUrl.contents?.geometry : undefined;
  const meshSelector = metaUrl ?
    meshMetadataSelector({ projectId, meshUrl: metaUrl }) :
    constSelector(false);
  const value = useRecoilValueLoadable<MeshMetadata | null | boolean>(
    projectId ? (meshSelector) : constSelector(null),
  );
  if (value.state === 'hasValue' && value.contents) {
    return true;
  }
  if (value.state === 'loading') {
    return false;
  }
  return value.contents;
};
