// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 249;
const baseHeight = 249;
export const LcLogoGraphOnlyIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="lcLogoGraphOnly"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M211.95,36.42
           C162.77,-12.75 84.32,-11.52 36.36,36.42
           C9.76,63.01 0,96.06 0,124.18
           C0,179.81 35.26,221.39 75.83,238.54
           C106.63,251.56 141.64,251.58 172.49,238.54
           C220.72,218.15 248.32,171.65 248.32,124.18
           C248.32,89.26 233.93,58.39 211.95,36.42Z
           M48.13,51.7
           C67.86,31.05 95.25,19.25 123.81,19.16
           C152,19.07 178.52,30 198.44,49.92
           C224.42,75.89 228.66,106.34 229.19,120.05
           C229.25,121.47 227.28,121.9 226.74,120.58
           C223.26,112.02 215.83,97.85 200.59,84.68
           C180.07,66.95 152.87,57.19 124.01,57.19
           C95.15,57.19 67.95,66.95 47.43,84.68
           C32.38,97.68 25.08,111.35 21.59,119.76
           C21.04,121.08 19.07,120.64 19.14,119.21
           C19.77,105.94 24.06,76.89 48.13,51.69
           L48.13,51.7Z
           M211.44,170.56
           C211.08,171.84 209.24,171.77 208.97,170.47
           C207.81,164.97 205.78,159.62 202.88,154.48
           C198.38,146.5 192,139.38 183.92,133.32
           C167.85,121.27 146.62,114.63 124.16,114.63
           C101.7,114.63 80.48,121.27 64.4,133.32
           C56.32,139.38 49.94,146.5 45.44,154.48
           C42.44,159.79 40.37,165.33 39.23,171.03
           C38.97,172.34 37.13,172.42 36.76,171.14
           C35.02,165.22 34.1,159.04 34.1,152.67
           C34.1,110.55 74.44,76.29 124.02,76.29
           C173.6,76.29 213.94,110.55 213.94,152.67
           C213.94,158.83 213.08,164.82 211.45,170.56
           L211.44,170.56Z
           M112.46,227.47
           C102.57,224.42 95.51,217.7 95.51,210.1
           C95.51,199.75 108.63,191.01 124.16,191.01
           C139.69,191.01 152.81,199.75 152.81,210.1
           C152.81,217.66 145.81,224.36 135.98,227.43
           C131.46,228.84 121.09,230.13 112.46,227.47Z
           M156.91,182.21
           C148.05,175.57 136.42,171.91 124.17,171.91
           C111.92,171.91 100.28,175.57 91.43,182.21
           C77.04,193 76.13,206.32 76.49,212.17
           C76.56,213.27 75.29,213.94 74.43,213.25
           C69.22,209.12 57.32,197.91 57.32,181.45
           C57.32,169.23 63.91,157.55 75.88,148.58
           C88.67,138.99 105.82,133.71 124.18,133.71
           C142.54,133.71 159.69,138.99 172.48,148.58
           C184.45,157.55 191.04,169.23 191.04,181.45
           C191.04,197.92 179.14,209.11 173.93,213.24
           C173.07,213.92 171.8,213.26 171.87,212.17
           C172.24,206.34 171.33,193 156.93,182.2
           L156.91,182.21Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
