// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { useAtom, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

/* Read/set clicked node links without paraview dependency */
export const NODE_LINK_KEY = 'nodeLinkState';

const nodeLinkState = atomWithStorage<string[]>(NODE_LINK_KEY, []);

export function useNodeLinkState() {
  return useAtom(nodeLinkState);
}

export function useSetNodeLinkState() {
  return useSetAtom(nodeLinkState);
}
