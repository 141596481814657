// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { NodeType } from '../../../../lib/simulationTree/node';
import { useSimulationTreeSubselect } from '../../../../recoil/simulationTreeSubselect';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useMaterialVolumes } from '../../../hooks/subselect/useMaterialVolumes';
import { NodeIdsSelectButton } from '../../NodeIdsSelectButton';
import { NodeSubselect } from '../../NodeSubselect';
import PropertiesSection from '../../PropertiesSection';

export interface MaterialVolumesProps {
  materialId: string;
}

export const MaterialVolumes = (props: MaterialVolumesProps) => {
  const { materialId } = props;

  const {
    assignedVolumeNodeIds,
    nodeFilter,
    referenceNodeIds,
    setAssignedVolumeNodeIds,
    mapVisualizerEntities,
  } = useMaterialVolumes(materialId);

  const treeSubselect = useSimulationTreeSubselect();
  const subselectId = 'material-volumes';

  return (
    <PropertiesSection>
      <CollapsibleNodePanel
        disabled={treeSubselect.id === subselectId}
        heading="Assigned Geometry"
        nodeId={materialId}
        panelName="volumes">
        <NodeSubselect
          id={subselectId}
          independentSelection
          labels={['volumes']}
          mapVisualizerEntities={mapVisualizerEntities}
          nodeFilter={nodeFilter}
          nodeIds={assignedVolumeNodeIds}
          onChange={setAssignedVolumeNodeIds}
          referenceNodeIds={referenceNodeIds}
          showNotFoundNodes
          title="Volumes"
          visibleTreeNodeTypes={[NodeType.VOLUME, NodeType.TAGS_BODY, NodeType.TAGS_CONTAINER]}
        />
        <NodeIdsSelectButton
          disabled={treeSubselect.active}
          help={treeSubselect.active ? '' : 'Click to select all assigned volumes'}
          nodeIds={assignedVolumeNodeIds}
        />
      </CollapsibleNodePanel>
    </PropertiesSection>
  );
};
