// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { ConfirmationConfig, ConfirmationItem, useConfirmations } from '../../state/internal/dialog/confirmations';
import { ConfirmationDialog } from '../dialog/Confirmation';

// If stack item is a function, return its output; otherwise return the item
function resolveConfirmation(item: ConfirmationItem): ConfirmationConfig {
  if (typeof item === 'function') {
    return item();
  }
  return item;
}

export const ConfirmationManager = () => {
  // Component state
  const [working, setWorking] = useState(false);

  // Recoil state
  const [stack, setStack] = useConfirmations();

  // Derived state
  const next = stack.length ? resolveConfirmation(stack[0]) : undefined;

  // Handlers
  const handleClose = (item: ConfirmationConfig) => {
    item.onClose?.();
    setStack((prevValue) => prevValue.slice(1));
  };

  const handleContinue = async (item: ConfirmationConfig) => {
    setWorking(true);
    await item.onContinue();
    setStack((prevValue) => prevValue.slice(1));
    setWorking(false);
  };

  return (
    <>
      {next && (
        <ConfirmationDialog
          {...next}
          controlState={working ? 'working' : next.controlState}
          onClose={() => handleClose(next)}
          onContinue={() => handleContinue(next)}
          open
        />
      )}
    </>
  );
};
