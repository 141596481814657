// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 13;
const baseHeight = 13;
export const RectanglePoppedOutIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="rectanglePoppedOut"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M8 4H12.4V12.4H3V7"
        stroke={getColor()}
        strokeWidth="1.2"
        style={{ fill: 'none' }}
      />
      <path
        d="M0.6 0.6h7v6H0.6Z"
        stroke={getColor()}
        strokeWidth="1.2"
        style={{ fill: 'none' }}
      />
    </svg>
  );
});
