// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as frontendpb from '../proto/frontend/frontend_pb';
import { useProjectCopiesInProgress } from '../recoil/useProjectCopiesInProgress';

import { newProjectAclLCEntry } from './projectShareUtils';
import { string } from './random';
import * as rpc from './rpc';

export const rpcRemoveProjectAccess = async (
  projectId: string,
  userId: string,
  roles: string[],
) => {
  const req = new frontendpb.UpdateProjectPermissionRequest({
    projectId,
    aclUpdate: [newProjectAclLCEntry(userId, roles)],
  });

  await rpc.callRetry('RemoveAccessProject', rpc.client.updateProjectPermission, req);
};

export const rpcCreateProject = async (name: string, description: string) => {
  const nonce = new Uint8Array(32);
  window.crypto.getRandomValues(nonce);
  const req = new frontendpb.NewProjectRequest({ name, description, nonce });

  return rpc.callRetry('NewProject', rpc.client.newProject, req);
};

export const rpcDeleteProject = async (projectId: string) => {
  const req = new frontendpb.DeleteProjectRequest({ projectId });

  await rpc.callRetry('DeleteProject', rpc.client.deleteProject, req);
};

export const rpcUpdateProject = async (projectId: string, name: string, description: string) => {
  const req = new frontendpb.EditProjectRequest({ projectId, name, description });
  await rpc.callRetry('EditProject', rpc.client.editProject, req);
};

const rpcCopyProject = (projectId: string) => {
  const req = new frontendpb.CopyProjectRequest({
    params: new frontendpb.CopyProjectParams({ sourceId: projectId }),
  });
  return rpc.callRetry('CopyProject', rpc.client.copyProject, req);
};

export const useCopyProject = () => {
  const [, setCopiesInProgress] = useProjectCopiesInProgress();

  return async (projectId: string, projectName: string) => {
    const tempId = string(8);
    // Mark the project for copy, which would show a transient "copy in progress" row to the table
    setCopiesInProgress((list) => [...list, {
      name: projectName,
      id: tempId,
    }]);

    try {
      const copiedProject = (await rpcCopyProject(projectId)).createdProject;
      // When the copy is completed, update the copiesInProgress with the real id so that
      // the transient row can be removed exactly when the new project list is updated.
      if (copiedProject) {
        setCopiesInProgress((list) => list.map((item) => {
          if (item.id === tempId) {
            item.id = copiedProject.id;
          }
          return item;
        }));
      }
    } catch (err) {
      console.error(`Could not copy ${projectId}`, err);
      setCopiesInProgress((list) => list.filter((item) => item.id !== tempId));
    }
  };
};
