// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useNavigate } from 'react-router-dom';

import { ERRORS, parseError } from '../../../lib/errors';
import { geometryLink } from '../../../lib/navigation';
import { rpcCreateProject, rpcUpdateProject } from '../../../lib/projectUtils';
import { addRpcError } from '../../../lib/transientNotification';

// Handles creating a new project or editing an existing one.
// Keep in mind that if a project is created from a sample project, this fn will not be called
// with isEdit false. The sample project will first be copied (in the Project.tsx dialog)
// and then this function will be called with isEdit mode to overwrite the default
// name/desc from the copy operation.
export const useSaveProject = (onSave?: () => void) => {
  const navigate = useNavigate();

  return async (
    projectId: string,
    name: string,
    description: string,
    isEdit: boolean,
  ) => {
    if (isEdit) {
      try {
        await rpcUpdateProject(projectId, name, description);
        onSave?.();
      } catch (err) {
        addRpcError(parseError(ERRORS.EditProject, [name]), err);
      }
    } else {
      try {
        const reply = await rpcCreateProject(name, description);
        onSave?.();
        navigate(geometryLink(reply.createdProject?.id || ''));
      } catch (err) {
        addRpcError(ERRORS.NewProject, err);
      }
    }
  };
};
