// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/*
  The CenterOfRotationCameraOverlay component is a mostly transparent SVG
  canvas that sits on top of a Paraview canvas. It reacts to a
  mouse click event and passes it to the setCenterOfRotationCamera rpc to set
  the center of rotation for the camera to a specific point selected by the
  user.
*/

import React, { useCallback } from 'react';

import { OverlayMode, useSetOverlayMode } from '../../../state/internal/vis/overlayMode';
import { useHandleManualCameraViewChange } from '../../hooks/useHandleManualCameraViewChange';
import { useParaviewContext } from '../ParaviewManager';

import ClickableOverlay from './ClickableOverlay';

export interface CenterOfRotationCameraOverlayProps {
  className: string;
}

const CenterOfRotationCameraOverlay = (props: CenterOfRotationCameraOverlayProps) => {
  const { className } = props;
  const onSelect = useHandleManualCameraViewChange();
  const { paraviewRenderer } = useParaviewContext();
  const setOverlayMode = useSetOverlayMode();

  // Called on mouse click. (x,y) are screen coordinates.
  const onSelectPoint = useCallback((x: number, y: number, _: number, height: number) => {
    // Paraview Y origin is the bottom of the screen.
    paraviewRenderer.setCenterOfRotationCamera(x, height - y);
    setOverlayMode(OverlayMode.NONE);
    onSelect();
  }, [paraviewRenderer, setOverlayMode, onSelect]);

  return (
    <ClickableOverlay
      className={className}
      onClick={onSelectPoint}
      text={
        'Select a point on screen to act as the center of rotation for the camera.' +
        ' Press ESC to exit.'
      }
    />
  );
};

export default CenterOfRotationCameraOverlay;
