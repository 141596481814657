// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 15;
export const CaretDownIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="caretDown"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M24,1.5
           C24,0.66 23.34,0 22.5,0
           L1.5,0
           C0.66,0 0,0.66 0,1.5
           C0,1.89 0.15,2.22 0.39,2.49
           L10.89,14.49
           C11.16,14.79 11.55,15 12,15
           C12.45,15 12.84,14.79 13.11,14.49
           L23.61,2.49
           C23.85,2.22 24,1.89 24,1.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
