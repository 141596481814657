// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useMemo } from 'react';

import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { colors } from '../../../lib/designSystem';
import { isInProject, routes } from '../../../lib/navigation';
import { useLeftNavExpandedValue, useSetLeftNavHovered } from '../../../state/internal/component/leftNav';
import { TRANSITION_DELAY, TRANSITION_TIME } from '../../LeftNavToggle';
import { createStyles, makeStyles } from '../../Theme';
import Tooltip from '../../Tooltip';
import { LcLogoGraphOnlyIcon } from '../../svg/LcLogoGraphOnlyIcon';
import { LcLogoIcon } from '../../svg/LcLogoIcon';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      width: 'var(--nav-width)',
      alignSelf: 'stretch',
      display: 'flex',
      alignItems: 'center',
      transition: `width ${TRANSITION_TIME}ms`,
      transitionDelay: `${TRANSITION_DELAY}ms`,
      marginRight: '-20px',

      '&.shifted': {
        marginLeft: '-20px',
        paddingLeft: '20px',
        marginRight: '0',
        paddingRight: '20px',
      },
    },
    logoContainer: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      // We have to make the logoContainer smaller when the nav is collapsed. Otherwise clicking
      // the expand button would immediatelly show the full logo, even before the nav is fully
      // expaneded. If we animate the width (and prevent overflow), we make the logo play nice.
      width: 'var(--nav-width)',
      overflow: 'hidden',
      transition: `width ${TRANSITION_TIME}ms`,
      transitionDelay: `${TRANSITION_DELAY}ms`,
    },
    homeLink: {
      display: 'flex',
      alignItems: 'center',
      color: colors.mediumEmphasisText,

      '&:hover, &:active': {
        color: 'white',
      },
    },
  }),
  { name: 'ProjectLogoSection' },
);

export const ProjectLogoSection = () => {
  const classes = useStyles();
  const leftNavExpanded = useLeftNavExpandedValue();
  const location = useLocation();
  const inProject = useMemo(() => isInProject(location.pathname), [location]);
  const setNavbarHovered = useSetLeftNavHovered();

  return (
    <div
      className={cx(classes.root, !inProject && 'shifted')}
      onMouseEnter={() => setNavbarHovered(true)}
      onMouseLeave={() => setNavbarHovered(false)}>
      <div className={cx(classes.logoContainer, { largeLogo: leftNavExpanded && !inProject })}>
        <Tooltip
          arrow={false}
          enterDelay={2 * 1000} // 2 seconds
          enterNextDelay={2 * 1000}
          PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [20, 0] } }] }}
          title="Projects">
          <Link
            className={classes.homeLink}
            data-link="Home"
            data-locator="home-link"
            to={routes.landingPage}>
            {!inProject ? (
              <LcLogoIcon maxHeight={24} />
            ) : (
              <LcLogoGraphOnlyIcon maxHeight={24} />
            )}
          </Link>
        </Tooltip>
      </div>
    </div>
  );
};
