// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { AxisBottom, AxisLeft, AxisTop } from '@visx/axis';
import { NumberValue, ScaleLinear/* , ScaleLogarithmic */ } from 'd3-scale';

import { AxisLabels } from '../../lib/componentTypes/plot';
import { colors } from '../../lib/designSystem';

import { LEFT_MARGIN } from './constants';

const DEFAULT_LABEL_PROPS = {
  fill: colors.neutral800,
  fontSize: 11,
  fontWeight: 600,
};

const HIDDEN_PROPS = {
  display: 'none',
};

interface ChartAxesProps {
  // The chart width in pixels.
  width: number;
  // The chart height in pixels.
  height: number;
  // Scale of the y axis (linear or logarithmic).
  xScale: ScaleLinear<number, number, never>;
  // Scale of the y axis (linear or logarithmic).
  yScale: ScaleLinear<number, number, never>;
  // The state of the averaging window.
  // Whether this chart is an XY chart or not
  xyChart: boolean;
  // The labels for the X and Y axis
  axisLabels?: AxisLabels;

  bottomMargin: number;
  tickFormatX: (i: NumberValue) => string;
  tickFormatY: (i: NumberValue) => string;
}

/** The X and Y axes of a the chart. */
export const ChartAxes = (props: ChartAxesProps) => {
  const {
    width,
    height,
    xyChart,
    axisLabels,
    xScale,
    yScale,
    bottomMargin,
    tickFormatX,
    tickFormatY,
  } = props;

  const labelPropsX = {
    ...DEFAULT_LABEL_PROPS,
    x: width / 2,
  };

  const labelPropsY = {
    ...DEFAULT_LABEL_PROPS,
    x: -height / 2 - 15,
    y: -55,
  };

  return (
    <>
      {xyChart ? (
        <>
          <AxisBottom
            hideAxisLine
            label={axisLabels?.xAxisLabel}
            labelOffset={5}
            labelProps={labelPropsX}
            numTicks={10}
            orientation="bottom"
            scale={xScale}
            stroke={colors.neutral350}
            strokeWidth="1px"
            tickFormat={tickFormatX}
            tickLabelProps={(value, index, values) => DEFAULT_LABEL_PROPS}
            top={height - bottomMargin - 2}
          />
          <AxisBottom
            labelOffset={5}
            labelProps={DEFAULT_LABEL_PROPS}
            numTicks={10}
            orientation="top"
            scale={xScale}
            stroke={colors.neutral350}
            strokeWidth="1px"
            tickLabelProps={(value, index, values) => HIDDEN_PROPS}
            tickStroke={colors.neutral350}
            top={height - bottomMargin + 5}
          />
        </>
      ) : (
        <>
          <AxisTop
            hideAxisLine
            hideTicks
            labelOffset={0}
            labelProps={DEFAULT_LABEL_PROPS}
            numTicks={2}
            orientation="top"
            scale={xScale}
            stroke={colors.surfaceBackground}
            strokeWidth="1px"
            tickFormat={tickFormatX}
            tickLabelProps={(value, index, values) => DEFAULT_LABEL_PROPS}
            top={28}
          />
          <AxisTop
            hideAxisLine
            labelOffset={0}
            labelProps={DEFAULT_LABEL_PROPS}
            numTicks={40}
            orientation="top"
            scale={xScale}
            stroke={colors.neutral300}
            strokeWidth="1px"
            tickLabelProps={(value, index, values) => HIDDEN_PROPS}
            tickStroke={colors.neutral350}
            top={5}
          />
        </>
      )}
      <AxisLeft
        label={axisLabels?.yAxisLabel}
        labelOffset={LEFT_MARGIN - 20}
        labelProps={labelPropsY}
        left={LEFT_MARGIN}
        numTicks={xyChart ? 5 : 2}
        orientation="left"
        scale={yScale}
        stroke={xyChart ? colors.neutral350 : colors.surfaceBackground}
        strokeWidth="1px"
        tickFormat={tickFormatY}
        tickLabelProps={(value, index, values) => ({
          ...DEFAULT_LABEL_PROPS,
          textAnchor: 'end',
        })}
        tickTransform="translate(-5)"
      />
      {xyChart ? (
        <AxisLeft
          labelOffset={5}
          labelProps={DEFAULT_LABEL_PROPS}
          left={LEFT_MARGIN}
          numTicks={5}
          orientation="left"
          scale={yScale}
          stroke={colors.neutral350}
          strokeWidth="1px"
          tickLabelProps={(value, index, values) => HIDDEN_PROPS}
          tickStroke={colors.neutral350}
        />
      ) : <></>}
    </>
  );
};
