// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../../lib/componentTypes/svgIcon';
import { deleteTreeNodeMenuItem } from '../../../../lib/treeUtils';
import { useNodeDeletion } from '../../../hooks/useNodeDeletion';
import { ContextMenuSection, TreeRow } from '../../TreeRow';

const PRIMARY_ICON: IconSpec = ({ name: 'wavesQuadruple' });

export const ExplorationVariableTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Hooks
  const { canDelete, deleteExplorationVariableNode, postDeleteNodeIds } = useNodeDeletion();

  const deleteRow = useCallback(async () => {
    if (await deleteExplorationVariableNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteExplorationVariableNode, node.id, postDeleteNodeIds]);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];

    const disabled = !canDelete(node.type, node.id);
    const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
    sections.push({ section: 'crud', menuItems: [deleteItem] });

    return sections;
  }, [canDelete, deleteRow, node.id, node.type]);

  return (
    <TreeRow
      {...props}
      getExtraContextMenuItems={getExtraContextMenuItems}
      primaryIcon={PRIMARY_ICON}
    />
  );
};
