// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/lcc/lcc.proto (package luminary.proto.lcc, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FileDescriptorSet, proto3 } from "@bufbuild/protobuf";

/**
 * Object is a blob of data that can be generated or processed by an lcc
 * operator.
 *
 * @generated from message luminary.proto.lcc.Object
 */
export const Object$ = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcc.Object",
  () => [
    { no: 1, name: "empty", kind: "message", T: Object_Empty, oneof: "locator" },
    { no: 2, name: "ref", kind: "message", T: Object_Reference, oneof: "locator" },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "locator" },
  ],
  {localName: "Object$"},
);

/**
 * Empty represents an object that does not exist. An object may not exist
 * because the object is an output that was not generated during the
 * processing of an operation.
 *
 * @generated from message luminary.proto.lcc.Object.Empty
 */
export const Object_Empty = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcc.Object.Empty",
  () => [
    { no: 1, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "Object_Empty"},
);

/**
 * Reference identifies an Object based on the operation that generated the
 * the Object.
 *
 * @generated from message luminary.proto.lcc.Object.Reference
 */
export const Object_Reference = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcc.Object.Reference",
  () => [
    { no: 1, name: "operation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "Object_Reference"},
);

/**
 * Operator defines a function-like processing step that can be executed in lcc.
 *
 * @generated from message luminary.proto.lcc.Operator
 */
export const Operator = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcc.Operator",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "signature_descriptor_set", kind: "message", T: FileDescriptorSet },
    { no: 3, name: "signature_full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

