// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useRef, useState } from 'react';

import { DragControl } from '../../../lib/DragControl';
import { DragOffset } from '../../../lib/dragUtil';
import { clamp } from '../../../lib/number';

import { RangeSliderSide } from './common';

/** Given a min/max set (e.g. 50/100), a slider thumb has 4 different values:
 *  1. The real value (e.g. 61)
 *  2. The parameterized value (e.g. 0.22)
 *  3. The percentage value (e.g. 22%)
 *  4. A pixel value for a given box (e.g. 419px)
 */
export class ThumbValue {
  public value: number;
  private min: number;
  private max: number;

  static fromParameterized(parameter: number, min: number, max: number) {
    const value = min + parameter * (max - min);
    return new this(value, min, max);
  }

  static fromPercentage(percentage: number, min: number, max: number) {
    return ThumbValue.fromParameterized(percentage / 100, min, max);
  }

  constructor(value: number, min: number, max: number) {
    this.value = value;
    this.max = max;
    this.min = min;
  }

  get range() {
    return this.max - this.min;
  }

  // Useful for interpolation
  get parameterized() {
    return (this.value - this.min) / this.range;
  }

  // Useful for CSS positioning
  get percentage() {
    return 100 * this.parameterized;
  }

  get percentageClamped() {
    return clamp(this.percentage, [0, 100]);
  }

  pixelsFrom(box: DOMRect) {
    return box.left + box.width * this.parameterized;
  }
}

export interface ThumbDragState {
  // The DragControl object
  dragControl: DragControl;
  // The width of the box in which the drag operation is occuring
  boxWidth: number;
  // The initial value of the thumb being dragged
  initialValue: ThumbValue;
  // Designates which thumb is being dragged
  side?: RangeSliderSide;
}

type DragStatus = null | 'started' | 'completed';

export const useSliderState = () => {
  const rootNodeRef = useRef<HTMLDivElement>(null);

  const [readoutActive, setReadoutActive] = useState(false);
  const [readoutTimer, setReadoutTimer] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [dragOffset, setDragOffset] = useState<DragOffset | null>(null);
  const [thumbDragState, setThumbDragState] = useState<ThumbDragState | null>(null);
  const [dragStatus, setDragStatus] = useState<DragStatus>(null);
  const [stopValues, setStopValues] = useState<ThumbValue[] | null>(null);

  // Ordinarily, a slider thumb's onClick handler should call
  // event.stopPropagation() to prevent bubbling onClick events to the parent
  // nodes (the root node, especially) when a drag motion ends.  This strategy,
  // however, relies on the mouse remaining over the thumb when the drag
  // operation ends.  If it's not over the thumb when the drag ends, then the
  // thumb's onClick handler never fires and clicks bubble to the root node.
  // This can happen when the mouse moves past the dragging constraints
  // (e.g. above/below the thumb or past the right/left edges) or when using
  // discrete stops (see stopCount) that cause the thumb to snap to positions
  // near the thumb.  To get around this, we maintain some state here, other
  // than the synchronously updated dragStatus, that indicates when clicking is
  // allowed and that we can update asynchronously (i.e. after some
  // small time interval after dragging ends)
  const [canClick, setCanClick] = useState(true);

  const startDrag = () => {
    setCanClick(false);
  };

  const endDrag = () => {
    window.requestAnimationFrame(() => {
      setCanClick(true);
    });
  };

  const scheduleDeactivateReadout = (milliseconds: number) => {
    if (readoutTimer !== null) {
      clearTimeout(readoutTimer);
    }

    const timer = setTimeout(() => {
      setReadoutActive(false);
    }, milliseconds);

    setReadoutTimer(timer);
  };

  const prepareMouseInteraction = (event: React.MouseEvent, disabled: boolean) => {
    // Ignore interactions when disabled
    if (disabled) {
      return false;
    }

    // Ignore right-click interactions
    if (event.button !== 0) {
      return false;
    }

    // This is an interaction of interest, so let's stop the event from bubbling
    event.stopPropagation();

    // Readout should be active now
    if (readoutTimer) {
      clearTimeout(readoutTimer);
      setReadoutTimer(null);
    }
    setReadoutActive(true);

    return true;
  };

  return {
    rootNodeRef,
    readoutActive,
    setReadoutActive,
    readoutTimer,
    setReadoutTimer,
    dragOffset,
    setDragOffset,
    dragStatus,
    setDragStatus,
    thumbDragState,
    setThumbDragState,
    stopValues,
    setStopValues,
    canClick,
    startDrag,
    endDrag,
    scheduleDeactivateReadout,
    prepareMouseInteraction,
  };
};

// Returns a list of ThumbValue objects representing stop points between min and
// max, given a stopCount.  If stopCount is not valid (i.e. an integer greater
// than or equal to 2), null is returned.
export function calculateStopValues(min: number, max: number, stopCount: number | undefined) {
  if (stopCount === undefined || !Number.isInteger(stopCount) || stopCount < 2) {
    return null;
  }

  const values: ThumbValue[] = [];
  for (let i = 0; i < stopCount; i += 1) {
    values.push(ThumbValue.fromParameterized(i / (stopCount - 1), min, max));
  }
  return values;
}

// Given a number, returns the closes item in stopValues
export function constrainToStopValues(value: number, stopValues: ThumbValue[]): ThumbValue {
  const lowStop = stopValues[0];
  const highStop = stopValues[stopValues.length - 1];

  if (value <= lowStop.value) {
    return lowStop;
  }

  if (value >= highStop.value) {
    return highStop;
  }

  const thumbValue = stopValues.reduce((result, stop, i) => {
    // Skip the first item, since we reference the current and previous items
    if (i === 0) {
      return result;
    }
    const stopPrev = stopValues[i - 1];
    if (value >= stopPrev.value && value <= stop.value) {
      const param = (value - stopPrev.value) / (stop.value - stopPrev.value);
      return param >= 0.5 ? stop : stopPrev;
    }
    return result;
  }, stopValues[0]);

  return thumbValue;
}

// Return a subset of stop values constrained by min/max
export function subsetStopValues(
  stopValues: ThumbValue[],
  min: number | null,
  max: number | null,
  exclusive = false,
) {
  return stopValues.filter((stop) => {
    if (min !== null) {
      const test = exclusive ? (stop.value <= min) : (stop.value < min);
      if (test) {
        return false;
      }
    }
    if (max !== null) {
      const test = exclusive ? (stop.value >= max) : (stop.value > max);
      if (test) {
        return false;
      }
    }
    return true;
  });
}
