// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useEffect, useState } from 'react';

import Tooltip from '../../Tooltip';
import { ArrowDownIcon } from '../../svg/ArrowDownIcon';
import { ArrowUpIcon } from '../../svg/ArrowUpIcon';
import { TrashIcon } from '../../svg/TrashIcon';

import { ContentRenderArgs } from './common';

export interface DefaultContentProps {
  // String to display in the heading
  title: string;
  // Arbitrary content to show below the heading
  body: ReactNode;
  // Arguments for list item content rendering
  renderArgs: ContentRenderArgs;
  // Element to render next to the heading
  titleContent?: ReactNode;
}

// Represents the default rendering for a list item as shown in the Figma
// design, requiring separate 'title' and 'body' props.
export const DefaultContent = (props: DefaultContentProps) => {
  const { body, renderArgs, title, titleContent } = props;

  const { disableControls, itemLabel, onDelete, onReorder } = renderArgs;

  const [showTooltips, setShowTooltips] = useState<Record<string, boolean>>({
    moveDown: false,
    moveUp: false,
    delete: false,
  });

  useEffect(() => {
    if (disableControls) {
      setShowTooltips({
        moveDown: false,
        moveUp: false,
        delete: false,
      });
    }
  }, [disableControls]);

  // For optional re-ordering controls
  const canRaiseIndex = renderArgs.canRaiseIndex && !disableControls;
  const canLowerIndex = renderArgs.canLowerIndex && !disableControls;
  const canDelete = renderArgs.canDelete && !disableControls;

  return (
    <div className="defaultContent">
      <div className="heading">
        {titleContent || <div className="title">{title}</div>}
        <div className="controls">
          {onReorder && (
            <>
              <Tooltip
                disableHoverListener
                open={showTooltips.moveDown}
                placement="top-end"
                title={canRaiseIndex ? `Move ${itemLabel} down` : ''}>
                <span>
                  <button
                    className="control move"
                    disabled={!canRaiseIndex}
                    name={`listManagerDelete::${title}`}
                    onClick={() => onReorder(true)}
                    onMouseDown={() => setShowTooltips({ ...showTooltips, moveDown: false })}
                    onMouseEnter={() => setShowTooltips({ ...showTooltips, moveDown: true })}
                    onMouseLeave={() => setShowTooltips({ ...showTooltips, moveDown: false })}
                    type="button">
                    <ArrowDownIcon maxHeight={10} maxWidth={10} />
                  </button>
                </span>
              </Tooltip>
              <Tooltip
                disableHoverListener
                open={showTooltips.moveUp}
                placement="top-end"
                title={canLowerIndex ? `Move ${itemLabel} up` : ''}>
                <span>
                  <button
                    className="control move"
                    disabled={!canLowerIndex}
                    onClick={() => onReorder(false)}
                    onMouseDown={() => setShowTooltips({ ...showTooltips, moveUp: false })}
                    onMouseEnter={() => setShowTooltips({ ...showTooltips, moveUp: true })}
                    onMouseLeave={() => setShowTooltips({ ...showTooltips, moveUp: false })}
                    type="button">
                    <ArrowUpIcon maxHeight={10} maxWidth={10} />
                  </button>
                </span>
              </Tooltip>
            </>
          )}
          <Tooltip
            disableHoverListener
            open={showTooltips.delete}
            placement="top-end"
            title={canDelete ? `Delete ${itemLabel}` : ''}>
            <span>
              <button
                className="control delete"
                disabled={!canDelete}
                onClick={onDelete}
                onMouseDown={() => setShowTooltips({ ...showTooltips, delete: false })}
                onMouseEnter={() => setShowTooltips({ ...showTooltips, delete: true })}
                onMouseLeave={() => setShowTooltips({ ...showTooltips, delete: false })}
                type="button">
                <TrashIcon maxHeight={12} maxWidth={12} />
              </button>
            </span>
          </Tooltip>
        </div>
      </div>
      <div className="body">{body}</div>
    </div>
  );
};
