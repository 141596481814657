// Copyright 2022 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useRef } from 'react';

/** FileUpload (originally split from FileUploadButton) manages the operation of
 * an <input type="file"/> tag without any other visual artefacts. It is a
 * controlled component; its operation depends on applications setting the
 * `opening` prop to true to trigger opening the native file browser and setting
 * `opening` to false in the onOpened handler. */
export interface FileUploadProps {
  opening: boolean;
  disabled?: boolean;
  onOpened: () => void;
  onUploadFiles: (files: File[]) => void;
  // See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
  accept?: string;
  // See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#multiple
  multiple?: boolean;
}

export const FileUpload = (props: FileUploadProps) => {
  const { accept, multiple, onOpened, opening } = props;

  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (opening) {
      if (fileInput.current) {
        // Reset the file value so that the onChange handler will fire again if
        // the user attempts to upload the same file again.
        fileInput.current.value = '';
        fileInput.current.click();
      }
      onOpened();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opening]);

  const handleFileChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const files = target.files ? [...target.files] : [];

    props.onUploadFiles(files);
  };

  return (
    <input
      accept={accept}
      disabled={props.disabled}
      multiple={multiple}
      name="fileUploadInput"
      onChange={handleFileChange}
      ref={fileInput}
      style={{ display: 'none' }}
      type="file"
    />
  );
};
