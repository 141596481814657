// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { syncDependentBoundaryCondition } from '../../lib/multiphysicsInterfaceUtils';
import {
  findPhysicsById,
  findPhysicsContainingSurfaces,
  getPhysicsId,
  getSubPhysics,
} from '../../lib/physicsUtils';
import { replaceInterfaceWithContactsWarning } from '../../lib/simulationTree/deletionWarningUtils';
import { appendSlidingInterface } from '../../lib/slidingInterfaceUtils';
import { usePhysics } from '../../model/hooks/usePhysics';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useGeometryContacts } from '../../recoil/geometryContactsState';
import { initializeNewNode, useSetNewNodes } from '../../recoil/nodeSession';
import { useStaticVolumes } from '../../recoil/volumes';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

import { useSimulationConfig } from './useSimulationConfig';

export const useInterfacesFromContacts = (physicsId: string) => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection } = useSelectionContext();

  // == Recoil
  const contacts = useGeometryContacts(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const setConfirmStack = useSetConfirmations();
  const setNewNodes = useSetNewNodes();
  const geometryTags = useGeometryTags(projectId);

  // == Custom hooks
  const { saveParamAsync } = useSimulationConfig();
  const { physics } = usePhysics(projectId, workflowId, jobId, readOnly, physicsId);

  // Replaces all the interfaces by those related to the contacts computed for the current geometry.
  // All the data within slidingInterfacesList is erased prior to the insertion.
  const addAllGeometryContacts = async () => {
    const newIds = await saveParamAsync((newParam) => {
      if (!contacts.contacts.length) {
        return [];
      }

      const newPhysics = findPhysicsById(newParam, physicsId);
      const subPhysics = newPhysics ? getSubPhysics(newPhysics) : null;

      if (!subPhysics) {
        return [];
      }
      subPhysics.slidingInterfaces = [];
      return contacts.contacts.reduce((result, contact) => {
        const { sideA, sideB } = contact;
        const surfaceIds = new Set([...sideA, ...sideB]);
        const matchingPhysics =
          findPhysicsContainingSurfaces(newParam, surfaceIds, geometryTags, staticVolumes);
        // This component is for converting contacts to interfaces only for the single physics
        // indicated by `physicsId` (creating an intra-physics interface), so check that the
        // contact includes surfaces only in this physics.  Contacts between physics will be
        // converted to interfaces under the Multiphysics Coupling node and will be handled
        // separately
        if (matchingPhysics && getPhysicsId(matchingPhysics) === physicsId) {
          const slidingInterface = appendSlidingInterface(newParam, physicsId);
          slidingInterface.slidingA = sideA;
          slidingInterface.slidingB = sideB;

          const intfId = slidingInterface.slidingInterfaceId;

          // Make sure to sync any dependent boundary conditions in the individual physics
          syncDependentBoundaryCondition(newParam, intfId, geometryTags, staticVolumes);

          result.push(intfId);
        }
        return result;
      }, [] as string[]);
    });
    if (newIds.length) {
      setSelection(newIds);
      setNewNodes((nodes) => [...nodes, ...newIds.map((id) => initializeNewNode(id))]);
    }
  };

  // Given that addAllGeometryContacts is destructive, we show a modal explaining the user that
  // the interfaces will be erased when copying the contacts.
  const queueAddContacts = async () => {
    const subPhysics = physics ? getSubPhysics(physics) : null;
    const slidingInterfaces = subPhysics?.slidingInterfaces;
    // If there are no interfaces, then there is no data-loss and hence we don't have to show
    // the modal.
    if (!slidingInterfaces?.length) {
      await addAllGeometryContacts();
      return;
    }

    pushConfirmation(setConfirmStack, {
      destructive: true,
      onContinue: async () => {
        await addAllGeometryContacts();
      },
      title: 'Convert geometry contacts into interfaces',
      children: replaceInterfaceWithContactsWarning('this physics'),
    });
  };

  return {
    queueAddContacts,
  };
};
