// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 29;
const baseHeight = 30;
export const CubeDashIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="cubeDash"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">

      <path
        d="m 18.105417,11.144062 -4.571385,2.8488 L 1.5594618,6.5305223 12.57484,0.25435022 c
           0.59437,-0.3386499 1.324034,-0.3386499 1.918404,0 l 1.23716,0.70489219 C
           14.39219,2.0186224 13.534032,3.6548624 13.534032,5.4909423 c 0,2.7736 1.958259,5.0911597
           4.571385,5.6531197 z m 8.961881,0.13004 v 10.447461 c 0,0.6918 -0.371681,1.3306
           -0.974035,1.6738 l -11.592538,6.605 V 15.663722 l 7.0439,-4.38962 z
           M 0.10938149,7.9001423 12.567399,15.663742 v 14.336621 l -11.59253865,-6.605 c
           -0.60237312,-0.3432 -0.97413509401,-0.982 -0.97413509401,-1.6736 V 8.5374023 c
           0,-0.22042 0.037749874,-0.43546 0.10865623401,-0.63726 z M 19.333993,3.5632824 h 7.733305
           c 1.067908,0 1.933427,0.8630599 1.933427,1.9276999 0,1.06464 -0.865519,1.9277
           -1.933427,1.9277 h -7.733305 c -1.067708,0 -1.933267,-0.86306 -1.933267,-1.9277
           0,-1.06464 0.865559,-1.9276999 1.933267,-1.9276999 z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
