// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ActionButton } from '../../../Button/ActionButton';
import { ProbeIcon } from '../../../svg/ProbeIcon';

import '../Label.scss';

interface ProbeLabelProps {
  onExit: () => void;
}

export const ProbeLabel = (props: ProbeLabelProps) => {
  const { onExit } = props;

  return (
    <div className="labelContainer">
      <span className="iconWrapper">
        <ProbeIcon maxHeight={12} maxWidth={12} />
      </span>
      <div className="header">Probe Mode</div>
      <div className="subtitle">Click on a surface to probe values</div>
      <ActionButton onClick={onExit} size="small">Exit</ActionButton>
    </div>
  );
};
