// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const FileDrawerIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="fileDrawer"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M21,4.5
           C21,3.675 20.325,3 19.5,3
           L4.5,3
           C3.675,3 3,3.675 3,4.5
           L3,6
           L21,6
           L21,4.5Z
           M18,0
           L6,0
           C5.175,0 4.5,0.675 4.5,1.5
           L19.5,1.5
           C19.5,0.675 18.825,0 18,0Z
           M22.5,7.5
           L1.5,7.5
           C0.675,7.5 0,8.175 0,9
           L0,22.5
           C0,23.325 0.675,24 1.5,24
           L22.5,24
           C23.325,24 24,23.325 24,22.5
           L24,9
           C24,8.175 23.325,7.5 22.5,7.5Z
           M18,16.5
           C18,17.325 17.325,18 16.5,18
           L7.5,18
           C6.675,18 6,17.325 6,16.5
           L6,13.5
           L7.5,13.5
           L7.5,16.5
           L16.5,16.5
           L16.5,13.5
           L18,13.5
           L18,16.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
