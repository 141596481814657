// Copyright 2022 Luminary Cloud, Inc. All Rights Reserved.
import React, { useRef, useState } from 'react';

// For anchoring a MUI Menu (or similar) to a Button, this hook manages
// anchorEl state and anchorRef.  A menu's open state can be derived from
// anchorEl (closed if null), and a parent container's ref can be set to
// 'anchorRef' to control the menu's positioning.  See Button/MenuButton for
// a reference implementation.
const useButtonMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const anchorRef = useRef<HTMLDivElement>(null);

  const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    anchorRef.current && setAnchorEl(anchorRef.current);
  };

  const onCloseMenu = () => setAnchorEl(null);

  return {
    anchorEl,
    anchorRef,
    onClickButton,
    onCloseMenu,
  };
};

export default useButtonMenu;
