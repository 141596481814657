// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const OpenIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="open"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M16.49,20.988
           L2.998,20.988
           L2.998,7.496
           L10.134,7.496
           L13.132,4.497
           L1.499,4.497
           C0.675,4.497 0,5.172 0,5.997
           L0,22.487
           C0,23.311 0.675,23.986 1.499,23.986
           L17.989,23.986
           C18.814,23.986 19.488,23.311 19.488,22.487
           L19.488,10.869
           L16.49,13.867
           L16.49,20.988Z
           M22.487,0
           L14.991,0
           C14.167,0 13.492,0.675 13.492,1.499
           C13.492,2.324 14.167,2.998 14.991,2.998
           L18.874,2.998
           L10.944,10.929
           C10.659,11.214 10.494,11.588 10.494,11.993
           C10.494,12.818 11.168,13.492 11.993,13.492
           C12.413,13.492 12.787,13.327 13.057,13.057
           L20.988,5.127
           L20.988,9.01
           C20.988,9.834 21.662,10.509 22.487,10.509
           C23.311,10.509 23.986,9.834 23.986,9.01
           L23.986,1.514
           C23.986,0.675 23.311,0 22.487,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
