// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { getQuantityTags } from '../../../../../QuantityDescriptor';
import { BaseSettingsProps, StoppingConditionSpec } from '../../../../../lib/componentTypes/output';
import { createIncludeOption } from '../../../../../lib/output/formUtil';
import { getOutputQuantity, isIncluded } from '../../../../../lib/outputNodeUtils';
import { useOutput } from '../../../../../model/hooks/useOutput';
import * as feoutputpb from '../../../../../proto/frontend/output/output_pb';
import { OutputIncludes } from '../../../../../proto/frontend/output/output_pb';
import { QuantityTag } from '../../../../../proto/quantity/quantity_options_pb';
import Form from '../../../../Form';
import LabeledInput from '../../../../Form/LabeledInput';
import { PositiveAbsoluteIntegerInput } from '../../../../Form/PositiveAbsoluteIntegerInput';
import { createStyles, makeStyles } from '../../../../Theme';
import { useCommonMultiInputLines } from '../../../../Theme/commonStyles';
import Tooltip from '../../../../Tooltip';
import { Link } from '../../../../notification/PanelLinks';
import { SectionMessage } from '../../../../notification/SectionMessage';

const useStyles = makeStyles(({ spacing }) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    marginTop: '8px',
    '& + &': {
      marginTop: spacing(3),
    },
  },
}), { name: 'AverageProperties' });

interface AveragePropertiesProps {
  outputNode: feoutputpb.OutputNode;
  projectId: string;
  showConvergenceMonitor: boolean;
}

const AverageProperties = (props: AveragePropertiesProps) => {
  const { outputNode, projectId, showConvergenceMonitor } = props;

  const localClasses = useStyles();
  const multiInputClasses = useCommonMultiInputLines();
  const quantity = getOutputQuantity(outputNode);

  const { updateOutputNode } = useOutput(projectId, outputNode.id);

  // TODO(LC-6878): coefficient include option removed for
  // actuator disk outputs for now. Add back in later with coefficient
  // calculated based on blade tip speed for disk actuators
  const avgIncludes = [OutputIncludes.OUTPUT_INCLUDE_TIME_AVERAGE];
  if (
    outputNode.nodeProps.case === 'force' &&
    quantity &&
    !getQuantityTags(quantity).includes(QuantityTag.TAG_ACTUATOR_DISK)
  ) {
    avgIncludes.push(OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT_TIME_AVERAGE);
  }

  const avgDisabled = (
    !isIncluded(outputNode, OutputIncludes.OUTPUT_INCLUDE_BASE) &&
    !isIncluded(outputNode, OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT)
  );

  const content = (
    <LabeledInput label="">
      <div className={multiInputClasses.root}>
        <label className={multiInputClasses.line}>
          <div className={multiInputClasses.control}>
            <Form.CheckBox {
              ...createIncludeOption(avgIncludes, '', outputNode, updateOutputNode, avgDisabled)
            }
            />
          </div>
          <Tooltip title="Include the trailing average of the data.">
            <div className={multiInputClasses.label}>Trailing Average</div>
          </Tooltip>
        </label>
        {!avgDisabled && isIncluded(outputNode, OutputIncludes.OUTPUT_INCLUDE_TIME_AVERAGE) && (
          <label className={multiInputClasses.line}>
            <div className={multiInputClasses.input}>
              <PositiveAbsoluteIntegerInput
                onCommit={(wholeValue) => updateOutputNode((newOutputNode) => {
                  newOutputNode.trailAvgIters = wholeValue;
                })}
                size="small"
                value={outputNode.trailAvgIters}
              />
            </div>
            <Tooltip title="Number of iterations in the trailing average.">
              <div className={multiInputClasses.label}>Averaging Iterations</div>
            </Tooltip>
          </label>
        )}
      </div>
    </LabeledInput>
  );
  if (!showConvergenceMonitor) {
    return (
      <div className={localClasses.root}>
        {content}
      </div>
    );
  }
  const convMonProps = (
    <LabeledInput label="">
      <div className={multiInputClasses.root}>
        <label className={multiInputClasses.line}>
          <div className={multiInputClasses.control}>
            <Form.CheckBox {
              ...createIncludeOption(
                [OutputIncludes.OUTPUT_INCLUDE_MAX_DEV],
                '',
                outputNode,
                updateOutputNode,
              )}
            />
          </div>
          <Tooltip title={'Convergence criterion is based on the maximum ' +
            'percent deviation in the trailing average of the data ' +
            'over a specified number of previous iterations from the current trailing average.'}>
            <div className={multiInputClasses.label}>Convergence Monitor</div>
          </Tooltip>
        </label>
        {isIncluded(outputNode, OutputIncludes.OUTPUT_INCLUDE_MAX_DEV) && (
          <>
            <label className={multiInputClasses.line}>
              <div className={multiInputClasses.input}>
                <PositiveAbsoluteIntegerInput
                  onCommit={(wholeValue) => updateOutputNode((newOutputNode) => {
                    newOutputNode.averageIters = wholeValue;
                  })}
                  size="small"
                  value={outputNode.averageIters}
                />
              </div>
              <Tooltip title="Number of iterations in the trailing average.">
                <div className={multiInputClasses.label}>Averaging Iterations</div>
              </Tooltip>
            </label>
            <label className={multiInputClasses.line}>
              <div className={multiInputClasses.input}>
                <PositiveAbsoluteIntegerInput
                  onCommit={(wholeValue) => updateOutputNode((newOutputNode) => {
                    newOutputNode.analysisIters = wholeValue;
                  })}
                  size="small"
                  value={outputNode.analysisIters}
                />
              </div>
              <Tooltip title={'Number of iterations to consider when determining ' +
                'maximum percent deviation from the current value.'}>
                <div className={multiInputClasses.label}>Iterations to Consider</div>
              </Tooltip>
            </label>
          </>
        )}
      </div>
    </LabeledInput>
  );
  return (
    <div className={localClasses.root}>
      {content}
      {convMonProps}
    </div>
  );
};

interface StoppingConditionWarningProps {
  projectId: string;
  outputId: string;
  stoppingCondition: feoutputpb.StoppingCondition,
  index: number;
}

const StoppingConditionWarning = (props: StoppingConditionWarningProps) => {
  const { index, outputId, projectId, stoppingCondition } = props;

  const { updateOutputNode } = useOutput(projectId, outputId);

  const avgIters = stoppingCondition.nIterations;
  const analysisIters = stoppingCondition.nAnalysisIters;

  const warning = `Stopping Condition ${index + 1} used ${avgIters} Average Iterations and
            ${analysisIters} Iterations to Consider.`;

  const resetLink: Link = ({
    label: 'Reset',
    onClick: () => {
      updateOutputNode((newOutput) => {
        newOutput.averageIters = avgIters;
        newOutput.analysisIters = analysisIters;
      });
    },
  });

  return (
    <SectionMessage
      key="stop-cond-warning"
      level="warning"
      links={[resetLink]}
      message={warning}
    />
  );
};

interface AverageSettingsProps extends BaseSettingsProps {
  showConvergenceMonitor: boolean;
  warnStoppingCondition?: StoppingConditionSpec;
}

export const AverageSettings = (props: AverageSettingsProps) => {
  const { outputNode, projectId, showConvergenceMonitor, warnStoppingCondition } = props;

  const nodeId = outputNode.id;

  return (
    <>
      <AverageProperties
        outputNode={outputNode}
        projectId={projectId}
        showConvergenceMonitor={showConvergenceMonitor}
      />
      {warnStoppingCondition && (
        <StoppingConditionWarning
          index={warnStoppingCondition.index}
          outputId={nodeId}
          projectId={projectId}
          stoppingCondition={warnStoppingCondition.cond}
        />
      )}
    </>
  );
};
