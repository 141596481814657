// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import { meshGenerationParamsFixture } from '../lib/fixtures';
import { nullableOptions } from '../lib/mesh';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import * as rpc from '../lib/rpc';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import { isTestingEnv } from '../lib/testing/utils';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as meshgenerationpb from '../proto/meshgeneration/meshgeneration_pb';

const meshGenerationKey = 'userMeshingParams';

function serialize(val: nullableOptions): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}

function deserialize(val: Uint8Array): nullableOptions {
  return (val.length ?
    meshgenerationpb.UserMeshingParams.fromBinary(val) :
    null);
}

export const meshGenerationStateRpc = atomFamily<nullableOptions, string>({
  key: meshGenerationKey,
  default: (projectId: string) => (
    persist.getProjectState(projectId, [meshGenerationKey], deserialize)
  ),
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, meshGenerationKey, deserialize, serialize),
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

const meshGenerationStateTesting = atomFamily<nullableOptions, string>({
  key: `${meshGenerationKey}/testing`,
  default: meshGenerationParamsFixture,
  dangerouslyAllowMutability: true,
});

export const meshGenerationState = isTestingEnv() ?
  meshGenerationStateTesting : meshGenerationStateRpc;

type RecoilKey = {
  projectId: string;
  meshUrl: string;
}

export const meshGenParamsSelector = selectorFamily<nullableOptions, RecoilKey>({
  key: 'getMeshGenerationParams',
  get: (key: RecoilKey) => async ({ get }) => {
    // If meshUrl is empty, use the mesh params associated with the projectId.
    if (!key.meshUrl) {
      return get(meshGenerationState(key.projectId));
    }

    // If meshUrl is defined send a request to get the mesh generation params.
    const req = new frontendpb.GetMeshGenerationParamsRequest({
      projectId: key.projectId,
      meshUrl: key.meshUrl,
    });

    const reply = await rpc.callRetry(
      'GetMeshGenerationParams',
      rpc.client.getMeshGenerationParams,
      req,
    );
    if (!reply.meshingParams) {
      throw Error(`No mesh params received for projectId=${key.projectId} meshUrl=${key.meshUrl}`);
    }
    return reply.meshingParams;
  },
});

// meshUrl can be empty or defined. If it is defined we return the mesh params used to create
// that URL. If it is empty, we return the mesh generation associated with the project. Both are
// needed. The project version is used during setup. The meshUrl is used in the results.
export default function useMeshGeneration(projectId: string, meshUrl: string) {
  return useRecoilValue(meshGenParamsSelector({ projectId, meshUrl }));
}

// meshUrl is not included here since only the mesh params for the project should be modified.
export const useSetMeshGeneration = (projectId: string) => (
  useSetRecoilState(meshGenerationState(projectId))
);
