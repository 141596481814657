// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

export type AnyKeyboardEvent = KeyboardEvent | React.KeyboardEvent;
export type AnyMouseEvent = MouseEvent | React.MouseEvent;
export type AnyEvent = AnyKeyboardEvent | AnyMouseEvent;

export const ARROW_UP_KEY = 'ArrowUp';
export const ARROW_DOWN_KEY = 'ArrowDown';
export const ARROW_LEFT_KEY = 'ArrowLeft';
export const ARROW_RIGHT_KEY = 'ArrowRight';

export interface ModifierMap {
  altKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
  shiftKey: boolean;
}

// Returns true iff any event's modifiers match the map
export const matchesModifiers = (event: AnyEvent, map: Partial<ModifierMap>) => (
  event.altKey === (map.altKey ?? false) &&
  event.ctrlKey === (map.ctrlKey ?? false) &&
  event.metaKey === (map.metaKey ?? false) &&
  event.shiftKey === (map.shiftKey ?? false)
);

// Returns true iff an event's shift key was pressed while meta/alt/ctrl keys were not pressed
export const isShiftModifiedOnly = (event: AnyEvent) => matchesModifiers(event, { shiftKey: true });

// Returns true iff an event's ctrl key was pressed while meta/alt/shift keys were not pressed
export const isCtrlModifiedOnly = (event: AnyEvent) => matchesModifiers(event, { ctrlKey: true });

export const isModified = (event: AnyEvent) => (
  event.altKey || event.ctrlKey || event.metaKey || event.shiftKey
);

export const isUnmodifiedKey = (event: AnyKeyboardEvent, key: string) => (
  !isModified(event) && event.key === key
);

export const isUnmodifiedArrowDownKey = (event: AnyKeyboardEvent) => (
  isUnmodifiedKey(event, ARROW_DOWN_KEY)
);

export const isUnmodifiedArrowUpKey = (event: AnyKeyboardEvent) => (
  isUnmodifiedKey(event, ARROW_UP_KEY)
);

export const isUnmodifiedArrowLeftKey = (event: AnyKeyboardEvent) => (
  isUnmodifiedKey(event, ARROW_LEFT_KEY)
);

export const isUnmodifiedArrowRightKey = (event: AnyKeyboardEvent) => (
  isUnmodifiedKey(event, ARROW_RIGHT_KEY)
);

export const isUnmodifiedEnterKey = (event: AnyKeyboardEvent) => isUnmodifiedKey(event, 'Enter');

export const isUnmodifiedSpaceKey = (event: AnyKeyboardEvent) => isUnmodifiedKey(event, ' ');

export const isUnmodifiedEscapeKey = (event: AnyKeyboardEvent) => isUnmodifiedKey(event, 'Escape');

export const isUnmodifiedTabKey = (event: AnyKeyboardEvent) => isUnmodifiedKey(event, 'Tab');

export const preventDefault = (event: React.KeyboardEvent) => event.preventDefault();

export interface ListenHandler {
  remove: () => void;
}

// One of the problems with using addEventListener and removeEventListener is
// that the latter's listener argument must be the same reference as the
// former's in order to safely remove the listener.  This isn't always possible
// when using anonymous functions; and the failure can be silent.
//
// This listen method takes target, event, and handler arguments and returns an
// object with a remove() method that correctly removes the listener.
// Applications don't have to worry about storing the correct listener function
// reference.  (I learned this technique from the Dojo toolkit some years ago,
// although its origin may go back further.)
export const listen = (
  target: EventTarget,
  event: string,
  handler: (event: Event) => void,
  options?: boolean | AddEventListenerOptions,
): ListenHandler => {
  target.addEventListener(event, handler, options);

  return {
    remove: () => target.removeEventListener(event, handler, options),
  };
};

// Check if two Keyboard events are equal. Only check alt, ctrl, meta, shift, and key properties.
// tolocalelowercase is used to account for case when caps lock is pressed (shiftkey will
// be false but event.key will be uppercase)
export const keyboardEventsEqual = (event1: AnyKeyboardEvent, event2: AnyKeyboardEvent) => (
  event1.key.toLocaleLowerCase() === event2.key.toLocaleLowerCase() &&
  event1.altKey === event2.altKey && event1.ctrlKey === event2.ctrlKey &&
  event1.metaKey === event2.metaKey && event1.shiftKey === event2.shiftKey
);

// get entries of keyboardEvent for testing
export const getKeyboardEventEntries = (event: AnyKeyboardEvent) => [
  event.key.toLocaleLowerCase(),
  event.altKey.toString(),
  event.ctrlKey.toString(),
  event.metaKey.toString(),
  event.shiftKey.toString(),
];

interface RelativeCoordsOptions {
  fromRight?: boolean;
  fromBottom?: boolean;
}

export function getRelativeEventCoordinates(
  event: MouseEvent,
  element: HTMLElement,
  opts: RelativeCoordsOptions = {},
) {
  const { fromRight, fromBottom } = opts;

  // getBoundingClientRect returns positioning relative to the viewport, and the clientX/clientY
  // event properties are also relative to the viewport.  Subtracting the x/y values yields the
  // position of the MouseEvent's click relative to the reference element.
  const box = element.getBoundingClientRect();

  const horizontalBoxRef = fromRight ? box.x + box.width : box.x;
  const verticalBoxRef = fromBottom ? box.y + box.height : box.y;
  return {
    x: event.clientX - horizontalBoxRef,
    y: event.clientY - verticalBoxRef,
  };
}

/** Convert a TouchEvent to a MouseEvent */
export const touchToMouse = (event: TouchEvent) => {
  event.preventDefault();
  const touches = event.changedTouches;
  const first = touches[0];
  let type = '';
  switch (event.type) {
    case 'touchstart': type = 'mousedown'; break;
    case 'touchmove': type = 'mousemove'; break;
    case 'touchend': type = 'mouseup'; break;
    default:
  }

  const eventOptions: MouseEventInit = {
    bubbles: true,
    cancelable: true,
    composed: true,
    screenX: first.screenX,
    screenY: first.screenY,
    clientX: first.clientX,
    clientY: first.clientY,
    ctrlKey: false,
    altKey: false,
    metaKey: false,
    button: event.touches.length === 1 ? 0 : 2, // pressing with 2 fingers maps to a right click
    buttons: event.touches.length === 1 ? 1 : 2, // pressing with 2 fingers maps to a right click
    view: window,
    detail: 1,
  };
  return new MouseEvent(type, eventOptions);
};

export function isArrowKey(event: AnyKeyboardEvent) {
  return [ARROW_DOWN_KEY, ARROW_LEFT_KEY, ARROW_RIGHT_KEY, ARROW_UP_KEY].includes(event.key);
}
