// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useRef } from 'react';

import {
  DefaultValue,
  atomFamily,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { useProjectContext } from '../../components/context/ProjectContext';
import { useFarfieldTransparency } from '../../components/hooks/useFarfieldTransparency';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { defaultDisplayProps } from '../../lib/lcvis/types';
import { Logger } from '../../lib/observability/logs';
import * as persist from '../../lib/persist';
import { syncProjectStateEffect } from '../../lib/recoilSync';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import { DisplayProps } from '../../pvproto/ParaviewRpc';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useGeometryTags } from '../geometry/geometryTagsState';
import { activeVisUrlState } from '../vis/activeVisUrl';

import { useLcVisEnabledValue } from './lcvisEnabledState';
import { LcvisKvStoreKey, makeLcvMeshKeys } from './lcvisPersistUtils';
import { useLcVisReadyValue } from './lcvisReadyState';
import { useLcvisVisibilityMap } from './lcvisVisibilityMap';

const logger = new Logger('lcvisSettings');

interface LCVisSettingsType {
  showAxes: boolean;
  displayProps: DisplayProps;
}

/**
 * The default settings to use for LCVis if the kvstore is empty, the settings schema changes,
 * or we reset the LCVis settings.
 */
const defaultLCVisSettings = (): LCVisSettingsType => ({
  showAxes: false,
  displayProps: defaultDisplayProps(),
});

function serializeSettings(val: LCVisSettingsType): Uint8Array {
  const value = new projectstatepb.LCVisSettings({
    json: JSON.stringify(val),
  });
  return value.toBinary();
}

/**
 * Deserialize the LCVis settings JSON from the kvstore. If there is no such value, instead return
 * the default settings from defaultLCVisSettings.
 */
function deserializeSettings(val: Uint8Array): LCVisSettingsType {
  const defaultSettings = defaultLCVisSettings();
  let settingsState = (val.length ?
    JSON.parse(projectstatepb.LCVisSettings.fromBinary(val).json) :
    defaultSettings
  ) as LCVisSettingsType;

  if (val.length) {
    // Check that the settings state from the parsed JSON is of the correct type. If there are any
    // missing or added fields, revert to the default settings state. This allows us to
    // change the LCVisSettingsType as needed and not worry about changing the proto, since
    // any old values will be automatically overwritten with the new default.
    if (!Object.entries(defaultSettings).every(([feature, on]) => (
      feature in settingsState &&
      typeof settingsState[feature as keyof typeof defaultSettings] === typeof on
    )) || Object.keys(defaultSettings).length !== Object.keys(settingsState).length) {
      settingsState = defaultSettings;
    }
  }
  return settingsState;
}

/**
 * Invokes lcvHandler to update the current LCVis session with the provided settings
 * @param settings The settings to apply to LCVis.
 *  */
function updateLCVisSettings(
  settings: LCVisSettingsType,
  visibilityMap: Map<string, boolean>,
) {
  const { displayProps, showAxes } = settings;
  lcvHandler.queueDisplayFunction('update settings', (display) => {
    if (showAxes !== undefined) {
      display.annotationHandler?.showGridAxes(showAxes);
    }
    if (displayProps) {
      display.workspace?.updateDisplayProps(displayProps, visibilityMap);
    }
  });
}

export const lcvisSettingsState = atomFamily<LCVisSettingsType, LcvisKvStoreKey>({
  key: 'lcvisSettingsState',
  default: (key: LcvisKvStoreKey) => {
    if (!key.lcvMeshKeys.length) {
      return defaultLCVisSettings();
    }
    return persist.getProjectState(key.projectId, key.lcvMeshKeys, deserializeSettings);
  },
  effects: (key: LcvisKvStoreKey) => [
    key.lcvMeshKeys.length ?
      syncProjectStateEffect(
        key.projectId,
        key.lcvMeshKeys[0],
        deserializeSettings,
        serializeSettings,
      ) : () => { },
  ],
});

export const lcvisSettingsSelector = selectorFamily<LCVisSettingsType, persist.RecoilProjectKey>({
  key: 'lcvisSettingsSelector',
  get: (key: persist.RecoilProjectKey) => ({ get }) => {
    const { projectId } = key;
    const activeVisUrl = get(activeVisUrlState(key));
    const lcvMeshKeys = makeLcvMeshKeys('lcvisSettings', activeVisUrl);
    return get(lcvisSettingsState({ projectId, lcvMeshKeys }));
  },
  set: (key: persist.RecoilProjectKey) => (
    { get, set },
    newVal: LCVisSettingsType | DefaultValue,
  ) => {
    const { projectId } = key;
    let activeVisUrl: string = '';
    try {
      activeVisUrl = get(activeVisUrlState(key));
    } catch {
      // activeVisUrlState is an async state, so this call can fail for timing reasons.
      // If it does, ignore it.
      return;
    }
    const lcvMeshKeys = makeLcvMeshKeys('lcvisSettings', activeVisUrl);
    if (newVal instanceof DefaultValue) {
      // newVal will only be DefaultValue if someone calls resetRecoilState. So in that case
      // set the state to the default LCVisSettings.
      set(lcvisSettingsState({ projectId, lcvMeshKeys }), defaultLCVisSettings());
      return;
    }
    set(lcvisSettingsState({ projectId, lcvMeshKeys }), newVal);
  },
});

export const useLcvisSettingsState = (
  key: persist.RecoilProjectKey,
) => useRecoilState(lcvisSettingsSelector(key));

export const useSetLcvisSettings = (
  key: persist.RecoilProjectKey,
) => useSetRecoilState(lcvisSettingsSelector(key));

export const useLcvisSettingsValue = (
  key: persist.RecoilProjectKey,
) => useRecoilValue(lcvisSettingsSelector(key));

/** Reapply the LCVis settings when the recoil state changes. */
export const useApplyLCVisSettings = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const lcvisSettings = useLcvisSettingsValue({ projectId, workflowId, jobId });
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvReady = useLcVisReadyValue();
  const [visibilityMap] = useLcvisVisibilityMap({ projectId, workflowId, jobId });
  const isGeometryView = useIsGeometryView();
  const geometryTags = useGeometryTags(projectId);
  const farfieldTransparency = useFarfieldTransparency();

  const executed = useRef(false);
  const visibilityMapRef = useRef(visibilityMap);
  const showAxesGridRef = useRef(lcvisSettings.showAxes);
  useEffect(() => {
    // This is needed to circumvent an issue in the geometry tab where lcvisVisibilityMapState
    // is always empty until it is first set. If empty, it results in the visibilityMap being
    // different on each render which retriggers this useEffect. This check is only needed the first
    // time this useEffect is called since after that the visibilityMap will have a value.
    let visibilityChanged = false;
    if (!isGeometryView) {
      visibilityChanged = true;
    } else if (visibilityMapRef.current.size !== visibilityMap.size) {
      visibilityChanged = true;
    } else if (!executed.current) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, val] of visibilityMap) {
        if (visibilityMapRef.current.get(key) !== val) {
          visibilityChanged = true;
          break;
        }
      }
    }

    if (lcvisEnabled && lcvReady && (
      visibilityChanged || showAxesGridRef.current !== lcvisSettings.showAxes
    )) {
      showAxesGridRef.current = lcvisSettings.showAxes;
      executed.current = true;
      visibilityMapRef.current = visibilityMap;
      // It seems that we are seeing random failures here when setting the initial LCVis settings.
      // Wrap this in a try catch and hopefully this should not be a big issue
      try {
        updateLCVisSettings(lcvisSettings, visibilityMap);
      } catch (err) {
        logger.error('Error applying initial LCVis settings: ', err);
      }

      farfieldTransparency();
    }
  }, [geometryTags,
    isGeometryView,
    lcvReady,
    lcvisEnabled,
    lcvisSettings,
    farfieldTransparency,
    visibilityMap,
  ]);
};
