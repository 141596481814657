// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { Message } from '@bufbuild/protobuf';
import * as FileSaver from 'file-saver';

import * as simulationpb from '../proto/client/simulation_pb';

import { protoToJson } from './proto';
import { simulationParamToJson } from './simulationParamUtils';
import { addRpcError } from './transientNotification';

/**
 * Save a protocol message as a local json file
 */
export function saveAsJson(proto: Message, filename: string, useStableApi: boolean = true): void {
  const saver = async () => {
    const json = await protoToJson(proto, useStableApi);
    const file = new File(
      [json],
      filename,
      { type: 'application/json;charset=utf-8' },
    );
    return FileSaver.saveAs(file);
  };
  saver().catch((err: Error) => addRpcError(`Save ${filename} failed`, err));
}

/**
 * Save param proto safely as a local json file
 */
export function saveParamAsJsonSafe(
  simParam: simulationpb.SimulationParam,
  filename: string,
): void {
  const saver = async () => {
    const json = await simulationParamToJson(simParam);
    const file = new File(
      [json],
      filename,
      { type: 'application/json;charset=utf-8' },
    );
    return FileSaver.saveAs(file);
  };
  saver().catch((err: Error) => addRpcError(`Save ${filename} failed`, err));
}

// Saves an object in json format.
export function saveObjectAsJson(object: any, filename: string): void {
  const saver = async () => {
    const json = JSON.stringify(object);
    const file = new File(
      [json],
      filename,
      { type: 'application/json;charset=utf-8' },
    );
    return FileSaver.saveAs(file);
  };
  saver().catch((err: Error) => addRpcError(`Save ${filename} failed`, err));
}
