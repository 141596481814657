// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ToggleSwitch } from './ToggleSwitch';

const DEFAULT_KNOB_RADIUS_SMALL = 6;
const DEFAULT_KNOB_RADIUS_MEDIUM = 7;

interface LuminaryToggleSwitchProps {
  value: boolean; // The component's value state
  onChange?: (value: boolean) => void; // Optional value state change handler
  disabled?: boolean; // Used to disable the component, making it non-interactive
  // The small type should be used everywhere in the Solution environment (i.e. Geometry,
  // Setup and Results). The default size is to be used outside of the Solution env.
  small?: boolean;
}

/**
 * Use this component instead of the basic low-level ToggleSwitch.
 */
export const LuminaryToggleSwitch = (props: LuminaryToggleSwitchProps) => {
  const { value, onChange, disabled, small } = props;

  const knobRadius = small ? DEFAULT_KNOB_RADIUS_SMALL : DEFAULT_KNOB_RADIUS_MEDIUM;
  const width = 4 * knobRadius + 4;

  return (
    <ToggleSwitch
      disabled={disabled}
      knobRadius={knobRadius}
      onChange={onChange}
      value={value}
      width={width}
    />
  );
};
