// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 21;
export const InboxSearchIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="inboxSearch"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M8.458,3
           C8.283,3.661 8.19,4.355 8.19,5.07
           C8.19,5.384 8.208,5.695 8.243,6
           L5.49,6
           L2.925,12
           L6,12
           C6.825,12 7.5,12.675 7.5,13.5
           L7.5,15
           L16.5,15
           L16.5,13.5
           C16.5,13.368 16.517,13.239 16.55,13.117
           C16.731,13.108 16.906,13.089 17.136,13.06
           C17.366,13.049 17.513,13.017 17.798,12.945
           C17.929,12.921 18.044,12.897 18.158,12.87
           L18.232,12.87
           L18.415,12.809
           L20.314,14.717
           C20.952,15.423 21.989,15.87 23.085,15.87
           C23.4,15.87 23.707,15.833 24,15.763
           L24,19.5
           C24,20.325 23.325,21 22.5,21
           L1.5,21
           C0.675,21 0,20.325 0,19.5
           L0,11.19
           L3.12,3.915
           C3.345,3.375 3.885,3 4.5,3
           L8.458,3Z
           M23.73,11.295
           C23.88,11.475 23.985,11.7 24,11.955
           C24,12.465 23.595,12.87 23.085,12.87
           C22.815,12.87 22.59,12.765 22.44,12.6
           L19.08,9.225
           C18.885,9.345 18.69,9.465 18.48,9.57
           C18.45,9.585 18.405,9.6 18.375,9.615
           C18.165,9.705 17.97,9.795 17.745,9.87
           L17.73,9.87
           C17.52,9.945 17.295,9.99 17.07,10.035
           C17.01,10.05 16.95,10.065 16.905,10.065
           C16.68,10.095 16.455,10.125 16.215,10.125
           C13.44,10.125 11.19,7.86 11.19,5.07
           C11.19,2.28 13.44,0.015 16.215,0
           C18.99,0 21.24,2.265 21.24,5.055
           C21.24,5.295 21.21,5.52 21.18,5.76
           C21.165,5.82 21.165,5.865 21.15,5.925
           C21.12,6.15 21.075,6.36 21,6.585
           L21,6.6
           C20.925,6.825 20.835,7.02 20.745,7.23
           C20.73,7.26 20.715,7.305 20.7,7.335
           C20.595,7.545 20.49,7.74 20.355,7.935
           L23.73,11.295Z
           M16.215,8.385
           C18.045,8.385 19.53,6.9 19.53,5.055
           C19.53,3.21 18.045,1.725 16.215,1.725
           C14.385,1.725 12.9,3.21 12.9,5.055
           C12.9,6.885 14.385,8.385 16.215,8.385Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
