/**
 * The ValidateParam message usually contains stack trace lines like
 * frontendserver.go:3230: ValidateParam cc/fvm/validator/validator.cc:1499: <actual message>
 * formatValidateParamMessage only returns the message after all the trace lines.
 */
export function formatValidateParamMessage(message: string) {
  const STACK_TRACE_SEPARATORS = ['runtime.goexit'];

  const messageWithoutErrorSource = message.replace(/.*\.(?:go|cc):\d+:/g, '');
  const contentBeforeSeparators = STACK_TRACE_SEPARATORS.reduce(
    (result, separator) => result.split(separator)[0],
    messageWithoutErrorSource,
  ).trim().replace(/:$/, '');

  return contentBeforeSeparators;
}
