// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
function getItemCategory(name: string) {
  const UNIT_SEPARATOR = ' (';
  const nameWithoutUnit = name.split(UNIT_SEPARATOR)[0].trim();

  const categoryMap = {
    Pressure: [
      'Pressure',
      'Pressure Average',
      'Total Pressure',
      'Dynamic Pressure',
      'Absolute Pressure',
      'Total Pressure Coefficient',
      'Total Pressure Coefficient Average',
      'Pressure Coefficient',
      'Pressure Coefficiant Average',
      'Pressure Drop',
      'Total Pressure Drop',
    ],
    Velocity: [
      'Mach',
      'Mach Average',
      'Velocity Magnitude',
      'Velocity',
      'Velocity X',
      'Velocity Y',
      'Velocity Z',
      'Velocity Average Magnitude',
      'Velocity Average X',
      'Velocity Average Y',
      'Velocity Average Z',
      'Cell Courant Number',
      'Q-Criterion',
      'Lambda2',
    ],
    Properties: [
      'Density',
      'Viscosity',
    ],
    Temperature: [
      'Temperature',
      'Temperature Average',
      'Total Temperature',
      'Total Temperature Average',
      'Heat Flux',
      'Energy Flux',
    ],
    Turbulence: [
      'Y Plus',
      'Wall Shear Stress Magnitude',
      'Wall Shear Stress',
      'Wall Shear Stress X',
      'Wall Shear Stress Y',
      'Wall Shear Stress Z',
      'Eddy Viscosity',
      'Spalart-Allmaras Variable',
      'Turbulent Viscosity Ratio',
      'Turbulent Kinetic Energy',
      'Specific Dissipation Rate',
      'Skin Friction Coefficient',
      'Roughness Reynolds Number',
    ],
    Transition: [
      'Turbulence Intermittency',
      'Momentum-Thickness Reynolds Number',
      'Amplification Factor',
    ],
    Forces: [
      'Lift',
      'Drag',
      'Viscous Drag',
      'Pressure Drag',
      'Sideforce',
      'Pressure Force',
      'Friction Force',
      'Force',
      'Downforce',
      'Thrust-per-unit-area',
      'Disk Thrust',
    ],
    Moments: [
      'Moment',
      'Pitching Moment',
      'Rolling Moment',
      'Yawing Moment',
      'Torque-per-unit-area',
      'Disk Torque',
    ],
    Global: [
      'Residuals',
      'Convergence Monitors',
    ],
    Mesh: [
      'Area',
      'x-coordinate',
      'y-coordinate',
      'z-coordinate',
    ],
    'Flow Rate': [
      'Mass Flow Rate',
      'Absolute Mass Flow Rate',
      'Volumetric Flow Rate',
      'Absolute Volumetric Flow Rate',
    ],
  };

  const [foundCategory] = Object.entries(categoryMap)
    .find(([, categoryItems]) => categoryItems.includes(nameWithoutUnit)) || [name, []];

  return foundCategory;
}

export function groupVariables<T extends {name: string}>(data: T[]): Record<string, T[]> {
  return data.reduce<Record<string, T[]>>((result, item) => {
    const itemCategory = getItemCategory(item.name);
    const categoryExists = itemCategory in result;

    if (categoryExists) {
      return {
        ...result,
        [itemCategory]: [...result[itemCategory], item],
      };
    }

    return { ...result, [itemCategory]: [item] };
  }, {});
}
