// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtom, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import * as frontendpb from '../proto/frontend/frontend_pb';

type RequestedWorkOrders = Map<frontendpb.WorkOrderType, frontendpb.PendingWorkOrder>;

// Saves a map of all the work orders that have currently requested meaning that an RPC is in
// progress for it. This map should match pendingWorkOrders when all the work orders are in
// progress. This gets wiped out if the user navigates away.
const workOrderState = atomFamily((projectId: string) => atom<RequestedWorkOrders>(new Map()));

export const useRequestedWorkOrders = (projectId: string) => (
  useAtom(workOrderState(projectId))
);
export const useSetRequestedWorkOrders = (projectId: string) => (
  useSetAtom(workOrderState(projectId))
);
