// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 120;
const baseHeight = 94;
export const UngroupActionIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="ungroupAction"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M50.834,93.667
           C51.528,93.667 52.118,93.424 52.604,92.937
           C53.091,92.451 53.334,91.861 53.334,91.167
           L53.334,88.667
           L100,88.667
           L100,91.167
           C100,91.861 100.243,92.451 100.729,92.937
           C101.215,93.424 101.806,93.667 102.5,93.667
           L117.5,93.667
           C118.195,93.667 118.785,93.424 119.271,92.937
           C119.757,92.451 120,91.861 120,91.167
           L120,76.167
           C120,75.472 119.757,74.882 119.271,74.396
           C118.785,73.91 118.195,73.667 117.5,73.667
           L115,73.667
           L115,40.333
           L117.5,40.333
           C118.195,40.333 118.785,40.09 119.271,39.604
           C119.757,39.118 120,38.528 120,37.833
           L120,22.833
           C120,22.139 119.757,21.549 119.271,21.062
           C118.785,20.576 118.195,20.333 117.5,20.333
           L102.5,20.333
           C101.806,20.333 101.215,20.576 100.729,21.062
           C100.243,21.549 100,22.139 100,22.833
           L100,25.333
           L81.667,25.333
           L81.667,20.333
           L84.167,20.333
           C84.861,20.333 85.452,20.09 85.938,19.604
           C86.424,19.118 86.667,18.528 86.667,17.833
           L86.667,2.833
           C86.667,2.139 86.424,1.549 85.938,1.062
           C85.452,0.576 84.861,0.333 84.167,0.333
           L69.167,0.333
           C68.472,0.333 67.882,0.576 67.396,1.062
           C66.91,1.549 66.667,2.139 66.667,2.833
           L66.667,5.333
           L20,5.333
           L20,2.833
           C20,2.139 19.757,1.549 19.271,1.062
           C18.785,0.576 18.194,0.333 17.5,0.333
           L2.5,0.333
           C1.806,0.333 1.215,0.576 0.729,1.062
           C0.243,1.549 0,2.139 0,2.833
           L0,17.833
           C0,18.528 0.243,19.118 0.729,19.604
           C1.215,20.09 1.806,20.333 2.5,20.333
           L5,20.333
           L5,53.667
           L2.5,53.667
           C1.806,53.667 1.215,53.91 0.729,54.396
           C0.243,54.882 0,55.472 0,56.167
           L0,71.167
           C0,71.861 0.243,72.451 0.729,72.937
           C1.215,73.424 1.806,73.667 2.5,73.667
           L17.5,73.667
           C18.194,73.667 18.785,73.424 19.271,72.937
           C19.757,72.451 20,71.861 20,71.167
           L20,68.667
           L38.333,68.667
           L38.333,73.667
           L35.833,73.667
           C35.139,73.667 34.549,73.91 34.063,74.396
           C33.576,74.882 33.333,75.472 33.333,76.167
           L33.333,91.167
           C33.333,91.861 33.576,92.451 34.063,92.937
           C34.549,93.424 35.139,93.667 35.833,93.667
           L50.834,93.667Z
           M80,13.667
           L73.334,13.667
           L73.334,7
           L80,7
           L80,13.667Z
           M13.333,13.667
           L6.667,13.667
           L6.667,7
           L13.333,7
           L13.333,13.667Z
           M66.667,58.667
           L20,58.667
           L20,56.167
           C20,55.472 19.757,54.882 19.271,54.396
           C18.785,53.91 18.194,53.667 17.5,53.667
           L15,53.667
           L15,20.333
           L17.5,20.333
           C18.194,20.333 18.785,20.09 19.271,19.604
           C19.757,19.118 20,18.528 20,17.833
           L20,15.333
           L66.667,15.333
           L66.667,17.833
           C66.667,18.528 66.91,19.118 67.396,19.604
           C67.882,20.09 68.472,20.333 69.167,20.333
           L71.667,20.333
           L71.667,53.667
           L69.167,53.667
           C68.472,53.667 67.882,53.91 67.396,54.396
           C66.91,54.882 66.667,55.472 66.667,56.167
           L66.667,58.667Z
           M113.333,33.667
           L106.667,33.667
           L106.667,27
           L113.333,27
           L113.333,33.667Z
           M100,78.667
           L53.334,78.667
           L53.334,76.167
           C53.334,75.472 53.091,74.882 52.604,74.396
           C52.118,73.91 51.528,73.667 50.834,73.667
           L48.333,73.667
           L48.333,68.667
           L66.667,68.667
           L66.667,71.167
           C66.667,71.861 66.91,72.451 67.396,72.937
           C67.882,73.424 68.472,73.667 69.167,73.667
           L84.167,73.667
           C84.861,73.667 85.452,73.424 85.938,72.937
           C86.424,72.451 86.667,71.861 86.667,71.167
           L86.667,56.167
           C86.667,55.472 86.424,54.882 85.938,54.396
           C85.452,53.91 84.861,53.667 84.167,53.667
           L81.667,53.667
           L81.667,35.333
           L100,35.333
           L100,37.833
           C100,38.528 100.243,39.118 100.729,39.604
           C101.215,40.09 101.806,40.333 102.5,40.333
           L105,40.333
           L105,73.667
           L102.5,73.667
           C101.806,73.667 101.215,73.91 100.729,74.396
           C100.243,74.882 100,75.472 100,76.167
           L100,78.667Z
           M80,67
           L73.334,67
           L73.334,60.333
           L80,60.333
           L80,67Z
           M13.333,67
           L6.667,67
           L6.667,60.333
           L13.333,60.333
           L13.333,67Z
           M46.667,87
           L40,87
           L40,80.333
           L46.667,80.333
           L46.667,87Z
           M113.333,87
           L106.667,87
           L106.667,80.333
           L113.333,80.333
           L113.333,87Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
