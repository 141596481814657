// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      color: 'var(--color-disabled-type)',
    },
  }),
  { name: 'AxisAdornment' },
);

export interface AxisAdornmentProps {
  axis: 'x' | 'y' | 'z';
}

export const AxisAdornment = (props: AxisAdornmentProps) => {
  const { axis } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>{axis}</div>
  );
};
