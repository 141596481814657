// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import streamSaver from 'streamsaver';

import * as frontendpb from '../proto/frontend/frontend_pb';

import assert from './assert';
import { fromBigInt } from './number';
import { Logger } from './observability/logs';
import * as rpc from './rpc';

const logger = new Logger('exportMovie');

export default async function exportMovie(
  urls: string[],
  camera: string,
  view: string,
): Promise<void> {
  let fileStream: WritableStream | null = null;
  let fileWriter: WritableStreamDefaultWriter | null = null;
  let fileSize = 1;
  try {
    const req = new frontendpb.ExportMovieRequest({ urls, camera, view });
    let reply: frontendpb.ExportMovieReply | null = null;
    try {
      // This could be a long time
      reply = await rpc.callRetry(
        'ExportMovie',
        rpc.client.exportMovie,
        req,
      );
    } catch (error) {
      logger.error('Movie export error: ', error);
    }
    if (!reply) {
      return;
    }
    fileSize = fromBigInt(reply.size);

    // Create writer with size info so progress is tracked.
    if (!fileWriter) {
      assert(fileSize > 0, 'File size cannot be zero');
      // We could offer a format from a list and pass it as a param
      fileStream = streamSaver.createWriteStream('movie.mp4', { size: fileSize });
      fileWriter = fileStream.getWriter();
    }
    const { data } = reply;
    await fileWriter.write(data);
  } catch (err) {
    if (fileWriter) {
      await fileWriter.abort();
    }
    throw err;
  }
  if (fileWriter) {
    await fileWriter.close();
  }
}
