// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { MultipleChoiceParam } from '../../ProtoDescriptor';
import { ParamName, paramDesc } from '../../SimulationParamDescriptor';
import { boldEscaped } from '../../lib/html';
import { findParticleGroupById } from '../../lib/particleGroupUtils';
import { appendPhysicalBehavior, compatiblePhysicalBehaviorModelTypes, getPhysicalBehaviorsByParticleType } from '../../lib/physicalBehaviorUtils';
import { getFluid, getFluidPhysics, getPhysicsId, getPhysicsName } from '../../lib/physicsUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useSelectionContext } from '../context/SelectionManager';
import { ModelCreator, ModelData } from '../controls/ModelSelector';

import { useSimulationConfig } from './useSimulationConfig';

// When creating a physical behavior for a selected particle group, choose a default behavior model
// type (disk, line, etc.) based on the particle group type.  For now, take the first item in the
// list of compatible behavior model types, but this may change.
function defaultPhysicalBehaviorModel(type: simulationpb.ParticleGroupType) {
  const modelTypes = compatiblePhysicalBehaviorModelTypes(type);
  const modelType = modelTypes.length ? modelTypes[0] : null;

  return (paramDesc[ParamName.PhysicalBehaviorModel] as MultipleChoiceParam).choices.find(
    (choice) => choice.enumNumber === modelType,
  );
}

// Transform a physical behavior into a ModelData object
function getModelData(
  behavior: simulationpb.PhysicalBehavior,
  title: string,
): ModelData<simulationpb.PhysicalBehavior> {
  return {
    model: behavior,
    id: behavior.physicalBehaviorId,
    label: behavior.physicalBehaviorName,
    title,
  };
}

// Collect all physical behaviors from a fluid physics and transform into ModelData
function getPhysicsModelData(
  fluidPhysics: simulationpb.Physics,
  param: simulationpb.SimulationParam,
  addTitle = false,
): ModelData<simulationpb.PhysicalBehavior>[] {
  const title = addTitle ? getPhysicsName(fluidPhysics, param) : '';
  return getFluid(fluidPhysics)!.physicalBehavior.map(
    (behavior) => getModelData(behavior, title || ''),
  );
}

/**
 * Manage the properties passed to ModelSelector for a particle group (actuator disk) in order to
 * assign, unassign, and create physical behavior associations.
 * @param id
 * @returns
 */
export function useDiskBehaviorModel(id: string) {
  const { setSelection, setScrollTo } = useSelectionContext();

  const { simParam, saveParamAsync } = useSimulationConfig();

  const allFluidPhysics = useMemo(() => getFluidPhysics(simParam), [simParam]);
  const particleGroup = useMemo(() => findParticleGroupById(simParam, id), [id, simParam]);
  const particleGroupType = useMemo(() => particleGroup?.particleGroupType, [particleGroup]);
  const behaviorType = useMemo(
    () => (particleGroupType ? defaultPhysicalBehaviorModel(particleGroupType) : null),
    [particleGroupType],
  );

  // Creates a new physical behavior and attaches it to the particle group
  const createBehaviorModel = useCallback(async (physicsId: string) => {
    if (behaviorType) {
      const newBehaviorId = await saveParamAsync((newParam) => {
        const behavior = appendPhysicalBehavior(newParam, physicsId, behaviorType, id);
        return behavior.physicalBehaviorId;
      });

      setSelection([newBehaviorId]);
      setScrollTo({ node: newBehaviorId });
    }
  }, [behaviorType, id, saveParamAsync, setScrollTo, setSelection]);

  const currentBehavior = useMemo(() => {
    const behaviors = particleGroupType ?
      getPhysicalBehaviorsByParticleType(simParam, particleGroupType) :
      [];
    return behaviors.find(({ physicalBehaviorId }) => (
      physicalBehaviorId === particleGroup?.particleGroupBehaviorModelRef
    ));
  }, [particleGroup, particleGroupType, simParam]);

  const modelData = useMemo(() => allFluidPhysics.reduce((result, fluidPhysics) => {
    result.push(...getPhysicsModelData(fluidPhysics, simParam, allFluidPhysics.length > 1));
    return result;
  }, [] as ModelData<simulationpb.PhysicalBehavior>[]), [allFluidPhysics, simParam]);

  const selected = currentBehavior ? [currentBehavior.physicalBehaviorId] : undefined;

  const creators = useMemo(() => {
    const items: ModelCreator[] = [];
    if (behaviorType) {
      const modelLabel = behaviorType.text;
      if (allFluidPhysics.length === 1) {
        // If there's just one physics, the creator is a normal menu item that creates a behavior
        // with that physics's ID.
        items.push({
          label: modelLabel,
          onClick: () => createBehaviorModel(getPhysicsId(allFluidPhysics[0])),
          tooltip: 'Add a physical behavior and attach this disk',
        });
      } else if (allFluidPhysics.length) {
        // If there are multiple physics, the creator uses a sub-creator (i.e. a nested menu) for
        // each physics.
        items.push({
          label: modelLabel,
          onClick: () => { },
          subCreators: allFluidPhysics.map((fluidPhysics) => {
            const name = getPhysicsName(fluidPhysics, simParam);
            return {
              label: name,
              icon: null,
              onClick: () => createBehaviorModel(getPhysicsId(fluidPhysics)),
              tooltip: `Add a physical behavior on ${boldEscaped(name)} and attach this disk`,
            };
          }),
        });
      }
    }
    return items;
  }, [allFluidPhysics, behaviorType, createBehaviorModel, simParam]);

  // If behavior is undefined, any existing attachment is unlinked.  Otherwise, attach the provided
  // behavior to the particle group
  const attachBehavior = (behavior?: simulationpb.PhysicalBehavior) => saveParamAsync(
    (newParam) => {
      const newItem = findParticleGroupById(newParam, id);
      if (newItem) {
        newItem.particleGroupBehaviorModelRef = behavior ? behavior.physicalBehaviorId : '';
      }
    },
  );

  return {
    creators,
    modelData,
    selected,
    attachBehavior,
  };
}
