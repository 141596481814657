// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue } from 'recoil';

import { relatedPhysicsList } from '../../../../lib/contactsUtils';
import { RecoilProjectKey } from '../../../../lib/persist';
import * as frontendpb from '../../../../proto/frontend/frontend_pb';
import { geometryTagsState } from '../../../../recoil/geometry/geometryTagsState';
import { geometryContactsStateSelector } from '../../../../recoil/geometryContactsState';
import { staticVolumesState } from '../../../../recoil/volumes';

import { simulationParamState } from './param';

export type NamesRecord = Record<string, string>;

/**
 * Maintain a subset of geometry contacts whose surfaces belong to volumes that are collectively
 * assigned to exactly two physics, making them eligible for conversion to multiphysics (coupling)
 * interfaces
 */
export const simulationAvailableMultiphysicsContactsState = selectorFamily<
  frontendpb.GetGeometryContactsReply_GeoContact[],
  RecoilProjectKey
>({
  key: 'simulationAvailableMultiphysicsContactsState',
  get: (key: RecoilProjectKey) => ({ get }) => {
    const { projectId } = key;
    const simParam = get(simulationParamState(key));
    const staticVolumes = get(staticVolumesState(projectId));
    const geoContacts = get(geometryContactsStateSelector({ projectId }));
    const geometryTags = get(geometryTagsState({ projectId }));

    return geoContacts.contacts.filter((contact) => {
      // Only those contacts that span multiple physics should be converted to multiphysics coupling
      // interfaces
      const physicsList = relatedPhysicsList(contact, simParam, geometryTags, staticVolumes);
      return (physicsList.length === 2);
    });
  },
});

export function useSimulationAvailableMultiphysicsContacts(
  projectId: string,
  workflowId: string,
  jobId: string,
) {
  return useRecoilValue(
    simulationAvailableMultiphysicsContactsState({ projectId, workflowId, jobId }),
  );
}
