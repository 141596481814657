// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { MultiCheckBoxItemProps } from '../../../../lib/componentTypes/form';
import { colors } from '../../../../lib/designSystem';
import { addWarning } from '../../../../lib/transientNotification';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useEditState } from '../../../../recoil/paraviewState';
import Form from '../../../Form';
import { DataSelect } from '../../../Form/DataSelect';
import { NumberInput } from '../../../Form/NumberInput';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import DataComponentSelect from '../../../Paraview/DataComponentSelect';
import Divider from '../../../Theme/Divider';
import { useSelectedFilterNode } from '../../../visFilter/useFilterNode';
import { FilterEditControl } from '../../FilterEditControl';
import PropertiesSection from '../../PropertiesSection';
import { CommonFilterMessages } from '../shared/CommonFilterMessages';
import { FilterDisplayPanel } from '../shared/FilterDisplayPanel';

import { FilterPropertiesPanelProps } from './props';

/** Create the param filled with default values, to be used when creating a new
    filter off the given parent. */
export function newThresholdParam(
  parent: ParaviewRpc.TreeNode,
): ParaviewRpc.ThresholdParam {
  const data = parent.pointData.filter(
    (item: ParaviewRpc.ArrayInformation) => item.dim === 1 || item.dim === 3,
  );
  return {
    typ: ParaviewRpc.TreeNodeType.THRESHOLD,
    thresholdVariable: {
      displayDataName: data[0].name,
      displayDataNameComponent: 0,
    },
    lowerThreshold: 0,
    upperThreshold: 0,
    inclusiveMode: true,
    allScalarsWithinRange: false,
    useContinuousCellRange: false,
    clipCells: false,
  };
}

// Parameter-input dialog for the threshold filter.
export const ThresholdPropPanel = (props: FilterPropertiesPanelProps) => {
  const { displayProps, filterNode, nodeId, parentFilterNode, viewState } = props;

  const [editState] = useEditState();
  const { updateEditState } = useSelectedFilterNode();

  const onUpdate = (newParam: ParaviewRpc.TreeNodeParam) => (
    updateEditState({
      param: newParam,
    })
  );

  const param = props.param as ParaviewRpc.ThresholdParam;
  const readOnly = !editState;
  // Threshold works only for scalar and vector data.
  const pointData = parentFilterNode.pointData.filter(
    (item: ParaviewRpc.ArrayInformation) => item.dim === 1 || item.dim === 3,
  );
  const empty = pointData.length <= 0;

  const thresholdVar = param.thresholdVariable;
  const paramData = pointData.find(({ name }) => (name === thresholdVar.displayDataName));

  // Tracks whether the clipCells option is active. This is needed because this
  // option activates/deactivates other checkboxes, so having the state of
  // the clipCells checkbox avoids excessive rerenders and glitches due to the
  // change in the ThresholdParam.
  const [clipCells, setClipCells] = useState<boolean>(param.clipCells);

  const setDataName = (newName: string) => {
    const thresholdVariable: ParaviewRpc.DisplayPvVariable = {
      ...thresholdVar,
      displayDataName: newName,
      displayDataNameComponent: 0,
    };
    onUpdate({
      ...param,
      thresholdVariable,
    });
  };

  const setDataNameComponent = (newComponent: number) => {
    const thresholdVariable: ParaviewRpc.DisplayPvVariable = {
      ...thresholdVar,
      displayDataNameComponent: newComponent,
    };
    onUpdate({
      ...param,
      thresholdVariable,
    });
  };

  const setLowerThreshold = (newValue: number) => {
    if (newValue > param.upperThreshold) {
      addWarning('Lower range cannot be bigger than the upper range');
    }
    onUpdate({
      ...param,
      lowerThreshold: newValue,
    });
  };

  const setUpperThreshold = (newValue: number) => {
    if (newValue < param.lowerThreshold) {
      addWarning('Upper range cannot be lower than the lower range');
    }
    onUpdate({
      ...param,
      upperThreshold: newValue,
    });
  };

  const toggleInclusive = () => {
    const inclusive = param.inclusiveMode;
    onUpdate({
      ...param,
      inclusiveMode: !inclusive,
    });
  };

  const toggleAllScalarsWithinRange = () => {
    const allScalarsWithinRange = param.allScalarsWithinRange;
    onUpdate({
      ...param,
      allScalarsWithinRange: !allScalarsWithinRange,
    });
  };

  const toggleClipCells = () => {
    onUpdate({
      ...param,
      clipCells: !param.clipCells,
    });
  };

  const checkboxOptions: MultiCheckBoxItemProps[] = [];
  checkboxOptions.push({
    checked: clipCells,
    disabled: readOnly || param.allScalarsWithinRange,
    help: 'Clip the boundary thresholded cells.',
    onChange: (checked) => {
      setClipCells(checked);
      toggleClipCells();
    },
    optionText: 'Smooth',
  });
  checkboxOptions.push({
    checked: param.inclusiveMode,
    disabled: readOnly,
    help: 'Whether to include all thresholded cells or only those within the range.',
    onChange: () => {
      toggleInclusive();
    },
    optionText: 'Inclusive',
  });
  checkboxOptions.push({
    checked: param.allScalarsWithinRange,
    disabled: readOnly || clipCells,
    help: 'Whether all points or only one point of thresholded cells must ' +
      'be within the range.',
    onChange: () => {
      toggleAllScalarsWithinRange();
    },
    optionText: 'Strict',
  });

  return (
    <div>
      <CommonFilterMessages emptyFilter={empty} />
      <FilterDisplayPanel filterNode={filterNode} />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={!!editState}
          expandWhenDisabled
          headerRight={(
            <FilterEditControl
              disableEdit={empty}
              displayProps={displayProps}
              nodeId={nodeId}
              param={param}
            />
          )}
          heading="Visualization Input"
          nodeId={nodeId}
          panelName="input">
          <Form.LabeledInput label="Threshold Field">
            <DataSelect
              asBlock
              disabled={readOnly}
              onChange={setDataName}
              options={pointData.map(({ name }) => ({
                name,
                value: name,
                selected: name === thresholdVar.displayDataName,
              }))}
              size="small"
            />
          </Form.LabeledInput>
          <DataComponentSelect
            disabled={readOnly}
            displayVariable={thresholdVar}
            fullRow
            onChange={(component: number) => {
              setDataNameComponent(component);
            }}
            viewState={viewState}
          />
          <Form.LabeledInput label="Value Range">
            <div style={{ display: 'flex', gap: '4px' }}>
              <NumberInput
                asBlock
                disabled={readOnly}
                onCommit={(value) => setLowerThreshold(value)}
                size="small"
                value={(param as ParaviewRpc.ThresholdParam).lowerThreshold}
              />
              <NumberInput
                asBlock
                disabled={readOnly}
                onCommit={(value) => setUpperThreshold(value)}
                size="small"
                value={(param as ParaviewRpc.ThresholdParam).upperThreshold}
              />
            </div>
          </Form.LabeledInput>
          <Form.LabeledInput label="">
            <div style={{ color: colors.neutral650 }}>
              Min, Max [
              {paramData ?
                paramData.range[thresholdVar.displayDataNameComponent][0].toPrecision(5) :
                'none'}
              ,&nbsp;
              {paramData ?
                paramData.range[thresholdVar.displayDataNameComponent][1].toPrecision(5) :
                'none'}
              ]
            </div>
          </Form.LabeledInput>
          <Form.LabeledInput
            help="Extra Options for controlling the threshold"
            label="Extraction Options">
            <Form.MultiCheckBox checkBoxProps={checkboxOptions} />
          </Form.LabeledInput>
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
    </div>
  );
};
