// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';

/**
 * This holds the value for a toggle switch which appears when we select volumes for a Frame.
 * It true, selecting the volume will also automatically select all bounding surfaces of the volume.
 */
const autoSelectSurfacesState = atomFamily((projectId: string) => atom(true));

export function useAutoSelectSurfaces(projectId: string) {
  return useAtom(autoSelectSurfacesState(projectId));
}
export function useAutoSelectSurfacesValue(projectId: string) {
  return useAtomValue(autoSelectSurfacesState(projectId));
}
export function useSetAutoSelectSurfaces(projectId: string) {
  return useSetAtom(autoSelectSurfacesState(projectId));
}
