// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as auth0 from 'auth0-js';
import axios from 'axios';

import * as frontendpb from '../proto/frontend/frontend_pb';

import { AuthState } from './AuthInfoCallback';
import { getAuthInfoV2, setAuthInfoV2 } from './AuthInfoCallbackV2';
import { authClientId, authTenant } from './RuntimeParams';
import { getSessionStorageData } from './browserStorage';
import * as jwt from './jwt';
import { locationOriginRoute, routes } from './navigation';
import { Logger } from './observability/logs';
import * as rpc from './rpc';
import { isStorybookEnv } from './testing/utils';

const logger = new Logger('AuthV2');

export interface OobChallengeResponse {
  'challenge_type': 'oob';
  'oob_code': string;
  'binding_method': 'prompt';
}

export interface OobMfaInStorage {
  oob: OobChallengeResponse,
  timestamp: number,
}

export const REDIRECT_AFTER_LOGIN_STORAGE_KEY = 'redirectAfterLogin';

export const REFRESH_TOKEN_KEY = 'lcRefreshToken';
export const REFRESH_TOKEN_COUNTER_KEY = 'lcRefreshTokenCounter';

const TEMP_EMAIL_KEY = 'temp-login-email';

/**
 * Saves the email that's entered on the login page to the browser storage. It can be used in the
 * Forgot/Reset password pages and also as a storage key for saving Oob MFA code received during
 * MFA login.
  */
export function setTempEmail(email: string) {
  return localStorage.setItem(TEMP_EMAIL_KEY, email);
}

/** Retreives the email from the browser storage that was previously entered on the login page */
export function getTempEmail() {
  return localStorage.getItem(TEMP_EMAIL_KEY) ?? '';
}

export function getLastOobForUserStorageKey() {
  const email = getTempEmail();
  return email ? `last-oob-mfa:${email}` : null;
}

/** When a user tries to login and have SMS-based MFA, they will receive an SMS and Auth0 will
 * generate an oob response. We need to save that oob into the browser storage so that we can
 * retreive it if the user refreshes the page in a 5-min interval.
 * That way we'll avoid sending them another SMS if it's not necessary.
 * If the user logs in from scratch in that 5-min interval, thay may still receive the same SMS but
 * we can't avoid that because the request that sends the SMS is the same that generates the oob
 * and we need a new oob for every new login attempt.
 */
export function setLastOobForUser(data: OobMfaInStorage) {
  const userKey = getLastOobForUserStorageKey();
  if (userKey) {
    sessionStorage.setItem(userKey, JSON.stringify(data));
  }
}

export function getLastOobForUser() {
  const userKey = getLastOobForUserStorageKey();
  if (userKey) {
    return getSessionStorageData<OobMfaInStorage | null>(userKey, null);
  }
  return null;
}

export function removeLastOobForUser() {
  const userKey = getLastOobForUserStorageKey();
  if (userKey) {
    sessionStorage.removeItem(userKey);
  }
}

// Initialize client for login/logout.
//
// NOTE: We use auth0.js instead of auth0-spa-js as our JS client SDK for Auth0.
// Not 100% sure, but we think the reasons for this decision are:
// - auth0-spa-js was intended to be used for the Universal Login types of Auth0
// authentication which we don't use.
// - auth0-js offered more customization for self-hosted logins (like ours in
// the username/password case).
//
// We should revisit this decision at some point (perhaps as part of LC-18937);
// these tradeoffs may have changed.
export const webAuth = new auth0.WebAuth({
  domain: authTenant,
  clientID: authClientId,
  responseType: 'token id_token',
});

export function logout() {
  jwt.clearSession();
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_COUNTER_KEY);
  webAuth.logout({
    returnTo: locationOriginRoute(routes.login),
  });
}

export const ACCESS_DENIED_ERROR = 'Access Denied, ' +
  'if you were invited, check your email for an activation link';

/**
  Validates if a user was invited to the platform

  This is called for user-visible login dialog for initial authentication
*/
async function validateInvitation(jwtData: jwt.Jwt | null): Promise<frontendpb.UserStatus> {
  if (jwtData == null) {
    return frontendpb.UserStatus.DISABLED;
  }
  // We check if the user was invited before we proceed to the platform
  const email = jwtData!.email;
  const req = new frontendpb.ValidateUserInviteRequest({ email, newUserLogIn: true });

  try {
    const reply = await rpc.callRetry(
      'ValidateUserInvitation',
      rpc.client.validateUserInvitation,
      req,
    );
    return reply.status;
  } catch (err) {
    logger.error(err);
    throw Error(err);
  }
}

/**
  Handle an authentication result.

  This is called for any successful authentication, whether via the
  user-visible login dialog for initial authentication or periodically via
  background session refresh.
*/
export function handleAuthenticatedV2(session: string) {
  if (isStorybookEnv()) {
    return Promise.reject(new Error('Storybook'));
  }

  if (!session) {
    logger.error('empty session');
  }
  // Set Luminary auth cookie for future API requests
  jwt.setSession(session);
  const jwtToken = jwt.parseJwt(session);

  // If we login successfully, we don't want to keep any references to old OOB codes as they might
  // interfere with new login attempts otherwise.
  removeLastOobForUser();

  setAuthInfoV2({
    authState: AuthState.AUTHENTICATION_PENDING,
    jwt: jwtToken,
  });
  return validateInvitation(jwtToken).then((userStatus) => {
    if (userStatus === frontendpb.UserStatus.PENDING_REGISTRATION) {
      setAuthInfoV2({
        authState: AuthState.AUTHENTICATION_PENDING_REGISTRATION,
        jwt: jwtToken,
      });
    } else if (userStatus === frontendpb.UserStatus.DISABLED) {
      jwt.clearSession();
      setAuthInfoV2({
        authState: AuthState.UNAUTHENTICATED,
        jwt: null,
      });
    } else if (userStatus === frontendpb.UserStatus.ENABLED) {
      setAuthInfoV2({
        authState: AuthState.AUTHENTICATED,
        jwt: jwtToken,
      });
    } else if (userStatus === frontendpb.UserStatus.PENDING_REACCEPT_TERMS) {
      setAuthInfoV2({
        authState: AuthState.AUTHENTICATION_PENDING_REACCEPT_TERMS,
        jwt: jwtToken,
      });
    }
  }).catch((error) => {
    logger.error(`Error on validate invitation: ${error}`);
    throw Error(error);
  });
}

/**
  Handle a request for a new token

  This is called for a new token based on a refresh token.
  If not possible, returns an empty string
*/
export async function getNewLoginToken(): Promise<string> {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  const currentCounter = localStorage.getItem(REFRESH_TOKEN_COUNTER_KEY);
  // each token lasts 12 hours, so at the end of 30 days clear everything
  if (Number(currentCounter) > 60) {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    // TODO - give some kind of warning to the user that their login is about to expire and
    //        prompt them to reauthenticate rather than hard-logging them out
    return '';
  }
  if (!refreshToken) {
    // no refresh token, skipping new login
    return '';
  }
  const options = {
    method: 'POST',
    url: `https://${authTenant}/oauth/token`,
    headers: { 'content-type': 'application/json' },
    data: {
      grant_type: 'refresh_token',
      client_id: authClientId,
      refresh_token: refreshToken,
    },
  };

  return axios.request(options).then((response: { data: any }) => {
    console.log('Refreshed token:', response.data);
    if (response.data.id_token) {
      localStorage.setItem(REFRESH_TOKEN_COUNTER_KEY, String(Number(currentCounter) + 1));
    }
    return response.data.id_token;
  }).catch((error) => {
    console.error(error);
  });
}

/**
 * Refreshes the login token using the refresh token.
 * @returns A promise that resolves to the new login token, or null if refresh failed.
 */
export async function refreshLoginToken(): Promise<string | null> {
  if (!localStorage.getItem(REFRESH_TOKEN_KEY)) {
    return null;
  }

  try {
    const newLoginToken = await getNewLoginToken();
    if (!newLoginToken) {
      jwt.clearSession();
      setAuthInfoV2({
        authState: AuthState.UNAUTHENTICATED,
        jwt: null,
      });
      return null;
    }
    await handleAuthenticatedV2(newLoginToken);
    return newLoginToken;
  } catch (err) {
    console.error('Failed to refresh login token:', err);
    return null;
  }
}

/**
  First-time initialization of the Auth module. To be called *once* at index
  page load.
*/
export function initV2() {
  // If we are AUTHENTICATED at the time Auth.init is called, we had a cached JWT.
  // This means we can't be certain of the validity of the user within our system.
  // For example, we don't know if the user has been invited or accepted the terms.
  // We only know that they were able to successfully log in with auth0.
  // We must call the handleAuthenticatedV2 code with the validateInvitation,
  // once at the beginning to handle edge cases like LC-5290.
  if (getAuthInfoV2().authState === AuthState.AUTHENTICATED) {
    handleAuthenticatedV2(jwt.getSession()).catch(() => { });
  } else if (getAuthInfoV2().authState === AuthState.AUTHENTICATION_EXPIRED) {
    refreshLoginToken().catch((err) => console.error('Failed to refresh token during init:', err));
  } else if (getAuthInfoV2().authState === AuthState.UNAUTHENTICATED) {
    jwt.clearSession();
    setAuthInfoV2({
      authState: AuthState.UNAUTHENTICATED,
      jwt: null,
    });
  }
}
