// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useMemo } from 'react';

import { JobStatusType } from '../../proto/base/base_pb';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useWorkflowMap } from '../../recoil/workflowState';

// This gets the status type for all workflows and all jobs for the workloads within a project.
// The returned object has the form of { [workflowId | jobId]: JobStatusType | undefined, ... }
export default function useProjectWorkflowAndJobStatusMap(projectId: string) {
  const projectMetadata = useProjectMetadata(projectId);
  const workflowIds = useMemo(
    () => (projectMetadata?.workflow || []).map((workflow) => workflow.workflowId),
    [projectMetadata],
  );
  const workflowMap = useWorkflowMap(projectId, workflowIds);

  return useMemo(() => {
    const statuses: Record<string, JobStatusType | undefined> = {};

    workflowIds.forEach((id) => {
      const workflowReply = workflowMap[id];

      if (workflowReply) {
        statuses[id] = workflowReply.status?.typ;

        Object.entries(workflowMap).forEach(([jobId, job]) => {
          statuses[jobId] = job?.status?.typ;
        });
      }
    });
    return statuses;
  }, [workflowIds, workflowMap]);
}
