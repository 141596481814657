// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVType } from '@luminarycloudinternal/lcvis';

import assert from '../../../../assert';
import { RgbColor } from '../../../../designSystem';
import { SimAnnotationParam } from '../../../../simAnnotationUtils';
import { LcvModule } from '../../../types';
import { LcvObjectList } from '../../base/LcvObjectList';

import { LcvSimAnnotation } from './LcvSimAnnotation';

export class LcvMonitorPointList extends LcvObjectList implements LcvSimAnnotation {
  constructor(lcv: LcvModule, sessionHandle: number, size: number) {
    super(
      lcv,
      lcv.newAnnotation(sessionHandle, 'monitor_points', 0).annotation,
      sessionHandle,
      size,
    );
  }

  updateItemAtIndex(index: number, param: SimAnnotationParam): void {
    assert(param.typ === 'MonitorPoint', () => {
      console.warn(param);
      return 'Param is not a monitor point';
    });
    const { x, y, z } = param.point.center;
    this.setParamAtIndex(index, 'points', LCVType.kLCVDataTypeFloat3, [x, y, z]);
  }

  showItemAtIndex(index: number): void {
    this.setParamAtIndex(index, 'visible', LCVType.kLCVDataTypeUint, 1);
  }

  hideItemAtIndex(index: number): void {
    this.setParamAtIndex(index, 'visible', LCVType.kLCVDataTypeUint, 0);
  }

  setColorAtIndex(index: number, color: RgbColor): void {
    this.setParamAtIndex(index, 'colors', LCVType.kLCVDataTypeFloat3, color);
  }
}
