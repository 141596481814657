// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const DiskArrowUpIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="diskArrowUp"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M12,0
           C5.37,0 0,5.37 0,12
           C0,18.63 5.37,24 12,24
           C18.63,24 24,18.63 24,12
           C24,5.37 18.63,0 12,0Z
           M16.5,12
           C16.08,12 15.705,11.835 15.435,11.565
           L13.5,9.615
           L13.5,18
           C13.5,18.825 12.825,19.5 12,19.5
           C11.175,19.5 10.5,18.825 10.5,18
           L10.5,9.615
           L8.565,11.565
           C8.295,11.835 7.92,12 7.5,12
           C6.675,12 6,11.325 6,10.5
           C6,10.08 6.165,9.705 6.435,9.435
           L10.935,4.935
           C11.205,4.665 11.58,4.5 12,4.5
           C12.42,4.5 12.795,4.665 13.065,4.935
           L17.565,9.435
           C17.835,9.705 18,10.08 18,10.5
           C18,11.325 17.325,12 16.5,12Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
