// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 80;
const baseHeight = 79;
export const CylinderIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="cylinder"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeMiterlimit: 2,
        fill: getColor(0),
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0,14.271C0,12.972 0.652,11.439 2.465,9.738C4.283,8.033 7.069,6.366 10.749,4.906C18.093,
           1.993 28.433,0.129 40,0.129C51.567,0.129 61.907,1.993 69.251,4.906C72.931,6.366 75.717,
           8.033 77.535,9.738C79.348,11.439 80,12.972 80,14.271C80,15.571 79.348,17.104 77.535,
           18.805C75.717,20.51 72.931,22.177 69.251,23.636C61.907,26.549 51.567,28.414 40,
           28.414C28.433,28.414 18.093,26.549 10.749,23.636C7.069,22.177 4.283,20.51 2.465,
           18.805C0.652,17.104 0,15.571 0,14.271ZM80,24.206C77.656,26.038 74.704,27.621 71.358,
           28.948C63.19,32.188 52.103,34.129 40,34.129C27.897,34.129 16.809,32.188 8.642,
           28.948C5.296,27.621 2.344,26.038 0,24.206L0,63.005C0,71.767 17.909,78.871 40,
           78.871C62.091,78.871 80,71.768 80,63.005L80,24.206Z"
      />
    </svg>
  );
});
