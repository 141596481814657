// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 25;
const baseHeight = 15;
export const ChevronUpIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="chevronUp"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M24.275,10.725
           L14.275,0.725
           C13.825,0.275 13.2,0 12.5,0
           C11.8,0 11.175,0.275 10.725,0.725
           L0.725,10.725
           C0.275,11.175 0,11.8 0,12.5
           C0,13.875 1.125,15 2.5,15
           C3.2,15 3.825,14.725 4.275,14.275
           L12.5,6.025
           L20.725,14.25
           C21.175,14.725 21.8,15 22.5,15
           C23.875,15 25,13.875 25,12.5
           C25,11.8 24.725,11.175 24.275,10.725Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
