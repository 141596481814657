// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const LinkIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="link"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M7.496,17.99
           C7.915,17.99 8.29,17.825 8.56,17.555
           L17.555,8.56
           C17.824,8.29 17.989,7.916 17.989,7.496
           C17.989,6.671 17.315,5.997 16.49,5.997
           C16.07,5.997 15.696,6.162 15.426,6.431
           L6.431,15.426
           C6.161,15.696 5.996,16.071 5.996,16.49
           C5.996,17.315 6.671,17.99 7.496,17.99Z
           M13.267,14.961
           L9.609,18.619
           L8.11,20.118
           L8.095,20.103
           C7.556,20.643 6.821,21.003 5.996,21.003
           C4.347,21.003 2.998,19.653 2.998,18.004
           C2.998,17.18 3.358,16.445 3.898,15.906
           L3.883,15.891
           L5.382,14.392
           L9.04,10.734
           C8.545,10.584 8.035,10.494 7.496,10.494
           C5.847,10.494 4.362,11.184 3.283,12.278
           L3.253,12.248
           L1.754,13.747
           L1.784,13.777
           C0.69,14.856 0,16.34 0,17.989
           C0,21.302 2.683,23.986 5.996,23.986
           C7.645,23.986 9.13,23.296 10.209,22.202
           L10.239,22.232
           L11.738,20.733
           L11.708,20.703
           C12.802,19.623 13.492,18.139 13.492,16.49
           C13.492,15.966 13.402,15.456 13.267,14.961Z
           M23.986,5.997
           C23.986,2.684 21.302,0 17.989,0
           C16.34,0 14.856,0.69 13.777,1.784
           L13.747,1.754
           L12.248,3.253
           L12.278,3.283
           C11.198,4.363 10.494,5.847 10.494,7.496
           C10.494,8.035 10.584,8.53 10.719,9.025
           L14.376,5.367
           L15.876,3.868
           L15.891,3.883
           C16.43,3.343 17.165,2.983 17.989,2.983
           C19.638,2.983 20.988,4.333 20.988,5.982
           C20.988,6.806 20.628,7.541 20.088,8.08
           L20.103,8.095
           L18.604,9.595
           L14.961,13.267
           C15.456,13.402 15.966,13.492 16.49,13.492
           C18.139,13.492 19.623,12.803 20.703,11.708
           L20.733,11.738
           L22.232,10.239
           L22.202,10.209
           C23.296,9.13 23.986,7.661 23.986,5.997Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
