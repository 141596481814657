// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { copiedName } from '../../../lib/name';
import { newNodeId } from '../../../lib/projectDataUtils';
import { useSetPlotNodes } from '../../../recoil/plotNodes';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';

/**
 * @returns a callback which takes an id and duplicates the XY plot row with that id.
 */
export const useCopyXYPlot = () => {
  const { projectId } = useProjectContext();
  const { setSelection } = useSelectionContext();

  const setPlotNodes = useSetPlotNodes(projectId);

  const copyXYPlot = useCallback((id: string) => {
    const newId = newNodeId();
    setPlotNodes((prevValue) => {
      const newPlotNodes = prevValue.clone();
      const plot = newPlotNodes.plots.find((item) => (item.id === id));
      if (plot) {
        const newPlot = plot.clone();
        newPlot.name = copiedName(plot.name);
        newPlot.id = newId;
        newPlotNodes.plots.push(newPlot);
      }
      return newPlotNodes;
    });
    setSelection([newId]);
  }, [setPlotNodes, setSelection]);

  return copyXYPlot;
};
