// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useState } from 'react';

import { BaseProps, CollapsiblePanel } from './CollapsiblePanel';

export interface AutoCollapsiblePanelProps extends BaseProps {
  initialCollapsed?: boolean;
  children: ReactNode;
}

export const AutoCollapsiblePanel = (props: AutoCollapsiblePanelProps) => {
  const { initialCollapsed = false } = props;

  const [collapsed, setCollapsed] = useState(initialCollapsed);

  return (
    <CollapsiblePanel
      {...props}
      collapsed={collapsed}
      onToggle={() => setCollapsed(!collapsed)}
    />
  );
};
