// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 50;
const baseHeight = 50;
export const BrowserShieldIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="browserShield"
      height={finalHeight}
      ref={ref}
      style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round' }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M49,21
           L49,9
           C49,6.878 48.157,4.843 46.657,3.343
           C45.157,1.843 43.122,1 41,1
           L9,1
           C6.878,1 4.843,1.843 3.343,3.343
           C1.843,4.843 1,6.878 1,9
           L1,41
           C1,43.122 1.843,45.157 3.343,46.657
           C4.843,48.157 6.878,49 9,49
           L25,49
           M1,16
           L49,16"
        style={{ fill: 'none', fillRule: 'nonzero', stroke: getColor(0), strokeWidth: '2px' }}
      />
      <path
        d="M38.568,47.558
           C36.246,47.094 34.157,45.839 32.655,44.007
           C31.154,42.176 30.333,39.881 30.333,37.513
           L30.333,34.25
           C30.333,32.858 30.886,31.522 31.871,30.538
           C32.856,29.553 34.191,29 35.583,29
           L43.75,29
           C45.142,29 46.478,29.553 47.462,30.538
           C48.447,31.522 49,32.858 49,34.25
           L49,37.513
           C49,39.881 48.179,42.176 46.678,44.007
           C45.177,45.839 43.087,47.093 40.765,47.558
           C40.04,47.703 39.293,47.703 38.568,47.558Z"
        style={{
          fill: getColor(1),
          fillRule: 'nonzero',
          stroke: getColor(0),
          strokeWidth: '2px',
          strokeLinecap: 'butt',
        }}
      />
      <circle
        cx="9.5"
        cy="8.5"
        r="1.5"
        style={{ fill: getColor(0) }}
      />
      <circle
        cx="16.5"
        cy="8.5"
        r="1.5"
        style={{ fill: getColor(0) }}
      />
      <circle
        cx="23.5"
        cy="8.5"
        r="1.5"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
