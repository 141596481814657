// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { RenderElementProps } from 'slate-react';

import assert from '../../lib/assert';
import { colors } from '../../lib/designSystem';
import { PreviewElement, Type } from '../../lib/expressionInput/slate';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(() => createStyles({
  root: {
    color: colors.neutral650,
  },
}), { name: 'PreviewElement' });

export function Preview(props: RenderElementProps) {
  const { attributes, element, children } = props;

  assert(element.type === Type.Preview, 'Element is not a preview');
  const previewElement = element as PreviewElement;
  const classes = useStyles();

  return (
    <span
      {...attributes}
      className={classes.root}
      contentEditable={false}
      data-slate-inline>
      {previewElement.preview}
      {children}
    </span>
  );
}
