// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

export interface ElementDetails {
  offset: number; width: number
}

/**
 * Based on the shift, current element, and available elements,
 * this function performs calculations to determine which element
 * should be swapped with the currently dragged element.
 */
export function findClosestElementIdentifier(
  shift: number,
  currentElementIdentifier: string,
  availableElements: Map<string, ElementDetails>,
) {
  const currentElementDetails = availableElements.get(currentElementIdentifier)!;

  const getItemDistance = (currentItem: ElementDetails) => {
    const currentItemCenter = currentItem.offset + currentItem.width / 2;
    let currentItemPivot = currentElementDetails.offset + currentItem.width / 2;

    if (currentItem.offset > currentElementDetails.offset) {
      currentItemPivot = currentElementDetails.offset + currentElementDetails.width;
    } else if (currentItem.offset < currentElementDetails.offset) {
      currentItemPivot = currentElementDetails.offset;
    }

    const result = currentItemPivot - currentItemCenter + shift;

    if (currentItem.offset < currentElementDetails.offset) {
      return -result;
    }

    return result;
  };

  return [...availableElements.entries()]
    .reduce(([previousIdentifier, previousDetails], [currentIdentifier, currentDetails]) => {
      const previousDistance = getItemDistance(previousDetails);
      const currentDistance = getItemDistance(currentDetails);

      if (currentDistance > 0 && (currentDistance < previousDistance || previousDistance < 0)) {
        return [currentIdentifier, currentDetails];
      }

      return [previousIdentifier, previousDetails];
    }, [currentElementIdentifier, currentElementDetails])[0];
}
