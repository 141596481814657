// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { CommonMenuItem } from '../../../lib/componentTypes/menu';
import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { colors } from '../../../lib/designSystem';
import { isGroupVisible, unwrapSurfaceIds } from '../../../lib/entityGroupUtils';
import { isFarfield } from '../../../lib/farfieldUtils';
import { isGeomHealthId } from '../../../lib/geometryHealthUtils';
import { getAllAttachedDomains, getAllAttachedSurfaceIds } from '../../../lib/motionDataUtils';
import { formatDescendantCount } from '../../../lib/simulationTree/utils';
import {
  deleteTreeNodeMenuItem,
  groupTreeNodeMenuItem,
  ungroupTreeNodeMenuItem,
} from '../../../lib/treeUtils';
import { useEntityGroupData, useNumDescendants } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import { useLcvisVisibilityMapValue } from '../../../recoil/lcvis/lcvisVisibilityMap';
import { useShowRowChildrenCountValue } from '../../../recoil/simulationTree/showRowChildrenCount';
import { useToggleVisibility } from '../../../recoil/vis/useToggleVisibility';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { useIsGeometryView } from '../../../state/internal/global/currentView';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useTagsInteractiveGeometry } from '../../hooks/useInteractiveGeometry';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeGrouping } from '../../hooks/useNodeGrouping';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { useTagSurfaceIds } from '../../hooks/useTagSurfaceIds';
import { ContextMenuSection, TreeRow } from '../TreeRow';

const isTagVisible = (
  visibilityRealm: Map<string, boolean>,
  surfaceIds: string[],
) => surfaceIds.every((surfaceId) => visibilityRealm.get(surfaceId) || false);

// A row displaying information about a surface group.
export const SurfaceGroupTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;
  const { id: nodeId } = node;

  // == Context
  const { viewState, visibilityMap } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Recoil
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();
  const visibilityV2 = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const showRowChildren = useShowRowChildrenCountValue();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const isGeometryView = useIsGeometryView();
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteSurfaceGroupNode, postDeleteNodeIds } = useNodeDeletion();
  const {
    canGroup,
    canUngroup,
    groupEntities,
    ungroupEntities,
    groupableNodes,
  } = useNodeGrouping();
  const {
    removeTag,
    isRemoveTagDisabled,
    addVolumeToExistingTag,
    addSurfaceToExistingTag,
  } = useTagsInteractiveGeometry();

  const isTag = geometryTags.isTagId(nodeId);
  const tagSurfaces = useTagSurfaceIds(nodeId);

  const visibilityRealm = lcvisEnabled ? visibilityV2 : visibilityMap;
  const isVisible = isTag ?
    isTagVisible(visibilityRealm, tagSurfaces) :
    isGroupVisible(visibilityRealm, entityGroupData.groupMap, nodeId);

  const surfacesWithMotion = getAllAttachedSurfaceIds(
    simParam,
    { motion: 'moving' },
    geometryTags,
    entityGroupData,
  );
  const volumesWithMotion = getAllAttachedDomains(simParam, { motion: 'moving' }, geometryTags);

  const toggleIds = useMemo(() => {
    const isSelected = selectedNodeIds.includes(nodeId);

    const surfacesToHide = isTag ? tagSurfaces : [nodeId];

    // when tag is selected - hide its surfaces
    const unrolledSelectedNodeIds = isTag ?
      selectedNodeIds.flatMap((id) => (id === nodeId ? tagSurfaces : [id])) :
      selectedNodeIds;

    // Toggle all the selected IDs, if this ID is selected.
    const ids = (isSelected ? unrolledSelectedNodeIds : surfacesToHide);

    // Vis knows nothing about geometry health ids, but those ids are included as part of the
    // selection since both the heath check row and the sim tree row are selected.
    return ids.reduce((result, id) => {
      if (!isGeomHealthId(id)) {
        result.add(id);
      }
      return result;
    }, new Set<string>());
  }, [isTag, nodeId, selectedNodeIds, tagSurfaces]);
  const toggleVis = useToggleVisibility(toggleIds, isVisible);

  const visibilityControl = {
    show: isVisible,
    disabled: lcvisEnabled ? !lcvisReady : !viewState,
    isHovered: (rowHovered: boolean) => rowHovered,
    toggle: toggleVis,
  };

  const numDescendants = useNumDescendants(projectId, workflowId, jobId, nodeId);
  const descendantsLabel = showRowChildren ? formatDescendantCount(numDescendants) : '';

  const auxIcons = useMemo<IconSpec[]>(() => {
    // Find all the leaf surfaces in the group

    // Check for nodeId in the map, because there's a race condition where this component re-renders
    // after the group is removed from the entity map but before the group node is removed from the
    // control panel.
    const { groupMap } = entityGroupData;

    let groupSurfaces: string[] = [];
    let [volumesSomeIn, volumesSomeOut] = [false, false];

    if (geometryTags.isTagId(nodeId)) {
      const tagDomains = geometryTags.domainsFromTag(nodeId);

      volumesSomeIn = tagDomains.some((domain) => volumesWithMotion.has(domain));
      volumesSomeOut = tagDomains.some((domain) => !volumesWithMotion.has(domain));

      groupSurfaces = unwrapSurfaceIds([nodeId], geometryTags, entityGroupData);
    } else if (groupMap.has(nodeId)) {
      const group = groupMap.get(nodeId);
      groupSurfaces = groupMap.findDescendants(group.id);
    }

    // Determine if some member surfaces are attached and some are unattached
    const surfacesSomeIn = groupSurfaces.some((surface) => surfacesWithMotion.has(surface));
    const surfacesSomeOut = groupSurfaces.some((surface) => !surfacesWithMotion.has(surface));

    const someIn = surfacesSomeIn || volumesSomeIn;
    const someOut = surfacesSomeOut || volumesSomeOut;

    if (someIn) {
      return [{ name: 'rotatingDots', color: colors.citronGreen600, opacity: someOut ? 0.5 : 1 }];
    }

    return [];
  }, [entityGroupData, geometryTags, nodeId, surfacesWithMotion, volumesWithMotion]);

  const deleteRow = useCallback(() => {
    if (deleteSurfaceGroupNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteSurfaceGroupNode, node.id, postDeleteNodeIds]);

  const addItemOptions = useMemo<CommonMenuItem[]>(() => ([
    {
      label: 'Add Volume',
      onClick: () => {},
      items: staticVolumes.map((volume) => ({
        label: volume.defaultName,
        onClick: async () => {
          await addVolumeToExistingTag(node.id, volume.id);
        },
      })),
    }, {
      label: 'Add Surface',
      onClick: () => {},
      items: staticVolumes.flatMap((volume) => [...volume.bounds].map((surfaceId) => {
        const surfaceName = entityGroupData.groupMap.has(surfaceId) ?
          entityGroupData.groupMap.get(surfaceId).name :
          'unknown';

        return {
          label: surfaceName,
          onClick: async () => {
            await addSurfaceToExistingTag(node.id, surfaceId);
          },
        };
      })),
    }]), [
    addSurfaceToExistingTag,
    addVolumeToExistingTag,
    entityGroupData.groupMap,
    node.id,
    staticVolumes,
  ]);

  const deleteItemOption = useMemo(() => {
    const deleteTagRow = async () => {
      const isSelected = selectedNodeIds.includes(nodeId);
      const ids = (isSelected ? selectedNodeIds : [nodeId]);
      await removeTag(ids);
    };

    return deleteTreeNodeMenuItem(deleteTagRow, isRemoveTagDisabled);
  }, [isRemoveTagDisabled, nodeId, removeTag, selectedNodeIds]);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];
    if (isGeometryView) {
      if (!isRemoveTagDisabled && isTag) {
        sections.push({ section: 'crud', menuItems: [deleteItemOption, ...addItemOptions] });
      }
      return sections;
    }

    if (isFarfield(node.id)) {
      const disabled = !canDelete(node.type, node.id);
      const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
      sections.push({ section: 'crud', menuItems: [deleteItem] });
    }

    const nodeIdsToGroup = groupableNodes(node.id);
    if (canGroup(nodeIdsToGroup, entityGroupData)) {
      const groupItem = groupTreeNodeMenuItem(() => groupEntities(nodeIdsToGroup));
      sections.push({ section: 'grouping', menuItems: [groupItem] });
    }

    if (canUngroup(node, entityGroupData)) {
      const ungroupItem = ungroupTreeNodeMenuItem(() => ungroupEntities(node.id));
      sections.push({ section: 'grouping', menuItems: [ungroupItem] });
    }

    return sections;
  }, [
    canDelete,
    canGroup,
    canUngroup,
    deleteRow,
    entityGroupData,
    groupEntities,
    groupableNodes,
    node,
    ungroupEntities,
    isRemoveTagDisabled,
    isGeometryView,
    isTag,
    addItemOptions,
    deleteItemOption,
  ]);

  return (
    <TreeRow
      {...props}
      auxIcons={auxIcons}
      canMultiSelect
      getExtraContextMenuItems={getExtraContextMenuItems}
      label={node.name}
      primaryIcon={isTag ? { name: 'groupAction' } : { name: 'group' }}
      propertiesControl={!isGeometryView}
      renaming={renaming}
      sublabel={descendantsLabel || undefined}
      visibility={visibilityControl}
    />
  );
};
