// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { HTMLProps } from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      color: colors.lowEmphasisText,
      fontSize: '0.8125rem',
      fontWeight: 'normal',
      lineHeight: 1.2,
    },
  }),
  { name: 'HelperText' },
);

export interface HelperTextProps extends HTMLProps<HTMLDivElement> { }

/**
 * Annotation that can be added underneath a form field to provide additional
 * instructions, display error states, etc.
 */
const HelperText = (props: HelperTextProps) => {
  const classes = useStyles();

  return (
    <div {...props} className={cx(classes.root, props.className)}>
      {props.children}
    </div>
  );
};

export default HelperText;
