// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import { RecoilSync } from 'recoil-sync';

import { getSessionStoreId, writeSessionState } from '../../lib/recoilSync';

// Recoil sync to write states to user session state kv store
const UserStateSync = (props: { children: ReactNode }) => (
  <RecoilSync
    storeKey={getSessionStoreId()}
    // Writes back all states that have changed in the current recoil snapshot
    write={writeSessionState()}>
    {props.children}
  </RecoilSync>
);

export default UserStateSync;
