// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 21;
export const WarningIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="warning"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M23.761,18.745
           L23.776,18.73
           L13.282,0.741
           L13.267,0.756
           C13.012,0.306 12.547,0.006 11.993,0.006
           C11.438,0.006 10.988,0.306 10.719,0.756
           L10.704,0.741
           L0.21,18.73
           L0.225,18.745
           C0.09,18.97 0,19.21 0,19.495
           C0,20.319 0.675,20.994 1.499,20.994
           L22.487,20.994
           C23.311,20.994 23.986,20.319 23.986,19.495
           C23.986,19.21 23.896,18.97 23.761,18.745Z
           M13.492,17.981
           L10.494,17.981
           L10.494,14.982
           L13.492,14.982
           L13.492,17.981Z
           M13.492,13.483
           L10.494,13.483
           L10.494,5.988
           L13.492,5.988
           L13.492,13.483Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
