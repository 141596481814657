// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const PointerCubeOutlinedIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="pointerCubeOutlined"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0.187,4.496
           L9.253,0
           L18.319,4.496
           L18.319,13.472
           L16.924,12.976
           L16.924,5.712
           L9.95,9.484
           L9.95,17.638
           L12.523,16.362
           L12.991,17.678
           L9.564,19.378
           L9.253,19.53
           L0.187,15.034
           L0.187,4.496Z
           M15.747,4.772
           L9.253,1.546
           L2.759,4.772
           L9.253,8.284
           L15.747,4.772Z
           M8.556,9.484
           L1.582,5.712
           L1.582,14.18
           L8.556,17.638
           L8.556,9.484Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M23.399,21.656
           C23.661,21.89 23.799,22.373 23.799,22.759
           C23.799,23.366 23.178,23.986 22.572,23.986
           C22.185,23.986 21.703,23.848 21.482,23.586
           L17.166,19.297
           L15.993,24
           L12.049,12.898
           C11.833,12.232 12.007,12.003 12.711,12.236
           L23.813,16.18
           L19.11,17.339
           L23.399,21.656Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
