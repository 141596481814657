// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/api/v0/luminarycloud/simulation_template/simulation_template.proto (package luminary.proto.api.v0.luminarycloud.simulation_template, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { SimulationParam } from "../../../../client/simulation_pb.js";

/**
 * Represents a simulation template object.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation_template.SimulationTemplate
 */
export const SimulationTemplate = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation_template.SimulationTemplate",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "parameters", kind: "message", T: SimulationParam },
    { no: 4, name: "create_time", kind: "message", T: Timestamp },
    { no: 5, name: "update_time", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation_template.CreateSimulationTemplateRequest
 */
export const CreateSimulationTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation_template.CreateSimulationTemplateRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "parameters", kind: "message", T: SimulationParam },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation_template.CreateSimulationTemplateResponse
 */
export const CreateSimulationTemplateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation_template.CreateSimulationTemplateResponse",
  () => [
    { no: 1, name: "simulation_template", kind: "message", T: SimulationTemplate },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation_template.ListSimulationTemplatesRequest
 */
export const ListSimulationTemplatesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation_template.ListSimulationTemplatesRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation_template.ListSimulationTemplatesResponse
 */
export const ListSimulationTemplatesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation_template.ListSimulationTemplatesResponse",
  () => [
    { no: 1, name: "simulation_templates", kind: "message", T: SimulationTemplate, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation_template.GetSimulationTemplateRequest
 */
export const GetSimulationTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation_template.GetSimulationTemplateRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation_template.GetSimulationTemplateResponse
 */
export const GetSimulationTemplateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation_template.GetSimulationTemplateResponse",
  () => [
    { no: 1, name: "simulation_template", kind: "message", T: SimulationTemplate },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation_template.UpdateSimulationTemplateRequest
 */
export const UpdateSimulationTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation_template.UpdateSimulationTemplateRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "parameters", kind: "message", T: SimulationParam, opt: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation_template.UpdateSimulationTemplateResponse
 */
export const UpdateSimulationTemplateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation_template.UpdateSimulationTemplateResponse",
  () => [
    { no: 1, name: "simulation_template", kind: "message", T: SimulationTemplate },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation_template.DeleteSimulationTemplateRequest
 */
export const DeleteSimulationTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation_template.DeleteSimulationTemplateRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation_template.ValidateSimulationTemplateRequest
 */
export const ValidateSimulationTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation_template.ValidateSimulationTemplateRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.simulation_template.ValidateSimulationTemplateResponse
 */
export const ValidateSimulationTemplateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.simulation_template.ValidateSimulationTemplateResponse",
  () => [
    { no: 1, name: "is_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "messages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

