// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ParamGroupName, paramGroupDesc } from '../../../SimulationParamDescriptor';
import { useGeneral } from '../../../model/hooks/useGeneral';
import { useTime } from '../../../model/hooks/useTime';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { useSimulationParamScope } from '../../../state/external/project/simulation/paramScope';
import { ParamForm } from '../../ParamForm';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import PropertiesSection from '../PropertiesSection';

export const TransientSettingsPropPanel = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const {
    flowBehavior,
  } = useGeneral(projectId, workflowId, jobId, readOnly);
  const { time, setTime } = useTime(projectId, workflowId, jobId, readOnly);
  const propClasses = useCommonTreePropsStyles();

  if (flowBehavior !== simulationpb.FlowBehavior.TRANSIENT) {
    return null;
  }

  return (
    <>
      <div className={propClasses.properties}>
        <PropertiesSection>
          <ParamForm<simulationpb.Time>
            group={paramGroupDesc[ParamGroupName.Time]}
            onUpdate={setTime}
            paramScope={paramScope}
            proto={time}
            readOnly={readOnly}
          />
        </PropertiesSection>
      </div>
    </>
  );
};
