// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import { ConnectError } from '@connectrpc/connect';
import { FilterProgressInfo, PipelineUrlsStreamingCallbacks, RenderDataUrls } from '@luminarycloudinternal/lcvis';

import * as basepb from '../proto/base/base_pb';
import * as simulationpb from '../proto/client/simulation_pb';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as vispb from '../proto/vis/vis_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';
import { ColorMap } from '../pvproto/ParaviewRpc';
import { StaticVolume } from '../recoil/volumes';

import GroupMap from './GroupMap';
import { listToPv, toProto } from './Vector';
import { newAdFloat, newScalarAdVector, vec3ToAdVec3 } from './adUtils';
import { isSurfaceBoundaryConditionType } from './boundaryConditionUtils';
import { IconName } from './componentTypes/svgIcon';
import { EntityGroupMap } from './entityGroupMap';
import { isImposter } from './imposterFilteringUtils';
import { Bounds, LcVisVisibilityMap } from './lcvis/types';
import { fetchLink } from './navigation';
import { fromBigInt } from './number';
import { Logger } from './observability/logs';
import { traverseTreeNodes, updateTreeNodes } from './paraviewUtils';
import * as random from './random';
import * as rpc from './rpc';
import { NodeType, SimulationTreeNode } from './simulationTree/node';
import { addInfo } from './transientNotification';

const logger = new Logger('visUtils');

// EditSource is used by a filter-parameter setting panel that utilizes both an
// HTML form and a Paraview widget. FORM means that the current parameter value
// originates from user inputs in the HTML form. PARAVIEW means that the current
// parameter value comes from the paraview widget.

export enum EditSource {
  FORM = 1,
  PARAVIEW = 2
}
// State about ongoing tree-node edit session.

export interface EditState {
  // True if creating a new node. False if editing an existing node.
  newNode: boolean;
  // If newNode, then the parent of the new node.
  // Else, the node being edited.
  nodeId: string;
  // The starting parameters for the filter.
  param: ParaviewRpc.TreeNodeParam;
  // The starting display properties for the filter extract.
  displayProps: ParaviewRpc.DisplayProps;
  // Indicates where the value in param came from.  If editSource=FORM, and if
  // the parameter-setting panel uses a widget, then the widget state must be
  // updated to match the HTML form. If editSource=PARAVIEW, then the HTML form
  // must be updated to match the widget value.
  editSource: EditSource;
}

export const VIEWER_PADDING = 8;
export const OVERLAY_CARD_WIDTH = '296px';

export const DEFAULT_FILTER_ROOT = 'Reader:00000001';

export const VIS_FILTER_CREATION_ERROR = `An error occured while creating the visualization filter.
  Please update or remove the filter, or contact support for assistance.`;

export const VIS_FILTER_EMPTY_WARNING = `Please fix issues with upstream filters to enable this
  filter.`;

export interface ViewStateVisibilityStats {
  anyHidden: boolean;
  anyVisible: boolean;
  allHidden: boolean;
  allVisible: boolean;
  filterCount: number;
}

export const ASYNC_VIS_NODES = [
  ParaviewRpc.TreeNodeType.MULTI_SLICE,
  ParaviewRpc.TreeNodeType.STREAMLINES,
  ParaviewRpc.TreeNodeType.SURFACE_L_I_C,
] as const;

export type AsyncVisNodeType = typeof ASYNC_VIS_NODES[number];

export function getFilterNodeIcon(type: ParaviewRpc.TreeNodeType): IconName {
  switch (type) {
    case ParaviewRpc.TreeNodeType.CONTOUR:
      return 'contour';
    case ParaviewRpc.TreeNodeType.THRESHOLD:
      return 'verticalControls';
    case ParaviewRpc.TreeNodeType.CLIP:
      return 'scissors';
    case ParaviewRpc.TreeNodeType.SLICE:
      return 'scalpel';
    case ParaviewRpc.TreeNodeType.STREAM_TRACER:
    case ParaviewRpc.TreeNodeType.STREAMLINES:
      return 'streamVortices';
    case ParaviewRpc.TreeNodeType.GLYPH:
      return 'streamArrows';
    case ParaviewRpc.TreeNodeType.MULTI_SLICE:
      return 'layers';
    case ParaviewRpc.TreeNodeType.EXTRACT_SURFACES:
      return 'diskArrowUpRight';
    case ParaviewRpc.TreeNodeType.LINE:
      return 'lineSegment';
    case ParaviewRpc.TreeNodeType.INTERSECTION_CURVE:
      return 'intersectingPlanes';
    case ParaviewRpc.TreeNodeType.SURFACE_L_I_C:
      return 'surfaceLIC';
    default:
      return 'wand';
  }
}

/** Type to inform which visibility menu items to show. */
export type VisibilityInfo = {
  selectedHidden: Set<string>,
  selectedVisible: Set<string>,
  deselectedHidden: Set<string>,
  deselectedVisible: Set<string>,
}

export function findFilterTreeNode(
  node: ParaviewRpc.TreeNode,
  id: string,
): ParaviewRpc.TreeNode | undefined {
  if (node.id === id) {
    return node;
  }

  return node.child.reduce(
    (result, child) => result ?? findFilterTreeNode(child, id),
    undefined as ParaviewRpc.TreeNode | undefined,
  );
}

// Get the parent of the currently visible filter node. Only valid to call
// from inside the PropPanel for a filter node (in other words, if a filter
// node is either selected or being edited).
export function findParentFilterNode(
  editState: EditState | null,
  rootNode: ParaviewRpc.TreeNode,
  selectedNode: SimulationTreeNode,
  simulationTree: SimulationTreeNode,
): ParaviewRpc.TreeNode | undefined {
  let foundNode = selectedNode.parent;
  if (editState) {
    // When editing a node, that state overrides the selected node.
    const editNode = simulationTree.getDescendant(editState.nodeId);
    // When creating a node, the editState already holds the parent.
    foundNode = editState.newNode ? editNode : (editNode?.parent ?? null);
    // The simulation tree may change between views (e.g. setup -> analysis). If the editState
    // is active during this transition, the parent node might not exist in the new tree. In that
    // case we should just make the node a child of the root.
    foundNode ??= simulationTree.getDescendant(DEFAULT_FILTER_ROOT);
  }
  if (!foundNode) {
    throw Error(
      `parentFilterNode: no filter found.
      selectedNode=${selectedNode.id}, ${selectedNode.name}.
      editState=${editState?.nodeId}, ${editState?.newNode}.`,
    );
  }
  if (foundNode.type !== NodeType.FILTER) {
    throw Error(`parentFilterNode: node=${foundNode.name} type=${foundNode.type} is not a filter`);
  }

  return findFilterTreeNode(rootNode, foundNode.id);
}

// Global variable used to determine what to do with async replies from the vis service
// and paraview.
let currentVisUrl: string = '';

// Function to check if the provided url matches what the frontend is currently
// using. We can receive ansyc replies from stale urls, and we need a way to check
// to see if we need to disregard the reply. Using paraview manager is not possible
// because its a single component that communicates with multiple clients.
// Ideally we would have a separate component / interface per paraview.
export function isCurrentVisUrl(url: string): boolean {
  return currentVisUrl === url;
}

export function setCurrentVisUrl(url: string): void {
  currentVisUrl = url;
}

export function getCurrentVisUrl(): string {
  return currentVisUrl;
}

export function isSurfaceListOverset(
  surfaceList: string[],
  param: simulationpb.SimulationParam,
): boolean {
  // Check the surface list is of non-zero length and whether any of the surface BCs is overset.
  return surfaceList.length > 0 && surfaceList.some(
    (str) => isSurfaceBoundaryConditionType(param, str, simulationpb.PhysicalBoundary.OVERSET),
  );
}

// Issues a ComputeMultiSlice RPC to compute multiple slice planes.
export async function visComputeMultiSlice(
  projectId: string,
  activeUrl: string,
  nSlices: number,
  start: basepb.Vector3,
  end: basepb.Vector3,
  projectVectors: boolean,
): Promise<{
  result: string,
}> {
  if (!activeUrl) {
    throw Error('no url');
  }
  const req = new frontendpb.VisMultiSliceRequest({
    projectId,
    url: activeUrl,
    nSlices,
    start: vec3ToAdVec3(start),
    end: vec3ToAdVec3(end),
    projectVectors,
  });
  const reply = await rpc.callRetry('ComputeMultiSlice', rpc.client.computeVisMultiSlice, req);
  return { result: reply.result };
}

function pvToVisSeedPlacementType(
  type: ParaviewRpc.SeedPlacementType,
): vispb.SeedPlacementType {
  switch (type) {
    case ParaviewRpc.SeedPlacementType.RAKE:
      return vispb.SeedPlacementType.RAKE;
    case ParaviewRpc.SeedPlacementType.GRID:
      return vispb.SeedPlacementType.GRID;
    case ParaviewRpc.SeedPlacementType.GLOBE:
      return vispb.SeedPlacementType.GLOBE;
    case ParaviewRpc.SeedPlacementType.SURFACE:
      return vispb.SeedPlacementType.SURFACE;
    default:
      throw Error('Invalid seed param type.');
  }
}

function pvToVisIntegrationDirection(
  integrationDirection: ParaviewRpc.IntegrationDirection,
): vispb.IntegrationDirection {
  switch (integrationDirection) {
    case ParaviewRpc.IntegrationDirection.FORWARD:
      return vispb.IntegrationDirection.FORWARD;
    case ParaviewRpc.IntegrationDirection.BACKWARD:
      return vispb.IntegrationDirection.BACKWARD;
    case ParaviewRpc.IntegrationDirection.BOTH:
      return vispb.IntegrationDirection.BOTH;
    default:
      throw Error('Invalid integration direction specified.');
  }
}

function getMinBoundsPoint(bounds: ParaviewRpc.Bounds): basepb.AdVector3 {
  return newScalarAdVector(bounds[0], bounds[2], bounds[4]);
}

function getMaxBoundsPoint(bounds: ParaviewRpc.Bounds): basepb.AdVector3 {
  return newScalarAdVector(bounds[1], bounds[3], bounds[5]);
}

function pvToVisSeedPlacementParams(
  resolution: number,
  type: ParaviewRpc.SeedPlacementType,
  params: ParaviewRpc.SeedPlacementParams,
): vispb.SeedPlacementParams {
  const reqParams = new vispb.SeedPlacementParams();
  switch (type) {
    case ParaviewRpc.SeedPlacementType.RAKE: {
      const { start, end } = params as ParaviewRpc.SeedRakeParam;
      const rakeParams = new vispb.SeedPlacementParams_RakeParams({
        start: vec3ToAdVec3(toProto(start)),
        end: vec3ToAdVec3(toProto(end)),
      });
      reqParams.rakeParams = rakeParams;
      reqParams.nStreamlines = resolution;
      break;
    }
    case ParaviewRpc.SeedPlacementType.GRID: {
      const seedGridParam = params as ParaviewRpc.SeedGridParam;
      const { center, seedSpacing, seedRes, rakeSpacing, rakeRes } = seedGridParam;
      const gridParams = new vispb.SeedPlacementParams_GridParams({
        center: vec3ToAdVec3(toProto(center)),
        u: vec3ToAdVec3(toProto(seedGridParam.u)),
        v: vec3ToAdVec3(toProto(seedGridParam.v)),
        seedSpacing: newAdFloat(seedSpacing),
        seedRes,
        rakeSpacing: newAdFloat(rakeSpacing),
        rakeRes,
      });
      reqParams.gridParams = gridParams;
      reqParams.nStreamlines = seedRes * rakeRes;
      break;
    }
    case ParaviewRpc.SeedPlacementType.GLOBE: {
      const { center, radius } = params as ParaviewRpc.SeedGlobeParam;
      const globeParams = new vispb.SeedPlacementParams_GlobeParams({
        center: vec3ToAdVec3(toProto(center)),
        radius: newAdFloat(radius),
      });
      reqParams.globeParams = globeParams;
      reqParams.nStreamlines = resolution;
      break;
    }
    case ParaviewRpc.SeedPlacementType.SURFACE: {
      const {
        surfaces, sampleRate, projectOnSurface, offset,
      } = params as ParaviewRpc.SeedSurfaceParam;
      const surfaceParams = new vispb.SeedPlacementParams_SurfaceParams({
        surfaceNames: surfaces,
        sampleRate,
        projectOnSurface,
        offset,
      });
      // n streamlines is determined by surface resolution, sampling rate inside the filter.
      reqParams.surfaceParams = surfaceParams;
      reqParams.nStreamlines = 0;
      break;
    }
    case ParaviewRpc.SeedPlacementType.L_I_C: {
      const { surfaceType, surfaces, numberSteps } = params as ParaviewRpc.SeedLICParam;
      const licParams = new vispb.SeedPlacementParams_LICParams();
      if (surfaceType === ParaviewRpc.LICSurfaceType.GEOMETRY_SURFACE) {
        const surfaceParams = new vispb.SeedPlacementParams_LICParams_GeometrySurfaceLICParams({
          surfaceNames: surfaces as string[],
        });
        licParams.surfaceType = vispb.LicSurfaceType.GEOMETRY_SURFACE;
        licParams.params = { case: 'surfaces', value: surfaceParams };
        licParams.numberOfSteps = numberSteps;
      } else if (surfaceType === ParaviewRpc.LICSurfaceType.PLANE) {
        const {
          plane: { origin, normal }, bounds, useBounds,
        } = surfaces as ParaviewRpc.PlaneLICParam;
        const planeParams = new vispb.SeedPlacementParams_LICParams_PlaneLICParams({
          origin: vec3ToAdVec3(toProto(origin)),
          normal: vec3ToAdVec3(toProto(normal)),
          minPt: getMinBoundsPoint(bounds),
          maxPt: getMaxBoundsPoint(bounds),
          clipToBounds: useBounds,
        });
        licParams.surfaceType = vispb.LicSurfaceType.PLANE;
        licParams.params = { case: 'plane', value: planeParams };
        licParams.numberOfSteps = numberSteps;
      }
      reqParams.licParams = licParams;
      break;
    }
    default:
      throw Error('Invalid seed param type.');
  }
  return reqParams;
}

// Issues a ComputeStreamlines RPC to compute streamlines.
export async function visComputeStreamlines(
  projectId: string,
  activeUrl: string,
  treeNodeParams: ParaviewRpc.TreeNodeParam,
): Promise<{
  result: string,
}> {
  if (!activeUrl) {
    throw Error('no url');
  }
  const {
    seedPlacementType,
    seedPlacementParams,
    integrationDirection,
    dataName,
    maximumLength,
    nstreamlines,
  } = treeNodeParams as ParaviewRpc.VisStreamlinesParam;
  const req = new frontendpb.VisStreamlinesRequest({
    projectId,
    url: activeUrl,
    seedPlacementType: pvToVisSeedPlacementType(seedPlacementType),
    integrationDirection: pvToVisIntegrationDirection(integrationDirection),
    dataName,
    maximumLength,
    seedPlacementParams: pvToVisSeedPlacementParams(
      nstreamlines,
      seedPlacementType,
      seedPlacementParams!,
    ),
  });
  const reply = await rpc.callRetry('ComputeVisStreamlines', rpc.client.computeVisStreamlines, req);
  return { result: reply.result };
}

// Issues a ComputeSurfaceLIC RPC to compute streamlines and corresponding surface LIC.
export async function visComputeSurfaceLIC(
  projectId: string,
  activeUrl: string,
  treeNodeParams: ParaviewRpc.TreeNodeParam,
): Promise<{ result: string }> {
  if (!activeUrl) {
    throw Error('no url');
  }
  const {
    integrationDirection,
    dataName,
    maximumLength,
    version,
    seedPlacementType,
    seedPlacementParams,
  } = treeNodeParams as ParaviewRpc.SurfaceLICParam;
  const req = new frontendpb.VisSurfaceLICRequest({
    projectId,
    url: activeUrl,
    seedPlacementType: vispb.SeedPlacementType.LIC,
    integrationDirection: pvToVisIntegrationDirection(integrationDirection),
    dataName,
    maximumLength,
    version: version ?? 1,
    seedPlacementParams: pvToVisSeedPlacementParams(0, seedPlacementType, seedPlacementParams!),
  });
  const reply = await rpc.callRetry('ComputeVisSurfaceLIC', rpc.client.computeVisSurfaceLIC, req);
  return { result: reply.result };
}

type VisFilterParam =
  ParaviewRpc.MultiSliceParam | ParaviewRpc.VisStreamlinesParam | ParaviewRpc.SurfaceLICParam;

// An error can occur on the visualization service node for a specific filter or if the service is
// down. In these cases, set the url to an error state that can be used to show a message in the UI.
const ASYNC_VIS_ERROR_STRING = 'ERROR';

const missingSurfacesWarning = (name?: string) => {
  let msg = 'Select at least one surface to view results';
  if (name) {
    msg = `${name}: ${msg}`;
  }
  return msg;
};

// Check whether a filter has any warnings.  A filter with warnings may be saved to the kvstore but
// should not be sent to the vis service.
export function filterWarnings(
  param: ParaviewRpc.TreeNodeParam,
  name?: string,
): string[] {
  const warnings: string[] = [];
  switch (param.typ) {
    case ParaviewRpc.TreeNodeType.SURFACE_L_I_C: {
      const filterParam = param as ParaviewRpc.SurfaceLICParam;
      const seedParam = filterParam.seedPlacementParams as ParaviewRpc.SeedLICParam;
      if (seedParam.surfaceType === ParaviewRpc.LICSurfaceType.GEOMETRY_SURFACE &&
        (seedParam.surfaces as string[]).length === 0) {
        warnings.push(missingSurfacesWarning(name));
      }
      break;
    }
    case ParaviewRpc.TreeNodeType.EXTRACT_SURFACES: {
      const filterParam = param as ParaviewRpc.ExtractSurfacesParam;
      if (filterParam.surfaces.length === 0) {
        warnings.push(missingSurfacesWarning(name));
      }
      break;
    }
    case ParaviewRpc.TreeNodeType.STREAMLINES: {
      const filterParam = param as ParaviewRpc.VisStreamlinesParam;
      if (filterParam.seedPlacementType === ParaviewRpc.SeedPlacementType.SURFACE) {
        const seedParam = filterParam.seedPlacementParams as ParaviewRpc.SeedSurfaceParam;
        if (seedParam.surfaces.length === 0) {
          warnings.push(missingSurfacesWarning(name));
        }
      }
      break;
    }
    default:
      // Do nothing, warnings are only explicitly determined per filter type.
      break;
  }
  return warnings;
}

// A wrapper function for the async vis service function that handles
// the promise and updates the TreeNode based on the response.
export function visComputeFilterWrapper(
  projectId: string,
  activeUrl: string,
  param: ParaviewRpc.TreeNodeParam,
  nodeId: string,
  activeEdit: (nodeId: string, param: ParaviewRpc.TreeNodeParam) => void,
): void {
  const warnings = filterWarnings(param);
  if (warnings.length > 0) {
    // Node has warnings, save it as-is; don't bother calling vis service.
    const newParam = {
      ...param,
      url: '',
    };
    activeEdit(nodeId, newParam);
    return;
  }

  const handleResponse = (
    filterParam: VisFilterParam,
    response: any,
    errorType: string,
  ) => {
    const newParam = {
      ...filterParam,
      url: response?.result || ASYNC_VIS_ERROR_STRING,
    };

    if (isCurrentVisUrl(activeUrl)) {
      activeEdit(nodeId, newParam);
    } else {
      logger.warn(`${errorType} response came back to a different url`);
    }
  };

  if (param.typ === ParaviewRpc.TreeNodeType.MULTI_SLICE) {
    const filterParam = param as ParaviewRpc.MultiSliceParam;
    visComputeMultiSlice(
      projectId,
      activeUrl,
      filterParam.nslices,
      toProto(filterParam.start),
      toProto(filterParam.end),
      filterParam.projectvectors,
    ).then((response) => {
      handleResponse(filterParam, response, 'MultiSlice');
    }).catch((err: Error) => {
      handleResponse(filterParam, null, 'MultiSlice');
      addInfo('visComputeMultiSlice was unsuccessful');
    });
  }
  if (param.typ === ParaviewRpc.TreeNodeType.STREAMLINES) {
    const filterParam = param as ParaviewRpc.VisStreamlinesParam;
    visComputeStreamlines(
      projectId,
      activeUrl,
      param,
    ).then((response) => {
      handleResponse(filterParam, response, 'Streamlines');
    }).catch((err: Error) => {
      handleResponse(filterParam, null, 'Streamlines');
      addInfo('visComputeStreamlines was unsuccessful');
    });
  }
  if (param.typ === ParaviewRpc.TreeNodeType.SURFACE_L_I_C) {
    const filterParam = param as ParaviewRpc.SurfaceLICParam;
    visComputeSurfaceLIC(
      projectId,
      activeUrl,
      param,
    ).then((response) => {
      handleResponse(filterParam, response, 'SurfaceLIC');
    }).catch((err: Error) => {
      handleResponse(filterParam, null, 'SurfaceLIC');
      addInfo('visComputeSurfaceLIC was unsuccessful');
    });
  }
}

function displayVarEqual(a: ParaviewRpc.DisplayPvVariable, b: ParaviewRpc.DisplayPvVariable) {
  return (
    a.displayDataName === b.displayDataName &&
    a.displayDataNameComponent === b.displayDataNameComponent
  );
}

export type OrderedDisplayResult = { ordered: ParaviewRpc.DisplayPvVariable[], changed: boolean };

// Order the variables in arrayB as close as possible to arrayA. Returns the ordered list and a
// changed boolean. changed is true iff any elements have been added or removed between arrayA and
// arrayB.
export function orderDisplayVars(
  arrayA: ParaviewRpc.DisplayPvVariable[],
  arrayB: ParaviewRpc.DisplayPvVariable[],
): OrderedDisplayResult {
  // Keep in place any variables of A found in B.
  const ordered = arrayA.map((varA) => (
    arrayB.find((varB) => displayVarEqual(varA, varB))
  ));
  const changed = arrayA.length !== arrayB.length || ordered.some((a) => !a);

  // Find the variables of B that are not in A.
  const unplaced = arrayB.filter((varB) => (
    !arrayA.find((varA) => displayVarEqual(varA, varB))
  ));
  // Place them in the first available spot.
  unplaced.forEach((varB) => {
    const emptyIndex = ordered.findIndex((a) => !a);
    if (emptyIndex >= 0) {
      ordered[emptyIndex] = varB;
    } else {
      ordered.push(varB);
    }
  });
  // Remove any empty spots.
  const orderedFinal: ParaviewRpc.DisplayPvVariable[] = [];
  ordered.forEach((a) => {
    a && orderedFinal.push(a);
  });
  return { ordered: orderedFinal, changed };
}

// Return true if the node is a filter and returns false for the reader and
// any imposters.
export function isFilterNode(node: ParaviewRpc.TreeNode) {
  if (isImposter(node) || node.param.typ === ParaviewRpc.TreeNodeType.READER) {
    return false;
  }
  return true;
}

/**
 * Get some stats about the view state relevant to node visibility
 */
export function evaluateVisibility(root: ParaviewRpc.TreeNode): ViewStateVisibilityStats {
  const result: ViewStateVisibilityStats = {
    anyHidden: false,
    anyVisible: false,
    allHidden: true,
    allVisible: true,
    filterCount: 0,
  };

  const checkNode = (node: ParaviewRpc.TreeNode) => {
    if (isFilterNode(node)) {
      result.filterCount += 1;
      if (node.visible) {
        result.allHidden = false;
        result.anyVisible = true;
      } else {
        result.allVisible = false;
        result.anyHidden = true;
      }
    }
  };

  traverseTreeNodes(root, checkNode);
  return result;
}

export function getPipelineDataUrlsStreaming(
  projectId: string,
  workspaceState: string,
  neededIds: string[],
  lcvisCallbacks: PipelineUrlsStreamingCallbacks,
): () => void {
  logger.info(
    `GetPipelineDataUrlsStreaming` +
    ` projectId=${projectId} state=${workspaceState} ids=${neededIds}`,
  );

  let completed = false;
  const onRequest = () => new frontendpb.VisPipelineRequest({
    workspaceState,
    projectId,
    requestedIds: neededIds,
  });

  const onReply = (resp: frontendpb.VisPipelineStreamReply) => {
    if (resp.completed) {
      logger.info(
        `GetPipelineDataUrlsStreaming projectId=${projectId} completed.`,
      );
      completed = true;
      lcvisCallbacks.onComplete();
      return;
    }

    // Handle progresses if non-empty
    if (resp.progress?.length > 0) {
      logger.info(`GetPipelineDataUrlsStreaming projectId=${projectId}` +
                  ` progress: ${resp.progress}`);
      const newProgress: FilterProgressInfo[] = resp.progress.map((msg) => {
        const progress = msg.progress;
        const filterProgress = {
          completedUnits: Number(progress?.completedUnits),
          totalUnits: Number(progress?.totalUnits),
          details: progress?.details ?? '',
          indeterminate: progress?.indeterminate ?? false,
          filterId: msg.progress ? msg.filterId : '',
        };
        if (filterProgress.filterId && filterProgress.totalUnits > 0) {
          const percent = 100.0 * filterProgress.completedUnits / filterProgress.totalUnits;
          logger.info(
            `GetPipelineDataUrlsStreaming`,
            ` projectId=${projectId} Filter ${msg.filterId} progress ${percent}`,
          );
        }
        // progress should never be null right? So we shouldn't need this..
        // nor the filter call below
        return filterProgress;
      }).filter((elem) => elem.filterId !== '');
      if (newProgress.length > 0) {
        lcvisCallbacks.onProgress(newProgress);
      }
    }

    if (resp.urlsReply?.filterIds && resp.urlsReply?.filterIds.length > 0) {
      const urlsReply = resp.urlsReply;
      logger.info(`GetPipelineDataUrlsStreaming` +
        ` projectId=${projectId} urls reply: ${urlsReply.filterIds}`);
      const newUrls: RenderDataUrls[] = urlsReply.filterIds.map((filterId, index) => {
        const dataFile = urlsReply.dataFiles[index];
        const metaFile = urlsReply.metaFiles[index];

        const rawSize = fromBigInt(dataFile?.metadata?.size ?? 0);
        const metadataSize = fromBigInt(metaFile?.metadata?.size ?? 0);
        if (
          dataFile.contents.case === 'fileId' &&
          dataFile.contents.value !== ''
        ) {
          // ITAR envs cannot use signed URLs, so we expect GetRenderDataUrl to return file IDs.
          // These files must be fetched using the fetch endpoint with the auth header.
          // We won't have a metadata URL if we're downloading a combined render data file
          const metaFileValue =
            metaFile.contents.case === 'fileId' ? metaFile.contents.value : '';
          const metadataFetchLink = metaFileValue ? fetchLink(metaFileValue) : '';
          return {
            filterId,
            raw: fetchLink(dataFile.contents.value ?? ''),
            rawSize,
            metadata: metadataFetchLink,
            metadataSize,
          };
        }
        // If non-itar, use signed URLs as usual.
        const dataUrl = dataFile.contents.case === 'signedUrl' ? dataFile.contents.value : '';
        const metaUrl = metaFile.contents.case === 'signedUrl' ? metaFile.contents.value : '';
        return {
          filterId,
          raw: dataUrl ?? '',
          rawSize,
          metadata: metaUrl ?? '',
          metadataSize,
        };
      });
      if (newUrls.length > 0) {
        lcvisCallbacks.onRenderDataUrls(newUrls);
      }
    }
  };

  const rpcPool =
    new rpc.StreamingRpcPool<
      frontendpb.VisPipelineRequest,
      frontendpb.VisPipelineStreamReply>(
        'ComputeVisPipelineStream',
        rpc.client.computeVisPipelineStream,
        true,
      );

  const onError = (err: ConnectError) => {
    logger.info(`GetPipelineDataUrlsStreaming` +
      ` projectId=${projectId} state=${workspaceState} error=${err}`);
    lcvisCallbacks.onError(err.message);
  };

  const onStart = () => {
    logger.info(`GetPipelineDataUrlsStreaming projectId=${projectId} request started`);
  };

  const onStop = () => {
    logger.info(`GetPipelineDataUrlsStreaming projectId=${projectId} request stopped`);
    if (!completed) {
      lcvisCallbacks.onCancel();
    }
  };

  // We only allow one RPC request at a time, so the key for this request is per project.
  // Start returns an function to cancel the request, we pass this back to LCVis so
  // that it can cancel the RPC
  return rpcPool.start(
    `GetPipelineDataUrlsStreaming/${projectId}`,
    onRequest,
    onReply,
    onError,
    rpc.client,
    onStop,
    onStart,
    undefined,
    0,
  );
}

export async function getPipelineDataUrls(
  projectId: string,
  workspaceState: string,
  neededIds: string[],
): Promise<RenderDataUrls[]> {
  logger.info(`GetPipelineDataUrls projectId=${projectId} state=${workspaceState}`);
  const req = new frontendpb.VisPipelineRequest({
    workspaceState,
    projectId,
    requestedIds: neededIds,
  });

  const reply = await rpc.callRetry(
    'ComputeVisPipeline',
    rpc.client.computeVisPipeline,
    req,
  );
  const renderDataUrls: RenderDataUrls[] = reply.filterIds.map((filterId, index) => {
    const dataFile = reply.dataFiles[index];
    const metaFile = reply.metaFiles[index];

    const rawSize = fromBigInt(dataFile?.metadata?.size ?? 0);
    const metadataSize = fromBigInt(metaFile?.metadata?.size ?? 0);
    if (
      dataFile.contents.case === 'fileId' &&
      dataFile.contents.value !== ''
    ) {
      // ITAR envs cannot use signed URLs, so we expect GetRenderDataUrl to return file IDs.
      // These files must be fetched using the fetch endpoint with the auth header.
      // We won't have a metadata URL if we're downloading a combined render data file
      const metaFileValue = metaFile.contents.case === 'fileId' ? metaFile.contents.value : '';
      const metadataFetchLink = metaFileValue ? fetchLink(metaFileValue) : '';
      return {
        filterId,
        raw: fetchLink(dataFile.contents.value ?? ''),
        rawSize,
        metadata: metadataFetchLink,
        metadataSize,
      };
    }
    // If non-itar, use signed URLs as usual.
    const dataUrl = dataFile.contents.case === 'signedUrl' ? dataFile.contents.value : '';
    const metaUrl = metaFile.contents.case === 'signedUrl' ? metaFile.contents.value : '';
    return {
      filterId,
      raw: dataUrl ?? '',
      rawSize,
      metadata: metaUrl ?? '',
      metadataSize,
    };
  });
  return renderDataUrls;
}

/** Given a tree of TreeNodes, hide all visualizations in the tree. */
export function hideAllFilters(root: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode {
  const newRoot = updateTreeNodes(
    root,
    (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
      if (isFilterNode(node)) {
        return { ...node, visible: false };
      }
      return node;
    },
  );

  return newRoot;
}

/** Given a root TreeNode, show all leaves in the tree. */
export function showAllLeaves(root: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode {
  const newRoot = updateTreeNodes(
    root,
    (node: ParaviewRpc.TreeNode) => {
      if (isFilterNode(node)) {
        // Only show nodes with no children.
        const wantVisible = node.child.length === 0;
        return { ...node, visible: wantVisible };
      }
      return node;
    },
  );
  return newRoot;
}

/** Set the visibility of a node in the tree. */
export function setVisualizationVisibility(
  root: ParaviewRpc.TreeNode,
  nodeId: string,
  wantVisible: boolean,
): ParaviewRpc.TreeNode {
  const newRoot = updateTreeNodes(
    root,
    (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
      if (node.id !== nodeId) {
        return node;
      }
      return { ...node, visible: wantVisible };
    },
  );

  return newRoot;
}

export function isClipOrSlice(node: ParaviewRpc.TreeNode): boolean {
  return ['Clip', 'Slice'].includes(node.param?.typ);
}

/** Hide all clips and slices that are descendants of the specified node. */
export function hideAllDescendantClips(
  root: ParaviewRpc.TreeNode,
  nodeId: string,
): ParaviewRpc.TreeNode {
  // keep a set of the ids of all nodes that are descendants of the given node
  const descendantIds = new Set<string>();
  traverseTreeNodes(root, (node) => {
    if (node.id === nodeId || descendantIds.has(node.id)) {
      node.child.forEach((child) => descendantIds.add(child.id));
    }
  });

  const newRoot = updateTreeNodes(root, (node) => {
    if (descendantIds.has(node.id) && isClipOrSlice(node)) {
      return { ...node, visible: false };
    }
    return { ...node };
  });
  return newRoot;
}

export function findPathToNode(
  root: ParaviewRpc.TreeNode,
  nodeId: string,
): Set<string> {
  // keep a map of the direct parent of each node in the tree.
  const parentMap = new Map<string, string>();
  // populate the parent map such that each node id maps to its parent's id.
  traverseTreeNodes(root, (node: ParaviewRpc.TreeNode) => {
    node.child.forEach((child) => parentMap.set(child.id, node.id));
  });

  // record the path from the root to the node we want. This path excludes the final node.
  const pathToNode = new Set<string>();
  let name = nodeId;
  while (parentMap.has(name)) {
    const parentId = parentMap.get(name)!;
    pathToNode.add(parentId);
    name = parentId;
  }

  return pathToNode;
}

/** Hide all clips and slices that are ancestors of the specified node. */
export function hideAllAncestorClips(
  root: ParaviewRpc.TreeNode,
  nodeId: string,
) {
  const pathToNode = findPathToNode(root, nodeId);
  // traverse the tree, and if any node is on the path to the specified node, and is also a
  // clip or a slice, hide it.
  const newRoot = updateTreeNodes(root, (node: ParaviewRpc.TreeNode) => {
    if (pathToNode.has(node.id) && isClipOrSlice(node)) {
      return { ...node, visible: false };
    }
    return { ...node };
  });

  return newRoot;
}

/**
 * Set { visible: wantVisible } on the node with the given nodeId.
 * We have a design requirement here. For LCVis, only 1 clip or slice may be active at a given time
 * in the filter tree. So if the user turns on the visibility for a clip or slice, we must
 * turn off the visibilities of all other clips and slices in the tree.
 */
export function applyVisibilityToNode(
  root: ParaviewRpc.TreeNode,
  nodeId: string,
  isClipOrSliceFilter: boolean,
  wantVisible: boolean,
  // if isMesh is true, we do not apply the constraint and simply set the new visibility normally.
  isMesh: boolean,
) {
  if (isClipOrSliceFilter && wantVisible && !isMesh) {
    const newRoot = updateTreeNodes(root, (node) => {
      // hide all other clips and slices in the filter tree.
      if (node.id !== nodeId && isClipOrSlice(node)) {
        return { ...node, visible: false };
      }
      if (node.id === nodeId) {
        return { ...node, visible: true };
      }
      return { ...node };
    });
    return newRoot;
  }
  // if this isn't a clip or slice, just apply the new visibility as normal.
  return setVisualizationVisibility(root, nodeId, wantVisible);
}

/** An empty ArrayInformation for testing. */
const emptyArrayInformation: ParaviewRpc.ArrayInformation = {
  name: 'testArrayInformation',
  type: ParaviewRpc.FieldAssociation.CELL,
  dim: 1,
  range: [],
  n: 0,
};

/** Create a ClipSliceParam for testing. You can override the defaults by providing extraAttrs. */
export const testClip = (
  extraAttrs?: Partial<ParaviewRpc.ClipSliceParam>,
): ParaviewRpc.ClipSliceParam => {
  const defaultClip: ParaviewRpc.ClipSliceParam = {
    typ: 'Clip',
    paramType: 'Clip',
    filterParam: {
      typ: 'Plane',
      origin: listToPv([0, 0, 0]),
      normal: listToPv([1, 0, 0]),
    },
    smooth: false,
    invert: false,
  };

  return { ...defaultClip, ...extraAttrs };
};

/** Create a slice ClipSliceParam for testing.
 * You can override the defaults by providing extraAttrs. */
export const testSlice = (
  extraAttrs?: Partial<ParaviewRpc.ClipSliceParam>,
): ParaviewRpc.ClipSliceParam => {
  const defaultSlice: ParaviewRpc.ClipSliceParam = {
    typ: 'Slice',
    filterParam: {
      typ: 'Plane',
      normal: listToPv([0, 0, 0]),
      origin: listToPv([0, 0, 0]),
    },
    invert: false,
    smooth: false,
    paramType: 'Slice',
  };

  return { ...defaultSlice, ...extraAttrs };
};

/** Create a TreeNode for testing. You can override the defaults by providing extraAttrs. */
export function createTestTreeNode(
  extraAttrs?: Partial<ParaviewRpc.TreeNode>,
): ParaviewRpc.TreeNode {
  const defaultEntries = {
    name: random.string(16),
    id: random.string(16),
    bounds: null,
    pointData: [emptyArrayInformation],
    param: testClip(),
    paramSeq: 0,
    visible: true,
    child: [],
    displayProps: {},
  };

  return { ...defaultEntries, ...extraAttrs };
}

/** Create an editState for testing. You can override the defaults by providing extraAttrs. */
export function createTestEditState(extraAttrs?: Partial<EditState>): EditState {
  const state = {
    newNode: false,
    nodeId: 'b',
    displayProps: {},
    editSource: EditSource.FORM,
    param: testSlice(),
  };
  return { ...state, ...extraAttrs };
}

/** Return the ids of all nodes in the tree. */
export const getAllNodeIds = (root: ParaviewRpc.TreeNode): Set<string> => {
  const allNodeIds = new Set<string>();
  traverseTreeNodes(root, (node) => allNodeIds.add(node.id));
  return allNodeIds;
};

/** Delete a TreeNode from the tree. Deletes the node's children as well. */
export function deleteTreeNode(root: ParaviewRpc.TreeNode, nodeId: string) {
  const updateTreeNode = (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
    // If we are deleting a visible node, make its parent visible if this is
    // not the root node.
    const isRoot = (node.id === root.id);
    const visibleChild = node.child.some((child) => child.id === nodeId && child.visible);
    const visible = node.visible || (!isRoot && visibleChild);
    return {
      ...node,
      child: node.child.filter((child) => child.id !== nodeId),
      visible,
    };
  };
  const newRoot = updateTreeNodes(root, updateTreeNode);
  return newRoot;
}

/**
 * Apply the editState param to the root TreeNode. If createdNode is null, updates an existing
 * node. If createdNode is a TreeNode,
 * appends createdNode to the children of the node with id === edit.nodeId.
 */
export function applyEditToTreeNode(
  root: ParaviewRpc.TreeNode,
  edit: EditState,
  createdNode: ParaviewRpc.TreeNode | null,
): ParaviewRpc.TreeNode {
  const newRoot = updateTreeNodes(
    root,
    (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
      if (node.id !== edit.nodeId) {
        return node;
      }
      if (edit.newNode) {
        return {
          ...node,
          visible: false,
          child: [
            ...node.child,
            createdNode!,
          ],
        };
      }
      return {
        ...node,
        paramSeq: node.paramSeq + 1,
        param: edit.param,
        visible: true,
        displayProps: edit.displayProps,
      };
    },
  );
  return newRoot;
}

/** Set the name of the node with the given id. If that node is not in the tree, this is a noop. */
export function renameTreeNode(root: ParaviewRpc.TreeNode, id: string, newName: string) {
  const newRoot = updateTreeNodes(
    root,
    (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
      if (node.id !== id) {
        return node;
      }
      return { ...node, name: newName };
    },
  );
  return newRoot;
}

export function removeResultsFilter(root: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode {
  // Logic for removing post-processing filters that can creep in.
  const shouldKeep = (node: ParaviewRpc.TreeNode): boolean => {
    switch (node.param.typ) {
      case ParaviewRpc.TreeNodeType.MULTI_SLICE:
      case ParaviewRpc.TreeNodeType.STREAMLINES:
      case ParaviewRpc.TreeNodeType.SURFACE_L_I_C:
      case ParaviewRpc.TreeNodeType.LINE:
      case ParaviewRpc.TreeNodeType.GLYPH:
      case ParaviewRpc.TreeNodeType.THRESHOLD:
      case ParaviewRpc.TreeNodeType.CONTOUR:
      case ParaviewRpc.TreeNodeType.PLOT:
      case ParaviewRpc.TreeNodeType.INTERSECTION_CURVE:
        return false;
      default:
        return true;
    }
  };

  const removeNodes = (node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => ({
    ...node,
    child: node.child.filter(shouldKeep),
  });

  return updateTreeNodes(root, removeNodes);
}

/**
 * Toggle the smooth property of all top-level clip filters in the tree (i.e. all clip filters
 * that are direct children of the root node). Descendants further down are ignored.
 *
 * @param root - the root TreeNode of the tree
 * @param smooth - the value to set the smooth property to. If false, all clips will be
 * crinkle clips.
 */
export function toggleSmoothClips(
  root: ParaviewRpc.TreeNode,
  smooth: boolean,
): ParaviewRpc.TreeNode {
  const newRoot: ParaviewRpc.TreeNode = {
    ...root,
    child: root.child.map((node) => {
      if (node.param.typ === ParaviewRpc.TreeNodeType.CLIP) {
        return {
          ...node,
          param: {
            ...node.param,
            smooth,
          },
        };
      }
      return node;
    }),
  };
  return newRoot;
}

/**
 * Traverse a TreeNode from the root and execute the provided callback which will be called with
 * both the node being visited and its parent, if it has one.
 * @param root the root node of the tree
 * @param callback the callback to be executed at every node
 * @param parent an optional parent param that is used internally for the funciton.
 */
export function traverseTreeNodeAndParent(
  root: ParaviewRpc.TreeNode,
  callback: (node: ParaviewRpc.TreeNode, parentNode: ParaviewRpc.TreeNode | null) => void,
): void {
  const internalCallback = (
    node: ParaviewRpc.TreeNode,
    parentNode: ParaviewRpc.TreeNode | null,
  ) => {
    callback(node, parentNode);
    node.child.forEach((child) => {
      internalCallback(child, node);
    });
  };
  internalCallback(root, null);
}

/**
 * Find a node in the tree by its node id, and return its parent too.
 *
 * @param root the root node of the tree
 * @param nodeId the id of the node to find
 */
export function findNodeAndParent(
  root: ParaviewRpc.TreeNode,
  nodeId: string,
): [node: ParaviewRpc.TreeNode | null, parent: ParaviewRpc.TreeNode | null] {
  let foundNode: ParaviewRpc.TreeNode | null = null;
  let foundParent: ParaviewRpc.TreeNode | null = null;

  traverseTreeNodeAndParent(root, (node, parent) => {
    if (foundNode) {
      return;
    }
    if (node.id === nodeId) {
      foundNode = node;
      foundParent = parent;
    }
  });

  return [foundNode, foundParent];
}

/**
 * Add a new node to the tree. If parentId is not in the tree, this is a noop
 *
 * @param root the root node of the tree
 * @param parentId the id of the parent node
 * @param node the new node to add
 */
export function addChildNode(
  root: ParaviewRpc.TreeNode,
  parentId: string,
  node: ParaviewRpc.TreeNode,
) {
  const newRoot = updateTreeNodes(
    root,
    (currNode: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode => {
      if (currNode.id !== parentId) {
        return currNode;
      }
      return {
        ...currNode,
        child: [...currNode.child, node],
      };
    },
  );
  return newRoot;
}

/**
 * Returns a stripped-down version of the tree that's easier to read/log.
 * Removes non-filter nodes and removes some data that Paraview returns.
 */
export function stripTreeNodeForLogging(node: ParaviewRpc.TreeNode): ParaviewRpc.TreeNode | null {
  if (isImposter(node)) {
    return null;
  }
  return {
    ...node,
    pointData: [],
    bounds: null,
    displayProps: null,
    visible: false,
    child: node.child.map(stripTreeNodeForLogging).filter(
      (child) => child !== null,
    ) as ParaviewRpc.TreeNode[],
  };
}

/**
 * @returns True if the node is a filter which produces surfaces rather than volumes.
 */
export const isSurfaceFilter = (node: ParaviewRpc.TreeNode) => {
  switch (node.param.typ) {
    case ParaviewRpc.TreeNodeType.SLICE:
    case ParaviewRpc.TreeNodeType.MULTI_SLICE:
    case ParaviewRpc.TreeNodeType.STREAMLINES:
    case ParaviewRpc.TreeNodeType.CONTOUR:
    case ParaviewRpc.TreeNodeType.EXTRACT_SURFACES:
      return true;
    default:
      return false;
  }
};

/**
 * Checks if the given TreeNode has an ancestor which outputs surfaces rather than volumes.
 * ancestors for which this returns true:
 * slice; multislice, streamlines, contour, extract surfaces.
 *
 * @param root the root TreeNode of the tree
 * @param nodeId the id of the node to check
 */
export const hasSurfaceAncestor = (
  root: ParaviewRpc.TreeNode,
  nodeId: string,
) => {
  const ancestors = findPathToNode(root, nodeId);

  let hasSurfaceAnc = false;
  traverseTreeNodes(root, (node) => {
    if (ancestors.has(node.id)) {
      hasSurfaceAnc ||= isSurfaceFilter(node);
    }
  });
  return hasSurfaceAnc;
};

export function newPlaneParam(bounds: ParaviewRpc.Bounds): ParaviewRpc.PlaneParam {
  return {
    typ: 'Plane',
    // The initial center is the bounds center.
    origin: {
      x: 0.5 * (bounds[1] + bounds[0]),
      y: 0.5 * (bounds[3] + bounds[2]),
      z: 0.5 * (bounds[5] + bounds[4]),
    },
    // The default normal is the YZ plane.
    normal: { x: 1, y: 0, z: 0 },
  };
}

// The default params for the box clip.
export function newBoxParam(bounds: ParaviewRpc.Bounds): ParaviewRpc.BoxClipParam {
  const xmin = bounds[0];
  const xmax = bounds[1];
  const ymin = bounds[2];
  const ymax = bounds[3];
  const zmin = bounds[4];
  const zmax = bounds[5];
  return {
    typ: 'BoxClip',
    position: { x: (xmin + xmax) / 2, y: (ymin + ymax) / 2, z: (zmin + zmax) / 2 },
    // The rotation of the box about x, y, z axes.
    // Order of rotation is Y > X > Z.
    rotation: { x: 0, y: 0, z: 0 },
    // Use length of each dimension range.
    length: { x: xmax - xmin, y: ymax - ymin, z: zmax - zmin },
  };
}

/**
 * Mutates a VisibilityInfo object to reflect the visibility of vis filters in the tree, based
 * on the visibility of the filter nodes and the selection.
 */
export function updateFilterVisibilityInfo(
  filterState: ParaviewRpc.TreeNode,
  selection: Set<string>,
  initialVisibilityInfo: VisibilityInfo,
): void {
  const {
    selectedVisible,
    selectedHidden,
    deselectedVisible,
    deselectedHidden,
  } = initialVisibilityInfo;

  traverseTreeNodes(filterState, (node) => {
    if (node.id === DEFAULT_FILTER_ROOT) {
      // ignore the root node
      return;
    }
    if (node.visible) {
      if (selection.has(node.id)) {
        selectedVisible.add(node.id);
      } else {
        deselectedVisible.add(node.id);
      }
    } else if (selection.has(node.id)) {
      selectedHidden.add(node.id);
    } else {
      deselectedHidden.add(node.id);
    }
  });
}

/**
 * Mutates a VisibilityInfo object to reflect the visibility of surfaces in the tree based on
 * the visibility map and selection, plus volumes and groups.
 */
export function updateSurfaceVisibilityInfo(
  selection: Set<string>,
  visibilityMap: LcVisVisibilityMap,
  entityGroupMap: EntityGroupMap,
  staticVolumes: StaticVolume[],
  initialVisibilityInfo: VisibilityInfo,
): void {
  const {
    selectedVisible,
    selectedHidden,
    deselectedVisible,
    deselectedHidden,
  } = initialVisibilityInfo;

  const volumeMap = new Map<string, StaticVolume>();
  staticVolumes.forEach((volume) => {
    volumeMap.set(volume.id, volume);
  });
  const selectedVolumeSurfaces = new Set<string>();
  staticVolumes.forEach((volume) => {
    if (selection.has(volume.domain)) {
      volume.bounds.forEach((bound) => {
        selectedVolumeSurfaces.add(bound);
      });
    }
  });
  entityGroupMap.getGroups().forEach((group) => {
    const { id, children } = group;
    if (children.size || volumeMap.has(id) || GroupMap.isRoot(id)) {
      // ignore group and volume ids. We have already processed them by now by flattening the tree.
      return;
    }
    const isSelected = selection.has(id) || selectedVolumeSurfaces.has(id);
    const isVisible = visibilityMap.get(id) ?? false;
    if (isVisible) {
      if (isSelected) {
        selectedVisible.add(id);
      } else {
        deselectedVisible.add(id);
      }
    } else if (isSelected) {
      selectedHidden.add(id);
    } else {
      deselectedHidden.add(id);
    }
  });
}

/**
 * Get the visibility info for a given filter state and entity group map. This is needed to
 * determine which rows are visible/selected and which items to show in the vis context menu.
 */
export function getVisibilityInfo(
  isMesh: boolean,
  filterState: ParaviewRpc.TreeNode,
  selection: Set<string>,
  visibilityMap: LcVisVisibilityMap,
  entityGroupMap: EntityGroupMap,
  staticVolumes: StaticVolume[],
): VisibilityInfo {
  const visibilityInfo: VisibilityInfo = {
    selectedVisible: new Set<string>(),
    selectedHidden: new Set<string>(),
    deselectedVisible: new Set<string>(),
    deselectedHidden: new Set<string>(),
  };

  if (isMesh) {
    // We need to account for the filter state in mesh mode,
    // because in that view filter visibilities matter.
    updateFilterVisibilityInfo(filterState, selection, visibilityInfo);
  }
  updateSurfaceVisibilityInfo(
    selection,
    visibilityMap,
    entityGroupMap,
    staticVolumes,
    visibilityInfo,
  );

  return visibilityInfo;
}

/**
 * Given a representation type, returns whether to show lines and whether to show surfaces.
 * For example, if the representation type is 'Surface', this function will return
 * { showLines: false, showSurfaces: true }, and the opposite for 'Wireframe'.
 *
 * @param reprType the representation type
 * @returns an object with showLines and showSurfaces booleans
 */
export const parseRepresentationType = (reprType?: ParaviewRpc.RepresentationType): {
  showLines: boolean,
  showSurfaces: boolean
} => ({
  showSurfaces: reprType === 'Surface' || reprType === 'Surface With Edges',
  showLines: reprType === 'Wireframe' || reprType === 'Surface With Edges',
});

/**
 * Given a list of bounds, returns the union of all the bounds.
 * Bounds are in the form [minX,minY,...,maxZ]
 *
 * @param bounds the list of bounds to union
 */
export const unionBounds = (bounds: Bounds[]): Bounds | null => {
  if (bounds.length === 0) {
    return null;
  }
  const finalBounds = bounds.reduce(
    (acc, [minX, minY, minZ, maxX, maxY, maxZ]) => [
      Math.min(acc[0], minX),
      Math.min(acc[1], minY),
      Math.min(acc[2], minZ),
      Math.max(acc[3], maxX),
      Math.max(acc[4], maxY),
      Math.max(acc[5], maxZ),
    ],
    [Infinity, Infinity, Infinity, -Infinity, -Infinity, -Infinity],
  );

  return finalBounds;
};

// Returns the number of components of a display variable.
// Returns null if the variable is not found in the viewState data.
const displayVariableComponentsFromData = (
  displayVariable: ParaviewRpc.DisplayPvVariable,
  data: ParaviewRpc.ArrayInformation[],
): number | null => {
  const filteredArrayInfo = data.filter(
    (arr: ParaviewRpc.ArrayInformation) => arr.type === 'Point' &&
        arr.name === displayVariable.displayDataName,
  );
  if (filteredArrayInfo.length > 1) {
    throw Error('The viewState contains multiple data values with the same name.');
  }
  if (filteredArrayInfo.length === 1) {
    return filteredArrayInfo[0].dim;
  }
  return null;
};

// Convert a display variable to a string.
export const displayVariableToTextFromData = (
  displayVariable: ParaviewRpc.DisplayPvVariable,
  data: ParaviewRpc.ArrayInformation[],
): string => {
  // Figure out if the displayVariable is a vector or not.
  const nComponents = displayVariableComponentsFromData(displayVariable, data);
  if (nComponents === 1) {
    return displayVariable.displayDataName;
  } if (nComponents === 3) {
    const componentsName = ['Magnitude', 'X', 'Y', 'Z'];
    const componentName = componentsName[displayVariable.displayDataNameComponent];
    if (displayVariable.displayDataNameComponent === 0) {
      return `${displayVariable.displayDataName!} ${componentName}`;
    }
    return `${displayVariable.displayDataName!} ${componentName}-Component`;
  } if (!nComponents) {
    return '';
  }
  throw Error('Display variable has more than three components. This is not supported');
};

/**
 * In Paraview, order of ranges is mag, x, y, z.
 * In LCVis, it's returned as x, y, z, mag.
 * The UI only understands the Paraview mapping so we have to rearrange here so the UI is less
 * confusing.
 * @param index the initial index to remap to work with LCVis
 * @param toLCVis whether we're going PV -> LCVis or LCVis -> PV
 * @returns the remapped index
 */
export const remapComponentIndex = (index: number, toLCVis = true): number => {
  if (toLCVis) {
    if (index === 0) {
      return 3;
    }
    return index - 1;
  }
  if (index === 3) {
    return 0;
  }
  return index + 1;
};

// The default colormap preset.
const DEFAULT_PRESET = 'Jet';
// The default colormap type is continuous.
const DEFAULT_DISCRETIZE = false;
// The default number of discrete levels/bins.
const DEFAULT_DISCRETE_BINS = 8;
// The default colormap visibility.
const DEFAULT_VISIBLE = true;

export const defaultCmap = (): ColorMap => ({
  presetName: DEFAULT_PRESET,
  globalRange: [0, 0],
  range: [0, 0],
  bins: DEFAULT_DISCRETE_BINS,
  visible: DEFAULT_VISIBLE,
  discretize: DEFAULT_DISCRETIZE,
});
