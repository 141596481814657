// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import { MultipleChoiceParam } from '../ProtoDescriptor';
import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../SimulationParamDescriptor';
import * as simulationpb from '../proto/client/simulation_pb';
import { EntityGroupData } from '../recoil/entityGroupState';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import assert from './assert';
import { getAssignedSurfaces, removeBoundsPrefix } from './boundaryConditionUtils';
import { unwrapSurfaceIds } from './entityGroupUtils';
import { boldEscaped } from './html';
import { initParamGroupProto } from './initParam';
import { intersects } from './lang';
import { prefixNameGen, uniqueSequenceName } from './name';
import {
  SubPhysics,
  findPhysicsById,
  getPhysicsId,
  getSubPhysics,
  getSurfaceToPhysicsMap,
} from './physicsUtils';
import { newNodeId } from './projectDataUtils';

const interfaceTypeParam = (paramDesc[ParamName.InterfaceType] as MultipleChoiceParam);

/**
 * Return sliding interfaces from a (heat or fluid) physics
 * @param physics
 * @returns
 */
export function getSlidingInterfacesByPhysics(physics: simulationpb.Physics) {
  return physics.params.value?.slidingInterfaces ?? [];
}

/**
 * Return all sliding interfaces from every physics in the param
 * @param param
 * @returns
 */
export function getAllSlidingInterfaces(param: simulationpb.SimulationParam) {
  return param.physics.reduce((result, physics) => {
    result.push(...getSlidingInterfacesByPhysics(physics));
    return result;
  }, [] as simulationpb.SlidingInterfaces[]);
}

/**
 * Find and return a sliding interface by its ID
 * @param param
 * @param id
 * @returns sliding interface or undefined
 */
export function findSlidingInterfaceById(param: simulationpb.SimulationParam, id: string) {
  return param.physics.reduce(
    (result, physics) => result || getSubPhysics(physics)?.slidingInterfaces.find(
      (item) => item.slidingInterfaceId === id,
    ),
    undefined as simulationpb.SlidingInterfaces | undefined,
  );
}

/**
 * Find and return the physics that contains a sliding interface identified by ID
 * @param param
 * @param id
 * @returns
 */
export function findParentPhysicsBySlidingInterfaceId(
  param: simulationpb.SimulationParam,
  id: string,
) {
  return param.physics.find((physics) => (
    getSubPhysics(physics)?.slidingInterfaces.some((item) => item.slidingInterfaceId === id)
  ));
}

/**
 * Find and return a sliding interface that contains a given surface ID
 * @param surfaceId
 * @param param
 * @returns
 */
export function findSlidingInterfaceBySurfaceId(
  param: simulationpb.SimulationParam,
  surfaceId: string,
): simulationpb.SlidingInterfaces | undefined {
  return param.physics.reduce((result, physics) => {
    if (result) {
      return result;
    }
    return getSubPhysics(physics)?.slidingInterfaces.find(((slidingInterface) => {
      const { slidingA, slidingB } = slidingInterface;
      return (slidingA?.includes(surfaceId) || slidingB?.includes(surfaceId));
    }));
  }, undefined as simulationpb.SlidingInterfaces | undefined);
}

/**
 * Get all sliding interfaces that contain one or more member of the given surface IDs list
 * @param surfaceIds
 * @param param
 * @returns
 */
export function getSlidingInterfacesWithSurfaceIds(
  param: simulationpb.SimulationParam,
  surfaceIds: string[],
): simulationpb.SlidingInterfaces[] {
  return param.physics.reduce((result, physics) => {
    result.push(
      ...getSubPhysics(physics)?.slidingInterfaces.filter(((slidingInterface) => {
        const { slidingA, slidingB } = slidingInterface;
        return intersects(surfaceIds, [...slidingA, ...slidingB]);
      })) || [],
    );
    return result;
  }, [] as simulationpb.SlidingInterfaces[]);
}

/**
 * Create a new sliding interface with sequential name
 * @param subPhysics
 * @returns sliding interface
 */
function createSlidingInterface(subPhysics: SubPhysics) {
  // Get list of existing names and max order value
  const existingNames: string[] = subPhysics.slidingInterfaces.map(
    (item) => item.slidingInterfaceName,
  );

  // Construct new interface
  const slidingInterface = initParamGroupProto(
    new simulationpb.SlidingInterfaces({
      slidingInterfaceId: newNodeId(),
      slidingInterfaceName: uniqueSequenceName(existingNames, prefixNameGen('Interface')),
      interfaceType: interfaceTypeParam.choices[0].enumNumber,
    }),
    paramGroupDesc[ParamGroupName.SlidingInterfaces],
  );

  return slidingInterface;
}

/**
 * Create a new sliding interface and append it to a fluid or heat physics
 * @param param
 * @param physicsId
 * @returns sliding interface
 */
export function appendSlidingInterface(
  param: simulationpb.SimulationParam,
  physicsId: string,
) {
  const physics = findPhysicsById(param, physicsId);
  const subPhysics = physics ? getSubPhysics(physics) : null;

  if (subPhysics) {
    const slidingInterface = createSlidingInterface(subPhysics);
    subPhysics.slidingInterfaces.push(slidingInterface);
    return slidingInterface;
  }

  throw Error('Invalid physics ID');
}

/**
 * Change the name of a sliding interface identified by ID
 * @param param
 * @param id
 * @param newName
 * @returns true if a sliding interface was renamed
 */
export function renameSlidingInterface(
  param: simulationpb.SimulationParam,
  id: string,
  newName: string,
) {
  return param.physics.some(
    (physics) => getSubPhysics(physics)?.slidingInterfaces.some((slidingInterface) => {
      if (
        (slidingInterface.slidingInterfaceId === id) &&
        (slidingInterface.slidingInterfaceName !== newName)
      ) {
        slidingInterface.slidingInterfaceName = newName;
        return true;
      }
      return false;
    }),
  );
}

/**
 * Remove a sliding interface, identified by ID, from a simulation param
 * @param param
 * @param id
 * @returns true if a sliding interface was deleted
 */
export function removeSlidingInterface(
  param: simulationpb.SimulationParam,
  id: string,
) {
  return param.physics.some((physics) => {
    const subPhysics = getSubPhysics(physics);

    if (subPhysics) {
      const oldInterfaces = subPhysics.slidingInterfaces;
      const newInterfaces = oldInterfaces.filter((item) => item.slidingInterfaceId !== id);
      if (newInterfaces.length < oldInterfaces.length) {
        subPhysics.slidingInterfaces = newInterfaces;
      }
    }
    return false;
  });
}

// Assign surfaces to one side of a SlidingInterface. If dryRun is true, we only check for errors
// and don't make any assignments.
export function assignSlidingInterfaceSurfaces(
  param: simulationpb.SimulationParam,
  id: string,
  sideA: boolean,
  surfaceIds: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
  entityGroupData: EntityGroupData,
  dryRun: boolean,
): string[] {
  const physics = findParentPhysicsBySlidingInterfaceId(param, id);
  if (!physics) {
    // Should never happen
    return ['Sliding interface does not belong to a physics'];
  }

  const errors: string[] = [];
  const physicsId = getPhysicsId(physics);
  const surfacesToPhysics = getSurfaceToPhysicsMap(param, geometryTags, staticVolumes);
  const slidingInterface = findSlidingInterfaceById(param, id);
  assert(!!slidingInterface, 'Invalid sliding interface ID');

  // If any of the surfaces are already assigned, return an error and don't make any assignments.
  const assignedSurfaces = new Set(
    unwrapSurfaceIds(
      getAssignedSurfaces(param, geometryTags, entityGroupData),
      geometryTags,
      entityGroupData,
    ),
  );
  const currentSurfaces = new Set(sideA ?
    unwrapSurfaceIds(slidingInterface.slidingA, geometryTags, entityGroupData) :
    unwrapSurfaceIds(slidingInterface.slidingB, geometryTags, entityGroupData));

  unwrapSurfaceIds(surfaceIds, geometryTags, entityGroupData).forEach((surfaceId) => {
    // Search all entries for availability
    const surfaceName = boldEscaped(
      entityGroupData.groupMap.has(surfaceId) ?
        entityGroupData.groupMap.get(surfaceId).name :
        removeBoundsPrefix(surfaceId),
    );
    if (!currentSurfaces.has(surfaceId)) {
      if (surfacesToPhysics[surfaceId] !== physicsId) {
        errors.push(`Physics volume selections do not contain surface ${surfaceName}.`);
      } else if (assignedSurfaces.has(surfaceId)) {
        errors.push(`${surfaceName} is already defined.`);
      }
    }
  });

  // Update the default boundary condition members and nodelist members if there are no errors
  if (!errors.length && !dryRun) {
    if (sideA) {
      slidingInterface.slidingA = surfaceIds;
    } else {
      slidingInterface.slidingB = surfaceIds;
    }
  }

  return errors;
}
