// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { memo, useEffect } from 'react';

import { CommonMenuItem, CommonMenuPosition } from '../../lib/componentTypes/menu';
import useButtonMenu from '../../lib/useButtonMenu';
import { CommonMenu, CommonMenuProps } from '../Menu/CommonMenu';

import { ActionButton, ActionButtonProps } from './ActionButton';

export interface MenuButtonProps extends ActionButtonProps {
  menuProps?: Partial<CommonMenuProps>;
  menuItems: CommonMenuItem[];
  position?: CommonMenuPosition;
  onToggle?: (open: boolean) => void;
}

// An ActionButton component that, when clicked, opens a CommonMenu
export const MenuButton = memo((props: MenuButtonProps) => {
  const { menuItems, menuProps, onToggle, position, ...buttonProps } = props;

  const { anchorEl, anchorRef, onClickButton, onCloseMenu } = useButtonMenu();

  useEffect(() => onToggle?.(!!anchorEl), [anchorEl, onToggle]);

  return (
    <div ref={anchorRef}>
      <ActionButton
        {...buttonProps}
        aria-haspopup="true"
        endIcon={{ name: 'caretDown', maxHeight: 4 }}
        justifyContent="space-between"
        onClick={(event) => {
          onClickButton(event);
          props.onClick?.(event);
        }}
      />
      <CommonMenu
        {...menuProps}
        anchorEl={anchorEl}
        menuItems={menuItems}
        onClose={onCloseMenu}
        open={!!anchorEl}
        position={position}
      />
    </div>
  );
});

MenuButton.displayName = 'MenuButton';
