// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties } from 'react';

import cx from 'classnames';

import { clamp } from '../../../lib/number';

import './ProgressBar.scss';

const DEFAULT_BAR_COLOR = 'var(--color-primary-cta)';
const DEFAULT_BACKGROUND_COLOR = 'var(--color-surface-background)';

export interface ProgressBarProps {
  height: number;
  progress: number | null;
  barColor?: string;
  backgroundColor?: string;
  rounded?: boolean;
  // the time it takes for the determinate progress bar to transition from one value to the next
  transitionDuration?: number;
}

export const ProgressBar = (props: ProgressBarProps) => {
  // Props
  const { progress, rounded = true, transitionDuration = 1000 } = props;
  const barColor = props.barColor || DEFAULT_BAR_COLOR;
  const bgColor = props.backgroundColor || DEFAULT_BACKGROUND_COLOR;

  // Other state
  const variant = progress === null ? 'indeterminate' : 'determinate';
  const barWidth = progress === null ? '' : `${clamp(progress, [0, 1]) * 100}%`;
  const barTransition = `${transitionDuration}ms`;
  const height = `${props.height}px`;
  const style = {
    '--height': height,
    '--bar-width': barWidth,
    '--bar-color': barColor,
    '--bg-color': bgColor,
    '--bar-transition': barTransition,
  } as CSSProperties;

  return (
    <div
      className={cx('progressBar', variant, { rounded })}
      style={style}>
      <div className="bar" />
    </div>
  );
};
