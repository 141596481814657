// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { useParaviewContext } from '../../components/Paraview/ParaviewManager';
import { useProjectContext } from '../../components/context/ProjectContext';
import { toggleVisibility } from '../../lib/entityGroupUtils';
import { useEntityGroupMap } from '../entityGroupState';
import { useLcVisEnabledValue } from '../lcvis/lcvisEnabledState';
import { useSetLcvisVisibilityMap } from '../lcvis/lcvisVisibilityMap';

/**
 * Returns a function that toggles the visibilities of the nodes specified in either PV or LCVis.
 *
 * @param nodeIds the ids whose visibilities will change
 * @param isVisible whether the ids are currently visible. The visibility of all nodes in nodeIds
 * will be set to !isVisible.
 */
export const useToggleVisibility = (nodeIds: Set<string>, isVisible: boolean) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const { visibilityMap, setVisibility } = useParaviewContext();

  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const setVisibilityV2 = useSetLcvisVisibilityMap({ projectId, workflowId, jobId });

  const toggleIds = useCallback(() => {
    if (lcvisEnabled) {
      setVisibilityV2(
        (currVisibility) => toggleVisibility(
          currVisibility,
          entityGroupMap,
          nodeIds,
          !isVisible,
        ),
      );
    } else {
      setVisibility(toggleVisibility(
        visibilityMap,
        entityGroupMap,
        nodeIds,
        !isVisible,
      ));
    }
  }, [
    entityGroupMap,
    setVisibilityV2,
    visibilityMap,
    setVisibility,
    isVisible,
    nodeIds,
    lcvisEnabled,
  ]);

  return toggleIds;
};
