// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useMemo, useState } from 'react';

import { colors } from '../../lib/designSystem';
import { ERRORS } from '../../lib/errors';
import { formatDay } from '../../lib/formatDate';
import * as rpc from '../../lib/rpc';
import { addRpcError, addSuccess } from '../../lib/transientNotification';
import * as librarypb from '../../proto/library/library_pb';
import { useLoadCameraList } from '../../recoil/cameraState';
import { useRefreshLibraryState } from '../../recoil/library';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { usePersonalLibrary, useRefreshPersonalLibrary } from '../../state/external/library/personalLibrary';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { TextInput } from '../Form/TextInput';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { createStyles, makeStyles } from '../Theme';
import { CollapsibleCard } from '../layout/CollapsibleCard';
import { SearchIcon } from '../svg/SearchIcon';
import { DialogSymbol } from '../visual/DialogSymbol';

import { Dialog } from './Base';

const useStyles = makeStyles(
  () => createStyles({
    listTitle: {
      color: colors.lowEmphasisText,
      fontSize: '10px',
      lineHeight: '16px',
    },
    rowContainer: {
      '& .CollapsibleCard-card': {
        fontSize: '13px',
        lineHeight: '16px',
      },
      '& .CollapsibleCard-description': {
        fontSize: '10px',
        lineHeight: '14px',
      },
    },
    spacer: {
      height: '18px',
    },
    empty: {
      color: colors.lowEmphasisText,
      lineHeight: '20px',
      paddingBottom: '16px',
      borderBottom: `1px solid ${colors.neutral350}`,
    },
  }),
  { name: 'ImportLibraryItem' },
);

export interface ImportLibraryItemDialogProps {
  open: boolean;
  // The project to which the settings will be imported.
  projectId: string;
  onClose: () => void;
}

export const ImportLibraryItemDialog = (props: ImportLibraryItemDialogProps) => {
  // Props
  const { open, projectId } = props;

  const projectName = useProjectMetadata(projectId)?.summary?.name;
  const { paraviewActiveUrl } = useParaviewContext();
  // State
  const [
    selectedItem,
    setSelectedItem,
  ] = useState<librarypb.LibraryItem | undefined>(undefined);
  const [filter, setFilter] = useState('');
  const items = usePersonalLibrary();

  const filteredItems = useMemo(() => (
    items?.filter((item) => item.name.toLowerCase().includes(filter.toLowerCase()))
  ), [filter, items]);

  // Hooks
  const classes = useStyles();
  const setConfirmStack = useSetConfirmations();
  const loadCameraList = useLoadCameraList(projectId);
  const refreshLibraryState = useRefreshLibraryState(projectId, paraviewActiveUrl);
  const refreshPersonalLibrary = useRefreshPersonalLibrary();

  // Effects
  useEffect(() => {
    if (open) {
      setSelectedItem(undefined);
      setFilter('');
      refreshPersonalLibrary();
    }
  }, [open, refreshPersonalLibrary]);

  // Method
  const applyItem = async () => {
    const req = new librarypb.ApplyItemRequest({
      projectId,
      item: selectedItem,
    });
    await rpc.callRetry('ApplyLibraryItem', rpc.client.applyLibraryItem, req).then(() => {
      addSuccess(selectedItem!.name, 'Library item imported');
    }).catch((err) => {
      addRpcError(ERRORS.ImportLibraryItem, err, { projectId, projectName });
    });
  };

  // Handlers
  const handleSubmit = async () => {
    pushConfirmation(setConfirmStack, {
      continueLabel: 'Confirm',
      destructive: false,
      onContinue: async () => {
        await applyItem();
        await refreshLibraryState();
        await loadCameraList();
        props.onClose();
      },
      symbol: (
        <DialogSymbol
          colorBase="yellow"
          iconName="warning"
          size={40}
        />
      ),
      title: 'Confirm import',
      children: (
        <div>
          Are you sure you want to override your existing settings with new settings from
          the library? This cannot be undone.
        </div>
      ),
    });
  };

  return (
    items && items.length > 0 ? (
      <Dialog
        cancelButton={{ label: 'Cancel' }}
        continueButton={{ label: 'Import', disabled: !selectedItem }}
        modal
        onClose={props.onClose}
        onContinue={handleSubmit}
        open={open}
        subtitle={`Importing settings from the Library will completely \
                  override your existing settings.`}
        title="Import From Library"
        width="360px">
        <TextInput
          asBlock
          dataLocator="libraryItemFilter"
          onChange={setFilter}
          placeholder="Search…"
          startAdornment={<SearchIcon maxHeight={12} maxWidth={12} />}
          value={filter}
        />
        <div className={classes.spacer} />
        <div className={classes.listTitle}>PROJECT SETTINGS</div>
        <div className={classes.rowContainer}>
          {filteredItems?.map((item) => {
            const createdStr = formatDay(item.createTime!.toDate());
            return (
              <CollapsibleCard
                key={item.id}
                onClick={() => setSelectedItem(item)}
                rounded
                selected={selectedItem?.id === item.id}
                title={item.name}>
                Created on {createdStr}
                <br />
                {item.description}
              </CollapsibleCard>
            );
          })}
        </div>
      </Dialog>
    ) : (
      <Dialog
        continueButton={{ label: 'OK' }}
        modal
        onClose={props.onClose}
        onContinue={props.onClose}
        open={open}
        title="The Library is empty">
        <div className={classes.empty}>
          You don&apos;t have any items in your library. Save items to your library from the
          properties panel to import them into projects later.
        </div>
      </Dialog>
    )
  );
};
