// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/lcc/internal.proto (package luminary.proto.lcc, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Any, proto3 } from "@bufbuild/protobuf";
import { Operator } from "./lcc_pb.js";

/**
 * CompletionCode is used by operators to signal whether or not the requested
 * operation was executed successfully.
 *
 * @generated from enum luminary.proto.lcc.CompletionCode
 */
export const CompletionCode = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.lcc.CompletionCode",
  [
    {no: 0, name: "UNKNOWN"},
    {no: 1, name: "SUCCESS"},
    {no: 2, name: "FAILURE"},
  ],
);

/**
 * Operation logically defines a deterministic, atomic unit of execution in the
 * lcc framework.
 *
 * @generated from message luminary.proto.lcc.Operation
 */
export const Operation = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcc.Operation",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "operator", kind: "message", T: Operator },
    { no: 3, name: "input", kind: "message", T: Any },
  ],
);

/**
 * Job encapsulates an lcc operation and everything necessary to execute the
 * operation.
 *
 * @generated from message luminary.proto.lcc.Job
 */
export const Job = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcc.Job",
  () => [
    { no: 1, name: "operation", kind: "message", T: Operation },
    { no: 2, name: "jwt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "output_location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Manifest is a record of the result of executing an operation.
 *
 * @generated from message luminary.proto.lcc.Manifest
 */
export const Manifest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcc.Manifest",
  () => [
    { no: 1, name: "operation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "output", kind: "message", T: Any },
    { no: 3, name: "code", kind: "enum", T: proto3.getEnumType(CompletionCode) },
    { no: 4, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * OperatorArgs is the set of input and output objects paths passed to the
 * executing operator.
 *
 * @generated from message luminary.proto.lcc.OperatorArgs
 */
export const OperatorArgs = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcc.OperatorArgs",
  () => [
    { no: 1, name: "input", kind: "message", T: Any },
    { no: 2, name: "output", kind: "message", T: Any },
    { no: 3, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "manifest_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "assignment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

