// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { colors } from '../../../lib/designSystem';
import { createStyles, makeStyles } from '../../Theme';
import { TREE_VERTICAL_PADDING } from '../../project/treePanelShared';

const BORDER_HEIGHT = 1;
export const SEPARATOR_HEIGHT = BORDER_HEIGHT + 2 * TREE_VERTICAL_PADDING;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      borderTop: `${BORDER_HEIGHT}px solid ${colors.neutral150}`,
      paddingTop: `${TREE_VERTICAL_PADDING}px`,
      marginTop: `${TREE_VERTICAL_PADDING}px`,
    },
  }),
  { name: 'SeparatorTreeRow' },
);

export const Separator = () => {
  const classes = useStyles();

  return <div className={classes.root} />;
};
