// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVType } from '@luminarycloudinternal/lcvis';

import { colors, hexToRgbList } from '../../../../designSystem';
import { LcvModule } from '../../../types';
import * as AnnotationType from '../types';

import { LcvRefinementRegionAnnotation } from './LcvRefinementRegionAnnotation';

export class LcvRefinementSphere extends LcvRefinementRegionAnnotation {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    region: AnnotationType.AnnotationParam,
  ) {
    super(
      lcv,
      AnnotationType.REFINEMENT_SPHERE,
      AnnotationType.REFINEMENT_REGION,
      sessionHandle,
    );
    this.updateAnnotationParam(region);
  }

  updateAnnotationParam(region: AnnotationType.AnnotationParam) {
    if (region.param.typ === AnnotationType.REFINEMENT_SPHERE) {
      this.setParam('center', LCVType.kLCVDataTypeFloat3, region.param.center);
      this.setParam('outer_radius', LCVType.kLCVDataTypeFloat, region.param.outerRadius);
      this.setParam('inner_radius', LCVType.kLCVDataTypeFloat, region.param.innerRadius);
      this.setParam('visible', LCVType.kLCVDataTypeUint, Number(region.visible));
      this.setParam(
        'color',
        LCVType.kLCVDataTypeFloat3,
        region.selected ? hexToRgbList(colors.rrSelectedColor) :
          hexToRgbList(colors.rrDeselectedColor),
      );
    }
  }
}
