// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

/**
 * Javascript language utilities, mostly handling Set and Array arithmetic and comparisons
 */

type Group<T> = Set<T> | T[];

/**
 * Return a Set that is the intersection of two input Sets (or lists)
 * @param itemsA Set or Array
 * @param itemsB Set or Array
 * @returns Set
 */
export function intersectSet<T>(itemsA: Group<T>, itemsB: Group<T>): Set<T> {
  const setB = new Set(itemsB);
  return new Set([...itemsA].filter((value: T) => setB.has(value)));
}

/**
 * Return true if two input Sets (or lists) have any elements in common
 * @param itemsA Set or Array
 * @param itemsB Set or Array
 * @returns boolean
 */
export function intersects<T>(itemsA: Group<T>, itemsB: Group<T>): boolean {
  const setB = new Set(itemsB);
  return [...itemsA].some((value: T) => setB.has(value));
}

/**
 * Return a Set that is the union of two input Sets (or lists)
 * @param itemsA Set or Array
 * @param itemsB Set or Array
 * @returns Set
 */
export function unionSet<T>(itemsA: Group<T>, itemsB: Group<T>): Set<T> {
  const setA = new Set(itemsA);
  const setB = new Set(itemsB);
  return new Set([...setA, ...setB]);
}

/**
 * Return the result of subtracting one Set or list (the subtrahend) from another Set or list (the
 * minuend)
 * @param minuendItems Set or Array
 * @param subtrahendItems Set or Array
 * @returns Set
 */
export function subtractSet<T>(minuendItems: Group<T>, subtrahendItems: Group<T>): Set<T> {
  const setSubtrahend = subtrahendItems instanceof Set ? subtrahendItems : new Set(subtrahendItems);
  return new Set([...minuendItems].filter((value: T) => !setSubtrahend.has(value)));
}

/**
 * Return true if `items` is a superset of `subitems`
 * @param items Set or Array
 * @param subItems Set or Array
 * @returns boolean
 */
export function isSuperset<T>(items: Group<T>, subItems: Group<T>): boolean {
  const set = new Set(items);
  return [...subItems].every((value: T) => set.has(value));
}

/**
 * Return true if `itemsA` and `itemsB` have exactly the same members
 * @param itemsA Set or Array
 * @param itemsB Set or Array
 * @returns boolean
 */
export function areSetsEquivalent<T>(itemsA: Group<T>, itemsB: Group<T>): boolean {
  return isSuperset(itemsA, itemsB) && isSuperset(itemsB, itemsA);
}

/**
 * Return the result of subtracting one Array (the subtrahend) from another Array (the minuend)
 * @param minuend Array
 * @param subtrahend Array
 * @returns Array
 */
export function subtractArray<T>(minuend: T[], subtrahend: T[]): T[] {
  const subtrahendSet = new Set(subtrahend);
  return minuend.filter((value: T) => !subtrahendSet.has(value));
}

/**
 * If `test` is provided, add an item to a Set or delete it from the set, depending on the value of
 * `test`.  Otherwise, toggle the item into or out of the set, depending on whether the item is
 * already in the set or not.
 * @param set Set
 * @param item Set member
 * @param test boolean
 */
export function toggleSetItem<T>(set: Set<T>, item: T, test?: boolean) {
  if (test ?? !set.has(item)) {
    set.add(item);
  } else {
    set.delete(item);
  }
}

/**
 * Return true if `arr1` and `arr2` have exactly the same members in the same order
 * @param arr1
 * @param arr2
 * @returns boolean
 */
export function areArraysEqual<T>(arr1: T[], arr2: T[]) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((elem1, i) => elem1 === arr2[i]);
}

/**
 * Return true if `arr1` and `arr2` have exactly the same numbers in each position, within a given
 * tolerance
 * @param arr1 Array of numbers
 * @param arr2 Array of numbers
 * @param tolerance number (defaults to 1e-3)
 * @returns
 */
export function areArraysNear(arr1: number[], arr2: number[], tolerance = 1e-3) {
  return arr1.every((val, index) => Math.abs(val - arr2[index]) <= tolerance);
}
