// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { getMenuButtonOpacity } from '../../lib/jobTableUtils';
import { ActionButton, ActionButtonProps } from '../Button/ActionButton';
import { createStyles, makeStyles } from '../Theme';
import { FixedSizeWrapper } from '../layout/FixedSizeWrapper';
import { HorizontalCirclesTripleIcon } from '../svg/HorizontalCirclesTripleIcon';

import { useHoveredRowIdValue, useSelectedRowIdValue } from './state';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      transition: 'opacity 500ms',
    },
  }),
  { name: 'JobTableMenuButton' },
);

interface MenuButtonProps {
  rowId: string;
  onClick?: ActionButtonProps['onClick'];
}

export function MenuButton(props: MenuButtonProps) {
  const { onClick, rowId } = props;

  const rowHovered = useHoveredRowIdValue();
  const rowSelected = useSelectedRowIdValue();

  const classes = useStyles();
  const opacity = getMenuButtonOpacity(rowId, rowHovered, rowSelected);
  return (
    <span
      className={classes.root}
      style={{ opacity }}>
      <ActionButton
        compact
        disabled={!opacity}
        kind="secondary"
        onClick={onClick}
        size="small">
        <FixedSizeWrapper height={16} width={16}>
          <HorizontalCirclesTripleIcon maxHeight={10} maxWidth={10} />
        </FixedSizeWrapper>
      </ActionButton>
    </span>
  );
}
