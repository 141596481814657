// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { Line } from '@visx/shape';

import { colors } from '../../../lib/designSystem';
import { formatNumber } from '../../../lib/number';
import { AxisRange } from '../../../lib/sensitivityUtils';
import { createStyles, makeStyles } from '../../Theme';
import Tooltip from '../../Tooltip';

import { NumberDisplay } from './NumberDisplay';
import { Y_PIXELS } from './SensitivityGraph';

const useStyles = makeStyles(
  () => createStyles({
    outputCell: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
      gap: '8px',
    },
    outputLeft: {
      color: '#8e8e8f',
      textAlign: 'left',
      width: '135px',
    },
    outputLeftText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '128px',
    },
    outputCenter: {
      background: colors.surfaceDark3,
      borderRadius: '4px',
      width: '94px',
      height: '64px',
    },
    outputRight: {
      color: colors.highEmphasisText,
      textAlign: 'right',
      width: '135px',
    },
  }),
  { name: 'OutputCell' },
);

interface OutputRowHeaderProps {
  // The name of the output.
  name: string;
  // The adjusted output value.
  yAdjusted: number;
  // The range of the y-axis.
  yRange: AxisRange;
  // Top margin added to the row.
  marginTop?: number;
  // Bottom margin added to row.
  marginBottom?: number;
}

// The start of an output row. Shows the sum of the changes made by each of
// inputs.
const OutputRowHeader = (props: OutputRowHeaderProps) => {
  const classes = useStyles();
  const percentChange = 100 * (props.yAdjusted / props.yRange.center - 1);
  // The y-position of the adjusted output value.
  const yAdjustedPixels = (
    -(
      (props.yAdjusted - props.yRange.center) /
      (props.yRange.max - props.yRange.min)
    ) *
    (Y_PIXELS.max - Y_PIXELS.min) +
    Y_PIXELS.center
  );
  return (
    <div
      className={classes.outputCell}
      style={{ marginTop: props.marginTop ?? 24, marginBottom: props.marginBottom ?? 24 }}>
      <span className={classes.outputLeft}>
        <div
          className={classes.outputLeftText}>
          <Tooltip
            title={props.name}><span>{props.name}</span>
          </Tooltip>
        </div>
        <div>{formatNumber(props.yRange.center)}</div>
      </span>
      <span className={classes.outputCenter}>
        <svg height="64px" width="94px">
          <g>
            <Line
              key="bar"
              stroke={colors.primaryCta}
              strokeWidth="20"
              x1={47}
              x2={47}
              y1={Y_PIXELS.center}
              y2={yAdjustedPixels}
            />
            <Line
              key="y-axis"
              stroke={colors.neutral450}
              strokeWidth="1"
              x1={9}
              x2={85}
              y1={Y_PIXELS.center}
              y2={Y_PIXELS.center}
            />
          </g>
        </svg>
      </span>
      <span className={classes.outputRight}>
        <div style={{ float: 'right' }}>
          <NumberDisplay digits={2} percent={percentChange} />
        </div>
        <div style={{ clear: 'right' }}>
          {formatNumber(props.yAdjusted)}
        </div>
      </span>
    </div>
  );
};

export default OutputRowHeader;
