// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { getVolumeName } from '../../lib/volumeUtils';
import { useEntityGroupMap } from '../../recoil/entityGroupState';
import { useStaticVolumes, useSurfaceToVolumes } from '../../recoil/volumes';
import { useProjectContext } from '../context/ProjectContext';

export const useVolumeNodes = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const staticVolumes = useStaticVolumes(projectId);
  const surfaceVolumeMap = useSurfaceToVolumes(projectId);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);

  // Return a volume label for a given surface ID (used in NodeTables)
  const surfaceIdToVolumeScope = (surfaceId: string) => {
    const volume = surfaceVolumeMap.get(surfaceId);

    if (volume) {
      return `[${getVolumeName(volume.id, staticVolumes, entityGroupMap)}]`;
    }
    return '[Unknown]';
  };

  return {
    surfaceIdToVolumeScope,
  };
};
