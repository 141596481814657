// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { TreeRow } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'circle', maxHeight: 4.5, maxWidth: 4.5 };

export const PointContainerTreeRow = (props: SimulationRowProps) => (
  <TreeRow
    {...props}
    primaryIcon={PRIMARY_ICON}
  />
);
