// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const SurfaceLICIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="surfaceLIC"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M2.143,14.693
           C1.141,14.045 0.348,12.963 0,11.898
           C0.006,11.31 0.055,10.725 0.145,10.15
           C0.744,10.989 1.537,11.778 2.581,12.406
           C4.252,13.41 6.421,13.911 9.189,13.698
           C14.022,13.327 16.932,15.026 18.644,16.699
           C19.524,17.559 20.119,18.445 20.494,19.116
           C20.681,19.451 20.811,19.728 20.892,19.916
           C20.907,19.952 20.92,19.984 20.932,20.013
           C20.573,20.414 20.184,20.794 19.768,21.147
           C19.419,20.516 18.962,19.828 18.367,19.153
           C16.367,16.887 12.919,14.9 7.231,15.518
           C4.748,15.788 3.164,15.354 2.143,14.693Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M22.321,18.117
           C21.853,17.315 21.147,16.306 20.135,15.317
           C17.881,13.114 14.231,11.135 8.693,11.56
           C6.275,11.745 4.658,11.29 3.569,10.635
           C2.524,10.007 1.41,8.464 0.909,7.426
           C1.004,7.197 1.105,6.971 1.213,6.749
           C1.353,6.844 1.527,6.96 1.731,7.089
           C2.285,7.44 3.067,7.896 3.974,8.316
           C5.724,9.125 8.208,9.92 10.417,9.186
           C11.575,8.801 12.562,7.935 13.369,6.985
           C14.188,6.019 14.897,4.874 15.473,3.799
           C15.846,3.104 16.379,1.944 16.795,1.003
           C19.552,2.205 21.863,4.438 23.096,7.44
           C24.584,11.061 24.172,14.989 22.321,18.117Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M16.56,23.096
           C10.449,25.608 3.462,22.705 0.925,16.613
           C2.49,17.617 4.622,18.076 7.451,17.769
           C12.416,17.229 15.149,18.942 16.668,20.663
           C17.203,21.27 17.601,21.894 17.892,22.452
           C17.467,22.691 17.023,22.906 16.56,23.096Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
      <path
        d="M2.233,5.031
           C2.366,5.123 2.56,5.254 2.803,5.408
           C3.302,5.724 4,6.131 4.799,6.501
           C6.461,7.269 8.296,7.75 9.711,7.28
           C10.366,7.062 11.07,6.509 11.782,5.671
           C12.48,4.847 13.115,3.831 13.657,2.822
           C14.142,1.916 14.541,1.036 14.832,0.341
           C12.454,-0.238 9.877,-0.098 7.44,0.904
           C5.27,1.796 3.501,3.251 2.233,5.031Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
