// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

// ProjectAccessDenied is a dialog that is shown when the user has opened a particular project that
// was shared to them an in that current session their access to the project gets revoked by owner.

import React, { useCallback, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { colors } from '../../lib/designSystem';
import { isInProject, projectsLink } from '../../lib/navigation';
import { ProjectDeniedStatus, useProjectDeniedDialog } from '../../state/external/project/sharing';
import { createStyles, makeStyles } from '../Theme';

import { Dialog } from './Base';

const useStyles = makeStyles(
  () => createStyles({
    text: {
      color: colors.lowEmphasisText,
    },
  }),
  { name: 'ProjectDeniedDialog' },
);

export const ProjectDeniedDialog = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [projectDeniedDialog, setProjectDeniedDialog] = useProjectDeniedDialog();

  const resetProjectDeniedDialog = useCallback(() => {
    setProjectDeniedDialog({ open: false, type: ProjectDeniedStatus.NONE, projectId: '' });
  }, [setProjectDeniedDialog]);

  const onClose = () => {
    resetProjectDeniedDialog();
    navigate(projectsLink(), { replace: true });
  };

  const classes = useStyles();

  let title = '';
  let message = '';

  if (projectDeniedDialog.type === ProjectDeniedStatus.DELETED) {
    title = 'This project is no longer available';
    message = 'This project has been deleted by its owner.';
  } else if (projectDeniedDialog.type === ProjectDeniedStatus.NO_ACCESS) {
    title = 'You need permission to view this project';
    message = 'Your access to this project has been revoked by its owner.';
  }

  // When a user loses access to a project while the project is currently opened, that correctly
  // causes the useProjectMetadata hook to show the AccessDenied error dialog. The problem is that
  // the user may leave the project on their own from the Project's header menu and that would also
  // cause the useProjectMetadata hook to show the error, even though the user has been redirected
  // to the project list page. We can work around that, by disabling the error dialog if the user is
  // no longer on the project page.
  useEffect(() => {
    if (
      projectDeniedDialog.open &&
      !isInProject(location.pathname, projectDeniedDialog.projectId)
    ) {
      resetProjectDeniedDialog();
    }
  }, [projectDeniedDialog, location, resetProjectDeniedDialog]);

  return (
    <Dialog
      continueButton={{ label: 'Go to projects' }}
      modal
      onClose={() => onClose()}
      onContinue={() => onClose()}
      open={projectDeniedDialog.open}
      title={title}>
      <div className={classes.text}>
        {message}
      </div>
    </Dialog>
  );
};
