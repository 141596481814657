// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo, useRef } from 'react';

import { AnyKeyboardEvent } from '../../../../lib/event';
import { isEventTrigger } from '../../../../lib/keyBindings';
import { ListenerEvent, useEventListener } from '../../../../lib/useEventListener';
import { findFilterTreeNode } from '../../../../lib/visUtils';
import { useEntityGroupMap } from '../../../../recoil/entityGroupState';
import { useLcvisProbeState } from '../../../../recoil/lcvis/lcvisProbeState';
import { useFilterState } from '../../../../recoil/vis/filterState';
import { useRightOverlayCardsWidthValue } from '../../../../state/internal/component/rightOverlayCards';
import { useProjectContext } from '../../../context/ProjectContext';
import { OverlayValue } from '../util';

import ProbeCard from './ProbeCard';
import { ProbeLabel } from './ProbeLabel';

import '../Overlay.scss';

const ProbeOverlay = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const [filterState] = useFilterState({ projectId, workflowId, jobId });
  const [lcvisProbeState, setLcvisProbeState] = useLcvisProbeState();
  const rightCardsWidth = useRightOverlayCardsWidthValue();
  const removeProbe = useCallback(() => {
    setLcvisProbeState((oldProbe) => (
      { ...oldProbe, active: false, probedId: '', placedProbe: false }
    ));
  }, [setLcvisProbeState]);
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const probeData: Map<string, OverlayValue> = useMemo(() => {
    const { coordinates, probedId } = lcvisProbeState;

    const map = new Map<string, OverlayValue>();
    if (!coordinates || !probedId) {
      return map;
    }
    let probedName = entityGroupMap.has(probedId) ? entityGroupMap.get(probedId).name : probedId;
    if (probedName === probedId) {
      const node = findFilterTreeNode(filterState, probedId);
      if (node) {
        probedName = node.name;
      }
    }
    map.set('Entity Name', probedName);
    map.set('Location', coordinates);
    return map;
  }, [lcvisProbeState, entityGroupMap, filterState]);

  // Pressing escape will exit the overlay.
  const handleExitOverlay = useCallback((event: AnyKeyboardEvent) => {
    if (isEventTrigger('exitOverlay', event)) {
      event.preventDefault();
      removeProbe();
    }
  }, [removeProbe]);
  useEventListener(
    'keydown',
    (event: ListenerEvent) => handleExitOverlay(event as AnyKeyboardEvent),
  );

  return (
    <div
      className="overlayContainer"
      ref={overlayRef}
      style={{ width: `calc(100% - ${rightCardsWidth}px` }}>
      {/* an empty div to occupy the 1st column in the grid */}
      <div />
      <ProbeLabel onExit={removeProbe} />
      {probeData.size ? (
        <ProbeCard
          data={probeData}
          disableReset={false}
          onReset={() => {
            setLcvisProbeState((prev) => (
              { ...prev, placedProbe: false, probedId: '', coordinates: undefined }
            ));
          }}
          title="Probed Values"
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default ProbeOverlay;
