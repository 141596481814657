// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useMemo } from 'react';

import { Empty } from '@bufbuild/protobuf';
import { ConnectError } from '@connectrpc/connect';
import { atom, useRecoilState } from 'recoil';

import { AuthState } from '../lib/AuthInfoCallback';
import { getLcUserId } from '../lib/jwt';
import * as rpc from '../lib/rpc';
import { addRpcError } from '../lib/transientNotification';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { useAuthInfoV2Value } from '../state/external/auth/authInfo';

import { useRemainingCredits } from './useCreditsPoller';

const rpcPool = new rpc.StreamingRpcPool<Empty, frontendpb.AccountInfoReply>(
  'AccountInfo',
  rpc.client.accountInfo,
);

export const accountInfoState = atom<frontendpb.AccountInfoReply | null>({
  key: 'accountInfo',
  default: null,
});

export default function useAccountInfo(): frontendpb.AccountInfoReply | null {
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoState);
  const authInfoV2 = useAuthInfoV2Value();
  const isAuthenticated = authInfoV2.authState === AuthState.AUTHENTICATED;

  useEffect(
    () => {
      if (!isAuthenticated) {
        return;
      }

      return rpcPool.start(
        'AccountInfo',
        () => new Empty(),
        (reply: frontendpb.AccountInfoReply) => setAccountInfo(reply),
        (err: ConnectError) => addRpcError('Failed to get account info', err),
      );
    },
    [isAuthenticated, setAccountInfo],
  );

  return accountInfo;
}

export const useCurrentUser = () => {
  const accountInfo = useAccountInfo();

  return useMemo(() => (accountInfo?.user || []).find(
    (user) => user.lcUserId === getLcUserId(),
  ), [accountInfo]);
};

export const useIsAdmin = () => {
  const currentUser = useCurrentUser();
  return currentUser?.accountRole === 'admin';
};

export const useUserRemainingCredits = () => {
  const remainingCredits = useRemainingCredits();

  return remainingCredits;
};

/** The hook returns the boolean if user is using the starter plan. */
export const useIsStarterPlan = () => {
  const accountInfo = useAccountInfo();

  return accountInfo?.billingType === frontendpb.BillingType.TRIAL_BILLING_TYPE;
};

export const useNoCredits = () => {
  const isStarterPlan = useIsStarterPlan();
  const remainingCredits = useUserRemainingCredits();

  return isStarterPlan && remainingCredits !== null && remainingCredits <= 0;
};
