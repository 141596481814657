// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { formatNumber } from '../../../lib/number';

export type OverlayValue = string | number | number[]

/** Format a overlay value to show in the overlay card. */
export const formatOverlayValue = (value: OverlayValue): string => {
  if (typeof value === 'string') {
    return value;
  }
  if (Array.isArray(value)) {
    return `(${value.map((num) => num.toPrecision(6)).join(', ')})`;
  }
  return formatNumber(value);
};
