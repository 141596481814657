// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/vis/vis.proto (package luminary.proto.vis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { AdFloatType, AdVector3 } from "../base/base_pb.js";

/**
 * Type of seed placement
 *
 * @generated from enum luminary.proto.vis.SeedPlacementType
 */
export const SeedPlacementType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.vis.SeedPlacementType",
  [
    {no: 0, name: "RAKE"},
    {no: 1, name: "GRID"},
    {no: 2, name: "GLOBE"},
    {no: 3, name: "SURFACE"},
    {no: 4, name: "LIC"},
  ],
);

/**
 * @generated from enum luminary.proto.vis.IntegrationDirection
 */
export const IntegrationDirection = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.vis.IntegrationDirection",
  [
    {no: 0, name: "FORWARD"},
    {no: 1, name: "BACKWARD"},
    {no: 2, name: "BOTH"},
  ],
);

/**
 * @generated from enum luminary.proto.vis.LicSurfaceType
 */
export const LicSurfaceType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.vis.LicSurfaceType",
  [
    {no: 0, name: "SURFACE_TYPE_INVALID"},
    {no: 1, name: "GEOMETRY_SURFACE"},
    {no: 2, name: "PLANE"},
  ],
);

/**
 * @generated from message luminary.proto.vis.ComputeMultiSliceRequest
 */
export const ComputeMultiSliceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.ComputeMultiSliceRequest",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "n_slices", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "start", kind: "message", T: AdVector3 },
    { no: 4, name: "end", kind: "message", T: AdVector3 },
    { no: 5, name: "project_vectors", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "digest", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.vis.ComputeMultiSliceReply
 */
export const ComputeMultiSliceReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.ComputeMultiSliceReply",
  () => [
    { no: 1, name: "result", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.vis.ComputePipelineRequest
 */
export const ComputePipelineRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.ComputePipelineRequest",
  () => [
    { no: 1, name: "workspace_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.vis.ComputePipelineReply
 */
export const ComputePipelineReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.ComputePipelineReply",
  () => [
    { no: 1, name: "filter_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "meta_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "data_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * ProgressInfo contains information about a request's execution progress.
 *
 * @generated from message luminary.proto.vis.ProgressInfo
 */
export const ProgressInfo = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.ProgressInfo",
  () => [
    { no: 1, name: "completed_units", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "total_units", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "details", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "indeterminate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "filter_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.vis.ComputePipelineStreamReply
 */
export const ComputePipelineStreamReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.ComputePipelineStreamReply",
  () => [
    { no: 1, name: "urls_reply", kind: "message", T: ComputePipelineReply },
    { no: 2, name: "progress", kind: "message", T: ProgressInfo, repeated: true },
    { no: 3, name: "resource_acquired", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.vis.SeedPlacementParams
 */
export const SeedPlacementParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.SeedPlacementParams",
  () => [
    { no: 1, name: "n_streamlines", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "rake_params", kind: "message", T: SeedPlacementParams_RakeParams, opt: true },
    { no: 3, name: "grid_params", kind: "message", T: SeedPlacementParams_GridParams, opt: true },
    { no: 4, name: "globe_params", kind: "message", T: SeedPlacementParams_GlobeParams, opt: true },
    { no: 5, name: "surface_params", kind: "message", T: SeedPlacementParams_SurfaceParams, opt: true },
    { no: 6, name: "lic_params", kind: "message", T: SeedPlacementParams_LICParams, opt: true },
  ],
);

/**
 * Define messages for each strategy
 *
 * @generated from message luminary.proto.vis.SeedPlacementParams.RakeParams
 */
export const SeedPlacementParams_RakeParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.SeedPlacementParams.RakeParams",
  () => [
    { no: 1, name: "start", kind: "message", T: AdVector3 },
    { no: 2, name: "end", kind: "message", T: AdVector3 },
  ],
  {localName: "SeedPlacementParams_RakeParams"},
);

/**
 * @generated from message luminary.proto.vis.SeedPlacementParams.GridParams
 */
export const SeedPlacementParams_GridParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.SeedPlacementParams.GridParams",
  () => [
    { no: 1, name: "center", kind: "message", T: AdVector3 },
    { no: 2, name: "u", kind: "message", T: AdVector3 },
    { no: 3, name: "v", kind: "message", T: AdVector3 },
    { no: 4, name: "seed_res", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "rake_res", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "seed_spacing", kind: "message", T: AdFloatType },
    { no: 7, name: "rake_spacing", kind: "message", T: AdFloatType },
  ],
  {localName: "SeedPlacementParams_GridParams"},
);

/**
 * @generated from message luminary.proto.vis.SeedPlacementParams.GlobeParams
 */
export const SeedPlacementParams_GlobeParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.SeedPlacementParams.GlobeParams",
  () => [
    { no: 1, name: "center", kind: "message", T: AdVector3 },
    { no: 2, name: "radius", kind: "message", T: AdFloatType },
  ],
  {localName: "SeedPlacementParams_GlobeParams"},
);

/**
 * @generated from message luminary.proto.vis.SeedPlacementParams.SurfaceParams
 */
export const SeedPlacementParams_SurfaceParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.SeedPlacementParams.SurfaceParams",
  () => [
    { no: 1, name: "surface_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "sample_rate", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "offset", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "project_on_surface", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "SeedPlacementParams_SurfaceParams"},
);

/**
 * @generated from message luminary.proto.vis.SeedPlacementParams.LICParams
 */
export const SeedPlacementParams_LICParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.SeedPlacementParams.LICParams",
  () => [
    { no: 2, name: "number_of_steps", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "surface_type", kind: "enum", T: proto3.getEnumType(LicSurfaceType) },
    { no: 4, name: "surfaces", kind: "message", T: SeedPlacementParams_LICParams_GeometrySurfaceLICParams, oneof: "params" },
    { no: 5, name: "plane", kind: "message", T: SeedPlacementParams_LICParams_PlaneLICParams, oneof: "params" },
  ],
  {localName: "SeedPlacementParams_LICParams"},
);

/**
 * @generated from message luminary.proto.vis.SeedPlacementParams.LICParams.GeometrySurfaceLICParams
 */
export const SeedPlacementParams_LICParams_GeometrySurfaceLICParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.SeedPlacementParams.LICParams.GeometrySurfaceLICParams",
  () => [
    { no: 1, name: "surface_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "SeedPlacementParams_LICParams_GeometrySurfaceLICParams"},
);

/**
 * @generated from message luminary.proto.vis.SeedPlacementParams.LICParams.PlaneLICParams
 */
export const SeedPlacementParams_LICParams_PlaneLICParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.SeedPlacementParams.LICParams.PlaneLICParams",
  () => [
    { no: 1, name: "origin", kind: "message", T: AdVector3 },
    { no: 2, name: "normal", kind: "message", T: AdVector3 },
    { no: 3, name: "min_pt", kind: "message", T: AdVector3 },
    { no: 4, name: "max_pt", kind: "message", T: AdVector3 },
    { no: 5, name: "clip_to_bounds", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "SeedPlacementParams_LICParams_PlaneLICParams"},
);

/**
 * @generated from message luminary.proto.vis.ComputeStreamlinesRequest
 */
export const ComputeStreamlinesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.ComputeStreamlinesRequest",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(SeedPlacementType) },
    { no: 3, name: "integration_direction", kind: "enum", T: proto3.getEnumType(IntegrationDirection) },
    { no: 4, name: "data_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "params", kind: "message", T: SeedPlacementParams },
    { no: 6, name: "maximum_length", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "digest", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.vis.ComputeStreamlinesReply
 */
export const ComputeStreamlinesReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.ComputeStreamlinesReply",
  () => [
    { no: 1, name: "result", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.vis.ComputeSurfaceLICRequest
 */
export const ComputeSurfaceLICRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.ComputeSurfaceLICRequest",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(SeedPlacementType) },
    { no: 3, name: "integration_direction", kind: "enum", T: proto3.getEnumType(IntegrationDirection) },
    { no: 4, name: "data_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "params", kind: "message", T: SeedPlacementParams },
    { no: 6, name: "maximum_length", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "digest", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.vis.ComputeSurfaceLICReply
 */
export const ComputeSurfaceLICReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.vis.ComputeSurfaceLICReply",
  () => [
    { no: 1, name: "result", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

