// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { RecoilProjectKey } from '../lib/persist';
import { getSimulationParam } from '../lib/simulationParamUtils';
import { isSimulationTransient } from '../lib/simulationUtils';

import { currentConfigSelector } from './workflowConfig';

// If the results are measured in seconds rather than steps.
// This only applies to transient simulations. For them, the default value is true.
const inTimeUnitSelector = atomFamily<boolean, RecoilProjectKey>({
  key: 'treeViewTypeState',
  default: selectorFamily<boolean, RecoilProjectKey>({
    key: 'reeViewTypeState/default',
    get: (key: RecoilProjectKey) => ({ get }) => {
      const currentConfig = get(currentConfigSelector(key));
      const simParam = getSimulationParam(currentConfig);
      return simParam ? isSimulationTransient(simParam) : false;
    },
  }),
});

export const useSetInTimeUnits = (
  key: RecoilProjectKey,
) => useSetRecoilState(inTimeUnitSelector(key));

export const useInTimeUnitsValue = (
  key: RecoilProjectKey,
) => useRecoilValue(inTimeUnitSelector(key));

export const useInTimeUnits = (
  key: RecoilProjectKey,
) => useRecoilState(inTimeUnitSelector(key));
