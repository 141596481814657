// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { explorationVariableNodeId, getCandidates, newExplorationVar } from '../../lib/explorationUtils';
import { updateExploration } from '../../lib/proto';
import * as explorationpb from '../../proto/exploration/exploration_pb';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useStaticVolumes } from '../../recoil/volumes';
import { useCurrentConfig } from '../../recoil/workflowConfig';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useSimulationParamScope } from '../../state/external/project/simulation/paramScope';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

import { AddNodeButton } from './AddNodeButton';

// A menu of items to add to the tree in the explorations mode.
const AddExplorationVarButton = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly, onNewWorkflowConfig } = useProjectContext();
  const { setSelection } = useSelectionContext();

  // == Recoil
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  // == Data
  const { exploration } = config;
  if (!exploration || readOnly) {
    return null;
  }
  const vars = exploration.var;

  // Called when add variable is selected.
  const addExplorationVar = () => {
    const candidates = getCandidates(
      explorationpb.VarType.GLOBAL,
      '',
      simParam,
      paramScope,
      exploration.policy.case === 'sensitivityAnalysis',
      vars,
      geometryTags,
      staticVolumes,
    );
    if (candidates.length === 0) {
      return;
    }
    const newVar = newExplorationVar(
      simParam,
      candidates[0],
      paramScope,
      exploration.policy.case === 'custom' ? 'column' : 'range',
      explorationpb.VarType.GLOBAL,
      '',
      geometryTags,
      staticVolumes,
    );
    const newExp = exploration.clone();

    if (!newExp.policy.case || newExp.policy.case === 'baseline') {
      newExp.policy = {
        case: 'gridSearch',
        value: new explorationpb.GridSearch(),
      };
    }
    newExp.var.push(newVar);

    onNewWorkflowConfig(updateExploration(config, newExp));
    setSelection([explorationVariableNodeId(vars.length)]);
  };

  return (
    <AddNodeButton addNode={addExplorationVar} />
  );
};

export default AddExplorationVarButton;
