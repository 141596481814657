// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 83;
const baseHeight = 110;
export const VerticalPlanesFilledOutlinedIcon = forwardRef<SVGSVGElement, SvgProps>(
  (props, ref) => {
    const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

    return (
      <svg
        data-icon-name="verticalPlanesFilledOutlined"
        height={finalHeight}
        ref={ref}
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          strokeLinejoin: 'round',
          strokeMiterlimit: 2,
        }}
        version="1.1"
        viewBox={`0 0 ${baseWidth} ${baseHeight}`}
        width={finalWidth}
        xmlSpace="preserve">
        <path
          d={
            `M53.003,83.161
             L53.003,109.989
             L0,80.253
             L0,9.999
             L29.997,26.892
             L29.997,0
             L83,29.85
             L83,99.99
             L53.003,83.161Z
             M53.003,72.302
             L73.53,83.817
             L73.53,35.386
             L39.468,16.203
             L39.468,32.226
             L53.003,39.849
             L53.003,72.302Z`
          }
          style={{ fill: getColor(0) }}
        />
      </svg>
    );
  },
);
