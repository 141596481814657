// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { DefaultValue, atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { animationLoop, easeOutQuart } from '../../lib/animationUtils';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { clamp } from '../../lib/number';

/** The duration of the explode out/collapse in animations. */
export const EXPLODE_ANIMATION_DURATION = 400;

/** Function to animate going from the current explode factor to zero. */
const animateToZero = (prev: number) => {
  animationLoop(prev ?? 0, 0, EXPLODE_ANIMATION_DURATION, (curr) => {
    lcvHandler.queueDisplayFunction('set explode factor', (display) => {
      display.workspace?.setExplodeFactor(curr * 2);
    });
  }, easeOutQuart);
};

/** This atom tracks the 'explode factor' for the exploded geometry view. */
export const lcvisExplodeFactor = atomFamily<number | null, string>({
  key: 'lcvisExplodeFactor',
  default: null,
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newVal, prev) => {
        if (newVal === null && !(prev instanceof DefaultValue) && prev !== null) {
          // animate the transition from exploded to non-exploded mode.
          animateToZero(prev);
          return;
        }
        const valToSend = (newVal === null) ? 0 : clamp(newVal, [0, 1]);
        lcvHandler.queueDisplayFunction('set explode factor', (display) => {
          display.workspace?.setExplodeFactor(valToSend * 2);
        });
        if (newVal && (newVal > 1 || newVal < 0)) {
          setSelf(clamp(newVal, [0, 1]));
        }
      });
    },
  ],
});

export const useLcvisExplodeFactorState = (
  projectId: string,
) => useRecoilState(lcvisExplodeFactor(projectId));

export const useLcvisExplodeFactorValue = (
  projectId: string,
) => useRecoilValue(lcvisExplodeFactor(projectId));

export const useSetLcvisExplodeFactor = (
  projectId: string,
) => useSetRecoilState(lcvisExplodeFactor(projectId));
