// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/client/entity.proto (package luminary.proto.client, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * This message stores all components (uniqueid, name) which are used
 * to represent unique identifier for an entity.
 *
 * @generated from message luminary.proto.client.EntityIdentifier
 */
export const EntityIdentifier = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.EntityIdentifier",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

