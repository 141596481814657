// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { ReactElement } from 'react';

import { ClientState, ConnState, PAUSED_MESSAGE, keepalive } from '../../lib/ParaviewClient';
import { colors, hexToRgb } from '../../lib/designSystem';
import { ActionButton } from '../Button/ActionButton';
import { createStyles, makeStyles } from '../Theme';
import { LoadingEllipsis } from '../visual/LoadingEllipsis';

// StatusOverlay is used to show status messages on top of the mesh display.
// It is activated when the paraview is disconnected, or paraview is running a
// long-running operations that needs the display of progress bar.

const useStyles = makeStyles(() => createStyles({
  overlay: {
    backgroundColor: hexToRgb(colors.surfaceBackground, 0.5),
    position: 'absolute',
    // Should be lower than FloatingToolbar's zIndex
    zIndex: 2,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    overflow: 'hidden',
  },
  dialog: {
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    position: 'absolute',
  },
  title: {
    fontSize: '14',
    fontColor: colors.highEmphasisText,
    fontWeight: 600,
    paddingBottom: '24px',
    textShadow: '0px 1px 1px #000000',
  },
}), { name: 'StatusOverlay' });

export interface StatusOverlayProps {
  clientState: ClientState;
  syncing: boolean;
}

const StatusOverlay = (props: StatusOverlayProps) => {
  const { clientState, syncing } = props;

  const classes = useStyles();

  let progressPercent: number = -1;
  let widget: ReactElement | null = null;
  let titleText: string | null = null;

  switch (clientState.connState) {
    case ConnState.CONNECTING:
    case ConnState.CONNECTED:
      if (!clientState.message && !syncing) {
        throw Error(`empty connecting message and not syncing: ${JSON.stringify(clientState)}`);
      }
      if (clientState.message) {
        titleText = clientState.message;
      } else {
        titleText = 'Syncing';
      }
      if (clientState.progress && clientState.progress > 0) {
        progressPercent = Math.floor(clientState.progress * 100);
        titleText += ` (${progressPercent}%)`;
      }
      break;
    case ConnState.VOLUNTARY_DISCONNECT:
      widget = (
        <ActionButton
          kind="secondary"
          onClick={() => {
            keepalive(clientState);
          }}>
          Restart
        </ActionButton>
      );
      titleText = clientState.message!;
      break;
    default:
      throw Error(`Invalid Paraview connection state ${clientState.connState}`);
  }

  return (
    <div className={classes.overlay}>
      <div className={classes.dialog}>
        <div className={classes.title}>
          {titleText !== PAUSED_MESSAGE ?
            <>{titleText}<LoadingEllipsis /></> :
            titleText}
        </div>
        {widget}
      </div>
    </div>
  );
};

export default StatusOverlay;
