// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useState } from 'react';

import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import { RibbonToolbarTool } from '../../RibbonToolbar/RibbonToolbarButton';
import { useLcvOriginVisualizer } from '../useLcvCoordinateVisualizer';

// Button that allows showing/hiding the origin (by default it's hidden). Mouting this component
// will establish a useEffect that handles the showing/hiding of the origin.
export const useShowOriginData = (): RibbonToolbarTool => {
  const [show, setShow] = useState(false);
  const lcvisReady = useLcVisReadyValue();

  const { show: showFrame, clear: clearFrame } = useLcvOriginVisualizer();

  useEffect(() => {
    if (show) {
      showFrame();
    } else {
      clearFrame();
    }
    return () => {
      clearFrame();
    };
  }, [show, showFrame, clearFrame]);
  return {
    disabled: !lcvisReady,
    icon: { name: show ? 'gridOn' : 'gridOff', maxHeight: 15 },
    key: 'showOrigin',
    locator: 'show-origin-button',
    onClick: () => {
      setShow((oldShow) => !oldShow);
    },
    label: show ? 'Hide origin' : 'Show origin',
    title: show ? 'Hide origin' : 'Show origin',
  };
};
