// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Virtuoso as List } from 'react-virtuoso';

import { SimulationRowProps } from '../../lib/componentTypes/simulationTree';
import { SIMULATION_TREE_DATA_LOCATOR, SimulationTreeNode } from '../../lib/simulationTree/node';
import useResizeObserver from '../../lib/useResizeObserver';
import { useNoCredits } from '../../recoil/useAccountInfo';
import { useIsExplorationStarterPlan } from '../../recoil/useProjectPage';
import { useSimulationTree } from '../../state/internal/tree/simulation';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useTree } from '../hooks/useTree';
import { MessagePanel } from '../notification/MessagePanel';

import { SimulationRowContainer } from './SimulationRowContainer';
import { useArrowKeyNav } from './useArrowKeyNav';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      height: '100%',
      overflow: 'hidden',
    },
    message: {
      padding: '0 8px',
    },
  }),
  { name: 'SimulationTreePanel' },
);

interface SimulationTreePanelProps {
  panelRoot: SimulationTreeNode;
}

/**
 * This panel displays a simulation tree containing the simulation settings.
 * Any node with children can be opened or closed. The children are displayed
 * if the node is opened.
 */
const SimulationTreePanel = React.memo(({ panelRoot }: SimulationTreePanelProps) => {
  const classes = useStyles();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();
  const simulationTree = useSimulationTree(projectId, workflowId, jobId);
  const isExplorationAndStarterPlan = useIsExplorationStarterPlan();

  const hasNoCredits = useNoCredits();
  const { rowProps, maybeUpdateRowsOpened, listRef } = useTree(panelRoot);

  const domRef = useRef<HTMLDivElement>(null);
  const domSize = useResizeObserver(domRef, { name: 'simTreePanel' });
  const maxHeight = useMemo(() => Math.max(100, domSize.height), [domSize.height]);

  // Listen to arrow keys for navigating in the simtree with keyboard shortcuts
  useArrowKeyNav(simulationTree, rowProps, listRef);

  const renderRow = useCallback((index: number, row: SimulationRowProps) => (
    <SimulationRowContainer key={row.node.id} {...row} />
  ), []);

  useEffect(() => {
    const updateRows = () => {
      maybeUpdateRowsOpened(simulationTree, selectedNodeIds);
    };
    const rafId = requestAnimationFrame(updateRows);
    return () => cancelAnimationFrame(rafId);
  }, [selectedNodeIds, maybeUpdateRowsOpened, simulationTree]);

  if (!simulationTree) {
    return null;
  }

  return (
    <div className={classes.root} data-locator={SIMULATION_TREE_DATA_LOCATOR} ref={domRef}>
      {isExplorationAndStarterPlan && (
        <div className={classes.message}>
          <MessagePanel level="warning" variant="sectionMessage">
            Cannot run Design of Experiments on the Starter plan
          </MessagePanel>
        </div>
      )}
      {!isExplorationAndStarterPlan && hasNoCredits && (
      <div className={classes.message}>
        <MessagePanel level="error" variant="sectionMessage">
          Credits are required to run simulations
        </MessagePanel>
      </div>
      )}

      {!(isExplorationAndStarterPlan || hasNoCredits) && (
        <List
          data={rowProps}
          itemContent={renderRow}
          overscan={20}
          ref={listRef}
          style={{ width: domSize.width, height: maxHeight }}
        />
      )}
    </div>
  );
});

SimulationTreePanel.displayName = 'SimulationTreePanel';

export default SimulationTreePanel;
