// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import { tertiaryColors } from '../../../lib/color';
import { SelectOption, SelectOptionGroup } from '../../../lib/componentTypes/form';
import { isUnmodifiedEnterKey } from '../../../lib/event';
import { axisTableToSparkLineSeries, getTableDataSelectOptions } from '../../../lib/rectilinearTable/util';
import { Metadata } from '../../../proto/table/table_pb';
import useTableState from '../../../recoil/tableState';
import { ActionButton } from '../../Button/ActionButton';
import { DataSelect } from '../../Form/DataSelect';
import Tooltip from '../../Tooltip';
import { SparkLine } from '../../charting/SparkLine';
import { RectilinearTableDialog, RectilinearTableDialogProps } from '../../dialog/RectilinearTable';
import { TablePreviewDialog } from '../../dialog/TablePreviewDialog';
import { RingXIcon } from '../../svg/RingXIcon';
import Collapsible from '../../transition/Collapsible';
import { FolderTriangle } from '../FolderTriangle';

import './TableMapInput.scss';

/**
 * TableMapInput is an input-like component that manages [1] the creation and upload of name/table
 * pairs in the global param table-map and [2] the assignment of names to other param objects.  For
 * compatibility with ParamFieldInput, which takes a `setValue` callback with a single argument,
 * this component's onChange callback also takes a single argument, an argument that supports
 * multiple operations through the discriminated-union ChangeOperation type below.
 */

// An operation that creates an entry in the table reference map
interface CreateTableOperation {
  type: 'create';
  name: string;
  metadata: Metadata;
}

// An operation that assigns a table map reference to some other object
interface AssignTableOperation {
  type: 'assign';
  name: string;
}

// An operation that combines the 'create' and 'assign' operations for a
// table reference
interface CreateAndAssignTableOperation {
  type: 'create-assign';
  name: string;
  metadata: Metadata;
}

export type ChangeOperation = CreateTableOperation |
  AssignTableOperation |
  CreateAndAssignTableOperation;

export interface TableMapConfig {
  // This prop represents a map (typically a subset of the global table map,
  // based on Metadata.tableType compatibility) of name/table pairs that should
  // appear as choices (in a menu).  Note that is a native Map, rather than the
  // more limited jspb.Map type that represents the global table map.
  tableMap: Map<string, Metadata>;
  // A function that is passed to the table upload dialog and should return
  // an error message if a proposed name is invalid (e.g. if it has already
  // been used in the global table map).
  nameErrorFunc: (name: string) => string;
  // Title to display in the dialog
  dialogTitle: string;
  // Optional subtitle to display in the dialog
  dialogSubtitle?: string | JSX.Element;
  // Table definition (axis/record labels)
  tableDefinition: RectilinearTableDialogProps['tableDefinition'];
  // Optional table validation method
  tableErrorFunc?: RectilinearTableDialogProps['tableErrorFunc'];
  // Optionally specify a file format
  uploadOptions?: RectilinearTableDialogProps['uploadOptions'];
  // Optionally specify file format tooltip text
  uploadHelp?: RectilinearTableDialogProps['uploadHelp'];
  // Optionally disable the help tooltip
  disableHelp?: RectilinearTableDialogProps['disableHelp'];
  // Optionally specify the tooltip for unlinking the table file, default: "Unlink table file"
  unlinkTooltip?: string;
  /** When true, displays the uploaded table as a grid rather than a set of graphs */
  gridPreview?: RectilinearTableDialogProps['gridPreview'];
  /** When true, displays the uploaded table as a grid rather than a set of graphs */
  gridHeaderColumn?: RectilinearTableDialogProps['gridHeaderColumn'];
  /** When true, a grid preview dialog is available */
  dialogPreview?: boolean;
}

export interface TableMapInputProps extends TableMapConfig {
  // The value assigned to the param object (used as a key in the global table map)
  value: string;
  // Used for reading tables using RPC
  projectId: string;
  // Called when an operation is to be performed on the global table map or on a param object that
  // can store references to the table map.
  onChange: (value: ChangeOperation) => void;
  /** When true, the graph preview is disabled */
  hidePreview?: boolean;
  disabled?: boolean;
}

export const TableMapInput = (props: TableMapInputProps) => {
  const {
    dialogTitle,
    dialogSubtitle,
    disabled,
    hidePreview,
    nameErrorFunc,
    onChange,
    tableDefinition,
    tableMap: tableReferenceMap,
    tableErrorFunc,
    uploadOptions,
    uploadHelp,
    disableHelp,
    unlinkTooltip = 'Unlink table file',
    gridPreview,
    dialogPreview,
    gridHeaderColumn,
    projectId,
    value,
  } = props;
  // The rectilinear table dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  // The graph preview details dropdown
  const [detailsCollapsed, setDetailsCollapsed] = useState(true);
  // The grid preview dialog
  const [previewOpen, setPreviewOpen] = useState(false);
  const [axisIndex, setAxisIndex] = useState(0);

  const selectedTableReference = tableReferenceMap.get(value);
  // tableProto used for sparklines
  const tableProto = useTableState(projectId, selectedTableReference?.url || null);

  useEffect(() => {
    setAxisIndex(0);
  }, [selectedTableReference]);

  // Unassign a table key from the param object
  const unlinkTable = () => {
    onChange({
      type: 'assign',
      name: '',
    });
  };

  // Assign a table key to the param object
  const linkTable = (name: string) => {
    onChange({
      type: 'assign',
      name,
    });
  };

  // Start a workflow for creating a new name/table pair
  const startTableDialog = () => {
    setDialogOpen(true);
  };

  const menuItems:
    (SelectOption<string | undefined> | SelectOptionGroup<string | undefined>)[] = [];

  menuItems.push({
    options: Array.from(tableReferenceMap).map(([tableKey]) => ({
      value: tableKey,
      name: tableKey,
      onClick: () => linkTable(tableKey),
    })),
  });

  menuItems.push({
    value: undefined,
    name: 'Upload File',
    icon: { name: 'diskArrowUp' },
    onClick: startTableDialog,
  });

  // Generate axis drop-down options
  const axisOptions = getTableDataSelectOptions(tableProto, axisIndex);

  const fileUrl = selectedTableReference?.uploadedFilename;
  const fileTooltip = fileUrl ? `File name: ${fileUrl}` : '';

  return (
    <div className="tableMapInput">
      <RectilinearTableDialog
        disableHelp={disableHelp}
        gridHeaderColumn={gridHeaderColumn}
        gridPreview={gridPreview}
        nameErrorFunc={nameErrorFunc}
        onClose={() => {
          setDialogOpen(false);
        }}
        onSubmit={(name, metadata) => {
          onChange({ type: 'create-assign', name, metadata });
          setDialogOpen(false);
        }}
        open={dialogOpen && !disabled}
        projectId={projectId}
        subtitle={dialogSubtitle}
        tableDefinition={tableDefinition}
        tableErrorFunc={tableErrorFunc}
        title={dialogTitle}
        uploadHelp={uploadHelp}
        uploadOptions={uploadOptions}
      />
      {dialogPreview && (
        <TablePreviewDialog
          onClose={() => setPreviewOpen(false)}
          open={previewOpen}
          subtitle="Explore your time dependent heat flux data."
          tableName={value}
          tableUrl={selectedTableReference?.url ?? ''}
        />
      )}
      {selectedTableReference && (
        <div className={cx('tableinfo', { collapsed: detailsCollapsed })}>
          <div className="header">
            {!hidePreview && (
              <button
                className="control"
                onClick={() => setDetailsCollapsed(!detailsCollapsed)}
                type="button">
                <Tooltip title="Show or hide details">
                  <div className="controlIcon">
                    <FolderTriangle open={!detailsCollapsed} />
                  </div>
                </Tooltip>
              </button>
            )}
            <Tooltip title={fileTooltip}>
              <div
                className={cx('name', { dialogPreview })}
                onClick={() => dialogPreview && setPreviewOpen(true)}
                onKeyUp={(event) => {
                  if (dialogPreview && isUnmodifiedEnterKey(event)) {
                    setPreviewOpen(true);
                  }
                }}
                role="button"
                tabIndex={0}>
                {value}
              </div>
            </Tooltip>
            {!props.disabled && (
              <div className="controls">
                <button
                  className="control"
                  disabled={disabled}
                  onClick={unlinkTable}
                  type="button">
                  <Tooltip title={disabled ? '' : unlinkTooltip}>
                    <div className="controlIcon">
                      <RingXIcon maxHeight={12} maxWidth={12} />
                    </div>
                  </Tooltip>
                </button>
              </div>
            )}
          </div>
          <Collapsible collapsed={detailsCollapsed} transitionPeriod={250}>
            <div className="body">
              <div className="divider" />
              <div className="records">
                {tableProto &&
                  axisTableToSparkLineSeries(tableProto, axisIndex).map((series, i) => (
                    <React.Fragment key={series.yLabel}>
                      <div className="label" title={series.yLabel}>{series.yLabel}</div>
                      <div className="chart">
                        <SparkLine
                          color={tertiaryColors[i % tertiaryColors.length]}
                          height={20}
                          options={{ format: { maximumFractionDigits: 6 } }}
                          series={series}
                          strokeWidth={1.5}
                          width={30}
                        />
                      </div>
                    </React.Fragment>
                  ))}
              </div>
              {axisOptions.length > 1 && (
                <>
                  <div className="divider" />
                  <div className="axisSelect">
                    <DataSelect
                      onChange={setAxisIndex}
                      options={axisOptions}
                      placeholderText="Select axis"
                      size="small"
                    />
                  </div>
                </>
              )}
            </div>
          </Collapsible>
        </div>
      )}
      {
        !selectedTableReference &&
        (tableReferenceMap.size ? (
          <DataSelect
            asBlock
            disabled={dialogOpen}
            options={menuItems}
            placeholderText="Select..."
            size="small"
          />
        ) : (
          <ActionButton
            asBlock
            disabled={dialogOpen}
            kind="secondary"
            onClick={startTableDialog}
            size="small"
            startIcon={{ name: 'diskArrowUp' }}>
            Upload File
          </ActionButton>
        ))
      }
    </div>
  );
};
