// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { NodeType } from '../../lib/simulationTree/node';
import { deleteTreeNodeMenuItem } from '../../lib/treeUtils';
import { ContextMenuSection } from '../treePanel/TreeRow';

import { useNodeDeletion } from './useNodeDeletion';

/**
 * A custom hook for managing tree nodes that represent physics
 */
export const usePhysicsNode = (
  nodeId: string,
  nodeType: NodeType.PHYSICS_FLUID | NodeType.PHYSICS_HEAT,
) => {
  const { canDelete, deletePhysicsNode, postDeleteNodeIds } = useNodeDeletion();

  const deleteRow = useCallback(async () => {
    if (await deletePhysicsNode(nodeId)) {
      postDeleteNodeIds([nodeId]);
    }
  }, [deletePhysicsNode, nodeId, postDeleteNodeIds]);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];

    const disabled = !canDelete(nodeType, nodeId);
    const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
    sections.push({ section: 'crud', menuItems: [deleteItem] });

    return sections;
  }, [canDelete, deleteRow, nodeId, nodeType]);

  return {
    getExtraContextMenuItems,
  };
};
