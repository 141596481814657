// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 72;
const baseHeight = 86;
export const BooksIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="books"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M14.864,85.62
           C14.797,85.651 14.721,85.667 14.632,85.667
           L0.615,85.667
           C0.314,85.667 0.06,85.411 0.06,85.11
           C0.06,85.064 0.065,85.014 0.06,84.968
           C-0.074,83.902 0.06,82.731 0.06,81.657
           L0.06,1.373
           C0.06,1.071 0.315,0.816 0.615,0.816
           L14.632,0.816
           C14.711,0.816 14.787,0.835 14.857,0.867
           C14.926,0.835 15.003,0.816 15.082,0.816
           L29.099,0.816
           C29.178,0.816 29.254,0.835 29.323,0.867
           C29.393,0.835 29.469,0.814 29.548,0.814
           L43.565,0.814
           C43.866,0.814 44.121,1.071 44.121,1.371
           C44.121,1.417 44.115,1.468 44.121,1.513
           C44.151,1.754 44.166,2.001 44.173,2.251
           C44.187,2.248 44.201,2.24 44.216,2.237
           C45.395,2.048 46.574,1.857 47.752,1.668
           C48.118,1.609 48.484,1.55 48.849,1.491
           C49.124,1.447 49.398,1.403 49.673,1.359
           C49.7,1.353 53.054,0.807 53.084,0.803
           L53.085,0.81
           C53.434,0.754 53.784,0.696 54.133,0.641
           C55.441,0.43 56.749,0.219 58.055,0.008
           C58.323,-0.035 58.687,0.078 58.739,0.397
           C58.745,0.443 58.747,0.494 58.761,0.537
           C59.09,1.543 59.118,2.765 59.284,3.807
           C59.648,6.082 60.013,8.356 60.377,10.63
           C61.008,14.569 61.639,18.507 62.27,22.445
           C62.273,22.457 62.275,22.469 62.277,22.482
           C62.37,23.062 62.463,23.645 62.556,24.225
           C62.762,25.51 62.969,26.794 63.174,28.078
           C64.481,36.232 65.787,44.385 67.094,52.537
           C67.452,54.771 67.81,57.005 68.168,59.239
           L69.555,67.89
           C69.562,67.937 69.564,67.983 69.562,68.027
           C69.564,67.985 69.564,67.944 69.558,67.9
           C69.93,70.223 70.302,72.545 70.674,74.868
           C71.112,77.604 71.55,80.34 71.989,83.076
           C71.996,83.126 71.997,83.172 71.994,83.217
           C72.031,83.462 71.918,83.71 71.6,83.761
           C66.987,84.505 62.374,85.248 57.761,85.992
           C57.493,86.035 57.129,85.922 57.078,85.603
           C57.071,85.557 57.07,85.506 57.056,85.463
           C56.727,84.457 56.699,83.235 56.532,82.193
           C56.167,79.918 55.804,77.644 55.438,75.37
           C55.173,73.72 54.909,72.069 54.644,70.417
           C54.346,68.552 54.046,66.687 53.747,64.821
           C52.441,56.669 51.134,48.515 49.828,40.362
           C49.008,35.243 48.188,30.126 47.368,25.008
           C46.628,20.382 45.887,15.758 45.145,11.132
           C44.804,9.005 44.463,6.876 44.122,4.748
           L44.122,10.571
           C44.039,10.368 43.837,10.222 43.61,10.222
           C43.836,10.223 44.035,10.37 44.119,10.571
           C44.147,10.635 44.162,10.706 44.162,10.779
           L44.162,56.993
           C44.162,57.077 44.146,57.149 44.119,57.213
           L44.119,85.11
           C44.119,85.274 44.059,85.4 43.969,85.489
           C43.966,85.493 43.962,85.496 43.959,85.5
           C43.953,85.506 43.94,85.518 43.94,85.518
           L43.939,85.519
           C43.85,85.609 43.725,85.668 43.562,85.668
           L29.546,85.668
           C29.469,85.668 29.396,85.652 29.328,85.621
           C29.262,85.651 29.186,85.667 29.099,85.667
           L15.082,85.667
           C15.005,85.667 14.932,85.651 14.864,85.62Z
           M1.17,11.29
           L1.17,84.553
           L14.038,84.553
           C13.975,83.602 14.076,82.593 14.076,81.657
           L14.076,11.29
           L1.17,11.29Z
           M1.17,7.033
           L14.076,7.033
           L14.076,1.93
           L1.215,1.93
           C1.208,2.176 1.17,2.445 1.17,2.636
           L1.17,7.033Z
           M15.637,2.636
           L15.637,10.222
           L28.543,10.222
           L28.543,1.93
           L15.681,1.93
           C15.676,2.176 15.637,2.445 15.637,2.636Z
           M43.011,10.222
           L43.011,1.93
           L30.15,1.93
           C30.142,2.176 30.105,2.445 30.105,2.636
           L30.105,10.222
           L43.011,10.222Z
           M52.652,1.995
           C50.625,2.323 46.501,2.992 45.035,3.229
           C45.063,3.456 45.07,3.697 45.099,3.875
           C45.216,4.596 45.331,5.316 45.446,6.037
           C45.81,8.313 46.175,10.586 46.539,12.86
           C47.147,16.649 47.754,20.436 48.361,24.225
           C52.61,23.54 56.859,22.855 61.108,22.17
           C60.4,17.748 59.691,13.325 58.983,8.903
           C58.571,6.33 58.158,3.757 57.746,1.182
           C56.048,1.455 54.351,1.729 52.653,2.002
           L52.652,1.995Z
           M68.56,68.673
           C64.31,69.358 60.061,70.043 55.812,70.728
           C56.152,72.851 56.492,74.975 56.832,77.098
           C57.245,79.672 57.657,82.246 58.069,84.819
           C62.302,84.137 66.534,83.455 70.767,82.772
           C70.553,81.868 70.51,80.85 70.369,79.961
           C70.005,77.686 69.64,75.412 69.276,73.138
           C69.037,71.65 68.799,70.161 68.56,68.673Z
           M43.008,57.551
           L30.102,57.551
           L30.102,84.554
           L42.969,84.554
           C42.907,83.603 43.008,82.594 43.008,81.658
           L43.008,57.551Z
           M15.637,57.551
           L15.637,84.553
           L28.504,84.553
           C28.442,83.602 28.543,82.593 28.543,81.657
           L28.543,57.551
           L15.637,57.551Z
           M14.076,8.147
           L1.17,8.147
           L1.17,10.176
           L14.076,10.176
           L14.076,8.147Z
           M55.637,69.633
           C59.887,68.948 64.136,68.263 68.386,67.578
           C68.12,65.916 67.853,64.252 67.586,62.589
           C66.781,57.567 65.977,52.545 65.172,47.523
           C64.671,44.392 64.168,41.261 63.667,38.13
           C63.12,34.714 62.573,31.3 62.026,27.886
           C61.779,26.344 61.532,24.803 61.285,23.262
           L48.536,25.317
           C48.802,26.979 49.069,28.643 49.335,30.306
           C49.388,30.633 49.439,30.959 49.492,31.286
           C50.132,35.288 50.774,39.288 51.415,43.289
           C52.029,47.115 52.641,50.94 53.255,54.766
           C53.907,58.839 54.56,62.914 55.212,66.986
           C55.354,67.868 55.495,68.751 55.637,69.633Z
           M43.008,11.336
           L30.102,11.336
           L30.102,56.437
           L43.051,56.438
           L43.051,56.436
           L43.008,56.436
           L43.008,11.336Z
           M15.637,56.437
           L28.543,56.438
           L28.543,11.336
           L15.637,11.336
           L15.637,56.437Z
           M37.595,82.751
           C32.486,83.684 28.867,77.561 32.153,73.52
           C33.682,71.64 36.311,70.942 38.57,71.799
           C40.807,72.647 42.182,74.777 42.25,77.137
           C42.173,79.854 40.315,82.254 37.595,82.751Z
           M36.557,72.541
           C34.067,72.541 31.96,74.632 31.972,77.139
           C31.986,79.645 34.006,81.736 36.557,81.736
           C39.107,81.736 41.069,79.642 41.141,77.139
           C41.069,74.634 39.107,72.541 36.557,72.541Z
           M23.055,82.751
           C17.946,83.684 14.326,77.561 17.613,73.52
           C19.141,71.64 21.771,70.942 24.03,71.799
           C26.266,72.647 27.642,74.777 27.71,77.137
           C27.632,79.854 25.775,82.254 23.055,82.751Z
           M22.015,72.541
           C19.526,72.541 17.418,74.632 17.431,77.139
           C17.445,79.645 19.465,81.736 22.015,81.736
           C24.566,81.736 26.527,79.642 26.6,77.139
           C26.527,74.634 24.566,72.541 22.015,72.541Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
