// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useState } from 'react';

import cx from 'classnames';

import { ActionButton } from '../components/Button/ActionButton';
import { ActionLink } from '../components/Button/ActionLink';
import { createStyles, makeStyles } from '../components/Theme';
import ProjectDialog from '../components/dialog/Project';
import { MainPageLayout } from '../components/layout/page/Main';
import { useProjectDialogState } from '../components/project/controller/dialog/useProjectDialogState';
import { useSaveProject } from '../components/project/controller/useSaveProject';
import { LEARNING_URL } from '../lib/constants';
import { colors } from '../lib/designSystem';
import { isUnmodifiedEnterKey, isUnmodifiedSpaceKey } from '../lib/event';
import { SampleProject } from '../proto/frontend/frontend_pb';
import { useSampleProjectsValue } from '../state/external/project/sampleProjects';

const useStyles = makeStyles(
  () => createStyles({
    emptyState: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      width: '900px',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    title: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 600,
    },
    samples: {
      padding: '16px 0',
      display: 'flex',
      gap: '48px',
      justifyContent: 'center',
    },
    sample: {
      width: '268px',
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      padding: '24px',
      cursor: 'pointer',
      border: '2px solid transparent',
      borderRadius: '8px',
      '&.active, &:focus': {
        outline: 'none',
        borderColor: colors.primaryInteractive,
        backgroundColor: colors.surfaceMedium1,
      },
    },
    sampleImage: {
      width: '220px',
      height: '115px',
      border: `1px solid ${colors.neutral300}`,
      borderRadius: '8px',
    },
    sampleInfo: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      alignItems: 'flex-start',
    },
    sampleName: {
      fontSize: '16px',
      fontWeight: 600,
    },
    sampleDescription: {
      color: colors.lowEmphasisText,
      lineHeight: '20px',
      textAlign: 'left',
    },
    blankSection: {
      display: 'flex',
      padding: '24px 48px',
      flexDirection: 'column',
      gap: '24px',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderRadius: '8px',
      backgroundColor: colors.surfaceMedium1,
    },
  }),
  { name: 'ProjectListEmptyState' },
);

const SHORT_DESCRIPTIONS: Record<string, string> = {
  'Piper Cherokee': 'Get started with an external flow example.',
  'NACA 0012': 'Get started with fluid dynamics of a cross-section using the NACA airfoil.',
};

const ProjectListEmptyState = () => {
  // Hooks
  const dialogState = useProjectDialogState();
  const sampleProjects = useSampleProjectsValue();
  const saveProject = useSaveProject(() => dialogState.close());
  const classes = useStyles();

  // State

  // The hoveredSample is used mainly for changing the styles if the sample cards
  const [hoveredSample, setHoveredSample] = useState<SampleProject | null>(null);
  // We can't use the hoveredSample preselecting a sample in the Project dialog, because when the
  // dialog is opened, the hovered state is lost. That's why we need a separate state that we'll
  // pass to the Project dialog for preselecting a sample.
  const [preselectSample, setPreselectSample] = useState<number>(-1);

  return (
    <MainPageLayout
      noHeading
      title="Projects">
      <div className={classes.emptyState}>
        <div className={classes.title}>Create a Project</div>
        <div>
          Start from a sample project or create a blank project below.<br />
          Visit <ActionLink href={LEARNING_URL}>Luminary Learning</ActionLink> for more information.
        </div>
        <div className={classes.samples}>
          {sampleProjects?.sampleProjects.slice(0, 3).map((sample, idx) => {
            const name = sample.projectName;
            const active = hoveredSample?.projectId === sample.projectId;
            return (
              <div
                className={cx(classes.sample, { active })}
                data-locator="sample-project-item"
                key={sample.projectId}
                onBlur={() => {
                  setHoveredSample(null);
                }}
                onClick={() => dialogState.newProject()}
                onFocus={() => {
                  setHoveredSample(sample);
                  setPreselectSample(idx);
                }}
                onKeyUp={(event) => {
                  if (isUnmodifiedEnterKey(event) || isUnmodifiedSpaceKey(event)) {
                    dialogState.newProject();
                  }
                }}
                onMouseEnter={() => {
                  setHoveredSample(sample);
                  setPreselectSample(idx);
                }}
                onMouseLeave={() => {
                  setHoveredSample(null);
                }}
                role="button"
                tabIndex={0}>
                <img
                  alt={name}
                  className={classes.sampleImage}
                  crossOrigin="anonymous" // LC-17265 sample images are not same-origin.
                  draggable={false}
                  src={sample.imageUrl}
                />
                <div className={classes.sampleInfo}>
                  <div className={classes.sampleName}>{name}</div>
                  <div className={classes.sampleDescription}>
                    {SHORT_DESCRIPTIONS[name] || `Get started with ${name}.`}
                  </div>
                  {/* Don't need handlers for the button, because the whole section is clickable */}
                  <ActionButton kind={active ? 'primary' : 'secondary'} tabIndex={-1}>
                    Create
                  </ActionButton>
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes.blankSection} onMouseEnter={() => setPreselectSample(-1)}>
          <div>Create something of your own by starting from a blank project.</div>
          <ActionButton
            dataLocator="create-project-button"
            kind="secondary"
            onClick={() => dialogState.newProject()}>
            Create a Blank Project
          </ActionButton>
        </div>
      </div>

      <ProjectDialog
        {...dialogState.props}
        onSubmit={saveProject}
        preselectedSample={preselectSample}
      />
    </MainPageLayout>
  );
};

export default ProjectListEmptyState;
