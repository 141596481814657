// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/cadmetadata/cadmetadata.proto (package luminary.proto.cadmetadata, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Vector3 } from "../base/base_pb.js";
import { EntityGroups } from "../entitygroup/entitygroup_pb.js";

/**
 * Box with min and max coordinates
 *
 * @generated from message luminary.proto.cadmetadata.Box
 */
export const Box = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cadmetadata.Box",
  () => [
    { no: 1, name: "min", kind: "message", T: Vector3 },
    { no: 2, name: "max", kind: "message", T: Vector3 },
  ],
);

/**
 * Metadata about the geometry
 *
 * @generated from message luminary.proto.cadmetadata.CadMetadata
 */
export const CadMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cadmetadata.CadMetadata",
  () => [
    { no: 1, name: "global_min_size_m", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "global_max_size_m", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 14, name: "model_max_size_m", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "entity_groups", kind: "message", T: EntityGroups },
    { no: 7, name: "bounding_box", kind: "message", T: Box },
    { no: 10, name: "n_bodies", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "n_faces", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "n_edges", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "n_vertices", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "volume_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 17, name: "volume_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ],
);

