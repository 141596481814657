// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { clamp } from './number';

/**
 * Due to the magic of floating point rounding errors, we need to use the below value
 * for sqrt(2).
 *
 * Mathematically: log(2) / log(sqrt(2)) = 2
 * JS: Math.log(2) / Math.log(Math.sqrt(2)) = 1.9999999999999998
 *
 * When we floor this value, the number of refinements is off by one.
 */
const targetGrowth = Math.sqrt(2 - 0.000000000000001);

// geometric interpolation between initial and final number of CV's
// c = exp((1-t)*log(c0)+(t)*log(c1)) ; t = i / (final-1)
export function logrithmicInterpolation(
  initial: number,
  final: number,
  c0: number,
  c1: number,
) {
  const steps = final - 1;
  const interval = clamp(initial, [0, steps]);
  const t1 = interval / steps;
  const t0 = 1.0 - t1;
  return (c0 ** t0) * (c1 ** t1);
}

export function amrRefinements(
  initialCount: number,
  finalCount: number,
) {
  const minIters = Math.log(finalCount / initialCount) / Math.log(targetGrowth);
  const refinementIterations = Math.floor(Math.max(1, minIters));
  return refinementIterations + 1;
}

export function amrInterval(
  refinementIterations: number,
  steps: number,
) {
  // The final adaptive mesh will be used for 2 intervals, this is where the +1 comes from in the
  // denominator. For a 1000 step sim with 4 iterations, remeshing will occur at iteration
  // 200, 400, and 600 (this last one will run for 400 iterations).
  const refinementInterval = Math.floor(steps / (refinementIterations + 1));
  return refinementInterval;
}
