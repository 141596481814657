// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.

import React, { useMemo } from 'react';

import { PageLink, usePageControl } from '../../../lib/usePagination';
import { DataSelect } from '../../Form/DataSelect';

import { DirectionControl } from './DirectionControl';
import { PageLinkControl } from './PageLinkControl';
import { PageSliceControl } from './PageSliceControl';

import './PaginationControl.scss';

export interface PaginationControlProps {
  // The current page number
  currentPage: number;
  // The current page size
  currentPageSize: number;
  // A list of page sizes available to the user.  Add Infinity to the list to include an option to
  // show all items in the data set.
  availablePageSizes: number[];
  // Total number of items requiring pagination
  total: number;
  // The number of page links to show on either side of the current page.  See Pagination Design at
  // https://docs.google.com/document/d/1PfTnqsEythZ4vjSPpkFcmcInSSqy8hwVvmhGt7ydjHk
  linkNeighborhood: number;
  // Callback for changing the current page size
  onChangePageSize: (size: number) => void;
  // Callback for changing the current page
  onChangePage: (size: number) => void;
}

function linkKey(link: PageLink) {
  if (link.type === 'slice') {
    return `slice-${link.slice[0].page}`;
  }
  return `page-${link.page} `;
}

/**
 * PaginationControl exposes controls for managing that pagination of enumerable data.  If multiple
 * page sizes are allowed, it includes a drop-down to change the page size.  If the page size
 * results in multiple pages, buttons are exposed to navigate to specific pages and to increment or
 * decrement the page number.
 */
export const PaginationControl = (props: PaginationControlProps) => {
  const {
    availablePageSizes,
    currentPage,
    currentPageSize,
    linkNeighborhood,
    onChangePage,
    onChangePageSize,
    total,
  } = props;

  const {
    canDecrementPage,
    canIncrementPage,
    pageCount,
    pageLinks,
  } = usePageControl(total, currentPageSize, currentPage, linkNeighborhood);

  const allSizes = useMemo(() => {
    const sizes = [...(new Set([...availablePageSizes, currentPageSize]))];
    sizes.sort((a, b) => a - b);
    return sizes;
  }, [availablePageSizes, currentPageSize]);

  const pageSizeOptions = useMemo(() => allSizes.map((size: number) => ({
    value: size,
    name: size === Infinity ? 'All' : `${size}`,
    selected: size === currentPageSize,
  })), [allSizes, currentPageSize]);

  const decrementPage = (step = 1) => {
    onChangePage(currentPage - step);
  };

  const incrementPage = (step = 1) => {
    onChangePage(currentPage + step);
  };

  return (
    <div className="paginationControl">
      <div className="pageSizes">
        <DataSelect
          onChange={onChangePageSize}
          options={pageSizeOptions}
          size="small"
        />
      </div>
      {pageCount > 1 && (
        <div className="pageControls">
          <DirectionControl
            direction="left"
            enabled={canDecrementPage}
            onClick={() => {
              decrementPage(1);
            }}
          />
          {pageLinks.map((link) => (
            <React.Fragment key={linkKey(link)}>
              {link.type === 'page' && (
                <PageLinkControl
                  display={link.display}
                  isCurrent={currentPage === link.page}
                  onClick={() => onChangePage(link.page)}
                />
              )}
              {link.type === 'slice' && (
                <PageSliceControl
                  onSelectPage={(page) => onChangePage(page)}
                  slice={link.slice}
                />
              )}
            </React.Fragment>
          ))}
          <DirectionControl
            direction="right"
            enabled={canIncrementPage}
            onClick={() => {
              incrementPage(1);
            }}
          />
        </div>
      )}
    </div>
  );
};
