// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
// Flags that enable or disable functionality across all the files.
const Flags = {};

export const monitorPlanes = 'monitor_planes';
export const parasolidMeshing = 'parasolid';
export const runtimeEstimate = 'rte';
export const advancedMeshParam = 'advanced_mesh_param';
export const visExcludeSmoothClip = 'vis_exclude_smoothclip';
export const referenceValues = 'reference_values';
export const geomSurfaceWithEdges = 'geom_surface_with_edges';
export const lcvis = 'lcvis';
export const lcsurfaceTessellation = 'lcsurface_tessellation';
export const contacts = 'contacts';
export const remeshing = 'remeshing';
export const customSampleDOE = 'custom_sample_doe';
export const lcvisMesh = 'lcvis_mesh';
export const solutionInterpolation = 'solution_interpolation';
export const interactiveGeometryDiscrete = 'interactive_discrete';
export const adjointSA = 'adjoint_sa';
export const oversetv2 = 'overset_v2';
export const geoTags = 'geo_tags';
export const aiAssistant = 'ai_assistant';
export const lcvisAnalysis = 'lcvis_analysis';
export default Flags;
