// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const FarfieldIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="farfield"
      fill="none"
      height={finalHeight}
      ref={ref}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M14.469 5.32a6.983 6.983 0 0 1 .389 1.272 1.33 1.33 0 0 1-1.618-.763 5.648 5.648 0
           0 0-1.23-1.838 5.65 5.65 0 0 0-1.839-1.23 1.33 1.33 0 0 1-.763-1.619 6.994 6.994 0
           0 1 1.272.39 6.98 6.98 0 0 1 2.27 1.518 6.98 6.98 0 0 1 1.519 2.27ZM13.24 10.171
           a1.33 1.33 0 0 1 1.618-.764 6.993 6.993 0 0 1-.39 1.272 6.978 6.978 0 0 1-1.518 2.27
           6.979 6.979 0 0 1-3.542 1.909 1.33 1.33 0 0 1 .763-1.618 5.652 5.652 0 0 0
           1.838-1.23 5.648 5.648 0 0 0 1.23-1.839ZM5.829 13.24c.64.264.963.97.764 1.618a6.993
           6.993 0 0 1-1.272-.39 6.978 6.978 0 0 1-2.27-1.518 6.978 6.978 0 0 1-1.909-3.543
           1.33 1.33 0 0 1 1.618.764 5.65 5.65 0 0 0 1.23 1.838 5.651 5.651 0 0 0 1.839
           1.23ZM2.76 5.829a1.33 1.33 0 0 1-1.618.763 6.996 6.996 0 0 1 .39-1.271
           c.34-.826.847-1.6 1.518-2.27a6.979 6.979 0 0 1 3.543-1.909 1.33 1.33 0 0 1-.764
           1.618 5.649 5.649 0 0 0-1.838 1.23A5.649 5.649 0 0 0 2.76 5.83ZM8 10.835a3 3 0
           1 0 0-6 3 3 0 0 0 0 6Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
