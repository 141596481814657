// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Logger } from './observability/logs';

export const web3DReportLogger = new Logger('lib/web3d_report.ts', 'LCVis-web3D');

function webGL2Report() {
  const report: { [id: string]: any } = {
    webgl2: false,
  };

  // Make a canvas to get the WebGL2 context on
  const canvas = document.createElement('canvas') as HTMLCanvasElement;

  let gl = canvas.getContext('webgl2', {
    powerPreference: 'high-performance',
    failIfMajorPerformanceCaveat: true,
  });

  // Re-try without failing if major performance caveat to see if this
  // system has that issue
  if (!gl) {
    gl = canvas.getContext('webgl2', {
      powerPreference: 'high-performance',
      failIfMajorPerformanceCaveat: false,
    });

    // Ok, we really don't have WebGL2 support
    if (!gl) {
      return report;
    }
    // This system has some issues
    report.major_performance_caveat = true;
  } else {
    report.major_performance_caveat = false;
  }

  report.webgl2 = true;
  report.extensions = gl.getSupportedExtensions();

  const queryValues: Partial<WebGL2RenderingContext> = {
    // Vendor/Renderer info
    RENDERER: gl.RENDERER,
    VENDOR: gl.VENDOR,
    VERSION: gl.VERSION,
    SHADING_LANGUAGE_VERSION: gl.SHADING_LANGUAGE_VERSION,
    MAX_COMBINED_TEXTURE_IMAGE_UNITS: gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS,
    MAX_CUBE_MAP_TEXTURE_SIZE: gl.MAX_CUBE_MAP_TEXTURE_SIZE,
    MAX_FRAGMENT_UNIFORM_VECTORS: gl.MAX_FRAGMENT_UNIFORM_VECTORS,
    MAX_RENDERBUFFER_SIZE: gl.MAX_RENDERBUFFER_SIZE,
    MAX_TEXTURE_IMAGE_UNITS: gl.MAX_TEXTURE_IMAGE_UNITS,
    MAX_TEXTURE_SIZE: gl.MAX_TEXTURE_SIZE,
    MAX_VARYING_VECTORS: gl.MAX_VARYING_VECTORS,
    MAX_VERTEX_ATTRIBS: gl.MAX_VERTEX_ATTRIBS,
    MAX_VERTEX_TEXTURE_IMAGE_UNITS: gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS,
    MAX_VERTEX_UNIFORM_VECTORS: gl.MAX_VERTEX_UNIFORM_VECTORS,
    MAX_VIEWPORT_DIMS: gl.MAX_VIEWPORT_DIMS,
    MAX_3D_TEXTURE_SIZE: gl.MAX_3D_TEXTURE_SIZE,
    MAX_ARRAY_TEXTURE_LAYERS: gl.MAX_ARRAY_TEXTURE_LAYERS,
    MAX_CLIENT_WAIT_TIMEOUT_WEBGL: gl.MAX_CLIENT_WAIT_TIMEOUT_WEBGL,
    MAX_COLOR_ATTACHMENTS: gl.MAX_COLOR_ATTACHMENTS,
    MAX_COMBINED_FRAGMENT_UNIFORM_COMPONENTS: gl.MAX_COMBINED_FRAGMENT_UNIFORM_COMPONENTS,
    MAX_COMBINED_UNIFORM_BLOCKS: gl.MAX_COMBINED_UNIFORM_BLOCKS,
    MAX_COMBINED_VERTEX_UNIFORM_COMPONENTS: gl.MAX_COMBINED_VERTEX_UNIFORM_COMPONENTS,
    MAX_DRAW_BUFFERS: gl.MAX_DRAW_BUFFERS,
    MAX_ELEMENT_INDEX: gl.MAX_ELEMENT_INDEX,
    MAX_ELEMENTS_INDICES: gl.MAX_ELEMENTS_INDICES,
    MAX_ELEMENTS_VERTICES: gl.MAX_ELEMENTS_VERTICES,
    MAX_FRAGMENT_INPUT_COMPONENTS: gl.MAX_FRAGMENT_INPUT_COMPONENTS,
    MAX_FRAGMENT_UNIFORM_BLOCKS: gl.MAX_FRAGMENT_UNIFORM_BLOCKS,
    MAX_FRAGMENT_UNIFORM_COMPONENTS: gl.MAX_FRAGMENT_UNIFORM_COMPONENTS,
    MAX_PROGRAM_TEXEL_OFFSET: gl.MAX_PROGRAM_TEXEL_OFFSET,
    MIN_PROGRAM_TEXEL_OFFSET: gl.MIN_PROGRAM_TEXEL_OFFSET,
    MAX_TRANSFORM_FEEDBACK_INTERLEAVED_COMPONENTS: gl.MAX_TRANSFORM_FEEDBACK_INTERLEAVED_COMPONENTS,
    MAX_TRANSFORM_FEEDBACK_SEPARATE_ATTRIBS: gl.MAX_TRANSFORM_FEEDBACK_SEPARATE_ATTRIBS,
    MAX_TRANSFORM_FEEDBACK_SEPARATE_COMPONENTS: gl.MAX_TRANSFORM_FEEDBACK_SEPARATE_COMPONENTS,
    MAX_UNIFORM_BLOCK_SIZE: gl.MAX_UNIFORM_BLOCK_SIZE,
    MAX_UNIFORM_BUFFER_BINDINGS: gl.MAX_UNIFORM_BUFFER_BINDINGS,
    MAX_VARYING_COMPONENTS: gl.MAX_VARYING_COMPONENTS,
    MAX_VERTEX_OUTPUT_COMPONENTS: gl.MAX_VERTEX_OUTPUT_COMPONENTS,
    MAX_VERTEX_UNIFORM_BLOCKS: gl.MAX_VERTEX_UNIFORM_BLOCKS,
    MAX_VERTEX_UNIFORM_COMPONENTS: gl.MAX_VERTEX_UNIFORM_COMPONENTS,
  };

  Object.entries(queryValues).forEach(([key, value]) => {
    report[key] = gl?.getParameter(value as number);
  });

  const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
  if (debugInfo) {
    report.UNMASKED_VENDOR_WEBGL = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
    report.UNMASKED_RENDERER_WEBGL = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
  }

  return report;
}

async function webGPUReport() {
  const report: { [id: string]: any } = { webgpu: false };
  if (navigator.gpu === undefined) {
    return report;
  }

  // Get a GPU device to render with
  const adapter = await navigator.gpu.requestAdapter();
  if (!adapter) {
    return report;
  }
  const device = await adapter.requestDevice();
  if (!device) {
    return report;
  }

  report.webgpu = true;
  // Not sure how to adapter.limits without the linter complaining
  // That would be preferred as they add new limits here
  report.limits = {
    maxTextureDimension1D: adapter.limits.maxTextureDimension1D,
    maxTextureDimension2D: adapter.limits.maxTextureDimension2D,
    maxTextureDimension3D: adapter.limits.maxTextureDimension3D,
    maxTextureArrayLayers: adapter.limits.maxTextureArrayLayers,
    maxBindGroups: adapter.limits.maxBindGroups,
    maxBindGroupsPlusVertexBuffers: adapter.limits.maxBindGroupsPlusVertexBuffers,
    maxBindingsPerBindGroup: adapter.limits.maxBindingsPerBindGroup,
    maxDynamicUniformBuffersPerPipelineLayout:
      adapter.limits.maxDynamicUniformBuffersPerPipelineLayout,
    maxDynamicStorageBuffersPerPipelineLayout:
      adapter.limits.maxDynamicStorageBuffersPerPipelineLayout,
    maxSampledTexturesPerShaderStage: adapter.limits.maxSampledTexturesPerShaderStage,
    maxSamplersPerShaderStage: adapter.limits.maxSamplersPerShaderStage,
    maxStorageBuffersPerShaderStage: adapter.limits.maxStorageBuffersPerShaderStage,
    maxStorageTexturesPerShaderStage: adapter.limits.maxStorageTexturesPerShaderStage,
    maxUniformBuffersPerShaderStage: adapter.limits.maxUniformBuffersPerShaderStage,
    maxUniformBufferBindingSize: adapter.limits.maxUniformBufferBindingSize,
    maxStorageBufferBindingSize: adapter.limits.maxStorageBufferBindingSize,
    minUniformBufferOffsetAlignment: adapter.limits.minUniformBufferOffsetAlignment,
    minStorageBufferOffsetAlignment: adapter.limits.minStorageBufferOffsetAlignment,
    maxVertexBuffers: adapter.limits.maxVertexBuffers,
    maxBufferSize: adapter.limits.maxBufferSize,
    maxVertexAttributes: adapter.limits.maxVertexAttributes,
    maxVertexBufferArrayStride: adapter.limits.maxVertexBufferArrayStride,
    maxInterStageShaderComponents: adapter.limits.maxInterStageShaderComponents,
    maxInterStageShaderVariables: adapter.limits.maxInterStageShaderVariables,
    maxColorAttachments: adapter.limits.maxColorAttachments,
    maxColorAttachmentBytesPerSample: adapter.limits.maxColorAttachmentBytesPerSample,
    maxComputeWorkgroupStorageSize: adapter.limits.maxComputeWorkgroupStorageSize,
    maxComputeInvocationsPerWorkgroup: adapter.limits.maxComputeInvocationsPerWorkgroup,
    maxComputeWorkgroupSizeX: adapter.limits.maxComputeWorkgroupSizeX,
    maxComputeWorkgroupSizeY: adapter.limits.maxComputeWorkgroupSizeY,
    maxComputeWorkgroupSizeZ: adapter.limits.maxComputeWorkgroupSizeZ,
    maxComputeWorkgroupsPerDimension: adapter.limits.maxComputeWorkgroupsPerDimension,
  };

  report.features = [...adapter.features];

  const adapterInfo = adapter.info || await adapter.requestAdapterInfo();
  report.adapter = {
    vendor: adapterInfo.vendor,
    architecture: adapterInfo.architecture,
    device: adapterInfo.device,
    description: adapterInfo.description,
  };

  return report;
}

function sharedArrayBufferSupport() {
  try {
    const buffer = new SharedArrayBuffer(1024);
    if (buffer === undefined) {
      return false;
    }
  } catch (error) {
    return false;
  }
  return true;
}

export async function web3DReport(userId: string) {
  // Check WebGL2
  const webgl2 = webGL2Report();

  // Check WebGPU
  const webgpu = await webGPUReport();

  const report = {
    webgpu,
    webgl2,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    userAgent: navigator.userAgent,
    sharedArrayBufferSupport: sharedArrayBufferSupport(),
    userId,
    concurrency: navigator.hardwareConcurrency,
    deviceMemory: navigator['deviceMemory' as keyof Navigator] ?? 0,
  };
  web3DReportLogger.info(JSON.stringify(report));

  return report;
}
