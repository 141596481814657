// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useLocation } from 'react-router-dom';
import {
  DefaultValue,
  atomFamily,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  waitForAll,
} from 'recoil';

import { TabsState, getTabText, newTabsState, renameTabWithJobName, tabLink } from '../lib/TabManager';
import { CurrentView } from '../lib/componentTypes/context';
import { getJobType } from '../lib/jobNameMap';
import { geometryLink } from '../lib/navigation';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';
import { currentViewAtom_DEPRECATED } from '../state/internal/global/currentView';
import { workflowFlagState } from '../workflowFlag';

import { geometryListState_DEPRECATED } from './geometry/geometryListState';
import { onGeometryTabSelector } from './geometry/geometryState';
import { jobNameMapSelector } from './jobNameMap';
import { meshUrlState } from './meshState';
import { selectedGeometryState } from './selectedGeometry';
import { projectMetadataMapState_DEPRECATED } from './useProjectMetadata';
import { controlPanelModeState_DEPRECATED } from './useProjectPage';
import { workflowState } from './workflowState';

const tabsKey = 'closableTabs';

const EMPTY_CLOSABLE_TABS = new projectstatepb.ClosableTabs();

// Keys: project ID
const closableTabsState = atomFamily<projectstatepb.ClosableTabs, string>({
  key: 'closableTabs',
  default: (projectId: string) => persist.getProjectState(
    projectId,
    [tabsKey],
    (val: Uint8Array) => (val.length ?
      projectstatepb.ClosableTabs.fromBinary(val) :
      EMPTY_CLOSABLE_TABS),
  ),
  effects: (projectId: string) => [
    syncProjectStateEffect(
      projectId,
      tabsKey,
      (val: Uint8Array) => projectstatepb.ClosableTabs.fromBinary(val),
      (val: projectstatepb.ClosableTabs) => val.toBinary(),
    ),
  ],
});

// To be used when there is no workflow to show. The value is used as a useState dep in
// useTabsState, so it not change between render cycles, or useState will run into an infinite loop.
const EMPTY_WORKFLOW_LIST: frontendpb.WorkflowMetadata[] = [];

const tabsSelector = selectorFamily<TabsState, string>({
  key: 'tabsSelector',
  get: (projectId: string) => ({ get }) => {
    const [
      projectMetadata,
      closableTabs,
      geometryList,
      meshUrl,
      jobNameMap,
      selectedGeometry,
      workflowFlag,
      currentView,
      controlPanelMode,
    ] = get(waitForAll([
      projectMetadataMapState_DEPRECATED(projectId),
      closableTabsState(projectId),
      geometryListState_DEPRECATED(projectId),
      meshUrlState(projectId),
      jobNameMapSelector(projectId),
      selectedGeometryState(projectId),
      workflowFlagState,
      currentViewAtom_DEPRECATED,
      controlPanelModeState_DEPRECATED,
    ]));
    const workflows = projectMetadata?.workflow || EMPTY_WORKFLOW_LIST;
    // If the user has not uploaded anything, we want to hide even the geo tab.
    const geoModEnabled = !!geometryList?.geometries.length;
    const renamedClosableTabsList = closableTabs.tab.map(
      (tab) => renameTabWithJobName(tab, jobNameMap),
    );

    const isExplorationSetup = (
      workflowFlag &&
      currentView === CurrentView.SOLVER && // update with LC-22632, should use new CurrentView
      controlPanelMode === 'exploration'
    );

    return newTabsState(
      projectId,
      workflows,
      geoModEnabled,
      meshUrl.url,
      renamedClosableTabsList,
      selectedGeometry,
      workflowFlag,
      isExplorationSetup,
    );
  },
  set: (projectId: string) => ({ set }, newVal) => {
    if (!(newVal instanceof DefaultValue)) {
      set(
        closableTabsState(projectId),
        new projectstatepb.ClosableTabs({
          tab: newVal.closableTabs,
        }),
      );
    }
  },
});

// Get the recoil state representing the current state of the tabs for the given project.
// Only the "closable tabs" member is persisted on the server side.
export function useTabsState(
  projectId: string,
) {
  return useRecoilState(tabsSelector(projectId));
}

export function useSetTabState(projectId: string) {
  return useSetRecoilState(tabsSelector(projectId));
}

type TabsKey = {
  projectId: string,
  pathname: string,
};

// The current active tab.
const currentTabSelector = selectorFamily<projectstatepb.TabInfo | undefined, TabsKey>({
  key: 'currentTabSelector',
  get: (currentTabKey: TabsKey) => ({ get }) => {
    const { projectId, pathname } = currentTabKey;
    const tabInfos = get(tabsSelector(projectId)).tabs;
    if (get(onGeometryTabSelector)) {
      return tabInfos.find((tab: projectstatepb.TabInfo) => tab.link === geometryLink(projectId));
    }
    return tabInfos.find((tab: projectstatepb.TabInfo) => tab.link === pathname);
  },
});

export const useCurrentTab = (projectId: string) => {
  const { pathname } = useLocation();
  return useRecoilValue(currentTabSelector({ projectId, pathname }));
};

export type TabDescKey = {
  projectId: string | null,
  workflowId: string | null,
  jobId: string | null,
};

export type TabDescription = {
  tabName: string | null,
  pathName: string | null,
};

const emptyDescription = () => ({ projectName: null, tabName: null, pathName: null });

/** Returns a tab name and relative url, given its projectId, workflowId, and jobId. */
export const tabDescState = selectorFamily<TabDescription, TabDescKey>({
  key: 'tabDescState',
  get: (key: TabDescKey) => ({ get }) => {
    const { projectId, workflowId, jobId } = key;
    if (projectId === null || workflowId === null || jobId === null) {
      return emptyDescription();
    }
    const reply = get(workflowState({ projectId, workflowId }));
    const tabState = get(tabsSelector(projectId));

    if (!reply) {
      // e.g. if this selector is being accessed from a project setup or results tab.
      return emptyDescription();
    }
    const jobType = getJobType(reply);
    const pathname = tabLink(jobType, projectId, workflowId, jobId);
    const tabName = getTabText(pathname, tabState);
    return { tabName, pathName: pathname };
  },
});

/** Given a list of Recoil keys, get the corresponding tab descriptions for each key. */
export const tabDescListState = selectorFamily<TabDescription[], TabDescKey[]>({
  key: 'tabDescListState',
  get: (keys: TabDescKey[]) => ({ get }) => {
    const getDescriptions = keys.map((key: TabDescKey) => tabDescState(key));
    const descriptions = get(waitForAll(getDescriptions));
    return descriptions;
  },
});

/**
 * Given a projectId, workflowId, and jobId, return
 * the simulation name and path to that simulation tab.
 */
export const useTabDetails = (key: TabDescKey) => useRecoilValue(tabDescState(key));

/**
 * Takes a list, keys, of {projectId, workflowId, jobID} objects and returns
 * a list of tab descriptions for each object in the list.
 */
export const useTabDetailsList = (keys: TabDescKey[]) => useRecoilValue(tabDescListState(keys));
