// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 48;
const baseHeight = 39;
export const FileArrowUpRightIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M26.31,11.97
           C24.9,10.38 24,8.31 24,6
           L22.26,6
           L17.13,0.9
           C16.59,0.33 15.84,0 15,0
           L3,0
           C1.35,0 0,1.35 0,3
           L0,12
           L26.28,12
           L26.31,11.97Z
           M45,3
           L33,3
           C31.35,3 30,4.35 30,6
           C30,7.65 31.35,9 33,9
           L37.77,9
           L27.87,18.9
           C27.33,19.44 27,20.19 27,21
           C27,22.65 28.35,24 30,24
           C30.84,24 31.59,23.67 32.13,23.13
           L42,13.26
           L42,18.03
           C42,19.68 43.35,21.03 45,21.03
           C46.65,21.03 48,19.68 48,18.03
           L48,6.03
           C48,4.35 46.65,3 45,3Z
           M39.06,24.689
           L36.36,27.389
           L36.33,27.359
           C34.71,29.009 32.49,30.029 30,30.029
           C25.02,30.029 21,26.009 21,21.029
           C21,18.72 21.9,16.619 23.34,15.029
           L0,15.029
           L0,36.029
           C0,37.679 1.35,39.029 3,39.029
           L45,39.029
           C46.65,39.029 48,37.679 48,36.029
           L48,26.489
           C47.07,26.819 46.05,27.029 45,27.029
           C42.72,26.999 40.65,26.099 39.06,24.689Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
