// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.

/** How close the cursor must be to snap to a particular value. */
export const SNAP_DISTANCE = 20;

export enum Scale {
  LINEAR,
  LOG
}

/** Chart layout. Note the chart's width fills the parent component, so it is left unspecified. */
export const LEFT_MARGIN = 70;
export const RIGHT_MARGIN = 5;
export const BOTTOM_MARGIN = 0;
export const TOP_MARGIN = 23;
