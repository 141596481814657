// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { MeshPanelType, useSetMeshPanelState } from '../../../../../recoil/useMeshPanelState';
import { ActionButton } from '../../../../Button/ActionButton';
import { useProjectContext } from '../../../../context/ProjectContext';
import { DoubleArrowLeftRightIcon } from '../../../../svg/DoubleArrowLeftRightIcon';

export const SwapMeshButton = (props: {disabled?: boolean}) => {
  const { disabled } = props;

  const { projectId } = useProjectContext();
  const setMeshPanelState = useSetMeshPanelState(projectId);

  const handleSwapMeshSelect = () => {
    setMeshPanelState(MeshPanelType.SELECT);
  };

  return (
    <ActionButton
      compact
      disabled={disabled}
      kind="minimal"
      onClick={handleSwapMeshSelect}
      size="small">
      <DoubleArrowLeftRightIcon maxHeight={12} />
      Swap
    </ActionButton>
  );
};

export default SwapMeshButton;
