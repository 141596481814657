// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const ListNumberedIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="listNumbered"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M4.137,10.493
           L6.026,10.493
           L6.026,0
           L4.617,0
           C4.557,0.315 4.437,0.585 4.242,0.809
           C4.047,1.034 3.808,1.214 3.523,1.349
           C3.253,1.484 2.938,1.589 2.593,1.649
           C2.249,1.709 1.904,1.739 1.529,1.739
           L1.529,3.088
           L4.137,3.088
           L4.137,10.493Z
           M3.253,21.242
           C3.523,21.062 3.808,20.867 4.122,20.687
           C4.437,20.507 4.752,20.312 5.067,20.102
           C5.382,19.892 5.681,19.683 5.981,19.443
           C6.281,19.203 6.536,18.948 6.761,18.678
           C6.986,18.393 7.18,18.094 7.315,17.764
           C7.45,17.434 7.525,17.059 7.525,16.654
           C7.525,16.325 7.465,15.98 7.345,15.605
           C7.225,15.215 7.031,14.871 6.746,14.571
           C6.476,14.256 6.101,13.986 5.666,13.791
           C5.217,13.581 4.647,13.476 3.987,13.476
           C3.373,13.476 2.833,13.581 2.369,13.776
           C1.889,13.986 1.499,14.256 1.169,14.616
           C0.839,14.96 0.6,15.38 0.435,15.875
           C0.27,16.355 0.195,16.879 0.195,17.449
           L1.979,17.449
           C1.994,17.089 2.024,16.744 2.099,16.445
           C2.174,16.13 2.264,15.86 2.413,15.635
           C2.548,15.41 2.743,15.23 2.983,15.095
           C3.223,14.96 3.508,14.901 3.868,14.901
           C4.257,14.901 4.572,14.96 4.812,15.08
           C5.052,15.2 5.247,15.35 5.382,15.53
           C5.517,15.71 5.606,15.905 5.651,16.115
           C5.696,16.325 5.726,16.52 5.726,16.714
           C5.711,17.119 5.606,17.464 5.397,17.779
           C5.187,18.094 4.917,18.378 4.602,18.633
           C4.272,18.903 3.928,19.143 3.538,19.368
           C3.148,19.593 2.773,19.832 2.428,20.072
           C1.679,20.537 1.094,21.092 0.675,21.736
           C0.225,22.351 0.015,23.1 0,23.955
           L7.495,23.955
           L7.495,22.456
           L2.144,22.456
           C2.219,22.231 2.354,22.021 2.548,21.811
           C2.758,21.616 2.983,21.421 3.253,21.242Z
           M22.501,2.983
           L12.007,2.983
           C11.183,2.983 10.508,3.658 10.508,4.482
           L10.508,5.981
           C10.508,6.806 11.183,7.48 12.007,7.48
           L22.501,7.48
           C23.325,7.48 24,6.806 24,5.981
           L24,4.482
           C24,3.658 23.34,2.983 22.501,2.983Z
           M22.501,16.475
           L12.007,16.475
           C11.183,16.475 10.508,17.149 10.508,17.974
           L10.508,19.473
           C10.508,20.297 11.183,20.972 12.007,20.972
           L22.501,20.972
           C23.325,20.972 24,20.297 24,19.473
           L24,17.974
           C24,17.149 23.34,16.475 22.501,16.475Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
