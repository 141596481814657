// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../../lib/componentTypes/svgIcon';
import { NodeType } from '../../../../lib/simulationTree/node';
import { useMaterialEntityNode } from '../../../hooks/useMaterialEntityNode';
import { useNodeRenaming } from '../../../hooks/useNodeRenaming';
import { TreeRow } from '../../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'gridBlocks' };

export const MaterialFluidTreeRow = (props: SimulationRowProps) => {
  const { node } = props;
  const { getExtraContextMenuItems } = useMaterialEntityNode(node.id, NodeType.MATERIAL_SOLID);
  const renaming = useNodeRenaming(node);

  return (
    <TreeRow
      {...props}
      getExtraContextMenuItems={getExtraContextMenuItems}
      primaryIcon={PRIMARY_ICON}
      renaming={renaming}
    />
  );
};
