// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import * as frontendpb from '../proto/frontend/frontend_pb';

export function getFullName(user: frontendpb.AccountInfoReply_UserInfo) {
  return `${user.givenName} ${user.familyName}`;
}

export function getUserName(user: frontendpb.AccountInfoReply_UserInfo) {
  const given = user ? user.givenName : '';
  const family = user ? user.familyName : '';
  const full = `${given} ${family}`;

  return { given, family, full };
}
