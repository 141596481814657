// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { IconName } from '../../lib/componentTypes/svgIcon';
import { LcVisButtonType } from '../../lib/lcvis/types';

import { ToolbarButton } from './ToolbarButton';

type LcVisToolbarButtonProps = {
  type: LcVisButtonType,
  disabled: boolean,
  id: string,
  title: string,
  earlyAccess: boolean,
  engaged: boolean,
  onClick: (ev: React.MouseEvent, buttonType: LcVisButtonType) => void,
  iconName?: IconName
}

export const getIconName = (buttonType: LcVisButtonType): IconName => {
  switch (buttonType) {
    case 'clipBox':
      return 'dashedBox';
    case 'clip':
      return 'scissors';
    case 'slice':
      return 'scalpel';
    case 'clipHide':
      return 'eyeOff';
    case 'probe':
      return 'probe';
    case 'measure':
      return 'ruler';
    case 'threshold':
      return 'verticalControls';
    case 'contour':
      return 'contour';
    case 'streamlines':
      return 'streamVortices';
    case 'surfaceLIC':
      return 'surfaceLIC';
    case 'glyph':
      return 'streamArrows';
    case 'intersectionLine':
      return 'lineSegment';
    case 'intersectionCurve':
      return 'intersectingPlanes';
    case 'multislice':
      return 'layers';
    default:
      return 'wand';
  }
};

export const LcVisToolbarButton = (props: LcVisToolbarButtonProps) => {
  const {
    type,
    disabled,
    id,
    title,
    engaged,
    onClick,
    earlyAccess = false,
    iconName = getIconName(type),
  } = props;

  return (
    <ToolbarButton
      disabled={disabled}
      earlyAccess={earlyAccess}
      engaged={engaged}
      icon={{ name: iconName }}
      locator={`toolbar-${id}`}
      onClick={(ev) => onClick(ev, type)}
      title={title}
    />
  );
};
