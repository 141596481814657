// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { getOrCreateMpCouplingOptions } from '../../lib/simulationParamUtils';
import * as simulationpb from '../../proto/client/simulation_pb';

import { useWorkflowConfig } from './useWorkflowConfig';

const {
  MP_COUPLING_LIN_SOL_COUPLING_OFF,
  MP_COUPLING_LIN_SOL_COUPLING_ON,
} = simulationpb.MpCouplingLinSolCoupling;

export const useMultiCoupling = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
) => {
  // == Recoil
  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  const disablePresetsReason = useMemo(() => {
    if (!simParam) {
      return 'Unknown error with multiphysics coupling.';
    }
    return '';
  }, [simParam]);

  const coupling = useMemo(() => getOrCreateMpCouplingOptions(simParam), [simParam]);

  const setCoupling = useCallback(
    async (value: simulationpb.MultiPhysicsCouplingOptions) => saveParamAsync(
      (newParam) => {
        newParam.mpCouplingOptions = value;
      },
    ),
    [saveParamAsync],
  );

  const enhancedCouplingOn = useMemo(
    () => coupling.mpCouplingLinSolCoupling === MP_COUPLING_LIN_SOL_COUPLING_ON,
    [coupling],
  );

  const setEnhancedCouplingOn = useCallback(
    (value: boolean) => saveParamAsync(
      (newParam) => {
        getOrCreateMpCouplingOptions(newParam).mpCouplingLinSolCoupling = value ?
          MP_COUPLING_LIN_SOL_COUPLING_ON :
          MP_COUPLING_LIN_SOL_COUPLING_OFF;
      },
    ),
    [saveParamAsync],
  );

  return {
    disablePresetsReason,

    coupling,
    setCoupling,

    enhancedCouplingOn,
    setEnhancedCouplingOn,
  };
};
