// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { relatedPhysicsList } from '../../lib/contactsUtils';
import {
  appendMultiphysicsInterface,
  liberateDependentBoundaryCondition,
  removeMultiphysicsInterface,
  syncDependentBoundaryCondition,
} from '../../lib/multiphysicsInterfaceUtils';
import { findFluidPhysicsMaterial, getPhysicsId, isPhysicsFluid } from '../../lib/physicsUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useGeometryContacts } from '../../recoil/geometryContactsState';
import { useStaticVolumes } from '../../recoil/volumes';

import { useWorkflowConfig } from './useWorkflowConfig';

const { CONSTANT_DENSITY } = simulationpb.DensityRelationship;

export const useMultiphysicsInterfaces = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
) => {
  // == Recoil
  const contacts = useGeometryContacts(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const geometryTags = useGeometryTags(projectId);

  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  // All multiphysics coupling interfaces
  const multiphysicsInterfaces = simParam.interfaces;

  // Add a multiphysics coupling interface to a simulation param and save the updated param
  const addMultiphysicsInterface = useCallback(
    async (): Promise<simulationpb.SlidingInterfaces> => saveParamAsync(
      (newParam) => appendMultiphysicsInterface(newParam),
    ),
    [saveParamAsync],
  );

  // Scan through all available geometry contacts, identifying any whose surfaces belong to volumes
  // that span exactly two physics, and adding those as multiphysics (coupling) interfaces
  const addMultiphysicsInterfacesFromContacts = useCallback(
    (param: simulationpb.SimulationParam) => {
      param.interfaces = [];

      // Do these computations just once, rather than for each contact
      const incompatiblePhysics: Record<string, boolean> = {};
      param.physics.forEach((physics) => {
        if (isPhysicsFluid(physics)) {
          const material = findFluidPhysicsMaterial(param, physics, geometryTags, staticVolumes);
          if (
            material?.material.case === 'materialFluid' &&
            material.material.value.densityRelationship === CONSTANT_DENSITY
          ) {
            // Surfaces in a fluid physics with a constant density material cannot be assigned to
            // multiphysics coupling interfaces
            incompatiblePhysics[getPhysicsId(physics)] = true;
          }
        }
      });

      return contacts.contacts.reduce((result, contact) => {
        const physicsList = relatedPhysicsList(contact, param, geometryTags, staticVolumes);

        // Only those contacts that span two physics may be converted to multiphysics coupling
        // interfaces.
        if (physicsList.length === 2) {
          const isCompatible = physicsList.every((physics) => {
            const physicsId = getPhysicsId(physics);
            return !incompatiblePhysics[physicsId];
          });

          if (isCompatible) {
            const slidingInterface = appendMultiphysicsInterface(param);
            slidingInterface.slidingA = contact.sideA;
            slidingInterface.slidingB = contact.sideB;

            const intfId = slidingInterface.slidingInterfaceId;
            result.push(intfId);

            syncDependentBoundaryCondition(param, intfId, geometryTags, staticVolumes);
          }
        }
        return result;
      }, [] as string[]);
    },
    [contacts, staticVolumes, geometryTags],
  );

  // Delete a multiphysics coupling interface (by ID) from a simulation param and save the updated
  // param
  const deleteMultiphysicsInterface = useCallback(async (id: string): Promise<boolean> => (
    saveParamAsync((newParam) => {
      const removed = removeMultiphysicsInterface(newParam, id);
      liberateDependentBoundaryCondition(newParam, id);
      return removed;
    })
  ), [saveParamAsync]);

  return {
    multiphysicsInterfaces,
    addMultiphysicsInterface,
    addMultiphysicsInterfacesFromContacts,
    deleteMultiphysicsInterface,
  };
};
