// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as simulationpb from '../proto/client/simulation_pb';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import {
  AnyBoundaryCondition,
  getBoundaryConditionsByPhysics,
  getOrCreateDependentFluidBoundaryCondition,
  getOrCreateDependentHeatBoundaryCondition,
  isDependentBoundaryCondition,
} from './boundaryConditionUtils';
import { prefixNameGen, uniqueSequenceName } from './name';
import { findPhysicsById, getPhysicsContainingSurfaces, getPhysicsId, getSubPhysics } from './physicsUtils';
import { newNodeId } from './projectDataUtils';
import { PARAM_VALIDATION_ERROR_NOTIFICATION_ID, setNotification } from './transientNotification';
import { getVolumeIdsFromSurfaces } from './volumeUtils';

const BASE_PREFIX = 'Multiphysics Interface';

/**
 * Find and return a multiphysics coupling interface by ID
 * @param simParam
 * @param id
 * @returns a multiphysics interface or undefined
 */
export function findMultiphysicsInterfaceById(
  simParam: simulationpb.SimulationParam,
  id: string,
) {
  return simParam.interfaces.find((item) => item.slidingInterfaceId === id);
}

/**
 * Get a name for a multiphysics coupling interface Currently a stub that returns the output of the
 * interface's getSlidingInterfaceName(), this method will be enhanced to provide custom names based
 * on the surface selection
 * @param multiphysicsInterface
 * @returns
 */
export function getMultiphysicsInterfaceName(
  multiphysicsInterface: simulationpb.SlidingInterfaces,
  index?: number,
) {
  const name = multiphysicsInterface.slidingInterfaceName;
  if (name) {
    return name;
  }

  if (index === undefined) {
    return BASE_PREFIX;
  }

  return `${BASE_PREFIX} ${index + 1}`;
}

/**
 * Get all multiphysics coupling interface names already in use
 * @param simParam
 * @returns
 */
function getAllNames(simParam: simulationpb.SimulationParam): string[] {
  return simParam.interfaces.map((item) => item.slidingInterfaceName);
}

/**
 * Create and return a new multiphysics coupling interface
 * @param simParam
 * @returns
 */
function createMultiphysicsInterface(
  simParam: simulationpb.SimulationParam,
): simulationpb.SlidingInterfaces {
  const newName = uniqueSequenceName(
    getAllNames(simParam),
    prefixNameGen(BASE_PREFIX),
  );
  return new simulationpb.SlidingInterfaces({
    slidingInterfaceId: newNodeId(),
    slidingInterfaceName: newName,
  });
}

/**
 * Create a new multiphysics coupling interface, append it to the param, and return the interface
 * @param simParam
 * @returns
 */
export function appendMultiphysicsInterface(
  simParam: simulationpb.SimulationParam,
) {
  const multiphysicsInterface = createMultiphysicsInterface(simParam);
  simParam.interfaces.push(multiphysicsInterface);
  return multiphysicsInterface;
}

/**
 * Remove a multiphysics coupling interface (identified by ID) from the param
 * @param simParam
 * @param id
 * @returns true if an object was removed
 */
export function removeMultiphysicsInterface(
  simParam: simulationpb.SimulationParam,
  id: string,
): boolean {
  const oldInterfaces = simParam.interfaces;
  const newInterfaces = oldInterfaces.filter((item) => item.slidingInterfaceId !== id);

  if (newInterfaces.length < oldInterfaces.length) {
    simParam.interfaces = newInterfaces;
    return true;
  }

  return false;
}

/**
 * Summarize a multiphysics coupling interface, evaluating the surfaces on each side to find the
 * enclosing volumes and implicated physics
 * @param simParam
 * @param interfaceId
 * @param staticVolumes
 * @returns a summary of each side of coupling interface, including implicated surfaces, volumes,
 * and physics
 */
export function physicsSummary(
  simParam: simulationpb.SimulationParam,
  interfaceId: string,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  const multiphysicsInterface = findMultiphysicsInterfaceById(simParam, interfaceId);

  const surfaceIdsA = multiphysicsInterface?.slidingA || [];
  const surfaceIdsB = multiphysicsInterface?.slidingB || [];

  const volumesIdsA = getVolumeIdsFromSurfaces(surfaceIdsA, staticVolumes);
  const volumesIdsB = getVolumeIdsFromSurfaces(surfaceIdsB, staticVolumes);

  const physicsA = getPhysicsContainingSurfaces(simParam, surfaceIdsA, geometryTags, staticVolumes);
  const physicsB = getPhysicsContainingSurfaces(simParam, surfaceIdsB, geometryTags, staticVolumes);

  const physicsIdsA = physicsA.map((item) => getPhysicsId(item));
  const physicsIdsB = physicsB.map((item) => getPhysicsId(item));

  return {
    sideA: {
      physics: physicsIdsA,
      volumes: volumesIdsA,
      surfaces: surfaceIdsA,
    },
    sideB: {
      physics: physicsIdsB,
      volumes: volumesIdsB,
      surfaces: surfaceIdsB,
    },
  };
}

/**
 * Remove any dependent boundary conditions across all physics (excluding those identified by
 * `excludePhysicsIds`) that reference the given `interfaceId`
 * @param simParam
 * @param interfaceId
 * @param excludePhysicsIds
 */
function removeDependentBoundaryConditions(
  simParam: simulationpb.SimulationParam,
  interfaceId: string,
  excludePhysicsIds: string[],
) {
  simParam.physics.forEach((physics) => {
    if (excludePhysicsIds.includes(getPhysicsId(physics))) {
      return;
    }
    if (physics.params.case === 'fluid') {
      const fluid = physics.params.value;
      fluid.boundaryConditionsFluid = fluid.boundaryConditionsFluid.filter(
        (bc) => bc.boundaryConditionInterfaceId !== interfaceId,
      );
    }
    if (physics.params.case === 'heat') {
      const heat = physics.params.value;
      heat.boundaryConditionsHeat = heat.boundaryConditionsHeat.filter(
        (bc) => bc.boundaryConditionInterfaceId !== interfaceId,
      );
    }
  });
}

/**
 * For a given multiphysics coupling interface (identified by ID), kick each of the indicated side's
 * surfaces out of any independent boundary conditions, and add the side's surfaces to a dependent
 * boundary condition.
 * @param simParam
 * @param interfaceId
 * @param staticVolumes
 * @param sideA
 */
export function syncDependentBoundaryConditionBySide(
  simParam: simulationpb.SimulationParam,
  interfaceId: string,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
  sideA: boolean,
) {
  if (!interfaceId) {
    setNotification(
      PARAM_VALIDATION_ERROR_NOTIFICATION_ID,
      'warning',
      'Detected multiphysics interface without an ID',
      undefined,
    );
    return;
  }
  const summary = physicsSummary(simParam, interfaceId, geometryTags, staticVolumes);
  const sideSummary = sideA ? summary.sideA : summary.sideB;
  const otherSideSummary = sideA ? summary.sideB : summary.sideA;

  if (!sideSummary.surfaces.length) {
    // If the side has been emptied, remove any dependent BCs that reference the coupling interface
    removeDependentBoundaryConditions(simParam, interfaceId, otherSideSummary.physics);
  } else if (sideSummary.physics.length === 1) {
    const physics = findPhysicsById(simParam, sideSummary.physics[0]);

    let depBc: AnyBoundaryCondition | null = null;
    const physicsBcs: AnyBoundaryCondition[] = [];
    const sideSurfacesSet = new Set(sideSummary.surfaces);
    if (physics?.params.case === 'fluid') {
      depBc = getOrCreateDependentFluidBoundaryCondition(physics, interfaceId);
      physicsBcs.push(...physics.params.value.boundaryConditionsFluid);
    } else if (physics?.params.case === 'heat') {
      depBc = getOrCreateDependentHeatBoundaryCondition(physics, interfaceId);
      physicsBcs.push(...physics.params.value.boundaryConditionsHeat);
    }

    physicsBcs.forEach((bc) => {
      if (!isDependentBoundaryCondition(bc)) {
        // For independent boundary conditions, "kick out" any surfaces in the coupling interface's
        // surface list
        bc.surfaces = bc.surfaces.filter((surfaceId) => !sideSurfacesSet.has(surfaceId));
      }
    });

    // Also "kick out" any of the coupling interface's surfaces from sliding interfaces and periodic
    // pairs
    if (physics) {
      const subPhysics = getSubPhysics(physics);
      subPhysics?.slidingInterfaces.forEach((slidingInterface) => {
        slidingInterface.slidingA = slidingInterface.slidingA.filter(
          (id) => !sideSurfacesSet.has(id),
        );
        slidingInterface.slidingB = slidingInterface.slidingB.filter(
          (id) => !sideSurfacesSet.has(id),
        );
      });
      subPhysics?.periodicPair.forEach((pair) => {
        pair.boundA = pair.boundA.filter((id) => !sideSurfacesSet.has(id));
        pair.boundB = pair.boundB.filter((id) => !sideSurfacesSet.has(id));
      });
    }

    // Now make sure the coupling interface's surfaces are added to the dependent boundary condition
    if (depBc) {
      depBc.surfaces = sideSummary.surfaces;
    }
  }
}

/**
 * Sync each side of a multiphysics coupling interface (identified by ID), kicking each of the
 * side's surfaces out of any independent boundary conditions and adding each side's surfaces to a
 * dependent boundary condition.
 * @param simParam
 * @param interfaceId
 * @param staticVolumes
 */
export function syncDependentBoundaryCondition(
  simParam: simulationpb.SimulationParam,
  interfaceId: string,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  syncDependentBoundaryConditionBySide(simParam, interfaceId, geometryTags, staticVolumes, true);
  syncDependentBoundaryConditionBySide(simParam, interfaceId, geometryTags, staticVolumes, false);
}

/**
 * Call when a multiphysics coupling interface is deleted or disabled to "liberate" any of its
 * dependent boundary conditions
 * @param simParam
 * @param interfaceId
 */
export function liberateDependentBoundaryCondition(
  simParam: simulationpb.SimulationParam,
  interfaceId: string,
) {
  simParam.physics.forEach((physics) => {
    getBoundaryConditionsByPhysics(physics).forEach((boundaryCondition) => {
      if (boundaryCondition.boundaryConditionInterfaceId === interfaceId) {
        boundaryCondition.boundaryConditionInterfaceId = '';
      }
    });
  });
}

/**
 * Remove any multi-physics (coupling) interfaces with one or more surfaces associated with a given
 * physics
 * @param simParam
 * @param physicsId
 * @param staticVolumes
 * @returns true if any interfaces were removed, false otherwise
 */
export function removeMultiphysicsInterfacesByPhysicsSet(
  simParam: simulationpb.SimulationParam,
  physicsIds: Set<string>,
): boolean {
  const idsToRemove = simParam.physics.reduce((result, physics) => {
    if (physicsIds.has(getPhysicsId(physics))) {
      getBoundaryConditionsByPhysics(physics).forEach((bc) => {
        if (bc.boundaryConditionInterfaceId) {
          // For any dependent BCs in the physics, queue the referenced coupling interface ID for
          // deletion
          result.add(bc.boundaryConditionInterfaceId);
        }
      });
    }
    return result;
  }, new Set<string>());

  // If any of the interface surfaces is in the set of surfaces associated with the physics, it
  // should be removed
  const oldInterfaces = simParam.interfaces;
  const newInterfaces = oldInterfaces.filter(
    (couplingInterface) => !idsToRemove.has(couplingInterface.slidingInterfaceId),
  );

  idsToRemove.forEach((interfaceId) => liberateDependentBoundaryCondition(simParam, interfaceId));

  if (newInterfaces.length < oldInterfaces.length) {
    simParam.interfaces = newInterfaces;
    return true;
  }
  return false;
}
