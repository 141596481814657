// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as outputpb from '../../proto/output/output_pb';
import * as rpc from '../rpc';

import { RpcQueue } from './RpcQueue';

type OutputResultChunk = {
  output: outputpb.Output;
  result: outputpb.Result;
}

class OutputRequestRpcQueue extends RpcQueue<
  frontendpb.OutputRequest,
  frontendpb.ComputeOutputBatchRequest,
  OutputResultChunk,
  frontendpb.ComputeOutputBatchReply
> {
  packData(
    outputRequest: frontendpb.OutputRequest[],
    req: frontendpb.ComputeOutputBatchRequest,
  ): void {
    outputRequest.forEach((request) => {
      req.outputRequests.push(request);
    });
  }

  unpackData(index: number, reply: frontendpb.ComputeOutputBatchReply): OutputResultChunk {
    return {
      output: reply.output[index],
      result: reply.result[index],
    };
  }

  getDataSize(req: frontendpb.ComputeOutputBatchRequest): number {
    return req.outputRequests.length;
  }

  sendRequest(
    req: frontendpb.ComputeOutputBatchRequest,
  ): Promise<frontendpb.ComputeOutputBatchReply> {
    return rpc.callRetry('computeOutputBatch', rpc.client.computeOutputBatch, req);
  }

  clearData(req: frontendpb.ComputeOutputBatchRequest): void {
    req.outputRequests = [];
  }
}

export default OutputRequestRpcQueue;
