// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import {
  LCVArcballCameraOrientation,
  LCVArcballCameraWidgetSignal,
  LCVKeyEvent,
  LCVMouseEvent,
  LCVMouseWheelEvent,
  LCVType,
} from '@luminarycloudinternal/lcvis';

import { touchToMouse } from '../../event';
import { Logger } from '../../observability/logs';

import { lcvHandler } from './LcvHandler';

const supportedEvents = [
  'mousedown',
  'mouseup',
  'mousemove',
  'wheel',
  'keydown',
  'keyup',
  'touchstart',
  'touchend',
  'touchmove',
] as const;

const logger = new Logger('LCVis');

/** Events that lcvis can listen to. */
export type LcVisEventType = typeof supportedEvents[number];
type EventHandler = (event: Event) => void;

/** Add event listeners to the target element and pass them to the lcvis display. */
export const mountLcVisListeners = async (
  element: HTMLDivElement,
  allowKeyListeners: React.MutableRefObject<boolean>,
) => {
  const display = await lcvHandler.getDisplay();
  const invokeEvent = display?.invokeEvent.bind(display);
  if (!invokeEvent) {
    return { remove: () => { } };
  }
  const callbacks = new Map<LcVisEventType, EventHandler>();
  supportedEvents.forEach((type: LcVisEventType) => {
    switch (type) {
      case ('touchend'):
      case ('touchstart'):
        callbacks.set(type, (event) => invokeEvent(
          LCVMouseEvent.fromMouseButtonEvent(touchToMouse(event as TouchEvent)),
          type,
        ));
        break;
      case 'touchmove':
        callbacks.set(type, (event) => {
          event.preventDefault();
          invokeEvent(
            LCVMouseEvent.fromMouseMoveEvent(touchToMouse(event as TouchEvent)),
            type,
          );
        });
        break;
      case 'mousemove':
        callbacks.set(type, (event) => invokeEvent(
          LCVMouseEvent.fromMouseMoveEvent(event as MouseEvent),
          type,
        ));
        break;
      case 'mousedown':
        callbacks.set(type, (event) => {
          if ((event as MouseEvent).button === 3) {
            // If this is a right click, don't send it to LCVis. It should trigger the context menu
            // instead.
            return;
          }
          // We have to prevent default here so that dragging outside of the canvas won't highlight
          // text.
          event.preventDefault();
          // Because we prevented the default mousedown behavior, we should also manually deselect
          // anything that was highlighted outside the canvas when clicking inside the canvas.
          if (window.getSelection) {
            window.getSelection()?.removeAllRanges();
          } else if (document.getSelection) {
            document.getSelection()?.empty();
          }
          // invoke the LCVis event.
          invokeEvent(
            LCVMouseEvent.fromMouseButtonEvent(event as MouseEvent),
            type,
          );
        });
        break;
      case 'mouseup':
        callbacks.set(type, (event) => invokeEvent(
          LCVMouseEvent.fromMouseButtonEvent(event as MouseEvent),
          type,
        ));
        break;
      case 'keydown':
      case 'keyup':
        callbacks.set(type, (event) => {
          if (allowKeyListeners.current) {
            invokeEvent(
              LCVKeyEvent.fromKeyboardEvent(event as KeyboardEvent),
              type,
            );
          }
        });
        break;
      case 'wheel':
        callbacks.set(type, (event) => {
          event.preventDefault();
          invokeEvent(
            LCVMouseWheelEvent.fromMouseWheelEvent(event as WheelEvent),
            type,
          );
        });
        break;
      default:
    }
  });

  [...callbacks.keys()].forEach((key) => {
    // For keypress events, listen on the document since the display might not be in focus.
    const target = key.startsWith('key') ? document : element;
    target.addEventListener(
      key as keyof HTMLElementEventMap,
      callbacks.get(key)!,
      {
        // Chrome gives a warning if you preventDefault on non-passive
        // wheel/touch events since they can block the scrolling thread with actions on the UI
        // thread. But we don't want to allow the browser scroll from the canvas anyway,
        // so this is deliberately false and suppresses the warning.
        passive: false,
      },
    );
  });

  return {
    remove: () => [...callbacks.keys()].forEach((key) => {
      const target = key.startsWith('key') ? document : element;
      target.removeEventListener(
        key as keyof HTMLElementEventMap,
        callbacks.get(key)!,
      );
    }),
  };
};
// lcvis is already listening for this key events that implements common
// commands like zoom in and zoom out. This helper emits a specific event based
// on a string.
type LcvCommand = '+X' | '-X' | '-Y' | '+Y' | '-Z' | '+Z' |
  'zoomIn' | 'zoomOut' | 'NW' | 'NE' | 'SW' | 'SE';

export async function emitLCVKeyEvent(command: LcvCommand) {
  let event: KeyboardEvent | null = null;
  let arcballWidgetOrientation: LCVArcballCameraOrientation | number = 0;
  switch (command) {
    case ('zoomIn'):
      event = new KeyboardEvent('keydown', { code: '187', key: '+' });
      break;
    case ('zoomOut'):
      event = new KeyboardEvent('keydown', { code: '189', key: '-' });
      break;
    case ('+X'):
      // The +/- X/Y/Z here tell us which axes we should be looking down, which means we want to
      // place the camera on the opposite sign so we're looking down the right direction
      arcballWidgetOrientation = LCVArcballCameraOrientation.kLCVArcballCameraWidgetOrientNX;
      break;
    case ('-X'):
      arcballWidgetOrientation = LCVArcballCameraOrientation.kLCVArcballCameraWidgetOrientPX;
      break;
    case ('+Y'):
      arcballWidgetOrientation = LCVArcballCameraOrientation.kLCVArcballCameraWidgetOrientNY;
      break;
    case ('-Y'):
      arcballWidgetOrientation = LCVArcballCameraOrientation.kLCVArcballCameraWidgetOrientPY;
      break;
    case ('+Z'):
      arcballWidgetOrientation = LCVArcballCameraOrientation.kLCVArcballCameraWidgetOrientNZ;
      break;
    case ('-Z'):
      arcballWidgetOrientation = LCVArcballCameraOrientation.kLCVArcballCameraWidgetOrientPZ;
      break;
    case ('NE'):
      arcballWidgetOrientation = LCVArcballCameraOrientation.kLCVArcballCameraWidgetOrientPXPYPZ;
      break;
    case ('NW'):
      arcballWidgetOrientation = LCVArcballCameraOrientation.kLCVArcballCameraWidgetOrientNXPYPZ;
      break;
    case ('SE'):
      arcballWidgetOrientation = LCVArcballCameraOrientation.kLCVArcballCameraWidgetOrientPXNYPZ;
      break;
    case ('SW'):
      arcballWidgetOrientation = LCVArcballCameraOrientation.kLCVArcballCameraWidgetOrientNXNYPZ;
      break;
    default:
      break;
  }

  if (event) {
    document.dispatchEvent(event);
  } else if (arcballWidgetOrientation !== 0) {
    const display = await lcvHandler.getDisplay();
    display?.widgets?.arcballWidget?.sendSignal(
      LCVArcballCameraWidgetSignal.kLCVArcballCameraWidgetSignalOrient,
      LCVType.kLCVDataTypeUint,
      arcballWidgetOrientation,
    );
  } else {
    logger.error(`Unknown lcvis command '${command}'`);
  }
}
