// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/table/table.proto (package luminary.proto.table, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { AdFloatType } from "../base/base_pb.js";
import { QuantityType } from "../quantity/quantity_pb.js";

/**
 * These table types are written to gcs by the frontend.
 * Renaming the enums will break backwards compatibility when the app tries to read these files again.
 *
 * @generated from enum luminary.proto.table.TableType
 */
export const TableType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.table.TableType",
  [
    {no: 0, name: "INVALID"},
    {no: 1, name: "MONITOR_POINTS"},
    {no: 2, name: "RADIAL_DISTRIBUTION"},
    {no: 3, name: "BLADE_GEOMETRY"},
    {no: 4, name: "AIRFOIL_PERFORMANCE"},
    {no: 5, name: "PROFILE_BC"},
    {no: 6, name: "FAN_CURVE"},
    {no: 7, name: "CUSTOM_SAMPLE_DOE"},
    {no: 8, name: "TEMP_VARYING"},
  ],
);

/**
 * General n-dimensional rectilinear grid that can store a variety of data
 * types as record elements at the grid cells
 * The number of records in the table must be the product of the number of
 * coordinates across all axes.
 *
 * @generated from message luminary.proto.table.RectilinearTable
 */
export const RectilinearTable = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.table.RectilinearTable",
  () => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 2, name: "header", kind: "message", T: Header },
    { no: 3, name: "axis", kind: "message", T: Axis, repeated: true },
    { no: 4, name: "record", kind: "message", T: Record, repeated: true },
  ],
);

/**
 * Stores the coordinate data for a single axis
 *
 * @generated from message luminary.proto.table.Axis
 */
export const Axis = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.table.Axis",
  () => [
    { no: 1, name: "coordinate", kind: "message", T: Axis_Coordinate, repeated: true },
  ],
);

/**
 * Defines the allowable element types for coordinates
 *
 * @generated from message luminary.proto.table.Axis.Coordinate
 */
export const Axis_Coordinate = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.table.Axis.Coordinate",
  () => [
    { no: 1, name: "adfloat", kind: "message", T: AdFloatType, oneof: "type" },
  ],
  {localName: "Axis_Coordinate"},
);

/**
 * Stores record data for the Table message
 *
 * @generated from message luminary.proto.table.Record
 */
export const Record = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.table.Record",
  () => [
    { no: 1, name: "entry", kind: "message", T: Record_Entry, repeated: true },
  ],
);

/**
 * Defines the allowable element types for record entries
 *
 * @generated from message luminary.proto.table.Record.Entry
 */
export const Record_Entry = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.table.Record.Entry",
  () => [
    { no: 1, name: "adfloat", kind: "message", T: AdFloatType, oneof: "type" },
    { no: 2, name: "string", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "type" },
    { no: 3, name: "empty", kind: "message", T: Record_Entry_Empty, oneof: "type" },
  ],
  {localName: "Record_Entry"},
);

/**
 * Used to denote intentionally missing entries
 *
 * @generated from message luminary.proto.table.Record.Entry.Empty
 */
export const Record_Entry_Empty = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.table.Record.Entry.Empty",
  [],
  {localName: "Record_Entry_Empty"},
);

/**
 * Stores header (title) information for record entries of the table
 *
 * @generated from message luminary.proto.table.Header
 */
export const Header = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.table.Header",
  () => [
    { no: 1, name: "axis_label", kind: "message", T: Header_Label, repeated: true },
    { no: 2, name: "record_label", kind: "message", T: Header_Label, repeated: true },
    { no: 4, name: "allow_missing_entries", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.table.Header.Label
 */
export const Header_Label = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.table.Header.Label",
  () => [
    { no: 1, name: "quantity", kind: "enum", T: proto3.getEnumType(QuantityType), oneof: "type" },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "type" },
  ],
  {localName: "Header_Label"},
);

/**
 * Stores data associated with the table not directly related to table entries
 *
 * @generated from message luminary.proto.table.Metadata
 */
export const Metadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.table.Metadata",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "table_type", kind: "enum", T: proto3.getEnumType(TableType) },
    { no: 3, name: "uploaded_filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

