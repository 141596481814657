// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 15;
const baseHeight = 24;
export const CaretRightIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="caretRight"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M15,12
           C15,11.55 14.79,11.16 14.49,10.89
           L2.49,0.39
           C2.22,0.15 1.89,0 1.5,0
           C0.66,0 0,0.66 0,1.5
           L0,22.5
           C0,23.34 0.66,24 1.5,24
           C1.89,24 2.22,23.85 2.49,23.61
           L14.49,13.11
           C14.79,12.84 15,12.45 15,12Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
