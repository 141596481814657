// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { getUnassignedSurfacesByPhysics } from '../../lib/boundaryConditionUtils';
import { assignDomainsToPhysics, getPhysicsDomainsWithoutUnroll } from '../../lib/entityRelationships';
import { findPhysicsById, getAssignedVolumes } from '../../lib/physicsUtils';
import { mapDomainsToIds } from '../../lib/volumeUtils';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useStaticVolumes } from '../../recoil/volumes';

import { usePhysicsSet } from './usePhysicsSet';
import { useWorkflowConfig } from './useWorkflowConfig';

/**
 * A model hook for managing an individual physics
 * @param projectId
 * @param workflowId
 * @param jobId
 * @param readOnly
 * @param id
 * @returns
 */
export const usePhysics = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
  id: string, // Physics ID
) => {
  // == Recoil
  const staticVolumes = useStaticVolumes(projectId);
  const geometryTags = useGeometryTags(projectId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);
  const {
    getVolumePhysicsAssignmentDisabledReason,
  } = usePhysicsSet(projectId, workflowId, jobId, readOnly);

  const physics = useMemo(() => findPhysicsById(simParam, id), [id, simParam]);

  const hasVolumes = !!getPhysicsDomainsWithoutUnroll(simParam, id).size;

  /** The volumes assigned to this physics */
  const volumes = useMemo(
    () => getAssignedVolumes(simParam, id, geometryTags, staticVolumes),
    [id, geometryTags, simParam, staticVolumes],
  );

  const entityIds = useMemo(() => {
    const entsWoUnroll = getPhysicsDomainsWithoutUnroll(simParam, id);
    const regularDomains = new Set(mapDomainsToIds(staticVolumes, entsWoUnroll));
    entsWoUnroll.forEach((ent) => {
      const tag = geometryTags.domainsFromTag(ent);
      if (tag.length) {
        regularDomains.add(ent);
      }
    });
    return Array.from(regularDomains);
  }, [id, simParam, geometryTags, staticVolumes]);

  /** A callback for updating the domains (volumes) assigned to this physics */
  const setDomains = useCallback(async (domains: Set<string>) => {
    await saveParamAsync(
      (newParam) => {
        assignDomainsToPhysics(newParam, new Set(domains), id);
      },
    );
  }, [id, saveParamAsync]);

  /** Return a reason why a volume may not be assigned to this physics */
  const volumeAssignmentDisabledReason = useCallback(
    (domain: string) => getVolumePhysicsAssignmentDisabledReason(id, domain),
    [getVolumePhysicsAssignmentDisabledReason, id],
  );

  const surfacesWithoutBoundaryConditions = useMemo(
    () => getUnassignedSurfacesByPhysics(
      simParam,
      id,
      geometryTags,
      staticVolumes,
      entityGroupData,
    ),
    [simParam, id, geometryTags, staticVolumes, entityGroupData],
  );

  return {
    entityIds,
    physics,
    hasVolumes,
    volumes,
    setDomains,
    volumeAssignmentDisabledReason,
    surfacesWithoutBoundaryConditions,
  };
};
