// Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved.
import vtkInteractorStyleManipulator from '@kitware/vtk.js/Interaction/Style/InteractorStyleManipulator';
import vtkCamera from '@kitware/vtk.js/Rendering/Core/Camera';

import { listToPv } from '../../lib/Vector';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { CameraMode } from '../../recoil/paraviewState';

// Update the local camera with the given parameters.
export function setCameraState(
  state: ParaviewRpc.CameraState,
  camera: vtkCamera,
  style: vtkInteractorStyleManipulator | null,
): void {
  camera.setFocalPoint(state.focal.x, state.focal.y, state.focal.z);
  camera.setParallelProjection(state.parallelProjection);
  state.scale && camera.setParallelScale(state.scale);
  camera.setPosition(state.position.x, state.position.y, state.position.z);
  camera.setViewUp(state.up.x, state.up.y, state.up.z);
  state.angle && camera.setViewAngle(state.angle);
  style?.setCenterOfRotation(state.center.x, state.center.y, state.center.z);
}

export function getCameraState(
  camera: vtkCamera,
  style: vtkInteractorStyleManipulator,
): ParaviewRpc.CameraState {
  if (!camera) {
    throw Error('null camera');
  }
  if (!style) {
    throw Error('null style');
  }
  return {
    center: listToPv(style.getCenterOfRotation()),
    focal: listToPv(camera.getFocalPoint()),
    parallelProjection: camera.getParallelProjection(),
    scale: camera.getParallelScale(),
    position: listToPv(camera.getPosition()),
    up: listToPv(camera.getViewUp()),
    angle: camera.getViewAngle(),
  };

  // Note: no need to act on centerOfRotation, since it is used only by the remote interactor.
}

export function parallelProjectionToCameraMode(parallelProjection: boolean) {
  return parallelProjection ? CameraMode.ORTHOGRAPHIC : CameraMode.PERSPECTIVE;
}
