// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const GearIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="gear"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M15.19,6.397
           L13.342,6.397
           C13.232,6.027 13.072,5.687 12.892,5.357
           L14.251,3.998
           C14.561,3.688 14.561,3.179 14.251,2.869
           L13.122,1.739
           C12.812,1.43 12.302,1.43 11.993,1.739
           L10.633,3.099
           C10.304,2.929 9.964,2.769 9.594,2.659
           L9.594,0.8
           C9.594,0.36 9.234,0.001 8.795,0.001
           L7.195,0.001
           C6.756,0.001 6.396,0.36 6.396,0.8
           L6.396,2.659
           C6.006,2.779 5.646,2.939 5.297,3.129
           L3.998,1.829
           C3.698,1.53 3.208,1.53 2.908,1.829
           L1.829,2.919
           C1.529,3.219 1.529,3.708 1.829,4.008
           L3.128,5.307
           C2.928,5.647 2.768,6.007 2.648,6.397
           L0.799,6.397
           C0.36,6.397 0,6.756 0,7.196
           L0,8.795
           C0,9.235 0.36,9.595 0.799,9.595
           L2.648,9.595
           C2.758,9.964 2.918,10.304 3.098,10.634
           L1.739,11.993
           C1.429,12.303 1.429,12.813 1.739,13.122
           L2.868,14.252
           C3.178,14.561 3.688,14.561 3.998,14.252
           L5.357,12.892
           C5.686,13.072 6.026,13.222 6.396,13.332
           L6.396,15.191
           C6.396,15.631 6.756,15.991 7.195,15.991
           L8.795,15.991
           C9.234,15.991 9.594,15.631 9.594,15.191
           L9.594,13.332
           C9.984,13.212 10.343,13.052 10.693,12.863
           L11.993,14.162
           C12.292,14.462 12.782,14.462 13.082,14.162
           L14.171,13.072
           C14.471,12.773 14.471,12.283 14.171,11.983
           L12.872,10.684
           C13.062,10.334 13.232,9.974 13.352,9.585
           L15.201,9.585
           C15.64,9.585 16,9.225 16,8.785
           L16,7.186
           C15.99,6.756 15.63,6.397 15.19,6.397Z
           M7.995,10.994
           C6.336,10.994 4.997,9.655 4.997,7.996
           C4.997,6.337 6.336,4.997 7.995,4.997
           C9.654,4.997 10.993,6.337 10.993,7.996
           C10.993,9.655 9.654,10.994 7.995,10.994Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
