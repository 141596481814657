// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, useRecoilStateLoadable } from 'recoil';

import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';

const experimentsKey = 'experiments';

const DEFAULT_EXPERIMENT_SET = new projectstatepb.ExplorationSet({ index: -1 });

const deserialize = (val: Uint8Array) => (
  val.length ?
    projectstatepb.ExplorationSet.fromBinary(val) :
    DEFAULT_EXPERIMENT_SET);

// The persisted explorations for a project.
export const explorationsState = atomFamily<projectstatepb.ExplorationSet, string>({
  key: experimentsKey,
  default: (projectId: string) => persist.getProjectState(
    projectId,
    [experimentsKey],
    (val: Uint8Array) => (
      val.length ?
        projectstatepb.ExplorationSet.fromBinary(val) :
        DEFAULT_EXPERIMENT_SET),
  ),
  effects: (projectId: string) => [
    syncProjectStateEffect(
      projectId,
      experimentsKey,
      deserialize,
      (val: projectstatepb.ExplorationSet) => val.toBinary(),
    ),
  ],
  dangerouslyAllowMutability: true,
});

// Return the experiments for the given project, and a setter for this state.
// The experiments are just on the front end.
export default function useExplorationSet(projectId: string):
  [projectstatepb.ExplorationSet, (newVal: projectstatepb.ExplorationSet) => void] {
  const [info, setter] = useRecoilStateLoadable(explorationsState(projectId));
  return [info.state === 'hasValue' ? info.contents : DEFAULT_EXPERIMENT_SET, setter];
}
