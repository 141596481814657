// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { updateOutputParam } from '../../lib/outputNodeUtils';
import * as feoutputpb from '../../proto/frontend/output/output_pb';
import { useOutputNodes } from '../../recoil/outputNodes';

export function useOutput(projectId: string, outputId: string) {
  const [outputNodes, setOutputNodes] = useOutputNodes(projectId, '', '');

  // Custom hook for updating outputs
  const updateOutputNode = useCallback(
    (changeParam: (newOutput: feoutputpb.OutputNode) => void) => {
      // Change a value within an output and then update the set of outputs.
      updateOutputParam(outputNodes, outputId, changeParam, setOutputNodes);
    },
    [outputId, outputNodes, setOutputNodes],
  );
  return {
    updateOutputNode,
  };
}
