// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 24;
export const BookmarkIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="bookmark"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M10.8,0.015
           L10.575,0.015
           C10.545,0.015 10.53,0 10.5,0
           L1.5,0
           C1.47,0 1.455,0.015 1.425,0.015
           L1.2,0.015
           C0.54,0.015 0,0.57 0,1.245
           L0,23.37
           C0,24.045 0.375,24.21 0.855,23.73
           L5.16,19.32
           C5.625,18.84 6.39,18.84 6.855,19.32
           L11.16,23.73
           C11.625,24.21 12.015,24.045 12.015,23.37
           L12.015,1.245
           C12,0.57 11.46,0.015 10.8,0.015Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
