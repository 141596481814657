// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as papb from '../proto/platformadmin/platformadmin_pb';

export enum PlatformAdminIcon {
  ADMIN = 'crown',
  SUPPORT = 'headphones',
  SAMPLE = 'airplane',
  BIZOPS = 'bill',
}

export enum PlatformAdminRoleType {
  INVALID = 'INVALID',
  ADMIN = 'ADMIN',
  SUPPORT = 'SUPPORT',
  SAMPLE = 'SAMPLE',
  BIZOPS = 'BIZOPS',
}

export const PlatformRoles = [
  papb.PlatformRole.ADMIN,
  papb.PlatformRole.BIZOPS,
  papb.PlatformRole.SAMPLE,
  papb.PlatformRole.SUPPORT,
];

export const GetPlatformAdminRole = (roleType: papb.PlatformRole): PlatformAdminRoleType => {
  switch (roleType) {
    case (papb.PlatformRole.ADMIN): {
      return PlatformAdminRoleType.ADMIN;
    }
    case (papb.PlatformRole.SUPPORT): {
      return PlatformAdminRoleType.SUPPORT;
    }
    case (papb.PlatformRole.SAMPLE): {
      return PlatformAdminRoleType.SAMPLE;
    }
    case (papb.PlatformRole.BIZOPS): {
      return PlatformAdminRoleType.BIZOPS;
    }
    default: {
      return PlatformAdminRoleType.INVALID;
    }
  }
};

export const PlatformAdminRoleNames = (role: PlatformAdminRoleType): string => {
  switch (role) {
    case (PlatformAdminRoleType.ADMIN): {
      return 'Admin';
    }
    case (PlatformAdminRoleType.SUPPORT): {
      return 'Support';
    }
    case (PlatformAdminRoleType.SAMPLE): {
      return 'Sample';
    }
    case (PlatformAdminRoleType.BIZOPS): {
      return 'Bizops';
    }
    default: {
      return 'Invalid';
    }
  }
};
