// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 9;
const baseHeight = 9;
export const GridFourDots = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="grid-four-dots"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
        fill: getColor(0),
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0 1.5
           C0 0.671573 0.671573 0 1.5 0
           C2.32843 0 3 0.671573 3 1.5
           C3 2.32843 2.32843 3 1.5 3
           C0.671573 3 0 2.32843 0 1.5
           Z
           M0 7.5
           C0 6.67157 0.671573 6 1.5 6
           C2.32843 6 3 6.67157 3 7.5
           C3 8.32843 2.32843 9 1.5 9
           C0.671573 9 0 8.32843 0 7.5
           Z
           M7.5 0
           C6.67157 0 6 0.671573 6 1.5
           C6 2.32843 6.67157 3 7.5 3
           C8.32843 3 9 2.32843 9 1.5
           C9 0.671573 8.32843 0 7.5 0
           Z
           M6 7.5
           C6 6.67157 6.67157 6 7.5 6
           C8.32843 6 9 6.67157 9 7.5
           C9 8.32843 8.32843 9 7.5 9
           C6.67157 9 6 8.32843 6 7.5
           Z"
      />
    </svg>
  );
});
