// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useMemo, useState } from 'react';

import { ERRORS } from '../../lib/errors';
import { prefixNameGen, uniqueSequenceName } from '../../lib/name';
import * as rpc from '../../lib/rpc';
import { addRpcError, addSuccess } from '../../lib/transientNotification';
import useFormValidation from '../../lib/useFormValidation';
import * as librarypb from '../../proto/library/library_pb';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { usePersonalLibrary, useRefreshPersonalLibrary } from '../../state/external/library/personalLibrary';
import Form from '../Form';
import { TextInput } from '../Form/TextInput';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { AutoCollapsingMessage } from '../visual/AutoCollapsingMessage';

import { Dialog } from './Base';

const useStyles = makeStyles(
  () => createStyles({
    inputs: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    input: {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
    },
  }),
  { name: 'SaveEditLibraryItem' },
);

export interface SaveEditLibraryItemDialogProps {
  open: boolean;
  onClose: () => void;
  // When editing, item is the library item to edit.
  item?: librarypb.LibraryItem;
}

interface FormValues {
  name: string;
  description: string;
}

// Used for both saving new library items and editing name/description of existing items.
export const SaveEditLibraryItemDialog = (props: SaveEditLibraryItemDialogProps) => {
  // Props
  const { open, item } = props;

  // State
  const [formValues, setFormValues] = useState<FormValues>({ name: '', description: '' });
  const [working, setWorking] = useState(false);
  const projectContext = useProjectContext();
  const projectId = projectContext?.projectId || '';
  const workflowId = projectContext?.workflowId || '';
  const jobId = projectContext?.jobId || '';
  const currentItems = usePersonalLibrary();
  const refreshPersonalLibrary = useRefreshPersonalLibrary();
  const projectName = useProjectMetadata(projectId)?.summary?.name;

  // Derived state
  const isEdit = !!item;
  const titleText = isEdit ? 'Edit Library Item' : 'Save to Library';
  const submitButtonText = isEdit ? 'Apply' : 'Save';

  // Hooks
  const classes = useStyles();
  const { validate, errors } = useFormValidation([
    {
      key: 'name',
      value: formValues.name,
      required: true,
    },
  ]);

  // A map of all names currently in use by library items.
  const currentNames = useMemo(() => {
    const ret = new Map<string, boolean>();
    currentItems?.forEach(({ name }) => ret.set(name, true));
    return ret;
  }, [currentItems]);

  // A default name in the pattern: "Project Settings", "Project Settings 2", ...
  // such that no item exists with that name.
  const defaultName = useMemo(() => {
    const nameGen = prefixNameGen('Project Settings');
    return uniqueSequenceName(currentNames, nameGen, { recycleNumbers: true });
  }, [currentNames]);

  // Tracks whether there is a warning or error on the name field.
  const nameError: {
    level?: 'warning' | 'error',
    message: string,
  } = useMemo(() => {
    // Never consider the closed dialog to be an error state.
    if (!open) {
      return {
        message: '',
      };
    }
    // Error if name is empty.
    if (errors.name) {
      return {
        level: 'error',
        message: errors.name,
      };
    }
    // Warning if name is a duplicate (only on creation, not edit).
    if (!isEdit && currentNames.get(formValues.name)) {
      return {
        level: 'warning',
        message: `A library item with the name ${formValues.name} already exists. ` +
          'Consider choosing a new name.',
      };
    }
    return {
      message: '',
    };
  }, [errors.name, formValues.name, currentNames, open, isEdit]);

  // Effects
  useEffect(() => {
    // Initialize fields.
    if (open && item) {
      setFormValues({ name: item.name, description: item.description });
    } else {
      // We must set the name when the dialog is closed, too, or else the name
      // input flashes red for a moment when reopening the dialog.
      setFormValues({ name: defaultName, description: '' });
    }
  }, [open, item, defaultName]);

  // Methods
  const createLibraryItem = async () => {
    const simSource = !!workflowId;
    const req = new librarypb.CreateItemRequest({
      item: {
        name: formValues.name.trim(),
        description: formValues.description.trim(),
      },
      settingsSource: {
        projectId,
        source: {
          case: simSource ? 'simulation' : 'scratch',
          value: simSource ? {
            workflowId,
            jobId,
          } : {},
        },
      },
    });
    await rpc.callRetry('CreateLibraryItem', rpc.client.createLibraryItem, req).then(() => {
      addSuccess(formValues.name, 'Library item saved');
      props.onClose();
    }).catch((err) => {
      addRpcError(ERRORS.CreateLibraryItem, err, { projectId, projectName });
    });
  };

  const updateLibraryItem = async () => {
    const req = new librarypb.UpdateItemRequest({
      item: {
        ...item,
        name: formValues.name.trim(),
        description: formValues.description.trim(),
      },
    });
    await rpc.callRetry('UpdateLibraryItem', rpc.client.updateLibraryItem, req).then(() => {
      addSuccess(formValues.name, 'Library item updated');
      props.onClose();
    }).catch((err) => {
      addRpcError(ERRORS.UpdateLibraryItem, err, { projectId, projectName });
    });
  };

  // Handlers
  const changeFormValue = (field: keyof FormValues, value: string) => {
    setFormValues((prevValue) => ({ ...prevValue, [field]: value }));
  };

  const handleSubmit = async () => {
    if (validate()) {
      setWorking(true);
      if (isEdit) {
        await updateLibraryItem();
      } else {
        await createLibraryItem();
      }
      setWorking(false);
      refreshPersonalLibrary();
    }
  };

  return (
    <Dialog
      cancelButton={{ label: 'Cancel' }}
      continueButton={{
        disabled: nameError.level === 'error',
        label: submitButtonText,
      }}
      controlState={working ? 'working' : 'normal'}
      modal
      onClose={props.onClose}
      onContinue={handleSubmit}
      open={open}
      title={titleText}>
      <div className={classes.inputs}>
        <div className={classes.input}>
          <Form.Label>Item Name</Form.Label>
          <TextInput
            asBlock
            dataLocator="libraryItemName"
            disabled={working}
            faultType={nameError.level}
            name="name"
            onChange={(value) => changeFormValue('name', value)}
            placeholder="Choose a name for this item…"
            value={formValues.name}
          />
          <AutoCollapsingMessage level={nameError.level} message={nameError.message} />
        </div>
        <div className={classes.input}>
          <Form.Label>Description</Form.Label>
          <TextInput
            asBlock
            dataLocator="libraryItemDescription"
            disabled={working}
            multiline
            name="description"
            onChange={(value) => changeFormValue('description', value)}
            placeholder={'Add a description that will help you distinguish this item from' +
              ' others like it in the Library.'}
            rows={4}
            value={formValues.description}
          />
        </div>
      </div>
    </Dialog>
  );
};
