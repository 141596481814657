// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { LCVType } from '@luminarycloudinternal/lcvis';

import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import assert from '../../assert';
import { colors, hexToRgbList } from '../../designSystem';
import { SimAnnotationParam, findAnnotationModifications, getAnnotationId } from '../../simAnnotationUtils';
import { TwoWayMap } from '../../tsUtils';
import { LcvFrame } from '../classes/LcvFrame';
import { LcvDiskList } from '../classes/annotations/simAnnotations/LcvDiskList';
import { LcvMonitorPointList } from '../classes/annotations/simAnnotations/LcvMonitorPointList';
import { LcvSimAnnotation } from '../classes/annotations/simAnnotations/LcvSimAnnotation';
import { DEFAULT_POINT_RADIUS, LcvModule } from '../types';

const DESELECTED_COLOR = hexToRgbList(colors.deselectedSurface);
const SELECTED_COLOR = hexToRgbList(colors.selectedSurface);
const HOVERED_COLOR = hexToRgbList(colors.hoveredSurface);

/**
 * Since the LcvObjectList is just an array of objects, an IdToIndexMap gives us a mapping
 * from the string id of an object to its index in the LcvObjectList.
 */
type IdToIndexMap = TwoWayMap<string, number>

/**
 * Handler class for managing LCVis objects from the simulation param,
 * for example disks, points, and planes.
 *  */
export class LcvSimAnnotationHandler {
  lcv: LcvModule;
  sessionHandle: number;

  activeFrame: LcvFrame | null = null;

  /** The underlying LcvObjectLists which show the annotations in LCVis. */
  diskObjectList: LcvDiskList | null = null;
  pointObjectList: LcvMonitorPointList | null = null;

  /** The indexMaps map the id of each annotation to its index in the corresponding LcvObjectList */
  diskIndexMap: IdToIndexMap = new TwoWayMap()
  pointIndexMap: IdToIndexMap = new TwoWayMap()

  /**
   * The previous state of the annotation input params. We compare these to the new params
   * whenever they change, to decide what params to change on each LcvObjectList.
   */
  prevDisks: Map<string, ParaviewRpc.ActuatorDiskParam> = new Map();
  prevPoints: Map<string, ParaviewRpc.MonitorPointParam> = new Map();

  /** Track the ids of the currently selected annotations */
  currentSelection: Set<string> = new Set();
  currentlyHovered: string[] = [];

  constructor(lcv: LcvModule, sessionHandle: number) {
    this.lcv = lcv;
    this.sessionHandle = sessionHandle;

    this.diskObjectList = new LcvDiskList(lcv, sessionHandle, 0);
    const pointList = new LcvMonitorPointList(lcv, sessionHandle, 0);
    pointList.setParam('radius', LCVType.kLCVDataTypeFloat, DEFAULT_POINT_RADIUS);
    this.pointObjectList = pointList;
  }

  /** Returns true if the LcvSimAnnotationHandler manages an LCVObjectList with the given id. */
  hasObjectId(objectId: number) {
    return (
      this.diskObjectList?.objectId === objectId || this.pointObjectList?.objectId === objectId
    );
  }

  /** Given an object id and primitive index, return the UI id of the object. */
  getIdFromSelection(objectId: number, primitiveId: number): string {
    switch (objectId) {
      case this.diskObjectList?.objectId:
        return this.diskIndexMap.getByValue(primitiveId)!;
      case this.pointObjectList?.objectId:
        return this.pointIndexMap.getByValue(primitiveId)!;
      default:
        return '';
    }
  }

  /** Given a list of new point and disk params, maybe update the annotations in LCVis. */
  updateAnnotations(
    newPoints: ParaviewRpc.MonitorPointParam[],
    newDisks: ParaviewRpc.ActuatorDiskParam[],
  ) {
    // get the ids of all the simulation annotations that have changed.
    const pointModifications = findAnnotationModifications(newPoints, this.prevPoints);
    const diskModifications = findAnnotationModifications(newDisks, this.prevDisks);
    const added = pointModifications.added.concat(diskModifications.added);
    const removed = pointModifications.removed.concat(diskModifications.removed);
    const edited = pointModifications.edited.concat(diskModifications.edited);

    // remove any annotations that were removed
    removed.forEach((id) => this.removeAnnotation(id));

    // update any annotations that were edited
    edited.forEach((annotation) => this.editAnnotation(annotation));

    // add any new annotations
    added.forEach((annotation) => this.addAnnotation(annotation));

    // update this.prevDisks and this.prevPoints for the next time we update the params.
    const newPointMap = new Map(newPoints.map((point) => ([getAnnotationId(point), point])));
    const newDiskMap = new Map(newDisks.map((disk) => ([getAnnotationId(disk), disk])));
    this.prevDisks = newDiskMap;
    this.prevPoints = newPointMap;
  }

  /**
   * If we want to modify one of our underlying LcvObjectLists, we also want to use its
   * corresponding indexMap so that we can go from id -> index in the list.
   * So given an id, this returns the map and LcvObjectList to use.
   */
  private getMapAndObjectList(
    annotationType: SimAnnotationParam['typ'],
  ): [mapToUse: IdToIndexMap, listObject: LcvSimAnnotation | null] {
    switch (annotationType) {
      case 'ActuatorDisk': {
        const mapToUse = this.diskIndexMap;
        const listObject = this.diskObjectList;
        return [mapToUse, listObject];
      }
      case 'MonitorPoint': {
        const mapToUse = this.pointIndexMap;
        const listObject = this.pointObjectList;
        return [mapToUse, listObject];
      }
      default:
        throw Error(`invalid annotation type: ${annotationType}`);
    }
  }

  /**
   * Delete the annotation with the given id. This is O(n) since deleting an object from the
   * LcvObjectList changes the indices of all objects after it so we have to update the map
   * with new indices.
   */
  private removeAnnotation(id: string) {
    const annotationType: SimAnnotationParam['typ'] = (
      this.diskIndexMap.hasKey(id) ? 'ActuatorDisk' : 'MonitorPoint'
    );
    const [idIndexMap, listObject] = this.getMapAndObjectList(annotationType);
    assert(idIndexMap.hasKey(id), () => {
      console.error(idIndexMap, id, listObject);
      return 'Cannot remove unknown annotion';
    });

    if (!listObject) {
      return;
    }
    // the annotation is a disk
    const index = idIndexMap.getByKey(id)!;
    listObject?.deleteItemAtIndex(index);
    idIndexMap.delete(id);
    // since we deleted the item at {index}, all the ids in the idIndexMap with indices > {index}
    // move down in the list by 1. So we should update the idIndexMap. We have to do it in the
    // order index -> listObject.size since the TwoWayMap requires unique keys and values. We have
    // an empty slot at value {index}, so we move the higher index items down one at a time.
    for (let i = index; i < listObject.size; i += 1) {
      const annotationId = idIndexMap.getByValue(i + 1)!;
      idIndexMap.set(annotationId, i);
    }
  }

  /** Edit the annotation with the given id */
  private editAnnotation(annotation: SimAnnotationParam) {
    const id = getAnnotationId(annotation);
    const [idIndexMap, listObject] = this.getMapAndObjectList(annotation.typ);
    assert(idIndexMap.hasKey(id), () => {
      console.error(idIndexMap, id, listObject);
      return 'Cannot edit unknown annotion';
    });

    if (!listObject) {
      return;
    }
    const index = idIndexMap.getByKey(id)!;
    listObject?.updateItemAtIndex(index, annotation);
  }

  /** Add a new annotation */
  private addAnnotation(annotation: SimAnnotationParam) {
    const [idIndexMap, listObject] = this.getMapAndObjectList(annotation.typ);

    if (!listObject) {
      return;
    }
    listObject.increaseSizeByOne();
    const newIndex = listObject.getSize() - 1;
    listObject.updateItemAtIndex(newIndex, annotation);
    listObject.setParamAtIndex(
      newIndex,
      'colors',
      LCVType.kLCVDataTypeFloat3,
      DESELECTED_COLOR,
    );
    idIndexMap.set(getAnnotationId(annotation), newIndex);
  }

  /**
   * Given an id, returns the LcvObjectList that the object with the id is part of, and
   * its index in the LcvObjectList. Finding an object by its id is O(1).
   *
   * If there is no corresponding index or LcvObjectList, index will be -1.
   */
  private findIndexAndObject(id: string): [index: number, objectList: LcvSimAnnotation | null] {
    const mapObjectPairs: [IdToIndexMap, LcvSimAnnotation | null][] = [
      [this.pointIndexMap, this.pointObjectList],
      [this.diskIndexMap, this.diskObjectList],
    ];
    let toReturn: [index: number, objectList: LcvSimAnnotation | null] = [-1, null];
    mapObjectPairs.some(([map, listObject]) => {
      if (map.hasKey(id)) {
        const index = map.getByKey(id)!;
        if (listObject) {
          toReturn = [index, listObject];
        }
        return true;
      }
      return false;
    });
    return toReturn;
  }

  /**
   * Show the annotations with ids in toShow. Hide the annotations with ids in toHide.
   * If some id is in either set but doesn't correspond to an annotation, this is a noop
   */
  showOrHideAnnotations(toShow: Set<string>, toHide: Set<string>) {
    toShow.forEach((id) => {
      const [index, listObject] = this.findIndexAndObject(id);
      if (index !== -1) {
        listObject?.showItemAtIndex(index);
      }
    });
    toHide.forEach((id) => {
      const [index, listObject] = this.findIndexAndObject(id);
      if (index !== -1) {
        listObject?.hideItemAtIndex(index);
      }
    });
  }

  setActiveFrame(frame: LcvFrame) {
    this.activeFrame = frame;
    this.attachAnnotations(frame);
  }

  /** Attach the annotation lists to the given frame */
  attachAnnotations(frame: LcvFrame) {
    if (this.diskObjectList) {
      frame.attachAnnotation('diskList', this.diskObjectList);
    }
    if (this.pointObjectList) {
      frame.attachAnnotation('pointList', this.pointObjectList);
    }
  }

  /**
   * Highlight the items with the ids in the Set toSelect, and deselect (un-highlight) any items
   * not in the set.
   */
  selectAnnotations(toSelect: Set<string>) {
    toSelect.forEach((id) => {
      if (!this.currentSelection.has(id)) {
        // if we're not already selecting this surface, select it.
        const [index, listObject] = this.findIndexAndObject(id);
        if (index !== -1) {
          listObject?.setColorAtIndex(index, SELECTED_COLOR);
        }
      }
    });
    this.currentSelection.forEach((id) => {
      // if we're currently highlighting the surface and it's not supposed to be highlighted, stop.
      if (!toSelect.has(id)) {
        const [index, listObject] = this.findIndexAndObject(id);
        if (index !== -1) {
          listObject?.setColorAtIndex(index, DESELECTED_COLOR);
        }
      }
    });
    this.currentSelection = toSelect;
  }

  /** Given a string id, highlight the corresponding annotation with HOVERED_COLOR if it exists. */
  hoverAnnotationsById(ids: string[]) {
    if (!ids.length) {
      this.exitHover();
      this.currentlyHovered = [];
      return;
    }
    this.exitHover();
    ids.forEach((id) => {
      // check if the object we're not hovering anymore is in the current selection, since
      // if it is, we should 'select' it.
      const [index, listObject] = this.findIndexAndObject(id);
      if (index !== -1) {
        listObject?.setColorAtIndex(index, HOVERED_COLOR);
      }
      this.currentlyHovered.push(id);
    });
  }

  /** Stop highlighting the annotation with id this.currentlyHovered, if it exists. */
  exitHover() {
    this.currentlyHovered.forEach((id) => {
      // if we're currently selecting the previously hovered surface, go back to its selection
      // color. Otherwise, deselect it.
      const color = (
        this.currentSelection.has(id) ? SELECTED_COLOR : DESELECTED_COLOR
      );
      const [index, listObject] = this.findIndexAndObject(id);
      if (index !== -1) {
        listObject?.setColorAtIndex(index, color);
      }
    });
    this.currentlyHovered = [];
  }

  releaseAll() {
    this.diskObjectList?.release();
    this.pointObjectList?.release();
    this.diskObjectList = null;
    this.pointObjectList = null;

    this.diskIndexMap.clear();
    this.pointIndexMap.clear();

    this.prevDisks.clear();
    this.prevPoints.clear();
  }
}
