// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, useRecoilState, useSetRecoilState } from 'recoil';

import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import { isTestingEnv } from '../lib/testing/utils';
import * as meshgenerationpb from '../proto/meshgeneration/meshgeneration_pb';

// Users wanting to run external aerodynamics simulations will often need to
// modify the geometry model that they import into the app. The CAD modifier
// represents an outer volume that is added to this model. This acts as an
// input to the backend which then returns the new geometry as an output.
// meshgenerationpb.UserGeometryMod consists of a shape for the outer volume
// either a sphere, half-sphere, or cube.

const cadModifierKey = 'cadModifier';

export type CadModifier = meshgenerationpb.UserGeometryMod | null;

function serialize(val: CadModifier): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}

function deserialize(val: Uint8Array): CadModifier {
  return (val.length ?
    meshgenerationpb.UserGeometryMod.fromBinary(val) :
    null);
}

const cadModifierStateRpc = atomFamily<CadModifier, string>({
  key: cadModifierKey,
  default: (projectId: string) => (
    persist.getProjectState(projectId, [cadModifierKey], deserialize)
  ),
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, cadModifierKey, deserialize, serialize),
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

const cadModifierStateTesting = atomFamily<CadModifier, string>({
  key: cadModifierKey,
  default: null,
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

export const cadModifierState = isTestingEnv() ? cadModifierStateTesting : cadModifierStateRpc;

export const useCadModifier = (projectId: string) => (
  useRecoilState(cadModifierState(projectId))
);

export const useSetCadModifier = (projectId: string) => (
  useSetRecoilState(cadModifierState(projectId))
);
