// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { isIntermediateView } from '../../../lib/componentTypes/context';
import { CommonMenuItem } from '../../../lib/componentTypes/menu';
import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';
import { projectHasMotion } from '../../../lib/motionDataUtils';
import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import { useLcVisEnabledState } from '../../../recoil/lcvis/lcvisEnabledState';
import { useAnyFiltersPending } from '../../../recoil/lcvis/lcvisFilterStatus';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import { useEditState } from '../../../recoil/paraviewState';
import { useMeshReadyState } from '../../../recoil/useMeshReadyState';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { useCurrentView, useIsAnalysisView, useIsGeometryView, useIsSetupView } from '../../../state/internal/global/currentView';
import { useMotionAnimationPlaying, useShowMotionAnimationSettings } from '../../../state/internal/vis/motionAnimation';
import { useWorkflowFlagValue } from '../../../workflowFlag';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { RibbonToolbarTool } from '../../RibbonToolbar/RibbonToolbarButton';
import { getIconName } from '../../Toolbar/LcVisToolbarButton';
import { ToolbarButtonProps } from '../../Toolbar/ToolbarButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useGetFilterMenuItem } from '../../visFilter/useGetFilterMenuItem';
import { useHandleLcVisFilterClick } from '../../visFilter/useHandleLcVisFilterClick';
import { useVisFilterParentNode } from '../../visFilter/useVisFilterParentNode';

import { useLcVisButtonData } from './useLcVisButtonData';

export const useVisDropdownData = (): RibbonToolbarTool => {
  // == Contexts
  const { paraviewClientState } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const [
    showMotionAnimationSettings,
    setShowMotionAnimationSettings,
  ] = useShowMotionAnimationSettings();
  const [motionAnimationPlaying] = useMotionAnimationPlaying();
  const handleLcvButtonClick = useHandleLcVisFilterClick();
  const getFilterMenuItem = useGetFilterMenuItem();
  const { isTreeModal } = useSelectionContext();
  const isAnalysisView = useIsAnalysisView();
  const isGeometryView = useIsGeometryView();
  const isSetupView = useIsSetupView();
  const currentView = useCurrentView();
  const [editState] = useEditState();
  const meshReadyState = useMeshReadyState(projectId, workflowId, jobId);
  const [lcVisEnabled] = useLcVisEnabledState(projectId);
  const lcVisReady = useLcVisReadyValue();
  const anyFiltersPending = useAnyFiltersPending();
  const workflowFlag = useWorkflowFlagValue();
  const lcVisButtonData = useLcVisButtonData();

  const clientDisconnected = paraviewClientState.client === null;

  // Only show the animation button if we are in the setup tab and if motion has been imposed.
  const canAnimateMotion = (
    isSetupView || isIntermediateView(currentView)
  ) && projectHasMotion(simParam);

  // The parent to which the new node will be added as a child. If the currently selected node is a
  // filter node, return it. Otherwise, return the root.
  const parentNode = useVisFilterParentNode();

  const isEditing = (type: string) => {
    if (editState) {
      return editState.param.typ === type;
    }
    return false;
  };

  const toolboxItems: CommonMenuItem[] = [];

  // Enable users with access to lcvis to switch between lcvis and Paraview
  // Don't even show the toggle if in simulation results view.
  if (!workflowFlag && lcVisButtonData) {
    toolboxItems.push({
      disabled: lcVisButtonData.disabled,
      label: lcVisEnabled ?
        'Switch to server-side rendering' :
        'Switch to client-side rendering',
      startIcon: lcVisButtonData.icon,
      onClick: (event: any) => lcVisButtonData.onClick(event),
    });
  }

  if (!lcVisEnabled && parentNode && meshReadyState) {
    if (canAnimateMotion) {
      const title = editState ?
        'The motion animation cannot be activated while editing a visualization' :
        'Motion Animation';
      const disabled = clientDisconnected || showMotionAnimationSettings || editState !== null;
      // The button to show the animation settings panel is disabled if there's an animation being
      // displayed, if the client is disconnected or while editState is active. In the latter case,
      // we need to disable the button because while we edit filters we may display widgets and
      // we don't want the widgets to be displayed at the same time the animation is playing.
      toolboxItems.push({
        disabled,
        label: title,
        startIcon: { name: 'play' },
        onClick: () => setShowMotionAnimationSettings(true),
      });
    }

    if (!isGeometryView) {
      toolboxItems.length && toolboxItems.push({ separator: true });

      toolboxItems.push(getFilterMenuItem('Clip', ParaviewRpc.TreeNodeType.CLIP));

      // Enable Contour and Threshold with scalars and vectors.
      const hasData = parentNode.pointData.some((item) => item.dim === 1 || item.dim === 3);

      if (isAnalysisView && hasData) {
        const sliceItems = [
          getFilterMenuItem('Slice', ParaviewRpc.TreeNodeType.SLICE),
          getFilterMenuItem('MultiSlice', ParaviewRpc.TreeNodeType.MULTI_SLICE),
        ];
        toolboxItems.push(...sliceItems);
      } else {
        toolboxItems.push(getFilterMenuItem('Slice', ParaviewRpc.TreeNodeType.SLICE));
      }

      if (isAnalysisView) {
        if (hasData) {
          // Contours are displayed to the user as "Isosurface".
          toolboxItems.push(
            getFilterMenuItem('Threshold', ParaviewRpc.TreeNodeType.THRESHOLD),
            getFilterMenuItem('Isosurface', ParaviewRpc.TreeNodeType.CONTOUR),
          );
        }

        // Streamlines, surface LIC and glyph work only for vector arrays.
        if (parentNode.pointData.some((item) => item.dim === 3)) {
          toolboxItems.push(
            { separator: true },
            getFilterMenuItem('Streamlines', ParaviewRpc.TreeNodeType.STREAMLINES),
            getFilterMenuItem('SurfaceLIC', ParaviewRpc.TreeNodeType.SURFACE_L_I_C),
            getFilterMenuItem('Vector', ParaviewRpc.TreeNodeType.GLYPH),
          );
        }
      }
      toolboxItems.push(
        { separator: true },
        { title: 'More Filters' },
      );

      if (isAnalysisView && hasData) {
        toolboxItems.push(
          getFilterMenuItem('Line', ParaviewRpc.TreeNodeType.LINE, true),
          getFilterMenuItem(
            'Intersection Curve',
            ParaviewRpc.TreeNodeType.INTERSECTION_CURVE,
            true,
          ),
        );
      }

      toolboxItems.push(
        getFilterMenuItem('Extract Surfaces', ParaviewRpc.TreeNodeType.EXTRACT_SURFACES),
      );
    }
  }

  if (lcVisEnabled) {
    if (canAnimateMotion) {
      const onClickStop = async () => {
        const display = await lcvHandler.getDisplay();
        display?.getWorkspace()?.stopAnimation();
      };
      const onClickPlay = () => setShowMotionAnimationSettings(true);

      const playButtonProps: ToolbarButtonProps = motionAnimationPlaying ? {
        icon: { name: 'stopOutline' },
        onClick: onClickStop,
        locator: 'toolbar-stop-motion-animation',
        title: 'Stop Motion Preview',
      } : {
        icon: { name: 'playOutline' },
        onClick: onClickPlay,
        locator: 'toolbar-motion-animation',
        title: 'Motion Preview',
      };
      playButtonProps.disabled = (showMotionAnimationSettings || editState !== null || !lcVisReady);
      toolboxItems.push(
        {
          disabled: playButtonProps.disabled,
          label: playButtonProps.title as string,
          startIcon: { name: playButtonProps.icon.name },
          onClick: motionAnimationPlaying ? onClickStop : onClickPlay,
        },
        { separator: true },
      );
    }

    const needServerSideRendering = 'Coming soon, currently requires server-side rendering';
    toolboxItems.push(
      {
        disabled: !lcVisReady || anyFiltersPending,
        engaged: isEditing(ParaviewRpc.TreeNodeType.CLIP),
        label: 'Clip',
        startIcon: { name: getIconName('clip') },
        onClick: (event) => handleLcvButtonClick(event, 'clip'),
      },
      {
        disabled: !lcVisReady || anyFiltersPending,
        engaged: isEditing(ParaviewRpc.TreeNodeType.SLICE),
        label: 'Slice',
        startIcon: { name: getIconName('slice') },
        onClick: (event) => handleLcvButtonClick(event, 'slice'),
      },
    );

    // Add disabled entries for all the vis filters that LCVis doesn't
    // support yet so that users know to switch to server-side rendering
    // to use them for now
    if (isAnalysisView) {
      toolboxItems.push(
        {
          disabled: true,
          engaged: false,
          label: 'MultiSlice',
          disabledReason: `MultiSlice (${needServerSideRendering})`,
          startIcon: { name: getIconName('multislice') },
          // TODO: not implemented in LCVis yet
          onClick: () => {},
        },
        {
          disabled: true,
          engaged: false,
          label: 'Threshold',
          disabledReason: `Threshold (${needServerSideRendering})`,
          startIcon: { name: getIconName('threshold') },
          // TODO: not implemented in LCVis yet
          onClick: () => {},
        },
        {
          disabled: true,
          engaged: false,
          label: 'Isosurface',
          disabledReason: `Isosurface (${needServerSideRendering})`,
          startIcon: { name: getIconName('contour') },
          // TODO: not implemented in LCVis yet
          onClick: () => {},
        },
        { separator: true },
        {
          disabled: true,
          engaged: false,
          label: 'Streamlines',
          disabledReason: `Streamlines (${needServerSideRendering})`,
          startIcon: { name: getIconName('streamlines') },
          // TODO: not implemented in LCVis yet
          onClick: () => {},
        },
        {
          disabled: true,
          engaged: false,
          label: 'SurfaceLIC',
          disabledReason: `SurfaceLIC (${needServerSideRendering})`,
          startIcon: { name: getIconName('surfaceLIC') },
          // TODO: not implemented in LCVis yet
          onClick: () => {},
        },
        {
          disabled: true,
          engaged: false,
          label: 'Vector',
          disabledReason: `Vector (${needServerSideRendering})`,
          startIcon: { name: getIconName('glyph') },
          // TODO: not implemented in LCVis yet
          onClick: () => {},
        },
        { separator: true },
        { title: 'More Filters' },
        {
          disabled: true,
          label: 'Line',
          startIcon: { name: getIconName('intersectionLine') },
          onClick: () => {},
          disabledReason: `${needServerSideRendering}`,
          earlyAccess: true,
        },
        {
          disabled: true,
          label: 'Intersection Curve',
          startIcon: { name: getIconName('intersectionCurve') },
          onClick: () => {},
          disabledReason: `${needServerSideRendering}`,
          earlyAccess: true,
        },
      );
    }
  }

  return {
    disabled: isTreeModal || !toolboxItems.length,
    icon: { name: 'wand' },
    key: 'toolbox',
    locator: 'toolbar-toolbox',
    onClick: () => { },
    label: 'Visualizations',
    title: 'Visualizations',
    items: toolboxItems,
  };
};
