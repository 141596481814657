// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 48;
const baseHeight = 39;
export const PeopleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="people"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M41.018,36.079
           C40.868,35.358 40.598,34.578 40.267,33.797
           C39.186,31.214 36.903,29.802 32.188,27.79
           C31.767,27.609 30.416,27.039 30.386,27.039
           C29.755,26.769 29.305,26.588 28.884,26.378
           C28.944,26.078 28.944,25.777 28.974,25.447
           C28.974,25.327 29.004,25.207 29.004,25.056
           C28.794,24.876 28.614,24.696 28.434,24.486
           C29.094,23.525 29.635,22.474 30.055,21.332
           C30.116,21.152 30.116,21.152 30.146,21.032
           C31.017,20.341 31.587,19.32 31.918,18.149
           C32.398,17.157 32.669,15.956 32.548,14.664
           C32.458,13.463 32.068,12.382 31.437,11.571
           C31.377,9.979 31.227,8.177 30.987,6.946
           C30.957,6.765 30.926,6.585 30.896,6.375
           C31.587,6.195 32.338,6.105 33.059,6.105
           C34.531,6.105 36.212,6.585 37.384,7.486
           C38.525,8.357 39.396,9.589 39.787,11
           C39.877,11.301 39.937,11.631 39.997,11.931
           C40.207,13.043 40.327,14.755 40.327,15.926
           L40.327,16.076
           C40.748,16.256 41.138,16.707 41.198,17.608
           C41.258,18.359 40.988,18.96 40.808,19.23
           C40.658,19.861 40.327,20.551 39.667,20.672
           C39.606,20.972 39.516,21.272 39.396,21.573
           C39.396,21.603 39.366,21.663 39.366,21.663
           C38.856,22.984 38.075,24.155 37.114,24.996
           L37.114,25.417
           C37.204,26.468 36.843,27.189 39.606,28.42
           C42.4,29.652 46.575,30.943 47.416,32.926
           C48.286,34.908 47.926,36.049 47.926,36.049
           L41.018,36.049
           L41.018,36.079Z
           M37.504,34.938
           C38.615,37.521 38.165,39.023 38.165,39.023
           L0.08,39.023
           C0.08,39.023 -0.34,37.521 0.741,34.938
           C1.823,32.355 7.139,30.643 10.683,29.051
           C14.197,27.429 13.836,26.468 13.957,25.146
           C13.957,24.996 13.987,24.816 13.987,24.636
           C12.755,23.585 11.734,22.053 11.073,20.281
           L11.073,20.251
           C11.073,20.251 11.043,20.221 11.043,20.191
           C10.923,19.83 10.773,19.41 10.683,19.019
           C9.842,18.869 9.361,17.938 9.181,17.097
           C9.001,16.737 8.61,15.926 8.7,14.965
           C8.821,13.733 9.331,13.163 9.872,12.922
           L9.872,12.742
           C9.872,11.21 10.022,8.958 10.292,7.516
           C10.352,7.126 10.442,6.705 10.563,6.315
           C11.073,4.513 12.185,2.921 13.626,1.779
           C15.128,0.608 17.26,-0.023 19.153,-0.023
           C21.045,-0.023 23.177,0.608 24.679,1.779
           C26.121,2.921 27.232,4.513 27.743,6.345
           C27.863,6.735 27.953,7.156 28.013,7.546
           C28.283,8.988 28.433,11.21 28.433,12.742
           L28.433,12.952
           C28.974,13.193 29.455,13.763 29.545,14.965
           C29.635,15.926 29.274,16.737 29.064,17.097
           C28.884,17.938 28.433,18.839 27.622,18.989
           C27.532,19.38 27.412,19.77 27.262,20.161
           C27.262,20.191 27.232,20.281 27.232,20.281
           C26.571,22.023 25.58,23.525 24.379,24.636
           L24.379,25.177
           C24.499,26.528 24.018,27.489 27.562,29.081
           C31.106,30.673 36.423,32.355 37.504,34.938Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
