// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const ClockWedgeIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="clockWedge"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M12,0
           C5.387,0 0,5.387 0,12
           C0,18.613 5.387,24 12,24
           C18.613,24 24,18.613 24,12
           C24,5.387 18.613,0 12,0Z
           M12,2.4
           C17.316,2.4 21.6,6.684 21.6,12
           C21.6,17.316 17.316,21.6 12,21.6
           C6.684,21.6 2.4,17.316 2.4,12
           C2.4,9.788 3.148,7.761 4.397,6.141
           L10.8,13.2
           L18,13.2
           L18,10.8
           L12,10.8
           L12,2.4Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
