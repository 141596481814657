// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import { HelpfulIconSpec } from '../../lib/componentTypes/menu';
import LabeledInput from '../Form/LabeledInput';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';

import { PropertiesSection } from './PropertiesSection';

const useStyles = makeStyles(
  () => createStyles({
    value: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
  }),
  { name: 'AttributesDisplay' },
);

export interface Attribute {
  // An optional unique key for React list uniqueness (label is used by default)
  key?: string;
  label: string;
  value: ReactNode;
  icon?: HelpfulIconSpec;
}

export interface AttributesDisplayProps {
  attributes: Attribute[];
}

export const AttributesDisplay = (props: AttributesDisplayProps) => {
  const { attributes } = props;

  const classes = useStyles();

  return (
    <PropertiesSection>
      {attributes.map((attribute) => (
        <LabeledInput key={attribute.key || attribute.label} label={attribute.label}>
          <div className={classes.value}>
            <div>{attribute.value}</div>
            {attribute.icon && (
              <Tooltip title={attribute.icon.tooltip}>
                <div>
                  <SvgIcon {...attribute.icon} maxHeight={12} maxWidth={12} />
                </div>
              </Tooltip>
            )}
          </div>
        </LabeledInput>
      ))}
    </PropertiesSection>
  );
};
