// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import cx from 'classnames';

export interface SliderThumbProps {
  dragging: boolean;
  leftPercentage: number;
  min: number;
  max: number;
  value: number;
  onFocus: () => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  onMouseDown: React.MouseEventHandler<HTMLDivElement>;
}

export const SliderThumb = forwardRef<HTMLDivElement, SliderThumbProps>((props, ref) => {
  const { dragging, leftPercentage, max, min, value, onFocus, onKeyDown, onMouseDown } = props;
  return (
    <div
      className={cx('thumb', { dragging })}
      ref={ref}
      style={{ left: `${leftPercentage}%` }}>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <div
        aria-valuemax={max}
        aria-valuemin={min}
        aria-valuenow={value}
        className="control"
        onClick={(event) => event.stopPropagation()}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onMouseDown={onMouseDown}
        role="slider"
        tabIndex={0}
      />
    </div>
  );
});
