// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as entitygrouppb from '../proto/entitygroup/entitygroup_pb';
import * as meshgenerationpb from '../proto/meshgeneration/meshgeneration_pb';
import { StaticVolume } from '../recoil/volumes';

import { EntityGroupMap } from './entityGroupMap';
import { intersects } from './lang';
import { fromBigInt } from './number';

export type VolumesByDomain = Record<string, StaticVolume>;

export function volumeNodeId(volumeIndex: number) {
  return `volume-${volumeIndex}`;
}

export function findStaticVolumeById(id: string, staticVolumes: StaticVolume[]) {
  return staticVolumes.find((staticVolume) => staticVolume.id === id);
}

export function findStaticVolumeByDomain(domain: string, staticVolumes: StaticVolume[]) {
  return staticVolumes.find((staticVolume) => staticVolume.domain === domain);
}

export function filterStaticVolumesByIndices(indices: number[], staticVolumes: StaticVolume[]) {
  return staticVolumes.filter((_, i) => indices.includes(i));
}

export function getVolumeName(
  volumeId: string,
  staticVolumes: StaticVolume[],
  entityGroupMap: EntityGroupMap,
) {
  if (entityGroupMap.has(volumeId)) {
    const group = entityGroupMap.get(volumeId);
    if (group.name) {
      return group.name;
    }
  }

  const staticVolume = findStaticVolumeById(volumeId, staticVolumes);

  if (!staticVolume) {
    throw Error(`Volume with ID ${volumeId} does not exist.`);
  }

  return staticVolume.defaultName;
}

// Change the name of the volume associated with the given index
export function renameVolume(
  groupMap: EntityGroupMap,
  volumeId: string,
  newName: string,
) {
  if (groupMap.has(volumeId)) {
    const group = groupMap.get(volumeId);
    group.name = newName;
  } else {
    groupMap.add({
      id: volumeId,
      name: newName,
      parentId: EntityGroupMap.rootId,
      entityType: entitygrouppb.EntityType.VOLUME,
    });
  }
}

export function volumeIdsToNames(volumeIds: string[], entityGroupMap: EntityGroupMap) {
  const idList = [...new Set(volumeIds)];
  return idList.reduce((result, id) => {
    if (entityGroupMap.has(id)) {
      const group = entityGroupMap.get(id);
      result.push(group.name);
    }
    return result;
  }, [] as string[]);
}

// Returns true if all volumes are assigned to one of the volume params.
export const allVolumesAssigned = (
  numVolumes: number,
  meshMultiPart: meshgenerationpb.MeshingMultiPart,
) => {
  const allVolumes = meshMultiPart.volumeParams.reduce(
    (result, volParams) => [...result, ...volParams.volumes],
    [] as bigint[],
  );
  for (let i = 0; i < numVolumes; i += 1) {
    if (!allVolumes.includes(BigInt(i))) {
      return false;
    }
  }
  return true;
};

// For a given list of surface IDs, return a list of volume IDs to which the surfaces belong
export function getVolumeIdsFromSurfaces(
  surfaceIds: string[],
  staticVolumes: StaticVolume[],
) {
  const volumeIds = staticVolumes.reduce((result, staticVolume) => {
    const { bounds, id } = staticVolume;
    if (intersects(bounds, surfaceIds)) {
      result.push(id);
    }
    return result;
  }, [] as string[]);

  volumeIds.sort((a, b) => a.localeCompare(b));

  return volumeIds;
}

// Convert a set of volume IDs to volume indices
export function mapVolumeIdsToIndices(volumeIds: string[], staticVolumes: StaticVolume[]) {
  return staticVolumes.reduce((result, volume) => {
    if (volumeIds.includes(volume.id)) {
      result.push(BigInt(volume.index));
    }
    return result;
  }, [] as bigint[]);
}

export function surfacesFromVolumes(volumes: StaticVolume[]): string[] {
  return volumes.reduce((result, volume) => {
    result.push(...volume.bounds);
    return result;
  }, [] as string[]);
}

export function mapIdsToDomains(staticVolumes: StaticVolume[], volumeIds: string[] | Set<string>) {
  const volumeIdSet = new Set(volumeIds);
  return staticVolumes.reduce((result, volume) => {
    if (volumeIdSet.has(volume.id)) {
      result.push(volume.domain);
    }
    return result;
  }, [] as string[]);
}

export function mapDomainsToIds(staticVolumes: StaticVolume[], domains: string[] | Set<string>) {
  const domainSet = new Set(domains);
  return staticVolumes.reduce((result, volume) => {
    if (domainSet.has(volume.domain)) {
      result.push(volume.id);
    }
    return result;
  }, [] as string[]);
}

export function mapIndicestoIds(staticVolumes: StaticVolume[], bigIndices: (bigint | number)[]) {
  const indices = bigIndices.map(fromBigInt);
  const volumes = filterStaticVolumesByIndices(indices, staticVolumes);
  return volumes.map(({ id }) => id);
}

// A tooltip to aid in changing the assignment between a volume and a material
export function getMaterialAssignmentTooltip(
  readOnly: boolean,
  physicsAssigned: boolean,
  materialAssigned: boolean,
) {
  if (readOnly) {
    return '';
  }

  // If the volume is assigned to both a material and a physics, then the material assignment cannot
  // be severed.
  if (physicsAssigned && materialAssigned) {
    return 'Unassign a volume\'s physics before unassigning its material';
  }

  // If the volume is assigned to a material but not a physics, then it's free to be unassigned from
  // a material.
  if (materialAssigned) {
    return 'Unassign material from this volume';
  }

  // If volume is assigned neither to a physics nor a material, then it's free to be assigned to a
  // material.
  return 'Assign this volume to an existing material or create a new one';
}

// A tooltip to aid in changing the assignment between a volume and a physics
export function getPhysicsAssignmentTooltip(
  readOnly: boolean,
  physicsAssigned: boolean,
  materialAssigned: boolean,
) {
  if (readOnly) {
    return '';
  }

  // If the volume is not assigned to a material, then it cannot be assigned to a physics yet.
  if (!materialAssigned) {
    return 'Volume must be assigned to a material before it may be assigned to a physics';
  }

  // If the volume is assigned to a material and a physics, then it's free to be unassigned from the
  // physics
  if (physicsAssigned && materialAssigned) {
    return 'Unassign physics from this volume';
  }

  // If the volume is not assigned to a material but not a physics, then it's free to assign to a
  // physics
  return 'Assign this volume to an existing physics or create a new one';
}

export function mapByDomains(staticVolumes: StaticVolume[]): VolumesByDomain {
  const result: VolumesByDomain = {};
  staticVolumes.forEach((volume) => {
    result[volume.domain] = volume;
  });
  return result;
}
