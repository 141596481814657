// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { EMPTY_VALUE } from '../../../lib/constants';
import { TriangleIcon } from '../../svg/TriangleIcon';

export interface NumberDisplayProps {
  percent: number;
  digits: number;
}

// Format a percentage number.
export const NumberDisplay = (props: NumberDisplayProps) => {
  const { percent, digits } = props;

  if (Number.isNaN(percent)) {
    return <span>{EMPTY_VALUE}</span>;
  }

  let num = `${percent.toFixed(digits)}%`;
  let arrowUp = true;
  if (percent > 0) {
    num = `+${num}`;
    arrowUp = true;
  } else if (percent < 0) {
    arrowUp = false;
  }
  return (
    <div style={{ display: 'inline-flex', gap: '4px', alignItems: 'center' }}>
      <div>{num}</div>
      <div style={{ display: 'flex', padding: '0 2px' }}>
        <TriangleIcon direction={arrowUp ? 'up' : 'down'} height={5} width={10} />
      </div>
    </div>
  );
};
