// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useMemo } from 'react';

import * as frontendpb from '../proto/frontend/frontend_pb';
import useAccountInfo from '../recoil/useAccountInfo';

import { getLcUserId } from './jwt';
import { isTestingEnv } from './testing/utils';

export type Role = 'admin' | 'reader';

export const useCheckProjectRole = () => {
  const userId = getLcUserId();
  const accountInfo = useAccountInfo();
  const accountId = useMemo(() => accountInfo?.id, [accountInfo]);

  return useCallback((projectSummary: frontendpb.ProjectSummary, role: Role) => {
    const perms = projectSummary.acl!.filter((entry) => (
      (userId && entry.subject!.id === userId) ||
      (accountId && entry.subject!.id === accountId)
    )) || [];
    return perms.some((perm) => perm.role?.includes(role));
  }, [accountId, userId]);
};

// Check if the current user has a certain permission for a specific project
// If a user has created the project, they would have an "admin" permission role.
// And if a user is a recipient of a project (a project has been shared to them), they would
// get a "reader" role.
export const useUserHasProjectRole = (
  projectSummary: frontendpb.ProjectSummary | undefined,
  role: Role,
) => {
  const checkRole = useCheckProjectRole();

  return useMemo(
    () => (projectSummary ? checkRole(projectSummary, role) : false),
    [checkRole, projectSummary, role],
  );
};

export const useUserCanEdit = (
  projectSummary: frontendpb.ProjectSummary | undefined,
) => {
  const checkRole = useCheckProjectRole();

  return useMemo(
    () => {
      const hasEditPermission = (projectSummary ? (checkRole(projectSummary, 'admin')) : false);
      return hasEditPermission || isTestingEnv();
    },
    [checkRole, projectSummary],
  );
};
