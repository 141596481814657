// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { deepEqual } from 'fast-equals';

import { DragControl } from '../../../lib/DragControl';
import {
  DiscreteValuesConstraint,
  DragConstraint,
  MinMaxDeltaConstraint,
} from '../../../lib/dragConstraints';
import { DragOffset } from '../../../lib/dragUtil';
import { isArrowKey } from '../../../lib/event';
import { getElementCenter } from '../../../lib/geometry';
import { clamp, generateRange } from '../../../lib/number';

import { Readout } from './Readout';
import { SliderThumb } from './Thumb';
import {
  CommonSliderProps,
  DEFAULT_GUTTER_HEIGHT,
  DEFAULT_INCREMENT_STEPS,
  DEFAULT_READOUT_TIMEOUT,
  DEFAULT_THUMB_RADIUS,
  RangeSliderSide,
  isArrowDecrement,
} from './common';
import {
  ThumbValue,
  calculateStopValues,
  constrainToStopValues,
  subsetStopValues,
  useSliderState,
} from './util';

import './Slider.scss';

const THUMB_WIDTH = 8;
const THUMB_HEIGHT = 16;

export type RangeValue = [number, number];

interface RangeThumbControlProps {
  // Which side (left or right) is this control for
  side: RangeSliderSide;

  // Minimum, maximum and current slider values (for aria props)
  min: number;
  max: number;
  value: number;

  onFocus: (side: RangeSliderSide) => void;
  onKeyDown: (event: React.KeyboardEvent, side: RangeSliderSide) => void;
  onMouseDown: (event: React.MouseEvent, side: RangeSliderSide) => void;
}

/**
 * RangeThumbControl encapsulates layout and functionality common to both of
 * the RangeSlider's thumb controls.
 */
export const RangeThumbControl = (props: RangeThumbControlProps) => {
  const { min, max, onFocus, onKeyDown, onMouseDown, side, value } = props;
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <div
      aria-valuemax={max}
      aria-valuemin={min}
      aria-valuenow={value}
      className="control"
      onClick={(event) => event.stopPropagation()}
      onFocus={() => onFocus(side)}
      onKeyDown={(event) => onKeyDown(event, side)}
      onMouseDown={(event) => onMouseDown(event, side)}
      role="slider"
      tabIndex={0}
    />
  );
};

export type RangeSliderProps = CommonSliderProps<RangeValue>;

/**
 * The RangeSlider component implements a min/max slider with two thumbs, using
 * the implementation in the EditColorsBox component as a reference and guide.
 */
export const RangeSlider = (props: RangeSliderProps) => {
  const {
    disabled = false,
    gutterHeight = DEFAULT_GUTTER_HEIGHT,
    incrementSteps = DEFAULT_INCREMENT_STEPS,
    max,
    min,
    onChange,
    onCommit,
    readoutConfig,
    showStops,
    stopCount,
    value,
    thumbRadius = DEFAULT_THUMB_RADIUS,
    thumbOffset = 0,
  } = props;

  // How far above the gutter should the readout appear
  const readoutOffset = readoutConfig?.offset ?? gutterHeight;

  // The useSliderState hook includes helper refs, functions, and state used
  // for common slider operations
  const {
    rootNodeRef,
    readoutActive, setReadoutActive,
    readoutTimer, setReadoutTimer,
    dragOffset, setDragOffset,
    dragStatus, setDragStatus,
    thumbDragState, setThumbDragState,
    stopValues, setStopValues,
    canClick,
    startDrag,
    endDrag,
    scheduleDeactivateReadout,
    prepareMouseInteraction,
  } = useSliderState();

  // Track DOM nodes containing the thumb controls
  const leftThumbNode = useRef<HTMLDivElement>(null);
  const rightThumbNode = useRef<HTMLDivElement>(null);

  // Track which thumb (left or right) to sync with the Readout
  const [readoutSide, setReadoutSide] = useState<RangeSliderSide | null>(null);
  // Wrap value(s) in ThumbValue class for easier access to derived values (like percentage)
  const [thumbValue, setThumbValue] = useState<Record<RangeSliderSide, ThumbValue>>(
    {
      left: new ThumbValue(value[0], min, max),
      right: new ThumbValue(value[1], min, max),
    },
  );

  // Call onChange only if the new value differs from the existing value and
  // call onCommit when `commit` is true (i.e. when dragging ends or when the
  // value is changed by a simple click event).
  const announceValue = useCallback((newValue: RangeValue, commit = false) => {
    if (newValue[0] !== value[0] || newValue[1] !== value[1]) {
      onChange(newValue);
    }
    if (commit && onCommit) {
      onCommit(newValue);
    }
  }, [onChange, onCommit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStopValues(calculateStopValues(min, max, stopCount));
  }, [max, min, setStopValues, stopCount]);

  // Whenever the value changes, update the thumbValue state.
  useEffect(() => {
    const newThumbValue = {
      left: new ThumbValue(value[0], min, max),
      right: new ThumbValue(value[1], min, max),
    };

    // If we don't check for equality, we'll enter in an infinite loop.
    if (!deepEqual(thumbValue, newThumbValue)) {
      setThumbValue(newThumbValue);
    }
  }, [max, min, value, thumbValue]);

  // Whenever dragOffset changes during an active thumb drag, update the value.
  useEffect(() => {
    if (dragOffset && thumbDragState) {
      // Given a drag offset and a parent box width, extrapolate a `real` value
      const deltaValue = (dragOffset.deltaX / thumbDragState.boxWidth) * (max - min);
      const newSideValue = thumbDragState?.initialValue.value + deltaValue;

      const newValue = [...value] as RangeValue;
      const insignificantDistanceFromBoundary = (max - min) / 1000;

      if (thumbDragState.side === 'right') {
        // If the dragged thumb is so close to the "max" value that the difference between them is
        // less than 1/1000th of the whole range, we can safely assume that the user intention was
        // to drag to the "max" value but sub pixel precision and rounding errors are setting a
        // value that is slightly different. This is easier seen if the "max" value is 0.
        // In that case, we just force the "max" value directly.
        newValue[1] = max - newSideValue < insignificantDistanceFromBoundary ? max : newSideValue;
      } else {
        // Same as above, but for the min value. Force the "min" value if the left thumb was dragged
        // very close to the "min" but the value generated was not "min". This is also easily
        // manifested when "min" is 0.
        newValue[0] = newSideValue - min < insignificantDistanceFromBoundary ? min : newSideValue;
      }

      announceValue(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announceValue, dragOffset, max, min, thumbDragState]);

  // When dragStatus is set to 'completed', set it to null and trigger a call to
  // onCommit
  useEffect(() => {
    if (dragStatus === 'completed') {
      setDragStatus(null);
      announceValue([...value], true);
    }
  }, [announceValue, dragStatus, setDragStatus, value]);

  const handleMouseDownThumb = (event: React.MouseEvent, side: RangeSliderSide) => {
    // Start a drag event if prepareMouseInteraction returns true
    if (!prepareMouseInteraction(event, disabled)) {
      return;
    }

    const isRightSide = (side === 'right');
    const thisThumb = isRightSide ? rightThumbNode.current : leftThumbNode.current;
    const otherThumb = isRightSide ? leftThumbNode.current : rightThumbNode.current;

    if (thisThumb && otherThumb && rootNodeRef.current) {
      setReadoutSide(side);
      // Constrain the drag operation to the left/right edges of the root node.
      // Each thumb (left/right) is further constrained not to cross the other
      // thumb

      const thisThumbValue = thumbValue[side];
      const otherThumbValue = thumbValue[isRightSide ? 'left' : 'right'];

      // First get the box describing the root node
      const refBox = rootNodeRef.current.getBoundingClientRect();
      // Get the x-coordinates of each thumb
      const thisThumbPosn = getElementCenter(thisThumb).x;
      const otherThumbPosn = getElementCenter(otherThumb).x;

      // Calculate min/max x-coordinates relative to refBox
      const minX = isRightSide ? otherThumbPosn : refBox.left;
      const maxX = isRightSide ? refBox.right : otherThumbPosn;

      // Calculate min/max deltas
      const minDeltaX = minX - thisThumbPosn;
      const maxDeltaX = maxX - thisThumbPosn;
      // Construct drag constraint
      const constraints: DragConstraint[] = [];

      if (stopValues) {
        // Initial position (in pixels) of the thumb being dragged
        const initPoint = { x: thisThumbValue.pixelsFrom(refBox), y: 0 };
        // Subset of stopValues between the 'other' thumb and its far side
        const subStops = subsetStopValues(
          stopValues,
          isRightSide ? otherThumbValue.value : null,
          isRightSide ? null : otherThumbValue.value,
        );
        // Transform stopValues into pixel positions for compatibility with the
        // DiscreteValuesConstraint
        const stops = subStops.map((stop) => ({ x: stop.pixelsFrom(refBox), y: 0 }));
        constraints.push(
          new DiscreteValuesConstraint(initPoint, stops, 'horizontal'),
        );
      } else {
        constraints.push(
          new MinMaxDeltaConstraint(minDeltaX, maxDeltaX, 'horizontal'),
        );
      }

      // Initiate drag
      const dragControl = new DragControl(
        event.nativeEvent,
        () => {
          // When drag starts, activate the readout
          setReadoutActive(true);
          setReadoutSide(side);
          setDragStatus('started');
          startDrag();
        },
        (offset: DragOffset) => {
          // When dragging, update the dragOffset
          setDragOffset(offset);
        },
        () => {
          // When drag ends, reset the drag state and schedule the readout for deactivation
          setDragOffset(null);
          setThumbDragState(null);
          setDragStatus('completed');
          scheduleDeactivateReadout(DEFAULT_READOUT_TIMEOUT);
          endDrag();
        },
        constraints,
      );

      setThumbDragState({
        dragControl,
        boxWidth: refBox.width,
        initialValue: thisThumbValue,
        side,
      });
    }
  };

  const handleClickRoot = (event: React.MouseEvent) => {
    if (!canClick) {
      return;
    }

    // If prepareMouseInteraction returns true, then handle clicks anywhere in
    // the DOM structure by moving the closest thumb to the x-coordinate of the
    // click.
    if (!prepareMouseInteraction(event, disabled)) {
      return;
    }

    event.preventDefault();

    // event.target should be the root node, because the inner children all
    // have pointer-events set to none in the CSS or their onClick handlers
    // stop the event from bubbling
    const box = (event.target as HTMLElement).getBoundingClientRect();
    const xPosn = clamp(event.nativeEvent.offsetX, [0, box.width]);
    const xValue = ThumbValue.fromParameterized(xPosn / box.width, min, max);

    // We can use the midpoint between the left and right thumbs as a dividing
    // line.  Clicks to the left of this midpoint should move the left thumb,
    // clicks to the right of this midpoint should move the right thumb.
    const midParam = (thumbValue.left.parameterized + thumbValue.right.parameterized) / 2;

    const rightThumb = (xValue.parameterized >= midParam);
    const newValue = [...value] as RangeValue;

    let newSideValue = xValue.value;

    if (stopValues) {
      newSideValue = constrainToStopValues(newSideValue, stopValues).value;
    }

    newValue[rightThumb ? 1 : 0] = newSideValue;

    setReadoutSide(rightThumb ? 'right' : 'left');
    scheduleDeactivateReadout(DEFAULT_READOUT_TIMEOUT);

    announceValue(newValue, true);
  };

  const handleKeyDownThumb = (event: React.KeyboardEvent, side: RangeSliderSide) => {
    // When the user clicks the left or right arrows while focused on a thumb,
    // move the thumb by increments defined by the `steps` prop
    if (disabled) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey) {
      return;
    }

    event.stopPropagation();

    if (isArrowKey(event)) {
      if (readoutTimer) {
        clearTimeout(readoutTimer);
        setReadoutTimer(null);
      }

      const moveLeft = isArrowDecrement(event);

      // If user is holding down SHIFT, multiply increments by 10
      const multiplier = event.shiftKey ? 10 : 1;

      const isRightSide = side === 'right';
      const curValue = isRightSide ? value[1] : value[0];
      const divisor = stopValues ? (stopValues.length - 1) : incrementSteps;
      const deltaValue = ((moveLeft ? -1 : 1) * multiplier * (max - min)) / divisor;

      // Constrain value to the main min/max values
      let newSideValue = clamp(curValue + deltaValue, [min, max]);

      if (stopValues) {
        let subStops = subsetStopValues(
          stopValues,
          moveLeft ? null : curValue,
          moveLeft ? curValue : null,
          true,
        );
        if (event.shiftKey) {
          subStops = moveLeft ? subStops.slice(0, -9) : subStops.slice(-9, 0);
        }
        // If subStops is empty, then we're already at the far left or far right
        if (subStops.length) {
          const idx = moveLeft ? subStops.length - 1 : 0;
          newSideValue = subStops[idx].value;
        }
      }

      // Further constrain values such that one slider never crosses the other
      if (isRightSide) {
        newSideValue = Math.max(newSideValue, value[0]);
      } else {
        newSideValue = Math.min(newSideValue, value[1]);
      }

      const newValue = [...value] as RangeValue;
      if (isRightSide) {
        newValue[1] = newSideValue;
      } else {
        newValue[0] = newSideValue;
      }

      setReadoutSide(side);
      setReadoutActive(true);
      scheduleDeactivateReadout(DEFAULT_READOUT_TIMEOUT);

      announceValue(newValue, true);
    }
  };

  // When a thumb acquires focus, show the readout for a short time
  const handleFocusThumb = (side: RangeSliderSide) => {
    // If thumbDragState is truthy, then the user has focused the thumb by
    // clicking to drag, and the readout state is already being managed.
    if (thumbDragState) {
      return;
    }

    // If we're here, then the user has focused the thumb by tabbing, and it's
    // useful to flash the readout for a brief moment.
    if (readoutTimer) {
      clearTimeout(readoutTimer);
      setReadoutTimer(null);
    }
    setReadoutActive(true);
    setReadoutSide(side);
    scheduleDeactivateReadout(DEFAULT_READOUT_TIMEOUT);
  };

  return (
    <div
      className={cx('rangeSlider', 'sliderCommon', { disabled, dragging: thumbDragState })}
      onClick={(event) => handleClickRoot(event)}
      onKeyUp={() => { }}
      ref={rootNodeRef}
      role="button"
      style={{
        '--thumb-height': `${THUMB_HEIGHT}px`,
        '--thumb-width': `${THUMB_WIDTH}px`,
        '--gutter-height': `${gutterHeight}px`,
        '--thumb-radius': `${thumbRadius}px`,
        '--thumb-offset': `${thumbOffset}px`,
      } as CSSProperties}
      tabIndex={0}>
      <div className="gutter">
        <div
          className="indicator"
          style={{
            left: `${thumbValue.left.percentageClamped}%`,
            width: `${thumbValue.right.percentageClamped - thumbValue.left.percentageClamped}%`,
          }}
        />
        {showStops && stopValues && (
          <div className="stops">
            {generateRange(stopValues.length - 2).map((i) => (
              <div
                className="stopMark"
                key={`${i}`}
                style={{ left: `${(100 * (i + 1)) / (stopValues.length - 1)}%` }}
              />
            ))}
          </div>
        )}
      </div>
      <SliderThumb
        dragging={thumbDragState?.side === 'left'}
        leftPercentage={thumbValue.left.percentageClamped}
        max={max}
        min={min}
        onFocus={() => handleFocusThumb('left')}
        onKeyDown={(event) => handleKeyDownThumb(event, 'left')}
        onMouseDown={(event) => handleMouseDownThumb(event, 'left')}
        ref={leftThumbNode}
        value={value[0]}
      />
      <SliderThumb
        dragging={thumbDragState?.side === 'right'}
        leftPercentage={thumbValue.right.percentageClamped}
        max={max}
        min={min}
        onFocus={() => handleFocusThumb('right')}
        onKeyDown={(event) => handleKeyDownThumb(event, 'right')}
        onMouseDown={(event) => handleMouseDownThumb(event, 'right')}
        ref={rightThumbNode}
        value={value[1]}
      />
      {readoutSide && (
        <Readout
          active={readoutActive}
          disabled={readoutConfig?.disabled || disabled}
          formatValue={readoutConfig?.formatValue}
          thumbValue={thumbValue[readoutSide]}
          verticalOffset={readoutOffset}
        />
      )}
    </div>
  );
};
