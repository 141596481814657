// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as userstatepb from '../proto/userstate/userstate_pb';

import * as jwt from './jwt';
import { isTestingEnv } from './testing/utils';

const allRoles = [
  { proto: userstatepb.SystemRole.USER, name: 'User' },
  { proto: userstatepb.SystemRole.STAFF, name: 'Staff' },
  { proto: userstatepb.SystemRole.ADMIN, name: 'Admin' },
];

/**
 * @returns all roles the user should be able to impersonate (only their actual role and below)
 */
export function validRoles() {
  if (isTestingEnv()) {
    return allRoles;
  }

  const js = jwt.loadSessionJwt();
  if (js) {
    const systemRole = jwt.systemRole(js);
    return allRoles.filter((role) => role.proto <= systemRole);
  }
  return [];
}
