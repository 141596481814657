// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import './InputDescription.scss';
import { RingExclamationIcon } from '../../svg/RingExclamationIcon';

export interface InputDescriptionProps {
  value: string;
  faultType?: 'error' | 'warning';
}

export const InputDescription = (props: InputDescriptionProps) => {
  const { value, faultType } = props;

  return (
    <div className={cx('inputDescription', faultType)}>
      <span>
        {(faultType === 'error') && <><RingExclamationIcon maxHeight={10} maxWidth={10} />&nbsp;</>}
        {value}
      </span>
    </div>
  );
};
