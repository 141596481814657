// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Logger } from '../../observability/logs';
import { LcvDisplay } from '../classes/LcvDisplay';
import { LcvGridAxes } from '../classes/annotations/LcvGridAxes';
import { LcvRefinementBox } from '../classes/annotations/refinementRegionAnnotations/LcvRefinementBox';
import { LcvRefinementCylinder } from '../classes/annotations/refinementRegionAnnotations/LcvRefinementCylinder';
import { LcvRefinementRegionAnnotation } from '../classes/annotations/refinementRegionAnnotations/LcvRefinementRegionAnnotation';
import { LcvRefinementSphere } from '../classes/annotations/refinementRegionAnnotations/LcvRefinementSphere';
import * as AnnotationType from '../classes/annotations/types';
import { LcvModule } from '../types';

const logger = new Logger('LcvAnnotationHandler');

const GRID_AXES_KEY: string = 'grid_axes';

/**
 * A class which handles refinement region annotations in LCVis.
 */
export class LcvRrAnnotationHandler {
  lcv: LcvModule = null;
  display: LcvDisplay | null = null;
  sessionHandle: number | null = null;

  annotationsMap: Map<string, LcvRefinementRegionAnnotation> = new Map();

  gridAxes: LcvGridAxes | null = null;
  gridAttached : boolean = false;

  constructor(lcv: LcvModule, display: LcvDisplay, sessionHandle: number) {
    this.lcv = lcv;
    this.display = display;
    this.sessionHandle = sessionHandle;
  }

  /**
 * Function to create a concrete annotation instance based on the 'typ' property.
 */
  createAnnotation(
    lcv: LcvModule,
    sessionHandle: number,
    region: AnnotationType.AnnotationParam,
  ): LcvRefinementRegionAnnotation | null {
    switch (region.param.typ) {
      case AnnotationType.REFINEMENT_BOX:
        return new LcvRefinementBox(lcv, sessionHandle, region);
      case AnnotationType.REFINEMENT_CYLINDER:
        return new LcvRefinementCylinder(lcv, sessionHandle, region);
      case AnnotationType.REFINEMENT_SPHERE:
        return new LcvRefinementSphere(lcv, sessionHandle, region);
      default:
        return null;
    }
  }

  updateRefinementRegionAnnotations(
    refinementRegions: Map<string, AnnotationType.AnnotationParam>,
  ) {
    // Detach and release all refinement regions that are not in proto anymore.
    this.detachAndReleaseRefinementRegions(refinementRegions);

    // Update existing annotations
    this.updateExistingRefinementRegions(refinementRegions);

    // Add and attach new refinement region annotations
    this.addAndAttachNewRefinementRegions(refinementRegions);
  }

  showGridAxes(show: boolean): void {
    if (show) {
      if (!this.gridAxes) {
        this.gridAxes = new LcvGridAxes(this.lcv, this.sessionHandle!);
      }

      this.display!.frames.forEach((frame) => {
        frame.attachAnnotation(GRID_AXES_KEY, this.gridAxes!);
      });
      this.gridAttached = true;
    } else {
      this.display!.frames.forEach((frame) => {
        frame.detachAnnotation(GRID_AXES_KEY);
      });
      this.gridAttached = false;
    }
  }

  /**
 * Identify annotations to be detached and released.
 * These are annotations in the map but not in the input refinement regions map.
 */
  identifyRefinementRegionsToDetach(
    refinementRegions: Map<string, AnnotationType.AnnotationParam>,
  ): string[] {
    const regionsToDelete: string[] = [];
    this.annotationsMap.forEach((annotation, key) => {
      if (annotation?.annotationCategory === AnnotationType.REFINEMENT_REGION &&
        !refinementRegions.has(key)) {
        regionsToDelete.push(key);
      }
    });
    return regionsToDelete;
  }

  /**
   * Detach and release annotations that are not present in the input refinement regions map.
   */
  detachAndReleaseRefinementRegions(
    refinementRegions: Map<string, AnnotationType.AnnotationParam>,
  ): void {
    // Create a list of keys to track which annotations need to be deleted from the map
    const regionsToDelete = this.identifyRefinementRegionsToDetach(refinementRegions);

    regionsToDelete.forEach((key) => {
      this.display!.frames.forEach((frame) => {
        frame.detachAnnotation(key);
      });
      this.annotationsMap.get(key)?.release();
      this.annotationsMap.delete(key);
    });
  }

  /**
   * Update existing annotations based on the input refinement regions map.
   */
  updateExistingRefinementRegions(
    refinementRegions: Map<string, AnnotationType.AnnotationParam>,
  ): void {
    refinementRegions.forEach((region, key) => {
      const annotation = this.annotationsMap.get(key);
      if (annotation) {
        annotation.updateAnnotationParam(region);
      }
    });
  }

  /**
   * Add and attach new annotations based on the input refinement regions map.
   */
  addAndAttachNewRefinementRegions(
    refinementRegions: Map<string, AnnotationType.AnnotationParam>,
  ): void {
    refinementRegions.forEach((region, key) => {
      const annotation = this.annotationsMap.get(key);
      if (!annotation) {
        const newAnnotation = this.createAnnotation(this.lcv, this.sessionHandle!, region);
        if (newAnnotation) {
          this.display!.frames.forEach((frame) => {
            frame.attachAnnotation(key, newAnnotation);
            this.annotationsMap.set(key, newAnnotation);
          });
        } else {
          logger.error(`No concrete refinement region found for ${key}`);
        }
      }
    });
  }

  /*
  * Release all annotations and clear the map once all are released.
  */
  releaseAnnotations(): void {
    this.annotationsMap.forEach((annotation) => {
      annotation.release();
    });
    this.annotationsMap.clear();
    if (this.gridAxes) {
      this.gridAxes.release();
      this.gridAxes = null;
    }
  }
}
