// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { applyFeatureWarning, isFeatureModified } from '../../../../lib/geometryUtils';
import { useGeometryState } from '../../../../recoil/geometry/geometryState';
import { ActionButton } from '../../../Button/ActionButton';
import { IconButton } from '../../../Button/IconButton';
import Tooltip from '../../../Tooltip';
import { useProjectContext } from '../../../context/ProjectContext';
import { useDeleteGeometryModification } from '../../../hooks/useInteractiveGeometry';
import { TrashIcon } from '../../../svg/TrashIcon';

interface GeoModFooterProps {
  featureId: string;
  onModificationSave?: () => void;
}

function getDisabledApplyReason(readOnly: boolean, featureModified: boolean) {
  if (readOnly) {
    return 'Cannot modify the geometry in read-only mode';
  }
  if (!featureModified) {
    return 'Feature is up-to-date';
  }
  return undefined;
}

export const GeometryModificationPanelFooter = (props: GeoModFooterProps) => {
  const { readOnly, projectId, geometryId } = useProjectContext();
  const geometryState = useGeometryState(projectId, geometryId);
  const { deleteRow, disabledDeleteReason } = useDeleteGeometryModification();

  const feature = useMemo(() => (
    geometryState?.geometryFeatures.find((item) => item.id === props.featureId)
  ), [geometryState?.geometryFeatures, props.featureId]);
  const isImport = feature?.operation.case === 'import';
  const featureModified = isFeatureModified(feature, geometryState?.geometryFeaturesServer);

  const warning = applyFeatureWarning(feature);
  const disableApply = readOnly || !featureModified || !!warning;
  const disabledApplyReason = getDisabledApplyReason(readOnly, featureModified) || warning;

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: '20px',
    }}>
      <Tooltip placement="top" title={disabledDeleteReason}>
        <span>
          <IconButton
            disabled={!!disabledDeleteReason}
            name="Delete Modification"
            onClick={deleteRow}>
            <TrashIcon maxWidth={12} />
          </IconButton>
        </span>
      </Tooltip>
      {!isImport && (
        <ActionButton
          disabled={disableApply}
          onClick={props.onModificationSave}
          size="small"
          title={disabledApplyReason}>
          Apply
        </ActionButton>
      )}
    </div>
  );
};

export default GeometryModificationPanelFooter;
