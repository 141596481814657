// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { particleGroupTypeLabel } from '../../../../lib/particleGroupUtils';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { Attribute, AttributesDisplay } from '../../AttributesDisplay';

interface ParticleGroupMetaPanelProps {
  particleGroupType: simulationpb.ParticleGroupType;
}

// Displays the particle group type in a unique layout
export const ParticleGroupMetaPanel = (props: ParticleGroupMetaPanelProps) => {
  const { particleGroupType } = props;

  const attributes: Attribute[] = [];
  const typeLabel = particleGroupTypeLabel(particleGroupType);
  if (typeLabel) {
    attributes.push({ label: 'Type', value: typeLabel });
  }

  return attributes ? (
    <AttributesDisplay attributes={attributes} />
  ) : (
    <></>
  );
};
