// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const CommentsIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="comments"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M22.4,-0
           L1.6,-0
           C0.72,-0 0,0.72 0,1.6
           L0,16
           C0,16.88 0.72,17.6 1.6,17.6
           L4.8,17.6
           L4.8,22.4
           C4.8,23.28 5.52,24 6.4,24
           C6.848,24 7.248,23.824 7.536,23.536
           L13.456,17.6
           L22.4,17.6
           C23.28,17.6 24,16.88 24,16
           L24,1.6
           C24,0.72 23.28,-0 22.4,-0Z
           M5.6,11.2
           C4.272,11.2 3.2,10.128 3.2,8.8
           C3.2,7.472 4.272,6.4 5.6,6.4
           C6.928,6.4 8,7.472 8,8.8
           C8,10.128 6.928,11.2 5.6,11.2Z
           M12,11.2
           C10.672,11.2 9.6,10.128 9.6,8.8
           C9.6,7.472 10.672,6.4 12,6.4
           C13.328,6.4 14.4,7.472 14.4,8.8
           C14.4,10.128 13.328,11.2 12,11.2Z
           M18.4,11.2
           C17.072,11.2 16,10.128 16,8.8
           C16,7.472 17.072,6.4 18.4,6.4
           C19.728,6.4 20.8,7.472 20.8,8.8
           C20.8,10.128 19.728,11.2 18.4,11.2Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
