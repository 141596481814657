// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import computeBackoff, { backoffParams } from './backoff';

// Wraps "func" so that it is invoked at most once per period. The function
// will be called after it stops being called for the period specified.
export function debounce(func: ((this: any, ...args: any[]) => void), period = 100) {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  return function debouncedFn(this: any, ...args: any[]) {
    const delayedFunc = () => {
      timeout = null;
      return func.apply(this, args);
    };
    timeout && clearTimeout(timeout);
    timeout = setTimeout(delayedFunc, period);
  };
}

// Wraps "func" so that the function cannot be invoked more than once per period. The wrapped func
// will be executed when it's first called, but it would execute again only if it's called after
// the period has passed from its last call.
export function throttle(func: ((this: any, ...args: any[]) => void), period = 100) {
  let isRunning = false;
  return function throttledFn(this: any, ...args: any[]) {
    if (!isRunning) {
      isRunning = true;
      func.apply(this, args);

      setTimeout(() => {
        isRunning = false;
      }, period);
    }
  };
}

// Quick but weak hash of a message.
export function hashMessage(message: string): number {
  let hash = 0;
  for (let i = 0; i < message.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + message.charCodeAt(i) | 0;
  }
  return hash;
}

// Helper function that waits for "ms" milliseconds.
async function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Implements "fetch" with numRetries retries on network errors and specified retryCodes.
// Uses exponential backoff with jitter. Default backoff is limited to ~32 secs.
// The response of the first successful fetch or response/error of the last fetch (1 + numRetries)
// is returned/thrown.
export async function fetchWithRetry(
  url: string,
  options = {},
  numRetries: number,
  retryCodes: number[] = [],
  backoff: backoffParams = {
    jitter: 1.2,
    exp: 2,
    minBackoff: 500,
    maxMultiplier: 64,
  },
): Promise<Response> {
  if (numRetries < 0) {
    throw Error('Invalid argument');
  }
  let error;
  for (let i = 0; i <= numRetries; i += 1) {
    try {
      const resp = await fetch(url, options);
      if (i >= numRetries || !retryCodes.includes(resp.status)) {
        return resp;
      }
    } catch (err) {
      error = err;
    }
    // Backoff except on last retry
    if (i !== numRetries) {
      await wait(computeBackoff(i, backoff));
    }
  }
  throw error;
}
