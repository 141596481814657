// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { FixedSizeArray } from './tsUtils';

export type Vector<N extends number> = FixedSizeArray<N, number>;
export type Matrix<M extends number, N extends number> = FixedSizeArray<M, Vector<N>>;

// Add two N-dimensional vectors
export function addVectors<N extends number>(v1: Vector<N>, v2: Vector<N>): Vector<N> {
  return v1.map((value, i) => value + v2[i]) as Vector<N>;
}

// Subtract one N-dimensional vector from another
export function subtractVectors<N extends number>(v1: Vector<N>, v2: Vector<N>): Vector<N> {
  return v1.map((value, i) => value - v2[i]) as Vector<N>;
}

// Multiply an N-dimensional vector by a scalar
export function scalarMultiplyVector<N extends number>(v1: Vector<N>, scale: number): Vector<N> {
  return v1.map((value, i) => value * scale) as Vector<N>;
}

// Calculate the dot product (or scalar product) of two N-dimensional vectors
export function dotProduct<N extends number>(v1: Vector<N>, v2: Vector<N>): number {
  return v1.reduce((result, value, i) => result + value * v2[i], 0);
}

// Calculate the cross product (or vector product) of two 3-dimensional vectors
export function crossProduct(v1: Vector<3>, v2: Vector<3>): Vector<3> {
  return [
    (v1[1] * v2[2]) - (v1[2] * v2[1]),
    (v1[2] * v2[0]) - (v1[0] * v2[2]),
    (v1[0] * v2[1]) - (v1[1] * v2[0]),
  ];
}

// Calculate the magnitude of an N-dimensional vector
export function magnitude<N extends number>(vec: Vector<N>): number {
  return Math.sqrt(dotProduct(vec, vec));
}

export function unitVector<N extends number>(vec: Vector<N>): Vector<N> {
  return scalarMultiplyVector(vec, 1 / magnitude(vec));
}

// Get the column of a square matrix `matrix` at index `index`
export function getMatrixColumn<M extends number, N extends number>(
  matrix: Matrix<M, N>,
  index: number,
): Vector<M> {
  return matrix.map((row) => row[index]) as Vector<M>;
}

// Return an NxN identity matrix
export function identityMatrix<N extends number>(size: N): Matrix<N, N> {
  const result: number[][] = [];

  for (let i = 0; i < size; i += 1) {
    const row: number[] = [];
    for (let j = 0; j < size; j += 1) {
      row.push(i === j ? 1 : 0);
    }
    result.push(row);
  }

  return result as Matrix<N, N>;
}

// Return the product of one or more square matrices
export function matrixProduct<N extends number>(...matrices: Matrix<N, N>[]): Matrix<N, N> {
  if (matrices.length === 0) {
    throw Error('Cannot multiply empty matrix list');
  }

  return matrices.reduce((result, matrix, i) => {
    if (i) {
      return result.map(
        (row) => row.map((cell, j) => dotProduct(row, getMatrixColumn(matrix, j))),
      ) as Matrix<N, N>;
    }
    return result;
  }, matrices[0]);
}

// Apply an MxN matrix to an N-dimensional vector, returning an M-dimensional vector
export function applyMatrixToVector<M extends number, N extends number>(
  matrix: Matrix<M, N>,
  vector: Vector<N>,
): Vector<M> {
  return matrix.map((row, i) => (
    row.reduce((result, cell, j) => result + (cell * vector[j]), 0)
  )) as Vector<M>;
}

// Compute the opengl view matrix for a given camera position, lookAt position
// and an up vector. This uses a right-handed coordinate system so the camera
// will be looking down the negative z-axis.
export function computeViewMatrix(pos: Vector<3>, lookAt: Vector<3>, up: Vector<3>): Matrix<4, 4> {
  const look = unitVector(subtractVectors(lookAt, pos));
  const right = unitVector(crossProduct(look, up));
  const uUp = unitVector(crossProduct(right, look));
  const viewMat = identityMatrix(4);
  viewMat[0][0] = right[0];
  viewMat[0][1] = right[1];
  viewMat[0][2] = right[2];
  viewMat[1][0] = uUp[0];
  viewMat[1][1] = uUp[1];
  viewMat[1][2] = uUp[2];
  viewMat[2][0] = -look[0];
  viewMat[2][1] = -look[1];
  viewMat[2][2] = -look[2];
  viewMat[0][3] = -dotProduct(right, pos);
  viewMat[1][3] = -dotProduct(uUp, pos);
  viewMat[2][3] = dotProduct(look, pos);
  return viewMat;
}
