// Copyright 2020-2023 Luminary Cloud, Inc. All Rights Reserved.

import React, { useEffect } from 'react';

import { createStyles, makeStyles } from '../../components/Theme';
import { AuthPageLayout, useAuthPageLayoutStyles } from '../../components/layout/page/AuthPageLayout';
import LetterShielded from '../../components/svg/LetterShielded';
import useCountdown from '../../lib/useCountdown';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      alignItems: 'center',
      marginTop: '52px', // needs 168px, but the left section in AuthPageLayout already has 116px
      textAlign: 'center',
    },
  }),
  { name: 'Setup2FAComplete' },
);

interface Setup2FACompleteProps {
  onComplete: () => void;
}

// This page will be shown when the user sucessfully configures MFA method and has written their
// recovery code. The page will show only for a few seconds, before the user gets redirected.
const Setup2FAComplete = (props: Setup2FACompleteProps) => {
  // Props
  const { onComplete } = props;

  // Hooks
  const classes = useStyles();
  const authClasses = useAuthPageLayoutStyles();
  const { completed, secondsLeft, startCountdown } = useCountdown(5);

  // Effects
  useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  useEffect(() => {
    if (completed) {
      onComplete();
    }
  }, [completed, onComplete]);

  return (
    <AuthPageLayout
      rightSection={false}>
      <div className={classes.root}>
        <LetterShielded />
        <div className={authClasses.title}>
          Great! Two-factor authentication is now enabled on your account
        </div>
        <div>
          Redirecting in {secondsLeft} seconds...
        </div>
      </div>
    </AuthPageLayout>
  );
};

export default Setup2FAComplete;
