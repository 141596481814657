// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useWorkflowConfig } from '../../model/hooks/useWorkflowConfig';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useSimulationParamScope } from '../../state/external/project/simulation/paramScope';
import { useProjectContext } from '../context/ProjectContext';

export type ParamOperation<T> = (param: simulationpb.SimulationParam) => T;

/**
 * A wrapper for the useWorkflowConfig model hook
 */
export const useSimulationConfig = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  const result = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  return {
    ...result,
    paramScope,
  };
};
