// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import validator from 'validator';

import { ActionButton } from '../../components/Button/ActionButton';
import Form from '../../components/Form';
import { RadioButton } from '../../components/Form/RadioButton';
import { TextInput } from '../../components/Form/TextInput';
import { NumberSpinner } from '../../components/NumberSpinner';
import { createStyles, makeStyles } from '../../components/Theme';
import Tooltip from '../../components/Tooltip';
import { LuminaryToggleSwitch } from '../../components/controls/LuminaryToggleSwitch';
import { Table } from '../../components/data/Table';
import { Dialog } from '../../components/dialog/Base';
import { MainPageLayout } from '../../components/layout/page/Main';
import { DuplicateIcon } from '../../components/svg/DuplicateIcon';
import { ColumnConfig, RowConfig } from '../../lib/componentTypes/table';
import { colors } from '../../lib/designSystem';
import { formatDate, formatDuration } from '../../lib/formatDate';
import { Logger } from '../../lib/observability/logs';
import * as rpc from '../../lib/rpc';
import * as status from '../../lib/status';
import * as basepb from '../../proto/base/base_pb';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as kmspb from '../../proto/kms/kms_pb';
import useAccountInfo from '../../recoil/useAccountInfo';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';

const logger = new Logger('AccountPage/SecurityPageBody');

const useStyles = makeStyles(
  () => createStyles({
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '96px',
    },
    copyButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: 0,
      color: colors.highEmphasisText,
      '&:active': {
        color: colors.lowEmphasisText,
        scale: 0.9,
      },
    },
    formSections: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '40px',
      padding: '20px 0',
    },
    sectionLabel: {
      color: colors.lowEmphasisText,
      fontSize: '12px',
      fontWeight: 600,
      textTransform: 'uppercase',
      marginBottom: '8px',
    },
    radioGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: '24px',
    },
    radioButtonField: {
      flex: '1 1 50%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: '8px',
    },
    radioButtonInput: {
      flex: '0 0 auto',
      display: 'flex',
      padding: '3px 0',
    },
    radioButtonLabel: {
      color: colors.highEmphasisText,
      lineHeight: '24px',
      fontWeight: 600,
      marginBottom: '4px',
    },
    radioButtonDescription: {
      fontWeight: 400,
      lineHeight: '20px',
      color: colors.lowEmphasisText,
    },
    rightInput: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    generateInput: {
      display: 'flex',
      alignItems: 'baseline',
      gap: '8px',
    },
    rotationPeriod: {
      display: 'inline-block',
      maxWidth: '64px',
    },
    inputError: {
      margin: '2px',
      color: colors.red500,
      fontSize: '12px',
    },
    tableHeadline: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '16px',
      fontWeight: 600,
      fontSize: '16px',
      marginBottom: '16px',
    },
    domainToggle: {
      display: 'flex',
      marginLeft: 'auto',
      marginRight: '4px',
      alignItems: 'center',
      gap: '8px',
      fontSize: '13px',
      fontWeight: 400,
    },
    dialogContent: {
      color: colors.lowEmphasisText,
    },
  }),
  { name: 'SecurityPageBody' },
);

function statusString(message: basepb.Status | undefined): string {
  if (!message) {
    return 'Active';
  }
  const parsed = new status.Parser(message);
  return parsed.headlineMessage();
}

// Returns green for Active status, else gray
function getStatusColor(message: string): string {
  return message === 'Active' ? colors.greenYellow500 : colors.neutral500;
}

// Show a placeholder row for the system default encryption key.
const defaultKeyRow: RowConfig[] = [{
  id: '0',
  values: {
    keyName: 'Default',
    rotationSchedule: 'N/A',
    nextScheduledTime: 'N/A',
    status: 'Active',
  },
  cellDisplay: {
    status: [
      { type: 'bullet', color: colors.greenYellow500 },
    ],
  },
}];

function validateDomain(domain: string) {
  // we check if the domains is valid, the library allows us to handle unique domain name situations
  // these are listed below with the default values:
  // require_tld: true
  // allow_underscores: false
  // allow_trailing_dot: false
  // allow_numeric_tld: false
  // allow_wildcard: false
  // The default options are sufficient at this time
  return validator.isFQDN(domain);
}

interface AddDomainDialogProps {
  isOpen: boolean;
  // Called when the user clicks "Add Domain"
  onSubmit: (
    domain: string
  ) => void;
  onClose: () => void;
}

// Dialog for adding a user.
const AddDomainDialog = (props: AddDomainDialogProps) => {
  const classes = useStyles();
  const [domain, setDomain] = useState<string>('');
  const [domainValid, setDomainValid] = useState<boolean>(true);
  const titleText = 'Add a domain';
  const domainErrorText = 'Domain is invalid';
  const submitButtonText = 'Add Domain';

  const [
    domainError,
    setDomainError,
  ] = useState<string>(domainValid ? '' : domainErrorText);
  function validateForm() {
    return validateDomain(domain);
  }

  const handleSubmit = () => {
    if (validateForm()) {
      props.onSubmit(domain);
    }
  };

  return (
    <Dialog
      cancelButton={{ label: 'Cancel' }}
      continueButton={{ label: submitButtonText, disabled: !validateForm() }}
      modal
      onClose={props.onClose}
      onContinue={handleSubmit}
      open={props.isOpen}
      subtitle="Input a domain name that is to be added to the allowed domains"
      title={titleText}>
      <div className={classes.dialogContent}>
        <TextInput
          asBlock
          dataPrivate
          faultType={domainValid ? undefined : 'error'}
          name="email"
          onChange={(newValue) => {
            setDomain(newValue);
            if (validateDomain(newValue)) {
              setDomainValid(true);
              setDomainError('');
            } else {
              setDomainValid(false);
              setDomainError(domainErrorText);
            }
          }}
          placeholder="Add a domain name..."
          value={domain}
        />
        <div className={classes.inputError}>
          {domainError || <>&nbsp;</>}
        </div>
      </div>
    </Dialog>
  );
};

interface AllowAnyDomainsPopupProps {
  onClose: () => void;
  onAllowAllDomains: () => void;
  isOpen: boolean;
}

const AllowAnyDomainsPopup = (props: AllowAnyDomainsPopupProps) => {
  const classes = useStyles();
  return (
    <Dialog
      cancelButton={{ label: 'Cancel' }}
      continueButton={{ label: 'Allow All Domains' }}
      destructive
      modal
      onClose={props.onClose}
      onContinue={props.onAllowAllDomains}
      open={props.isOpen}
      title="Allow All Domains">
      <div className={classes.dialogContent}>
        This will permit sign up invitations to be sent to users from any domain,
        including those outside your organization.
      </div>
    </Dialog>
  );
};

interface EditKeyDialogProps {
  isOpen: boolean;
  // Key being edited.
  keyInfo: kmspb.KeyInfo;
  // Called when the user clicks "Save".
  // period is the new key rotation period in seconds.
  // If rotationEnabled is false, the automatic rotation will be disabled for this key.
  // If rotateImmediately is true, the key will be rotated now.
  onSubmit: (period: number, rotationEnabled: boolean, rotateImmediately: boolean) => void;
  onClose: () => void;
}

// Dialog for changing the KMS settings for one crypto key.
const EditKeyDialog = (props: EditKeyDialogProps) => {
  const [period, setPeriod] = useState<number>(props.keyInfo.rotationPeriod);
  const [rotationEnabled, setRotationEnabled] = useState<boolean>(period > 0);
  const [rotateImmediately, setRotateImmediately] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <Dialog
      cancelButton={{ label: 'Cancel' }}
      continueButton={{ label: 'Save' }}
      modal
      onClose={props.onClose}
      onContinue={() => props.onSubmit(period, rotationEnabled, rotateImmediately)}
      open={props.isOpen}
      subtitle="Update your encryption key settings."
      title="Encryption Key Settings">
      <div className={classes.dialogContent}>
        <div className={classes.formSections}>
          <section>
            <Form.Label className={classes.sectionLabel}>Key Name</Form.Label>
            <TextInput
              asBlock
              disabled
              endAdornment={(
                <button
                  className={classes.copyButton}
                  onClick={async (event) => {
                    event.stopPropagation();
                    await navigator.clipboard.writeText(props.keyInfo.keyName);
                  }}
                  type="button">
                  <DuplicateIcon maxHeight={15} maxWidth={15} />
                </button>
              )}
              onChange={() => { }}
              value={props.keyInfo.keyName}
            />
          </section>
          <section>
            <Form.Label className={classes.sectionLabel}>Rotate Keys</Form.Label>
            <div className={classes.radioGroup}>
              <div className={classes.radioButtonField}>
                <div className={classes.radioButtonInput}>
                  <RadioButton
                    checked={rotationEnabled}
                    name="rotation"
                    onClick={() => setRotationEnabled(true)}
                    value
                  />
                </div>
                <div>
                  <div className={classes.radioButtonLabel}>Automatically</div>
                  <div className={classes.radioButtonDescription}>
                    Luminary will update keys every &nbsp;
                    <div className={classes.rotationPeriod}>
                      <NumberSpinner
                        disabled={!rotationEnabled}
                        minimumValue={1}
                        onCommit={(value) => setPeriod(value * 86400)}
                        value={rotationEnabled ? period / 86400 : 0}
                      />
                    </div>
                    &nbsp; days (the period must be at least 2 days).
                  </div>
                </div>
              </div>
              <div className={classes.radioButtonField}>
                <div className={classes.radioButtonInput}>
                  <RadioButton
                    checked={!rotationEnabled}
                    name="rotation"
                    onClick={() => setRotationEnabled(false)}
                    value={false}
                  />
                </div>
                <div>
                  <div className={classes.radioButtonLabel}>Manually</div>
                  <div className={classes.radioButtonDescription}>
                    User will manually update keys as needed
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className={classes.rightInput}>
              <div className={classes.generateInput}>
                <Form.CheckBox
                  checked={rotateImmediately}
                  onChange={setRotateImmediately}
                />
                <span>Generate new key</span>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Dialog>
  );
};

function handleSubmitKeys(
  keyName: string,
  period: number,
  rotationEnabled: boolean,
  rotateImmediately: boolean,
) {
  const req = new frontendpb.SetKMSRequest({
    keyName,
    rotationPeriod: period,
    manual: !rotationEnabled,
    rotateNow: rotateImmediately,
  });
  rpc.callRetry('SetKMS', rpc.client.setKMS, req).catch((error) => {
    logger.warn(`setkms error: ${status.stringifyError(error)}`);
  });
}

const encryptionKeysColumnConfig: ColumnConfig[] = [
  { id: 'keyName', label: 'Key Name', type: 'string' },
  { id: 'rotationSchedule', label: 'Rotation Schedule', type: 'string' },
  { id: 'nextScheduledTime', label: 'Next Scheduled Time', type: 'string' },
  { id: 'status', label: 'Status', type: 'string' },
];

const allowedDomainsColumnConfig: ColumnConfig[] = [
  { id: 'domainName', label: 'Domain Name', type: 'string' },
];

const SecurityPageBody = () => {
  const classes = useStyles();
  const accountInfo = useAccountInfo();
  const [editDialogOpened, setEditDialogOpened] = useState<boolean>(false);
  const [addDomainDialogOpened, setAddDomainDialogOpened] = useState<boolean>(false);
  const [anyDomainInviteAllowed, setAnyDomainInviteAllowed] = useState<boolean>(false);
  const [anyDomainDialogOpened, setAnyDomainDialogOpened] = useState<boolean>(false);

  const setConfirmStack = useSetConfirmations();

  // Note: accountInfo.kms_key contains at most one elem (for GCS) as of 2021-11.
  const keys = accountInfo?.kmsKey || [];
  const [allowedDomains, setAllowedDomains] = useState<frontendpb.DomainEntry[] | null>(null);
  useEffect(() => {
    const reqAllowedDomains = new frontendpb.AllowedDomainsRequest();
    rpc.callRetry(
      'AllowedDomains',
      rpc.client.getInviteDomains,
      reqAllowedDomains,
    ).then((reply) => {
      setAllowedDomains(reply.domains);
    }).catch((err: Error) => {
      logger.info(`get all allowed domains error: ${status.stringifyError(err)}`);
    });

    const reqAnyDomainInviteAllowed = new frontendpb.AnyDomainInviteAllowedRequest();
    rpc.callRetry(
      'AnyDomainInviteAllowed',
      rpc.client.anyDomainInviteAllowed,
      reqAnyDomainInviteAllowed,
    ).then((reply) => {
      setAnyDomainInviteAllowed(reply.allowAnyDomainInvite);
    }).catch((err: Error) => {
      logger.info(`get allow all domains error: ${status.stringifyError(err)}`);
    });
  }, []);

  function handleSubmitAddDomain(domainName: string) {
    const req = new frontendpb.AddInviteDomainRequest({
      domain: new frontendpb.Domain({ name: domainName }),
    });
    rpc.callRetry('AddInviteDomainRequest', rpc.client.addInviteDomain, req).then(() => {
      window.location.reload();
    }).catch((error) => {
      logger.info(`AddInviteDomainRequest error: ${status.stringifyError(error)}`);
    });
  }

  const handleDeleteDomain = async (domain: frontendpb.DomainEntry) => {
    const req = new frontendpb.DeleteInviteDomainRequest({ domain });
    await rpc.callRetry('DeleteInviteDomain', rpc.client.deleteInviteDomain, req).then(() => {
      window.location.reload();
    }).catch((error) => {
      logger.info(`DeleteInviteDomain error: ${status.stringifyError(error)}`);
    });
  };

  function updateAnyDomainInviteAllowed(allowAnyDomainInvite: boolean) {
    const req = new frontendpb.AllowAnyInviteDomainRequest({ allowAnyDomainInvite });
    rpc.callRetry('AllowAnyInviteDomain', rpc.client.allowAnyInviteDomain, req).then(() => {
      setAnyDomainInviteAllowed(allowAnyDomainInvite);
    }).catch((error) => {
      logger.info(`AllowAnyInviteDomain error: ${status.stringifyError(error)}`);
    });
  }

  const queueDelete = (domain: frontendpb.DomainEntry) => {
    pushConfirmation(setConfirmStack, {
      destructive: true,
      onContinue: () => handleDeleteDomain(domain),
      title: 'Delete Domain',
      children: (
        <div className={classes.dialogContent}>
          Are you sure you want to delete the domain &quot;{domain.name?.name}&quot;
          from the allowed domain list?
        </div>
      ),
    });
  };

  const encryptionKeysRowData: RowConfig[] = accountInfo?.usingDefaultKmsKey ?
    defaultKeyRow :
    keys.map((key, index) => {
      const statusDisplayValue = statusString(key.status);
      return {
        id: String(index),
        values: {
          keyName: key.keyName,
          rotationSchedule: key.rotationPeriod > 0 ?
            `Every ${formatDuration(key.rotationPeriod)}` :
            'Manual',
          nextScheduledTime: key.nextRotationTime > 0 ?
            formatDate(key.nextRotationTime) :
            'Never',
          status: statusDisplayValue,
        },
        cellDisplay: {
          status: [
            { type: 'bullet', color: getStatusColor(statusDisplayValue) },
          ],
        },
      };
    });

  const allowedDomainsRowData: RowConfig[] = allowedDomains?.map((domain) => (
    {
      id: domain.id,
      values: { domainName: domain.name!.name },
      menuItems: [
        {
          label: 'Delete',
          onClick: () => queueDelete(domain),
          destructive: true,
        },
      ],
    })) ?? [];

  return (
    <MainPageLayout permission={accountInfo?.hasSecurityInfo} title="Security">
      <div className={classes.contentContainer}>
        <div>
          <div className={classes.tableHeadline}>
            <span>Encryption keys</span>
            {keys.length !== 0 && (
              <ActionButton
                kind="primary"
                onClick={() => setEditDialogOpened(true)}
                title="Edit key rotation schedule">
                Edit
              </ActionButton>
            )}
          </div>
          <Table
            asBlock
            columnConfigs={encryptionKeysColumnConfig}
            name="encryption-keys-table"
            rowConfigs={encryptionKeysRowData}
          />
        </div>
        <div>
          <div className={classes.tableHeadline}>
            <span>Allowed domains for user invites</span>

            <Tooltip
              title="Allow all domains">
              <div className={classes.domainToggle}>
                <LuminaryToggleSwitch
                  disabled={false}
                  onChange={(ev) => {
                    if (!anyDomainInviteAllowed) {
                      setAnyDomainDialogOpened(true);
                    } else {
                      updateAnyDomainInviteAllowed(ev);
                    }
                  }}
                  value={anyDomainInviteAllowed}
                />
                Allow all domains
              </div>
            </Tooltip>
            <ActionButton
              disabled={anyDomainInviteAllowed}
              kind="primary"
              onClick={() => setAddDomainDialogOpened(true)}
              title="Add an allowed domain for user invites">
              Add Domain
            </ActionButton>
          </div>
          <Table
            asBlock
            columnConfigs={allowedDomainsColumnConfig}
            disableColumnSettings
            name="allowed-domains-table"
            rowConfigs={allowedDomainsRowData}
          />
        </div>
      </div>
      <AddDomainDialog
        isOpen={addDomainDialogOpened}
        onClose={() => setAddDomainDialogOpened(false)}
        onSubmit={(domain) => {
          handleSubmitAddDomain(domain);
          setAddDomainDialogOpened(false);
          domain = '';
        }}
      />
      <AllowAnyDomainsPopup
        isOpen={anyDomainDialogOpened}
        onAllowAllDomains={() => {
          updateAnyDomainInviteAllowed(true);
          setAnyDomainDialogOpened(false);
        }}
        onClose={() => setAnyDomainDialogOpened(false)}
      />
      {keys.length ? (
        <EditKeyDialog
          isOpen={editDialogOpened}
          keyInfo={keys[0]}
          onClose={() => setEditDialogOpened(false)}
          onSubmit={(period, rotationEnabled, rotateImmediately) => {
            setEditDialogOpened(false);
            handleSubmitKeys(keys[0].keyName, period, rotationEnabled, rotateImmediately);
          }}
        />
      ) : null}
    </MainPageLayout>
  );
};

export default SecurityPageBody;
