// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const WandIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="wand"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M5.156,24
           C5.781,24 6.313,23.781 6.75,23.344
           L23.344,6.75
           C23.781,6.313 24,5.781 24,5.156
           C24,4.531 23.781,4 23.344,3.563
           L20.437,0.656
           C20,0.219 19.469,0 18.844,0
           C18.219,0 17.688,0.219 17.25,0.656
           L0.656,17.25
           C0.219,17.688 0,18.219 0,18.844
           C0,19.469 0.219,20 0.656,20.437
           L3.563,23.344
           C4,23.781 4.531,24 5.156,24Z
           M10.5,4.5
           L11.25,3
           L12.75,2.25
           L11.25,1.5
           L10.5,0
           L9.75,1.5
           L8.25,2.25
           L9.75,3
           L10.5,4.5Z
           M3.75,7.5
           L5.016,5.016
           L7.5,3.75
           L5.016,2.484
           L3.75,0
           L2.484,2.484
           L0,3.75
           L2.484,5.016
           L3.75,7.5Z
           M17.25,9.656
           L14.344,6.75
           L18.844,2.25
           L21.75,5.156
           L17.25,9.656Z
           M5.156,21.75
           L2.25,18.844
           L12.75,8.344
           L15.656,11.25
           L5.156,21.75Z
           M20.25,21
           L21.516,18.516
           L24,17.25
           L21.516,15.984
           L20.25,13.5
           L18.984,15.984
           L16.5,17.25
           L18.984,18.516
           L20.25,21Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
