// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useFilterStateValue } from '../../recoil/vis/filterState';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useProjectContext } from '../context/ProjectContext';

import { useSelectedFilterNode } from './useFilterNode';

/**
 * When we create a new vis filter, it can be a child of a different filter node, or of the root
 * visualizations node.
 * @returns The parent to which the new node will be added as a child. If the currently selected
 * node is a filter node, return it. Otherwise, return the root.
 */
export const useVisFilterParentNode = () => {
  // context
  const { projectId, workflowId, jobId } = useProjectContext();
  const { viewState } = useParaviewContext();

  // recoil
  const { filterNode } = useSelectedFilterNode();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisFilterState = useFilterStateValue({ projectId, workflowId, jobId });

  const parentNode = filterNode ?? (lcvisEnabled ? lcvisFilterState : viewState?.root);
  return parentNode;
};
