// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const GridQuadOutlinedIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="gridQuadOutlined"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0,0
           L0,10.667
           L10.667,10.667
           L10.667,0
           L0,0Z
           M8,8
           L2.667,8
           L2.667,2.667
           L8,2.667
           L8,8Z
           M0,13.333
           L0,24
           L10.667,24
           L10.667,13.333
           L0,13.333Z
           M8,21.333
           L2.667,21.333
           L2.667,16
           L8,16
           L8,21.333Z
           M13.333,0
           L13.333,10.667
           L24,10.667
           L24,0
           L13.333,0Z
           M21.333,8
           L16,8
           L16,2.667
           L21.333,2.667
           L21.333,8Z
           M13.333,13.333
           L13.333,24
           L24,24
           L24,13.333
           L13.333,13.333Z
           M21.333,21.333
           L16,21.333
           L16,16
           L21.333,16
           L21.333,21.333Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
