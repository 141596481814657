// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { LabelInputProps } from '../../lib/componentTypes/form';
import { useCommonMultiInputLines } from '../Theme/commonStyles';
import Tooltip from '../Tooltip';

import CheckBox from './CheckBox';

export const LabeledInput = (props: LabelInputProps) => {
  const multiInputClasses = useCommonMultiInputLines();

  return (
    <label
      className={multiInputClasses.line}
      onClick={props.onClick}
      role="presentation">
      <div className={multiInputClasses.control}>
        <CheckBox
          checked={props.checkbox.checked}
          disabled={props.checkbox.disabled}
          onChange={props.checkbox.onChange}
        />
      </div>
      <Tooltip title={props.labelTooltip.title}>
        <div>{props.labelTooltip.body}</div>
      </Tooltip>
      <Tooltip title={props.svgTooltip.title}>
        <span>
          {props.svgTooltip.icon}
        </span>
      </Tooltip>
    </label>
  );
};

export default LabeledInput;
