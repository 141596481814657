// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/*
 Displays the list of projects for a user. This is usually a landing page.
*/
import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import { colors } from '../../lib/designSystem';
import { projectLink } from '../../lib/navigation';
import * as rpc from '../../lib/rpc';
import useFormValidation from '../../lib/useFormValidation';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import { analytics } from '../../services/analytics';
import { useSampleProjectsValue } from '../../state/external/project/sampleProjects';
import { ActionLink } from '../Button/ActionLink';
import Form from '../Form';
import { TextInput } from '../Form/TextInput';
import ImageSelectionMenu from '../Menu/ImageSelection/ImageSelectionMenu';
import { createStyles, makeStyles } from '../Theme';
import { AutoCollapsingMessage } from '../visual/AutoCollapsingMessage';

import { Dialog } from './Base';

const useStyles = makeStyles(
  () => createStyles({
    instructions: {
      color: colors.lowEmphasisText,
    },
    selectionInfo: {
      background: colors.surfaceLight2,
      borderRadius: '4px',
      display: 'flex',
      gap: '10px',
      flexDirection: 'column',
      maxWidth: '357px',
      height: '170px',
      padding: '16px',
    },
    selectionTitle: {
      color: colors.highEmphasisText,
      fontSize: '18px',
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    selectionDesc: {
      color: colors.highEmphasisText,
      maxHeight: '64px',
      overflowY: 'auto',
    },
    bodySamples: {
      display: 'flex',
      gap: '48px',
    },
    leftSide: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    extraPadding: {
      padding: '16px',
    },
    inputs: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    input: {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
    },
  }),
  { name: 'ProjectDialog' },
);

const BLANK_PROJECT_DATA = {
  text: 'Blank Project',
  imgSrc: '',
  desc: 'Start from a blank template and build the simulation from the ground up.',
  docUrl: '',
};

interface ProjectDialogProps {
  // Whether the dialog is used to add or edit
  isEdit: boolean,
  // Whether the dialog is open.
  isOpen: boolean,
  // Current project description. Defined iff isEdit===true.
  description?: string
  // Current project name. Defined iff isEdit===true.
  name?: string
  // Cancels the dialog modifications.
  onCancel: () => void,
  // Callback used when the user hits on Apply (for editing) or Create.
  onSubmit: (
    projectId: string,
    name: string,
    description: string,
    isEdit: boolean,
  ) => Promise<void>,
  // Current project id. Defined iff isEdit===true.
  projectId?: string,
  // We can pass a sample index if we want to preselect a particular sample from the samples state
  preselectedSample?: number,
}

// Dialog which allows us to set or edit the name and description of a given
// project.
const ProjectDialog = (props: ProjectDialogProps) => {
  // == Props
  const { isOpen, isEdit, preselectedSample = -1 } = props;

  // == Hooks
  const classes = useStyles();
  const navigate = useNavigate();

  // == State
  const sampleProjects = useSampleProjectsValue();

  const [isComponentMounted, setComponentMounted] = useState(true);

  // == Data
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  // Used to avoid calling multiple onContinue in quick succession.
  const [submitting, setSubmitting] = useState(false);
  const [selected, setSelected] = useState<number>(0);

  const samplesEnabled = !isEdit;

  // Display the list of sample projects.
  const cellData = sampleProjects?.sampleProjects.map((sampleProject) => ({
    desc: sampleProject.projectDescription,
    text: sampleProject.projectName,
    imgSrc: sampleProject.imageUrl,
    docUrl: sampleProject.documentationUrl,
  })) || [];
  // Display a blank project first.
  cellData.unshift(BLANK_PROJECT_DATA);
  const selectedData = cellData[selected];

  const { validate, errors, clearErrors } = useFormValidation([{
    key: 'projectName',
    value: name,
    required: true,
  }]);

  const nameChangeTimeout = useRef<NodeJS.Timeout | null>(null);
  // Debounce the name change to avoid sending too many events to MixPanel
  const handleNameChange = (value: string) => {
    setName(value);

    if (nameChangeTimeout.current) {
      clearTimeout(nameChangeTimeout.current);
    }

    nameChangeTimeout.current = setTimeout(() => {
      analytics.track('Project Name Changed', { projectName: value });
    }, 500);
  };

  // Update the selected cell and the name in the dialog.
  const updateSelection = (index: number) => {
    setSelected(index);
    if (index === 0) {
      setName(props.name ?? '');
      setDescription(props.description ?? '');
      // If we are not editing and just opening a blank project, that will set the name to '',
      // which will trigger the validation and show an error for the name field. We don't want that
      // error for initial openings of the dialog so must clear it after all field updates are done.
      setTimeout(() => clearErrors(), 0);
    } else {
      setName(`${cellData[index].text} Sample`);
    }

    // Track sample selection
    analytics.track('Project Sample Selected', { sampleName: cellData[index].text });
  };

  useEffect(() => {
    if (isOpen) {
      if (preselectedSample > -1) {
        // Increase the section index by 1, because the project dialog shows the blank project
        // at index 0.
        updateSelection(preselectedSample + 1);
      } else {
        // If we don't have a preselection, use 0, which will select the blank project
        updateSelection(0);
      }

      // Track dialog open
      analytics.track('Project Dialog Opened', { isEdit });
    }
    // We must not include the `updateSelection` in the dependency array because we'll enter in a
    // loop between this useEffect and the `updateSelection`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, preselectedSample, clearErrors, isEdit]);

  useEffect(() => {
    setComponentMounted(true);

    // Return cleanup function to handle unmounting
    return () => {
      setComponentMounted(false);
    };
  }, []);

  return (
    <Dialog
      cancelButton={{ label: 'Cancel' }}
      continueButton={{
        label: isEdit ? 'Apply' : 'Create',
        name: 'save-project',
        disabled: !name,
      }}
      controlState={submitting ? 'working' : 'normal'}
      modal
      onClose={() => {
        props.onCancel();
        analytics.track('Project Dialog Cancelled', { isEdit });
      }}
      onContinue={async () => {
        if (validate() && !submitting) {
          setSubmitting(true);

          try {
            if (samplesEnabled && selected > 0 && sampleProjects) {
              // A sample is selected. Copy the sample.
              const sampleId = sampleProjects.sampleProjects[selected - 1].projectId;
              const req = new frontendpb.CopyProjectRequest({
                params: {
                  sourceId: sampleId,
                },
              });
              const reply = await rpc.callRetry('CopyProject', rpc.client.copyProject, req);

              const newProjectId = reply.createdProject?.id || '';
              // Update the copied project with the name/description the user has provided
              await props.onSubmit(
                newProjectId,
                name.trim(),
                description.trim(),
                true, // isEdit
              );

              // Only navigate if the component is still mounted
              if (isComponentMounted) {
                navigate(projectLink(newProjectId));
              }

              analytics.track('Project Created', {
                projectId: newProjectId,
                projectName: name.trim(),
                sampleUsed: cellData[selected].text,
              });
            } else {
              // Edit or create new blank project
              await props.onSubmit(props.projectId || '', name.trim(), description.trim(), isEdit);

              if (isEdit) {
                analytics.track('Project Edited', {
                  projectId: props.projectId,
                  projectName: name.trim(),
                });
              } else {
                analytics.track('Blank Project Created', {
                  projectName: name.trim(),
                });
              }
            }
          } catch (error: any) {
            if (isComponentMounted) {
              setSubmitting(false);
            }
            analytics.track('Project Creation Error', { error: error.message });
            throw error;
          }

          if (isComponentMounted) {
            setSubmitting(false);
          }
        }
      }}
      open={isOpen}
      subtitle={isEdit ? 'Edit your project information' : ''}
      title={isEdit ? 'Edit Project' : 'New Project'}
      width={samplesEnabled ? 'auto' : '600px'}>
      <div className={cx({ [classes.bodySamples]: samplesEnabled })}>
        {samplesEnabled && (
          <ImageSelectionMenu
            cellData={cellData}
            columns={3}
            disabled={submitting}
            rows={2}
            selected={selected}
            setSelected={updateSelection}
          />
        )}
        <div className={classes.leftSide}>
          {samplesEnabled && (
            <>
              <div className={cx(classes.selectionInfo, classes.extraPadding)}>
                <div className={classes.selectionTitle}>
                  {selectedData.text}
                </div>
                <div className={classes.selectionDesc}>
                  {selectedData.desc}
                </div>
                {selectedData.docUrl && (
                  <ActionLink
                    bold
                    externalIcon
                    href={selectedData.docUrl}
                    onClick={() => analytics.track('Tutorial Link Clicked', {
                      tutorialName: selectedData.text,
                    })}
                    variant="secondary">
                    View Tutorial
                  </ActionLink>
                )}
              </div>
            </>
          )}
          <div className={cx(classes.inputs, { [classes.extraPadding]: samplesEnabled })}>
            {samplesEnabled && (
              <div className={classes.instructions}>
                Enter basic project information below.
              </div>
            )}
            <div className={classes.input}>
              <Form.Label>Project Name</Form.Label>
              <TextInput
                autoFocus
                dataPrivate
                disabled={submitting}
                faultType={errors.projectName ? 'error' : undefined}
                name="name"
                onChange={handleNameChange}
                placeholder="Add a project name..."
                value={name}
              />
              <AutoCollapsingMessage level="error" message={errors.projectName} />
            </div>
            <div className={classes.input}>
              <Form.Label>Description</Form.Label>
              <TextInput
                dataPrivate
                disabled={submitting}
                multiline
                name="description"
                onChange={(value) => {
                  setDescription(value);
                  analytics.track('Project Description Changed', {
                    descriptionLength: value.length,
                  });
                }}
                placeholder="Add a project description..."
                rows={4}
                size="small"
                value={description}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ProjectDialog;
