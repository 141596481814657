// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';

import { tertiaryColors } from '../../../lib/color';
import { ColumnConfig, RowConfig } from '../../../lib/componentTypes/table';
import * as csv from '../../../lib/csv';
import { colors } from '../../../lib/designSystem';
import { isUnmodifiedSpaceKey } from '../../../lib/event';
import { formatPrefixed } from '../../../lib/number';
import { Logger } from '../../../lib/observability/logs';
import * as path from '../../../lib/path';
import { protoToJson } from '../../../lib/proto';
import { TableDefinition, isGenericTableType } from '../../../lib/rectilinearTable/model';
import {
  UNLIMITED_COL_SIZE,
  USER_DEFINED_HEADER,
  axisTableToSparkLineSeries,
  dataToTable,
  getTableColumnLabels,
  getTableDataSelectOptions,
  tableToColumnConfig,
  tableToRowConfig,
} from '../../../lib/rectilinearTable/util';
import * as rpc from '../../../lib/rpc';
import { wordsToList } from '../../../lib/text';
import { FileUploader } from '../../../lib/upload/fileUploader';
import { UploadResult, sha256Sum } from '../../../lib/upload/uploadUtils';
import * as basepb from '../../../proto/base/base_pb';
import * as frontendpb from '../../../proto/frontend/frontend_pb';
import {
  GetRectilinearTableRequest,
  ImportC81Reply,
  ImportC81Request,
} from '../../../proto/frontend/frontend_pb';
import { Metadata, RectilinearTable, TableType } from '../../../proto/table/table_pb';
import { ActionButton } from '../../Button/ActionButton';
import Form from '../../Form';
import { DataSelect } from '../../Form/DataSelect';
import { TextInput } from '../../Form/TextInput';
import Tooltip from '../../Tooltip';
import { SparkLine } from '../../charting/SparkLine';
import { Table } from '../../data/Table';
import { HEADING_COLUMN_OPTIONS } from '../../data/Table/util';
import { MessagePanel } from '../../notification/MessagePanel';
import { CloudUploadIcon } from '../../svg/CloudUploadIcon';
import { DiskInfoIcon } from '../../svg/DiskInfoIcon';
import { RingXIcon } from '../../svg/RingXIcon';
import Collapsible from '../../transition/Collapsible';
import { AutoCollapsingMessage } from '../../visual/AutoCollapsingMessage';
import { ProgressBar } from '../../visual/ProgressBar';
import { Dialog, ExtraButtonAction } from '../Base';
import './RectilinearTableDialog.scss';

const logger = new Logger('dialog/RectilinearTable');

// Some sensible file size limit to prevent bricking the browser when we read
// the file
const MAX_FILE_SIZE = 10_000_000;
const TRANSITION_PERIOD = 500;

export type TableErrorCheck = (table: RectilinearTable) => string;

function csvTemplates(tableDefinition: TableDefinition): csv.ColumnTemplate[] {
  const allLabels = [
    ...tableDefinition.axisLabels,
    ...tableDefinition.recordLabels,
  ];

  return allLabels.map((labelDefn) => {
    if (labelDefn.quantity) {
      return { format: 'number' };
    }
    return { format: 'string' };
  });
}

// Read a CSV file, expecting 4 numerical columns
function readFileContent(
  file: File,
  projectId: string,
  tableDefinition: TableDefinition,
  tableErrorFunc: TableErrorCheck,
  tableNameFunc: (originalName: string) => string,
  maxSize: number,
  onLoad: (table: RectilinearTable, tableName: string) => void,
  onError: (error: string) => void,
) {
  if (file && file.size > maxSize) {
    onError(`Files over ${formatPrefixed(maxSize, 'B')} are not supported`);
    return;
  }

  const reader = new FileReader();
  reader.onload = () => {
    try {
      const fileContent = reader.result as string;
      let template: csv.ColumnTemplate[] = [];
      if (!UNLIMITED_COL_SIZE.includes(tableDefinition.tableType)) {
        // if the number of possible columns is known, then create a template for it
        // otherwise, a template will be created as it is parsed
        template = csvTemplates(tableDefinition);
      }
      const rows = csv.parse(fileContent, template, {
        header: USER_DEFINED_HEADER.includes(tableDefinition.tableType),
        generic: isGenericTableType(tableDefinition.tableType),
        columnNames: tableDefinition.tableType === TableType.FAN_CURVE ?
          getTableColumnLabels(tableDefinition) : undefined,
      });
      const table = dataToTable(rows, tableDefinition);

      if (table) {
        const tableErr = tableErrorFunc(table);
        if (tableErr) {
          onError(tableErr);
        } else {
          const tableName = tableNameFunc(file.name);
          protoToJson(table, false).then(
            (tableJson: string) => {
              const data = new TextEncoder().encode(tableJson);
              const size = BigInt(data.length);
              const req = new frontendpb.UploadFileRequest({
                projectId,
                filename: `${tableName}.json`,
                data,
                size,
                startOffset: 0n,
              });
              return sha256Sum(data).then((checksum) => [req, checksum]);
            },
          ).then(([req, checksum]) => {
            (req as frontendpb.UploadFileRequest).checksum = checksum as basepb.Checksum;
            return rpc.callRetry(
              'writeFileUsingUploadFile',
              rpc.client.uploadFile,
              req as frontendpb.UploadFileRequest,
            );
          }).then((reply: frontendpb.UploadFileReply) => {
            table.metadata = new Metadata({
              url: reply.url,
              uploadedFilename: file.name,
              tableType: tableDefinition.tableType,
            });
          }).catch((err: Error) => {
            logger.error('protoToJson: ', err);
          }).finally(() => {
            onLoad(table, tableName);
          });
        }
      } else {
        onError('Unexpected error reading table');
      }
    } catch (error) {
      onError(`${error}`);
    }
  };
  reader.onerror = () => {
    onError('Error reading file');
  };
  reader.readAsText(file);
}

export interface RectilinearTableDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string, metadata: Metadata) => void;
  nameErrorFunc: (name: string) => string;
  title: string;
  subtitle?: string | JSX.Element;
  tableDefinition: TableDefinition;
  tableErrorFunc?: TableErrorCheck;
  uploadOptions?: {
    extensions?: string[];
    /**
     * Allowed file extensions for this upload input in the format of the 'accept' HTML attribute
     */
    inputAccept?: string;
  },
  /**
   * Optional tooltip text to explain the format of the uploaded file, defaults to the table
   * definition labels
   */
  uploadHelp?: ReactElement,
  /** When true, disables the tooltip help text and its icon */
  disableHelp?: boolean;
  /** When true, displays the uploaded table as a grid rather than a set of graphs */
  gridPreview?: boolean;
  /** If true, stylize the first column of the grid preview as a header column */
  gridHeaderColumn?: boolean;
  projectId: string;
}

export interface FileState {
  // Tracks base name of user-upload file path
  fileName: string;
  // Tracks error associated with user-selected file
  fileError: string;
}

interface Validation {
  // Tracks form validity
  isValid: boolean;
  // Tracks error associated with user-entered table name
  nameError: string;
}

export const RectilinearTableDialog = (props: RectilinearTableDialogProps) => {
  const {
    nameErrorFunc,
    onClose,
    onSubmit,
    open,
    title,
    subtitle,
    tableDefinition,
    uploadOptions,
    uploadHelp,
    disableHelp,
    gridPreview = false,
    gridHeaderColumn,
    projectId,
  } = props;

  const tableErrorFunc = props.tableErrorFunc || (() => '');
  const fileInput = useRef<HTMLInputElement>(null);

  const expectedFileType = uploadOptions?.extensions || ['CSV'];

  // Dialog shows two views: one for uploading file and one for showing file
  // spark lines.  This state is used to determine which view to show.
  const [needUpload, setNeedUpload] = useState(true);
  // Tracks when the selected file is being read
  const [isWorking, setIsWorking] = useState(false);
  // Tracks whether a user is dragging a file over the dialog
  const [isDragging, setIsDragging] = useState(false);
  // Tracks table proto built from uploaded file
  const [tableProto, setTableProto] = useState<RectilinearTable | null>(null);
  // Tracks table name entered by user
  const [tableName, setTableName] = useState<string>('');
  const [fileState, setFileState] = useState<FileState>({
    fileError: '',
    fileName: '',
  });
  const [validation, setValidation] = useState<Validation>({
    isValid: false,
    nameError: '',
  });
  const [axisIndex, setAxisIndex] = useState(0);

  useEffect(() => {
    setAxisIndex(0);
  }, [tableProto]);

  useEffect(() => {
    if (open) {
      // Initialize state when the dialog is opened
      setNeedUpload(true);
      setIsWorking(false);
      setIsDragging(false);
      setTableProto(null);
      setTableName('');
      setFileState({
        fileError: '',
        fileName: '',
      });
    }
  }, [open]);

  useEffect(() => {
    // Validate form
    let isValid = true;
    let nameError = '';

    const trimmedTableName = tableName.trim();

    if (trimmedTableName) {
      nameError = nameErrorFunc(trimmedTableName);
      if (nameError) {
        isValid = false;
      }
    } else {
      isValid = false;
    }

    if (needUpload || !tableProto) {
      isValid = false;
    }
    setValidation({
      nameError,
      isValid,
    });
  }, [needUpload, tableName, tableProto, nameErrorFunc]);

  const closeDialog = () => {
    if (!isWorking) {
      onClose();
    }
  };

  const handleSubmit = () => {
    const trimmedTableName = tableName.trim();

    const metadata = tableProto?.metadata;
    if (trimmedTableName && metadata && !nameErrorFunc(trimmedTableName)) {
      onSubmit(trimmedTableName, metadata);
    }
  };

  const handleNameChange = (newValue: string) => {
    setTableName(newValue);
  };

  // Generate a unique name from the file name, adding sequential numbers if
  // necessary
  const generateTableName = useCallback((fileName: string) => {
    const { name } = path.parse(fileName);

    if (!nameErrorFunc(name)) {
      return name;
    }

    const ok = false;
    let ct = 1;

    let result = '';
    while (!ok) {
      const newName = `${name}-${ct}`;
      if (!nameErrorFunc(newName)) {
        result = newName;
        break;
      }
      ct += 1;
    }

    return result;
  }, [nameErrorFunc]);

  // Attempt to read file input whenever its value changes
  const readFile = (file: File) => {
    setIsWorking(true);
    setFileState({
      fileError: '',
      fileName: file.name,
    });

    readFileContent(
      file,
      projectId,
      tableDefinition,
      tableErrorFunc,
      generateTableName,
      MAX_FILE_SIZE,
      (table: RectilinearTable, tableFileName: string) => {
        setTableName(tableFileName);
        setTableProto(table);
        setIsWorking(false);
        setNeedUpload(false);
      },
      (fileError: string) => {
        setIsWorking(false);
        setFileState({
          fileName: file.name,
          fileError,
        });
      },
    );
  };

  const startFileUpload = (file: File, extensions: string[]) => {
    const fileExtension = path.extname(file.name).toLowerCase();
    if (!extensions.map((ext): string => `.${ext.toLowerCase()}`).includes(fileExtension)) {
      setTableProto(null);
      setFileState({
        fileError: `File extension is not ${wordsToList(extensions, { conjunction: 'or' })}`,
        fileName: file.name,
      });
      return;
    }

    setIsWorking(true);

    const fileUploader = new FileUploader();
    fileUploader.uploadFile(
      projectId,
      file,
      1, // this scaling value should be ignored for non-mesh files
      false, // this variable should be ignored for non-mesh files.
      () => { }, // ignore progress for now
    ).then((fileResult: UploadResult) => {
      const req = new ImportC81Request({ projectId, url: fileResult.url });
      return rpc.callRetry('ImportC81', rpc.client.importC81, req);
    }).then((importC81Reply: ImportC81Reply) => {
      const url = importC81Reply.url;
      const req = new GetRectilinearTableRequest({ projectId, url });
      return Promise.all([
        Promise.resolve(url),
        rpc.callRetry('GetRectilinearTableC81', rpc.client.getRectilinearTable, req),
      ]);
    }).then(
      ([url, c81TableReply]) => {
        const { table } = c81TableReply;
        if (table) {
          table.metadata = new Metadata({
            url,
            uploadedFilename: file.name,
            tableType: TableType.AIRFOIL_PERFORMANCE,
          });
          setTableName(generateTableName(file.name));
          setTableProto(table);
          setNeedUpload(false);
          setFileState({
            fileError: '',
            fileName: file.name,
          });
        } else {
          setTableName('');
          setTableProto(null);
          setNeedUpload(true);
          setFileState({
            fileError: 'Unable to process file',
            fileName: file.name,
          });
        }
      },
    )
      .catch(
        (error: any) => {
          setFileState({
            fileError: `${error}`,
            fileName: file.name,
          });
        },
      )
      .finally(
        () => setIsWorking(false),
      );
  };

  const processFile = (file: File) => {
    if (uploadOptions?.extensions?.includes('C81')) {
      // if this uploader is for a c81 file, use the ImportC81 request
      startFileUpload(
        file,
        uploadOptions.extensions,
      );
    } else {
      readFile(file);
    }
  };

  const handleFileChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    if (target.files?.length) {
      processFile(target.files[0]);
    }
  };

  const handleFileDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const { items } = event.dataTransfer;

    let fileError = (
      `Please drag a single ${wordsToList(expectedFileType, { conjunction: 'or' })} file`
    );
    if (items.length === 1) {
      const entry = items[0].webkitGetAsEntry() as FileSystemEntry;
      if (entry.isFile) {
        (entry as FileSystemFileEntry).file((file: File) => {
          processFile(file);
        });
        fileError = '';
      }
    }

    setIsDragging(false);
    setFileState({
      ...fileState,
      fileError,
    });
  };

  const gridPreviewOpen = !isWorking && !needUpload && gridPreview;

  const columns: ColumnConfig[] = useMemo(() => {
    if (tableProto && gridPreview) {
      const configs = tableToColumnConfig(tableProto, { disableVisibility: true } as ColumnConfig);
      if (gridHeaderColumn) {
        configs[0].displayOptions = {
          ...configs[0].displayOptions,
          ...HEADING_COLUMN_OPTIONS,
        };
      }
      return configs;
    }
    return [];
  }, [gridHeaderColumn, gridPreview, tableProto]);

  const rows: RowConfig[] = useMemo(() => {
    if (tableProto && gridPreview) {
      return tableToRowConfig(tableProto);
    }
    return [];
  }, [tableProto, gridPreview]);

  const tableHasEmptyValues = useMemo(() => rows.some(
    (row) => Object.keys(row.values).some((key) => row.values[key] === undefined),
  ), [rows]);

  const reuploadButton: ExtraButtonAction | undefined = gridPreviewOpen ?
    {
      key: 'grid-preview-upload-file',
      label: 'Upload New',
      onClick: () => setNeedUpload(true),
      icon: { name: 'diskArrowUp' },
    } : undefined;

  // Generate axis drop-down options
  const axisOptions = getTableDataSelectOptions(tableProto, axisIndex);
  const allLabels = getTableColumnLabels(tableDefinition);

  const tableHelp = uploadHelp ?? (
    <>
      <div>Each CSV file row should have the following entries:</div>
      <ul style={{ margin: 0, marginTop: '0.5em' }}>
        {allLabels.map((label) => (
          <li key={label}>{label}</li>
        ))}
      </ul>
    </>
  );

  const tableHelpC81 = (
    <>
      <div>
        This file should contain lift, drag and pitching moment
        coefficients for a range of Mach numbers and angles of attack.
      </div>
    </>
  );

  return (
    <Dialog
      cancelButton={{
        label: 'Cancel',
        disabled: isWorking,
        name: 'aeroFileCancelButton',
      }}
      continueButton={{
        label: 'Save',
        disabled: isWorking || !validation.isValid,
        name: 'aeroFileSubmitButton',
      }}
      modal
      onClose={closeDialog}
      onContinue={handleSubmit}
      open={open}
      subtitle={(
        <div className="tableDialogSubtitle">
          <div>{subtitle}</div>
          {!disableHelp && (
            <Tooltip title={expectedFileType.includes('C81') ? tableHelpC81 : tableHelp}>
              <div>
                <DiskInfoIcon color={colors.primaryCta} maxHeight={12} maxWidth={12} />
              </div>
            </Tooltip>
          )}
        </div>
      )}
      tertiaryButtons={reuploadButton && [reuploadButton]}
      title={title}
      width="800px">
      <div className="tableDialogContent">
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <TextInput
            disabled={isWorking}
            faultType={validation.nameError ? 'error' : undefined}
            onChange={handleNameChange}
            value={tableName}
          />
          <AutoCollapsingMessage level="error" message={validation.nameError} />
        </Form.Group>
        {tableHasEmptyValues && tableDefinition.tableType === TableType.CUSTOM_SAMPLE_DOE && (
          <Form.Group>
            <Collapsible collapsed={!tableHasEmptyValues}>
              <MessagePanel
                level="info"
                message="To ensure accuracy, we will pre-populate these fields with the
                  corresponding data from the simulation setup as soon as columns are assigned to
                  input parameters. Or you can edit and re-upload your CSV."
                title="Some fields are missing data from your CSV."
                variant="sectionMessage"
              />
            </Collapsible>
          </Form.Group>
        )}
        <Form.Group className="fileSelection">
          <Form.Label>File</Form.Label>
          <div className="collapsibleScroll">
            <Collapsible collapsed={!isWorking}>
              <ProgressBar height={6} progress={null} />
            </Collapsible>
            <Collapsible
              collapsed={isWorking || !needUpload}
              transitionPeriod={TRANSITION_PERIOD}>
              <div
                className={cx('formSection', 'primary', { dragging: isDragging })}
                onDragEnter={() => {
                  setFileState({
                    ...fileState,
                    fileError: '',
                  });
                  setIsDragging(true);
                }}
                onDragLeave={() => setIsDragging(false)}
                onDragOver={(event) => {
                  event.preventDefault();
                  setIsDragging(true);
                }}
                onDrop={handleFileDrop}>
                <div className="uploadScreen">
                  <div className="icon">
                    <CloudUploadIcon maxHeight={56} maxWidth={56} />
                  </div>
                  <div className="guide">
                    Drag & drop one {
                      wordsToList(expectedFileType, { conjunction: 'or' })
                    } file or click the button below.
                  </div>
                  <div className="status">
                    <AutoCollapsingMessage centered level="error" message={fileState.fileError} />
                  </div>
                  <div className="button">
                    <ActionButton
                      disabled={isDragging}
                      kind="primary"
                      onClick={() => {
                        if (fileInput.current) {
                          // Reset the file value so that the change event will
                          // fire if the user attempts to upload the same file
                          // multiple times.
                          fileInput.current.value = '';
                          fileInput.current.click();
                          setFileState({ fileError: '', fileName: '' });
                        }
                      }}>
                      Browse Files
                    </ActionButton>
                  </div>
                </div>
              </div>
            </Collapsible>
            <Collapsible
              collapsed={isWorking || needUpload || gridPreview}
              transitionPeriod={TRANSITION_PERIOD}>
              <div className={cx('formSection', 'secondary')}>
                <div className="file">
                  <div className="filename">{fileState.fileName}</div>
                  <div
                    className="control"
                    onClick={() => setNeedUpload(true)}
                    onKeyUp={(event) => {
                      if (isUnmodifiedSpaceKey(event)) {
                        setNeedUpload(true);
                      }
                    }}
                    role="button"
                    tabIndex={0}>
                    <RingXIcon maxHeight={12} maxWidth={12} />
                  </div>
                </div>
                <div className="preview">
                  {tableProto && (
                    <div className="records">
                      {axisTableToSparkLineSeries(tableProto, axisIndex).map((series, i) => (
                        <div className="record" key={series.yLabel}>
                          <div className="label">{series.yLabel}</div>
                          <div className="chart">
                            <SparkLine
                              color={tertiaryColors[i % tertiaryColors.length]}
                              height={100}
                              options={{ format: { maximumFractionDigits: 6 } }}
                              series={series}
                              strokeWidth={3}
                              width={130}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {tableProto && (axisOptions.length > 1) && (
                    <div className="axisSelect">
                      <div className="label">Axis:</div>
                      <DataSelect
                        onChange={setAxisIndex}
                        options={axisOptions}
                        placeholderText="Select axis"
                        size="small"
                      />
                    </div>
                  )}
                </div>
              </div>
            </Collapsible>
            <Collapsible allowOverflow={gridPreviewOpen} collapsed={!gridPreviewOpen}>
              <Table
                asBlock
                columnConfigs={columns}
                density="comfortable"
                disableColumnSettings
                disableSortHighlighting
                disableSorting
                name="upload-table-grid-preview"
                rowConfigs={rows}
                variant="grid"
              />
            </Collapsible>
          </div>
          <input
            accept={uploadOptions?.inputAccept}
            data-name="aeroFileInput"
            disabled={isWorking}
            name="fileInput"
            onChange={handleFileChange}
            ref={fileInput}
            style={{ display: 'none' }}
            type="file"
          />
        </Form.Group>
      </div>
    </Dialog>
  );
};
