// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/cad/transformation.proto (package luminary.proto.cad, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Matrix3, Vector3 } from "../base/base_pb.js";

/**
 * Augmented transformation matrix such that `x' = xA + t`.
 *
 * @generated from message luminary.proto.cad.AugmentedMatrix
 */
export const AugmentedMatrix = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.AugmentedMatrix",
  () => [
    { no: 1, name: "affine", kind: "message", T: Matrix3 },
    { no: 2, name: "translation", kind: "message", T: Vector3 },
  ],
);

/**
 * Vector defined by direction and magnitude.
 *
 * @generated from message luminary.proto.cad.MagnitudeVector3
 */
export const MagnitudeVector3 = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.MagnitudeVector3",
  () => [
    { no: 1, name: "direction", kind: "message", T: Vector3 },
    { no: 2, name: "magnitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Translation along a vector.
 *
 * @generated from message luminary.proto.cad.Translation
 */
export const Translation = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.Translation",
  () => [
    { no: 1, name: "vector", kind: "message", T: Vector3, oneof: "displacement" },
    { no: 2, name: "magnitude_vector", kind: "message", T: MagnitudeVector3, oneof: "displacement" },
  ],
);

/**
 * A vector with an origin, which can describe an axis or a plane in 3D.
 *
 * @generated from message luminary.proto.cad.AnchoredVector3
 */
export const AnchoredVector3 = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.AnchoredVector3",
  () => [
    { no: 1, name: "origin", kind: "message", T: Vector3 },
    { no: 2, name: "direction", kind: "message", T: Vector3 },
  ],
);

/**
 * Rotation around an axis.
 *
 * @generated from message luminary.proto.cad.Rotation
 */
export const Rotation = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.Rotation",
  () => [
    { no: 1, name: "angle", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "arbitrary", kind: "message", T: AnchoredVector3, oneof: "axis" },
  ],
);

/**
 * Isotropic scaling.
 *
 * @generated from message luminary.proto.cad.Scaling
 */
export const Scaling = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.Scaling",
  () => [
    { no: 1, name: "arbitrary", kind: "message", T: Vector3, oneof: "origin" },
    { no: 2, name: "isotropic", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "factor" },
  ],
);

/**
 * Reflection across a plane
 *
 * @generated from message luminary.proto.cad.Reflection
 */
export const Reflection = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.cad.Reflection",
  () => [
    { no: 1, name: "arbitrary", kind: "message", T: AnchoredVector3, oneof: "plane" },
  ],
);

