// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import { ColumnState, RawValue, RowConfig } from '../../../lib/componentTypes/table';

function compareNumberValues(a: number, b: number) {
  if (Number.isNaN(a) || Number.isNaN(b)) {
    if (Number.isNaN(a) && Number.isNaN(b)) {
      return 0;
    }
    return Number.isNaN(a) ? -1 : 1;
  }

  return a - b;
}

function compareBooleanValues(a: boolean, b: boolean) {
  if (a === b) {
    return 0;
  }
  return a ? -1 : 1;
}

function compareStringValues(a: string, b: string) {
  return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
}

function parseString(val: RawValue) {
  if (val === null || val === undefined) {
    return '';
  }
  return `${val}`;
}

export function sortRows(
  rowData: RowConfig[],
  columnState: ColumnState,
  descending = false,
): RowConfig[] {
  const rows = rowData.slice();

  rows.sort((a, b) => {
    const valA = a.values[columnState.config.id];
    const valB = b.values[columnState.config.id];
    switch (columnState.config.type) {
      case 'number':
        return compareNumberValues(Number(valA), Number(valB));
      case 'boolean':
        return compareBooleanValues(!!valA, !!valB);
      default:
        return compareStringValues(parseString(valA), parseString(valB));
    }
  });

  if (descending) {
    rows.reverse();
  }

  return rows;
}
