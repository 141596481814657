// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties } from 'react';

import { colors } from '../../../lib/designSystem';

import './Propeller.scss';

const DEFAULT_COLOR = colors.neutral900;
const DEFAULT_SIZE = 200;

export interface PropellerProps {
  color?: string;
  size?: number;
}

export const Propeller = (props: PropellerProps) => {
  const { color = DEFAULT_COLOR, size = DEFAULT_SIZE } = props;

  return (
    <div
      className="propellerSpinner"
      style={{
        '--color': color,
        '--size': `${size}px`,
      } as CSSProperties}>
      <svg
        height={`${size}px`}
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          strokeLinejoin: 'round',
          strokeMiterlimit: 2.0,
        }}
        viewBox="0 0 100 100"
        width={`${size}px`}>
        <g fill={color}>
          <path
            d={`M46,45C42.789,43.024 36.368,39.073 33.239,33.586C28.14,24.642 30.647,15.21
                33.865,10.261C38.429,3.244 43.847,1.575 50,0.867C56.03,0.172 63.46,1.081
                67.453,3.387C71.22,5.563 75.86,10.79 73.958,14.703C69.808,23.238 61.347,24.915
                56.989,31.118C54.447,34.737 54.149,40.943 54,44.046`}
          />
          <path
            d={`M47.67,55.964C47.564,59.733 47.354,67.269 44.165,72.722C38.969,81.611
                29.548,84.155 23.653,83.842C15.294,83.399 11.139,79.541
                7.449,74.567C3.833,69.692 0.905,62.803 0.906,58.192C0.906,53.841 3.113,47.209
                7.453,46.9C16.92,46.227 22.602,52.716 30.153,53.388C34.559,53.78 40.082,50.935
                42.844,49.513`}
          />
          <path
            d={`M56.33,49.036C59.646,47.243 66.279,43.657 72.595,43.692C82.891,43.748
                89.805,50.635 92.482,55.896C96.277,63.357 95.014,68.884
                92.551,74.567C90.137,80.136 85.635,86.116 81.642,88.421C77.873,90.596
                71.027,92.001 68.589,88.396C63.273,80.535 66.051,72.369
                62.858,65.494C60.994,61.482 55.769,58.122 53.156,56.441`}
          />
          <path
            d={`M50,42C54.415,42 58,45.585 58,50C58,54.415 54.415,58 50,58C45.585,58 42,54.415
                42,50C42,45.585 45.585,42 50,42ZM50,46C52.208,46 54,47.792 54,50C54,52.208
                52.208,54 50,54C47.792,54 46,52.208 46,50C46,47.792 47.792,46 50,46Z`}
          />
        </g>
      </svg>
    </div>
  );
};
