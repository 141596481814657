// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as projectstatepb from '../../proto/projectstate/projectstate_pb';

export const DEFAULT_FRONTEND_MENU_STATE: projectstatepb.FrontendMenuState = (
  (): projectstatepb.FrontendMenuState => {
    const initState = new projectstatepb.InitializationState();
    return new projectstatepb.FrontendMenuState({
      initState,
      materialPreset: projectstatepb.MaterialPreset.CUSTOM,
      // Apply the default presets when creating a new frontendMenuState.
      // NOTE: the applied presets must be in sync with those in defaults in
      // config.ts.
      controlsPreset: projectstatepb.ControlsPreset.DEFAULT_CONTROLS,
      discretizationPreset: projectstatepb.DiscretizationPreset.DEFAULT_DISCRETIZATION,
      meshScaling: 1,
    });
  }
)();
