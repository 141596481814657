// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { Getter, SetStateAction, WritableAtom } from 'jotai';

export function getValueFromSetStateAction<T>(
  get: Getter,
  arg: SetStateAction<T>,
  sourceAtom: WritableAtom<T, [arg: SetStateAction<T>], void>,
) {
  const value = typeof arg === 'function' ? (arg as (prev: T) => T)(get(sourceAtom)) : arg;
  return value;
}
