// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as simulationpb from '../../proto/client/simulation_pb';
import * as workflowpb from '../../proto/workflow/workflow_pb';
import { meshLink, outputsLink, physicsLink, solverLink } from '../navigation';

export enum CurrentView {
  SETUP = 0,
  ANALYSIS = 1,
  RESULTS = 2,
  GEOMETRY = 3,
  PHYSICS = 4,
  MESH = 5,
  OUTPUTS = 6,
  SOLVER = 7,
}

export const INTERMEDIATE_VIEWS = [
  CurrentView.PHYSICS,
  CurrentView.MESH,
  CurrentView.OUTPUTS,
  CurrentView.SOLVER,
];

export const isIntermediateView = (view: CurrentView): boolean => INTERMEDIATE_VIEWS.includes(view);

export const getNextPath = (
  projectId: string,
  view: CurrentView,
  isIgeoProject: boolean,
): string => {
  switch (view) {
    case CurrentView.GEOMETRY:
      return physicsLink(projectId);
    case CurrentView.PHYSICS:
      return isIgeoProject ? meshLink(projectId) : outputsLink(projectId);
    case CurrentView.MESH:
      return outputsLink(projectId);
    case CurrentView.OUTPUTS:
      return solverLink(projectId);
    default:
      return '';
  }
};

export const getPathFromView = (view: CurrentView, projectId: string): string => {
  switch (view) {
    case CurrentView.PHYSICS:
      return physicsLink(projectId);
    case CurrentView.MESH:
      return meshLink(projectId);
    case CurrentView.OUTPUTS:
      return outputsLink(projectId);
    case CurrentView.SOLVER:
      return solverLink(projectId);
    default:
      return '';
  }
};

export const getViewFromPath = (path: string): CurrentView => {
  if (path.includes('physics')) {
    return CurrentView.PHYSICS;
  }
  if (path.includes('mesh')) {
    return CurrentView.MESH;
  }
  if (path.includes('outputs')) {
    return CurrentView.OUTPUTS;
  }
  if (path.includes('solver')) {
    return CurrentView.SOLVER;
  }
  return CurrentView.SETUP;
};

export type ProjectContextType = {
  projectId: string;
  workflowId: string;
  jobId: string;
  readOnly: boolean;
  geometryId: string;
  // Called to update the simulation parameters.
  onParamUpdate: (newParam: simulationpb.SimulationParam) => void;
  asyncOnParamUpdate: (newParam: simulationpb.SimulationParam) => Promise<void>;
  // Called to update the workflow config.
  onNewWorkflowConfig: (newConfig: workflowpb.Config) => void;
  copyToSetup: () => Promise<void>;
};
