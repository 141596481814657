// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const ClockResetIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="clockReset"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M12,0
           C15.6,0 18.81,1.605 21,4.11
           L21,1.5
           C21,0.675 21.675,0 22.5,0
           C23.325,0 24,0.675 24,1.5
           L24,7.5
           C24,8.325 23.325,9 22.5,9
           L16.5,9
           C15.675,9 15,8.325 15,7.5
           C15,6.675 15.675,6 16.5,6
           L18.69,6
           C17.04,4.17 14.67,3 12,3
           C7.035,3 3,7.035 3,12
           C3,15.915 5.505,19.215 9,20.445
           C9.945,20.775 10.935,21 12,21
           C16.965,21 21,16.965 21,12
           C21,11.175 21.675,10.5 22.5,10.5
           C23.325,10.5 24,11.175 24,12
           C24,18.63 18.63,24 12,24
           C11.49,24 10.995,23.955 10.5,23.895
           C10.47,23.895 10.44,23.88 10.41,23.88
           C4.53,23.1 0,18.09 0,12
           C0,5.37 5.37,0 12,0Z
           M12.5,14
           C13.328,14 14,13.328 14,12.5
           L14,6.5
           C14,5.672 13.328,5 12.5,5
           C11.672,5 11,5.672 11,6.5
           L11,11
           L6.5,11
           C5.672,11 5,11.672 5,12.5
           C5,13.328 5.672,14 6.5,14
           L12.5,14Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
