// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/entitygroup/entitygroup.proto (package luminary.proto.entitygroup, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum luminary.proto.entitygroup.EntityType
 */
export const EntityType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.entitygroup.EntityType",
  [
    {no: 0, name: "INVALID_ENTITY_TYPE"},
    {no: 1, name: "SURFACE"},
    {no: 2, name: "PARTICLE_GROUP"},
    {no: 3, name: "MONITOR_PLANE"},
    {no: 4, name: "PROBE_POINTS"},
    {no: 5, name: "MIXED"},
    {no: 6, name: "VOLUME"},
    {no: 7, name: "BODY_TAG"},
    {no: 8, name: "FACE_TAG"},
    {no: 9, name: "TAG_CONTAINER"},
  ],
);

/**
 * A nested group of entities (e.g. surfaces, monitor points etc).
 *
 * @generated from message luminary.proto.entitygroup.EntityGroup
 */
export const EntityGroup = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.entitygroup.EntityGroup",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "children", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(EntityType) },
    { no: 6, name: "index", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message luminary.proto.entitygroup.EntityGroups
 */
export const EntityGroups = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.entitygroup.EntityGroups",
  () => [
    { no: 1, name: "groups", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: EntityGroup} },
    { no: 2, name: "group_prefix_removed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "upgraded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

