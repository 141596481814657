// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 64;
const baseHeight = 64;

const baseColors: string[] = ['#ffffff'];

const LetterShielded = (props: SvgProps) => {
  const { color = baseColors, ...scaling } = props;

  const {
    finalHeight,
    finalWidth,
    getColor,
  } = useSvg(baseWidth, baseHeight, { ...scaling, color });

  return (
    <svg
      fill="none"
      height={finalHeight}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.1758 55.7204C23.2044 54.5261 17.8313 51.2999 13.9706 46.5905C10.1098 41.8812 7.99999
         35.9797 8 29.8901V21.3333C8 17.7971 9.40476 14.4057 11.9052 11.9052C14.4057 9.40476 17.7971
         8 21.3333 8H42.6667C46.2029 8 49.5943 9.40476 52.0948 11.9052C54.5952 14.4057 56 17.7971 56
         21.3333V29.89C56 35.9795 53.8902 41.8809 50.0296 46.5902C46.169 51.2995 40.796 54.5258
         34.8248 55.7202C32.9605 56.0931 31.0401 56.0932 29.1758 55.7204Z"
        opacity="0.5"
        stroke={getColor(0)}
        strokeWidth="2"
      />
      <path
        d="M40.0003 18.666H24.0003C21.0548 18.666 18.667 21.0538 18.667 23.9993V34.666C18.667
         37.6115 21.0548 39.9993 24.0003 39.9993H40.0003C42.9458 39.9993 45.3337 37.6115 45.3337
         34.666V23.9993C45.3337 21.0538 42.9458 18.666 40.0003 18.666Z"
        fill={getColor(0)}
        fillOpacity="0.2"
        stroke={getColor(0)}
        strokeWidth="2"
      />
      <path
        d="M44 22.666L34.5224 28.9247C32.8848 29.9993 31.1152 29.9993 29.4776 28.9247L20 22.666"
        stroke={getColor(0)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default LetterShielded;
