// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import NodeLink from '../treePanel/NodeLink';

interface BaseLink {
  label: string;
}

interface ClickLink extends BaseLink {
  onClick: () => void;
  className?: string;
}

interface HrefLink extends BaseLink {
  href: string;
  external?: boolean;
}

interface TreeLink extends BaseLink {
  nodeIds: string[];
}

export type Link = ClickLink | HrefLink | TreeLink;

export const PanelLink = (props: { link: Link }) => {
  const { link } = props;

  const clickLink = link as ClickLink;
  const hrefLink = link as HrefLink;
  const treeLink = link as TreeLink;

  if (clickLink.onClick) {
    return (
      <button
        className={cx('link', clickLink.className)}
        onClick={clickLink.onClick}
        type="button">
        {clickLink.label}
      </button>
    );
  }

  if (hrefLink.href) {
    return (
      <a
        className="link"
        href={hrefLink.href}
        rel="noopener noreferrer"
        target={hrefLink.external ? '_blank' : ''}>
        {hrefLink.label}
      </a>
    );
  }

  if (treeLink.nodeIds) {
    return (
      <div className="link">
        <NodeLink asBlock nodeIds={treeLink.nodeIds} text={treeLink.label} unstyled />
      </div>
    );
  }

  return null;
};

export const PanelLinks = (props: { links: Link[] }) => {
  const { links } = props;

  return (
    <>
      {links.map((link, i) => (
        <React.Fragment key={link.label}>
          {i ? <span className="linkSeparator">·</span> : ''}
          <PanelLink link={link} />
        </React.Fragment>
      ))}
    </>
  );
};
