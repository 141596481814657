// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as simulationpb from '../../proto/client/simulation_pb';
import { newAdFloat } from '../adUtils';
import newInt from '../intUtils';

// The default parameter values for the boundary layer.
const BL_NUM_LAYERS = 40;
const BL_INITIAL_SIZE = 0.000001;
const BL_GROWTH_RATE = 1.2;

export const DEFAULT_ADAPTATION_BL = new simulationpb.BoundaryLayerProfile({
  initialSize: newAdFloat(BL_INITIAL_SIZE),
  growthRate: newAdFloat(BL_GROWTH_RATE),
  nLayers: newInt(BL_NUM_LAYERS),
  surfaces: [],
});
