// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import { BaseEditor, Descendant, Range } from 'slate';
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate } from 'slate-react';

import { FaultType } from '../../lib/componentTypes/form';
import { CommonMenuPositionTransform } from '../../lib/componentTypes/menu';
import { AutocompleteEditor, EMPTY_EDITOR, SuggestionType } from '../../lib/expressionInput/AutocompleteEditor';
import { Type } from '../../lib/expressionInput/slate';
import { getCurrentLineText } from '../../lib/expressionInput/util';
import './ExpressionInput.scss';

import { AutocompleteSuggestionsMenu } from './AutocompleteSuggestionsMenu';
import { Function } from './FunctionElement';
import { Preview } from './PreviewElement';
import { Variable } from './VariableElement';

export interface ExpressionInputProps {
  /** The Slate editor associated to this input */
  editor: BaseEditor & ReactEditor;
  /**
   * The initial state of the editor
   *
   * Note: Once this is set, the editor's initial value can never change. To change the editor's
   * value, you must replace the editor's entire Node state, LC-18844
   */
  initialValue?: Descendant[];
  /** The list of available autocomplete variables */
  options?: string[];
  /** The list of available autocomplete functions */
  functions?: string[];
  /** The placeholder text */
  placeholder?: string;
  /** The minimum height of the input */
  minHeight?: number;
  /** Whether the input is read-only */
  readOnly?: boolean;
  /** The fault type to add a different border color */
  faultType?: FaultType;
}

export function ExpressionInput(props: ExpressionInputProps) {
  const {
    editor, initialValue, options = [], functions = [], placeholder, minHeight, readOnly, faultType,
  } = props;

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [target, setTarget] = useState<Range | undefined>(undefined);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [suggestionPosition, setSuggestionPosition] = useState<CommonMenuPositionTransform>({
    top: 0,
    left: 0,
  });

  /** The search string when the user cancelled searching */
  const cancelledSearch = useRef<string>();

  const cancelSearch = useCallback((currentEditor: BaseEditor & ReactEditor) => {
    const currentLine = getCurrentLineText(currentEditor);
    cancelledSearch.current = currentLine?.text;
  }, []);

  const resetSearch = useCallback(() => {
    setSearch(undefined);
    setTarget(undefined);
    setSelectedIndex(0);
  }, []);

  const allOptions = useMemo(() => [
    ...options.map(
      (option) => ({
        type: SuggestionType.VARIABLE,
        value: option,
      }),
    ),
    ...functions.map((func) => ({ type: SuggestionType.FUNCTION, value: func })),
  ], [options, functions]);

  const suggestions = useMemo(() => {
    if (search !== undefined) {
      return allOptions.filter(
        (option) => option.value.toLowerCase().startsWith(search.toLowerCase()),
      ).slice(0, 5);
    }
    return [];
  }, [search, allOptions]);

  const suggestionRef = useRef<HTMLDivElement>(null);

  const renderElement = useCallback((elementProps: RenderElementProps) => {
    switch (elementProps.element.type) {
      case Type.Variable:
        return <Variable {...elementProps} />;
      case Type.Function:
        return <Function {...elementProps} />;
      case Type.Preview:
        return <Preview {...elementProps} />;
      default:
        return (
          <div {...elementProps.attributes}>
            <div ref={suggestionRef} />
            {elementProps.children}
          </div>
        );
    }
  }, []);

  const renderLeaf = useCallback((leafProps: RenderLeafProps) => (
    <span
      {...leafProps.attributes}
      style={{ padding: '4px 0' }}>
      {leafProps.children}
    </span>
  ), []);

  const onValueChange = useCallback(() => {
    const currentLine = getCurrentLineText(editor);
    if (currentLine && currentLine.text === cancelledSearch.current) {
      // if the current line hasn't changed since the last time the user cancelled the search,
      // then do not search again
      return;
    }
    cancelledSearch.current = undefined;

    const parsedVariable = AutocompleteEditor.parseForVariables(editor, options);
    if (parsedVariable) {
      AutocompleteEditor.insertSuggestion(
        editor,
        { type: SuggestionType.VARIABLE, value: parsedVariable.variable },
        parsedVariable.range,
      );
      resetSearch();
      return;
    }
    const searchedVariable = AutocompleteEditor.searchForVariables(editor);
    if (searchedVariable) {
      setSearch(searchedVariable.search);
      setTarget(searchedVariable.target);
      return;
    }
    const parsedFunction = AutocompleteEditor.parseForFunctions(editor, functions);
    if (parsedFunction) {
      AutocompleteEditor.insertSuggestion(
        editor,
        { type: SuggestionType.FUNCTION, value: parsedFunction.func },
        parsedFunction.range,
      );
      resetSearch();
      return;
    }
    const searchedSuggestions = AutocompleteEditor.searchForSuggestions(editor);
    if (searchedSuggestions) {
      setSearch(searchedSuggestions.search);
      setTarget(searchedSuggestions.target);
      return;
    }
    resetSearch();
  }, [editor, functions, options, resetSearch]);

  const onKeyDown = useCallback((event) => {
    if (target && suggestions.length) {
      const selection = AutocompleteEditor.selectSuggestion(
        editor,
        event,
        target,
        selectedIndex,
        suggestions,
      );
      if (selection) {
        if (selection.selected !== undefined) {
          setSelectedIndex(selection.selected);
        } else {
          resetSearch();
          if (event.key === 'Escape') {
            cancelSearch(editor);
          }
        }
      }
    }
  }, [target, suggestions, editor, selectedIndex, resetSearch, cancelSearch]);

  useEffect(() => {
    if (target && suggestions.length) {
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      const refRect = suggestionRef.current?.getBoundingClientRect();
      setSuggestionPosition({
        left: rect.left - (refRect?.left ?? 0),
        top: rect.top - (refRect?.top ?? 0) + 18,
      });
    } else {
      setSuggestionPosition({ left: 0, top: 0 });
    }
  }, [editor, target, suggestions]);

  useEffect(() => {
    // preview autocomplete suggestion
    if (search && suggestions.length) {
      const currentSuggestion = suggestions[selectedIndex];
      AutocompleteEditor.insertPreview(editor, currentSuggestion.value, search);
    } else if (!suggestions.length) {
      // delete previews when there are no available suggestions
      AutocompleteEditor.deletePreview(editor);
    }
  }, [editor, search, suggestions, selectedIndex]);

  return (
    <Slate
      editor={editor}
      initialValue={initialValue ?? EMPTY_EDITOR}
      onChange={onValueChange}>
      <Editable
        className={cx('expressionInput', faultType ? `fault-${faultType}` : '')}
        onKeyDown={(event) => onKeyDown(event)}
        placeholder={placeholder}
        readOnly={readOnly}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        renderPlaceholder={({ children, attributes }) => (
          <div
            className="placeholder"
            {...attributes}>{children}
          </div>
        )}
        style={{ minHeight }}
      />
      {!!suggestions.length && (
        <AutocompleteSuggestionsMenu
          anchorEl={suggestionRef.current}
          onClick={(index) => {
            if (target) {
              AutocompleteEditor.insertSuggestion(editor, suggestions[index], target);
              resetSearch();
              ReactEditor.focus(editor);
            }
          }}
          onClose={() => {
            resetSearch();
            cancelSearch(editor);
          }}
          position={suggestionPosition}
          selectedIndex={selectedIndex}
          suggestions={suggestions}
        />
      )}
    </Slate>
  );
}
