// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/*
  The Overlay component is a mostly transparent SVG canvas that sits on
  top of a Paraview canvas and displays a text message.
*/

import React, { ReactNode, useCallback } from 'react';

import { AnyKeyboardEvent } from '../../../lib/event';
import { isEventTrigger } from '../../../lib/keyBindings';
import { ListenerEvent, useEventListener } from '../../../lib/useEventListener';
import { OverlayMode, useSetOverlayMode } from '../../../state/internal/vis/overlayMode';

export interface OverlayProps {
  text: string;
  children?: ReactNode;
}

const Overlay = (props: OverlayProps) => {
  const { text } = props;
  const setOverlayMode = useSetOverlayMode();

  // pressing escape should exit the current overlay.
  const handleExitOverlay = useCallback((event: AnyKeyboardEvent) => {
    if (isEventTrigger('exitOverlay', event)) {
      event.preventDefault();
      setOverlayMode(OverlayMode.NONE);
    }
  }, [setOverlayMode]);
  useEventListener(
    'keydown',
    (event: ListenerEvent) => handleExitOverlay(event as AnyKeyboardEvent),
  );

  return (
    <svg
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
      }}>
      <g>
        <text
          style={{
            // Make sure that the text is not selectable
            dominantBaseline: 'middle',
            color: 'white',
            cursor: 'default',
            fill: 'white',
            fontFamily: 'Inter,Helvetica,Arial,sans-serif',
            fontSize: '15',
            pointerEvents: 'none',
            textAnchor: 'middle',
            MozUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none',
            WebkitUserSelect: 'none',
          }}
          x="50%"
          y="90%">
          {text}
        </text>
      </g>
      {props.children}
    </svg>
  );
};

export default Overlay;
