// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import LabeledInput from '../LabeledInput';
import { ValidVector3Input, ValidVector3InputProps } from '../ValidatedInputs/ValidVector3Input';

interface CenterInputProps extends ValidVector3InputProps {
  // The label for the input
  label: 'Center' | 'Origin';
  // The help text for the label
  help?: string,
}

/**
 * A generic input component for a Center or Origin field.
 * For a center field, there are no validation requirements.
 */
export const CenterInput = (props: CenterInputProps) => {
  const { label, help } = props;
  return (
    <LabeledInput help={help} label={label}>
      <ValidVector3Input {...props} />
    </LabeledInput>
  );
};
