// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import assert from 'assert';

import { LCVType } from '@luminarycloudinternal/lcvis';
import { SetterOrUpdater } from 'recoil';

import { TreeNode, TreeNodeParam } from '../../../pvproto/ParaviewRpc';
import { Logger } from '../../observability/logs';
import { traverseTreeNodes } from '../../paraviewUtils';
import { EditState, applyVisibilityToNode, findPathToNode, isClipOrSlice } from '../../visUtils';
import { LcvFrame } from '../classes/LcvFrame';
import { LcvRenderer } from '../classes/LcvRenderer';
import { LcvFilterWidgetType } from '../classes/widgets/LcvWidget';
import { applyParamToWidget, lcvWidgetFromParam } from '../classes/widgets/widgetUtils';
import { LcvModule } from '../types';

const logger = new Logger('LcvFilterHandler');

export class LcvFilterHandler {
  lcv: LcvModule;
  sessionHandle: number;
  activeFrame: LcvFrame;
  renderer: LcvRenderer;

  /** The LcvWidgets representing the filterState */
  savedWidgets: Map<string, LcvFilterWidgetType> = new Map();

  /**
   * When the editState is active, editWidget is the LcvWidget whose controls are shown.
   * Once the edit is committed (applyEdit), the editWidget is added to this.savedWidgets.
   * If the edit is canceled (cancelEdit), the editWidget is released.
   * */
  editWidget: LcvFilterWidgetType | null = null;

  /** The saved filter state from the most recent time the user updated the LCVis filters. */
  savedFilterState: TreeNode | null = null;

  /** The editState at the most recent time an edit function was invoked on this class. */
  prevEditState: EditState | null = null;

  /** Whether the clip widgets will hide the clipped regions or make them transparent. */
  clipHideEnabled = true;

  // If disabled is true, we should not use this filterHandler class, since instead we will be
  // applying 'true' mesh filters to the volumes. So we will make sure all widgets are hidden
  // if disabled = true.
  disabled: boolean = false;

  constructor(lcv: LcvModule, sessionHandle: number, renderer: LcvRenderer, frame: LcvFrame) {
    this.lcv = lcv;
    this.sessionHandle = sessionHandle;
    this.renderer = renderer;
    this.activeFrame = frame;
  }

  // Hide all filter widgets. Keep the editWidget since we still want to use it for setting
  // up mesh filters.
  setDisabled(disable: boolean) {
    if (disable) {
      this.detachWidgets();
      if (this.editWidget) {
        this.editWidget.attachFrame(this.activeFrame);
      }
    } else if (this.savedFilterState) {
      this.applyInitialState(this.savedFilterState);
      this.restorePrevVisibility();
    }
    this.disabled = disable;
  }

  toggleClipHide() {
    if (this.clipHideEnabled) {
      this.setClipTransparent();
    } else {
      this.setClipHide();
    }
  }

  setClipTransparent() {
    this.clipHideEnabled = false;
    this.renderer.setParam('clip_hide', LCVType.kLCVDataTypeUint, 0);
  }

  setClipHide() {
    this.clipHideEnabled = true;
    this.renderer.setParam('clip_hide', LCVType.kLCVDataTypeUint, 1);
  }

  /**
   * During editing, the user may click a different filter button to instead start creating a
   * different type of filter than what is currently displayed. In that case, we must release
   * the current editWidget and assign it to a new widget of the correct type.
   * (for example, switching from a box clip to a plane clip, or vice versa)
   *
   * returns false if no switch occurred, and true if it did.
   */
  private maybeHandleParamTypChange(param: TreeNodeParam): boolean {
    if (!this.prevEditState) {
      return false;
    }
    assert(this.editWidget !== null);
    const prevParam = this.prevEditState.param;
    const paramChanged = (
      param.typ !== prevParam.typ ||
      (
        param.typ === 'Clip' && prevParam.typ === 'Clip' &&
        param.filterParam.typ !== prevParam.filterParam.typ
      )
    );
    if (paramChanged) {
      const newWidget = lcvWidgetFromParam(this.lcv, this.sessionHandle, param);
      if (!newWidget) {
        logger.error('there was an error creating a new widget when switching param types');
        return false;
      }
      this.editWidget.setEditStateCallback(null);
      this.editWidget.release();
      this.editWidget = newWidget;
      return true;
    }
    return false;
  }

  /**
   * Start editing a widget. Show the controls for the widget being edited.
   * Attach a callback to the widget being controlled to call setEditState whenever
   * the user modifies the filter using its controls in the visualizer.
   * @returns true on success
   */
  public startEdit(editState: EditState, setEditState: SetterOrUpdater<EditState | null>): boolean {
    if (this.prevEditState) {
      // if we were in a different edit before, cancel it and start a new one.
      this.cancelEdit();
    }
    const newWidget = lcvWidgetFromParam(this.lcv, this.sessionHandle, editState.param);
    if (!newWidget) {
      logger.error(`failed to create new widget in LCVis with param ${editState.param}`);
      this.prevEditState = null;
      return false;
    }
    if (!editState.newNode) {
      // if we are not creating a new node, hide the filter we're showing a widget for, otherwise
      // we'll have duplicates.
      this.savedWidgets.get(editState.nodeId)?.detachFrame();
    }
    newWidget.attachFrame(this.activeFrame);
    newWidget.showControls();
    // attach the callback so that editing the widget controls updates the editState
    newWidget.setEditStateCallback(setEditState);

    this.editWidget = newWidget;
    this.prevEditState = editState;
    return true;
  }

  /**
   * Update the widget being controlled according to the new editState.
   * @returns true on success
   */
  public updateEdit(
    editState: EditState | null,
    setEditState: SetterOrUpdater<EditState | null>,
  ): boolean {
    if (!this.prevEditState && editState) {
      this.startEdit(editState, setEditState);
      return true;
    }

    if (editState === null && this.prevEditState !== null) {
      this.cancelEdit();
      return true;
    }
    if (editState === null) {
      return false;
    }

    // we know this.prevEditState !== null && editState !== null;
    if (!this.editWidget) {
      logger.error('no editWidget found in LCVis, cannot edit widget');
      return false;
    }

    // if the editState param refers to a box while the editWidget is a plane (or vice versa),
    // we may need to swap the widget type.
    if (this.maybeHandleParamTypChange(editState!.param)) {
      this.editWidget.attachFrame(this.activeFrame);
      this.editWidget.setEditStateCallback(setEditState);
      this.editWidget.showControls();
    } else {
      applyParamToWidget(this.editWidget, editState!.param);
    }

    this.prevEditState = editState;
    return true;
  }

  /**
   * Update the widget being controlled according to the new editState.
   * @returns true on success
   */
  public applyEdit(editState: EditState, newId?: string): boolean {
    this.prevEditState = null;
    if (!this.editWidget) {
      // no widget to apply
      logger.error('something went wrong, no edit widget found');
      return false;
    }

    this.editWidget.hideControls();
    if (this.disabled) {
      this.editWidget.detachFrame();
      this.editWidget.release();
      this.editWidget = null;
      return true;
    }

    // If we made a new node, add the currently edited widget to the map.
    if (editState.newNode) {
      if (!newId) {
        logger.error('no new id provided in applyEdit');
        return false;
      }
      // just in case, check if we have a widget with that id and release if so.
      if (this.savedWidgets.has(newId)) {
        this.savedWidgets.get(newId)!.release();
        this.savedWidgets.delete(newId);
      }
      this.savedWidgets.set(newId, this.editWidget);
      this.editWidget = null;
      return true;
    }

    // we didn't make a new node. So we're replacing our saved widget with the new one.
    const oldWidget = this.savedWidgets.get(editState.nodeId);
    oldWidget?.release();
    this.savedWidgets.set(editState.nodeId, this.editWidget);
    this.editWidget = null;
    return true;
  }

  /**
   * Cancel the edit.
   * @returns true on success
   */
  public cancelEdit(): boolean {
    // delete the active widget.
    this.editWidget?.release();
    this.editWidget = null;
    this.prevEditState = null;
    this.restorePrevVisibility();
    return true;
  }

  /**
   * Initialize the filter state. For each filter in the tree, create the corresponding
   * LCVis widget.
   * If a filter is 'visible', attach it to the frame.
   * @returns true on success
   */
  public applyInitialState(filterState: TreeNode): boolean {
    if (this.savedWidgets.size) {
      // applyInitialState was already called
      return false;
    }
    const createFilter = (node: TreeNode) => {
      const newWidget = lcvWidgetFromParam(this.lcv, this.sessionHandle, node.param);
      if (newWidget) {
        // Hide the controls for all the widgets, since we only show controls for the filter
        // being actively edited.
        newWidget.hideControls();
        this.savedWidgets.set(node.id, newWidget);
      }
    };

    try {
      traverseTreeNodes(filterState, createFilter);
      this.maybeUpdateVisibility(filterState);
      return true;
    } catch (error) {
      throw Error(`could not initialize filter state in LCVis. Error: ${error}`);
    }
  }

  /**
   * In LCVis we have a limitation on the number of filters that can be applied at one time.
   * maybeUpdateVisibility takes @param filterState with a desired set of visibilities, and applies
   * all or a subset of those visibilities depending on the types of filters present.
   * @returns the filterState with the visibilities that were actually applied.
   */
  public maybeUpdateVisibility(filterState: TreeNode, forceNotDisabled?: boolean): TreeNode {
    if (this.disabled && !forceNotDisabled) {
      return filterState;
    }
    let numVisibleClipSlices = 0;
    let clipSliceNode = '';
    traverseTreeNodes(filterState, (node) => {
      if (isClipOrSlice(node) && node.visible) {
        clipSliceNode = node.id;
        numVisibleClipSlices += 1;
      }
    });
    // We can only allow 1 clip or slice to be shown as visible in the tree. So if more than 1 is
    // shown, hide all but 1.
    const newRoot = numVisibleClipSlices < 2 ?
      filterState :
      applyVisibilityToNode(filterState, clipSliceNode, true, true, false);

    // In Paraview, clips and slices applied to their parents are additive. In LCVis, we emulate
    // that behavior: when a clip is visible, we will display it as the intersection of that
    // clip, plus all its parent clips as well. So making a clip visible really means making it +
    // its parents also visible. And by 'make visible', what we do is attach the widget to the
    // frame.
    // if we have a visible clip or slice, we must attach it and all its parents.
    const pathToClipSliceNode = findPathToNode(newRoot, clipSliceNode);
    traverseTreeNodes(newRoot, (node) => {
      if (!this.savedWidgets.has(node.id)) {
        return;
      }
      if (pathToClipSliceNode.has(node.id) || node.visible) {
        this.savedWidgets.get(node.id)!.attachFrame(this.activeFrame);
      } else {
        this.savedWidgets.get(node.id)!.detachFrame();
      }
    });

    this.savedFilterState = newRoot;
    return newRoot;
  }

  /**
   * When we cancel or commit an edit, we may have been showing controls for a widget that
   * is actually hidden in the filterState. So we should revert to the old visibility in the
   * filterState.
   */
  private restorePrevVisibility() {
    if (this.savedFilterState) {
      this.maybeUpdateVisibility(this.savedFilterState);
    }
  }

  /** Remove all filters */
  deleteAllNodes(): void {
    this.savedWidgets.forEach((widget) => widget.release());
    this.savedWidgets.clear();
    this.editWidget?.release();
    this.editWidget = null;
    this.savedFilterState = null;
  }

  /** Remove a filter. */
  deleteNode(nodeId: string): void {
    if (!this.savedWidgets.has(nodeId)) {
      return;
    }
    this.savedWidgets.get(nodeId)?.release();
    this.savedWidgets.delete(nodeId);
  }

  attachWidgets() {
    if (!this.savedFilterState) {
      return;
    }
    this.editWidget?.attachFrame(this.activeFrame);
    if (this.disabled) {
      // if the handler is disabled, only attach the edit widget. The rest should not be used.
      return;
    }
    this.restorePrevVisibility();
  }

  detachWidgets() {
    this.savedWidgets.forEach((widget) => widget.detachFrame());
    this.editWidget?.detachFrame();
  }

  release() {
    this.savedWidgets.forEach((widget) => widget.release());
    this.savedWidgets.clear();
    if (this.editWidget) {
      this.editWidget.release();
      this.editWidget = null;
    }
    this.savedFilterState = null;
  }
}
