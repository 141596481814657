// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 20;
export const HorizontalWavesTripleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="horizontalWavesTriple"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0,16.023
           L1.846,16.023
           C1.846,17.121 2.673,18.012 3.692,18.012
           C4.712,18.012 5.538,17.121 5.538,16.023
           L7.385,16.023
           C7.385,17.121 8.211,18.012 9.231,18.012
           C10.25,18.012 11.077,17.121 11.077,16.023
           L12.923,16.023
           C12.923,17.121 13.75,18.012 14.769,18.012
           C15.789,18.012 16.615,17.121 16.615,16.023
           L18.462,16.023
           C18.462,17.121 19.288,18.012 20.308,18.012
           C21.327,18.012 22.154,17.121 22.154,16.023
           L24,16.023
           C24,18.206 22.334,20 20.308,20
           C19.203,20 18.217,19.456 17.539,18.617
           C16.86,19.456 15.874,20 14.769,20
           C13.665,20 12.679,19.456 12,18.617
           C11.321,19.456 10.336,20 9.231,20
           C8.126,20 7.14,19.456 6.462,18.617
           C5.783,19.456 4.797,20 3.692,20
           C1.666,20 0,18.206 0,16.023Z
           M0,8.012
           L1.846,8.012
           C1.846,9.11 2.673,10 3.692,10
           C4.712,10 5.538,9.11 5.538,8.012
           L7.385,8.012
           C7.385,9.11 8.211,10 9.231,10
           C10.25,10 11.077,9.11 11.077,8.012
           L12.923,8.012
           C12.923,9.11 13.75,10 14.769,10
           C15.789,10 16.615,9.11 16.615,8.012
           L18.462,8.012
           C18.462,9.11 19.288,10 20.308,10
           C21.327,10 22.154,9.11 22.154,8.012
           L24,8.012
           C24,10.194 22.334,11.988 20.308,11.988
           C19.203,11.988 18.217,11.445 17.539,10.606
           C16.86,11.445 15.874,11.988 14.769,11.988
           C13.665,11.988 12.679,11.445 12,10.606
           C11.321,11.445 10.336,11.988 9.231,11.988
           C8.126,11.988 7.14,11.445 6.462,10.606
           C5.783,11.445 4.797,11.988 3.692,11.988
           C1.666,11.988 0,10.194 0,8.012Z
           M0,0
           L1.846,0
           C1.846,1.098 2.673,1.988 3.692,1.988
           C4.712,1.988 5.538,1.098 5.538,0
           L7.385,0
           C7.385,1.098 8.211,1.988 9.231,1.988
           C10.25,1.988 11.077,1.098 11.077,0
           L12.923,0
           C12.923,1.098 13.75,1.988 14.769,1.988
           C15.789,1.988 16.615,1.098 16.615,0
           L18.462,0
           C18.462,1.098 19.288,1.988 20.308,1.988
           C21.327,1.988 22.154,1.098 22.154,0
           L24,0
           C24,2.183 22.334,3.977 20.308,3.977
           C19.203,3.977 18.217,3.433 17.539,2.594
           C16.86,3.433 15.874,3.977 14.769,3.977
           C13.665,3.977 12.679,3.433 12,2.594
           C11.321,3.433 10.336,3.977 9.231,3.977
           C8.126,3.977 7.14,3.433 6.462,2.594
           C5.783,3.433 4.797,3.977 3.692,3.977
           C1.666,3.977 0,2.183 0,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
