// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

type MessageProto = string | (() => string);

/** If !cond, throws an error with args printed as JSON in the message. */
export default function assert(cond: boolean, msg: MessageProto): asserts cond {
  if (!msg) {
    // In case it's an empty string
    throw Error('assert() requires a message');
  }

  if (!cond) {
    const message = typeof msg === 'function' ? msg() : msg;
    throw Error(`assertion failure: ${message}`);
  }
}
