// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as ProtoDescriptor from '../ProtoDescriptor';
import { Param } from '../ProtoDescriptor';
import {
  ParamGroupName,
  ParamName,
  paramDesc,
  paramGroupDesc,
} from '../SimulationParamDescriptor';
import * as flags from '../flags';
import * as simulationpb from '../proto/client/simulation_pb';
import * as feoutputpb from '../proto/frontend/output/output_pb';
import * as codespb from '../proto/lcstatus/codes_pb';
import * as geometrypb from '../proto/lcstatus/details/geometry/geometry_pb';
import * as levelspb from '../proto/lcstatus/levels_pb';
import {
  MeshingMultiPart_BoundaryLayerParams_SelectionType,
  MeshingMultiPart_ModelParams_SelectionType,
} from '../proto/meshgeneration/meshgeneration_pb';
import * as outputpb from '../proto/output/output_pb';
import { ReferenceValueType } from '../proto/output/reference_values_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';
import { EntityGroupData } from '../recoil/entityGroupState';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { InitData } from '../recoil/useInitData';
import { StaticVolume } from '../recoil/volumes';
import { NamesRecord } from '../state/external/project/simulation/param/boundaryNames';
import { ProjectValidationData } from '../state/external/project/validationData';

import { MessageCollector } from './MessageCollector';
import { ParamScope, chainParamScopes, createParamScope } from './ParamScope';
import { equalsZero, getAdValue } from './adUtils';
import {
  AnyBoundaryCondition,
  findFarfield,
  getNonSlipWallSurfaces,
  getUnassignedSurfacesByPhysics,
  getWallSurfaces,
  hasMultipleFarfields,
  hasPeriodicPair,
  isFarfieldBoundaryCondition,
  isUndefinedBoundaryCondition,
  isWallBoundaryCondition,
  periodicPairId,
  removeBoundsPrefix,
} from './boundaryConditionUtils';
import {
  MAX_HEAT_PHYSICS,
  MAX_PHYSICS,
  MAX_STOPPING_COND_INNER_ITERATIONS,
  MAX_STOPPING_COND_ITERATIONS,
  MAX_STOPPING_COND_WEIGHT,
  UNSUPPORTED_LMA,
  UNSUPPORTED_MULTIPHYS_MOTION,
} from './constants';
import { unwrapSurfaceIds } from './entityGroupUtils';
import {
  findMaterialIdByDomain,
  findPhysicsIdByDomain,
  getMaterialDomains,
  getPhysicsDomains,
} from './entityRelationships';
import {
  explorationVariableNodeId,
  extractFormattedValue,
  extractScalarValue,
  getLabel,
  getProtoForType,
  varSpecToParamState,
} from './explorationUtils';
import { FARFIELD_NODE_ID, isInitialized } from './farfieldUtils';
import { getNodeIds, getTitle } from './geometryHealthUtils';
import { boldEscaped } from './html';
import { findImposter, isImposter } from './imposterFilteringUtils';
import {
  existingSolutionChoiceInParam,
  fluidConstraints,
  getSolutionDivergedWarning,
  getViolatedParamConstraints,
  isPhysicsCompatible,
  meshConstraints,
} from './initializationUtils';
import { intersects, isSuperset } from './lang';
import {
  findMaterialEntityById,
  getMaterialFluid,
  getMaterialId,
  getMaterialName,
  isMaterialFluid,
  isMaterialSolid,
} from './materialUtils';
import { BOUNDARY_ID, MODEL_ID, SIZE_ID, nullableMeshing } from './mesh';
import {
  commonFrameWarnings,
  findBodyFrame,
  findFrameById,
  frameHasGeometryInBranch,
  frameHasMotion,
  frameInheritsMotion,
  generalMotionWarnings,
  getFrameDisplacement,
  getFrameVelocity,
  orderedFrames,
  projectHasMovingFrames,
  unsteadyMotionConflict,
} from './motionDataUtils';
import { getMultiphysicsInterfaceName } from './multiphysicsInterfaceUtils';
import { Level, LeveledMessage, levelToRank } from './notificationUtils';
import { getOutputForcesWarning, getOutputNodeWarnings } from './outputNodeUtils';
import { getProbePoints, particleGroupTypeLabel } from './particleGroupUtils';
import { PHYSICAL_BEHAVIOR_LABEL, findPhysicalBehaviorById } from './physicalBehaviorUtils';
import {
  createPhysicsScope,
  findFluidPhysicsMaterial,
  findPhysicsByDomain,
  findPhysicsById,
  getAllPorousBehaviors,
  getAvailableSurfaces,
  getFluid,
  getHeat,
  getPhysicsContainingSurfaces,
  getPhysicsId,
  getPhysicsInitialization,
  getPhysicsMaterials,
  getPhysicsName,
  getSubPhysics,
  isPhysicsHeat,
  makeBoundaryConditionsId,
  makeInitializationId,
  makeVolumeSelectionId,
  physicsTypeCount,
} from './physicsUtils';
import { validatePorousModelCoefficients } from './porousModelUtils';
import { unpackProto } from './protoUtils';
import { string } from './random';
import { getHLimitBounds, getRefinementRegionWarnings } from './refinementRegionUtils';
import { disabledOutputCategories } from './sensitivityUtils';
import { getOrCreateConvergenceCriteria, getSimulationParam } from './simulationParamUtils';
import {
  GENERAL_SETTINGS_NODE_ID,
  GEOMETRY_CONTACTS_CONTAINER_ID,
  GLOBAL_FRAME_NODE_ID,
  MATERIAL_CONTAINER_NODE_ID,
  MESH_NODE_ID,
  OUTPUT_CONTAINER_ID,
  OUTPUT_REFERENCE_VALUE_NODE_ID,
  PHYSICS_CONTAINER_NODE_ID,
  POLICY_CONTAINER_ID,
  STOPPING_CONDITIONS_NODE_ID,
  SimulationTreeNode,
  VARIABLE_CONTAINER_ID,
} from './simulationTree/node';
import { getBoundaryCondName, paramGroupWarnings } from './simulationTree/utils';
import { isSimLMACompatible, isSimulationImplicitTime, isSimulationTransient } from './simulationUtils';
import { findSlidingInterfaceBySurfaceId } from './slidingInterfaceUtils';
import { compareAlphanumeric } from './sorting';
import { updateStoppingConds } from './stoppingCondsUtils';
import { truncatedWordList, upperFirst, wordsToList } from './text';
import { filterWarnings } from './visUtils';
import { VolumesByDomain, allVolumesAssigned, getVolumeIdsFromSurfaces, getVolumeName, mapByDomains } from './volumeUtils';
import { ParamWithFallback } from './weakParamCompare';

const { REFERENCE_FARFIELD_VALUES } = ReferenceValueType;
const {
  ACTUATOR_DISK_BLADE_ELEMENT,
  ACTUATOR_DISK_RADIAL_DISTRIBUTION,
  FAN_CURVE_INTERNAL,
} = simulationpb.ActuatorDiskModel;
const { ACTUATOR_DISK_SPECIFY_NORMAL_VECTOR } = simulationpb.ActuatorDiskOrientationSelection;
const { CONSTANT_DENSITY } = simulationpb.DensityRelationship;
const { FARFIELD_ANGLES } = simulationpb.FarFieldFlowDirectionSpecification;
const { INVALID_HEAT_PHYSICAL_BOUNDARY } = simulationpb.HeatPhysicalBoundary;
const { FAN_CURVE_INLET } = simulationpb.InletMomentum;
const { EXISTING_SOLUTION } = simulationpb.InitializationType;
const { GENERAL_INTERFACE } = simulationpb.InterfaceType;
const { FAN_CURVE_OUTLET } = simulationpb.OutletStrategy;
const { INVALID_PARTICLE_GROUP_TYPE } = simulationpb.ParticleGroupType;
const { INVALID_PROFILE_TYPE } = simulationpb.ProfileType;
const { ERROR } = levelspb.Level;

const bcNuTildeColDesc = paramDesc[ParamName.BcNuTildeCol];
const bcOmegaColDesc = paramDesc[ParamName.BcOmegaCol];
const bcTkeColDesc = paramDesc[ParamName.BcTkeCol];
const inletVelMagColDesc = paramDesc[ParamName.InletVelocityMagnitudeCol];
const totPresColDesc = paramDesc[ParamName.TotalPressureCol];
const totTempColDesc = paramDesc[ParamName.TotalTemperatureCol];
const heatFluxColDesc = paramDesc[ParamName.HeatFluxCol];
const heatSourceColDesc = paramDesc[ParamName.HeatSourceCol];

export interface ValidationData extends ProjectValidationData {
  boundaryConditionNames: NamesRecord;
  geometryPending: boolean;
  initData: InitData;
  meshReady: boolean;
  geometryTags: GeometryTags;
}

function getFormattedBoundCondLabel(bcName: string) {
  return `Boundary condition ${boldEscaped(bcName)}`;
}

function getFormattedBoundCondLabelByPhysics(
  physics: simulationpb.Physics,
  param: simulationpb.SimulationParam,
  bcName: string,
) {
  return `${boldEscaped(getPhysicsName(physics, param))} boundary condition ${boldEscaped(bcName)}`;
}

/** A class that stores and manages validation messages in a Map whose keys are node IDs and
 * whose values are MessageCollector instances */
export class SimulationValidator {
  public messageMap: Map<string, MessageCollector> = new Map();
  id = string(16);

  private getCollector(id: string): MessageCollector {
    if (!this.messageMap.has(id)) {
      this.messageMap.set(id, new MessageCollector());
    }
    return this.messageMap.get(id)!;
  }

  public getMessages(id: string): LeveledMessage[] {
    return this.getCollector(id).messages;
  }

  public addError(id: string, message: string) {
    this.getCollector(id).addError(message);
  }

  public addWarning(id: string, message: string) {
    this.getCollector(id).addWarning(message);
  }

  public addSuccess(id: string, message: string) {
    this.getCollector(id).addSuccess(message);
  }

  public addInfo(id: string, message: string) {
    this.getCollector(id).addInfo(message);
  }

  public addErrors(id: string, messages: string[]) {
    this.getCollector(id).addErrors(messages);
  }

  public addWarnings(id: string, messages: string[]) {
    this.getCollector(id).addWarnings(messages);
  }

  public addSuccesses(id: string, messages: string[]) {
    this.getCollector(id).addSuccesses(messages);
  }

  public addInfos(id: string, messages: string[]) {
    this.getCollector(id).addInfos(messages);
  }
}

function checkBoundaryLikeSurfaceIntegrity(
  validator: SimulationValidator,
  id: string,
  surfaceIds: string[],
  availableSurfaces: Set<string>,
  label: string,
): boolean {
  if (surfaceIds.length) {
    if (surfaceIds.some((surfaceId) => !availableSurfaces.has(surfaceId))) {
      // Add errors for boundary-like entities that reference surfaces in volumes outside of the
      // parent physics
      validator.addError(
        id,
        `${label} contains surfaces not available to the parent physics.`,
      );
      return true;
    }
  } else {
    // Add errors for boundary-like entities that have no surfaces
    validator.addError(id, `${label} has no surfaces.`);
    return true;
  }
  return false;
}

function checkBoundaryConditionSurfaceIntegrity(
  validator: SimulationValidator,
  boundaryCondition: AnyBoundaryCondition,
  availableSurfaces: Set<string>,
  label: string,
) {
  const { boundaryConditionName: id, surfaces } = boundaryCondition;
  checkBoundaryLikeSurfaceIntegrity(validator, id, surfaces, availableSurfaces, label);
}

function checkHeatSources(
  validator: SimulationValidator,
  sources: simulationpb.HeatSource[],
  physicsId: string,
  param: simulationpb.SimulationParam,
  volumesByDomain: VolumesByDomain,
  paramScope: ParamScope,
  experimentConfig: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  const domains = getPhysicsDomains(param, physicsId, geometryTags, staticVolumes);
  sources.forEach((source) => {
    const { heatSourceId: id, heatSourceName: name, heatSourceZoneIds: zoneIds } = source;
    const formattedLabel = `Heat source ${boldEscaped(name)}`;

    if (zoneIds.length) {
      const unrolledZoneIds = [...new Set(
        zoneIds
          .flatMap((zoneId) => (
            geometryTags.isTagId(zoneId) ? geometryTags.domainsFromTag(zoneId) : [zoneId])),
      )];

      const hasValidVolumeReference = unrolledZoneIds.every((zone) => !!volumesByDomain[zone]);

      if (!hasValidVolumeReference) {
        validator.addError(id, `${formattedLabel} has invalid volume references`);
      } else if (!isSuperset(domains, unrolledZoneIds)) {
        validator.addError(
          id,
          `${formattedLabel} references volumes that aren't assigned to the parent physics`,
        );
      }
    } else {
      validator.addError(id, `${formattedLabel} is not assigned to any volumes`);
    }

    const hsScope = createParamScope(source, experimentConfig, paramScope);
    const isEnabled = (protoParam: Param) => hsScope.isEnabled(protoParam.cond);

    if (source.profileSource) {
      if (!source.profileSourceData.length) {
        validator.addError(id, `${formattedLabel} profile must be uploaded`);
      }
      if (!(isEnabled(heatSourceColDesc) && source.heatSourceCol?.value)) {
        validator.addError(
          id,
          `${formattedLabel} must have at least one column selected if Tabulated Profile is on`,
        );
      }
    }
  });
}

function checkInitializationSettings(
  data: ValidationData,
  validator: SimulationValidator,
  initType: simulationpb.InitializationType | undefined,
  time: simulationpb.FlowBehavior | undefined,
  physics: simulationpb.Physics,
  physicsScope: ParamScope,
  constraints: ParamWithFallback[],
  paramHasExistingSolution: boolean,
): void {
  const { experimentConfig, initData, param, geometryTags: geoTags, staticVolumes } = data;

  const { initState, initReference } = initData;

  const physicsId = getPhysicsId(physics);
  const physicsIndex = param.physics.findIndex((item) => physicsId === getPhysicsId(item));

  const nodeId = makeInitializationId(physicsId);

  if (getFluid(physics)?.boundaryConditionsFluid?.some(
    (bc) => bc.physicalBoundary === simulationpb.PhysicalBoundary.FARFIELD,
  ) &&
    initType === simulationpb.InitializationType.UNIFORM_VALUES) {
    validator.addWarning(
      nodeId,
      'When using a far-field boundary it is best practice to use far-field initialization.',
    );
  }

  if (initType === EXISTING_SOLUTION) {
    getFluid(physics)?.boundaryConditionsFluid?.forEach((bc) => {
      const {
        boundaryConditionName: id,
        physicalBoundary,
        outletStrategy,
      } = bc;
      if (physicalBoundary === simulationpb.PhysicalBoundary.OUTLET &&
        (outletStrategy === simulationpb.OutletStrategy.OUTLET_TARGET_MASS_FLOW_RATE ||
          outletStrategy === simulationpb.OutletStrategy.OUTLET_TARGET_CORRECTED_MASS_FLOW_RATE)) {
        validator.addInfo(id, 'It is best practice to set the initial outlet pressure to the ' +
          'value obtained in the solution being used for initialization.');
      }
    });

    if (time !== simulationpb.FlowBehavior.TRANSIENT &&
      param.general?.floatType !== simulationpb.FloatType.ADA1D &&
      getFluid(physics)?.solutionControlsFluid?.robustStartup ===
      simulationpb.RobustStartup.ROBUST_STARTUP_ON) {
      validator.addInfo(
        physicsId,
        'Consider disabling "Robust Startup" if the solution used for initialization was ' +
        'obtained at similar operating conditions.',
      );
    }
  }

  if (!initType || !initReference) {
    return;
  }
  // We don't support existing solution init type for a subset of the physics. For now we just
  // show a warning to prevent running a simulation.
  if (paramHasExistingSolution && initType !== simulationpb.InitializationType.EXISTING_SOLUTION) {
    validator.addError(
      nodeId,
      'Initialization type \'Existing Solution\' must be selected for all physics or none.',
    );
  }
  const initConfig = initReference.initConfig;
  switch (initConfig.state) {
    case 'hasValue': {
      const initParam = getSimulationParam(initConfig.contents);
      // == Validate Initialization
      // A valid param (i.e. non-default) has the url field non-empty
      const validParam = initParam?.input?.url !== '';
      if (initParam && validParam && (initType === EXISTING_SOLUTION)) {
        const physicsCompatible = isPhysicsCompatible(param, initParam, geoTags, staticVolumes);
        if (!physicsCompatible) {
          validator.addError(nodeId, 'Selected simulation has incompatible physics settings.');
          break;
        }
        //
        const sameStartingMesh = initParam?.input?.url === param.input?.url;
        if (!sameStartingMesh && isSimulationTransient(initParam) &&
          projectHasMovingFrames(initParam)) {
          validator.addError(nodeId, 'Cannot initialize from a solution on a different mesh ' +
            'for transient simulations with moving frames.');
        }
        const otherPhysics = initParam.physics[physicsIndex];
        const otherParamScope =
          createPhysicsScope(initParam, otherPhysics, experimentConfig, geoTags, staticVolumes);
        const violatedConstraints = getViolatedParamConstraints(
          physicsScope,
          otherParamScope,
          constraints,
        );
        if (
          violatedConstraints.length ||
          !initState ||
          !initState.jobId ||
          initState.iter === -1
        ) {
          // Join the constraints' validation errors
          const errorMessages = violatedConstraints.reduce((result, constraint) => {
            if (constraint.warningMessage) {
              result.push(constraint.warningMessage);
            }
            return result;
          }, [] as string[]);
          if (errorMessages.length > 0 || initState?.iter === -1 || !initState?.jobId) {
            validator.addError(
              nodeId,
              'No valid simulation for the initialization selected.',
            );
            validator.addErrors(nodeId, errorMessages);
          }
        }
      }
    }
      break;
    case 'loading':
      validator.addError(nodeId, 'Checking initialization settings...');
      return;
    case 'hasError':
      validator.addError(nodeId, 'Validation of initialization settings failed.');
      return;
    // no default
  }

  const workflowReply = initReference?.workflowReply;
  switch (workflowReply.state) {
    case 'hasValue':
      if (initState) {
        const job = workflowReply.contents?.job[initState?.jobId];
        const warning = job && getSolutionDivergedWarning(job, BigInt(initState.iter));
        warning && validator.addError(nodeId, warning);
      }
      break;
    case 'loading':
      validator.addError(nodeId, 'Checking initialization settings...');
      return;
    case 'hasError':
      validator.addError(
        nodeId,
        'Validation of initialization settings failed.',
      );

    // no default
  }
}

function checkPeriodicPairs(
  validator: SimulationValidator,
  pairs: simulationpb.PeriodicPair[],
  physicsId: string,
  availableSurfaces: Set<string>,
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  pairs.forEach((pair, i) => {
    const {
      boundA,
      boundB,
      periodicPairName,
      periodicTranslation,
      periodicRotationAngles,
    } = pair;
    const id = periodicPairId(physicsId, i);
    const formattedLabel = `Periodic pair ${boldEscaped(periodicPairName)}`;

    const surfacesA = unwrapSurfaceIds(boundA, geometryTags, entityGroupData);
    const surfacesB = unwrapSurfaceIds(boundB, geometryTags, entityGroupData);

    const hasSurfaceErrors = checkBoundaryLikeSurfaceIntegrity(
      validator,
      id,
      [...surfacesA, ...surfacesB],
      availableSurfaces,
      formattedLabel,
    );

    if (hasSurfaceErrors) {
      return;
    }

    if (!surfacesA.length || !surfacesB.length) {
      validator.addError(id, `${formattedLabel}: both bounds must be assigned.`);
    } else if (intersects(surfacesA, surfacesB)) {
      validator.addError(
        id,
        `${formattedLabel}: bound A and Bound B must have different surfaces.`,
      );
    } else if (periodicTranslation && periodicRotationAngles) {
      const zeroTrans = equalsZero(periodicTranslation);
      const zeroRot = equalsZero(periodicRotationAngles);
      if (zeroTrans && zeroRot) {
        validator.addError(
          id,
          `${formattedLabel}: periodic translation or rotation must be specified.`,
        );
      } else if (!zeroTrans && !zeroRot) {
        validator.addError(
          id,
          `${formattedLabel}: cannot specify both translation and rotation.`,
        );
      }
    }
  });
}

function checkPhysicalBehaviors(
  validator: SimulationValidator,
  behaviors: simulationpb.PhysicalBehavior[],
) {
  behaviors.forEach((behavior) => {
    const {
      physicalBehaviorId: id,
      physicalBehaviorName: name,
      actuatorDiskModel,
      actuatorDiskRadialTableData,
      bladeElementAirfoilData,
      bladeElementGeometryData,
      fanCurveTableData,
    } = behavior;
    const formattedLabel = `Physical behavior ${boldEscaped(name)}`;

    switch (actuatorDiskModel) {
      case ACTUATOR_DISK_RADIAL_DISTRIBUTION: {
        if (!actuatorDiskRadialTableData) {
          validator.addError(
            id,
            `${formattedLabel}: aero file data is required for radial distribution model.`,
          );
        }
        break;
      }
      case ACTUATOR_DISK_BLADE_ELEMENT: {
        if (!bladeElementAirfoilData.length) {
          validator.addError(id, `${formattedLabel}: at least one airfoil is required.`);
        }

        if (!bladeElementGeometryData) {
          validator.addError(
            id,
            `${formattedLabel}: blade geometry is required for blade element model.`,
          );
        }

        const stations = bladeElementAirfoilData.map(
          (airfoil) => getAdValue(airfoil.airfoilRadialStation),
        );
        const stationSet = new Set(stations);
        if (stationSet.size < stations.length) {
          validator.addError(
            id,
            `${formattedLabel}: blade element radial stations must be unique.`,
          );
        }

        const perfs = bladeElementAirfoilData.map((airfoil) => airfoil.airfoilPerformanceData);
        const perfsSet = perfs.every((tableKey) => !!tableKey);
        if (!perfsSet) {
          validator.addError(
            id,
            `${formattedLabel}: performance data required for each blade element airfoil.`,
          );
        }
        break;
      }
      case FAN_CURVE_INTERNAL: {
        if (!fanCurveTableData.length) {
          validator.addError(id, `${formattedLabel} fan curve data must be uploaded.`);
        }
        break;
      }
      default: {
        // no default
      }
    }
  });
}

function checkPorousModels(
  validator: SimulationValidator,
  porousModels: simulationpb.PorousBehavior[],
  param: simulationpb.SimulationParam,
): void {
  porousModels.forEach((porousModel) => {
    const {
      porousBehaviorId: id,
      porousBehaviorName: name,
      refFrameId,
    } = porousModel;

    const formattedLabel = `Porous model ${boldEscaped(name)}`;

    const frame = refFrameId ? findFrameById(param, refFrameId) : undefined;

    if (frame) {
      if (frameHasMotion(frame)) {
        // Warn if reference frame has motion
        validator.addError(id, `${formattedLabel} reference frame may not have motion`);
      } else if (frameInheritsMotion(param, frame)) {
        // Warn if any of the referenced frame's ancestors has motion
        validator.addError(id, `${formattedLabel} reference frame may not inherit motion`);
      }
    }

    if (porousModel && !validatePorousModelCoefficients(porousModel)) {
      validator.addError(id, `${formattedLabel} cannot have negative coefficients`);
    }

    if (porousModel.zoneIds.length === 0) {
      validator.addError(id, `${formattedLabel} will not have any effect on the simulation until
        one or more volumes have been assigned to it`);
    }
  });
}

type BoundaryConditionsBase = {
  surfaces: string[];
  clone: () => any;
};

function unrollBoundaryConditionTags<T extends BoundaryConditionsBase>(
  boundaryCondition: T,
  geometryTags: GeometryTags,
): T {
  const bcOut = boundaryCondition.clone();
  const setSurfaces = new Set<string>();
  boundaryCondition.surfaces.forEach((surfaceId) => {
    if (geometryTags.isTagId(surfaceId)) {
      geometryTags.surfacesFromTagEntityGroupId(surfaceId)?.forEach((id) => {
        setSurfaces.add(id);
      });
      return;
    }
    setSurfaces.add(surfaceId);
  });
  bcOut.surfaces = Array.from(setSurfaces);
  return bcOut;
}

function checkSlidingInterfaces(
  validator: SimulationValidator,
  slidingInterfaces: simulationpb.SlidingInterfaces[],
  staticVolumes: StaticVolume[],
  availableSurfaces: Set<string>,
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
): void {
  slidingInterfaces.forEach((slidingInterface) => {
    const {
      slidingInterfaceId: id,
      slidingInterfaceName: name,
      slidingA: sideA,
      slidingB: sideB,
      interfaceType,
    } = slidingInterface;
    const formattedLabel = `Interface ${boldEscaped(name)}`;
    const surfacesA = unwrapSurfaceIds(sideA, geometryTags, entityGroupData);
    const surfacesB = unwrapSurfaceIds(sideB, geometryTags, entityGroupData);

    const hasSurfaceIntegrityErrors = checkBoundaryLikeSurfaceIntegrity(
      validator,
      id,
      [...surfacesA, ...surfacesB],
      availableSurfaces,
      formattedLabel,
    );
    if (hasSurfaceIntegrityErrors) {
      return;
    }

    if (!interfaceType) {
      validator.addError(id, `${formattedLabel} method is not defined.`);
    }
    if (!surfacesA.length || !surfacesB.length) {
      validator.addError(id, `${formattedLabel} requires surfaces on both sides.`);
    }

    const volumesA = getVolumeIdsFromSurfaces(surfacesA || [], staticVolumes);
    const volumesB = getVolumeIdsFromSurfaces(surfacesB || [], staticVolumes);

    // Each interface side can only hold surfaces that belong to a unique volume.
    if (volumesA.length > 1) {
      validator.addError(id, `${formattedLabel}: side A has surfaces on multiple volumes.`);
    }
    if (volumesB.length > 1) {
      validator.addError(id, `${formattedLabel}: side B has surfaces on multiple volumes.`);
    }

    // For non-conformal interfaces we can only use the GENERAL_INTERFACE method.
    if (volumesA.length === 1 && volumesB.length === 1 && volumesA[0] === volumesB[0]) {
      if (interfaceType !== GENERAL_INTERFACE) {
        validator.addError(
          id,
          `${formattedLabel} requires selecting the automatic treatment method, because it is an
             internal interface.`,
        );
      }
    }
  });
}

/** Validate all boundary conditions */
function validateBoundaryConditions(data: ValidationData, validator: SimulationValidator): void {
  const {
    boundaryConditionNames,
    experimentConfig,
    param,
    paramScope,
    staticVolumes,
    geometryTags,
    entityGroupData,
  } = data;

  const bodyFrame = findBodyFrame(param);
  const allPhysics = param.physics;
  const multiPhysics = allPhysics.length > 1;

  allPhysics.forEach((physics) => {
    const physicsId = getPhysicsId(physics);
    const physicsAnnotation = multiPhysics ?
      `in physics ${boldEscaped(getPhysicsName(physics, param))}` :
      '';

    if (getUnassignedSurfacesByPhysics(
      param,
      physicsId,
      geometryTags,
      staticVolumes,
      entityGroupData,
    ).length) {
      validator.addError(
        makeBoundaryConditionsId(physicsId),
        `Every surface ${physicsAnnotation} should be assigned to a boundary condition.`,
      );
    }
  });

  const rootId = PHYSICS_CONTAINER_NODE_ID;

  if (hasMultipleFarfields(param)) {
    validator.addError(
      rootId,
      'Multiple far-field boundaries are not allowed.',
    );
  }

  allPhysics.forEach((physics) => {
    const physicsId = getPhysicsId(physics);
    if (!getPhysicsDomains(param, physicsId, geometryTags, staticVolumes)) {
      // If there are no volumes yet, then don't bother with the remaining validations
      return;
    }

    const availableSurfaces =
      new Set(getAvailableSurfaces(param, physicsId, geometryTags, staticVolumes));

    // Validate any fluid boundary conditions
    getFluid(physics)?.boundaryConditionsFluid.forEach((boundaryConditionIn) => {
      const boundaryCondition = unrollBoundaryConditionTags(boundaryConditionIn, geometryTags);
      const {
        boundaryConditionName: id,
        farFieldFlowDirectionSpecification,
        profileBc,
        profileBcData,
        profileType,
        totalPressureCol,
        totalTemperatureCol,
        bcNuTildeCol,
        bcTkeCol,
        bcOmegaCol,
        inletVelocityMagnitudeCol,
        inletMomentum,
        outletStrategy,
        fanCurveTableData,
        wallMomentum,
      } = boundaryCondition;
      const name = getBoundaryCondName(boundaryConditionNames, boundaryCondition);
      const formattedLabel = multiPhysics ?
        getFormattedBoundCondLabelByPhysics(physics, param, name) :
        getFormattedBoundCondLabel(name);

      // Add errors for boundary conditions that don't have a valid physical boundary type
      if (isUndefinedBoundaryCondition(boundaryCondition)) {
        validator.addError(id, `${formattedLabel} has no type.`);
      }
      checkBoundaryConditionSurfaceIntegrity(
        validator,
        boundaryCondition,
        availableSurfaces,
        formattedLabel,
      );

      if (
        isFarfieldBoundaryCondition(boundaryCondition) &&
        farFieldFlowDirectionSpecification === FARFIELD_ANGLES &&
        !bodyFrame
      ) {
        validator.addError(
          id,
          'Far-field boundary condition requires a Body Frame to determine the flow direction.',
        );
      }

      if (
        isWallBoundaryCondition(boundaryCondition) &&
        wallMomentum === simulationpb.WallMomentum.SLIP &&
        getFluid(physics)?.basicFluid?.viscousModel !== simulationpb.ViscousModel.INVISCID
      ) {
        validator.addWarning(id, 'Slip walls are typically only used in inviscid simulations, ' +
          'please ensure the current selection is intentional.');
      }

      const material = findFluidPhysicsMaterial(param, physics, geometryTags, staticVolumes);
      const bcScope = chainParamScopes(
        [material, physics, boundaryCondition],
        experimentConfig,
        paramScope,
      );
      const isEnabled = (protoParam: Param) => bcScope.isEnabled(protoParam.cond);

      if (profileBc) {
        if (profileType === INVALID_PROFILE_TYPE) {
          validator.addError(id, `${formattedLabel} must have a profile type.`);
        }
        if (!profileBcData.length) {
          validator.addError(id, `${formattedLabel} profile must be uploaded.`);
        }
        if (
          !(isEnabled(totPresColDesc) && totalPressureCol?.value) &&
          !(isEnabled(totTempColDesc) && totalTemperatureCol?.value) &&
          !(isEnabled(bcNuTildeColDesc) && bcNuTildeCol?.value) &&
          !(isEnabled(bcTkeColDesc) && bcTkeCol?.value) &&
          !(isEnabled(bcOmegaColDesc) && bcOmegaCol?.value) &&
          !(isEnabled(inletVelMagColDesc) && inletVelocityMagnitudeCol?.value)
        ) {
          validator.addError(
            id,
            `${formattedLabel} must have at least one column selected if Tabulated Profile is on`,
          );
        }
      }

      const isFanCurve = (
        (inletMomentum === FAN_CURVE_INLET) ||
        (outletStrategy === FAN_CURVE_OUTLET)
      );
      if (isFanCurve && !fanCurveTableData.length) {
        validator.addError(id, `${formattedLabel} fan curve data must be uploaded.`);
      }

      validator.addErrors(
        id,
        paramGroupWarnings(paramGroupDesc[ParamGroupName.BoundaryConditionsFluid], bcScope),
      );
    });

    // Validate any heat boundary conditions
    getHeat(physics)?.boundaryConditionsHeat.forEach((boundaryConditionIn) => {
      const boundaryCondition = unrollBoundaryConditionTags(boundaryConditionIn, geometryTags);
      const {
        boundaryConditionName: id,
        heatPhysicalBoundary,
      } = boundaryCondition;
      const name = getBoundaryCondName(boundaryConditionNames, boundaryCondition);
      const formattedLabel = multiPhysics ?
        getFormattedBoundCondLabelByPhysics(physics, param, name) :
        getFormattedBoundCondLabel(name);

      // Add errors for boundary conditions that don't have a valid physical boundary type
      if (heatPhysicalBoundary === INVALID_HEAT_PHYSICAL_BOUNDARY) {
        validator.addError(id, `${formattedLabel} has no type.`);
      }
      checkBoundaryConditionSurfaceIntegrity(
        validator,
        boundaryCondition,
        availableSurfaces,
        formattedLabel,
      );

      const bcScope = createParamScope(boundaryCondition, experimentConfig, paramScope);
      const isEnabled = (protoParam: Param) => bcScope.isEnabled(protoParam.cond);

      if (boundaryCondition.profileBc) {
        if (boundaryCondition.profileType === INVALID_PROFILE_TYPE) {
          validator.addError(id, `${formattedLabel} must have a profile type.`);
        }
        if (!boundaryCondition.profileBcData.length) {
          validator.addError(id, `${formattedLabel} profile must be uploaded.`);
        }
        if (!(isEnabled(heatFluxColDesc) && boundaryCondition.heatFluxCol?.value)) {
          validator.addError(
            id,
            `${formattedLabel} must have at least one column selected if Tabulated Profile is on`,
          );
        }
      }

      validator.addErrors(
        id,
        paramGroupWarnings(paramGroupDesc[ParamGroupName.BoundaryConditionsHeat], bcScope),
      );
    });
  });
}

function validateMultiphysicsInterfaces(
  data: ValidationData,
  validator: SimulationValidator,
): void {
  const { param, staticVolumes, geometryTags } = data;

  param.interfaces.forEach((multiphysicsInterface, i) => {
    const {
      slidingInterfaceId: interfaceId,
      slidingA: surfaceIdsA,
      slidingB: surfaceIdsB,
    } = multiphysicsInterface;
    const interfaceName = getMultiphysicsInterfaceName(multiphysicsInterface, i);
    const formattedLabel = `Interface ${boldEscaped(interfaceName)}`;
    if (!surfaceIdsA.length || !surfaceIdsB.length) {
      validator.addError(interfaceId, `${formattedLabel} requires surfaces on both sides`);
      return;
    }

    const physicsA = getPhysicsContainingSurfaces(param, surfaceIdsA, geometryTags, staticVolumes);
    const physicsB = getPhysicsContainingSurfaces(param, surfaceIdsB, geometryTags, staticVolumes);

    const physicsIdsA = physicsA.map((item) => getPhysicsId(item));
    const physicsIdsB = physicsB.map((item) => getPhysicsId(item));

    if (physicsIdsA.length !== 1 || physicsIdsB.length !== 1 || physicsIdsA[0] === physicsIdsB[0]) {
      validator.addError(
        interfaceId,
        `Each side of ${formattedLabel} must have surfaces associated with exactly one ` +
        `unique physics`,
      );
    } else {
      const fluidMaterialA =
        findFluidPhysicsMaterial(param, physicsA[0], geometryTags, staticVolumes);
      const fluidMaterialB =
        findFluidPhysicsMaterial(param, physicsB[0], geometryTags, staticVolumes);

      const maybeAddConstantDensityError = (
        material: simulationpb.MaterialEntity | undefined,
        side: string,
      ) => {
        if (material && getMaterialFluid(material)?.densityRelationship === CONSTANT_DENSITY) {
          validator.addError(
            interfaceId,
            `Surfaces of ${side} in ${formattedLabel} cannot be assigned to a material with ` +
            `<b>Density Relationship -> Constant Density</b>.`,
          );
        }
      };
      maybeAddConstantDensityError(fluidMaterialA, 'Side A');
      maybeAddConstantDensityError(fluidMaterialB, 'Side B');
    }
  });
}

/** Validate any exploration variables */
function validateExploration(data: ValidationData, validator: SimulationValidator): void {
  const { config, param, paramScope, geometryTags, staticVolumes } = data;

  const { exploration } = config;

  // Validate policy
  if (exploration?.policy.case === 'custom') {
    const table = exploration.policy.value.table;
    if (!table) {
      validator.addError(
        POLICY_CONTAINER_ID,
        `Policy ${boldEscaped('Manual')}: A table must be uploaded and selected.`,
      );
    }
  }

  // Validate variables
  const userDefinedVars = exploration?.var.filter((variable) => !variable.synthetic);
  const variables = userDefinedVars?.filter((variable) => {
    const varSpec = variable.spec;
    return !!(varSpec && getProtoForType(param, varSpec.type, varSpec.id));
  });

  if (variables?.length) {
    variables.forEach((explorationVar, i) => {
      const id = explorationVariableNodeId(i);
      const name = getLabel(param, data.boundaryConditionNames, explorationVar.spec!);
      const formattedLabel = `Input ${boldEscaped(name)}`;

      if (explorationVar.valueTyp.case === 'range') {
        const range = explorationVar.valueTyp.value;
        const rangeMin = range.min;
        const rangeMax = range.max;
        if (range.max?.typ.case !== range.min?.typ.case) {
          validator.addError(
            id,
            `${formattedLabel}: Min and max values must be of the same value type.`,
          );
        }
        // If the exploration variable is defined via a min-max range, make sure that the min and
        // max are different. The number of samples cannot be less than 2, so the min and max cannot
        // be the same value. Some vars are vectors so its easier to compare them as formatted
        // values.
        const minStr = rangeMin ? extractFormattedValue(rangeMin) : undefined;
        const maxStr = rangeMax ? extractFormattedValue(rangeMax) : undefined;
        if (minStr === maxStr) {
          validator.addError(
            id,
            `${formattedLabel}: Min and max values should be different.`,
          );
        }
        // We only validate min < max for scalar values (Real or Integer)
        const minValue = extractScalarValue(rangeMin);
        const maxValue = extractScalarValue(rangeMax);
        if (minValue !== undefined && maxValue !== undefined && minValue >= maxValue) {
          validator.addError(
            id,
            `${formattedLabel}: Min value must be less than max value.`,
          );
        }
      } else if (explorationVar.valueTyp.case === 'enumerated') {
        // If the exploration variable is defined via a value list, make sure that the entries
        // within the value list are unique for the same reason as described above.
        const values = explorationVar.valueTyp.value.value.map(extractFormattedValue);
        const uniqueValues = new Set(values);
        if (values?.length !== uniqueValues.size) {
          validator.addError(id, `${formattedLabel}: Value entries must be unique.`);
        }
      } else if (explorationVar.valueTyp.case === 'column') {
        const columnVar = explorationVar.valueTyp.value;
        const values = columnVar?.value;
        if (!values?.length) {
          validator.addError(id, `${formattedLabel}: A column must be selected.`);
        }
        const varSpec = explorationVar.spec;
        const paramState = !!varSpec &&
          varSpecToParamState(varSpec, param, paramScope, geometryTags, staticVolumes);
        if (paramState && paramState.param.type === ProtoDescriptor.ParamType.VECTOR3) {
          if (
            columnVar.selectedColumns.case !== 'columnIndexes' ||
            columnVar.selectedColumns.value.x === 0n ||
            columnVar.selectedColumns.value.y === 0n ||
            columnVar.selectedColumns.value.z === 0n
          ) {
            validator.addError(id, `${formattedLabel}: All axes must be assigned to a column.`);
          }
        }
      }
    });
  } else {
    // There must be at least one input variable before running an exploration.
    validator.addError(VARIABLE_CONTAINER_ID, 'Add an input exploration variable');
  }
}

/** Validate a far-field if it exists */
function validateFarField(data: ValidationData, validator: SimulationValidator): void {
  const { cadModifier } = data;

  if (cadModifier && !isInitialized(cadModifier)) {
    validator.addError(FARFIELD_NODE_ID, 'Farfield settings not saved');
  }
}

/** Validate a visualization filter */
function validateFilter(filterNode: ParaviewRpc.TreeNode, validator: SimulationValidator): void {
  filterWarnings(filterNode.param, filterNode.name).forEach(
    (warning) => validator.addError(filterNode.id, warning),
  );

  filterNode.child.forEach((child) => {
    if (!isImposter(filterNode)) {
      validateFilter(child, validator);
    }
  });
}

/** Validate visualization filters */
function validateFilters(data: ValidationData, validator: SimulationValidator): void {
  const { viewState, filterStatus } = data;

  if (viewState) {
    validateFilter(viewState.root, validator);
  }
  filterStatus.forEach((value, id) => {
    if (value.error) {
      validator.addError(id, value.error);
    }
  });
}

/** Validate material settings */
function validateMaterials(data: ValidationData, validator: SimulationValidator): void {
  const { experimentConfig, param, paramScope, geometryTags } = data;

  if (!param.materialEntity.length) {
    validator.addError(MATERIAL_CONTAINER_NODE_ID, 'No material(s) configured');
  }

  if (param.materialEntity.length > 1 && param.materialEntity.every((entity) => {
    const materialId = getMaterialId(entity);
    return !getMaterialDomains(param, materialId, geometryTags).size;
  })) {
    validator.addError(
      MATERIAL_CONTAINER_NODE_ID,
      'At least one material must be associated with volumes',
    );
  }

  param.materialEntity.forEach((entity) => {
    const materialId = getMaterialId(entity);
    const formattedLabel = `Material ${boldEscaped(getMaterialName(entity, param))}`;

    const materialScope = createParamScope(entity, experimentConfig, paramScope);
    switch (entity.material.case) {
      case 'materialFluid': {
        validator.addErrors(
          materialId,
          paramGroupWarnings(paramGroupDesc[ParamGroupName.MaterialFluid], materialScope),
        );
        break;
      }
      case 'materialSolid': {
        validator.addErrors(
          materialId,
          paramGroupWarnings(paramGroupDesc[ParamGroupName.MaterialSolid], materialScope),
        );
        break;
      }
      default: {
        // no default
      }
    }

    if (!getMaterialDomains(param, materialId, geometryTags).size) {
      if (param.materialEntity.length > 1) {
        validator.addInfo(materialId, `${formattedLabel} is not associated with any volumes. This is
           only a warning and will not affect the simulation outcome.`);
      } else {
        validator.addError(materialId, `${formattedLabel} is not associated with any volumes`);
      }
    }
  });
}

/** The max number of surfaces to list in a duplicate surfaces error */
const MAX_DUPLICATE_SURFACES = 5;

/**
 * Validates that there are no duplicate surfaces in the Model and BL mesh params.
 */
export function getDuplicateSurfaceMeshErrors(
  multiPartMeshing: nullableMeshing,
  param: simulationpb.SimulationParam,
  entityGroupData: EntityGroupData,
): { boundary: string[], model: string[] } {
  if (!multiPartMeshing) {
    return { boundary: [], model: [] };
  }
  const { blParams, modelParams } = multiPartMeshing;
  const errors = { boundary: [] as string[], model: [] as string[] };

  const defaultBL = blParams[0];
  const BlSelectionType = MeshingMultiPart_BoundaryLayerParams_SelectionType;
  const blWallSurfacesSelected = defaultBL.selection === BlSelectionType.WALL_NO_SLIP;
  if (blWallSurfacesSelected && blParams.length > 1) {
    const noSlipWallSurfaces = new Set(getNonSlipWallSurfaces(param));
    blParams.forEach((blParam, index) => {
      if (index === 0) {
        return;
      }
      const { surfaces } = blParam;
      const invalidSurfaces = surfaces.filter(
        (surface) => noSlipWallSurfaces.has(surface) && entityGroupData.groupMap.has(surface),
      ).sort(compareAlphanumeric);
      if (invalidSurfaces.length) {
        const formattedNames = truncatedWordList(
          invalidSurfaces.map((surface) => boldEscaped(entityGroupData.groupMap.get(surface).name)),
          MAX_DUPLICATE_SURFACES,
        );
        errors.boundary.push(
          `<b>Boundary Layer ${index}</b> has ${invalidSurfaces.length} wall surfaces
            duplicated in the Default Boundary Layer: ${formattedNames}`,
        );
      }
    });
  }

  const defaultModel = modelParams[0];
  const ModelSelectionType = MeshingMultiPart_ModelParams_SelectionType;
  const modelWallSurfacesSelected = defaultModel.selection === ModelSelectionType.WALL;
  if (modelWallSurfacesSelected && modelParams.length > 1) {
    const wallSurfaces = new Set(getWallSurfaces(param));
    modelParams.forEach((modelParam, index) => {
      if (index === 0) {
        return;
      }
      const { surfaces } = modelParam;
      const invalidSurfaces = surfaces.filter(
        (surface) => wallSurfaces.has(surface) && entityGroupData.groupMap.has(surface),
      ).sort(compareAlphanumeric);
      if (invalidSurfaces.length) {
        const formattedNames = truncatedWordList(
          invalidSurfaces.map((surface) => boldEscaped(entityGroupData.groupMap.get(surface).name)),
          MAX_DUPLICATE_SURFACES,
        );
        errors.model.push(
          `<b>Model ${index}</b> has ${invalidSurfaces.length} wall surfaces
            duplicated in the Default Model: ${formattedNames}`,
        );
      }
    });
  }
  return errors;
}

/** Validate meshing configurations */
function validateMesh(data: ValidationData, validator: SimulationValidator): void {
  const { cadMetadata, meshUrl, multiPartMeshing, param } = data;
  const isLMACompatible = isSimLMACompatible(param);
  const meshMethod = param.adaptiveMeshRefinement?.meshingMethod;
  const isLMAActive = meshMethod === simulationpb.MeshingMethod.MESH_METHOD_AUTO;

  // Until we have a computational mesh, don't allow running the simulation.
  if (!meshUrl.mesh) {
    validator.addError(MESH_NODE_ID, 'Mesh must be generated before running simulation.');
  }

  if (!isLMACompatible && isLMAActive) {
    validator.addError(MESH_NODE_ID, UNSUPPORTED_LMA);
  }
  if (multiPartMeshing && !allVolumesAssigned(cadMetadata.nBodies, multiPartMeshing)) {
    validator.addError(SIZE_ID, 'Every volume should be assigned a mesh size.');
  }

  // Generate errors if there are duplicate surfaces in the meshing configuration
  const errors = getDuplicateSurfaceMeshErrors(
    multiPartMeshing,
    param,
    data.entityGroupData,
  );
  validator.addErrors(BOUNDARY_ID, errors.boundary);
  validator.addErrors(MODEL_ID, errors.model);
}

function planeHasValidBounds(
  monitorPlane: simulationpb.MonitorPlane,
  viewState: ParaviewRpc.ViewState,
): boolean {
  const imposter = findImposter(monitorPlane.monitorPlaneId, viewState.root);
  if (imposter?.bounds) {
    // Bounds are only valid if min <= max, so if the bounds are invalid, then the slice does not
    // intersect the volume.
    if (
      imposter.bounds[0] <= imposter.bounds[1] &&
      imposter.bounds[2] <= imposter.bounds[3] &&
      imposter.bounds[4] <= imposter.bounds[5]) {
      // Plane intersects volume
      return true;
    }
  }
  return false;
}

/** Validate monitor planes */
function validateMonitorPlanes(data: ValidationData, validator: SimulationValidator): void {
  const { lcvisEnabled, param, viewState } = data;

  param.monitorPlane.forEach((plane) => {
    const { monitorPlaneId: id, monitorPlaneName } = plane;
    const formattedLabel = `Monitor plane ${boldEscaped(monitorPlaneName)}`;
    // TODO: LC-16921. We do no validation for monitor planes while LCVis is enabled in geometry
    // setup. But once we have LCVis with meshes, we will need to validate the planes.
    if (viewState && !lcvisEnabled) {
      if (!planeHasValidBounds(plane, viewState)) {
        validator.addError(id, `${formattedLabel} must intersect the volume.`);
      }
    } else if (!lcvisEnabled) {
      validator.addError(
        id,
        `${formattedLabel}: Connection to visualizer required for validation.`,
      );
    }
  });
}

/** Validate motion */
function validateMotion(data: ValidationData, validator: SimulationValidator): void {
  const { param, entityGroupData, staticVolumes } = data;

  // Complain if motion (moving frames) is used with multi-physics or heat transfer physics
  const physics = param.physics;

  if (
    projectHasMovingFrames(param) &&
    (physics.length > 1 || physics.some(isPhysicsHeat))
  ) {
    validator.addError(GLOBAL_FRAME_NODE_ID, UNSUPPORTED_MULTIPHYS_MOTION);
  }

  const frames = orderedFrames(param).frames;
  frames.forEach((frame, i) => {
    if (i === 0) {
      validator.addErrors(
        GLOBAL_FRAME_NODE_ID,
        [
          ...generalMotionWarnings(param),
          ...commonFrameWarnings(
            frame,
            param,
            entityGroupData,
            staticVolumes,
            data.geometryTags,
          ),
        ],
      );
    } else {
      const { frameId, frameName } = frame;
      const formattedLabel = `Frame ${boldEscaped(frameName)}`;

      validator.addErrors(
        frameId,
        commonFrameWarnings(
          frame,
          param,
          entityGroupData,
          staticVolumes,
          data.geometryTags,
        ),
      );

      if (frameHasMotion(frame)) {
        const velocity = getFrameVelocity(frame);
        const displacement = getFrameDisplacement(frame);
        if (velocity && displacement && equalsZero(velocity) && equalsZero(displacement)) {
          validator.addError(frameId, `${formattedLabel} cannot have zero-velocity motion`);
        }

        if (!frameHasGeometryInBranch(frame, param)) {
          validator.addError(
            frameId,
            `${formattedLabel} or child frames have no assigned geometry.`,
          );
        }
      }
    }
  });
}

/** Validate outputs */
function validateOutputs(data: ValidationData, validator: SimulationValidator): void {
  const {
    entityGroupData,
    isSensitivityAnalysis,
    outputNodes,
    param,
    paramScope,
    staticVolumes,
    geometryTags,
  } = data;

  const outputs = outputNodes.nodes;

  if (isSensitivityAnalysis) {
    const hasValidNodes = outputs.some(
      (output) => !disabledOutputCategories.includes(output.nodeProps.case),
    );
    if (!hasValidNodes) {
      validator.addError(
        OUTPUT_CONTAINER_ID,
        'Sensitivity Analysis should have at least one output other than Residuals.',
      );
    }
  }

  if (
    outputNodes.referenceValues?.referenceValueType === REFERENCE_FARFIELD_VALUES &&
    !findFarfield(param)
  ) {
    validator.addError(
      OUTPUT_REFERENCE_VALUE_NODE_ID,
      'Invalid Reference Value selection: No Far-field boundary found.',
    );
  }

  const porousModels = getAllPorousBehaviors(param);
  outputs.forEach((output) => {
    const { id } = output;
    validator.addErrors(
      id,
      getOutputNodeWarnings(
        output,
        outputNodes,
        param,
        entityGroupData,
        paramScope,
        staticVolumes,
        geometryTags,
        outputNodes.referenceValues?.referenceValueType,
      ),
    );
    validator.addWarnings(
      id,
      getOutputForcesWarning(
        param,
        output,
        porousModels,
        entityGroupData,
        staticVolumes,
        geometryTags,
      ),
    );
  });
}

/** Validate particle groups (actuator disks) */
function validateParticleGroups(data: ValidationData, validator: SimulationValidator): void {
  const { param } = data;

  param.particleGroup.forEach((particleGroup) => {
    const {
      particleGroupId: id,
      particleGroupName,
      particleGroupType,
      actuatorDiskOrientationSelection: orientation,
      actuatorDiskNormalVector: normal,
      actuatorDiskInnerRadius: adInnerRadius,
      actuatorDiskOuterRadius: adOuterRadius,
      particleGroupBehaviorModelRef: behaviorId,
    } = particleGroup;

    const typeLabel = particleGroupTypeLabel(particleGroupType ?? INVALID_PARTICLE_GROUP_TYPE);
    const displayLabel = upperFirst((typeLabel || 'particle group').toLocaleLowerCase());
    const formattedLabel = `${displayLabel} ${boldEscaped(particleGroupName)}`;

    const innerRadius = getAdValue(adInnerRadius) || 0;
    const outerRadius = getAdValue(adOuterRadius) || 0;

    if (innerRadius > outerRadius) {
      validator.addError(id, `${formattedLabel} inner radius cannot be greater than outer radius.`);
    }

    if (orientation === ACTUATOR_DISK_SPECIFY_NORMAL_VECTOR) {
      if (!normal || equalsZero(normal)) {
        validator.addError(id, `${formattedLabel} normal must be defined with magnitude > 0.`);
      }
    }

    if (!(behaviorId && findPhysicalBehaviorById(param, behaviorId))) {
      // Add a warning for particle groups not attached to a physical behavior model.
      validator.addError(
        id,
        `${formattedLabel} must be assigned to a valid ${PHYSICAL_BEHAVIOR_LABEL}.`,
      );
    }

    // Actuator disks are not compatible with unsteady moving grid simulations.
    if (unsteadyMotionConflict(param, data.geometryTags)) {
      validator.addError(id, 'Disk models cannot be used with unsteady moving grid simulations.');
    }
  });
}

/** Validate physics */
function validatePhysics(data: ValidationData, validator: SimulationValidator) {
  const {
    experimentConfig,
    param,
    paramScope,
    staticVolumes,
    geometryTags,
    entityGroupData,
  } = data;

  const allPhysics = param.physics;
  const volumesByDomain = mapByDomains(staticVolumes);

  if (!allPhysics.length && param.materialEntity.length) {
    validator.addError(PHYSICS_CONTAINER_NODE_ID, 'No physics configured');
  }

  if (allPhysics.length > MAX_PHYSICS) {
    validator.addError(
      PHYSICS_CONTAINER_NODE_ID,
      `Only ${MAX_PHYSICS} or fewer physics are supported`,
    );
  }

  const typeCounts = physicsTypeCount(param);
  if (typeCounts.get('heat')! > MAX_HEAT_PHYSICS) {
    validator.addError(
      PHYSICS_CONTAINER_NODE_ID,
      `Only ${MAX_HEAT_PHYSICS} or fewer heat transfer physics are supported`,
    );
  }

  const hasExistingSolution = existingSolutionChoiceInParam(param);
  const allowDifferentMeshes = experimentConfig.includes(flags.solutionInterpolation);

  allPhysics.forEach((physics) => {
    const subPhysics = getSubPhysics(physics);
    const physicsId = getPhysicsId(physics);
    const initType = getPhysicsInitialization(physics)?.initializationType;
    const physicsLabel = `Physics ${boldEscaped(getPhysicsName(physics, param))}`;
    const domains = getPhysicsDomains(param, physicsId, geometryTags, staticVolumes);
    const hasVolumes = !!domains.size;
    const volumeSelectionId = makeVolumeSelectionId(physicsId);
    const availableSurfaces =
      new Set(getAvailableSurfaces(param, physicsId, geometryTags, staticVolumes));
    const materials = getPhysicsMaterials(param, physics, geometryTags, staticVolumes);

    if (hasVolumes) {
      checkPeriodicPairs(
        validator,
        subPhysics?.periodicPair || [],
        physicsId,
        availableSurfaces,
        geometryTags,
        entityGroupData,
      );
      checkSlidingInterfaces(
        validator,
        subPhysics?.slidingInterfaces || [],
        staticVolumes,
        availableSurfaces,
        geometryTags,
        entityGroupData,
      );
      const hasInvalidDomains = [...domains].every((domain) => !volumesByDomain[domain]);
      if (hasInvalidDomains) {
        validator.addError(volumeSelectionId, `${physicsLabel} has invalid volume references`);
      }
    } else {
      validator.addError(volumeSelectionId, `${physicsLabel} has no volumes`);
    }

    switch (physics.params.case) {
      case 'fluid': {
        const material = findFluidPhysicsMaterial(data.param, physics, geometryTags, staticVolumes);
        const physicsScope = chainParamScopes([material, physics], experimentConfig, paramScope);
        validator.addErrors(
          physicsId,
          paramGroupWarnings(paramGroupDesc[ParamGroupName.SolutionControlsFluid], physicsScope),
        );
        validator.addErrors(
          physicsId,
          paramGroupWarnings(
            paramGroupDesc[ParamGroupName.SpatialDiscretizationFluid],
            physicsScope,
          ),
        );
        validator.addErrors(
          physicsId,
          paramGroupWarnings(paramGroupDesc[ParamGroupName.Turbulence], physicsScope),
        );
        if (hasVolumes) {
          const fluid = physics.params.value;
          checkPhysicalBehaviors(validator, fluid.physicalBehavior || []);
          checkPorousModels(validator, fluid.porousBehavior || [], param);
          if (materials.length !== 1 || !isMaterialFluid(materials[0])) {
            validator.addError(
              physicsId,
              `${physicsLabel} must be associated with exactly one fluid material through its
               volume selections.`,
            );
          }
        }
        checkInitializationSettings(
          data,
          validator,
          initType,
          param.general?.flowBehavior,
          physics,
          physicsScope,
          [...fluidConstraints, ...meshConstraints(allowDifferentMeshes)],
          hasExistingSolution,
        );
        break;
      }
      case 'heat': {
        const physicsScope = chainParamScopes([physics], experimentConfig, paramScope);
        validator.addErrors(
          physicsId,
          paramGroupWarnings(paramGroupDesc[ParamGroupName.SolutionControlsHeat], physicsScope),
        );
        validator.addErrors(
          physicsId,
          paramGroupWarnings(
            paramGroupDesc[ParamGroupName.SpatialDiscretizationHeat],
            physicsScope,
          ),
        );
        if (hasVolumes) {
          checkHeatSources(
            validator,
            physics.params.value.heatSource || [],
            physicsId,
            param,
            volumesByDomain,
            physicsScope,
            experimentConfig,
            geometryTags,
            staticVolumes,
          );
          if (!materials.length || !materials.every(isMaterialSolid)) {
            validator.addError(
              physicsId,
              `${physicsLabel} must be associated with one or more solid materials through its
               volume selections.`,
            );
          }
        }
        checkInitializationSettings(
          data,
          validator,
          initType,
          param.general?.flowBehavior,
          physics,
          physicsScope,
          meshConstraints(experimentConfig.includes(flags.solutionInterpolation)),
          hasExistingSolution,
        );
        break;
      }
      default: {
        // no default
      }
    }
  });
}

/** Validate monitor points */
function validateProbePoints(data: ValidationData, validator: SimulationValidator): void {
  const { param } = data;

  // Probe points are not compatible with unsteady moving grid simulations.
  if (unsteadyMotionConflict(param, data.geometryTags)) {
    getProbePoints(param).forEach((point) => {
      validator.addWarning(
        point.id,
        'Warning, placing monitor points inside moving volumes of unsteady moving grid ' +
        'simulations will result in a validation error after the solver starts. ' +
        'Please ensure that the volumes that contain monitor points are stationary.',
      );
    });
  }
}

/** Validate refinement regions */
function validateRefinementRegions(data: ValidationData, validator: SimulationValidator): void {
  const { multiPartMeshing } = data;

  if (multiPartMeshing) {
    const { maxSize, minSize } = getHLimitBounds(multiPartMeshing);
    multiPartMeshing.refinementParams.forEach((refinementParam) => {
      validator.addErrors(
        refinementParam.id,
        getRefinementRegionWarnings(refinementParam, maxSize, minSize),
      );
    });
  }
}

export function computeOutputWeight(
  output: outputpb.Output,
  conditions: outputpb.StoppingCondition[],
): number {
  let output_weight = 0.0;
  // Dependent outputs do not have a surface count and need to be handled specially.
  if (output.outputProperties.case === 'derivedProperties') {
    // Check to see if dependent outputs are also present in the provided request. Make sure to not
    // double count them.
    const dt = output.outputProperties.value;
    dt.dependencies.forEach((dependency) => {
      const found = conditions.some((cond) => dependency.id === cond?.output?.id);
      if (!found) {
        output_weight += computeOutputWeight(dependency, conditions);
      }
    });
    output_weight *= output.avgIters;
  } else {
    output_weight = output.avgIters * output.inSurfaces.length;
  }
  return output_weight;
}

/** Validate stopping condition settings */
function validateStoppingConditionSettings(
  data: ValidationData,
  validator: SimulationValidator,
): void {
  const { entityGroupData, outputNodes, param, stoppingConditions } = data;

  const maxIters = stoppingConditions.maxIterations;
  const maxInnerIters = stoppingConditions.maxInnerIterations;

  // Check for consistency wrt to the number of iterations and inner iterations.
  const transient = isSimulationTransient(param);
  const implicitTime = transient && isSimulationImplicitTime(param);

  // Inner iterations checks only matter for unsteady + implicit relaxation simulations. Explicit
  // relaxation method do not use inner iterations. Note that we only support implicit time +
  // implicit relaxation methods in the UI.
  if (implicitTime && maxInnerIters <= 0) {
    validator.addError(
      STOPPING_CONDITIONS_NODE_ID,
      'Stopping condition Max Inner Iterations must be greater than 0',
    );
  }
  if (implicitTime && maxInnerIters > MAX_STOPPING_COND_INNER_ITERATIONS) {
    validator.addError(
      STOPPING_CONDITIONS_NODE_ID,
      `Stopping condition Max Inner Iterations must be less than
        ${MAX_STOPPING_COND_INNER_ITERATIONS}`,
    );
  }

  // Global iterations checks apply for all simulations.
  if (maxIters <= 0) {
    validator.addError(
      STOPPING_CONDITIONS_NODE_ID,
      'Stopping condition Max Iterations must be greater than 0',
    );
  }
  if (maxIters > MAX_STOPPING_COND_ITERATIONS) {
    validator.addError(
      STOPPING_CONDITIONS_NODE_ID,
      `Stopping condition Max Iterations must be less than ${MAX_STOPPING_COND_ITERATIONS}`,
    );
  }

  // Stopping conditions start at and averaging ranges checks.
  stoppingConditions.cond?.forEach((cond, i) => {
    const formattedLabel = boldEscaped(`${i + 1}`);

    // Do not allow stopping conditions defined on Per Surface outputs when there is more than one
    // surface.
    if (cond.node?.calcType === feoutputpb.CalculationType.CALCULATION_PER_SURFACE &&
      cond.node?.inSurfaces.length > 1) {
      validator.addError(
        STOPPING_CONDITIONS_NODE_ID,
        `Stopping condition ${formattedLabel}: "Calculation: Per Surface" outputs are not
         supported.`,
      );
    }

    if (!transient) {
      // For steady cases, we check the start iteration against the max iterations
      // and the max iterations against the averaging parameters.
      if (cond.startAt >= maxIters) {
        validator.addError(
          STOPPING_CONDITIONS_NODE_ID,
          `Stopping condition ${formattedLabel}: Start Iteration must be less than Max Iterations`,
        );
      }
      if (cond.nIterations + cond.nAnalysisIters >= maxIters) {
        validator.addError(
          STOPPING_CONDITIONS_NODE_ID,
          `Stopping condition ${formattedLabel}: Averaging Iterations + Iterations to Consider
            must be less than Max Iterations`,
        );
      }
    } else if (implicitTime) {
      // Check averaging specification for stopping conditions wrt to the inner iterations.
      // This only applies to unsteady + implicit relaxation methods which use the averaging
      // method to assess the stopping conditions in the inner iterations. Note that we only
      // support implicit time + implicit relaxation methods in the UI. Implicit time + explicit
      // relaxation would require other checks.
      if (cond.startAt >= maxInnerIters) {
        validator.addError(
          STOPPING_CONDITIONS_NODE_ID,
          `Stopping condition ${formattedLabel}: Start Iteration must be less than Max Inner
            Iterations`,
        );
      }
      if (cond.nIterations + cond.nAnalysisIters >= maxInnerIters) {
        validator.addError(
          STOPPING_CONDITIONS_NODE_ID,
          `Stopping condition ${formattedLabel}: Averaging Iterations + Iterations to Consider
            must be less than Max Inner Iterations`,
        );
      }
    }
  });

  // Do not allow users to run with very large stopping conditions. This can be very slow and end
  // up wasting resources for the user without them knowing.
  const stopping_param = updateStoppingConds(
    stoppingConditions,
    param,
    outputNodes,
    entityGroupData,
  );
  const convCriteria = getOrCreateConvergenceCriteria(stopping_param);
  let totalWeight = 0;
  convCriteria.stoppingCondition.forEach((cond) => {
    if (cond.output) {
      const output = cond.output;
      const output_weight = computeOutputWeight(output, convCriteria.stoppingCondition);
      totalWeight += output_weight;
    }
  });
  if (totalWeight / MAX_STOPPING_COND_WEIGHT > 1.0) {
    validator.addWarning(STOPPING_CONDITIONS_NODE_ID, `Stopping conditions reference outputs with
        too many surfaces or averaging iterations.`);
  }
}

/** Validate surfaces */
function validateSurfaces(data: ValidationData, validator: SimulationValidator): void {
  const { entityGroupData, geometryHealth, param, staticVolumes, geometryTags } = data;

  const surfacesInDefinedBcs = new Set<string>();

  const unrollTag = (surface: string) => {
    const tag = geometryTags.surfacesFromTagEntityGroupId(surface);
    if (tag?.length) {
      tag.forEach((surf) => surfacesInDefinedBcs.add(surf));
    } else {
      surfacesInDefinedBcs.add(surface);
    }
  };
  param.physics.forEach((physics) => {
    getFluid(physics)?.boundaryConditionsFluid.forEach((bc) => {
      if (!isUndefinedBoundaryCondition(bc)) {
        bc.surfaces.forEach((surface) => unrollTag(surface));
      }
    });
    getHeat(physics)?.boundaryConditionsHeat.forEach((bc) => {
      if (bc.heatPhysicalBoundary !== INVALID_HEAT_PHYSICAL_BOUNDARY) {
        bc.surfaces.forEach((surface) => unrollTag(surface));
      }
    });
  });

  const hasIssues = geometryHealth?.issues.some((issue) => (issue.level === ERROR));

  const annotateByVolume = staticVolumes.length > 1;

  staticVolumes.forEach((volume) => {
    const physics = findPhysicsByDomain(param, volume.domain, geometryTags);
    const volumeName = getVolumeName(volume.id, staticVolumes, entityGroupData.groupMap);

    volume.bounds.forEach((surfaceId) => {
      const surfaceName = entityGroupData.groupMap.has(surfaceId) ?
        entityGroupData.groupMap.get(surfaceId).name :
        surfaceId;
      const formattedSurface = `Surface ${boldEscaped(surfaceName)}`;
      const formattedLabel = annotateByVolume ?
        `${formattedSurface} in ${boldEscaped(volumeName)}` :
        formattedSurface;

      if (hasIssues) {
        // If there are any geometry health issues, those issues are displayed as a red warning dot.
        const relatedIssue = geometryHealth?.issues.find((issue) => (
          (issue.level === ERROR) &&
          getNodeIds(issue).includes(surfaceId)
        ));
        if (relatedIssue) {
          validator.addError(surfaceId, getTitle(relatedIssue));
        }
      } else if (
        // Otherwise, if the volume is assigned to a physics, we consider if the surface has a
        // boundary condition, periodic pair, or sliding interface
        physics &&
        !hasPeriodicPair(param, surfaceId, entityGroupData.groupMap) &&
        !findSlidingInterfaceBySurfaceId(param, surfaceId) &&
        !surfacesInDefinedBcs.has(surfaceId)
      ) {
        validator.addError(
          surfaceId,
          `${formattedLabel} must be assigned to a boundary condition.`,
        );
      }
    });
  });
}

/** Validate general settings */
function validateGeneralSettings(data: ValidationData, validator: SimulationValidator): void {
  validator.addErrors(
    GENERAL_SETTINGS_NODE_ID,
    paramGroupWarnings(paramGroupDesc[ParamGroupName.BasicFluid], data.paramScope),
  );
  validator.addErrors(
    GENERAL_SETTINGS_NODE_ID,
    paramGroupWarnings(paramGroupDesc[ParamGroupName.Time], data.paramScope),
  );
  validator.addErrors(
    GENERAL_SETTINGS_NODE_ID,
    paramGroupWarnings(paramGroupDesc[ParamGroupName.Output], data.paramScope),
  );
}

/** Validate adjoint settings */
function validateAdjoint(data: ValidationData, validator: SimulationValidator): void {
  const { outputNodes, param } = data;
  if (param.general?.floatType !== simulationpb.FloatType.ADA1D) {
    return;
  }

  const outputs = outputNodes.nodes;
  if (!param.adjoint?.adjointOutput?.id.length ||
    !outputs.some((output) => output.id === param.adjoint!.adjointOutput!.id)) {
    validator.addError(GENERAL_SETTINGS_NODE_ID, 'The adjoint output is not defined.');
  }
}

/** Validate volumes */
function validateVolumes(data: ValidationData, validator: SimulationValidator): void {
  const { entityGroupData, geometryPending, meshReady, param, staticVolumes, geometryTags } = data;

  const working = !meshReady || geometryPending;

  const porousModelsByDomain: Record<string, string[]> = {};
  param.physics.forEach((physics) => {
    getFluid(physics)?.porousBehavior.forEach((model) => {
      model.zoneIds.forEach((domain) => {
        porousModelsByDomain[domain] = porousModelsByDomain[domain] || [];
        porousModelsByDomain[domain].push(model.porousBehaviorName);
      });
    });
  });

  staticVolumes.forEach((volume) => {
    const { defaultName, domain, id } = volume;
    const name = entityGroupData.groupMap.has(id) ? entityGroupData.groupMap.get(id).name : '';
    const formattedLabel = `Volume ${boldEscaped(name || defaultName)}`;

    // If a volume is assigned to multiple porous models, show a warning.  We prevent this in the
    // front end, but there's nothing in the data design to prevent a user from, say, uploading a
    // JSON file with this error, so we should double check.
    const porousModels = porousModelsByDomain[domain] || [];
    if (porousModels.length > 1) {
      validator.addError(
        id,
        `${formattedLabel} is assigned to multiple porous models:
           ${wordsToList(porousModels.map((modelName) => boldEscaped(modelName)))}`,
      );
    }

    if (!working) {
      const materialId = findMaterialIdByDomain(param, domain, geometryTags);
      if (materialId) {
        const material = findMaterialEntityById(param, materialId);
        if (material) {
          const physicsId = findPhysicsIdByDomain(param, domain, geometryTags);
          if (physicsId) {
            const physics = findPhysicsById(param, physicsId);
            if (!physics) {
              validator.addError(id, `${formattedLabel} has invalid physics reference`);
            }
          } else {
            validator.addError(id, `${formattedLabel} must be assigned to a physics`);
          }
        } else {
          validator.addError(id, `${formattedLabel} has invalid material reference`);
        }
      } else {
        validator.addError(id, `${formattedLabel} must be assigned to a material`);
      }
    }
  });
}

// Inserts the geometry contacts LCStatus into the respective node ids. We use warnings because
// geometry contacts issues should not stop users from launching simulations.
function validateGeometryContacts(data: ValidationData, validator: SimulationValidator): void {
  const { entityGroupData, geometryContacts } = data;

  const contactNamesById: Record<string, string> = {};
  geometryContacts.contacts.forEach((contact, i) => {
    contactNamesById[contact.id] = `Contact ${i + 1}`;
  });

  geometryContacts.issues.forEach((issue) => {
    if (issue.code === codespb.Code.GEO_CONTACT_NEEDS_IMPRINT) {
      const details = unpackProto(issue.details, geometrypb.GeoContactNeedsImprintDetails);
      const id = details?.geometryContactId!;
      const name = contactNamesById[id];
      const formattedLabel = name ? `${boldEscaped(name)}` : 'Geometry contact';

      validator.addWarning(
        id,
        `${formattedLabel} is not conformal. The geometry bodies involved in the contact may
         require imprinting.`,
      );
    } else if (issue.code === codespb.Code.GEO_FACE_NEEDS_IMPRINT) {
      const details = unpackProto(issue.details, geometrypb.GeoFaceNeedsImprintDetails);
      const surfaceId = details?.surfaceId!;
      const surfaceName = entityGroupData.groupMap.has(surfaceId) ?
        entityGroupData.groupMap.get(surfaceId).name :
        removeBoundsPrefix(surfaceId);
      validator.addWarning(
        surfaceId!,
        `${boldEscaped(surfaceName)} appears in multiple contacts. The face has to be imprinted
         on the opposite body.`,
      );
    }
  });

  if (geometryContacts.operationError) {
    validator.addWarning(
      GEOMETRY_CONTACTS_CONTAINER_ID,
      'An internal error happened while computing the geometry contacts. Please contact support.',
    );
  }
}

/** Validate the simulation data */
export function validateSimulation(data: ValidationData) {
  const validator = new SimulationValidator();

  validateBoundaryConditions(data, validator);
  validateExploration(data, validator);
  validateFarField(data, validator);
  validateFilters(data, validator);
  validateGeneralSettings(data, validator);
  validateAdjoint(data, validator);
  validateGeometryContacts(data, validator);
  validateMaterials(data, validator);
  validateMesh(data, validator);
  validateMonitorPlanes(data, validator);
  validateMotion(data, validator);
  validateMultiphysicsInterfaces(data, validator);
  validateOutputs(data, validator);
  validateParticleGroups(data, validator);
  validatePhysics(data, validator);
  validateProbePoints(data, validator);
  validateRefinementRegions(data, validator);
  validateStoppingConditionSettings(data, validator);
  validateSurfaces(data, validator);
  validateVolumes(data, validator);

  return validator;
}

export function getNodeMessages(
  validator: SimulationValidator,
  node: SimulationTreeNode,
  minChildRank = 0,
): LeveledMessage[] {
  const messages: LeveledMessage[] = [...validator.getMessages(node.id)];

  node.children.forEach((child) => {
    const childMessages = getNodeMessages(validator, child, minChildRank).filter(
      ({ level }) => levelToRank(level) >= minChildRank,
    );

    messages.push(...childMessages);
  });

  return messages;
}

function getMessageKey(msg: LeveledMessage): string {
  return `${msg.level}:${msg.message}`;
}

export function getAllNodeMessages(
  validator: SimulationValidator,
  root: SimulationTreeNode,
  minChildRank = levelToRank('warning'),
): Map<string, LeveledMessage[]> {
  const result = new Map<string, LeveledMessage[]>();

  function traverse(node: SimulationTreeNode): Map<string, LeveledMessage> {
    const nodeMessages = validator.getMessages(node.id).filter(
      ({ level }) => levelToRank(level) >= minChildRank,
    );

    const allMessagesMap = new Map<string, LeveledMessage>();

    // Add this node's messages
    nodeMessages.forEach((msg) => {
      const key = getMessageKey(msg);
      allMessagesMap.set(key, msg);
    });

    // Recursively process children and merge their messages
    node.children.forEach((child) => {
      const childMessages = traverse(child);
      childMessages.forEach((msg, key) => {
        if (!allMessagesMap.has(key)) {
          allMessagesMap.set(key, msg);
        }
      });
    });

    // Store the result for this node
    if (allMessagesMap.size > 0) {
      result.set(node.id, Array.from(allMessagesMap.values()));
    }

    return allMessagesMap;
  }

  traverse(root);

  return result;
}

export function getValidatorMessageByIdAndLevel(
  id: string,
  validator: SimulationValidator,
  minLevel: Level,
) {
  const refLevel = levelToRank(minLevel);
  return validator.getMessages(id).filter(({ level }) => levelToRank(level) >= refLevel);
}
