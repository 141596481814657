// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as basepb from '../proto/base/base_pb';
import * as shapepb from '../proto/cad/shape_pb';
import * as cadmetadatapb from '../proto/cadmetadata/cadmetadata_pb';
import * as simulationpb from '../proto/client/simulation_pb';
import { EntityType } from '../proto/entitygroup/entitygroup_pb';
import * as meshgenerationpb from '../proto/meshgeneration/meshgeneration_pb';
import { CadModifier } from '../recoil/useCadModifier';

import { add, equal, magnitude, mult, newProto, subtract } from './Vector';
import { getBoundaryConditionsByPhysics } from './boundaryConditionUtils';
import { EntityGroupMap } from './entityGroupMap';

export const FARFIELD_NAME = 'Far-Field';
export const FARFIELD_NODE_ID = 'Generated Far-Field';
export const FARFIELD_ID = 'farfield';
export const FARFIELD_PREFIX = 'Farfield/'; // Temporary solution until LC-8632

export const isFarfield = (groupId: string) => groupId === FARFIELD_ID;
export const isFarfieldChild = (groupId: string, entityGroups: EntityGroupMap) => (
  !!entityGroups.findAncestor(groupId, (group) => isFarfield(group.id))
);

// The default radius of the farfield is equal to the diagonal of the bounding box times this
// constant.
export const DEFAULT_FARFIELD_SCALE = 20;

// Groups farfield related surfaces/groups together into common group
export function createFarfieldGroups(geomEntityGroups: EntityGroupMap) {
  // TODO(LC-7261): find a better way of identifiyng farfield related groups.
  const farfieldGroups = geomEntityGroups.getGroups(
    (group) => group.name.startsWith(FARFIELD_PREFIX),
  );
  if (!farfieldGroups.length) {
    return geomEntityGroups;
  }
  const newGroups = new EntityGroupMap(geomEntityGroups);
  newGroups.getGroups().forEach((group) => {
    group.entityType = group.entityType !== EntityType.INVALID_ENTITY_TYPE ?
      EntityType.SURFACE : group.entityType;
    group.name = group.name.replace(FARFIELD_PREFIX, '');
  });
  newGroups.group(FARFIELD_NAME, farfieldGroups.map((group) => group.id), FARFIELD_ID);
  return newGroups;
}

export function validateFarfield(cadModifier: CadModifier): string[] {
  const errors: string[] = [];
  switch (cadModifier?.farField.case) {
    case 'sphere':
      if (cadModifier.farField.value.radius <= 0) {
        errors.push('Radius must be larger than 0.');
      }
      break;
    case 'cylinder': {
      const cylinder = cadModifier.farField.value;
      if (cylinder.radius <= 0) {
        errors.push('Radius must be larger than 0.');
      }
      const { start, end } = cylinder;
      if (start && end && equal(end, start)) {
        errors.push('Start and end must be different.');
      }
      break;
    }
    case 'halfSphere': {
      const halfSphere = cadModifier.farField.value;
      if (halfSphere.radius <= 0) {
        errors.push('Radius must be larger than 0.');
      }
      if (magnitude(halfSphere.normal!) <= 0) {
        errors.push('Normal vector must be non-zero.');
      }
      break;
    }
    case 'cube':
      break;
    default:
      Error('Undefined farfield case');
  }
  return errors;
}

export function isInitialized(cadModifier: CadModifier): boolean {
  return !!cadModifier?.farField.case;
}

// Compute the center point between two vectors.
function centerPoint(a: basepb.Vector3, b: basepb.Vector3) {
  return mult(add(a, b), 0.5);
}

// Find the center of a box.
export function boxCenter(box?: cadmetadatapb.Box) {
  const min = box?.min;
  const max = box?.max;
  if (min && max) {
    return centerPoint(min, max);
  }
  return newProto(0, 0, 0);
}

// Returns the length between two opposite diagonal corners of the box.
export function diagonalLength(box?: cadmetadatapb.Box) {
  const min = box?.min;
  const max = box?.max;
  if (min && max) {
    return magnitude(subtract(max, min));
  }
  return 1;
}

// Returns a cube with the given width and center point.
export function defaultCube(center: basepb.Vector3, width: number) {
  const offset = newProto(width / 2, width / 2, width / 2);
  const min = subtract(center, offset);
  const max = add(center, offset);
  return new shapepb.Cube({ min, max });
}

// Returns a cylinder with the given center point and radius. The cylinder is oriented along the
// z-axis with the height twice the radius.
export function defaultCylinder(center: basepb.Vector3, radius: number) {
  const offset = newProto(0, 0, radius);
  const start = add(center, offset);
  const end = subtract(center, offset);
  return new shapepb.Cylinder({ start, end, radius });
}

// Find the radius or width of the current modifier.
export function getRadius(modifier: meshgenerationpb.UserGeometryMod) {
  switch (modifier.farField.case) {
    case 'sphere':
    case 'halfSphere':
      return modifier.farField.value.radius;
    case 'cube': {
      // For a cube, return half the width of the largest dimension.
      const { min, max } = modifier.farField.value;
      if (min && max) {
        const size = subtract(max, min);
        return Math.max(size.x, size.y, size.z) / 2;
      }
      break;
    }
    case 'cylinder': {
      // For a cylinder, return either half the height or the radius, whichever is greater.
      const { start, end, radius } = modifier.farField.value;
      if (start && end) {
        const height = magnitude(subtract(end, start));
        return Math.max(height / 2, radius);
      }
      break;
    }
    default:
      break;
  }
  return 1;
}

// Scale the shape in the modifier up or down from the center of the shape.
export function scaleModifier(scale: number, modifier: meshgenerationpb.UserGeometryMod) {
  switch (modifier.farField.case) {
    case 'sphere': {
      const sphere = modifier.farField.value;
      sphere.radius *= scale;
      break;
    }
    case 'halfSphere': {
      const halfSphere = modifier.farField.value;
      halfSphere.radius *= scale;
      break;
    }
    case 'cube': {
      const cube = modifier.farField.value;
      const { min, max } = cube;
      if (min && max) {
        // Scale the min and max from the center point.
        const center = centerPoint(min, max);
        const offset = mult(subtract(max, center), scale);
        cube.max = add(center, offset);
        cube.min = subtract(center, offset);
      }
      break;
    }
    case 'cylinder': {
      const cylinder = modifier.farField.value;
      const { start, end } = cylinder;
      if (start && end) {
        // Scale the start and end from the center point.
        const center = centerPoint(start, end);
        const offset = mult(subtract(start, center), scale);
        cylinder.start = add(center, offset);
        cylinder.end = subtract(center, offset);
        cylinder.radius *= scale;
      }
      break;
    }
    default:
      break;
  }
  return modifier;
}

/**
 * If the project contains groups or boundary conditions, when a farfield is modified or deleted,
 * then those groups and boundary conditions will be removed.
 *
 * @returns true if modifying / deleting a farfield will cause destructive changes to the project
 */
export function willChangeBeDestructive(
  simParam: simulationpb.SimulationParam,
  entityGroupMap: EntityGroupMap,
): boolean {
  const hasBCs = simParam.physics.some((physics) => getBoundaryConditionsByPhysics(physics).length);
  const existingGroups = entityGroupMap.getGroups(
    (group) => group.children.size > 0 && group.parentId !== 'root',
  );
  return hasBCs || existingGroups.length > 0;
}

// Confirmation dialog base settings when adding/deleting a farfield
export const FARFIELD_CHANGE_BASE_DIALOG = {
  title: 'This will reset all currently defined surface groups and boundary conditions',
  subtitle: 'Are you sure you want to continue?',
  destructive: true,
};
