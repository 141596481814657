// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 21;
export const HorizontalLinesQuadrupleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="horizontalLinesQuadruple"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M1.5,3
           L22.5,3
           C23.325,3 24,2.325 24,1.5
           C24,0.675 23.325,0 22.5,0
           L1.5,0
           C0.675,0 0,0.675 0,1.5
           C0,2.325 0.675,3 1.5,3Z
           M22.5,18
           L1.5,18
           C0.675,18 0,18.675 0,19.5
           C0,20.325 0.675,21 1.5,21
           L22.5,21
           C23.325,21 24,20.325 24,19.5
           C24,18.675 23.325,18 22.5,18Z
           M22.5,12
           L1.5,12
           C0.675,12 0,12.675 0,13.5
           C0,14.325 0.675,15 1.5,15
           L22.5,15
           C23.325,15 24,14.325 24,13.5
           C24,12.675 23.325,12 22.5,12Z
           M22.5,6
           L1.5,6
           C0.675,6 0,6.675 0,7.5
           C0,8.325 0.675,9 1.5,9
           L22.5,9
           C23.325,9 24,8.325 24,7.5
           C24,6.675 23.325,6 22.5,6Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
