// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import * as Vector from '../../../../lib/Vector';
import { Logger } from '../../../../lib/observability/logs';
import { addRpcError, addWarning } from '../../../../lib/transientNotification';
import { EditSource, visComputeFilterWrapper } from '../../../../lib/visUtils';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useEditState } from '../../../../recoil/paraviewState';
import Form from '../../../Form';
import CheckBox from '../../../Form/CheckBox';
import { Vector3Input } from '../../../Form/Vector3Input';
import { NumberSpinner } from '../../../NumberSpinner';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import Divider from '../../../Theme/Divider';
import { SimpleSlider } from '../../../controls/slider/SimpleSlider';
import { PlaneInput, PlaneParam } from '../../../visFilter/PlaneInput';
import { useSelectedFilterNode } from '../../../visFilter/useFilterNode';
import { FilterEditControl } from '../../FilterEditControl';
import PropertiesSection from '../../PropertiesSection';
import { CommonFilterMessages } from '../shared/CommonFilterMessages';
import { FilterDisplayPanel } from '../shared/FilterDisplayPanel';

import { FilterPropertiesPanelProps } from './props';

const logger = new Logger('filter/MultiSlice');

function getDefaultStart(bounds: ParaviewRpc.Bounds): ParaviewRpc.Vector3 {
  let x, y, z;
  if (bounds && bounds.length > 2) {
    x = parseFloat((bounds[0] +
      ((bounds[1] - bounds[0]) * 0.25)).toPrecision(4));
    y = parseFloat(((bounds[2] + bounds[3]) / 2).toPrecision(4));
    z = parseFloat(((bounds[4] + bounds[5]) / 2).toPrecision(4));
  } else {
    x = 0.0;
    y = 0.0;
    z = 0.0;
  }
  return { x, y, z };
}

function getDefaultEnd(bounds: ParaviewRpc.Bounds): ParaviewRpc.Vector3 {
  let x, y, z;
  if (bounds && bounds.length > 2) {
    x = parseFloat((bounds[1] -
      ((bounds[1] - bounds[0]) * 0.25)).toPrecision(4));
    y = parseFloat(((bounds[2] + bounds[3]) / 2).toPrecision(4));
    z = parseFloat(((bounds[4] + bounds[5]) / 2).toPrecision(4));
  } else {
    x = 1.0;
    y = 0.0;
    z = 0.0;
  }
  return { x, y, z };
}

/** Create the param filled with default values, to be used when creating a new
    filter off the given parent. */
export function newMultiSliceParam(bounds: ParaviewRpc.Bounds): ParaviewRpc.MultiSliceParam {
  return {
    typ: ParaviewRpc.TreeNodeType.MULTI_SLICE,
    nslices: 2,
    start: getDefaultStart(bounds),
    end: getDefaultEnd(bounds),
    currentslice: 0,
    showmultiple: false,
    url: '',
    projectvectors: false,
  };
}

// Panel for displaying and modifying a multislice filter.
export const MultiSlicePropPanel = (props: FilterPropertiesPanelProps) => {
  const { displayProps, filterNode, nodeId, viewState } = props;

  const {
    activeEdit,
    getDataVisibilityBounds,
    paraviewProjectId,
    paraviewActiveUrl,
    paraviewClientState,
    paraviewMeshMetadata,
    paraviewRenderer,
    onRpcSuccess,
  } = useParaviewContext();

  const [editState] = useEditState();
  const editSource = editState ? editState.editSource : EditSource.FORM;
  const paraviewClient = paraviewClientState.client;
  const { updateEditState } = useSelectedFilterNode();

  const onUpdate = (source: EditSource, newParam: ParaviewRpc.TreeNodeParam) => (
    updateEditState({
      editSource: source,
      param: newParam,
    })
  );

  const param = props.param as ParaviewRpc.MultiSliceParam;
  const calc = !editState && param.url === '';
  const creationError = param.url === 'ERROR';

  const MAX_SLICES = 100;
  const MIN_SLICES = 1;

  const [sliderValue, setSliderValue] = useState(param.currentslice);
  const [projectVectors, setProjectVectors] = useState<boolean>(param.projectvectors);

  // Function to make asynchronous call to vis service to compute MultiSlice
  const executeVisFilter = (newNodeId: string) => {
    const currentParam = editState!.param as ParaviewRpc.MultiSliceParam;
    visComputeFilterWrapper(
      paraviewProjectId,
      paraviewActiveUrl,
      currentParam,
      newNodeId,
      activeEdit,
    );
  };

  // We need to keep track of whether we need to reset the axes to prior state on unmount.
  // If ON from before - set to false and do nothing.
  // If OFF prior to component mount - set to true and hide on unmount.
  // If turned ON / OFF by the user while component is mounted - set to false and do nothing.
  const resetAxesGridRef = useRef<boolean>(false);

  useEffect(() => {
    // Run on mount
    if (!viewState.axesGridVisibility && editState) {
      paraviewRenderer.showAxesGrid(true).then((res: ParaviewRpc.RpcResult) => {
        onRpcSuccess('showAxesGrid', res);
        resetAxesGridRef.current = true;
      }).catch((err: Error) => {
        addRpcError('Could not set grid axes', err);
      });
    }
    // Run on unmount
    return () => {
      if (resetAxesGridRef.current) {
        paraviewRenderer.showAxesGrid(false).then((res: ParaviewRpc.RpcResult) => {
          onRpcSuccess('showAxesGrid', res);
        }).catch((err: Error) => {
          addRpcError('Could not unset grid axes', err);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!editState]);

  useEffect(() => {
    // If at any point the user switches axes grid off, (and then on/off/on again,)
    // do not hide the grid axes on line probe panel unmount. Leave it in user chosen end state.
    if (!viewState.axesGridVisibility) {
      resetAxesGridRef.current = false;
    }
  }, [viewState.axesGridVisibility]);

  useEffect(() => {
    if (!editState) {
      return () => { };
    }

    let mounted = true;
    let unsubscribeFn: (() => void) | null = null;

    // Called when the user moves the widget interactively and nslices > 1.
    const onLineWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION) {
        throw Error('only line widget supported now');
      }
      if (!mounted) {
        return;
      }
      if (
        !Vector.nearPv(newParam.line.point1, param.start) ||
        !Vector.nearPv(newParam.line.point2, param.end)
      ) {
        onUpdate(
          EditSource.PARAVIEW,
          {
            ...param,
            start: newParam.line.point1,
            end: newParam.line.point2,
          },
        );
      }
    };

    // Called when the user moves the widget interactively and nslices = 1
    const onPlaneWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION) {
        throw Error('only plane widget supported now');
      }
      if (!mounted) {
        return;
      }

      if (
        !Vector.nearPv(newParam.plane.origin, param.start) ||
        !Vector.nearPv(newParam.plane.normal, param.end)
      ) {
        onUpdate(
          EditSource.PARAVIEW,
          {
            ...param,
            start: newParam.plane.origin,
            end: newParam.plane.normal,
          },
        );
      }
    };

    const startWidget = async (): Promise<void> => {
      if (!paraviewClient || !paraviewMeshMetadata) {
        return;
      }
      if (param.nslices === 1) {
        // Register the widget update handler.
        unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onPlaneWidgetUpdate);
        // Activate the widget.
        paraviewRenderer.activateWidget(
          getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
          {
            typ: ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
            plane: {
              typ: 'Plane',
              origin: param.start,
              normal: param.end,
            },
            bounds: getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
          },
        );
      } else { // nslices > 1
        // Register the widget update handler.
        unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onLineWidgetUpdate);
        // Activate the widget.
        paraviewRenderer.activateWidget(
          filterNode.bounds!,
          {
            typ: ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION,
            line: {
              typ: 'Line',
              point1: param.start,
              point2: param.end,
            },
            resolution: 1000.0,
          },
        );
      }
    };

    startWidget().then(() => { }).catch((err: Error) => {
      addRpcError('Could not activate widget', err);
    });
    return () => {
      mounted = false;
      if (unsubscribeFn) {
        unsubscribeFn();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!editState, param.nslices, nodeId]);

  useEffect(() => {
    if (editState && editSource !== EditSource.PARAVIEW && viewState && paraviewMeshMetadata) {
      logger.debug('MultiSlice: set widget');
      // When the user manually updates the line dialog, reflect the new line
      // params to the widget.
      if (param.nslices === 1) {
        const ws: ParaviewRpc.ImplicitPlaneWidgetState = {
          typ: ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
          plane: {
            typ: 'Plane',
            origin: param.start,
            normal: param.end,
          },
          bounds: getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
        };
        paraviewRenderer.activateWidget(
          getDataVisibilityBounds(
            paraviewMeshMetadata.meshMetadata,
          ),
          ws,
        );
      } else {
        const ws: ParaviewRpc.LineSourceWidgetState = {
          typ: ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION,
          line: {
            typ: 'Line',
            point1: param.start,
            point2: param.end,
          },
          resolution: 1000.0,
        };
        paraviewRenderer.activateWidget(
          filterNode.bounds!,
          ws,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editState, editSource, param, nodeId, param.start, param.end]);

  // Update the number of slices to compute
  const setNumberOfSlices = (value: number) => {
    if (Number.isNaN(value)) {
      // This is not a valid number so don't do anything.
      // The NumberSlider will keep the old value.
      return;
    }
    if (value > MAX_SLICES) {
      value = MAX_SLICES;
      addWarning(`Maximum number of slices supported is ${MAX_SLICES}.`);
    }
    if (value < MIN_SLICES) {
      value = MIN_SLICES;
      addWarning(`Minimum number of slices supported is ${MIN_SLICES}.`);
    }
    const bounds = getDataVisibilityBounds(paraviewMeshMetadata!.meshMetadata);
    if (value === 1 && param.nslices !== 1) { // User is changing the value from > 1 to 1.
      // Set defaults
      const xCenter = parseFloat(((bounds[0] + bounds[1]) / 2).toPrecision(4));
      const yCenter = parseFloat(((bounds[2] + bounds[3]) / 2).toPrecision(4));
      const zCenter = parseFloat(((bounds[4] + bounds[5]) / 2).toPrecision(4));
      onUpdate(
        EditSource.FORM,
        {
          ...param,
          nslices: value,
          start: { x: xCenter, y: yCenter, z: zCenter },
          end: { x: 1, y: 0, z: 0 },
          url: '',
        },
      );
    } else if (value > 1 && param.nslices === 1) { // User is changing the value from 1 to > 1
      onUpdate(
        EditSource.FORM,
        {
          ...param,
          nslices: value,
          start: getDefaultStart(bounds),
          end: getDefaultEnd(bounds),
          url: '',
        },
      );
    } else {
      onUpdate(
        EditSource.FORM,
        {
          ...param,
          nslices: value,
          url: '',
        },
      );
    }
  };

  const toggleAllSlices = (checked: boolean) => {
    const newParam = { ...param, showmultiple: checked };
    activeEdit(nodeId, newParam);
  };

  // Set the slice using slider and not in edit state
  const setSlice = (newValue: number) => {
    const newParam = { ...param, currentslice: newValue };
    activeEdit(nodeId, newParam);
  };

  // Depending on the number of slices requested, we require different inputs
  // from the user. If a single slice is requested, nslices = 1,
  // then an origin and normal is required to specify the slice plane.
  // When the number of slices is > 1, then we require two locations (point1, point2),
  // on the start and end plane. Then the normal is computed as point2 - point1 .
  const renderMultiSliceInput = (nslices: number): ReactElement => {
    if (nslices === 1) {
      return (
        <div>
          <PlaneInput
            onCommit={(newParam: PlaneParam) => onUpdate(
              EditSource.FORM,
              {
                ...param,
                start: Vector.toPvProto(newParam.origin),
                end: Vector.toPvProto(newParam.normal),
                url: '',
              },
            )}
            param={{
              origin: Vector.toProto(param.start),
              normal: Vector.toProto(param.end),
            }}
            readOnly={!editState}
          />
        </div>
      );
    }
    return (
      <div>
        <Form.LabeledInput help="Start point on the plane" label="Start">
          <Vector3Input
            disabled={!editState}
            onCommit={(value) => {
              onUpdate(
                EditSource.FORM,
                {
                  ...param,
                  start: Vector.toPvProto(value),
                  url: '',
                },
              );
            }}
            value={Vector.toProto(param.start)}
          />
        </Form.LabeledInput>
        <Form.LabeledInput help="End point on the plane" label="End">
          <Vector3Input
            disabled={!editState}
            onCommit={(value) => {
              onUpdate(
                EditSource.FORM,
                {
                  ...param,
                  end: Vector.toPvProto(value),
                  url: '',
                },
              );
            }}
            value={Vector.toProto(param.end)}
          />
        </Form.LabeledInput>
      </div>
    );
  };

  return (
    <div>
      <CommonFilterMessages calculating={calc} creationError={creationError} />
      <FilterDisplayPanel filterNode={filterNode} />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={!!editState}
          expandWhenDisabled
          headerRight={(
            <FilterEditControl
              disableEdit={calc}
              displayProps={displayProps}
              executeVisFilter={executeVisFilter}
              nodeId={nodeId}
              param={param}
            />
          )}
          heading="Visualization Input"
          nodeId={nodeId}
          panelName="input">
          <Form.LabeledInput label="Number of Slices">
            <NumberSpinner
              disabled={!editState}
              maximumValue={MAX_SLICES}
              minimumValue={MIN_SLICES}
              onCommit={(value) => {
                setNumberOfSlices(value);
              }}
              value={param.nslices}
            />
          </Form.LabeledInput>
          <Form.LabeledInput label="">
            <Form.ControlLabel
              help="Project vectors onto the slice planes."
              label="Project Vectors">
              <CheckBox
                checked={projectVectors}
                disabled={!editState}
                onChange={(checked: boolean) => {
                  setProjectVectors(checked);
                  onUpdate(
                    EditSource.FORM,
                    {
                      ...param,
                      projectvectors: checked,
                      url: '',
                    },
                  );
                }}
              />
            </Form.ControlLabel>
          </Form.LabeledInput>
          <Form.LabeledInput
            label={param.nslices === 1 ? 'Single Slice Input' : 'Multiple Slice Input'}
          />
          {renderMultiSliceInput(param.nslices)}
          {!editState && param.nslices > 1 && (
            <div style={{ padding: '8px 12px' }}>
              <SimpleSlider
                disabled={param.showmultiple}
                max={param.nslices - 1}
                min={0}
                onChange={setSliderValue}
                onCommit={(newValue) => {
                  setSliderValue(Math.round(newValue));
                  setSlice(Math.round(newValue));
                }}
                readoutConfig={{
                  formatValue: (value: number) => Math.round(value).toFixed(0),
                }}
                showStops
                stopCount={param.nslices}
                value={sliderValue}
              />
              <Form.LabeledInput label="">
                <Form.ControlLabel
                  label="Show All">
                  <CheckBox
                    checked={param.showmultiple}
                    onChange={(checked) => {
                      toggleAllSlices(checked);
                    }}
                  />
                </Form.ControlLabel>
              </Form.LabeledInput>
            </div>
          )}
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
    </div>
  );
};
