// Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { CircularLoader } from './visual/CircularLoader';

const suspenseWidget = (
  <div
    data-locator="loading-widget"
    style={{
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <CircularLoader size={72} />
  </div>
);

export default suspenseWidget;
