// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const PauseIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="pause"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M7.2,0
           L2.4,0
           C1.08,0 0,1.08 0,2.4
           L0,21.6
           C0,22.92 1.08,24 2.4,24
           L7.2,24
           C8.52,24 9.6,22.92 9.6,21.6
           L9.6,2.4
           C9.6,1.08 8.52,0 7.2,0Z
           M21.6,0
           L16.8,0
           C15.48,0 14.4,1.08 14.4,2.4
           L14.4,21.6
           C14.4,22.92 15.48,24 16.8,24
           L21.6,24
           C22.92,24 24,22.92 24,21.6
           L24,2.4
           C24,1.08 22.92,0 21.6,0Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
