// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { Scaling } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 17;
const baseHeight = 16;

const googleColors = ['#4285F4', '#34A853', '#FBBC04', '#EA4335'];

export const GoogleIcon = (props: Scaling) => {
  const {
    finalHeight,
    finalWidth,
    getColor,
  } = useSvg(baseWidth, baseHeight, { ...props, color: googleColors });

  return (
    <svg
      fill="none"
      height={finalHeight}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d={`M16.3442 8.18417C16.3442 7.64035 16.3001 7.09359 16.206
              6.55859H8.66016V9.63925H12.9813C12.802 10.6328 12.2258
              11.5117 11.3822 12.0703V14.0692H13.9602C15.4741 12.6758
              16.3442 10.6181 16.3442 8.18417Z`}
        fill={getColor(0)}
        id="Vector"
      />
      <path
        d={`M8.65974 16.0002C10.8174 16.0002 12.637 15.2918 13.9627 14.0689L11.3847 12.07C10.6675
              12.558 9.7415 12.8343 8.66268 12.8343C6.5756 12.8343 4.80598 11.4263 4.17104
              9.5332H1.51074V11.5938C2.86882 14.2953 5.63494 16.0002 8.65974 16.0002Z`}
        fill={getColor(1)}
        id="Vector_2"
      />
      <path
        d={`M4.16852 9.5338C3.83341 8.54023 3.83341 7.46435 4.16852
              6.47078V4.41016H1.51116C0.376489 6.67067 0.376489 9.33391 1.51116
              11.5944L4.16852 9.5338Z`}
        fill={getColor(2)}
        id="Vector_3"
      />
      <path
        d={`M8.65974 3.16644C9.80029 3.1488 10.9026 3.57798 11.7286 4.36578L14.0127
              2.08174C12.5664 0.72367 10.6469 -0.0229773 8.65974 0.000539111C5.63494 0.000539111
              2.86882 1.70548 1.51074 4.40987L4.1681 6.4705C4.8001 4.57449 6.57266 3.16644 8.65974
              3.16644Z`}
        fill={getColor(3)}
        id="Vector_4"
      />
    </svg>
  );
};
