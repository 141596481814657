// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const GridQuadSolidIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="gridQuadSolid"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0,1.5
           L0,10.5
           L10.5,10.5
           L10.5,0
           L1.5,0
           C0.675,0 0,0.675 0,1.5Z
           M0,22.5
           C0,23.325 0.675,24 1.5,24
           L10.5,24
           L10.5,13.5
           L0,13.5
           L0,22.5Z
           M22.5,0
           L13.5,0
           L13.5,10.5
           L24,10.5
           L24,1.5
           C24,0.675 23.325,0 22.5,0Z
           M13.5,24
           L22.5,24
           C23.325,24 24,23.325 24,22.5
           L24,13.5
           L13.5,13.5
           L13.5,24Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
