// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { AugmentedKind } from './componentTypes/form';

interface TagColors {
  backgroundColor: string;
  hoverBackgroundColors: string[];
  foregroundColor: string;
}

/**
 * Return standard colors used for Tag components
 * @param disabled
 * @param kind
 * @returns a foreground color, a background color, and a list of hover background colors, the first
 * for hovering over the tag and the second for hovering over a close button
 */
export function getTagColors(disabled: boolean, kind?: AugmentedKind): TagColors {
  if (disabled) {
    return {
      backgroundColor: 'var(--color-neutral-350)',
      foregroundColor: 'var(--color-neutral-700)',
      hoverBackgroundColors: [],
    };
  }

  if (kind === 'tertiary') {
    return {
      backgroundColor: 'var(--color-neutral-750)',
      foregroundColor: 'var(--color-neutral-50)',
      hoverBackgroundColors: ['var(--color-neutral-600)', 'var(--color-neutral-700)'],
    };
  }

  if (kind === 'secondary') {
    return {
      backgroundColor: 'var(--color-cyan-850)',
      foregroundColor: 'var(--color-cyan-50)',
      hoverBackgroundColors: ['var(--color-cyan-700)', 'var(--color-neutral-600)'],
    };
  }

  return {
    backgroundColor: 'var(--color-violet-800)',
    foregroundColor: 'var(--color-violet-100)',
    hoverBackgroundColors: ['var(--color-violet-700)', 'var(--color-violet-600)'],
  };
}
