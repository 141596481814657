// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/kms/kms.proto (package luminary.proto.kms, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Status } from "../base/base_pb.js";

/**
 * Represents a single GCS symmetric encryption key. Stable.
 *
 * @generated from message luminary.proto.kms.GCSKey
 */
export const GCSKey = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.kms.GCSKey",
  () => [
    { no: 1, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Represents all the keys used by an account. Stored in account(kms_key)
 * column. Stable.
 *
 * @generated from message luminary.proto.kms.KeySet
 */
export const KeySet = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.kms.KeySet",
  () => [
    { no: 16, name: "gcs_key", kind: "message", T: GCSKey, repeated: true },
  ],
);

/**
 * Summary of one key. Unstable.
 *
 * @generated from message luminary.proto.kms.KeyInfo
 */
export const KeyInfo = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.kms.KeyInfo",
  () => [
    { no: 2, name: "key_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "rotation_period", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "next_rotation_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "status", kind: "message", T: Status },
  ],
);

