// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Color } from './componentTypes/svgIcon';
import { Point } from './geometry';
import { clamp } from './number';

export interface Scaling {
  // An optional number to scale an image relative to its base width/height
  scale?: number;
  // If scale is not provided, maxWidth and/or maxHeight may be provided to scale the image
  maxWidth?: number;
  maxHeight?: number;
}

export interface SvgProps extends Scaling {
  color?: Color | Color[];
}

/**
 * Given a baseWidth, baseHeight, and a Scaling object, find and return a single scaling factor,
 * giving precedence to scaling.scale and otherwise preferring the smaller scale implied by
 * scaling.maxHeight and scaling.maxWidth.
 */
export function getComputedScale(baseWidth: number, baseHeight: number, scaling: Scaling) {
  const { scale, maxHeight, maxWidth } = scaling;

  // Explicit scale always takes precedence
  if (scale !== undefined) {
    return scale;
  }

  const scales: number[] = [];
  if (maxHeight !== undefined) {
    scales.push(maxHeight / baseHeight);
  }
  if (maxWidth !== undefined) {
    scales.push(maxWidth / baseWidth);
  }
  return scales.length ? Math.min(...scales) : 1;
}

// Convert a list of points to an SVG path string
export function pointsToPath(points: Point[], autoClose?: boolean): string {
  if (!points.length) {
    return '';
  }

  let path = '';

  points.forEach((point, i) => {
    path += `${i ? 'L' : 'M'}${point.x} ${point.y}`;
  });

  if (autoClose) {
    path += 'Z';
  }

  return path;
}

export function ringPath(size: number, strokeRatio: number) {
  const center = size / 2;
  const outerRadius = size / 2;
  const innerRadius = outerRadius * (1 - clamp(strokeRatio, [0, 1]));

  return `
    M ${center} ${center - outerRadius}
    A ${outerRadius} ${outerRadius} 0 1 0 ${center} ${center + outerRadius}
    A ${outerRadius} ${outerRadius} 0 1 0 ${center} ${center - outerRadius}
    Z
    M ${center} ${center - innerRadius}
    A ${innerRadius} ${innerRadius} 0 1 1 ${center} ${center + innerRadius}
    A ${innerRadius} ${innerRadius} 0 1 1 ${center} ${center - innerRadius}
    Z`;
}
