// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { SetterOrUpdater, atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

// We need to use the selection system to select a list  of surfaces to extract into
// a logical group in paraview. These lists are natively part of some simulation tree nodes,
// but filters have no such list. Thus, to pass around a list to the various components that
// touch the selection system, we will use recoil.
export const extractSurfaceState = atom<string[]>({
  key: 'extractBlocksState',
  default: [],
});

// Returns a [getter, setter] combo for passsing a list of surfaces to the
// various components used in selection.
// Must be called inside a react component.
export function useExtractSurfacesState(): [string[], SetterOrUpdater<string[]>] {
  return useRecoilState(extractSurfaceState);
}

// Returns the current list.
// Must be called inside a react component.
export function useExtractSurfacesValue(): string[] {
  return useRecoilValue(extractSurfaceState);
}

// Returns a setter for the filter edit activity.
// Must be called inside a react component.
export function useSetExtractSurfacesState(): (value: string[]) => void {
  return useSetRecoilState(extractSurfaceState);
}
