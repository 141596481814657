// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
// Utilities for converting between string and Uint8Array.

// Fixes the problem "ReferenceError: TextEncoder is not defined" when running jest
if (typeof TextEncoder === 'undefined') {
  // eslint-disable-next-line global-require
  global.TextEncoder = require('util').TextEncoder;
}
if (typeof TextDecoder === 'undefined') {
  // eslint-disable-next-line global-require
  global.TextDecoder = require('util').TextDecoder;
}
const textEncoder = new TextEncoder();
const textDecoder = new TextDecoder();

export const EMPTY_UINT8_ARRAY = new Uint8Array();

// Converts string to bytes. Assumes utf-8 encoding.
export function stringToArray(value: string): Uint8Array {
  return textEncoder.encode(value);
}

// Converts bytes to string. Assumes utf-8 encoding.
export function arrayToString(arr: Uint8Array): string {
  return textDecoder.decode(arr);
}
