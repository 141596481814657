// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ActionButton } from '../../Button/ActionButton';
import { useHandleLoadToSetup, useLoadToSetupDisabled } from '../../project/ModificationTreePanel';

export const LoadToSetupButton = () => {
  const handleLoadToSetup = useHandleLoadToSetup();
  const { disabled, disabledReason } = useLoadToSetupDisabled();

  return (
    <ActionButton
      asBlock
      disabled={disabled}
      onClick={handleLoadToSetup}
      size="medium"
      title={disabledReason}>
      Load to Setup
    </ActionButton>
  );
};
