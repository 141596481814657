// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { RenderElementProps, useFocused, useSelected } from 'slate-react';

import assert from '../../lib/assert';
import { colors } from '../../lib/designSystem';
import { Type, VariableElement } from '../../lib/expressionInput/slate';
import { createStyles, makeStyles } from '../Theme';

const useStyles = makeStyles(
  () => createStyles({
    variable: {
      fontFamily: 'monospace',
      color: colors.orange700,
      backgroundColor: colors.surfaceDark2,
      borderColor: colors.surfaceDark3,
      borderRadius: '4px',
      borderWidth: '1px',
      padding: '4px',
    },
  }),
  { name: 'VariableElement' },
);

export function Variable(props: RenderElementProps) {
  const { attributes, element, children } = props;

  assert(element.type === Type.Variable, 'Element is not a variable');
  const variableElement = element as VariableElement;

  const styles = useStyles();
  const selected = useSelected();
  const focused = useFocused();

  return (
    <span
      {...attributes}
      className={styles.variable}
      contentEditable={false}
      data-slate-inline
      style={{
        boxShadow:
          selected && focused ?
            `0 0 0 1px ${colors.highEmphasisBorder}` :
            undefined,
      }}>
      {variableElement.variable}
      {children}
    </span>
  );
}
