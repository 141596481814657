// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 500;
const baseHeight = 500;
export const GhostIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="ghost"
      height={finalHeight}
      ref={ref}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}>
      <path
        d="M 250 0 C 342.046 0 416.666 74.62 416.666 166.667 L 416.666 500 L 361.666 416.667 L 305
        500 L 250 416.667 L 195 500 L 138.333 416.667 L 83.333 500 L 83.333 166.667 C 83.333 74.62
        157.953 0 250 0 Z M 250 100 C 231.59 100 216.666 114.923 216.666 133.333 C 216.666 151.743
        231.59 166.667 250 166.667 C 268.41 166.667 283.333 151.743 283.333 133.333 C 283.333
        114.923 268.41 100 250 100 Z M 350 100 C 331.59 100 316.666 114.923 316.666 133.333 C
        316.666 151.743 331.59 166.667 350 166.667 C 368.41 166.667 383.333 151.743 383.333 133.333
        C 383.333 114.923 368.41 100 350 100 Z"
        fill={getColor(0)}
      />
    </svg>
  );
});
