// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { ParamScope } from '../../lib/ParamScope';
import { ColumnConfig, RowConfig } from '../../lib/componentTypes/table';
import { tableToColumnConfig, tableToRowConfig } from '../../lib/rectilinearTable/util';
import * as explorationpb from '../../proto/exploration/exploration_pb';
import useTableState from '../../recoil/tableState';
import { useProjectContext } from '../context/ProjectContext';
import { Table } from '../data/Table';
import { HEADING_COLUMN_OPTIONS } from '../data/Table/util';
import { useCustomDoeValue } from '../hooks/useCustomDoeValues';

import { Dialog } from './Base';

export interface TablePreviewDialogProps {
  /** The name of the table which is displayed as the dialog's title */
  tableName: string;
  /** The table's URL which is used to get the table data */
  tableUrl: string;
  /** The subtitle for the dialog */
  subtitle?: string;
  /**
   * The column index to highlight with a purple border, where column 0 is the first
   * user defined column
   */
  highlightColumn?: number;
  /** If true, stylize the first column as a header column */
  headerColumn?: boolean;
  /** True if the dialog is open */
  open: boolean;
  /** Callback for when the dialog closes */
  onClose: () => void;
  explorationPreview?: {
    /** The param scope */
    paramScope: ParamScope
    /** The exploration */
    exploration: explorationpb.Exploration;

  }
}

export function TablePreviewDialog(props: TablePreviewDialogProps) {
  // Props
  const {
    tableName, tableUrl, subtitle, highlightColumn, headerColumn, open, onClose, explorationPreview,
  } = props;

  // Contexts
  const { projectId } = useProjectContext();

  // Recoil
  const tableProto = useTableState(projectId, tableUrl);

  const columns: ColumnConfig[] = useMemo(() => {
    if (tableProto) {
      const configs = tableToColumnConfig(tableProto, { disableVisibility: true } as ColumnConfig);
      if (highlightColumn !== undefined) {
        configs[highlightColumn].displayOptions = { highlight: true };
      }
      if (headerColumn) {
        configs[0].displayOptions = {
          ...configs[0].displayOptions,
          ...HEADING_COLUMN_OPTIONS,
        };
      }
      return configs;
    }
    return [];
  }, [headerColumn, highlightColumn, tableProto]);

  const { rows: customRows } = useCustomDoeValue(
    explorationPreview?.exploration,
    tableProto ?? undefined,
    columns,
  );

  const rows: RowConfig[] = useMemo(() => {
    if (tableProto) {
      return explorationPreview ? customRows : tableToRowConfig(tableProto);
    }
    return [];
  }, [tableProto, explorationPreview, customRows]);

  return (
    <Dialog
      continueButton={{ label: 'Done' }}
      modal
      onClose={onClose}
      onContinue={onClose}
      open={open}
      subtitle={subtitle}
      title={tableName}
      width="800px">
      <div style={{ height: '100%', overflow: 'auto' }}>
        <Table
          asBlock
          columnConfigs={columns}
          density="comfortable"
          disableColumnSettings
          disableSortHighlighting
          disableSorting
          name="polict-table-preview"
          rowConfigs={rows}
          variant="grid"
        />
      </div>
    </Dialog>
  );
}
