// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 112;
const baseHeight = 120;
export const StreamArrowsIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="streamArrows"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M111.714,59.936
           C111.697,58.766 111.235,57.649 110.426,56.821
           L95.772,41.838
           C94.67,40.663 93.033,40.19 91.492,40.601
           C89.952,41.012 88.748,42.242 88.347,43.817
           C87.945,45.393 88.408,47.067 89.556,48.194
           L96.705,55.505
           L4.744,55.505
           C3.159,55.482 1.684,56.333 0.885,57.734
           C0.086,59.134 0.086,60.866 0.885,62.266
           C1.684,63.667 3.159,64.518 4.744,64.495
           L96.705,64.495
           L89.556,71.805
           C88.408,72.933 87.945,74.607 88.347,76.183
           C88.748,77.758 89.952,78.988 91.492,79.399
           C93.033,79.809 94.67,79.336 95.772,78.162
           L110.426,63.178
           C111.266,62.32 111.73,61.15 111.714,59.936Z
           M82.408,25.472
           C82.39,24.303 81.928,23.186 81.119,22.359
           L66.466,7.375
           C65.363,6.2 63.726,5.727 62.185,6.138
           C60.645,6.549 59.442,7.779 59.04,9.354
           C58.638,10.929 59.101,12.604 60.249,13.731
           L67.399,21.042
           L25.259,21.042
           C16.305,21.042 9.14,13.715 9.14,4.559
           C9.163,2.938 8.33,1.43 6.961,0.613
           C5.591,-0.204 3.898,-0.204 2.528,0.613
           C1.159,1.43 0.326,2.938 0.348,4.559
           C0.348,18.574 11.553,30.032 25.259,30.032
           L67.399,30.032
           L60.249,37.343
           C59.101,38.47 58.638,40.144 59.04,41.719
           C59.442,43.295 60.645,44.525 62.185,44.936
           C63.726,45.346 65.363,44.873 66.466,43.699
           L81.119,28.715
           C81.959,27.856 82.424,26.687 82.408,25.472Z
           M82.408,94.399
           C82.39,93.229 81.928,92.112 81.119,91.285
           L66.466,76.301
           C65.363,75.127 63.726,74.653 62.185,75.064
           C60.645,75.475 59.442,76.705 59.04,78.28
           C58.638,79.856 59.101,81.53 60.249,82.657
           L67.399,89.968
           L25.259,89.968
           C11.553,89.968 0.348,101.425 0.348,115.441
           C0.326,117.062 1.159,118.569 2.528,119.387
           C3.898,120.204 5.591,120.204 6.961,119.387
           C8.33,118.569 9.163,117.062 9.14,115.441
           C9.14,106.284 16.305,98.959 25.259,98.959
           L67.399,98.959
           L60.249,106.269
           C59.101,107.396 58.638,109.071 59.04,110.646
           C59.442,112.221 60.645,113.451 62.185,113.862
           C63.726,114.273 65.363,113.8 66.466,112.625
           L81.119,97.641
           C81.959,96.783 82.424,95.613 82.408,94.399Z"
        style={{ fill: getColor(0), fillRule: 'nonzero' }}
      />
    </svg>
  );
});
