// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { memo } from 'react';

import cx from 'classnames';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { getIconSpecDims } from '../../lib/svgIcon/utils';
import useButtonMenu from '../../lib/useButtonMenu';
import { SvgIcon } from '../Icon/SvgIcon';
import { CommonMenu } from '../Menu/CommonMenu';
import { createStyles, makeStyles } from '../Theme';

import { ActionButton, ActionButtonProps, ActionKind } from './ActionButton';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'inline-flex',
      alignItems: 'stretch',
      gap: '1px',

      '&.asBlock': {
        display: 'flex',

        '& $button:first-child': {
          flexGrow: 1,
        },
      },
    },
    button: {
      /**
       * The buttons don't overlap, but their focus rings can intrude on each other's space.  This
       * styling makes sure the focused button is on top, so the focus rings are always fully
       * visible (per Figma designs).
       */
      position: 'relative',
      zIndex: 1,
      '&:focus-within': {
        zIndex: 2,
      },
    },
  }),
  { name: 'SplitButton' },
);

export type SplitKind = Exclude<ActionKind, 'cancel' | 'destructive' | 'minimal'>;

export interface SplitButtonProps extends Omit<ActionButtonProps, 'compact' | 'inset' | 'kind'> {
  kind: SplitKind;
  menuItems: CommonMenuItem[];
  splitIcon?: SvgIconSpec;
}

export const SplitButton = memo((props: SplitButtonProps) => {
  const {
    asBlock,
    menuItems,
    showSpinner,
    splitIcon = { name: 'caretDown' },
    dataLocator = 'splitButton',
    ...mainButtonProps
  } = props;

  const { children, title, ...menuButtonProps } = mainButtonProps;

  const classes = useStyles();
  const { anchorEl, anchorRef, onClickButton, onCloseMenu } = useButtonMenu();

  return (
    <div className={cx(classes.root, { asBlock })}>
      <div className={classes.button}>
        <ActionButton
          {...mainButtonProps}
          asBlock
          dataLocator={`${dataLocator}-main`}
          inset={menuItems.length ? 'left' : undefined}
          showSpinner={showSpinner}
          title={title}
        />
      </div>
      {menuItems.length ? (
        <div className={classes.button} ref={anchorRef}>
          <ActionButton
            {...menuButtonProps}
            aria-haspopup="true"
            asBlock
            dataLocator={`${dataLocator}-options`}
            inset="right"
            justifyContent="space-between"
            onClick={onClickButton}>
            <SvgIcon {...splitIcon} {...getIconSpecDims(splitIcon, 6, 6)} />
          </ActionButton>
          <CommonMenu
            anchorEl={anchorEl}
            closeOnSelect
            menuItems={menuItems}
            onClose={onCloseMenu}
            open={!!anchorEl}
            position="below-left"
          />
        </div>
      ) : null}
    </div>
  );
});

SplitButton.displayName = 'SplitButton';
