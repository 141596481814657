// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useRecoilState, useRecoilValue } from 'recoil';

import { NodeTableIdentifier, NodeTableType } from '../lib/nodeTableUtil';

/**
 * The NodeTableType that is currently being activated for selection.
 */
export const activeNodeTableAtom = atom<NodeTableIdentifier>({
  key: 'activeNodeTable',
  default: { type: NodeTableType.NONE },
});

export const useActiveNodeTable = () => useRecoilState(activeNodeTableAtom);
export const useActiveNodeTableValue = () => useRecoilValue(activeNodeTableAtom);
