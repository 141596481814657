// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { colors } from '../../lib/designSystem';

import { createStyles, makeStyles } from '.';

const useStyles = makeStyles(
  () => createStyles({
    divider: {
      height: '1px',
      backgroundColor: colors.surfaceDark3,
    },
  }),
  { name: 'Divider' },
);

// A dark horizontal divider.
const Divider = () => {
  const classes = useStyles();

  return <div className={classes.divider} />;
};

export default Divider;
