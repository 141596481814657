// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { CommonMenuItem } from '../../../lib/componentTypes/menu';
import { SelectionAction } from '../../../lib/selectionUtils';
import { NodeType } from '../../../lib/simulationTree/node';
import { canSubselectTypeAndId, getSelectableRows } from '../../../lib/subselectUtils';
import {
  NodeFilter,
  useResetSimulationTreeSubselect,
  useSetSimulationTreeSubselect,
  useSimulationTreeSubselect,
} from '../../../recoil/simulationTreeSubselect';
import { StaticVolume } from '../../../recoil/volumes';
import { useSimulationTree } from '../../../state/internal/tree/simulation';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';

export const useSubselectControl = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const {
    modifySelection,
    setRevealNodes,
    setScrollTo,
    setFinishingSubselectNodeIds,
    setSelection,
  } = useSelectionContext();

  const simulationTree = useSimulationTree(projectId, workflowId, jobId);
  const resetTreeSubselect = useResetSimulationTreeSubselect();
  const setTreeSubselect = useSetSimulationTreeSubselect();

  const startSubselect = useCallback(async (
    subselectId: string,
    nodeFilter: NodeFilter,
    referenceNodeIds: string[],
    initialNodeIds: string[],
    independentSelection = false,
    visibleTreeNodeTypes: NodeType[],
    onChange: (nodeIds: string[]) => void,
    onStart?: () => void,
  ) => {
    // Pre-select rows that are already assigned to the reference
    modifySelection({
      action: SelectionAction.OVERWRITE,
      modificationIds: initialNodeIds,
    });

    const selectableIds = getSelectableRows(simulationTree, nodeFilter);
    // Reveal all eligible rows
    setRevealNodes(selectableIds);

    // Scroll either to the first selected or to the first selectable node
    const scrollId = initialNodeIds.length ? initialNodeIds[0] : selectableIds[0];
    if (scrollId) {
      // If nodeIds is populated, scroll to the first node.  Otherwise, scroll to the first
      // selectable row.  Set `fast` to true, because smooth scrolling might now work correctly
      // immediately after `setRevealRows` does its thing
      setScrollTo({ node: scrollId, fast: true });
    }

    // Activate the subselect
    setTreeSubselect({
      active: true,
      id: subselectId,
      nodeFilter,
      referenceNodeIds,
      independentSelection,
      visibleTreeNodeTypes,
      onChange,
    });

    onStart?.();
  }, [setRevealNodes, setScrollTo, modifySelection, setTreeSubselect, simulationTree]);

  const endSubselect = useCallback((idsToSelect: string[]) => {
    // To avoid SelectionManager's `selectedNode` from becoming temporarily `null`, set some state
    // so that SelectionManager knows what to select once subselect becomes inactive.
    setFinishingSubselectNodeIds(idsToSelect);
    resetTreeSubselect();
    setSelection([...idsToSelect]);
    if (idsToSelect.length) {
      setScrollTo({ node: idsToSelect[0] });
    }
  }, [resetTreeSubselect, setFinishingSubselectNodeIds, setScrollTo, setSelection]);

  return {
    endSubselect,
    startSubselect,
  };
};

/**
 * Generalizes the generation of menu items for toggling surfaces and volumes when a NodeSubselect
 * is active
 * @returns CommonMenuItem[]
 */
export const useSubselectVisualizerMenuItems = () => {
  const { modifySelection, setScrollTo } = useSelectionContext();

  const treeSubselect = useSimulationTreeSubselect();

  const getMenuItems = useCallback((
    surfaceId: string,
    boundingVolume: StaticVolume | undefined,
    showIcons,
  ) => {
    const menuItems: CommonMenuItem[] = [];

    const surfaceDisabledTooltip = (
      treeSubselect.nodeFilter(NodeType.SURFACE, surfaceId).tooltip ?? ''
    );
    // If the surface is explicity allowed, we obviously show a menu item, but if it's not allowed
    // and there's a tooltip, then it *could* be allowed but isn't for some validation reason.  In
    // either case, show the menu item, disabling if neceesary.
    if (
      canSubselectTypeAndId(treeSubselect.nodeFilter, NodeType.SURFACE, surfaceId) ||
      surfaceDisabledTooltip
    ) {
      menuItems.push({
        label: 'Toggle surface',
        onClick: () => {
          modifySelection({
            action: SelectionAction.TOGGLE,
            modificationIds: [surfaceId],
          });
          setScrollTo({ node: surfaceId, fast: true });
        },
        disabled: !!surfaceDisabledTooltip,
        disabledReason: surfaceDisabledTooltip,
        startIcon: showIcons ? { name: 'cubeOutline' } : undefined,
      });
    }
    if (boundingVolume) {
      const volumeDisabledTooltip = (
        treeSubselect.nodeFilter(NodeType.VOLUME, boundingVolume.id).tooltip ?? ''
      );
      // If the volume is explicity allowed, we obviously show a menu item, but if it's not allowed
      // but there's tooltip, then it *could* be allowed but isn't for some validation reason.  In
      // either case, show the menu item, disabling if neceesary.
      if (
        canSubselectTypeAndId(treeSubselect.nodeFilter, NodeType.VOLUME, boundingVolume.id) ||
        volumeDisabledTooltip
      ) {
        menuItems.push({
          label: 'Toggle volume',
          onClick: () => {
            modifySelection({
              action: SelectionAction.TOGGLE,
              modificationIds: [boundingVolume.id],
            });
            setScrollTo({ node: boundingVolume.id, fast: true });
          },
          disabled: !!volumeDisabledTooltip,
          disabledReason: volumeDisabledTooltip,
          startIcon: showIcons ? { name: 'cubeSolid' } : undefined,
        });
      }
    }
    return menuItems;
  }, [modifySelection, setScrollTo, treeSubselect]);

  return getMenuItems;
};
