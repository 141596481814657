import React, { useState } from 'react';

import { ClickAwayListener } from '@mui/material';

import { CreditStatus, getCreditsStatus } from '../../../lib/credits';
import { colors } from '../../../lib/designSystem';
import { INTERCOM_LAUNCHER_SELECTOR } from '../../../lib/intercom';
import { useIsStarterPlan, useUserRemainingCredits } from '../../../recoil/useAccountInfo';
import { CREDITS_POLLING_TIME_IN_SECONDS } from '../../../recoil/useCreditsPoller';
import { ActionButton } from '../../Button/ActionButton';
import { ActionLink } from '../../Button/ActionLink';
import { createStyles, makeStyles } from '../../Theme';
import Tooltip from '../../Tooltip';
import { DiskExclamationIcon } from '../../svg/DiskExclamationIcon';
import { WarningIcon } from '../../svg/WarningIcon';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      marginRight: '12px',
    },
    addCredits: {
      display: 'flex',
      marginTop: '8px',
    },
    creditsLabel: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    details: {
      marginBottom: '8px',
      color: colors.neutral750,
    },
  }),
  { name: 'RemainingCredits' },
);

const CREDITS_LEARN_MORE_URL = 'https://www.luminarycloud.com/pricing?faq=1';

export const RemainingCredits = () => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const classes = useStyles();
  const remainingCredits = useUserRemainingCredits();
  const creditsStatus = getCreditsStatus(remainingCredits);
  const isStarterPlan = useIsStarterPlan();

  // don't display credits number for non-starter accounts and when there's no response
  if (!isStarterPlan || remainingCredits === null) {
    return null;
  }

  return (
    <div className={classes.root}>
      <ClickAwayListener onClickAway={() => setDetailsVisible(false)}>
        <div>
          <Tooltip
            arrow={false}
            disableFocusListener
            disableHoverListener
            disableInteractive={false}
            disableTouchListener
            onClose={() => setDetailsVisible(false)}
            onOpen={() => setDetailsVisible(true)}
            open={detailsVisible}
            placement="bottom-end"
            slotProps={{
              popper: { modifiers: [{ name: 'offset', options: { offset: [2, -8] } }] },
            }}
            title={(
              <div>
                <div className={classes.details}>
                  Updates every {CREDITS_POLLING_TIME_IN_SECONDS} seconds
                </div>

                Credits enable you to upload geometry,
                generate mesh and run simulations.{' '}
                <ActionLink href={CREDITS_LEARN_MORE_URL}>Learn more</ActionLink>

                <div className={classes.addCredits}>
                  <div className={INTERCOM_LAUNCHER_SELECTOR.replace('.', '')}>
                    <ActionButton onClick={() => {}} size="small">
                      Add Credits via chat
                    </ActionButton>
                  </div>
                </div>
              </div>
            )}>
            <div>
              <ActionButton kind="minimal" onClick={() => setDetailsVisible(true)} size="small">
                <div className={classes.creditsLabel}>
                  {creditsStatus === CreditStatus.ERROR &&
                    <DiskExclamationIcon color={colors.red600} maxWidth={12} />}
                  {creditsStatus === CreditStatus.WARNING &&
                    <WarningIcon color={colors.yellow400} maxWidth={12} />}

                  {remainingCredits} Credit{remainingCredits !== 1 ? 's' : ''}
                </div>
              </ActionButton>
            </div>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};
