// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { METAKEY_SYMBOL } from '../../../lib/navigation';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import { useProjectName } from '../../../recoil/useProjectMetadata';
import { useCurrentTab } from '../../../recoil/useTabsState';
import { useIsGeometryView } from '../../../state/internal/global/currentView';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { RibbonToolbarTool } from '../../RibbonToolbar/RibbonToolbarButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useHandleLcVisFilterClick } from '../../visFilter/useHandleLcVisFilterClick';

/** A group of DataSelects to show in the Toolbar for setting selection options.  */
export const useScreenshotButtonData = (): RibbonToolbarTool | null => {
  // == Contexts
  const { projectId } = useProjectContext();
  const {
    paraviewClientState,
    paraviewRenderer,
  } = useParaviewContext();

  // == Hooks
  const handleLcvButtonClick = useHandleLcVisFilterClick();

  // == Recoil

  const isGeometryView = useIsGeometryView();
  const projectName = useProjectName(projectId);

  const currentTab = useCurrentTab(projectId);
  const lcvisReady = useLcVisReadyValue();
  const lcvisEnabled = useLcVisEnabledValue(projectId);

  const showLcVisScreenshotButton = lcvisEnabled;
  const showParaviewScreenshotButton = (
    !lcvisEnabled &&
    !isGeometryView &&
    currentTab
  );
  const pvClientDisconnected = paraviewClientState.client === null;

  if (showLcVisScreenshotButton) {
    return {
      disabled: !lcvisReady,
      key: 'tool-screenshot',
      icon: { name: 'screenshot' },
      locator: 'camerabar-screenshot',
      onClick: (ev) => handleLcvButtonClick(ev, 'screenshot'),
      label: 'Screenshot',
      title: `Screenshot (press ${METAKEY_SYMBOL} + Click to use a transparent background)`,
    };
  }

  if (showParaviewScreenshotButton) {
    return {
      disabled: pvClientDisconnected,
      key: 'tool-screenshot',
      icon: { name: 'desktopMonitorFramed' },
      locator: 'camerabar-screenshot',
      onClick: (ev) => {
        // To avoid showing a modal dialog to opt in for a screenshot with a transparent
        // background, we allow users to press ctrl (or cmd) + click to enable such a mode.
        const transparentBackground = (ev.ctrlKey || ev.metaKey);
        paraviewRenderer.screenshot(
          `Project ${projectName} ${currentTab!.text}`,
          transparentBackground,
        );
      },
      label: 'Screenshot',
      title: `Screenshot (press ${METAKEY_SYMBOL} + Click to use a transparent background)`,
    };
  }

  return null;
};
