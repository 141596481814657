// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { Level, LeveledMessage, levelToRank } from './notificationUtils';

/** The MessageCollector class stores lists of LeveledMessage objects and exposes convenience
 * methods for managing and inspecting the store. */

export class MessageCollector {
  public messages: LeveledMessage[] = [];

  public addMessage(message: string, level: Level): void {
    this.messages.push({ level, message });
  }

  public addMessages(messages: string[], level: Level): void {
    this.messages.push(...messages.map((message) => ({ level, message })));
  }

  public addLeveledMessages(messages: LeveledMessage[]) {
    this.messages.push(...messages);
  }

  public addError(message: string): void {
    this.addMessage(message, 'error');
  }

  public addErrors(messages: string[]): void {
    this.addMessages(messages, 'error');
  }

  public addWarning(message: string): void {
    this.addMessage(message, 'warning');
  }

  public addWarnings(messages: string[]): void {
    this.addMessages(messages, 'warning');
  }

  public addSuccess(message: string): void {
    this.addMessage(message, 'success');
  }

  public addSuccesses(messages: string[]): void {
    this.addMessages(messages, 'success');
  }

  public addInfo(message: string): void {
    this.addMessage(message, 'info');
  }

  public addInfos(messages: string[]): void {
    this.addMessages(messages, 'info');
  }

  public clear(): void {
    this.messages = [];
  }

  public merge(collector: MessageCollector): void {
    this.addLeveledMessages(collector.messages);
  }

  public getOrderedMessages(): LeveledMessage[] {
    return [...this.messages].sort((a, b) => levelToRank(b.level) - levelToRank(a.level));
  }

  public get hasErrors(): boolean {
    return this.messages.some((message) => message.level === 'error');
  }
}
