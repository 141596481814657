import React, { forwardRef, useEffect, useMemo, useRef } from 'react';

import cx from 'classnames';

import { colors } from '../../../lib/designSystem';
import { OVERLAY_CARD_WIDTH } from '../../../lib/visUtils';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import { useViewStateOverflow } from '../../../recoil/lcvis/viewStateOverflow';
import { useWorkflowFlagValue } from '../../../workflowFlag';
import ColorBarPanel from '../../Paraview/ColorBarPanel';
import { useColorPanelState } from '../../Paraview/ColorPanelManager';
import EditColorsBox from '../../Paraview/EditColorsBox';
import { useDraggable } from '../../Paraview/draggable';
import { createStyles, makeStyles } from '../../Theme';
import { useProjectContext } from '../../context/ProjectContext';

const useStyles = makeStyles(
  () => createStyles({
    colorDialog: {
      position: 'absolute',
      right: '10px',
      top: '10px',
      // Should be higher than FloatingToolbar's zIndex
      zIndex: 4,
    },
    colorPanelsContainer: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      padding: '0px',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: '0px',
      '&.noPointerEvents': {
        pointerEvents: 'none',
      },
    },
    colorPanel: {
      position: 'relative',
      zIndex: 1,
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
      pointerEvents: 'auto',
      padding: '30px 20px 30px 60px',
      backgroundColor: 'transparent',
      filter: `drop-shadow(2px 2px 1px ${colors.neutral100})`,
      transition: 'background-color 500ms, backdrop-filter 500ms',
      '&.dragging': {
        backgroundColor: 'rgb(0, 0, 0, 0.5)',
        backdropFilter: 'blur(3px)',
        zIndex: 2,
      },
    },
  }),
  { name: 'Paraview' },
);

const EDIT_COLOR_DRAG_ID = 'lcvis-color-dialog';

export const LcVisColorPanel = forwardRef((props, ref) => {
  const overlayRef = ref as React.MutableRefObject<HTMLDivElement>;
  const { projectId, workflowId, jobId } = useProjectContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();
  const [lcvisData] = useViewStateOverflow({ projectId, workflowId, jobId });
  const panelNodes = useRef<Array<HTMLDivElement | null>>([]);
  const panelContainer = useRef<HTMLDivElement>(null);
  const dialogNode = useRef<HTMLDivElement>(null);
  const { initDrag, draggedPositions, dragPositionToStyle, currentDragId } = useDraggable();
  const workflowFlag = useWorkflowFlagValue();
  const classes = useStyles();

  const {
    colorDialogState,
    closeEditState,
    cancelEdit,
    updateCmap,
    initColorDialogState,
    lcvColorBarsToShow,
    MAX_BINS,
    MAX_TICKS,
    changeOrientation,
    getVertical,
  } = useColorPanelState();

  const colorPanels = useMemo(() => lcvColorBarsToShow(), [lcvColorBarsToShow]);

  useEffect(() => {
    panelNodes.current = panelNodes.current.slice(0, colorPanels.length);
  }, [colorPanels.length]);

  if (!lcvisEnabled || !lcvisReady || !lcvisData.attrs.colorMaps?.length) {
    return null;
  }

  return (
    <>
      <div
        className={cx(classes.colorPanelsContainer, {
          noPointerEvents: true,
        })}
        ref={panelContainer}>
        {colorPanels.map(({ colorMap, displayVar, dragId, label }, i) => (
          // eslint-disable-next-line jsx-a11y/interactive-supports-focus
          <div
            className={cx(
              classes.colorPanel,
              { dragging: currentDragId === dragId },
            )}
            key={label}
            onKeyUp={() => { }}
            onMouseDown={(event) => {
              if (panelContainer.current && panelNodes.current[i]) {
                initDrag(
                  dragId,
                  event,
                  panelNodes.current[i] as HTMLElement,
                  panelContainer.current as HTMLElement,
                );
              }
            }}
            ref={(el) => {
              panelNodes.current[i] = el;
            }}
            role="button"
            style={{
              ...dragPositionToStyle(draggedPositions[dragId]),
              right: workflowFlag ? OVERLAY_CARD_WIDTH : 0,
              pointerEvents: 'all',
            }}>
            <ColorBarPanel
              bins={colorMap.discretize ? colorMap.bins : MAX_BINS}
              colorMapName={colorMap.presetName}
              // if lcvColorBarsToShow returned a color bar, it's guaranteed to be
              // visible.
              hidden={false}
              isVertical={getVertical(label)}
              label={label}
              max={colorMap.range[1]}
              min={colorMap.range[0]}
              numTicks={colorMap.discretize &&
                    colorMap.bins < MAX_TICKS ?
                colorMap.bins + 1 : MAX_TICKS}
              onClick={() => {
                if (!currentDragId) {
                  initColorDialogState(displayVar);
                }
              }}
              selected={currentDragId === dragId}
            />
          </div>
        ))}
      </div>
      {colorDialogState && (
      <div
        className={classes.colorDialog}
        data-locator="colorDialog"
        ref={dialogNode}
        style={dragPositionToStyle(draggedPositions[EDIT_COLOR_DRAG_ID])}>
        <EditColorsBox
          cancelEdit={cancelEdit}
          changeOrientation={changeOrientation}
          currentCmap={colorDialogState.editedCmap}
          displayVariable={colorDialogState.displayVariable}
          isDragging={currentDragId === EDIT_COLOR_DRAG_ID}
          isVertical={getVertical(colorDialogState.name)}
          onComplete={closeEditState}
          onMouseDown={(event: React.MouseEvent) => {
            if (overlayRef.current && dialogNode.current) {
              initDrag(EDIT_COLOR_DRAG_ID, event, dialogNode.current, overlayRef.current);
            }
          }}
          setCurrentCmap={updateCmap}
        />
      </div>
      )}

    </>
  );
});
