// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect } from 'react';

import { atom, useSetRecoilState } from 'recoil';

import { listen } from '../../lib/event';

// The current active element (the element with focus).
export const activeElementState = atom<Element | null>({
  key: 'activeElementState',
  default: null,
});

/**
 * Adds a listener to update the active element whenever the focus changes.
 */
export const useActiveElement = () => {
  const setActiveElement = useSetRecoilState(activeElementState);

  useEffect(() => {
    const focusListener = listen(
      document,
      'focus',
      () => setActiveElement(document?.activeElement),
      true,
    );
    const blurListener = listen(document, 'blur', () => setActiveElement(null), true);

    return () => {
      focusListener.remove();
      blurListener.remove();
    };
  }, [setActiveElement]);
};
