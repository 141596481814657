// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useMemo, useState } from 'react';

import { colors } from '../../../lib/designSystem';
import { useUserCanEdit } from '../../../lib/projectRoles';
import { useProjectMetadataValue } from '../../../recoil/useProjectMetadata';
import { ProjectMenu } from '../../Menu/ProjectMenu';
import { createStyles, makeStyles } from '../../Theme';
import { ChevronDownIcon } from '../../svg/ChevronDownIcon';
import { ChevronUpIcon } from '../../svg/ChevronUpIcon';
import Tag from '../../treePanel/Tag';

const useStyles = makeStyles(
  () => createStyles({
    dropdownToggle: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      cursor: 'pointer',
    },
    name: {
      color: colors.mediumEmphasisText,
      fontSize: '14px',
    },
  }),
  { name: 'ProjectNameSection' },
);

export interface ProjectNameSectionProps {
  projectId: string;
}

export const ProjectNameSection = (props: ProjectNameSectionProps) => {
  const { projectId } = props;

  const projectMetadata = useProjectMetadataValue(projectId);
  const classes = useStyles();

  const userCanEdit = useUserCanEdit(projectMetadata?.summary);

  const projectName = useMemo(
    () => projectMetadata?.summary!.name || '',
    [projectMetadata],
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div>
      {projectName && (
        <ProjectMenu
          onClose={() => setIsDropdownOpen(false)}
          onOpen={() => setIsDropdownOpen(true)}
          projectSummary={projectMetadata?.summary}>
          <div className={classes.dropdownToggle}>
            <div className={classes.name} data-locator="projectName">{projectName}</div>
            {!userCanEdit && <Tag kind="tertiary" size="small" text="VIEW ONLY" />}
            {isDropdownOpen ? (
              <ChevronUpIcon maxHeight={5} />
            ) : (
              <ChevronDownIcon maxHeight={5} />
            )}
          </div>
        </ProjectMenu>
      )}
    </div>
  );
};
