// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ActionButton } from '../../Button/ActionButton';

export interface HelperButtonProps {
  title?: string;
  onClick: () => void;
  disabled?: boolean;
  label: string;
}

export const HelperButton = (props: HelperButtonProps) => {
  const { disabled, label, onClick, title } = props;

  return (
    <ActionButton
      disabled={disabled}
      kind="minimal"
      onClick={onClick}
      size="small"
      title={title}>
      {label}
    </ActionButton>
  );
};
