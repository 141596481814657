// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const DiskInfoIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="diskInfo"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M12,0
           C5.37,0 0,5.37 0,12
           C0,18.63 5.37,24 12,24
           C18.63,24 24,18.63 24,12
           C24,5.37 18.63,0 12,0Z
           M10.5,4.5
           L13.5,4.5
           L13.5,7.5
           L10.5,7.5
           L10.5,4.5Z
           M15,19.5
           L9,19.5
           L9,18
           L10.5,18
           L10.5,10.5
           L9,10.5
           L9,9
           L13.5,9
           L13.5,18
           L15,18
           L15,19.5Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
