// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 21;
const baseHeight = 15;
export const CheckmarkIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="checkmark"
      height={finalHeight}
      ref={ref}
      style={{ fill: 'none' }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}>
      <path
        d="M 19.5,0
           C 19.08,0 18.705,0.165 18.435,0.435
           L 7.5,11.385 2.565,6.435
           C 2.2950002,6.165 1.9200001,6 1.5,6 0.675,6 0,6.675 0,7.5 0,7.92 0.165,8.295 0.435,8.565
           l 6.0000002,6
           C 6.705,14.835 7.08,15 7.5,15 7.92,15 8.295,14.835 8.565,14.565
           l 12,-12
           C 20.835,2.295 21,1.92 21,1.5 21,0.675 20.325,0 19.5,0 Z"
        fill={getColor(0)}
        style={{ clipRule: 'evenodd', fillRule: 'evenodd' }}
      />
    </svg>
  );
});
