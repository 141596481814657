// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../../../lib/componentTypes/simulationTree';
import { SvgIconSpec } from '../../../../../lib/componentTypes/svgIcon';
import { TreeRow } from '../../../TreeRow';

const PRIMARY_ICON: SvgIconSpec = { name: 'gridUneven' };

export const PhysicsFluidInitializationTreeRow = (props: SimulationRowProps) => (
  <TreeRow
    {...props}
    primaryIcon={PRIMARY_ICON}
  />
);
