// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { BoolValue } from '@bufbuild/protobuf';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

import * as persist from '../lib/persist';
import { syncUserStateEffect } from '../lib/recoilSync';

const returningUserKey = 'returningUser';
const skipSetupSummaryKey = 'skipSetupSummary';

const serialize = (value: boolean) => new BoolValue({ value }).toBinary();
const deserialize = (value: Uint8Array) => BoolValue.fromBinary(value).value;

const returningUserState = atom<boolean>({
  key: returningUserKey,
  effects: [
    syncUserStateEffect(returningUserKey, deserialize, serialize),
    persist.getUserStateEffect(returningUserKey, deserialize),
  ],
});

// Do not call the hook in components where the user might still be unauthenticated
export const useReturningUser = () => useRecoilState(returningUserState);

/**
 * This holds the truth of whether the Settings Preview dialog will appear or not when the user
 * clicks on the Run Simulation button.
 */
const skipSetupSummaryState = atom<boolean>({
  key: skipSetupSummaryKey,
  effects: [
    syncUserStateEffect(skipSetupSummaryKey, deserialize, serialize),
    persist.getUserStateEffect(skipSetupSummaryKey, deserialize),
  ],
});

export const useSkipSetupSummary = () => useRecoilState(skipSetupSummaryState);
export const useSkipSetupSummaryValue = () => useRecoilValue(skipSetupSummaryState);
