// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import * as simulationpb from '../proto/client/simulation_pb';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';
import * as workflowpb from '../proto/workflow/workflow_pb';

import { DEFAULT_BOUNDARY_CONDITION_NAME } from './boundaryConditionUtils';
import { getFluid, getHeat } from './physicsUtils';

// We no longer maintain the special-case "Undefined" boundary condition.  If it appears in an
// existing project, remove it.
export function pruneBoundaryConditions(config: workflowpb.Config) {
  if (config.jobConfigTemplate?.typ.case === 'simulationParam') {
    const param = config.jobConfigTemplate.typ.value;
    param.physics.forEach((physics) => {
      const fluid = getFluid(physics);
      const heat = getHeat(physics);

      if (fluid) {
        fluid.boundaryConditionsFluid = fluid.boundaryConditionsFluid.filter(
          (bc) => bc.boundaryConditionName !== DEFAULT_BOUNDARY_CONDITION_NAME,
        );
      }

      if (heat) {
        heat.boundaryConditionsHeat = heat.boundaryConditionsHeat.filter(
          (bc) => bc.boundaryConditionName !== DEFAULT_BOUNDARY_CONDITION_NAME,
        );
      }
    });
  }
}

const { AGGRESSIVE, CUSTOM_CONTROLS, INTERMEDIATE } = projectstatepb.ControlsPreset;
const { CONSERVATIVE, CUSTOM_DISCRETIZATION, HIGH_ACCURACY } = projectstatepb.DiscretizationPreset;

const {
  UNSET_SPATIAL_DISCRETIZATION_FLUID_PRESET,
  CONSERVATIVE_SPATIAL_DISCRETIZATION_FLUID,
  CUSTOM_SPATIAL_DISCRETIZATION_FLUID,
  DEFAULT_SPATIAL_DISCRETIZATION_FLUID,
  HIGH_ACCURACY_SPATIAL_DISCRETIZATION_FLUID,
} = simulationpb.SpatialDiscretizationFluidPreset;

const {
  AGGRESSIVE_SOLUTION_CONTROLS_FLUID,
  CUSTOM_SOLUTION_CONTROLS_FLUID,
  DEFAULT_SOLUTION_CONTROLS_FLUID,
  INTERMEDIATE_SOLUTION_CONTROLS_FLUID,
  UNSET_SOLUTION_CONTROLS_FLUID_PRESET,
} = simulationpb.SolutionControlsFluidPreset;

const {
  CUSTOM_MATERIAL_FLUID,
  STANDARD_AIR,
  WATER_NTP,
  UNSET_MATERIAL_FLUID_PRESET,
} = simulationpb.MaterialFluidPreset;

function upgradeSolutionControls(
  control: simulationpb.SolutionControlsFluid,
  frontendMenu: projectstatepb.FrontendMenuState,
) {
  // Only upgrade UNSET values
  if (control.solutionControlsFluidPreset === UNSET_SOLUTION_CONTROLS_FLUID_PRESET) {
    switch (frontendMenu.controlsPreset) {
      case AGGRESSIVE: {
        control.solutionControlsFluidPreset = AGGRESSIVE_SOLUTION_CONTROLS_FLUID;
        break;
      }
      case CUSTOM_CONTROLS: {
        control.solutionControlsFluidPreset = CUSTOM_SOLUTION_CONTROLS_FLUID;
        break;
      }
      case INTERMEDIATE: {
        control.solutionControlsFluidPreset = INTERMEDIATE_SOLUTION_CONTROLS_FLUID;
        break;
      }
      default: {
        control.solutionControlsFluidPreset = DEFAULT_SOLUTION_CONTROLS_FLUID;
        break;
      }
    }
  }
}

function upgradeSpatialDiscretization(
  disc: simulationpb.SpatialDiscretizationFluid,
  frontendMenu: projectstatepb.FrontendMenuState,
) {
  // Only upgrade UNSET values
  if (disc.spatialDiscretizationFluidPreset === UNSET_SPATIAL_DISCRETIZATION_FLUID_PRESET) {
    switch (frontendMenu.discretizationPreset) {
      case CONSERVATIVE: {
        disc.spatialDiscretizationFluidPreset = CONSERVATIVE_SPATIAL_DISCRETIZATION_FLUID;
        break;
      }
      case CUSTOM_DISCRETIZATION: {
        disc.spatialDiscretizationFluidPreset = CUSTOM_SPATIAL_DISCRETIZATION_FLUID;
        break;
      }
      case HIGH_ACCURACY: {
        disc.spatialDiscretizationFluidPreset = HIGH_ACCURACY_SPATIAL_DISCRETIZATION_FLUID;
        break;
      }
      default: {
        disc.spatialDiscretizationFluidPreset = DEFAULT_SPATIAL_DISCRETIZATION_FLUID;
        break;
      }
    }
  }
}

function upgradeFluidMaterial(
  fluid: simulationpb.MaterialFluid,
  frontendMenu: projectstatepb.FrontendMenuState,
) {
  if (fluid.materialFluidPreset === UNSET_MATERIAL_FLUID_PRESET) {
    switch (frontendMenu.materialPreset) {
      case projectstatepb.MaterialPreset.STANDARD_AIR: {
        fluid.materialFluidPreset = STANDARD_AIR;
        break;
      }
      case projectstatepb.MaterialPreset.WATER_NTP: {
        fluid.materialFluidPreset = WATER_NTP;
        break;
      }
      default: {
        fluid.materialFluidPreset = CUSTOM_MATERIAL_FLUID;
        break;
      }
    }
  }
}

export function upgradeMultiPhysicsPresets(
  config: workflowpb.Config,
  frontendMenu: projectstatepb.FrontendMenuState,
) {
  // When FVM params are (were) used, the presets were stored in the frontendMenu state.  In the
  // client params, the presets are stored directly on the param objects.  However, in the
  // conversion from FVM to client params, it's not possible to migrate these preset values.  So the
  // conversion sets every preset on every spatial discretization and every solution control to a
  // special "UNSET" enum, which indicates that it needs to be upgraded from the old frontendMenu
  // values.
  //
  // Here we loop over each fluid physics and upgrade its solution controls and spatial
  // discretization settings.
  if (config.jobConfigTemplate?.typ.case === 'simulationParam') {
    const param = config.jobConfigTemplate.typ.value;
    if (param) {
      param.physics.forEach((physics) => {
        const fluid = getFluid(physics);
        const ctrl = fluid?.solutionControlsFluid;
        const disc = fluid?.spatialDiscretizationFluid;

        if (ctrl) {
          upgradeSolutionControls(ctrl, frontendMenu);
        }
        if (disc) {
          upgradeSpatialDiscretization(disc, frontendMenu);
        }
      });
      param.materialEntity.forEach((material) => {
        if (material.material.case === 'materialFluid') {
          upgradeFluidMaterial(material.material.value, frontendMenu);
        }
      });
    }
  }
}
