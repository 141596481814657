// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 94;
const baseHeight = 120;
export const VerticalPlanesFilledTripleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="verticalPlanesFilledTriple"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M73.008,39.853L20,10L20,22.321L60.009,44.853L60.009,102.707L73.008,110L73.008,39.853Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M93.008,29.853L40,0L40,12.321L80.009,34.853L80.009,92.707L93.008,100L93.008,29.853Z"
        style={{ fill: getColor(0) }}
      />
      <path
        d="M0,20L53.008,49.853L53.008,120L0,90.261L0,20Z"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
